import React from 'react'
import { Button, Col, Nav, NavItem, OverlayTrigger, Popover, Row, Tab, Tabs } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { FanvilProdId, x3s, x7a, YeaLinkProdId } from '../../../constants/Constants'
import { fetchHelper } from '../../../helpers/fetchHelper'

const FreePhoneMoreInfo = (
	<Popover id="popover-basic">
		<p>To get this phone(s) for free, during the last step of the signup process (Checkout page), select the <i>Bi-Annual</i> payment option.</p>
        <p>
            <img src="/images/bi-annual.png" className="w-100" alt="Bi-Annual payment screenshot" />
        </p>
	</Popover>
)

class OutboundManualDeskphone extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      activeKey: this.props.match.params.activeTab ? this.props.match.params.activeTab : 'installation',
      showHandsetOrder: false
    }
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>VoIP Phone Setup Guide</title>
                <meta name="description" content="SwitchboardFREE outbound phone - Fanvil X4U manual" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/howitworks-bg.jpg')" }} className="full-width full-hero-small with-text">
            <h1>Deskphone manual</h1>
                <h2 className="subtitle">Make and receive calls on your deskphone.</h2>
            </header>
            <div className="container-styled">
                <div className="container pb-100">
                    <div className="row">
                        <Tabs defaultActiveKey={1} className="tabs-container justify-content-center">
                            <Tab eventKey={1} title="Video instructions">
                                <div className="pt-55 pb-30 mt-50 bt-1">
                                    <div className="col-md-8 mx-auto">
                                        <div className="ratio ratio-16x9">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/1ZhuQESW5tc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                        <div className="ratio ratio-16x9 mt-30">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/jTeRtBAvABc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey={2} title="Text instructions">
                                <div className="row">
                                    <Tab.Container defaultActiveKey={this.state.activeKey}>
                                        <Row className="clearfix">
                                            <div className="bt-1 pt-40 mt-40 mb-20"></div>
                                            <Col sm={2}>
                                                <Nav variant="pills" className="bg-dull mt-30" stacked>
                                                    <NavItem eventKey="installation">Installation</NavItem>
                                                    <NavItem eventKey="interface">Interface</NavItem>
                                                    <NavItem eventKey="contactbook">Using the phonebook</NavItem>
                                                    <NavItem eventKey="calllogs">Call logs</NavItem>
                                                    <NavItem eventKey="dontdisturb">Do-Not-Disturb</NavItem>
                                                    <NavItem eventKey="volume">Audio volume</NavItem>
                                                    <NavItem eventKey="transfercalls">Transferring calls</NavItem>
                                                </Nav>
                                                <a href="/pdf/deskphone-manual.pdf" className="btn btn-sm btn-info btn-block mt-30" download>Download Full Manual</a>
                                            </Col>
                                            <Col sm={10} className="bl-1 pl-30 ">
                                                <Tab.Content animation>
                                                    <Tab.Pane eventKey="installation">
                                                        <div className="row">
                                                            <div className="col-xs-12 alert-success text-center brad-20 p-30 mb-30 mt-30">
                                                                <p>Please note, our Deskphones come pre-configured. Once you receive your phone, all you have to do is plug the ethernet cable into your internet router,
                                                                    plug in the power adapter into the socket, turn the phone on and voila... you can make and receive calls using your SwitchboardFREE number.</p>
                                                            </div>
                                                        </div>
                                                        <h3 className="text-cta text-500 mt-0">Installation</h3>
                                                        <p>
                                                            The device supports two installation modes, desktop and wall-mount.
                                                        </p>
                                                        <h4 className="text-cta bt-5 pt-30 mt-40 text-500">Desktop phone</h4>
                                                        <p>To set up the phone to be used on desktop, please follow the instructions in below picture to install the device.</p>
                                                        <div className="text-center mt-30">
                                                            <img src="/images/fanvil/install1.png" alt="installation #1" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" />
                                                        </div>
                                                        <h4 className="text-cta bt-5 pt-30 mt-40 text-500">Wall mounted</h4>
                                                        <p>
                                                            To mount the device on the wall, please follow the instructions in below picture.
                                                    </p>
                                                        <div className="text-center mt-30">
                                                            <img src="/images/fanvil/install2.png" alt="installation #2" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" />
                                                        </div>
                                                        <h4 className="text-cta bt-5 pt-30 mt-40 text-500">Connecting</h4>
                                                        <p>
                                                            Connect power adapter, network, PC, handset, and headset (optional) to the corresponding ports as described in below picture.
                                                    </p>
                                                        <div className="text-center mt-30">
                                                            <img src="/images/fanvil/install3.png" alt="installation #3" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="interface">
                                                        <h4 className="text-cta text-500">Phone Interface</h4>
                                                        <div className="col-md-6">
                                                            <div className="text-center mt-30 mb-30">
                                                                <img src="/images/fanvil/phone_interface.jpg" alt="phone interface" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" />
                                                            </div>
                                                            <p>The above picture shows the keypad layout and their functions. </p>
                                                            <p>
                                                                Some keys support long-pressing function. Press and hold the key for 1.5 seconds to trigger the long-pressed function.
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 mt-30 text-sm">
                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">1</span>
                                                            <span className="text-500">Soft keys</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">These four buttons provide different functions corresponding to the soft-menu displayed on the screen.</p>

                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">2</span>
                                                            <span className="text-500">Navigation Keys</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">You can press the up/down navigation keys to move the cursor in a screen with multiple items listed; in some configuration or text editor screens, you can press left/right navigation keys to switch options or move the cursor to the left/right.</p>

                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">3</span>
                                                            <span className="text-500">Put on-hold</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">Puts a caller on-hold. Press it again to switch off the on-hold status.</p>

                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">4</span>
                                                            <span className="text-500">Conference</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">Start a 3-way conference call</p>

                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">5</span>
                                                            <span className="text-500">Phonebook</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">By pressing ‘Phonebook’ button, you can open phonebook as a shortcut.</p>

                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">6</span>
                                                            <span className="text-500">Call logs</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">By pressing ‘Call logs’ button, you open call logs as a shortcut.</p>

                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">7</span>
                                                            <span className="text-500">Using Line Keys(Defined by DSS Key)</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">User can use line key to make or answer a call on specific line. If handset has been lifted, the audio channel will be opened in handset, otherwise, the audio channel will be opened in hands-free or headset.</p>

                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">8</span>
                                                            <span className="text-500">Microphone Mute</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">Mute the microphone when on an active call.</p>

                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">9</span>
                                                            <span className="text-500">Redial</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">By pressing ‘Redial’ button, you can redial the last dialled number.</p>

                                                            <span className="bg-cta text-lightest text-500 img-circle text-center number-point mr-5">10</span>
                                                            <span className="text-500">Speaker</span>
                                                            <br />
                                                            <p className="pb-10 pl-30">By pressing this button once, you can turn on the hands-free phone setting.</p>
                                                        </div>
                                                        <div className="col-md-12 mt-30">
                                                            <div className="ratio ratio-16x9">
                                                                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/jTeRtBAvABc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="contactbook">
                                                        <h4 className="text-cta text-500">Using the phonebook</h4>
                                                        <p>
                                                            You can save contacts’ information in the phonebook and dial the contact’s phone number(s) from the phonebook. To open the phonebook, press the soft-menu button [Dir] in the default standby screen or keypad.
                                                        </p>
                                                        <p>
                                                            By default, the phonebook is empty, you may add contact(s) into the phonebook manually or from call logs.
                                                        </p>
                                                        <p>
                                                            If you have the contact book activated on your SwitchboardFREE account, the cloud phonebook will be enabled. This can be selected from the phonebook menu.
                                                            When there are contact records in the phonebook, the contact records will be arranged in the alphabetical order. You can browse the contacts with up/down navigation keys. The record indicator tells user which contact is currently focused. User may check the contact’s information by pressing [OK] button.
                                                        </p>
                                                        <div className="text-center mt-30 mb-30">
                                                            <img src="/images/fanvil/contacts.png" alt="contacts" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" />
                                                        </div>

                                                        <h4 className="text-cta text-500">Adding and removing contacts</h4>
                                                        <p>
                                                            To add a new contact, press the [Add] button to open Add Contact screen and enter the contact information of the followings:
                                                        </p>
                                                        <ul>
                                                            <li>Contact Name</li>
                                                            <li>Tel. Number</li>
                                                            <li>Mobile Number</li>
                                                            <li>Other Number</li>
                                                            <li>Ring Tone</li>
                                                            <li>Contact Group</li>
                                                        </ul>
                                                        <div className="text-center mt-30 mb-30">
                                                            <img src="/images/fanvil/adding_contact.png" alt="adding contacts" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" />
                                                        </div>
                                                        <p>
                                                            You can edit a contact by pressing [Option]  [Edit] button.
                                                        </p>
                                                        <p>
                                                            To delete a contact, move the indicator to the position of the contact to be deleted press [Option]  [Delete] button and confirm with [Yes].
                                                        </p>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="calllogs">
                                                        <h4 className="text-cta text-500">Accessing call logs</h4>
                                                        <p>
                                                            To access all the call logs on the phone, press the soft-menu button [History]. Those call logs are specific for this handset only.
                                                        </p>
                                                        <div className="text-center mt-30 mb-30">
                                                            <img src="/images/fanvil/missed_call_screen.png" alt="missed call screen" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" />
                                                        </div>
                                                        <p>
                                                            In the call logs screen, you can browse the call logs with up/down navigation keys.
                                                        </p>
                                                        <p>
                                                            In the call logs screen, you can browse the call logs with up/down navigation keys. Each call log record is presented with ‘call type’ and ‘call party number / name’. User can check further call log detail by pressing [OK] button and dial the number with [Dial] button or add the call log number to phonebook with pressing [Option] [Add to Contact].
                                                        </p>
                                                        <p>
                                                            you can delete a call log by pressing [Delete] button and can clear all call logs by pressing [Clear] button.
                                                        </p>

                                                        <p>
                                                            You can also filter call logs with specific call log type to narrow down the call log records by pressing the left/right navigation button and select one of the call log types in the soft-menu buttons:
                                                        </p>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="dontdisturb">
                                                        <h4 className="text-cta text-500">Do-Not-Disturb</h4>
                                                        <div className="text-center mt-30 mb-30">
                                                            <img src="/images/fanvil/dndon.png" alt="Do Not Disturb" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" />
                                                        </div>
                                                        <p>The above picture shows the keypad layout and their functions. </p>
                                                        <p>
                                                            Some keys support long-pressing function. Press and hold the key for 1.5 seconds to trigger the long-pressed function.
                                                        </p>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="volume">
                                                        <h4 className="text-cta text-500">Adjusting audio volume</h4>
                                                        <p>
                                                            Use the highlighted buttons to adjust the volume.
                                                        </p>
                                                        <div className="text-center mt-30 mb-30">
                                                            <img src="/images/fanvil/volume.jpg" style={{ maxWidth: '100%' }} alt="adjust volume" width="700" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="transfercalls">
                                                    <h4 className="text-500 text-cta mt-50">Blind transfer</h4>
                                                    <p>
                                A blind transfer is when you transfer the caller to another users extension without speaking to them first.
                                </p>
                                <p className="text-500 pt-10 pb-20">
                                How to perform a blind transfer on your:<br/>deskphone, softphone and SwitchboardFREE app
                                </p>
                                <ol className="text-400 pl-15">
                                    <li className="pb-10">
                                        <span className="text-500">Softphone:</span>
                                        <br/>
                                        Press the transfer button <span className="text-500">or</span> press #7,
                                        followed by the extension number you wish to transfer the call to.
                                    </li>
                                    <li className="pb-10">
                                        <span className="text-500">Desk phone:</span>
                                        <br/>
                                        Press #7, followed by the extension number you wish to transfer the call to.
                                    </li>
                                    <li className="pb-10">
                                        <span className="text-500">SwitchboardFREE Phone App: </span>
                                        <br/>
                                        Press #7, followed by the extension number you wish to transfer the call to.
                                    </li>
                                </ol>
                                <p>
                                If a call is not answered by the final recipient in 10 seconds then call will return to the original recipient.
                                </p>

                                <h4 className="text-500 text-cta mt-50">An Assisted transfer</h4>
                                <p>
                                    An assisted transfer is when you transfer the caller to another users extension,
                                    but before the call is put through, you will be able to speak to the other user before transfering the caller to them. This is useful to make a polite introduction.
                                </p>
                                <p className="text-500 pt-10 pb-20">
                                    How to perform a assisted transfer on your:<br/>Softphone, Desk phone &amp; SwitchboardFREE Phone App
                                </p>
                                <ol className="text-400 pl-15">
                                    <li className="pb-10">
                                        Press #8, followed by the extension number of the user you wish to dial.
                                    </li>
                                    <li className="pb-10">
                                        You can then speak to that person before connecting the caller to them.

                                    </li>
                                    <li className="pb-10">
                                        Hang up the call to connect the two parties.
                                    </li>
                                </ol>
                                <p>
                                If a call is not answered by the final recipient in 10 seconds then call will return to the original recipient.
                                </p>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
            <div className="full-width bg-dull">
                <div className="container">
                    <div className="row text-center mb-30 mt-30">
                        <div className="col-md-8 mx-auto">
                            <div className="row">
                                <div className="col-xs-12 mb-30">
                                    <h3 className="text-500">Other devices manuals</h3>
                                </div>
                                <div className="col-xs-4 col-xxs-12 mb-20">
                                    <Link to={'/setup/wireless-phone'}>
                                        <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                                            <img src="/images/product-shots/ecom/W52P.jpg" className="img-responsive" />
                                            <h5 className="text-500 text-sm-md">Wireless Phone</h5>
                                            <Link to={'/setup/wireless-phone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xs-4 col-xxs-12 mb-20">
                                    <Link to={'/setup/app'}>
                                        <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                                            <img src="/images/product-shots/ecom/app.jpg" className="img-responsive" />
                                            <h5 className="text-500 text-sm-md">App</h5>
                                            <Link to={'/setup/app'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xs-4 col-xxs-12 mb-20">
                                    <Link to={'/setup/softphone'}>
                                        <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                                            <img src="/images/product-shots/ecom/softphone.jpg" className="img-responsive" />
                                            <h5 className="text-500 text-sm-md">PC Softphone</h5>
                                            <Link to={'/setup/softphone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="full-width bg-light">
                <div className="container" style={{ width: '2000px', maxWidth: '100%' }}>
                    <div className="row">
                        <div className="col-md-12 mx-auto pt-30 mb-100">
                            <div className="row">
                                <div className="col-md-12 text-center pt-30">
                                    <h3 className="text-500">Get Your HD VoIP Handset</h3>
                                    {/* <h4 className="text-cta"><span className="text-500">FROM JUST £6</span> p/month</h4> */}

                                    <h5 className="text-500 pt-10 pb-30">
                                        HASSLE-FREE Installation <span className="text-cta">•</span> FREE UK Support <span className="text-cta">•</span> UNRIVALLED Quality
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-3 col-xxs-12 text-center mb-20">
                                    <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light ">
                                        <img src="/images/product-shots/ecom/W53P.jpg" alt="Cordless VoIP Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">Cordless VoIP Handset</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£7.50</span> p/month</p> */}
                                        <p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
                                        <p className="pt-5 pb-5 pl-20 pr-20 text-center d-block">&nbsp;</p>
                                        <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(YeaLinkProdId)}>Order Now</Button>
                                        <br/>
                                        <a href="/products/yealink-w53p-wireless-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-xxs-12 text-center mb-20">
                                    <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                        <img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">Advanced VoIP Handset</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
                                        <p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
                                        <p className="pt-5 pb-5 pl-20 pr-20 text-center d-block">&nbsp;</p>
                                        <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(FanvilProdId)}>Order Now</Button>
                                        <br/>
                                        <a href="/products/advanced-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-xxs-12 text-center mb-20">
                                    <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                        <img src="/images/product-shots/ecom/X3S.jpg" alt="Fanvil X3S VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">VoIP Handset</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
                                        <p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
                                        <div className="d-block">
                                            <p className="bg-secondary brad-10 pt-5 pb-5 pl-20 pr-20 text-center text-lightest d-inline-block">
                                                Get it FREE! <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={FreePhoneMoreInfo}>
                                                    <span className="underline cursor-pointer ml-5 text-sm">See how</span>
                                                    </OverlayTrigger>.
                                            </p>
                                        </div>

                                        <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(x3s)}>Order Now</Button>
                                        <br/>
                                        <a href="/products/voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-xxs-12 text-center mb-20">
                                    <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                        <img src="/images/product-shots/ecom/X7A.jpg" alt="Fanvil X7A Android IP Video Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">Android Touchscreen Phone</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
                                        <p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
                                        <p className="pt-5 pb-5 pl-20 pr-20 text-center d-block">&nbsp;</p>

                                        <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(x7a)}>Order Now</Button>
                                        <br/>
                                        <a href="/products/x7a-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentDidMount () {
    this.getHardware()
    const tabs = document.getElementsByClassName('nav-tabs')
    tabs[0].classList.add('nav-tabs-centered')
    const wrapper = document.createElement('div')
    wrapper.classList.add('tabs-container')
    tabs[0].parentNode.insertBefore(wrapper, tabs[0])
    wrapper.appendChild(tabs[0])
  }

  getHardware () {
    const url = 'api/Purchase/GetHardware'
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data) {
          this.setState({ products: res.data })
        }
      }).catch(err => console.error(err))
  }

  handleAddHandset (prodId) {
    const product = this.state.products.filter(f => f.prodId === prodId)
    const tempData = [{
      qty: 1,
      ...product[0]
    }]

    localStorage.setItem('HardwareFirst', JSON.stringify(tempData))

    this.props.history.push('/get-started')
  }
}
export default connect(
  state => {
    return state
  },
  {}
)(OutboundManualDeskphone)
