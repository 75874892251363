import moment from 'moment'
import React from 'react'

const QueueMonitor = (props) => {
  const thresholdTriggered = props.waiting >= props.alertThreshold
  return (
        <div className={props.depNo ? 'col-xs-12 col-sm-6 col-md-4 mt-30 pl-10 pr-10'.concat(thresholdTriggered ? ' threshold-warning' : '') : 'col-xs-12 col-sm-12 col-md-6 mx-auto mt-30 pl-10 pr-10 single'.concat(thresholdTriggered ? ' threshold-warning' : '')} style={{ width: `${props.width}%` }}>
            <div className="col-xs-12 p-0 b-1 box-rounded box-shadow bg-light">
                {props.depNo && <div className="p-15 text-center brad-t-10 bg-secondary">
                    <h2 className="text-lg mt-0 pt-10 text-lightest">Department #{props.depNo}</h2>
                    <h3 className="text-sm mt-0 text-lightest">{props.description ? props.description : 'No description'}</h3>
                </div>}

                {/* On hold */}
                <div className="col-xs-12 pl-0 pr-0 bb-1">
                    <div className="col-xxs-12 col-xs-12 text-center">
                        <p className="mt-15">People waiting in queue:</p>
                    </div>
                    <div className="col-xxs-12 col-xs-12 text-center pb-xxs-20">
                        <h3 className="mt-0 text-500">{props.waiting}{thresholdTriggered && <span className="glyphicon glyphicon-exclamation-sign text-danger text-sm" title="You've reached the critical amount of people on hold."/>}</h3>
                    </div>
                </div>

                {/* Next person to be connected hold-time */}
                <div className="col-xs-12 pl-0 pr-0 bb-1">
                    <div className="col-xxs-12 col-xs-12 text-center">
                        <p className="mt-15">The next person in queue has been waiting:</p>
                    </div>
                    <div className="col-xxs-12 col-xs-12 text-center pb-xxs-20">
                        <h3 className="mt-0 text-500">{moment().startOf('day').seconds(props.maxHold).format('HH:mm:ss')}</h3>
                    </div>
                </div>

                {/* Average hold-time */}
                <div className="col-xs-12 pl-0 pr-0 bb-1">
                    <div className="col-xxs-12 col-xs-12 text-center">
                        <p className="mt-15">Average hold time:</p>
                    </div>
                    <div className="col-xxs-12 col-xs-12 text-center pb-xxs-20">
                        <h3 className="mt-0 text-500">{moment().startOf('day').seconds(props.avgHold).format('HH:mm:ss')}</h3>
                    </div>
                </div>

                {/* Conntected */}
                <div className="col-xs-12 pl-0 pr-0 bb-1">
                    <div className="col-xxs-12 col-xs-12 text-center">
                        <p className="mt-15">Currently connected:</p>
                    </div>
                    <div className="col-xxs-12 col-xs-12 text-center pb-xxs-20">
                        <h3 className="mt-0 text-500">{props.connected}</h3>
                    </div>
                </div>

                {/* Maximum on hold alert */}
                <div className="col-xs-12 pl-0 pr-0">
                    <div className="col-xxs-12 col-xs-12 text-center">
                        <p className="mt-15">Alert me when the number of people in the queue reaches: <span style={{ fontWeight: 'bold' }}>{props.alertThreshold}</span></p>
                    </div>
                    <div className="col-xxs-8 col-xs-8 text-center pb-30">
                        <input className="mt-10 form-control" type="number" value={props.alertThresholdNotSet} onChange={event => props.handleAlertThresholdChange(event)} />
                    </div>
                    <input type="button" style={{ height: '30px', marginTop: '11px', paddingTop: '10px', marginRight: '7px', marginLeft: '-7px' }} className="btn-xs btn btn-success col-xxs-4 col-xs-4 text-center pb-30" value="Set" onClick={() => props.handleAlertThresholdSet()} />
                </div>
            </div>
        </div>
  )
}

QueueMonitor.propTypes = {}
QueueMonitor.defaultProps = { alertThreshold: 2, alertThresholdNotSet: 2 }

export default QueueMonitor
