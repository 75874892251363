import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'

// TODO: If constants is empty then make sure that the baseUrl is correct

class FetchHelper {
  token: string = ''

  init (token: string) {
    this.token = token
  }

  dispose () {
    this.token = ''
  }

  getJson: <T>(url: string, token?: string, ignoreToken?: boolean) => Promise<AxiosResponse<T>> = async <T>(url: string, token: string, ignoreToken: boolean) => {
    // TODO:Redirect on 401
    // TODO:Store user somewhere so not having to retrieve on every request

    let config: AxiosRequestConfig = {}

    if ((token || this.token) && !ignoreToken) {
      const authorization = 'Bearer '.concat(token || this.token)
      config = {
        ...config, headers: { Authorization: authorization, 'Cache-Control': 'no-cache' }
      }
    }

    const promise: Promise<AxiosResponse<T>> = axios.get<T, AxiosResponse<T>, T>(url, config)
      .then(res => {
        return res
      }).catch(function (error) {
        console.error(error)

        if (error.response && error.response.status === 401) {
          // Make sure we haven't recently logged out
          const loggedOut = sessionStorage.getItem('loggedout')
          if (loggedOut) {
            // @ts-expect-error
            const diffMs = (new Date() - new Date(loggedOut))
            const mins = Math.floor((diffMs / 1000) / 60)
            if (mins <= 0) { return }
          } else if (loggedOut) {
            sessionStorage.removeItem('loggedout')
          }

          localStorage.removeItem('user')
          localStorage.removeItem('userDetails')
          window.location.href = `/login?authError=true&redirect=${window.location.pathname}`
        }

        if (error.response && error.response.status === 500 && error.response.data.includes('Mandatory password reset required')) {
          const userLocal = localStorage.getItem('user')
          if (userLocal !== null) {
            const email = JSON.parse(userLocal).email
            if (email) {
              window.location.href = `/reset-password/&acc=${email}`
            } else {
              window.location.href = '/reset-password/'
            }
          } else {
            window.location.href = '/reset-password/'
          }
        }

        return error
      })
    return await promise
  }

  async getBlob (url: string, token?: string) {
    // TODO:Redirect on 401
    // TODO:Store user somewhere so not having to retreive on every request

    let config: AxiosRequestConfig = { responseType: 'blob' }

    if (token || this.token) {
      const authorization = 'Bearer '.concat(token || this.token)
      config = { ...config, headers: { Authorization: authorization, 'Cache-Control': 'no-cache' } }
    }

    if (config) {
      return await axios.get(url, config)
        .then(res => {
          return res
        }).catch(function (error) {
          console.error(error)
          return error
        })
    }
  }

  async postJson<T, B>(url: string, body: B, token?: string): Promise<AxiosResponse<T>> {
    let config = {}

    if (token || this.token) {
      const authorization = 'Bearer '.concat(token || this.token)
      config = { ...config, headers: { Authorization: authorization } }
    }

    const promise = axios.post<T, AxiosResponse<T>, B>(url, body, config)
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.error(error)
        return error
      })

    return await promise
  }

  async postFile (url: string, fileData: string | Blob, token: string) {
    let config: AxiosRequestConfig = { headers: { 'Content-Type': 'multipart/form-data' } }

    if (token || this.token) {
      const authorization = 'Bearer '.concat(token || this.token)
      // let authHeader = {'Authorization': authorization};
      config = {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data'
        }
      }
    }

    const formData = new FormData()
    formData.append('File', fileData)

    return await axios.post(url, formData, config)
      .then(function (response: AxiosResponse) {
        return response
      })
      .catch(function (error) {
        console.error(error)
        return error
      })
  }

  isUnauthorised (response) {
    const isUnauthorised = (response && response.response && response.response.status === 401)
    if (isUnauthorised) {
      localStorage.removeItem('user')
      localStorage.removeItem('userDetails')
      this.dispose()
    }

    return isUnauthorised
  }
}

const fetchHelper = new FetchHelper()
export { fetchHelper }
