import React, { Component } from 'react'

export default class ReviewsBadgeWidget extends Component {
  render () {
    return (
            <div id="badge-ribbon"></div>
    )
  }

  componentDidMount () {
    // create widget code
    if (window && window.reviewsBadgeRibbon) {
      const widgetInit = document.createElement('script')
      widgetInit.type = 'text/javascript'
      widgetInit.async = true

      if (this.props.position === 'center') {
        if (this.props.size === 'medium') {
          widgetInit.innerHTML = "reviewsBadgeRibbon('badge-ribbon', { store: 'switchboard-free', mono: '', size: 'medium', css: '.BadgeRibbon { margin: 0; display: inline-block; text-align: center; } .BadgeRibbon .BadgeRibbon__cell { display: inline-block; } .BadgeRibbon__img__content img {opacity: 0.4 !important;} .BadgeRibbon__read__reviews {opacity: 0.7 !important;}' });"
        } else {
          widgetInit.innerHTML = "reviewsBadgeRibbon('badge-ribbon', { store: 'switchboard-free', mono: '', size: 'small', css: '.BadgeRibbon { margin: 0; display: inline-block; text-align: center; } .BadgeRibbon .BadgeRibbon__cell { display: inline-block; } .BadgeRibbon__img__content img {opacity: 0.4 !important;} .BadgeRibbon__read__reviews {opacity: 0.7 !important;}' });"
        }
      } else if (this.props.position === 'right') {
        if (this.props.size === 'medium') {
          widgetInit.innerHTML = "reviewsBadgeRibbon('badge-ribbon', { store: 'switchboard-free', mono: '', size: 'medium', css: '.BadgeRibbon { margin: 0; display: inline-block; text-align: right; } .BadgeRibbon .BadgeRibbon__cell { display: inline-block; } .BadgeRibbon__img__content img {opacity: 0.4 !important;} .BadgeRibbon__read__reviews {opacity: 0.7 !important;}' });"
        } else {
          widgetInit.innerHTML = "reviewsBadgeRibbon('badge-ribbon', { store: 'switchboard-free', mono: '', size: 'small', css: '.BadgeRibbon { margin: 0; display: inline-block; text-align: right; } .BadgeRibbon .BadgeRibbon__cell { display: inline-block; } .BadgeRibbon__img__content img {opacity: 0.4 !important;} .BadgeRibbon__read__reviews {opacity: 0.7 !important;}' });"
        }
      } else if (this.props.position === 'left') {
        if (this.props.size === 'medium') {
          widgetInit.innerHTML = "reviewsBadgeRibbon('badge-ribbon', { store: 'switchboard-free', mono: '', size: 'medium', css: '.BadgeRibbon { margin: 0; display: inline-block; text-align: left; } .BadgeRibbon .BadgeRibbon__cell { display: inline-block; } .BadgeRibbon__img__content img {opacity: 0.3 !important;} .BadgeRibbon__read__reviews {opacity: 0.7 !important;}' });"
        } else {
          widgetInit.innerHTML = "reviewsBadgeRibbon('badge-ribbon', { store: 'switchboard-free', mono: '', size: 'small', css: '.BadgeRibbon { margin: 0; display: inline-block; text-align: left; } .BadgeRibbon .BadgeRibbon__cell { display: inline-block; } .BadgeRibbon__img__content img {opacity: 0.3 !important;} .BadgeRibbon__read__reviews {opacity: 0.7 !important;}' });"
        }
      } else {
        if (this.props.size === 'medium') {
          widgetInit.innerHTML = "reviewsBadgeRibbon('badge-ribbon', { store: 'switchboard-free', mono: '', size: 'medium' });"
        } else {
          widgetInit.innerHTML = "reviewsBadgeRibbon('badge-ribbon', { store: 'switchboard-free', mono: '', size: 'small' });"
        }
      }

      // append to body
      document.body.appendChild(widgetInit)
    }
  }
}
