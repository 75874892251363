import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'

class CallTranscriptionSalesPage extends React.Component {
  render () {
    return <>
			<ScrollToTopOnMount />
            <DisplayMainHeader />
            <DisplayMainFooter />
			<Helmet>
				<title>Conversation Search</title>
				<meta name="description" content="Automatically transcribe your calls to text." />
				<style type="text/css">{`
                       .no-bg{
                            background-image:none !important;
                       }
                       .well, .well.well-transparent p,.well.well-transparent h5{
                        color:#3b3b3b !important;
                       }

                    `}</style>
			</Helmet>

			<header>
				<div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/call-filtering.jpg')" }}>
					<h1>Conversation Search</h1>
					<h2 className="subtitle">Transcribe, Search, and Streamline with Conversation Search</h2>
				</div>
			</header>

			<div className={'full-width bg-light'}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-10">

							<div className="row mt-50 mb-60">
								<div className="col-md-6 col-sm-12 order-last pt-100 pt-xs-0">
									<img src="/images/conversation-search2.jpg" alt="Coversation Search with transcription" className="img-responsive brad-20 " style={{ maxWidth: "600px" }} />
								</div>
								<div className="col-md-6 col-sm-12 order-first">
									<h2 className="text-500 text-darker">
										Transcribe &amp; Revise
									</h2>
									<h2 className="text-lg text-400 mb-30 mt-10 text-dark-light w-600px">
										Unlock your business potential with our transcribe & search feature.
									</h2>

									<p>
										Gone are the days when you and your staff have to make notes during each call to ensure important details are taken down. With Conversation Search, every word is recorded and transcribed into easy-to-read text.
									</p>

									<p>
										And it doesn't just stop there. Now you can easily search for specific words or phrases that have been said, or not said.
									</p>

									<p>
										Plus, you can set custom alerts for specific words or phrases mentioned or omitted from conversations.
									</p>

									<p className="text-500 mb-0">Stop:</p>
									<ul className="styled-list success rounded text-start text-400 mt-20 pl-0">
										<li className="pb-10">Manically taking notes whilst taking calls.</li>
										<li className="pb-10">Paying staff to manually transcribe call recordings.</li>
										<li className="pb-10">Scrolling through & searching hours of conversations for key information.</li>
									</ul>
									<p className="text-500 mt-30 mb-0">Start:</p>
									<ul className="styled-list success rounded text-start text-400 mt-20 pl-0">
										<li className="pb-10">Allowing you & your staff to concentrate on the call, not the note-taking.</li>
										<li className="pb-10">Saving time & money by letting SwitchboardFREEs advanced technology record, transcribe & index your calls.</li>
										<li className="pb-10">Effortlessly searching through your transcripts for crucial information using terms, keywords or phrases.</li>
									</ul>

									<Link variant="cta" className="btn btn-cta btn-lg mt-30 mb-0" to={'/get-started'} > Create account </Link>

								</div>
							</div>
							<div className="row mt-100 mb-60">
								<div className="col-md-6 col-sm-12 order-last order-md-first text-end pt-100 pt-xs-0">
									<img src="/images/conversation-search.jpg" alt="Coversation Search with transcription" className="img-responsive brad-20 " style={{ maxWidth: "600px" }} />
								</div>
								<div className="col-md-6 col-sm-12 order-first order-md-last">
									<h2 className="text-500 text-darker">
										Drive Your Business Forward
									</h2>
									<h2 className="text-lg text-400 mb-30 mt-10 text-dark-light w-600px">
										Use Conversation Search to transform & elevate your business
									</h2>

									<p>
										If you don't currently record or transcribe your calls, then you could be missing out on some key benefits that could positively impact your business and help it to grow. </p>

									<ul className="styled-list success rounded text-start text-400 mt-40 pl-0">
										<li className="pb-10">
											<strong>Boost staff productivity,</strong> by ensuring employees spend less time on admin & more time on engaging with customers.
										</li>
										<li className="pb-10">
											<strong>Easily analyse data,</strong> & keep up-to-date with your customer's needs, preferences & pain points to deliver consistent & exceptional customer service.
										</li>
										<li className="pb-10">
											<strong>Enhance your decision-making,</strong> by uncovering valuable insights within your call data to optimise your communication & marketing strategies.
										</li>
										<li className="pb-10">
											<strong>Remain compliant,</strong> by easily checking that staff are passing on essential information or terms & conditions to your customers.
										</li>
										<li className="pb-10">
											<strong>Simplify training & quality control,</strong> by revisiting past calls to highlight best practices, identify areas for improvement, or educate new team members.
										</li>
									</ul>

									<Link variant="cta" className="btn btn-cta btn-lg mt-30 mb-0" to={'/get-started'} > Create account </Link>

								</div>
							</div>

							<div className="row mt-100 mb-60 justify-content-center">
								<div className="col-10 text-center box-shadow-v3 p-40 brad-20">
									<h2 className="text-lg text-400 mb-30 mt-10 text-dark-light">
										With no fixed monthly charges and a budget-friendly rate of 5p per minute of transcribed calls, Conversation Search is the perfect addition to your call management system.
									</h2>

									<Link variant="cta" className="btn btn-cta btn-lg mt-30 mb-0" to={'/get-started'} > Create account </Link>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
  }
}

export default CallTranscriptionSalesPage
