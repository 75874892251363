import moment from 'moment'
import { fetchHelper } from './fetchHelper'

export function genericHelper () {
  window.addEventListener('load', function () {
    try {
      // Check for marketing source
      const urlParams = new URLSearchParams(window.location.search)
      let clickId = ''
      let source = ''
      let campaign = ''
      let medium = ''

      if (urlParams.get('utm_source') !== null) { source = urlParams.get('utm_source') }

      if (urlParams.get('gclid') !== null || urlParams.get('gclsrc') !== null || urlParams.get('wbraid') !== null || urlParams.get('gbraid') !== null) {
        source = 'Adwords'
        clickId = urlParams.get('gclid') !== null
          ? urlParams.get('gclid')
          : urlParams.get('gclsrc') !== null
            ? urlParams.get('gclsrc')
            : urlParams.get('wbraid') !== null ? urlParams.get('wbraid') : urlParams.get('gbraid')
      }

      if (urlParams.get('msclkid') !== null) {
        source = 'Bing'
        clickId = urlParams.get('msclkid')
      }

      if (urlParams.get('') !== null) {
        source = 'Twitter'
        clickId = urlParams.get('twclid')
      }

      if (urlParams.get('yclid') !== null) {
        source = 'Yahoo'
        clickId = urlParams.get('yclid')
      }

      if (urlParams.get('fbclid') !== null) {
        source = 'Facebook'
        clickId = urlParams.get('fbclid')
      }

      if (urlParams.get('utm_campaign') !== null) { campaign = urlParams.get('utm_campaign') }

      if (urlParams.get('utm_medium') !== null) { medium = urlParams.get('utm_medium') }

      const data = {
        clickId,
        source,
        campaign,
        medium,
        referrer: document.referrer
      }

      localStorage.setItem('marketingData', JSON.stringify(data))
    } catch (err) {
      window.console(err.Message)
    }

    window.scrollToTop.init()
  })

  window.addEventListener('click', function (e) {
    if (e.target.matches('.download-btn')) {
      e.target.setAttribute('disabled', 'true')
    }

    if (e.target.matches('.open-live-chat')) {
      e.preventDefault()
      if (window.lhnJsSdk) {
        window.lhnJsSdk.openHOC('c')
      }
    }

    const device = navigator.userAgent.toLowerCase()
    const ios = device.match(/(iphone|ipod|ipad)/)
    if (ios) {
      const ele = e.target
      const link = ele.getAttribute('href')
      if (link && link !== '#') window.location = link
    }

    if (e.target.hasAttribute('data-delay')) {
      const delay = parseInt(e.target.getAttribute('data-delay'))
      e.target.setAttribute('disabled', 'true')
      this.setTimeout(function () {
        e.target.removeAttribute('disabled')
      }, delay * 1000)
    }
  })

  window.addEventListener('scroll', function () {
    // Leave beta banner
    if (localStorage.getItem('isBeta')) {
      if (this.pageYOffset > 0) {
        document.body.classList.add('scrolled-down')
      } else {
        document.body.classList.remove('scrolled-down')
      }
    }

    let lastScrollTop = 0
    const progressSteps = document.getElementsByClassName('signup-progress')
    if (progressSteps.length > 0) {
      const container = progressSteps[0].closest('.progress-container')
      const st = window.pageYOffset || document.documentElement.scrollTop
      if (st > lastScrollTop) {
        container.classList.add('scroll-down')
      } else {
        container.classList.remove('scroll-down')
      }
      lastScrollTop = st <= 0 ? 0 : st
    }

    window.scrollToTop.toggle()
  })

  // Scroll to top
  window.scrollToTop = {
    scroll: function () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    init: function () {
      const getEle = document.getElementById('scrollTop')
      if (getEle === null) {
        const scrollTopEle = document.createElement('a')
        scrollTopEle.setAttribute('id', 'scrollTop')
        scrollTopEle.setAttribute('onclick', 'javascript:window.scrollToTop.scroll();')
        scrollTopEle.innerHTML = "<span class='icon icon-arrow-up4'></span>"
        document.body.appendChild(scrollTopEle)
      }
      this.toggle()
    },
    toggle: function () {
      const scrollTop = document.getElementById('scrollTop')
      if (scrollTop !== null) {
        scrollTop.style.display = (window.pageYOffset > 850 && window.outerWidth <= 800) ? 'inline-block' : 'none'
      }
    }
  }

  // Exit Intent
  window.exitIntent = {
    showPopup: function () {
      const cookie = JSON.parse(localStorage.getItem('exitIntent')) // dateStamp (moment) & shown (bool)
      let duration = 0
      if (cookie) {
        duration = moment.duration(moment().diff(cookie.dateStamp)).days()
      }

      // Only show exit intent once a week
      if (cookie === null || duration >= 7) {
        const exitPopup = document.getElementById('exitPopup')
        if (exitPopup !== null) {
          exitPopup.classList.add('show')
        }

        const exitIntent = {
          shown: true,
          dateStamp: moment()
        }
        localStorage.setItem('exitIntent', JSON.stringify(exitIntent))
      }
    }
  }

  // Insert at cursor
  window.insertAtCursor = {
    insert: function (ele, text) {
      const txtarea = document.getElementById(ele)
      if (!txtarea) {
        return
      }

      const scrollPos = txtarea.scrollTop
      let strPos = 0
      const br = ((txtarea.selectionStart || txtarea.selectionStart === '0')
        ? 'ff'
        : (document.selection ? 'ie' : false))
      if (br === 'ie') {
        txtarea.focus()
        const range = document.selection.createRange()
        range.moveStart('character', -txtarea.value.length)
        strPos = range.text.length
      } else if (br === 'ff') {
        strPos = txtarea.selectionStart
      }

      const front = (txtarea.value).substring(0, strPos)
      const back = (txtarea.value).substring(strPos, txtarea.value.length)
      strPos = strPos + text.length
      if (br === 'ie') {
        txtarea.focus()
        const ieRange = document.selection.createRange()
        ieRange.moveStart('character', -txtarea.value.length)
        ieRange.moveStart('character', strPos)
        ieRange.moveEnd('character', 0)
        ieRange.select()
      } else if (br === 'ff') {
        txtarea.selectionStart = strPos
        txtarea.selectionEnd = strPos
        txtarea.focus()
      }

      txtarea.scrollTop = scrollPos

      return front + text + back
    }
  }

  const isInViewport = function (elem) {
    const bounding = elem.getBoundingClientRect()
    return (
      bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  window.isIos = {
    check: function () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    }
  }
}

export function getBasketCount (basket) {
  // Safety check
  if (basket) {
    const parsedBasket = JSON.parse(basket)
    let count = 0

    if (parsedBasket.numbers && parsedBasket.numbers.length > 0) { count += parsedBasket.numbers.length }

    if (parsedBasket.hardware) {
      parsedBasket.hardware.forEach(i => count += i.qty)
    }

    if (parsedBasket.package && parsedBasket.package.packageId) { count++ }

    return count
  } else {
    return 0
  }
}

export async function getCustomerCount() {
  const url = 'api/Home/GetTotalCustomers';
  const response = await fetchHelper.getJson(url);
  if (response !== undefined) {
    const res = response.data.toLocaleString(navigator.language);
    return res;
  } else {
    return "";
  }
}
