import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'
import CustomPrompts from '../../CustomPrompts'

const CustomRecordingSectionHelperDialog = props => (
    <Modal className="w-900px" show={props.isDisplayed}
        onHide={props.hideCustomRecordingMethodHelperDialog}>
        <Modal.Header className="bb-0 pb-0" closeButton>
            {/* <Modal.Title>Custom Recordings</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="clearfix pt-0">
        <div className="col-md-12 pl-0 pr-0">
                <div className="col-md-12 text-center pb-30">
                    <h3 className="text-500">Make your company sound amazing!</h3>
                    <p>Choose one of the below voice artists to record your out of hours message, introduction, call screening, voicemail message, departments menu... or simply do it yourself.</p>
                </div>
            </div>
            <CustomPrompts />

        </Modal.Body>
        <Modal.Footer>
            <Button variant="default" size="xs" onClick={props.hideCustomRecordingMethodHelperDialog}>Close</Button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.customRecordingInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomRecordingSectionHelperDialog)
