import React from 'react'

const FeaturesList = () => {
  return (
        <ul className="unstyled text-400">
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                A.I. caller greeting
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Virtual phone numbers
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Open & close times
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Queue inbound calls
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Announce position
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Departments
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Redirect your calls
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Teams
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Call screening
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Custom prompts & voices
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Music-on-hold
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Missed call alerts
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Voicemail to email
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Voicemail transcription
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Call transcription
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Fast config changes
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Cloud storage
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Quick close/open
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                iOS & Android apps
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Webhooks
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Contact book
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Disaster recovery
            </li>
            <li className="mb-10">
                <span className="icon icon-checkmark-circle text-success mr-10" />
                Call reports
            </li>
        </ul>
  )
}

export default FeaturesList
