import update from 'immutability-helper'
import moment from 'moment'
import { combineReducers } from 'redux'
import { createAction, handleActions } from 'redux-actions'
import { ASCENDING, DESCENDING } from '../constants/Constants'
import { getTomorrowMorningDate } from '../helpers/dateTimeHelper'
import { fetchHelper } from '../helpers/fetchHelper'
import { fetchMyNumbersRequest } from './SBF'

const _ = require('lodash')

// ACTION CREATORS

const fetchMyNumbersResponse = createAction('MY_NUMBERS_FETCH_RESPONSE')
const sortPhoneNumbersAscending = createAction('SORT_PHONE_NUMBERS_ASCENDING')
const sortPhoneNumbersDescending = createAction('SORT_PHONE_NUMBERS_DESCENDING')

export const handleFilterChange = createAction('UPDATE_MY_NUMBERS_VIEW_FILTERED')
export const toggleStarFilter = createAction('TOGGLE_STAR_FILTER')
export const handleShowAutoReopenDialog = createAction('HANDLE_SHOW_AUTO_REOPEN_DIALOG')
export const dismissAutoReopenDialog = createAction('DISMISS_AUTO_REOPEN_DIALOG')
export const handleChangeAutoReopenDateTime = createAction('HANDLE_CHANGE_AUTO_REOPEN_DATETIME')

export const handleShowCallRecordingDialog = createAction('HANDLE_SHOW_CALL_RECORDING_DIALOG')
export const handleDismissCallRecordingDialog = createAction('DISMISS_CALL_RECORDING_DIALOG')

export const handleShowCallAnsweringDialog = createAction('HANDLE_SHOW_CALL_ANSWERING_DIALOG')
export const handleDismissCallAnsweringDialog = createAction('DISMISS_CALL_ANSWERING_DIALOG')

const toggleStarRequest = createAction('TOGGLE_STAR_REQUEST')
const toggleStarReceive = createAction('TOGGLE_STAR_RECEIVE')

export const handleShowDeptCallRecordingDialog = createAction('HANDLE_DEPARTMENT_SHOW_CALL_RECORDING_DIALOG')
export const handleDismissDeptCallRecordingDialog = createAction('DISMISS_DEPARTMENT_CALL_RECORDING_DIALOG')

const handleChangeCallAnsweringRequest = createAction('HANDLE_CALL_ANSWERING_CHANGE_REQUEST')
const handleChangeCallAnsweringReceived = createAction('HANDLE_CALL_ANSWERING_CHANGE_RECEIVED')
const fetchDepartmentCallAnsweringRequest = createAction('FETCH_CALL_ANSWERING_REQUEST')
const handleShowDepartmentCallAnsweringDialog = createAction('HANDLE_SHOW_DEPARTMENT_CALL_ANSWERING')
export const handleDismissDepartmentCallAnsweringDialog = createAction('HANDLE_DISMISS_DEPARTMENT_CALL_ANSWERING')
const handleChangeCallAnsweringDeptRequest = createAction('HANDLE_DEPARTMENT_CALL_ANSWERING_REQUEST')
const handleCallAnsweringDeptRequest = createAction('HANDLE_DEPARTMENT_CALL_ANSWERING_CHANGE_REQUEST')

const handleChangeQuickCloseRequest = createAction('HANDLE_QUICK_CLOSE_CHANGE_REQUEST')
const handleChangeQuickCloseReceived = createAction('HANDLE_QUICK_CLOSE_CHANGE_RECEIVED')
const handleChangeCallRecordingRequest = createAction('HANDLE_CALL_RECORDING_CHANGE_REQUEST')
const handleChangeCallRecordingReceived = createAction('HANDLE_CALL_RECORDING_CHANGE_RECEIVED')
const saveAutoReopenTimeRequest = createAction('HANDLE_SAVE_AUTO_REOPEN_CHANGE_REQUEST')
const saveAutoReopenTimeReceived = createAction('HANDLE_SAVE_AUTO_REOPEN_CHANGE_RECEIVED')
const cancelAutoReopenTimeRequest = createAction('HANDLE_CANCEL_REOPEN_TIME_REQUEST')
const cancelAutoReopenTimeReceived = createAction('HANDLE_CANCEL_REOPEN_TIME_RECEIVED')

const handleShowDepartmentOpenCloseDialog = createAction('HANDLE_SHOW_DEPARTMENT_QUICK_CLOSE_DIALOG')
const handleShowDepartmentCallRecordingDialog = createAction('HANDLE_SHOW_DEPARTMENT_CALL_RECORDING_DIALOG')
const handleChangeQuickCloseDeptRequest = createAction('HANDLE_CHANGE_QUICK_CLOSE_DEPT_REQUEST')
const handleChangeQuickCloseDeptReceived = createAction('HANDLE_CHANGE_QUICK_CLOSE_DEPT_RECEIVED')
const handleChangeCallRecordingDeptRequest = createAction('HANDLE_CALL_RECORDING_DEPT_CHANGE_REQUEST')
const handleChangeCallRecordingDeptReceived = createAction('HANDLE_CALL_RECORDING_DPT_CHANGE_RECEIVED')
export const handleHideDeparmentOpenCloseDialog = createAction('HANDLE_HIDE_DEPARTMENT_QUICK_CLOSE_DIALOG')
export const handleHideDepartmentCallRecordingDialog = createAction('HANDLE_HIDE_DEPARTMENT_CALL_RECORDING_DIALOG')

const fetchDepartmentOpenCloseRequest = createAction('FETCH_DEPARTMENT_OPEN_CLOSE_REQUEST')
const fetchDepartmentCallRecordingRequest = createAction('FETCH_DEPARTMENT_CALL_RECORDING_REQUEST')

export const handleChangeQuickCloseDept = context => async (dispatch) => {
  dispatch(handleChangeQuickCloseDeptRequest(context))

  const url = 'api/Numbers/UpdateDepartmentQuickClose/'

  await fetchHelper.postJson(url, {
    quickClosed: context.quickClosed,
    qlid: context.num.qlid,
    departmentNo: context.departmentNo
  }, context.token).then(data => console.log(data))
    .catch(error => console.error(error))

  dispatch(handleChangeQuickCloseDeptReceived(context))
}

export const handleChangeQuickClose = context => async (dispatch) => {
  const newQuickCloseState = context.quickClosed

  dispatch(handleChangeQuickCloseRequest({ qlid: context.qlid, quickClosed: newQuickCloseState }))

  const url = 'api/Numbers/UpdateQuickClosed/'

  await fetchHelper.postJson(url, { qlid: context.qlid, quickClosed: newQuickCloseState }, context.token)
    .then(data => console.log(data))
    .catch(error => console.error(error))

  dispatch(handleChangeQuickCloseReceived())
}

export const handleChangeCallRecordingDept = context => async (dispatch) => {
  const newCallRecordingState = context.hasCallRecording

  if ((context.currentRecording && context.currentRecording.qlid !== context.num.qlid) && context.callRecordingCount > 0 && newCallRecordingState && (!context.userServices.hasCallRecordingAll && !context.userServices.hasUnlimitedCallRecording && context.userServices.hasCallRecordingSingle)) {
    dispatch(handleShowCallRecordingDialog())
  } else {
    dispatch(handleChangeCallRecordingDeptRequest(context))

    const url = 'api/Numbers/UpdateDepartmentCallRecording/'
    await fetchHelper.postJson(url, { hasCallRecording: context.hasCallRecording, qlid: context.num.qlid, departmentNo: context.departmentNo }, context.token)
      .then(data => {
        if (!data.data) { dispatch(handleShowCallRecordingDialog()) }
      })
      .catch(err => console.error(err))

    dispatch(handleChangeCallRecordingDeptReceived(context))
  }
}

export const handleChangeCallAnswering = context => async (dispatch) => {
  dispatch(fetchDepartmentCallRecordingRequest(context))
  const newCallAnsweringState = context.hasCallAnswering
  const url = 'api/Numbers/UpdatePocketState/'
  await fetchHelper.postJson(url, { qlid: context.qlid, state: newCallAnsweringState, cqmId: context.cqmId }, context.token)
    .then(data => {
      if (data.data) {
        if (context.cqmId) {
          dispatch(handleChangeCallAnsweringDeptRequest({ qlid: context.qlid, cqmId: context.cqmId, state: newCallAnsweringState }))
        } else {
          dispatch(handleChangeCallAnsweringRequest({ qlid: context.qlid, state: newCallAnsweringState }))
        }
      } else {
        dispatch(handleShowCallAnsweringDialog())
      }
    })
    .catch(err => console.error(err))
  dispatch(handleChangeCallAnsweringReceived())
}

export const handleChangeCallRecording = context => async (dispatch) => {
  const newCallRecordingState = context.hasCallRecording
  let callRecordingCount = 0

  if (newCallRecordingState) {
    fetchHelper.getJson('api/CallRecording/GetCallRecordingCount').then(async (res) => {
      callRecordingCount = res.data
      if (newCallRecordingState && (!context.userServices.hasCallRecordingAll && !context.userServices.hasUnlimitedCallRecording && !context.userServices.hasCallRecordingSingle)) {
        dispatch(handleShowCallRecordingDialog())
      } else {
        dispatch(handleChangeCallRecordingRequest({ qlid: context.qlid, hasCallRecording: newCallRecordingState }))

        const url = 'api/Numbers/UpdateCallRecording/'
        await fetchHelper.postJson(url, { qlid: context.qlid, hasCallRecording: newCallRecordingState }, context.token)
          .then(data => {
            if (!data.data) { dispatch(handleShowCallRecordingDialog()) }
          })
          .catch(err => console.error(err))

        dispatch(handleChangeCallRecordingReceived())
      }
    })
  } else {
    dispatch(handleChangeCallRecordingRequest({ qlid: context.qlid, hasCallRecording: newCallRecordingState }))

    const url = 'api/Numbers/UpdateCallRecording/'
    await fetchHelper.postJson(url, { qlid: context.qlid, hasCallRecording: newCallRecordingState }, context.token)
      .then(data => {
        if (!data.data) { dispatch(handleShowCallRecordingDialog()) }
      })
      .catch(err => console.error(err))

    dispatch(handleChangeCallRecordingReceived())
  }
}

export const fetchDepartmentQuickCloseState = context => async (dispatch) => {
  dispatch(fetchDepartmentOpenCloseRequest(context))

  const url = 'api/Numbers/DeptQuickCloseState/' + context.qlid

  const quickCloseStates = await fetchHelper.getJson(url)

  dispatch(handleShowDepartmentOpenCloseDialog(quickCloseStates.data))
}

export const fetchDepartmentCallRecordingState = context => async (dispatch) => {
  dispatch(fetchDepartmentCallRecordingRequest(context))
  const url = 'api/Numbers/DeptCallRecordingState/' + context.qlid
  const callRecordingStates = await fetchHelper.getJson(url)

  dispatch(handleShowDepartmentCallRecordingDialog(callRecordingStates.data))
}

export const handleCancelAutoReopen = context => async (dispatch) => {
  dispatch(cancelAutoReopenTimeRequest(context))

  const url = 'api/Numbers/CancelAutoReopen/'

  await fetchHelper.postJson(url, {
    qlid: context.qlid,
    depNo: Number(context.digit)
  }, context.token)
    .then(data => console.log(data))
    .catch(error => console.error(error))

  dispatch(cancelAutoReopenTimeReceived())
}

export const handleSaveAutoReopenTime = context => async (dispatch) => {
  // Check we have a valid date first
  if (moment(context.autoReopenTime, 'DD/MM/YYYY HH:mm', true).isValid()) {
    dispatch(saveAutoReopenTimeRequest(context))
    const url = 'api/Numbers/UpdateAutoReopenTime/'

    await fetchHelper.postJson(url, {
      qlid: context.num.qlid,
      autoReopenTime: context.autoReopenTime.format(),
      departmentNo: context.departmentNo
    }, context.token)
      .then(data => console.log(data))
      .catch(error => console.error(error))

    dispatch(saveAutoReopenTimeReceived())
    dispatch(dismissAutoReopenDialog())
  } else {
    // Warn the user that the date is not valid
    alert('The date/time is not valid. Please use this format: DD/MM/YYYY HH:mm')
  }
}

export const toggleStar = context => async (dispatch) => {
  const newStarredState = (context.starred === undefined || context.starred === null || context.starred === false)
  dispatch(toggleStarRequest({ qlid: context.qlid, starred: newStarredState }))

  const url = 'api/Numbers/UpdateStarred/'

  await fetchHelper.postJson(url, { qlid: context.qlid, starred: newStarredState }, context.token)
    .then(data => console.log(data))
    .catch(error => console.error(error))

  dispatch(toggleStarReceive())

  // we need to offset the initial star count to account for the star the user has just
  // toggled which won't at this point have been updated in context.numbers
  let sum = -1

  if (newStarredState) {
    sum = 1
  }

  const noOfStars = _.reduce(context.numbers, function (sum, n) {
    let addStar = 0

    if (n.starred === true) {
      addStar = 1
    }

    return sum + addStar
  }, sum)

  if (noOfStars === 0) {
    dispatch(handleFilterChange(context.filter))
  }
}

export const fetchMyNumbers = context => async (dispatch) => {
  dispatch(fetchMyNumbersRequest())

  const url = 'api/Numbers/'

  const response = await fetchHelper.getJson(url, context.token)

  if (fetchHelper.isUnauthorised(response)) {
    // dispatch(logout({history:context.history}))
  } else {
    dispatch(fetchMyNumbersResponse(response.data))
  }
}

export const sortPhoneNumber = currentSortDirection => async (dispatch) => {
  let newSortDirection = ASCENDING

  if (currentSortDirection === ASCENDING) { newSortDirection = DESCENDING }

  if (newSortDirection === ASCENDING) { dispatch(sortPhoneNumbersAscending()) } else { dispatch(sortPhoneNumbersDescending()) }
}

export const fetchDepartmentCallAnsweringState = context => async (dispatch) => {
  dispatch(fetchDepartmentCallAnsweringRequest(context))

  const url = 'api/Numbers/DeptCallAnsweringState/' + context.qlid

  const req = await fetchHelper.getJson(url)
  if (req.data) {
    dispatch(handleCallAnsweringDeptRequest(req.data))
    dispatch(handleShowDepartmentCallAnsweringDialog(req.data))
  } else {
    dispatch(handleShowCallAnsweringDialog())
  }
}

// REDUCERS
const myNumbersRequested = handleActions({
  [fetchMyNumbersRequest] () {
    return true
  },
  [fetchMyNumbersResponse] () {
    return false
  }
}, false)

const myNumbersView = handleActions({
  [fetchMyNumbersResponse] (state, action) {
    return {
      cachedResult: action.payload,
      viewResult: action.payload,
      filter: '',
      starredOnly: false,
      sortOrder: ASCENDING
    }
  },
  [sortPhoneNumbersDescending] (state) {
    return { ...state, viewResult: _.orderBy(state.viewResult, ['ddi'], ['desc']), sortOrder: DESCENDING }
  },
  [sortPhoneNumbersAscending] (state) {
    return { ...state, viewResult: _.orderBy(state.viewResult, ['ddi'], ['asc']), sortOrder: ASCENDING }
  },
  [handleFilterChange] (state, action) {
    let filtered = _.filter(state.cachedResult, function (number) {
      const filterWithoutWhitespaceAndLowerCase = action.payload.numberFilter.toLowerCase().replace(/ /g, '')
      return _.includes(number.ddi.replace(/ /g, ''), filterWithoutWhitespaceAndLowerCase) || _.includes(number.queueDescription.toLowerCase().replace(/ /g, ''), filterWithoutWhitespaceAndLowerCase)
    })

    filtered = _.filter(filtered, function (number) {
      return (action.payload.starFilterEnabled === true && number.starred === true) || action.payload.starFilterEnabled === false
    })

    if (filtered.length === 0) {
      // Default to all
      filtered = _.filter(state.cachedResult, function (number) {
        return _.includes(number.ddi, action.payload.numberFilter)
      })
    }

    if (state.sortOrder === ASCENDING) {
      filtered = _.orderBy(filtered, ['ddi'], ['asc'])
    } else {
      filtered = _.orderBy(filtered, ['ddi'], ['desc'])
    }

    return { ...state, viewResult: filtered }
  },
  [toggleStarRequest] (state, action) {
    const toggleNumberViewIndex = _.findIndex(state.viewResult, { qlid: action.payload.qlid })
    const toggleNumberCachedIndex = _.findIndex(state.cachedResult, { qlid: action.payload.qlid })

    return update(state,
      {
        viewResult: { [toggleNumberViewIndex]: { $merge: { starred: action.payload.starred } } },
        cachedResult: { [toggleNumberCachedIndex]: { $merge: { starred: action.payload.starred } } }
      })
  },
  [saveAutoReopenTimeRequest] (state, action) {
    if (action.payload.departmentNo) {
      return state
    }

    const toggleNumberViewIndex = _.findIndex(state.viewResult, num => {
      return num.qlid === action.payload.num.qlid
    })
    const toggleNumberCachedIndex = _.findIndex(state.cachedResult, num => {
      return num.qlid === action.payload.num.qlid
    })

    return update(state,
      {
        viewResult: {
          [toggleNumberViewIndex]: {
            $merge: {
              autoReopen: moment(action.payload.autoReopenTime)
            }
          }
        },
        cachedResult: {
          [toggleNumberCachedIndex]: {
            $merge: {
              autoReopen: moment(action.payload.autoReopenTime)
            }
          }
        }
      })
  },
  [cancelAutoReopenTimeRequest] (state, action) {
    if (action.payload.digit) {
      return state
    }

    const toggleNumberViewIndex = _.findIndex(state.viewResult, num => {
      return num.qlid === action.payload.qlid
    })
    const toggleNumberCachedIndex = _.findIndex(state.cachedResult, num => {
      return num.qlid === action.payload.qlid
    })

    return update(state,
      {
        viewResult: {
          [toggleNumberViewIndex]: {
            $merge: {
              autoReopen: undefined
            }
          }
        },
        cachedResult: {
          [toggleNumberCachedIndex]: {
            $merge: {
              autoReopen: undefined
            }
          }
        }
      })
  },
  [handleChangeQuickCloseRequest] (state, action) {
    const toggleNumberViewIndex = _.findIndex(state.viewResult, { qlid: action.payload.qlid })
    const toggleNumberCachedIndex = _.findIndex(state.cachedResult, { qlid: action.payload.qlid })

    return update(state,
      {
        viewResult: {
          [toggleNumberViewIndex]: {
            $merge: {
              quickClosed: action.payload.quickClosed,
              quickClosedChanged: true
            }
          }
        },
        cachedResult: {
          [toggleNumberCachedIndex]: {
            $merge: {
              quickClosed: action.payload.quickClosed,
              quickClosedChanged: true
            }
          }
        }
      })
  },
  [handleChangeCallAnsweringRequest] (state, action) {
    const toggleNumberViewIndex = _.findIndex(state.viewResult, { qlid: action.payload.qlid })
    const toggleNumberCachedIndex = _.findIndex(state.cachedResult, { qlid: action.payload.qlid })
    return update(state,
      {
        viewResult: {
          [toggleNumberViewIndex]: {
            $merge: {
              callAnswering: action.payload.state
            }
          }
        },
        cachedResult: {
          [toggleNumberCachedIndex]: {
            $merge: {
              callAnswering: action.payload.state
            }
          }
        }
      })
  },
  [handleChangeCallRecordingRequest] (state, action) {
    const toggleNumberViewIndex = _.findIndex(state.viewResult, { qlid: action.payload.qlid })
    const toggleNumberCachedIndex = _.findIndex(state.cachedResult, { qlid: action.payload.qlid })

    return update(state,
      {
        viewResult: {
          [toggleNumberViewIndex]: {
            $merge: {
              hasCallRecording: action.payload.hasCallRecording
            }
          }
        },
        cachedResult: {
          [toggleNumberCachedIndex]: {
            $merge: {
              hasCallRecording: action.payload.hasCallRecording
            }
          }
        }
      })
  }
}, { cachedResult: [], viewResult: [], filter: '', starredOnly: false, sortOrder: ASCENDING })

const numberCount = handleActions({
  [fetchMyNumbersResponse] (state, action) {
    return action.payload.length
  }
}, 0)

const starFilterEnabled = handleActions({
  [handleFilterChange] (state, action) {
    return action.payload.starFilterEnabled
  }
}, false)

const numberFilter = handleActions({
  [handleFilterChange] (state, action) {
    return action.payload.numberFilter
  }
}, '')

const phoneNumberSortDirection = handleActions({
  [sortPhoneNumbersDescending] () {
    return DESCENDING
  },
  [sortPhoneNumbersAscending] () {
    return ASCENDING
  }
}, ASCENDING)

const autoReopenDialogDisplayed = handleActions({
  [handleShowAutoReopenDialog] () {
    return true
  },
  [dismissAutoReopenDialog] () {
    return false
  }
}, false)

const callRecordingDialogDisplayed = handleActions({
  [handleShowCallRecordingDialog] () {
    return true
  },
  [handleDismissCallRecordingDialog] () {
    return false
  }
}, false)

const callAnsweringDialogDisplayed = handleActions({
  [handleShowCallAnsweringDialog] () {
    return true
  },
  [handleDismissCallAnsweringDialog] () {
    return false
  }
}, false)

const autoReopenDialogDateTime = handleActions({
  [handleChangeAutoReopenDateTime] (state, action) {
    const newTime = action.payload.newValue
    const currentTime = action.payload.currentTime
    if (moment(newTime, 'DD/MM/YYYY HH:mm', true).isValid()) {
      const isTimeInPast = newTime.diff(currentTime) < 0
      if (isTimeInPast) {
        const defaultDate = moment(currentTime).add(2, 'hours')
        return defaultDate
      } else {
        return newTime
      }
    } else {
      return newTime
    }
  }
}, getTomorrowMorningDate())

const departmentCallAnsweringToggleDisplayed = handleActions({
  [handleShowDepartmentCallAnsweringDialog] () {
    return true
  },
  [handleDismissDepartmentCallAnsweringDialog] () {
    return false
  }
}, false)

const departmentCallRecordingToggleDisplayed = handleActions({
  [handleShowDepartmentCallRecordingDialog] () {
    return true
  },
  [handleHideDepartmentCallRecordingDialog] () {
    return false
  }
}, false)

const departmentOpenCloseToggleDisplayed = handleActions({
  [handleShowDepartmentOpenCloseDialog] () {
    return true
  },
  [handleHideDeparmentOpenCloseDialog] () {
    return false
  }
}, false)

const currentNumberEditing = handleActions({
  [fetchDepartmentOpenCloseRequest] (state, action) {
    return action.payload
  },
  [handleShowAutoReopenDialog] (state, action) {
    if (action.payload !== undefined) {
      return action.payload.num
    }

    return state
  },
  [fetchDepartmentCallRecordingRequest] (state, action) {
    return action.payload
  },

  [fetchDepartmentCallAnsweringRequest] (state, action) {
    return action.payload
  }

}, null)

const departmentCallAnsweringStates = handleActions({
  [handleCallAnsweringDeptRequest] (state, action) {
    return _.map(action.payload, function (department) {
      return { ...department }
    })
  },
  [handleChangeCallAnsweringDeptRequest] (state, action) {
    const index = _.findIndex(state, { cqmId: action.payload.cqmId })
    const newState = update(state, {
      [index]: {
        $merge: {
          callAnswering: action.payload.state
        }
      }
    })
    return newState
  }
}, [])

const departmentCallRecordingStates = handleActions({
  [handleShowDepartmentCallRecordingDialog] (state, action) {
    return _.map(action.payload, function (department) {
      return { ...department }
    })
  },
  [handleChangeCallRecordingDeptRequest] (state, action) {
    const departmentIndex = _.findIndex(state, { digit: action.payload.departmentNo.toString() })
    const newState = update(state, {
      [departmentIndex]: {
        $merge: {
          hasCallRecording: action.payload.hasCallRecording
        }
      }
    })
    return newState
  }
}, [])

const departmentOpenCloseStates = handleActions({
  [saveAutoReopenTimeRequest] (state, action) {
    if (!action.payload.departmentNo) {
      return state
    }

    const departmentIndex = _.findIndex(state, { digit: action.payload.departmentNo.toString() })
    const newState = update(state, {
      [departmentIndex]: {
        $merge: {
          autoReopenTime: action.payload.autoReopenTime.format()
        }
      }
    })
    return newState
  },
  [handleShowDepartmentOpenCloseDialog] (state, action) {
    return _.map(action.payload, function (department) {
      return { ...department, autoReopenTime: department.autoReopenTime ? department.autoReopenTime : undefined }
    })
  },

  [handleShowDepartmentCallAnsweringDialog] (state, action) {
    return _.map(action.payload, function (data) {
      return { ...data, hasCallAnswering: data }
    })
  },

  [handleChangeQuickCloseDeptRequest] (state, action) {
    const departmentIndex = _.findIndex(state, { digit: action.payload.departmentNo.toString() })
    const newState = update(state, {
      [departmentIndex]: {
        $merge: {
          quickClosed: action.payload.quickClosed,
          stateDirty: true
        }
      }
    })
    return newState
  },
  [cancelAutoReopenTimeRequest] (state, action) {
    if (!action.payload.digit) {
      return state
    }

    const departmentIndex = _.findIndex(state, { digit: action.payload.digit.toString() })
    const newState = update(state, {
      [departmentIndex]: {
        $merge: {
          autoReopenTime: undefined
        }
      }
    })
    return newState
  }
}, [])

const currentDepartmentEditing = handleActions({
  [handleChangeQuickCloseDeptReceived] (state, action) {
    return action.payload.departmentNo
  },
  [handleHideDeparmentOpenCloseDialog] () {
    return null
  },
  [handleHideDepartmentCallRecordingDialog] () {
    return null
  },
  [handleShowAutoReopenDialog] (state, action) {
    if (action.payload !== undefined) {
      return action.payload.departmentNo || null
    }

    return state
  },
  [handleChangeCallRecordingDeptReceived] (state, action) {
    return action.payload.departmentNo
  }
}, null)

export const reducer = combineReducers({
  requesting: myNumbersRequested,
  numbersView: myNumbersView,
  phoneNumberSortDirection,
  starFilterEnabled,
  numberFilter,
  numberCount,
  autoReopenDialogDisplayed,
  autoReopenDialogDateTime,
  departmentOpenCloseToggleDisplayed,
  departmentCallRecordingToggleDisplayed,
  departmentCallAnsweringToggleDisplayed,
  departmentOpenCloseStates,
  departmentCallRecordingStates,
  departmentCallAnsweringStates,
  currentNumberEditing,
  currentDepartmentEditing,
  callRecordingDialogDisplayed,
  callAnsweringDialogDisplayed
})

// SELECTORS

export const getMyNumbersView = state => state.myNumbers.numbersView.viewResult
