import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { PromptTypes } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'
import PromptAudioPlayer from '../PromptAudioPlayer'
import DepartmentMenuChoiceInstructionHelperDialog from './InstructionHelperDialogs/DepartmentMenuChoiceInstructionHelperDialog'

const DepartmentChoicePromptSection = props => (
    <>
    <div className="row bg-dullest p-30 zoom">
        <div className="col-md-5 pb-sm-10">
            <h5 className="text-secondary text-500 text-lg">
                Department Title
            </h5>
            <p className="text-400">
                Select what your callers hears when being given the department choices<br />(i.e. press 1 for sales, press 2 for service). <a onClick={props.handleShowDepartmentMenuChoicePromptSectionHelper} id="DepartmentTitleMoreInfoButton" className="underline">More info</a>
            </p>
        </div>
            <div className="col-md-7 mt-10">
                <p>Select below:</p>
                <DepartmentMenuChoiceInstructionHelperDialog/>
                <Select
                    className="form-control select-menu"
                    classNamePrefix="custom"
                    id="choiceSelect"
                    onChange={props.handleDepartmentMenuChange}
                    options={props.departmentMenuPrompts}
                    simpleValue
                    name="selectedChoice"
                    value={props.selectedDepartmentChoicePrompt !== undefined ? { value: props.selectedDepartmentChoicePrompt.promptId, label: props.selectedDepartmentChoicePrompt.promptDescription } : undefined}
                    searchable={false}
                    clearable={false}
                    isLoading={props.isLoadingPromptOptions}
                />
                <PromptAudioPlayer promptId={props.selectedDepartmentChoicePrompt !== undefined ? props.selectedDepartmentChoicePrompt.promptId : undefined} voice={props.voice} promptType={PromptTypes.departmentalChoice} />
            </div>
    </div>
    </>
)

const mapStateToProps = state => ({
  departmentMenuPrompts: state.configuration.prompts.departmentMenuPrompts,
  selectedDepartmentChoicePrompt: state.configuration.configuration.menuOptionPrompt ? state.configuration.configuration.menuOptionPrompt : undefined,
  isLoadingPromptOptions: state.configuration.loading.departmentMenuPrompts,
  voice: state.configuration.configuration.voicePrompt.voicePromptId
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentChoicePromptSection)

// export default connect(
//     state => state.configuration,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(IntroductionSection);
