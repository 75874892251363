import moment from 'moment'
import React from 'react'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { fetchHelper } from '../../helpers/fetchHelper'
import { handleNotificationChange, handleShowCallbackForm, handleShowPaymentOptionsModal, handleDeliveryNameChange, handleDeliveryAdd1Change, handleDeliveryAdd2Change, handleDeliveryTownChange, handleDeliveryCountyChange, handleDeliveryPcodeChange, handleDeliveryNoteChange } from '../../store/SBF'
import { UnlimitedOutboundPlan, BasicOutboundPlan } from '../../constants/Constants'
import { isLoggedIn } from '../../selectors'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import Logos from '../../components/ClientLogos'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import { BillingPeriodUnit } from '../../models/SignupTypes'
import * as fromHome from '../../store/Home'
import TermsAndConditions from '../../Text/TermsAndConditions'
import { Link } from 'react-router-dom'
import AddressLookup from '../../components/AddressLookup'

class VoIPUpsell extends React.Component {
  state = {
    qty: 1,
    isAnnual: true,
    isLoading: true,
    isPurchaseInProgress: false,
    finished: false,
    packageSelected: 0,
    packages: []
  }

  constructor (props) {
    super(props)
  }

  handleChangeQty (isDecrement) {
    let qty = this.state.qty

    if (isDecrement && qty > 0) {
      qty--
    } else if (!isDecrement) {
      qty++
    }

    this.setState({ qty })
  }

  render () {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <>
				<p className="text-700 text-cta text-uppercase text-md mb-20">
					Offer Ends in:
				</p>
				<div className="countdown-timer">
					<div className="timer-block">
						{days}<br /><span className="text-sm">days</span>
					</div>
					<div className="timer-block">
						{hours}<br /><span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}<br /><span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}<br /><span className="text-sm">seconds</span>
					</div>
				</div>
			</>
    }

    let backgroundImage = this.state.isAnnual ? '/images/campaigns/outbound/freebies.jpg' : '/images/campaigns/outbound/claim-freebies.jpg'
    if (this.state.finished === true) { backgroundImage = '' }

    let isUnlimited = false
    let totalPrice = 0
    if (this.state.packages && this.state.packages.length > 0) {
      const sp = this.state.packages[this.state.packageSelected] // Selected plan;
      isUnlimited = sp.id === UnlimitedOutboundPlan

      if (this.state.packages[this.state.packageSelected].id !== UnlimitedOutboundPlan && this.state.packages[this.state.packageSelected].id !== BasicOutboundPlan) {
        totalPrice = this.state.qty * (this.state.isAnnual ? sp.price * 24 : sp.price)
      } else {
        totalPrice = this.state.qty * (this.state.isAnnual ? sp.price * 12 : sp.price)
      }
    }

    return <><DisplayMainFooter />
			<Helmet>
				<body className={'body-simple bg-dull body-signup'} />
				<title>EXCLUSIVE! Claim Your FREE VoIP Phones!</title>
				<style type="text/css">{`
						.background {
							background-position: 95% 60% !important;
							background-size: 40% !important;
						}
					}
					`}</style>
			</Helmet>
			{this.state.isLoading
			  ? <div className="p-50">
					<LoadingNotification loadingText={'Getting your plans, please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
				</div>
			  :				<>
					<div className={'p-50 p-xs-20 background'} style={{ background: `#fff url(${backgroundImage}) no-repeat` }}>
						<div className="container p-xs-0">
							<div className="row mb-50">
								<div className="col-md-10 mx-auto text-center">
									<p className="text-lg text-400 mt-30">
										<em>Claim Your <span className="text-cta text-500"><u>FREE</u></span> VoIP Handset Today!</em>
									</p>
									<h1 className="text-600 text-xxl">
										Add a VoIP User To Your Account &amp; <br className="d-none d-md-inline" />Get a <em className="text-cta"><u>FREE VoIP Phone!</u></em>
									</h1>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-5 col-md-4 col-sm-12 text-center order-first order-md-last">
									{!this.state.finished && !this.state.offerEnded &&
										<>
											<span className="p-20 bg-darker mb-20 d-inline-block center-block brad-100">
												<Countdown date={moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
											</span>
										</>
									}

									<div className="d-block d-sm-none">
										{this.state.isAnnual
										  ? <img src="/images/campaigns/outbound/freebies.jpg" className="img-responsive" />
										  : <img src="/images/campaigns/outbound/claim-freebies.jpg" className="img-responsive" />
										}
									</div>
								</div>
								<div className="col-lg-7 col-md-8 col-sm-12 order-last order-md-first ">
									<div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
										{this.state.offerEnded
										  ? <>
												<h3 className="text-500 mt-30">
													Oh no!
												</h3>
												<p className="text-400 lead mb-10 mt-0">Sorry, but this offer has now ended.</p>
												<p className="text-md mt-30">
													Please keep an eye on your inbox and our social media channels for new offers in the future.
												</p>
												<p className="mt-30">
													<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
												</p>
											</>
										  :											this.state.finished
										    ? <>
													<h3 className="text-500 mt-30">
														All done!
													</h3>
													<p className="text-400 lead mb-10 mt-0">Congratulations, your new users have been added to your account.</p>
													<p className="text-md mt-30">
														Please allow 3-5 working days to receive your free devices.
													</p>
													<p className="mt-30">
														<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
													</p>
												</>
										    :												this.state.diallerSent === true
										      ? <>
														<h3 className="text-500 mt-30">
															Thanks!
														</h3>
														<p className="text-400 lead mb-10 mt-0">We'll give you a call shortly.</p>
														<p className="text-md mt-30">
															We've received your request and one of our team will call you shortly to answer any questions you may have and help you get setup.
														</p>
														<p className="mt-30">
															<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
														</p>
													</>
										      :													<>
														<p className="text-400 text-uppercase text-lg mb-10 mt-0">Add a new user to your team!</p>

														<div className="row mb-30">
															<div className="col-sm-3 col-md-4 col-xs-3 text-sm pt-10 col-xxs-12 text-xxs-center pb-xxs-10 text-400">
																<em><span className="text-500">Step 1:</span> Choose package:</em>
															</div>
														</div>

														<div className="row">
															{this.state.packages.map((pack, i) => {
															  return (
																	<div className="col-md-4">
																		<div className="bg-light brad-10 p-20 b-1-dark text-center mb-xs-20 p-xs-10">
																			<h3 className="text-cta text-500 text-lg">
																				{pack.name}
																			</h3>
																			<p className="text-md mt-10 text-500">
																				From &pound;{pack.price}<br />
																				<span className="text-400 text-sm">per user, per month.</span>
																			</p>
																			{this.state.packageSelected === i
																			  ? <Button variant="cta" size="sm" className="btn-block" disabled>
																					Selected
																				</Button>
																			  :																				<Button variant="cta" size="sm" className="btn-block" onClick={() => this.setState({ packageSelected: i })}>
																					Select
																				</Button>
																			}
																		</div>
																	</div>
															  )
															})}
														</div>

														<div className="row">
															<div className="col-md-10 col-sm-10 mt-30">
																<h3 className="text-600">
																	{this.state.packages[this.state.packageSelected].name} Plan<br />
																	<span className="text-xs text-400">
																		Includes{' '}
																		<span className="text-500 underline">
																			{isUnlimited === true
																			  ? 'Unlimited'
																			  : this.state.packages[this.state.packageSelected].mins}
																		</span> minutes.
																	</span>
																</h3>

																<p className="text-md mb-30 mt-10">
																	<span className="text-uppercase text-cta text-500 underline mr-10 text-md"><em>
																		Just &pound;{this.state.packages[this.state.packageSelected].price} per month, per user.</em>
																	</span>
																</p>

															</div>
														</div>

														<div className="row mb-30">
															<div className="col-sm-4 col-md-4 col-xs-12 text-sm pt-10 col-xxs-12 text-xxs-center pb-xxs-10 text-400">
																<em><span className="text-500">Step 2:</span> Choose payment plan:</em>
															</div>
															<div className="col-sm-8 col-md-8 col-xs-12 col-xxs-12 text-xxs-center pt-5">
																<span className={`mr-10 text-500 ${!this.state.isAnnual ? 'active' : 'text-muted'}`}>Pay Monthly</span>
																<Toggle
																	onClick={() => this.setState({ isAnnual: this.state.isAnnual !== true })}
																	on=""
																	off=""
																	offstyle="default"
																	onstyle="success"
																	width="null"
																	height="null"
																	size="sm"
																	className="toggle-secondary rounded h-35px w-60px"
																	active={this.state.isAnnual}
																/>

																<span className={`ml-10 text-500 ${!this.state.isAnnual ? 'text-muted' : 'active'}`}>
																	{(this.state.packages[this.state.packageSelected].id !== UnlimitedOutboundPlan && this.state.packages[this.state.packageSelected].id !== BasicOutboundPlan)
																	  ? 'Pay 2-Yearly'
																	  :																		'Pay Annually'
																	}
																</span>
																<p className="pl-30 pl-xs-0 mt-10 text-sm">Total payment today: <span className="underline">&pound;{parseFloat(totalPrice).toFixed(2)} +VAT.</span></p>
															</div>
														</div>

														{this.state.isAnnual
														  ? <span className="bg-light brad-10 p-20 text-400 d-block mt-10 mb-30">
																Includes FREE VoIP handset for each user - <em className="text-cta text-500">Worth &pound;49.99</em>
															</span>
														  : <span className="bg-light brad-10 p-20 text-400 d-block mt-10 mb-30">
																Want to switch to <span className="text-cta text-500">Annual Payments</span>?<br />
																Get a FREE handset for each user - <em className="text-cta text-500">Worth &pound;49.99</em> - <span className="underline cursor-pointer text-sm text-secondary">Learn more</span>
															</span>
														}

														<p className="text-md mb-0">
															Add a new user for the following benefits:
														</p>

														<ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-30 text-xs-xs">
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Make calls presenting your company phone number</em></li>
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Create a ring group including home workers </em></li>
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Transfer calls between users at home, in the office or even abroad</em></li>
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Free Handset - <em className="text-cta text-500">Worth &pound;49.99</em></em> {!this.state.isAnnual && <span className="text-muted ml-10 text-sm text-400">(<sup>*</sup>When paying annually)</span>}</li>
														</ul>

														<p className="text-md mt-50">
															Plus, you'll also get the following included for each user.
														</p>

														<ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-30 text-xs-xs">
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Unlimited inbound calls to desk phone, PC softphone or mobile app</em></li>
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>{isUnlimited === true ? 'Unlimited' : this.state.packages[this.state.packageSelected].mins} outbound minutes to UK landlines & mobiles</em></li>

															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Make & receive calls anywhere in the world</em></li>
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Free mobile & PC softphone app</em></li>
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Create "Teams" so all phones ring at once</em></li>

														</ul>

														<div className="row mb-30">
															<div className="col-sm-5 col-md-5 col-xs-12 text-sm pt-10 col-xxs-12 text-xxs-center pb-xxs-10 text-400">
																<em className="float-start mt-10"><span className="text-500">Step 3:</span> Choose number of users:</em>
															</div>
															<div className="col-sm-7 col-md-7 col-xs-12 col-xxs-12 text-xxs-center">
																<form className="qty text-center mt-15 center">
																	<input
																		type="button"
																		className="btn btn-default"
																		onClick={() => this.handleChangeQty(true)}
																		value="-"
																		disabled={this.state.qty === 1}
																	/>
																	<input
																		type="text"
																		id="number"
																		className="form-control"
																		value={this.state.qty}
																	/>
																	<input
																		type="button"
																		className="btn btn-default"
																		onClick={() => this.handleChangeQty(false)}
																		value="+"
																	/>
																</form>
															</div>

														</div>

														<div className="mb-20 mt-30">
															{this.state.isPurchaseInProgress
															  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
															  :																<>
																	<Button variant="cta" size="lg" className="btn-block" onClick={() => this.setState({ showCheckout: true }) }>Add to account now</Button>

																	<p className="text-center mt-10 text-sm mb-0">
																		<Button variant="link" className="text-dark" onClick={() => this.handleSubmitToSupport()}>Or, request more information</Button>
																	</p>
																</>
															}
														</div>

														{this.state.diallerSent === false &&
															<p className="text-400 text-danger text-center">
																Sorry, there was a problem submitting your request, please try again.
															</p>
														}
													</>
										}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="bg-dull p-50 text-center">
						<sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
					</div>
				</>
			}

			<div className="bg-light pt-50 pb-50">
				<h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
				<Logos style="light" />
			</div>

			<div className="full-width bg-dull">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>

			<Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
				<Modal.Header closeButton>
					<Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TermsAndConditions />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
				</Modal.Footer>
			</Modal>

			<ChoosePayment
				total={this.state.wpAmount}
				clientId={this.props.clientId}
				purchaseData={this.state.key}
				returnRoute={`/get/free-voip-phone?t=${this.state.token}`}
				email={this.props.userDetails.email}
				name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
				mandate={!this.props.services.hasAgreement}
			/>

			<Modal size="sm" show={this.state.showCheckout} onHide={e => this.setState({ showCheckout: false })} backdrop="static">
				<Modal.Body>
					<span className="close-modal" onClick={e => this.setState({ showCheckout: false })}>
						<span>&times;</span>
					</span>
					{this.state.isAnnual &&
						<>
							<h3 className="text-500 text-md text-center mb-30">
								Please enter your delivery details below
							</h3>
							<div className="direction-block dir-down bb-1 mb-40 pt-0 pb-30">
								<AddressLookup
									showDeliveryOnly={true}
									showDeliveryAddress={true}
									padding="p-0"
									deliveryAddress={this.props.delivery}
									handleUpdateAddress={(e) => this.handleUpdateAddress(e)}
								/>
							</div>

							<div className="direction-block dir-down bb-1 mb-40 pt-0 pb-30">
								<FloatingLabel
									label="Notes (i.e. Leave in reception)"
									className="mb-3 text-lg d-flex"
								>
									<Form.Control
										value={this.state.notes}
										onChange={e => this.setState({ notes: e.target.value })}
										type="tel"
										placeholder='Notes' />
								</FloatingLabel>
							</div>
						</>
					}

					<div className="direction-block dir-down bb-1 mb-0 pt-0 pb-10">
						<span className="text-center m-0 ml-0 d-block mt-30 bg-dull p-20 brad-10 mb-30" style={{ backgroundColor: '#d6e8ef' }}>
							{this.state.isAnnual
							  ? <p className="text-500 lead">Quick... Claim Your FREE Handset</p>
							  :								<>
									<p className="text-500 lead">Switch to Annual Payments &amp; Claim {this.state.qty}x <span className="text-cta underline">FREE Handsets</span></p>

									<div className="col-12 text-center">
										<div className="bg-light brad-10 p-20 mb-20">
											<span className={`mr-10 text-500 ${!this.state.isAnnual ? 'active' : 'text-muted'}`}>Pay Monthly</span>
											<Toggle
												onClick={() => this.setState({ isAnnual: this.state.isAnnual !== true })}
												on=""
												off=""
												offstyle="default"
												onstyle="success"
												width="null"
												height="null"
												size="sm"
												className="toggle-secondary rounded h-35px w-60px"
												active={this.state.isAnnual}
											/>
											<span className={`ml-10 text-500 ${!this.state.isAnnual ? 'text-muted' : 'active'}`}>
												Pay Annually
											</span>
										</div>
									</div>
								</>
							}

							<img className="" src="/images/freebies-big.png" alt="Free VoIP Phone" style={{ maxHeight: '200px' }} />
							<p className="text-400 text-sm">🔥 Hurry, only {this.state.unitsLeft} handsets in stock.</p>
						</span>
					</div>

				</Modal.Body>
				<Modal.Footer className="bt-0 text-center pb-30">
					<p className="text-500 mb-20">
						Total payment today: &pound;{parseFloat(totalPrice).toFixed(2)} +VAT.<br />
						<span className="text-400">For {this.state.qty} users {this.state.isAnnual && '(billed annually)'}<br />
							{this.state.isAnnual && <>Plus, {this.state.qty} free handsets - <em className="text-cta text-500 underline">Worth &pound;{this.state.qty * 90}</em>.</>}</span>
					</p>

					{this.state.isPurchaseInProgress
					  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
					  :						<>
							{(this.state.isAnnual && ((this.props.delivery.add1 === '' || this.props.delivery.add1 === undefined) || (this.props.delivery.pcode === '' || this.props.delivery.pcode === undefined))) &&
								<p className="text-sm text-light bg-danger p-10 brad-10">Please enter your delivery address before you can checkout.</p>
							}

							<Button variant="cta" size="lg" className="btn-block" onClick={() => this.handlePurchase()}
								disabled={(this.state.isAnnual && ((this.props.delivery.add1 === '' || this.props.delivery.add1 === undefined) || (this.props.delivery.pcode === '' || this.props.delivery.pcode === undefined)))}
							>
								Checkout &amp; Pay
							</Button>

							<p className="text-sm mt-10 cursor-pointer underline d-block w-100" onClick={() => this.setState({ showCheckout: false })}>Or, cancel order</p>
						</>
					}

				</Modal.Footer>
			</Modal>

			<Modal size="sm" show={this.state.showLogin}>
				<Modal.Header>
					<Modal.Title className="mx-auto">Log into your account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12 text-center pb-30">
							<form name="form" className="text-left" onSubmit={e => this.props.login({
							  userName: this.props.userName,
							  password: this.props.password,
							  event: e,
							  history: this.props.history,
							  persist: document.getElementById('persistLogin').checked,
							  route: `/get/free-voip-phone?t=${this.state.token}`
							})}>
								<div className="row">
									<div className="col-xs-12">
										<p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
									</div>
									<div className="col-xs-12">
										<label>Email</label>
										<input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
									</div>
									<div className="col-xs-12 mt-20">
										<label>Password</label>
										<input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
									</div>
								</div>
								<div className="pt-10 pb-10">
									<div className="col-xs-6 pl-0">
										<input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
									</div>
									<div className="col-xs-6 text-right pr-0">
										<Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
									</div>
								</div>
								<div className="text-center pt-30">
									{this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                  {this.props.loginFailed === true && (this.props.loginFailReason === '' || this.props.loginFailReason === null || this.props.loginFailReason === undefined) ?
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'} className="underline text-danger">resetting your password</Link>.</p>
                  :
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage" hidden={!this.props.loginFailed}>{this.props.loginFailReason}</p>
                  }
									<button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

									<p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
									<p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>

		</>
  }

  handlePurchase () {
    const context = this
    const url = `api/AccessToken/AddSipUsersToClient?t=${context.state.token}`

    const data = {
      Qty: this.state.qty,
      PackageId: this.state.packages[this.state.packageSelected].id,
      IsAnnual: this.state.isAnnual,
      IsFreeHandset: this.state.isAnnual,
      DeliveryDetails: {
        Name: this.props.delivery.name,
        Add1: this.props.delivery.add1,
        Add2: this.props.delivery.add2,
        Add3: this.props.delivery.add3,
        Add4: this.props.delivery.add4,
        Pcode: this.props.delivery.pcode
      },
      user: null,
      BillingPeriod: this.state.isAnnual === true
        ? (this.state.packages[this.state.packageSelected].id !== UnlimitedOutboundPlan && this.state.packages[this.state.packageSelected].id !== BasicOutboundPlan)
            ? BillingPeriodUnit.Yearlyx2
            : BillingPeriodUnit.Year
        : BillingPeriodUnit.Month
    }

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.postJson(url, data)
        .then(res => {
          if (res.data && res.data.isSuccess === true) {
            context.setState({ finished: true, showCheckout: false })
          } else {
            context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false, clientId: res.data.clientId }, () => {
              context.props.handleShowPaymentOptionsModal()
            })
          }
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)
        })
    })
  }

  componentWillMount () {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount () {
    const context = this
    context.setState({ added: ((Math.random() * 18) + 2).toFixed(0), unitsLeft: ((Math.random() * 100) + 50).toFixed(0) })

    if (!context.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else {
      const url = '/api/Purchase/GetPackages'

      fetchHelper.getJson(url)
        .then(res => {
          if (res.data.packages) {
            const packages = []
            res.data.packages.forEach((pack) => {
              if (pack.numberType !== '0800') {
                const p = {
                  name: pack.packageName,
                  id: pack.packageId,
                  price: pack.monthlyPrice,
                  mins: (pack.outboundSeconds.landlineSeconds + pack.outboundSeconds.mobileSeconds) / 60
                }

                packages.push(p)
              }
            })

            context.setState({ packages, isLoading: false })
          }
        }).catch(err => console.error(err))
    }
  }

  handleSubmitToSupport () {
    const context = this

    context.setState({ submittingToDialler: true }, () => {
      const url = `/api/AccessToken/SubmitLeadToSupport?t=${context.state.token}&product=Free%20Handset%20Deal%20With%20Extra%20Users`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ diallerSent: res.data, submittingToDialler: false })
        }).catch(err => console.error(err))
    })
  }

  handleUpdateAddress (address) {
    this.props.handleDeliveryNameChange({ newValue: address.name })
    this.props.handleDeliveryAdd1Change({ newValue: address.add1 })
    this.props.handleDeliveryAdd2Change({ newValue: address.add2 })
    this.props.handleDeliveryCountyChange({ newValue: address.add3 })
    this.props.handleDeliveryTownChange({ newValue: address.add4 })
    this.props.handleDeliveryPcodeChange({ newValue: address.pcode })
  }
}

export default connect(
  state => {
    const deliveryData = state.sbfApp.deliveryData

    return {
      delivery: {
        name: deliveryData.deliveryName ? deliveryData.deliveryName.value : '',
        add1: deliveryData.deliveryAdd1 ? deliveryData.deliveryAdd1.value : '',
        add2: deliveryData.deliveryAdd2 ? deliveryData.deliveryAdd2.value : '',
        add3: deliveryData.deliveryTown ? deliveryData.deliveryTown.value : '',
        add4: deliveryData.deliveryCounty ? deliveryData.deliveryCounty.value : '',
        pcode: deliveryData.deliveryPcode ? deliveryData.deliveryPcode.value : ''
      },
      isLoggedIn: isLoggedIn(state),
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed,
      loginFailReason: state.home.login.reason
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    notify: handleNotificationChange,
    handleShowCallbackForm,
    handleShowPaymentOptionsModal,
    handleDeliveryNameChange,
    handleDeliveryAdd1Change,
    handleDeliveryAdd2Change,
    handleDeliveryTownChange,
    handleDeliveryCountyChange,
    handleDeliveryPcodeChange,
    handleDeliveryNoteChange
  }
)(VoIPUpsell)
