import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { redirectDurationOptions } from '../../constants/ConfigurationConstants'
import * as configurationActions from '../../store/Configuration'

const RedirectSecondsSelect = props => {
  return <Select
        className="form-control select-menu mb-10 redirect-time"
        classNamePrefix="custom"
        id={'redirect_' + props.position + '_seconds_select'}
        options={redirectDurationOptions}
        simpleValue
        name="selected-state"
        value={redirectDurationOptions.filter(f => f.value === props.seconds.value)}
        // value={props.seconds}
        onChange={e => props.updateRedirectDuration({ newValue: e, position: props.position })}
        searchable={false}
        clearable={false}
    />
}

export default connect(
  state => state.configuration,
  dispatch => bindActionCreators(configurationActions, dispatch)
)(RedirectSecondsSelect)
