import React from 'react'
import { Dropdown, DropdownButton, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { HideRingGroupsConfig, RedirectType } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'

const RedirectNumberDropdown = props => (
  (props.redirectsHistory || props.outboundUsers || props.ringGroups) &&
	<DropdownButton
		className="p-0"
		componentClass={InputGroup.Button}
		id="input-dropdown-addon"
		title=""
	>
		{props.redirectsHistory && props.redirectsHistory.length > 0 &&
			<>
				<Dropdown.Header className="text-sm">
					Recent Redirects
					{!props.isTimeSlot &&
						<span className="float-end cursor-pointer" onClick={() => props.clearRedirectsHistory({ notifier: props.notifier })}>Clear</span>
					}
				</Dropdown.Header>
				{props.redirectsHistory.map((redirect) =>
					<Dropdown.Item id={`RedirectHistoryDropdownItem${redirect.value}`} className={props.number.redirectDetail === redirect.label ? 'active bg-success' : ''} onClick={e =>
					  props.number.redirectDetail === redirect.label
					    ? null
					    : props.changeRedirectNumber({
					      newNumber: redirect.label,
					      position: props.position,
					      itemId: redirect.value,
					      itemType: RedirectType.PhoneNumber,
					      isWizard: props.isWizard,
					      isTimeSlot: props.isTimeSlot
					    })}>{redirect.label}</Dropdown.Item>
				)}
			</>
		}

		{(props.ringGroups && props.ringGroups.length > 0 && !HideRingGroupsConfig) &&
			<>
				<Dropdown.Header className="text-sm">Teams ({props.ringGroups.length})
					<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
						<Popover id="popover-basic">
							<p>Teams allow you to add multiple users (Extensions) or telephone numbers to ring simultaneously.</p>
							<p>For example: If you have a sales team consisting of 4 people and you want all their phones to ring at the same time, you'd simply add all 4 of your sales team into a Team.</p>
						</Popover>
					}>
						<span className="icon icon-info2 text-sm ml-5"></span>
					</OverlayTrigger>
				</Dropdown.Header>
				{props.ringGroups.map((group) =>
					<Dropdown.Item id={`RedirectTeamsDropdownItem${group.label.replace(/\s/g, '')}`} className={`shorter ${props.number.redirectName === group.label ? 'active bg-success' : ''}`} onClick={e =>
					  props.number.redirectDetail === group.label
					    ? null
					    : props.changeRedirectNumber({
					      newNumber: group.label,
					      itemId: group.value,
					      itemType: RedirectType.Group,
					      position: props.position,
					      isWizard: props.isWizard,
					      isTimeSlot: props.isTimeSlot
					    })}>
						{group.label}
					</Dropdown.Item>
				)}
			</>
		}

		{(props.outboundUsers && props.outboundUsers.length > 0 && !HideRingGroupsConfig) &&
			<>
				<Dropdown.Header className="text-sm">Your VoIP Users ({props.outboundUsers.length})
					<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
						<Popover id="popover-basic">
							<p>These are your Outbound users (Extension numbers). You can add a users extension into a redirect so an inbound call goes directly to their extension, or add them to a Ring Group above.</p>
						</Popover>
					}>
						<span className="icon icon-info2 text-sm ml-5"></span>
					</OverlayTrigger>
				</Dropdown.Header>
				{props.outboundUsers.map((user) =>
					<Dropdown.Item id={`RedirectUserDropdownItem${user.label.replace(/\s/g, '')}`} className={props.number.redirectName === user.label ? 'active bg-success' : ''} onClick={e =>
					  props.number.redirectDetail === user.label
					    ? null
					    : props.changeRedirectNumber({
					      newNumber: user.label,
					      itemId: user.value,
					      itemType: RedirectType.Endpoint,
					      position: props.position,
					      isWizard: props.isWizard,
					      isTimeSlot: props.isTimeSlot
					    })}>{user.label}</Dropdown.Item>
				)}
			</>
		}

	</DropdownButton>
)

const mapStateToProps = state => {
  return ({
    redirectsHistory: state.configuration.redirects.redirectsHistory,
    outboundUsers: state.configuration.redirects.outboundUsers,
    ringGroups: state.configuration.redirects.ringGroups,
    is084: state.configuration.selectedConfiguration.queueNo ? state.configuration.selectedConfiguration.queueNo.startsWith('084') : false
  })
}

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators(configurationActions, dispatch)
)(RedirectNumberDropdown)
