import React, { useEffect, useRef, useState } from "react";
import {
  GoogleCredentialAuthResponse,
  GoogleLoginProps,

} from "../../models/OAuth";


// uses Google Identity 'Sign In with Google for Web'
// https://developers.google.com/identity/gsi/web/guides/overview

export default function GoogleLogin({
  onSuccess,
  onError,
  useOneTap,
  type = 'standard',
  theme = 'outline',
  size = 'large',
  text,
  shape,
  logo_alignment,
  width,
  locale,
  click_listener,
  containerProps,
  ...props
}: GoogleLoginProps) {
  const googleButton = useRef<HTMLDivElement>(null);
  const clientId = '248954550261-9tt23hbfoihpl83d0ahmb7s4c7mbllh1.apps.googleusercontent.com'

  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;

  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  const loadScript = (src) =>
    new Promise<void>((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) return resolve()
      const script = document.createElement('script')
      script.src = src
      script.onload = () => resolve()
      script.onerror = (err) => reject(err)
      document.body.appendChild(script)
    })


  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'

    loadScript(src)
      .then(() => {
        // @ts-ignore
        window?.google?.accounts?.id?.initialize({
          client_id: clientId,
          callback: (credentialResponse: GoogleCredentialAuthResponse) => {
            if (!credentialResponse?.credential) {
              return onErrorRef.current?.();
            }

            const { credential } = credentialResponse;
            onSuccessRef.current({
              credential,
              clientId: credentialResponse?.clientId ?? credentialResponse?.client_id,
            });
          },
          ...props,
        });

        // @ts-ignore
        window?.google?.accounts?.id?.renderButton(googleButton.current!, {
          type,
          theme,
          size,
          text,
          shape,
          logo_alignment,
          width,
          locale,
           click_listener,
        });

        if (useOneTap)
          // @ts-ignore
          window?.google?.accounts?.id?.prompt();

        return () => {
          // @ts-ignore
          if (useOneTap) window?.google?.accounts?.id?.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      })
      .catch(console.error)


    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [
    clientId,
    useOneTap,
    type,
    theme,
    size,
    text,
    shape,
    logo_alignment,
    width,
    locale,
  ]);

  return (
    props.hideButton !== true ?
      <div {...containerProps}
        ref={googleButton}
        style={{ ...containerProps?.style }}
      /> : <div />
  );
}