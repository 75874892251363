import React, { Component } from 'react'
import { fetchHelper } from '../helpers/fetchHelper'

export default class GetEnvVariable extends Component {
	state = {
		value: ""
	}

	render() {
		const formattedValue = this.props.type === "price"
			? (Number(this.state.value) < 1
				? Number(this.state.value).toFixed(2).split('.')[1]
				: Number(this.state.value).toFixed(2))
			: this.state.value;

		return (
			`${formattedValue}`
		)
	}

	async componentDidMount() {
		fetchHelper.getJson(`api/Purchase/GetEnvVariable?name=${this.props.envName}`)
			.then(res => {
				this.setState({ value: res.data })
			}).catch(err => console.error(err));
	}
}