import React from 'react'
import { connect } from 'react-redux'

class AboutMenu extends React.Component {
  constructor (props) {
    super(props)
  }

  aboutNavRoutes = {
    aboutRoute: '/about-us/',
    teamRoute: '/meet-the-team/',
    contactRoute: '/contact/',
    faqRoute: '/faq/',
    reviewsRoute: '/reviews/'
  }

  render () {
    return <ul className="unstyled subnav">
                <li>
                    <a href={this.aboutNavRoutes.aboutRoute}>About Us</a>
                </li>
                <li>
                    <a href={this.aboutNavRoutes.teamRoute}>Meet the team</a>
                </li>
                <li>
                    <a href={this.aboutNavRoutes.contactRoute}>Contact us</a>
                </li>
                <li>
                    <a href={this.aboutNavRoutes.faqRoute}>FAQ</a>
                </li>
                <li >
                    <a href={this.aboutNavRoutes.reviewsRoute}>Testimonials</a>
                </li>
        </ul>
  }
}
export default connect(
  state => {
    return state
  },
  {}
)(AboutMenu)
