import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'
import CustomPrompts from '../../CustomPrompts'

const CallWhisperInstructionHelperDialog = props => (
    <Modal className="w-900px" show={props.callWhisperInstructionHelperDialogDisplayed} onHide={props.hideCallWhisperInstructionHelperDialog}>
        <Modal.Header closeButton>
            <Modal.Title>Call Screening</Modal.Title>
        </Modal.Header>
        <Modal.Body className="col-md-12">
            <p>
                Call Screening is a very short message you will hear before you&apos;re connected with the caller.
            </p>
            <p>
                This will allow you to know where the call has come
                from so that you can answer the call appropriately. Secondly it allows you to decide whether
                or not you want to answer the call without the caller knowing and thirdly it allows you to
                send the caller to voicemail if you do not want to speak to them but don&apos;t want them to stay
                waiting in your call queue either.
            </p>
            {/* <h4 className="pt-20">Custom call screening message</h4>
            <p>You can record your own call screening messages using the app or via calling our special number or our team can record it for you, <a onClick={props.handleShowCustomRecordingSectionHelperInstruction}>click here to find out more</a>.</p> */}
            <div className="col-md-12 text-center pb-30 pt-30">
                <h3 className="text-500">Make your company sound amazing!</h3>
                <p>Choose one of the below voice artists to record your out of hours message, introduction, call screening, voicemail message, departments menu... or simply do it yourself.</p>
            </div>
            <CustomPrompts />

        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideCallWhisperInstructionHelperDialog}>Close</button>
        </Modal.Footer>
    </Modal>
)

export default connect(
  state => state.configuration.dialogVisibility,
  dispatch => bindActionCreators(configurationActions, dispatch)
)(CallWhisperInstructionHelperDialog)
