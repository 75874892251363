import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { siteFooter } from '../constants/Constants'
import CallbackRequest from './CallbackRequest'
import CallbackRequestNew from './CallbackRequestNew'

const FooterMain = props => {
  const [showNav, setShowNav] = useState(true)

  if (props.ShowFooterMain) {
    return (
            <>
                <CallbackRequestNew />
                <footer id="footerMain" className="p-50">

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row">
                                    <Button id="footerNavToggle" className="d-block d-md-none center-block btn-lightest no-shadow text-400 text-md" onClick={() => setShowNav(!showNav)}>
                                        <h3 className="text-dark text-md">Useful Links<span className={'icon ' + (showNav ? 'icon-arrow-up4' : 'icon-arrow-down4') + ' text-dark ml-10'}></span></h3>
                                    </Button>

                                    {showNav && <div id="navWrapper" className='row'>
                                        <div className="col-12 col-sm-3">
                                            <p className="text-500">
                                                Products
                                            </p>
                                            <ul>
                                                <li>
                                                    <Link to={'/keep-my-number/'} id="TransferYourNumberFooterLink">Transfer your number</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/call-recording'} id="CallRecordingFooterLink">Call recording</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/virtual-phone-numbers'} id="VirtualPhoneNumbersFooterLink">Virtual Phone Numbers</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/business-voip-phone-system'} id="BusinessVoIPPhonesSystemsFooterLink">Business VoIP phones systems</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/0203-numbers'} id="BuyLondonNumbersFooterLink">Buy London Numbers</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/0800-numbers'} id="Buy0800NumbersFooterLink">Buy 0800 Numbers</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <p className="text-500">
                                                Resources
                                            </p>
                                            <ul>
                                                <li>
                                                    <Link to={'/blog'} id="BlogFooterLink">Blog</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/videos'} id="SetupAndEducationalVideoLink">Setup & Educational Video</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/competitors/'} id="CompetitorsComparisonLink">Competitors comparison</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/customers'} id="CustomersFooterLink">Customers</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/case-studies/'} id="CaseStudiesFooterLink">Case studies</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/emergency-services'} id="CaseStudiesFooterLink">Emergency services</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <p className="text-500">
                                                Other services
                                            </p>
                                            <ul>
                                                <li>
                                                    <Link to={'/telephone-answering-service/'} id="TelephoneAnsweringFooterLink">Telephone answering service</Link>
                                                </li>
                                                <li>
                                                    <a href="https://www.phonely.co.uk/services/residential-voip" rel="noopener noreferrer" target="_blank" id="PhonelyFooterLink">Residential VoIP</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.datasoap.co.uk/" rel="noopener noreferrer" target="_blank" id="DatasoapFooterlink">Data cleaning</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <p className="text-500">
                                                Company
                                            </p>
                                            <ul>
                                                <li>
                                                    <Link to={'/about-us/'} id="AboutUsFooterLink">About us</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/contact/'} id="ContactUsFooterLink">Contact us</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/charities/'} id="CharitiesFooterLink">Charities</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <p className="mb-20">
                                    <a className="text-md text-500 text-secondary" href="tel:02031891213">0203 189 1213</a>
                                </p>
                                <div className="mt-20">
                                    <a className="text-sm" href="mailto:support@switchboardfree.co.uk">Email us</a>
                                    <br className="d-block d-md-none" />
                                    <span className="pr-20 pl-20 text-xs text-muted d-none d-md-inline-block">|</span>
                                    <a className="text-sm open-live-chat">Chat to us</a>
                                    <br className="d-block d-md-none" />
                                    <span className="pr-20 pl-20 text-xs text-muted d-none d-md-inline-block">|</span>
                                    <CallbackRequest textLink={true} className="text-sm" />
                                </div>
                                <ul className="social-icons">
                                    <li>
                                        <a href="https://www.facebook.com/SwitchboardFREEUK"rel="noopener" target="blank" className="facebook" >
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/SwitchboardFREE" rel="noopener" target="blank" className="twitter">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/switchboardfree"rel="noopener" target="blank" className="linkedin">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/subscription_center?add_user=SwitchboardFREE"rel="noopener" target="blank" className="youtube">
                                        </a>
                                    </li>
                                </ul>
                                <p>
                                    <a href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREE&hl=en_GB" rel="noopener"><img className="app-img mr-30" src={'images/g-play.svg'} /></a>
                                    <a href="https://itunes.apple.com/gb/app/sbf-2012/id556878596" rel="noopener"><img className="app-img" src={'images/app-store.svg'} /></a>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-4 text-xs">
                                        {/* <img src={'/images/logo/sflogo.svg'} alt="SwitchboardFREE" className="mr-30 float-start logo" height="15" /> */}
                                        <span className="inline-block mr-30"><Link to={'/terms-and-conditions'} target="_blank">Terms and conditions</Link></span>
                                        <span className="inline-block mr-30"><Link to={'/privacy-policy'}>Privacy policy</Link></span>
                                        <span className="inline-block"><Link to={'/sitemap'}>Site map</Link></span>
                                        <p className="text-sm mt-20 text-center text-md-start">
                                            &copy; {(new Date().getFullYear())} Liquid11, Sea Lake Road, Lowestoft, Suffolk, NR32 3LQ, Company number: 04404380 Regulated by Ofcom.
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-8 text-center text-md-start mt-xs-30 mt-sm-30">
                                        <p className="footer-logos">
                                            <img src={'/images/future50.png'} alt="Future50 Winners" />
                                            <img src={'/images/years/years-stamp-19.png'} alt="SwitchboardFREE - Established in 2005" />
                                            <img src={'/images/carbon2.jpg'} alt="Suffolk Carbon Charter Gold" />
                                            <img src={'/images/prs-logo.png'} alt="PRS logo" />
                                            <img src={'/images/cyber-essentials.png'} alt="Cyber Essentials Plus badge" />
                                            <img src="/images/demendia-friend-badge.jpg" alt="Demendia Friends badge" className='brad-10' />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
    )
  } else { return null }
}

const mapStateToProps = state => {
  return { ShowFooterMain: state.sbfApp.visibility.FOOTER === siteFooter.MAIN }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {},
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterMain)
