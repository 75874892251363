import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface AnotherNumberProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const AnotherNumber = (props: AnotherNumberProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Another Number vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Another Number vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Another Number vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What is Another Number?</h2>
                            <p>Another Number is essentially a mobile app working on iPhones. Users are provided with a virtual work number they can use as a main business number. The leading purpose of the Another Number app is to give users an alternative to using their personal mobile phone as a business phone, too.</p>
                            <p>The Another Number app only has to be downloaded and the customer enters their 1-week free trial after which they are automatically enrolled to a monthly subscription that costs £9.99. There are no setup fees or long-term contracts – you can cancel your subscription any time.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What do you get with Another Number?</h2>
                            <p>The app allows you to receive and make phone calls. Calls to landlines are free, so are calls and messages to and from other Another Number users. If you want to make calls to non-subscribers, you have to buy a bundle of credits from iTunes. 1 credit equals 1 minute of calling or 1 message (up to 160 characters). The credit amounts and prices are:</p>
                            <ul className="fancy cta text-400 pl-30 pt-10 pb-30">
                                <li className="mb-5">60 credits - £2.99</li>
                                <li className="mb-5">130 credits - £5.99</li>
                                <li className="mb-5">200 credits - £8.99</li>
                                <li className="mb-5">400 credits - £14.99</li>
                            </ul>

                            <p>On your renewal date you automatically receive a bonus of 200 loyalty credits.</p>
                            <p>Bear in mind that the minutes you talk will be taken from your monthly allowance on your current phone contract.</p>
                            <p>You can record your own greeting via the Another Number app which will be played when you are unable to take a call.</p>
                            <p>Voicemails are easily transcribed and will be displayed in the app.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Comparison between Another Number and SwitchboardFREE</h2>
                            <p>There are some fundamental differences between the two but each of them has its advantages. Another Number might be limited to just an app working only on iPhones at the moment but it is good for allowing the users to also make, and not only receive calls.</p>
                            <p>It seems like an app that is more concerned with the personal comfort of the user, as it gives them a different number from their personal one to use when making business. SwitchboardFREE, on the other hand, is completely business-oriented, and we do everything possible to reduce the risk of you missing a call.</p>
                            <p>This being said, having Another Number practically leaves you on your own, and if you cannot answer a call, your callers will be forced to leave a voicemail or hang up. With SwitchboardFREE, when there is an inbound call to your virtual number, it can be diverted to up to 5 redirects which ensures that someone from your business will be available to deal with the call.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Another Numbers current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.thumbtel.com/another-number-second-mobile-number/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(AnotherNumber)
