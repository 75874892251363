import moment from 'moment'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Logos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import { productBillingOption, ProductIds } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat } from '../../helpers/utils'
import { getConfiguration, getConfigValidators, isLoggedIn } from '../../selectors'
import { changeUserDetails, handleInitSipUsers, validateUser } from '../../store/Configuration'
import * as fromHome from '../../store/Home'
import { getProductPrice } from '../../store/Home'
import { handleCallBlockingEnabledChange, handleNotificationChange, handleShowCallbackForm, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'
import TermsAndConditions from '../../Text/TermsAndConditions'

class CallBlocking extends React.Component {
  state = {
    isSaving: false,
    monthly: false
  }

  constructor (props) {
    super(props)
  }

  render () {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <>
				<p className="text-500 text-lightest text-uppercase text-md mb-20">
					HURRY... <em className="text-cta">offer <u>ends soon</u></em>
				</p>
				<div className="countdown-timer">
					<div className="timer-block">
						{days}<br /><span className="text-sm">days</span>
					</div>
					<div className="timer-block">
						{hours}<br /><span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}<br /><span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}<br /><span className="text-sm">seconds</span>
					</div>
				</div>
			</>
    }

    const callBlockPrice = {
      annual: {
        firstPayment: 0, repeatPayment: 0
      },
      monthly: {
        firstPayment: 0, repeatPayment: 0
      }
    }

    if (this.props.productDetails) {
      const callBlockProdDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.callBlocking)

      if (callBlockProdDetails) {
        callBlockPrice.monthly.firstPayment = callBlockProdDetails.monthlyPrice.firstPaymentAmount
        callBlockPrice.monthly.repeatPayment = callBlockProdDetails.monthlyPrice.repeatPaymentAmount
        callBlockPrice.annual.firstPayment = callBlockProdDetails.yearlyPrice.firstPaymentAmount
        callBlockPrice.annual.repeatPayment = callBlockProdDetails.yearlyPrice.repeatPaymentAmount
      }
    }

    return <><DisplayMainFooter />
			<Helmet>
				<body className={'body-simple bg-dull'} />
				<title>EXCLUSIVE! Get Unlimited Outbound Calls!</title>
			</Helmet>
			<div className="p-50 p-sm-30 p-xs-15 pt-xs-50 pt-100" style={{ background: "#fff url('/images/campaigns/call-blocking/background.jpg') no-repeat", backgroundPosition: '125% 100%', backgroundSize: '60%' }}>
				<div className="container p-xs-0">
					<div className="row mb-50">
						<div className="col-md-8 col-sm-10 mx-auto text-center">
							<span className="bg-secondary p-10 pr-50 pl-50 pl-xs-10 pr-xs-10 text-light text-lg text-500 text-uppercase text-lightest text-xs-sm">
								Exclusive annual savings
									</span>
							<p className="text-lg text-400 mt-30">
								<em>Block Unwanted Calls Across All Your Numbers...</em>
							</p>
							<h1>
								Stop Bothersome Spam &amp; Cold Calls Instantly With <span className="text-cta">Nuisance Call Blocker</span> - <em><span className="text-cta"><u>Save Over 22%!</u></span></em>
							</h1>
							<>
								<span className="p-30 bg-darker mb-20 d-inline-block center-block brad-100 mt-50">
									<Countdown date={moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
								</span>
								<p className="text-sm mb-0 p-0">
									{this.state.added} people added <span className="text-500">Nuisance Call Blocker</span> in the last hour
								</p>
							</>
						</div>
					</div>
					<div className="row">
						<div className={'col-lg-7 col-md-8 col-sm-12'}>
							<div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
								{this.state.diallerSent === true
								  ? <>
										<h3 className="text-500 mt-30">
											Thanks!
														</h3>
										<p className="text-400 lead mb-10 mt-0">We'll give you a call shortly.</p>
										<p className="text-md mt-30">
											We've successfully received your request and one of our team will call you shortly.
														</p>
										<p className="mt-30">
											<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
										</p>
									</>
								  :									<>
										<p className="text-400 text-uppercase text-lg mb-10 mt-0">Upgrade your account today!</p>
										<div className="row mb-30">
											<div className="col-sm-3 col-md-2 col-xs-3 text-xs pt-10 col-xxs-12 pb-xxs-10">
												<em>Plan options:</em>
											</div>
											<div className="col-sm-9 col-md-10 col-xs-9 col-xxs-12 ">
												<span className={`mr-10 text-500 ${this.state.monthly ? 'text-muted' : 'active'}`}>Annual</span>
												<Toggle
													onClick={() => this.setState({ monthly: this.state.monthly !== true })}
													on=""
													off=""
													offstyle="default"
													onstyle="success"
													width="null"
													height="null"
													size="sm"
													className="toggle-secondary rounded h-35px w-60px"
													active={this.state.monthly}
												/>
												<span className={`ml-10 text-500 ${this.state.monthly ? 'active' : 'text-muted'}`}>Monthly</span>
											</div>
										</div>

										<div className="row">
											<div className="col-md-10 col-sm-9">
												{this.state.monthly
												  ? <>
														<h3 className="text-500 mt-0">
															Nuisance Call Blocker<br className="d-none d-sm-block" />
															<span className="text-cta"> Add To Your Account Now!</span>
														</h3>
														<p className="text-dullest text-sm">
															Just &pound;{callBlockPrice.monthly.repeatPayment} per month!
														</p>
													</>
												  :													<>
														<h3 className="text-500 mt-0">
															Nuisance Call Blocker<br className="d-none d-sm-block" />
															<span className="text-cta"> SAVE Over 22%!</span>
														</h3>
														<p className="text-dullest text-sm">
															<strike>Normally &pound;{callBlockPrice.monthly.repeatPayment * 12} for a year</strike> - Now just &pound;{callBlockPrice.annual.repeatPayment} per year!
														</p>
													</>
												}
											</div>
											<div className="col-md-2 col-sm-3 text-xs-center">
												<img src="/images/campaigns/call-blocking/top-feature.png" alt="Top Feature" className="img-responsive" style={{ maxWidth: '100px' }} />
											</div>
										</div>

										<ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-30">
											<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Automatically identify &amp; stop cold callers before they get to you</em></li>
											<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Block all calls from outside the UK</em></li>
											<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Intercept calls from suspicious "withheld" numbers</em></li>
											<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Block individual or a list of numbers</em></li>
											<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Account wide, block calls across multiple numbers</em></li>
											<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Instantly add or remove callers at will</em></li>
										</ul>
										{this.state.submittingToDialler
										  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
										  :											<>
												<p className="mt-30 mb-20">
													<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20 btn-xs-block" onClick={() => this.handlePurchaseCallBlocking()}>Add to account now</Button>
												</p>
												{this.state.diallerSent === false &&
													<p className="text-400 text-danger text-center">
														Sorry, there was a problem submitting your request, please try again.
											</p>
												}
												<p>
													Want to know more? Give us a call on <a href="tel:02031891213" className="text-500">0203 189 1213</a> <Button variant="link" className="text-dark" onClick={() => this.handleAddToDialler()}>or, request more information</Button>
												</p>
											</>
										}
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-dull p-50 text-center">
				<sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
			</div>

			<div className="bg-light pt-50 pb-50">
				<h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
				<Logos style="light" />
			</div>

			<div className="full-width bg-dull">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>

			<Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
				<Modal.Header closeButton>
					<Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TermsAndConditions />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
				</Modal.Footer>
			</Modal>

			<ChoosePayment
				total={this.state.wpAmount ? calculateVat(this.state.wpAmount) : 0}
				clientId={this.state.clientId}
				purchaseData={this.state.key}
				email={this.props.userDetails.email}
				name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
				mandate={!this.props.services.hasAgreement}
			/>

			<Modal size="sm" show={this.state.showLogin}>
				<Modal.Header>
					<Modal.Title>Log into your account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12 text-center pb-30">
							<form name="form" className="text-left" onSubmit={e => this.props.login({
							  userName: this.props.userName,
							  password: this.props.password,
							  event: e,
							  history: this.props.history,
							  persist: document.getElementById('persistLogin').checked,
							  route: `/access/call-blocking?${this.state.offer ? 'offer=true' : ''}`
							})}>
								<div className="row">
									<div className="col-xs-12">
										<p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
									</div>
									<div className="col-xs-12">
										<label>Email</label>
										<input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
									</div>
									<div className="col-xs-12 mt-20">
										<label>Password</label>
										<input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
									</div>
								</div>
								<div className="pt-10 pb-10">
									<div className="col-xs-6 pl-0">
										<input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
									</div>
									<div className="col-xs-6 text-right pr-0">
										<Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
									</div>
								</div>
								<div className="text-center pt-30">
									{this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                  {this.props.loginFailed === true && (this.props.loginFailReason === '' || this.props.loginFailReason === null || this.props.loginFailReason === undefined) ?
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'} className="underline text-danger">resetting your password</Link>.</p>
                  :
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage" hidden={!this.props.loginFailed}>{this.props.loginFailReason}</p>
                  }
									<button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

									<p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
									<p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>

		</>
  }

  componentDidUpdate () {
    if (this.props.isLoggedIn && this.state.showLogin === true) {
      this.setState({ showLogin: false }, () => {
        this.getPrice()
      })
    }
  }

  componentWillMount () {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount () {
    const context = this
    context.setState({ added: ((Math.random() * 18) + 2).toFixed(0) })

    if (context.state.token) {
      fetchHelper.getJson(`/api/AccessToken/ClientHasCallBlocking?t=${context.state.token}`)
        .then(res => this.setState({ hasCallBlocking: res.data }))
        .catch(err => console.error(err))
    } else if (!this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else if (context.props.isLoggedIn) {
      this.setState({ showLogin: false })
    }

    const purchasedCallBlock = localStorage.getItem('purchaseCallBlocking')
    if (purchasedCallBlock) { context.enableCallBlocking(context) }

    context.props.getProductDetails([
      { productId: ProductIds.callBlocking, monthlyOption: productBillingOption.callBlockingBillingOptionMonthly, yearlyOption: productBillingOption.callBlockingBillingOptionYearly }
    ])
  }

  handlePurchaseCallBlocking () {
    const context = this
    const url = `/api/AccessToken/PurchaseProduct?t=${context.state.token}`

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.postJson(url, { BillingOption: context.state.monthly ? productBillingOption.callBlockingBillingOptionMonthly : productBillingOption.callBlockingBillingOptionYearly, ProductId: ProductIds.callBlocking }).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          context.enableCallBlocking(context)
        } else if (res.data && res.data.postponed === true) {
          context.enableCallBlocking(context)
          context.props.handleShowPaymentPostponedModal()
        } else {
          context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false, clientId: res.clientId }, () => {
            localStorage.setItem('step', JSON.stringify(this.state.step))
            localStorage.setItem('purchaseCallBlocking', true)
            context.props.handleShowPaymentOptionsModal()
          })
        }

        window.dataLayer.push({
          event: 'callBlockingPurchased'
        })
      }).catch(function (error) {
        context.setState({ isPurchaseInProgress: false })
        console.error(error)
      })
    })
  }

  enableCallBlocking (context) {
    localStorage.removeItem('purchaseCallBlocking')
    context.setState({ isPurchaseInProgress: false })
    context.props.notify({ message: 'Call blocking enabled' })
    context.props.handleCallBlockingEnabledChange(true)
  }

  handleSubmitToSupport () {
    const context = this

    context.setState({ submittingToDialler: true }, () => {
      const url = `/api/AccessToken/SubmitLeadToSupport?t=${context.state.token}&product=Discounted%20Call%20Blocking`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ diallerSent: res.data, submittingToDialler: false })
        }).catch(err => console.error(err))
    })
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      productDetails: state.home.productDetails.products,
      config: getConfiguration(state),
      validation: getConfigValidators(state),
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed,
      loginFailReason: state.home.login.reason
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    getProductDetails: getProductPrice,
    notify: handleNotificationChange,
    handleShowCallbackForm,
    handleInitSipUsers,
    changeUserDetails,
    validateUser,
    handleCallBlockingEnabledChange,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal
  }
)(CallBlocking)
