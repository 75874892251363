import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { isLoggedIn } from '../../selectors'
import { updateUserFromLocalStorage } from '../../store/SBF'
import DisplayMainFooter from '../DisplayMainFooter'
import DisplayMainHeader from '../DisplayMainHeader'
import ScrollToTopOnMount from '../ScrollToTopOnMount'

class PipedriveInboundRequest extends React.Component {
  state = {
    code: ''
  }

  componentDidMount () {
    const query = new URLSearchParams(this.props.location.search)

    const code = query.get('code')

    this.setState({ code })
  }

  render () {
    if (this.props.isLoggedIn === true) {
      const query = new URLSearchParams(this.props.location.search)

      const code = query.get('code')

      return <Redirect to={'/customer/contact-sync/pipedrive-crm?code=' + code}/>
    } else {
      return <>
                <ScrollToTopOnMount/>
                <DisplayMainFooter/>
                <DisplayMainHeader/>
                <Helmet>
                    <title>Pipedrive CRM Sync</title>
                    <meta name="description" content="Outbound call settings" />
                </Helmet>
                <header>
                    <div className="full-width full-hero-small with-text" >
                    <h1>Pipedrive CRM Sync</h1>
                    </div>
                </header>

                <div className="container pt-30 pb-30">
                    <div className="w-600px center-block bg-light mt-30 mb-100 brad-30 p-40 text-center box-shadow">
                        <div className="loading-content text-center">
                            <h5 className="text-500">
                                Please log in, or create your account
                            </h5>
                            <p>
                                Looks like you&apos;re trying to integrate Pipedrive with your SwitchboardFREE's Contact Book.
                                To continue, please either log in to your existing account or create an account if you&apos;re new to SwitchboardFREE.
                            </p>
                            <p className="pt-20">
                                <Link to={'/login?redirect=/customer/contact-sync/pipedrive-crm?code=' + this.state.code} className="btn btn-cta btn-lg">Login</Link>
                            </p>
                            <p className="pt-20">
                                <Link to={'/get-started?pipedriveCode=' + this.state.code} className="text-dark underline">Create an account</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </>
    }
  }
}

export default withRouter(connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  }, { updateUserFromLocalStorage }
)(PipedriveInboundRequest))
