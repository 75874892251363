import React from 'react'
import { Button } from 'react-bootstrap'

export const ClearableTextBox = props => (
    <div className="buttonInside">
        <input className="form-control" type="text" placeholder={props.placeholder}
               onChange={e => props.onChange ? props.onChange(e) : undefined}
               onBlur={e => props.onBlur ? props.onBlur(e) : undefined} value={props.value}/>
        <Button variant="default" onClick={e => props.onChange ? props.onChange(undefined) : undefined}>
            <span className={'icon icon-cross3 text-lightest'}/>
        </Button>
    </div>
)
