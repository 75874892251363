import moment from 'moment'
import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import CallbackRequest from '../../components/CallbackRequest'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import MusicOnHoldChristmas from '../../components/MusicOnHoldChristmas'
import MusicOnHoldOccasions from '../../components/MusicOnHoldOccasions'
import MusicOnHoldPlaylists from '../../components/MusicOnHoldPlaylists'
import MusicOnHoldTheBestOf from '../../components/MusicOnHoldTheBestOf'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { productBillingOption, ProductIds } from '../../constants/Constants'

class MusicOnHold extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      show: false,
      showMohTracks: false,
      mohCatId: 0
    }
  }

  scrollTo () {
    scroller.scrollTo('start', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>Music On-Hold on your phone - Christmas Hits, UK Top10 Hits or your own custom music</title>
                <meta name="description" content="Watch and learn how to turn on missed call alerts, how to set up multiple departments and many more." />
                <style type="text/css">{`
                        @media screen and (max-width:992px) {
                            .playlist .img-cover {
                                min-height: 280px;
                            }
                        }
                        @media screen and (max-width:600px) {
                            .playlist .img-cover {
                                max-width: 80%;
                                min-height:300px;
                            }
                            .slick-next {
                                right: 20px !important;
                            }
                            .slick-next {
                                right: 20px !important;
                            }
                            .slick-prev {
                                left: 10px !important;
                            }
                        }
                        @media screen and (max-width:480px) {
                            .playlist .img-cover {
                                max-width: 230px;
                                min-height: 180px;
                            }
                            .playlist .img-cover .icon {
                                top:25%;
                                margin-left:-30px;
                            }
                            .slick-next, .slick-prev {
                                top:33% !important;
                                z-index:1;
                            }
                        }
                    `}</style>
            </Helmet>
            {/* <header>
                <div className="full-width full-hero-small" style={{ backgroundImage: "url('/images/backgrounds/moh.jpg')" }}></div>
            </header> */}
            <header>
                <div className="full-width full-hero dark-overlay showreel-page pt-100 pb-50" id="hero">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center text-lightest">
                                <h1 className="text-lightest text-500">Instantly improve your customer experience</h1>
                                <h2 className="subtitle text-400 text-lightest">Create the right impression with Music On-Hold</h2>
                                <p className="d-none d-xs-block">
                                Your company’s on-hold music is vital to creating the right impression. Music is inspirational, it can make us <br className="d-none d-sm-block"/>laugh or cry,

                                it can inspire us to think differently about the business.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-20 arrow-scroll">
                                    <a onClick={this.scrollTo} className="btn btn-xl btn-cta pl-xs-20 pr-xs-20 mr-20 btn-mobile-block mb-xs-5" >
                                        Choose your music
                                    </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center arrow-scroll">
                                <a onClick={this.scrollTo}>
                                    <span className="icon icon-arrow-down4"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container-styled1 bg-light" id="start">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row text-center pt-20 pb-30 mt-30">

                            {moment().month() === 11
                              ? <>
                                    <h3 className="text-400 pb-10 text-xs-lg pt-xs-60 mt-xs-50 bt-xs-10">Christmas Playlists</h3>
                                    <p className="pb-30">
                                        Give your customers some fetive cheer with our Christmas playlists.
                                    </p>
                                    <MusicOnHoldChristmas/>

                                    <h3 className="text-400 pb-10 text-xs-lg">Music Categories</h3>
                                    <p className="pb-30">
                                        Selection of some of the best, most known music tracks for nearly every music genre.
                                    </p>

                                    <MusicOnHoldPlaylists/>
                                    <h3 className="text-400 pb-10 text-xs-lg pt-xs-60 mt-xs-50 bt-xs-10">The Best Of...</h3>
                                    <p className="pb-30">
                                        Some of the best artists and bands best of's...
                                    </p>
                                    <MusicOnHoldTheBestOf/>

                                    <h3 className="text-400 pb-10 text-xs-lg pt-xs-60 mt-xs-50 bt-xs-10">Special Occasions...</h3>
                                    <p className="pb-30">
                                        Valentine’s day? Halloween? We've got you covered!
                                    </p>
                                    <MusicOnHoldOccasions/>
                                </>
                              : <>
                                    <h3 className="text-400 pb-10 text-xs-lg">Music Categories</h3>
                                    <p className="pb-30">
                                        Selection of some of the best, most known music tracks for nearly every music genre.
                                    </p>

                                    <MusicOnHoldPlaylists/>
                                    <h3 className="text-400 pb-10 text-xs-lg pt-xs-60 mt-xs-50 bt-xs-10">The Best Of...</h3>
                                    <p className="pb-30">
                                        Some of the best artists and bands best of's...
                                    </p>
                                    <MusicOnHoldTheBestOf/>

                                    <h3 className="text-400 pb-10 text-xs-lg pt-xs-60 mt-xs-50 bt-xs-10">Christmas Playlists</h3>
                                    <p className="pb-30">
                                        Give your customers some fetive cheer with our Christmas playlists.
                                    </p>
                                    <MusicOnHoldChristmas/>

                                    <h3 className="text-400 pb-10 text-xs-lg pt-xs-60 mt-xs-50 bt-xs-10">Special Occasions...</h3>
                                    <p className="pb-30">
                                        Valentine’s day? Halloween? We've got you covered!
                                    </p>
                                    <MusicOnHoldOccasions/>

                                </>
                            }
                        </div>
                    </div>
                </div>
                </div>
                <div className="full-width pt-0 pb-0 mb-60 mt-30">
                    <div className="container bg-dull">
                        <div className="row">
                            <div className="col-sm-3 d-none d-md-block mr-30" style={{ backgroundImage: "url('/images/moh/prs-bg.jpg')", backgroundSize: 'cover', minHeight: '200px' }}>
                                <img src="/images/prs-logo.png" alt="PRS for Music logo" style={{ top: '10px', position: 'absolute', right: '15px' }} />
                            </div>
                            <div className="col-sm-8 pt-40 pb-30">
                                <h5 className="text-xs-lg text-500">Fully licensed with PRS Media</h5>
                                <p>
                                    The Music Licence allows you to legally play or perform music for employees or customers in your business through the radio, TV, other digital devices and live performances.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <MohTracks showModal={this.state.showMohTracks} catId={this.state.mohCatId} hideModal={e => this.handleCloseModal()} name={this.state.mohName} /> */}
        </>
  }

  componentDidMount () {
    document.addEventListener('play', function (e) {
      const audios = document.getElementsByTagName('audio')
      for (let i = 0, len = audios.length; i < len; i++) {
        if (audios[i] !== e.target) {
          audios[i].pause()
        }
      }
    }, true)

    const random = Math.floor(Math.random() * 6) + 0
    const bigSize = [

      "url('/images/backgrounds/moh-bg1.jpg')",
      "url('/images/backgrounds/moh-bg2.jpg')",
      "url('/images/backgrounds/moh-bg3.jpg')",
      "url('/images/backgrounds/moh-bg4.jpg')",
      "url('/images/backgrounds/moh-bg5.jpg')",
      "url('/images/backgrounds/moh-bg6.jpg')",
      "url('/images/backgrounds/moh-bg7.jpg')"
    ]
    moment().month() === 11
      ? document.getElementById('hero').style.backgroundImage = "url('/images/backgrounds/moh-xmas.jpg')"
      : document.getElementById('hero').style.backgroundImage = bigSize[random]
  }
}

export default connect(
  state => {
    return ({
      monthlyPrice: state.home.productDetails.monthlyRepeatPayment,
      setupCharge: state.home.productDetails.monthlyFirstPayment
    })
  },
  {}
)(MusicOnHold)
