import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { PromptTypes } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'
import PromptAudioPlayer from '../PromptAudioPlayer'
import MusicOnHoldInstructionHelperDialog from './InstructionHelperDialogs/MusicOnHoldInstructionHelperDialog'

const MusicOnHoldSection = props => (

    <>
    <div className="row bg-light p-30 zoom">
        <div className="col-md-5 pb-sm-10">
            <h5 className="text-secondary text-500 text-lg">
                Music On Hold
            </h5>
            <p className="text-400">Entertain your callers with an exceptional choice of on hold music to enhance your business’s audio branding.
            <br/><a onClick={props.handleShowMusicOnHoldInstruction} className="underline" id="MOHMoreInfoLink">More info</a></p>
        </div>
            <div className="col-md-7 mt-10 pl-sm-0 pr-sm-0">

                    <div className="col-12 col-md-8 float-end">
                    <p>Select music genre:</p>
                    <MusicOnHoldInstructionHelperDialog/>
                    <Select
                        id="mohSelect"
                        className="form-control select-menu"
                        classNamePrefix="custom"
                        onChange={props.fetchMohTrack}
                        options={props.mohPrompts}
                        simpleValue
                        name="selectedMoh"
                        value={props.selectedMoh !== undefined ? { value: props.selectedMoh.promptId, label: props.selectedMoh.promptDescription } : undefined }
                        searchable={false}
                        clearable={false}
                        isLoading={props.isLoadingMohOptions}
                    />
                    <br/>
                    <PromptAudioPlayer noMargin={true} promptId={props.selectedMoh !== undefined ? props.selectedMoh.selectedMohTrackId : undefined} promptType={PromptTypes.holdMusicTrack} />
                </div>

            </div>
        </div>
    </>
)

const mapStateToProps = state => ({
  mohPrompts: state.configuration.prompts.mohPrompts,
  selectedMoh: state.configuration.configuration.musicOnHoldPrompt,
  isLoadingMohOptions: state.configuration.loading.mohPrompts
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MusicOnHoldSection)
