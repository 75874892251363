import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface VirtualLandlineProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const VirtualLandLine = (props: VirtualLandlineProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Virtual Landline vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Virtual Landline vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Virtual Landline vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What is a virtual landline?</h2>
                            <p>A virtual landline number is simply a phone number that isn’t connected or associated with a fixed landline or telephone line. These numbers live in the cloud and exist on a virtual landline provider's system or platform. They work in the same way a regular landline phone number but the service provider forwards or "diverts" any incoming calls directly to your landline or mobile phone.</p>
                            <p>Typically, we all make and receive calls with our landline at home and this landline is associated with a single local, geographical phone number that is assigned to that landline. This technology is called PSTN (Public Switched Telephone Network) or referred to as an "analogue line". Typically, the first 5 numbers of a landline number are used to identify the geographical location or "area code" of that line. This is the phone number you present when making outbound calls.</p>
                            <p>A virtual landline service works in a slightly different way. If someone calls your virtual landline number, the call is then transferred to either your mobile or landline. This allows you to advertise a business number and change the destination number or call divert number as you see fit without the need to change your business number which is often used for advertising.</p>
                            <p>Many small businesses advertise their mobile number instead of a landline number to receive calls which can look unprofessional and invite spam calls and SMS messages.</p>
                            <p>It also can be difficult to separate a personal mobile from a business mobile and many people do not want to carry two mobile devices.</p>
                            <p>There are mobile phones that contain dual sim cards, allowing two mobile numbers on one device but this is not an ideal system.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Why do virtual landlines exist?</h2>
                            <p>Virtual landline numbers were primarily created for several different reasons but fundamentally a virtual landline service exists to provide more features and flexibility to businesses.</p>
                            <p>These features can be added as the business grows and most virtual landlines are VoIP based allowing for a great deal of scalability.</p>
                            <p>The main drivers for a virtual landline are its use as a "follow me" number. These numbers divert calls or use a call-forwarding system. These can be either "Simultaneous", "In sequence" or "load-balanced" in order to minimise missed calls.</p>
                            <p>Another main use is to be free from a fixed-line or landline area code. Typically, landline telephone numbers are allocated based on an area code. With virtual or VoIP numbers, you're free to choose your number type.</p>
                            <p>Some divert examples:</p>
                            <ol>
                                <li>When customers call a virtual landline, the virtual landline providers system may call the business owners landline phone number first, then after 30 seconds, dial a mobile and so on with possible varying calls times in between. This is known as a sequential call configuration.</li>
                                <li>A sales team may have several salespeople all using mobile phones, so in this instance, a virtual landline number could be set up to load balance a client’s telephone calls between all members of the sales team. This ensures that the potential sales are evenly distributed and is known as a load-balanced configuration.</li>
                                <li>An owner of a customer service team may set a group of business lines to ring simultaneously in order to reduce a customers waiting time. This is known as a simultaneous configuration.</li>
                            </ol>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">The importance of not missing calls</h2>
                            <p>Virtual landlines reduce missed calls by diverting to multiple numbers and varying different ways and the cost of missing a business call has always been the catalyst for the use of virtual landlines.</p>
                            <p>This still remains true today. If you’ve ever called a new business from an internet search and receive no answer, you'll tend to just ring the next one on the list.</p>
                            <p>With so many companies fighting for business, people are often spoilt for choice and calling an alternative company has no real detrimental impact on potential customers. However, that business at the top of the internet search has paid handsomely to appear at the very top of the results and they paid for that phone call.</p>
                            <p>If that company misses a client’s phone call, they’ve not only had to pay to be there, but they’ve also paid for that "click". By not answering the phone, they’ve not received a return on their advertising spend and in addition, they've also missed out on a client’s potential sale, possible repeat business, any referrals or even a glowing review.</p>
                            <p>A 2021 survey of almost 7,000 businesses revealed that on average, companies miss around 22% of all customers calls. Missing these calls isn’t just affecting new business, this also has a detrimental effect on existing customers too.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Call satisfaction</h2>
                            <p>If an existing customer receives no answer to an important or urgent question, this can be quite infuriating. Over time, faith in that company may decline and they may decide to move their business to a competitor or leave a bad review.</p>
                            <p>All these effects compound, attrition increases and ultimately the bottom line of the business suffers. In this respect, the importance of not missing calls cannot be overstated and this is where virtual landlines offer a low-cost solution that may have a bigger impact on the success of a business than first thought.</p>

                            <h2 className="text-500 pt-50 pb-30 text-center text-darker">Uses for virtual landlines</h2>
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Cost savings</h2>
                            <p>Keeping unnecessary costs down in business is fundamental and virtual landlines can definitely help. Fixed lines can be expensive in terms of their initial set-up and ongoing maintenance, and many businesses who opt for a fixed-line approach use what's called a PBX or (Private Branch Exchange).</p>
                            <p>This PBX is similar to PC and is housed within the business, this then distributes calls to extensions set up on individual phones to receive and make outbound calls. PBX's are quite scalable and come with some good business benefits but they often come with a per month subscription cost and require external support for changes and updates.</p>
                            <p>In comparison, virtual landlines have a low or no set-up cost and a low per month subscription. These require no hardware or phones lines and are often updated regularly. Making changes to the call forwarding, adding and removing numbers or changing users’ extension can be done by way of an online web portal. As this is essentially cloud-based software and there are many providers, competition is rife and costs are always kept to a minimum.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Marketing</h2>
                            <p>Virtual landlines can also be used to track marketing spend across multiple campaigns. unlike a standard telephone number, a virtual phone number can be used on a temporary basis and become essentially "disposable".</p>
                            <p>Customers can therefore use multiple virtual landline numbers within their marketing campaigns and track client calls over a fixed period. This helps to ascertain return on investment relating to inbound calls.</p>
                            <p>Using a unique virtual phone number on a website, radio advertising and social advertising campaigns. can give in-depth insights as phone calls tend to be a difficult metric to track.</p>
                            <p>Luckily, most virtual landline providers give detailed stats on calls and even in-depth campaign reporting for specific marketing projects.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Working from home</h2>
                            <p>Remote working has been on the increase steadily over the last decade, never more so than during the recent pandemic. Covid 19 and the furlough scheme has accelerated the need to work from home and with the advent of high-speed internet and video conferencing, fewer people are subjected to the daily commute to work. Virtual landlines are well placed to ease this migration and the adoption of virtual phones service has grown in direct response.</p>
                            <p>Many virtual landline providers also supply softphones and calling apps with their service. This means that each employees laptop, home PC or even mobile can be used as a fully featured business phone. This makes working from home easier and switching between the home and office becomes a more seamless experience.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Disaster recovery</h2>
                            <p>The reason for having disaster recovery in place is for the business to continue operating to close as normal as possible in the event something should affect it. Clients can never plan for every eventuality – no matter how much they try, but the recent pandemic has proved that it pays to be prepared.</p>
                            <p>Virtual landlines give the ability to migrate teams quickly and with minimum disruption. Whether that's from home or off-site.</p>
                            <p>Having complete and instant control over a phone system also gives the ability to record a custom message informing callers of changes to the business. Some companies even set up an emergency line for their staff to assist with technical help or support during a disaster.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 pt-50 pb-30 text-center text-darker">Other uses for virtual landlines</h2>
                            <p>Virtual landlines can come with many features, some free and some at a cost. I've detailed below a list of the mains features with an explanation so you can decide if that feature is right for your business.</p>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="hello" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Time of day routing</h4>
                                    <div>
                                        <p>This feature allows customers to better manage busy periods, staffing schedules holidays and weekends by automatically routing calls made to a virtual number, on a specific day and time.</p>
                                        <p>For example, a team might take regular breaks and have regular appointments throughout the week, or just want different people to handle customers calls at different times and on different days. This is where the time of day redirects fit in.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Open and close times</h4>
                                    <div>
                                        <p>All businesses have their own open and closing times and with this feature, they can set opening hours to suit.</p>
                                        <p>When customers call a virtual landline number that is closed, they will hear an Out of Hours message. For example:</p>
                                        <p>"Our offices are closed, could you please call us during our normal business hours, or to leave us a voicemail please press 1 on your phone's keypad and record after the tone."</p>
                                    </div>
                                </div>
                                <div className="col-xs-2 pt-40">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="openclosetimes" svgWidth="60px" svgClassName="mt-20" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="queue-calls" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Inbound call queues and position announcement</h4>
                                    <div>
                                        <p>If a business has limited lines or it's difficult to manage call peaks? they can queue incoming calls and put them through as soon as a line becomes free, meanwhile, callers are entertained with on-hold music together with a position announcement.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Multiple Departments</h4>
                                    <div>
                                        <p>In multiple department mode or "auto-attendant", callers are provided with a selection of options. These auto-attendant options are set up to ensure callers are routed to the most appropriate person or department.</p>
                                        <p>For example, when callers call your virtual number they might hear: "Press one for sales or press two for support".</p>
                                        <p>With auto-attendant, a company can usually configure up to 9 separate departments and each department has its own independent settings, for example, a sales department might have different opening times, redirect phone numbers, different hold music to the customer service department.</p>
                                    </div>
                                </div>
                                <div className="col-xs-2 pt-40">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="departments" svgWidth="60px" svgClassName="mt-20" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="call-groups" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Call Teams and groups</h4>
                                    <div>
                                        <p>Call groups can be set up to route callers through to different teams of people. This is perfect for priority callers, sales & support teams, separate offices or remote and home workers. This gives the flexibility to optimise teams to suit that business.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Call screening</h4>
                                    <div>
                                        <p>Call screening can be sometimes be referred to as call whisper. This is a brief recorded introduction set up at the start of a call to let the person answering the call know which telephone number or business was called. This can be especially helpful with multiple businesses or if calls are redirected to a home landline number, so whoever picks up the line can answer with the correct greeting.</p>
                                        <p>You may hear: "You have a sales call, press the star key to accept the call or press 3 to send the caller to voicemail"</p>
                                        <p>This feature has another often overlooked attribute as it can be used to circumvent voicemails often with a command to press a key to connect the call.</p>
                                        <p>If that whisper is played to a machine playing a voicemail message and the key is never pressed, the call flips to the next divert or redirect, this increases the likelihood of that call is being answered by a human and not a machine.</p>
                                    </div>
                                </div>
                                <div className="col-xs-2 pt-40">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="call-screening" svgWidth="60px" svgClassName="mt-20" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="custom-prompts" svgWidth="40px" svgClassName="mt-20" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Custom prompts and voices</h4>
                                    <div>
                                        <p>These are simple greetings that can be recorded by the company owner or professionally recorded and assigned to the virtual landline number. These are often managed via a call management portal and used for introductions, departmental prompts or as an out-of-hours message.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">On-hold music</h4>
                                    <div>
                                        <p>When someone calls a virtual landline number, their customer experience can be vastly improved with on-hold music. On-hold music can be vital to creating the right impression but it can also be quite polarising so choose wisely.</p>
                                    </div>
                                </div>
                                <div className="col-xs-2 pt-10">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="music-on-hold" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-5">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="missed-call-alerts" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Missed call alerts</h4>
                                    <div>
                                        <p>These are usually email or SMS based real-time alerts whenever a call is missed. These contain the caller's number together with the date and time of the call.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Voicemail</h4>
                                    <div>
                                        <p>Voicemail works in much the same way as you'd expect, except these are emailed in an MP3 or WAV format. Some services will transcribe the voicemail in real-time also.</p>
                                    </div>
                                </div>
                                <div className="col-xs-2 pt-10">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="voicemail" svgWidth="60px" svgClassName="mt-20" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="contact-book" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Contact Book</h4>
                                    <div>
                                        <p>Some services allow the upload of contacts from a mobile phone so names can be assigned to numbers that call you regularly. This is usually managed using an online portal or call management suite. These can then be synced with softphones and shared with other users.</p>
                                        <p>Favourite callers can often be given priority even if there is a queue whilst unfavourable customers can have their numbers barred.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Call answering</h4>
                                    <div>
                                        <p>Call answering services can often be very cost-effective in comparison to employing someone. This is where a team of agents will answer a company's calls which is great to cope with busy periods, holidays or just used as an overflow from the existing team if a business grows.</p>
                                        <p>Call details are sent over in an email or via an app or online portal. It usually takes a couple of weeks in order for the remote agents to refine the service but the more calls the service takes the better they become.</p>
                                    </div>
                                </div>
                                <div className="col-xs-2 pt-40">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="call-answering" svgWidth="60px" svgClassName="mt-20" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="call-recording" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Call recording</h4>
                                    <div>
                                        <p>Call recording might be an obvious necessity for every business but call recording is something that once used is normally cherished. This is a small addition to a business that can help to resolve conflicts, staff training and assist with compliance.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Call reporting</h4>
                                    <div>
                                        <p>Good call reporting gives you in-depth statistics on all answered calls, missed calls and voicemails such as time and date of the call and hold times. These come with fantastic features such as smart callback lists of people that haven't been spoken to and reports to identify business periods for more efficient staffing.</p>
                                    </div>
                                </div>
                                <div className="col-xs-2 pt-10">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="reporting-features" svgWidth="60px" svgClassName="mt-20" />
                                    </div>
                                </div>
                            </div>

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 pt-50 pb-30 text-center text-darker">What is a virtual number?</h2>
                            <p>A virtual number is just the advertised or presented number chosen for a business or project. Unlike a non-virtual landline number, a company decides on the type of number that suits its business regardless of location.</p>
                            <p>Businesses can decide on number types such as a London number like 0207 to make it appear that they have a central London office or a non-geographical number like 0333.</p>
                            <p>Some companies advertise local numbers on their targeted advertising, as this often increase calls from the local community, or choose a free phone number like 0800 which is great for support lines.</p>
                            <p>There are many different benefits to choose a certain number type or multiple number types for that matter.</p>
                            <p>Below I explain what these numbers are used and how they can be applied to any business.</p>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block-cta bg-light"><span className="icon icon-location2 text-secondary"></span></div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Local numbers (01 / 02)</h4>
                                    <div>
                                        <p>If a business operates in specific local areas then a local number is a great way to give its a presence in any large town or city. This can increase calls by 22% from that area. They can also use multiple numbers to target a wider area or even make their business appear larger.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block-cta bg-light"><span className="icon icon-gherkin text-secondary"></span></div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">London numbers (0207 / 0203)</h4>
                                    <div>
                                        <p>London numbers are just like local numbers for central and the wider London area. These numbers are recognised the world over and can give a business the prestige of operating from an exclusive London location.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block-cta bg-light"><span className="icon icon-0800 text-secondary"></span></div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Free to call or "free phone" numbers (0800 / 0808)</h4>
                                    <div>
                                        <p>Calls made to 0800 or 0808 numbers either from a landline or mobile phone are completely free. This makes them ideal to be used in all kinds of advertising. In the past, free-to-call numbers have been proven to improve the number of calls received but with today's unlimited mobile call plans, these are becoming less relevant.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block-cta bg-light"><span className="icon icon-phone2 text-secondary"></span></div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Non-geographical numbers (0333 / 084 / 085)</h4>
                                    <div>
                                        <p>A relative newcomer to the non-geographical phone market is the 0333 number. These are called at the same call rate as a local number and are often described as "mobile-friendly" for this reason. 0333 numbers are mainly used for National call lines such as e-commerce businesses are increasing in popularity as the general public are becoming more used to seeing them.</p>
                                        <p>084 and 085 numbers are a different breed entirely. These numbers were used quite prolifically in the past and were more expensive to call. The additional revenues generated were often used to cover the cost of the service so many 084 numbers were given away as a free service to businesses. The service provider would then receive a partial payment for every minute used, essentially paying for the service.</p>
                                        <p>084 numbers are no longer allowed to be used for customer service lines are their use is declining in favour of the new 0333 numbers.</p>
                                    </div>
                                </div>
                            </div>

                            <PageGetStartedCta history={props.history} smallText="Find your perfect phone number" mainColouredText="Get started" mainText="with SwitchboardFREE today" />

                            <h2 className="text-500 pt-50 pb-30 text-center text-darker">FAQ’s</h2>
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What other terms are virtual landlines know as?</h2>
                            <p>Virtual landlines can also be called: a virtual landline, a virtual landline number, a virtual phone, virtual numbers, virtual call management or VoIP number.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Can a company choose any number for their virtual landline?</h2>
                            <p>Almost. Virtual landline providers get allocated sets of each number type and businesses can choose from a selection. Within these telephone numbers, they often have what's termed as "golden numbers" or "memorable numbers" but these often incur additional charges.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Are virtual landline numbers the same as VoIP numbers?</h2>
                            <p>They can be either but typically most virtual landline numbers are VoIP (voice over internet protocol) based. VoIP offers a greater range of features and with advances in 5g and the internet and VoIP is set to surpass standard landline phones.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">How do I receive calls on my virtual landline number?</h2>
                            <p>Companies receive incoming calls in much the same way as they would receive calls on their normal telephones. Inbound calls are forwarded or redirected to a home phone, business phone or mobile phone using existing numbers.</p>
                            <p>Virtual numbers can direct any incoming call to any phone including VoIP phones. As long as the call divert correctly, you should be receiving calls.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Can I make calls out using my virtual landline?</h2>
                            <p>Most virtual landline providers have the ability to make and receive calls using your virtual numbers. Outbound dialling typically using a VoIP app that routes calls via your internet connection. This is great if you want to make or receive calls anywhere in the world.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">If I dial out using my virtual number from my mobile app, will it show my mobile number?</h2>
                            <p>If you have more than one number, you should be able to select which number you present when you dial out. This will therefore hide your mobile number; you only display your mobile number when you dial out using your mobile carrier.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">How do I make changes to my call redirect settings?</h2>
                            <p>You can change your number setting via your call management system. This is usually a web page or app where you can access the features of your number or numbers to make changes. This management system changes depending on the provider you use and most providers can help you get started.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Can I try the service first?</h2>
                            <p>Most providers offer a free or contract-free option to customers. This is where you don&apos;t have to commit to a per month charge until you finish the free trial period. During this period, you may be limited to the telephone numbers that you're allowed.</p>
                            <p>The free period can have limited features or services and you may be restricted to receiving calls only. After this period, you can either close your account or sign up for either a rolling or longer-term contract.</p>
                            <p>Most providers have a rolling one-month contract aimed at small businesses so you are free of any long term commitment.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">I have an existing VoIP phone system, can port my number to a new provider?</h2>
                            <p>Yes, most virtual landline or VoIP phone telecom providers that are registered in England, or England and Wales have a porting system or agreement in place. This allows you to port your number from a previous telecom supplier.</p>
                            <p>There are many benefits to porting your existing number but you'll need to get in touch with your telecom provider or check your contract to ensure this option is available.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(VirtualLandLine)
