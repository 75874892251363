import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchHelper } from '../../helpers/fetchHelper'
import LoadingNotification from '../LoadingNotification'

class MyTeams extends React.Component {
  state = {
    isLoading: true
  }

  constructor (props) {
    super(props)
  }

  handleFetchTeams () {
    // Get teams
    const context = this
    const url = 'api/Dashboard/GetTeams'
    fetchHelper.getJson(url)
      .then(response => {
        const data = response.data
        context.setState({ teams: data, isLoading: false })
      }).catch(err => console.error('Error', err))
  }

  componentDidMount () {
    this.handleFetchTeams()
  }

  render () {
    return (
      this.state.isLoading
        ? <LoadingNotification loadingText={'Loading your teams'} isLoading={this.state.isLoading} textColor="text-dark"
                    width={100} height={100} />
        : <>
                    {this.state.alert &&
                        <div className={`mb-20 text-500 text-center alert ${this.state.alert.isError ? 'alert-danger' : 'alert-success'}`}>
                            {this.state.alert.message}
                        </div>
                    }
                    {!(this.state.createTeam || this.state.editTeam) && this.state.teams && this.state.teams.length > 0 &&
                        <>
                            <div className="row mb-30">
                                <div className="col-xs-12 col-md-6 col-sm-5 pt-10">
                                    <h5 className="float-start mr-10 mt-0">My Teams</h5>
                                    <span className="badge bg-cta text-xs text-500">{this.state.teams ? this.state.teams.length : 0}</span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-sm-7 text-end">
                                    <Button variant="primary" size="sm" className="btn-xs-block brad-10">
                                        <i className="icon icon-plus3 text-sm mr-10"></i>
                                        Add new team
                                    </Button>
                                </div>
                            </div>
                            {this.state.teams && this.state.teams.map((team, i) => {
                              return (
                                    <div className="row pl-20 pr-20 text-sm">
                                        <div className="col-xs-12 pt-20 pb-20 bb-1">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-6 pl-0 text-500">
                                                    {team.name}
                                                </div>
                                                <div className="col-xs-6 col-sm-4">
                                                    {team.members} users
                                                </div>
                                                <div className="col-xs-6 col-sm-2 pr-0 text-end">
                                                    <Button variant="transparent" className="p-0 m-0 text-dull" onClick={() => this.handleEditTeam(team.id)}>
                                                        <i className="icon icon-cog3"></i>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                              )
                            })}
                        </>
                    }

                    {this.state.editTeam &&
                        this.state.teamLoading && !this.state.teamToEdit
                      ? <LoadingNotification loadingText={'Loading team'} isLoading={true} textColor="text-dark" width={50} height={50} />
                      : this.state.teamToEdit &&
                            <>
                                <div className="row mb-30">
                                    <div className="col-xs-12 col-md-6 col-sm-5 pt-10">
                                        <h5 className="float-start mr-10 mt-0">Edit team</h5>
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-sm-7 text-end">
                                        <Button variant="outline" size="sm" className="btn-xs-block brad-10 mr-10 outline-dark" onClick={() => this.setState({ editTeam: false, createTeam: false, teamToEdit: undefined })}>
                                            Cancel
                                        </Button>
                                        <Button variant="cta" size="sm" className="btn-xs-block brad-10" onClick={() => this.handleSaveTeam()}>
                                            Done
                                        </Button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <Form.Control
                                            value={this.state.teamToEdit.name}
                                            onChange={e => this.setState({ teamToEdit: { ...this.state.teamToEdit, name: e.target.value } })}
                                        />
                                    </div>
                                </div>
                                <div className="row text-sm mt-30 mb-10">
                                    <div className="col-xs-12 col-sm-6">
                                        Users
                                    </div>
                                    <div className="col-xs-12 col-sm-6 text-end">
                                        <span className="text-primary text-500 cursor-pointer" onClick={() => this.handleAddUserToTeam()}>
                                            Add user
                                        </span>
                                    </div>
                                </div>
                                {this.state.teamToEdit.members.map((item, i) => {
                                  return (
                                        <div className="row pl-20 pr-20 text-sm">
                                            <div className="col-xs-12 pt-20 pb-20 bb-1">
                                                <div className="row text-sm">
                                                    <div className="col-xs-12 col-sm-5 text-500 pl-0">
                                                        {item.description}
                                                    </div>
                                                    <div className="col-xs-6 col-sm-4">
                                                        {item.itemDetail}
                                                    </div>
                                                    <div className="col-xs-6 col-sm-3 pr-0 text-end">
                                                        {item.itemType === 1 &&
                                                            <Button variant="transparent" className="p-0 m-0 text-dull mr-10" onClick={() => this.editTeamMember(item.id, i)}>
                                                                <i className="icon icon-pencil"></i>
                                                            </Button>
                                                        }
                                                        <Button variant="transparent" className="p-0 m-0 text-dull" onClick={() => this.handleDeleteMemberFromTeam(item.id)}>
                                                            <i className="icon icon-bin"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  )
                                })}
                                <div className="text-end mt-20">
                                    <Button variant="danger" size="sm" onClick={() => this.handleDeleteTeam()}>
                                        Delete team
                                    </Button>
                                </div>
                            </>
                    }
                </>
    )
  }

  handleEditTeam (teamId) {
    const context = this
    context.setState({ editTeam: true, teamLoading: true }, () => {
      const url = `api/Dashboard/GetTeamToEdit/${teamId}`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ teamToEdit: res.data, teamLoading: false })
        }).catch(err => console.error(err))
    })
  }

  handleSaveTeam () {
    const context = this
    const url = 'api/Dashboard/EditTeam'
    const data = {
      Id: context.state.teamToEdit.id,
      Members: context.state.teamToEdit.members,
      Name: context.state.teamToEdit.name
    }
    fetchHelper.postJson(url, data)
      .then(res => {
        context.setState({
          isLoading: res.data === true,
          teamToEdit: res.data === true ? undefined : context.state.teamToEdit,
          editTeam: res.data === false,
          createTeam: res.data === false,
          alert: {
            isError: res.data === false,
            message: res.data === true ? 'Your team has been updated.' : 'There was a problem updating your team. Please try again, or contact support.'
          }
        }, () => this.handleFetchTeams())
      }).catch(err => console.error(err))
  }

  handleDeleteMemberFromTeam (id) {
    const context = this
    const url = 'api/Dashboard/RemoveTeamMember'
    const data = {
      MemberId: id,
      TeamId: context.state.teamToEdit.id
    }

    fetchHelper.postJson(url, data)
      .then(res => {
        context.setState({
          teamToEdit: {
            ...context.state.teamToEdit,
            members: context.state.teamToEdit.members.filter(f => f.id !== id)
          },
          alert: {
            isError: res.data === false,
            message: res.data === true ? 'Your team member has been removed.' : 'There was a problem removing this user. Please try again, or contact support.'
          }
        })
      }).catch(err => console.error(err))
  }

  handleDeleteTeam () {
    const context = this
    const url = `api/Dashboard/RemoveTeam/${context.state.teamToEdit.id}`
    fetchHelper.postJson(url)
      .then(res => {
        context.setState({
          teamToEdit: res.data === true ? undefined : context.state.teamToEdit,
          editTeam: res.data === false,
          createTeam: res.data === false,
          alert: {
            isError: res.data === false,
            message: res.data === true ? 'Your team has been removed.' : 'There was a problem removing this team. Please try again, or contact support.'
          },
          teams: context.state.teams.filter(f => f.id !== context.state.teamToEdit.id)
        })
      }).catch(err => console.error(err))
  }
}

export default connect(
  state => {
    return ({
    })
  },
  {
  }
)(MyTeams)
