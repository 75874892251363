import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface AirLandlineProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const AirLandline = (props: AirLandlineProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Air Landline vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Air Landline vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Air Landline vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Initial perception</h2>
                            <p>If you’ve ever seen Air Landline’s <a href="https://www.youtube.com/@airlandline6944" target="_blank" rel="noreferrer">adverts</a>, you‘d know they are designed to be impactful, humorous and attention-grabbing. These ads do what they set out to achieve because I found myself clicking through to their website to see what the product was all about.</p>
                            <p>My initial impression was good. A clean and simple-looking, no-fluff-website with a straightforward call-to-action at the forefront, and a glowing five-star rating from Trustpilot.</p>
                            <p>Reading through them, Air Landline has some impressive reviews on Trustpilot. If you couple that with the low upfront cost of only £9.99 per month, they become an attractive option. Add to that their non-obligation seven-day free trial and you’re left feeling you have nothing to lose.</p>
                            <p>I decided to give the seven-day free trial a go and found the signup process to be quick and simple with minimal information required to get started.</p>
                            <p>After sign-up I discovered that there wasn’t an option to choose your own number, only the area code. Once you’ve selected your geographical area or non-geographical number, a random number with said area code is emailed to you.  </p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Basic and straightforward</h2>
                            <p>Once I had my new phone number and changed my password, I logged into the Air Landline online portal.</p>
                            <p>From my experience, the portal looked like a ‘white-label’ or ‘off-the-shelf’ telecoms website. I’m not saying that all websites should be highly-designed masterpieces, but the black-and-white portal was so basic that some of the key features were displayed as simple hyperlinks, buried within the text on the site. In addition, emojis replaced icons for some of the fundamental operations.</p>
                            <p>As the text became increasingly difficult to read, my overall confidence in the company as a whole started to wane. After all, how can I entrust my business calls to a company that seems incapable of providing a usable interface where simple input boxes fail to line up with the feature they’re intending to activate? </p>
                            <p>As I continued working through each of the sections of the number configuration, I found that the choice of only four on-hold music options was limited and the audio quality could have been better too.</p>
                            <p>When testing the line, the stock call whisper message was a deep male voice. I was then surprised to hear a female voice informing me of the options to choose from. The lack of voice continuity completely threw me off! </p>
                            <p>I was disappointed that there was no option to automatically refresh for recent calls. Having to refresh and remember to refresh manually to see any new missed calls or changes was painfully frustrating.</p>
                            <p>Overall, the web portal felt unfinished and too basic. It was apparent that no real thought had been put into making it a useable and pleasant experience. I felt it was a shame as their ad videos looked like they were filmed professionally. </p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">So, what’s on offer?</h2>
                            <p>Air landlines’ main offering is their advertised £9.99 package. Sadly you don’t get much for your tenner. You're provided with a simple inbound divert system (landline and mobile) with a recorded calls feature. </p>
                            <p>To be fair, they present a long list of other slightly tenuous features that are included, but as I read through it, I could only focus on what they didn’t include.</p>
                            <p><b>For example:</b></p>
                            <ul className="fancy cross-list text-400 pl-30 pb-20">
                                <li className="mb-5">No option for sequential calling of numbers. This means that all redirects will ring at the same time.</li>
                                <li className="mb-5">No departments (press one for this and two for that), unless you pay an additional £4.99 a month.</li>
                                <li className="mb-5">No time-of-day redirects/time-of-day routing.</li>
                                <li className="mb-5">No user teams or ring groups.</li>
                                <li className="mb-5">No admin or phone apps are included unless you pay an additional £7.99 a month.</li>
                                <li className="mb-5">No choice of stock recordings for the intro and welcome messages.</li>
                                <li className="mb-5">Very limited range of poor quality hold music.</li>
                                <li className="mb-5">No contact book.</li>
                                <li className="mb-5">No Google analytics or webhook integrations.</li>
                                <li className="mb-5">No ability for multiple numbers under one account. Every new number is another £9.99 a month and a separate account.</li>
                            </ul>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Price comparison</h2>
                            <p>Even if you’re only looking for a simple divert for your business number to a mobile phone via an app or pc via softphone, Air Landline costs are far from competitive. Comparatively, SwitchboardFREE provides the aforementioned for only £5 per month,  and it includes all the missing features listed above as not provided by Air Landline.</p>
                            <p>As for add-ons, you can have one thousand minutes a month diverted to a UK landline or mobile* for only £4 extra. That’s a total of £9 which is still cheaper than Air Landline.</p>
                            <p>When you make an equal comparison, Air Landline would have to add both the <i>app</i> add-on and the <i>department</i> add-on, bringing their total to £22.97 a month for what is essentially unlimited calls in and out.</p>
                            <p>The team at SwitchboardFREE offers you unlimited calls, in and out, for just £16 a month, plus you get all of what is classed as ‘fundamental’ features such as time-of-day routing and teams (as listed above) for free. And, if you opt for the annual or bi-annual payment you get you a free desk phone as well.</p>
                            <p>Where SwitchboardFREE really stands out, is their ability to tailor your business number to ensure your caller not only gets a rememberable and professional experience but also maximises the chance of that call being answered in the first place. And that’s what having a call-handling service is for, right?</p>
                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Conclusion</h2>
                            <p>From the outset, Air Landline adverts, although humorous, often use risky sexual references. I’m all for using humour within marketing, however, there is a line that if crossed may alienate your target demographic and I feel that Air Landline crossed that line.</p>
                            <p>The seemingly low upfront cost is obviously appealing, but adding essentials such as departments and an app ramps up the cost considerably, especially when you consider their lack of fundamental features.</p>
                            <p>For me, it also seems strange that they have a relatively small number of reviews, all of which are at least four stars. When you consider that realistically you can’t please all the people all of the time, I suspect that (although I can’t prove it) bad reviews are being removed. Even the best companies in the world get the odd bad review.</p>
                            <p>If you’re considering using Air Landline for your VoIP service then I advise that you contact the friendly UK-based welcome team at SwitchboardFREE via their <a href="/contact/">contact page</a>. I know they will more than happy to give you a like-for-like comparison.</p>

                            <p>*If you didn’t want to use the app or the softphone</p>
                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Air Landlines current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://airlandline.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>
                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(AirLandline)
