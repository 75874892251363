import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { RequestAuthorizationCode } from 'react-oauth2-auth-code-flow'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { fetchHelper } from '../../helpers/fetchHelper'
import OauthHelper from '../../helpers/OauthHelper'
import { isLoggedIn } from '../../selectors'
import { updateUserFromLocalStorage } from '../../store/SBF'
import DisplayMainFooter from '../DisplayMainFooter'
import DisplayMainHeader from '../DisplayMainHeader'
import TextBannerWidget from '../Reviews/TextBannerWidget'
import ScrollToTopOnMount from '../ScrollToTopOnMount'
import { getCustomerCount } from '../../helpers/generic'

class PipedriveCrm extends Component {
  state = {
      totalCustomers: 0
  }

  render () {
    const oauthClient = OauthHelper.GetPipeDriveCrmOauthClient()

    return (
            <>
                <ScrollToTopOnMount />
                <DisplayMainFooter />
                <DisplayMainHeader />
                <Helmet>
                    <title>Pipedrive CRM Integration with SwitchboardFREE</title>
                    <meta name="description" content="Automatically synchronise your Pipedrive CRM database with SwitchboardFREE for a more connected phone system." />
                </Helmet>
                <header className="full-width full-hero-medium circle-image full-secondary pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 pb-sm-60">
                                        <h1 className={`${this.props.isLoggedIn === true ? 'text-lightest pt-40 pt-md-30 pt-sm-20 mt-0 text-xs-500' : 'text-lightest pt-80 pt-md-30 pt-sm-20 mt-0 text-xs-500'}`}>
                                            Pipedrive CRM Integration
                                        </h1>
                                        <h2 className="subtitle pt-20">
                                        Automatically synchronise your Pipedrive CRM database with SwitchboardFREE for a more connected phone system.
                                        </h2>
                                        {this.props.isLoggedIn === true
                                          ? <>
                                                 <RequestAuthorizationCode
                                                    oauthClient={oauthClient}
                                                    render={({ url }) => <> <a href={url} className="btn btn-lg btn-cta mb-20">Connect to Pipedrive</a> </>
                                                }/>
                                            </>
                                          : <></>
                                            }
                                    </div>
                                    <div className="d-none d-md-block col-md-6 float-end">
                                        <img
                                            src="/images/campaigns/pipedrive-header.png"
                                            alt="Pipedrive CRM"
                                            style={{ maxWidth: '100%' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="full-width full-dull text-xs">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <TextBannerWidget textDark={true} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full-width bg-dull pb-100">
                    <div className="container pt-100 pt-sm-30">
                        <div className="row pl-30 pr-30 pl-sm-5 pr-sm-5">
                            <div className="col-6 d-none d-md-block text-center">
                                <img src="/images/contact-book-preview-pipedrive.jpg" className="img-responsive" />
                            </div>

                            <div className="col-6 bg-light brad-30 pt-50 pb-30 pl-50 pr-50">

                                <h2 className="text-500 mt-0 pt-0 mb-30 h3">New and FREE Pipedrive CRM integration</h2>
                                <p className="text-500">
                                    Our Pipedrive CRM integration automatically updates your Pipedrive contacts in your Contact book, App, softphone and desk phones whilst simultaneously logging all of your call activity in the Pipedrive CRM.
                                </p>
                                <p className="mt-30">
                                    This becomes even more powerful with the addition of A.I. Caller Greeting™ by greeting your callers using their name and also alerting you audibly to your callers' name before you accept the call.
                                </p>
                                <ul className="fancy cta pl-25 text-400 mt-30">
                                    <li className="pb-20">
                                        All of your contacts are quickly and easily accessible across all your devices
                                    </li>
                                    <li className="pb-20">
                                        Received calls, missed calls and voicemails are all fed directly into your Pipedrive CRM system
                                    </li>
                                    <li className="pb-20">
                                        A.I. Caller Greeting™. Greets your callers by their name held in Pipedrive.
                                    </li>
                                    <li className="pb-20">
                                        Be alerted to your callers' name before you accept a call
                                    </li>
                                    <li className="pb-20">
                                        Automatically link notes from Pipedrive to your calls recordings
                                    </li>
                                    <li className="pb-20">
                                        When you answer a call, your caller's Pipedrive page instantly opens in your browser
                                    </li>
                                    <li className="pb-20">
                                        Simple integration
                                    </li>
                                </ul>

                                {this.props.isLoggedIn === true
                                  ? <>
                                        <h5 className="text-500 pt-0">Setup your Pipedrive CRM sync today</h5>
                                        <RequestAuthorizationCode
                                            oauthClient={oauthClient}
                                            render={({ url }) => <> <a href={url} className="btn btn-lg btn-cta mt-20">Connect to Pipedrive</a> </>
                                        }/>
                                    </>
                                  : <>
                                        <h5 className="text-500 pt-0">Create an account and link it to Pipedrive today</h5>
                                        <Link to={'/get-started'} className="btn btn-lg btn-cta mt-30">Create Account</Link>
                                        <Link to={'/login'} className="text-sm text-dark underline mt-30 block text-400">Or, login if you already have an account</Link>.
                                    </>
                                    }

                            </div>

                        </div>
                    </div>
                </div>

                <div className="full-width bg-light pb-100 pt-100 pt-sm-30">
                    <div className="container">
                        <div className="row pl-30 pr-30 pl-sm-5 pr-sm-5">
                            <div className="col-md-6 text-center">
                                <img src="/images/pipedrive-switchboardfree-integration.png" className="img-responsive w-sm-600px" alt="What's Pipedrive CRM?" />
                            </div>

                            <div className="col-md-6 pl-80 pl-sm-25 pt-100 pt-sm-30">
                                <h3 className="text-500 mt-0 pt-0 mb-10">About Pipedrive</h3>
                                <p>
                                Pipedrive is the easy-to-use, #1 user-rated CRM tool. Get more qualified leads and grow your business. <a href='https://www.pipedrive.com/' target="_blank" rel="nofollow noopener noreferrer" className="text-cta underline" >Visit Pipedrive</a>.
                                </p>

                                <h5 className="text-500 mt-40 pt-0 mb-10">How does SwitchboardFREE integrate with Pipedrive?</h5>
                                <p>
                                    Synchronising your Pipedrive account, couldn’t be easier. Just click on the “Sync contacts” button in your SwitchboardFREE Contact Book and ensure you’re also signed into your Pipedrive CRM in the same browser.
                                    Then just authorise the Pipedrive, and SwitchboardFREE will open a connection and import your contacts.
                                </p>
                                <div className="b-1 text-center p-30 brad-30 box-shadow mt-30 w-100">

                                    {this.props.isLoggedIn === true
                                      ? <>
                                        <h5 className="text-500 pt-0">Setup your Pipedrive CRM sync today</h5>
                                        <RequestAuthorizationCode
                                            oauthClient={oauthClient}
                                            render={({ url }) => <> <a href={url} className="btn btn-lg btn-cta mt-20">Connect to Pipedrive</a> </>
                                        }/>
                                    </>
                                      : <>
                                        <h5 className="text-500 pt-0">Create an account and link it to Pipedrive today</h5>
                                        <Link to={'/get-started'} className="btn btn-lg btn-cta mt-30">Create Account</Link>
                                        <Link to={'/login'} className="text-sm text-dark underline mt-30 block text-400">Or, login if you already have an account</Link>.
                                    </>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="full-width bg-dull pt-30 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="w-1200px mx-auto row">
                                <div className="col-md-6 pr-50 pr-sm-25 pt-60">
                                    <h4 className="text-500 mt-0 pt-0 mb-30">What other features does SwitchboardFREE offer?</h4>
                                    <p>
                                        SwitchboardFREE’s VoIP and telephony services help you to create a more professional image, have a national or local presence, manage calls more effectively or track the effectiveness of your marketing, all with the built-in benefit of disaster recovery, we have the answer!
                                    </p>
                                    <p className="pt-10">
                                        With over {this.state.totalCustomers} businesses and homeworkers registered already you can be confident that you’re in safe hands.
                                    </p>
                                    <p className="pt-20">
                                        <Link to={'/features'} className="text-cta underline">See our free features</Link>
                                    </p>
                                </div>
                                <div className="col-md-6 text-center">
                                    <img src="/images/features.png" className="img-responsive" alt="SwitchboardFREE call management features" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full-width bg-dark pt-60 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="text-500 text-lightest">SwitchboardFREE</h3>
                                <h6 className="text-400">The UK's favourite call management system</h6>
                                <p className="text-400 pt-20 pb-20">Instant activation  <span className="pl-10 pr-10 text-cta">•</span>  No long-term contracts  <span className="pl-10 pr-10 text-cta">•</span>  Award-winning UK support</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
  }

  async getCustomers () {
    const data = await getCustomerCount()

    this.setState({ totalCustomers:  data})
  }

  componentDidMount () {
    this.getCustomers()

    if (this.props.isLoggedIn === true) {
      fetchHelper.getJson('api/contacts/GetCrmContactBookSyncSettings').then(res => this.setState({ syncSettings: res.data }))
    }
  }
}

export default withRouter(connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  }, { updateUserFromLocalStorage }
)(PipedriveCrm))
