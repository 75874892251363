import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import DisplaySimpleFooter from '../../components/DisplaySimpleFooter'
import DisplaySimpleHeader from '../../components/DisplaySimpleHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../helpers/fetchHelper'
const classNames = require('classnames')

const PasswordUI = (props) => {
  const validations = props.rules.map((rule) => rule(props.password))

  const isValid = validations.reduce(function (acc, val) {
    return acc && val
  }, true)

  return <ul className="password-check">{React.Children.map(props.children, (child, i) => {
    const btnClass = classNames(child.props.className, {
      'text-success': validations[i],
      'text-danger': !validations[i] && props.triggeredValidation
    })
    return React.cloneElement(child, { className: btnClass })
  })}</ul>
}

class ResetPassword extends React.Component {
  state = {
    expiredKey: false,
    invalidKey: false
  }

  schema = this.props.match.params.key
    ? yup.object().shape({
      newPassword: yup.string().test('has-valid-password', 'Please enter a valid password', v => new RegExp(/[0-9]/).test(v) && new RegExp(/[!#@$%^&*)(+=._-]/).test(v) && v.length >= 12),
      confirm: yup.string().required('Confirm Password is required').oneOf([yup.ref('newPassword')], "Passwords don't match")
    })
    : yup.object().shape({
      email: yup.string().email().required('Email is required'),
      oldPassword: yup.string().required('Please provide your old password'),
      newPassword: yup.string().test('has-valid-password', 'Please enter a valid password', v => new RegExp(/[0-9]/).test(v) && new RegExp(/[!#@$%^&*)(+=._-]/).test(v) && v.length >= 12),
      confirm: yup.string().required('Confirm Password is required').oneOf([yup.ref('newPassword')], "Passwords don't match")
    })

  getErrorsFromValidationError (validationError) {
    const FIRST_ERROR = 0
    return validationError.inner.reduce(function (errors, error) {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      }
    }, {})
  }

  constructor (props) {
    super(props)

    this.renderExpiredKeyDisplay = this.renderExpiredKeyDisplay.bind(this)
    this.renderInvalidKeyDisplay = this.renderInvalidKeyDisplay.bind(this)
    this.renderChangePasswordDisplay = this.renderChangePasswordDisplay.bind(this)
    this.requestNewKey = this.requestNewKey.bind(this)
  }

  hasKey = Boolean(this.props.match.params.key)
  email = this.props.match.params.email ? this.props.match.params.email.substr(5) : ''

  render () {
    return <div><ScrollToTopOnMount /><DisplaySimpleFooter /><DisplaySimpleHeader />
            <Helmet>
                <body className={'body-simple '} />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/features-bg.jpg')" }}
                className="full-width full-hero-small">
            </header>
            {this.state.finished && <>
                <div className="container-styled pb-100">
                    <div className="row">
                        <div className="col-md-12 text-center pb-100">
                            <h1>Password updated</h1>
                            <p className="lead">You can now log in to your account using your new details.</p>
                            <Link to={'/login'} className="btn btn-cta btn-lg mt-30 mb-100">Log In</Link>
                        </div>
                    </div>
                </div>
            </>
            }

            <div className="container-styled">

                {this.state.invalidKey === true &&
                    this.renderInvalidKeyDisplay()
                }

                {this.state.expiredKey === true &&
                    this.renderExpiredKeyDisplay()
                }

                {!this.state.finished && this.state.invalidKey === false && this.state.expiredKey === false &&
                    this.renderChangePasswordDisplay()
                }
            </div>
        </div>
  }

  renderInvalidKeyDisplay () {
    return <>
        <p> The key you provided is invalid, please try again with a different key or request one be sent</p>
        </>
  }

  renderExpiredKeyDisplay () {
    return <>
            <p> The key you provided has expired, click the button below to get a new one</p>
            <Button onClick={ () => this.requestNewKey()}> Request New Key </Button>
        </>
  }

  renderChangePasswordDisplay () {
    return <>
            <div className="b-2 brad-20 pl-20 pr-20 pt-30 pb-30 box-shadow mb-100" style={{ margin: '0 auto', width: '360px', maxWidth: '100%' }}>
                <div className="text-center">
                    <p className="pt-0 mt-0 pb-30 text-500 text-md">Change password</p>
                </div>
                {this.hasKey === false && !this.email && <>
                    <label>Email:</label>
                    <input type='email' name='email' autoComplete='username' className="form-control" onBlur={event => {
                      try {
                        yup.reach(this.schema, 'email').validateSync(event.target.value)
                        this.setState({ errors: { ...this.state.errors, email: '' } })
                        return {}
                      } catch (error) {
                        this.setState({ errors: { ...this.state.errors, email: error.message } })
                      }
                    }} onChange={event => this.setState({ email: event.target.value })} />

                    {this.state.errors && this.state.errors.email &&
                        <p className="text-danger text-xs text-400">{this.state.errors.email}</p>}
                </>
                }

                {this.hasKey === false && <>
                    <label className="pt-20">Old Password:</label>
                    <input className="form-control" type='password' autoComplete='current-password' name='current-password' onBlur={event => {
                      try {
                        yup.reach(this.schema, 'oldPassword').validateSync(event.target.value)
                        this.setState({ errors: { ...this.state.errors, oldPassword: '' } })
                        return {}
                      } catch (error) {
                        this.setState({ errors: { ...this.state.errors, oldPassword: error.message } })
                      }
                    }} onChange={event => this.setState({ oldPassword: event.target.value })} />

                    {this.state.errors && this.state.errors.oldPassword &&
                        <p className="text-danger text-xs text-400">{this.state.errors.oldPassword}</p>}

                    {this.getForgottenPasswordButton()}
                </>}

                <label>New password:</label>
                <input className="form-control" type='password' autoComplete="new-password" name="new-password" onFocus={() => this.setState({ triggeredValidation: true })} onBlur={event => {
                  try {
                    yup.reach(this.schema, 'newPassword').validateSync(event.target.value)
                    this.setState({ errors: { ...this.state.errors, newPassword: '' } })
                    return {}
                  } catch (error) {
                    this.setState({ errors: { ...this.state.errors, newPassword: error.message } })
                  }
                }} onChange={event => this.setState({ newPassword: event.target.value })}
                />
                <div className="col-md-12 p-0">
                    <div className="bg-dull text-center p-10 brad-5">
                        <p className="p-0 mt-0 mb-5 text-xs text-400">Password must contain:</p>
                        <PasswordUI
                            ref="pwValidate"
                            password={this.state.newPassword}
                            triggeredValidation={this.state.triggeredValidation}
                            rules={[v => new RegExp(/[0-9]/).test(v), v => v && v.length >= 12, v => new RegExp(/[A-Z]/).test(v), v => new RegExp(/[!#@$%^&*)(+=._-]/).test(v)]}>
                            <li>
                                <i className="icon icon-question2"></i>
                                <i className="icon icon-checkmark-circle is-valid hidden"></i>
                                <i className="icon icon-cancel-circle2 not-valid"></i>
                                Contains a number
                                                    </li>
                            <li>
                                <i className="icon icon-question2"></i>
                                <i className="icon icon-checkmark-circle is-valid hidden"></i>
                                <i className="icon icon-cancel-circle2 not-valid"></i>
                                At least 12 characters
                                                    </li>
                            <li>
                                <i className="icon icon-question2"></i>
                                <i className="icon icon-checkmark-circle is-valid hidden"></i>
                                <i className="icon icon-cancel-circle2 not-valid"></i>
                                An uppercase character
                                                    </li>
                            <li>
                                <i className="icon icon-question2"></i>
                                <i className="icon icon-checkmark-circle is-valid hidden"></i>
                                <i className="icon icon-cancel-circle2 not-valid"></i>
                                A special character
                                                    </li>
                        </PasswordUI>
                    </div>
                </div>

                {this.state.errors && this.state.errors.newPassword &&
                    <p className="text-danger text-xs text-400">{this.state.errors.newPassword}</p>
                }

                <label className="pt-20">Confirm password:</label>
                <input className="form-control" autoComplete="new-password" name="new-password" type='password' onBlur={event => {
                  try {
                    this.schema.validateSyncAt('confirm', this.state)
                    this.setState({ errors: { ...this.state.errors, confirm: '' } })
                    return {}
                  } catch (error) {
                    this.setState({ errors: { ...this.state.errors, confirm: error.message } })
                  }
                }} onChange={event => this.setState({ confirm: event.target.value })}
                />

                {this.state.errors && this.state.errors.confirm &&
                    <p className="text-danger text-xs text-400">{this.state.errors.confirm}</p>}
                <div className="text-center pt-30">
                    <input type='button' className="btn btn-cta btn-lg btn-block" value={'Change Password'} onClick={() => this.attemptChangePassword()} />
                </div>
                {this.state.error &&
                    <p className={'pt-20 text-danger'}>We were unable to change your password, please check and try again. <a href="/forgot-password" className="text-cta">Click here if you've fogotten your password?</a></p>}
            </div>
    </>
  }

  requestNewKey () {
    const url = '/api/Users/RequestNewKeyBeSent/' + this.props.match.params.key

    fetchHelper.postJson(url).then(res => {
      if (res.data.newKeySent === true) {
        alert('pass')
      } else {
        if (res.data.keyValid === true) {
          alert('error thrown while trying to send email')
        } else {
          alert('invalid key provided')
        }
      }
    }).catch(error => alert('error 3: ' + JSON.stringify(error)))
  }

  getForgottenPasswordButton () {
    return <div>
            <p><a href="/forgot-password" className="text-xs text-cta">Fogotten your password?</a></p>
            <p className="pb-30 pt-30 text-center text-500 text-md">Set a new password</p>
        </div>
  }

  attemptChangePassword () {
    const context = this

    this.setState({ error: false, email: context.state.email || context.email }, () => {
      if (context.hasKey) {
        try {
          context.schema.validateSync(this.state, { abortEarly: false })
          context.setState({ errors: undefined }, () => {
            const key = context.props.match.params.key
            const newPassword = context.state.newPassword
            const url = '/api/Users/ResetPassword'

            fetchHelper.postJson(url, { key, newPassword }).then(res => {
              if (res.data) {
                context.setState({ error: false, finished: true })
              } else {
                context.setState({ error: true })
              }
            }).catch(function (error) {
              context.setState({ error: true })
            })
          })
        } catch (error) {
          const errorsFromValidationError = this.getErrorsFromValidationError(error)
          this.setState({ errors: errorsFromValidationError })
        }
      } else {
        try {
          this.schema.validateSync(this.state, { abortEarly: false })
          this.setState({ errors: undefined }, () => {
            const newPassword = context.state.newPassword
            const email = context.state.email || context.email
            const oldPassword = context.state.oldPassword
            const url = '/api/Users/ResetPassword'

            fetchHelper.postJson(url, { oldPassword, email, newPassword }).then(res => {
              if (res.data) {
                context.setState({ error: false, finished: true })
              } else {
                context.setState({ error: true })
              }
            }).catch(function (error) {
              context.setState({ error: true })
            })
          })
        } catch (error) {
          const errorsFromValidationError = this.getErrorsFromValidationError(error)
          this.setState({ errors: errorsFromValidationError })
        }
      }
    })
  }

  componentDidMount () {
    document.title = 'Enter New Password'
    const url = '/api/Users/IsPasswordRequestLinkValid/' + this.props.match.params.key

    fetchHelper.getJson(url).then(res => {
      if (res.data !== true) {
        // Invalid Key
        this.setState({
          invalidKey: true
        })
      } else {
        const url = '/api/Users/IsPasswordRequestLinkExpired/' + this.props.match.params.key

        fetchHelper.getJson(url).then(res => {
          if (res.data !== true) {
            // expired key
            this.setState({
              expiredKey: true
            })
          }
        }).catch(error => alert('error 1: ' + JSON.stringify(error)))
      }
    }).catch(error => alert('error 2: ' + JSON.stringify(error)))
  }
}

export default ResetPassword
