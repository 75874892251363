import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { handleNotificationChange } from '../../../store/SBF'

class CompanyDetails extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    size: 0,
    position: 0,
    industry: 0
  }

  componentDidMount () {
    this.setState
    ({
      size: this.props.selectedCompanySize.value,
      position: this.props.selectedCompanyPosition.value,
      industry: this.props.selectedIndustry.value
    })
  }

  handleSubmitPressed () {
    const url = '/api/Profile/SaveContactDetails'

    const values = {

      Size: this.state.size,
      Position: this.state.position,
      Industry: this.state.industry
    }

    fetchHelper.postJson(url, values).then(res => {
      const data = res.data

      if (data.hasErrors) {
        this.props.notify({
          message: 'Unable to save, please correct and try again',
          isErrorState: true
        })
      } else {
        this.props.notify({ message: 'Details saved' })
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  render () {
    return (
            <>
                <h4 className="text-center mb-30 text-400">Company details</h4>
                <div className="row">
                    <div className="col-md-4 col-sm-4 pb-sm-10">
                        <label> Type of business</label>
                        <select name='industry' className="form-control" onChange={(e) => this.setState({ industry: e.target.value })}>
                            {this.props.industry && this.props.industry.map((industryOption) => <option
                                selected={this.state.industry === industryOption.value}
                                value={industryOption.value}>{industryOption.label}</option>)}
                        </select>
                    </div>
                    <div className="col-md-4 col-sm-4 pb-sm-10">
                        <label>Your position</label>
                        <select name='position' className="form-control" onChange={(e) => this.setState({ position: e.target.value })}>
                            {this.props.position && this.props.position.map((positionOption) => <option
                                selected={this.state.position === positionOption.value}
                                value={positionOption.value}>{positionOption.label}</option>)}
                        </select>
                    </div>
                    <div className="col-md-4 col-sm-4 pb-sm-10">
                        <label>Company size</label>
                        <select name='size' className="form-control" onChange={(e) => this.setState({ size: e.target.value })}>
                            {this.props.companySize && this.props.companySize.map((companySizeOption) =>
                                <option value={companySizeOption.value}
                                    selected={this.state.size === companySizeOption.value}>{companySizeOption.label}</option>)}
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-12 text-center pt-30">
                        <Button variant="cta" disabled={!this.props.companySize} type='submit' size="sm" onClick={() => this.handleSubmitPressed()}>Save
                            Details</Button>
                    </div>
                </div>
            </>
    )
  }
}

export default connect(state => {
}, { notify: handleNotificationChange })(CompanyDetails)
