import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AboutMenu from '../components/AboutMenu'
import ClientLogos from '../components/ClientLogos'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import Testimonials from '../components/Testimonials'

class TestimonialsPage extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader /><AboutMenu />
            <Helmet>
                <title>Reviews - SwitchboardFREE customers</title>
                <meta name="description" content="Read customer reviews and testimonials, see how SwitchboardFREE could work for you." />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/testimonials-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1 id="TestimonialsH1">Testimonials</h1>
                <h2 className="subtitle text-center">See what other businesses are saying about our SwitchboardFREE Virtual Phone Numbers. </h2>
            </header>
            <div className="container-styled bg-dull">
                <div className="container">
                    <div className="col-md-10 col-sm-12 mx-auto customer-reviews">
                        <Testimonials />
                    </div>
                </div>
                <div className="full-width full-secondary">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center pt-20">
                                <h4>Still not convinced that this is the best virtual phone number service for your business?</h4>
                                <Link to={'/case-studies'} className="btn btn-md btn-cta mt-20 mb-20"> View case studies</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ClientLogos style='light' />
        </>
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(TestimonialsPage)
