import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import TeamManagement from '../components/Dashboard/TeamManagement'
import DisplayLoggedInFooter from '../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import RefreshUserServices from '../components/RefreshUserServices'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { Modal } from 'react-bootstrap'

class CustomerTeamsSettings extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    isLoading: true,
    showUsersAndTeamsVideo: false
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayLoggedInFooter /><DisplayMainHeader /><RefreshUserServices />
            <Helmet>
                <title>My Teams</title>
                <meta name="description" content="Outbound call settings" />
            </Helmet>

            <header className="full-width my-calls-header">
                <h1 id="MyTeamsH1">My Teams</h1>
                <h2 className="subtitle">Group your users into teams so they can all receive calls at the same time. <span className='cursor-pointer underline' onClick={() => this.setState({ showUsersAndTeamsVideo: true })}>See video</span></h2>
            </header>

            <div className="container pt-30">
                <div className="center-block w-1200px mb-30 brad-30 pl-xs-0 pr-xs-0">
                        <TeamManagement createTeam={this.props.match.params.createteam} />
                </div>
            </div>

            <Modal className='w-900px' show={this.state.showUsersAndTeamsVideo} onHide={e => this.setState({ showUsersAndTeamsVideo: false })}>
                <Modal.Header closeButton className='bb-0' />
                <Modal.Body className="text-center">
                <div className="ratio ratio-16x9 pt-20">
                    <iframe src="https://www.youtube.com/embed/5EEAqlWXHws" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>
                </Modal.Body>
            </Modal>
        </>
  }
}

export default connect(
  state => {
    return {
    }
  },
  {
  }
)(CustomerTeamsSettings)
