import { type AxiosResponse } from 'axios'
import { isSet } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import DisplaySimpleHeaderV2 from '../../components/DisplaySimpleHeaderV2'
import LoadingNotification from '../../components/LoadingNotification'
import { PaymentProviders } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getQueryStringFromProps } from '../../helpers/utils'

declare global {
  interface Window {
    dataLayer: Array<Record<string, any>>
    gtag: any
    ga: any
  }
}

interface PaymentSetupState {
  cancelled: boolean
  directDebit: boolean
  creditCard: boolean
  intervalId?: NodeJS.Timeout | undefined
  canProgress: boolean
};

interface PaymentSetupProps {
  isLoggedIn: boolean
  userDetails: any
};

const PaymentSetup: React.FunctionComponent<PaymentSetupProps> = (
  props: PaymentSetupProps
): JSX.Element => {
  const initialState: PaymentSetupState = {
    cancelled: false,
    directDebit: false,
    creditCard: false,
    canProgress: false
  }

  const [ticking, setTicking] = useState(true)
  const [count, setCount] = useState(0)
  const [state, setState] = React.useState<PaymentSetupState>(initialState)

  useEffect(() => {
    setTimeout(() => { ticking && setCount(count + 1) }, 1e3)
    if (state.canProgress) {
      handleRedirect(false)
    }

    const queryString = getQueryStringFromProps(props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('cancel=true')) {
        setState({ ...state, cancelled: true })
      } else {
        if (count === 0) { handleContinue() }
      }
    } else {
      if (count === 0) { handleContinue() }
    }
  }, [count, ticking])

  function handleRedirect (paymentFailed) {
    const route = LocalStorageHelper.getItem<string>('returnRoute')?.value

    if ((route !== null && route !== undefined) && !route.includes('signup/checkout/complete')) {
      if (paymentFailed === true) {
        // @ts-expect-error
        window.location = `${JSON.parse(route)}&paymentFailed=true`
      } else {
        // @ts-expect-error
				window.location = `${JSON.parse(route)}&paymentComplete=true`;
      }
    } else {
      // @ts-expect-error
      window.location = '/customer/number-setup-wizard'
    }
  }

  function handleContinue () {
    let gcId = ''
    let paymentIntent = ''
    let isSetup = false

    const query = new URLSearchParams(window.location.search)
    if (query.get('redirect_flow_id')) {
      gcId = query.get('redirect_flow_id')!
    } else if (query.get('payment_intent')) {
      paymentIntent = query.get('payment_intent')!
    } else if (query.get('setup_intent')) {
      paymentIntent = query.get('setup_intent')!
      isSetup = true
    }

    const clientId = LocalStorageHelper.getItem<number>('clientId')?.value ?? 0
    if (!state.canProgress) {
      if (gcId !== '' || paymentIntent !== '') {
        let paymentId = ''
        let optionalParam = ''
        let providerId = PaymentProviders.GoCardless
        if (gcId === '') {
          paymentId = paymentIntent
          optionalParam = query.get('payment_intent_client_secret') ?? ''

          if (optionalParam === '') { optionalParam = query.get('setup_intent_client_secret') ?? '' }

          providerId = PaymentProviders.Stripe
        } else {
          paymentId = gcId
        }

        if (providerId === PaymentProviders.GoCardless) {
          // We need to poll til we receive the GC webhook
          setInterval(() => {
            verifySetup(clientId, providerId, paymentId, optionalParam, isSetup)
          }, 7500)
        } else {
          // No need to poll here
          verifySetup(clientId, providerId, paymentId, optionalParam, isSetup)
        }

      } else {
        // PaymentSuccess alread triggered
        setState({ ...state, canProgress: true })
      }
    }
  }

  function verifySetup(clientId: number, providerId: PaymentProviders, paymentId: string, optionalParam: string, isSetup: boolean) {
    fetchHelper
      .getJson(`api/Purchase/VerifySetup/${clientId}/${providerId}/${paymentId}/${optionalParam}/${isSetup}`)
      .then((res: AxiosResponse) => {
        LocalStorageHelper.removeItem('BasketHardware')
        localStorage.removeItem('stripeSecret')

        setTicking(true)
        if (res.status === 200 && res.data === true) {
          setState({ ...state, canProgress: true })
        } else {
          if (providerId = PaymentProviders.Stripe) {
            handleRedirect(true)
          }
        }
      })
      .catch(err => { console.error(err) })
  }

  function renderComponent () {
    const isNewCustomer = LocalStorageHelper.getItem('isNewCustomer')?.value

    return (
			<>
				<DisplaySimpleHeaderV2 />
				<Helmet>
					<body className={'body-simple bg-dull body-signup pb-0 '.concat(LocalStorageHelper.getItem<boolean>('isBeta') ? 'beta-body' : '')} />
					<title>Instant Signup</title>
				</Helmet>
				<div className="container mt-0 mb-50">
					<div className="row">
						<div className="col-lg-6 col-md-8 mx-auto mx-auto bg-light box-shadow shadow-lighter mt-50 text-center p-40 brad-10">
							{state.cancelled
							  ? (
								<>
									<p className="lead text-500">Your payment was cancelled</p>
									<p>
										Before we can add your new products &amp; numbers to your account, please add your billing details via{' '}
										<Link to="/customer/profile/billing" className="underline">
											your profile page
										</Link>
									</p>
									<p className="text-center mt-20">
										<Link to="/customer/profile/billing" className="btn btn-cta btn-md">
											Add Billing Details
										</Link>
									</p>
								</>
							    )
							  : (isNewCustomer === true)
							      ? (
								<>
									<p className="lead text-500">Congratulations &amp; Welcome to SwitchboardFREE</p>
									<p>
										You've succesfully created your {state.directDebit ? 'Direct Debit' : state.creditCard ? 'Credit/Debit Card' : 'Payment'} Agreement with us.<br />Please wait while we finalise your account setup. <span className="text-500">This may take up-to 60 seconds</span>, please do not close this window or press the back button.
									</p>

									<LoadingNotification className="mt-50" message={'Just setting you up...'} isLoading={true} textColor="text-dullest" textSize="sm" />
								</>
							        )
							      : (
								<>
									<p className="lead text-500">Thanks for your payment.</p>
									<p>We're just processing your payment. <span className="text-500">This may take up-to 60 seconds</span>, please do not close this window or press the back button.</p>

									<LoadingNotification className="mt-50" message={'Processing your payment...'} isLoading={true} textColor="text-dullest" textSize="sm" />
								</>
							        )}
						</div>
					</div>
				</div>
			</>
    )
  }

  return renderComponent()
}

export default PaymentSetup
