import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const CallFilteringSectionHelperDialog = props => (
    <Modal show={props.isDisplayed}
           onHide={props.hideCallFilteringHelperInstruction}>
        <Modal.Header closeButton>
            <Modal.Title>Call Filtering</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                Call blocking is a premium feature which allows you to block people from calling your number from either
                a witheld number or from abroad.
            </p>

            <p>
                Both types of call blocking can be set independently so you can block withheld
                callers <b>or</b> international callers <b>or</b> both.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideCallFilteringHelperInstruction}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.callFilteringInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallFilteringSectionHelperDialog)
