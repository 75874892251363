import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { handleNotificationChange } from '../../../store/SBF'

class AddressDetails extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    addressLine1: '',
    addressLine1Valid: true,
    addressLine2: '',
    addressLine2Valid: true,
    addressLine3: '',
    addressLine3Valid: true,
    addressLine4: '',
    addressLine4Valid: true,
    companyName: '',
    companyNameValid: true,
    postcode: '',
    postcodeValid: true
  }

  componentDidMount () {
    this.handleAddressLine1Changed(this.props.add1)
    this.handleAddressLine2Changed(this.props.add2)
    this.handleAddressLine3Changed(this.props.add3)
    this.handleAddressLine4Changed(this.props.add4)
    this.handleCompanyNameChanged(this.props.client)
    this.handlePostcodeChanged(this.props.pcode)
  }

  handleAddressLine1Changed (value) {
    const isValid = true

    this.setState
    ({
      addressLine1: value,
      addressLine1Valid: isValid
    })
  }

  handleAddressLine2Changed (value) {
    const isValid = true

    this.setState
    ({
      addressLine2: value,
      addressLine2Valid: isValid
    })
  }

  handleAddressLine3Changed (value) {
    const isValid = true

    this.setState
    ({
      addressLine3: value,
      addressLine3Valid: isValid
    })
  }

  handleAddressLine4Changed (value) {
    const isValid = true

    this.setState
    ({
      addressLine4: value,
      addressLine4Valid: isValid
    })
  }

  handleCompanyNameChanged (value) {
    let isValid = true
    
    if(value == ''){
      isValid = false
    }

    this.setState
    ({
      companyName: value,
      companyNameValid: isValid
    })
  }

  handlePostcodeChanged (value) {
    const isValid = true

    this.setState
    ({
      postcode: value,
      postcodeValid: isValid
    })
  }

  handleSaveAddressPressed () {
    const url = '/api/Profile/SaveAddress'

    const data = {
      Add1: this.state.addressLine1,
      Add2: this.state.addressLine2,
      Add3: this.state.addressLine3,
      Add4: this.state.addressLine4,
      Pcode: this.state.postcode,
      Client: this.state.companyName
    }

    fetchHelper.postJson(url, data).then(res => {
      const data = res.data

      if (data.hasErrors === true) {
        this.props.notify({
          message: data.errors[0],
          isError: true
        })
      }

      if (data.hasErrors) {
        this.props.notify({
          message: 'Unable to save, please correct and try again',
          isError: true
        })
      } else {
        this.props.notify({ message: 'Address saved' })
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  render () {
    const invalidBorderStyling = '2px solid #e84118'

    return (
            <>
                <h4 className="text-center mb-30 text-400">Address details</h4>
                <div className="row">
                    <div className="col-sm-6 col-md-6">
                        <label>Address line 1</label>
                        <input
                            type="text"
                            name="add1"
                            maxLength={30}
                            className="form-control"
                            placeholder="eg. Address line 1"
                            value={this.state.addressLine1}
                            onChange={(e) => this.handleAddressLine1Changed(e.target.value)}
                            style={{ border: this.state.addressLine1Valid === true ? '' : invalidBorderStyling }}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <label>Address line 2</label>
                        <input type="text"
                            name="add2"
                            maxLength={30}
                            className="form-control"
                            placeholder="eg. Address line 2"
                            value={this.state.addressLine2}
                            onChange={(e) => this.handleAddressLine2Changed(e.target.value)}
                            style={{ border: this.state.addressLine2Valid === true ? '' : invalidBorderStyling }}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <label>Address line 3</label>
                        <input type="text"
                            name="add3"
                            maxLength={30}
                            className="form-control"
                            placeholder="eg. Address line 3"
                            value={this.state.addressLine3}
                            onChange={(e) => this.handleAddressLine3Changed(e.target.value)}
                            style={{ border: this.state.addressLine3Valid === true ? '' : invalidBorderStyling }}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <label>Address line 4</label>
                        <input type="text"
                            name="add4"
                            maxLength={30}
                            className="form-control"
                            placeholder="eg. Address line 4"
                            value={this.state.addressLine4}
                            onChange={(e) => this.handleAddressLine4Changed(e.target.value)}
                            style={{ border: this.state.addressLine4Valid === true ? '' : invalidBorderStyling }}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <label>Company name</label>
                        <input type="text"
                            name="client"
                            maxLength={32}
                            className="form-control"
                            placeholder="eg. Smith Ltd."
                            value={this.state.companyName}
                            onChange={(e) => this.handleCompanyNameChanged(e.target.value)}
                            style={{ border: this.state.companyNameValid === true ? '' : invalidBorderStyling }}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <label>Postcode</label>
                        <input type="text"
                            name="pcode"
                            maxLength={8}
                            className="form-control"
                            placeholder="eg. NR32 3LQ"
                            value={this.state.postcode}
                            onChange={(e) => this.handlePostcodeChanged(e.target.value)}
                            style={{ border: this.state.postcodeValid === true ? '' : invalidBorderStyling }}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 text-center pt-30">
                        <Button variant="cta" type='submit' size="sm" onClick={() => this.handleSaveAddressPressed()}>Save Address Details</Button>
                    </div>
                </div>
            </>
    )
  }
}

export default connect(state => { }, { notify: handleNotificationChange })(AddressDetails)
