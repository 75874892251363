import React from 'react'

export default class CallRecordingBenefits extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <div className="call-recording-benefits">
               <div className="row-flex flex-3-col flex-sm-2-col ">
                        <div className="col-xs-12 col-sm-12 col-md-4 pb-0 pb-xs-30">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h3 className="text-md text-500 d-inline"><span className="icon icon-checkbox-checked text-success pr-10"></span>Resolve conflicts</h3>
                                    <p className="pt-5 text-sm pl-30 mb-0">By being able to review and share the call recording with your customer, it can help stop disputes and complaints.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 pb-0 pb-xs-30">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h3 className="text-md text-500 d-inline"><span className="icon icon-checkbox-checked text-success pr-10"></span>Capture every detail</h3>
                                    <p className="pt-5 text-sm pl-30 mb-0">Forgot what was said? Just listen to the recording and verify the information exchanged.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 pb-0 pb-xs-30">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h3 className="text-md text-500 d-inline"><span className="icon icon-checkbox-checked text-success pr-10"></span>For legal records</h3>
                                    <p className="pt-5 text-sm pl-30 mb-0">Secure yourself against possible legal complications. <br className="d-none d-lg-block"/>
                                    Having a call recording is like having an eye witness.</p>
                                </div>
                            </div>
                        </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 pb-0 pb-xs-30">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h3 className="text-md text-500 d-inline"><span className="icon icon-checkbox-checked text-success pr-10"></span>Staff training</h3>
                                    <p className="pt-5 text-sm pl-30 mb-0">
                                    Help new staff quickly understand what is expected of them. Use recordings as a motivational trigger.
                                    </p>
                                </div>
                            </div>
                        </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 pb-0 pb-xs-30">
                        <div className="row">
                            <div className="col-xs-12">
                                <h3 className="text-md text-500 d-inline"><span className="icon icon-checkbox-checked text-success pr-10"></span>Monitor staff performance</h3>
                                <p className="pt-5 text-sm pl-30 mb-0">Besides using call recording to train your new members, <br className="d-none d-md-block"/>use it to monitor everyone’s performance.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 pb-0 pb-xs-30">
                        <div className="row">
                            <div className="col-xs-12">
                                <h3 className="text-md text-500 d-inline"><span className="icon icon-checkbox-checked text-success pr-10"></span>Compliance</h3>
                                <p className="pt-5 text-sm pl-30 mb-0">Many industries require call recording, storage and retrieval for a specific amount of time. Don&apos;t risk getting fined.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 pb-0 pb-xs-30">
                        <div className="row">
                            <div className="col-xs-12">
                                <h3 className="text-md text-500 d-inline"><span className="icon icon-checkbox-checked text-success pr-10"></span>Clinch the sale</h3>
                                <p className="pt-5 text-sm pl-30 mb-0">Learn what works best and really sells to your customers. Ensure your next call is slick!</p>
                            </div>
                        </div>
                    </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 pb-0 pb-xs-30">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h3 className="text-md text-500 d-inline"><span className="icon icon-checkbox-checked text-success pr-10"></span>Improve customers experience</h3>
                                    <p className="pt-5 text-sm pl-30 mb-0">Expectations are continually changing. Being able to listen to sales calls allows you to
                                    improve conversion rates and examine wording that actually works.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-4 pb-0 pb-xs-30">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h3 className="text-md text-500 d-inline"><span className="icon icon-checkbox-checked text-success pr-10"></span>Go back in time</h3>
                                    <p className="pt-5 text-sm pl-30 mb-0">Download the recording for your own records, play it at any time you want.</p>
                                </div>
                            </div>
                        </div>

                    </div>

        </div>
  }
}
