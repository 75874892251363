import React, { Component } from 'react'

export default class CarouselWidget extends Component {
  render () {
    return (
            <div id="carousel-widget"></div>
    )
  }

  componentDidMount () {
    // create widget code
    if (window && window.richSnippetReviewsWidgets) {
      const widgetInit = document.createElement('script')
      widgetInit.type = 'text/javascript'
      widgetInit.async = true
      if (this.props.light) {
        if (this.props.small) {
          widgetInit.innerHTML = "richSnippetReviewsWidgets('carousel-widget', {" +
                    "store: 'switchboard-free'," +
                    "primaryClr: '#f47e27'," +
                    "neutralClr: '#cccccc'," +
                    "reviewTextClr: '#333333'," +
                    "widgetName: 'carousel'," +
                    "layout: 'fullWidth'," +
                    'numReviews: 40,' +
                    "contentMode: 'company;third-party'," +
                    'hideDates: true, ' +
                    "css:'.CarouselWidget .reviewsContainer .reviewWrap .reviewText p { color:#fff; } .reviewSource { display: none; } .CarouselWidget { padding-bottom: 0; } .CarouselWidget .reviewsContainer .reviewWrap .reviewHeader .author { color: #fff } .CarouselWidget .reviewsContainer .reviewWrap { height: auto; } .fullWidth.CarouselWidget .reviewsContainer { margin-bottom: 0; } .fullWidth.CarouselWidget .cw__header { display: none; }' });"
        } else {
          widgetInit.innerHTML = "richSnippetReviewsWidgets('carousel-widget', {" +
                    "store: 'switchboard-free'," +
                    "primaryClr: '#f47e27'," +
                    "neutralClr: '#cccccc'," +
                    "reviewTextClr: '#333333'," +
                    "widgetName: 'carousel'," +
                    "layout: 'fullWidth'," +
                    'numReviews: 40,' +
                    "contentMode: 'company;third-party'," +
                    'hideDates: true, ' +
                    "css:'.CarouselWidget .reviewsContainer .reviewWrap .reviewText p { color:#fff; } .reviewSource { display: none; } .CarouselWidget { padding-bottom: 0; } .CarouselWidget .reviewsContainer .reviewWrap .reviewHeader .author { color: #fff } .CarouselWidget .reviewsContainer .reviewWrap { height: auto; } .fullWidth.CarouselWidget .reviewsContainer { margin-bottom: 0; } ' });"
        }
      } else {
        if (this.props.small) {
          widgetInit.innerHTML = "richSnippetReviewsWidgets('carousel-widget', {" +
                    "store: 'switchboard-free'," +
                    "primaryClr: '#f47e27'," +
                    "neutralClr: '#cccccc'," +
                    "reviewTextClr: '#333333'," +
                    "widgetName: 'carousel'," +
                    "layout: 'fullWidth'," +
                    'numReviews: 40,' +
                    "contentMode: 'company;third-party'," +
                    'hideDates: true,' +
                    "css:'.fullWidth.CarouselWidget .cw__header { display: none; }' });"
        } else {
          widgetInit.innerHTML = "richSnippetReviewsWidgets('carousel-widget', {" +
                    "store: 'switchboard-free'," +
                    "primaryClr: '#f47e27'," +
                    "neutralClr: '#cccccc'," +
                    "reviewTextClr: '#333333'," +
                    "widgetName: 'carousel'," +
                    "layout: 'fullWidth'," +
                    'numReviews: 40,' +
                    "contentMode: 'company;third-party'," +
                    'hideDates: true });'
        }
      }

      // append to body
      document.body.appendChild(widgetInit)
    }
  }
}
