import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux'

class SoftphoneManualText extends React.Component {
  constructor (props) {
    super(props)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false
    }
  }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow () {
    this.setState({ show: true })
  }

  render () {
    return <>

                    <Tabs className="tabs-container justify-content-center" defaultActiveKey={1}>
                        {/* <Tab eventKey={1} title="Video instructions">
                            <div className=" bt-1 pt-50 pb-30 mt-40">
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/0SJLd0xNji8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>

                        </Tab> */}
                        <Tab eventKey={1} >
                            <p className="">
                                1. <a href="https://www.switchboardfree.co.uk/files/switchboardFREE.zip" className="text-cta underline text-500">Click here</a> to download the SwitchboardFREE VoIP Softphone (.exe file)
                            </p>

                            <p className="mt-30">
                                2.	Once you download the file, double click on it to begin the installation process.
                            </p>

                            <p className="mt-30">
                                3. You may get the below warning, click <span className="text-500">&quot;More Info&quot;</span> and then at the bottom click <span className="text-500">&quot;Run anyway&quot;</span>
                                <br/>
                                <img src="/images/softphone/1.PNG" className="pt-30 pb-30 brad-10 box-shadow img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                                <br/>
                                <img src="/images/softphone/2.PNG" className="pt-30 pb-30 brad-10 box-shadow img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                            </p>

                            <p className="mt-30">
                                4.	Continue through with the installation steps and click <span className="text-500">Finish</span>.
                                <br/>
                                <img src="/images/softphone/3.PNG" className="pt-30 pb-30 brad-10 box-shadow img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                                <br/>
                                <img src="/images/softphone/4.PNG" className="pt-30 pb-30 brad-10 box-shadow img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                            </p>

                            <p className="mt-30">
                                5.	You will require your SwitchboardFREE outbound settings for the next step to find these follow the instructions below.
                            </p>
                            <p className="pl-20">
                                a.	<a href="/login">Login to your SwitchboardFREE</a> account. Once logged in, from the top navigation click <span className="text-500">&quot;My Users&quot;</span> and then <span className="text-500">&quot;List Users&quot;</span>.
                            </p>
                            <div className="mb-30">
                                <img src="/images/softphone/nav.jpg" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                            </div>
                            <p className="pl-20">
                                b.	Click the highlighted <span className="text-500">&quot;eye icon&quot;</span> on the user you would like the details for.
                            </p>
                            <div className="mb-30">
                                <img src="/images/softphone/users.jpg" className="p-30 brad-10 box-shadow text-center b-2 img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                            </div>

                            <p className="pl-20">
                                c.	You will now see your SwitchboardFREE user details
                            </p>
                            <div className="mb-30">
                                {/* <img src="/images/bria/b12.jpg" className="p-30 brad-10 box-shadow text-center b-2" /> */}
                                <img src="/images/bria/s2.jpg" className="p-30 brad-10 box-shadow text-center b-2 img-responsive img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                            </div>
                            <p className="mt-30">
                                6.	Enter the following details and click <span className="text-500">&quot;Save&quot;</span>
                            </p>
                            <p className="pl-20">
                                a.	Phone username: That's your ID, followed by a &quot;.&quot; (fullstop) and then your domain name.
                                <br/>
                                In our example above our ID is number &quot;5232&quot;, and the domain name is the word &quot;london&quot;, so our username will look like this: <span className="text-500">5232.london</span>
                                <br/><br/>
                                b.	Password: In our example i&apos;s <span className="text-500">#GreenSwitch781!</span>
                                <br/>
                                <img src="/images/softphone/5.PNG" className="pt-30 pb-30 brad-10 box-shadow img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                            </p>
                            <p className="mt-30">
                                7.	This will now show that you are online at the bottom and will enable you to make calls.
                                <br/>
                                <img src="/images/softphone/5v1.PNG" className="pt-30 pb-30 brad-10 box-shadow img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                            </p>
                            <p className="pt-20">
                                8.	If you have synced your contacts with the <span className="text-500">SwitchboardFREE Phone</span> smartphone app (<a target="_blank" className="text-cta underline" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" rel="noreferrer">iOS</a>, <a className="text-cta underline" target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB" rel="noreferrer">Android</a>) you will be able to find your contacts under the <span className="text-500">Contacts</span> tab.
                                <br/>
                                <img src="/images/softphone/6.PNG" className="pt-30 pb-30 brad-10 box-shadow img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                            </p>
                            {/* <h4 className="text-500 text-cta pt-60">Transferring Calls</h4>
                            <p className="pt-20">
                                1. To start a call transfer, click the transfer button.
                                <br/>
                                <img src="/images/softphone/7.PNG" className="pt-30 pb-30 brad-10 box-shadow" />
                            </p>
                            <p className="pt-20">
                                2. Type in the extension number you would like to transfer to and click <span className="text-500">OK</span>.
                                <br/>
                                <img src="/images/softphone/7v1.PNG" className="pt-30 pb-30 brad-10 box-shadow" />
                            </p> */}
                            <h4 className="text-500 text-cta pt-60">Blind transfer</h4>
                            <p className="pt-20">

                            A blind transfer is when you transfer the caller to another users extension without speaking to them first.
                            </p>
                           <p className="pt-10 text-italic text-500">
                                How to perform a blind transfer?
                           </p>
                            <p className="pt-20">
                                Press the transfer button or press #7, followed by the extension number you wish to transfer the call to.
                                <br/>
                                <img src="/images/softphone/7.PNG" className="pt-30 pb-30 brad-10 box-shadow img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                                <br/>
                                <img src="/images/softphone/7v1.PNG" className="pt-30 pb-30 brad-10 box-shadow img-responsive" style={{ maxWidth: '500px', maxHeight: 'auto' }}/>
                            </p>
                            <p>
                                If a call is not answered by the final recipient in 10 seconds then call will return to the original recipient.
                                </p>

                                <h4 className="text-500 text-cta pt-60 mt-30">An Assisted transfer</h4>

                            <p>
                                    An assisted transfer is when you transfer the caller to another users extension,
                                    but before the call is put through, you will be able to speak to the other user before transfering the caller to them. This is useful to make a polite introduction.
                                </p>
                                <p className="text-500 pt-10 pb-20">
                                    How to perform a assisted transfer on your:<br/>Softphone, Desk phone &amp; SwitchboardFREE Phone App
                                </p>
                                <ol className="text-400 pl-15">
                                    <li className="pb-10">
                                        Press #8, followed by the extension number of the user you wish to dial.
                                    </li>
                                    <li className="pb-10">
                                        You can then speak to that person before connecting the caller to them.

                                    </li>
                                    <li className="pb-10">
                                        Hang up the call to connect the two parties.
                                    </li>
                                </ol>
                                <p>
                                If a call is not answered by the final recipient in 10 seconds then call will return to the original recipient.
                                </p>

                        </Tab>
                    </Tabs>
        </>
  }

  componentDidMount () {
    const tabs = document.getElementsByClassName('nav-tabs')
    tabs[0].classList.add('nav-tabs-centered')
    const wrapper = document.createElement('div')
    wrapper.classList.add('tabs-container')
    tabs[0].parentNode.insertBefore(wrapper, tabs[0])
    wrapper.appendChild(tabs[0])

    //    function openNewWindow(){
    //     window.open("https://www.w3schools.com", "width=200,height=100");
    //     }
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(SoftphoneManualText)
