import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import NumberTypeInfo from '../../components/NumberTypes/NumberTypeInfo'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import StatsBanner from '../../components/StatsBanner'
import { scroller } from 'react-scroll'
import { MultiNumberPrice } from '../../constants/Constants'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'
import { handleShowCallbackForm } from '../../store/SBF'
import { handleAddToBasket, handleBasketInit, handleRemoveFromBasket } from '../../store/Signup'
import PlansAndNumbersDropdown from '../../components/PlansAndNumbersDropdown'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import { withRouter } from 'react-router'


class LandlineOnMobile extends React.Component {
  state = {
    prefix: '0203',
  }

  constructor(props) {
    super(props)
  }

  scrollTo (el) {
    scroller.scrollTo(el, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -50
    })
  }

  render () {
    const basket = getEmptyBasket()
    const numbers = LocalStorageHelper.getItem('LandingPageNumberSelection')
    basket.numbers = numbers.value ?? []

    return <>
            <ScrollToTopOnMount/><DisplayMainFooter/> <DisplayMainHeader/>
            <Helmet>
                <title>Get a Landline on a mobile phone | SwitchboardFREE</title>
				<meta name="description" content="How do I get a landline on my mobile? Is it possible to get a landline number that rings my mobile?" />
                {/* inline style elements */}
                    <style type="text/css">{`
                        .tag {
                            position: absolute;
      
                        }
                        @media screen and (max-width: 1400px){
                            .bg-img {
                                background-position: 100% 100%;
                            }
                        }
                        @media screen and (max-width: 1024px){
                            .bg-img {
                                background-image: none !important;
                            }
                        }
                        // @media screen and (max-width:1100px) {
                        //     .tag {
                        //         left:20%;
                        //     }
                        //     .landline-to-mobile .mx-auto #slideshow.landline-to-mobile .slide img {
                        //         height:auto;
                        //         width:98%;
                        //     }
                            
                        // }
                        @media screen and (max-width:800px) {
                            .tag {
                                left:10%;
                            }
                        }
                        @media screen and (max-width:500px) {
                            .tag {
                                width:130px;
                            }
                        }
                        @media screen and (max-width:360px) {
                            .tag {
                                width:100px;
                                left:3%;
                            }
                        }
                    `}</style>
            </Helmet>
        <header className="full-width full-hero-medium full-secondary pb-0 landline-to-mobile">
        <div className="container ">
            <div className="row">
                <div className="col-lg-10 mx-auto pl-md-30 pr-md-30">
                    <div className="row">
                        <div className="col-sm-12 col-md-5 d-none d-md-block">
                            <h1 className="text-lightest pt-100">Get a landline number straight to your mobile</h1>
                            <h2 className="subtitle pt-20">Choose from our great range of local <br/>or national numbers and give your business that professional look.</h2>
                        </div>
                        <div className="col-sm-12 col-md-7">
                            <img src="/images/headers/price2.png" className="tag" alt="price"/>
                            <div id="slideshow" className="landline-to-mobile">
                                <div className="slide show">
                                    <img src="/images/headers/builder.png" alt="builder" />
                                </div>
                                <div className="slide">
                                    <img src="/images/headers/woman.png" alt="business woman" />
                                </div>
                                <div className="slide">
                                    <img src="/images/headers/baker.png" alt="baker" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 d-block d-md-none bg-secondary pl-30 pr-30">
                            <h1 className="text-lightest pt-30 mt-0">Get a landline number straight to your mobile</h1>
                            <h2 className="subtitle pt-20 pb-30 mb-0">Choose from our great range of local or national numbers and give your business that professional look.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </header>

        <div className="full-width bg-dull pb-70 pt-70 number-page" id="numbers">
				<div className="container numbers-pages pl-sm-0 pr-sm-0">
					<div className="row">
						<div className="col-md-12 text-center pb-60">
							<h2 className="text-500 text-darker">
								Select your perfect numbers
							</h2>
							<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light">
								Divert multiple numbers to your mobile for an additional &pound;{MultiNumberPrice} per number,<br className="d-none d-md-block" />
								or simply choose a single number, included free with your plan <em>(Which you'll choose later)</em>.
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<PlansAndNumbersDropdown
								prefix={this.state.prefix}
								ddiLid={this.state.ddiLid?.value}
								handleChangeNumberType={e => this.setState({ prefix: e })}
								handleChangeAreaCode={e => this.setState({ ddiLid: e })}
								hidePlans={true}
                displayAll={true}

								WrapperClassName="mb-40"
								LabelsClassName="text-xs text-dark text-400"

								NumberButtonClassName={'text-start bg-light pl-25 pr-25 ml-5 b-0 inbound-only'}
								NumberNameClassName="text-dark text-400"
								NumbersResultsDropdownClassName="bg-light ml-5 box-shadow"
								NumberNameClassNameSelected="text-cta text-500"

								AreaCodeButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
								AreaCodeNameClassName="text-dark text-400"
								AreaCodeResultsDropdownClassName="bg-light box-shadow"
								AreaCodeNameClassNameSelected="text-cta text-500"
							/>

							<DdiSelectorNew
								numberType={this.state.prefix}
								isSignup={false}
								currentBasket={basket}
								hideShowAll={false}
								isModal={false}
								hideLoadMore={false}
								hideRegions={true}
								hidestars={false}
								isLoggedIn={false}
								isEditing={false}
								menuOpen={false}
								citysOnly={false}
								ddiLid={this.state.ddiLid?.value}
								handleUpdateBasket={(_, sbfNumber) => {
								  // let item: LocalStorageItem<SbfNumber[]> = LocalStorageHelper.getItem < SbfNumber[] > ("LandingPageNumberSelection");
								  const item = LocalStorageHelper.getItem('LandingPageNumberSelection')
								  let sbfNumbers = item.value ?? []
								  // add the number to the array if it doesn't already exist, otherwise remove it
								  if (sbfNumbers.find(number => number.number === sbfNumber.number)) {
								    sbfNumbers = sbfNumbers.filter(number => number.number !== sbfNumber.number)
								  } else {
								    sbfNumbers.push(sbfNumber)
								  }
								  LocalStorageHelper.setItem('LandingPageNumberSelection', sbfNumbers)
								  this.setState({})
								}}
							/>
						</div>
					</div>
				</div>
			</div>

    <div className="full-width bg-light pt-60" id="start">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center mb-20 mb-xs-0">
                    <h2 className="text-500">We’ve got the perfect number for you</h2>
                    <h4 className="pt-30 text-400">
                        One of the common questions we hear at SwitchboardFREE is <i>“How do I get a landline on a mobile?”</i> <br className="d-none d-md-block"/>or <i>“Is it possible to get a landline number that rings my mobile?”</i>
                    </h4>
                    <h4 className="pt-30 pb-60 text-400">
                        The answer to both these questions is Yes! - and it’s very simple to do.
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 text-center text-md-right">
                    <img src="/images/devices.png" className="img-responsive" style={{ maxHeight: '560px', width: '565px' }}/>
                </div>
                <div className="col-md-6">
                    <p>
                        SwitchboardFREE offers a range of different number types varying from <span className="text-500">local 01/02</span> numbers through to <span className="text-500">0333</span> and <span className="text-500">0800</span> numbers. Each number type has its own unique benefits that can affect the way your customers view your business.
                    </p>
                    <p className="pt-20">
                        Often know as a ‘Pocket Landline’, getting a virtual phone number routing to your mobile is a great solution for anyone who is out on the road and not in one location.
                    </p>
                    <p className="pt-20">
                        You can make outbound phone calls from your mobile phone presenting your landline number to you customers via our app.
                    </p>
                    <p className="pt-20">
                        It also has the added benefit of making your business seem larger and builds more trust with potential clients.
                    </p>
                    <p className="text-500">
                        Numbers start from the low price of £5 a month so it doesn’t have to break the bank either.
                    </p>
                    <Link className="btn btn-lg btn-xxs-block btn-cta mt-30 mb-30" to={'/number-types'}>Get your number</Link>
                </div>

            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-12 mt-50">
                    <div className="row">
                        <div className="col-md-12 bg-dull brad-50 pt-60 pl-100 pb-60 pr-100 pl-xs-20 pr-xs-20">
                            <div className="row">
                                <div className="col-md-12 text-center pb-50">
                                    <h2 className="text-500">
                                        I&apos;s as easy as 1-2-3!
                                    </h2>
                                </div>
                                <div className="col-md-4 text-center pb-sm-30">
                                    <span className="icon-block lg icon-full-secondary">
                                        <span className="icon number">1</span>
                                    </span>
                                    <h5 className="text-500 pt-20 mb-20">Sign up for an account &amp; <br className="d-none d-md-block"/>choose a number</h5>
                                    <p>Choose from a huge range of virtual numbers from any UK town or city, a prestigious London 0203/0207 number, a non-geographic 0333, 0800, 084 or keep your existing number.</p>
                                </div>
                                <div className="col-md-4 text-center pb-sm-30">
                                    <span className="icon-block lg icon-full-secondary">
                                        <span className="icon number">2</span>
                                    </span>
                                    <h5 className="text-500 pt-20 mb-20">Choose the perfect system <br className="d-none d-md-block"/>to suit you</h5>
                                    <p>Choose between inbound call management with a virtual number connected to your existing landline, mobile or phone system. Or, a cloud-based VoIP phone system allowing you to make &amp; receive calls.</p>
                                </div>
                                <div className="col-md-4 text-center">
                                    <span className="icon-block lg icon-full-secondary">
                                        <span className="icon number">3</span>
                                    </span>
                                    <h5 className="text-500 pt-20 mb-20">Start making or <br className="d-none d-md-block"/>receiving calls</h5>
                                    <p>Set up your phone number in minutes and start using your new smart number right away. Speak to our UK based customer service team who guarantee to answer your call with friendly advice or support.</p>
                                </div>
                                <div className="col-md-12 pt-50 pb-30 text-center">
                                    <Link to={'get-started'} className="btn btn-cta btn-lg">
                                        Get Started
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="full-width bg-darker pb-50 pt-0 pb-sm-0 bg-img" id="apps" style={{ backgroundImage: "url('/images/product-shots/mobile-app-hand.png')", backgroundSize: 'initial', backgroundRepeat: 'no-repeat', backgroundPosition: '90% 100%' }}>
        <div className="container">
            <div className="row">
                <div className="col-md-6 pt-100 pt-sm-10 pb-sm-30">
                    <h2 className="text-500 text-lightest mt-0 mt-sm-50 text-center ">Our Mobile Apps</h2>
                    <p className="lead text-lightest text-center ">Be connected to the office even when on the move.</p>
                    <div className="row mt-50">
                        <div className="col-xs-12 col-md-2 text-center text-sm-start">
                            <img src="/images/product-shots/phone-app-icon.png" alt="SwitchboardFREE Phone App" style={{ maxWidth: '100%' }} />
                        </div>
                        <div className="col-xs-12 col-md-10">
                            <p className="lead text-500 text-cta  text-start mt-sm-15">
                                SwitchboardFREE Phone App
                            </p>
                            <p className="mb-30">
                                Ideal for occasional use allowing you to make and receive calls via any of your SwitchboardFREE numbers.
                            </p>
                            <p className="mb-30">
                                Our Phone App is perfect for holiday use and international travel. Some international destination charges are up to £20 per minute to call back to the UK. Avoid unnecessary call charges by making and receiving calls at local rates. This feature alone is guaranteed to save travelers thousands of pounds..
                            </p>
                            <p className="text-400 text-md pb-30 text-left text-md-center">
                                <a target="_blank" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '150px' }} className="mr-20 mr-xs-10 mb-5" /></a>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '150px' }}/></a>
                            </p>
                        </div>
                    </div>
                    <div className="row mt-50">
                        <div className="col-xs-12 col-md-2  text-center text-sm-left">
                            <img src="/images/product-shots/management-app-icon.png" alt="SwitchboardFREE Management App" style={{ maxWidth: '100%' }} />
                        </div>
                        <div className="col-xs-12 col-md-10">
                            <p className="lead text-500 text-cta text-start mt-sm-15">
                                SwitchboardFREE Management App
                            </p>
                            <p className="mb-30">
                                With our SwitchboardFREE management app you have total control over your account, allowing you to manage your inbound calls and configure your number settings, all while on the go. Update your redirect numbers, on hold music or customise your greetings. See all missed calls and listen to voicemails all in one place.
                            </p>
                            <p className="text-400 text-md pb-30  text-center text-sm-left">
                                <a target="_blank" href="https://apps.apple.com/gb/app/sbf-2012/id556878596" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '150px' }} className="mr-20 mr-xs-10 mb-5" /></a>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREE&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '150px' }}/></a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 pt-100 pt-sm-10 pb-sm-0 d-block d-md-none">
                    <img src="/images/product-shots/mobile-app-hand.png" alt="SBF Mobile App" className="img-responsive" />
                </div>
            </div>
        </div>
    </div>

    <div className="full-width full-secondary p-20">
        <div className="row">
            <div className="col-md-12">
                <h3 className="text-center text-lightest mt-10 text-100 text-xl">
                    <StatsBanner />
                </h3>
            </div>
        </div>
    </div>
    <ClientLogos style='light' showText={true} />
    </>
  }

  componentWillMount () {
  }

  componentDidMount () {
    const slides = document.querySelectorAll('#slideshow .slide')
    let currentSlide = 0
    const slideInterval = setInterval(nextSlide, 4000)

    function nextSlide () {
      slides[currentSlide].className = 'slide'
      currentSlide = (currentSlide + 1) % slides.length
      slides[currentSlide].className = 'slide show'
    }
  }
}

export default withRouter(
    connect(
      state => {
        return {
          currentBasket: state.signup.packageInfo.currentBasket
        }
      },
      {
        handleAddToBasket,
        handleRemoveFromBasket,
        handleBasketInit,
        handleShowCallbackForm
      }
    )(LandlineOnMobile)
  )
