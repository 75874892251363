import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import FeaturesList from '../../components/Competitors/FeaturesList'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface VirtuallyLocalProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const VirtuallyLocal = (props: VirtuallyLocalProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Virtually Local vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Virtually Local vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Virtually Local vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What do Virtually Local do?</h2>
                            <p>Virtually Local provide geographic virtual telephone numbers, which may be forwarded to either a landline or a mobile as well as outbound calls using the Virtually local App.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Virtual landline</h2>
                            <p>Virtually Local only seem to offer 0333 and local numbers. In comparison, SwitchboardFREE offers all ranges of virtual numbers including central London and free phone numbers.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Phone Numbers</h2>
                            <p>With Virtually Local, some numbers are free but 1st and 2nd level memorable numbers are expensive, £95.00 and £495.00 respectively. In comparison, SwitchboardFREE's 1st and 2nd level memorable numbers are only an additional £5.00 and £10.00 per month respectively.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Virtual Phone Number</h2>
                            <p>You can choose a brand new virtual number or choose to port an existing number to the Virtually Local platform. Numbers are instantly set up and there doesn't seem to be a limit to the amount of numbers you can have.</p>

                            <p><span className="text-500 text-lg text-dark">Virtually Essential</span> (£3.95 per month)</p>
                            <ul>
                                <li>150 Included Minutes</li>
                                <li>Free number</li>
                                <li>Single Destination</li>
                                <li>Online Management & Searchable Call History</li>
                            </ul>

                            <p><span className="text-500 text-lg text-dark">Virtually Essential+</span> (£5.95 per month)</p>
                            <ul>
                                <li>300 Included Minutes</li>
                                <li>Free number</li>
                                <li>Single Destination</li>
                                <li>Multiple Destinations</li>
                                <li>Online Management & Searchable Call History</li>
                                <li>Voicemail to Email</li>
                            </ul>

                            <p><span className="text-500 text-lg text-dark">Virtually Business</span> (£8.95 per month)</p>
                            <ul>
                                <li>1500 Included Minutes</li>
                                <li>Free number</li>
                                <li>Single Destination</li>
                                <li>Multiple Destinations</li>
                                <li>Online Management & Searchable Call History</li>
                                <li>Voicemail to Email</li>
                                <li>Outbound Calling</li>
                                <li>Time of Day Routing</li>
                                <li>Personalised Greetings</li>
                                <li>Call Recording (30 Days)</li>
                            </ul>

                            <p><span className="text-500 text-lg text-dark">Virtually Business</span> (£8.95 per month)</p>
                            <ul>
                                <li>Unlimited Minutes* (*2500 minutes per month)</li>
                                <li>Free number</li>
                                <li>Single Destination</li>
                                <li>Multiple Destinations</li>
                                <li>Online Management & Searchable Call History</li>
                                <li>Voicemail to Email</li>
                                <li>Outbound Calling</li>
                                <li>Time of Day Routing</li>
                                <li>Personalised Greetings</li>
                                <li>Call Recording (7 Years)</li>
                            </ul>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Virtual landline</h2>
                            <p>SwitchboardFRE packages in our opinion are cheaper have more features than Virtually Local. Virtually Local also restrict core features such as call recording and outbound calling to encourage you to commit to a more expensive package where these options are available across all of our packages.</p>
                            <p>Just as an example, our Unlimited VoIP package costs only £12 per month and comes with:</p>

                            <FeaturesList />

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Things To Consider</h2>
                            <p>Although Virtually Local state that there is no minimum contract length, their terms state "The parties agree that the Contract will run for a minimum period of 3 months from the start date". Also, unless you remain "in Credit", your service will be suspended immediately.</p>
                            <p>SwitchboardFREE has <b>no long-term contracts</b> and you cancel with only 30 days notice. We will also maintain your account without any credit.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Virtually Locals current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.virtuallylocal.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(VirtuallyLocal)
