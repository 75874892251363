import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { type GetInvoicesDto, type Invoice } from '../../../models/ProfileTypes'
import Pager from '../../pager'
const fileDownload = require('js-file-download')

const Invoices = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [invoices, setInvoices] = useState<Invoice[]>()

  const GetInvoices = () => {
    const url = `/api/Profile/GetInvoices/page=${currentPage}`

    fetchHelper.getJson<GetInvoicesDto>(url).then(res => {
      setTotalPages(res.data.totalPages)
      setInvoices(res.data.invoices)
      console.log(res.data)
    })
  }

  const handleDownloadAllInvoices = () => {
    if (invoices !== null && invoices !== undefined) {
      invoices.forEach((invoice) => {
        handleDownloadInvoice(invoice)
      })
    }
  }

  const handleDownloadInvoice = (invoice: Invoice) => {
    const url = `/api/purchase/invoice/${invoice.invoiceId}/pdf`
    const res = fetchHelper.getBlob(url)

    if (res !== null) {
      res.then(res => {
        const formattedDate = invoice.date.replace(/\//g, '')
        fileDownload(res.data, `switchboardfree_${invoice.invoiceId}_${formattedDate}.pdf`)
      }).catch(error => { console.error(error) })
    }
  }

  useEffect(() => {
    GetInvoices()
  }, [currentPage])

  return (
        <>
            <div>
                <div className="bg-light p-30 pb-40 pr-xxs-15 pl-xxs-15 brad-10 b-1 w-100 center-block mb-20" style={{ overflow: 'auto' }}>
                    <div className="col-md-12 text-center">
                        <h4 className="pb-20 text-400">Invoices</h4>
                    </div>

                    {/* Show this div if there are no invoices */}
                    {(invoices === null || invoices === undefined) || invoices.length === 0
                      ? <div className="col-sm-12 col-md-12 pl-0 pr-0 pt-10 pb-10">
                            <div className="text-center">
                                <p className="text-italic">
                                    There aren't any invoices connected with your account.
                                </p>
                            </div>
                        </div>
                      : <div className="bb-1 mb-20 text-center pb-20">
                            <Button variant="primary" size="sm" onClick={() => { handleDownloadAllInvoices() }} className="download-btn"><i className="icon icon-download mr-10 text-sm"></i> Download all {invoices.length} invoices</Button>
                        </div>
                    }
                    {/* End of: Show this div if there are no invoices */}

                    {/* Show the below if there are ant invoices */}
                    {(invoices !== null && invoices !== undefined) && invoices.length > 0 &&
                        <div id="invoices">
                            {/* Invoice row */}
                            {invoices.map((invoice) => {
                              return <div className="">
                                    <div className="pb-20 mb-20 bb-1 clearfix row">
                                        <div className="col-xxs-12 col-xs-12 col-sm-2 text-center text-sm-start">
                                            <p className="text-sm">
                                                <span className="text-500 text-xs">Invoice ID: </span>
                                                <br />
                                                {invoice.invoiceId}
                                            </p>
                                        </div>
                                        <div className="col-xxs-12 col-xs-12 col-sm-3 text-center text-sm-start">
                                            <p className="text-sm">
                                                <span className="text-500 text-xs">Invoice Date: </span>
                                                <br />
                                                {invoice.date}
                                            </p>
                                        </div>
                                        <div className="col-xxs-12 col-xs-12 col-sm-2 text-center text-sm-start">
                                            <p className="text-sm">
                                                <span className="text-500 text-xs">Total Paid: </span>
                                                <br />
                                                &pound;{invoice.total.toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="col-xxs-12 col-xs-12 col-sm-1 text-center text-sm-start">
                                            <p className="text-sm">
                                                <span className="text-500 text-xs">Status: </span>
                                                <br />

                                                {(() => {
                                                  switch (invoice.paymentStatus) {
                                                    case 'P':
                                                      return <span className="text-success text-500"><span className="icon icon-checkmark4 mr-5" />Paid</span>
                                                    case 'W':
                                                      return <span className="alert-warning text-500 p-5 brad-5">Outstanding </span>
                                                    case 'E':
                                                      return <span className="alert-danger text-500 p-5 brad-5">Esculated</span>
                                                  }
                                                })()}
                                            </p>
                                        </div>

                                        <div className="col-xxs-12 col-xs-12 col-sm-4 text-center text-sm-end pt-10">
                                            <Button variant="primary" onClick={() => { handleDownloadInvoice(invoice) }} className="mr-10 btn-xs download-btn">Download</Button>
                                            <a href={`/customer/invoice/${invoice.invoiceId}`} className="btn btn-xs btn-cta" target="_blank" rel="noreferrer">View</a>
                                        </div>
                                    </div>
                                </div>
                            })}
                            <Pager onSelected={page => { setCurrentPage(page) }} activePage={currentPage}
                                pages={totalPages} />
                        </div>}
                    {/* End of: Show the below if there are ant invoices */}
                </div>
            </div>
        </>
  )
}

export default Invoices
