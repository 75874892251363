import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SvgRender from '../../components/SvgRender'
import { checkUserHasCallRecording } from '../../helpers/utils'
import ProgressRing from '../ProgressRing'

const _ = require('lodash')

class ProfileProgress extends React.Component {
  state = {
    isLoading: false
  }

  constructor (props) {
    super(props)
  }

  componentDidMount () {
  }

  render () {
    const hasCallrecording = checkUserHasCallRecording(this.props.services)
    const completePercent = Math.floor(this.props.profile ? this.props.profile.complete : 0)
    return (
            <>
                <div className="profile-progress bb-1 pb-xs-20 pt-xs-20">
                    <div className="container">
                        <div className="row">
                            <div className="col col-sm-6 p-40 p-sm-20 p-xs-10 progress-left">
                                <div className="row">
                                    <div className="col col-sm-3 profile-image text-center text-sm-start d-none d-sm-block">
                                        <ProgressRing
                                            progress={Math.floor(this.props.profile ? this.props.profile.complete : 0)}
                                            size={126}
                                            strokeWidth={6}
                                        />
                                        <img src="/images/dashboard/profile-image.png" alt={this.props.firstName} />
                                    </div>
                                    <div className="col-sm-9 text-center text-sm-start pt-20">
                                        <h1 className="text-xxl text-xs-lg">Hi, {this.props.firstName}!</h1>
                                        <p className="text-500">
                                            {completePercent < 100
                                              ? <>
                                                    <span className="text-cta d-none d-sm-block">Your profile is at {completePercent}%.</span>
                                                    {/* Complete & receive a treat */}
                                                </>
                                              : <>
                                                    <span className="text-cta">Welcome back to SwitchboardFREE.</span>
                                                </>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-sm-6 text-center p-30 p-sm-20 p-xs-10 pt-30 pb-25 bt-xs-1 progress-right d-none d-sm-block">
                                {completePercent === 100
                                  ? <>
                                        <p className="text-cta text-500 mb-20 mt-xs-20">
                                            Get more from SwitchboardFREE
                                        </p>
                                        <div className="text-center row">
                                            <div className="col-md-3 col-sm-3 col-xs-3">
                                                <Link to="/customer/call-recording/" className={`text-500 ${hasCallrecording === true ? 'text-secondary' : 'text-dark'}`}>
                                                    <div className={`icon-block icon-block-xs icon-thick ${hasCallrecording ? 'feature-on' : ''}`}>
                                                        {hasCallrecording === true
                                                          ? <>
                                                            <SvgRender icon="call-recording" svgWidth="40px" svgClassName="d-none d-sm-block mt-15" />
                                                        </>
                                                          : <img src="/images/dashboard/call-record-off.svg" className="mt-xs-15" alt="Call Recording" />
                                                        }
                                                    </div>
                                                    <p className="text-sm mt-10 text-500">
                                                        Call Recording
                                                    </p>
                                                </Link>
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-xs-3">
                                                <Link to="/customer/call-blocking/" className={`text-500 ${this.props.services.hasNewCallBlockingFeature === true ? 'text-secondary' : 'text-dark'}`}>
                                                    <div className={`icon-block icon-block-xs icon-thick ${this.props.services.hasNewCallBlockingFeature ? 'feature-on' : ''}`}>
                                                        {this.props.services.hasNewCallBlockingFeature === true
                                                          ? <img src="/images/dashboard/call-blocker-on.svg" className="mt-xs-15" alt="Call Blocking" />
                                                          : <img src="/images/dashboard/call-blocker-off.svg" className="mt-xs-15" alt="Call Blocking" />
                                                        }
                                                    </div>
                                                    <p className="text-sm mt-10 text-500">
                                                        Call Blocking
                                                    </p>
                                                </Link>
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-xs-3">
                                                <Link to="/customer/call-prioritiser/" className={`text-500 ${this.props.services.hasVipCallerFeature === true ? 'text-secondary' : 'text-dark'}`}>
                                                    <div className={`icon-block icon-block-xs icon-thick ${this.props.services.hasVipCallerFeature ? 'feature-on' : ''}`}>
                                                        {this.props.services.hasVipCallerFeature === true
                                                          ? <img src="/images/dashboard/vip-on.svg" className="mt-xs-15" alt="Call Prioritiser" />
                                                          : <img src="/images/dashboard/vip-off.svg" className="mt-xs-15" alt="Call Prioritiser" />
                                                        }
                                                    </div>
                                                    <p className="text-sm mt-10 text-500">
                                                    Call Prioritiser
                                                    </p>
                                                </Link>
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-xs-3">
                                                <Link to="/customer/contact-book/" className={'text-500 text-secondary'}>
                                                    <div className={'icon-block icon-block-xs icon-thick feature-on'}>
                                                        <img src="/images/dashboard/contact-book-on.svg" className="mt-xs-15" alt="Contact Book" />
                                                    </div>
                                                    <p className="text-sm mt-10 text-500">
                                                        Contact Book
                                                    </p>
                                                </Link>
                                            </div>

                                        </div>
                                    </>
                                  : <>
                                        <p className="text-cta text-500 mb-20">
                                            Get more from SwitchboardFREE by setting up the basics
                                        </p>
                                        <div className="text-center row">
                                            <div className="col">
                                                <Link to="/customer/profile/your-details" className={`text-500 ${this.props.profile && this.props.profile.nameComplete === true ? 'text-secondary' : 'text-dark'}`}>
                                                    <div className="icon-block icon-block-xs icon-thick">
                                                        {this.props.profile && this.props.profile.nameComplete === true
                                                          ? <img src="/images/dashboard/tick.svg" alt="Done" className="done" />
                                                          : <img src="/images/dashboard/name.svg" alt="Your Name" />
                                                        }
                                                    </div>
                                                    <p className="text-sm mt-10 text-500">
                                                        Name
                                            </p>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/customer/profile/your-details" className={`text-500 ${this.props.profile && this.props.profile.contactComplete === true ? 'text-secondary' : 'text-dark'}`}>
                                                    <div className="icon-block icon-block-xs icon-thick">
                                                        {this.props.profile && this.props.profile.contactComplete === true
                                                          ? <img src="/images/dashboard/tick.svg" alt="Done" className="done" />
                                                          : <img src="/images/dashboard/contact.svg" alt="Contact Details" />
                                                        }
                                                    </div>
                                                    <p className="text-sm mt-10 text-500">
                                                        Contact
                                            </p>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/customer/profile/your-details" className={`text-500 ${this.props.profile && this.props.profile.addressComplete === true ? 'text-secondary' : 'text-dark'}`}>
                                                    <div className="icon-block icon-block-xs icon-thick ">
                                                        {this.props.profile && this.props.profile.addressComplete === true
                                                          ? <img src="/images/dashboard/tick.svg" alt="Done" className="done" />
                                                          : <img src="/images/dashboard/address.svg" alt="Address Details" />
                                                        }
                                                    </div>
                                                    <p className="text-sm mt-10 text-500">
                                                        Address
                                            </p>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/customer/profile/billing" className={`text-500 ${this.props.profile && this.props.profile.billingComplete === true ? 'text-secondary' : 'text-dark'}`}>
                                                    <div className="icon-block icon-block-xs icon-thick">
                                                        {this.props.profile && this.props.profile.billingComplete === true
                                                          ? <img src="/images/dashboard/tick.svg" alt="Done" className="done" />
                                                          : <img src="/images/dashboard/billing.svg" alt="Billing Details" />
                                                        }
                                                    </div>
                                                    <p className="text-sm mt-10 text-500">
                                                        Billing
                                            </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </>
    )
  }
}

export default connect(
  state => {
    return ({
      services: state.sbfApp.enabledServices
    })
  },
  {
  }
)(ProfileProgress)
