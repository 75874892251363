import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const NumberDescriptionSectionHelperDialog = props => (
    <Modal show={props.isDisplayed}
           onHide={props.hideNumberDescriptionHelperInstruction}>
        <Modal.Header closeButton>
            <Modal.Title>Number Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                Enter a description for your SwitchboardFREE number to help distinguish it on reports and missed call alerts.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideNumberDescriptionHelperInstruction}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.numberDescriptionInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberDescriptionSectionHelperDialog)
