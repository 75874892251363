import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import NumberLandingView from '../../components/NumberTypes/NumberLandingView'
import NumberTypeFeatures from '../../components/NumberTypes/NumberTypesFeaures'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import { is033Offer, numberOfferDuration } from '../../constants/Constants'
import { isLoggedIn } from '../../selectors'

class Numbers03 extends React.Component {
  static defaultProps = {}

  static propTypes = {}

  state = {}

  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            {/* <NumbersMenu /> */}
            <Helmet>
                <title>Buy 0333 Numbers - National Low Call Rate number</title>
                <meta name="description"
                    content="03 UK-wide phone numbers available for purchase online plus free call management. Great business customer support line." />
            </Helmet>

            <NumberLandingView
                h1="0333 Phone Numbers"
                h2="Mobile-friendly national numbers, great for customer support."
                numberType={'03'}
                history={this.props.history}
            />

            <div className="full-width bg-light">
                <div className="container pl-xxs-30 pr-xxs-30">
                    <div className="row">

                        <div className=" col-lg-7 col-md-10 mx-auto" >
                            <div id="contentWrapper">
                                <p className="text-md pt-20 pt-xs-0 text-500 text-center">
                                    {is033Offer
                                      ? <i>
                                            <span className="pt-5 pb-5 pl-20 pr-20 brad-10 text-caps text-500 text-xs text-lightest box-shadow mr-10" style={{ whiteSpace: 'nowrap', background: 'linear-gradient(to right, rgba(245,92,0,1) 0%,rgba(255,120,20,1) 100%)' }}>Special Offer</span>
                                                JUST 99p For Your First <span className="text-500">{numberOfferDuration} Months</span>
                                        </i>
                                      : <i>
                                            From just <GetProdPrice prefix="03" /> per month
                                            </i>
                                    }
                                </p>
                                <p className="text-400 text-md text-primary mb-30 text-center">
                                    Instant activation <span className="pl-20 pr-20">&bull;</span> No hardware required <span className="pl-20 pr-20">&bull;</span> No long term contracts
                                    </p>
                                <p className="text-md pt-30 pt-xs-0 text-xs-sm text-xs-left pb-30">
                                    03 numbers are national, mobile friendly and free to call from most landline and mobiles. This makes them perfect for customer services and sales lines as 03 numbers are usually included in bundled minutes.
                                    </p>
                                <p>
                                    Get a national and professional 03 number that’s usually free for your callers to use.
                                    </p>
                                <p>
                                    Add an 03 number to your account today.
                                    </p>
                                <p>
                                    <ul className="unstyled ml-0 pl-0">
                                        <li>
                                            <span className="text-cta icon icon-checkmark mr-10"></span> Call for free using inclusive minutes
                                            </li>
                                        <li>
                                            <span className="text-cta icon icon-checkmark mr-10"></span> Trusted National phone number
                                            </li>
                                        <li>
                                            <span className="text-cta icon icon-checkmark mr-10"></span> Over 40 powerful free features
                                            </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NumberTypeFeatures history={this.props.history} />

            <div className="full-width bg-light pt-50 pb-50 mt-20">
                <div className="container">
                    <div className="row text-center features-list">
                        <div className="col-sm-4 pb-sm-30 br-1 br-sm-0">
                            <div className="text-center img-circle bg-dull block center-block" style={{ width: '100px', height: '100px' }}>
                                <SvgRender icon="apps" svgWidth="50px" svgClassName="mt-25" />
                            </div>
                            <p className="pt-20">Manage your calls on the move with <br className="d-block d-sm-none d-lg-block" />the free SwitchboardFREE app.</p>
                        </div>
                        <div className="col-sm-4 pb-sm-30 br-1 br-sm-0">
                            <div className="text-center img-circle bg-dull block center-block" style={{ width: '100px', height: '100px' }}>
                                <SvgRender icon="reporting-features" svgWidth="50px" svgClassName="mt-25" />
                            </div>
                            <p className="pt-20">Grow faster, with over 40 professional <br className="d-block d-sm-none d-lg-block" />free call handling features.</p>
                        </div>
                        <div className="col-sm-4 pb-sm-30">
                            <div className="text-center img-circle bg-dull block center-block" style={{ width: '100px', height: '100px' }}>
                                <SvgRender icon="config-changes" svgWidth="60px" svgClassName="mt-20" />
                            </div>
                            <p className="pt-20">Tailor your number settings to your unique <br className="d-block d-sm-none d-lg-block" />business, and manage easily 24/7.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width bg-dull pt-50">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>

            {/* Logoos */}
            <div className="full-width bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500">Partners &amp; Customers</h3>
                            {/* <h6>The UK's favourite call management system</h6> */}
                            <p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
                        </div>
                    </div>
                </div>
            </div>
            <ClientLogos style='light' />

        </>
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
  }
)(Numbers03)
