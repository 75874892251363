import moment from 'moment'
import React from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import Datetime from 'react-datetime';
import { withRouter } from 'react-router'
import Select from 'react-select'
import HourStatsChart from '../../components/HourStatsChart'
import VoicemailAudioPlayerButton from '../../components/VoicemailAudioPlayerButton'
import { fetchHelper } from '../../helpers/fetchHelper'
import { simplePhoneNumberFormat } from '../../helpers/utils'
import LoadingNotification from '../LoadingNotification'
import MyCallsCallInformation from './MyCallsCallInformation'

const report = {
  summary: 'SUMMARY',
  allCalls: 'ALL_CALLS',
  allCallsOutbound: 'ALL_CALLS_OUTBOUND',
  answered: 'ANSWERED_CALLS',
  answeredOutbound: 'ANSWERED_CALLS_OUTBOUND',
  missed: 'MISSED_CALLS',
  callbackList: 'CALLBACK_LIST',
  callActivityHour: 'HOURLY_ACTIVITY',
  departmentSelectionSummary: 'DEPARTMENT_SELECTION_SUMMARY',
  departmentSelection: 'DEPARTMENT_SELECTION'
}

const reportSelectionData = [
  { report: report.missed, title: 'List of inbound missed calls', subtitle: 'A list of all the calls that weren\'t answered' },
  { report: report.allCalls, title: 'List all inbound calls', subtitle: 'A list of all calls received on your number' },
  {
    report: report.summary,
    title: 'Summary of inbound call results',
    subtitle: 'A count of calls: voicemail, missed and answered calls'
  },
  { report: report.allCallsOutbound, title: 'List all outbound calls', subtitle: 'A list of all calls outbound from your number' },
  { report: report.answered, title: 'List answered inbound calls', subtitle: 'View a list of all answered inbound calls' },
  { report: report.answeredOutbound, title: 'List answered outbound calls', subtitle: 'View a list of all answered outbound calls' },
  {
    report: report.callbackList,
    title: 'Callback list',
    subtitle: 'List of callers to your number who you have not yet spoken to'
  },
  {
    report: report.callActivityHour,
    title: 'Inbound call activity',
    subtitle: 'View your busiest hours & when you miss the most calls'
  },
  {
    report: report.departmentSelectionSummary,
    title: 'Department selection report (summary)',
    subtitle: 'View how many people are choosing which departments to be put through to'
  },
  {
    report: report.departmentSelection,
    title: 'Department selection report (call listing)',
    subtitle: 'View which department each individual caller chose to be put through to'
  }
]

export const selectTimes = [
  { value: 0, label: '00:00' },
  { value: 1, label: '00:15' },
  { value: 2, label: '00:30' },
  { value: 3, label: '00:45' },
  { value: 4, label: '01:00' },
  { value: 5, label: '01:15' },
  { value: 6, label: '01:30' },
  { value: 7, label: '01:45' },
  { value: 8, label: '02:00' },
  { value: 9, label: '02:15' },
  { value: 10, label: '02:30' },
  { value: 11, label: '02:45' },
  { value: 12, label: '03:00' },
  { value: 13, label: '03:15' },
  { value: 14, label: '03:30' },
  { value: 15, label: '03:45' },
  { value: 16, label: '04:00' },
  { value: 17, label: '04:15' },
  { value: 18, label: '04:30' },
  { value: 19, label: '04:45' },
  { value: 20, label: '05:00' },
  { value: 21, label: '05:15' },
  { value: 22, label: '05:30' },
  { value: 23, label: '05:45' },
  { value: 24, label: '06:00' },
  { value: 25, label: '06:15' },
  { value: 26, label: '06:30' },
  { value: 27, label: '06:45' },
  { value: 28, label: '07:00' },
  { value: 29, label: '07:15' },
  { value: 30, label: '07:30' },
  { value: 31, label: '07:45' },
  { value: 32, label: '08:00' },
  { value: 33, label: '08:15' },
  { value: 34, label: '08:30' },
  { value: 35, label: '08:45' },
  { value: 36, label: '09:00' },
  { value: 37, label: '09:15' },
  { value: 38, label: '09:30' },
  { value: 39, label: '09:45' },
  { value: 40, label: '10:00' },
  { value: 41, label: '10:15' },
  { value: 42, label: '10:30' },
  { value: 43, label: '10:45' },
  { value: 44, label: '11:00' },
  { value: 45, label: '11:15' },
  { value: 46, label: '11:30' },
  { value: 47, label: '11:45' },
  { value: 48, label: '12:00' },
  { value: 49, label: '12:15' },
  { value: 50, label: '12:30' },
  { value: 51, label: '12:45' },
  { value: 52, label: '13:00' },
  { value: 53, label: '13:15' },
  { value: 54, label: '13:30' },
  { value: 55, label: '13:45' },
  { value: 56, label: '14:00' },
  { value: 57, label: '14:15' },
  { value: 58, label: '14:30' },
  { value: 59, label: '14:45' },
  { value: 60, label: '15:00' },
  { value: 61, label: '15:15' },
  { value: 62, label: '15:30' },
  { value: 63, label: '15:45' },
  { value: 64, label: '16:00' },
  { value: 65, label: '16:15' },
  { value: 66, label: '16:30' },
  { value: 67, label: '16:45' },
  { value: 68, label: '17:00' },
  { value: 69, label: '17:15' },
  { value: 70, label: '17:30' },
  { value: 71, label: '17:45' },
  { value: 72, label: '18:00' },
  { value: 73, label: '18:15' },
  { value: 74, label: '18:30' },
  { value: 75, label: '18:45' },
  { value: 76, label: '19:00' },
  { value: 77, label: '19:15' },
  { value: 78, label: '19:30' },
  { value: 79, label: '19:45' },
  { value: 80, label: '20:00' },
  { value: 81, label: '20:15' },
  { value: 82, label: '20:30' },
  { value: 83, label: '20:45' },
  { value: 84, label: '21:00' },
  { value: 85, label: '21:15' },
  { value: 86, label: '21:30' },
  { value: 87, label: '21:45' },
  { value: 88, label: '22:00' },
  { value: 89, label: '22:15' },
  { value: 90, label: '22:30' },
  { value: 91, label: '22:45' },
  { value: 92, label: '23:00' },
  { value: 93, label: '23:15' },
  { value: 94, label: '23:30' },
  { value: 95, label: '23:45' }
]

const quickDateSelects = [
  { value: 2, label: 'Yesterday' },
  { value: 1, label: 'Today' },
  { value: 3, label: 'Last Weekend' },
  { value: 4, label: 'Prev Weekend' },
  { value: 5, label: 'This Week' },
  { value: 6, label: 'Last Week' },
  { value: 7, label: 'This Month' },
  { value: 8, label: 'Last Month' },
  { value: 9, label: 'Last 3 Months' },
  { value: 10, label: 'Last 6 Months' },
  { value: 11, label: 'This Year' },
  { value: 12, label: 'Last Year' }
]

export const categories = [
  { value: 0, label: 'Category 1' },
  { value: 1, label: 'Category 2' },
  { value: 2, label: 'Category 3' },
  { value: 3, label: 'Category 4' },
  { value: 4, label: 'Category 5' }
]

class MyCallsCallStats extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    selectedReport: -1,
    fromDate: moment().startOf('day'),
    toDate: moment().endOf('day'),
    numbers: [{ value: -1, label: 'All Numbers' }],
    selectedQlid: { value: -1, label: 'All Numbers' },
    menuSelection: reportSelectionData[0],
    menuExpanded: false,
    quickDateSelect: { value: 1, label: 'Today' },
    textSearch: undefined
  }

  componentDidMount () {
    const url = '/api/Numbers/GetNumbersListForCustomer'
    const context = this

    fetchHelper.getJson(url)
      .then(response => {
        const numbers = response.data
        numbers.forEach(number => { simplePhoneNumberFormat(number.label) })
        numbers.unshift({ value: -1, label: 'All Numbers' })
        context.setState({ numbers })

        // if numberId passed as parameter, check its in the numbers list and get todays stats
        const numberIdParam = Number(this.props.match.params.numberId)
        if (numberIdParam !== undefined && !isNaN(numberIdParam)) {
          const number = numbers.filter(x => Number(x.value) === numberIdParam)

          if (number !== undefined) {
            const date = this.getToday()
            this.setState({
              fromDate: date.fromDate,
              toDate: date.toDate,
              quickDateSelect: { value: 1, label: 'Today' },
              selectedQlid: numberIdParam
            })

            this.callApi(this.state.fromDate, this.state.toDate, this.state.menuSelection.report)
          }
        }

        if (localStorage.getItem('statsData')) {
          const data = JSON.parse(localStorage.getItem('statsData'))
          const from = moment(data.from)
          const to = moment(data.to)
          this.callApi(from, to, reportSelectionData[data.report].report)
          this.setState({
            fromDate: from,
            toDate: to,
            menuSelection: reportSelectionData[data.report]
          })

          if (moment().diff(from, 'days') === 0) {
            this.setState({ quickDateSelect: { value: 1, label: 'Today' } })
          }

          localStorage.removeItem('statsData')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  getToday () {
    const start = moment().startOf('day')
    const end = moment().endOf('day')
    return { fromDate: start, toDate: end }
  }

  getLastWeekend () {
    const start = moment().weekday(-1).startOf('day')
    const end = moment().weekday(0).endOf('day')
    return { fromDate: start, toDate: end }
  }

  getThisWeek () {
    const start = moment().weekday(1).startOf('day')
    const end = moment().weekday(7).endOf('day')
    return { fromDate: start, toDate: end }
  }

  getThisMonth () {
    const start = moment().startOf('month')
    const end = moment().endOf('month')
    return { fromDate: start, toDate: end }
  }

  getYesterday () {
    const start = moment().add(-1, 'days').startOf('day')
    const end = moment().add(-1, 'days').endOf('day')
    return { fromDate: start, toDate: end }
  }

  getPreviousWeekend () {
    const start = moment().weekday(-8).startOf('day')
    const end = moment().weekday(-7).endOf('day')
    return { fromDate: start, toDate: end }
  }

  getPreviousWeek () {
    const start = moment().weekday(-6).startOf('day')
    const end = moment().weekday(0).endOf('day')
    return { fromDate: start, toDate: end }
  }

  getLastMonth () {
    const start = moment().add(-1, 'months').startOf('month')
    const end = moment().add(-1, 'months').endOf('month')
    return { fromDate: start, toDate: end }
  }

  getLastThreeMonths () {
    const start = moment().add(-3, 'months').startOf('month')
    const end = moment().add(-1, 'months').endOf('month')
    return { fromDate: start, toDate: end }
  }

  getLastSixMonths () {
    const start = moment().add(-6, 'months').startOf('month')
    const end = moment().add(-1, 'months').endOf('month')
    return { fromDate: start, toDate: end }
  }

  getLastYear () {
    const start = moment().add(-1, 'years').startOf('year')
    const end = moment().add(-1, 'years').endOf('year')
    return { fromDate: start, toDate: end }
  }

  getThisYear () {
    const start = moment().startOf('year')
    const end = moment().endOf('year')
    return { fromDate: start, toDate: end }
  }

  handleQuickSelectDateChange (e) {
    let date = this.getToday()
    switch (String(e.value)) {
      case '1':
        date = this.getToday()
        break
      case '2':
        date = this.getYesterday()
        break
      case '3':
        date = this.getLastWeekend()
        break
      case '4':
        date = this.getPreviousWeekend()
        break
      case '5':
        date = this.getThisWeek()
        break
      case '6':
        date = this.getPreviousWeek()
        break
      case '7':
        date = this.getThisMonth()
        break
      case '8':
        date = this.getLastMonth()
        break
      case '9':
        date = this.getLastThreeMonths()
        break
      case '10':
        date = this.getLastSixMonths()
        break
      case '11':
        date = this.getThisYear()
        break
      case '12':
        date = this.getLastYear()
        break
    }

    this.setState({
      fromDate: date.fromDate,
      toDate: date.toDate,
      quickDateSelect: e
    })
  }

  handleLoadCallInformationPage (callId) {
    window.open('/customer/calls?callId=' + callId + '&isInbound=1', '_blank')
  }

  handleLoadVoicemailPage (callId) {
    fetchHelper.getJson('/api/reports/GetVoicemailIdForCallId/' + callId).then((res) => {
      const voicemailId = res.data

      window.open('/customer/my-calls/voicemail-details/' + voicemailId, '_blank')
    })
  }

  handleGetStatsPressed () {
    this.callApi(this.state.fromDate, this.state.toDate, this.state.menuSelection.report)
  }

  handeNumberFilterChanged (selectedQlid) {
    this.setState({ selectedQlid })
  }

  async callApi (fromDate, toDate, selectedReport) {
    const context = this

    context.setState({ isLoading: true })
    const from = fromDate.format('YYYY-MM-DD H:m')
    const to = toDate.format('YYYY-MM-DD H:m')
    let textSearch = this.state.textSearch

    if (textSearch === undefined || textSearch === '') {
      textSearch = ' '
    }

    function getUrlForSelectedReport (selectedReport) {
      let url

      switch (selectedReport) {
        case report.callActivityHour:
          url = `api/Reports/GetCallActivityByTimeOfDay/${context.state.selectedQlid.value}/${from}/${to}`
          break
        case report.summary:
          url = `api/Reports/GetCallSummary/${context.state.selectedQlid.value}/${from}/${to}`
          break
        case report.allCalls:
          url = `api/Reports/GetCallList/${context.state.selectedQlid.value}/${from}/${to}/${textSearch}/`
          break
        case report.allCallsOutbound:
          url = `api/Reports/GetCallListOutbound/${context.state.selectedQlid.value}/${from}/${to}/${textSearch}/`
          break
        case report.answered:
          url = `api/Reports/GetAnsweredCallList/${context.state.selectedQlid.value}/${from}/${to}/${textSearch}/`
          break
        case report.answeredOutbound:
          url = `api/Reports/GetOutboundAnsweredCallList/${context.state.selectedQlid.value}/${from}/${to}/${textSearch}/`
          break
        case report.missed:
          url = `api/Reports/GetMissedCallList/${context.state.selectedQlid.value}/${from}/${to}`
          break
        case report.voicemails:
          url = `api/Reports/GeVoicemailCallList/${context.state.selectedQlid.value}/${from}/${to}`
          break
        case report.callbackList:
          url = `api/Reports/GetCallbackList/${context.state.selectedQlid.value}/${from}/${to}`
          break
        case report.departmentSelection:
          url = `api/Reports/GetDepartmentSelectionListing/${context.state.selectedQlid.value}/${from}/${to}`
          break
        case report.departmentSelectionSummary:
          url = `api/Reports/GetDepartmentSummary/${context.state.selectedQlid.value}/${from}/${to}`
          break
      }

      return url
    }

    const url = getUrlForSelectedReport(selectedReport)

    const response = await fetchHelper.getJson(url)

    if (fetchHelper.isUnauthorised(response)) {
      /// /dispatch(logout({history: context.history}))
    } else {
      const reportData = response.data
      context.setState({ data: reportData, isLoading: false, loadedReport: selectedReport })
    }
  }

  render () {
    if (window.location.href.toLowerCase().indexOf('call-information') > -1) {
      return <MyCallsCallInformation/>
    } else {
      const today = moment()
      const disableFutureDt = current => {
        return current.isBefore(today)
      }

      const context = this

      /**
         * @return {string}
         */
      function GetTimeDescriptionForHour (hour) {
        if (hour === 0) return '12 AM'
        if (hour < 12) return `${hour} AM`
        return `${hour - 12 === 0 ? 12 : hour - 12} PM`
      }

      async function handleDownloadCsvRequest (event, fromDate, toDate, selectedReport) {
        context.setState({ isLoading: true })
        event.preventDefault()
        const from = fromDate.startOf('day').format('YYYY-MM-DDTHH:mm')
        const to = toDate.endOf('day').format('YYYY-MM-DDTHH:mm')

        let textSearch = context.state.textSearch

        if (textSearch === undefined || textSearch === '') {
          textSearch = ' '
        }

        function getUrlForSelectedReport (selectedReport) {
          let url

          switch (selectedReport) {
            case report.summary:
              url = `api/Reports/GetCallSummaryCSV/${context.state.selectedQlid.value}/${from}/${to}`
              break
            case report.allCalls:
              url = `api/Reports/GetCallListCSV/${context.state.selectedQlid.value}/${from}/${to}/${textSearch}/`
              break
            case report.answered:
              url = `api/Reports/GetAnsweredCallListCSV/${context.state.selectedQlid.value}/${from}/${to}/${textSearch}/`
              break
            case report.missed:
              url = `api/Reports/GetMissedCallListCSV/${context.state.selectedQlid.value}/${from}/${to}`
              break
            case report.voicemails:
              url = `api/Reports/GeVoicemailCallListCSV/${context.state.selectedQlid.value}/${from}/${to}`
              break
            case report.callbackList:
              url = `api/Reports/GetCallbackListCSV/${context.state.selectedQlid.value}/${from}/${to}`
              break
            case report.departmentSelection:
              url = `api/Reports/GetDepartmentSelectionListingCSV/${context.state.selectedQlid.value}/${from}/${to}`
              break
            case report.departmentSelectionSummary:
              url = `api/Reports/GetDepartmentSummaryCSV/${context.state.selectedQlid.value}/${from}/${to}`
              break
            case report.allCallsOutbound:
              url = `api/Reports/GetCallListOutboundCSV/${from}/${to}/${textSearch}/`
              break
            case report.answeredOutbound:
              url = `api/Reports/GetOutboundAnsweredCallListCSV/${context.state.selectedQlid.value}/${from}/${to}/${textSearch}/`
              break
          }

          return url
        }

        const url = getUrlForSelectedReport(selectedReport)

        fetchHelper.getBlob(url).then(response => {
          context.setState({ isLoading: false })
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'SwitchboardFREE-report.csv')
          document.body.appendChild(link)
          link.click()
        }).catch(error => console.error(error))
      }

      const headerRowData = this.state.data ? this.state.data[0] : []
      return (
                <>
                    <br/>
                    <div className="container text-start" style={{ maxWidth: '1100px' }}>
                        <div className="row">
                            <div className="col-md-6 col-sm-6 pb-20" style={{ zIndex: 1006 }}>
                                <p> Quick Date Select </p>
                                <Select options={quickDateSelects} defaultValue={this.state.quickDateSelect} value={this.state.quickDateSelect} isSearchable={false} onChange={(e) => this.handleQuickSelectDateChange(e)} id="QuickDateSelectDropdown"/>
                            </div>
                            <div className="col-md-6 col-sm-6 pb-20" style={{ zIndex: 1005 }}>
                                <p> Number (optional) </p>
                                <Select options={this.state.numbers} defaultValue={this.state.selectedQlid} isSearchable={false} onChange={(e) => this.handeNumberFilterChanged(e)} id="NumberDropdown"/>
                            </div>
                        </div>

                            <Form className="row">
                            <div className="col-md-6 col-sm-6 pb-20" style={{ zIndex: 1004 }}>
                                <p> Start date </p>
                                <Form.Group>
                                    <Datetime isValidDate={disableFutureDt}
                                              closeOnSelect={true}
                                              value={this.state.fromDate}
                                              timeFormat={true}
                                              dateFormat='DD/MM/YYYY'
                                              isSearchable={false}
                                              onChange={(e) => this.setState({ fromDate: e })}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6 col-sm-6 pb-20" style={{ zIndex: 1003 }}>
                                <p> End date </p>
                                <Form.Group>
                                    <Datetime isValidDate={disableFutureDt} closeOnSelect={true} value={this.state.toDate} timeFormat={true} dateFormat='DD/MM/YYYY' isSearchable={false} onChange={(e) => this.setState({ toDate: e })} />
                                </Form.Group>
                            </div>
                            </Form>

                        <div className="row">
                            <div className={[report.allCalls, report.allCallsOutbound, report.answered, report.answeredOutbound].includes(this.state.menuSelection.report) ? 'col-md-12 pb-10' : 'col-md-12 pb-20'} style={{ zIndex: 1002 }}>
                                <p> Report Type </p>
                                <div className="simple-dropdown call-stats-dropdown">
                                    {this.state.menuExpanded &&
                                        <ul className="text-start">
                                            <li className="bb-0" onClick={e => this.setState({ menuExpanded: false })}>
                                                <a className="btn btn-primary">
                                                    <span className="text-500">{this.state.menuSelection.title}</span> <span className="caret"></span>
                                                    <br />
                                                    {this.state.menuSelection.subtitle}
                                                </a>
                                            </li>
                                            {this.state.menuExpanded && reportSelectionData.map((reportData) => {
                                              return <li className={'bb-0 '.concat(reportData.report === this.state.menuSelection.report ? 'selected' : '')}
                                                    onClick={e => reportData.report === this.state.menuSelection.report
                                                      ? ''
                                                      : this.setState({
                                                        menuSelection: reportData,
                                                        menuExpanded: false,
                                                        textSearch: undefined
                                                      })}
                                                    key={reportData.report}>
                                                    <a className="btn btn-primary" id={`${reportData.title.replace(/\s/g, "")}Option`}>
                                                        <span className="text-500">{reportData.title}</span>
                                                        <br />
                                                        {reportData.subtitle}
                                                    </a>
                                                </li>
                                            })}
                                        </ul>
                                    }

                                    {!this.state.menuExpanded &&
                                        <ul className="text-center" onClick={e => this.setState({ menuExpanded: true })}>
                                            <li className="bb-0">
                                                <a className="btn btn-primary brad-5" id="ReportTypeDropdown">
                                                    <span className="text-500">{this.state.menuSelection.title}</span> <span className="caret"></span>
                                                    <br />
                                                    {this.state.menuSelection.subtitle}
                                                </a>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>

                        {[report.allCalls, report.allCallsOutbound, report.answered, report.answeredOutbound].includes(this.state.menuSelection.report) && <div className="row">
                            <div className="col-md-12 pb-30" style={{ zIndex: 1001 }}>
                                <p> Call Transcription Text Search </p>
                                <input className="form-control" type="text" placeholder="No search filter applied if blank" value={this.state.textSearch} onChange={(e) => this.setState({ textSearch: e.target.value })} />
                            </div>
                        </div>}

                        <div className="row">
                            <div className="col-md-4">
                                <Button size="lg" variant="cta" className="mb-5 brad-30 pl-30 pr-30 b-1" onClick={() => this.handleGetStatsPressed()}>Get Stats</Button>
                            </div>
                        </div>
                        <br/>

                            {this.state.isLoading && <LoadingNotification loadingText={'Loading...'} isLoading={this.state.isLoading} height="100" width="100" />}

                             <div id="report">
                            {this.state.data && this.state.loadedReport === report.callActivityHour &&
                                <div className="container" style={{ width: '100%' }}>
                                    <div className="row">
                                        <div className="col-md-12 text-center pb-30 pt-30 mb-30">
                                            <h2>Busiest Hour of the Day</h2>
                                            <p>
                                                This report shows you when your busiest hours are and helps you manage staffing levels by highlighting when you are missing the most phone calls.</p>
                                            <p>
                                                The size of each bar indicates the volume of calls you received during each hour, the red portion indicates the percentage of missed calls and the green portion indicates
                                                the percentage of answered calls. Rows which are highlighted pink indicate that during this hour you are missing more than 10.0% of your calls and should consider
                                                getting additional staff to answer calls during these times.
                            </p>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <h3>Key</h3>
                                            <p className="pt-5 pb-5 pl-10 pr-10" style={{ backgroundColor: '#FFC0CB', display: 'inline-block' }}>
                                                Highlighted rows indicate a missed call rate of more than 10.0%
                            </p>
                                            <div className="col-md-6 mx-auto pb-30">
                                                <div className="col-md-6 text-center">
                                                    <span className="pt-5 pb-5 pl-10 pr-10 brad-t-10 brad-b-10 mr-10" style={{ backgroundColor: '#FF0000' }}></span> Missed / Voicemail Calls
                                </div>
                                                <div className="col-md-6 text-center">
                                                    <span className="pt-5 pb-5 pl-10 pr-10 brad-t-10 brad-b-10 mr-10" style={{ backgroundColor: '#008000' }}></span> Answered Calls
                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.data.map(data => {
                                          function GetMaxCalls (data) {
                                            return data.reduce((acc, val) => {
                                              const totalCallsForHour = val.answeredCalls + val.missedCalls
                                              return (totalCallsForHour > acc) ? totalCallsForHour : acc
                                            }, 0)
                                          }

                                          /**
                                             * @return {boolean}
                                             */
                                          function GetHasTriggeredErrorThreshold (data) {
                                            if (data.missedCalls === 0) {
                                              return false
                                            }

                                            if (data.answeredCalls === 0) {
                                              return true
                                            }

                                            const errorThresholdPercentage = 0.1
                                            return (data.missedCalls * 1.0 / data.answeredCalls) >= errorThresholdPercentage
                                          }

                                          return <div key={data.hour} className="col-xs-12 vertical-align bar-warning bb-1 mb-xs-30 mb-5"
                                                id={`_${data.hour}_row`}
                                                style={{
                                                  'text-align': 'center',
                                                  // 'border-radius': '10px',
                                                  'background-color': GetHasTriggeredErrorThreshold(data) ? 'pink' : 'white'
                                                }}>
                                                <div className="col-md-1 col-sm-1 col-xs-12 text-xs-center bar-warning vertical-align text-sm pt-10 pb-10 text-xs-lg text-xs-bold">
                                                    {GetTimeDescriptionForHour(data.hour)}
                                                </div>
                                                <div className="col-md-3 col-sm-3 col-xs-12 text-xs-center bar-warning vertical-align text-sm pt-10 pb-10">
                                                    {`${data.answeredCalls} answered, ${data.missedCalls} missed/voicemail`}
                                                </div>
                                                <div className="col-md-8 col-sm-8 col-xs-12 pt-10 pb-10" id={`_${data.hour}`} style={{ height: '50px' }}>
                                                    <HourStatsChart data={data} maxCalls={GetMaxCalls(this.state.data)} />
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                                 {this.state.data && this.state.loadedReport !== report.callActivityHour &&
                                 <div className="col-xs-12 bt-1 pt-30 stats-table">
                                     {this.state.data.length > 1 &&
                                     <div className="text-end mb-20"><Button variant="cta" size="sm"
                                                                               onClick={e => handleDownloadCsvRequest(e, this.state.fromDate, this.state.toDate, this.state.menuSelection.report)}>Download
                                         as CSV</Button></div>}

                                     <Table responsive striped bordered condensed hover
                                            style={{ display: 'inline-table' }}>
                                         <thead className="d-none d-sm-table-header-group">
                                         <tr>
                                             {headerRowData.map((headerField, index) => {
                                               // If the report is the voicemail report we need to add an extra column header for the download/playback options

                                               if (this.state.loadedReport !== report.voicemails || (this.state.loadedReport === report.voicemails && index < this.state.data[0].length - 1)) {
                                                 return headerRowData[index] === 'Call Id' ? <th></th> : <th>{headerField}</th>
                                               } else {
                                                 return <th>Playback</th>
                                               }
                                             })}
                                         </tr>
                                         </thead>
                                         <tbody>
                                         {this.state.data.map((row, index) => <tr>
                                             {this.state.data.length > 1 && index > 0 ? row.map((field, index) => {
                                               // If the report is the voicemail report we need to add an extra column <td> for the download/playback options

                                               // if call id column
                                               if (headerRowData[index] === 'Call Id') {
                                                 if (index - 3 > -1 && headerRowData[index - 3] === 'Voicemail' && row[index - 3] === 'Yes') {
                                                   return <td>
                                                            <Button size="xs" className="mb-5" variant="secondary" onClick={ () => this.handleLoadCallInformationPage(field) }>Call Info</Button>
                                                        </td>
                                                 } else {
                                                   return <td>
                                                            <Button size="xs" variant="secondary" onClick={ () => this.handleLoadCallInformationPage(field) }>Call Info</Button>
                                                        </td>
                                                 }
                                               } else if (this.state.loadedReport !== report.voicemails || (this.state.loadedReport === report.voicemails && index < row.length - 1)) {
                                                 return <td><span
                                                         className="text-500 d-inline d-sm-none">{this.state.data[0][index]}:</span>{field}
                                                     </td>
                                               } else {
                                                 return <td><VoicemailAudioPlayerButton callId={field}/><Button size="xs" variant="cta" className="m-5" onClick={e => this.handleVoicemailDownload(field, e)}>Download</Button></td>
                                               }
                                             }) : null}
                                             {this.state.data.length === 1 &&
                                             <td colSpan={headerRowData.length}>There are no records, please try
                                                 another criteria</td>}
                                         </tr>)}
                                         </tbody>
                                     </Table>

                                     {this.state.data.length > 1 &&
                                     <div className="text-end mb-20 mt-30"><Button variant="cta" size="sm"
                                                                               onClick={e => handleDownloadCsvRequest(e, this.state.fromDate, this.state.toDate, this.state.menuSelection.report)}>Download
                                         as CSV</Button></div>}
                                 </div>
                                 }
                             </div>
                        <br/>

                    </div>
                </>
      )
    }
  }
}

export default withRouter(MyCallsCallStats)
