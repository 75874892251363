import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import FeaturesList from '../../components/Competitors/FeaturesList'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface PiPcallProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const PiPcall = (props: PiPcallProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>PiPcall vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>PIPcall vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the packages, pricing, and features of PiPcall with SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <p>PiPcall offers two main package options – Basic and Mobile+. The website shows upfront pricing and what’s included within each package, providing transparency from the outset. PiPcall have even created package and pricing comparison pages, so you know exactly what you’re getting. Or so we thought.</p>
                            <p>Our initial impression was great – brilliant website, tons of information, and they even offer a free trial to test the service. PiPcall also offer a self-proclaimed “breakthrough mobile app”. But as we read through their website, there were some big gaps in their offerings.</p>
                            <p>What isn’t clear is their Basic package. As they are driving sales towards their Mobile+ packages, there isn’t much information available regarding the Basic option. Our expert had to dig deep into the PiPcall website and speak with the Sales Team to get clarification.</p>
                            <p>Read on to find out what we thought of the service and comparisons with our own business phone system.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Sign Up Process</h2>
                            <p>PiPcall offer a free trial which is perfect for anyone who is unsure and prefers to test the service first. After completing the online form, you then have to wait to be contacted by a member of their sales team.</p>
                            <p>Want to skip the trial? You will still have to contact their sales team directly to sign up. PiPcall doesn’t offer a self-sign-up service which is disappointing. People are busy and want to get up and running efficiently in minutes, which isn’t what’s on offer here.</p>
                            <p>You have to either submit your details online or have a lengthy sales call. While they offer to provide a no-obligation quote, you can expect a hard sell.</p>
                            <p>Upon signing up, you are required to pay a £25 setup fee with an additional £5 fee per user. Depending on how many team members you have, this may come out a bit pricey, just for the privilege of creating an account.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Package Overview</h2>
                            <p>The whole sales pitch of PiPcall is that the service has been designed for mobiles, only with better quality calls. Although connecting every call, requires data, so you must have an appropriate data bundle.</p>
                            <p>As we mentioned at the outset of this article, PiPcall offer two main package types. You get to choose from their Basic or Mobile+ packages, depending on what suits your business best.</p>

                            <h4 className="text-500 text-darker mt-20 mb-30">Basic Package</h4>
                            <p>On PiPcall’s Basic package, don’t expect any frills, as it’s exactly what it says on the tin – basic! With this package, you get the mobile calling app only. If you want to enjoy any enhanced features, you have to upgrade to their Mobile+ package.</p>
                            <p>What you get for the Basic package is simple:</p>

                            <ul className="fancy cta text-400 pl-30 pt-10 pb-10">
                                <li className="mb-5">Different ringtones for PiPcall calls</li>
                                <li className="mb-5">Voicemail inbox</li>
                                <li className="mb-5">Call history</li>
                                <li className="mb-5">Contact directory</li>
                                <li>Do-not-disturb</li>
                            </ul>

                            <p>PiPcall does offer some amazing features, so it’s a shame they aren’t on offer here across the board.</p>

                            <h4 className="text-500 text-darker mt-20 mb-30">Mobile+ Package</h4>
                            <p>PiPcall has 6 Mobile+ packages available and it’s clear this is where they are trying to drive customers to sign-up. When selecting which package you want, the only difference between all Mobile+ packages is the data allowance. As all calls require data to connect, you will need to carefully choose an allowance that will last the month.</p>
                            <p>On a Mobile+ package, you can take advantage of all the enhanced features on offer. Some of the features available are:</p>

                            <ul className="fancy cta text-400 pl-30 pt-10 pb-10">
                                <li className="mb-5">Call Recording</li>
                                <li className="mb-5">Voicemail to Email</li>
                                <li className="mb-5">Ring Groups</li>
                                <li className="mb-5">Call Whispers</li>
                                <li className="mb-5">Call Forwarding</li>
                                <li className="mb-5">Call Queuing</li>
                                <li className="mb-5">Call Screening</li>
                                <li className="mb-5">Three-Way Calling</li>
                                <li className="mb-5">Admin Access</li>
                                <li className="mb-5">Add &amp; Remove Users</li>
                                <li>Call Analytics</li>
                            </ul>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-10 mb-30">Package Comparison</h2>
                            <p>At SwitchboardFREE, we understand that some features are fundamental for any business phone system. We provide our customers with full access to ALL features across all our plans, including:</p>

                            <FeaturesList />
                            <p>and so many more!</p>

                            <p>To sign-up with us, you can do so online or give our friendly team a call to get set up within minutes:</p>
                            <ol className="text-400 pl-30 pt-10 pb-10">
                                <li className="mb-5">Choose your virtual number</li>
                                <li className="mb-5">Add users</li>
                                <li className="mb-5">Choose your plan</li>
                            </ol>
                            <p>That really is it! Our simplified signing-up process allows you to start answering calls within minutes. But if you need any support or guidance, our Welcome Team will be glad to assist.</p>

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Our Plans</h2>
                            <p>We offer 3 core plans that start from as low as £5 per month. Regardless of the plan you choose, you get the following included:</p>

                            <ul className="unstyled text-400">
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    No long-term contracts
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    No set-up fees or activation fees
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    FREE 01, 02, 03 phone number
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    Unlimited inbound minutes to the app, softphone, or VoIP phone
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    20+ powerful call management features
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    Access to ALL features
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    FREE mobile app and softphone
                                </li>
                                <li>
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    Optional VoIP desk phone
                                </li>
                            </ul>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Final Thoughts</h2>
                            <p>On the surface, PiPcall offers a fantastic mobile-first service. But they have failed to provide business owners with full access to the core features required for business calls.</p>
                            <p>Something worth noting is the severe lack of reviews online. We struggled to find verified reviews from real customers, which is worrying for prospective customers. Out of the reviews we did find, it didn’t look too great – reported glitches with the software and disappointment with the ‘basic’ app.</p>
                            <p>When you take into consideration their setup fees and data allowances, the total cost may be larger than you initially expect.</p>
                            <p>If you’re considering using PiPcall for your business phone system, why not give our Welcome Team a call? Our friendly team will be happy to give you a comparison.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see PiPcalls current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.pipcall.com/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(PiPcall)
