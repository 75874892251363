import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ImageLeftFullWidth from '../components/ImageLeftFullWidth'
import ImageRightFullWidth from '../components/ImageRightFullWidth'
import LeadGenForm from '../components/LeadGenForm'
import PlansAndNumbersDropdown from '../components/PlansAndNumbersDropdown'
import DdiSelectorNew from '../components/purchase/DdiSelectorNew'
import TextBannerWidget from '../components/Reviews/TextBannerWidget'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import StatsBanner from '../components/StatsBanner'
import SvgRender from '../components/SvgRender'
import { VoicemailPlan0800Id, VoicemailPlanId, BasketItemType, SignupStep } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import LocalStorageHelper, { type LocalStorageItem } from '../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../helpers/utils'
import { isLoggedIn } from '../selectors'
import { handleShowCallbackForm } from '../store/SBF'
import { handleAddToBasket, handleBasketClear, handleBasketInit, handleRemoveFromBasket } from '../store/Signup'
import { RouteComponentProps } from 'react-router'
import { ReduxState, SbfPackage, SbfNumber } from '../models/SignupTypes'
import * as getSignup from '../store/Signup'

interface VoicemailState {
	prefix: string
	showEditNumbers: boolean
	packInfo: { rental: number, seconds: number, packageId?: number }
	showEnable: boolean
	basketUpdated: boolean
	selectedNumberType: string
	ddiLid: { value: number } | null
}

interface VoicemailProps extends RouteComponentProps {
	isLoggedIn: boolean
	handleRemoveFromBasket: (e: any) => void
	getProductDetails: (p: Array<{}>) => void
	productDetails: any
	userFirstName: string
	login: (u: any) => void
	userName: string
	password: string
	loginFailed: boolean
	loggingIn: boolean
	updateUsername: (e: any) => void
	updatePassword: (e: any) => void
	//handleAddToBasket: (e: any) => void
	clientId: number
	userDetails: any
	services: any
	handleAddToBasket: (item: SbfPackage, itemType: BasketItemType) => void
}

class VirtualVoicemail extends React.Component<VoicemailProps, VoicemailState> {
  constructor (props) {
    super(props)
  }

	state: VoicemailState = {
		prefix: '01',
		showEditNumbers: false,
		packInfo: { rental: 0, seconds: 0 },
		showEnable: false,
		basketUpdated: false,
		selectedNumberType: '01',
		ddiLid: { value: 0 }
	}

  scrollTo (ele) {
    scroller.scrollTo(ele, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -75
    })
  }

  handleUpdatePackage () {
    let url = `api/Purchase/GetPackageInfoById/${VoicemailPlanId}`

    if (this.state.prefix.startsWith('08')) {
			url = `api/Purchase/GetPackageInfoById/${VoicemailPlan0800Id}`
			fetchHelper
				.getJson<{ packageId?: number, rental: number, seconds: number }>(url)
				.then(res => {
					this.setState({ packInfo: res.data, selectedNumberType: '0800' })
				})
				.catch(err => { console.error(err) })
    } else {
			fetchHelper
				.getJson<{ packageId?: number, rental: number, seconds: number }>(url)
				.then(res => {
					this.setState({ packInfo: res.data })
				})
				.catch(err => { console.error(err) })
    }

  }

	componentDidMount() {
		LocalStorageHelper.setItem<SignupStep>('SignupStep', SignupStep.IsCheckingOut)
    this.handleUpdatePackage()
    localStorage.setItem('WizardStepsToHide', JSON.stringify([2, 4, 5, 6]))
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.prefix !== this.state.prefix) { this.handleUpdatePackage() }
  }

  render () {
    // load basket
		const basket = getEmptyBasket()
		const numbers = LocalStorageHelper.getItem<SbfNumber[]>('LandingPageNumberSelection')
		basket.numbers = numbers.value ?? []
		basket.package = new SbfPackage(
			'Virtual Voicemail',
			this.state.packInfo.seconds,
			this.state.packInfo.rental,
			this.state.packInfo.rental,
			this.state.prefix.startsWith('08') ? VoicemailPlan0800Id : VoicemailPlanId,
			1,
			this.state.selectedNumberType,
			{ qty: 1, monthlyPrice: this.state.packInfo.rental }
		)

		localStorage.setItem('basket', JSON.stringify(basket))
		//this.props.handleAddToBasket(pack, BasketItemType.package)

    return <>
			<ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
			<Helmet>
				<title>
					Get a Virtual Voicemail from SwitchboardFREE
				</title>
				<meta
					name="description"
					content="Get your virtual voicemail number & have calls delivered straight to your email."
				/>
				<style type="text/css">{`
                .navbar.navbar-default .btn-cta { display: none; }
                .fill-cta {
                    fill:#ff9314;
                }
                .fill-dark {
                    fill:#3b3b3b;
                }
                .icon-block.xl .icon {
                  top: 17px !important;
              }
              .girl-img, .girl-img2 {
                position:relative;
                border:10px solid #ff9314;
                border-radius:50%;
                bottom:0;
                height:310px;
                width:310px;
                top:30px;
              } 
              .girl-img {
                left:0;
              }
              .girl-img2 {
                right:0;
              }
              @media screen and (max-width:767px) {
                .col-xs-6 .icon-block {
                  width: 130px;
                  height: 130px;
                  padding: 8px 0;
                }
              }
              @media screen and (min-width:601px) {
                .girl-img, .girl-img2 {
                  position:absolute;
                  max-width:100%;
                  border-radius:0;
                  top:unset;
                  border:0;
                }
                .girl-img {
                  left:-6%;
                }
              }
              @media screen and (min-width:768px) {
                .girl-img {
                  height:510px;
                  width:auto;
                }
                .girl-img2 {
                  height:100%;
                  width:auto;
                  right:0;
                }
              }
              
              @media screen and (min-width:992px) {
                .girl-img {
                  left:0%;
                }
              }
              @media screen and (min-width:1200px) {
                .girl-img {
                  left:9%;
                }
                .girl-img2 {
                  right:5%;
                }
              }
              @media screen and (min-width:1600px) {
                .girl-img {
                  left:20%;
                }
                .girl-img2 {
                  right:20%;
                }
              }
              @media screen and (min-width:1920px) {
                .girl-img {
                  left:26%;
                }
                .girl-img2 {
                  right:26%;
                }
              }
              @media screen and (min-width:2200px) {
                .girl-img {
                  left:30%;
                }
              }
            }
            `}</style>
			</Helmet>
			<header className="full-width full-hero-medium circle-image full-secondary pb-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-10 mx-auto">
							<div className="row">
								<div className="col-sm-12 col-md-6 pb-sm-60">
									<h1 className="text-lightest pt-80 pt-md-30 pt-sm-20 mt-0 text-xs-500">
										Virtual Voicemail Number
									</h1>
									<h2 className="subtitle pt-20">
										Choose a local number from any UK town or city for your virtual voicemail number &amp; have calls delivered straight to your email. Just &pound;{this.state.packInfo.rental} per month
									</h2>
								</div>
								<div className="d-none d-md-block col-md-6 float-end">
									<img
										src="/images/voicemail.png"
										alt="Virtual voicmail"
										style={{ maxWidth: '100%' }}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			<div className="full-width full-dull text-xs">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<TextBannerWidget textDark={true} />
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pt-60 pb-60">
				<div className="container">

					<div className="row pl-30 pr-30 pl-sm-5 pr-sm-5">
						<div className="col-lg-8 mx-auto">
							<h2 className="text-center text-500 mt-0 pt-0 mb-60">Easily setup your virtual voicemail to email number within minutes</h2>

							<p className="text-500">
								We understand that answering &amp; returning phone calls in a timely manner is extremely important but also time consuming and can take focus away from other tasks. Returning a calls can collecting messages has never been easier.
							</p>
							<p className="mt-30 text-500 text-cta">
								Voicemail to Email
							</p>
							<p className="mt-30">
								With a virtual voicemail system you get a dedicated phone number that can go straight to an automated voicemail system which will email you a copy of the voicemail instantly. You can even access voicemails any time via our website.
							</p>
							<p className="mt-30">
								The email includes an audio attachment and a transcription so you know what the voicemail is about without having the listen.
							</p>

						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pt-100 pb-100">
				<div className="container text-center">
					<h4 className="text-500 mt-0 mb-20">Incredible Value! All for <span className="text-cta">JUST &pound;{this.state.packInfo.rental} </span>per month</h4>
					<p className="mb-30">
						Get started with Virtual Vociemail today
					</p>
					<Button variant="cta" size="lg" onClick={() => this.scrollTo('chooseNumber')}>
						Choose Your Number
					</Button>
				</div>
			</div>

			<div className="full-width bg-light pt-60 pb-60">
				<div className="container text-center">
					<h4 className="text-center text-500 mt-0 pt-0 mb-60">
						Sign up for  Virtual Voicemail number &amp; get:
					</h4>

					<div className="row row-flex flex-nopad text-center mt-50 flex-3-col">
						<div className="col-sm-6 col-md-4 col-xs-12 mb-0 p-30">
							<div className="icon-block icon-cta svg mb-20">
								<SvgRender icon="outgoing-call" svgWidth="40px" svgClassName="mt-20" />
							</div>
							<p className="lead text-500">Outbound Calling</p>
							<p className="mb-30">Call back your callers presenting <br />your voicemail number</p>
						</div>
						<div className="col-sm-6 col-md-4 col-xs-12 mb-0 p-30">
							<div className="icon-block icon-cta svg mb-20">
								<SvgRender icon="voicemail-transcription" svgWidth="40px" svgClassName="mt-15" />
							</div>
							<p className="lead text-500">Voicemail Transcription</p>
							<p className="mb-30">No need to listen, when you can <br />read the voicemail.</p>
						</div>
						<div className="col-sm-6 col-md-4 col-xs-12 mb-0 p-30">
							<div className="icon-block icon-cta svg mb-20">
								<SvgRender icon="voicemil-to-email" svgWidth="40px" svgClassName="mt-15" />
							</div>
							<p className="lead text-500">Voicemail To Email</p>
							<p className="mb-30">Receive the voicemail in audio <br />format straight to your inbox</p>
						</div>
						<div className="col-sm-6 col-md-4 col-xs-12 mb-0 p-30">
							<div className="icon-block icon-cta svg mb-20">
								<SvgRender icon="voicemail" svgWidth="40px" svgClassName="mt-20" />
							</div>
							<p className="lead text-500">Unlimited Voicemails<span title={`Subject to fair usage of ${this.state.packInfo.seconds / 60} minutes of voicemails per month.`}>*</span></p>
							<p className="mb-30">There are no limits on the amount of <br />voicemails you can receive
								<br />
								<br />
								<span className="text-xs">
									*Subject to fair usage of {this.state.packInfo.seconds / 60} minutes of voicemails per month.
								</span>
							</p>
						</div>
						<div className="col-sm-6 col-md-4 col-xs-12 mb-0 p-30">
							<div className="icon-block icon-cta svg mb-20">
								<SvgRender icon="custom-prompts" svgWidth="25px" svgClassName="mt-20" />
							</div>
							<p className="lead text-500">Custom prompts &amp; voices</p>
							<p className="mb-30">Customise the voicemail message</p>
						</div>
						<div className="col-sm-6 col-md-4 col-xs-12 mb-0 p-30">
							<div className="icon-block icon-cta svg mb-20">
								<SvgRender icon="suitcase" svgWidth="40px" svgClassName="mt-15" />
							</div>
							<p className="lead text-500">Professional Business Image</p>
							<p className="mb-30">Display your company name &amp; number</p>
						</div>
					</div>
				</div>
			</div>

			<ImageLeftFullWidth
				MainContentClass="pt-60 pb-60 pt-xs-0 pb-xs-30 bg-dull"
				bgImgPosition="60% 8%"
				ImgURL="/images/26.jpg"
				RightImageClass="d-none d-sm-block"
				TextCopy={
					<>
						<h2 className="text-500 mb-30 mt-0 pt-xs-40 text-dark h3">What is a virtual voice mail?</h2>
						<p className="text-400 text-dark pb-10">
							Virtual voicemail is a feature offered by cloud-based phone system providers which allows you to store your messages online so they’re easily accessible from anywhere.
						</p>
						<p className="text-400 text-dark pb-10">
							You can then simply listen to your voicemail messages from a Web portal, an app or as an attachment in an email instead of having to dial a number to retrieve your messages.
						</p>
						<p className="text-400 text-dark pb-10">
							Having your voicemails hosted in the cloud also gives you and your team the ability to use some great business features associated with having a virtual line, such as professional greetings, separate voicemail boxes for each extension and custom open and close times.
						</p>
						<p className="text-400 text-dark pb-10">
							Moving to a virtual voicemail really gives you the power and freedom you need in your business.
						</p>
						<div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 mt-20 zoom">
							<p className="text-500 pt-20 text-dark">
								Easy to manage
							</p>
							<p className="text-400 pt-10 text-dark">
								A local virtual phone number is a number associated with any major local town or city. These numbers see an average increase of 22% or more calls from the local area.
							</p>
						</div>

						<div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 zoom">
							<p className="text-500 pt-20 text-dark">
								More convenient
							</p>
							<p className="text-400 pt-10 text-dark">
								Access your voicemails without the need of your work phone and check your messages online at a time that's convenient to you. You can even access voicemail messages directly from your email or an alternative phone.
							</p>
						</div>

						<div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 zoom">
							<p className="text-500 pt-20 text-dark">
								Saves you time
							</p>
							<p className="text-400 pt-10 text-dark">
								Access your voicemail messages instantly without having to wait for any prompts or introductions. You get the heart of messages straight away with a simple click.
							</p>
						</div>

						<div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 zoom">
							<p className="text-500 pt-20 text-dark">
								More professional
							</p>
							<p className="text-400 pt-10 text-dark">
								Give your callers a professional and business-grade voicemail experience by recording a personalised greeting or easily upload individual recordings for each of your team members.
							</p>
						</div>
					</>
				}
			/>

			<div className={'full-width text-center pb-100 pt-100 bg-dark text-lightest'}>
				<div className="container">
					<div className="row">
						<div className="w-800px center-block">
							<h3 className={'text-500 mb-40 mt-0 pt-0 text-lightest'}>Get Started With Virtual Voicemail</h3>
							<p className="text-md">
								Visual voicemail is another name for virtual voicemail and is an app-based voicemail service accessed using a mobile.
							</p>
							<p className="pb-40">
								This provides a central portal where voicemails are stored automatically and accessed as individual recordings which can be selected, played, deleted or shared directly from your phone or device.
							</p>

							<Button variant="cta" size="lg" onClick={() => this.scrollTo('chooseNumber')}>
								Choose Your Number
							</Button>
						</div>
					</div>
				</div>
			</div>

			<ImageRightFullWidth
				MainContentClass="pt-60 pb-60 pt-xs-0 pb-xs-30 bg-light"
				bgImgPosition="60% 8%"
				ImgURL="/images/27.jpg"
				RightImageClass="d-none d-sm-block"
				TextCopy={
					<>
						<h3 className="text-500 text-dark">
							Virtual voicemail Phone Number
						</h3>
						<p className="text-400 text-dark pb-10">
							In order to use a virtual voicemail, you can either chose a new virtual line for your business or portal your existing phone line to our platform.
						</p>
						<p className="text-400 text-dark pb-10">
							You can choose a local number from any UK town or city for your virtual voicemail and take voicemails that are delivered straight to your email.
						</p>
						<p className="text-400 text-dark pb-10">
							You'll then be able to utilise over 40 additional and free business features that come with our virtual service which minimises missed calls and enhances your business.
						</p>

						<h3 className="text-500 text-dark pt-30">
							Voicemail messages
						</h3>
						<p className="text-400 text-dark pb-10">
							You can access your voicemail message from the mobile phone app, using an online account or playing your voicemail directly from an email attachment.
							This gives you the freedom to choose how you play your messages from almost any device.
						</p>

					</>
				}
			/>

			<div className="full-width bg-dull bt-1 bb-1 pb-70 pt-70" id="chooseNumber">
				<div className="container numbers-pages pl-sm-0 pr-sm-0">
					<div className="row">
						<div className="col-md-12 text-center pb-60">
							<h3 className="text-500">
								Choose the perfect Virtual Phone Number(s) for your business...
							</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-7">
							<PlansAndNumbersDropdown
								basket={basket}
								prefix={this.state.prefix}
								ddiLid={this.state.ddiLid?.value}
								hidePlans={true}
								//selectedPackage={this.state.selectedPackage}
								handleChangeNumberType={e => this.setState({ prefix: e })}
								//handleChangePackage={e => this.handleSelectPackage(e)}
								handleChangeAreaCode={e => this.setState({ ddiLid: e })}
								preventDefaultSelection={true}
								displayAll={true}

								WrapperClassName="mb-40"
								LabelsClassName="text-xs text-dark text-400"

								PlansButtonClassName="text-start bg-light pl-25 pr-25 b-0"
								PlanNameClassName="text-dark text-400"
								PlansResultsDropdownClassName="bg-light box-shadow"
								PlanNameClassNameSelected="text-primary text-500"

								NumberButtonClassName={'text-start bg-light pl-25 pr-25 ml-5 b-0 brad-l-20'}
								NumberNameClassName="text-dark text-400"
								NumbersResultsDropdownClassName="bg-light ml-5 box-shadow brad-l-20"
								NumberNameClassNameSelected="text-cta text-500"

								AreaCodeButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
								AreaCodeNameClassName="text-dark text-400"
								AreaCodeResultsDropdownClassName="bg-light box-shadow brad-l-0 brad-r-20"
								AreaCodeNameClassNameSelected="text-cta text-500"
							/>

							<DdiSelectorNew
								numberType={this.state.prefix}
								isSignup={false}
								currentBasket={basket}
								hideShowAll={false}
								isModal={false}
								hideLoadMore={false}
								isSimple={true}
								hideRegions={true}
								hidestars={false}
								isLoggedIn={false}
								isEditing={false}
								menuOpen={true}
								citysOnly={false}
								ddiLid={this.state.ddiLid?.value}
								handleUpdateBasket={(_, sbfNumber: SbfNumber) => {
									const item: LocalStorageItem<SbfNumber[]> = LocalStorageHelper.getItem<SbfNumber[]>('LandingPageNumberSelection')
									let sbfNumbers = item.value ?? []
									// add the number to the array if it doesn't already exist, otherwise remove it
									if (sbfNumbers.find(number => number.number === sbfNumber.number) !== undefined) {
										sbfNumbers = sbfNumbers.filter(number => number.number !== sbfNumber.number)
									} else {
										sbfNumbers.push(sbfNumber)
									}

									LocalStorageHelper.setItem<SbfNumber[]>('LandingPageNumberSelection', sbfNumbers)
									this.setState({})
								}}
							/>
						</div>
						<div className="col-sm-5">
							<LeadGenForm
								history={this.props.history}
								FormHeadingText="Instant signup"
								ReserveNumber={true}
								ButtonText="Create my account"
								hideLabels={true}
								showFees={true}
								CompanyName={true}
								basket={basket}
								inboundOnly={true}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light bb-1 pt-50 pb-50">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h4 className="text-500 h2 text-center pb-40">FAQ</h4>
							<div className="row-flex flex-2-col">
								<div className="col-xxs-12 col-xs-6 text-start bg-dull brad-10 mb-20 pl-30 pr-30 pt-30 pb-30">
									<h5 className="text-500">How do I turn on Virtual voicemail / visual voicemail?</h5>
									<p className="text-dark pt-10">
										To turn on your virtual voicemail or visual voicemail, simply go to your online account, go to settings, press the button under voicemail email alerts and hit save.
									</p>
								</div>

								<div className="col-xxs-12 col-xs-6 text-start bg-dull brad-10 mb-20 pl-30 pr-30 pt-30 pb-30">
									<h5 className="text-500">What is a visual voicemail on an iPhone?</h5>
									<p className="text-dark pt-10">
										Visual voicemail is just a visual representation of your voicemail messages on a device such as an iPhone or Android device. These usually show up as recording icons which when clicked on, play your voicemail and come with several options to select, forward or play those voicemail messages.
									</p>
								</div>

								<div className="col-xxs-12 col-md-6 text-start bg-dull brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
									<h5 className="text-500">Is ringless voicemail illegal?</h5>
									<p className="text-dark pt-10">
										Ringless voicemails are not legal. Unless you provide consent, however, the company sending the ringless voicemail must provide you with an easy way for you to opt out.
									</p>
								</div>

								<div className="col-xxs-12 col-md-6 text-start bg-dull brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
									<h5 className="text-500">Can I port my existing phone number over?</h5>
									<p className="text-dark pt-10">
										Yes, we hold porting agreements will the majority of telecommunication providers in the UK and can typically port your existing number to our platform in as little as 14 days.
									</p>
								</div>

								<div className="col-xxs-12 col-md-6 text-start bg-dull brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
									<h5 className="text-500">What else can I use a virtual line for?</h5>
									<p className="text-dark pt-10">
										You can use and call your virtual line just as you would call any other phone line. Virtual lines come with many additional services and benefits over a standard line such as:
									</p>
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 text-dark">
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Departmental selections (Press 1 for sales and 2 for support)
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Call Groups to route your callers through to different teams
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Capture every detail with call recording
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Set open and close times to fit your business hours
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Using a custom greeting, plus a huge range of on-hold music
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Using a custom greeting, plus a huge range of on-hold music
										</li>
									</ul>
								</div>

								<div className="col-xxs-12 col-md-6 text-start bg-dull brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
									<h5 className="text-500">Does virtual voicemail cost extra?</h5>
									<p className="text-dark pt-10">
										No, virtual voicemail is included and in fact, cloud-based phone systems are much more affordable than a standard phone line. Our unlimited packages are only £12.00 per month and you can receive and make calls without the worry of any big bills. All of our lines also come with over 40 free, business features so you don&apos;t have to pay extra for the features you really need.

									</p>
								</div>

								<div className="col-xxs-12 col-md-6 text-start bg-dull brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
									<h5 className="text-500">How can I forward voicemail to email?</h5>
									<p className="text-dark pt-10">
										This is done automatically for you. Any voicemail you receive will be sent in real-time to an email address of your choosing.
									</p>
								</div>

								<div className="col-xxs-12 col-md-6 text-start bg-dull brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
									<h5 className="text-500">What are the ways I can check my voicemail?</h5>
									<p className="text-dark pt-10">
										You can check your messages in 3 ways.
										<br /><br />
										1. Messages are automatically sent to you via email
										<br />
										2. Messages automatically appear in your app
										<br />
										3. You can access your voicemails by logging into your online account.
									</p>
								</div>
								<div className="col-xxs-12 col-md-6 text-start bg-dull brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
									<h5 className="text-500">How do I record a voicemail greeting?</h5>
									<p className="text-dark pt-10">
										Recording your own greetings is easy using the app. Simply open the app, click on the button called prompts, click voicemail and record a new prompt.
									</p>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pt-100 pb-100">
				<div className="container text-center">
					<h4 className="text-500 mt-0 mb-20">Incredible Value! All for <span className="text-cta">JUST &pound;{this.state.packInfo.rental} </span>per month</h4>
					<p className="mb-30">
						Get started with Virtual Vociemail today
					</p>
					<Button variant="cta" size="lg" onClick={() => this.scrollTo('chooseNumber')}>
						Choose Your Number
					</Button>
					<p className="text-xs pt-40">
						*Subject to fair usage of {this.state.packInfo.seconds / 60}  minutes of voicemails per month.
					</p>
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

		</>
  }


}

export default connect(
	(state: ReduxState) => {
    return ({
      monthlyPayment: state.home.productDetails.monthlyFirstPayment,
      isLoggedIn: isLoggedIn(state),
    })
  },
	{
		handleAddToBasket: getSignup.handleAddToBasket,
		handleRemoveFromBasket: getSignup.handleRemoveFromBasket,
    handleShowCallbackForm
  }
)(VirtualVoicemail)
