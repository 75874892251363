import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CallbackRequest from '../../../components/CallbackRequest'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../../selectors'

class Redirects extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <header style={{ backgroundImage: "url('/images/backgrounds/redirects.jpg')" }} className="full-width full-hero-small">
                <h1>Redirecting calls</h1>
                <h2 className="subtitle text-center">Powerful, automatic, call routing to ensure you never miss a call</h2>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto pb-30">
                            <h4 className="text-500 pt-30 pb-10">What is call forwarding or “call redirects”?</h4>
                            <p>
                                Call forwarding is a feature that lets you redirect your incoming calls to another telephone number. These redirect numbers can be landlines, mobiles, user extensions or call groups.
                           </p>
                           <p>
                                Being able to redirect your calls is one of the main benefits of a cloud-based VoIP system as you’re able to change or optimise where your calls are redirected to depending on:
                           </p>
                            <ul className="fancy cta text-400 pl-30 pt-20 pb-20">
                                <li className="pb-10">
                                    Changes to your business
                                </li>
                                <li className="pb-10">
                                    Staffing levels
                                </li>
                                <li className="pb-10">
                                    Busy periods
                                </li>
                                <li className="pb-10">
                                    Seasonal changes
                                </li>
                                <li className="pb-10">
                                    The day of the week
                                </li>
                                <li className="pb-10">
                                    The time of day
                                </li>
                                <li className="pb-10">
                                    In the event of a disaster
                                </li>
                            </ul>
                            <h4 className="text-500 pb-10">
                                Multiple Redirects
                            </h4>
                            <p>
                                Being able to redirect a call to more than one number has many advantages over a standard phone line.
                            </p>
                            <p>
                                If the call isn’t answered by your first redirect within a predetermined time frame, the system will automatically route the call to your next redirect in your list.  When the system has tried each of your redirects in turn, its repeats this process until the call has been answered.
                            </p>
                            <p>
                                There are several features you can use to help keep your caller on the line whilst the system tries your redirects in turn such as <Link to={'/music-on-hold'} className="underline text-cta">on-hold music</Link>, <Link to={'/custom-prompts'} className="underline text-cta">call prompts</Link> and call position announcement.
                            </p>

                            <h4 className="text-500 pb-10 pt-30">
                                Redirect Sequence
                            </h4>
                            <p>
                                There are only two ways you can direct the sequence of your call redirects.
                            </p>
                            <p className="pb-20 pt-10">
                                <span className="text-500">1. Sequential</span>
                                <br/>
                                “Sequential” will route calls to your redirects in order, which helps you to prioritise specific users or teams.
                            </p>
                            <p>
                            <span className="text-500">2. Load Balanced</span>
                                <br/>
                                “Load Balanced” will equally distribute calls amongst your redirects based on the call answer rate which is ideal for sales teams.
                            </p>

                            <h4 className="text-500 pb-10 pt-30">
                                Call groups and teams
                            </h4>
                            <p>
                                You can further increase the flexibility of redirects by utilising groups or “Teams”. By creating groups of user extensions such as “Sales” or “Support”, you can use these as individual redirects giving you complete flexibility to manage larger teams.
                            </p>
                            <p>
                                If you have a large team, you may want learn more about <Link to={'/departments-selection'} className="underline text-cta">Departments</Link>.
                            </p>

                            <h4 className="text-500 pb-10 pt-30">
                                Time of day routing
                            </h4>
                            <p>
                                With “Time of day routing”, you can automatically change the redirects of your calls based on the time of day or the day of the week.
                            </p>
                            <p>
                                <Link to={'/time-of-day-call-routing'} className="underline text-cta">Watch a video explaining Time of day call routing</Link>.
                            </p>

                            <h4 className="text-500 pb-10 pt-30">
                                Disaster Recovery
                            </h4>
                            <p>
                                Having the ability to change your call redirects from any browser or smartphone allows you to adapt instantly in the event of a disaster or significant change to your business.
                            </p>
                            <p>
                                <Link to={'/business-disaster-recovery'} className="underline text-cta">Learn more about Disaster Recovery</Link>.
                            </p>

                            <h4 className="text-500 pb-10 pt-30">
                                Call Answering
                            </h4>
                            <p>
                                We also have teams of trained and experienced agents on standby to answer your calls for you. You can add these teams as your main redirect number for us to take all of your calls or a secondary overflow to help pick up the calls you not able to answer.
                            </p>
                            <p className="pb-40">
                                <Link to={'/telephone-answering-service/'} className="underline text-cta">Learn more about Call Answering</Link>.
                            </p>
                            <div className="ratio ratio-16x9 pt-30 brad-20">
                                <iframe src="https://www.youtube.com/embed/F7SQ-xGK9g4" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            </div>
                           </div>
                    </div>
                </div>

                <div className="full-width bg-dull text-center p-30 mt-30 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <h4 className="text-400 pb-30">Do you have any more questions about Redirects?</h4>
                                <h5 className="mb-30 pb-30">Call us on <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a>, email <a href="support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a> or request a callback below. <br />Our offices are open Monday to Friday 8:30am to 6pm.</h5>
                                <p></p>
                                <CallbackRequest subject="Callback request from Departments page" isForm={true} />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(Redirects)
