import React from 'react'
import { Modal, Button } from "react-bootstrap";
import Toggle from 'react-bootstrap-toggle'
import { productBillingOption, ProductIds } from "../../constants/Constants";
import { fetchHelper } from '../../helpers/fetchHelper'
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import * as fromSbf from "../../store/SBF";
import * as fromHome from "../../store/Home";
import LoadingNotification from "../../components/LoadingNotification";
import ChoosePayment from "../purchase/ChoosePayment";

/*

This component displays a simple toggle button for enabling/disabling call transcription.

Call transcription requires that call recording is enabled on the customer account. This components takes
care of prompting the user and giving them the ability to add call recording if they don't already have it.

*/
class CallTranscriptionToggle extends React.Component {

  constructor (props) {
    super(props)
		this.state = {
			CallTranscriptionEnabled: false,
			ShowCallrecordingModal: false,
			firstPaymentAmount: 0
		};

    this.state = { CallTranscriptionEnabled: false }
  }

	// make sure that we have latest prices for any upgrades this component will promote.
  getPrices () {
		this.props.getProductDetails([
			{ productId: ProductIds.callRecordAll, monthlyOption: productBillingOption.callRecordingAllBillingOptionMonthly }
		]);
  }

	// check if the customer has transcription enabled or not
	HasCallTranscriptionTurnedOn() {
		/* check if the customer has transcription and call recording both enabled */

    const url = 'api/CallTranscription/HasCallTranscriptionTurnedOn'
    fetchHelper.getJson(url)
      .then(res => {
				this.setState({ CallTranscriptionEnabled: res.data });
      }).catch(err => console.error(err))

	}

	componentDidMount() {
		this.getPrices();
		this.HasCallTranscriptionTurnedOn();

  }

	handleCallTranscriptionEnabledChange(hasCallRecording) {

		// if user attempts to turn on transcription but doesn't have call recording then sell it to them!
		if (!hasCallRecording) {
			// user doesn't have call recording which is required for transcription...
			// show them an overlay to promote call recording and confirm if they want it adding.

			let crAll = this.props.productDetails.find(prod => prod.productId === ProductIds.callRecordAll && prod.billingOptionId === productBillingOption.callRecordingAllBillingOptionMonthly);

			this.setState({ ShowCallrecordingModal: true, wpAmount: crAll.monthlyPrice.repeatPaymentAmount, firstPaymentAmount: crAll.monthlyPrice.firstPaymentAmount });

		} else {

			let url = ''

			if (this.state.CallTranscriptionEnabled) {
				url = 'api/CallTranscription/TurnOffCallTranscription';
			}
			else {
				url = 'api/CallTranscription/TurnOnCallTranscription';
			}

			fetchHelper.postJson(url).then(res => {

				if (res.data == true) {
					this.setState({ CallTranscriptionEnabled: !this.state.CallTranscriptionEnabled });
				}

			}).catch(err => console.error(err));

		}

  }

	// activate call recording on this customer's accounts, add the subscription and take first payment.
	// if call recording is successfully added and any payment taken then carry on enabling transcription for them.
	// if it isn't then pop them the ability to add payment details using the information returned by the below
	// API call.
	purchaseCallRecording() {

		let context = this;

		this.setState({ loading: true }, () => {
			let url = 'api/Purchase/PurchaseCallRecording';

			let crAll = this.props.productDetails.find(prod => prod.productId === ProductIds.callRecordAll && prod.billingOptionId === productBillingOption.callRecordingAllBillingOptionMonthly);

			let amount = crAll.monthlyPrice.firstPaymentAmount;

			fetchHelper.postJson(url, {
				ProductId: ProductIds.callRecordAll,
				billingOption: productBillingOption.callRecordingAllBillingOptionMonthly,
				IsUnlimited: false,
				Amount: amount
			}).then(res => {

				if (res.data && res.data.isSuccess === true) {

					this.CallRecordingSucesfullyPurchased();

				} else {
					context.setState({ wpAmount: amount, key: res.data.key }, () => {
						context.props.handleShowPaymentOptionsModal();
					});
				}

			});
		})
	}

	CallRecordingSucesfullyPurchased() {
		this.setState({ ShowCallrecordingModal: false })
		this.props.handleCallRecordingAllEnableChange(true);
		this.handleCallTranscriptionEnabledChange(true);
	}

  render () {
		let crPrice = 0;
		if (this.props.productDetails) {
			let crAll = this.props.productDetails.find(prod => prod.productId === ProductIds.callRecordAll && prod.billingOptionId === productBillingOption.callRecordingAllBillingOptionMonthly);

			if (crAll) {
				crPrice = crAll.monthlyPrice.repeatPaymentAmount;
			}
		}
    return <>
			<div className={`text-center ${this.state.CallTranscriptionEnabled ? "" : "mt-30 brad-10 p-30"}`} style={{ backgroundColor: this.state.CallTranscriptionEnabled ? "" : "#d6e8ef" }}>
				{this.state.CallTranscriptionEnabled &&
					<>
						<p className="lead text-500">No longer need access to call transcriptions or keyword triggers?</p>
						<p className="mb-30">Remember, you can switch it back on at anytime. However, any calls answered while this feature is turned off will <strong>not</strong> be transcribed, so you'll be unable to search them when turning this feature back on.</p>
					</>
				}

				<Button variant={this.state.CallTranscriptionEnabled ? "danger" : "cta"} size={this.state.CallTranscriptionEnabled ? "" : "lg"}
					onClick={() => this.handleCallTranscriptionEnabledChange((this.props.services.hasUnlimitedCallRecording || this.props.services.hasCallRecordingAll))}>
					{this.state.CallTranscriptionEnabled ? 'Disable Transcription' : 'Activate Now'}
				</Button>

				{!this.state.CallTranscriptionEnabled &&
					<p className="text-sm mt-10 mb-0">Budget-friendly rate of 5p per minute of transcribed calls. <em>Cancel anytime.</em></p>
				}
			</div>

			<Modal size="sm" show={this.state.ShowCallrecordingModal} onHide={e => this.setState({ ShowCallrecordingModal: false })}>
				<Modal.Header closeButton className="bb-0 p-0">
				</Modal.Header>
				<Modal.Body className="text-center">
					<h2 className="text-500 pt-0 mb-20 text-lg">Call Recording is required before you can activate this feature.</h2>
					<p>Unlock the power of call transcriptions with SwitchboardFREE's efficient Call Recording subscription. Not only will you gain access to detailed transcriptions of your calls, but you'll also unlock a wealth of benefits that come with call recording:</p>

					<ul className="styled-list success rounded text-start text-400 mt-20 pl-0">
						<li className="pb-10"><strong>Improved Quality Assurance:</strong> Revisit calls to ensure top-notch customer service.</li>
						<li className="pb-10"><strong>Compliance Confidence:</strong> Maintain a record of calls for regulatory compliance.</li>
						<li className="pb-10"><strong>Performance Enhancement:</strong> Use real interactions for training and improvement.</li>
						<li className="pb-10"><strong>Dispute Resolution:</strong> Recorded calls provide undeniable evidence if disputes arise.</li>
						<li className="pb-10"><strong>Insight Collection:</strong> Gain valuable insights into customer needs and preferences.</li>
					</ul>

					<p>Enjoy all of these benefits at no cost today and then only &pound;{this.state.wpAmount} per month from your next billing day.</p>
					<p>Plus, with the flexibility to cancel at any time, you're always in control</p>

					{this.state.loading ?
						<LoadingNotification
							className="mt-30"
							message={"Please wait..."}
							isLoading={true}
							textColor="text-dark"
						/>
						:
						<>
							<Button variant="cta" size="lg" className="btn-block mt-30" onClick={e => { this.purchaseCallRecording() }}>Add To My Account</Button>

							<p className="text-sm mt-10">
								{(this.props.services.hasUnlimitedCallRecording || this.props.services.hasCallRecordingAll) ?
									<>
										Just 5p per minute. Cancel anytime.
									</>
									:
									<>
										Just &pound;{crPrice} per month, plus 5p per minute. Cancel anytime.
									</>
								}
							</p>
						</>
					}

					<ChoosePayment
						// immediate payment amount
						total={this.state.firstPaymentAmount}
						purchaseData={this.state.key}
						clientId={this.props.clientId}
						email={this.props.userDetails.email}
						name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
						// indicates that if GoCardless is chosen that we wish to create a new mandate (as opposed to a single instant bank payment)
						mandate={true}
						// if the payment method chosen involves sending the user off-page then once payment is processed they will be returned to this location
						returnRoute="/customer/call-transcription/"
						// triggered when payment agreement is successfully setup and the payment flow doesn't take the user away from this page
						paymentSuccess={() => {
							this.CallRecordingSucesfullyPurchased();
						}}
						// triggered when payment agreement is not successfully setup and the payment flow doesn't take the user away from this page
						paymentFailure={() => {
							alert('failure')
						}}
					/>
				</Modal.Body>
			</Modal>
		</>
  }
}

export default withRouter(connect(
	state => {
		return {
			services: state.sbfApp.enabledServices,
			userDetails: state.sbfApp.authentication,
			productDetails: state.home.productDetails.products
		};
	},
	{
		handleShowPaymentOptionsModal: fromSbf.handleShowPaymentOptionsModal,
		getProductDetails: fromHome.getProductPrice,
		handleCallRecordingAllEnableChange: fromSbf.handleCallRecordingAllEnableChange
	}
)(CallTranscriptionToggle)); 