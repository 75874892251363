import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { isLoggedIn } from '../selectors'
import { logout } from '../store/SBF'

const LogoutButton = (props) => {
  return props.isLoggedIn
    ? (
        <Route render={({ history }) => (
            <Button id="LogoutButton" variant={props.isMobile ? 'link' : 'cta'} className={`${props.classCustom ? props.classCustom : 'btn-rounded mr-0'}`} size="sm" onMouseDown={e => props.logout({ history, event: e })}>Logout</Button>
        )}/>
      )
    : null
}

// const mapStateToProps = state => ({
//     isLoggedOut: isLoggedOut(state)
// });

// export default connect(
//     mapStateToProps,
// )(LogoutButton);

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    logout
  }
)(LogoutButton)
