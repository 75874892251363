import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface TTNCProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const TTNC = (props: TTNCProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>TTNC vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>TTNC vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of TTNC vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Who are TTNC?</h2>
                            <p>TTNC are a call management services company providing their clients with a broad range of virtual phone numbers and call management features.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">TTNC number types</h2>
                            <p>TTNC provide all kinds of landline numbers – geographical 01 / 02; 03; 0800; 084, as well as international numbers. The acquisition price starts from £20 per year and depends on the memorability of each number.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">TTNC call plans</h2>
                            <p>The users have two options when choosing how to pay. They can select the pay-as-you-go or the monthly plan.</p>

                            <p className="text-500 text-lg text-dark">The monthly plan prices are:</p>
                            <ul>
                                <li>£10 / month for 500 minutes to landlines <b>OR</b> 155 minutes to mobiles</li>
                                <li>£15 / month for 800 minutes to landlines <b>OR</b> 275 minutes to mobiles</li>
                                <li>£25 / month for 1400 minutes to landlines <b>OR</b> 500 minutes to mobiles</li>
                                <li>£45 / month for 2810 minutes to landlines <b>OR</b> 1000 minutes to mobiles</li>
                                <li>£60 / month for 4000 minutes to landlines <b>OR</b> 1500 minutes to mobiles</li>
                            </ul>

                            <p className="text-500 text-lg text-dark">The PAYG tariffs are as follows:</p>
                            <ul>
                                <li>£10 for 500 minutes to landlines OR 100 minutes to mobiles</li>
                                <li>£25 for 1250 minutes to landlines OR 250 minutes to mobiles</li>
                                <li>£50 for 2500 minutes to landlines OR 500 minutes to mobiles</li>
                            </ul>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">TTNC call management packages</h2>
                            <p>Aside from a call plan, the customers have to choose a call management features package. There are four different packages, each upgrading the previous.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">EXTRA</h2>
                            <p>The Extra package costs £7 per month, and involves call screening, voicemail, call greeting, hunt groups, call stats, email and SMS alerts, call tracking, time-based forwarding and call blocking.</p>
                            <p>It is recommended as the most suitable package for sole traders and home office workers.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">ENHANCED</h2>
                            <p>TTNC’s Enhanced package builds on the Extra one, by feature a few more tools, such as an auto attendant, call screening, and the possibility to forward calls to up to 10 destinations.</p>
                            <p>The monthly cost of the package is £14, and it targets businesses with 2 or more employees.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">EVERYTHING</h2>
                            <p>This is the most advanced package by TTNC. The new features that come with it are call queuing, on hold music, call recording and group forwarding.</p>
                            <p>The Everything package costs £21 per month.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">ESSENTIAL</h2>
                            <p>The fourth package could be examined as separate from the other three. It enables the user to add only those features they need at the time of purchase. While some of the tools are included as standard (hunt group, voicemail, call stats, email and SMS alerts, fax to email), others are to be paid for.</p>
                            <p>The call whisper, missed call alert, call greeting, forward to all and call blocking features each cost £2 per month.</p>
                            <p>And the auto attendant, the call recording and the call queuing are each priced at £8 per month.</p>

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">TTNC vs. SwitchboardFREE comparison</h2>
                            <p>There is not a big difference between the features TTNC and SwitchboardFREE offer. The difference is in their pricing. While SwitchboardFREE do not charge extra for our features (bar the personal recording of a prompt or a greeting), most of the essential call management features offered by TTNC are part of the two most advanced, and therefore most expensive, packages.</p>
                            <p>In terms of call plans, there is more flexibility in the SwitchboardFREE packs, as these allow you to mix your landline and mobile minutes. The TTNC plans require you to choose only one of the two. Besides, the SwitchboardFREE prices are generally lower. You can get 1200 minutes to a landline for just £14.96 a month, and TTNC’s offer is 1250 minutes for £25.</p>
                            <p>Although TTNC have clarified the larger packages they have (aimed at businesses that take a lot of calls every month), you can always have a unique package with SwitchboardFREE, tailored according to your business’ needs. You just have to give us a call on 0203 189 1213 and we will be able to offer you a competitive price.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see TTNCs current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.ttnc.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(TTNC)
