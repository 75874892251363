export function getIdForTime (config) {
  const openSevenDays = (config.openMon && config.openTue && config.openWed && config.openThu && config.openFri && config.openSat && config.openSun)

  if (openSevenDays) {
    if (config.monOpen === '00:00' && config.tueOpen === '00:00' && config.wedOpen === '00:00' && config.thuOpen === '00:00' && config.friOpen === '00:00' && config.satOpen === '00:00' && config.sunOpen === '00:00' &&
            config.monClose === '23:59' && config.tueClose === '23:59' && config.wedClose === '23:59' && config.thuClose === '23:59' && config.friClose === '23:59' && config.satClose === '23:59' && config.sunClose === '23:59') {
      return 1
    }
  }

  const openFiveDays = (config.openMon && config.openTue && config.openWed && config.openThu && config.openFri && !config.openSat && !config.openSun)

  if (openFiveDays) {
    if (config.monOpen === '00:00' && config.tueOpen === '00:00' && config.wedOpen === '00:00' && config.thuOpen === '00:00' && config.friOpen === '00:00' &&
            config.monClose === '23:59' && config.tueClose === '23:59' && config.wedClose === '23:59' && config.thuClose === '23:59' && config.friClose === '23:59') {
      return 2
    }

    if (config.monOpen === '09:00' && config.tueOpen === '09:00' && config.wedOpen === '09:00' && config.thuOpen === '09:00' && config.friOpen === '09:00' &&
            config.monClose === '17:00' && config.tueClose === '17:00' && config.wedClose === '17:00' && config.thuClose === '17:00' && config.friClose === '17:00') {
      return 3
    }
  }

  return 4
}

export function getTimeForQuickOpenCloseId (openCloseId) {
  if (openCloseId === 1) {
    return {
      monOpen: '00:00',
      tueOpen: '00:00',
      wedOpen: '00:00',
      thuOpen: '00:00',
      friOpen: '00:00',
      satOpen: '00:00',
      sunOpen: '00:00',
      monClose: '23:59',
      tueClose: '23:59',
      wedClose: '23:59',
      thuClose: '23:59',
      friClose: '23:59',
      satClose: '23:59',
      sunClose: '23:59',
      openMon: true,
      openTue: true,
      openWed: true,
      openThu: true,
      openFri: true,
      openSat: true,
      openSun: true
    }
  } else if (openCloseId === 2) {
    return {
      monOpen: '00:00',
      tueOpen: '00:00',
      wedOpen: '00:00',
      thuOpen: '00:00',
      friOpen: '00:00',
      monClose: '23:59',
      tueClose: '23:59',
      wedClose: '23:59',
      thuClose: '23:59',
      friClose: '23:59',
      satOpen: '00:00',
      satClose: '00:00',
      sunOpen: '00:00',
      sunClose: '00:00',
      openMon: true,
      openTue: true,
      openWed: true,
      openThu: true,
      openFri: true,
      openSat: false,
      openSun: false
    }
  } else if (openCloseId === 3) {
    return {
      monOpen: '09:00',
      tueOpen: '09:00',
      wedOpen: '09:00',
      thuOpen: '09:00',
      friOpen: '09:00',
      monClose: '17:00',
      tueClose: '17:00',
      wedClose: '17:00',
      thuClose: '17:00',
      friClose: '17:00',
      satOpen: '00:00',
      satClose: '00:00',
      sunOpen: '00:00',
      sunClose: '00:00',
      openMon: true,
      openTue: true,
      openWed: true,
      openThu: true,
      openFri: true,
      openSat: false,
      openSun: false
    }
  }
}
