import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'
import NumberDescriptionSectionHelperDialog from './InstructionHelperDialogs/NumberDescriptionSectionHelperDialog'

const NumberDescriptionSection = props => (
    <>
    <div className="row bg-light p-30 zoom">
        <div className="col-md-5 pb-sm-10 number-description">
            <h5 className="text-secondary text-500 text-lg">
                {/* Number Description */}
            <span className="department">Department #{props.depNo}</span><span className="number">Number</span> Description
            </h5>
                <p className="text-400">
                Enter a description for your SwitchboardFREE number to help distinguish it on reports and missed call alerts.
                {/* Enter a description for this number (This will be used on call reports and missed call alerts). <a onClick={props.handleShowNumberDescriptionHelperInstruction}>More info</a> */}
                {/* Enter a description for this <span className="department">department</span><span className="number">number</span> (This will be used on call reports and missed call alerts). <a onClick={props.handleShowNumberDescriptionHelperInstruction}>More info</a> */}

                </p>

        </div>
            <div className="col-md-7 mt-10">
            <p>Enter description:</p>
                <NumberDescriptionSectionHelperDialog/>
                <input id="NumberDescriptionField" type='text' placeholder="eg. Sales Department" maxLength='64' className="form-control" onBlur={e => props.handleNumberDesctiptionNoFocus(props.numberDescription)} onFocus={e => props.handleNumberDescriptionHasFocus(props.numberDescription)} value={props.numberDescription} onChange={e => props.handleChangeNumberDescription(e.target.value)} />
            </div>
    </div>
    </>
)

const mapStateToProps = state => ({
  numberDescription: state.configuration.configuration.description,
  depNo: state.configuration.selectedConfiguration.depNo
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberDescriptionSection)

// export default connect(
//     state => state.configuration,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(IntroductionSection);
