import React from 'react'
import { Button } from 'react-bootstrap'
import SvgRender from '../../components/SvgRender'

function NumberTypeChooser (props) {
  return (
        <>
            {props.icon !== undefined &&
                <div className="icon-block mt-20">
                    <span className={'icon icon-'.concat(props.icon)}></span>
                </div>
            }

                <Button className={`number-type-chooser scale-up-hover brad-20 text-center b-1 box-shadow shadow-lighter ${props.lessPad ? 'less-pad' : ''}`} size="xs" variant={`${props.isActive ? 'secondary' : 'lightest'}`} onClick={e => props.onClick()} disabled={props.disabled}>
                    {props.numberTypeTitle === '0808' &&
                        <div className="corner-sash">
                            <span className="pt-5 pb-5 pl-20 pr-20 text-caps text-500 text-sm text-lightest box-shadow mr-10" style={{ whiteSpace: 'nowrap', background: 'linear-gradient(to right, rgba(92,184,92,1) 0%,rgba(119,209,119,1) 100%)' }}>NEW!</span>
                        </div>
                    }
                    <SvgRender icon="selected" />
                    <h4 className={'text-500 mb-20 mt-0 text-center mb-xs-0'}>
                        {props.numberTypeTitle}
                        <span className="text-xs block text-400 text-capitalize pt-10 pt-xs-5">
                            {props.numberTypeDescription}
                        </span>
                    </h4>
                    <Button className="btn-sm b-0 brad-10 btn-block btn-rounded pull-xs-right mb-xs-0" variant={props.isActive ? 'cta' : 'inactive'}>
                        {props.isActive ? 'Selected' : 'Select'}
                    </Button>
                </Button>

        </>
  )
}

NumberTypeChooser.propTypes = {}
NumberTypeChooser.defaultProps = {}

export default NumberTypeChooser
