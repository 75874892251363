import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { handleNotificationChange } from '../../../store/SBF'
import LoadingNotification from '../../LoadingNotification'

const _ = require('lodash')

class EmergencyContacts extends Component {
  static defaultProps = {}
  static propTypes = {}
  state = {
    isLoading: true
  }

  componentDidMount () {
    this.handleGetAddressDetails()
  }

  handleGetAddressDetails () {
    const context = this
    const url = 'api/Profile/GetEmergencyContactInfo'

    fetchHelper.getJson(url)
      .then(res => {
        context.setState({ isLoading: false, contacts: res.data })
      }).catch(err => console.error(err))
  }

  render () {
    return (
      this.state.isLoading === true
        ? <LoadingNotification className="mt-30" message={'Loading...'} isLoading={this.state.isLoading} textColor="text-dark"/>
        :				<>
					<div className="bg-light p-30 pb-40  brad-10 b-1 w-800px center-block mb-20" style={{ overflow: 'auto' }}>

						<h4 className="pb-20 text-400 text-center">Emergency Contact Details</h4>
						<p className="mb-40">Emergency address information is passed on to UK emergency services for use in 999 calls. It is a legal requirement to provide an accurate address for every phone number you hold.</p>
						<p>You can add a separate address for each phone number you have. Add or update an address below, edit the address to associate that particular address with an individual phone number from your account.</p>
						<p>You can update or amend these at any time.</p>

						{this.state.contacts && this.state.contacts.numbers && this.state.contacts.numbers.length > 0
						  ? <>
									<div className='row'>
										<div className='col-xs-12 text-right mb-20'>
											<Button variant="cta" size="sm" onClick={() => this.setState({ addressToAdd: {} })}>Add New Address</Button>
										</div>
									</div>

									<div className="row row-flex flex-3-col">
										{this.state.contacts.addresses.map((address, i) => (
											<div className="col-xs-12 col-md-4 brad-10 b-1 p-10 m-10 flex-container box-shadow pb-20">
												<p className="text-500 text-dullest pl-10 pr-10">
													Address #{i + 1}

												</p>
												<p className="text-400 m-0 text-sm pl-10">
													{address.premises}, <br/>{address.thoroughfare}, {address.locality}, <br/>{address.postcode}
												</p>
												<span className='text-cta underline text-xs w-100 ml-10 mt-20 text-400 cursor-pointer' onClick={() => this.setState({ addressToEdit: { address, number: i + 1, numbers: this.state.contacts.numbers } })}>Edit Address or Phone Numbers</span>
											</div>
										))}
										{this.state.contacts.addresses.length > 3
										  ? <>
												<div className='row visible-xxs'>
													<div className='col-xs-12 text-right pb-30 mt-30 mb-20'>
														<Button variant="cta" size="sm" onClick={() => this.setState({ addressToAdd: {} })}>Add New Address</Button>
													</div>
												</div>
											</>
										  :											<></>

										}
									</div>

									<div className="col-xs-12 bt-1 pt-30 pb-30 mt-30 pl-0 pr-0">
										<h4 className="mt-0 text-500 d-block">
											Unassigned Numbers
										</h4>
										<p>
											Below are numbers that still haven't been assigned an emergency contact.
										</p>
									</div>
									<div className="col-xs-12 pl-0 pr-0">
										{this.state.contacts && this.state.contacts.numbers && this.state.contacts.numbers.filter(f => f.addressId === 0).map((number, i) => (
											<div className="bb-1 p-10 pl-0 mb-5 mt-5">

														<span className="text-500 mr-10 mr-xs-0">
															{number.number}
														</span>
														<br className='visible-xs'/>
														<span className="text-italic text-sm text-400">
															To link this number to an address, simply edit, or add, an address above.
														</span>

											</div>
										))}
									</div>
								</>
						  :								<>
									<div className='b-1 brad-20 p-30 w-400px center-block box-shadow text-center mb-30'>
										<h4 className='text-500'>Oops!</h4>
										<p>Looks like you don&apos;t have any numbers on your account.</p>
										<p><Link to={'customer/numbers/new'} className="btn btn-cta mt-10">Get a new number</Link></p>
									</div>

								</>
						}

					</div>

					{this.state.addressToAdd &&
						<Modal size="sm" show={this.state.addressToAdd} onHide={() => this.setState({ addressToAdd: undefined })}>
							<Modal.Header closeButton className='bb-0'>
								<Modal.Title>Add a new address</Modal.Title>
							</Modal.Header>
							<Modal.Body className="p-40">
								<div className="row">
									<div className="col-xs-12">

											<label>Address Line 1</label>
											<input value={this.state.addressToAdd.premises} className="form-control mb-10"
												onChange={e => this.setState({ addressToAdd: { ...this.state.addressToAdd, premises: e.target.value } })} />
											<label>Town</label>
											<input value={this.state.addressToAdd.thoroughfare} className="form-control mb-10"
												onChange={e => this.setState({ addressToAdd: { ...this.state.addressToAdd, thoroughfare: e.target.value } })} />
											<label>County</label>
											<input value={this.state.addressToAdd.locality} className="form-control mb-10"
												onChange={e => this.setState({ addressToAdd: { ...this.state.addressToAdd, locality: e.target.value } })} />
											<label>Postcode</label>
											<input value={this.state.addressToAdd.postcode} className="form-control mb-10"
												onChange={e => this.setState({ addressToAdd: { ...this.state.addressToAdd, postcode: e.target.value } })} />
											<div className='text-center pt-30 pb-20'>
												<Button variant="cta" onClick={() => this.handleAddNewAddress()}>Save Address</Button>
											</div>

									</div>
								</div>
							</Modal.Body>
						</Modal>
					}

					{this.state.addressToEdit &&
						<Modal size="sm" show={this.state.addressToEdit} onHide={() => this.setState({ addressToEdit: undefined })}>
							<Modal.Header closeButton className='bb-0'>
								<Modal.Title>Address #{this.state.addressToEdit.number}</Modal.Title>
							</Modal.Header>
							<Modal.Body className="p-40">
								<div className="row">
									<div className="col-xs-12 bg-light b-1 box-shadow p-20 brad-20 mb-30">
										<div className='row'>
											<div className='col-xs-12 pb-20'>
												<p className="mt-0 text-sm float-start text-500 mb-0">
													Address details
												</p>

												{this.state.editAddress
												  ? this.isEmergancyAddressValid()
												    ?													<span className="text-500 text-lightest bg-cta pl-10 pr-10 pt-5 pb-5 brad-10 cursor-pointer text-sm float-end"
														onClick={() => this.handleSaveAddress()}>Save Changes</span>
												    :													<span className="text-500 text-lightest bg-dull pl-10 pr-10 pt-5 pb-5 brad-10 text-sm float-end"
													>Save Changes</span>
												  :													<span className="text-500 cta-hover text-secondary cursor-pointer text-sm float-end underline"
														onClick={() => this.setState({ editAddress: true })}>Edit Address</span>
												}
											</div>

											<div className="col-xs-12">
												<div className="text-400">
													{this.state.editAddress
													  ? <>
															<input value={this.state.addressToEdit.address.premises} className={ 'form-control mb-10 ' + (this.state.addressToEdit.address.premises.trim() === '' ? ' is-invalid' : 'is-valid')}
																onChange={e => this.setState({ addressToEdit: { ...this.state.addressToEdit, address: { ...this.state.addressToEdit.address, premises: e.target.value } } })} />
															<input value={this.state.addressToEdit.address.thoroughfare} className={ 'form-control mb-10 ' + (this.state.addressToEdit.address.thoroughfare.trim() === '' ? ' is-invalid' : 'is-valid')}
																onChange={e => this.setState({ addressToEdit: { ...this.state.addressToEdit, address: { ...this.state.addressToEdit.address, thoroughfare: e.target.value } } })} />
															<input value={this.state.addressToEdit.address.locality} className={ 'form-control mb-10 ' + (this.state.addressToEdit.address.locality.trim() === '' ? ' is-invalid' : 'is-valid')}
																onChange={e => this.setState({ addressToEdit: { ...this.state.addressToEdit, address: { ...this.state.addressToEdit.address, locality: e.target.value } } })} />
															<input value={this.state.addressToEdit.address.postcode} className={ 'form-control mb-10 ' + (this.state.addressToEdit.address.postcode.trim() === '' ? ' is-invalid' : 'is-valid')}
																onChange={e => this.setState({ addressToEdit: { ...this.state.addressToEdit, address: { ...this.state.addressToEdit.address, postcode: e.target.value } } })} />

														</>
													  :														<>
															{this.state.addressToEdit.address.premises},<br />
															{this.state.addressToEdit.address.thoroughfare},<br />
															{this.state.addressToEdit.address.locality}, <br />
															{this.state.addressToEdit.address.postcode}
														</>
													}
												</div>
											</div>
										</div>

									</div>

									<div className="col-xs-12 bg-light b-1 box-shadow p-20 brad-20 mb-30">
										<div className='row'>
											<div className='col-xs-12 pb-20'>
												<p className="mt-0 text-sm float-start text-500">
													Numbers linked to this address
												</p>

												{this.state.addNumbers
												  ? <span className="text-500 text-lightest bg-cta pl-10 pr-10 pt-5 pb-5 brad-10 cursor-pointer text-sm float-end"
														onClick={() => this.handleAddNumbers(this.state.addressToEdit.address.addressId)}>Save Changes</span>
												  :													<span className="text-500 cta-hover text-secondary cursor-pointer text-sm float-end underline"
														onClick={() => this.setState({ addNumbers: true })}>Add Number</span>
												}
											</div>
											<div className='col-xs-12 emergency-numbers-list' style={{ maxHeight: '300px', overflow: 'auto' }}>
												{this.state.addNumbers
												  ? this.state.addressToEdit.numbers.filter(f => f.addressId === 0).map((number, i) => (
														<div className="brad-5 bb-1 p-10 cursor-pointer" onClick={() => this.handleToggleNumberToAdd(i, number.id)}>
															<div className="row">
																<div className="col-xs-12 col-md-8">
																	<p className="m-0">{number.number}</p>
																</div>
																<div className="col-xs-12 col-md-4 text-right text-xs-center">
																	<span className="styled-checkbox checkbox-light float-end" style={{ margin: '-3px 0 0 0' }}>
																		<Form.Check inline
																			checked={number.checked} />
																	</span>
																</div>
															</div>
														</div>
												  ))
												  :													this.state.addressToEdit.numbers.filter(f => f.addressId === this.state.addressToEdit.address.addressId).map((number, i) => (
														<div className="brad-5 bb-1 p-10">
															<div className="row">
																<div className="col-xs-12 col-md-8">
																	<p className="m-0">{number.number}</p>
																</div>
																<div className="col-xs-12 col-md-4 text-right text-xs-center">
																	<Button size="xs" variant="danger" onClick={() => this.handleRemoveNumberFromAddress(number.id)}>Unlink</Button>
																</div>
															</div>
														</div>
												  ))
												}
											</div>
										</div>

									</div>
								</div>

							</Modal.Body>
						</Modal>
					}

				</>
    )
  }

  handleAddNewAddress () {
    const url = 'api/Profile/AddOrUpdateEmergencyContactAddress'

    fetchHelper.postJson(url, { ...this.state.addressToAdd })
      .then(res => {
        if (res.data === true) {
          this.setState({ addNumbers: false, addressToAdd: undefined }, () => {
            this.props.notify({ message: 'Your new address has been added.' })
            this.handleGetAddressDetails()
          })
        } else {
          this.props.notify({
            message: 'Unable to add your new address. Please try again.',
            isError: true
          })
        }
      }).catch(err => console.error(err))
  }

  handleToggleNumberToAdd (i, id) {
    const numbers = this.state.addressToEdit.numbers
    const index = numbers.findIndex(f => f.id === id)
    numbers[index] = {
      ...numbers[index],
      checked: !numbers[index].checked
    }

    this.setState({ addressToEdit: { ...this.state.addressToEdit, numbers } })
  }

  handleAddNumbers (id) {
    const url = 'api/Profile/AddNumbersToAddress'
    const data = {
      numbers: this.state.addressToEdit.numbers.filter(f => f.checked),
      addressId: id
    }

    fetchHelper.postJson(url, data)
      .then(res => {
        if (res.data === true) {
          this.setState({ addNumbers: false, addressToEdit: undefined }, () => {
            this.props.notify({ message: 'Your numbers have been added to your address.' })
            this.handleGetAddressDetails()
          })
        } else {
          this.props.notify({
            message: 'Unable to add numbers to your address. Please try again.',
            isError: true
          })
        }
      }).catch(err => console.error(err))
  }

  isEmergancyAddressValid () {
    let validAddress = true

    const address = { ...this.state.addressToEdit.address }

    if (address.premises.trim() === '') {
      validAddress = false
    }

    if (address.thoroughfare.trim() === '') {
      validAddress = false
    }

    if (address.locality.trim() === '') {
      validAddress = false
    }

    if (address.postcode.trim() === '') {
      validAddress = false
    }

    return validAddress
  }

  handleSaveAddress () {
    const validAddress = this.isEmergancyAddressValid()

    if (validAddress === false) {
      this.props.notify({
        message: 'Unable to save address. all address fields are mandatory',
        isError: true
      })
    } else {
      const url = 'api/Profile/AddOrUpdateEmergencyContactAddress'
      fetchHelper.postJson(url, { ...this.state.addressToEdit.address })
        .then(res => {
          if (res.data === true) {
            this.setState({ editAddress: false }, () => {
              this.props.notify({ message: 'Your address has been updated' })
              this.handleGetAddressDetails()
            })
          } else {
            this.props.notify({
              message: 'Unable to save address. Please try again.',
              isError: true
            })
          }
        }).catch(err => console.error(err))
    }
  }

  handleRemoveNumberFromAddress (id) {
    const url = `api/Profile/RemoveNumberFromAddress/${id}`
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data === true) {
          this.setState({ addressToEdit: { ...this.state.addressToEdit, numbers: this.state.addressToEdit.numbers.filter(f => f.id !== id) } }, () => {
            this.props.notify({ message: 'Your number has been unlinked from this address.' })
            this.handleGetAddressDetails()
          })
        } else {
          this.props.notify({
            message: 'Unable unlink number. Please try again.',
            isError: true
          })
        }
      }).catch(err => console.error(err))
  }
}

export default connect(
  state => {

  }, {
    notify: handleNotificationChange
  })(EmergencyContacts)
