import React from 'react'
import { connect } from 'react-redux'

class PhoneAppRedirect extends React.Component {
  render () {
    function iOS () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    }

    if (iOS() === true) {
      window.location.replace('https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509')
    } else {
      window.location.replace('https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB')
    }

    return null
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(PhoneAppRedirect)
