import React from 'react'
import { connect } from 'react-redux'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

class CallReporting extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount/><DisplayMainFooter/> <DisplayMainHeader/>
        <header style={{ backgroundImage: "url('/images/backgrounds/call-stats-bg2.jpg')" }} className="full-width full-hero-small">
   </header>
    <div className="container-styled">
    <div className="container">
       <div className="row">
            <div className="col-md-12">
                <h1 className="text-center pb-30">SwitchboardFREE’s call stats features</h1>

                <div className="bb-1 pb-20 text-center">
                    <h4>See how SwitchboardFREE has helped different types of companies improve their phone handling and transformed their business.</h4>
                </div>
            </div>
         </div>
        <div className="row">
            <div className="col-md-12 mt-20">
                <p>
                SwitchboardFREE complements your new virtual number with plenty of features to enhance your experience with the product.
                </p>
                <p>
                Our call reporting features will feed you with useful stats to measure the success of your business activities.
                </p>
                <p>
                You can browse information about the number of calls you have received, the time of each call, its length and whether it was answered,
                missed or a voicemail had been left. The data you accumulate will allow you to understand your business better – when are its busiest hours,
                what your callers are most interested in and how they respond to your marketing campaigns.
                </p>
                <h4 className="pt-30">Clever lists to organise your calls</h4>
                <p>
                All your missed calls will be displayed on your account in an easy to view graph. We wanted to eliminate the risk of you inadvertently skipping one or more call entries, which is equal to missing sales opportunities.
                </p>
                <p>
                You can combine the clever missed call list with a unique caller list. Create an instant database of all your unique callers and avoid the confusion of having multiple repeated numbers on your lists. Even if a caller has called you more than once, your report will feature their number only once.
                </p>
                <p>
                The unique caller list is useful if you want to measure the refined response to your campaign and identify the number of unique individuals who have engaged with it.
                </p>
                <p>
                For example, you can generate a report on the business departments your callers are predominantly choosing to be put through to. This will give you a better understanding of the aspects of your business the customers have the most enquiries about.
                </p>
                <h4 className="pt-30">
                Identify your busiest call periods
                </h4>
                <p>
                It is possible to access reports on the times of the day your business receives most calls. It is valuable to know when to deploy your staff and focus your attention on handling phone calls. You wouldn’t like to have your employees out for lunch when you are getting the most calls. Similarly, you will find out if you are wasting time and money by just waiting for calls at periods when the phone is not ringing at all.
                </p>
            </div>
        </div>
    </div>
        <div className="full-width full-secondary text-lightest  mt-20">
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="text-center">
                        Available call stats reports
                    </h4>
                    <ul className="fancy pt-20 pl-0">
                    <div className="col-md-6">
                        <li className="pb-10"><strong>Overview</strong> – an overview of all calls and statistics</li>
                        <li className="pb-10"><strong>Summary of all calls</strong> – a count of calls: answered and missed calls, and voicemails</li>
                        <li className="pb-10"><strong>List of all calls</strong> – a list of all calls received on your number</li>
                        <li className="pb-10"><strong>List of answered calls</strong> – a list of all answered calls</li>
                        <li className="pb-10"><strong>List of missed calls</strong> – a list of all calls that were not answered</li>
                        <li className="pb-10"><strong>List of voicemails</strong> – a list of voicemails left by customers</li>
                        </div>
                        <div className="col-md-6">
                        <li className="pb-10"><strong>Callback list</strong> – list of callers to your number you have not yet spoken to</li>
                        <li className="pb-10"><strong>Call activity by time of the day</strong> – view your busiest hours and when you miss the most calls</li>
                        <li className="pb-10"><strong>Department selection report (summary)</strong> – view how many people are choosing which department to be put through to</li>
                        <li className="pb-10"><strong>Department selection report (call listing)</strong> – view which department each individual caller chose to be put through to</li>
                        </div>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="ratio ratio-16x9 mb-30 mt-30">
                        <iframe className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/jGQbiUc9qi0" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(CallReporting)
