import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getEmptyBasket } from '../../helpers/utils'
import DisplayNoFooter from '../../components/DisplayNoFooter'
import DisplayNoHeader from '../../components/DisplayNoHeader'
import PlansAndNumbersDropdown from '../../components/PlansAndNumbersDropdown'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import { NumbersPremiumFilter } from '../../constants/Constants'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import * as getSignup from '../../store/Signup'

class LandingPageDDISelector extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    prefix: this.props.match.params.prefix && this.props.match.params.prefix.substring(0, 5),
    loadingNumbers: true,
    numbersPremiumFilter: NumbersPremiumFilter.all
  }

  render() {
    const basket = getEmptyBasket()
    const numbers = LocalStorageHelper.getItem('LandingPageNumberSelection')
    basket.numbers = numbers.value ?? []

    return <>
      <Helmet>
        <style type="text/css">{`
          body { padding: 0; }
          #lhnHelpOutCenter, #lhnHocButton { display: none !important; }
        `}</style>
      </Helmet>
      <DisplayNoFooter />
      <DisplayNoHeader />
      <div className="number-page" id="numbers">
          <PlansAndNumbersDropdown
            prefix={this.state.prefix && this.state.prefix.length <= 4 ? this.state.prefix : '01'}
            ddiLid={this.state.ddiLid?.value}
            defaultArea={this.state.prefix}
            handleChangeNumberType={e => this.setState({ prefix: e })}
            handleChangeAreaCode={e => this.setState({ ddiLid: e })}
            hidePlans={true}
            preventDefaultSelection={true}
            displayAll={true}

            WrapperClassName="mb-40"
            LabelsClassName="text-xs text-dark text-400"

            NumberButtonClassName={'text-start bg-light pl-25 pr-25 ml-5 b-0 inbound-only'}
            NumberNameClassName="text-dark text-400"
            NumbersResultsDropdownClassName="bg-light ml-5 box-shadow"
            NumberNameClassNameSelected="text-cta text-500"

            AreaCodeButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
            AreaCodeNameClassName="text-dark text-400"
            AreaCodeResultsDropdownClassName="bg-light box-shadow"
            AreaCodeNameClassNameSelected="text-cta text-500"
          />

          <DdiSelectorNew
            isIframe={true}
            numberType={this.state.prefix}
            isSignup={false}
            currentBasket={basket}
            hideShowAll={false}
            isModal={false}
            hideLoadMore={false}
            hideRegions={true}
            hidestars={false}
            isLoggedIn={false}
            isEditing={false}
            menuOpen={false}
            citysOnly={false}
            ddiLid={this.state.ddiLid?.value}
            handleUpdateBasket={(_, sbfNumber) => {
              // let item: LocalStorageItem<SbfNumber[]> = LocalStorageHelper.getItem < SbfNumber[] > ("LandingPageNumberSelection");
              const item = LocalStorageHelper.getItem('LandingPageNumberSelection')
              let sbfNumbers = item.value ?? []
              // add the number to the array if it doesn't already exist, otherwise remove it
              if (sbfNumbers.find(number => number.number === sbfNumber.number)) {
                sbfNumbers = sbfNumbers.filter(number => number.number !== sbfNumber.number)
              } else {
                sbfNumbers.push(sbfNumber)
              }
              LocalStorageHelper.setItem('LandingPageNumberSelection', sbfNumbers)
              this.setState({})
            }}
          />
      </div>
    </>
  }

  componentDidMount() {
    this.props.handleBasketInit()

    window.dataLayer.push({
      numberType: `${this.state.prefix}`,
      event: 'viewProduct'
    })
  }
}

export default withRouter(connect(
  state => {
    return {
      currentBasket: state.signup.packageInfo.currentBasket
    }
  },
  {
    handleAddToBasket: getSignup.handleAddToBasket,
    handleRemoveFromBasket: getSignup.handleRemoveFromBasket,
    handleBasketInit: getSignup.handleBasketInit
  }
)(LandingPageDDISelector))
