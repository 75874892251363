import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { PromptTypes } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import LoadingNotification from './LoadingNotification'
import PromptAudioPlayer from './PromptAudioPlayer'

class MohTracks extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    isLoading: true,
    tracks: undefined,
    catId: this.props.catId
  }

  closeModal (context) {
    context.setState({ tracks: undefined })
    context.props.hideModal()
  }

  render () {
    if (this.state.catId !== this.props.catId) { this.setState({ catId: this.props.catId }) }

    if (this.state.catId && this.state.catId > 0 && this.state.tracks === undefined) {
      const url = `api/Home/GetMusicTracksForCategory/${this.props.catId}`
      fetchHelper.getJson(url).then(res => {
        if (res.data && res.data.length > 0) {
          this.setState({ isLoading: false, tracks: res.data })
        } else {
          this.setState({ isLoading: false, tracks: undefined })
          this.closeModal(this)
        }
      }).catch(err => console.error(err))
    }
    return <Modal className="moh-playlist w-800px bg-darker" show={this.props.showModal} onHide={() => this.closeModal(this)}>
                    <Modal.Header className="bb-0 text-center d-block" closeButton>
                    <h2 className="text-cta mt-20 text-xs-500">{this.props.name}</h2>
                    </Modal.Header>

            <Modal.Body>
                <div className="text-center">
                    {/* <h4 className="underline">Christmas Playlist</h4> */}

                </div>

                {this.state.isLoading
                  ? <LoadingNotification className="mt-30" message={'Loading...'} isLoading={true} />
                  : <>
                        <div className="col-md-12 p-5 text-500 mb-10 d-none d-sm-block">
                            <div className="row">
                                <div className="col-sm-8 text-start">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            Song
                                        </div>
                                        <div className="col-sm-5">
                                            Artist
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    Sample
                                </div>
                            </div>
                        </div>
                        <ul className="pl-0 unstyled text-xs-center">
                            {this.state.tracks && this.state.tracks.map((track) =>
                                <li className="pt-10 pb-10 bb-1 brad-xs-10 b-xs-1 mt-xs-20 p-xs-20">
                                    <div className="row">
                                        <div className="col-sm-8 col-xs-12 text-xs-center text-400">
                                            <div className="row">
                                                <div className="col-sm-7 text-xs-lg pt-15 text-start">
                                                    &quot;{track.track}&quot;
                                                </div>
                                                <div className="col-sm-5 text-xs-md pt-15 text-xs-cta text-start">
                                                    {track.artist}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 col-xs-12 pt-5">
                                            <PromptAudioPlayer noMargin={true} promptId={track.mohId} promptType={PromptTypes.holdMusicTrack} />
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </>
                }

            </Modal.Body>
            <Modal.Footer className="bt-0">
                <Button variant="cta" size="large" className="d-block d-sm-none btn-block" onClick={() => this.closeModal(this)}>Close</Button>
            </Modal.Footer>
        </Modal>
  }
}
export default connect(
  state => {
    return state
  },
  {}
)(MohTracks)
