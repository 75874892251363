import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { isLoggedIn } from '../selectors'
import { handleShowCallbackForm } from '../store/SBF'

class StartingBusiness extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>A Guide To Starting A New Business </title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>A Guide To Starting A New Business </h1>
                <h2 className=" subtitle">Discover exactly how to start a new business &amp; where SwitchboardFREE can help</h2>
            </header>
            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <p>When you start a business, there are literally thousands of companies all promising to help you take your amazing idea and turn that into a profitable and successful business.  </p>
                            <p>This "secret" information or service usually comes with a hefty price tag,  a long commitment and is always touted as something your business can't live without! - Funny how that works isn't it? </p>
                            <p>However, the set of principles in this guide is designed to show you how you can increase the probability of success, whilst keeping your costs and commitments down to a bare minimum. </p>
                            <p>Out of the tens of thousands of businesses we've had the privilege to help, these two core principles are the primary drivers for success and longevity. </p>
                            <p>Our customers stated that in the initial stages of developing their business, it was very easy to get carried away with the fun of buying shiny things, However, unexpected costs and delays WILL come from every angle and without warning. I&apos;s therefore imperative that you start controlling your overheads from the outset. </p>
                            <p>Most new start-ups fail in the early stages due to a lack of working capital, so keeping your expenses and commitments down to a minimum may not be fun, but it is going to keep your head above water whilst you start to build that all-important momentum. </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Start With a Business Plan</h2>
                            <p>The process of writing a business plan is as important, if not more important, than the plan itself. Even if your business is not shown to anyone, It forces you to objectively consider every aspect of your new business. Owners with a business plan see growth 30% faster than those without one.</p>
                            <p>If you've not already drawn up a business plan then you can get some excellent free resources from <a href="https://www.princes-trust.org.uk/help-for-young-people/tools-resources/business-tools/business-plans" target="_blank" rel="noreferrer">The Prince's Trust website</a>. Here you can download a really useful business plan pack and get access to free templates and business tools ranging from budget control, all the way through to <a href="https://www.princes-trust.org.uk/help-for-young-people/tools-resources/business-tools/business-plans" target="_blank" rel="noreferrer">marketing</a>. </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Register Your Business</h2>
                            <p>If you have a business in the UK, then you need to register it with the UK government for it to be legally recognised and to ensure you are paying the right amount of tax.</p>
                            <p>This will cost you anywhere between &pound;12 and &pound;100 depending on what method you choose. The cheapest and quickest way to register is <a href="https://ewf.companieshouse.gov.uk/runpage?page=welcome" target="_blank" rel="noreferrer">online</a>. This shouldn't take long with the vast majority of requests being processed within 24 hours.</p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Business Banking</h2>
                            <p>You won't be able to use your personal account in place of a business account if you have either a limited company or an incorporated company, and your bank is likely to threaten to close your account if you make too many business transactions on your personal account. </p>
                            <p>There's been a lot of newcomers in this space recently and the costs of business accounts have come down. <a href="https://www.moneysupermarket.com/current-accounts/business-bank-accounts/results/" target="_blank" rel="noreferrer">Money Supermarket</a> have a great up-to-date list of these for you to compare, and I&apos;s worth noting that <a href="https://www.tide.co/company-registration/" target="_blank" rel="noreferrer">Tide </a> will also cover the cost of registering your business if you open an account with them.     </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Accounting</h2>
                            <p>Digital Accounting software helps you to stay on top of your business finances by making it easier to record, store and analyse your data, while reducing the opportunity for human error. It can also help by automating administrative tasks, saving you time to focus on other areas of your business.</p>
                            <p>The majority of online business accounting software will automatically submit your VAT returns to HMRC and will digitise your Tax for you. We would recommend <a href="https://www.xero.com/uk/pricing-plans/" target="_blank" rel="noreferrer">Xero</a> which starts from &pound;14 per month and <a href="https://www.sage.com/en-gb/sage-business-cloud/accounting/" target="_blank" rel="noreferrer">Sage</a> which starts from &pound;12 a month. Both are great and both come with free trials.  </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Accounting</h2>
                            <p>This is a no brainer for us. SwitchboardFREE can give you a local or geographic business number with unlimited minutes for only &pound;5 a month. </p>
                            <p>But don&apos;t take our word for it � The independent review company <a href="https://www.reviews.co.uk/company-reviews/store/switchboard-free" target="_blank" rel="noreferrer">Reviews.io</a>, rated us as 4.9 out of five stars and 98% of reviewers would recommend our service. </p>

                            <div className="mt-40 mb-30">
                                <div className="col bg-light brad-10 box-shadow-v3 p-30 b-1 clearfix">
                                    <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
                                        <span className="text-cta">Talk to us today</span> and get started
                                    </p>
                                    <h2 className="text-lg text-400 mb-0 mt-0 text-dark-light">
                                        Schedule a quick, no-obligation call &amp; discover...
                                    </h2>
                                    <ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            What type of number is best for your business
                                        </li>
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            How you can move to VoIP before the <Link to="/bt-landline-switchoff-guide">2023 deadline </Link>
                                        </li>
                                    </ul>

                                    <span className="d-inline-block">
                                        <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.props.handleShowCallbackForm()}>Get a callback</Button>
                                        <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
                                    </span>
                                </div>
                            </div>

                            <p>There are no long-term contracts, setup fees or activation fees and we'll also give you over 40 free features that other companies will almost certainly charge you for.  </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Domains And Websites </h2>
                            <p>A business's online presence, regardless of industry, can have a massive impact on its success and regardless of what  you do, the majority of your customers will visit your website before making a purchase.</p>
                            <p><a href="https://www.godaddy.com/en-uk/websites/website-builder" target="_blank" rel="noreferrer">GoDaddy</a> and <a href="https://www.wix.com/" target="_blank" rel="noreferrer">Wix</a> both have a great resources where you can build your own site using templates. You can also add a shop, domain name and hosting. </p>
                            <p>Best of all, you can try them both out for free. </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Recruitment </h2>
                            <p>If you need staff then you need to recruit but it can be a minefield, and the hiring process can be both complicated and expensive. </p>
                            <p>Social media may work well for you if you already have large following and you need someone locally. This can be a great free resource if you ask your friends to spread the word for you. </p>
                            <p>If that doesn't work for you then <a href="https://uk.indeed.com/" target="_blank" rel="noreferrer">Indeed.com</a> is undoubtedly the market leader when it comes to recruiting on a budget. They have <a href="https://uk.indeed.com/hire/resources" target="_blank" rel="noreferrer">great free guides</a> on everything from posting your fist job and interviewing through to managing your whole business. </p>

                            <div className="mt-40 mb-30">
                                <div className="col bg-light b-1 brad-10 box-shadow-v3 p-30 clearfix">
                                    <span className="d-inline-block float-start text-darker col-12 col-md-5">
                                        <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                                            Get a dedicated phone number
                                        </span>
                                        <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-10" style={{ lineHeight: '1em' }}>
                                            <span className="text-cta">Get started</span> with VoIP today
                                        </p>
                                    </span>

                                    <span className="d-inline-block float-end col-12 col-md-6">
                                        <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.handleSignup()}>Get started</Button>
                                        <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
                                    </span>
                                </div>
                            </div>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Advertising &amp; Marketing </h2>
                            <p>If your target audience is online then getting to know <a href="https://ads.google.com/intl/en_gb/getstarted/" target="_blank" rel="noreferrer">Google Ads</a> is inevitable. There's a &pound;400 free ad credit incentive at the moment to help you get started. Although it has quite a <a href="https://support.google.com/google-ads/answer/6146252?hl=en" target="_blank" rel="noreferrer">comprehensive online guide</a>, it can be a tricky art to master.  </p>
                            <p>This does have the potential to be a black hole for both your funds and your time, so we would suggest that you look at online freelance marketplaces such as <a href="https://www.fiverr.com/?source=top_nav" target="_blank" rel="noreferrer">Fiverr</a>. Here you can source some seriously talented Google ad specialists for less than you'd probably lose doing this yourself.  </p>
                            <p>Enticing your customers with new offers and converting leads in to cold, hard cash is an amazing feeling. There are  some excellent free resources dedicated to marketing but sometimes is difficult to find the diamond in the rough.  </p>
                            <p>Here's a free, downloadable marketing plan template from <a href="https://offers.hubspot.com/marketing-plan-template?" target="_blank" rel="noreferrer">Hubspot</a>. This is a good place to start to see the "big picture", and Exposure Ninja have some <a href="https://www.youtube.com/c/ExposureNinja" target="_blank" rel="noreferrer">amazing videos</a> where they expertly explain every aspect of marketing in detail. </p>
                            <p>And, finally, i&apos;s recommended that you set up an email automation platform. <a href="https://mailchimp.com/en-gb/guesswork/?currency=GBP" target="_blank" rel="noreferrer">Mailchimp</a> is a marketing and email marketing service and is great to start with. Best of all, you guessed it, you can start for free. </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">What's Driving Your Results? </h2>
                            <p>Focusing on results and understanding where your sales are coming from is the driving force for any business.  72% of small to medium sized businesses receive the majority of their revenue and new business from telephone calls so If you&apos;re spending half your time tweaking your web page, you may need shift your focus. </p>
                            <p>We surveyed over a thousand of our customers and found:</p>
                            <ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    94% would find an alternative business to contact if their call was not answered.
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    86% would find an alternative business to contact if they heard an engaged signal on more than one occasion
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    90% considered the customer service to be unsatisfactory if they were put on hold for two minutes or more
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    78% would find an alternative business to contact if they were not contacted after leaving voicemail
                                </li>
                            </ul>
                            <p>If you&apos;re missing calls, then you&apos;re jeopardising your whole business!  </p>

                            <div className="mt-40 mb-30">
                                <div className="col bg-light b-1 brad-10 box-shadow-v3 p-30 clearfix">
                                    <span className="d-inline-block float-start text-darker col-12 col-md-5">
                                        <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                                            Overflow calls to a Call Answering Service
                                        </span>
                                        <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-10" style={{ lineHeight: '1em' }}>
                                            Get a free quote
                                        </p>
                                    </span>

                                    <span className="d-inline-block float-end col-12 col-md-6">
                                        <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.handleSignup()}>Get started</Button>
                                        <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
                                    </span>
                                </div>
                            </div>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">What's Driving Your Results? </h2>
                            <p>Part of Amazon mission statement is, "To be Earth's most customer-centric company" and they seemed to have  done ok out it.  </p>
                            <p>Making sure that you&apos;re giving your customers the love they deserve WILL grow your bottom line, but how do you know if doing a good job? </p>
                            <p>A good CRM (Customer Relationship Management) system will help you track your customers, sales, tasks and communications and <a href="https://capsulecrm.com/" target="_blank" rel="noreferrer">Capsule</a> is a very versatile CRM system. Capsule is offering a free account with up to 250 contacts which should be more than enough to get you started. </p>
                            <p>A CRM system will also help you monitor your company's KPI's (Key performance Indicators) </p>
                            <ul className="text-400">
                                <li>Revenue growth</li>
                                <li>Revenue per client</li>
                                <li>Profit margin</li>
                                <li>Client retention rate</li>
                                <li>Customer satisfaction</li>
                            </ul>
                            <p>These KPI's will help you to be "on" your business rather than "in" your business and before you know it, you'll be gaining crucial insights that help to make a big difference. </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Good Luck!</h2>
                            <p>Concentrating on costs and KPI's may not be the rollercoaster of business fun you had initially envisaged, but running a successful and profitable business may be the most rewarding thing you ever do. </p>
                            <p>Above all, good luck! </p>

                            <div className="mt-50">
                                <div className="col bg-light brad-10 box-shadow-v3 p-30 b-1 clearfix">
                                    <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
                                        <span className="text-cta">Talk to us today</span> and get started
                                    </p>
                                    <h2 className="text-lg text-400 mb-0 mt-0 text-dark-light">
                                        Schedule a quick, no-obligation call &amp; discover...
                                    </h2>
                                    <ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            Start your business on the right foot
                                        </li>
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            How you can benefit from the advantages of VoIP
                                        </li>
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            Why you should move early before BT's deadline
                                        </li>
                                    </ul>

                                    <span className="d-inline-block">
                                        <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.props.handleShowCallbackForm()}>Get a callback</Button>
                                        <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="brad-10 bg-primary bg-gradient p-40 text-center pb-0 sticky-t-100">
                                <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                                    Discover the power of VoIP for your business
                                </span>
                                <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-20" style={{ lineHeight: '1em' }}>
                                    <span className="text-darker">Switch to VoIP</span> easily &amp; quickly with us
                                </p>
                                <p className="text-md text-400 mt-30 mb-0">
                                    From as little as &pound;5 per month.
                                </p>

                                <span className="d-inline-block col-12 mt-20 mb-50">
                                    <Button variant="cta" className="btn btn-lightest btn-outline p-15 pl-50 pr-50 text-md mb-xs-20" onClick={() => this.handleSignup()}>Get started</Button>
                                    <p className="text-sm mt-20">Or, call <a href="tel:02031891213" className="text-lightest">0203 189 1213</a></p>
                                </span>

                                <img src="/images/voip-feature-img.png" alt="VoIP Phone System" className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentDidMount () {

  }

  handleSignup () {
    localStorage.setItem('landingPage', true)
    this.props.history.push('/get-started')
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(StartingBusiness)
