import React from 'react'
import { connect } from 'react-redux'
import { getLoading, getLoadingMessage } from '../selectors'

const LoadingNotification = props => {
  if (props.hasFinishedLoading && props.removeOnLoaded) {
    return null
  } else {
    return (
            <div className={'loading-page'.concat(props.isLoading ? ' fade-in' : ' fade-out')}>
                <div className="loading-content">
                    <img id="loading" src={'images/icons/loading.svg'} width={props.width} height={props.height} className={props.imgClass} />
                    {!props.hideText &&
                        <p className={`${props.textColor} ${props.textSize ? `text-${props.textSize}` : ''}`} >{props.message}</p>
                    }
                </div>
            </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return ({
    isLoading: props.isLoading || getLoading(state),
    message: props.message ? props.message : props.loadingText ? props.loadingText : getLoadingMessage(state)
  })
}

export default connect(
  mapStateToProps
)(LoadingNotification)

LoadingNotification.propTypes = {}
LoadingNotification.defaultProps = { width: 60, height: 60, removeOnLoaded: false }
