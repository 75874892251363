import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SmsLimit } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'
import SmsCallbackSectionHelperDialog from './InstructionHelperDialogs/SmsCallbackSectionHelperDialog'

let isMax = false
let currLength = 0

function handleChange (props, val) {
  if (val) {
    if (currLength === 0) { currLength = props.autoSmsMessage ? props.autoSmsMessage.length : 0 }

    if (val.length > SmsLimit) {
      val = val.substring(0, SmsLimit)
    }

    if (!isMax || (val.length < currLength)) {
      props.handleSmsMessageChange(val)
      isMax = val.length === SmsLimit
      currLength = val.length
    }
  }
}

const SmsCallbackSection = (props) => (
    <>
        <div className="row bg-dullest p-30 zoom">
            <div className="col-md-5 pb-sm-10">
                <h5 className="text-secondary text-500 text-lg">Callback SMS <span className="bg-success text-400 text-lightest brad-10 d-none d-sm-inline-block" style={{ padding: '5px 20px', fontSize: '12px', display: 'inline-block', marginLeft: '10px', top: '-4px', position: 'relative' }}>Highly Recommended</span>
                </h5>
                <p className="text-400">
                    If you’re unable to answer your phone, we will automatically send your caller an SMS with your chosen message (Mobile only).
                    <br className="d-none d-lg-block" />
                    This lets your caller know that you’ll be calling them back shortly and often prevents them from calling an alternative provider.
                    <br /><a onClick={props.handleShowSmsCallbackHelperInstruction} className="underline" id="CallbackSMSMoreInfoLink">More info</a>
                </p>
            </div>
            <SmsCallbackSectionHelperDialog />
            <div className="col-md-7 pb-sm-30 pl-sm-0 pr-sm-0">
                <div className="col-md-12 pb-20 mt-10 text-center">
                    <p>Turn ON/OFF:</p>

                    <Toggle
                        className="mt-0"
                        onClick={e => props.handleAutomaticSmsEnabledChange(e)}
                        on={<span>ON</span>}
                        off={<span>OFF</span>}
                        size="sm"
                        offstyle="default"
                        onstyle="success"
                        width="null"
                        height="null"
                        active={props.isEnabled}
                        id="CallbackSMSToggle"
                    />
                </div>
                <div className="col-md-12">
                    <p>Message to be sent:</p>
                    {isMax &&
                        <div className="alert alert-warning">
                            You've reached the limit for your SMS. ({SmsLimit} characters)
                        </div>
                    }
                    <textarea rows="2" cols="20" placeholder="eg. Hi there, sorry I have missed your call...I will call back as soon as I&apos;m available" className="form-control" id="CallbackSMSTextArea"
                        value={props.autoSmsMessage}
                        onChange={e => handleChange(props, e.target.value.replaceAll('’', '\''))}
                    />
                </div>
            </div>
        </div>
    </>
)

const mapStateToProps = state => {
  return ({
    isEnabled: state.configuration.configuration.isMissedCallSmsEnabled,
    autoSmsMessage: state.configuration.configuration.smsMcMessage || ''
  })
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmsCallbackSection)

// export default connect(
//     state => state.configuration,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(IntroductionSection);
