import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const AnnouncePositionSectionHelperDialog = props => (
    <Modal show={props.isDisplayed}
           onHide={props.hideAnnouncePositionHelperInstruction}>
        <Modal.Header closeButton>
            <Modal.Title>Announce Position</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                Turning on this feature will mean that approximatley every 45 seconds your callers will be
                told which position they currently hold in your call queue.
            </p>

            <p>
                If you have small to medium queue lengths (up to about 5) or if you process your calls at a
                fast rate this feature can help to persuade your callers to stay holding. However if you have
                large queue lengths or if you are unable to quickly process your calls then we would not
                recommend using this feature as it can demoralise your callers and they are less likely to
                hold for long periods.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideAnnouncePositionHelperInstruction}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.announcePositionInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncePositionSectionHelperDialog)
