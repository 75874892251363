import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import TextBannerWidget from '../../components/Reviews/TextBannerWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

class PocketReceptionist extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
      showPRVideo: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({ isOpen: true })
  }

  render () {
    return (<><ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>
                    Telephone Answering Service, Call Handling Service
              </title>
                <meta
                    name="description"
                    content="Professional and cost effective telephone answering service with real receptionists answering your calls in real time. With us, you will never miss an important call."
                />
                <style type="text/css">{`
                .fill-cta {
                    fill:#ff9314;
                }
                .fill-dark {
                    fill:#3b3b3b;
                }
                .icon-block.xl .icon {
                  top: 17px !important;
              }
              .girl-img, .girl-img2 {
                position:relative;
                border:10px solid #ff9314;
                border-radius:50%;
                bottom:0;
                height:310px;
                width:310px;
                top:30px;
              } 
              .girl-img {
                left:0;
              }
              .girl-img2 {
                right:0;
              }
              @media screen and (max-width:767px) {
                .col-xs-6 .icon-block {
                  width: 130px;
                  height: 130px;
                  padding: 8px 0;
                }
              }
              @media screen and (min-width:601px) {
                .girl-img, .girl-img2 {
                  position:absolute;
                  max-width:100%;
                  border-radius:0;
                  top:unset;
                  border:0;
                }
                .girl-img {
                  left:-6%;
                }
              }
              @media screen and (min-width:768px) {
                .girl-img {
                  height:510px;
                  width:auto;
                }
                .girl-img2 {
                  height:100%;
                  width:auto;
                  right:0;
                }
              }
              
              @media screen and (min-width:992px) {
                .girl-img {
                  left:0%;
                }
              }
              @media screen and (min-width:1200px) {
                .girl-img {
                  left:9%;
                }
                .girl-img2 {
                  right:5%;
                }
              }
              @media screen and (min-width:1600px) {
                .girl-img {
                  left:20%;
                }
                .girl-img2 {
                  right:20%;
                }
              }
              @media screen and (min-width:1920px) {
                .girl-img {
                  left:26%;
                }
                .girl-img2 {
                  right:26%;
                }
              }
              @media screen and (min-width:2200px) {
                .girl-img {
                  left:30%;
                }
              }
            }
            `}</style>
            </Helmet>

            <header style={{ backgroundImage: "url('/images/backgrounds/call-answering.jpg')" }} className="full-width full-hero-small with-text" >
                <h1>Live Agent Call Answering</h1>
                <h2 className=" subtitle">Let real people answer your calls.</h2>
            </header>

            <div className="full-width full-dull text-xs">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <TextBannerWidget />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light pt-60 pb-60">
                <div className="container">
                    <div className="row pl-30 pr-30 pl-sm-5 pr-sm-5">
                        <div className="col-lg-8 mx-auto">
                            <div className="row">
                                <div className="col-sm-6">
                                <p className="text-500">
                                    With SwitchboardFREE Live Call Answering you’ll never miss a call again.
                                </p>
                                <p className="pt-10 text-400">
                                    We can answer all of your calls or just the ones you miss, so you can respond to callers at a time that better suits you.
                                </p>

                                <p className="text-500 pt-10">Your dedicated team will capture and email you the following:</p>
                                <ul className="styled-list list-2 cta pl-10 pt-10 text-400">
                                    <li className="pb-10">
                                        The caller’s name
                                    </li>
                                    <li className="pb-10">
                                        Return phone number
                                    </li>
                                    <li className="pb-10">
                                        Reason for the call
                                    </li>
                                    <li className="pb-10">
                                        A short message
                                    </li>
                                    <li className="pb-10">
                                        Time and date of the call
                                    </li>
                                </ul>
                                <p className="pt-10 text-400">
                                Your callers will always be greeted promptly and professionally, and you can turn us on or off as you need.
                                </p>
                                <p className="text-500 pt-10">
                                    This incredible feature has no monthly fees - only pay for the calls we answer.
                                </p>
                                <p className="pt-10 text-400">
                                SwitchboardFREE call answering is available between:
                                <br/><br/>
                                Monday to Friday <span className='text-500'>8:00 - 20:00</span><br />
                                Saturday and Sunday <span className='text-500'>9:00 - 18:00</span><br />
                                Bank Holidays <span className='text-500'>9:00 - 16:00</span>
                                </p>
                                <p className="pt-10 text-400">
                                Calls costs <span className="text-500">just £3.40 per call</span>, there are no extra charges, and any call we take will come off your existing call credit.
                                </p>

                                <p className="pt-10 text-500">
                                    Activate today and see how much faster your business grows!
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <div className="brad-t-10 bg-secondary p-15 text-center">
                                    <h4 className="text-lightest text-500">Live Call Answering<br/>Gives You:</h4>
                                </div>
                                <div className="pl-10 pr-10 pt-30 pb-30 b-2">
                                    <ul className="styled-list list-size-2 rounded pl-20 pt-30">
                                        <li>
                                            <span className="text-500">Your Very Own Team</span>
                                            <br/>
                                            <span className="text-italic text-400 text-sm">(Experienced, Dedicated Receptionists)</span>
                                        </li>
                                        <li>
                                        <span className="text-500">Instant Set Up</span>
                                        <br/>
                                        <span className="text-italic text-400 text-sm">(Activate In Seconds)</span>
                                        </li>
                                        <li>
                                        <span className="text-500">Versatile Options</span>
                                        <br/>
                                        <span className="text-italic text-400 text-sm">(Answer All Calls or Overflow Only)</span>
                                        </li>
                                        <li>
                                        <span className="text-500">Complete Flexibility</span>
                                        <br/>
                                        <span className="text-italic text-400 text-sm">(Turn On And Off At Will)</span>
                                        </li>
                                        <li>
                                        <span className="text-500">Zero Commitment</span>
                                        <br/>
                                        <span className="text-italic text-400 text-sm">(No Monthly Fees, Simply Pay As You Go)</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="text-center pt-20 pb-20 brad-b-10" style={{ backgroundColor: '#3b3b3b' }}>
                                    <h5 className="text-cta text-caps text-500">Incredible Value!</h5>
                                    <h4 className="text-400 text-lightest">All for <span className="text-500">JUST £3.40 </span>/call</h4>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="full-width full-dull pt-60 pb-60">
                    <div className="container">
                        <div className="row" >
                            <div className="col-md-8 mx-auto text-center pl-30 pr-30">
                            <Link to={'/login'} className="btn btn-secondary btn-lg btn-xxs-block mb-xxs-10" id="LogInButton">Log In</Link>
                            <span className="pl-20 pr-20 text-400">or</span>
                            <Link to={'/get-started'} className="btn btn-cta btn-lg btn-xxs-block mt-xxs-10" id="SignUpButton">Sign Up</Link>
                                <p className="text-italic text-400 pt-30">
                                Live Call Answering is supplied by Pocket Receptionist.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full-width pt-60 pb-60" style={{ backgroundImage: 'url(images/backgrounds/pr-bg2.jpg)', backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                                <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                                <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                                <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                                <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                                <h3 className="text-500 text-lightest">&quot;Fantastic Service. Would be lost without it&quot;</h3>
                                <h5 className="text-500 text-lightest">Jonathan Hobday</h5>
                            </div>
                        </div>
                    </div>
                </div>

        </ >
    )
  }

  componentWillMount () { }

  componentDidMount () {
    const widgetInit = document.createElement('script')
    widgetInit.type = 'text/javascript'
    widgetInit.async = true
    widgetInit.innerHTML = "richSnippetReviewsWidgets('text-banner-widget', {store: 'switchboard-free',starsClr: '#f47e27',textClr: '#595959',logoClr: '#595959', widgetName: 'text-banner', css:'.ReviewsLogo--small .ReviewsLogo__text i {color:#595959 !important;}' });"

    // append to body
    document.body.appendChild(widgetInit)
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(PocketReceptionist)
