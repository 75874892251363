import React from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { siteHeader } from '../constants/Constants'
import { isLoggedIn } from '../selectors'
import LoggedInMenu from './LoggedInMenu'
import LoggedOutMenu from './LoggedOutMenu'

const HeaderMain = props => {
  if (props && props.routing && props.ShowHeaderMain) {
    return (
			<>
				<div className="header">
					<Navbar expand="xl" className="fixed-top">
						<Container fluid>

							{props.resellerId > 1
							  ? <Navbar.Brand href="/">
									<img src={`/images/logo/resellers/${props.resellerId}.png`} className="reseller-logo" width="700" />
								</Navbar.Brand>
							  :								<Navbar.Brand className={(props.isLoggedIn === true) ? 'brand-loggedin' : ''}>
									<a href={`${(props.isLoggedIn === true) ? '/customer/dashboard' : '/'}`}><img src={'/images/logo/sflogo.svg'} width="500" /></a>
									<p>Good For Business</p>
								</Navbar.Brand>
							}

							<Navbar.Toggle />
							<Navbar.Collapse >
								{props.isLoggedIn
								  ? <LoggedInMenu path={props.routing.location.pathname} />
								  :									<LoggedOutMenu path={props.routing.location.pathname} />
								}
							</Navbar.Collapse>
						</Container>
					</Navbar>
				</div>

			</>

    )
  } else { return null }
}

const mapStateToProps = state => {
  return { ShowHeaderMain: state.sbfApp.visibility.HEADER === siteHeader.MAIN, routing: state.router, isLoggedIn: isLoggedIn(state) }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {},
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderMain)
