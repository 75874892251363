import update from 'immutability-helper'
import React from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux'
import DisplayLoggedInFooter from '../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import LoadingNotification from '../components/LoadingNotification'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { PromptTypes } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import { handleNotificationChange } from '../store/SBF'

const _ = require('lodash')

class RecordingsManagement extends React.Component {
  tabKeyToPromptMapping = {
    1: PromptTypes.departmentalMainIntro,
    2: PromptTypes.standardIntro,
    3: PromptTypes.standardOoh,
    4: PromptTypes.standardVoicemail,
    5: PromptTypes.standardWhisper,
    6: PromptTypes.departmentalChoice
  }

  emptyPrompts = [[], [], [], [], [], []]
  loadingPrompts = [false, false, false, false, false, false]

  state = { key: 1, prompts: this.emptyPrompts, loadingPrompts: this.loadingPrompts }

  constructor (props) {
    super(props)

    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect (key) {
    this.setState({ key }, () => this.fetchPrompts())
  }

  fetchPrompts () {
    const key = this.state.key
    const promptType = this.tabKeyToPromptMapping[key]

    const url = `/api/Numbers/GetPromptsForPromptManagementPage/${promptType}`

    const currentLoadingPrompts = this.state.loadingPrompts
    const newLoadingPrompts = [...currentLoadingPrompts.slice(0, key - 1), true, ...currentLoadingPrompts.slice(key)]

    this.setState({ loadingPrompts: newLoadingPrompts })

    fetchHelper.getJson(url).then(response => {
      const currentPrompts = this.state.prompts
      const newPrompts = [...currentPrompts.slice(0, key - 1), response.data, ...currentPrompts.slice(key)]

      const currentLoadingPrompts = this.state.loadingPrompts
      const newLoadingPrompts = [...currentLoadingPrompts.slice(0, key - 1), false, ...currentLoadingPrompts.slice(key)]

      this.setState({ prompts: newPrompts, loadingPrompts: newLoadingPrompts })
    }).catch(error => console.error(error))
  }

  render () {
    const arrayIndex = this.state.key - 1
    return <div><ScrollToTopOnMount/><DisplayLoggedInFooter/> <DisplayMainHeader/>
            <header>
                <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/contactbook-bg.jpg')" }}>
                    <h1>Custom recording management</h1>
                    <h2 className="subtitle">This page shows you all the custom recordings you have made <br className="d-none d-lg-block"/>for your SwitchboardFREE number(s).</h2>
                </div>
            </header>
            <div className="full-width bg-dull">
                <div className="container">
                    <div className="row mb-100">
                            <div className="col-md-12">
                                <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>
                                    <Tab eventKey={1} title="Global Intros">
                                        <div className="col-md-12 mb-30">
                                            <h3 className="text-500">Global introductions</h3>
                                            <p>
                                                When you have your SwitchboardFREE number set to departmental mode this
                                                is the very first introduction you callers
                                                will hear when calling your number (note that this is not the department
                                                introduction that they will hear
                                                once they have selected a department, these are available on the next
                                                tab "introductions").
                                            </p>
                                            <p>
                                                In order to use any of these recorded prompts with your SwitchboardFREE numbers you
                                                will need to select them in the appropiate
                                                dropdown boxes on your number's settings page. You can access your number's settings
                                                pages by clicking on the “My Numbers”
                                                link at the top of this page and then click on the number you wish to alter.
                                            </p>
                                        </div>

                                        {this.state.loadingPrompts[arrayIndex] && this.state.prompts[arrayIndex].length === 0
                                          ? <LoadingNotification message={'Loading ...'}
                                                                 isLoading={true}/>
                                          : this.state.prompts[arrayIndex].map((prompt) => this.renderRow(prompt))}

                                    </Tab>
                                    <Tab eventKey={2} title="Standard Intros">
                                        <div className="col-md-12 mb-30">
                                            <h3 className="text-500">Standard Intros</h3>
                                            <p>
                                                These are the introduction messages which you have recorded. If you have
                                                your SwitchboardFREE number set to Standard mode then this is
                                                the first thing your callers will hear when calling your number.
                                                If you have your SwitchboardFREE number setup in Auto Attendant mode
                                                then they will hear the introduction once they have selected a
                                                department.
                                            </p>
                                            <p>
                                                In order to use any of these recorded prompts with your SwitchboardFREE numbers you
                                                will need to select them in the appropiate
                                                dropdown boxes on your number's settings page. You can access your number's settings
                                                pages by clicking on the “My Numbers”
                                                link at the top of this page and then click on the number you wish to alter.
                                            </p>
                                        </div>

                                        {this.state.loadingPrompts[arrayIndex] && this.state.prompts[arrayIndex].length === 0
                                          ? <LoadingNotification message={'Loading ...'}
                                                                 isLoading={true}/>
                                          : this.state.prompts[arrayIndex].map((prompt) => this.renderRow(prompt))}
                                    </Tab>
                                    <Tab eventKey={3} title="Out of hours">
                                        <div className="col-md-12 mb-30">
                                            <h3 className="text-500">Out of hours messages</h3>
                                            <p>
                                                These are out of hours messages which you have recorded. Out of hours
                                                messages get played to
                                                your customers when they call your numbers or departments outside of
                                                your the opening hours you have set.
                                            </p>
                                            <p>
                                                In order to use any of these recorded prompts with your SwitchboardFREE numbers you
                                                will need to select them in the appropiate
                                                dropdown boxes on your number's settings page. You can access your number's settings
                                                pages by clicking on the “My Numbers”
                                                link at the top of this page and then click on the number you wish to alter.
                                            </p>
                                        </div>

                                        {this.state.loadingPrompts[arrayIndex] && this.state.prompts[arrayIndex].length === 0
                                          ? <LoadingNotification message={'Loading ...'}
                                                                 isLoading={true}/>
                                          : this.state.prompts[arrayIndex].map((prompt) => this.renderRow(prompt))}
                                    </Tab>
                                    <Tab eventKey={4} title="Voicemail">
                                        <div className="col-md-12 mb-30">
                                            <h3 className="text-500">Voicemail prompts</h3>
                                            <p>
                                                Voicemail prompts are what get played immediately before your caller
                                                gets given the oppurtunity
                                                to leave a voicemail after they choose to do so by pressing "1" while on
                                                hold or when you send
                                                your caller to voicemail by pressing "3" during a call screening message.
                                            </p>
                                            <p>
                                                In order to use any of these recorded prompts with your SwitchboardFREE numbers you
                                                will need to select them in the appropiate
                                                dropdown boxes on your number's settings page. You can access your number's settings
                                                pages by clicking on the “My Numbers”
                                                link at the top of this page and then click on the number you wish to alter.
                                            </p>
                                        </div>
                                        {this.state.loadingPrompts[arrayIndex] && this.state.prompts[arrayIndex].length === 0
                                          ? <LoadingNotification message={'Loading ...'}
                                                                 isLoading={true}/>
                                          : this.state.prompts[arrayIndex].map((prompt) => this.renderRow(prompt))}
                                    </Tab>
                                    <Tab eventKey={5} title="Call Screening">
                                        <div className="col-md-12 mb-30">
                                            <h3 className="text-500">Call Screening prompts</h3>
                                            <p>
                                                These are call screening prompts which you have recorded. These are the
                                                messages you hear when you answer a call
                                                via your SwitchboardFREE number and you have the call screening (otherwise
                                                known as call whisper) feature enabled.
                                            </p>
                                            <p>
                                                In order to use any of these recorded prompts with your SwitchboardFREE numbers you
                                                will need to select them in the appropiate
                                                dropdown boxes on your number's settings page. You can access your number's settings
                                                pages by clicking on the “My Numbers”
                                                link at the top of this page and then click on the number you wish to alter.
                                            </p>
                                        </div>
                                        {this.state.loadingPrompts[arrayIndex] && this.state.prompts[arrayIndex].length === 0
                                          ? <LoadingNotification message={'Loading ...'}
                                                                 isLoading={true}/>
                                          : this.state.prompts[arrayIndex].map((prompt) => this.renderRow(prompt))}
                                    </Tab>
                                    <Tab eventKey={6} title="Departments">
                                        <div className="col-md-12 mb-30">
                                            <h3 className="text-500">Department menus</h3>
                                            <p>
                                                These are the department menus (e.g. "press 1 for sales, 2 for support")
                                                which you have recorded.
                                                These are only used when you are using you SwitchboardFREE number in
                                                "departmental" mode.
                                            </p>
                                            <p>
                                                In order to use any of these recorded prompts with your SwitchboardFREE numbers you
                                                will need to select them in the appropiate
                                                dropdown boxes on your number's settings page. You can access your number's settings
                                                pages by clicking on the “My Numbers”
                                                link at the top of this page and then click on the number you wish to alter.
                                            </p>
                                        </div>
                                        {this.state.loadingPrompts[arrayIndex] && this.state.prompts[arrayIndex].length === 0
                                          ? <LoadingNotification message={'Loading ...'}
                                                                 isLoading={true}/>
                                          : this.state.prompts[arrayIndex].map((prompt) => this.renderRow(prompt))}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                </div>
            </div>

            <audio id='promptAudioControl' crossOrigin="anonymous"/>
        </div>
  }

  renderRow (prompt) {
    return <div key={prompt.id}
                    className="col-md-12 well well-light box-rounded box-shadow mt-20 text-center pb-30 pt-20">
                        <div className="row pl-25 pr-25">
                            <div className="col-md-3">
                                <label>Date recorded</label>
                                <p className="pt-15 pt-sm-0 pb-sm-15 text-sm-md">
                                    {prompt.recordingDate}
                                </p>
                            </div>
                            <div className="col-md-5">
                                <label>Description</label>
                                <input className="form-control" type='text' value={prompt.description}
                                    onChange={event => this.handleDescriptionChange(prompt, event.target.value)}/>
                            </div>
                            <div className="col-md-4 pt-30">
                                <Button className="m-5"
                                        size="xs" variant="primary"
                                        onClick={e => this.handleListenClick(prompt, this.tabKeyToPromptMapping[this.state.key])}>Listen</Button>
                                <Button className="m-5"
                                        size="xs" variant="danger"
                                        onClick={e => this.handleDelete(prompt, this.tabKeyToPromptMapping[this.state.key])}>Delete</Button>
                                <Button className="m-5"
                                        onClick={e => this.handleUpdate(prompt, this.tabKeyToPromptMapping[this.state.key])}
                                        variant="cta" size="xs">Save</Button>
                            </div>
                        </div>

        </div>
  }

  componentDidMount () {
    document.title = 'Custom recording management'

    this.fetchPrompts()
  }

  handleDescriptionChange (prompt, newDescription) {
    const arrayIndex = this.state.key - 1
    const thesePrompts = this.state.prompts[arrayIndex]

    const promptToUpdateIndex = _.findIndex(thesePrompts, p => p.id === prompt.id)
    const updatedPrompt = { ...thesePrompts[promptToUpdateIndex], description: newDescription }
    const newAllPrompts = update(this.state.prompts, { [arrayIndex]: { [promptToUpdateIndex]: { $set: updatedPrompt } } })

    this.setState({ prompts: newAllPrompts })
  }

  handleUpdate (prompt, promptType) {
    const context = this

    const arrayIndex = this.state.key - 1
    const thesePrompts = this.state.prompts[arrayIndex]

    const promptToUpdateIndex = _.findIndex(thesePrompts, p => p.id === prompt.id)
    const promptToSave = thesePrompts[promptToUpdateIndex]

    const url = '/api/Numbers/UpdateCustomPrompt'

    fetchHelper.postJson(url, {
      promptId: prompt.id,
      promptType,
      description: promptToSave.description
    }).then(res => {
      if (res.data) {
        context.props.notify({ message: 'Prompt Saved' })
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleListenClick (prompt, promptType) {
    const promptId = prompt.id
    const url = `/api/Numbers/GetPromptAudio/${promptType}/${promptId}`

    fetchHelper.getBlob(url).then(response => {
      const audioPlayer = document.getElementById('promptAudioControl')
      audioPlayer.pause()
      audioPlayer.currentTime = 0
      audioPlayer.src = window.URL.createObjectURL(response.data, { type: 'audio/x-wav' })
      audioPlayer.play()
    }).catch(error => console.error(error))
  }

  handleDelete (prompt, promptType) {
    const context = this
    const confirmDelete = window.confirm('Are you sure you wish to delete this prompt')
    if (confirmDelete === true) {
      const url = '/api/Numbers/DeleteCustomPrompt'

      const arrayIndex = this.state.key - 1
      const thesePrompts = this.state.prompts[arrayIndex]
      const promptToDeleteIndex = _.findIndex(thesePrompts, p => p.id === prompt.id)
      const newThesePrompts = [...thesePrompts.slice(0, promptToDeleteIndex), ...thesePrompts.slice(promptToDeleteIndex + 1)]

      const allPrompts = this.state.prompts
      const newAllPrompts = [...allPrompts.slice(0, arrayIndex), newThesePrompts, ...allPrompts.slice(arrayIndex + 1)]

      this.setState({ prompts: newAllPrompts })

      fetchHelper.postJson(url, { promptId: prompt.id, promptType }).then(res => {
        if (res.data) {
          context.props.notify({ message: 'Prompt Deleted' })
        }
      }).catch(function (error) {
        console.error(error)
      })
    }
  }
}

export default connect(
  state => {
    return state
  },
  { notify: handleNotificationChange }
)(RecordingsManagement)
