import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import FeaturesList from '../../components/Competitors/FeaturesList'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface NumberPeopleProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const NumberPeople = (props: NumberPeopleProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Number People vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/2-competitor-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Number People vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Number People vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Introduction</h2>
                            <p>The Number People on the surface of things seem to have the VoIP market all sewn up. An appealing website, lots of options and the words "low cost" and "Free" are thrown about the site quite prolifically.</p>
                            <p>This all looks quite enticing at first and if you're after a very basic service and you're happy entrusting your business phone calls to a 3rd party Voip app or 3rd party softphone then you won't find many other companies out there much cheaper.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">The Obvious Issue</h2>
                            <p>But therein lies the issue I have with the Number People. 3rd party software!</p>
                            <p>The calls I make and receive are the lifeblood of my business and I just wouldn't trust my own technical ability to ensure I had the correct settings to make the thing work.</p>
                            <p>Just the fuss of setting up 3rd parts apps, softphones, sip protocols and VoIP settings sounds like a complete nightmare I'd rather do without.</p>
                            <p>The thing that would scare me the most is that if any of those 3rd party, independent and non-related softphone providers decided to make a change to their app and my calls stop coming through, I have no recourse.</p>
                            <p>There's no responsibility to the Number People as they're also reliant on these 3rd party companies to help deliver their service.</p>
                            <p>In short, my calls are very important to me and I would vehemently insist that my service was delivered by the very company that I chose to provide me with that service in the first place.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">The virtual phone number provider</h2>
                            <p>Rant aside. If you want to only receive calls, the Number People give you a free landline number and additional users cost only £1 a month. Headline prices are low and enticing and with no contract, I found myself curious about how they make their money?</p>
                            <p>After some digging about and a few calculations, I found out exactly how they make their money and it's with Add-ons!</p>
                            <p>The low headline price is essentially for a standalone service, whereas the basic features which should be integral to a VoIP service provider all come at a price.</p>
                            <p>Below are just a few of the basic features you'd expect to pay extra for:</p>

                            <ul className="fancy cta text-400 pl-30 pt-10 pb-30">
                                <li className="mb-5">
                                    Custom Call Greetings – £1.50 p/month
                                </li>
                                <li className="mb-5">
                                    Personalised Auto Attendant – £2 p/month
                                </li>
                                <li className="mb-5">
                                    Voicemail to email – £1.50 p/month
                                </li>
                                <li className="mb-5">
                                    Extra ring groups – £1 p/month
                                </li>
                                <li className="mb-5">
                                    Extra numbers – £1 p/month
                                </li>
                                <li>
                                    Music on hold – £5 p/month
                                </li>
                            </ul>

                            <p>This is really a sneaky way to gain your trust with the promise of an upfront low cost and then they hit you up with additional charges every time you want to use a feature. This is just like the "frog in water" analogy. Once you're on board, your monthly cost increases as you start to use some of what I consider to be basic features.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Outbound and the cost to forward calls</h2>
                            <p>If you were to opt for the Number peoples call forwarding or outbound service then they again have these low headline prices starting at just £1 for 20 minutes and an unlimited bundle coming in at just £10 a month.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Unlimited calls</h2>
                            <p>Their fair usage policy is also pretty good at 4,000 minutes inbound and 2,000 minutes outbound. They also don&apos;t hit you hard with call forwarding rates outside of these bundles as these are kept at quite a standard 1.5 ppm to a landline and 7 ppm to a mobile.</p>
                            <p>However, add the basic features you would expect to use: (Call greetings, auto attendant, VM to email, ring groups and music on hold) and with the unlimited bundle, you're quickly up to £21 a month.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">The comparison</h2>
                            <p>With SwitchboardFREE, the unlimited service for inbound and outbound calls is only £16 per month, which is almost half the cost of the equivalent service offered by the Number People and SwitchboardFREE come with all these free features:</p>

                            <FeaturesList />

                            <p>To go back to my previous rant, SwitchboardFREE have their own App and Softphone, which is fully built, updated and supported by themselves.</p>

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <p>SwitchboardFREE also has 406 different area codes compared to only 71 held by the Number People. So you get more of a choice of the number that you want.</p>
                            <p>If you're considering using the Number People for your VoIP service then please contact or message the friendly Welcome team at SwitchboardFREE below who will more than happy to give you a like for like comparison.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Number Peoples current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://numberpeople.co.uk" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(NumberPeople)
