import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { Tab, Tabs } from 'react-bootstrap'
import janiceMp3Sample from '../sounds/janiceDemo.mp3'
import peterMp3Sample from '../sounds/peter.mp3'
import zoeMp3Sample from '../sounds/zoe.mp3'
import katieMp3Sample from '../sounds/katie.mp3'


export default class CustomPrompts extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>

            <Tabs defaultActiveKey="1" className="tabs-container justify-content-center">
                <Tab eventKey="1" title="Premium Prompts">
                        <div className="col-12 text-center pt-20 pl-0 pr-0">
                            <div className='row'>
                                <div className="col-12">
                                    <h3 className="pb-30 text-500">Premium Prompts</h3>
                                </div>
                                <div className="w-500px center-block text-center">
                                    <div className="b-2 brad-20 box-shadow pl-30 pr-30 pb-30 bg-dark">
                                            <img className="mt-30 mb-30" src="/images/janice-round.png" alt="Janice - the voice artist" width="190" style={{ maxWidth: '100%' }} />
                                            <h4 className="text-500 text-cta">Janice</h4>
                                            <h6 className="text-500 mb-20">The Voice Of Vodafone</h6>
                                            <p className="pb-20 text-sm">
                                                As the voice of the Vodafone Network, Janice provides a warm reassuring female voice with southern dialect.
                                            </p>
                                            <ReactAudioPlayer src={janiceMp3Sample} controls className="brad-10"/>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mt-30">
                                    <h5 className="text-500">Improve your company image now with Janice for only £180</h5>
                                    <p>£180 for 100 words and 79p a word thereafter</p>
                                    <a className="btn btn-md btn-cta btn-mobile-block mt-20 mr-10 mb-30" href="mailto:support@switchboardfree.co.uk?subject=Premium prompts">Email us <span className="ml-10 icon icon-arrow-right4"></span></a>
                                </div>
                            </div>
                        </div>
                    </Tab>
                <Tab eventKey="2" title="Standard Prompts">
                <div className="col-md-12 text-center pt-20 pl-0 pr-0">
                    <div className='row'>
                        <div className="col-12">
                            <h3 className="pb-30 text-500">Standard Prompts</h3>
                        </div>
                        <div className="col-sm-4 text-center pt-30">
                            <div className="b-2 brad-20 box-shadow pl-30 pr-30 pb-30 bg-dark">
                                <img className="mt-30 mb-30 img-circle" src="/images/team/katie.jpg" alt="Katie - the voice artist" width="100" style={{ maxWidth: '100%' }}/>
                                <h4 className="text-500 text-cta mb-20">Katie</h4>
                                <ReactAudioPlayer src={katieMp3Sample} controls className="brad-10"/>
                            </div>
                        </div>
                        <div className="col-sm-4 text-center pt-30">
                            <div className="b-2 brad-20 box-shadow pl-30 pr-30 pb-30 bg-dark">
                                <img className="mt-30 mb-30 img-circle" src="/images/team/peter.jpg" alt="Peter - the voice artist" width="100" style={{ maxWidth: '100%' }}/>
                                <h4 className="text-500 text-cta mb-20">Peter</h4>
                                <ReactAudioPlayer src={peterMp3Sample} controls className="brad-10"/>
                            </div>
                        </div>
                        <div className="col-sm-4 text-center pt-30">
                            <div className="b-2 brad-20 box-shadow pl-30 pr-30 pb-30 bg-dark">
                                <img className="mt-30 mb-30 img-circle" src="/images/team/zoe2.jpg" alt="Zoe - the voice artist" width="100" style={{ maxWidth: '100%' }} />
                                <h4 className="text-500 text-cta mb-20">Zoe</h4>
                                <ReactAudioPlayer src={zoeMp3Sample} controls className="brad-10"/>
                            </div>
                        </div>

                        <div className="col-md-12 text-center mt-30">
                            <h5 className="text-500">Make your business sound professional and stand out from the rest. Get your custom prompt recorded by our in-house team. Currently available at only £60 for 100 words as a minimum order and 27p per word thereafter.</h5>
                            <a className="btn btn-md btn-cta btn-mobile-block mt-20 mr-10 mb-30" href="mailto:support@switchboardfree.co.uk?subject=In-house prompts">Email us <span className="ml-10 icon icon-arrow-right4"></span></a>
                        </div>
                    </div>
                </div>
            </Tab>
                <Tab eventKey="3" title="Record it yourself">
                    <div className="col-12 text-center pt-20 pl-0 pr-0">
                        <div className="text-center">
                            <h3 className="text-500">Record prompts yourself</h3>
                        </div>
                        <p>
                            Download the SwitchboardFREE <a className="underline" target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREE&hl=en_GB" rel="noopener noreferrer">Android</a> or <a className="underline" target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/gb/app/sbf-2012/id556878596">iOS</a> app and easily record and re-record
                            your phone lines prompts instantly for free.
                        </p>
                        <div className="ratio ratio-16x9 pt-30">
                            <iframe src="https://www.youtube.com/embed/RL3iqGxnOro" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </>
  }

  componentDidMount () {
    const tabs = document.getElementsByClassName('nav-tabs')
    tabs[0].classList.add('nav-tabs-centered')
    const wrapper = document.createElement('div')
    wrapper.classList.add('tabs-container')
    tabs[0].parentNode.insertBefore(wrapper, tabs[0])
    wrapper.appendChild(tabs[0])
  }
}
