import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { RequestAuthorizationCode } from 'react-oauth2-auth-code-flow'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { fetchHelper } from '../../helpers/fetchHelper'
import OauthHelper from '../../helpers/OauthHelper'
import { isLoggedIn } from '../../selectors'
import { updateUserFromLocalStorage } from '../../store/SBF'
import DisplayMainFooter from '../DisplayMainFooter'
import DisplayMainHeader from '../DisplayMainHeader'
import TextBannerWidget from '../Reviews/TextBannerWidget'
import ScrollToTopOnMount from '../ScrollToTopOnMount'
import { getCustomerCount } from '../../helpers/generic'

class CapsuleCrm extends Component {
  static propTypes = {}

  state = {
    syncSettings: undefined,
    disconnectCapsuleCrm: false,
    totalCustomers: 0
  }

  disconnectFromCapsule () {
    const syncSettings = {
      ...this.state.syncSettings,
      capsuleCrmEnabled: false
    }

    const url = 'api/contacts/DisconnectFromCrm/1'

    fetchHelper.postJson(url).then((res) => {
      if (res.data === true) {
        this.setState({
          disconnectCapsuleCrm: false,
          syncSettings
        })

        this.LoadContacts()
      }
    })
  }

  render () {
    const oauthClient = OauthHelper.GetCapsuleCrmOauthClient()

    return (
            <>
                <ScrollToTopOnMount />
                <DisplayMainFooter />
                <DisplayMainHeader />
                <Helmet>
                    <title>Capsule CRM Integration with SwitchboardFREE</title>
                    <meta name="description" content="Automatically synchronise your Capsule CRM database with SwitchboardFREE for a more connected phone system." />
                </Helmet>
                <header className="full-width full-hero-medium circle-image full-secondary pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 pb-sm-60 text-sm-center">
                                        <h1 className="text-lightest pt-80 pt-md-30 pt-sm-20 mt-0 text-xs-500">
                                            Capsule CRM Integration
                                        </h1>
                                        <h2 className="subtitle pt-20">
                                            Automatically synchronise your Capsule CRM database with SwitchboardFREE for a more connected phone system.
                                        </h2>
                                        {this.props.isLoggedIn === true

                                          ? this.state.disconnectCapsuleCrm === false
                                            ? this.state.syncSettings && this.state.syncSettings.capsuleCrmEnabled === false
                                              ? <>

                                                    <RequestAuthorizationCode
                                                        oauthClient={oauthClient}
                                                        render={({ url }) => <> <a href={url} className="btn btn-lg btn-cta mb-30 brad-30">Link with Capsule</a> </>
                                                    }/>

                                            </>
                                              : <>
                                                <div className='d-block d-md-none'>
                                                    <div className='alert alert-success p-30 brad-30 text-success text-center w-400px center-block'>
                                                        <p className='text-400 text-success' style={{ color: '#3c763d' }}>You already have integrated Capsule CRM account into your SwitchboardFREE Contact Book.</p>
                                                        <a href="/customer/contact-book" className='btn btn-cta mt-20'>See your Contact Book</a>
                                                    </div>
                                                </div>
                                            </>
                                            : <>

                                            </>
                                          : <>
                                                <div className='d-block d-md-none'>
                                                    <Link to={'/get-started'} className="btn btn-lg btn-cta mt-30">Create Account</Link>
                                                    <Link to={'/login'} className="text-sm text-lightest underline mt-30 block text-400">Or, login if you already have an account</Link>.
                                                </div>

                                            </>
                                    }
                                    </div>
                                    <div className="d-none d-md-block col-md-6 float-end">
                                        <img
                                            src="/images/campaigns/capsule.png"
                                            alt="Capsule CRM"
                                            style={{ maxWidth: '100%' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="full-width full-dull text-xs">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <TextBannerWidget textDark={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-width bg-dull pb-100">
                    {this.props.isLoggedIn === true
                      ? this.state.disconnectCapsuleCrm === false
                        ? this.state.syncSettings && this.state.syncSettings.capsuleCrmEnabled === false
                          ? <>

                            </>
                          : <>
                                <div className='container pt-30 pb-30 d-none d-md-block'>
                                    <div className='alert alert-success p-30 brad-30 text-success text-center w-600px center-block'>
                                        <h5 className='text-500'>You're conntected!</h5>
                                        <p className='text-400'>You already have integrated your Capsule CRM account with your SwitchboardFREE Contact Book.</p>
                                        <a href="/customer/contact-book" className='btn btn-cta mt-20'>See your Contact Book</a>
                                    </div>
                                </div>
                            </>
                        : <>

                            </>
                      : <>

                            </>
                    }
                    <div className="container pt-100">
                        <div className="row pl-30 pr-30 pl-sm-5 pr-sm-5">
                            <div className="col-md-6 d-none d-md-block text-center">
                                <img src="/images/contact-book-preview4.jpg" className="img-responsive" />
                            </div>

                            <div className="col-md-6 bg-light brad-30 pt-50 pb-30 pl-50 pr-50">

                                <h2 className="text-500 mt-0 pt-0 mb-30 h3">New and FREE Capsule CRM integration</h2>
                                <p className="text-500">
                                    Our Capsule CRM integration automatically updates your Capsule contacts in your Contact book, App, softphone and desk phones whilst simultaneously logging all of your call activity in the Capsule CRM.
                                </p>
                                <p className="mt-30">
                                    This becomes even more powerful with the addition of A.I. Caller Greeting™ by greeting your callers using their name and also alerting you audibly to your callers' name before you accept the call.
                                </p>
                                <ul className="fancy cta pl-25 text-400 mt-30">
                                    <li className="pb-20">
                                        All of your contacts are quickly and easily accessible across all your devices
                                    </li>
                                    <li className="pb-20">
                                        Received calls, missed calls and voicemails are all fed directly into your Capsule CRM system
                                    </li>
                                    <li className="pb-20">
                                        A.I. Caller Greeting™. Greets your callers by their name held in Capsule.
                                        {/* [Example Recording]  */}
                                    </li>
                                    <li className="pb-20">
                                        Be alerted to your callers' name before you accept a call
                                        {/* [Example Recording]   */}
                                    </li>
                                    <li className="pb-20">
                                        Automatically link notes from Capsule to your calls recordings
                                    </li>
                                    <li className="pb-20">
                                        When you answer a call, your caller's Capsule page instantly opens in your browser
                                    </li>
                                    <li className="pb-20">
                                        Simple integration
                                    </li>
                                </ul>

                                {this.props.isLoggedIn === true

                                  ? this.state.disconnectCapsuleCrm === false
                                    ? this.state.syncSettings && this.state.syncSettings.capsuleCrmEnabled === false
                                      ? <>
                                                <div className="b-1 text-center p-30 brad-30 box-shadow mt-30 w-100">
                                                    <h5 className="text-500 pt-0 mb-10">
                                                        Link SwitchboardFREE Contact Book with <br className="d-none d-sm-block"/>your Capsule CRM account
                                                    </h5>
                                                    <p className="mb-30">It only takes 60 seconds.</p>

                                                    <RequestAuthorizationCode
                                                        oauthClient={oauthClient}
                                                        render={({ url }) => <> <a href={url} className="btn btn-lg btn-cta mb-30 brad-30">Link with Capsule</a> </>
                                                    }/>
                                                </div>
                                            </>
                                      : <>
                                                {/* <div className='alert alert-success p-30 brad-30 text-success text-center'>
                                                    <h5 className='text-500'>You're conntected!</h5>
                                                    <p className='text-400'>You already have integrated Capsule CRM account into your SwitchboardFREE Contact Book.</p>
                                                    <a href="/customer/contact-book" className='btn btn-cta mt-20'>See your Contact Book</a>
                                                </div> */}
                                            </>
                                    : <>

                                            </>
                                  : <>
                                                <div className="b-1 text-center p-30 brad-30 box-shadow mt-30 w-100">
                                                    <h5 className="text-500 pt-0">Create an account and link it to Capsule today</h5>
                                                    <Link to={'/get-started'} className="btn btn-lg btn-cta mt-30">Create Account</Link>
                                                    <Link to={'/login'} className="text-sm text-dark underline mt-30 block text-400">Or, login if you already have an account</Link>.
                                                </div>
                                            </>
                                    }

                            </div>

                        </div>
                    </div>
                </div>

                {this.props.isLoggedIn === true

                  ? this.state.disconnectCapsuleCrm === false
                    ? this.state.syncSettings && this.state.syncSettings.capsuleCrmEnabled === false
                      ? <>
                            <div className="full-width bg-light pb-100 pt-100">
                                <div className="container">
                                    <div className="row pl-30 pr-30 pl-sm-5 pr-sm-5">
                                        <div className="col-md-6 text-center">
                                            <img src="/images/capsule-switchboardfree-integration.png" className="img-responsive w-sm-600px" alt="What's Capsule CRM?" />
                                        </div>

                                        <div className="col-md-6 pl-80 pl-sm-25 pt-100">
                                            <h3 className="text-500 mt-0 pt-0 mb-10">About Capsule</h3>
                                            <p>
                                                Capsule is a simple yet powerful CRM where you can join over 10,000 global businesses and discover an easier way to manage contacts, sales and grow lasting relationships. <a href='https://capsulecrm.com' target="_blank" rel="nofollow noopener noreferrer" className="text-cta underline" >Visit Capsule</a>.
                                            </p>

                                            <h5 className="text-500 mt-40 pt-0 mb-10">How does SwitchboardFREE integrate with Capsule?</h5>
                                            <p>
                                                Synchronising your Capsule account, couldn’t be easier. Just click on the “Sync contacts” button in your SwitchboardFREE Contact Book and ensure you’re also signed into your Capsule CRM in the same browser.
                                                Then just authorise the Capsule and SwitchboardFREE will open a connection and import your contacts.
                                            </p>
                                            <p>
                                                Scroll down to see our step-by-step integration video.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                      : <>
                            {/* <div className='alert alert-success p-30 brad-30 text-success text-center'>
                                <h5 className='text-500'>You're conntected!</h5>
                                <p className='text-400'>You already have integrated Capsule CRM account into your SwitchboardFREE Contact Book.</p>
                                <a href="/customer/contact-book" className='btn btn-cta mt-20'>See your Contact Book</a>
                            </div> */}
                        </>
                    : <>

                        </>
                  : <>
                            <div className="full-width bg-light pt-100 pb-100">
                                <div className="container">
                                    <div className="row pl-30 pr-30 pl-sm-5 pr-sm-5">
                                        <div className="col-md-6 text-center">
                                            <img src="/images/capsule-switchboardfree-integration.png" className="img-responsive w-sm-600px" alt="What's Capsule CRM?" />
                                        </div>

                                        <div className="col-md-6 pl-80 pl-sm-25 pt-40">
                                            <h3 className="text-500 mt-0 pt-0 mb-10">About Capsule</h3>
                                            <p>
                                                Capsule is a simple yet powerful CRM where you can join over 10,000 global businesses and discover an easier way to manage contacts, sales and grow lasting relationships. <a href='https://capsulecrm.com' rel="nofollow noopener noreferrer" target="_blank" className="text-cta underline" >Visit Capsule</a>.
                                            </p>

                                            <h5 className="text-500 mt-40 pt-0 mb-10">How does SwitchboardFREE integrate with Capsule?</h5>
                                            <p>
                                                Synchronising your Capsule account, couldn’t be easier. Just click on the “Sync contacts” button in your SwitchboardFREE Contact Book and ensure you’re also signed into your Capsule CRM in the same browser.
                                                Then just authorise the Capsule and SwitchboardFREE will open a connection and import your contacts.
                                            </p>
                                            <p>
                                                Scroll down to see our step-by-step integration video.
                                            </p>
                                            <div className="b-1 text-center p-30 brad-30 box-shadow mt-30 w-100">
                                                <h5 className="text-500 pt-0">Create an account and link it to Capsule today</h5>
                                                <Link to={'/get-started'} className="btn btn-lg btn-cta mt-30">Create Account</Link>
                                                <Link to={'/login'} className="text-sm text-dark underline mt-30 block text-400">Or, login if you already have an account</Link>.
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                    </>
                }

                {this.props.isLoggedIn === true

                  ? this.state.disconnectCapsuleCrm === false
                    ? this.state.syncSettings && this.state.syncSettings.capsuleCrmEnabled === false
                      ? <>
                            <div className='full-width pt-60 pb-60' style={{ backgroundColor: '#202F5E' }}>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='w-800px center-block text-center'>
                                            <h4 className='text-lightest text-500 pb-30'>See how easy is to integrate your Capsule contacts into SwitchboardFREE's Contact Book</h4>
                                            <div className="ratio ratio-16x9 brad-20" style={{ border: '10px solid #152041' }}>
                                                <iframe src="https://www.youtube.com/embed/OQ_iFA5KVXY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe>
                                            </div>

                                            <h5 className="text-500 pt-0 mb-20 text-lightest pt-30">
                                                Easy, right? Link SwitchboardFREE Contact Book with <br className="d-none d-sm-block"/>your Capsule CRM account
                                            </h5>
                                            <p className="mb-30 text-lightest">It only takes 60 seconds.</p>

                                            <RequestAuthorizationCode
                                                oauthClient={oauthClient}
                                                render={({ url }) => <> <a href={url} className="btn btn-lg btn-cta mb-30 brad-30">Link with Capsule</a> </>
                                            }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                      : <>
                            {/* <div className='alert alert-success p-30 brad-30 text-success text-center'>
                                <h5 className='text-500'>You're conntected!</h5>
                                <p className='text-400'>You already have integrated Capsule CRM account into your SwitchboardFREE Contact Book.</p>
                                <a href="/customer/contact-book" className='btn btn-cta mt-20'>See your Contact Book</a>
                            </div> */}
                        </>
                    : <>

                        </>
                  : <>
                            <div className='full-width pt-60 pb-60' style={{ backgroundColor: '#202F5E' }}>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='w-800px center-block text-center'>
                                            <h4 className='text-lightest text-500 pb-20'>See how easy is to integrate your Capsule contacts into SwitchboardFREE's Contact Book</h4>
                                            <p className='mb-40 text-lightest'>It only takes about 1 minute. </p>
                                            <div className="ratio ratio-16x9 brad-20" style={{ border: '10px solid #152041' }}>
                                                <iframe src="https://www.youtube.com/embed/OQ_iFA5KVXY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe>
                                            </div>

                                            <h5 className="text-500 pt-40 text-lightest">Create an account and link it to Capsule today</h5>
                                            <Link to={'/get-started'} className="btn btn-lg btn-cta mt-30">Create Account</Link>
                                            <Link to={'/login'} className="text-sm text-lightest underline mt-30 block text-400">Or, login if you already have an account</Link>.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }

{/* this.state.disconnectCapsuleCrm === false ? this.state.syncSettings && this.state.syncSettings.capsuleCrmEnabled === false ?  */}

                    <div className="full-width bg-dull pt-30 pb-60">
                        <div className="container">
                            <div className="row pl-30 pr-30 pl-sm-5 pr-sm-5">
                                <div className="w-1200px center-block row">
                                    <div className="col-md-6 pr-50 pr-sm-25 pt-60">
                                        <h4 className="text-500 mt-0 pt-0 mb-30">What other features does SwitchboardFREE offer?</h4>
                                        <p>
                                            SwitchboardFREE’s VoIP and telephony services help you to create a more professional image, have a national or local presence, manage calls more effectively or track the effectiveness of your marketing, all with the built-in benefit of disaster recovery, we have the answer!
                                        </p>
                                        <p className="pt-10">
                                            With over {this.state.totalCustomers} businesses and homeworkers registered already you can be confident that you’re in safe hands.
                                        </p>
                                        <p className="pt-20">
                                            <Link to={'/features'} className="text-cta underline">See our free features</Link>
                                        </p>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <img src="/images/features.png" className="img-responsive" alt="SwitchboardFREE call management features" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="full-width bg-dark pt-60 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="text-500 text-lightest">SwitchboardFREE</h3>
                                <h6 className="text-400">The UK's favourite call management system</h6>
                                <p className="text-400 pt-20 pb-20">Instant activation  <span className="pl-10 pr-10 text-cta">•</span>  No long-term contracts  <span className="pl-10 pr-10 text-cta">•</span>  Award-winning UK support</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
  }

  async getCustomers () {
	const data = await getCustomerCount()

	this.setState({ totalCustomers:  data})
  }

  componentDidMount () {
    this.getCustomers()

    if (this.props.isLoggedIn === true) {
      fetchHelper.getJson('api/contacts/GetCrmContactBookSyncSettings').then(res => this.setState({ syncSettings: res.data }))
    }
  }
}

export default withRouter(connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  }, { updateUserFromLocalStorage }
)(CapsuleCrm))
