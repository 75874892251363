import React, { Component } from 'react'
const _ = require('lodash')

export default class ExitIntent extends Component {
  state = {
    user: undefined
  }

  render () {
    function closePopup () {
      const ele = document.getElementById('exitPopup')
      if (ele !== null) {
        ele.classList.remove('show')
      }
    }

    const user = sessionStorage.getItem('user')
    if (user && !_.isEqual(JSON.parse(user), this.state.user)) { this.setState({ user: JSON.parse(user) }) }

    return (
            <>
                <div id="exitPopup" className="pop-up">
                    <div className="content">
                        <button className="close" onClick={e => closePopup()}><i className="icon icon-cross2"></i></button>
                        <div className="content-inner text-center">
                            <h3 className="mt-0 mb-30">Before you go, let us know your thoughts!</h3>
                            <p className="mb-40">Thank you for using SwitchboardFREE{this.state.user && this.state.user.fName ? `, ${this.state.user.fName}` : ''}. We'd like to invite you to leave us a review with an independent 3rd party, Reviews.co.uk.</p>
                            {this.state.user
                              ? <p>
                                    <a rel="noopener noreferrer" href={`http://www.reviews.co.uk/store/landing_new_review?store=switchboard-free&user=${this.state.user.fName}&order_id=${this.state.user.clientId}&email=${this.state.user.email}&products=&type=company&rating=5`} className="btn btn-cta btn-lg" target="_blank">
                                        Leave us a review
                                        </a>
                                </p>
                              : <p>
                                    <a href="https://www.reviews.co.uk/company-reviews/store/switchboard-free" rel="noopener noreferrer" className="btn btn-cta btn-lg" target="_blank">
                                        Leave us a review
                                        </a>
                                </p>
                            }
                        </div>
                    </div>
                </div>
            </>
    )
  }
}
