import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import CallbackRequest from '../../../components/CallbackRequest'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import Features from '../../../components/Features/Features'
import CarouselWidget from '../../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { isLoggedIn } from '../../../selectors'

class FeaturesPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      totalCustomers: 0
    }
  }

  handleSignup (isLoggedIn, history) {
    return isLoggedIn ? '/customer/numbers/new' : '/get-started'
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>VoIP Phone Features</title>
                <meta name="description" content="Watch and learn how to turn on missed call alerts, how to set up multiple departments and many more." />
                <style type="text/css">{`
                        .featured-feature .feature-text {
                            padding-left:80px;
                        }
                        .featured-feature .tag {
                            margin-left: -30px;
                            margin-top: -30px;
                            width:160px;
                        }

                        @media screen and (max-width:1500px) {
                            .featured-feature {
                                background-size: 540px !important;
                            }
                       } 
                        
                        @media screen and (max-width:1170px) {
                            .featured-feature {
                                background-position: 104% 100% !important;
                                background-size: 430px !important;
                            }
                       } 
                        @media screen and (max-width:1050px) {
                            .featured-feature {
                                background-image:none !important;
                            }
                            .featured-feature br {
                                display:none !important;
                            }
                            .featured-feature .feature-text {
                                padding-left: 30px !important;
                            }
                            .featured-feature .tag {
                                width:140px;
                            }
                        } 

                        @media screen and (max-width:600px) {
                            .featured-feature .feature-text {
                                padding-left: 0 !important;
                            }
                            .featured-feature .tag {
                                width:110px;
                            }
                        } 
                    
                    `}</style>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/features-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1 id="FeaturesH1">Features of SwitchboardFREE</h1>
                <h2 className="subtitle">Powerful features to ensure your callers have an amazing audio experience whilst you never miss a call again</h2>
            </header>

            <div className="container-styled features-page">
                <div className="container">
                    <div className="row">
                        <div className="text-center col-md-12 mb-40 pt-20">
                            <p className="text-md ">
                                Whether you want to create a more professional image, have a national or local presence, manage calls more effectively or track the effectiveness of your marketing, all with the built-in benefit of disaster recovery, we have the answer!
                                With over {this.state.totalCustomers} businesses and homeworkers registered already you can be confident that you’re in safe hands.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="full-width bg-dull">
                    <div className="container">
                        <Features />
                    </div>
                </div>

                <div className="full-width full-img bg-light pt-60 pb-60 bt-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h4 className="text-600 pb-10">Have any questions?</h4>
                                <h5 className="mb-20 text-400"> Give us a call on <a href="tel:02031891213">0203 189 1213</a> or request a callback.</h5>
                                <CallbackRequest buttonSize="lg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-width bg-dull pt-50 pb-50">
                    <div className="container">
                        <CarouselWidget />
                    </div>
                </div>
            </div>
        </>
  }

  componentDidMount () {
    const tabs = document.getElementsByClassName('nav-tabs')
    // tabs[0].classList.add("nav-tabs-centered");
    const wrapper = document.createElement('div')
    wrapper.classList.add('tabs-container')
    tabs[0].parentNode.insertBefore(wrapper, tabs[0])
    wrapper.appendChild(tabs[0])

    const activeTab = this.props.match.params.activeTab
    if (activeTab !== undefined) {
      localStorage.setItem('featuresTab', activeTab)
    }

    const url = 'api/Home/GetTotalCustomers'
    fetchHelper.getJson(url)
      .then(response => {
        if (response !== undefined) { this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
    document.title = 'SwitchboardFREE Call Management Features'
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(FeaturesPage)
