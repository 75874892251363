import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CallbackRequest from '../../components/CallbackRequest'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { is0203Offer, is0207Offer, is033Offer, is0800Offer, isGeoOffer } from '../../constants/Constants'
import { isLoggedIn } from '../../selectors'

class CompareNumbers extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({ isOpen: true })
  }

  handleSignup (isLoggedIn, history) {
    return isLoggedIn ? '/customer/numbers/new' : '/get-started'
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            {/* <NumbersMenu /> */}
            <Helmet>
                <title>Buy Phone Numbers - Virtual Telephone Numbers</title>
                <meta name="description" content="Compare different phone numbers for business. Get a virtual number on the UK's favourite free call management system." />
                <style type="text/css">{`
                .full-width ul.unstyled {
                    list-style-position: outside !important;
                }
                .full-width ul.unstyled li {
                    padding:5px 10px;
                    font-size:1em;
                }
                .full-width ul.unstyled li .bg-cta {
                    border-radius:50%;
                    height:25px;
                    width:25px;
                    display:inline-block;
                    text-align:center;
                    color:#fff;
                }
                .full-width ul.unstyled li .bg-cta .icon {
                    font-size:14px;
                    top:-1px;
                    position:relative;
                }

                @media screen and (max-width:1310px) {
                    .row.buttons .col-md-6 {
                        width:100%;
                    }
                    .col-md-8.mx-auto {
                        width:100%;
                        margin-left:0;
                    }
                    .row-flex.flex-sm [class*="col-"] {
                        -ms-flex: auto;
                        flex: auto;
                    }
                    .row-flex [class*="col-"] {
                        display: block;
                    }
                 }
                }
                    `}</style>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="CompareNumbersH1">Compare Virtual Number Types</h1>
                <h2 className=" subtitle">Find the best number for your business. Compare our number types to find out more.</h2>
            </header>

            <div className="full-width bg-light">
                <div className="container">
                    <div className="col-md-8 mx-auto">
                        <div className="row row-flex flex-2-col">
                            <div className="col-xs-12 col-sm-6 mt-30 box-shadow b-1 brad-10 p-0 m-10">
                                <img src={`images/${isGeoOffer ? 'sale' : 'local'}.jpg`} className="brad-t-10 bb-cta-5" alt="local" style={{ maxWidth: '100%' }} />
                                <div className="p-50 pb-0 pl-xs-20 pr-xs-20">
                                    <div className="text-center pb-20">
                                        <h4 className="text-500">Local Numbers</h4>
                                        <p className={`${isGeoOffer ? 'text-cta' : 'text-secondary'} text-500`}>
                                            {isGeoOffer && 'On Sale - '}From just <GetProdPrice prefix="01" />
                                        </p>
                                    </div>
                                    <p>
                                        Get 01 &amp; 02 numbers to give your business a local presence &amp; receive up to 22% more calls from that town or city.
                                    </p>
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30">
                                        <li>
                                            Get a number for almost any city in the UK and route this to wherever you are.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Advertising a local number can increase your calls by 22% from the local area.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Give your organisation a local presence across multiple locations in the UK and increase your sales calls instantly.
                                        </li>
                                    </ul>
                                    <div className="button-set pl-40 pr-40 pl-xs-0 pr-xs-0 row row-flex">
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/local-phone-numbers'} className="btn btn-secondary btn-block" id="LocalNumbersLearnMoreButton">Learn more</Link>
                                        </div>
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/local-phone-numbers'} className="btn btn-cta btn-block" id="LocalNumbersGetNumberButton">Get number</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 mt-30 box-shadow b-1 brad-10 p-0 m-10">
                                <img src={`images/${is0800Offer ? 'sale' : '0800'}.jpg`} className="brad-t-10 bb-cta-5" alt="0800" style={{ maxWidth: '100%' }} />
                                <div className="p-50 pb-0 pl-xs-20 pr-xs-20">
                                    <div className="text-center pb-20">
                                        <h4 className="text-500">0800 numbers</h4>
                                        <p className={`${is0800Offer ? 'text-cta' : 'text-secondary'} text-500`}>
                                            {is0800Offer && 'On Sale - '}From just <GetProdPrice prefix="0800" />
                                        </p>
                                    </div>
                                    <p>
                                        Offer a trusted and recognised 0800 number to your callers to attract more sales opportunities.
                                    </p>
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30">
                                        <li>
                                            0800 numbers are completely free to call with no additional surcharges.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Make your business appear bigger and more accommodating with a free to call number.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            An ideal national number and great to use for advertising.
                                        </li>
                                    </ul>
                                    <div className="button-set pl-40 pr-40 pl-xs-0 pr-xs-0 row row-flex">
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/0800-numbers'} className="btn btn-secondary btn-block" id="0800NumbersLearnMoreButton">Learn more</Link>
                                        </div>
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/0800-numbers'} className="btn btn-cta btn-block" id="0800NumbersGetNumberButton">Get number</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-flex flex-2-col">
                            <div className="col-xs-12 col-sm-6 mt-30 box-shadow b-1 brad-10 p-0 m-10">
                                <img src={`images/${is0207Offer ? 'sale' : 'london1'}.jpg`} className="brad-t-10 bb-cta-5" alt="london" style={{ maxWidth: '100%' }} />
                                <div className="p-50 pb-0 pl-xs-20 pr-xs-20">
                                    <div className="text-center pb-20">
                                        <h4 className="text-500">0207 London numbers</h4>
                                        <p className={`${is0207Offer ? 'text-cta' : 'text-secondary'} text-500`}>
                                            {is0207Offer && 'On Sale - '}From just <GetProdPrice prefix="0207" />
                                        </p>
                                    </div>
                                    <p>
                                        The original and best-known London telephone number, 0207 London numbers have traditionally been associated with the Central London area.
                                    </p>
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30">
                                        <li>
                                            Gives you the prestige of operating from an exclusive London location.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            These numbers are becoming very rare and there are not many left.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Add a central London number to enhance your multi-city presence.
                                        </li>
                                    </ul>
                                    <div className="button-set pl-40 pr-40 pl-xs-0 pr-xs-0 row row-flex">
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/0207-numbers'} className="btn btn-secondary btn-block" id="0207NumbersLearnMoreButton">Learn more</Link>
                                        </div>
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/0207-numbers'} className="btn btn-cta btn-block" id="0207NumbersGetNumberButton">Get number</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 mt-30 box-shadow b-1 brad-10 p-0 m-10">
                                <img src={`images/${is0203Offer ? 'sale' : 'london2'}.jpg`} className="brad-t-10 bb-cta-5" alt="lodnon" style={{ maxWidth: '100%' }} />
                                <div className="p-50 pb-0 pl-xs-20 pr-xs-20">
                                    <div className="text-center pb-20">
                                        <h4 className="text-500">0203 London numbers</h4>
                                        <p className={`${is0203Offer ? 'text-cta' : 'text-secondary'} text-500`}>
                                            {is0203Offer && 'On Sale - '}From just <GetProdPrice prefix="0203" />
                                        </p>
                                    </div>
                                    <p>
                                        Give your company a London presence and all of the prestige without the expense of renting a London office.
                                    </p>
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30">
                                        <li>
                                            Cover the whole of the London area and are similar to 0207 numbers.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            These are great if you want a London number but not necessarily central London.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Make your business seem larger and more prestigious with an 0203 number.
                                        </li>
                                    </ul>
                                    <div className="button-set pl-40 pr-40 pl-xs-0 pr-xs-0 row row-flex">
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/0203-numbers'} className="btn btn-secondary btn-block" id="0203NumbersLearnMoreButton">Learn more</Link>
                                        </div>
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/0203-numbers'} className="btn btn-cta btn-block" id="0203NumbersGetNumberButton">Get number</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-flex flex-2-col">
                            <div className="col-xs-12 col-sm-6 mt-30 box-shadow b-1 brad-10 p-0 m-10">
                                <img src={`images/${is033Offer ? 'sale' : '03'}.jpg`} className="brad-t-10 bb-cta-5" alt="03 numbers" style={{ maxWidth: '100%' }} />
                                <div className="p-50 pb-0 pl-xs-20 pr-xs-20">
                                    <div className="text-center pb-20">
                                        <h4 className="text-500">03 Numbers</h4>
                                        <p className={`${is033Offer ? 'text-cta' : 'text-secondary'} text-500`}>
                                            {is033Offer && 'On Sale - '}From just <GetProdPrice prefix="03" />
                                        </p>
                                    </div>
                                    <p>
                                        Mobile-friendly national numbers, great for customer support lines.
                                    </p>
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30">
                                        <li>
                                            Non geographical and are not associated with any location, giving you a national presence.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Included in inclusive mobile minutes and are charged at standard call rates.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Perfect for customer support lines and online businesses such as eCommerce.
                                        </li>
                                    </ul>
                                    <div className="button-set pl-40 pr-40 pl-xs-0 pr-xs-0 row row-flex">
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/03-numbers'} className="btn btn-secondary btn-block" id="03NumbersLearnMoreButton">Learn more</Link>
                                        </div>
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/03-numbers'} className="btn btn-cta btn-block" id="03NumbersGetNumberButton">Get number</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 mt-30 box-shadow b-1 brad-10 p-0 m-10">
                                <img src="images/084.jpg" className="brad-t-10 bb-cta-5" alt="084" style={{ maxWidth: '100%' }} />
                                <div className="p-50 pb-0 pl-xs-20 pr-xs-20">
                                    <div className="text-center pb-20">
                                        <h4 className="text-500">084 Numbers</h4>
                                        <p className="text-secondary text-500">Free</p>
                                    </div>
                                    <p>
                                        Enjoy a national presence with FREE 0843 &amp; 0844 phone numbers for your business
                                    </p>
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30">
                                        <li>
                                            Completely free to you when routed to a UK landline.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Choose from a fantastic range of free 0844 and 0843 telephone numbers.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Give your business a professional image and national presence at no cost.
                                        </li>
                                    </ul>
                                    <div className="button-set pl-40 pr-40 pl-xs-0 pr-xs-0 row row-flex">
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/084-numbers'} className="btn btn-secondary btn-block" id="084NumbersLearnMoreButton">Learn more</Link>
                                        </div>
                                        <div className="col-md-6 p-5 pb-0 m-0">
                                            <Link to={'/084-numbers'} className="btn btn-cta btn-block" id="084NumbersGetNumberButton">Get number</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width full-secondary text-lightest pt-30 pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4 className="text-500">Have any questions?</h4>
                            <h5 className="mb-20"> Give us a call on <a href="tel:02031891213" className="text-lightest">0203 189 1213</a> or request a callback</h5>
                            <CallbackRequest />
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width bg-dull pt-50">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>
        </>
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(CompareNumbers)
