import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'

const DynamicCliPrefixes = (props) => (
  <>
    <div className="row bg-dullest p-30 zoom">
      <div className="col-md-5 pb-sm-10">
        <h5 className="text-secondary text-500 text-lg">Dynamic CLI</h5>
        <p className="text-400">
          By setting a two digit CLI selector prefix your users can change the number they present when making an outbound call by simply dialing # followed by your chosen 2 digits and the number they wish to dial.
        </p>
      </div>
      <div className="col-md-7 pb-sm-30 pl-sm-0 pr-sm-0">
        <div className="col-md-12 mt-15">
          <p>Prefix:</p>
          {(props.dynamicCli !== null && props.dynamicCli !== undefined) &&
            <div>
              {(props.dynamicCli > 99 || props.dynamicCli < 9) &&
                <div className="alert alert-warning">
                  The number must be between 10-99
                </div>
              }
            </div>
          }

          <input
            className="form-control"
            type='number'
            min={1} 
            max={99}
            maxLength={2}
            onChange={(e) => {props.handleDynamicCliChange(e.target.value === '' ? null : parseInt(e.target.value))}}
            value={props.dynamicCli}
          />
          </div>
        </div>
      </div>
    </>
)

const mapStateToProps = state => {
  return ({
    dynamicCli: state.configuration.configuration.dynamicCli
  })
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicCliPrefixes)
