import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'
import CustomPrompts from '../../CustomPrompts'

const DepartmentMenuChoiceInstructionHelperDialog = props => (
    <Modal className="w-900px" show={props.isDisplayed}
           onHide={props.handleHideDepartmentMenuChoiceInstructionHelperDialog}>
        <Modal.Header closeButton>
            <Modal.Title>Department Title</Modal.Title>
        </Modal.Header>
        <Modal.Body className="col-md-12">
            <p>
                This is what your caller will hear when being given the option to select the department. e.g. ( “For
                support press one, for sales press two”)
            </p>
            <h4 className="pt-30">Pre-recorded options</h4>
            <p>
                You can select a different pre-recorded department title for each of your departments and the system
                will seamlessly piece these together for your caller to hear. For example if you selected “support” as
                the title for your first department and “sales” for you second department your caller would hear “For
                support press one, for sales press two”.
            </p>
            <div className="col-md-12 text-center pb-30 pt-30">
                <h3>Make your company sound amazing!</h3>
                <p>Choose one of the below voice artists to record your departments menu, out of hours message, introduction, call screening, voicemail message... or simply do it yourself.</p>
            </div>
            <CustomPrompts />
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.handleHideDepartmentMenuChoiceInstructionHelperDialog}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.departmentMenuChoiceInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentMenuChoiceInstructionHelperDialog)
