import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'
import CustomPrompts from '../../CustomPrompts'

const MainIntroductionInstructionHelperDialog = props => (
    <Modal className='w-900px' show={props.isDisplayed}
           onHide={props.handleHideMainIntroductionHelperInstruction}>
        <Modal.Header closeButton>
            <Modal.Title>Main Introduction</Modal.Title>
        </Modal.Header>
        <Modal.Body className="col-md-12">
            <p>
                Your main introduction is the very first thing your call will hear when they call your number.
            </p>
                <p>
                After hearing this message your caller will be given the department options which you have made
                available to them.
                </p>
                <div className="col-md-12 text-center pb-30 pt-30">
                <h3>Make your company sound amazing!</h3>
                <p>Choose one of the below voice artists to record your out of hours message, introduction, call screening, voicemail message, departments menu... or simply do it yourself.</p>
            </div>
            <CustomPrompts />
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.handleHideMainIntroductionHelperInstruction}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.mainIntroductionInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainIntroductionInstructionHelperDialog)
