import React, { useEffect, useState } from 'react'

import { fetchHelper } from '../helpers/fetchHelper'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import LoadingNotification from "../components/LoadingNotification";

interface FeatureEnableDetectorProps {
  enabledComponent: React.FunctionComponent
  disabledComponent: React.FunctionComponent
  featureId: number
}

const FeatureEnableDetector: React.FC<FeatureEnableDetectorProps> = (props) => {
  const [initiatedFeatureCheck, setInitiatedFeatureCheck] = useState<boolean>(false)
  const [checkingIfFeatureEnabled, setCheckingIfFeatureEnabled] = useState<boolean>(false)
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(true)
  const [featureEnabled, setFeatureEnabled] = useState<boolean>(true)

  function CheckIfFeatureEnabled () {
    if (!checkingIfFeatureEnabled) {
      setCheckingIfFeatureEnabled(true)

      fetchHelper.getJson('/api/users/HasFeatureEnabled/' + props.featureId).then((res) => {
        setFeatureEnabled(res.data === true)
        setShowLoadingIcon(false)
        setCheckingIfFeatureEnabled(false)
      })
    }
  }

  useEffect(() => {
    if (!initiatedFeatureCheck) {
      setInitiatedFeatureCheck(true)
      CheckIfFeatureEnabled()
    }

    return () => {

    }
  }, [])

  function renderLoadingScreen () {
    return (
			<>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 pb-20">
                        <div className="text-center p-50">
                            <LoadingNotification
                                className="mt-30"
                                message={"Please wait..."}
                                isLoading={true}
                                textColor="text-dark"
                            />
                        </div>
                    </div>
                </div>
            </div>
            </>
    )
  }
  return (
        <>
        <DisplayMainHeader />
        <DisplayMainFooter />
        {showLoadingIcon && renderLoadingScreen()}
        {!showLoadingIcon && featureEnabled && <props.enabledComponent />}
        {!showLoadingIcon && !featureEnabled && <props.disabledComponent />}
        </>
  )
}

export default FeatureEnableDetector