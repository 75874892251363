import React, { Component } from 'react'
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayLoggedInFooter from '../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { fetchHelper } from '../helpers/fetchHelper'

class CallInformation extends Component {
  state = {
    CallDate: undefined,
    CallerNumber: undefined,
    CallDuration: undefined,
    HoldTime: undefined,
    Result: undefined,
    AnsweredBy: undefined,
    Cost: 0,
    showAddNotePopup: false,
    callNote: '',
    callRecordingId: undefined,
    rejectCode: undefined,
    withheld: false
  }

  constructor (props) {
    super(props)

    this.handleSaveCallNotePressed = this.handleSaveCallNotePressed.bind(this)
    this.handleRecordingDownload = this.handleRecordingDownload.bind(this)
  }

  componentDidMount () {
    const url = '/api/Numbers/GetCallInformation/' + this.props.match.params.callId

    fetchHelper.getJson(url).then(response => {
      this.setState({
        CallDate: response.data.callDate,
        CallerNumber: response.data.callerNumber,
        CallDuration: response.data.callDuration,
        HoldTime: response.data.holdTime,
        Result: response.data.result,
        AnsweredBy: response.data.answeredBy,
        Cost: response.data.cost,
        callNote: response.data.note,
        callRecordingId: response.data.callRecordingId,
        rejectCode: response.data.rejectCode,
        withheld: response.data.withheld
      })
    })
      .catch(error => {
        console.error(error)
      })

    document.title = 'Call Information'

    if (this.state.callRecordingId !== undefined && this.state.callRecordingId > 0) {
      alert('Call Recording Id Returned')

      const callRecordingUrl = 'api/CallRecording/DownloadCallRecordingAudio/' + this.state.callRecordingId

      fetchHelper.getBlob(callRecordingUrl).then(response => {
        const audioPlayer = document.getElementById('audioPlayer')
        audioPlayer.pause()
        audioPlayer.currentTime = 0
        audioPlayer.src = window.URL.createObjectURL(response.data, { type: 'audio/mpeg' })
      }).catch(error => console.error(error))
    }
  }

  render () {
    const rejectCodeOverlayText = [
      { code: 1, text: ' test1' },
      { code: 2, text: ' test2' }
    ]

    const rejectedOverlay = (
            <Popover>
                <p>
                    { this.state.Result === 'Rejected' && rejectCodeOverlayText.find(x => x.code === this.state.rejectCode) !== undefined && rejectCodeOverlayText.find(x => x.code === this.state.rejectCode).text }
                </p>
            </Popover>
    )

    const callRecording = (this.props.services.hasOutboundCallRecording || this.props.services.hasCallRecordingSingle || this.props.services.hasCallRecordingAll || this.props.services.hasUnlimitedCallRecording)

    return (
            <>
                <ScrollToTopOnMount />
                <DisplayMainHeader />
                <DisplayLoggedInFooter />
                <Helmet>
				    <title>Virtual Landlines - Virtual Phone Numbers</title>
                    <style type="text/css">{`
                        .call-info {
                            width: 13%;
                            float: left;
                            margin-right: 15px;
                        }
                        .call-info:last-of-type {
                            margin-right: 0px;
                        }
                        @media screen and (max-width:1500px) {
                            .call-info p.text-sm {
                                font-size:0.81em !important;
                            }
                        }
                        @media screen and (max-width:1200px) {
                            .call-info {
                                width: 23%;
                            }
                            .col-md-6 {
                                width:100%;
                            }
                        }
                        @media screen and (max-width:1200px) {
                            .call-info {
                                width: 23%;
                            }
                        }
                        @media screen and (max-width:992px) {
                            .call-info {
                                width: 47%;
                            }
                            .call-info:nth-child(2n+0) {
                                margin-right:0;
                            }
                        }
                        @media screen and (max-width:440px) {
                            .call-info {
                                width: 100%;
                                margin:0 0 20px 0 !important;
                            }
                        }
                        
                    `}</style>
    			</Helmet>
                <Modal className="modal-med question-modal" show={this.state.showAddNotePopup} onHide={ () => this.setState({ showAddNotePopup: false }) } >
                    <Modal.Body className="text-center">
                            <h4 className="pb-20">Your notes about this call</h4>
                            <textarea id="callNote" className="form-control" rows="10" defaultValue={ this.state.callNote } style={ { width: '100%' } }/>
                    </Modal.Body>
                    <Modal.Footer className="text-center bt-0 p-0 mt-30">
                        <Button variant="default" className="btn-block brad-0 pt-20 pb-20" onClick={ () => this.setState({ showAddNotePopup: false }) } > Cancel </Button>
                        <Button variant="cta" className="btn-block brad-0 pt-20 pb-20" onClick={ () => this.handleSaveCallNotePressed() }>Save</Button>
                    </Modal.Footer>
                </Modal>
                <header>
                    <div className="full-width full-hero-small" style={{ backgroundImage: "url('/images/backgrounds/4-competitor-bg.jpg')" }}></div>
                </header>

<div className="container-styled bg-light">
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center pb-30">
                <h1>Call info</h1>
                <p className="lead">Basic information about this call</p>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="bg-dull b-2 brad-20 pl-30 pr-30 pt-10 pb-30 mb-100 p-xxs-10">
        <div className="row">
            { callRecording === false
              ? <div className="col-md-6">
                        <div className="bg-light p-20 brad-10 mt-20 box-shadow b-1 text-center">
                            <img src="/images/icons/new-tag.png" className="new-tag" alt="new feature" style={{ width: '100px', left: '-10px', top: '0px' }} />
                            <p className="text-500 text-sm">Call recording</p>
                            <p className="text-sm">You don&apos;t have Call Recording enabled on your account.</p>
                            <a className="btn btn-cta btn-xs m-5" href="/call-recording/">Enable call recording</a>
                        </div>
                    </div>
              : this.state.callRecordingId !== undefined && this.state.callRecordingId > 0
                ? <div className="col-md-6 text-center">
                        <div className="bg-light p-20 brad-10 mt-20 box-shadow b-1">
                            <p className="text-500 text-sm">Call recording</p>
                            <audio id="audioPlayer" controls className="mb-20">
                                Your browser does not support the audio element. Please install newest version of Google Chrome or Firefox.
                            </audio>
                            <div className="text-center">
                                <Button variant="cta" size="xs" className="btn-xxs-block m-5" onClick={ () => this.handleRecordingDownload() }>Download</Button>
                                <a className="btn btn-secondary btn-xs btn-xxs-block m-5" href="./customer/call-recording/">See other recordings</a>
                            </div>
                        </div>
                    </div>
                : <div className="col-md-6">
                        <div className="bg-light p-20 brad-10 mt-20 box-shadow b-1 text-center">
                            <img src="/images/icons/new-tag.png" className="new-tag" alt="new feature" style={{ width: '100px', left: '-10px', top: '0px' }} />
                            <p className="text-500 text-sm">Call recording</p>
                            <p className="text-sm">No Recording found.</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                }
                <div className="col-md-6">
                    <div className="bg-light p-20 brad-10 mt-20 box-shadow b-1 text-center">
                        <p className="text-500 text-sm">Your notes about this call</p>
                        {/* <p className="text-sm">{this.state.callNote }</p> */}
                        <p className="text-sm">{this.state.callNote === '' ? 'No notes added' : this.state.callNote }</p>
                        <a className="btn btn-cta btn-xs m-5" onClick={() => this.setState({ showAddNotePopup: true })}>
                            {this.state.callNote === '' ? 'Add note' : 'Edit above notes' }
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 pl-20 pr-20 pb-30 pt-30">
                    <div className="call-info box-shadow b-1 brad-10 text-center p-20 mb-10 bg-light">
                        <p className="text-500 text-sm">Call from</p>
                        <p className="text-sm">{this.state.withheld === true ? 'withheld' : this.state.CallerNumber}</p>
                    </div>
                    <div className="call-info box-shadow b-1 brad-10 text-center p-20 mb-10 bg-light">
                        <p className="text-500 text-sm">Call date</p>
                        <p className="text-sm">{this.state.CallDate}</p>
                    </div>
                    <div className="call-info box-shadow b-1 brad-10 text-center p-20 bg-light">
                        <p className="text-500 text-sm">Call duration</p>
                        <p className="text-sm">{this.state.CallDuration}</p>
                    </div>
                    <div className="call-info box-shadow b-1 brad-10 text-center p-20 mb-10 bg-light">
                        <p className="text-500 text-sm">Hold time</p>
                        <p className="text-sm">{this.state.HoldTime}</p>
                    </div>
                    <div className="call-info box-shadow b-1 brad-10 text-center p-20 mb-10 bg-light">
                        <p className="text-500 text-sm">Result</p>

                        <p className="text-sm"> {this.state.Result} {this.state.rejectCode > 0 &&
                            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={rejectedOverlay}>
                                <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                            </OverlayTrigger>
                        }
                        </p>
                    </div>

                    <div className="call-info box-shadow b-1 brad-10 text-center p-20 mb-10 bg-light">
                        <p className="text-500 text-sm">Answered by</p>
                        <p className="text-sm">{this.state.AnsweredBy}</p>
                    </div>

                    <div className="call-info box-shadow b-1 brad-10 text-center p-20 mb-10 bg-light">
                        <p className="text-500 text-sm">Cost</p>
                        <p className="text-sm">{this.state.Cost === 0 ? '£0.00' : '£' + this.state.Cost } </p>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>

            </>

    )
  }

  handleSaveCallNotePressed () {
    const callNote = document.getElementById('callNote').value
    const url = '/api/Numbers/UpdateCallNote'

    const data = {
      callNote,
      callId: this.props.match.params.callId
    }

    fetchHelper.postJson(url, data).then(res => {
      const data = res.data

      this.setState({
        callNote,
        showAddNotePopup: false
      })
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleRecordingDownload () {
    const callRecordingId = this.state.callRecordingId

    const context = this
    context.setState({ isLoading: true })

    const url = `api/CallRecording/DownloadCallRecordingAudio/${callRecordingId}`

    fetchHelper.getBlob(url).then(response => {
      context.setState({ isLoading: false })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${callRecordingId}_callRecording.wav`)
      document.body.appendChild(link)
      link.click()
    }).catch(error => console.error(error))
  }
}

export default connect(
  state => {
    return {
      services: state.sbfApp.enabledServices
    }
  }
)(CallInformation)
