import moment from 'moment'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Logos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'
import * as fromHome from '../../store/Home'
import { handleNotificationChange, handleShowCallbackForm, handleShowPaymentOptionsModal } from '../../store/SBF'
import TermsAndConditions from '../../Text/TermsAndConditions'

class PriceIncrease extends React.Component {
  state = {
    qty: 1,
    isLoading: true,
    isPurchaseInProgress: false,
    finished: false,
    priceIncrease: 7, // 7%
    annualDiscount: 5 // 5%
  }

  constructor (props) {
    super(props)
  }

  render () {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <>
				<p className="text-700 text-cta text-uppercase text-md mb-20">
					Price Increase In...
				</p>
				<div className="countdown-timer">
					<div className="timer-block">
						{days}<br /><span className="text-sm">days</span>
					</div>
					<div className="timer-block">
						{hours}<br /><span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}<br /><span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}<br /><span className="text-sm">seconds</span>
					</div>
				</div>
			</>
    }

    let totalPrice = 0
    let savings = 0

    if (this.state.subs && this.state.subs.length > 0) {
      this.state.subs.filter(f => f.selected === true).forEach((sub, i) => {
        //const newPrice = (sub.amount + (sub.amount * (this.state.priceIncrease / 100))) // Existing price + 5%
				const newPrice = calculatePriceIncrease(sub.amount, this.state.priceIncrease)
        const annualPrice = ((sub.amount * 12) - ((sub.amount * 12) * this.state.annualDiscount / 100)) // Annual price with 5% discount

        savings += ((newPrice - sub.amount) * 12) + ((sub.amount * 12) - annualPrice)
        totalPrice += annualPrice
      })
		}

		function calculatePriceIncrease(amount, increase) {
			// lets get the increase amount
			const increaseAmount = amount * (increase / 100)

			// cap it at 70p
			const cappedIncrease = Math.min(increaseAmount, 0.70);

			return parseFloat(amount + cappedIncrease).toFixed(2);
		}

    return <><DisplayMainFooter />
			<Helmet>
				<body className={'body-simple bg-dull body-signup'} />
				<title>EXCLUSIVE! Claim Your FREE VoIP Phones!</title>
			</Helmet>
			{this.state.isLoading
			  ? <div className="p-50">
					<LoadingNotification loadingText={'Getting your subscriptions, please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
				</div>
			  :				<>
					<div className={'p-50 p-xs-20'}>
						<div className="container p-xs-0">
							<div className="row mb-50">
								<div className="col-md-10 mx-auto text-center">
									<p className="text-lg text-400 mt-30">
										Our Prices Are Increasing, <em>But You Can <span className="text-cta text-500"><u>Avoid The Price Increase</u></span>!</em>
									</p>
									<h1 className="text-600 text-xxl">
										Upgrade To Annual Billing Today, <br className="d-none d-md-inline" />Avoid The Price Increase - <em className="text-cta"><u>SAVE {this.state.priceIncrease + this.state.annualDiscount}%!</u></em>
									</h1>
									{!this.state.finished && !this.state.offerEnded &&
										<>
											<span className="p-20 bg-darker mb-20 d-inline-block center-block brad-100 mt-30">
											<Countdown date={moment('12/31/2024').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
											</span>
										</>
									}
								</div>
							</div>
							<div className="row">
								<div className="col-lg-7 col-md-8 col-sm-12 mx-auto">
									<div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
										{this.state.offerEnded
										  ? <>
												<h3 className="text-500 mt-30">
													Oh no!
												</h3>
												<p className="text-400 lead mb-10 mt-0">Sorry, but this offer has now ended.</p>
												<p className="text-md mt-30">
													Please keep an eye on your inbox and our social media channels for new offers in the future.
												</p>
												<p className="mt-30">
													<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
												</p>
											</>
										  :											this.state.finished
										    ? <>
													<h3 className="text-500 mt-30">
														All done!
													</h3>
													<p className="text-400 lead mb-10 mt-0">Congratulations, your subscriptions have been updated.</p>
													<p className="mt-30">
														<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
													</p>
												</>
										    :												this.state.diallerSent === true
										      ? <>
														<h3 className="text-500 mt-30">
															Thanks!
														</h3>
														<p className="text-400 lead mb-10 mt-0">We'll give you a call shortly.</p>
														<p className="text-md mt-30">
															We've received your request and one of our team will call you shortly to answer any questions you may have and help you get setup.
														</p>
														<p className="mt-30">
															<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
														</p>
													</>
										      :													<>
														<div className="row mb-30">
															<div className="col-12 text-sm pt-10 col-xxs-12 text-xxs-center pb-xxs-10 text-400">
																<em><span className="text-500">Step 1:</span> Select the subscriptions you want to upgrade:</em><br />
																Note: New monthly prices below effective from 1st Jan.
															</div>
														</div>

														<div className="row">
															{(this.state.subs && this.state.subs.length > 0)
															  ? <>
																	<div className="col-sm-12 col-md-12 pb-40">
																		<div className="row">
																			<div className="col-sm-4 col-md-4 d-none d-md-block">
																				<p className="text-500 text-xs mb-0 mt-10">Description</p>
																			</div>
																			<div className="col-sm-2 col-md-3 d-none d-md-block">
																				<p className="text-500 text-xs mb-0 mt-10">New Monthly Amount</p>
																			</div>
																			<div className="col-sm-2 col-md-3 d-none d-md-block">
																				<p className="text-500 text-xs mb-0 mt-10">Annual Amount</p>
																			</div>
																			<div className="col-sm-2 col-md-2 text-end">
																				<Button size="xs" variant="cta" className="mb-20" onClick={() => this.handleToggleAll(!(this.state.subs && this.state.subs.every(a => a.selected === true))) }>
																					Toggle all
																				</Button>
																			</div>
																		</div>

																		<div className='row'>
																			{this.state.subs.map((sub, i) => <>
																				<div className="bg-light brad-10 p-15 box-shadow shadow-lighter mb-10 cursor-pointer" onClick={() => this.handleToggle(i, sub.selected)}>
																					<div className="col-sm-12 col-md-12">
																						<div className='row'>
																							<div className="col-sm-12 col-md-4">
																								<span className="text-500 text-xs d-block d-md-none">Description: </span>
																								<p className="mb-0 text-sm">
																									{sub.description}
																								</p>
																							</div>
																							<div className="col-6 col-md-3 pt-sm-20">
																								<span className="text-500 text-xs d-block d-md-none">New Monthly Amount: </span>
																								<p className="mb-0 text-sm">
																									&pound;{calculatePriceIncrease(sub.amount, this.state.priceIncrease)}
																								</p>
																							</div>
																							<div className="col-6 col-md-3 pt-sm-20">
																								<span className="text-500 text-xs d-block d-md-none">Annual Amount: </span>
																								<p className="mb-0 text-sm">
																									<span className="text-dark">&pound;{parseFloat(
																									  (sub.amount * 12) -
																										((sub.amount * 12) * this.state.annualDiscount / 100)).toFixed(2)}</span>
																									<span className="ml-5 text-success text-500">Save {this.state.annualDiscount + this.state.priceIncrease}%!</span>
																								</p>
																							</div>
																							<div className="col-sm-12 col-md-2 pt-sm-20 text-end text-xs-start">
																								<span className="text-500 text-xs d-block d-md-none">Selected: </span>

																								<span className="styled-checkbox checkbox-light checkbox checkbox-full mr-20">
																									<Form.Check inline className="mt-neg-15" checked={sub.selected} />
																								</span>
																							</div>
																						</div>
																					</div>
																				</div>
																			</>
																			)}
																		</div>
																	</div>
																</>
															  : <p>It doesn't look like you currently have any subscriptions.</p>
															}
														</div>

														<div className="row">
															{this.state.subs.filter(f => f.selected === true).length === 0
															  ? <>
																	<h3 className="text-600">
																		You've not yet selected any subscriptions to upgrade<br />
																		<span className="text-xs text-400">
																			Simply select your subscriptions above
																		</span>
																	</h3>

																	<p className="text-md mb-30 mt-10" onClick={() => this.handleToggleAll(true) }>
																		<span className="text-uppercase text-cta text-500 underline mr-10 text-md cursor-pointer">
																			<em>Or, click here to select them all</em>
																		</span>
																	</p>
																</>
															  :																<>
																	<h3 className="text-600">
																		Upgrade Your Subscriptions<br />
																		<span className="text-xs text-400">
																			You've selected {this.state.subs.filter(f => f.selected === true).length} subscriptions to upgrade.
																		</span>
																	</h3>

																	<p className="text-md mb-30 mt-10">
																		<span className="text-uppercase text-cta text-500 underline mr-10 text-md">
																			<span className="text-dark">Your Savings:</span> <em>&pound;{parseFloat(savings).toFixed(2)}.</em> <span className="text-dark">per year</span>
																		</span>
																	</p>
																</>
															}
														</div>

														<p className="text-md mb-0">
															Switch to <span className="text-cta underline text-500">Annual Payments</span> today for the following benefits:
														</p>

														<ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-30 text-xs-xs">
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Avoid the {this.state.priceIncrease}% price increase. AND, save an additional {this.state.annualDiscount}% per year!</em></li>
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Enjoy a combined annual saving of {this.state.priceIncrease + this.state.annualDiscount}%!</em></li>
															<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Guarantee you&apos;re not effected by any further price increases in the next 12-months</em></li>
														</ul>

														<div className="mb-20 mt-30">
															{this.state.isPurchaseInProgress
															  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
															  :																<>
																	<div className="bg-light brad-10 p-20">
																		<p className="pl-30 pl-xs-0 mt-10 lead text-center text-400">Total payment today: <span className="underline">&pound;{parseFloat(totalPrice).toFixed(2)} +VAT.</span></p>
																		<Button variant="cta" size="lg" className="btn-block"
																			disabled={this.state.subs.filter(f => f.selected === true).length === 0}
																			onClick={() => this.handleUpgrade()}>Upgrade &amp; Pay Now</Button>

																		<p className="text-center mt-10 text-sm mb-0">
																			<Button variant="link" className="text-dark" onClick={() => this.handleSubmitToSupport()}>Or, request more information</Button>
																		</p>
																	</div>
																</>
															}
														</div>

														{this.state.diallerSent === false &&
															<p className="text-400 text-danger text-center">
																Sorry, there was a problem submitting your request, please try again.
															</p>
														}
													</>
										}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="bg-dull p-50 text-center">
						<sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
					</div>
				</>
			}

			<div className="bg-light pt-50 pb-50">
				<h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
				<Logos style="light" />
			</div>

			<div className="full-width bg-dull">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>

			<Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
				<Modal.Header closeButton>
					<Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TermsAndConditions />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
				</Modal.Footer>
			</Modal>

			<ChoosePayment
				isRedirect={false}
				total={this.state.wpAmount}
				clientId={this.props.clientId}
				purchaseData={this.state.key}
				returnRoute={`/price-increase?t=${this.state.token}`}
				email={this.props.userDetails.email}
				name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
				mandate={!this.props.services.hasAgreement}
				handleParentHideModal={() => this.handleUpgrade(true)}
			/>

			<Modal size="sm" show={this.state.showLogin}>
				<Modal.Header>
					<Modal.Title className="mx-auto">Log into your account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12 text-center pb-30">
							<form name="form" className="text-left" onSubmit={e => this.props.login({
							  userName: this.props.userName,
							  password: this.props.password,
							  event: e,
							  history: this.props.history,
							  persist: document.getElementById('persistLogin').checked,
								route: `/price-increase?t=${this.state.token}`
							})}>
								<div className="row">
									<div className="col-xs-12">
										<p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
									</div>
									<div className="col-xs-12">
										<label>Email</label>
										<input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
									</div>
									<div className="col-xs-12 mt-20">
										<label>Password</label>
										<input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
									</div>
								</div>
								<div className="pt-10 pb-10">
									<div className="col-xs-6 pl-0">
										<input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
									</div>
									<div className="col-xs-6 text-right pr-0">
										<Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
									</div>
								</div>
								<div className="text-center pt-30">
									{this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                  {this.props.loginFailed === true && (this.props.loginFailReason === '' || this.props.loginFailReason === null || this.props.loginFailReason === undefined) ?
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'} className="underline text-danger">resetting your password</Link>.</p>
                  :
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage" hidden={!this.props.loginFailed}>{this.props.loginFailReason}</p>
                  }
									<button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

									<p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
									<p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>

		</>
  }

  handleToggleAll (val) {
    const newArr = this.state.subs
    newArr.forEach(sub => {
      sub.selected = val
    })
    this.setState({ subs: newArr })
  }

  handleToggle (i, val) {
    const newArr = this.state.subs
    newArr[i].selected = !val
    this.setState({ subs: newArr })
  }

  handleUpgrade (paid) {
    const context = this
    context.setState({ isPurchaseInProgress: true })

    const url = `api/AccessToken/SwitchSubsToAnnualAndBill?t=${context.state.token}&paid=${paid}`

    const data = this.state.subs.filter(f => f.selected === true)

    fetchHelper.postJson(url, data)
      .then(res => {
        context.setState({ isPurchaseInProgress: false })
        if (res.data && res.data.isSuccess === true) {
          context.setState({ finished: true, showCheckout: false })
        } else {
          context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false, clientId: res.data.clientId }, () => {
            context.props.handleShowPaymentOptionsModal()
          })
        }
      }).catch(err => {
        context.setState({ isPurchaseInProgress: false })
        console.error(err)
      })
  }

  componentWillMount () {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount () {
    const context = this

    if (!context.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else {
      const url = `/api/AccessToken/GetSubForClient?t=${context.state.token}`

      fetchHelper.getJson(url)
        .then(res => {
          const subs = []

          res.data.subscriptions.forEach((sub, i) => {
            const data = {
              subscriptionId: sub.subscriptionId,
              description: sub.description,
              amount: sub.amount,
              selected: false
            }

            subs.push(data)
          })

          context.setState({
            isLoading: false,
            subs
          })
        }).catch(err => console.error(err))
    }
  }

  handleSubmitToSupport () {
    const context = this

    context.setState({ submittingToDialler: true }, () => {
      const url = `/api/AccessToken/SubmitLeadToSupport?t=${context.state.token}&product=Customer%20Interested%20In%20Switching%20Subs%20To%20Annual`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ diallerSent: res.data, submittingToDialler: false })
        }).catch(err => console.error(err))
    })
  }
}

export default connect(
  state => {
    const deliveryData = state.sbfApp.deliveryData

    return {
      isLoggedIn: isLoggedIn(state),
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed,
      loginFailReason: state.home.login.reason
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    notify: handleNotificationChange,
    handleShowCallbackForm,
    handleShowPaymentOptionsModal
  }
)(PriceIncrease)
