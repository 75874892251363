export const daytimeSelectorOptions = [
  { value: '0000', label: '00:00' },
  { value: '0015', label: '00:15' },
  { value: '0030', label: '00:30' },
  { value: '0045', label: '00:45' },
  { value: '0100', label: '01:00' },
  { value: '0115', label: '01:15' },
  { value: '0130', label: '01:30' },
  { value: '0145', label: '01:45' },
  { value: '0200', label: '02:00' },
  { value: '0215', label: '02:15' },
  { value: '0230', label: '02:30' },
  { value: '0245', label: '02:45' },
  { value: '0300', label: '03:00' },
  { value: '0315', label: '03:15' },
  { value: '0330', label: '03:30' },
  { value: '0345', label: '03:45' },
  { value: '0400', label: '04:00' },
  { value: '0415', label: '04:15' },
  { value: '0430', label: '04:30' },
  { value: '0445', label: '04:45' },
  { value: '0500', label: '05:00' },
  { value: '0515', label: '05:15' },
  { value: '0530', label: '05:30' },
  { value: '0545', label: '05:45' },
  { value: '0600', label: '06:00' },
  { value: '0615', label: '06:15' },
  { value: '0630', label: '06:30' },
  { value: '0645', label: '06:45' },
  { value: '0700', label: '07:00' },
  { value: '0715', label: '07:15' },
  { value: '0730', label: '07:30' },
  { value: '0745', label: '07:45' },
  { value: '0800', label: '08:00' },
  { value: '0815', label: '08:15' },
  { value: '0830', label: '08:30' },
  { value: '0845', label: '08:45' },
  { value: '0900', label: '09:00' },
  { value: '0915', label: '09:15' },
  { value: '0930', label: '09:30' },
  { value: '0945', label: '09:45' },
  { value: '1000', label: '10:00' },
  { value: '1015', label: '10:15' },
  { value: '1030', label: '10:30' },
  { value: '1045', label: '10:45' },
  { value: '1100', label: '11:00' },
  { value: '1115', label: '11:15' },
  { value: '1130', label: '11:30' },
  { value: '1145', label: '11:45' },
  { value: '1200', label: '12:00' },
  { value: '1215', label: '12:15' },
  { value: '1230', label: '12:30' },
  { value: '1245', label: '12:45' },
  { value: '1300', label: '13:00' },
  { value: '1315', label: '13:15' },
  { value: '1330', label: '13:30' },
  { value: '1345', label: '13:45' },
  { value: '1400', label: '14:00' },
  { value: '1415', label: '14:15' },
  { value: '1430', label: '14:30' },
  { value: '1445', label: '14:45' },
  { value: '1500', label: '15:00' },
  { value: '1515', label: '15:15' },
  { value: '1530', label: '15:30' },
  { value: '1545', label: '15:45' },
  { value: '1600', label: '16:00' },
  { value: '1615', label: '16:15' },
  { value: '1630', label: '16:30' },
  { value: '1645', label: '16:45' },
  { value: '1700', label: '17:00' },
  { value: '1715', label: '17:15' },
  { value: '1730', label: '17:30' },
  { value: '1745', label: '17:45' },
  { value: '1800', label: '18:00' },
  { value: '1815', label: '18:15' },
  { value: '1830', label: '18:30' },
  { value: '1845', label: '18:45' },
  { value: '1900', label: '19:00' },
  { value: '1915', label: '19:15' },
  { value: '1930', label: '19:30' },
  { value: '1945', label: '19:45' },
  { value: '2000', label: '20:00' },
  { value: '2015', label: '20:15' },
  { value: '2030', label: '20:30' },
  { value: '2045', label: '20:45' },
  { value: '2100', label: '21:00' },
  { value: '2115', label: '21:15' },
  { value: '2130', label: '21:30' },
  { value: '2145', label: '21:45' },
  { value: '2200', label: '22:00' },
  { value: '2215', label: '22:15' },
  { value: '2230', label: '22:30' },
  { value: '2245', label: '22:45' },
  { value: '2300', label: '23:00' },
  { value: '2315', label: '23:15' },
  { value: '2330', label: '23:30' },
  { value: '2345', label: '23:45' },
  { value: '2400', label: '24:00' }
]

export const redirectDurationOptions = [
  { value: 15, label: '15 Seconds' },
  { value: 20, label: '20 Seconds' },
  { value: 25, label: '25 Seconds' },
  { value: 30, label: '30 Seconds' },
  { value: 35, label: '35 Seconds' },
  { value: 40, label: '40 Seconds' },
  { value: 45, label: '45 Seconds' },
  { value: 50, label: '50 Seconds' },
  { value: 55, label: '55 Seconds' },
  { value: 60, label: '60 Seconds' },
  { value: 90, label: '90 Seconds' },
  { value: 120, label: '120 Seconds' },
  { value: 300, label: '5 minutes' },
  { value: 600, label: '10 minutes' }
]

export const pocketGreetingOptions = [
  { value: 1, label: 'Good Morning/Afternoon/Evening. Thank you for calling [Company], You’re through to [Agent name], How may I help?' },
  { value: 2, label: 'Good Morning/Afternoon/Evening. Thank you for calling [Company], can I start by taking your name please?' },
  { value: 3, label: 'Good Morning/Afternoon/Evening, [Company], [Agent Name] speaking...' },
  { value: 4, label: 'Hi, thank you for calling, how can I help you today?' }
]

export const pocketRedirectOptions = [
  { value: 20, label: '20 Seconds' },
  { value: 30, label: '30 Seconds' },
  { value: 40, label: '40 Seconds' }
]

export const voicemailDelayOptions = [
  { value: 0, label: 'from start of call' },
  { value: 30, label: 'after 30 seconds' },
  { value: 60, label: 'after 60 Seconds' },
  { value: 90, label: 'after 90 seconds' },
  { value: 120, label: 'after 2 minutes' },
  { value: 180, label: 'after 3 minutes' },
  { value: 240, label: 'after 4 minutes' },
  { value: 300, label: 'after 5 minutes' },
  { value: 600, label: 'after 10 minutes' }
]

export const deliveryMethodOptions = [
  { value: 'S', label: 'In the order they have been entered (sequentially)' },
  { value: 'P', label: 'In equal amounts across all your redirects (load balanced)' }
]

export const numberPresentationOptions = [
  { value: 'CLI', label: 'Your callers number' },
  { value: 'DDI', label: 'Your SwitchboardFREE number' }
]

export const OPEN_ALL_HOURS_ALL_DAYS = '1'
export const OPEN_ALL_HOURS_WEEKDAYS = '2'
export const OPEN_9_UNTIL_5_WEEKDAYS = '3'
export const BESPOKE_OPEN_CLOSE_TIMES = '4'

export const openCloseTimeConstants = {
  MON_OPEN: 'MON_OPEN',
  MON_CLOSED: 'MON_CLOSED',
  TUE_OPEN: 'TUE_OPEN',
  TUE_CLOSED: 'TUE_CLOSED',
  WED_OPEN: 'WED_OPEN',
  WED_CLOSED: 'WED_CLOSED',
  THU_OPEN: 'THU_OPEN',
  THU_CLOSED: 'THU_CLOSED',
  FRI_OPEN: 'FRI_OPEN',
  FRI_CLOSED: 'FRI_CLOSED',
  SAT_OPEN: 'SAT_OPEN',
  SAT_CLOSED: 'SAT_CLOSED',
  SUN_OPEN: 'SUN_OPEN',
  SUN_CLOSED: 'SUN_CLOSED'
}

export const scrollSectionNames = {
  MAIN_NUMBER_DESCRIPTION: 'mainNumberDescription',
  SYSTEM_VOICE: 'systemVoice',
  MAIN_INTRODUCTION: 'mainIntroduction',
  GLOBAL_MISSED_CALL_EMAIL: 'globalMissedCallEmail',
  CALL_FILTERING: 'callFiltering',
  DEPARTMENT_MENU: 'departmentMenu',
  INTRODUCTION: 'introduction',
  REDIRECTS: 'redirects',
  OPEN_CLOSE_TIMES: 'openCloseTimes',
  CALL_SCREENING: 'callScreening',
  MUSIC_ON_HOLD: 'musicOnHold',
  MISSED_CALL_EMAIL_ALERTS: 'missedCallEmailAlerts',
  VOICEMAIL: 'voicemail',
  CLOUD_STORAGE: 'cloudStorage',
  OUT_OF_HOURS: 'outOfHours',
  DELIVERY_METHOD: 'deliveryMethod',
  NUMBER_PRESENTATION: 'numberPresentation',
  CUSTOM_RECORDINGS: 'customRecordings',
  NUMBER_DESCRIPTION: 'numberDescription',
  ANNOUNCE_POSITION: 'announcePosition',
  GOOGLE_ANALYTICS: 'googleAnalytics',
  QUICK_CLOSE: 'quickClose',
  CALLBACK_SMS: 'callbackSms',
  INDIVIDUAL_DEPARTMENT: 'individualDepartment',
  CALL_RECORDING: 'callRecording',
  CALL_ANSWERING: 'callAnswering',
  DYNAMIC_CLI: 'dynamicCli'
}

export const fieldNames = {
  REDIRECT_1: 'redirect1',
  REDIRECT_2: 'redirect2',
  REDIRECT_3: 'redirect3',
  REDIRECT_4: 'redirect4',
  REDIRECT_5: 'redirect5',
  REDIRECTS_GROUP: 'redirectsGroup',
  MISSED_CALL_EMAIL: 'missedCallEmail',
  GLOBAL_MISSED_CALL_EMAIL: 'globalMissedCallEmail',
  VOICEMAIL_EMAIL: 'voicemailEmail',
  MON_OPEN: 'monOpen',
  MON_CLOSE: 'monClose',
  TUE_OPEN: 'tueOpen',
  TUE_CLOSE: 'tueClose',
  WED_OPEN: 'wedOpen',
  WED_CLOSE: 'wedClose',
  THU_OPEN: 'thuOpen',
  THU_CLOSE: 'thuClose',
  FRI_OPEN: 'friOpen',
  FRI_CLOSE: 'friClose',
  SAT_OPEN: 'satOpen',
  SAT_CLOSE: 'satClose',
  SUN_OPEN: 'sunOpen',
  SUN_CLOSE: 'sunClose',
  CALL_RECORD_EMAIL: 'callRecordEmail'
}

export const configValidationErrors = {
  VALIDATION_REDIRECT_REGEX: 'Please enter a valid telephone number',
  VALIDATION_REDIRECT_FULL_RECEIVED: 'Please enter a valid telephone number',
  VALIDATE_AT_LEAST_ONE_REDIRECT: 'You need to provide at least one redirect for us to divert your calls to',
  VALIDATE_EMAIL_SYNTAX: 'Please check you have entered a valid email and try again',
  VALIDATE_CLOSE_TIME: 'Close time must be later than open time',
  VALIDATE_REDIRECT_ENDPOINT: 'Please choose a valid user',
  VALIDATE_REDIRECT_GROUP: 'Please choose a valid group'
}

export const loadingMessages = {
  REQUEST_CONFIG: 'Loading Configuration Settings',
  MY_NUMBERS_FETCH_REQUEST: 'Loading Your Numbers'
}

export const prMode = {
  OFF: 0,
  LITE: 1,
  HOL: 2,
  FULL: 3
}
