import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

class Softphone extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
			<ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
			<header style={{ backgroundImage: "url('/images/backgrounds/8-competitor-bg.jpg')" }} className="full-width full-hero-small with-text" >
				<h1 id="VoipSoftphoneH1">SwitchboardFREE VoIP Softphone</h1>
				<h2 className=" subtitle">Make &amp; receive calls from your computer using your SwitchboardFREE number.</h2>
			</header>

			<div className="full-width bg-light pb-50 pt-50" id="softphone">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-sm-12 pt-0 pt-xs-0 pull-xs-none pt-30">
							<img src="/images/product-shots/softphone_desktop1.png" alt="SwitchboardFREE VoIP Softphone" style={{ maxWidth: '100%' }} />
						</div>
						<div className="col-md-6 col-sm-12 pt-30 float-none float-sm-end">
							<h2 className="text-500">SwitchboardFREE Softphone
								{/* <span className="badge badge-lg cta-gradient">FREE!</span> */}
							</h2>
							<p className="lead text-400">
								Make &amp; receive calls from your computer using your SwitchboardFREE number with the SwitchboardFREE Softphone
							</p>
							<p className="mb-30">SwitchboardFREE VoIP Softphone enables you to manage your SwitchboardFREE communications from your desktop or laptop.</p>
							<ul className="styled-list cta rounded text-300 pr-30 mb-30 ml-0 pl-0 pt-30">
								<li className="pb-xs-10 pt-xs-10 brad-5">
									Make HD voice calls over IP for crisp and clear communication.
								</li>
								<li className="pb-xs-10 pt-xs-10 brad-5">
									See user presence in real-time to manage availability and set your status to manage your calls.
								</li>
								<li className="pb-xs-10 pt-xs-10 brad-5">
									Comprehensive call history panel with access to call details
								</li>
								<li className="pb-xs-10 pt-xs-10 brad-5">
									Integrate with your SwitchboardFREE Contact Book.
								</li>
							</ul>
							<p className="pb-30">
								<a href="https://www.switchboardfree.co.uk/files/switchboardFREE.zip" className="btn btn-lg btn-cta btn-xxs-block">Download now</a>
								<Link to={'/setup/softphone'} className="btn btn-lg btn-outline ml-10 mr-xxs-0 mb-xxs-10 btn-xxs-block">Setup Instructions</Link>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pt-60">
				<div className="container">
					<div className="row">
						<div className="col text-center pt-30 pb-30">
							<h3 className="text-500">Related Products</h3>
							<p>All our phones come pre-configured. All you need to do is connect few cables and you&apos;re ready to <br className="d-none d-md-block" />make and receive calls using your SwitchboardFREE numbers.</p>
						</div>
					</div>
					<div className="row row-flex flex-3-col flex-sm-2-col flex-xs-grow">
						<div className="col-xs-4 col-xxs-12 text-center mb-20">
							<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
								<img src="/images/product-shots/ecom/W73P.jpg" alt="Cordless VoIP Phone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
								<h4 className="text-500 mb-5">Cordless VoIP Handset</h4>
								<p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
								<Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(w73p)}>Order Now</Button>
								<br />
								<a href="/products/yealink-w73p-cordless-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
							</div>
						</div>
						<div className="col-xs-4 col-xxs-12 text-center mb-20">
							<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
								<img src="/images/product-shots/ecom/T31P.jpg" alt="Yealink T31P VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
								<h4 className="text-500 mb-5">VoIP Handset</h4>
								<p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
								<Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(t31p)}>Order Now</Button>
								<br />
								<a href="/products/voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
							</div>
						</div>
						<div className="col-xs-4 col-xxs-12 text-center mb-20">
							<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
								<img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
								<h4 className="text-500 mb-5">Advanced VoIP Handset</h4>
								<p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
								<Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(FanvilProdId)}>Order Now</Button>
								<br />
								<a href="/products/advanced-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
  }

  handleAddHandset (prodId) {
    const product = this.state.products.filter(f => f.prodId === prodId)
    const tempData = [{
      qty: 1,
      ...product[0]
    }]
    localStorage.setItem('HardwareFirst', JSON.stringify(tempData))
    this.props.history.push('/get-started')
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(Softphone)
