import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import alarm from '../sounds/bell.mp3'

class QueueMonitorAlarm extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {}

  render () {
    return (
            <div><ReactAudioPlayer
                src={alarm}
                controls={false}
                loop={true}
                ref={(element) => { this.rap = element }}
            /></div>
    )
  }

  componentDidMount () {
    if (this.props.shouldSoundAlarm) {
      this.startPlayingAlarm()
    }
  }

  startPlayingAlarm () {
    try {
      this.rap.audioEl.pause()
      this.rap.audioEl.currentTime = 0
      this.rap.audioEl.play()
    } catch {

    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.shouldSoundAlarm !== this.props.shouldSoundAlarm) {
      if (this.props.shouldSoundAlarm) {
        this.startPlayingAlarm()
      } else {
        this.stopPlayingAlarm()
      }
    }
  }

  stopPlayingAlarm () {
    try {
      this.rap.audioEl.pause()
    } catch {

    }
  }
}

export default QueueMonitorAlarm
