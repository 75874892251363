import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface NumberSupermarketProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const NumberSupermarket = (props: NumberSupermarketProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Tamar Telecommunications vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Tamar Telecommunications vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Tamar Telecommunications vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Who are Tamar Telecommunications?</h2>
                            <p>Tamar Telecommunications provide their customers with virtual numbers and call management services. Among the features that come along with your new virtual number are a virtual switchboard, hunt groups, voicemail, welcome announcement, call screening, missed call alerts, call stats, etc.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Tamar Telecommunications number types</h2>
                            <p>Tamar Telecommunications offer virtual geographic numbers, as well as 03- and 0800 numbers.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Tamar Telecommunications pricing</h2>

                            <p className="text-500 text-lg text-dark">Geographic numbers plans</p>
                            <p>There are two plans available if you decide to choose a local number:</p>
                            <ul>
                                <li>2500 inclusive landline and mobile minutes for £4.99 per month</li>
                                <li>Unlimited inclusive landline and mobile minutes for £9.99 per month</li>
                            </ul>

                            <p className="text-500 text-lg text-dark">Geographic numbers plans</p>
                            <ul>
                                <li>All 03-numbers come with unlimited inclusive landline and mobile numbers at £14.99 per month.</li>
                            </ul>

                            <p className="text-500 text-lg text-dark">0800 numbers plans</p>
                            <p>Tamar Telecommunications offer 3 plans for their 0800 freephone numbers:</p>
                            <ul>
                                <li>500 inclusive landline and mobile minutes at £4.99 per month</li>
                                <li>1000 inclusive landline and mobile minutes at £9.99 per month</li>
                                <li>2000 inclusive landline and mobile minutes at £19.99 per month</li>
                            </ul>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Tamar Telecommunications’ features prices</h2>
                            <p>Most of the Tamar Telecoms features come for free, but there are some for which the customer is extra charged.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Tamar Telecommunications vs. SwitchboardFREE comparative analysis</h2>
                            <p>Both Tamar Telecommunications and SwitchboardFREE give local, 03- and 0800 numbers.</p>
                            <p>SwitchboardFREE also offer completely free 0843 / 0844 numbers to the clients with unlimited minutes to landlines, and the possibility to add unlimited minutes to mobiles for just £10.96 per month.</p>
                            <p>Tamar Telecommunications’ packages for the other number types are a bit cheaper and include fairly large bundles of minutes to both landline and mobiles.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What are SwitchboardFREE’s advantages?</h2>
                            <p>On the face of it, SwitchboardFREE are more expensive. However, when purchasing a number with SwitchboardFREE you get over 40 industry leading features included for free. It has to be noted, though, that they charge additionally for basic tools like the virtual switchboard (department selection) and call screening message, on top of the costs we outlined in the pricing section meaning that Tamar Telecom can end up more expensive in the long-run.</p>
                            <p>Therefore, despite being slightly more expensive for some of the number types, SwitchboardFREE offer a higher value for money service.</p>
                            <p>You can also benefit from a mobile app that allows you to quickly change your account settings wherever you are with the app’s user-friendly layout.</p>
                            <p>SwitchboardFREE boast more comprehensive call reporting features, as well. An example of this is the possibility to integrate Google Analytics to analyse your call and web traffic and gain some valuable insight of your call stats and marketing campaigns.</p>
                            <p>Finally, we have recently added a new powerful tool to complement SwitchboardFREE – the telephone answering service, powered by Pocket Receptionist. It employs a team of dedicated, professional receptionists who take your calls for you in real-time and can transfer them to you straight away. It makes a difference to the callers when they skip the voicemail-leaving part and get hold of a real human when they call your number. You can use the Pocket Receptionist service as one of your SwitchboardFREE redirects to ensure you never miss a call again.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Tamar telecommunications current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.tamartelecommunications.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(NumberSupermarket)
