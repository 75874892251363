import React, { Component } from 'react'
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { handleNotificationChange } from '../../../store/SBF'
import LoadingNotification from '../../LoadingNotification'
import CallTranscriptionToggle from "../../../components/CallTranscription/CallTranscriptionToggle"

const regulatory_prompt_popover = (
    <Popover>
        <p>In addition to any introduction you have set on your numbers your caller will also get played the following:<br/><br/>&quot;<i>Please note, calls to this number may be recorded for training and monitoring purposes</i>&quot;.</p>
        <p>It is your responsibility to make sure any regulatory requirements specific to the industry you operate in are met.</p>
    </Popover>
)
const pausing_popover = (
    <Popover>
        <p>On-demand pausing allows you to pause and resume recording mid call by pressing #1 on your phones keypad.</p>
        <p>This helps you to be compliant when discussing personal information such as credit card numbers over the phone.</p>
    </Popover>
)
const calltranscription_popover = (
    <Popover>
    <p>When enabled, all of your recorded calls will be transcribed into text. This means all of your calls will be readable and searchable using the my calls page.</p>
    <p>Call Transcription is charged at 5p per minute.</p>
    </Popover>
)

class AccountSettings extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    callRecordingSettings: null,
    isLoading: true,
    selectedDeletionPeriod: 48,
    callTranscriptionActive: false,
    showDeletionConfirmation: false,
    notify: handleNotificationChange
  }

  componentDidMount () {
    this.getSettings()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getSettings()
    }
  }

  getSettings () {
    fetchHelper.getJson('api/CallTranscription/HasCallTranscriptionTurnedOn')
      .then(res => {
        this.setState({ callTranscriptionActive: res.data })
      }).catch(err => console.error(err))

    const url = '/api/Profile/GetAccountSettings'

    fetchHelper.getJson(url).then(res => {
      const callRecordingSettings = res.data.callRecordingSettings

      this.setState({ callRecordingSettings, isLoading: false })
    }).catch(function (error) {
      console.error(error)
    })
  }

  render () {
    return (<>
            {this.state.isLoading === false &&
            <div className="mb-100 bg-light p-30 pb-40 brad-10 b-1 w-800px center-block" style={{ overflow: 'auto' }}>
                <div className='row'>
                    <div className="col-xs-12 text-center pb-30">
                        <h4 className='text-400 pb-20'>Call Recording</h4>
                        <p className="mb-40">Account wide settings for call recording</p>
                    </div>
                </div>

                <div className='row row-flex flex-3-col flex-sm-2-col flex-sm-grow no-flex-grow'>
                    <div className="col-xs-12 col-md-4 text-center b-1 brad-10 box-shadow pb-20 mb-20 pl-sm-15" id="PlayRegulatoryPromptBox">
                        <div >
                            <h6 className="pb-10 pt-20  text-500">Play regulatory prompt
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={regulatory_prompt_popover}>
                                <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                            </OverlayTrigger>
                            </h6>
                            <Toggle
                                on={<span>ON</span>}
                                off={<span>OFF</span>}
                                size="sm"
                                offstyle="default"
                                onstyle="success"
                                width="null"
                                height="null"
                                active={!this.state.callRecordingSettings.supressCallRecordingPrompt}
                                onClick={event => handleCallRecordingSettingPromptChange(event, this)}
                                id="PlayRegulatoryPromptToggle"
                            />
                        </div>
                    </div>

                    <div className="col-xs-12 col-md-4 text-center b-1 brad-10 box-shadow pb-20 mb-20 pl-sm-15" id="OnDemandPausingBox">
                        <div >
                            <h6 className="pb-10 pt-20  text-500">On-demand recording pausing
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={pausing_popover}>
                                <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                            </OverlayTrigger></h6>
                            <Toggle
                                on={<span>ON</span>}
                                off={<span>OFF</span>}
                                size="sm"
                                offstyle="default"
                                onstyle="success"
                                width="null"
                                height="null"
                                active={this.state.callRecordingSettings.canPauseCallRecording}
                                onClick={event => handleCallRecordingSettingPauseChange(event, this)}
                                id="OnDemandPausingToggle"
                            />
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 text-center b-1 brad-10 box-shadow pb-20 mb-20 pl-sm-15">
                        <div >
                            <h6 className="pb-10 pt-20  text-500">Delete recordings older than: </h6>
                            <div className="form-inline">
                                <div className="form-group">
                                    <Button size="xs" variant="cta" className="m-3" onClick={e => this.setState({ selectedDeletionPeriod: 1, showDeletionConfirmation: true })}>1 month</Button>
                                    <Button size="xs" variant="cta" className="m-3" onClick={e => this.setState({ selectedDeletionPeriod: 3, showDeletionConfirmation: true })}>3 months</Button>
                                    <Button size="xs" variant="cta" className="m-3" onClick={e => this.setState({ selectedDeletionPeriod: 6, showDeletionConfirmation: true })}>6 months</Button>
                                    <Button size="xs" variant="cta" className="m-3" onClick={e => this.setState({ selectedDeletionPeriod: 24, showDeletionConfirmation: true })}>2 years</Button>
                                    <Button size="xs" variant="cta" className="m-3" onClick={e => this.setState({ selectedDeletionPeriod: 48, showDeletionConfirmation: true })}>4 years</Button>
                                    <Button size="xs" variant="cta" className="m-3" onClick={e => this.setState({ selectedDeletionPeriod: 72, showDeletionConfirmation: true })}>6 years</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 text-center b-1 brad-10 box-shadow pb-20 mb-20 pl-sm-15">
                        <div>
                            <h6 className="pb-10 pt-20  text-500">Recordings stored </h6>
                            <p> {this.state.callRecordingSettings.minutesStored} minutes</p>
                            <p> ({this.state.callRecordingSettings.recordingsStored} calls) </p>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 text-center b-1 brad-10 box-shadow pb-20 mb-20 pl-sm-15">
                        <div>
                            <h6 className="pb-10 pt-20  text-500">Storage charge </h6>
                            <p> £{this.state.callRecordingSettings.storageCharge.toFixed(2)} </p>
                            <p> (per minute / per month)</p>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 text-center b-1 brad-10 box-shadow pb-20 mb-20 pl-sm-15">
                        <div>
                            <h6 className="pb-10 pt-20 text-500">Current monthly charge </h6>
                            <p> £{this.state.callRecordingSettings.currentMonthlyCharge.toFixed(2)} </p>
                            <p> &nbsp; </p>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 text-center b-1 brad-10 box-shadow pb-20 mb-20 pl-sm-15">
                        <div>
                            <h6 className="pb-10 pt-20  text-500">Call Transcription
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={calltranscription_popover}>
                                <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                            </OverlayTrigger></h6>

                            <CallTranscriptionToggle />    
                        </div>

                        <span className="text-sm pt-10">Charged at 5p per minute</span>
                    </div>
                </div>

            </div>
        }

        <Modal className="modal-med question-modal" show={this.state.showDeletionConfirmation} onHide={e => this.setState({ showDeletionConfirmation: false })}>
            <Modal.Body className="text-center">
                    <div className="icon-wrapper mt-20 mb-20">
                        <span className="icon icon-question6"></span>
                    </div>
                    <h4>Are you sure?</h4>
                    <p>You're about to remove all recordings which older than {this.state.selectedDeletionPeriod} months.</p>
                    <p>Once removed call recordings are unrecoverable</p>
            </Modal.Body>
            <Modal.Footer className="text-center bt-0 p-0 mt-30">
                <Button variant="default" className="btn-block brad-0 pt-20 pb-20" onClick={e => this.setState({ showDeletionConfirmation: false })}>Cancel</Button>
                <Button variant="cta" className="btn-block brad-0 pt-20 pb-20" onClick={e => handleDoDeletion(this)}>Yes, delete</Button>
            </Modal.Footer>
        </Modal>

        {this.state.isLoading === true &&
            <LoadingNotification className="mt-30" message={'Loading...'} isLoading={this.state.isLoading} />
        }
        </>)

    function handleDeletionPeriodChange (period, context) {
      context.setState({ selectedDeletionPeriod: period })
    }

    function handleCallRecordingSettingPromptChange (toggleVal, context) {
      const callRecordingSettingsTmp = context.state.callRecordingSettings

      callRecordingSettingsTmp.supressCallRecordingPrompt = !toggleVal

      context.setState({ callRecordingSettings: callRecordingSettingsTmp })

      const url = '/api/Profile/setCallRecordingSupressIntroPrompt'

      fetchHelper.postJson(url, { val: !toggleVal })
        .then(data => {
          return console.log(data)
        })
        .catch(error => {
          return console.error(error)
        })
    }

    function handleDoDeletion (context) {
      const url = '/api/Profile/deleteCallRecordings'

      fetchHelper.postJson(url, { period: context.state.selectedDeletionPeriod })
        .then(data => {
          context.props.notify({
            message: 'Call recordings have been removed',
            isErrorState: false
          })
          context.setState({ showDeletionConfirmation: false })
          return console.log(data)
        })
        .catch(error => {
          context.props.notify({
            message: 'Sorry, something went wrong, please try again or contact support.',
            isErrorState: true
          })
          return console.error(error)
        })
    }

    function handleCallRecordingSettingPauseChange (toggleVal, context) {
      const callRecordingSettingsTmp = context.state.callRecordingSettings

      callRecordingSettingsTmp.canPauseCallRecording = toggleVal

      context.setState({ callRecordingSettings: callRecordingSettingsTmp })

      const url = '/api/Profile/setCallRecordingCanPause'

      fetchHelper.postJson(url, { val: toggleVal })
        .then(data => {
          return console.log(data)
        })
        .catch(error => {
          return console.error(error)
        })
    }
  }
}

export default connect(state => {
}, { notify: handleNotificationChange })(AccountSettings)
