import React from 'react'
import { Helmet } from 'react-helmet'
import CallTranscriptionToggle from "../../../components/CallTranscription/CallTranscriptionToggle"
import { Link } from "react-router-dom";

export default class ConversationSearchFunctionPage extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>

            <Helmet>
                <title>Call Search Pro</title>
                <meta name="description" content="Search your calls for specific words and phrases." />
                <style type="text/css">{`
                    .no-bg{
                            background-image:none !important;
                    }
                    .well, .well.well-transparent p,.well.well-transparent h5{
                        color:#3b3b3b !important;
                    }

                    `}</style>
            </Helmet>

            <div className={`full-width bg-light`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <div className="row mt-50 mb-60">
                                <div className="col-md-6 col-sm-12 order-last">
                                    <img src="/images/conversation-search.jpg" alt="Coversation Search with transcription" className="img-responsive brad-20 " style={{ maxWidth: "600px" }} />
                                </div>
                                <div className="col-md-6 col-sm-12 order-first">
                                    <h2 className="text-500 text-darker">
                                        Call Search Pro
                                    </h2>
                                    <div className="alert alert-success mt-20 mb-30">
                                        <span class="icon icon-checkmark4 ml-5 text-success text-sm mr-5"></span> Congratulations, you have Call Search Pro enabled on your account.
                                    </div>

                                    <p>
                                        Remember, it's not just about keeping records; it's about retrieving them efficiently. SwitchboardFREE - turning the tide of communication in your favor!
                                    </p>

                                    <p>
                                        Having trouble pinpointing that crucial conversation among your sea of recorded calls? You can swiftly locate any call by searching for specific words or phrases discussed during the call. It's like having your very own communication compass guiding you through the expansive call ocean. Now, every important discussion is just a keyword away
                                    </p>

                                    <Link variant="cta" className="btn btn-cta btn-lg mt-30 mb-0" to={'/customer/calls'}>Go To My Calls</Link>
                                    <Link variant="cta" className="btn btn-outline btn-lg mt-30 mb-0 ml-20 ml-xs-0" to={'/customer/keyword-triggers/'}>Keyword &amp; Alert Settings</Link>

                                </div>
                            </div>

                            <div className="row mt-50 mb-60 justify-content-center">
                                <div className="col-md-8 col-sm-12 order-first">
                                    <div className="bg-dull brad-10 text-center p-50">
                                        <CallTranscriptionToggle />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
  }
}
