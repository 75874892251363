import React from 'react'
import { connect } from 'react-redux'
import LoadingNotification from '../components/LoadingNotification'
import { fetchHelper } from '../helpers/fetchHelper'

class TermsAndConditions extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    loading: true
  }

  render () {
    return <>
			{this.state.loading
			  ? <LoadingNotification loadingText={'Please wait, preparing your invoice'} isLoading={this.state.loading}
					width={50} height={50} />
			  : <>
					<style type="text/css">{`
						#invoicebody { width: 100% !important; }
						table { width: 100% !important; }
						table table { widht: 90% !important; }
						.imgpop img { width: 75% !important; height: auto; }
					}
					`}</style>

					<div dangerouslySetInnerHTML={{ __html: this.state.termsHtml }} />
				</>
			}

        </>
  }

  componentDidMount () {
    const url = '/api/Home/Terms/'

    fetchHelper.getJson(url).then(res => {
      this.setState({ loading: false, termsHtml: res.data })
    }).catch(error => {
      console.error(error)
    })
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(TermsAndConditions)
