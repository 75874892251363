import React from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { siteHeader } from '../constants/Constants'
import { isLoggedIn } from '../selectors'

const HeaderSimpleV2 = props => props.ShowHeaderSimpleV2
  ? <div className="header">

            <Navbar
                // fluid inverse collapseOnSelect
                className="fixed-top navbar-simple-v2">
                    <Container fluid>

                    <Navbar.Brand>
                        <a href={`${(props.isLoggedIn === true) ? '/customer/dashboard' : '/'}`}><img src={'/images/logo/sflogo.svg'} width="500" /></a>
                        <p>Good For Business</p>
                    </Navbar.Brand>
                    <div className="float-end">
                        <span className="text-500">Need help?</span>
                        <span className="text-400 pr-15 pl-10">Chat with us:</span>
                        <a className="text-secondary text-500" href="tel:0203 189 1213">0203 189 1213</a>
                        <span className="pl-15 pr-15">|</span>
                        <a className="text-secondary text-500 pr-10 open-live-chat">Live Chat</a>
                        <a className="open-live-chat">
                            <img src="/images/team/katie.jpg" className='rounded-circle' alt="Need help?" />
                        </a>
                    </div>
                    </Container>
            </Navbar>

    </div>
  : null

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {},
  dispatch
)

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state),
      ShowHeaderSimpleV2: state.sbfApp.visibility.HEADER === siteHeader.SIMPLEV2
    })
  },
  {
    mapDispatchToProps
  }
)(HeaderSimpleV2)
