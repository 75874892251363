import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import FeaturesList from '../../components/Competitors/FeaturesList'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface TelavoxFlowProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const TelavoxFlow = (props: TelavoxFlowProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Telavox Flow vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Telavox Flow vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Telavox Flow vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Who are Telavox Flow?</h2>
                            <p>Telavox Flow is a Sweden-based company offering PBX services and local phone numbers to their clients in the UK and Europe.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What features do Telavox Flow have?</h2>
                            <p>Getting a free local business number from Telavox Flow enables you to forward calls made to it to unlimited mobile extensions.</p>
                            <p>You can download the Telavox Flow smartphone app to choose a business number, record a customized welcome message and add your colleagues. The app also allows you to set opening hours for your business, share short messages with your associates in the Flow chat, and hold teleconferencing sessions with as many people as you like.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Telavox Pricing</h2>
                            <p>There are only two packages to choose from.</p>
                            <p>The Flow Free has no setup or monthly fee and supplies you with the features outlined in the above section.</p>
                            <p>The Flow Pro costs £10 per month and adds a couple more features to your profile. You can benefit from a website widget which shows the customers how long their queue time is likely to be. Additionally, you get a shared voicemail for your team, and 200 outgoing minutes per month.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Why choose SwitchboardFREE over Telavox?</h2>
                            <p>With SwitchboardFREE, our clients can redirect their calls to landlines, too, and not just to mobile phones.</p>
                            <p>We also offer a lot more number types to pick from. Aside from local numbers, we offer 03, 084, 0800 and 0808 numbers. The packages start from only £5 per month.</p>
                            <p>Businesses could select an 0800 number, calls to which are completely free from both landline and mobile phones.</p>
                            <p>And the latest addition to our assortment is the future-proof 03 number type. These have gained popularity because calls made from mobile phones to 03-numbers are priced at standard call rates.</p>

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <p>Among the other features our users can utilise are iOS and Android apps allowing them to alter their account settings any time they want; customized prompts; call screening to identify the nature of the call prior to taking it; Callback SMS and email alerts; call tracking to monitor advertisement ROI; voicemail services, and the opportunity to create up to 9 virtual departments to route the callers to the most appropriate person for their enquiry.</p>

                            <p>Other features include:</p>

                            <FeaturesList />

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Telavox Flows current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://telavox.com/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(TelavoxFlow)
