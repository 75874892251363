import moment from 'moment'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { fetchHelper } from '../../helpers/fetchHelper'

class StatsBlocks extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    voicemailCount: 0,
    answeredCalls: 0,
    missedCalls: 0,
    lastAccess: undefined,
    mobileCredits: 0,
    timerId: 0,
    totalUsers: 0
  }

  handleClickStats (report, history) {
    const data = {
      from: moment().startOf('day'),
      to: moment().endOf('day'),
      report
    }

    localStorage.setItem('statsData', JSON.stringify(data))
    history.push('/customer/my-calls/call-stats')
  }

  render () {
    return (
			<>
				<div className="row text-center">
					<div className="col-md-1-5 col-xs-12">
						<div className="bg-light p-30 p-sm-10 pt-sm-30 pb-sm-30 box-shadow shadow-lighter brad-20 cursor-pointer" onClick={() => this.handleClickStats(1, this.props.history)}>
							<div className="circle circle-primary">
								<span>
									<p className="text-sm title pt-10 text-500 mb-0">Total Calls</p>
									<p className="text-500 text-lg text-cta mb-0">
										{this.state.voicemailCount + this.state.missedCalls + this.state.answeredCalls}
									</p>
								</span>
							</div>
						</div>
					</div>
					<div className="col-md-1-5 col-xs-12">
						<div className="bg-light p-30 p-sm-10 pt-sm-30 pb-sm-30 box-shadow shadow-lighter brad-20 cursor-pointer" onClick={() => this.handleClickStats(4, this.props.history)}>
							<div className="circle circle-primary">
								<span>
									<p className="text-sm title pt-10 text-500 mb-0">Answered</p>
									<p className="text-500 text-lg text-cta mb-0">
										{this.state.answeredCalls}
									</p>
								</span>
							</div>
						</div>
					</div>
					<div className="col-md-1-5 col-xs-12">
						<div className="bg-light p-30 p-sm-10 pt-sm-30 pb-sm-30 box-shadow shadow-lighter brad-20 cursor-pointer" onClick={() => this.handleClickStats(0, this.props.history)}>
							<div className="circle circle-primary">
								<span>
									<p className="text-sm title pt-10 text-500 mb-0">Missed</p>
									<p className="text-500 text-lg text-cta mb-0">
										{this.state.missedCalls}
									</p>
								</span>
							</div>
						</div>
					</div>
					<div className="col-md-1-5 col-xs-12">
						<div className="bg-light p-30 p-sm-10 pt-sm-30 pb-sm-30 box-shadow shadow-lighter brad-20 cursor-pointer" onClick={() => this.handleClickStats(0, this.props.history)}>
							<div className="circle circle-primary">
								<span>
									<p className="text-sm title pt-10 text-500 mb-0">Voicemails</p>
									<p className="text-500 text-lg text-cta mb-0">
										{this.state.voicemailCount}
									</p>
								</span>
							</div>
						</div>
					</div>

					<div className="col-md-1-5 col-xs-12">
						<Link to="/customer/outbound-management/">
							<div className="bg-light p-30 p-sm-10 pt-sm-30 pb-sm-30 box-shadow shadow-lighter brad-20">
								<div className="circle circle-primary">
									<span>
										<p className="text-sm title pt-10 text-500 mb-0">Users</p>
										<p className="text-500 text-lg text-cta mb-0">
											{this.state.totalUsers}
										</p>
									</span>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</>
    )
  }

  componentDidMount () {
    const url = 'api/Numbers/GetStatsOverviewHeader'
    const context = this

    fetchHelper.getJson(url).then(response => {
      const data = response.data
      context.setState({
        ...data
      })
    })
      .catch(error => {
        console.error(error)
      })
  }
}

export default StatsBlocks
