import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,
  draggable: true,
  arrows: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
  ]
}

class IntegrationsSlider extends Component {
  state = {
  }

  renderFeatureSnippet (text, history, url, name) {
    return (
			<>
				<span dangerouslySetInnerHTML={{ __html: `${text}...` }} />
				<span className="underline text-400 text-primary pl-5 cursor-pointer" onClick={() => history.push(url)} id={`${name.replace(/\s/g, '')}SliderReadMoreButton`}>Read more</span>
			</>
    )
  }

  renderSlides (features, history) {
    return (
			<Slider className={`features features-slider ${this.props.hideIcons === true ? 'hide-icons' : ''} `} {...settings}>
				{features.map((item, i) => {
				  return (
						<div className={`${this.props.boxOutsideCustomClass ? this.props.boxOutsideCustomClass : 'text-start m-10'}`}>
							<div className={`${this.props.boxInsideCustomClass ? this.props.boxInsideCustomClass : 'p-20 text-start bg-light brad-10 overflow-auto box-shadow-v3'}`}>
								<div className="w-20 text-center float-start icon pr-20 pt-10">
									<div className="brad-10 bg-dull text-center p-10">
										<img src={item.img} height="35" alt="" />
									</div>
								</div>
								<div className="w-80 float-start">
									<h4 className="text-500">{item.name}</h4>
									<p>
										{this.renderFeatureSnippet(item.desc.slice(0, 80), history, item.url, item.name)}
									</p>
								</div>
							</div>
						</div>
				  )
				})}
			</Slider>
    )
  }

  render () {
    const featureList = [
      {
        img: 'images/webhooks/capsule.png',
        name: 'Capsule CRM',
        desc: 'Our Capsule CRM integration automatically updates your Capsule contacts on your Contact book, App, softphone and desk phones whilst simultaneously logging all of your call activity in the Capsule CRM.',
        url: '/integrations/capsule-crm'
      },
      {
        img: 'images/logo/pipedrive-crm.png',
        name: 'Pipedrive CRM',
        desc: 'Integrate with Pipedrive to synchronize contact from Pipedrive to SwitchboardFREE and upload call information to your Pipedrive contacts.',
        url: '/integrations/pipedrive-crm'
      },
      {
        img: 'images/webhooks/google-sheets.png',
        name: 'Google Sheets',
        desc: 'Allow your SwitchboardFREE account to log each call as a row in your Google sheets account.',
        url: '/web-hook-integrations/google-sheets'
      },
      {
        img: 'images/webhooks/hubspot.png',
        name: 'HubSpot',
        desc: 'Allow your SwitchboardFREE account to log each call as a row in your HubSpot account.',
        url: '/integrations/hubspot'
      },
      {
        img: 'images/webhooks/todo.png',
        name: 'Microsoft To-Do',
        desc: 'Allow your SwitchboardFREE account to log each call as a row in your Microsoft To-Do account.',
        url: '/web-hook-integrations/microsoft-to-do'
      },
      {
        img: 'images/webhooks/salesforce.png',
        name: 'Salesforce',
        desc: 'Allow your SwitchboardFREE account to log each call as a row in your Salesforce account.',
        url: '/web-hook-integrations/salesforce'
      },
      {
        img: 'images/webhooks/slack.png',
        name: 'Slack',
        desc: 'Allow your SwitchboardFREE account to log each call as a row in your Slack account.',
        url: '/web-hook-integrations/slack'
      },
      {
        img: 'images/webhooks/trello.png',
        name: 'Trello',
        desc: 'Allow your SwitchboardFREE account to log each call as a row in your Trello account.',
        url: '/web-hook-integrations/trello'
      },
      {
        img: 'images/webhooks/zapier-sq.png',
        name: 'Zapier',
        desc: 'Zapier allows you to utilise our webhooks facility to automate your workflow across 4,000+ apps.',
        url: '/webhooks'
      },
      {
        img: 'images/webhooks/zoho.png',
        name: 'Zoho Crm',
        desc: 'Allow your SwitchboardFREE account to log each call as a row in your Zoho Crm account.',
        url: '/web-hook-integrations/zoho'
      }
    ]

    return (
			<>
				{this.renderSlides(featureList, this.props.history)}
			</>
    )
  }
}

export default withRouter(IntegrationsSlider)
