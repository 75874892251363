import React from 'react'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import SiteMapComponent from '../components/Sitemap'

export default class SiteMap extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
			<ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
			<div className="full-width bg-light pb-60 pt-50">
				<div className="container">
					<h1 className="text-500 text-darker text-center">Site Map</h1>
					<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light text-center bb-1 pb-30">
						Can't find the page you&apos;re looking for?
					</h2>

					<SiteMapComponent />
				</div>
			</div>
		</>
  }
}
