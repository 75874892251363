import React, { Component } from 'react'
import connect from 'react-redux/es/connect/connect'
import LoadingNotification from '../components/LoadingNotification'
import { fetchHelper } from '../helpers/fetchHelper'

class InvoiceViewer extends Component {
  state = {
    loading: true
  }

  render () {
    return (
            <>
            <style>
            {`
                header, .header, footer { display: none; }
                p { color: #222; }
            `}
        </style>
        <div className="text-center p-50">
                    <LoadingNotification loadingText={'Please wait, preparing your invoice'} isLoading={this.state.loading}
                        width={100} height={100} />
                </div>
            </>
    )
  }

  componentDidMount () {
    const url = `/api/purchase/invoice/${this.props.match.params.invId}/`

    if (this.props.match.params.format !== 'pdf') {
      fetchHelper.getJson(url).then(response => {
        this.setState({ loading: false })
        const newDoc = document.open('text/html', 'replace')
        newDoc.write(response.data)
        newDoc.close()
      }).catch(error => {
        console.error(error)
      })
    }
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(InvoiceViewer)
