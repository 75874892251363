import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../helpers/fetchHelper'
import { handleShowCallbackForm } from '../../store/SBF'

import { Link } from 'react-router-dom'
import TextBannerWidget from '../../components/Reviews/TextBannerWidget'

class InternationalCalls extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    showRatesModal: false
  }


  render() {
    const options = {
      sizePerPage: 15,
      paginationSize: 3,
      prePage: 'Prev',
      nextPage: 'Next',
      hideSizePerPage: true,
      alwaysShowAllBtns: true,
      withFirstAndLast: false
    };

    return <>
      <Helmet>
        <title>Expand Beyond the UK With International Calling</title>
        <meta name="description" content="" />

        <style type="text/css">{`
          .table { margin-bottom: 0; }
          .react-bs-table-pagination .col-md-6 {
            width: 100% !important;
            text-align: center;
          }
          .react-bs-table-pagination .pagination {
            display: inline-flex;
            margin-bottom: 20px
          }
          table.table tr {
            border-color: #ddd;
          }
          .table.table tr th {
            background: #eee;
            border: none
          }
          .table.table tr td, .table.table tr th {
            width: 20%;
            padding: 8px !important;
            display: table-cell !important;
          }
          .table.table tr td:last-child, .table.table tr th:last-child {
            width: 60%;
          }

          .side-box {
            background-repeat:no-repeat;
            background-color:#ff9314;
          }
          .header .navbar.navbar-simple {
            min-height: 75px;
          }
          @media screen and (max-width:992px) {
            .form-control.h-60px {
              height:45px;
            }
          }
          @media screen and (max-height:800px) {
            .form-control.h-60px {
              height:45px;
            }
          }
          @media screen and (max-width:767px) {
            .side-box p.text-lg {
              font-size:21px !important;
            }
            .col-xs-6 .icon-block {
              width: 130px;
              height: 130px;
              padding: 8px 0;
            }
            table.table tr td:first-child {
              margin-top: 0;
            }
          }

                    `}</style>
      </Helmet>
      <ScrollToTopOnMount />
      <DisplayMainFooter />
      <DisplayMainHeader />

      <div className="full-width bg-light pb-60 pt-0">
        <div className="container numbers-pages">
          <div className="row mt-100 mt-xs-30 p-xs-20">
            <div className="col-md-6 col-sm-12 order-last text-center p-xs-30">
              <img src="/images/homepage-features-2.jpg" alt="" className="img-responsive mt-20 mt-xs-0" style={{ maxWidth: '600px' }} />
              <img src="/images/icons/trust-icons.png" alt="" className="img-responsive mt-20" style={{ maxWidth: '500px' }} />
            </div>
            <div className="col-md-6 col-sm-12 order-first">
              <h1 className="text-500 text-darker text-capatalize">
                Expand Beyond the UK With International Calling
              </h1>
              <h2 className="text-lg text-400 mb-20 mt-20 text-dark-light w-600px">
                Opening your business up globally has never been so easy.
              </h2>

              <p>Growing your business beyond the shores of the UK can open up new and exciting opportunities. And with SwitchboardFREEs international call feature, it has never been simpler to start experiencing the countless benefits that await you.</p>

              <ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  You'll have access to a broader customer base.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  You'll discover new innovative approaches & fresh perspectives.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  You'll reduce your reliance on a single market.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  You'll gain knowledge & experience in the complexities of cultural differences, regulatory environments & logistics.
                </li>
              </ul>

              <Link to="/get-started" className="btn btn-cta p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20">Set up International Calling</Link>
              <Button variant="outline" className="btn btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.props.handleShowCallbackForm()}>Request a Callback</Button>

            </div>
          </div>
        </div>
      </div>

      <div className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40 p-xs-40" style={{ backgroundColor: '#d6e8ef' }}>
        <div className="container numbers-pages">
          <div className="row">
            <div className="col-sm-7 order-last order-md-first pr-100 pr-xs-0 px-sm-0">
              <h5 className="text-500">Breaking barriers for small businesses.</h5>
              <p>
                For the most part, global growth has been out of reach to smaller businesses due to the cost of communication outside of the UK; with calls to such countries as UAE and China costing as much as &pound;2.50 per minute on a business mobile plan such as Vodafone's.
              </p>
              <p>
                However, by switching your telephony to a VoIP system such as the one we provide at SwitchboardFREE, even the smallest of companies can afford to spread their wings.
              </p>
              <p>
                And with <strong>over 150 countries</strong> to choose from, the only question is, which one will you start with?
              </p>
              <p>
                To help you decide what's within your budget, we are transparent with our costs, unlike many of our competitors. Below are our customer's 5 most popular call destinations, 4 of which are considerably less than 2p per minute.
              </p>

              <Link to="/get-started" className="btn btn-cta p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20">Get Started Now</Link>
              <Button variant="outline" className="btn btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.props.handleShowCallbackForm()}>Get a Callback</Button>
            </div>
            <div className="col-sm-5 order-first order-md-last mb-xs-50">
              <img src="/images/example-outbound-rates.png" alt="Example Outbound Dialling Rates" className="img-responsive" />
             

            </div>
          </div>
        </div>
      </div>

      <div className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40 p-xs-40">
        <div className="container numbers-pages">
          <div className="row">
            <div className="col-sm-7 order-first order-md-last pl-100 pl-xs-0 pl-sm-0">
              <h5 className="text-500">Much more than just saving money.</h5>
              <p>
                For the most part, global growth has been out of reach to smaller businesses due to the cost of communication outside of the UK; with calls to such countries as UAE and China costing as much as &pound;2.50 per minute on a business mobile plan such as Vodafone's.
              </p>
              <p>
                You'll find that it's not just the monetary benefits that you will get with our business VoIP. SwitchboardFREE offers a whole call management system, which aims to provide efficient communication, promote collaboration across borders and streamline your operations.  
              </p>
              <p>
                This can all be achieved with,
              </p>
              <ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  Call forwarding.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  Voicemail to email transcription.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  Call recording.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  Audio branding.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  Multiple virtual phone numbers.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  Easy integration with applications and CRMs.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  Flexibility & scalability with additional lines with a few clicks.
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  Resilience & continuity with disaster recovery. 
                </li>
                <li className="text-md mb-10">
                  <span className="icon icon-checkmark-circle text-success mr-10" />
                  International calling.
                </li>
              </ul>

              <p>
                And you'll also find that some of <Link to="/features" className="text-primary">these features and more</Link> are absolutely FREE! 
              </p>

              <p>
                So to switch your current phone system to SwitchboardFREE and instantly save money, click the left button below. And if you want to upgrade your current call management plan with us, hit the right button below!
              </p>

              <Link to="/get-started" className="btn btn-cta p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20">Switch &amp; Save Today </Link>
            </div>
            <div className="col-sm-5 order-last order-md-first">
              <div className="brad-10 text-lightest p-30 mt-10 side-box">
                <div className="row">
                  <div className="col-md-12">
                    <p className="text-lg text-italic text-400 pt-10 pt-xs-10">&quot;These guys really know how to look after customers. The VoIP service they have provided me with for both my businesses is flawless and the support is great. Highly recommended company.&quot;</p>
                    <p className="pt-10">
                      <span className="icon icon-star-full text-lightest mr-3"></span>
                      <span className="icon icon-star-full text-lightest mr-3"></span>
                      <span className="icon icon-star-full text-lightest mr-3"></span>
                      <span className="icon icon-star-full text-lightest mr-3"></span>
                      <span className="icon icon-star-full text-lightest mr-10"></span>
                      <span>Nigel</span>
                      <span className="bg-dark brad-20 text-lightest pt-0 pb-3 pl-10 pr-10 ml-10">
                        <span className="img-circle text-lightest d-inline-block mr-5" style={{ width: '15px', height: '15px', backgroundColor: '#33b995', verticalAlign: 'middle' }}>
                          <span className="icon icon-checkmark4" style={{ fontSize: '9px', padding: '4px 3px 3px 2px', display: 'block' }} />
                        </span>
                        <span className="text-500 text-xs">Verified user</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-light box-shadow shadow-lighter brad-20 p-20 mb-20 text-center pt-15 trust-pilot mt-30">
                <a href="https://uk.trustpilot.com/review/switchboardfree.co.uk" target="_blank" rel="noopener noreferrer" className="d-block">
                  <img src="images/trustpilot-reviews.png" alt="Trust Pilot Reviews" />
                </a>
              </div>
              <div className="bg-light box-shadow shadow-lighter brad-20 p-20 reviews-box">
                <TextBannerWidget className="text-dark" textDark={true} />
              </div>


            </div>
          </div>
        </div>
      </div>



    </>
  }


}

export default withRouter(connect(
  state => {
    return {
    }
  },
  {
    handleShowCallbackForm
  }
)(InternationalCalls))
