import React from 'react'
import { connect } from 'react-redux'
import CallbackRequest from '../../components/CallbackRequest'
import DepartmentNumberControl from '../../components/configuration/DepartmentNumberControl'
import StandardMultipleDepartmentToggle from '../../components/configuration/StandardMultipleDepartmentToggle'
import { simpleDateTimeFormat, simplePhoneNumberFormat } from '../../helpers/utils'
import { getApiValidation } from '../../selectors'
import * as configurationActions from '../../store/Configuration'
import SaveButton from '../SaveButton'
import MultipleDepartmentsHelperDialog from './InstructionHelperDialogs/MultipleDepartmentsHelperDialog'

// import ConfigNavigationMenu from "../../components/configuration/ConfigNavigationMenu";

class ConfigNavigationMenu extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showNav: false
    }
  }

  render () {
    return <>
        <aside className="sidebar text-center" id="aside">
            <span id="asideToggle" className="open ">
                <span className="icon icon-arrow-right2" title="Click to show the sidebar"></span>
                <span className="icon icon-cross3 close-nav" title="Click to hide the sidebar"></span>
            </span>
            <div className="about-number">
                <h4 className="text-500">Settings for</h4>
                <h4 className="text-secondary text-700 text-xl">{simplePhoneNumberFormat(this.props.queueNo)}</h4>

                <p className="text-400 text-sm"><span className="icon icon-info" style={{ cursor: 'pointer' }} onClick={this.props.handleShowMultipleDepartmentsHelperDialog} title="Click to find out more"></span> Configure this number as:</p>
                <MultipleDepartmentsHelperDialog/>
            </div>
            <div className="keypad-wrapper">
                <div className="department-type">
                    <StandardMultipleDepartmentToggle />
                    <p className="text-xs text-start pt-10 pl-15 pr-15 d-none d-md-block">
                        <span className="text-500">Single Departments:</span> One introduction and up to 5 redirects. <span className="text-dark underline" style={{ cursor: 'pointer' }} onClick={this.props.handleShowMultipleDepartmentsHelperDialog}>More info</span>
                        <br/><br/>
                        <span className="text-500">Multiple departments:</span> One introduction, then a main menu with up to 9 departments i.e. “Press 1 for sales, press 2 for support. <span className="text-dark underline" style={{ cursor: 'pointer' }} onClick={this.props.handleShowMultipleDepartmentsHelperDialog}>More info</span>
                    </p>
                    <p className="text-primary underline text-xs text-center pt-10 d-block d-md-none pb-15 mb-0" onClick={this.props.handleShowMultipleDepartmentsHelperDialog}>Single &amp; Multiple departments explained</p>
                </div>
                <>
                    <DepartmentNumberControl />
                </>
            </div>
            <div className="save">
                <p className="text-xs text-400">Last saved: {simpleDateTimeFormat(this.props.lastSaveDate)}</p>
                {this.props.apiValidation && this.props.apiValidation.valid === false &&
                    <div className="alert alert-danger mt-20 mb-20">
                        <p>There was a problem saving your settings. Please try again.</p>
                    </div>
                }
                <SaveButton disabled={this.props.disabled} isSaving={this.props.isSaving} onClick={e => this.props.handleSave()} />

            </div>
            <div className="bg-dull fixed-bottom p-20">
                <h3 className="mt-0 lead">Need help?</h3>
                <p>
                    <span className="icon icon-phone mr-5"></span> <a href="tel:02031891213">0203 189 1213</a>
                </p>
                <p>
                    <span className="icon icon-bubble mr-5"></span> <a className="open-live-chat">Live Chat</a>
                </p>
                <p><CallbackRequest buttonSize="sm" buttonStyle="primary" buttonText="Request Callback" /></p>
            </div>
        </aside>

        {/* <div className={"row sidebar-nav ".concat(this.state.showNav === true ? 'nav-open' : 'nav-closed')} id="sidebarNav">
            <a id="sidebarToggle" onClick={e => this.setState({ showNav: this.state.showNav ? false : true })}>
                <span className="icon icon-menu7 open-nav"></span>
                <span className="icon icon-cross3 close-nav"></span>
            </a>

            {this.props.isStandardActive &&
                <div className="sidebar-buttons">
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.NUMBER_DESCRIPTION}>Number Description <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.SYSTEM_VOICE}>System Voice <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.OPEN_CLOSE_TIMES}>Open/Close Times <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.INTRODUCTION}>Introduction <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.REDIRECTS} >Redirects <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CALL_RECORDING}>Call Recording <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CALL_SCREENING}>Call Screening <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.MUSIC_ON_HOLD}>Music On Hold <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.MISSED_CALL_EMAIL_ALERTS}>Missed Call Email Alerts <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.VOICEMAIL}>Voicemail Email Alerts <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.OUT_OF_HOURS}>Out of Hours Prompt <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.NUMBER_PRESENTATION}>Number Presentation <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CUSTOM_RECORDINGS}>Custom Recordings <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.ANNOUNCE_POSITION}>Annnounce Position <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CALLBACK_SMS}>Callback SMS <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CALL_FILTERING}>Call Filtering <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>

                </div>
            }
            {this.props.isDepartmentActive &&
                <div className="sidebar-buttons">
                    <div className="list-divider text-sm text-500 pl-10">Global Settings</div>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.MAIN_NUMBER_DESCRIPTION}>Main Number Description <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.SYSTEM_VOICE}>System Voice <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.MAIN_INTRODUCTION}>Main Introduction <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.GLOBAL_MISSED_CALL_EMAIL}>Missed Call Email Alerts <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CALL_FILTERING}>Call Filtering <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>

                    <div className="list-divider text-sm text-500 pl-10">Department #{this.props.depNo} Settings</div>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.NUMBER_DESCRIPTION}>Description <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.INTRODUCTION}>Introduction <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.OPEN_CLOSE_TIMES}>Open/Close Times <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.DEPARTMENT_MENU}>Department Title <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.REDIRECTS}>Redirect Numbers <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CALL_RECORDING}>Call Recording <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CALL_SCREENING}>Call Screening <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.MUSIC_ON_HOLD}>Music On Hold <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.MISSED_CALL_EMAIL_ALERTS}>Missed Call Email Alerts <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.VOICEMAIL}>Voicemail Email Alerts <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.OUT_OF_HOURS}>Out of Hours Prompt <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.NUMBER_PRESENTATION}>Number Presentation <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CUSTOM_RECORDINGS}>Custom Recordings <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.ANNOUNCE_POSITION}>Annnounce Position <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                    <ConfigNavigationMenuButton navTo={scrollSectionNames.CALLBACK_SMS}>Callback SMS <span className="icon icon-arrow-right2"></span></ConfigNavigationMenuButton>
                </div>
            }
        </div> */}
    </>
  }

  componentDidMount () {
    const sidebarToggle = document.getElementById('asideToggle')

    sidebarToggle.addEventListener('click', function () {
      document.getElementById('asideToggle').classList.toggle('closed')
      document.getElementById('aside').classList.toggle('closed')
      document.getElementById('configurationPage').classList.toggle('closed')
    })
  }
}

export default connect(
  state => {
    return {
      isDepartmentActive: state.configuration.selectedConfiguration.isDepartmentActive,
      isStandardActive: state.configuration.selectedConfiguration.isStandardActive,
      depNo: state.configuration.selectedConfiguration.depNo,
      queueNo: state.configuration.selectedConfiguration.queueNo,
      lastSaveDate: state.configuration.configuration.lastSaveDate,
      apiValidation: getApiValidation(state) // Get any validation error passed back from API
    }
  }, {
    handleShowMultipleDepartmentsHelperDialog: configurationActions.handleShowMultipleDepartmentsHelperDialog
  }
)(ConfigNavigationMenu)
