import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import CarouselWidget from '../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { isLoggedIn } from '../selectors'

class Cancellation extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>How to Cancel Your Account</title>
                <meta name="description" content="Easily set up to 9 different departments on your phone" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/departments-bg.jpg')" }} className="full-width full-hero-small pl-30 pr-30">
                <h1>How to cancel your account & delete your data</h1>
                <h2 className="subtitle text-center">We're sorry to see you go...</h2>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="w-1000px center-block pb-30 pt-30">
                            <p>
                            We're sorry to hear that you&apos;re considering canceling your account with Switchboard Free. We value your feedback and want to ensure the cancellation process is as smooth as possible. Please take a moment to read the following instructions on how to cancel your account.
                            </p>
                            <ul className="pl-0 pt-30 unstyled text-400">
                                <li className="pb-20">
                                    <h4 className="text-500">1. Cancellation Request</h4>
                                    <p>
                                        To initiate the cancellation process, please send an email to <a className="text-cta underline" href="mailto:support@switchboardfree.co.uk?subject=Account%20cancellation">support@switchboardfree.co.uk</a> from the registered email address associated with your account.
                                        This will help us verify your identity and ensure a secure cancellation process.
                                        Please include your account details and a brief reason for cancellation in your email.
                                    </p>
                                </li>
                                <li className="pb-20">
                                <h4 className="text-500">2. Notice Period</h4>
                                <p>
                                    Please note that accounts can be terminated by giving notice equivalent to 30 days from the day notice is given or 90 days from the signup date, whichever is greater. During this time, your service and payments will continue as normal.
                                    We understand this may seem lengthy, but it allows us to ensure a smooth transition and provide any necessary support during the cancellation process.
                                </p>
                                </li>
                                <li className="pb-20">
                                <h4 className="text-500">3. Phone Number Recovery</h4>
                                <p>
                                    If there are any phone numbers associated with your account at the time of cancellation,
                                    they will be placed into quarantine for a minimum of 6 months. During this period, you have the opportunity to recover
                                    these numbers by contacting <a className="text-cta underline" href="mailto:support@switchboardfree.co.uk">support@switchboardfree.co.uk</a>. However, please note that recovery is not guaranteed, as availability depends on various factors.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
           </div>
           <div className="full-width bg-dull pt-60 pb-50">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget />
				</div>
			</div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(Cancellation)
