
import React from 'react'
import { connect } from 'react-redux'

// ######################################
//
//    Example 1: the below will show the chat opening times in one line, like this: 8:00 - 20:00 Mon-Fri. 9:00 - 18:00 Sat-Sun
//
//      <OpeningTimesLiveChat />
//
//
//    Example 2:  the below will show the chat opening times in two lines. Sat and Sun will be in the second line.
//
//       <OpeningTimesLiveChat TwoLines={true} />
//
// ######################################

class OpeningTimesLiveChat extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      this.props.TwoLines
        ? 
        <>
          <span>
              Mon-Fri 9:00 - 17:30
              <br/>
              Sat-Sun is closed
          </span>
        </>
        :			
        <>
          <span>9:00 - 17:30 Mon-Fri. Closed Sat-Sun</span>
        </>
    )
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(OpeningTimesLiveChat)
