import React from 'react'
import { Helmet } from 'react-helmet'
import CallTranscriptionToggle from '../../../components/CallTranscription/CallTranscriptionToggle'
import { Link } from "react-router-dom";

export default class CallTranscriptionFunctionPage extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>

            <Helmet>
                <title>Call Transcription</title>
                <meta name="description" content="Transcribe your calls for record keeping, searchability and compliance." />
                <style type="text/css">{`
                    .no-bg{
                            background-image:none !important;
                    }
                    .well, .well.well-transparent p,.well.well-transparent h5{
                        color:#3b3b3b !important;
                    }

                    `}</style>
            </Helmet>


            <div className={`full-width bg-light`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <div className="row mt-50 mb-60">
                                <div className="col-md-6 col-sm-12 order-last pt-50 pt-xs-0">
                                    <img src="/images/keyword-triggers.jpg" alt="Keyword Triggers with transcription" className="img-responsive brad-20 " style={{ maxWidth: "600px" }} />
                                </div>
                                <div className="col-md-6 col-sm-12 order-first">
                                    <h2 className="text-500 text-darker" id="CallTranscriptionH1">
                                        Call Transcription
                                    </h2>
                                    <div className="alert alert-success mt-20 mb-30">
                                        <span class="icon icon-checkmark4 ml-5 text-success text-sm mr-5"></span> Congratulations, you have Call Search Pro enabled on your account.
                                    </div>

                                    <p>
                                        This powerful tool allows you to set up alerts for specific words or phrases that matter to your business. Whether it's ensuring your team adheres to compliance protocols, or safeguarding a respectful conversation environment, your new feature is here to assist.
                                    </p>

                                    <p>Just follow these simple steps:</p>

                                    <ul className="styled-list success rounded text-start text-400 mt-40 pl-0">
                                        <li className="pb-10">
                                            <strong>Step 1:</strong> Visit the <Link to={'/customer/keyword-triggers'}>Keyword Triggers</Link> page to access your settings.
                                        </li>
                                        <li className="pb-10">
                                            <strong>Step 2:</strong> Start creating your triggers &amp; alerts. Remember, you can set triggers to ensure words or phrases are either said, or missing, in each conversation.
                                        </li>
                                        <li className="pb-10">
                                            <strong>Step 3:</strong> Once you've setup your triggers, simply keep an eye on your email inbox.
                                        </li>
                                    </ul>


                                    <Link className="btn btn-cta btn-lg mt-30 mb-0" to={'/customer/keyword-triggers'}>Keyword &amp; Alert Settings</Link>

                                </div>
                            </div>

                            <div className="row mt-100 mb-60">
                                <div className="col-md-6 col-sm-12 order-md-first text-end ">
                                    <img src="/images/conversation-search.jpg" alt="Keyword Triggers with transcription" className="img-responsive brad-20 " style={{ maxWidth: "600px" }} />
                                </div>
                                <div className="col-md-6 col-sm-12 order-first order-md-first">
                                    <h2 className="text-500 text-darker">
                                        Your Call Transcriptions
                                    </h2>

                                    <p>Did you know that you can easily access and review all your call transcriptions and recordings? </p>

                                    <p className="text-500">It's as simple as visiting the <Link className="text-500" to={'/customer/calls'}>My Calls Page</Link>. </p>

                                    <p>Here, every conversation you've had is stored for your convenience, making it effortless to reference past discussions, review important details, and identify key insights.</p>

                                    <p>
                                        Make the most out of your transcriptions by utilising the search functionality to find specific words or phrases that were spoken during a call. It's as if you have a conversation GPS, guiding you right to the heart of the information you need.


                                    </p>


                                    <Link variant="cta" className="btn btn-cta btn-lg mt-30 mb-0" to={'/customer/calls'}>Go To My Calls</Link>

                                </div>
                            </div>


                            <div className="row mt-50 mb-60 justify-content-center">
                                <div className="col-md-8 col-sm-12 order-first">
                                    <div className="bg-dull brad-10 text-center p-50">
                                        <CallTranscriptionToggle />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


           
        </>
  }
}
