import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../../../selectors'

class ZapierCapsule extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const title = `
        {
            "cli": "%%cli%%",
            "ddi": "%%ddi%%",
            "callDate": "%%call-date%%"
        }
        `
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>Integrate Capsule CRM via Webhooks with SwitchbaordFREE</title>
                <meta name="description" content="Discover how to utilise Webhooks and Zapier to integrate Capsule CRM with SwitchboardFREE's VoIP phone system" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/profile-bg.jpg')" }} className="full-width full-hero-small">
            </header>
            <div className="full-width bg-light">
                <div className="container pl-xxs-0 pr-xxs-0">
                    <div className="row">
                        <div className="col-lg-2 col-md-3 d-none d-md-block">
                            <div className="well well-light text-center mt-30" style={{ height: '200px', width: '200px' }}>
                                <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ height: '120px', width: '120px', margin: '20px auto' }}>
                                    <img src="/images/webhooks/slack.png" height="105" alt="slack logo" className="pt-20 pb-20" />
                                </div>
                            </div>
                            <div className="well bg-dull mt-20 text-center" style={{ width: '200px' }}>
                                <p className="text-500 pt-10">More integrations</p>
                                <p className="text-xs mb-40">Select apps you use to see guided workflows</p>

                                <div className="row zapier-sidebar">
                                    <div className="col-md-12 pb-30">
                                        <Link to={'/web-hook-integrations/salesforce'}>
                                            <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ height: '70px', width: '70px', margin: '20px auto' }}>
                                                <img src="/images/webhooks/salesforce.png" height="40" alt="salesforce logo" className="mt-5" />
                                            </div>
                                        </Link>
                                        <p className="text-500 mt-15 mb-10">
                                            Salesforce
                                        </p>
                                        <p className="text-xs">
                                            <Link to={'/web-hook-integrations/salesforce'} className="text-dark underline">View details</Link>
                                        </p>
                                    </div>

                                    <div className="col-md-12 pb-30">
                                        <Link to={'/web-hook-integrations/slack'}>
                                            <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ height: '70px', width: '70px', margin: '20px auto' }}>
                                                <img src="/images/webhooks/hubspot.png" height="40" alt="hubspot logo" className="mt-5" />
                                            </div>
                                        </Link>
                                        <p className="text-500 mt-15 mb-10">
                                            HubSpot
                                        </p>
                                        <p className="text-xs">
                                            <Link to={'/web-hook-integrations/slack'} className="text-dark underline">View details</Link>
                                        </p>
                                    </div>

                                    <div className="col-md-12 pb-30">
                                        <Link to={'/web-hook-integrations/trello'}>
                                            <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ height: '70px', width: '70px', margin: '20px auto' }}>
                                                <img src="/images/webhooks/trello.png" height="40" alt="trello logo" className="mt-5" />
                                            </div>
                                        </Link>
                                        <p className="text-500 mt-15 mb-10">
                                            Trello
                                        </p>
                                        <p className="text-xs">
                                            <Link to={'/web-hook-integrations/trello'} className="text-dark underline">View details</Link>
                                        </p>
                                    </div>

                                    <div className="col-md-12">
                                        <Link to={'/web-hook-integrations/google-sheets'}>
                                            <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ height: '70px', width: '70px', margin: '20px auto' }}>
                                                <img src="/images/webhooks/google-sheets.png" height="40" alt="google sheets logo" className="mt-5" />
                                            </div>
                                        </Link>
                                        <p className="text-500 mt-15 mb-10">
                                            Google Sheets
                                        </p>
                                        <p className="text-xs">
                                            <Link to={'/web-hook-integrations/google-sheets'} className="text-dark underline">View details</Link>
                                        </p>
                                    </div>

                                    <div className="col-md-12 pt-30">
                                        <div className="bt-2 pl-30 pr-30 block mb-20"></div>
                                        <p className="text-xxs text-400">In partnership with</p>
                                        <img src="/images/webhooks/zapier-logo-bw.png" style={{ width: '80px' }}/>
                                        <p className="text-xxs pt-10 text-400">
                                            Zapier automates 2,000+ apps in millions of ways. Let&apos;s find a way to save you time.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-10 col-md-9">
                            <h1 className="pb-30">Capsule Webhooks Integration</h1>

                            <h5 className="text-400 pb-30">
                                Here’s how to sync SwitchboardFREE with Capsule
                            </h5>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>1</span>
                                   <span className="text-500 text-italic">Sign in/sign up</span>  to <span className="text-500 text-italic">Zapier</span> and head to <a href="https://zapier.com/app/dashboard" target="_blank" className="text-cta underline" rel="noreferrer">https://zapier.com/app/dashboard</a>
                                </p>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>2</span>
                                   At the side, click <span className="text-500 text-italic">‘Make a Zap’</span>.
                                </p>
                                <div className="text-center p-30 well brad-t-0 brad-b-10 mb-0 b-0 box-shadow-none">
                                    <img src="/images/webhooks/zapier1.jpg" className="box-shadow" style={{ maxWidth: '100%' }} />
                                </div>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>3</span>
                                   In the search box, search for <span className="text-500 text-italic">‘Webhooks’</span> and select <span className="text-500 text-italic">‘Webhooks by Zapier’</span>.
                                </p>
                                <div className="text-center p-30 well brad-t-0 brad-b-10 mb-0 b-0 box-shadow-none">
                                    <img src="/images/webhooks/zapier2.jpg" className="box-shadow" style={{ maxWidth: '100%' }} />
                                </div>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>4</span>
                                   Under the <span className="text-500 text-italic">‘Choose Trigger Event’</span>, select <span className="text-500 text-italic">‘Catch Hook’</span> and click <span className="text-500 text-italic">‘Continue’</span>.
                                </p>
                                <div className="text-center p-30 well brad-t-0 brad-b-10 mb-0 b-0 box-shadow-none">
                                    <img src="/images/webhooks/zapier3.jpg" className="box-shadow" style={{ maxWidth: '100%' }} />
                                </div>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>5</span>
                                   Copy your <span className="text-500 text-italic">Custom Webhook URL</span> and keep it safe (you will need it in the next step). Click <span className="text-500 text-italic">‘Continue’</span>.
                                </p>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>6</span>
                                   Head to <span className="text-500 text-italic">SwitchboardFREE</span> in a new tab and <a href="/login" target="_blank" className="text-cta underline">Login</a> to your account
                                </p>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>7</span>
                                   Go to <a href="https://www.switchboardfree.co.uk/web-hook-settings/" target="_blank" className="text-cta underline" rel="noreferrer">https://www.switchboardfree.co.uk/web-hook-settings/</a>
                                </p>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>8</span>
                                   <span className="text-500 text-italic">Enter the following details</span>, and click <span className="text-500 text-italic">‘Save’</span>.
                                   <form className="form-horizontal mt-30" style={{ width: '500px', maxWidth: '100%', margin: '0 auto' }}>
                                        <div className="form-group">
                                            <label htmlFor="inputEmail3" className="col-sm-3 control-label">URL:</label>
                                            <div className="col-sm-9">
                                            <input type="url" className="form-control" value="The webhook URL from Zapier" readOnly="readonly"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputEmail3" className="col-sm-3 control-label">Method:</label>
                                            <div className="col-sm-9">
                                            <input type="url" className="form-control" value="POST" readOnly="readonly"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputEmail3" className="col-sm-3 control-label">Content Type:</label>
                                            <div className="col-sm-9">
                                            <input type="url" className="form-control" value="application/json" readOnly="readonly"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputEmail3" className="col-sm-3 control-label">Content Type:</label>
                                            <div className="col-sm-9">
                                            <textarea type="url" className="form-control" readOnly="readonly" style={{ minHeight: '170px' }}>
                                                {title}
                                            </textarea>
                                            </div>
                                        </div>
                                    </form>
                                </p>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>9</span>
                                   <span className="text-500 text-italic">Make a call</span> to your <span className="text-500 text-italic">SwitchboardFREE number</span> and head back to <span className="text-500 text-italic">Zapier</span>.
                                </p>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>10</span>
                                   Click <span className="text-500 text-italic">‘Test &amp; Review’</span> and wait until it says <span className="text-500 text-italic">“We found a request!”</span>. Click <span className="text-500 text-italic">‘Done Editing’</span>.
                                </p>
                                <div className="text-center p-30 well brad-t-0 brad-b-10 mb-0 b-0 box-shadow-none">
                                    <img src="/images/webhooks/zapier5.jpg" className="box-shadow" style={{ maxWidth: '100%' }} />
                                </div>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>11</span>
                                   In the search box, search for <span className="text-500 text-italic">Capsule</span> and select <span className="text-500 text-italic">Capsule</span> when the name is displayed.
                                </p>
                                <div className="text-center p-30 well brad-t-0 brad-b-10 mb-0 b-0 box-shadow-none">
                                    <img src="/images/webhooks/capsule1.png" className="box-shadow" style={{ maxWidth: '100%' }} />
                                </div>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>12</span>
                                   Under <span className="text-500 text-italic">‘Choose Action Event’</span>, select <span className="text-500 text-italic">‘Create Task’</span>, and click <span className="text-500 text-italic">‘Continue’</span>.
                                </p>
                                <div className="text-center p-30 well brad-t-0 brad-b-10 mb-0 b-0 box-shadow-none">
                                    <img src="/images/webhooks/capsule2.png" className="box-shadow" style={{ maxWidth: '100%' }}/>
                                </div>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>13</span>
                                   Click <span className="text-500 text-italic">‘Sign into Capsule CRM’</span>, and log in with your account and click <span className="text-500 text-italic">‘Continue’</span>.
                                </p>
                                <div className="text-center p-30 well brad-t-0 brad-b-10 mb-0 b-0 box-shadow-none">
                                    <img src="/images/webhooks/capsule3.png" className="box-shadow" style={{ maxWidth: '100%' }} />
                                </div>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>14</span>
                                   Enter the following details and click  <span className="text-500 text-italic">‘Continue’</span>.
                                </p>
                                <div className="text-center p-30 well brad-t-0 brad-b-10 mb-0 b-0 box-shadow-none">
                                    <img src="/images/webhooks/capsule4.png" className="box-shadow" style={{ maxWidth: '100%' }} />
                                </div>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>15</span>
                                   Click <span className="text-500 text-italic">‘Test &amp; Review’</span> and check that the record has been added to the leads section of Slack.
                                </p>
                                <div className="text-center p-30 well brad-t-0 brad-b-10 mb-0 b-0 box-shadow-none">
                                    <img src="/images/webhooks/capsule5.png" className="box-shadow" style={{ maxWidth: '100%' }}/>
                                </div>
                            </div>
                            <div className="b-2 brad-10 mb-20">
                                <p className="p-20 pb-10">
                                   <span className="text-lightest text-center img-circle text-500 bg-cta d-inline-block mr-20" style={{ height: '30px', width: '30px', lineHeight: '1.7' }}>16</span>
                                   Click <span className="text-500 text-italic">‘Done Editing’</span> and turn the <span className="text-500 text-italic">‘Zap’</span> on in the top right corner. You’re now all ready and set to go!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width full-dull text-center pb-60 pt-60">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-500">Check out other integrations</h3>
                            <a href="/webhooks" className="btn btn-lg btn-cta mt-30 mb-30">See more integrations</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(ZapierCapsule)
