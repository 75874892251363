import React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { FanvilProdId, x3s, x7a, YeaLinkProdId } from '../../../constants/Constants'
import { fetchHelper } from '../../../helpers/fetchHelper'
import SoftphoneManualText from './SoftphoneManualText'

const FreePhoneMoreInfo = (
	<Popover id="popover-basic">
		<p>To get this phone(s) for free, during the last step of the signup process (Checkout page), select the <i>Bi-Annual</i> payment option.</p>
		<p>
			<img src="/images/bi-annual.png" className="w-100" alt="Bi-Annual payment screenshot" />
		</p>
	</Popover>
)

class OutboundManualSoftphone extends React.Component {
	constructor(props, context) {
		super(props, context)

		this.state = {
			activeKey: this.props.match.params.activeTab ? this.props.match.params.activeTab : 'installation',
			showHandsetOrder: false
		}
	}

	render() {
		return (
			<>
				<ScrollToTopOnMount />
				<DisplayMainFooter /> <DisplayMainHeader />
				<Helmet>
					<title>VoIP Phone Setup Guide</title>
					<meta
						name="description"
						content="SwitchboardFREE outbound phone - Fanvil X4U manual"
					/>
				</Helmet>
				<header
					style={{
						backgroundImage: "url('/images/backgrounds/howitworks-bg.jpg')"
					}}
					className="full-width full-hero-small with-text"
				>
					<h1 id="VoipSoftphoneSetupInstructionsH1">SwitchboardFREE VoIP Softphone</h1>
					<h2 className="subtitle">
						Make and receive calls on your computer with the help of a
						softphone.
					</h2>
				</header>
				<div className="container-styled">
					<div className="container pb-100">
						<div className="row">
							<div className="col-md-12">
								<ul role="tablist" className="nav nav-tabs justify-content-center">
									<li role="presentation" className="active">
										<Link to="/setup/softphone" className="bg-secondary text-light">
											Windows
										</Link>
									</li>
									<li role="presentation" className="">
										<Link to="/setup/softphone/mac">
											Mac
										</Link>
									</li>
								</ul>
								<div className="w-800px center-block">
									<SoftphoneManualText />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="full-width bg-dull">
					<div className="container">
						<div className="row text-center mb-30 mt-30">
							<div className="col-md-8 mx-auto">
								<div className="row">
									<div className="col-xs-12 mb-30">
										<h3 className="text-500">Other devices manuals</h3>
									</div>
									<div className="col-xs-4 col-xxs-12 mb-20">
										<Link to={'/setup/wireless-phone'}>
											<div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
												<img
													src="/images/product-shots/ecom/W52P.jpg"
													className="img-responsive"
												/>
												<h5 className="text-500 text-sm-md">
													Wireless Phone
												</h5>
												<Link
													to={'/setup/wireless-phone'}
													className="btn btn-xs btn-cta mt-10 mb-20"
												>
													See Instructions
												</Link>
											</div>
										</Link>
									</div>
									<div className="col-xs-4 col-xxs-12 mb-20">
										<Link to={'/setup/app'}>
											<div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
												<img
													src="/images/product-shots/ecom/app.jpg"
													className="img-responsive"
												/>
												<h5 className="text-500 text-sm-md">App</h5>
												<Link
													to={'/setup/app'}
													className="btn btn-xs btn-cta mt-10 mb-20"
												>
													See Instructions
												</Link>
											</div>
										</Link>
									</div>
									<div className="col-xs-4 col-xxs-12 mb-20">
										<Link to={'/setup/softphone'}>
											<div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
												<img
													src="/images/product-shots/ecom/softphone.jpg"
													className="img-responsive"
												/>
												<h5 className="text-500 text-sm-md">
													PC Softphone
												</h5>
												<Link
													to={'/setup/softphone'}
													className="btn btn-xs btn-cta mt-10 mb-20"
												>
													See Instructions
												</Link>
											</div>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="full-width bg-light">
					<div className="container" style={{ width: '2000px', maxWidth: '100%' }}>
						<div className="row">
							<div className="col-md-12 mx-auto pt-30 mb-100">
								<div className="row">
									<div className="col-md-12 text-center pt-30">
										<h3 className="text-500">Get Your HD VoIP Handset</h3>
										{/* <h4 className="text-cta"><span className="text-500">FROM JUST £6</span> p/month</h4> */}

										<h5 className="text-500 pt-10 pb-30">
											HASSLE-FREE Installation <span className="text-cta">•</span> FREE UK Support <span className="text-cta">•</span> UNRIVALLED Quality
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-xs-3 col-xxs-12 text-center mb-20">
										<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light ">
											<img src="/images/product-shots/ecom/W53P.jpg" alt="Cordless VoIP Phone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
											<h4 className="text-500 mb-5">Cordless VoIP Handset</h4>
											{/* <p className="text-cta text-md">From <span className="text-500">£7.50</span> p/month</p> */}
											<p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
											<p className="pt-5 pb-5 pl-20 pr-20 text-center d-block">&nbsp;</p>
											<Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(YeaLinkProdId)}>Order Now</Button>
											<br />
											<a href="/products/yealink-w53p-wireless-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
											{/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
										</div>
									</div>
									<div className="col-xs-3 col-xxs-12 text-center mb-20">
										<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
											<img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
											<h4 className="text-500 mb-5">Advanced VoIP Handset</h4>
											{/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
											<p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
											<p className="pt-5 pb-5 pl-20 pr-20 text-center d-block">&nbsp;</p>
											<Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(FanvilProdId)}>Order Now</Button>
											<br />
											<a href="/products/advanced-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
											{/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
										</div>
									</div>
									<div className="col-xs-3 col-xxs-12 text-center mb-20">
										<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
											<img src="/images/product-shots/ecom/X3S.jpg" alt="Fanvil X3S VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
											<h4 className="text-500 mb-5">VoIP Handset</h4>
											{/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
											<p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
											<div className="d-block">
												<p className="bg-secondary brad-10 pt-5 pb-5 pl-20 pr-20 text-center text-lightest d-inline-block">
													Get it FREE! <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={FreePhoneMoreInfo}>
														<span className="underline cursor-pointer ml-5 text-sm">See how</span>
													</OverlayTrigger>.
												</p>
											</div>

											<Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(x3s)}>Order Now</Button>
											<br />
											<a href="/products/voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
											{/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
										</div>
									</div>
									<div className="col-xs-3 col-xxs-12 text-center mb-20">
										<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
											<img src="/images/product-shots/ecom/X7A.jpg" alt="Fanvil X7A Android IP Video Phone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
											<h4 className="text-500 mb-5">Android Touchscreen Phone</h4>
											{/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
											<p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
											<p className="pt-5 pb-5 pl-20 pr-20 text-center d-block">&nbsp;</p>

											<Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(x7a)}>Order Now</Button>
											<br />
											<a href="/products/x7a-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
											{/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	componentDidMount() {
		this.getHardware()
		const tabs = document.getElementsByClassName('nav-tabs')
		tabs[0].classList.add('nav-tabs-centered')
		const wrapper = document.createElement('div')
		wrapper.classList.add('tabs-container')
		tabs[0].parentNode.insertBefore(wrapper, tabs[0])
		wrapper.appendChild(tabs[0])

		if (this.props.match !== undefined && this.props.match.params.scrollSection) {
			scroller.scrollTo(this.props.match.params.scrollSection, {
				duration: 1500,
				smooth: true,
				delay: 1000,
				offset: -200
			})
		}
	}

	getHardware() {
		const url = 'api/Purchase/GetHardware'
		fetchHelper.getJson(url)
			.then(res => {
				if (res.data) {
					this.setState({ products: res.data })
				}
			}).catch(err => console.error(err))
	}

	handleAddHandset(prodId) {
		const product = this.state.products.filter(f => f.prodId === prodId)
		const tempData = [{
			qty: 1,
			...product[0]
		}]

		localStorage.setItem('HardwareFirst', JSON.stringify(tempData))

		this.props.history.push('/get-started')
	}
}
export default connect(
	state => {
		return state
	},
	{}
)(OutboundManualSoftphone)
