import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { fetchHelper } from '../../../helpers/fetchHelper'
import Pager from '../../pager'
const _ = require('lodash')

class NumberRentals extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    page: 1,
    totalPages: 1,
    showCancelNumberModal: false
  }

  constructor (props) {
    super(props)

    this.handleOpenCancelNumberRental = this.handleOpenCancelNumberRental.bind(this)
    this.handleCloseCancelNumberRental = this.handleCloseCancelNumberRental.bind(this)
  };

  componentDidMount () {
    this.getRentals()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getRentals()
    }
  }

  getRentals () {
    const url = `/api/Profile/GetNumberRentals/page=${this.state.page}`

    fetchHelper.getJson(url).then(res => {
      const numberRentals = res.data.numberRentals
      const totalPages = res.data.totalPages
      const allowancePools = res.data.allowancePools

      this.setState({ numberRentals, totalPages, allowancePools })
    }).catch(function (error) {
      console.error(error)
    })
  }

  handlePageChange (page) {
    this.setState({ page })
  }

  renderAllowancePoolModal () {
    const selectedAllowancePool = this.state.selectedAllowancePool
    return (
            <Modal show={selectedAllowancePool} onHide={e => this.setState({ selectedAllowancePool: undefined })}>
                <Modal.Header closeButton className="text-center">
                    <Modal.Title>Allowance Pool</Modal.Title>
                </Modal.Header>
                {selectedAllowancePool && <Modal.Body>

                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <p>
                                An allowance pool is a number of inclusive call minutes which is shared between a selection of your SwitchboardFREE numbers.
                            </p>

                        </div>

                    <div className="col-sm-12 pt-30">
                        <div className="text-center">
                            <h4>Inclusive Minutes</h4>
                        </div>
                    </div>
                    <div className="col-sm-4 text-center br-sm-1 br-xs-0">
                    <div className="icon-block mt-20"><span className="icon icon-phone"></span></div>
                        <h4>Landline</h4>
                        {`${Math.round(selectedAllowancePool.landlineSeconds / 60)} (${Math.round((selectedAllowancePool.landlineSeconds - selectedAllowancePool.usedLandlineSeconds) / 60)} left)`}
                    </div>
                    <div className="col-sm-4 text-center br-sm-1 br-xs-0">
                    <div className="icon-block mt-20"><span className="icon icon-mobile"></span></div>
                        <h4>Mobile</h4>
                        {`${Math.round(selectedAllowancePool.mobileSeconds / 60)} (${Math.round((selectedAllowancePool.mobileSeconds - selectedAllowancePool.usedMobileSeconds) / 60)} left)`}
                    </div>
                    <div className="col-sm-4 text-center">
                    <div className="icon-block mt-20"><span className="icon icon-coin-pound"></span></div>
                        <h4>Monthly rental</h4>
                        {`£${selectedAllowancePool.amount}`}
                    </div>
                    <div className="col-sm-12 mt-40">
                        <div className="text-center">
                            <h4>Numbers in this pool</h4>
                        </div>
                        <ul className="unstyled pl-0 ml-0 text-center">
                        {selectedAllowancePool.numbers.map((num) => <li className="pb-5 pt-5 bt-1"><span className="icon icon-phone2 text-primary"></span> {num}</li>)}
                        </ul>
                    </div>
                    </div>
                </Modal.Body>}
                <Modal.Footer>
                    <Button size="md" variant="cta" onClick={e => this.setState({ selectedAllowancePool: undefined })}>Close</Button>
                </Modal.Footer>
            </Modal>)
  }

  handleOpenCancelNumberRental () {
    this.setState({ showCancelNumberModal: true })
  }

  handleCloseCancelNumberRental () {
    this.setState({ showCancelNumberModal: false })
  }

  render () {
    function renderInclusiveMinutesNonAllowance (rental) {
      return <div className="row">
                        <div className="col-xs-12 text-center pt-10 pb-20 mb-20 bb-1">
                            {/* <p> */}
                                <span className="text-500 text-xs">Inclusive Minutes</span>
                                <br/>
                            {/* </p> */}
                            <div className="row">
                                <div className="col-xs-6 text-xs text-xs-sm pt-10">
                                    <span title="Landline inclusive minutes" className="text-400"><span className="icon icon-phone"></span> {`${rental.landlineSecondsInc / 60} (${Math.round(rental.landlineSecondsLeft / 60)} left)`}</span>
                                </div>
                                <div className="col-xs-6 text-xs text-xs-sm pt-10">
                                    <span title="Mobile inclusive minutes" className="text-400"><span className="icon icon-mobile"></span> {`${rental.mobileSecondsInc / 60} (${Math.round(rental.mobileSecondsLeft / 60)} left)`}</span>
                                </div>
                            </div>
                    </div>
                </div>
    }

    return (
            <div className="w-800px pt-30 pb-40 center-block" style={{ overflow: 'auto' }}>
                {this.renderAllowancePoolModal()}
                <div className="col-md-12 text-center pb-40">
                    <h4 className="pb-20 text-400">Individual Number rentals</h4>
                    <p>
                        Details on additional number packages and inclusive minutes
                        If you wish to change any inclusive minute packages call our UK support
                        team on <a href="tel:0203 189 1213" className="text-cta underline">0203 189 1213</a>.
                    </p>
                </div>

                {this.state.numberRentals && this.state.numberRentals.length === 0 &&
                <div className="col-sm-12 col-md-12 pl-0 pr-0 pt-10 pb-10">
                    <div className="text-center">
                        <p>
                            There aren't any rentals connected with your account.
                        </p>
                    </div>
                </div>}

                {this.state.numberRentals && this.state.numberRentals.length > 0 &&
                <div>

                    {/* Number Rentals row */}
                    {this.state.numberRentals.map((rental) => {
                      const allowancePool = _.find(this.state.allowancePools, pool => pool.allowancePoolId === rental.allowancePoolId)

                      return <div className="w-48 w-xs-100 pl-20 pr-20 bt-1 pt-10 pb-20 bg-light brad-10 b-1 block float-start m-5 mb-10 ml-xs-0 mr-xs-0">
                            <div className="col-xs-12 text-center pb-10 pt-10">
                                <p>
                                    <span className="text-500 text-xs">
                                        Number
                                    </span>
                                    <br/>
                                    <span className="text-md">{rental.number}</span>
                                </p>
                            </div>
                            {/* <div className="col-sm-12 text-center pt-sm-30">
                                <p>
                                <span className="text-500 text-xs">
                                        Your allowance pool
                                    </span>
                                </p>
                                {(!rental.allowancePoolId || rental.allowancePoolId === 0) && renderInclusiveMinutesNonAllowance(rental)}
                                {(rental.allowancePoolId && rental.allowancePoolId > 0) && <p className="text-cta underline" onClick={event => this.setState({selectedAllowancePool:allowancePool})}>See allowance pool</p>}
                            </div> */}
                            <div className="col-xs-12 text-center pt-10 pb-10 bt-1">
                                <p>
                                    <span
                                        className="text-500 text-xs">Additional Minutes Rate
                                    </span>
                                    <br/>
                                    <div className="row">
                                        <div className="col-xs-6 text-xs text-xs-sm pt-10">
                                            <span><span className="icon icon-phone"></span> {rental.additionalLandlineRate}p per min</span>
                                        </div>
                                        <div className="col-xs-6 text-xs text-xs-sm pt-10">
                                            <span><span className="icon icon-mobile"></span> {rental.additionalMobileRate}p per min</span>
                                        </div>
                                    </div>

                                </p>
                            </div>
                            <div className="col-xs-12 text-center pt-10 pb-10 bt-1">
                                <p>
                                    <span className="text-500 text-xs">Monthly Charge </span>
                                    <br/>
                                    <span className="text-xs text-xs-sm pt-10">
                                        {rental.monthlyCharge > 0 ? `£${rental.monthlyCharge.toFixed(2)}` : 'N/A: View Allowance Pool details'}
                                    </span>
                                </p>
                            </div>
                            <div className="col-xs-12 text-center pt-10 pb-10 bt-1">
                            {(!rental.allowancePoolId || rental.allowancePoolId === 0) && renderInclusiveMinutesNonAllowance(rental)}
                                {(rental.allowancePoolId && rental.allowancePoolId > 0) && <Button size="xs" variant="secondary" className="mr-5" onClick={event => this.setState({ selectedAllowancePool: allowancePool })}>Allowance pool</Button>}
                                <Button variant="danger" size="xs" onClick={event => this.handleOpenCancelNumberRental()} >Cancel</Button>
                            </div>
                        </div>
                    })}
                    {/* End Number Rentals row */}

                    {/* Number Rentals subpages */}
                    <Pager onSelected={page => this.handlePageChange(page)} activePage={this.state.page}
                           pages={this.state.totalPages}/>
                </div>}

                <Modal className="modal-med notify-modal" show={this.state.showCancelNumberModal} onHide={this.handleCloseCancelNumberRental}>
                    <Modal.Body className="text-center">
                        <div className="icon-wrapper mt-20 mb-20">
                            <span className="icon icon-headphones"></span>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    Please email <a href="mailto:support@switchboardfree.co.uk" className="text-dark underline">support@switchboardfree.co.uk</a> to cancel your number.
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="text-center bt-0 p-0 mt-30">
                        <Button variant="cta" className="btn-block brad-0 pt-20 pb-20" onClick={this.handleCloseCancelNumberRental}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </div>
    )
  }
}

export default NumberRentals
