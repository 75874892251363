import React from 'react'
import { connect } from 'react-redux'
import CallbackRequest from '../components/CallbackRequest'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

class Charities extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount/><DisplayMainFooter/> <DisplayMainHeader/>
            <header>
				<div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/charities-bg.jpg)" }}>
					<h1>SwitchboardFREE for Charities</h1>
					<h2 className="subtitle">Over the years we have worked closely with a number of charitable organisations. SwitchboardFREE appreciates the incredible work that they do and also understands some of the difficulties that they face, particularly surrounding funding.</h2>
				</div>
			</header>
    <div className="container-styled">
        <div className="full-width bg-light">
            <div className="container">
                <div className="row">
                    <ul className="styled-list cta rounded">
                        <div className="col-md-12 text-center">
                            <h4 className="pb-30 text-500">As a registered charity we offer 10% off all our prices</h4>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <div className="full-width bg-dull pt-60 pb-60">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 mx-auto text-center pb-50 bg-light box-shadow brad-10">
                        <h3 className="text-500 pt-20">Enquire about charity discounts today!</h3>
                        <p className="pt-30">To qualify you will need to provide us with <br className="d-none d-md-block"/>
                        your registered charity number and be based in the UK.</p>
                        <p className=" mb-30">To activate your Charity pack contact us using one of the below options.</p>
                        <h5 className="text-400 mb-50">Call on <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a>, email <a href="support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a>
                        <br className="d-none d-md-block"/>or request a callback below.</h5>
                        <CallbackRequest subject="Callback request from Charities page" isForm={true} buttonSize="lg"/>
                    </div>
                </div>
            </div>
       </div>
    </div>

    </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(Charities)
