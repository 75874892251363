import moment from 'moment'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { Button, Form, Modal, OverlayTrigger, Popover, Tab, Tabs } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import Select from 'react-select'
import * as yup from 'yup'
import RedirectNumber from '../../components/configuration/RedirectNumber'
import RedirectSecondsSelect from '../../components/configuration/RedirectSecondsSelect'
import TeamManagement from '../../components/Dashboard/TeamManagement'
import DisplayNoFooter from '../../components/DisplayNoFooter'
import DisplaySimpleHeaderV2 from '../../components/DisplaySimpleHeaderV2'
import LoadingNotification from '../../components/LoadingNotification'
import PromptAudioPlayer from '../../components/PromptAudioPlayer'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import PurchasePhones from '../../components/purchase/PurchasePhones'
import RefreshUserServices from '../../components/RefreshUserServices'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import VoipUsers from '../../components/VoipUsers/VoipUsers'
import { additionalMobileRate, CallAnsweringPricePerCall, InhouseMinimumPromptPurchaseThreshold, InhousePricePerWord, PhoneTypes, productBillingOption, ProductIds, PromptTypes, RedirectLimit, validationStates, WizardDiscounts, WizardProductBillingOptions } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat, checkNumberType, getFirstNameFromName, getQueryStringFromProps, isObject, simplePhoneNumberFormat } from '../../helpers/utils'
import { getConfiguration, getConfigValidators, isLoggedIn } from '../../selectors'
import chrisMp3Sample from '../../sounds/chrisDemo.mp3'
import janiceMp3Sample from '../../sounds/janiceDemo.mp3'
import * as configurationActions from '../../store/Configuration'
import * as fromHome from '../../store/Home'
import { handleCallBlockingEnabledChange, handleCallRecordingAllEnableChange, handleChangeHasAgreement, handleDeliveryAdd1Change, handleDeliveryAdd2Change, handleDeliveryCountyChange, handleDeliveryNameChange, handleDeliveryNoteChange, handleDeliveryPcodeChange, handleDeliveryTownChange, handleNotificationChange, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal, handleUmrEnabledChange, handleUpdateCredit } from '../../store/SBF'
import CallTranscriptionBenefitsList from '../../components/Text/CallTranscriptionBenefitsList'

const _ = require('lodash')

const redirectsPopover = (
	<Popover id="popover-trigger-hover-focus">
		<p className="text-400">This is the amount of time we will ring this redirect number before pausing and attempting the next one.
			If you have only one redirect, then after the pause it will retry the first redirect over again &amp; again.
		</p>
	</Popover>
)

class NumberWizard extends React.Component {
  state = {
    userFirstName: '',
    activeStep: 1,
    showConfirmedModal: false,
    isLoadingIntroPrompts: false,
    introPrompts: [],
    errors: [],
    wizard: {
      wizIntroSelectedPrompt: undefined,
      wizSelectedVoice: undefined,
      closedBankHol: false,
      voicemailEnabled: true,
      voicemailEmail: '',
      missedCallEnabled: true,
      missedCallEmail: '',
      buyCallRecording: false,
      enableTranscription: false,
      buyCustomPrompts: false,
      buyUmr: false,
      buyCallAnswering: false,
      upgradePlan: false,
      callBlocking: false,
      addAudioBranding: false
    },
    userQty: 0,
    openMonFri: true,
    voiceOptions: [],
    isLoadingVoiceOptions: false,
    phoneQty: 0,
    showEditDeliveryAddress: false,
    gettingData: true,
    directToPhoneApp: true,
    directToSoftphone: true
  }

  constructor (props) {
    super(props)

    yup.addMethod(yup.string, 'openClose', function (ref, message) {
      return this.test('openClose', message, function (value) {
        const openTime = this.resolve(ref)
        return moment(value).isAfter(openTime) || moment(value).isSame(openTime)
      })
    })

    yup.addMethod(yup.string, 'emails', function (message) {
      return this.test('emails', message, function (value) {
        const arr = value.split(';').map(s => s.trim())
        const arrSchema = yup.array().of(yup.string().email())
        return arrSchema.isValidSync(arr)
      })
    })

    this.validation = yup.object().shape({
      // Open / close times
      monClosed: yup.string().openClose(yup.ref('monOpen'), 'Mondays close time needs to be after Mondays open time'),
      tueClosed: yup.string().openClose(yup.ref('tueOpen'), 'Tuesdays close time needs to be after Tuesdays open time'),
      wedClosed: yup.string().openClose(yup.ref('wedOpen'), 'Wednesdays close time needs to be after Wednesdays open time'),
      thuClosed: yup.string().openClose(yup.ref('thuOpen'), 'Thursdays close time needs to be after Thursdays open time'),
      friClosed: yup.string().openClose(yup.ref('friOpen'), 'Fridays close time needs to be after Fridays open time'),
      satClosed: yup.string().openClose(yup.ref('satOpen'), 'Saturdays close time needs to be after Saturdays open time'),
      sunClosed: yup.string().openClose(yup.ref('sunOpen'), 'Sundays close time needs to be after Sundays open time'),

      // Missec alls
      missedCallEmail: yup.string().emails('Please enter a valid missed call email').when('missedCallEnabled', { is: true, then: s => s.required('Please enter atleast 1 email to receive your alerts.') }),
      missedCallEnabled: yup.bool()
    })
  }

  scrollTop () {
    scroller.scrollTo('start', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -100
    })
  }

  render () {
    const { config } = this.props
    const { validation } = this.props

    const umrPayment = { firstPayment: 0, repeatPayment: 0 }
    const callRecordPayment = { firstPayment: 0, repeatPayment: 0 }
    const endpointPrice = { firstPayment: 0, repeatPayment: 0, partialPayment: 0 }
    const callBlockPrice = { firstPayment: 0, repeatPayment: 0 }

    if (this.props.productDetails) {
      const umrPaymentDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.umr)
      const callRecordPaymentDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.callRecordAll)
      const endpointProdDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.endpoint)
      const callBlockProdDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.callBlocking)

      if (endpointProdDetails && endpointProdDetails.monthlyPrice) {
        endpointPrice.firstPayment = endpointProdDetails.monthlyPrice.firstPaymentAmount
        endpointPrice.repeatPayment = endpointProdDetails.monthlyPrice.repeatPaymentAmount
      }

      if (umrPaymentDetails && umrPaymentDetails.monthlyPrice) {
        umrPayment.firstPayment = umrPaymentDetails.monthlyPrice.firstPaymentAmount
        umrPayment.repeatPayment = umrPaymentDetails.monthlyPrice.repeatPaymentAmount
      }

      if (callRecordPaymentDetails && callRecordPaymentDetails.monthlyPrice) {
        callRecordPayment.firstPayment = callRecordPaymentDetails.monthlyPrice.firstPaymentAmount
        callRecordPayment.repeatPayment = callRecordPaymentDetails.monthlyPrice.repeatPaymentAmount
      }

      if (callBlockProdDetails && callBlockProdDetails.monthlyPrice) {
        callBlockPrice.firstPayment = callBlockProdDetails.monthlyPrice.firstPaymentAmount
        callBlockPrice.repeatPayment = callBlockProdDetails.monthlyPrice.repeatPaymentAmount
      }
    }

    const errors = this.state.errors
    const openCloseErrors = (({ monClosed, tueClosed, wedClosed, thuClosed, friClosed, satClosed, sunClosed }) =>
      ({
        monClosed, tueClosed, wedClosed, thuClosed, friClosed, satClosed, sunClosed
      }))(errors)
    const hasOpenCloseErrors = (openCloseErrors.monClosed || openCloseErrors.tueClosed || openCloseErrors.wedClosed || openCloseErrors.thuClosed || openCloseErrors.friClosed || openCloseErrors.satClosed || openCloseErrors.sunClosed)

    if (!String.prototype.includes) {
      String.prototype.includes = function (search, start) {
        'use strict'
        if (typeof start !== 'number') {
          start = 0
        }

        if (start + search.length > this.length) {
          return false
        } else {
          return this.indexOf(search, start) !== -1
        }
      }
    }

    if (this.state.wizard.buyCallRecording === true && this.props.services.hasCallRecordingAll === true) {
      this.setState({ wizard: { ...this.state.wizard, buyCallRecording: false }, purchasedCallRec: undefined })
    } else if (this.state.wizard.buyCallRecording === true && this.state.purchasedCallRec === true) {
      this.setState({ wizard: { ...this.state.wizard, buyCallRecording: false } })
    }

    if (this.state.wizard.buyUmr === true && this.props.services.hasUmr === true) {
      this.setState({ wizard: { ...this.state.wizard, buyUmr: false }, purchasedUmr: undefined })
    } else if (this.state.wizard.buyUmr === true && this.state.purchasedUmr === true) {
      this.setState({ wizard: { ...this.state.wizard, buyUmr: false } })
    } else if (this.state.number && !this.state.number.number.startsWith('084') && this.state.wizard.buyUmr === true) {
      this.setState({ wizard: { ...this.state.wizard, buyUmr: false } })
    }

		if (this.props.match.params.step) {
			if (Number(this.props.match.params.step) !== this.state.activeStep) {
				this.setState({ activeStep: Number(this.props.match.params.step) })
			}
    } else if (localStorage.getItem('wizStep')) {
      const step = Number(localStorage.getItem('wizStep'))
      if (step && step !== this.state.activeStep) {
        this.props.history.push(`/customer/number-setup-wizard/${step}`)
        localStorage.removeItem('wizStep')
      }
    }

    let hardware = localStorage.getItem('hardware')
    if (hardware) {
      hardware = JSON.parse(hardware)
      let qty = 0
      hardware.forEach((phone) => {
        qty += phone.qty
      })

      if (this.state.phoneQty !== qty) {
        this.setState({ phoneQty: qty, wizard: { ...this.state.wizard, hardware } })
      }
    }

    if (this.state.activeStep === 5) {
      if (config && config.sipUsers && config.sipUsers.length <= 1 && !this.state.showRedirects) {
        // Don't show teams
        this.setState({ showRedirects: true })
      }
    }

    return <><ScrollToTopOnMount /><DisplayNoFooter /><DisplaySimpleHeaderV2 />
			{this.props.isLoggedIn &&
				<RefreshUserServices />
			}
			<Helmet>
				<body className={'body-simple bg-dull body-signup body-wizard'} />
				<title>{this.props.isLoggedIn ? 'Number setup' : 'Setting up your account'}</title>
			</Helmet>
			{this.state.isPorting === true
			  ? <>
					<div className="row">
						<div className="col-md-12 text-center pb-40">
							<h3 className="text-500 mb-0 mt-0"><img src="/images/icons/congrats.svg" style={{ height: '75px', marginRight: '20px' }} />
								Congratulations Transferring Your Number
							</h3>
							<p className="lead text-400">
								Please check your emails.
							</p>
						</div>

						<div className="col-md-12 text-center clearfix">
							<p className="text-md mt-30">
								We've emailed you a form to complete and return to us so that we can commence your number transfer.<br />In most cases these only take a few business days.<br/><br/>
								If you get stuck, our UK-based support team are available Mon - Fri, 8:30am - 5:30pm, on <br className="hidden-xs" /><a href="tel:0203 189 1213">0203 189 1213</a>, <a href="mailto:support@switchboardfree.co.uk">support@switchboardfree.co.uk</a> or <a className="open-live-chat">live chat</a>.<br />
							</p>
						</div>
					</div>
					<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
						<Link className="btn btn-cta btn-lg" to="/customer/dashboard">
							Go to my dashboard <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
						</Link>
					</div>
				</>
			  : (this.state.basket) &&
				<>
					<div className="full-width" id="start">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-10 col-xs-12 col-lg-offset-2 mx-auto">
									<div className="row m-0">
										<Tabs activeKey={this.state.activeStep} onSelect={e => this.handleStepSelect(e)} id="wizard-tabs">
											<Tab tabClassName="hidden" eventKey={1} title="Welcome">
													<div className="row">

														<div className="col-md-12 text-center pb-40">
															<h3 className="text-500 mb-0 mt-0"><img src="/images/icons/congrats.svg" style={{ height: '75px', marginRight: '20px' }} />Congratulations &amp; Welcome To SwitchboardFREE</h3>
															<h2 className="text-center text-dullest text-lg text-400 mb-30">
																Your new {this.state.basket.numbers.length > 1 ? 'numbers have been added to' : 'number is now on'} your account.<br className="d-none d-sm-block" />
															</h2>
														</div>

														<div className="col-md-12 text-center clearfix">
															<div className="ratio ratio-16x9 brad-30 mb-30">
																<iframe className="embed-responsive-item" src="https://www.youtube.com/embed/p6JQzzxcISE?start=5" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
															</div>

															<p className="text-md mt-30">
																If you get stuck, our UK-based support team are available Mon - Fri, 8:30am - 5:30pm, on <br className="hidden-xs" /><a href="tel:0203 189 1213">0203 189 1213</a>, <a href="mailto:support@switchboardfree.co.uk">support@switchboardfree.co.uk</a> or <a className="open-live-chat">live chat</a>.<br />
															</p>
														</div>
													</div>
													<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
														{this.state.isPurchasingCredit
														  ? <LoadingNotification loadingText={'Please wait...'} isLoading={true} width={40} height={40} />
														  :															<Button variant="cta" size="lg" className="btn-mobile-block" onClick={e => this.handleStepSelect(2)}>
																Setup my new number <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
															</Button>
														}
													</div>

											</Tab>
											<Tab tabClassName="hidden" eventKey={2} title="Welcome">
												<div className="row">

													<div className="col-md-12 text-center pb-40">
														<h2 className="text-500 text-center text-xl">How do you plan on answering your calls, {this.state.userFirstName}</h2>
														<h2 className="text-center text-dullest text-md text-400 mb-30">
															Please select all that apply
														</h2>
													</div>

													<div className="col-12 d-flex justify-content-center">
														<div className="m-10 text-center">
															<div className="bg-light p-20 brad-30 box-shadow shadow-lighter cursor-pointer"
																onClick={() => this.setState({ directToPhoneApp: !this.state.directToPhoneApp })}>
																<span className="styled-checkbox checkbox-light pull-left w-100 mb-40 checkbox-lg">
																	<Form.Check inline
																		checked={this.state.directToPhoneApp}
																		onChange={() => this.setState({ directToPhoneApp: !this.state.directToPhoneApp })} />
																</span>
																<p className="lead text-500 mb-10 mt-10">Phone App</p>
																<p className="text-sm">Answer unlimited calls from
																	anywhere in the world</p>
															</div>
														</div>
														<div className="m-10 text-center">
															<div className="bg-light p-20 brad-30 box-shadow shadow-lighter cursor-pointer"
																onClick={() => this.setState({ directToSoftphone: !this.state.directToSoftphone })}>
																<span className="styled-checkbox checkbox-light pull-left w-100 mb-40 checkbox-lg">
																	<Form.Check inline
																		checked={this.state.directToSoftphone}
																		onChange={() => this.setState({ directToSoftphone: !this.state.directToSoftphone })} />
																</span>
																<p className="lead text-500 mb-10 mt-10">Free Softphone</p>
																<p className="text-sm">Answer calls for free on your
																	PC and/or Laptop</p>
															</div>
														</div>
														{(!this.state.basket.isOutbound || this.state.basket.package.umr === true) &&
															<>
																<div className="m-10 text-center">
																	<div className="bg-light p-20 brad-30 box-shadow shadow-lighter cursor-pointer"
																		onClick={() => this.setState({ directToMobile: !this.state.directToMobile })}>
																		<span className="styled-checkbox checkbox-light pull-left w-100 mb-40 checkbox-lg">
																			<Form.Check inline
																				checked={this.state.directToMobile}
																				onChange={() => this.setState({ directToMobile: !this.state.directToMobile })} />
																		</span>
																		<p className="lead text-500 mb-10 mt-10">Mobile Phone</p>
																		<p className="text-sm">Answer calls on your existing mobile phone</p>
																	</div>
																</div>
																<div className="m-10 text-center">
																	<div className="bg-light p-20 brad-30 box-shadow shadow-lighter cursor-pointer"
																		onClick={() => this.setState({ directToLandline: !this.state.directToLandline })}>
																		<span className="styled-checkbox checkbox-light pull-left w-100 mb-40 checkbox-lg">
																			<Form.Check inline
																				checked={this.state.directToLandline}
																				onChange={() => this.setState({ directToLandline: !this.state.directToLandline })} />
																		</span>
																		<p className="lead text-500 mb-10 mt-10">Landline Phone</p>
																		<p className="text-sm">Answer calls on your existing landline phone</p>
																	</div>
																</div>
															</>
														}
													</div>

													<div className="row mt-50">
														<h2 className="text-500 text-center text-xl">Answer calls on a desk phone</h2>
														<h2 className="text-center text-dullest text-lg text-400 mb-30">
															Choose from our range of plug-and-play, pre-configured, VoIP Phones
														</h2>

														<PurchasePhones
															displayInline={true}
															handleUpdateHardware={(hardware) => handleHandsetUpdated(hardware)}
															currentBasket={this.state.basket}
															showEditDevices={false}
															handleCloseDevicesModal={() => setState({
															  ...state,
															  showEditDevices: false
															})}
															isSignup={false}
															hideFree={true}
															purchaseUrl="/api/Purchase/PurchasePhones"
															displayInstalments={true}
															hideAccessories={true}
														/>
													</div>

												</div>
												<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
													{this.state.isPurchasingCredit
													  ? <LoadingNotification loadingText={'Please wait...'} isLoading={true} width={40} height={40} />
													  :														<Button variant="cta" size="lg" className="btn-mobile-block" onClick={e => this.handleContinueWithDeviceSelection()} disabled={!this.state.directToLandline && !this.state.directToMobile && !this.state.directToSoftphone && !this.state.directToPhoneApp}>
															Continue with selection <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
														</Button>
													}
												</div>
											</Tab>

											<Tab tabClassName="hidden" eventKey={3} title="Welcome">
												<div className="row">
													<div className="col-md-12 text-center pb-40">
														<h2 className="text-500 text-center text-xl">
															Great, thanks {this.state.userFirstName}
														</h2>
														<h2 className="text-center text-dullest text-md text-400 mb-30">
															We've sent you a link to download our free softphone & phone app.
														</h2>
													</div>

													<div className="col-md-12 text-center clearfix">
														<div className="bg-light p-60 pt-30 pb-30 brad-30 box-shadow shadow-lighter">
															<p className="text-md mb-30">
																We've sent you an Email & SMS containing some links to download our free PC/Laptop softphone, aswell as our Phone App.
																<br /><br />
																If, for any reason, you don&apos;t receive these links, please use the links below to download.
															</p>

															{this.state.directToPhoneApp &&
																<>
																	<p className="text-md text-500">Download our iOS or Android App</p>
																	<p className="text-400 text-md pb-30 text-center text-sm-center">
																		<a target="_blank" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '100%', width: '175px' }} className="m-5" /></a>
																		<a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '100%', width: '175px' }} className="m-5" /></a>
																	</p>

																</>
															}

															{this.state.directToSoftphone &&
																<>
																	<p className="text-md text-500">Download our softphone for PC &amp; Laptop</p>
																	<p className="text-400 text-md pb-30 text-center text-sm-center">
																		<a target="_blank" href="https://www.switchboardfree.co.uk/files/switchboardFREE.zip" className="text-cta underline" rel="noreferrer"><img src="/images/microsoft.jpg" alt="Windows Softphone" style={{ maxWidth: '100%', width: '175px' }} className="m-5" /></a>
																		<a target="_blank" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" className="text-cta underline" rel="noreferrer"><img src="/images/mac.jpg" alt="Mac Softphone" style={{ maxWidth: '100%', width: '175px' }} className="m-5" /></a>
																	</p>

																</>
															}

															<p className="text-xs pt-20 block">
																Not got your email? I&apos;s worth checking your junk/spam folders.<br />
																We'd also recommend adding <span className="text-info text-500">support@switchboardfree.co.uk</span> to your safe senders list.
															</p>
														</div>
													</div>
												</div>
												<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
													{this.state.isPurchasingCredit
													  ? <LoadingNotification loadingText={'Please wait...'} isLoading={true} width={40} height={40} />
													  :														<Button variant="cta" size="lg" className="btn-mobile-block" onClick={e => this.handleStepSelect(4)}>
															Continue to introduction <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
														</Button>
													}
												</div>
											</Tab>

											<Tab tabClassName="hidden" eventKey={4} title="Voice &amp; Intro">
												<div className="row">
													<div className="col-md-12 text-center pb-40">
														<h2 className="text-500 text-center text-xl">
															Set Your Introduction
														</h2>
														<h2 className="text-center text-dullest text-md text-400 mb-30">
															Greet your customers with a recorded introduction, departmental prompt or an out-of-hours message that reflects your business opening hours and contact information.
														</h2>
													</div>

													<div className="col-md-12">
														<div className="brad-30 box-shadow shadow-lighter bg-light p-60 pt-30 pb-30">
															<div className="row">
																<div className="col-sm-6">
																	<h5 className="text-400 text-xs-500">Prompt Voice</h5>
																	<p className="pb-20 text-sm">Select the voice you would like to use for your number. All prompts your callers hear will be played in your chosen voice.</p>
																	<p className="text-sm">Choose one of the below options to make your company stand out from the rest.</p>
																</div>
																<div className="col-sm-6 mt-20">
																	<Form.Group size="lg" className="mb-10">
																		<Select
																			className="form-control select-menu mb-0"
																			classNamePrefix="custom"
																			id="system-voice-select"
																			options={this.state.voiceOptions}
																			simpleValue
																			name="selected-voice-prompt"
																			value={this.state.wizard.wizSelectedVoice}
																			onChange={e => this.handleWizVoiceChange(e.value)}
																			searchable={false}
																			clearable={false}
																			isLoading={this.state.isLoadingVoiceOptions}
																		/>
																	</Form.Group>
																	<p>
																		<Button variant="link" className="underline text-secondary" onClick={() => this.setState({ showVoiceSamples: true })}>
																			Not sure? Check out our examples
																		</Button>
																	</p>
																</div>
															</div>
														</div>

														<div className="brad-30 box-shadow shadow-lighter bg-light p-60 pt-30 pb-30 mt-30">
															<div className="row">
																<div className="col-sm-6">
																	<h5 className="text-400 text-xs-500">Caller Introduction</h5>
																	<p className="pb-20 text-sm">The introduction is the first thing your callers will hear when calling your number during your open hours.</p>
																</div>
																<div className="col-sm-6 mt-20">
																	<Select
																		className="form-control select-menu mb-20"
																		classNamePrefix="custom"
																		id="wizIntroSelectedPrompt"
																		name="wizIntroSelectedPrompt"
																		onChange={e => this.handleWizIntroChange(e.value)}
																		options={this.state.introPrompts}
																		simpleValue
																		value={this.state.wizard.wizIntroSelectedPrompt}
																		searchable={true}
																		clearable={false}
																		isLoading={this.state.isLoadingIntroPrompts}
																	/>
																	<PromptAudioPlayer noMargin={true} promptId={this.state.wizard.wizIntroSelectedPrompt !== undefined ? this.state.wizard.wizIntroSelectedPrompt.value : undefined} promptType={PromptTypes.standardIntro} voice={this.state.wizard.wizSelectedVoice !== undefined ? this.state.wizard.wizSelectedVoice.value : 2} />
																</div>
															</div>
														</div>

													</div>

													<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
														<Button variant="cta" size="lg" className="btn-mobile-block" onClick={e => this.handleStepSelect(5)}>
															Continue to redirects <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
														</Button>
													</div>
													{this.state.errors.wizIntroSelectedPrompt && <ul>
														<li>{this.state.errors.wizIntroSelectedPrompt}</li>
													</ul>}
												</div>
											</Tab>
											<Tab tabClassName="hidden" eventKey={5} title={'Redirect Numbers'}>
												<div className="row">

													{/* TEAMS */}
													{!this.state.showRedirects && config && config.sipUsers && config.sipUsers.length > 1 &&
														<div className="col-md-12 text-center">
															<h3 className="text-500 mt-0">Your Teams</h3>
															<p>
																Redirect your calls to as many as {RedirectLimit} different redirect numbers or create a ‘Team’ from the drop-down menu.<br />
																Your redirects can ring any UK mobile or landline number or a SwitchboardFREE User (which will ring on your SwitchboardFREE Phone App, VoIP Phone or PC/Laptop, using a Softphone).
															</p>
															<p>
																<em>Note: A ‘Team’ will call all numbers in the group at the same time. A great feature for teams and to reduce hold times.</em>
															</p>

															<div className="text-400 bg-light brad-30 box-shadow shadow-lighter p-20 mt-30">
																<TeamManagement fetchGroups={this.fetchRingGroups()} isWizard={true} />
															</div>
														</div>
													}

													{/* REDIRECTS */}
													{this.state.showRedirects === true &&
														<>
															<div className="col-md-12 text-center">
																<h2 className="text-500 text-center text-xl">
																	Call Redirect Numbers
																</h2>
															<h2 className="text-center text-dullest text-md text-400 mb-30">
																Enter a SwitchboardFREE user, team or a landline or mobile number where you would like to receive your phone calls. Calls are redirected to numbers in the order they are entered below, when using a team all numbers and/or users will ring at once.
																</h2>

																<div className="row redirects-section mt-30">

																	{(this.state.wizard && config && config.redirects && config.redirects.length > 0) && config.redirects.filter(f => !f.deleted).map((red, i) =>
																		<div className={`col-xs-4 col-xxs-12 mb-30 text-center  ${validation && validation.redirects && validation.redirects.length >= i && validation.redirects[i] && validation.redirects[i].valid === validationStates.INVALID ? 'has-error' : ''}`}>
																			<div className="bg-light brad-30 box-shadow shadow-lighter pl-30 pr-30 pb-30 pt-20 pl-sm-15 pr-sm-15">
																				<RedirectNumber redirectCount={config.redirects.length} isWizard={true} handleEditUsers={() => this.handleEditUsers()} number={red} redirectType={red.redirectType} position={red.order} is084={this.state.number && this.state.number.number.startsWith('084')} hasUmr={this.props.services.hasUmr} notifier={this.props.notify}
																					onValidate={redirect => this.props.validateRedirect({
																					  redirect,
																					  isMultiDept: false,
																					  position: red.order,
																					  redirectCount: config.redirects.length,
																					  redirectType: red.redirectType,
																					  wizard: true
																					})}
																					onValidateUserOrGroup={item => this.props.validateRedirect({
																					  isMultiDept: false,
																					  position: red.order,
																					  redirectCount: config.redirects.length,
																					  redirectType: red.redirectType,
																					  itemId: red.redirectDetail
																					})}
																					onRemoveRedirect={() => this.props.removeRedirect({
																					  redirect: red,
																					  queue: this.state.number.number,
																					  notifier: this.props.notify,
																					  wizard: true
																					})} />

																				<OverlayTrigger
																					trigger={['hover', 'focus']}
																					placement="bottom"
																					overlay={redirectsPopover}
																				>
																					<label>Ring for <span className="icon icon-info2 text-sm"></span></label>
																				</OverlayTrigger>
																				<RedirectSecondsSelect margin={validation && validation.redirects && validation.redirects.length >= i && validation.redirects[i] && validation.redirects[i].valid === validationStates.INVALID ? 0 : 10} seconds={isObject(red.dialDuration) ? red.dialDuration : { value: red.dialDuration, label: red.dialDuration + ' Seconds' }} position={red.order} />

																				{validation && validation.redirects && validation.redirects.length >= (red.order - 1) && validation.redirects[(red.order - 1)] && validation.redirects[(red.order - 1)].valid === validationStates.INVALID &&
																					<span className="help-block text-danger text-sm text-400">
																						Please enter a valid redirect number.
																					</span>
																				}
																			</div>
																		</div>
																	)}

																	{config && ((config.redirects && config.redirects.filter(f => !f.deleted).length < RedirectLimit) || !config.redirects) &&
																		<div className="col-xs-4 col-xxs-12 text-center mb-30">
																			<div className="bg-light brad-30 box-shadow shadow-lighter pl-30 pr-30 pb-30 pt-50 pl-sm-15 pr-sm-15 pt-sm 20 pt-xs-10">
																				<p className="text-500">
																					<SvgRender icon="config-changes" svgWidth="60px" svgClassName="mt-20 mb-20" /><br />
																					{config.redirects ? 'Add another number' : 'Add your first redirect'}
																				</p>
																				<Button variant="cta" size="lg" className="mb-30" onClick={() => this.props.handleAddRedirect()}>
																					Add Redirect #{config.redirects ? config.redirects.filter(f => f.deleted !== true).length + 1 : 1}
																				</Button>
																			</div>
																		</div>
																	}
																</div>

																<Modal className="modal-md" show={this.state.showUserSettings} onHide={() => this.setState({ showUserSettings: false })}>
																	<Modal.Body>
																		<VoipUsers basicView={true} />
																	</Modal.Body>
																	<Modal.Footer className="text-center bt-0 p-0 mt-30">
																		<Button className="btn-block brad-0 pt-20 pb-20 w-100" onClick={() => this.setState({ showUserSettings: false })}>Close</Button>
																	</Modal.Footer>
																</Modal>

															</div>
														</>
													}
												</div>
												<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
													{!this.state.showRedirects
													  ? <Button variant="cta" size="lg" className="btn-mobile-block" onClick={e => this.setState({ showRedirects: true })}>
															Continue <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
														</Button>
													  :														<Button variant="cta" size="lg" className="btn-mobile-block" onClick={e => this.handleCheckRedirects(config.redirects)} disabled={(config && config.redirect && config.redirects.length === 0) || (validation && validation.redirects && validation.redirects.some(s => s.valid === validationStates.INVALID)) || !config.redirects}>
															Continue to opening hours <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
														</Button>
													}
													<p className="mt-10 mb-0">
														<Button variant="link" size="sm" className="text-dullest" onClick={e => this.handleStepSelect(4)}>
															Go back
														</Button>
													</p>
												</div>
											</Tab>
											<Tab tabClassName="hidden" eventKey={6} title="Opening Hours">
												<div className="row">
													<div className="col-md-12 text-center pb-40">
														<h2 className="text-500 text-center text-xl">
															Opening Hours
														</h2>
														<h2 className="text-center text-dullest text-md text-400 mb-30">
															All calls outside of these times will result in the caller being payed a message informing them that you are closed and (optionally) given the opportunity to leave a voicemail. You will also (optionally) receive an email to tell you that you have missed a call.
														</h2>
													</div>

													<div className="col-md-12">
														<div className="brad-30 box-shadow shadow-lighter bg-light p-60 pt-30 pb-30 p-xs-30">
															<div className="pb-20 text-center row">
																<p className="text-500 mt-10 mb-20">Use the below quick selections buttons or enter your opening hours manually</p>
																<div className="col-md-12">
																	<Button variant={`${this.state.open247 ? 'secondary' : 'outline'}`} className={`m-5 btn-rounded btn-xxs-block ${this.state.open247 ? 'text-500' : 'text-dullest btn-default text-400'}`} onClick={e => this.handleOpenAllHoursClicked(4)}>Open 24/7</Button>
																	<Button variant={`${this.state.openMonFri ? 'secondary' : 'outline'}`} className={`m-5 btn-rounded btn-xxs-block ${this.state.openMonFri ? 'text-500' : 'text-dullest btn-default text-400'}`} onClick={e => this.handleOpenWeekdaysWorkingHoursClicked(4)}>9am - 5pm (Mon - Fri)</Button>
																	<Button variant={`${this.state.open7days ? 'secondary' : 'outline'}`} className={`m-5 btn-rounded btn-xxs-block ${this.state.open7days ? 'text-500' : 'text-dullest btn-default text-400'}`} onClick={e => this.handleOpenAllWeekWorkingHoursClicked(4)}>9am - 5pm (Mon - Sun)</Button>
																	<Button variant={`${this.state.openBespoke ? 'secondary' : 'outline'}`} className={`m-5 btn-rounded btn-xxs-block ${this.state.openBespoke ? 'text-500' : 'text-dullest btn-default text-400'}`} onClick={() => this.setState({ openBespoke: true, open7days: false, openMonFri: false, open247: false })}>Set Bespoke</Button>
																</div>
															</div>
															{this.state.openBespoke &&
																<>
																	<div className="row">
																		<div className="col-xs-12">
																			<div className="flex-grid opening-hours text-center">
																				<div className="col p-10">
																					<h6 className="text-500 pb-20">Monday</h6>

																					<label>Opens at</label>
																					<TimePicker name='monOpen' focusOnOpen={true}
																						value={this.state.wizard.monOpen} onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    monOpen: e
																						  }
																						})} className="form-control" showSecond={false} />

																					<label className="pt-20">Closes at</label>
																					<TimePicker name='monClosed' focusOnOpen={true}
																						value={this.state.wizard.monClosed}
																						onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    monClosed: e
																						  }
																						})} className="form-control" showSecond={false} />
																				</div>
																				<div className="col p-10">
																					<h6 className=" text-500 pb-20">Tuesday</h6>
																					<label>Opens at</label>
																					<TimePicker name='tueOpen' focusOnOpen={true}
																						value={this.state.wizard.tueOpen} onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    tueOpen: e
																						  }
																						})} className="form-control" showSecond={false} />

																					<label className="pt-20">Closes at</label>
																					<TimePicker name='tueClosed' focusOnOpen={true}
																						value={this.state.wizard.tueClosed}
																						onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    tueClosed: e
																						  }
																						})} className="form-control" showSecond={false} />
																				</div>
																				<div className="col p-10">
																					<h6 className=" text-500 pb-20">Wednesday</h6>

																					<label>Opens at</label>
																					<TimePicker name='wedOpen' focusOnOpen={true}
																						value={this.state.wizard.wedOpen} onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    wedOpen: e
																						  }
																						})} className="form-control" showSecond={false} />

																					<label className="pt-20">Closes at</label>
																					<TimePicker name='wedClosed' focusOnOpen={true}
																						value={this.state.wizard.wedClosed}
																						onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    wedClosed: e
																						  }
																						})} className="form-control" showSecond={false} />
																				</div>
																				<div className="col p-10">
																					<h6 className=" text-500 pb-20">Thursday</h6>

																					<label>Opens at</label>
																					<TimePicker name='thuOpen' focusOnOpen={true}
																						value={this.state.wizard.thuOpen} onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    thuOpen: e
																						  }
																						})} className="form-control" showSecond={false} />

																					<label className="pt-20">Closes at</label>
																					<TimePicker name='thuClosed' focusOnOpen={true}
																						value={this.state.wizard.thuClosed}
																						onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    thuClosed: e
																						  }
																						})} className="form-control" showSecond={false} />
																				</div>
																				<div className="col p-10">
																					<h6 className=" text-500 pb-20">Friday</h6>

																					<label>Opens at</label>
																					<TimePicker name='friOpen' focusOnOpen={true}
																						value={this.state.wizard.friOpen} onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    friOpen: e
																						  }
																						})} className="form-control" showSecond={false} />

																					<label className="pt-20">Closes at</label>
																					<TimePicker name='friClosed' focusOnOpen={true}
																						value={this.state.wizard.friClosed}
																						onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    friClosed: e
																						  }
																						})} className="form-control" showSecond={false} />
																				</div>
																				<div className="col p-10">
																					<h6 className=" text-500 pb-20">Saturday</h6>

																					<label>Opens at</label>
																					<TimePicker name='satOpen' focusOnOpen={true}
																						value={this.state.wizard.satOpen} onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    satOpen: e
																						  }
																						})} className="form-control" showSecond={false} />

																					<label className="pt-20">Closes at</label>
																					<TimePicker name='satClosed' focusOnOpen={true}
																						value={this.state.wizard.satClosed}
																						onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    satClosed: e
																						  }
																						})} className="form-control" showSecond={false} />
																				</div>
																				<div className="col p-10">
																					<h6 className="text-500 pb-20">Sunday</h6>

																					<label>Opens at</label>
																					<TimePicker name='sunOpen' focusOnOpen={true}
																						value={this.state.wizard.sunOpen} onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    sunOpen: e
																						  }
																						})} className="form-control" showSecond={false} />

																					<label className="pt-20">Closes at</label>
																					<TimePicker name='sunClosed' focusOnOpen={true}
																						value={this.state.wizard.sunClosed}
																						onChange={e => this.setState({
																						  wizard: {
																						    ...this.state.wizard,
																						    sunClosed: e
																						  }
																						})} className="form-control" showSecond={false} />
																				</div>
																			</div>
																		</div>
																	</div>
																</>
															}

															<div className="row">
																<div className="col-xs-12 text-center">
																	<hr className="mt-20 mb-20" />
																	<p>Automatically close lines on Bank Holidays?</p>
																	<div className={`mr-10 mt-5 d-inline-block text-500 ${this.state.wizard.closedBankHol ? 'text-dark' : 'text-dullest'}`}>
																		Yes
																	</div>
																	<Toggle
																		on=""
																		off=""
																		size="sm"
																		offstyle="default"
																		onstyle="success"
																		width="null"
																		height="null"
																		className="toggle-sm toggle-secondary"
																		active={!this.state.wizard.closedBankHol}
																		onClick={e => this.setState({ wizard: { ...this.state.wizard, closedBankHol: !e } })}
																	/>
																	<div className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${this.state.wizard.closedBankHol ? 'text-dullest' : 'text-dark'}`}>
																		No
																	</div>
																</div>

															</div>
														</div>
													</div>

													<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
														{hasOpenCloseErrors &&
															<ul className="alert alert-danger list-unstyled">
																{openCloseErrors.monClosed && <li>{openCloseErrors.monClosed}</li>}
																{openCloseErrors.tueClosed && <li>{openCloseErrors.tueClosed}</li>}
																{openCloseErrors.wedClosed && <li>{openCloseErrors.wedClosed}</li>}
																{openCloseErrors.thuClosed && <li>{openCloseErrors.thuClosed}</li>}
																{openCloseErrors.friClosed && <li>{openCloseErrors.friClosed}</li>}
																{openCloseErrors.satClosed && <li>{openCloseErrors.satClosed}</li>}
																{openCloseErrors.sunClosed && <li>{openCloseErrors.sunClosed}</li>}
															</ul>}
														<Button variant="cta" size="lg" className="btn-mobile-block" onClick={e => this.handleStepSelect(7)}>
															Continue to on hold-music <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
														</Button>
														<p className="mt-10 mb-0">
															<Button variant="link" size="sm" className="text-dullest" onClick={e => this.handleStepSelect(5)}>
																Go back
															</Button>
														</p>
													</div>
												</div>
											</Tab>
											<Tab tabClassName="hidden" eventKey={7} title="Music on-hold">
												<div className="row">
													<div className="col-md-12 text-center pb-40">
														<h2 className="text-500 text-center text-xl">
															On Hold-Music
														</h2>
														<h2 className="text-center text-dullest text-md text-400 mb-30">
															The caller will hear clips of music (usually 30-60 seconds long) between which they will hear various messages such as their position in the call queue (if enabled) or how they can leave a voicemail (if enabled).
														</h2>
													</div>

													<div className="col-md-12">
														<div className="brad-30 box-shadow shadow-lighter bg-light p-60 pt-30 pb-30 p-xs-30">
															<div className="row">
																<div className="col-xs-12">
																	<form className="form-inline col-md-10 mx-auto music-on-hold text-center">
																		<div className="form-group">
																			<label className="pr-30">Choose playlist:</label>
																			<Select
																				id="mohSelect"
																				className="form-control select-menu mb-20 mx-auto"
																				classNamePrefix="custom"
																				onChange={e => this.handleMohChange(e)}
																				options={this.state.mohTracks}
																				simpleValue
																				name="selectedMoh"
																				value={this.state.wizard.wizSelectedMoh}
																				searchable={false}
																				clearable={false}
																				isLoading={this.state.isLoadingMoh}
																			/>
																			<PromptAudioPlayer noMargin={true} promptId={config && config.musicOnHoldPrompt && config.musicOnHoldPrompt.selectedMohTrackId ? config.musicOnHoldPrompt.selectedMohTrackId : undefined} promptType={PromptTypes.holdMusicTrack} />
																		</div>
																	</form>
																</div>
															</div>
														</div>

													</div>

													<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
														<Button variant="cta" size="lg" className="btn-mobile-block" onClick={e => this.handleStepSelect(8)}>
															Continue to call alerts <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
														</Button>
														<p className="mt-10 mb-0">
															<Button variant="link" size="sm" className="text-dullest" onClick={e => this.handleStepSelect(6)}>
																Go back
															</Button>
														</p>
													</div>
												</div>
											</Tab>

											<Tab tabClassName="hidden" eventKey={8} title={(this.state.errors.missedCallEmail && this.state.wizard.missedCallEnabled) ? <span>Missed Call Alerts <span className="icon icon-warning2 text-danger"></span></span> : 'Missed Call Alerts'}>
												<div className="row">
													<div className="col-md-12 text-center pb-40">
														<h2 className="text-500 text-center text-xl">
															Missed Call Alerts
														</h2>
														<h2 className="text-center text-dullest text-md text-400 mb-30">
															Enter the email address which you would like missed call alerts sent to.
															Missed call alerts contain all the information you need to call the customer back, including their number and the amount of time they were on hold for. You can add multiple emails seperated with a semi-colon, for example: <i>john@example.com;mike@example.com</i>
														</h2>
													</div>

													<div className="col-md-12">
														<div className={`brad-30 box-shadow shadow-lighter bg-light p-60 pt-30 pb-30 ${this.state.wizard.missedCallEnabled ? 'brad-b-0' : ''}`}>
															<div className="row">
																<div className="text-center">
																	<div className="col-sm-6 text-center mx-auto">
																		<div className={`mr-10 mt-5 d-inline-block text-500 ${this.state.wizard.missedCallEnabled ? 'text-dark' : 'text-dullest'}`}>
																			Turn on alerts
																		</div>
																		<br className="d-block d-xs-none" />
																		<Toggle
																			on=""
																			off=""
																			size="sm"
																			offstyle="default"
																			onstyle="success"
																			width="null"
																			height="null"
																			className="toggle-sm toggle-secondary mt-xxs-10 mb-xxs-10"
																			active={!this.state.wizard.missedCallEnabled}
																			onClick={event => this.setState({
																			  wizard: {
																			    ...this.state.wizard,
																			    missedCallEnabled: !event
																			  }
																			})}
																		/>
																		<br className="d-block d-xs-none" />
																		<div className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${this.state.wizard.missedCallEnabled ? 'text-dullest' : 'text-dark'}`}>
																			No thanks
																		</div>
																	</div>
																</div>
															</div>
														</div>
														{this.state.wizard.missedCallEnabled &&
															<div className={`brad-30 box-shadow shadow-lighter bt-0 bg-light p-60 pt-30 pb-30 p-xs-30 ${this.state.wizard.missedCallEnabled ? 'brad-t-0' : ''}`}>
																<div className="row">
																	<div className="col-md-6 mx-auto pt-xs-30">
																		<label>Send missed call alerts emails to</label>
																		<input type="email" className="form-control"
																			value={this.state.wizard.missedCallEmail}
																			onChange={event => this.setState({
																			  wizard: {
																			    ...this.state.wizard,
																			    missedCallEmail: event.target.value
																			  }
																			})} />
																		{this.state.errors.missedCallEmail && this.state.wizard.missedCallEnabled &&
																			<div className="p-10 pl-15">
																				<p className="text-danger text-400 text-sm"><span className="icon icon-notification2"></span> {this.state.errors.missedCallEmail}</p>
																			</div>}
																	</div>
																</div>
															</div>
														}
													</div>

													<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
														{this.state.isSaving
														  ? <LoadingNotification loadingText={'Please wait...'} isLoading={true} width={60} height={60} />
														  :															<>
																<Button variant="cta" size="lg" className="btn-mobile-block" onClick={event => this.handleCompleteWizard(config)}>
																	Complete setup <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
																</Button>
																<p className="mt-10 mb-0">
																	<Button variant="link" size="sm" className="text-dullest" onClick={e => this.handleStepSelect(7)}>
																		Go back
																	</Button>
																</p>
															</>
														}
													</div>
												</div>
											</Tab>
											<Tab tabClassName="hidden" eventKey={9} title="Finish">
												<div className="row">
													<div className="col-xs-12 text-center pb-40">
														<h2 className="text-500 text-center text-xl">
															All done{this.state.userFirstName !== undefined && <span>, {this.state.userFirstName}  </span>}
														</h2>
														<h2 className="text-center text-dullest text-md text-400 mb-30">
															Your settings for <span className="text-500">{simplePhoneNumberFormat(this.state.number.number)}</span> have been saved and your new number is now ready to use.<br />
															We recommend giving your number a quick call to make sure i&apos;s all setup how you want it.
														</h2>
													</div>

													<div className="col-xs-12 text-center">
														<div className="bg-light p-60 pt-30 pb-30 mb-50 brad-30 box-shadow shadow-lighter p-xs-60">
															<p className="lead text-500 text-cta text-sm-center mt-sm-15">Download Our SwitchboardFREE Phone App</p>
															<p>It is essential that you <strong>download our SwitchboardFREE Phone App</strong> so you can make &amp; receive calls.</p>
															<p className="text-400 text-md pb-30 text-center text-sm-center">
																<a target="_blank" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '100%', width: '175px' }} className="m-5" /></a>
																<a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '100%', width: '175px' }} className="m-5" /></a>
																<a href="https://www.switchboardfree.co.uk/files/switchboardFREE.zip" className="text-cta underline"><img src="/images/microsoft.jpg" alt="Windows Softphone" style={{ maxWidth: '100%', width: '175px' }} className="m-5" /></a>
																<a href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" className="text-cta underline"><img src="/images/mac.jpg" alt="Mac Softphone" style={{ maxWidth: '100%', width: '175px' }} className="m-5" /></a>
															</p>
															<p className="text-400">Please check your emails for instructions on setting up your VoIP system, or visit our <Link className="underlined" to="/setup">Getting Started Guide</Link>.</p>
														</div>
													</div>

													{this.state.basket && this.state.basket.numbers.length > 1 &&
														<div className="col-xs-12">
															<div className="bg-light p-60 pt-30 pb-30 mb-50 brad-30 box-shadow shadow-lighter text-center">
																<p className="lead text-500 text-cta text-sm-center mt-sm-15">We've copied these settings to your other {this.state.basket.numbers.length - 1} numbers</p>
																<p>Want to change these settings for one, or more numbers? Visit your numbers dashboard</p>
															</div>
														</div>
													}

													{/* UPSELLS */}

													<div className="col-xs-12">
														<p className="lead text-center text-500">
															Before you go, we've got some great one-time deals for you.
														</p>

														<div className="row">
															<div className="col-xs-12">
																{(!this.state.hideCallRecordingUpsell && !this.props.services.hasCallRecordingAll) &&
																	<div className="bg-darker p-60 pt-30 pb-30 brad-30 box-shadow shadow-lighter text-start">
																		<div className="row">
																			<div className="col-sm-1">
																				<SvgRender icon="call-recording" svgWidth="75px" svgClassName="mt-10 ml-neg-20" fillColor="#fff" />
																			</div>
																			<div className="col-sm-6">
																				<h6 className="text-500">Add Call Recording <i className="text-secondary text-xs"><span className="text-cta">First month FREE.</span></i></h6>
																				<p className="text-sm">
																					Save all your calls. Capture every detail so you can train your staff, improve compliance &amp; easily resolve customer conflicts.
																					<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover title="Call Recording">
																						<p>Call recording is highly recommended for:</p>
																						<ul className="fancy cta pl-25 pt-20 pb-20 text-300">
																							<li>Capturing missing details</li>
																							<li>Resolving conflicts</li>
																							<li>Training new staff</li>
																							<li>Improving customers experience</li>
																						</ul>
																						<p>Add call recording to your account today.<br />
																							Get your first month FREE, then just &pound;{(callRecordPayment.repeatPayment).toFixed(2)} per month</p>
																						<p className="pt-10 text-300">Switch off at any time.</p>
																					</Popover>}>
																						<span className="text-400 cta-hover text-secondary cursor-pointer ml-10">More info</span>
																					</OverlayTrigger>
																				</p>
																			</div>
																			<div className="col-sm-5 text-center mt-xs-30 pt-15">
																				<div className="bg-transparent p-20 brad-10">
																					<div className={`mr-10 mt-5 d-inline-block text-500 ${this.state.wizard.buyCallRecording ? 'text-light' : 'text-dullest'}`}>
																						Yes
																					</div>
																					<Toggle
																						on=""
																						off=""
																						size="sm"
																						offstyle="default"
																						onstyle="success"
																						width="null"
																						height="null"
																						className="toggle-sm toggle-secondary"
																						active={!this.state.wizard.buyCallRecording}
																						onClick={e => this.setState({ wizard: { ...this.state.wizard, buyCallRecording: !e } })}
																					/>
																					<div className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${this.state.wizard.buyCallRecording ? 'text-dullest' : 'text-light'}`}>
																						No
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																}

																{!this.props.services.hasCallTranscription &&
																	<div className="bg-darker p-60 pt-30 pb-30 mt-30 brad-30 box-shadow shadow-lighter p-xs-30">
																		<div className="row">
																			<div className="col-sm-1">
																				<SvgRender icon="voicemail-transcription" svgWidth="60px" svgClassName="mt-10 ml-neg-20" fillColor="#fff" />
																			</div>
																			<div className="col-sm-6">
																				<h6 className="text-500">Add Call Transcription <i className="text-secondary text-xs"><span className="text-cta">NEW.</span></i></h6>
																				<p className="text-sm">
																				Automatically transcribe your call recordings into readable, searchable text. Just 5p per min.

																					<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover title="Call Transcription">
																					<p className="text-400">
																						Automatically transcribe your call recordings into a readable chat-style format. Not only that, but transcriptions are searchable, too!
																					</p>
																					<CallTranscriptionBenefitsList />
																					</Popover>}>
																						<span className="text-400 cta-hover text-secondary cursor-pointer ml-10">More info</span>
																					</OverlayTrigger>
																				</p>
																			</div>
																			<div className="col-sm-5 text-center mt-xs-30 pt-15">
																				<div className="bg-transparent p-20 brad-10">
																					<div className={`mr-10 mt-5 d-inline-block text-500 ${this.state.wizard.enableTranscription ? 'text-light' : 'text-dullest'}`}>
																						Yes
																					</div>
																					<Toggle
																						on=""
																						off=""
																						size="sm"
																						offstyle="default"
																						onstyle="success"
																						width="null"
																						height="null"
																						className="toggle-sm toggle-secondary"
																					active={!this.state.wizard.enableTranscription}
																					onClick={e => this.setState({ wizard: { ...this.state.wizard, enableTranscription: !e } })}
																					/>
																				<div className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${this.state.wizard.enableTranscription ? 'text-dullest' : 'text-light'}`}>
																						No
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																}

																{!this.state.hideCustomUpsell &&
																	<div className="bg-darker p-60 pt-30 pb-30 mt-30 brad-30 box-shadow shadow-lighter p-xs-30">
																		<div className="row">
																			<div className="col-sm-1 pl-0 text-xs-center">
																				<SvgRender icon="custom-prompts" svgWidth="40px" svgClassName="mt-15 ml-10" fillColor="#fff" />
																			</div>
																			<div className="col-sm-6">
																				<h6 className="text-500">Add Custom Prompts <i className="text-secondary text-xs">Just &pound;{InhouseMinimumPromptPurchaseThreshold}. <span className="text-cta">Save &pound;50.</span></i></h6>
																				<p className="text-sm">
																					Greet your customers with a professionally recorded introduction, departmental prompt or a custom out-of-hours message that reflects your business.
																					<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover title="Custom prompts information">
																						<p>100 words is typically enough to cover most standard introductions and departments greetings. Our experienced team will assist in writing your script to ensure your prompts are professional and sound great.</p>
																						<p>Custom prompts are charged at &pound;{InhouseMinimumPromptPurchaseThreshold} for 100 words. Any additional words will be charged at {InhousePricePerWord}p per word.</p>
																						<p>Add custom prompts to your account now, for a one-time exclusive price of just &pound;{InhouseMinimumPromptPurchaseThreshold}.</p>
																					</Popover>}>
																						<span className="text-400 cta-hover text-secondary cursor-pointer ml-10">More info</span>
																					</OverlayTrigger>
																				</p>
																			</div>
																			<div className="col-sm-5 text-center mt-xs-30 pt-15">
																				<div className="bg-transparent brad-10 p-20">
																					<div className={`mr-10 mt-5 d-inline-block text-500 ${this.state.wizard.buyCustomPrompts ? 'text-light' : 'text-dullest'}`}>
																						Yes
																					</div>
																					<Toggle
																						on=""
																						off=""
																						size="sm"
																						offstyle="default"
																						onstyle="success"
																						width="null"
																						height="null"
																						className="toggle-sm toggle-secondary"
																						active={!this.state.wizard.buyCustomPrompts}
																						onClick={e => this.setState({ wizard: { ...this.state.wizard, buyCustomPrompts: !e } })}
																					/>
																					<div className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${this.state.wizard.buyCustomPrompts ? 'text-dullest' : 'text-light'}`}>
																						No
																					</div>
																				</div>
																				{this.state.wizard.buyCustomPrompts === true &&
																					<p className="text-xs mt-10">
																						A member of our team will be in touch to assist with your script.
																					</p>
																				}
																			</div>
																		</div>
																	</div>
																}

																{(!this.state.hideCallBlockUpsell && !this.props.callFilteringEnabled) &&
																	<div className="bg-darker p-60 pt-30 pb-30 mt-30 brad-30 box-shadow shadow-lighter pl-xs-30">
																		<div className="row">
																			<div className="col-sm-1 pl-0 text-xs-center">
																				<SvgRender icon="call-blocking" svgWidth="60px" svgClassName="mt-10" fillColor="#fff" />
																			</div>
																			<div className="col-sm-6">
																				<h6 className="text-500">Call Blocking <i className="text-secondary text-xs">{WizardDiscounts.CallBlock > 0 && `${WizardDiscounts.CallBlock * 100}% Off!`} <span className="text-cta">Save &pound;{(callBlockPrice.repeatPayment / 2).toFixed(2)}.</span>  </i></h6>
																				<p className="text-sm">
																					Quickly &amp; easily block callers from calling your SwitchboardFREE number(s).

																					<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover title="Call Blocking">
																						<p className="text-400">Ideal for helping to stop any spam or nuisance calls you may receive.</p>
																						<p>Choose to block witheld or International callers, or add individual numbers to your block list!</p>
																						{WizardDiscounts.CallBlock > 0
																						  ? <p>Add today &amp; get an exclusive {WizardDiscounts.CallBlock * 100}% discount.<br /> Just £{(callBlockPrice.repeatPayment).toFixed(2)} per month.</p>
																						  :																							<p>Add today for just £{callBlockPrice.repeatPayment} per month.</p>
																						}
																					</Popover>}>
																						<span className="text-400 cta-hover text-secondary cursor-pointer ml-10">More info</span>
																					</OverlayTrigger>
																				</p>
																			</div>
																			<div className="col-sm-5 text-center mt-xs-30 pt-15">
																				<div className="bg-transparent p-20 brad-10">
																					<div className={`mr-10 mt-5 d-inline-block text-500 ${this.state.wizard.callBlocking ? 'text-light' : 'text-dullest'}`}>
																						Yes
																					</div>
																					<Toggle
																						on=""
																						off=""
																						size="sm"
																						offstyle="default"
																						onstyle="success"
																						width="null"
																						height="null"
																						className="toggle-sm toggle-secondary"
																						active={!this.state.wizard.callBlocking}
																						onClick={e => this.setState({ wizard: { ...this.state.wizard, callBlocking: !e } })}
																					/>
																					<div className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${this.state.wizard.callBlocking ? 'text-dullest' : 'text-light'}`}>
																						No
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																}

																<div className="bg-darker p-60 pt-30 pb-30 mt-30 brad-30 box-shadow shadow-lighter pl-xs-30">
																	<div className="row">
																		<div className="col-sm-1 pl-0 text-xs-center">
																			<SvgRender icon="call-answering" svgWidth="60px" svgClassName="mt-20" fillColor="#fff" />
																		</div>
																		<div className="col-sm-6">
																			<h6 className="text-500">Add Call Answering <i className="text-secondary text-xs">Premium</i></h6>
																			<p className="text-sm">
																				With Live Call Answering you’ll never miss a call again.<br />
																				A member of our team will answer all of your calls or just the ones you miss. Turn on/off at any time.

																				<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover title="Call Answering Service">
																					<p className="text-500">Live Call Answering Gives You:</p>
																					<ul className="fancy cta pl-25 pt-20 pb-20 text-400">
																						<li><span className="text-400">Your Very Own Team</span><br /><em>(Experienced, Dedicated Receptionists)</em></li>
																						<li className="mt-10"><span className="text-400">Instant Set Up</span><br /><em>(Activate In Seconds)</em></li>
																						<li className="mt-10"><span className="text-400">Versatile Options</span><br /><em>(Answer All Calls or Overflow Only)</em></li>
																						<li className="mt-10"><span className="text-400">Complete Flexibility</span><br /><em>(Turn On And Off At Will)</em></li>
																						<li className="mt-10"><span className="text-400">Zero Commitment</span><br /><em>(No Monthly Fees, Simply Pay As You Go)</em></li>
																					</ul>
																					<p>Add to your account today, just &pound;{CallAnsweringPricePerCall.toFixed(2)} per call.</p>
																					<p><em>No contracts. Turn on or off at any time.</em></p>
																				</Popover>}>
																					<span className="text-400 cta-hover text-secondary cursor-pointer ml-10">More info</span>
																				</OverlayTrigger>
																			</p>
																		</div>
																		<div className="col-sm-5 text-center mt-xs-30 pt-20">
																			<div className="bg-transparent p-20 brad-10">
																				<div className={`mr-10 mt-5 d-inline-block text-500 ${this.state.wizard.buyCallAnswering ? 'text-light' : 'text-dullest'}`}>
																					Yes
																				</div>
																				<Toggle
																					on=""
																					off=""
																					size="sm"
																					offstyle="default"
																					onstyle="success"
																					width="null"
																					height="null"
																					className="toggle-sm toggle-secondary"
																					active={!this.state.wizard.buyCallAnswering}
																					onClick={e => this.setState({ wizard: { ...this.state.wizard, buyCallAnswering: !e } })}
																				/>
																				<div className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${this.state.wizard.buyCallAnswering ? 'text-dullest' : 'text-light'}`}>
																					No
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="brad-30 box-shadow shadow-lighter bg-darker text-center mt-30 p-40 p-xxs-20">
																	<h6 className="text-500 pb-10 text-cta text-lg">Save {WizardDiscounts.HandsetDiscount * 100}% On Handsets!</h6>
																	<p>
																		<span className="text-500 text-cta">One-time deal.</span> Easily divert your calls to our business-grade VoIP handsets. Plug your phone into any location and instantly receive calls, without any need to update settings.
																	</p>
																	<p>Choose from our deskphone, or the cordless VoIP phone for ultimate flexibility.</p>
																	<p className="m-0 text-400 text-italic mb-20">
																		Buy today and save a massive 20% on every handset.
																	</p>
																	{this.state.isPurchasingPhones
																	  ? <LoadingNotification loadingText={'Please wait...'} isLoading={true} width={100} height={100} />
																	  :																	<div className="text-dark">

																		<PurchasePhones
																			handleHandsetUpdated={() => this.handleHandsetUpdated()}
																			handlePurchasePhones={e => this.handlePurchasePhones(e)}
																			handleSave={() => this.handleSaveEditDeliveryAddress()}
																			currentBasket={this.state.basket}
																			discountEligible={WizardDiscounts.HandsetDiscount}
																			hideFree={true}
																			editAddress={this.state.showEditDeliveryAddress && !this.state.isPurchaseInProgress}
																			addressButtonText="Save and Continue"
																			showEditDevices={this.state.showEditDevices}
																			handleCloseDevicesModal={() => this.setState({ showEditDevices: false })}
																			loggedIn={this.props.isLoggedIn}
																			clientId={this.props.clientId}
																			handleUpdateHardware={(e) => this.handleUpdateHardware(e)}
																			deliveryName={this.props.deliveryDetails.deliveryName ? this.props.deliveryDetails.deliveryName.value : ''}
																			deliveryAdd1={this.props.deliveryDetails.deliveryAdd1 ? this.props.deliveryDetails.deliveryAdd1.value : ''}
																			deliveryAdd2={this.props.deliveryDetails.deliveryAdd2 ? this.props.deliveryDetails.deliveryAdd2.value : ''}
																			deliveryTown={this.props.deliveryDetails.deliveryTown ? this.props.deliveryDetails.deliveryTown.value : ''}
																			deliveryCounty={this.props.deliveryDetails.deliveryCountry ? this.props.deliveryDetails.deliveryCountry.value : ''}
																			deliveryPcode={this.props.deliveryDetails.deliveryPcode ? this.props.deliveryDetails.deliveryPcode.value : ''}
																			deliveryNote={this.props.deliveryDetails.deliveryNote ? this.props.deliveryDetails.deliveryNote.value : ''}
																			handleDeliveryNameChange={(e) => this.props.handleDeliveryNameChange({ newValue: e })}
																			handleDeliveryAdd1Change={(e) => this.props.handleDeliveryAdd1Change({ newValue: e })}
																			handleDeliveryAdd2Change={(e) => this.props.handleDeliveryAdd2Change({ newValue: e })}
																			handleDeliveryTownChange={(e) => this.props.handleDeliveryTownChange({ newValue: e })}
																			handleDeliveryCountyChange={(e) => this.props.handleDeliveryCountyChange({ newValue: e })}
																			handleDeliveryPcodeChange={(e) => this.props.handleDeliveryPcodeChange({ newValue: e })}
																			handleDeliveryNoteChange={(e) => this.props.handleDeliveryNoteChange({ newValue: e })}
																			buttonText="Order now"
																			hideButton={this.state.showEditDeliveryAddress && !this.state.isPurchaseInProgress}
																			purchaseUrl="/api/Purchase/PurchasePhones"
																		/>
																		</div>
																	}

																	<div className="button-set">
																		<Button variant="lightest" size="lg" onClick={() => this.setState({ showEditDevices: true })}>
																			Buy Handsets
																		</Button>
																	</div>

																</div>
															</div>
														</div>
													</div>

													<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
														{this.state.showUpsellError &&
															<p className="text-danger text-400 text-center w-100">Something went wrong adding your products. Please try again, or <Link to="/contact">contact our UK-based support team</Link>.</p>
														}

														{this.state.isPurchaseInProgress
														  ? <LoadingNotification loadingText={'Please wait...'} isLoading={true} width={50} height={50} />
														  :															<Button variant="cta" size="lg" className="btn-mobile-block" onClick={() => this.handleContinueToDashboard(config)}>
																Continue to dashboard <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
															</Button>
														}
												</div>
												</div>
											</Tab>
										</Tabs>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Modal size="sm" show={this.state.showAddNewUser} onHide={() => this.setState({ showAddNewUser: false, isPurchaseInProgress: false })} >
						<Modal.Header closeButton>
							<Modal.Title>Add a new user to your account</Modal.Title>
						</Modal.Header>
						<Modal.Body className="p-40">
							{!this.state.isPurchaseInProgress &&
								<p className="text-center mb-30 text-md">Choose the perfect plan for your new user</p>
							}

							{this.state.isLoadingPlans || this.state.isPurchaseInProgress &&
								<LoadingNotification loadingText={'Loading plans...'} isLoading={true} width={60} height={60} />}

							{!this.state.isLoadingPlans && !this.state.isPurchaseInProgress && this.state.plans && this.state.plans.length > 0 && this.state.plans.map((plan, i) => (
								<div className="bg-dull p-20 mb-10 cursor-pointer zoom" onClick={() => this.setState({ selectedPlan: plan })}>
									<div className="row">
										<div className="col-md-1 col-sm-2">
											<span className="styled-checkbox checkbox-light float-start" style={{ margin: '-4px 0 0 0' }}>
												<Form.Check inline checked={this.state.selectedPlan && this.state.selectedPlan.packageId === plan.packageId} />
											</span>
										</div>
										<div className="col-md-11 col-sm-10">
											<p className="text-500 m-0">{plan.packageName} &pound;{plan.monthlyPrice} / month</p>
											<p className="text-sm m-0">
												{plan.packageName.includes('Unlimited')
												  ? 'Includes unlimited minutes to UK landline and mobile'
												  :													(plan.outboundSeconds.landlineSeconds + plan.outboundSeconds.mobileSeconds) === 0
												      ? 'Calls from 3p per min'
												      :														`Includes ${((plan.outboundSeconds.landlineSeconds + plan.outboundSeconds.mobileSeconds) / 60).toFixed(0)} call minutes`
												}
											</p>
										</div>
									</div>
								</div>
							))}

							{this.state.userToAdd && !this.state.isPurchaseInProgress &&
								<div className="mt-30">
									<label className={''}>Name</label>
									<input type='text'
										className={'form-control mb-20'}
										value={this.state.userToAdd.name}
										onChange={e => this.setState({ userToAdd: { ...this.state.userToAdd, name: e.target.value } })}
									/>

									<label className={`${validation && validation.userToAdd && validation.userToAdd.emailValid === validationStates.INVALID ? 'text-danger' : ''}`}>Email address</label>
									<input type='text'
										className={`form-control mb-20 ${validation && validation.userToAdd && validation.userToAdd.emailValid === validationStates.INVALID ? 'has-error' : ''}`}
										value={this.state.userToAdd.email}
										onChange={e => this.setState({ userToAdd: { ...this.state.userToAdd, email: e.target.value } })}
										onBlur={e => this.props.validateUserToAdd({
										  val: e.target.value,
										  type: 'email',
										  validation
										})}
									/>
									{validation && validation.userToAdd && validation.userToAdd.emailValid === validationStates.INVALID &&
										<Form.Text className="text-sm text-danger text-400">
											Please enter a valid email address
										</Form.Text>
									}

									<label className={`${validation && validation.userToAdd && validation.userToAdd.mobileValid === validationStates.INVALID ? 'text-danger' : ''}`}>Mobile number</label>
									<input type='tel'
										className={`form-control ${validation && validation.userToAdd && validation.userToAdd.mobileValid === validationStates.INVALID ? 'has-error' : ''}`}
										value={this.state.userToAdd.mobile}
										onChange={e => this.setState({ userToAdd: { ...this.state.userToAdd, mobile: e.target.value } })}
										onBlur={e => this.props.validateUserToAdd({
										  val: e.target.value,
										  type: 'mobile',
										  validation
										})}
									/>
									{validation && validation.userToAdd && validation.userToAdd.mobileValid === validationStates.INVALID &&
										<Form.Text className="text-sm text-danger text-400">
											Please enter a valid mobile number
										</Form.Text>
									}
								</div>
							}

						</Modal.Body>
						<Modal.Footer className="text-center">
							<Button variant="cta" className="btn-block btn-lg" onClick={() => this.handleAddUserToAccount(validation)} disabled={this.state.isPurchaseInProgress || (validation && validation.userToAdd && (validation.userToAdd.mobileValid !== validationStates.VALID || validation.userToAdd.emailValid !== validationStates.VALID) || !validation.userToAdd)}>
								Add To Account
							</Button>
							<p className="text-xs text-dark underline mt-30 cursor-pointer"
								onClick={() => this.setState({ showAddNewUser: false, isPurchaseInProgress: false })}>Cancel</p>
						</Modal.Footer>
					</Modal>

					<Modal show={this.state.showVoiceSamples} onHide={() => this.setState({ showVoiceSamples: false })}>
						<Modal.Header closeButton>
							<Modal.Title>Our Professional Voices</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="row p-20">
								<div className="col-sm-6 text-center br-1 br-xs-0 bb-xs-1 pb-xs-30">
									<img width="190" height="190" src="/images/janice-round.png" className="img-circle" alt="Janice" />
									<h3 className="text-500 mb-20">Janice</h3>
									<p><strong>The Voice Of Vodafone</strong></p>
									<p className="mb-30 mt-20">As the "voice of Vodafone" Janice provides a warm reassuring female voice with southern dialect</p>
									<ReactAudioPlayer src={janiceMp3Sample} controls />
								</div>
								<div className="col-sm-6 text-center mt-xs-30">
									<img width="190" height="190" src="/images/chris-round.png" className="img-circle" alt="Chris" />
									<h3 className="text-500 mb-20">Chris</h3>
									<p><strong>The Voice Of Sky</strong></p>
									<p className="mb-30 mt-20">A friendly professional male voice with southern dialect. Chris' voice is both calm &amp; professional</p>
									<ReactAudioPlayer src={chrisMp3Sample} controls />
								</div>
							</div>
						</Modal.Body>
					</Modal>

					{this.state.packUpgradeInfo &&
						<Modal size="sm" show={this.state.showUpgradePackage} onHide={() => this.setState({ showUpgradePackage: false })}>
							<Modal.Header closeButton>
								<Modal.Title className="text-500">Upgrade your plan</Modal.Title>
							</Modal.Header>
							<Modal.Body className="p-0">
								<div className="p-20 text-400">
									<p className="text-500">It looks like you&apos;re redirecting to a mobile number</p>
									<p>However, the plan you've chosen only includes landline minutes.</p>
									<p>Upgrade your plan today &amp; add {this.state.packUpgradeInfo.mins} mobile minutes, for just £{this.state.packUpgradeInfo.extraPrice.toFixed(2)}.</p>
									<p className="pt-10 text-500"><em>You can change your plan at any time</em></p>
								</div>
								<div className="bg-darker p-20 text-center">
									<p className="text-500 text-md pb-10">Upgrade your plan?</p>
									<div className={`mr-10 mt-5 d-inline-block text-500 ${this.state.wizard.upgradePlan ? 'text-light' : 'text-dullest'}`}>
										Yes
									</div>
									<Toggle
										on=""
										off=""
										size="sm"
										offstyle="default"
										onstyle="success"
										width="null"
										height="null"
										className="toggle-sm toggle-secondary"
										active={!this.state.wizard.upgradePlan}
										onClick={e => this.setState({ wizard: { ...this.state.wizard, upgradePlan: !e } })}
									/>
									<div className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${this.state.wizard.upgradePlan ? 'text-dullest' : 'text-light'}`}>
										No
									</div>
									{this.state.wizard.upgradePlan
									  ? <p className="text-xs mt-20">Your new plan cost will be £{this.state.packUpgradeInfo.newPrice} per month.</p>
									  :										<p className="text-xs mt-20">Calls diverted to mobiles will be charged at {additionalMobileRate}p per minute.</p>
									}
								</div>
							</Modal.Body>
							<Modal.Footer className="text-center">
								<Button variant="cta" className="btn-block btn-lg" onClick={() => this.handlePurchasePackageUpgrade()}>
									Upgrade &amp; Continue
								</Button>
								<p className="text-xs text-dark underline mt-30 cursor-pointer"
									onClick={() => this.setState({ showUpgradePackage: false }) + this.handleStepSelect(6)}>Continue without upgrading.</p>
							</Modal.Footer>
						</Modal>
					}

				</>
			}
			<ChoosePayment
				total={this.state.wpAmount ? calculateVat(this.state.wpAmount) : 0}
				clientId={this.props.clientId}
				purchaseData={this.state.key}
				email={this.props.userDetails.email}
				name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
				mandate={!this.props.services.hasAgreement}
			/>
		</>
  }

  handleContinueWithDeviceSelection () {
    const context = this

    if (this.state.directToSoftphone || this.state.directToPhoneApp) {
      const url = `/api/Users/SendSetupLinks/${this.state.directToSoftphone}/${this.state.directToPhoneApp}`
      fetchHelper.getJson(url)
        .then(res => {
        }).catch(err => console.error(err))

      context.handleStepSelect(3)
    } else {
      context.handleStepSelect(4)
    }
  }

  handleAddUserToAccount (validation) {
    const context = this
    const newUser = context.state.userToAdd

    this.setState({ isPurchaseInProgress: true })
    // Check all is good
    if (validation && validation.userToAdd && (validation.userToAdd.mobileValid === validationStates.VALID && validation.userToAdd.emailValid === validationStates.VALID)) {
      // Good to go
      const url = 'api/Purchase/AddNewSipUserToClient'
      fetchHelper.postJson(url, {
        User: {
          Name: newUser.name,
          Mobile: newUser.mobile,
          Email: newUser.email
        },
        PackageId: context.state.selectedPlan.packageId
      }).then(res => {
        this.setState({ isPurchaseInProgress: false, showAddNewUser: false })

        fetchHelper.getJson('api/Configuration/GetClientSipEndpoints').then(res => {
          context.props.handleInitSipUsers(res.data)
          context.props.notify({ message: 'Your new user has been added.' })
        })
      }).catch(err => console.error(err))
    } else {
      // Details missing or invalid
      context.setState({ isPurchaseInProgress: false })
    }
  }

  handleAddUser () {
    const context = this
    this.setState({ isLoadingPlans: true, showAddNewUser: true }, () => {
      // Get outbound plans
      const url = 'api/Purchase/GetPackages'
      fetchHelper.getJson(url)
        .then(res => {
          let packages = res.data.packages
          const freephone = packages.find(f => f.numberType === '0800')
          packages = packages.filter(f => f.numberType !== '0800')

          if (context.state.basket.package.numberType === '0800') {
            packages = []
            packages.push(freephone)
          }

          this.setState({
            userToAdd: {
              name: '',
              email: '',
              mobile: ''
            },
            selectedPlan: packages[packages.length - 1],
            isLoadingPlans: false,
            plans: packages.sort((a, b) => parseFloat(b.monthlyPrice) - parseFloat(a.monthlyPrice))
          })
        }).catch(err => console.error(err))
    })
  }

  handleCheckSipUsers (users, validation) {
    let canContinue = true

    if (validation === undefined || validation.length === 0) {
      this.setState({ showUsersError: true })
    } else {
      users.forEach((user, i) => {
        const val = validation[i]
        if (_.isEmpty(val) && (user.mobile || user.email)) {
          canContinue = false
        } else if ((val.validEmail === validationStates.INVALID || val.validMobile === validationStates.INVALID) && (user.mobile || user.email)) {
          canContinue = false
        }
      })
    }

    if (!canContinue) {
      // Validation failed
      this.setState({ showUsersError: true })
    } else {
      // Let's email the sip users and continue to next step
      const url = 'api/Outbound/InviteSipUsers'
      fetchHelper.postJson(url, users)
        .then(res => {
          if (res.data === true) {
            this.handleStepSelect(4)
          } else {
            this.setState({ showUsersError: true })
          }
        }).catch(err => console.error(err))
    }
  }

  handleCheckRedirects (redirects) {
    // Check if we have a mobile and no mobile mins...
    let canProceed = true
    const context = this
    let packUpgradeInfo
    function runAsync (num) {
      // return a promise
      return new Promise((resolve, reject) => {
        const numberType = checkNumberType(num.redirectDetail)
        if (!context.state.basket.isOutbound && numberType === PhoneTypes.mobile) {
          const api = `api/Purchase/CheckIfClientNeedsMobileUpgrade/${context.state.number.ddiid}`
          fetchHelper.getJson(api)
            .then(res => {
              if (res.data.extraPrice && res.data.extraPrice > 0) {
                packUpgradeInfo = { newPrice: res.data.totalPrice, extraPrice: res.data.extraPrice, mins: res.data.mobileMins, packageId: res.data.packageId }
                canProceed = false
                resolve(num)
              } else {
                canProceed = true
                resolve(num)
              }
            }).catch(err => console.error(err))
        } else {
          canProceed = true
          resolve(num)
        }
      })
    }

    const promise = redirects.map(runAsync)

    Promise.all(promise).then(() => {
      if (!canProceed) {
        context.setState({ showUpgradePackage: true, packUpgradeInfo, wizard: { ...this.state.wizard, upgradePlan: true, newPackage: packUpgradeInfo } })
      } else {
        context.handleStepSelect(6)
      }
    })
  }

  handleRestartWizard (number) {
    this.setState({ number }, () => {
      this.props.history.push('/customer/number-setup-wizard/3')
    })
  }

  handleHandsetUpdated () {
    this.setState({ handsetAdded: true })
  }

  handleUpdateHardware (hardware) {
    this.setState({ showEditDeliveryAddress: true }, () => {
      // let hardware = localStorage.getItem('hardware');
      if (hardware) {
        // hardware = JSON.parse(hardware);
        let qty = 0
        hardware.forEach((phone) => {
          qty += phone.qty
        })
        this.setState({ hardware })
      }
    })
  }

  handleClosePowerUpsell () {
    this.setState({ showPower: false })
  }

  handleSaveEditDeliveryAddress () {
    this.setState({ showPower: this.state.phoneQty > 0 })
  }

  handlePurchasePhones () {
    const context = this
    this.setState({ isPurchasingPhones: true, showPower: false, showEditDevices: false }, () => {
      const url = 'api/Purchase/PurchasePhones'
      const deliveryData = context.props.deliveryDetails
      const hardware = []
      context.state.hardware.forEach(item => {
        hardware.push({ amount: item.amount, qty: item.qty, prodId: item.prodId, name: item.name, monthlyPayments: false })
      })

      const data = {
        hardware,
        discount: WizardDiscounts.HandsetDiscount,
        deliveryDetails: {
          Name: deliveryData.deliveryName.value,
          Add1: deliveryData.deliveryAdd1.value,
          Add2: deliveryData.deliveryAdd2.value,
          Add3: deliveryData.deliveryTown.value,
          Add4: deliveryData.deliveryCounty.value,
          Pcode: deliveryData.deliveryPcode.value,
          Notes: deliveryData.deliveryNote ? deliveryData.deliveryNote.value : ''
        }
      }

      fetchHelper.postJson(url, data)
        .then(res => {
          if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
            context.setState({ isPurchasingPhones: false }, () => {
              context.props.notify({ message: 'Your phone(s) have been ordered.' })
            })
          } else if (res.data && res.data.postponed === true) {
            context.setState({ isPurchasingPhones: false }, () => {
              context.props.notify({ message: 'Your phone(s) have been ordered.' })
              context.props.handleShowPaymentPostponedModal()
            })
          } else {
            context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false }, () => {
              context.handleStoreSettingsBeforeWp()
              context.props.handleShowPaymentOptionsModal()
            })
          }
        }).catch(err => console.error(err))
    })
  }

  handleChangeUsers (isDecrement) {
    let qty = this.state.userQty

    if (isDecrement && qty > 0) {
      qty--
    } else {
      qty++
    }

    this.setState({ usersUpdated: true, userQty: qty, wizard: { ...this.state.wizard, userQty: qty } })
  }

  componentWillMount () {
    if (this.props.isLoggedIn) {
      this.props.fetchRedirectsHistory({ history: this.props.history })
      this.props.fetchOutboundUsers({ history: this.props.history })
      this.fetchRingGroups()
    }
  }

  fetchRingGroups () {
    this.props.fetchRingGroups({ history: this.props.history })
  }

  componentDidMount () {
    const context = this
    localStorage.removeItem('signupKey')
    const queryString = getQueryStringFromProps(this.props)

    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('cancel=true')) {
        this.props.history.push('/signup/checkout&cancel=true')
      }
    }

    const isPorting = localStorage.getItem('IsPorting') === 'true'
    this.setState({ isPorting })

    if (this.props.isLoggedIn && isPorting !== true) {
      if (localStorage.getItem('PipedriveCode') !== undefined) {
        const data = {
          code: localStorage.getItem('PipedriveCode')
        }

        const url = '/api/contacts/RegisterPipedriveCrmSyncRequest'

        // schedule request
        fetchHelper.postJson(url, data).then(() => {
          localStorage.removeItem('PipedriveCode')
        })
      }

      const getPromptsUrl = 'api/Configuration/IntroPrompts'
      const getMohUrl = 'api/Configuration/MohPrompts'

      let basket = localStorage.getItem('Basket')

      let fName = ''
      let userDetails = localStorage.getItem('userDetails')
      if (userDetails) {
        userDetails = JSON.parse(userDetails)
        fName = getFirstNameFromName(userDetails.name)
      }

      const user = JSON.parse(localStorage.getItem('user'))
      if (user && user.fName && !this.state.fName) {
        fName = user.fName
      }

      let number = ''

      if (basket) {
        this.setState({ basket })

        basket = JSON.parse(basket)
        number = basket.numbers[0]

        context.setState({ basket, userFirstName: fName, clientId: user.clientId, number, prefix: number.number.startsWith('01') || number.number.startsWith('03') ? number.number.substring(0, 2) : number.number.substring(0, 4) }, () => {
          const getInitialSettingsUrl = `api/Configuration/GetConfigurationWizardInitialSettings/${number.number}`
          const voiceOptionsUrl = `/api/Configuration/GetVoiceOptions/${number.number}`

          if (queryString) {
            const params = queryString.toLowerCase()
            if (params.includes('newnumber=true')) {
              this.props.notify({ message: 'Thank you, your new number has been added to your account' })
            }
          }

          // Check if we already have some wizard stored
          const tempWiz = localStorage.getItem('wizardData')
          if (tempWiz) {
            context.setState({ wizard: JSON.parse(tempWiz) }, () => {
              localStorage.removeItem('wizardData')
            })
          } else {
            fetchHelper.getJson(getInitialSettingsUrl).then(response => {
              const data = response.data
              const introPrompts = data.introPrompts
              const wizard = { ...context.state.wizard, voicemailEmail: data.email, missedCallEmail: data.email, wizIntroSelectedPrompt: introPrompts[29], redirects: data.redirects }

              context.setState({
                wizard,
                introPrompts
              })

              context.props.handleInitRedirects(data.redirects)
              this.handleOpenWeekdaysWorkingHoursClicked()
            }).catch(error => {
              console.error(error)
            })
          }

          fetchHelper.getJson('api/Configuration/GetClientSipEndpoints').then(res => {
            context.props.handleInitSipUsers(res.data)
          })

          context.props.getProductDetails([
            { productId: ProductIds.callRecordAll, monthlyOption: productBillingOption.callRecordingAllBillingOptionMonthly },
            { productId: ProductIds.umr, monthlyOption: productBillingOption.umrBillingOptionMonthly },
            { productId: ProductIds.endpoint, monthlyOption: WizardProductBillingOptions.endpointOptionMonthly },
            { productId: ProductIds.callBlocking, monthlyOption: WizardProductBillingOptions.callBlockingOptionMonthly }
          ])

          let umr = localStorage.getItem('purchasedUmr')
          if (umr) {
            umr = JSON.parse(umr)
            context.setState({ purchasedUmr: umr.purchasedUmr })
            if (umr.purchasedUmr === true) { this.enableUmr() }
          }

          context.setState({ isLoadingVoiceOptions: true }, () => {
            fetchHelper.getJson(voiceOptionsUrl).then(res => {
              const voicePrompts = res.data
              const wizard = { ...context.state.wizard, wizSelectedVoice: voicePrompts[0] }
              context.setState({ voiceOptions: res.data, isLoadingVoiceOptions: false, wizard })
            }).catch(function (error) {
              console.error(error)
            })
          })
        })
      }
      context.setState({ isLoadingIntroPrompts: true }, () => {
        fetchHelper.getJson(getPromptsUrl).then(response => {
          const introPrompts = response.data
          const wizard = { ...context.state.wizard, wizIntroSelectedPrompt: introPrompts[29] }
          context.setState({ introPrompts, wizard, isLoadingIntroPrompts: false })
        }).catch(error => {
          context.setState({ isLoadingIntroPrompts: false })
          console.error(error)
        })
      })

      context.setState({ isLoadingMoh: true }, () => {
        fetchHelper.getJson(getMohUrl)
          .then(res => {
            const moh = res.data
            const wizard = { ...context.state.wizard, wizSelectedMoh: moh.length > 88 ? moh[88] : moh[1] }
            context.setState({ mohTracks: moh, wizard, isLoadingMoh: false })
          }).catch(err => {
            console.error(err)
            context.setState({ isLoadingMoh: false })
          })
      })
    }

    // Check if we need to hide any steps
    let steps = localStorage.getItem('WizardStepsToHide')
    if (steps) {
      steps = JSON.parse(steps)
      steps.forEach(i => {
        const name = `hidestep_${i}`
        this.setState({ [name]: true })
      })
    }

    // Check if we need t oshow upsells
    if (localStorage.getItem('hideAllUpsells')) {
      this.setState({
        hideCallRecordingUpsell: true, hideUmrUpsell: true, hideCallBlockUpsell: true, hideAudioBrandUpsell: true, hideCustomUpsell: true
      })
    } else if (localStorage.getItem('hideUmrUpsell')) {
      this.setState({ hideUmrUpsell: true })
    } else if (localStorage.getItem('hideCallBlockUpsell')) {
      this.setState({ hideCallBlockUpsell: true })
    } else if (localStorage.getItem('hideAudioBrandUpsell')) {
      this.setState({ hideAudioBrandUpsell: true })
    } else if (localStorage.getItem('hideCustomUpsell')) {
      this.setState({ hideCustomUpsell: true })
    }
  }

  handleMohChange (moh) {
    this.props.fetchMohTrack(moh)

    const selectedMoh = _.find(this.state.mohTracks, track => track === moh)
    const wizard = { ...this.state.wizard, wizSelectedMoh: selectedMoh }
    this.setState({ wizard })
  }

  handleWizIntroChange (introPromptId) {
    const selectedIntroPrompt = _.find(this.state.introPrompts, prompt => prompt.value === introPromptId)
    const wizard = { ...this.state.wizard, wizIntroSelectedPrompt: selectedIntroPrompt }
    this.setState({ wizard })
  }

  handleWizVoiceChange (voiceId) {
    const selectedVoice = _.find(this.state.voiceOptions, opt => opt.value === voiceId)
    const wizard = { ...this.state.wizard, wizSelectedVoice: selectedVoice }
    this.setState({ wizard })
  }

  handleContinueStep (nextStep) {
    localStorage.setItem('wizStep', nextStep)
    this.setState({ errors: {} })

    if (this.props.match.params.step) {
      this.scrollTop()
      if (nextStep === 1 && this.state.hidestep_1 === true) {
        this.handleContinueStep(2)
      } else if (nextStep === 2 && this.state.hidestep_2 === true) {
        this.handleContinueStep(3)
      } else if (nextStep === 3 && this.state.hidestep_3 === true) {
        this.handleContinueStep(4)
      } else if (nextStep === 4 && this.state.hidestep_4 === true) {
        this.handleContinueStep(5)
      } else if (nextStep === 5 && this.state.hidestep_5 === true) {
        this.handleContinueStep(6)
      } else if (nextStep === 6 && this.state.hidestep_6 === true) {
        this.handleContinueStep(7)
      } else if (nextStep === 7 && this.state.hidestep_7 === true) {
        this.handleContinueStep(8)
      } else if (nextStep === 8 && this.state.hidestep_8 === true) {
        this.handleContinueStep(9)
      } else if (nextStep === 9 && this.state.hidestep_9 === true) {
        this.handleContinueStep(10)
      } else {
        this.props.history.push(`/customer/number-setup-wizard/${nextStep}`)
      }
    } else {
      if (nextStep === 1 && this.state.hidestep_1 === true) {
        this.handleContinueStep(2)
      } else if (nextStep === 2 && this.state.hidestep_2 === true) {
        this.handleContinueStep(3)
      } else if (nextStep === 3 && this.state.hidestep_3 === true) {
        this.handleContinueStep(4)
      } else if (nextStep === 4 && this.state.hidestep_4 === true) {
        this.handleContinueStep(5)
      } else if (nextStep === 5 && this.state.hidestep_5 === true) {
        this.handleContinueStep(6)
      } else if (nextStep === 6 && this.state.hidestep_6 === true) {
        this.handleContinueStep(7)
      } else if (nextStep === 7 && this.state.hidestep_7 === true) {
        this.handleContinueStep(8)
      } else if (nextStep === 8 && this.state.hidestep_8 === true) {
        this.handleContinueStep(9)
      } else if (nextStep === 9 && this.state.hidestep_9 === true) {
        this.handleContinueStep(10)
      } else {
        this.setState({ activeStep: nextStep, errors: {} })
      }
    }
  }

  handleStepSelect (nextStep) {
    const errors = this.validateWizard()
    const currentStep = parseInt(this.state.activeStep)

    if (_.isEmpty(errors)) {
      this.handleContinueStep(nextStep)
    } else {
      if (currentStep === 4) {
        const { wizIntroSelectedPrompt } = errors
        if (wizIntroSelectedPrompt) {
          this.setState({ errors })
          return
        }

        this.handleContinueStep(nextStep)
      } else if (currentStep === 6) {
        const openCloseErrors = (({
          monClosed,
          tueClosed,
          wedClosed,
          thuClosed,
          friClosed,
          satClosed,
          sunClosed
        }) => ({
          monClosed,
          tueClosed,
          wedClosed,
          thuClosed,
          friClosed,
          satClosed,
          sunClosed
        }))(errors)

        if (openCloseErrors.monClosed || openCloseErrors.tueClosed || openCloseErrors.wedClosed || openCloseErrors.thuClosed || openCloseErrors.friClosed || openCloseErrors.satClosed || openCloseErrors.sunClosed) {
          this.setState({ errors })
          return
        }

        this.handleContinueStep(nextStep)
      } else if (currentStep === 8) {
        if (errors.missedCallEmail) {
          this.setState({ errors })
          return
        }

        this.handleContinueStep(nextStep)
      } else {
        this.handleContinueStep(nextStep)
      }
    }
  }

  validateWizard () {
    const context = this
    function getErrorsFromValidationError (validationError) {
      const FIRST_ERROR = 0
      if (validationError) {
        return validationError.inner.reduce((errors, error) => {
          return {
            ...errors,
            [error.path]: error.errors[FIRST_ERROR]
          }
        }, {})
      }
    }

    try {
      context.validation.validateSync(this.state.wizard, { abortEarly: false })
      return {}
    } catch (error) {
      const errors = getErrorsFromValidationError(error)
      // context.setState({errors:errors})
      return errors
    }
  }

  handleOpenAllHoursClicked () {
    const openAllHours = {
      monOpen: moment({ h: 0, m: 0 }),
      tueOpen: moment({ h: 0, m: 0 }),
      wedOpen: moment({ h: 0, m: 0 }),
      thuOpen: moment({ h: 0, m: 0 }),
      friOpen: moment({ h: 0, m: 0 }),
      satOpen: moment({ h: 0, m: 0 }),
      sunOpen: moment({ h: 0, m: 0 }),
      monClosed: moment({ h: 23, m: 59 }),
      tueClosed: moment({ h: 23, m: 59 }),
      wedClosed: moment({ h: 23, m: 59 }),
      thuClosed: moment({ h: 23, m: 59 }),
      friClosed: moment({ h: 23, m: 59 }),
      satClosed: moment({ h: 23, m: 59 }),
      sunClosed: moment({ h: 23, m: 59 })
    }
    this.setState({ wizard: { ...this.state.wizard, ...openAllHours }, open247: true, openMonFri: false, open7days: false, openBespoke: false })
  }

  handleOpenWeekdaysWorkingHoursClicked () {
    const openAllHours = {
      monOpen: moment({ h: 9, m: 0 }),
      tueOpen: moment({ h: 9, m: 0 }),
      wedOpen: moment({ h: 9, m: 0 }),
      thuOpen: moment({ h: 9, m: 0 }),
      friOpen: moment({ h: 9, m: 0 }),
      satOpen: moment({ h: 0, m: 0 }),
      sunOpen: moment({ h: 0, m: 0 }),
      monClosed: moment({ h: 17, m: 0 }),
      tueClosed: moment({ h: 17, m: 0 }),
      wedClosed: moment({ h: 17, m: 0 }),
      thuClosed: moment({ h: 17, m: 0 }),
      friClosed: moment({ h: 17, m: 0 }),
      satClosed: moment({ h: 0, m: 0 }),
      sunClosed: moment({ h: 0, m: 0 })
    }
    this.setState({ wizard: { ...this.state.wizard, ...openAllHours }, open247: false, openMonFri: true, open7days: false, openBespoke: false })
  }

  handleOpenAllWeekWorkingHoursClicked () {
    const openAllHours = {
      monOpen: moment({ h: 9, m: 0 }),
      tueOpen: moment({ h: 9, m: 0 }),
      wedOpen: moment({ h: 9, m: 0 }),
      thuOpen: moment({ h: 9, m: 0 }),
      friOpen: moment({ h: 9, m: 0 }),
      satOpen: moment({ h: 9, m: 0 }),
      sunOpen: moment({ h: 9, m: 0 }),
      monClosed: moment({ h: 17, m: 0 }),
      tueClosed: moment({ h: 17, m: 0 }),
      wedClosed: moment({ h: 17, m: 0 }),
      thuClosed: moment({ h: 17, m: 0 }),
      friClosed: moment({ h: 17, m: 0 }),
      satClosed: moment({ h: 17, m: 0 }),
      sunClosed: moment({ h: 17, m: 0 })
    }
    this.setState({ wizard: { ...this.state.wizard, ...openAllHours }, open247: false, openMonFri: false, open7days: true, openBespoke: false })
  }

  handleCompleteWizard (config) {
    const context = this
    const errors = context.validateWizard()
    const currentStep = parseInt(context.state.activeStep)

    if (!_.isEmpty(errors)) {
      if (errors.missedCallEmail) {
        this.setState({ errors })
      }
    } else {
      const url = '/api/Configuration/UpdateConfigurationWizard'

      const wizard = this.state.wizard
      const numbers = []
      this.state.basket.numbers.forEach((num) => {
        numbers.push(num.number)
      })

      const data = {
        ...wizard,
        introPromptId: Number(wizard.wizIntroSelectedPrompt.value),
        introVoiceId: Number(wizard.wizSelectedVoice.value),
        mohCatId: Number(wizard.wizSelectedMoh.value),
        queueNos: numbers,
        redirects: config.redirects,
        openTimes: {
          monOpen: moment(wizard.monOpen).format('HH:mm'),
          tueOpen: moment(wizard.tueOpen).format('HH:mm'),
          wedOpen: moment(wizard.wedOpen).format('HH:mm'),
          thuOpen: moment(wizard.thuOpen).format('HH:mm'),
          friOpen: moment(wizard.friOpen).format('HH:mm'),
          satOpen: moment(wizard.satOpen).format('HH:mm'),
          sunOpen: moment(wizard.sunOpen).format('HH:mm'),
          monClosed: moment(wizard.monClosed).format('HH:mm'),
          tueClosed: moment(wizard.tueClosed).format('HH:mm'),
          wedClosed: moment(wizard.wedClosed).format('HH:mm'),
          thuClosed: moment(wizard.thuClosed).format('HH:mm'),
          friClosed: moment(wizard.friClosed).format('HH:mm'),
          satClosed: moment(wizard.satClosed).format('HH:mm'),
          sunClosed: moment(wizard.sunClosed).format('HH:mm')
        },
        userQty: this.state.userQty
      }

      context.setState({ isSaving: true }, () => {
        fetchHelper.postJson(url, data).then(res => {
          context.setState({ isSaving: false, isPurchaseInProgress: false })

          if (res.data) {
            if (res.data.valid === true) {
              // Cool, show success
              context.props.history.push('/customer/number-setup-wizard/9')
            } else {
              context.props.notify({ message: res.data.error ? res.data.error : 'Error saving settings, please contact support', isError: true })
              if (res.data.error && res.data.error.includes('redirect')) { context.props.history.push('/customer/number-setup-wizard/4') }
            }
          } else {
            context.props.notify({ message: 'Error saving settings, please contact support', isError: true })
          }
        }).catch(function (error) {
          context.props.notify({ message: 'Error saving settings, please contact support', isError: true })
        })
      })
    }
  }

  async handleContinueToDashboard (config) {
    this.setState({ showUpsellError: false })

    const checkUpsells = async () => {
      let canProceed = true
      if (this.state.wizard.buyUmr === true) {
        // Sort UMR
        await this.handlePurchaseUmr().then(res => {
          canProceed = res
        })
      }

      if (this.state.wizard.buyCallRecording === true) {
        // Sort CR
        await this.handlePurchaseCallRecording().then(res => {
          canProceed = res
        })
      }

      if (this.state.wizard.enableTranscription === true) {
        await this.handleEnableTranscription().then(res => {
          canProceed = res
        })
      }

      if (this.state.wizard.callBlocking === true) {
        // Sort call blocking
        await this.handlePurchaseCallBlocking().then(res => {
          canProceed = res
        })
      }

      if (this.state.wizard.buyCallAnswering === true) {
        await this.handleAddPrLite().then(res => {
          canProceed = res
        })
      }

      if (this.state.wizard.buyCustomPrompts === true) {
        await this.handleBuyCustomPrompts().then(res => {
          canProceed = res
        })
      }

      return canProceed
    }

    const canProceed = await checkUpsells()
    if (canProceed === true) {
      localStorage.removeItem('referrer')
      localStorage.removeItem('lookingAt')
      localStorage.removeItem('userDetails')
      localStorage.removeItem('partialId')
      localStorage.removeItem('Basket')
			localStorage.removeItem('basket')
      localStorage.removeItem('mobileNumber')
      localStorage.removeItem('seenWhatsApp')
      localStorage.removeItem('selectedNumberType')
      localStorage.removeItem('skipToCheckout')
      localStorage.removeItem('wizStep')
      localStorage.removeItem('wizardData')
      localStorage.removeItem('isAnnualBilling')
      localStorage.removeItem('planType')
      localStorage.removeItem('basketUpdate')
      localStorage.removeItem('wizStep')

      this.props.history.push('/customer/dashboard')
    } else {
      // No good
      this.setState({ showUpsellError: true })
    }
  }

  async handleBuyCustomPrompts () {
    const promise = new Promise((resolve, reject) => {
      fetchHelper.getJson(`api/Configuration/BuyCustomPromptsNotification/${this.state.number.number}`)
        .then(res => {
          resolve(res.data)
        }).catch(err => {
          console.error(err)
          resolve(false)
        })
    })

    return await promise
  }

  async handleAddPrLite () {
    const context = this
    const url = 'api/Configuration/AddPrLiteToCustomer'

    const promise = new Promise((resolve, reject) => {
      const numbers = []
      context.state.basket.numbers.forEach((num) => {
        numbers.push(num.number)
      })

      context.setState({ isPurchaseInProgress: true }, () => {
        fetchHelper.postJson(url, numbers).then(res => {
          resolve(res.data)
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)
          resolve(false)
        })
      })
    })

    return await promise
  }

  handlePurchasePackageUpgrade () {
    const context = this

    this.setState({ showUpgradePackage: false }, () => {
      const url = 'api/Purchase/UpgradeMinutesPackage'
      const data = {
        extraPrice: context.state.extraPrice,
        packageId: context.state.packageId,
        ddiid: context.state.number.ddiid
      }

      context.setState({ isPurchaseInProgress: true }, () => {
        fetchHelper.postJson(url, data)
          .then(res => {
            if (!data || !data.isSuccess || !data.postponed) {
              context.handleStoreSettingsBeforeWp()
              context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false })

              context.props.handleShowPaymentOptionsModal()
            } else {
              this.handleStepSelect(6)
            }
          }).catch(err => console.error(err))
      })
    })
  }

  async handlePurchaseUmr () {
    const context = this
    const url = 'api/Purchase/PurchaseUmr'

    const promise = new Promise((resolve, reject) => {
      context.setState({ isPurchaseInProgress: true }, () => {
        fetchHelper.postJson(url, { billingOption: productBillingOption.umrBillingOptionMonthly }).then(res => {
          if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
            this.enableUmr(context)
            resolve(true)
          } else if (res.data && res.data.postponed === true) {
            this.enableUmr(context)
            context.props.handleShowPaymentPostponedModal()
            resolve(true)
          } else {
            context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false }, () => {
              context.handleStoreSettingsBeforeWp()
              context.props.handleShowPaymentOptionsModal()
            })

            resolve(false)
          }
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)

          resolve(false)
        })
      })
    })

    return await promise
  }

  async handlePurchaseCallRecording (billingOptionId) {
    const context = this
    const url = 'api/Purchase/PurchaseCallRecording'

    const promise = new Promise((resolve, reject) => {
      context.setState({ isPurchaseInProgress: true }, () => {
        fetchHelper.postJson(url, { ProductId: ProductIds.callRecordAll, billingOption: billingOptionId || productBillingOption.callRecordingAllBillingOptionMonthly, IsUnlimited: false })
          .then(res => {
            if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
              this.enableCallRecording(context)

              resolve(true)
            } else {
              context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false }, () => {
                context.handleStoreSettingsBeforeWp()
                context.props.handleShowPaymentOptionsModal()
              })

              resolve(false)
            }
          }).catch(function (error) {
            context.setState({ isPurchaseInProgress: false })
            console.error(error)
            resolve(false)
            context.props.handleShowPaymentOptionsModal()
          })
      })
    })

    return await promise
  }

  async handleEnableTranscription () {
    const context = this
    const url = 'api/CallTranscription/TurnOnCallTranscription'

    const promise = new Promise((resolve, reject) => {
      context.setState({ isPurchaseInProgress: true }, () => {
        fetchHelper.postJson(url, {})
          .then(res => {
            resolve(res.data === true)
          })
          .catch(err => {
            resolve(false)
            console.error(err)
          })
      })
    })

    return await promise
  }

  async handleEnableTranscription () {
    const context = this
    const url = 'api/CallTranscription/TurnOnCallTranscription'

    const promise = new Promise((resole, reject) => {
      context.setState({ isPurchaseInProgress: true }, () => {
        fetchHelper.postJson(url, {})
          .then(res => {
            resolve(res.data === true)
          })
          .catch(err => {
            resolve(false)
            console.error(err)
          })
      })
    })

    return await promise
  }

  async handlePurchaseCallBlocking () {
    const context = this
    const url = 'api/Purchase/PurchaseProduct'

    const promise = new Promise((resolve, reject) => {
      context.setState({ isPurchaseInProgress: true }, () => {
        fetchHelper.postJson(url, { BillingOption: WizardProductBillingOptions.callBlockingOptionMonthly, ProductId: ProductIds.callBlocking, Discount: WizardDiscounts.CallBlock }).then(res => {
          if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
            this.enableCallBlocking(context)
            resolve(true)
          } else if (res.data && res.data.postponed === true) {
            this.enableCallBlocking(context)
            context.props.handleShowPaymentPostponedModal()
            resolve(true)
          } else {
            context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false }, () => {
              context.handleStoreSettingsBeforeWp()
              context.props.handleShowPaymentOptionsModal()
            })
            resolve(false)
          }
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)
          resolve(false)
        })
      })
    })

    return await promise
  }

  enableUmr (context) {
    localStorage.removeItem('purchasedUmr')
    context.setState({ isPurchaseInProgress: false })
    context.props.notify({ message: 'Unlimited Mobile Redirects Added' })
    context.props.handleUmrEnabledChange(true)
  }

  enableCallRecording (context) {
    context.setState({ isPurchaseInProgress: false })
    context.props.notify({ message: 'Call Recording Has Been Added' })
    context.props.handleCallRecordingAllEnableChange(true)
  }

  handleEditUsers () {
    this.setState({ showUserSettings: true })
  }

  handleStoreSettingsBeforeWp () {
    localStorage.setItem('wizardData', JSON.stringify(this.state.wizard))
  }

  enableCallBlocking (context) {
    context.props.notify({ message: 'Call blocking enabled' })
    context.props.handleCallBlockingEnabledChange(true)
  }

  handleAddNewUsers (context) {
    context.props.notify({ message: 'Your new users have been added.' })
  }
}
export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      config: getConfiguration(state),
      contactBookEnabled: state.sbfApp.enabledServices.hasContactBook,
      callFilteringEnabled: state.sbfApp.enabledServices.hasCallBlocking,
      umrEnabled: state.sbfApp.enabledServices.hasUmr,
      productDetails: state.home.productDetails.products,
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      deliveryDetails: state.sbfApp.deliveryData,
      validation: getConfigValidators(state),
      userDetails: state.sbfApp.authentication
    }
  },
  {
    getProductDetails: fromHome.getProductPrice,
    fetchIntroPrompts: configurationActions.fetchIntroPrompts,
    notify: handleNotificationChange,
    fetchRedirectsHistory: configurationActions.fetchRedirectsHistory,
    fetchOutboundUsers: configurationActions.fetchOutboundUsers,
    fetchRingGroups: configurationActions.fetchRingGroups,
    validateRedirect: configurationActions.validateRedirect,
    handleInitRedirects: configurationActions.handleInitRedirects,
    handleInitSipUsers: configurationActions.handleInitSipUsers,
    handleAddRedirect: configurationActions.handleAddRedirect,
    removeRedirect: configurationActions.removeRedirect,
    fetchMohTrack: configurationActions.fetchMohTrack,
    changeUserDetails: configurationActions.changeUserDetails,
    validateUser: configurationActions.validateUser,
    validateUserToAdd: configurationActions.validateUserToAdd,
    handleChangeHasAgreement,
    notify: handleNotificationChange,
    handleCallRecordingAllEnableChange,
    handleUmrEnabledChange,
    handleUpdateCredit,
    handleCallBlockingEnabledChange,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal,
    handleDeliveryNameChange,
    handleDeliveryAdd1Change,
    handleDeliveryAdd2Change,
    handleDeliveryTownChange,
    handleDeliveryCountyChange,
    handleDeliveryPcodeChange,
    handleDeliveryNoteChange

  }
)(NumberWizard)
