import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import ModalVideo from 'react-modal-video'
import { connect } from 'react-redux'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

class Videos extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
      isOpenTwo: false,
      isOpenThree: false,
      isOpenFour: false,
      isOpenFive: false,
      isOpenSix: false,
      isOpenSeven: false,
      isOpenEight: false,
      isOpenNine: false,
      isOpenTen: false,
      isOpenEleven: false,
      isOpenTwelve: false,
      isOpenThirteen: false,
      isOpenFourteen: false
    }
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
			<header style={{ backgroundImage: "url('/images/backgrounds/video-bg.jpg')" }} className="full-width full-hero-small with-text">
				<h1>SwitchboardFREE videos</h1>
				<h2 className="subtitle">Find out how SwitchboardFREE works</h2>
			</header>
			<div className="container-styled">
				<div className="container">
					<div className="row">
						<div className="col-md-12 pt-20 pb-20 text-center">
							<p className="text-md">Our system has many useful features that will definitely benefit your business and make your life easier. Below you will find videos explaining some of our features step by step.
                              </p>
						</div>
					</div>
					</div>
						<Tabs defaultActiveKey={1} className="tabs-container justify-content-center">
							<Tab eventKey={1} title="Educational">
								<div className="container">
								<div className="row mt-20">
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenNine} videoId='fuli0c0IiKo' onClose={() => this.setState({ isOpenNine: false })} />
										<a onClick={() => this.setState({ isOpenNine: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/uk-city-numbers.jpg" alt="UK City Phone Numbers" />
										</a>
										<h4 className="text-500">UK city numbers</h4>
										<p>See how to target local markets and get a big business presence with virtual UK City phone numbers, such as 020 London, 0161 Manchester etc.</p>
									</div>
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='W0l_hvSKMUI' onClose={() => this.setState({ isOpen: false })} />
										<a onClick={() => this.setState({ isOpen: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/open-close.jpg" />
										</a>
										<h4 className="text-500">Open &amp; close times</h4>
										<p>See how easy is to tell your callers that your lines are closed.</p>
									</div>
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenTwo} videoId='X8dKUN4FP_8' onClose={() => this.setState({ isOpenTwo: false })} />
										<a onClick={() => this.setState({ isOpenTwo: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/missed-call.jpg" />
										</a>
										<h4 className="text-500">Missed call alerts</h4>
										<p>Receive email notifications about any missed calls made to your SwitchboardFREE number.</p>
									</div>
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenThree} videoId='dfyg6ZjSqKM' onClose={() => this.setState({ isOpenThree: false })} />
										<a onClick={() => this.setState({ isOpenThree: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/voicemail.jpg" />
										</a>
										<h4 className="text-500">Voicemail alerts</h4>
										<p>Give callers the option to leave a voicemail. Get emails containing voicemail recordings.</p>
									</div>

								</div>
								<div className="row mt-20">
								<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenFour} videoId='bUbG-2S8WSk' onClose={() => this.setState({ isOpenFour: false })} />
										<a onClick={() => this.setState({ isOpenFour: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/call-queueing.jpg" />
										</a>
										<h4 className="text-500">Queueing calls</h4>
										<p>Limited lines into your office? We will queue your incoming calls and put them through to you as soon as a line becomes free.</p>
									</div>
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenFive} videoId='F7SQ-xGK9g4' onClose={() => this.setState({ isOpenFive: false })} />
										<a onClick={() => this.setState({ isOpenFive: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/redirect.jpg" />
										</a>
										<h4 className="text-500">Redirect your calls</h4>
										<p>See how to redirect calls made to your Switchboard number to up to 5 different UK mobile or landline numbers.</p>
									</div>
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenSix} videoId='jGQbiUc9qi0' onClose={() => this.setState({ isOpenSix: false })} />
										<a onClick={() => this.setState({ isOpenSix: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/call-stats.jpg" />
										</a>
										<h4 className="text-500">Call reports</h4>
										<p>Learn how to see real-time information from all of your calls and how you can use call reports to make better informed decisions to grow your business.</p>
									</div>
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenSeven} videoId='cykYlQGGbmE' onClose={() => this.setState({ isOpenSeven: false })} />
										<a onClick={() => this.setState({ isOpenSeven: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/multiple-departments.jpg" alt="Phone Multiple Departmetns selection" />
										</a>
										<h4 className="text-500">Multiple departments</h4>
										<p>Set up between 1 and 9 departments to ensure callers are routed to the most appropriate person or department.</p>
									</div>

								</div>
								<div className="row mt-20">
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenTen} videoId='XsEQiuKm418' onClose={() => this.setState({ isOpenTen: false })} />
										<a onClick={() => this.setState({ isOpenTen: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/number-types.jpg" alt="Types of Virtual Phone Numbers" />
										</a>
										<h4 className="text-500">Number Types</h4>
										<p>Find the ideal telephone number for your business. Choose from our great value 084 numbers, a local city number, 0800 or new 03 numbers.</p>
									</div>
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenEleven} videoId='-H4fdYfnvCs' onClose={() => this.setState({ isOpenEleven: false })} />
										<a onClick={() => this.setState({ isOpenEleven: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/call-tracking.jpg" alt="Track where your calls are coming from" />
										</a>
										<h4 className="text-500">Call tracking</h4>
										<p>See how you can track calls made to your SwitchboardFREE numbers from various advertising sources.</p>
									</div>
								</div>
								</div>
							</Tab>
							<Tab eventKey={2} title="Promotional">
								<div className="container">
								<div className="row mt-20">
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenTwelve} videoId='BILFP_6Mfkk' onClose={() => this.setState({ isOpenTwelve: false })} />
										<a onClick={() => this.setState({ isOpenTwelve: true })}>

											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/prompts.jpg" alt="Professional Custom Prompts Recording" />
										</a>
										<h4 className="text-500">Custom prompts</h4>
										<p>Use our in-house team to record your prompts.</p>
									</div>
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenThirteen} videoId='-4jYJPPWCBY' onClose={() => this.setState({ isOpenThirteen: false })} />
										<a onClick={() => this.setState({ isOpenThirteen: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/SF.jpg" />
										</a>
										<h4 className="text-500">About us in 60 seconds</h4>
										<p>Find out what SwitchboardFREE is in under 60 seconds.</p>
									</div>
									<div className="col-lg-3 col-md-6">
										<ModalVideo channel='youtube' isOpen={this.state.isOpenFourteen} videoId='4QIFEC8RObQ' onClose={() => this.setState({ isOpenFourteen: false })} />
										<a onClick={() => this.setState({ isOpenFourteen: true })}>
											<img className="img-responsive bb-cta-5" src="/images/video-thumbs/ss.jpg" />
										</a>
										<h4 className="text-500">Meet Simon &amp; Sam</h4>
										<p>See how what are the cons of not using SwitchboardFREE.</p>
									</div>
								</div>
								</div>
							</Tab>
						</Tabs>
			</div>
		</>
  }

  componentDidMount () {
    const tabs = document.getElementsByClassName('nav-tabs')
    tabs[0].classList.add('nav-tabs-centered')
    const wrapper = document.createElement('div')
    wrapper.classList.add('tabs-container')
    tabs[0].parentNode.insertBefore(wrapper, tabs[0])
    wrapper.appendChild(tabs[0])
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(Videos)
