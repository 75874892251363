import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Validator from '../../components/Validator'
import { fieldNames, openCloseTimeConstants } from '../../constants/ConfigurationConstants'
import { validationStates } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'
import BespokeOpenCloseTimesPicker from './BespokeOpenCloseTimesPicker'

const BespokeOpenCloseTimes = props => (
    <div className="pl-20 pr-20 pt-30 mt-30 bt-1 text-center">
        <div className='d-none d-sm-block'>
            <div className="row pt-10">
                <div className="col-sm-3 col-md-3 text-500 pb-10">Day</div>
                <div className="col-sm-3 col-md-3 text-500 pb-10">Open Time</div>
                <div className="col-sm-3 col-md-3 text-500 pb-10">Close Time</div>
                <div className="col-sm-3 col-md-3 text-500 pb-10">Is Open?</div>
            </div>
        </div>

        {/* MONDAY */}
        <div className="row pb-20 pt-10 bb-1 mt-10 mb-10">
            <div className="col-12 col-sm-3 col-md-3 pt-10 pb-xs-10">
                <p className="text-xs-lg">Monday</p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Open...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.MON_OPEN} time={props.configuration.monOpen}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Close...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.MON_CLOSED} time={props.configuration.monClose}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <p className="d-block d-sm-none pt-30">Open/Close number on Monday?</p>

                    {/* <input type="checkbox" checked={props.openMon} onChange={e => props.changeBespokeHoursHourClose({day:openCloseTimeConstants.MON_OPEN, isOpen:e.target.checked})} /> */}
                <Toggle
                    className="mt-0"
                    onClick={e => {
                      return props.changeBespokeHoursHourClose({
                        day: openCloseTimeConstants.MON_OPEN,
                        isOpen: e
                      })
                    }}
                    on={<span>Open</span>}
                    off={<span>Closed</span>}
                    size="sm"
                    offstyle="default"
                    onstyle="success"
                    width="null"
                    height="null"
                    active={props.configuration.openMon}
                    id="MondayOpenCloseToggle"
                />

            </div>
            {props.validation.monClose && props.validation.monClose.valid === validationStates.INVALID &&
                <div>
                    <div className="clearfix mb-10"></div>
                    <Validator validation={props.validation[fieldNames.MON_CLOSE]}/>
                </div>
            }
        </div>

        {/* TUESDAY */}
        <div className="row pb-20 pt-10 bb-1 mt-10 mb-10">
            <div className="col-12 col-sm-3 col-md-3 pt-10 pb-xs-10">
                <p className="text-xs-lg">Tuesday</p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Open...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.TUE_OPEN} time={props.configuration.tueOpen}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Close...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.TUE_CLOSED} time={props.configuration.tueClose}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <p className="d-block d-sm-none pt-30">Open/Close number on Tuesday?</p>

                <Toggle
                    className="mt-0"
                    onClick={e => props.changeBespokeHoursHourClose({ day: openCloseTimeConstants.TUE_OPEN, isOpen: e })}
                    on={<span>Open</span>}
                    off={<span>Closed</span>}
                    size="sm"
                    offstyle="default"
                    onstyle="success"
                    width="null"
                    height="null"
                    active={props.configuration.openTue}
                    id="TuesdayOpenCloseToggle"
                />

            </div>
            {props.validation.tueClose && props.validation.tueClose.valid === validationStates.INVALID &&
                <div>
                    <div className="clearfix mb-10"></div>
                    <Validator validation={props.validation[fieldNames.TUE_CLOSE]}/>
                </div>
            }
        </div>

        {/* WEDNESDAY */}
        <div className="row pb-20 pt-10 bb-1 mt-10 mb-10">
            <div className="col-12 col-sm-3 col-md-3 pt-10 pb-xs-10">
                <p className="text-xs-lg">Wednesday</p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Open...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.WED_OPEN} time={props.configuration.wedOpen}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Close...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.WED_CLOSED} time={props.configuration.wedClose}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <p className="d-block d-sm-none pt-30">Open/Close number on Wednesday?</p>

                <Toggle
                    className="mt-0"
                    onClick={e => props.changeBespokeHoursHourClose({ day: openCloseTimeConstants.WED_OPEN, isOpen: e })}
                    on={<span>Open</span>}
                    off={<span>Closed</span>}
                    size="sm"
                    offstyle="default"
                    onstyle="success"
                    width="null"
                    height="null"
                    active={props.configuration.openWed}
                    id="WednesdayOpenCloseToggle"
                />

            </div>
            {props.validation.wedClose && props.validation.wedClose.valid === validationStates.INVALID &&
                <div>
                    <div className="clearfix mb-10"></div>
                    <Validator validation={props.validation[fieldNames.WED_CLOSE]}/>
                </div>
            }
        </div>

        {/* THURSDAY */}
        <div className="row pb-20 pt-10 bb-1 mt-10 mb-10">
            <div className="col-12 col-sm-3 col-md-3 pt-10 pb-xs-10">
                <p className="text-xs-lg">Thursday</p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Open...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.THU_OPEN} time={props.configuration.thuOpen}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Close...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.THU_CLOSED} time={props.configuration.thuClose}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <p className="d-block d-sm-none pt-30">Open/Close number on Thursday?</p>

                <Toggle
                    className="mt-0"
                    onClick={e => props.changeBespokeHoursHourClose({ day: openCloseTimeConstants.THU_OPEN, isOpen: e })}
                    on={<span>Open</span>}
                    off={<span>Closed</span>}
                    size="sm"
                    offstyle="default"
                    onstyle="success"
                    width="null"
                    height="null"
                    active={props.configuration.openThu}
                    id="ThursdayOpenCloseToggle"
                />

            </div>
            {props.validation.thuClose && props.validation.thuClose.valid === validationStates.INVALID &&
                <div>
                    <div className="clearfix mb-10"></div>
                    <Validator validation={props.validation[fieldNames.THU_CLOSE]}/>
                </div>
            }
        </div>

        {/* FRIDAY */}
        <div className="row pb-20 pt-10 bb-1 mt-10 mb-10">
            <div className="col-12 col-sm-3 col-md-3 pt-10 pb-xs-10">
                <p className="text-xs-lg">Friday</p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Open...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.FRI_OPEN} time={props.configuration.friOpen}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Close...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.FRI_CLOSED} time={props.configuration.friClose}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <p className="d-block d-sm-none pt-30">Open/Close number on Friday?</p>

                <Toggle
                    className="mt-0"
                    onClick={e => props.changeBespokeHoursHourClose({ day: openCloseTimeConstants.FRI_OPEN, isOpen: e })}
                    on={<span>Open</span>}
                    off={<span>Closed</span>}
                    size="sm"
                    offstyle="default"
                    onstyle="success"
                    width="null"
                    height="null"
                    active={props.configuration.openFri}
                    id="FridayOpenCloseToggle"
                />

            </div>
            {props.validation.FriClose && props.validation.FriClose.valid === validationStates.INVALID &&
                <div>
                    <div className="clearfix mb-10"></div>
                    <Validator validation={props.validation[fieldNames.FRI_CLOSE]}/>
                </div>
            }
        </div>

        {/* SATURDAY */}
        <div className="row pb-20 pt-10 bb-1 mt-10 mb-10">
            <div className="col-12 col-sm-3 col-md-3 pt-10 pb-xs-10">
                <p className="text-xs-lg">Saturday</p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Open...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.SAT_OPEN} time={props.configuration.satOpen}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Close...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.SAT_CLOSED} time={props.configuration.satClose}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <p className="d-block d-sm-none pt-30">Open/Close number on Saturday?</p>

                <Toggle
                className="mt-0"
                onClick={e => props.changeBespokeHoursHourClose({ day: openCloseTimeConstants.SAT_OPEN, isOpen: e })}
                on={<span>Open</span>}
                off={<span>Closed</span>}
                size="sm"
                offstyle="default"
                onstyle="success"
                width="null"
                height="null"
                active={props.configuration.openSat}
                id="SaturdayOpenCloseToggle"
            />

            </div>
            {props.validation.SatClose && props.validation.SatClose.valid === validationStates.INVALID &&
                <div>
                    <div className="clearfix mb-10"></div>
                    <Validator validation={props.validation[fieldNames.SAT_CLOSE]}/>
                </div>
            }
        </div>

        {/* SUNDAY */}
        <div className="row pb-20 pt-10 mt-10 mb-10">
            <div className="col-12 col-sm-3 col-md-3 pt-10 pb-xs-10">
                <p className="text-xs-lg">Sunday</p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Open...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.SUN_OPEN} time={props.configuration.sunOpen}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <div className="d-block d-sm-none col-12">
                    <p className="pt-10">Close...</p>
                </div>
                <div className="col-12">
                    <BespokeOpenCloseTimesPicker className="form-control" day={openCloseTimeConstants.SUN_CLOSED} time={props.configuration.sunClose}/>
                </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 pb-xs-20 pl-xs-0 pr-xs-0">
                <p className="d-block d-sm-none pt-30">Open/Close number on Sunday?</p>

                    <Toggle
                        className="mt-0"
                        onClick={e => props.changeBespokeHoursHourClose({ day: openCloseTimeConstants.SUN_OPEN, isOpen: e })}
                        on={<span>Open</span>}
                        off={<span>Closed</span>}
                        size="sm"
                        offstyle="default"
                        onstyle="success"
                        width="null"
                        height="null"
                        active={props.configuration.openSun}
                        id="SundayOpenCloseToggle"
                    />

            </div>
            {props.validation.sunClose && props.validation.sunClose.valid === validationStates.INVALID &&
                <div>
                    <div className="clearfix mb-10"></div>
                    <Validator validation={props.validation[fieldNames.SUN_CLOSE]}/>
                </div>
            }
        </div>
    </div>
)

export default connect(
  state => state.configuration,
  dispatch => bindActionCreators(configurationActions, dispatch)
)(BespokeOpenCloseTimes)
