import React from 'react'
import { Button } from 'react-bootstrap'

interface GetACallbackProps {
  handleShowCallbackForm: () => void
}

const GetACallbackCta = (props: GetACallbackProps) => {
  return (
        <div className="mt-40 mb-30">
            <div className="col bg-light brad-10 box-shadow-v3 p-30 b-1 clearfix">
                <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
                    <span className="text-cta">Talk to us today</span> and get started
                </p>
                <h2 className="text-lg text-400 mb-0 mt-0 text-dark-light">
                    Schedule a quick, no-obligation call &amp; discover...
                </h2>
                <ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
                    <li className="text-md mb-10">
                        <span className="icon icon-checkmark-circle text-success mr-10" />
                        How you could reduce your phone costs
                    </li>
                    <li className="text-md mb-10">
                        <span className="icon icon-checkmark-circle text-success mr-10" />
                        How you can benefit from the advantages of SwitchboardFREE
                    </li>
                    <li className="text-md mb-10">
                        <span className="icon icon-checkmark-circle text-success mr-10" />
                        How the features of SwitchboardFREE can make your business stand out
                    </li>
                </ul>

                <span className="d-inline-block">
                    <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md m-5 mt-0" onClick={() => { props.handleShowCallbackForm() }} id="GetACallbackButton">Get a callback</Button>
                    <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md m-5 mt-0">Call us now</a>
                </span>
            </div>
        </div>
  )
}

export default GetACallbackCta
