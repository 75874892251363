import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { handleNotificationChange } from '../../../store/SBF'

class OptinDetails extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {}

  handleSubmit () {
    const values = {
      phoneOptedIn: document.getElementById('phoneOptedIn').checked,
      postalOptedIn: document.getElementById('postalOptedIn').checked,
      emailOptedIn: document.getElementById('emailOptedIn').checked,
      smsOptedIn: document.getElementById('smsOptedIn').checked
    }

    const url = '/api/Profile/SaveOptinDetails'

    fetchHelper.postJson(url, values).then(res => {
      const data = res.data

      if (data.hasErrors) {
        this.props.notify({
          message: 'Unable to save, please correct and try again',
          isErrorState: true
        })
      } else {
        this.props.notify({ message: 'Details saved' })
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  render () {
    return (
            <div>
                <div className="text-center">
                    <h4 className="pb-20 text-400">Contact Preferences</h4>
                    <p className="pb-20">
                        We'd like to be able to contact you about important updates, new
                        features and promotions.
                        Please select the methods of contact below you would like us to use.
                    </p>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-8 mx-auto text-center">
                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-3">
                                <label className="pt-20 block">SMS</label>
                                <input id="smsOptedIn"
                                    type="checkbox"
                                    defaultChecked={this.props.smsOptedIn}
                                    onPress={event =>
                                      this.props.setFieldValue('smsOptedIn', !this.props.smsOptedIn)
                                    }
                                />
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-3">
                                <label className="pt-20 block">Email</label>
                                <input type="checkbox"
                                    id="emailOptedIn"
                                    defaultChecked={this.props.emailOptedIn}
                                    onPress={event =>
                                      this.props.setFieldValue('emailOptedIn', !this.props.emailOptedIn)
                                    }
                                />
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-3">
                                <label className="pt-20 block">Post</label>
                                <input type="checkbox"
                                    id="postalOptedIn"
                                    defaultChecked={this.props.postalOptedIn}
                                    onPress={event =>
                                      this.props.setFieldValue('postalOptedIn', !this.props.postalOptedIn)
                                    }
                                />
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-3">
                                <label className="pt-20 block">Phone</label>
                                <input type="checkbox"
                                    id="phoneOptedIn"
                                    defaultChecked={this.props.phoneOptedIn}
                                    onPress={event =>
                                      this.props.setFieldValue('phoneOptedIn', !this.props.phoneOptedIn)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 text-center pt-30">
                        <Button variant="cta" type='submit' size="sm" onClick={(e) => this.handleSubmit()}>Save Preferences</Button>
                    </div>
                </div>
            </div>
    )
  }
}

export default connect(state => {
}, { notify: handleNotificationChange })(OptinDetails)
