import React from 'react'
import { Radio, RadioGroup } from 'react-radio-group'
import { connect } from 'react-redux'
import * as configurationActions from '../../store/Configuration'

const OpenCloseTimesQuickSet = props => (
    <div className="col-md-12 pb-50 pt-30 text-center">
        <RadioGroup name="quickSelectOpenClose" selectedValue={props.quickOpenCloseTimeId} id="OpenCloseTimeOptions"
                    onChange={e => props.openCloseQuickTimeSelected({
                      newValue: e,
                      openCloseTimes: props.openCloseTimes
                    })}>
                        <div className='row'>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className=' b-1 brad-10 p-20 mb-10'>
                                    <Radio value="1" id="Open247OpenCloseTimesRadio"/>
                                    <p className="block pt-10">
                                        Open 24/7 (All week)
                                    </p>
                                </div>

                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className=' b-1 brad-10 p-20 mb-10'>
                                    <Radio value="2" id="Open24OpenCloseTimesRadio"/>
                                    <p className="block pt-10">
                                        Open 24h (Mon - Fri)
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className=' b-1 brad-10 p-20 mb-10'>
                                    <Radio value="3" id="Open95OpenCloseTimesRadio"/>
                                    <p className="block pt-10">
                                        Open 9-5 (Mon - Fri)
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className=' b-1 brad-10 p-20 mb-10'>
                                    <Radio value="4" id="BespokeOpeningHoursRadio"/>
                                    <p className="block pt-10">
                                        Bespoke Opening Hours
                                    </p>
                                </div>
                            </div>
                        </div>

        </RadioGroup>
    </div>
)

// export default connect(
//     state => ({
//         quickOpenCloseTimeId: state.configuration.configuration.quickOpenCloseTimeId !== undefined ? state.configuration.configuration.quickOpenCloseTimeId.toString() : undefined,
//         openCloseTimes:{state.configuration.configuration.monOpen}
//     }),
//     {openCloseQuickTimeSelected: configurationActions.openCloseQuickTimeSelected}
// )(OpenCloseTimesQuickSet);

const mapStateToProps = (state) => {
  const configuration = state.configuration.configuration
  const { monOpen, tueOpen, wedOpen, thuOpen, friOpen, satOpen, sunOpen, monClose, tueClose, wedClose, thuClose, friClose, satClose, sunClose } = configuration

  return ({
    quickOpenCloseTimeId: configuration.quickOpenCloseTimeId !== undefined ? configuration.quickOpenCloseTimeId.toString() : undefined,
    openCloseTimes: {
      monOpen,
      tueOpen,
      wedOpen,
      thuOpen,
      friOpen,
      satOpen,
      sunOpen,
      monClose,
      tueClose,
      wedClose,
      thuClose,
      friClose,
      satClose,
      sunClose
    }
  })
}

export default connect(
  mapStateToProps,
  { openCloseQuickTimeSelected: configurationActions.openCloseQuickTimeSelected }
)(OpenCloseTimesQuickSet)
