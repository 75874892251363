import moment from 'moment'
import {
  MultiBuyDiscount,
  MultiBuyDiscountMin,
  NumbersPremiumFilter,
  PhoneTypes,
  VatMultiplier,
  VoipHandsetDiscount,
  billingPeriod
} from '../constants/Constants'
import { BillingPeriodUnit, type Basket } from '../models/SignupTypes'

const _ = require('lodash')

export function calculateVat (priceExcVat) {
  return Math.round((priceExcVat + priceExcVat * VatMultiplier) * 100) / 100
}

export function checkUserHasCallRecording (services) {
  if (services === undefined) {
    return false
  }

  if (
    services.hasCallRecordingAll ||
    services.hasUnlimitedCallRecording ||
    services.hasCallRecordingSingle
  ) { return true }

  return false
}

export function generateSignupKey (): string {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  )
}

export function calculateRemainingMonth(priceExcVat: number, billingDay: number) {
  const min = 0.01
  const currentDateTime = moment()
  const daysInCurrentMonth = currentDateTime.daysInMonth()
  const currentDayInMonth = Number(currentDateTime.format('D'))

  if (billingDay) {
    if (billingDay === currentDayInMonth) { return priceExcVat }

    let billingDate = moment().set('date', billingDay)
    if (currentDateTime.date() >= billingDay)
    // Billing date this month has passed
    { billingDate = billingDate.add(1, 'M') }

    const daysRemaining = billingDate.diff(currentDateTime, 'days')
    const ratePerDay = priceExcVat / daysInCurrentMonth
    const remainingLeftToPay = ratePerDay * daysRemaining

    return Math.max(remainingLeftToPay, min)
  } else {
    const daysRemainingInMonth = daysInCurrentMonth - currentDayInMonth + 1
    const ratePerDay = priceExcVat / daysInCurrentMonth
    const remainingLeftToPay = ratePerDay * daysRemainingInMonth

    return Math.max(remainingLeftToPay, min)
  }
}

export function calculatePartialMonthRefund (priceExcVat, billingDay) {
  const min = 0
  const currentDateTime = moment()
  const daysInCurrentMonth = currentDateTime.daysInMonth()

  let prevBillingDate = moment().set('date', billingDay)
  if (currentDateTime.date() < billingDay)
  // Billing date in future
  // So we need to set prev billing dte to last month
  { prevBillingDate = prevBillingDate.add(-1, 'M') }

  const daysPassed = currentDateTime.diff(prevBillingDate, 'days')
  const ratePerDay = priceExcVat / daysInCurrentMonth
  const refundToApply = priceExcVat - ratePerDay * daysPassed
  return Math.max(refundToApply, min)
}

export function getQueryStringFromProps (props): string {
  if (props && props.match && props.match.params) {
    return props.match.params.queryString
  }
  return ''
}

export function getNumbersForPrefixUrl (
  numbers,
  prefix,
  pageMax,
  numbersPremiumFilter = NumbersPremiumFilter.all,
  premiumOnly = false
) {
  const goldenNumbers = _.filter(numbers, (n) => n.ddipremium === 10)
  const maxGoldenNumber = _.maxBy(goldenNumbers, (n) => n.number)

  const professionalNumbers = _.filter(numbers, (n) => n.ddipremium === 5)
  const maxProfessionalNumber = _.maxBy(professionalNumbers, (n) => n.number)

  const businessNumbers = _.filter(numbers, (n) => n.ddipremium === 0)
  const maxBusinessNumber = _.maxBy(businessNumbers, (n) => n.number)

  return `/api/Purchase/GetNumbersForPrefix/${prefix}?lastGolden=${
    maxGoldenNumber ? maxGoldenNumber.number : ''
  }&lastBusiness=${
    maxBusinessNumber ? maxBusinessNumber.number : ''
  }&lastProfessional=${
    maxProfessionalNumber ? maxProfessionalNumber.number : ''
  }&pageMax=${
    pageMax || 20
  }&numbersPremiumFilter=${numbersPremiumFilter}&premiumOnly=${premiumOnly}`
}

export function getNumbersForRegionUrl (
  numbers,
  ddiLid,
  pageMax,
  numbersPremiumFilter = NumbersPremiumFilter.all,
  premiumOnly = false
) {
  const goldenNumbers = _.filter(numbers, (n) => n.ddipremium === 10)
  const maxGoldenNumber = _.maxBy(goldenNumbers, (n) => n.number)

  const professionalNumbers = _.filter(numbers, (n) => n.ddipremium === 5)
  const maxProfessionalNumber = _.maxBy(professionalNumbers, (n) => n.number)

  const businessNumbers = _.filter(numbers, (n) => n.ddipremium === 0)
  const maxBusinessNumber = _.maxBy(businessNumbers, (n) => n.number)

  return `/api/Purchase/GetNumbersForAreaPosition/${ddiLid}?lastGolden=${
    maxGoldenNumber ? maxGoldenNumber.number : ''
  }&lastBusiness=${
    maxBusinessNumber ? maxBusinessNumber.number : ''
  }&lastProfessional=${
    maxProfessionalNumber ? maxProfessionalNumber.number : ''
  }&pageMax=${
    pageMax || 20
  }&numbersPremiumFilter=${numbersPremiumFilter}&premiumOnly=${premiumOnly}`
}

// quick method for pretty printing a number, phone number validator will perform a 'proper' format
export function simplePhoneNumberFormat (number) {
  if (number && number.length > 0) {
    if (number === 'Withheld' || number === 'withheld') {
      return 'Withheld'
    }

    if (number.startsWith('44')) {
      number = '0' + number.substring(2, number.length)
    }

    if (
      number.substring(0, 3) === '084' ||
      number.substring(0, 3) === '020' ||
      number.substring(0, 2) === '03' ||
      number.substring(0, 3) === '080' ||
      number.substring(0, 4) === '01603'
    ) {
      return `${number.substring(0, 4)} ${number.substring(
        4,
        7
      )} ${number.substring(7)}`
    } else {
      return `${number.substring(0, 5)} ${number.substring(
        5,
        8
      )} ${number.substring(8)}`
    }
  }
}

export function simpleDateTimeFormat (dateTime): string | null {
  if (dateTime) {
    return moment(dateTime).format('DD/MM/YYYY HH:mm')
  }

  return null
}

export function simpleDateFormat (dateTime): string | null {
  if (dateTime) {
    return moment(dateTime).format('DD/MM/YYYY')
  }

  return null
}

// Takes a number of seconds and returns in format hh:mm:ss
export function simpleDurationFormat (seconds) {
  if (seconds) {
    let secondsTmp = seconds

    const hours = Math.floor(secondsTmp / 3600)
    secondsTmp = secondsTmp - hours * 3600
    const minutes = Math.floor(secondsTmp / 60)
    secondsTmp = secondsTmp - minutes * 60
    var seconds = secondsTmp

    let returnString = ''

    returnString = hours + ':'

    if (minutes > 9) {
      returnString = returnString + minutes + ':'
    } else {
      returnString = returnString + '0' + minutes + ':'
    }

    if (seconds > 9) {
      returnString = returnString + seconds
    } else {
      returnString = returnString + '0' + seconds
    }

    return returnString
  }
}

export function getQueryStringByParameter (name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (results === null) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function getFirstNameFromName (name) {
  if (name) {
    const split = name.split(' ')
    return split.length > 0
      ? split[0].charAt(0).toUpperCase() + split[0].slice(1)
      : ''
  }
}

export function getLastNameFromName (name) {
  if (name) {
    const split = name.split(' ')
    return split.length > 1 ? split.slice(1).join(' ') : ''
  }
}

export function isLandlineOrMobile (tel) {
  return (
    tel &&
    (tel.startsWith('441') ||
      tel.startsWith('+441') ||
      tel.startsWith('01') ||
      tel.startsWith('447') ||
      tel.startsWith('+447') ||
      tel.startsWith('07'))
  )
}

export function isObject (value) {
  return value && typeof value === 'object' && value.constructor === Object
}

export function checkNumberType (tel) {
  // Check if landline
  if (tel) {
    if (
      tel.startsWith('441') ||
      tel.startsWith('+441') ||
      tel.startsWith('01') ||
      tel.startsWith('001') ||
      tel.startsWith('442') ||
      tel.startsWith('+442') ||
      tel.startsWith('02') ||
      tel.startsWith('002')
    ) { return PhoneTypes.landline }

    // Not landline. Check if mobile
    if (
      tel.startsWith('447') ||
      tel.startsWith('+447') ||
      tel.startsWith('07') ||
      tel.startsWith('007')
    ) { return PhoneTypes.mobile }
  }

  // Who knows..
  return PhoneTypes.unknown
}

export function numberWithCommas (num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function calculateHandsetPricee (amount: number, users: number, basket: Basket): number {
  let price = amount
  let qty = 0

  basket.hardware.forEach(function (prod) {
    if (prod.amount > 0) { qty = qty += prod.qty }
  })

  if (MultiBuyDiscount > 0 && users >= MultiBuyDiscountMin && qty >= users) {
    price = parseFloat((price - (price * MultiBuyDiscount)).toFixed(2))
  } else if (VoipHandsetDiscount > 0) {
    price = parseFloat((price - (price * VoipHandsetDiscount)).toFixed(2))
  }

  return price
}

export function removeFromArray<T> (allItems: T[], itemToRemove: T): T[] {
  const index = allItems.findIndex((o) => o === itemToRemove)
  return [...allItems.slice(0, index), ...allItems.slice(index + 1)]
}

export function removeFromArrayAtIndex<T> (allItems: T[], index: number): T[] {
  return [...allItems.slice(0, index), ...allItems.slice(index + 1)]
}
export function replaceInArrayAtIndex<T> (
  allItems: T[],
  index: number,
  newItem: T
): T[] {
  return [newItem, ...allItems.slice(index + 1)]
}

export function TryParseJson (json) {
  try {
    return JSON.parse(json)
  } catch (e) {
    return ''
  }
}

// uses regex + substring to extract the prefix from a region label, eg 'Blackpool (01253364)' becomes '01253364'
export function getNumberTypeFromLabel (label: string) {
  const matchJustDigitsWithBracketsRegex = new RegExp(/\({1}(\d+)\){1}/)

  const regExpMatchArray = label.match(matchJustDigitsWithBracketsRegex)
  if (regExpMatchArray?.length ?? 0 > 2) {
    const extractedNumberPrefix = regExpMatchArray![1]
    if (
      extractedNumberPrefix.startsWith('01') ||
      extractedNumberPrefix.startsWith('03')
    ) {
      return extractedNumberPrefix.substring(0, 2)
    }
    return extractedNumberPrefix.substring(0, 4)
  }

  return ''
}

export function getFutureDateDays (days: number): Date {
  const date = new Date()
  date.setDate(date.getDate() + days)
  return date
}

export function getFutureDateMinutes (minutes: number): Date {
  const date = new Date()
  date.setMinutes(date.getMinutes() + minutes)
  return date
}

export function getEmptyBasket (): Basket {
  return {
    isOutbound: true,
    hardware: [],
    numbers: [],
    package: undefined
  }
}

export function getSignupKey (paramKey?: string): string {
  if (paramKey && paramKey !== undefined) {
    localStorage.setItem('signupKey', paramKey)
    return paramKey
  }

  let signupKey = localStorage.getItem('signupKey')
  if (signupKey === null) {
    const newSignupKey = generateSignupKey()
    localStorage.setItem('signupKey', newSignupKey)
    signupKey = newSignupKey
  }
  return signupKey
}

export function arraySum (items: any[], prop: string): number {
  return items.flat().reduce(function (a, b) {
    return b[prop] === null ? a : a + b[prop]
  }, 0)
}

export function formatDate (dateTimeString: string, incYear: boolean, incMonth: boolean, incDay: boolean, incHour: boolean, incMinute: boolean, incSecond: boolean): string {
  if (dateTimeString === null) {
    return ''
  }

  if (dateTimeString === '') {
    return ''
  }

  const date: string = dateTimeString.split('T')[0]
  const time: string = dateTimeString.split('T')[1].split('.')[0]

  const year: string = date.split('-')[0]
  const month: string = date.split('-')[1]
  const day: string = date.split('-')[2]

  const hour: string = time.split(':')[0]
  const minute: string = time.split(':')[1]
  const second: string = time.split(':')[2]

  let response = ''

  if (incDay) {
    response = response.concat(day)
  }

  if (incMonth) {
    if (response !== '') {
      response = response.concat('/')
    }
    response = response.concat(month)
  }

  if (incYear) {
    if (response !== '') {
      response = response.concat('/')
    }
    response = response.concat(year)
  }

  if (response !== '') {
    response = response.concat(' ')
  }

  if (incHour) {
    response = response.concat(hour)
  }

  if (incMinute) {
    if (!response.endsWith(' ')) {
      response = response.concat(':')
    }
    response = response.concat(minute)
  }

  if (incSecond) {
    if (response !== '') {
      response = response.concat(':')
    }
    response = response.concat(second)
  }

  return response.trim()
}

export function formatSeconds (seconds: number): string {
  let response: string = ''

  response = (seconds % 60).toString().concat((seconds % 60) < 2 ? ' seconds' : ' seconds').concat(' ').concat(response)

  if (seconds < 60) {
    return response
  }

  const minutes = (seconds - (seconds % 60)) / 60

  response = (minutes % 60).toString().concat(minutes < 2 ? ' minute ' : ' minutes').concat(' ').concat(response)

  if (minutes < 60) {
    return response
  }

  const hours = (minutes - (minutes % 60)) / 60

  response = (hours % 24).toString().concat(hours < 2 ? ' hours ' : ' hour').concat(' ').concat(response)

  if (hours < 24) {
    return response
  }

  const days = (hours - (hours % 24)) / 24

  response = (days).toString().concat(days < 2 ? ' day' : ' days').concat(' ').concat(response)

  return response
}
