import { Component } from 'react'
import { connect } from 'react-redux'
import { siteFooter } from '../constants/Constants'
import * as fromSbfApp from '../store/SBF'

class DisplayMainFooter extends Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.props.notifyOfFooterChange(siteFooter.MAIN)
  }

  render () {
    return null
  }
}

export default connect(
  state => state,
  {
    notifyOfFooterChange: fromSbfApp.notifyOfFooterChange
  }
)(DisplayMainFooter)
