import moment from 'moment'
import React from 'react'
import { Accordion, Button, Tab, Tabs } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AboutMenu from '../../components/AboutMenu'
import CallbackRequest from '../../components/CallbackRequest'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import StatsBanner from '../../components/StatsBanner'
import { fetchHelper } from '../../helpers/fetchHelper'

class FAQ extends React.Component {
  state = {
    viewModel: undefined,
    activeKey: this.props.match.params.activeTab ? this.props.match.params.activeTab : 'installation'
  }

  constructor (props) {
    super(props)

    this.setState({
      viewModel: undefined
    })
  }

  render () {
    const isLoggedIn = localStorage.getItem('user') !== null

    return this.state.viewModel !== undefined ? <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />
            <AboutMenu />
            <Helmet>
                <html itemScope itemType="https://schema.org/FAQPage"></html>
                <title>FAQ</title>
                <meta name="description" content="Get a virtual number on the UK's favourite free call management system." />
                <style type="text/css">{`
                .girl-img2 {
                    position:relative;
                    border:10px solid #ff9314;
                    border-radius:50%;
                    bottom:0;
                    height:360px;
                    width:360px;
                    top:30px;
                  }
                  .girl-img2 {
                    right:-12%;
                  }

                  @media screen and (min-width:601px) {
                   .girl-img2 {
                      position:absolute;
                      max-width:100%;
                      border-radius:0;
                      top:unset;
                      border:0;
                    }
                    .girl-img2 {
                      right:10%;
                    }
                  }
                  @media screen and (min-width:768px) {
                    .girl-img2 {
                      height:580px;
                      width:auto;
                      right:-10%;
                    }
                  }
                  
                  @media screen and (min-width:992px) {
                    .girl-img {
                      left:0%;
                    }
                    .girl-img2 {
                      right:-7%;
                    }
                  }
                  @media screen and (min-width:1200px) {
                    .girl-img2 {
                      right:5%;
                    }
                  }
                  @media screen and (min-width:1600px) {
                    .girl-img2 {
                      right:20%;
                    }
                  }
                  @media screen and (min-width:1920px) {
                    .girl-img2 {
                      right:26%;
                    }
                  }
            }
            `}</style>
            </Helmet>

            <header style={{ backgroundImage: "url('/images/backgrounds/faq-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1 id="FAQH1">Frequently Asked Questions</h1>
                <h2 className=" subtitle">Got a question? Find all the answers about SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pb-60">
                <div className="container">
                    {/* <div className="row">
                        <div className="col-md-12 text-center pb-30">
                            <h1>FAQs</h1>
                            <p>
                                Browse the topics below and find the answer to your query.
                        </p>
                        </div>
                    </div> */}

                    <div className="row">
                        <Tabs defaultActiveKey={1} className="tabs-container faq-tabs justify-content-center">
                            {this.state.viewModel.tabs.map((tabName, tabIndex) => (
                                <Tab eventKey={tabIndex + 1} title={tabName}>
                                    <Accordion accordion>
                                        {this.state.viewModel.questions[tabIndex].map((panelName, panelIndex) => (
                                            <Accordion.Item eventKey={panelIndex} className="panel-unstyled" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                                <Accordion.Header className="text-start text-400 pl-10" toggle>
                                                    <span itemProp="name">{panelName}</span>
                                                </Accordion.Header>
                                                <Accordion.Body collapsible itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                                    <div itemProp="text" dangerouslySetInnerHTML={{ __html: this.state.viewModel.answers[tabIndex][panelIndex] }} />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>

                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </div>
            </div>
            <div className="full-width bg-dull pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500">Found your answer?</h3>
                            <p className="pb-20">
                                Join SwitchboardFREE today!
                            </p>
                            <p>
                                <Link to={ isLoggedIn === false ? '/get-started' : '/customer/numbers/new'} className="btn btn-lg btn-cta">See our plans</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width full-secondary pb-50 pt-40 pr-20">
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-6 mx-auto text-center">
                            <h3 className="text-500 pt-xxs-30">
                                Get in touch
                            </h3>
                            <p>Got a question that needs answering? Our dedicated UK based support team are available 8.30am to 6pm every weekday and are happy to help.</p>

                            <p className="lead mt-20">
                                <span className="icon icon-phone mr-10" /><a href="tel:02031891213">0203 189 1213</a>
                            </p>
                            <p className="lead">
                                <span className="icon icon-envelop mr-10" /><a href="mailto:support@switchboardfree.co.uk">support@switchboardfree.co.uk</a>
                            </p>
                            <p>
                                {moment().day() === 6 || moment().day() === 0
                                // Weekend
                                  ? <CallbackRequest />
                                  :
                                // Not a weekend, double check hours
                                  moment().hour() >= 9 && moment().hour() < 18
                                  // Office hours
                                    ? <Button variant="cta" size="large" className="open-live-chat mt-5">Chat now</Button>
                                    :
                                        // Out of hours
                                        <CallbackRequest />
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull pt-50">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>
            <div className="full-width full-secondary p-20">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-center text-lightest mt-10 text-100 text-xl">
                            <StatsBanner />
                        </h3>
                    </div>
                </div>
            </div>
            <ClientLogos showText={true} />
        </>
      : <div className="text-center p-30">
                <img id="loading" src="images/icons/loading.svg" width="100" height="100" />
            </div>
  }

  componentDidMount () {
    const url = '/api/home/GetFaqModel'

    fetchHelper.getJson(url).then(res => {
      this.setState({
        viewModel: res.data
      })

      const tabs = document.getElementsByClassName('nav-tabs')
      tabs[0].classList.add('nav-tabs-centered col-md-2')
      const wrapper = document.createElement('div')
      wrapper.classList.add('tabs-container xx')
      tabs[0].parentNode.insertBefore(wrapper, tabs[0])
      wrapper.appendChild(tabs[0])
    }).catch(function (error) {
      console.error(error)
    })
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(FAQ)
