import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { siteFooter } from '../constants/Constants'
import { handleUpdateEnabledServices } from '../store/SBF'
import CallbackRequest from './CallbackRequest'
import RefreshUserServices from './RefreshUserServices'

class LoggedInFooter extends Component {
  state = {
    test: this.props.ShowLoggedInFooter
  }

  render () {
    if (this.props.ShowLoggedInFooter) {
      return (
                <>
                    <RefreshUserServices />
                    <footer id="footerMain" className="p-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-sm-7">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6 col-xs-6 col-xxs-12 text-center text-sm-start">
                                            <p className="text-500 pt-xxs-30">
                                                Products
                                            </p>
                                            <ul>
                                                <li className="pb-10">
                                                    <Link to={'/customer/numbers'} id="MyNumbersLoggedInFooterLink">My Numbers</Link>
                                                </li>
                                                <li className="pb-10">
                                                    <Link to={'/customer/my-calls/call-stats/'} id="CallStatsLoggedInFooterLink">Call Stats</Link>
                                                </li>
                                                <li className="pb-10">
                                                    <Link to={'/web-hook-settings/'} id="WebhooksSettingsLoggedInFooterLink">Webhooks settings</Link>
                                                </li>
                                                <li className="pb-10">
                                                    <Link to={'/customer/upgrades/all'} id="UpgradesLoggedInFooterLink">Upgrades</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-xs-6 col-xxs-12 text-center text-sm-start">
                                            <p className="text-500 pt-xxs-30">
                                                Resources
                                            </p>
                                            <ul>
                                                <li className="pb-10">
                                                    <Link to={'/privacy-policy'} id="PrivacyPolicyLoggedInFooterLink1">Privacy policy</Link>
                                                </li>
                                                <li className="pb-10">
                                                    <Link to={'/terms-and-conditions'} target="_blank" id="TermsAndConditionsLoggedInFooterLink1">Terms and Conditions</Link>
                                                </li>
                                                <li className="pb-10">
                                                    <Link to={'/contact/'} id="HelpLoggedInFooterLink">Help</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-5 text-center text-md-end">
                                    <p className="mb-20">
                                        <a className="text-md text-500 text-secondary" href="tel:02031891213">0203 189 1213</a>
                                    </p>
                                    <p className="mt-20">
                                        <a className="text-sm" href="mailto:support@switchboardfree.co.uk">Email us</a>
                                        <br className="d-block d-sm-none" />
                                        <span className="pr-20 pl-20 text-xs text-muted d-none d-sm-inline-block">|</span>
                                        <a className="text-sm open-live-chat">Chat to us</a>
                                        <br className="d-block d-sm-none" />
                                        <span className="pr-20 pl-20 text-xs text-muted d-none d-sm-inline-block">|</span>
                                        <CallbackRequest textLink={true} className="text-sm" />
                                    </p>
                                    <ul className="social-icons">
                                        <li>
                                            <a href="https://www.facebook.com/SwitchboardFREEUK" rel="noopener" target="blank" className="facebook" >
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.twitter.com/SwitchboardFREE" rel="noopener" target="blank" className="twitter">
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/switchboardfree" rel="noopener" target="blank" className="linkedin">
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/subscription_center?add_user=SwitchboardFREE" rel="noopener" target="blank" className="youtube">
                                            </a>
                                        </li>
                                    </ul>
                                    <p>
                                        <a href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREE&hl=en_GB" rel="noopener"><img className="app-img mr-10 mb-5" src={'images/g-play.svg'} /></a>
                                        <a href="https://itunes.apple.com/gb/app/sbf-2012/id556878596" rel="noopener"><img className="app-img mb-5" src={'images/app-store.svg'} /></a>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 col-lg-6 text-xs">
                                            <img src={'/images/logo/sflogo.svg'} alt="SwitchboardFREE" className="mr-30 float-start logo" height="15" />
                                            <span className="inline-block mr-30"><Link to={'/terms-and-conditions'} target="_blank" id="TermsAndConditionsLoggedInFooterLink2">Terms and conditions</Link></span>
                                            <span className="inline-block mr-30"><Link to={'/privacy-policy'} id="PrivacyPolicyLoggedInFooterLink2">Privacy policy</Link></span>
                                            <span className="inline-block"><Link to={'/sitemap'} id="SiteMapLoggedInFooterLink">Site map</Link></span>
                                            <p className="text-xs mt-20 text-start">
                                                &copy; {(new Date().getFullYear())} Liquid11, Sea Lake Road, Lowestoft, Suffolk, NR32 3LQ, Company number: 04404380 Regulated by Ofcom.
                                        </p>
                                        </div>
                                        <div className="col-12 col-lg-6 text-center text-md-start mt-xs-30 mt-sm-30">
                                            <p className="footer-logos">
                                                <img src={'/images/future50.png'} alt="Future50 Winners" />
                                                <img src={'/images/years/years-stamp-19.png'} alt="SwitchboardFREE - Established in 2005" />
                                                <img src={'/images/carbon2.jpg'} alt="Suffolk Carbon Charter Gold" />
                                                <img src={'/images/prs-logo.png'} alt="PRS logo" />
                                                <img src={'/images/cyber-essentials.png'} alt="Cyber Essentials Plus badge" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </>
      )
    } else {
      return null
    }
  }
}

export default connect(
  state => {
    return {
      ShowLoggedInFooter: state.sbfApp.visibility.FOOTER === siteFooter.LOGGEDIN,
      hasContactBook: state.sbfApp.enabledServices.hasContactBook,
      hasUmr: state.sbfApp.enabledServices.hasUmr,
      hasCallBlocking: state.sbfApp.enabledServices.hasCallBlocking
    }
  }, {
    handleUpdateEnabledServices
  }
)(LoggedInFooter)
