import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import LiveChatBlock from '../../components/LiveChatBlock'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

class AppPage extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount/><DisplayMainFooter/><DisplayMainHeader/>
            <Helmet>
                <title>FREE VoIP Calling Android and iPhone apps</title>
                <meta name="description" content="Start making and receiving calls using your virtual number, anywhere in the world." />
                <style type="text/css">{`
                    .phoneapp-bg{
                        right:0;
                    }
                    .adminapp-bg{
                        left:0;
                    }
                    @media screen and (max-width:1550px) {
                        .phoneapp-bg {
                            right:-100px;
                        }
                        .adminapp-bg {
                            left:-100px;
                        }
                    }
                    @media screen and (max-width:992px) {
                        .phoneapp-bg {
                            right:-290px;
                        }
                        .adminapp-bg {
                            left:-250px;
                        }
                    }
				`}</style>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/8-competitor-bg.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="AppsH1">iOS &amp; Android apps</h1>
                <h2 className=" subtitle">Run your business from your mobile</h2>
            </header>
            <div className="full-width bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pb-20">
                            <h2 className="text-500 h3">
                                Stay in control. Anywhere. Anytime.
                            </h2>
                            <p className="w-800px center-block">
                            Turn your mobile into your business line and complete phone system, with our Admin and Phone apps.
                            Access your control panel, and make and recive calls, from anywhere, at any time.
                            Essential for any modern business. Download today for iOS and Android.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width bg-light pb-0 pt-0 min-h-700px min-h-xs-unset" id="phoneapp">
                <img src="/images/phone-app-bg-xs.jpg" className="d-block d-sm-none w-100 center-block" />
                <img src="/images/phone-app-bg.jpg" className="absolute phoneapp-bg d-none d-sm-block" />
                <div className="container">

                    <div className="row">
                        <div className="col-md-6 col-sm-6 pt-30 pl-lg-60 pl-md-15">
                            <h2 className="text-500 h3">SwitchboardFREE Phone App (FREE)
                            {/* <span className="badge badge-lg cta-gradient">FREE!</span> */}
                            </h2>
                            <p className="lead text-400">
                                The SwitchboardFree phone app is ideal for making and receiving calls on the go.
                            </p>
                            <p>
                                You can sync your existing contact list with a single click and even change the phone number you present when dialling out.
                            </p>
                            <p>
                                Whilst on a call, you can put your caller on hold, mute or quickly transfer a call from the menu screen giving you full business phone functionality in the palm of your hand.
                            </p>
                            <p>
                                The SwitchboardFree phone app is also perfect for holiday use and international travel helping you to avoid unnecessary call charges by making and receiving calls at local rates.
                            </p>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0 pt-xs-0">
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                        Choose the number you want to present when dialling out
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                        Transfer calls to any of your team at the tap of a button
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                        Easily access your existing contacts
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-xs-0 pt-sm-0">
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                        Crystal clear audio quality
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                        Put callers on hold with a single tap
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                        New &amp; improved look for a fresher, cleaner feel.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p className="pb-30">
                                <a target="_blank" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '100%', width: '180px' }} className="m-5" /></a>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '100%', width: '180px' }} className="m-5" /></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ImageLeftFullWidth
                ImgURL="/images/backgrounds/guy1.jpg"
                MainContentClass="bg-secondary pt-60 pb-60 pt-xs-0 pb-xs-30"

                TextCopy={
                    <>
                        <h2 className="text-500 mb-30 mt-0 pt-xs-40 text-lightest">Good for Business</h2>
                        <p className="text-400 text-md">
                                Phone calls are critical to your business. Never miss a call again and instantly upgrade your audio branding whilst routing the call to your staff wherever they may be.
                            </p>
                            <p className="text-400 pt-20">
                                With over {this.state.totalCustomers} UK customers, 15 years of experience and a 5-star rated UK customer service team that picks up the phone within seconds of your call, your business couldn't be in better hands.
                            </p>
                            <p>
                                <Link to={'business-voip-phone-system'} className="btn btn-lg btn-cta mt-20 mb-30">Find out more</Link>
                            </p>
                        </>
                    }

            /> */}
            <div className="full-width bg-dull pt-0 pb-0 min-h-700px min-h-xs-unset" id="adminapp">
                <img src="/images/admin-app-bg-xs.jpg" className="d-block d-sm-none w-100 center-block" />
                <img src="/images/admin-app-bg.jpg" className="absolute adminapp-bg d-none d-sm-block" />
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                        <div className="col-md-6 col-sm-6 float-none float-sm-end pt-40">
                            <h2 className="text-500 h3">SwitchboardFREE Management App (FREE)</h2>
                            <p className="lead text-400">
                                The SwitchboardFree Management app is perfect for updating your account settings calls on the go.
                            </p>
                            <p>
                                With our SwitchboardFREE management app you have total control over your account, allowing you to manage your inbound calls and configure your number settings, all while on the go.
                            </p>
                            <p>
                                Update your redirect numbers, on hold music or customise your greetings.
                           </p>
                            <p>
                                See all missed calls and listen to voicemails all in one place and whilst on the move.
                            </p>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0 pt-xs-0">
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            See a list of recent calls or listen to your voicemails
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Call back missed calls with a single click
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Sync you existing phone contacts with your SwitchboardFREE contacts
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-xs-0 pt-sm-0">
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Change open hours and redirect numbers
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Record custom prompts or change your on hold music
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Quick close your numbers
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p className="text-400 text-md pb-30 text-start">
                                <a target="_blank" href="https://apps.apple.com/gb/app/sbf-2012/id556878596" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '100%', width: '180px' }} className="m-5" /></a>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREE&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '100%', width: '180px' }} className="m-5"/></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <LiveChatBlock style="secondary" />

    </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(AppPage)
