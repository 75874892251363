import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { isValidEmailSyntax, isValidNameSyntax, isValidTelSyntax } from '../../../helpers/validationHelpers'
import * as sbfApp from '../../../store/SBF'
import LogoutButton from '../../LogoutButton'

class ContactDetails extends Component {
  static defaultProps = {}

  static propTypes = {}
  
  state = {
    oldEmail: '',
    title: '',
    firstName: '',
    firstNameValid: true,
    lastName: '',
    lastNameValid: true,
    email: '',
    emailValid: true,
    confirmEmail: '',
    confirmEmailValid: true,
    landline: '',
    landlineValid: true,
    mobile: '',
    mobileValid: true,
    fax: '',
    faxValid: true,
    hasManagedAccounts: false
  }

  componentDidMount () {
    this.handleTitleChanged(this.props.salutation)
    this.handleFirstNameChanged(this.props.fname)
    this.handleLastNameChanged(this.props.sname)
    this.handleEmailChanged(this.props.email)
    this.handleConfirmEmailChanged(this.props.email)
    this.handleLandlineChanged(this.props.telephone)
    this.handleMobileChanged(this.props.mobile)
    this.handleFaxChanged(this.props.fax)
    this.setState({ oldEmail: this.props.email })

        
    fetchHelper.getJson('/api/users/HasManagedAccounts/').then((res) =>{
        const data = res.data;
        this.setState({hasManagedAccounts: data});
    })
  }

  handleTitleChanged (value) {
    this.setState
    ({
      title: value
    })
  }

  handleFirstNameChanged (value) {
    const isValid = isValidNameSyntax(value)

    this.setState
    ({
      firstName: value,
      firstNameValid: isValid
    })
  }

  handleLastNameChanged (value) {
    const isValid = isValidNameSyntax(value)

    this.setState
    ({
      lastName: value,
      lastNameValid: isValid
    })
  }

  handleEmailChanged (value) {
    const isValid = isValidEmailSyntax(value)
    let confirmEmailValid = true

    if(value !== this.state.confirmEmail){
      confirmEmailValid = false
    }

    this.setState
    ({
      email: value,
      emailValid: isValid,
      confirmEmailValid: confirmEmailValid
    })
  }

  handleConfirmEmailChanged (value) {
    let isValid = isValidEmailSyntax(value)

    if(value !== this.state.email && this.state.confirmEmail !== ''){
      isValid = false
    }

    this.setState
    ({
      confirmEmail: value,
      confirmEmailValid: isValid
    })
  }

  handleLandlineChanged (value) {
    const isValid = isValidTelSyntax(value)

    this.setState
    ({
      landline: value,
      landlineValid: isValid
    })
  }

  handleMobileChanged (value) {
    const isValid = isValidTelSyntax(value)

    this.setState
    ({
      mobile: value,
      mobileValid: isValid
    })
  }

  handleFaxChanged (value) {
    const isValid = true

    this.setState
    ({
      fax: value,
      faxValid: isValid
    })
  }

  handleSubmitPressed () {
    let proceed = true

    //Valid that the email is valid
    if(!this.state.emailValid || this.state.email !== this.state.confirmEmail) {
      this.props.notify({
        message: 'Unable to save, please check and correct the entered email address and try again',
        isError: true
      })

      return;
    }

    const emailChanged = this.state.oldEmail !== this.state.email
    if (emailChanged) {
      proceed = window.confirm('You are changing your account email address. You will be required to login again with the new email after the change. Are you sure?')
    }

    if (proceed) {
      const url = '/api/Profile/SaveProfile'

      const data = {
			    email: this.state.email,
			    confirmEmail: this.state.confirmEmail,
			    firstName: this.state.firstName,
			    fax: this.state.fax,
			    landline: this.state.landline,
			    mobile: this.state.mobile,
			    surName: this.state.lastName,
			    salutation: this.state.title
      }

      fetchHelper.postJson(url, data).then(res => {
        const data = res.data

        if (data.hasErrors === true) {
          if (data.errors) {
            this.props.notify({
              message: 'Unable to save, please correct and try again',
              isError: true
            })
          } else {
            alert('Validation Error')
          }
        }

        if (data.hasErrors) {
          this.props.notify({
            message: data.errors.generic ? data.errors.generic : 'Unable to save, please correct and try again',
            isError: true
          })
        } else {
          this.props.notify({ message: 'Profile saved' })
          if (emailChanged) {
            this.props.logout({ history: this.props.history })
          }
        }
      }).catch(function (error) {
        console.error(error)
      })
    }
  }

  render () {
    const invalidBorderStyling = '2px solid #e84118'

    return (
            <>
                {this.props.displayUserDetailsOnly
                  ? <>

                        <div className='w-300px bg-light box-shadow-v3 b-1 p-20 brad-20 profile-box' style={{ visibility: 'hidden' }}>
                            <div className='text-left pb-20 bb-1 mb-10 pl-10'>
                                <span className='block text-500 pb-2 trim-230px'>
                                    {this.props.fname} {this.props.sname}
                                </span>
                                <span className='block text-sm text-400'>
                                    <span className='trim-230px'>
                                        {this.props.email}
                                    </span>
                                </span>
                            </div>
                            {this.state.hasManagedAccounts === true && <Link to={'/customer/swap-account'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsSwapAccountsLink">
                                <span className='icon icon-transmission2 text-dark-lighter mr-10'></span> Switch Account
                            </Link>}
                            <Link to={'/customer/profile/your-details'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsProfileLink">
                                <span className='icon icon-user text-dark-lighter mr-10'></span> Contact Details
                            </Link>
                            <Link to={'/customer/profile/billing'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsBillingLink">
                                <span className='icon icon-stack text-dark-lighter mr-10'></span> Billing &amp; Invoices
                            </Link>
                            <Link to={'/customer/profile/usage'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsCallUsage">
                                <span className='icon icon-stats-dots text-dark-lighter mr-10'></span> Call Usage &amp; Charges
                            </Link>
                            <Link to={'/customer/profile/security'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsYourPassword">
                                <span className='icon icon-lock5 text-dark-lighter mr-10'></span> Security
                            </Link>
                            <Link to={'/customer/profile/subscriptions-and-numbers'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsSubscriptionsNumbers">
                                <span className='icon icon-list-numbered text-dark-lighter mr-10'></span> Subscriptions &amp; Numbers
                            </Link>
                            <Link to={'/customer/profile/call-recording-settings'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsCallRecordingSettings">
                                <span className='icon icon-cassette text-dark-lighter mr-10'></span> Call Recording settings
                            </Link>
                            <Link to={'/customer/profile/emergency-contacts'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsEmergencyContact">
                                <span className='icon icon-alarm2 text-dark-lighter mr-10'></span> Emergency Contact Info
                            </Link>
                            <Link to={'/setup/'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsSetupInstructions">
                                <span className='icon icon-wrench3 text-dark-lighter mr-10'></span> Setup &amp; Phone Instructions
                            </Link>
                            <Link to={'/customer/profile/tps-protection-settings'} className='block text-sm text-400 p-10 brad-10' id="ContactDetailsTpsProtectionSettings">
                                <span className='icon icon-phone-outgoing text-dark-lighter mr-10'></span> TPS Protection settings
                            </Link>
                            <div className='block text-sm text-400 pl-10 pr-10 pb-10 pt-25 bt-1 mt-20'>
                                <span className='icon icon-exit text-dark-lighter mr-10'></span> <LogoutButton isMobile={true} classCustom="text-dark"/>
                            </div>
                        </div>
                    </>
                  : <>
                        <h4 className="pb-20 text-center text-400">Contact Details</h4>
                        <div className="row">
                            <div className="col-xs-6 col-xxs-12">
                                <label>Title</label>
                                <select className="form-control" name='salutation' value={this.state.title} onChange={(e) => this.handleTitleChanged(e.target.value)}>
                                    <option>Mr</option>
                                    <option>Mrs</option>
                                    <option>Miss</option>
                                    <option>Dr</option>
                                    <option>Mx</option>
                                </select>
                            </div>
                            <div className="col-xs-6 col-xxs-12">
                                <label>First name</label>
                                <input type="text"
                                    id={this.state.firstNameValid ? "FirstNameInput" : "InvalidFirstNameInput"}
                                    name="firstName"
                                    value={this.state.firstName}
                                    maxLength={20}
                                    className="form-control"
                                    placeholder="eg. John"
                                    style={{ border: this.state.firstNameValid ? '' : invalidBorderStyling }}
                                    onChange={(e) => this.handleFirstNameChanged(e.target.value)}
                                />
                            </div>
                            <div className="col-xs-6 col-xxs-12">
                                <label>Surname</label>
                                <input
                                    id={this.state.lastNameValid ? "LastNameInput" : "InvalidLastNameInput"}
                                    type="text"
                                    className="form-control"
                                    name="surName"
                                    value={this.state.lastName}
                                    placeholder="eg. Smith"
                                    maxLength={30}
                                    style={{ border: this.state.lastNameValid ? '' : invalidBorderStyling }}
                                    onChange={(e) => this.handleLastNameChanged(e.target.value)}
                                />
                            </div>

                            <div className="col-xs-6 col-xxs-12">
                                <label>Email</label>
                                <input
                                    id={this.state.emailValid ? "EmailInput" : "InvalidEmailInput"}
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    className="form-control"
                                    placeholder="eg. john@example.com"
                                    maxLength={128}
                                    style={{ border: this.state.emailValid ? '' : invalidBorderStyling }}
                                    onChange={(e) => this.handleEmailChanged(e.target.value)}
                                />
                            </div>
                            <div className="col-xs-6 col-xxs-12">
                                <label>Confirm email</label>
                                <input
                                    id={this.state.confirmEmailValid ? "ConfirmEmailInput" : "InvalidConfirmEmailInput"}
                                    type="email"
                                    name="confirmEmail"
                                    value={this.state.confirmEmail}
                                    className="form-control"
                                    placeholder="eg. john@example.com"
                                    maxLength={128}
                                    style={{ border: this.state.confirmEmailValid ? '' : invalidBorderStyling }}
                                    onChange={(e) => this.handleConfirmEmailChanged(e.target.value)}
                                />
                            </div>

                            <div className="col-xs-6 col-xxs-12">
                                <label>Landline number</label>
                                <input
                                    id={this.state.landlineValid ? "LandlineInput" : "InvalidLandlineNameInput"}
                                    type="tel"
                                    name="landline"
                                    className="form-control"
                                    placeholder="eg. 02031891213"
                                    value={this.state.landline}
                                    maxLength={128}
                                    style={{ border: this.state.landlineValid ? '' : invalidBorderStyling }}
                                    onChange={(e) => this.handleLandlineChanged(e.target.value)}
                                />
                            </div>
                            <div className="col-xs-6 col-xxs-12">
                                <label>Mobile number</label>
                                <input
                                    id={this.state.mobileValid ? "MobileInput" : "InvalidMobileInput"}
                                    type="tel"
                                    name="mobile"
                                    className="form-control"
                                    placeholder="eg. 07445605577"
                                    value={this.state.mobile}
                                    style={{ border: this.state.mobileValid ? '' : invalidBorderStyling }}
                                    onChange={(e) => this.handleMobileChanged(e.target.value)}
                                />
                            </div>
                            <div className="col-xs-6 col-xxs-12">
                                <label>Fax number</label>
                                <input
                                    id={this.state.mobileValid ? "FaxInput" : "InvalidFaxInput"}
                                    type="tel"
                                    name="fax"
                                    className="form-control"
                                    placeholder="eg. 07445605577"
                                    value={this.state.fax}
                                    style={{ border: this.state.faxValid ? '' : invalidBorderStyling }}
                                    onChange={(e) => this.handleFaxChanged(e.target.value)}
                                />
                            </div>
                            <div className="col-xs-12 text-center pt-30">
                                <Button id="SaveProfileChangesButton" variant="cta" type='submit' size="sm" onClick={(e) => this.handleSubmitPressed()}>Save Profile Changes</Button>
                            </div>
                        </div>
                    </>
                }
            </>
    )
  }
}

export default connect(
  state => {},
  {
    notify: sbfApp.handleNotificationChange,
    logout: sbfApp.logout
  }
)(ContactDetails)
