import { type AxiosResponse } from 'axios'
import React, { useEffect } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link, type RouteComponentProps } from 'react-router-dom'
import DisplayNoFooter from '../../components/DisplayNoFooter'
import DisplaySimpleHeaderV2 from '../../components/DisplaySimpleHeaderV2'
import LoadingNotification from '../../components/LoadingNotification'
import ProgressSteps from '../../components/ProgressSteps'
import BadgeWidget from '../../components/Reviews/BadgeWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import Validator from '../../components/Validator'
import {
  CompanyNameLimit, EmailLimit, FirstNameLimit,
  LastNameLimit, NameLimit, PhoneTypes,
  validationStates, type SignupStep
} from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { checkNumberType } from '../../helpers/utils'
import { isValidEmailSyntax, isValidNameSyntax, isValidTelSyntax } from '../../helpers/validationHelpers'
import { type Basket, type SignupProfile } from '../../models/SignupTypes'
import { GoogleCredentialAuthResponse } from '../../models/OAuth'
import TermsAndConditions from '../../Text/TermsAndConditions'
import GoogleLogin from "../../components/Login/GoogleLogin"
import LinkedinLogin from "../../components/Login/LinkedinLogin"

declare const window: any

interface SignupPageProps extends RouteComponentProps {
  firstName: string
  lastName: string
  email: string
  phone: string
  basket: Basket
  company: string
  handleUpdateSignupFirstName: (name: string) => void
  handleUpdateSignupLastName: (name: string) => void
  handleUpdateSignupEmail: (name: string) => void
  handleUpdateSignupPhone: (name: string) => void
  handleUpdateSignupCompany: (name: string) => void
  handlePartialSignup: (signup: SignupProfile) => void
  handleBack: (step?: SignupStep) => void
  handleAuthCredentialsReceived: (firstName: string, lastName: string, email: string, platform: string) => void
}

interface SignupPageState {
  showTermsWarning: boolean
  acceptedTerms: boolean
  showTsAndCs: boolean
  canProgress: boolean
  showGenericError: boolean
  errorMsg: string
  firstNameValidationState: validationStates
  lastNameValidationState: validationStates
  phoneValidationState: validationStates
  emailValidationState: validationStates
  emailAlreadyInUse: validationStates
  focusFirstName: boolean
  focusLastName: boolean
  focusPhone: boolean
  focusEmail: boolean
  focusCompany: boolean
  isProcessing: boolean
  skip: boolean
  alreadyHasNumbers: boolean
  showForm: boolean
  showPartialForm: boolean
  socialLoginUsed?: string
}

interface ValidationState {
  firstNameValidationState: validationStates
  lastNameValidationState: validationStates
  phoneValidationState: validationStates
  emailValidationState: validationStates
  isValidating: boolean
}

const SignupPage: React.FunctionComponent<SignupPageProps> = (props: SignupPageProps): JSX.Element => {
  const initialState: SignupPageState = {
    showTermsWarning: false,
    acceptedTerms: false,
    showTsAndCs: false,
    canProgress: !!((props.firstName && props.lastName && props.email)),
    showGenericError: false,
    errorMsg: '',
    firstNameValidationState: validationStates.UNCHECKED,
    lastNameValidationState: validationStates.UNCHECKED,
    phoneValidationState: validationStates.UNCHECKED,
    emailValidationState: validationStates.UNCHECKED,
    emailAlreadyInUse: validationStates.UNCHECKED,
    focusFirstName: false,
    focusLastName: false,
    focusPhone: false,
    focusEmail: false,
    focusCompany: false,
    isProcessing: false,
    skip: false,
    alreadyHasNumbers: false,
    showForm: true,
    showPartialForm: false
  }

  const initialValidationState: ValidationState = {
    firstNameValidationState: validationStates.UNCHECKED,
    lastNameValidationState: validationStates.UNCHECKED,
    phoneValidationState: validationStates.UNCHECKED,
    emailValidationState: validationStates.UNCHECKED,
    isValidating: false
  }

  const [state, setState] = React.useState<SignupPageState>(initialState)
  const [validationState, setValidationState] = React.useState<ValidationState>(initialValidationState)

  function handleUserWantsToContinue() {
    setState({ ...state, showTermsWarning: false })
    setValidationState({ ...validationState, isValidating: true })

    const firstName = props.firstName ? props.firstName : ''
    const firstNameValidationState = validateName(firstName)
    const lastName = props.lastName ? props.lastName : ''
    const lastNameValidationState = validateName(lastName)
    const email = props.email ? props.email : ''

    // create a promise which will resolve when the email is proven valid or invalid
    const isEmailValid = getValidateEmailPromise(email)
    // create a promise which will resolve when the phone number is proven valid or invalid
    const isPhoneValid = getValidatePhonePromise(props.phone ? props.phone : '')

    // wait for the two promises to resolve
    Promise.all([isEmailValid, isPhoneValid]).then((values: validationStates[]) => {
      // update the validation state for all the fields
      const emailValidationStateResult = values[0]
      const phoneValidationStateResult = values[1]

      setValidationState({
        ...validationState,
        firstNameValidationState,
        lastNameValidationState,
        emailValidationState: emailValidationStateResult,
        phoneValidationState: phoneValidationStateResult,
        isValidating: false
      })

      const userDetails: SignupProfile = {
        name: `${props.firstName} ${props.lastName}`,
        email: props.email,
        marketingSource: '',
        company: props.company,
        phone: props.phone
      }

      if (!props.firstName || props.firstName && props.firstName.length === 0) {
        setState({ ...state, showGenericError: true, errorMsg: 'Please enter your name', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (props.firstName && props.firstName.length > NameLimit) {
        setState({ ...state, showGenericError: true, errorMsg: 'Your name is too long', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (props.lastName && props.lastName.length > NameLimit) {
        setState({ ...state, showGenericError: true, errorMsg: 'Your name is too long', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (firstNameValidationState !== validationStates.VALID) {
        setState({ ...state, showGenericError: true, errorMsg: 'Please enter a valid first name', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (lastNameValidationState !== validationStates.VALID) {
        setState({ ...state, showGenericError: true, errorMsg: 'Please enter a valid last name', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (!props.phone || props.phone && props.phone.length === 0) {
        setState({ ...state, showGenericError: true, errorMsg: 'Please enter your phone number', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (phoneValidationStateResult !== validationStates.VALID) {
        setState({ ...state, showGenericError: true, errorMsg: 'Please enter a valid telephone number', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (props.company && props.company.length > CompanyNameLimit) {
        setState({ ...state, showGenericError: true, errorMsg: 'Your company name is too long', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (!props.email || props.email && props.email.length === 0) {
        setState({ ...state, showGenericError: true, errorMsg: 'Please enter your email address', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (props.email && props.email.length > EmailLimit) {
        setState({ ...state, showGenericError: true, errorMsg: 'Your email address is too long', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else if (emailValidationStateResult !== validationStates.VALID) {
        setState({ ...state, showGenericError: true, errorMsg: 'Please enter a valid email address', showTermsWarning: false, showForm: true, showPartialForm: false })
      } else {
        // Identify in LO
        window.LOQ = window.LOQ || []
        window.LOQ.push(['ready', async LO => {
          await LO.$internal.ready('visitor')
          LO.visitor.identify({
            email: props.email,
            phone: props.phone,
            name: `${props.firstName} ${props.lastName}`
          })
        }])
        props.handlePartialSignup(userDetails)
      }
    })
  }

  useEffect(() => {
    document.body.className = ''

    const userDetails = LocalStorageHelper.getItem<SignupProfile>('userDetails')?.value
    if (userDetails !== null && (
      validationState.emailValidationState === validationStates.UNCHECKED ||
      validationState.phoneValidationState === validationStates.UNCHECKED ||
      validationState.firstNameValidationState === validationStates.UNCHECKED ||
      validationState.lastNameValidationState === validationStates.UNCHECKED))

    // Check if we already have numbers
    {
      if (props.basket && props.basket.numbers?.length > 0 && !state.alreadyHasNumbers) { setState({ ...state, alreadyHasNumbers: true }) }
    }

    window.dataLayer.push({
      event: 'signupInfo'
    })
  }, [])

  const canProgress = validationState.phoneValidationState === validationStates.VALID && validationState.emailValidationState === validationStates.VALID && validationState.firstNameValidationState === validationStates.VALID && validationState.lastNameValidationState === validationStates.VALID

  async function getCheckValidTelephonePromise(tel: string): Promise<AxiosResponse<boolean>> {
    const url = 'api/Users/isValidTelephone'
    const numberType = checkNumberType(tel)
    return await fetchHelper.postJson(url, { phoneNumber: tel, landline: numberType === PhoneTypes.landline })
  }

  async function getValidatePhonePromise(phone: string): Promise<validationStates> {
    return await new Promise((resolve, reject) => {
      if (phone) {
        // validation consists of performing an initial syntax check using the phone regex
        // if that passes, we then check if the phone is already in use
        const validPhoneSyntax = isValidTelSyntax(phone)
        if (validPhoneSyntax) {
          // if the phone syntax is valid, then perform additional checks backend
          const isPhoneNumberValid = getCheckValidTelephonePromise(phone).then(response => {
            // if the response is true then the phone number is ok to use so we resolve the promise with a validationState of VALID
            if (response.data) {
              resolve(validationStates.VALID)
            } else {
              // if the response is false, then the phone number cannot be used, so we resolve the promise with a validationState of INVALID
              resolve(validationStates.INVALID)
            }
          }).catch(error => { resolve(validationStates.INVALID) })
        } else {
          // if syntax check fails, we resolve the promise with a validation state of INVALID
          resolve(validationStates.INVALID)
        }
      } else {
        // if the phone is empty or null, we resolve the promise with a validation state of EMPTY
        resolve(validationStates.EMPTY)
      }
    })
  }

  function onCredentialAvailable(googleCredential: GoogleCredentialAuthResponse) {
    fetchHelper.postJson<{ email: string, firstName: string, surname: string }, any>('/api/Users/GetUserDetailsFromGoogleToken', { token: googleCredential.credential })
      .then(response => {
        let userDetails = response.data;
        if (userDetails.firstName && userDetails.email) {
          props.handleAuthCredentialsReceived(userDetails.firstName, userDetails.surname, userDetails.email, 'Google');
          setState({ ...state, showPartialForm: true, showForm: false })
        } else {
          setState({ ...state, showGenericError: true, errorMsg: 'There was a problem getting your data from Google. Please try again.' })
        }
      }, error => {
        console.log(error);
      }
      )
  }

  const linkedinLogin = LinkedinLogin({
    clientId: '788flvhgyaat69',
    redirectUri: `${window.location.origin}/login/linkedin`,
    onSuccess: (res) => {
      const data = res.data;

      props.handleAuthCredentialsReceived(data.firstName, data.surname, data.email, 'LinkedIn');
      setState({ ...state, showPartialForm: true, showForm: false, socialLoginUsed: 'li' })
    },
    onError: (error) => {
      console.log(error)
    },
  })

  //const login = useGoogleLogin({
  //  onSuccess: tokenResponse => console.log(tokenResponse),
  //});

  return <>
    <ScrollToTopOnMount />
    <DisplayNoFooter />
    <DisplaySimpleHeaderV2 />
    <Helmet>
      <body
        className={'body-simple bg-dull body-signup pb-0 step4-user-details'}
      />
    </Helmet>

    <div className="full-width progress-container">
      <div className="row">
        <div className="col-md-12 text-center pl-0 pr-0">
          <ProgressSteps current={3} steps={4} history={props.history}
            changeStep={step => { props.handleBack(step) }}
          />
        </div>
      </div>
    </div>
    <div className="full-width">
      <div className="container mt-0 mb-50">
        <div className="row">
          <div className="col-md-12 text-center fixed-container pb-30 pb-xs-25 mt-xs-10">
            <h3 className="text-500 mt-0">Your Details</h3>
            {props.basket && (props.basket.package !== null && props.basket.package !== undefined)
              ? <p>Get started with your <span
                className="text-500">{props.basket.package.name.toLowerCase()}</span> plan.</p>
              : <p>Simply enter your details below.</p>
            }
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 col-sm-6 mx-auto w-600px">

            <Form noValidate>
              <div className="row p-xxs-5">
                {state.showPartialForm !== true &&
                  <div className="col-12">
                    <div className="bg-light p-30 brad-10 box-shadow shadow-lighter">
                      <p className="text-500 lead text-center">
                        Continue with social account
                      </p>
                      <div className="center-content">
                        <GoogleLogin
                          text="continue_with"
                          onSuccess={onCredentialAvailable}
                          onError={() => {
                            console.log('Login Failed');
                          }}
                          useOneTap
                        />

                        <Button variant="outline" size="sm" className="mt-10 text-sm btn-linkedin" onClick={() => linkedinLogin()}>
                          Continue with LinkedIn
                          <span className="icon icon-linkedin ml-5"></span>
                        </Button>

                      </div>
                    </div>

                    <hr className="mt-30 mb-20 bt-1-dark m-0" />
                  </div>
                }

                {state.showPartialForm === true &&
                  <>
                  <div className="col-md-12 clearfix">
                    <p className="bg-light box-shadow shadow-lighter p-20 brad-10 mb-30">
                      Thanks, {props.firstName}!<br />We've got the following email address from {state.socialLoginUsed === 'li' ? 'LinkedIn' : state.socialLoginUsed === 'fb' ? 'Facebook' : 'Google'}.<br /><br />
                      <span className="text-500">{props.email}</span> <span className="text-400 cta-hover text-secondary cursor-pointer text-sm ml-10 d-inline-block underline"
                        onClick={() => { setState({ ...state, showForm: true, showPartialForm: false }) } }>
                        Not correct? Update it here
                      </span>
                    </p>
                  </div>

                    <div className="col-md-12 clearfix">
                      <Form.Group
                        className={`mb-30 mb-xs-10 form-group animated ${(state.focusPhone || (props.phone && props.phone.length > 0)) ? 'focused' : ''}`}
                      >
                        <Form.Label>
                          Enter your contact phone number
                          <span className="text-sm ml-10 text-danger">Required</span>
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          className="text-md box-shadow shadow-lighter no-border"
                          value={props.phone}
                          onFocus={() => { setState({ ...state, focusPhone: true }) }}
                          onBlur={e => {
                            setState({ ...state, focusPhone: false })
                          }}
                          isInvalid={validationState?.phoneValidationState === validationStates.INVALID}
                          isValid={validationState?.phoneValidationState === validationStates.VALID}
                          onChange={e => {
                            props.handleUpdateSignupPhone(e.target.value)
                            setState({ ...state, showGenericError: false, isProcessing: false })
                            setValidationState({ ...validationState, phoneValidationState: validationStates.EMPTY })
                          }} />

                        <Form.Control.Feedback type="invalid" className="text-400 pl-15">
                          Please enter a valid phone number
                        </Form.Control.Feedback>

                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group
                        className={`mb-30 mb-xs-10 form-group animated ${(state.focusCompany || (props.company && props.company.length > 0)) ? 'focused' : ''}`}
                      // validated={(props.company && props.company.length > CompanyNameLimit) ? true : undefined}
                      >
                        <Form.Label>Enter your company name</Form.Label>
                        <Form.Control
                          type="text"
                          className="text-md box-shadow shadow-lighter no-border"
                          value={props.company}
                          onFocus={() => { setState({ ...state, focusCompany: true }) }}
                          onBlur={e => { setState({ ...state, focusCompany: false }) }}
                          onChange={e => {
                            props.handleUpdateSignupCompany(e.target.value)
                            setState({ ...state, showGenericError: false, isProcessing: false })
                          }} />
                        {props.company && props.company.length > CompanyNameLimit &&
                          <Form.Text className="text-sm">
                            Company name is {props.company.length - CompanyNameLimit} characters too long.
                          </Form.Text>
                        }
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <label className="small pl-15 text-400 mb-20 mt-10 ml-20 mt-xs-0 text-xs-xs mt-xs-20">
                        <span className="text-400 mr-5">
                          By continuing with this service, you agree
                          to our <a className="underline text-dark"
                            onClick={e => {
                              setState({
                                ...state,
                                showTsAndCs: true
                              })
                            }}>terms &amp; conditions</a>
                        </span>

                        <span className="styled-checkbox checkbox-light" style={{ margin: '-6px 0 0 -30px', float: 'left' }}>
                          <Form.Check inline
                            checked={state.acceptedTerms}
                            onChange={e => { setState({ ...state, acceptedTerms: e.target.checked }) }} />
                        </span>

                        <Modal className="terms-modal" show={state.showTsAndCs}
                          onHide={() => { setState({ ...state, showTsAndCs: false }) }}>
                          <Modal.Header closeButton>
                            <Modal.Title>Terms &amp; Conditions</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <TermsAndConditions />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button onClick={e => { setState({ ...state, showTsAndCs: false }) }}>Close</Button>
                          </Modal.Footer>
                        </Modal>

                      </label>
                    </div>
                  </>
                }

                {state.showForm === true &&
                  <>
                    <p className="text-500 lead text-center">
                      Or, continue with your email address
                    </p>
                    <div className="col-md-6 col-xs-12 col-sm-12">
                      <Form.Group
                        className={`form-group mb-30 mb-xs-10 animated ${(state.focusFirstName || (props.firstName && props.firstName.length > 0)) ? 'focused' : ''}`}
                      >
                        <Form.Label>
                          Enter your first name
                          <span className="text-sm ml-10 text-danger">Required</span>
                        </Form.Label>
                        <Form.Control
                          className="text-md box-shadow shadow-lighter no-border"
                          value={props.firstName}
                          onFocus={() => { setState({ ...state, focusFirstName: true }) }}
                          onBlur={e => {
                            setState({ ...state, focusFirstName: false })
                          }}
                          isInvalid={validationState?.firstNameValidationState === validationStates.INVALID}
                          isValid={validationState?.firstNameValidationState !== validationStates.INVALID && validationState?.firstNameValidationState !== validationStates.UNCHECKED && validationState?.firstNameValidationState !== validationStates.EMPTY}
                          onChange={e => {
                            props.handleUpdateSignupFirstName(e.target.value)
                            setState({ ...state, showGenericError: false })
                            setValidationState({ ...validationState, firstNameValidationState: validationStates.EMPTY })
                          }} />

                        <Form.Control.Feedback type="invalid" className="text-400 pl-15">
                          {(props.firstName?.length > FirstNameLimit)
                            ? 'First name is ' + (props.firstName.length - FirstNameLimit) + ' characters too long.'
                            : 'Please enter a valid name'
                          }
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 col-xs-12 col-sm-12">
                      <Form.Group
                        className={`form-group animated mb-30 mb-xs-10 ${(state.focusLastName || (props.lastName && props.lastName.length > 0)) ? 'focused' : ''}`}
                      >
                      <Form.Label>
                        Enter your last name
                        <span className="text-sm ml-10 text-danger">Required</span>
                      </Form.Label>
                        <Form.Control
                          className="text-md box-shadow shadow-lighter no-border"
                          value={props.lastName}
                          onFocus={() => { setState({ ...state, focusLastName: true }) }}
                          onBlur={e => {
                            setState({ ...state, focusLastName: false })
                          }}
                          isInvalid={validationState?.lastNameValidationState === validationStates.INVALID}
                          isValid={validationState?.lastNameValidationState !== validationStates.INVALID && validationState?.lastNameValidationState !== validationStates.UNCHECKED && validationState?.lastNameValidationState !== validationStates.EMPTY}
                          onChange={e => {
                            props.handleUpdateSignupLastName(e.target.value)
                            setState({ ...state, showGenericError: false })
                            setValidationState({ ...validationState, lastNameValidationState: validationStates.EMPTY })
                          }} />

                        <Form.Control.Feedback type="invalid" className="text-400 pl-15">
                          {(props.lastName?.length > LastNameLimit)
                            ? 'Last name is ' + (props.lastName.length - LastNameLimit) + ' characters too long.'
                            : 'Please enter a valid name'
                          }
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-12 clearfix">
                      <Form.Group
                        className={`mb-30 mb-xs-10 form-group animated ${(state.focusPhone || (props.phone && props.phone.length > 0)) ? 'focused' : ''}`}
                      >
                        <Form.Label>
                          Enter your contact phone number
                          <span className="text-sm ml-10 text-danger">Required</span>
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          className="text-md box-shadow shadow-lighter no-border"
                          value={props.phone}
                          onFocus={() => { setState({ ...state, focusPhone: true }) }}
                          onBlur={e => {
                            setState({ ...state, focusPhone: false })
                          }}
                          isInvalid={validationState?.phoneValidationState === validationStates.INVALID}
                          isValid={validationState?.phoneValidationState === validationStates.VALID}
                          onChange={e => {
                            props.handleUpdateSignupPhone(e.target.value)
                            setState({ ...state, showGenericError: false, isProcessing: false })
                            setValidationState({ ...validationState, phoneValidationState: validationStates.EMPTY })
                          }} />

                        <Form.Control.Feedback type="invalid" className="text-400 pl-15">
                          Please enter a valid phone number
                        </Form.Control.Feedback>

                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group
                        className={`mb-30 mb-xs-10 form-group animated ${(state.focusEmail || (props.email && props.email.length > 0)) ? 'focused' : ''}`}
                      >
                        <Form.Label>
                          Enter your email address
                          <span className="text-sm ml-10 text-danger">Required</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          className="text-md box-shadow shadow-lighter no-border"
                          value={props.email}
                          onFocus={() => { setState({ ...state, focusEmail: true }) }}
                          onBlur={e => {
                            setState({ ...state, focusEmail: false })
                          }}
                          isInvalid={validationState?.emailValidationState === validationStates.INVALID || validationState?.emailValidationState === validationStates.INUSE}
                          isValid={validationState?.emailValidationState === validationStates.VALID}
                          onChange={e => {
                            props.handleUpdateSignupEmail(e.target.value)
                            setState({ ...state, showGenericError: false })
                            setValidationState({ ...validationState, emailValidationState: validationStates.EMPTY })
                          }} />

                        <Form.Control.Feedback type="invalid" className="text-400 pl-15">
                          {validationState.emailValidationState === validationStates.INUSE
                            ? 'Sorry, that emails already in use'
                            : 'Please enter a valid email address'
                          }
                        </Form.Control.Feedback>

                        {validationState.emailValidationState === validationStates.INUSE &&
                          <Alert variant="danger" className="mt-10">
                            <p className="text-500">Looks like you've already got an account.</p>
                            <p className="mb-0">You can <Link to={'/login'} className="underline text-cta">log into your
                              account</Link> with us, or if you've forgotten your password you can <Link
                                to={'/forgot-password'} className="underline text-cta">reset it here</Link>.</p>
                          </Alert>
                        }

                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group
                        className={`mb-30 mb-xs-10 form-group animated ${(state.focusCompany || (props.company && props.company.length > 0)) ? 'focused' : ''}`}
                      // validated={(props.company && props.company.length > CompanyNameLimit) ? true : undefined}
                      >
                        <Form.Label>Enter your company name</Form.Label>
                        <Form.Control
                          type="text"
                          className="text-md box-shadow shadow-lighter no-border"
                          value={props.company}
                          onFocus={() => { setState({ ...state, focusCompany: true }) }}
                          onBlur={e => { setState({ ...state, focusCompany: false }) }}
                          onChange={e => {
                            props.handleUpdateSignupCompany(e.target.value)
                            setState({ ...state, showGenericError: false, isProcessing: false })
                          }} />
                        {props.company && props.company.length > CompanyNameLimit &&
                          <Form.Text className="text-sm">
                            Company name is {props.company.length - CompanyNameLimit} characters too long.
                          </Form.Text>
                        }
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <label className="small pl-15 text-400 mb-20 mt-10 ml-20 mt-xs-0 text-xs-xs mt-xs-20">
                        <span className="text-400 mr-5">
                          By continuing with this service, you agree
                          to our <a className="underline text-dark"
                            onClick={e => {
                              setState({
                                ...state,
                                showTsAndCs: true
                              })
                            }}>terms &amp; conditions</a>
                        </span>

                        <span className="styled-checkbox checkbox-light" style={{ margin: '-6px 0 0 -30px', float: 'left' }}>
                          <Form.Check inline
                            checked={state.acceptedTerms}
                            onChange={e => { setState({ ...state, acceptedTerms: e.target.checked }) }} />
                        </span>

                        <Modal className="terms-modal" show={state.showTsAndCs}
                          onHide={() => { setState({ ...state, showTsAndCs: false }) }}>
                          <Modal.Header closeButton>
                            <Modal.Title>Terms &amp; Conditions</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <TermsAndConditions />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button onClick={e => { setState({ ...state, showTsAndCs: false }) }}>Close</Button>
                          </Modal.Footer>
                        </Modal>

                      </label>
                    </div>
                  </>
                }

                {state.emailAlreadyInUse === validationStates.INVALID &&
                  <div className="col-md-12 pt-10 text-center">
                    <div className="brad-5 bg-light p-20 mb-20">
                      <p className="text-500">Looks like you've already got an account.</p>
                      <p>You can <Link to={'/login'} className="underline text-cta">log into your
                        account</Link> with us, or if you've forgotten your password you can <Link
                          to={'/forgot-password'} className="underline text-cta">reset it here</Link>.</p>
                    </div>
                  </div>
                }

                <div className="col-md-12 text-uppercase text-center pt-30">
                  <div className="row justify-content-evenly flex-nowrap">
                    <div className="col-3">
                      <SvgRender icon="no-contract" svgHeight="30px" svgFill="#b3b3b3" />
                      <p className="text-dullest text-500 text-xs">No long term<br />contracts</p>
                    </div>
                    <div className="col-3">
                      <SvgRender icon="fast" svgHeight="30px" svgFill="#b3b3b3" />
                      <p className="text-dullest text-500 text-xs">Instant<br />Setup</p>
                    </div>
                    <div className="col-3">
                      <SvgRender icon="secure" svgHeight="30px" svgFill="#b3b3b3" />
                      <p className="text-dullest text-500 text-xs">Safe &amp;<br />Secure</p>
                    </div>
                    <div className="col-3">
                      <SvgRender icon="support" svgHeight="30px" svgWidth="33px" svgFill="#b3b3b3" />
                      <p className="text-dullest text-500 text-xs">Dedicated<br />UK Support</p>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 pt-10">
                  <div className="pt-20 pb-20 bt-1 pt-20 reviews-badge-container">
                    <BadgeWidget size="mediun" position="center" />
                  </div>
                </div>

                <p className="text-sm cursor-pointer d-inline-block pt-20 text-center" onClick={() => {
                  props.handleBack()
                }}>
                  <span className="icon icon-arrow-left4 mr-3 text-xs"></span> <span className="underline" >Go back to previous step</span>
                </p>

                <div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
                  <p className="col-md-12">
                    <Validator validation={state.showGenericError}
                      customClass="form-group has-error text-center mb-0 mt-0">
                      {state.errorMsg
                        ? state.errorMsg
                        : <>There seems to be a problem with your request.<br />Please double
                          check all the fields above.</>
                      }
                    </Validator>
                  </p>

                  {validationState.isValidating
                    ? <LoadingNotification
                      className="mt-30"
                      textColor="text-darker"
                      message={'Checking your details...'}
                      isLoading={true}
                    />
                    : <Button disabled={!props.firstName || !props.phone || !props.email} onClick={() => {
                      state.acceptedTerms ? handleUserWantsToContinue() : setState({ ...state, showTermsWarning: true })
                    }}
                      variant="cta"
                      size="lg"
                      className="brad-5 btn-xs-block pt-20 pb-20" data-delay={3}>
                      Continue<span className="hidden-xxs"> to checkout <span
                        className="icon icon-arrow-right4 ml-5 text-xs"></span></span>
                    </Button>
                  }
                </div>

              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <Modal size="sm" show={state.showTermsWarning}>
      <Modal.Header>
        <Modal.Title>Terms &amp; Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="direction-block dir-down bb-1 mb-20 p-40">
          To continue creating your account, it's important that you read &amp; agree with our terms &amp; conditions.<br />
          <span className="text-underline cursor-pointer" onClick={() => { setState({ ...state, showTsAndCs: true }) }}>Click here to view our latest terms &amp; conditions.</span>
        </div>

        <div className="p-30 pt-20 text-center">
          <Button variant="cta" className="btn-block text-lg btn-xl" onClick={() => { handleContinueCheckout() }}>
            Ok, Let's Continue
          </Button>
          <p className="text-sm mt-20">
            <Link to="/">Cancel checkout</Link>
          </p>
        </div>
      </Modal.Body>
    </Modal>

  </>

  async function getCheckValidEmailPromist(email: string): Promise<AxiosResponse<boolean>> {
    const url = 'api/Users/isValidEmailAddress'
    return await fetchHelper.postJson(url, { email })
  }

  async function getValidateEmailPromise(email: string): Promise<validationStates> {
    return await new Promise((resolve, reject) => {
      if (email) {
        // determine email length is less than the value set in the constant EmailLimit
        if (email.length > EmailLimit) {
          resolve(validationStates.INVALID)
          return
        }

        // validation consists of performing an initial syntax check using the email regex
        // if that passes, we then check if the email is already in use
        const validEmailSyntax = isValidEmailSyntax(email)
        if (validEmailSyntax) {
          // Check email not already in use
          const emailAlreadyInUse = isEmailAlreadyInUse(email).then(response => {
            // if the response is true then the email address is already in use, so we resolve the promise with a validationState of INUSE
            if (response.data) {
              resolve(validationStates.INUSE)
            } else {
              // if the response is false, then the email address is not in use, so let's validate it
              const isEmailValid = getCheckValidEmailPromist(email).then(response => {
                // if the response is true then the phone number is ok to use so we resolve the promise with a validationState of VALID
                if (response.data) {
                  resolve(validationStates.VALID)
                } else {
                  // if the response is false, then the phone number cannot be used, so we resolve the promise with a validationState of INVALID
                  resolve(validationStates.INVALID)
                }
              }).catch(error => { resolve(validationStates.INVALID) })
            }
          })
        } else {
          // if syntax check fails, we resolve the promise with a validation state of INVALID
          resolve(validationStates.INVALID)
        }
      } else {
        // if the email is empty or null, we resolve the promise with a validation state of EMPTY
        resolve(validationStates.EMPTY)
      }
    })
  }

  async function isEmailAlreadyInUse(email: string) {
    const url = 'api/Users/DoesSignupEmailAlreadyExists/'

    return await fetchHelper
      .postJson<boolean, { email: string }>(url, {
        email
      })
  }

  function validateName(name: string) {
    if (name) {
      if (name.length > NameLimit) return validationStates.INVALID
      const isValid = name.length > 0 && isValidNameSyntax(name, 'firstname')
      return validationStates.fromBool(isValid)
    } else {
      return validationStates.EMPTY
    }
  }

  function validateFirstNameField(context: { newValue: string }) {
    const firstNameValidationState = validateName(context.newValue)

    setValidationState({
      ...validationState,
      firstNameValidationState
    })
  }

  function validateLastNameField(context: { newValue: string }) {
    const lastNameValidationState = validateName(context.newValue)

    setValidationState({
      ...validationState,
      lastNameValidationState
    })
  }

  function handleContinueCheckout() {
    setState({ ...state, acceptedTerms: true, showTermsWarning: false })
    handleUserWantsToContinue()
  }

  function validateSignupEmail(context: { newValue: string }) {
    const email = context.newValue

    // set the initial state of the email validation state to INDETERMINATE as the lookup is async so won't resolve straightaway
    setValidationState({
      ...validationState,
      emailValidationState: validationStates.INDETERMINATE
    })

    getValidateEmailPromise(email).then((emailValidationState: validationStates.VALID | validationStates.INVALID | validationStates.INUSE | validationStates.EMPTY) => {
      setValidationState({
        ...validationState,
        emailValidationState
      })
    })
  }
}

export default SignupPage
