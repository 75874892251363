import update from 'immutability-helper'
import moment from 'moment'
import React from 'react'
import { Button, Modal, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap'
import 'react-datetime/css/react-datetime.css'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { scroller } from 'react-scroll'
import Select from 'react-select'
import CallTranscriptionActivation from '../../../components/CallTranscriptionActivation'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import LoadingNotification from '../../../components/LoadingNotification'
import CallRecordingPackages from '../../../components/purchase/CallRecordingPackages'
import RefreshUserServices from '../../../components/RefreshUserServices'
import ReportDateTimeSelectionPanel from '../../../components/ReportDateTimeSelectionPanel'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import CallTranscriptionBenefitsList from '../../../components/Text/CallTranscriptionBenefitsList'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { checkUserHasCallRecording, getQueryStringFromProps, simpleDateTimeFormat, simpleDurationFormat, simplePhoneNumberFormat } from '../../../helpers/utils'
import { handleNotificationChange } from '../../../store/SBF'


const _ = require('lodash')

const popover = (
	<Popover id="popover-basic">
		<p>This field is optional, you can leave it blank.</p>
		<p className="text-600 pt-20 bt-1">Find calls from a specific number</p>
		<p>If you know the callers number simply type it in and press the search button.</p>
		<p className="mb-0">If you don&apos;t know the whole number but you remember that the number contained 222, simply enter 222.</p>

		{/* <p className="text-600">Find calls from multiple numbers</p>
      <p>To display calls from few phone numbers just type the phone numbers you want to find calls from, divided by a comma, for example: <br/><br/><i>07445605576, 333, 1112</i></p> */}
	</Popover>
)

const textFilterPopover = (
	<Popover id="popover-basic">
		<p className="mb-0">Search for words that have been spoken during a call, for example: "sale", "John".</p>

		{/* <p className="text-600">Find calls from multiple numbers</p>
      <p>To display calls from few phone numbers just type the phone numbers you want to find calls from, divided by a comma, for example: <br/><br/><i>07445605576, 333, 1112</i></p> */}
	</Popover>
)

const departmentOverlay = (
	<Popover id="popover-basic">
		<p className="mb-0">Only inbound calls will be displayed if the department value is not set to any</p>
	</Popover>
)

class CallRecordingList extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    fromDate: moment().startOf('day'),
    toDate: moment().endOf('day'),
    numbers: [{ value: -1, label: 'All Numbers' }],
    selectedQlid: { value: -1, label: 'All Numbers' },
    filtered: [],
    selectedCli: '',
    data: [],
    selectedRecording: null,
    selectedCallDuration: 0,
    callDurationOptions: [
      { value: '0', label: 'Any duration' },
      { value: '1', label: 'less than 1 minute' },
      { value: '2', label: '1 to 5 minutes' },
      { value: '3', label: '5 to 15 minutes' },
      { value: '4', label: 'over 15 minutes' }
    ],
    selectedDepartment: { value: '0', label: 'Any' },
    departmentOptions: [
      { value: '0', label: 'Any' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9' }
    ],
    showAddNote: false,
    hasPerformedSearch: false,
    textFilter: '',
    isInitialSearch: true,
    advancedSearchOptions: false,
    showCallTranscriptionModal: false,
    showCallRecordingPackagesModal: false,
    callTranscriptionActive: false,
    callInformation: undefined,
    numberDescription: '',
    numberCalled: '',
    initiatorName: '',
    initiatorNumber: '',
    showCallTranscriptionActivatedConfirmation: false
  }

  async componentDidMount () {
    const context = this

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('new=true')) {
        this.props.notify({ message: 'Thank you, call recording has been added to your account' })
      }
    }

    if (this.props.match.params.recordingId) {
      const recordingId = this.props.match.params.recordingId
      const url = `api/CallRecording/GetSingleCallRecording/${recordingId}`
      const response = await fetchHelper.getJson(url)
      if (fetchHelper.isUnauthorised(response)) {
      } else {
        const recording = response.data

        context.setState({ selectedRecording: recording })
        this.setState({ showAudioPlayer: true })
      }
    }

    const url = '/api/Numbers/GetNumbersListForCustomer'

    fetchHelper.getJson(url)
      .then(response => {
        const numbers = response.data
        numbers.forEach(number => { simplePhoneNumberFormat(number.label) })
        numbers.unshift({ value: -1, label: 'All Numbers' })
        context.setState({ numbers })
      })
      .catch(error => {
        console.error(error)
      })

    const url2 = 'api/CallTranscription/HasCallTranscriptionTurnedOn'
    fetchHelper.getJson(url2)
      .then(res => {
        this.setState({ callTranscriptionActive: res.data })
      }).catch(err => console.error(err))

    this.loadFirstHundreCallRecordings(context)
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.showAudioPlayer && this.state.showAudioPlayer) {
      if (this.state.selectedRecording !== null) {
        const url = 'api/CallRecording/DownloadCallRecordingAudio/' + this.state.selectedRecording.id

        fetchHelper.getBlob(url).then(response => {
          const audioPlayer = document.getElementById('audioPlayer')
          audioPlayer.pause()
          audioPlayer.currentTime = 0
          audioPlayer.src = window.URL.createObjectURL(response.data, { type: 'audio/mpeg' })
          audioPlayer.play()
        }).catch(error => console.error(error))
      }
    }
  }

  onFilteredChangeCustom = (value, accessor) => {
    const filtered = this.state.filtered
    let insertNewFilter = 1

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter.id === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1)
          else filter.value = value

          insertNewFilter = 0
        }
      })
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value })
    }

    this.setState({ filtered })
  }

  render () {
    const context = this

    async function callApi (event, fromDate, toDate, textFilter) {
      event.preventDefault()

      context.setState({ isLoading: true })

      const from = fromDate.format('YYYY-MM-DD H:m')

      const to = toDate.format('YYYY-MM-DD H:m')

      const url = `api/CallRecording/GetRecordingLogs/${context.state.selectedQlid.value}/${from}/${to}/${context.state.selectedCallDuration}/${(context.state.selectedCli === '') ? 'X' : context.state.selectedCli}/${context.state.selectedDepartment.value}/` + textFilter

      const response = await fetchHelper.getJson(url)

      if (fetchHelper.isUnauthorised(response)) {
        // dispatch(logout({history: context.history}))
      } else {
        const reportData = response.data

        if (reportData !== null && reportData !== '') {
          reportData.forEach(record => { record.callerCli = (record.callerCli !== 'Withheld') ? simplePhoneNumberFormat(record.callerCli) : record.callerCli })
          reportData.forEach(record => { record.calledDdi = simplePhoneNumberFormat(record.calledDdi) })
          reportData.forEach(record => { record.callDate = simpleDateTimeFormat(record.callDate) })
          reportData.forEach(record => { record.recordingDurationSecs = simpleDurationFormat(record.recordingDurationSecs) })
        }
        context.setState({ data: reportData })

        context.setState({ isLoading: false, hasPerformedSearch: true, isInitialSearch: false })

        scroller.scrollTo('report', {
          duration: 1500,
          delay: 100,
          smooth: true
        })
      }
    }

    function handleQlidSelected (qlid, context) {
      context.setState({ selectedQlid: { value: qlid.value, label: qlid.label } })
    }

    function handleCallDurationSelected (duration, context) {
      context.setState({ selectedCallDuration: duration })
    }

    function handleCliSelected (cli, context) {
      context.setState({ selectedCli: cli })
    }

    const hasCallRecording = checkUserHasCallRecording(this.props.services) || this.props.services.hasOutboundCallRecording
    const hasCallTranscription = false// this.state.callTranscriptionActive;
    const hasUnlimitedCallRecording = false// this.props.services.hasUnlimitedCallRecording ? this.props.services.hasUnlimitedCallRecording : false;

	function getCallDateTimeForDateTimeString(text){
		debugger;
		var dateParts = text.split('T')[0].split('-')
		var timeParts = text.split('T')[1].split(':')

		return dateParts[2] + '/' + dateParts[1] + '/' + dateParts[0] + ' at ' + timeParts[0]  + ':' + timeParts[1]
	}

    return <><ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader /><RefreshUserServices />
			{/* {!checkUserHasCallRecording(this.props.services) || this.props.services.hasOutboundCallRecording &&
				<UpgradesMenu />
			} */}
			<Helmet>
				<title>Call Recording</title>
				<meta name="description" content="Your call recordings" />
			</Helmet>
			{this.state.isLoading

			  ? <></>

			  :				hasCallRecording

			    ? <header style={{ backgroundImage: "url('/images/backgrounds/10-competitor-bg.jpg')" }} className="full-width full-hero-small with-text">
						<h1>Call Recording</h1>
						<h2 className="subtitle">Listen to and download your call recordings</h2>
					</header>

			    :					<></>
			}
			<div className="container-styled pt-0 clearfix">
				{this.state.isLoading
				  ? <div className="col-xs-12 mt-30">
						<LoadingNotification loadingText={'Search for calls...'} width={100} height={100} isLoading={this.state.isLoading} />
					</div>

				  :					hasCallRecording
				    ? <div className="container">

							{/* <div className="row">
								<div className="col-md-12 bg-dull b-1 brad-20 w-500px center-block mb-30 mt-30 text-center p-30">
									<h4 className="text-500 mb-30">
										Call Transcription
										<OverlayTrigger trigger={['hover', 'focus']} placement="top"
										overlay={
											<Popover>
												<ul className="fancy text-start cta text-400 mt-20 pl-40">
													<li className="pb-10">
														Keep records of calls in text format
													</li>
													<li className="pb-10">
														Search & create alerts for words or phrses spoken or not during the call
													</li>
													<li className="pb-10">
														Read it like a chat
													</li>
													<li className="pb-10">
														Easily copy paste and share the transcription
													</li>
													<li className="pb-10">
														Use transcriptions for training purposes
													</li>
													<li className="pb-10">
														Keep transcriptions for legal purposes
													</li>
													<li>
														Improve accessibility
													</li>
												</ul>
											</Popover>
											}>
												<span className="icon icon-info2 text-xs ml-5"></span>
										</OverlayTrigger>
										</h4>
									<p>Besides recording your calls, would you like us to automatically transcribe them to text? <i>Cost is only 5p per minute.</i></p>
									<CallTranscriptionActivation isBox={true} customDivClasses=" " hideText={true} />
								</div>
							</div> */}
							<div className="row">
								<div className="bg-gradient-v1 text-center mb-20 mt-30 p-30 pb-50 brad-30 text-lightest">
									<h4 className="text-500">
										Use our new Calls page instead.
									</h4>
									<p>
										We have a new page where you can view your calls in one place.
									</p>
									<p className="mt-30">
										<a href="/customer/calls" className="btn btn-lg btn-lightest">See all my calls</a>
									</p>
								</div>
								<div className="col-md-12 mt-30">
									<>
										<form name="form" onSubmit={e => callApi(e, this.state.fromDate, this.state.toDate, this.state.textFilter)}>
											<div className="row">
												{<div className="bg-dull b-1 p-30 brad-20 col-md-12 call-recording-filter">
													<div className="row mb-40">
														<div className="col-md-8 filter-options">
															<div className="row">
																<div className="col-md-4 data-range-filter">
																	<ReportDateTimeSelectionPanel fromDateValue={this.state.fromDate} toDateValue={this.state.toDate}
																		onFromDateChange={e => this.setState({ fromDate: e })}
																		onToDateChange={e => this.setState({ toDate: e })}
																		dateSelection={1}
																	/>
																</div>
																<div className="col-md-2">
																	<label>Switchboard number</label>
																	<Select
																		className="form-control select-menu text-start"
																		classNamePrefix="custom"
																		id="qlidSelect"
																		onChange={e => handleQlidSelected(e, this)}
																		options={this.state.numbers}
																		simpleValue
																		name="selectedQlid"
																		value={this.state.selectedQlid}
																		searchable={false}
																		clearable={false}

																		multi={true}
																	// isLoading={props.isLoadingPromptOptions}
																	/>
																</div>
																<div className="col-md-2">
																	<label>Call duration</label>
																	<Select
																		className="form-control select-menu"
																		classNamePrefix="custom"
																		id="callDurationSelect"
																		onChange={e => handleCallDurationSelected(e, this)}
																		simpleValue
																		options={this.state.callDurationOptions}
																		name="selectedCallDuration"
																		value={this.state.selectedCallDuration}
																		searchable={false}
																		clearable={false}
																	/>

																</div>
																<div className="col-md-2">
																	<label>Department
																		<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={departmentOverlay}>
																			<span className="icon icon-info2 ml-5 text-dark-light"></span>
																		</OverlayTrigger></label>
																	<Select
																		className="form-control select-menu"
																		classNamePrefix="custom"
																		onChange={e => this.setState({ selectedDepartment: e })}
																		simpleValue
																		options={this.state.departmentOptions}
																		value={this.state.selectedDepartment}
																		searchable={false}
																		clearable={false}
																	/>

																</div>
																<div className="col-md-2">
																	<label>Callers number
																		<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popover}>
																			<span className="icon icon-info2 ml-5 text-dark-light"></span>
																		</OverlayTrigger>
																	</label>
																	<input
																		onChange={e => handleCliSelected(e.target.value, this)}
																		value={this.state.selectedCli}
																		className="form-control"
																		type="tel"
																		placeholder="Optional"
																	/>

																</div>
																<div className="col-md-12 mt-15">
																	<label>Call Transcription search
																		<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={textFilterPopover}>
																			<span className="icon icon-info2 ml-5 text-dark-light"></span>
																		</OverlayTrigger>
																	</label>
																	<input
																		className="form-control"
																		onChange={e => this.setState({ textFilter: e.target.value })}
																		value={this.state.textFilter}
																		type="text"
																		placeholder="No search filter applied if blank"
																	/>
																</div>
															</div>
														</div>

														<div className="col-md-2 search text-center">
															<input className="btn btn-cta btn-lg mt-45" type='submit' value='Search' onClick={this.scrollTo} />
														</div>
													</div>
												</div>}
												{this.state.hasPerformedSearch === true && this.state.data.length === 0 &&
													<div className="col-md-6 mx-auto text-center alert alert-warning mb-60 mt-30">
														{this.state.isInitialSearch === true
														  ? <>
																<p className="text-warning">
																	You don&apos;t have any recent call recordings. You either just enabled call recording on your account, or haven't made/received any calls yet, or you deleted all your recordings.
																</p>
																<p className="text-warning pt-20">
																	Make a call to your SwitchboardFREE number (make sure call recording is enabled for this number). Once the call ends, the call recording should appear here within 1-5 minutes.
																</p>
															</>

														  :															<p className="text-warning">
																No recordings were found with the above chosen search criteria.
																<br />
																Refine your search or <a className="text-warning underline" href="/customer/call-recording">see most recent call recordings</a>.
															</p>
														}
													</div>
												}
												{(this.state.hasPerformedSearch === false) &&
													<div className="col-md-6 mx-auto text-center alert alert-warning mb-60 mt-30">

														<p className="text-warning">
															Select your search criteria above and click "Search" to begin.
														</p>
													</div>
												}
											</div>
										</form>

										{this.state.isLoading &&
											<div className="col-xs-12">
												<LoadingNotification loadingText={'Search for calls...'} width={100} height={100} isLoading={this.state.isLoading} />
											</div>
										}

										{this.state.data !== null && this.state.data.length > 0 &&
											<div className="row">
												<span id="report" className="anchor"></span>
												<div className="col-md-12 mb-60 pt-40 pl-0 pr-0">
													<div className="row">
														<div className="col-xs-6">
															{this.state.isInitialSearch === true
															  ? <h4 className="text-500">Most recent call recordings</h4>
															  :																<h4 className="text-500">Based on your search we found: {this.state.data !== null ? this.state.data.length : 0} calls
																	<span className="text-xs pl-10 text-400"><a className="underline text-xs text-cta text-400" href="/customer/call-recording">reset all search filters</a></span>
																</h4>
															}
														</div>
														<div className="col-xs-6">
															{hasCallTranscription === false && <OverlayTrigger placement="top" overlay={<Tooltip>Call Transcription</Tooltip>}>
																<Button className="mt-5 mr-10 b-1 text-primary p-0 float-end" style={{ borderRadius: '50%', height: '30px', width: '30px' }} variant="lightest" size="sm" onClick={() => this.setState({ showCallTranscriptionModal: !this.state.showCallTranscriptionModal })}>
																	<span className="icon icon-bubble-lines3 text-primary"></span>
																</Button>
															</OverlayTrigger>}
															{hasUnlimitedCallRecording === false && <OverlayTrigger placement="top" overlay={<Tooltip>Upgrade</Tooltip>}>
																<Button className="mt-5 mr-10 b-1 text-primary p-0 float-end" style={{ borderRadius: '50%', height: '30px', width: '30px' }} variant="lightest" size="sm" onClick={() => this.setState({ showCallRecordingPackagesModal: !this.state.showCallRecordingPackagesModal })}>
																	<span className="icon icon-cart text-primary"></span>
																</Button>
															</OverlayTrigger>}
															{/* { this.state.advancedSearchOptions === false &&  */}
															<OverlayTrigger placement="top" overlay={<Tooltip>Advanced Search</Tooltip>}>
																<Button className="mt-5 mr-10 b-1 text-primary p-0 float-end" style={{ borderRadius: '50%', height: '30px', width: '30px' }} variant="lightest" size="sm" onClick={() => this.setState({ advancedSearchOptions: !this.state.advancedSearchOptions })}>
																	<span className="icon icon-search text-primary"></span>
																</Button>
															</OverlayTrigger>
															{/* } */}
														</div>

														<Table className="call-recordings-table" responsive striped bordered condensed hover>
															<thead>
																<tr>
																	<th>Call date</th>
																	<th>Number called</th>
																	<th>Callers numbers</th>
																	<th>Recording duration</th>
																	<th>Notes</th>
																	<th>Type</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{this.state.data.map((item, i) => <tr>
																	<td>
																		<span className="d-inline d-sm-none text-500 mr-10">Call date:</span>
																		<span>{item.callDate}</span>
																	</td>
																	<td>
																		<span className="d-inline d-sm-none text-500 mr-10">Number called:</span>
																		<span>{item.calledDdi} {item.isOutbound === false && item.departmentNumber !== -1 && <><br/>Department {item.departmentNumber}</>}</span>
																	</td>
																	<td>
																		<span className="d-inline d-sm-none text-500 mr-10">Callers number:</span>
																		<a href={`tel:${item.callerCli}`} className="text-cta underline">{item.callerCli}</a><br />
																		<span> {item.numberDescription} </span>
																	</td>
																	<td>
																		<span className="d-inline d-sm-none text-500 mr-10">Call duration:</span>
																		<span>{item.recordingDurationSecs}</span>
																	</td>
																	<td>
																		<span className="d-inline d-sm-none text-500 mr-10">Note:</span>
																		{item.note === null ? <span>no note</span> : item.note.length > 30 ? this.renderNote(item.note) : <span>{item.note}</span>}
																	</td>
																	<td>
																		<span className="d-inline d-sm-none text-500 mr-10">Call type:</span>
																		{item.isOutbound === true ? <span>Outbound Call</span> : <span>Inbound Call</span>}
																	</td>
																	<td>
																		<Button size="xs" variant="secondary" className="m-5" onClick={e => this.handleShowPlayer(item.id, item.isOutbound, e, this)}>Play</Button>
																		<Button size="xs" variant="secondary" className="m-5" onClick={e => this.handleRecordingDownload(item.id, e)}>Download</Button>
																		<Button size="xs" variant="secondary" className="m-5" onClick={e => this.handleAddNote(item.id, e, this)}>Note</Button>
																		<Button size="xs" variant="secondary" className="m-5" onClick={e => this.handleCallInfomationButtonPressed(item.callId, item.isOutbound)}>Call Info</Button>
																	</td>
																</tr>)}
															</tbody>

														</Table>
														{this.state.isInitialSearch === false &&
															<a className="underline text-sm text-cta text-400" href="/customer/call-recording">Reset all search filters</a>
														}
													</div>
												</div>
											</div>
										}
									</>

								</div>
							</div>
						</div>
				    :						<CallRecordingPackages userServices={this.props.services} isCallRecordingPage={true} />
				}
			</div>

			<Modal
				className="w-600px brad-20"
				show={this.state.showCallRecordingPackagesModal}
				onHide={e => this.setState({ showCallRecordingPackagesModal: false })}>
				<Modal.Header closeButton className="bb-0 bg-dull"></Modal.Header>
				<Modal.Body className="text-center bg-dull">
					<CallRecordingPackages />
				</Modal.Body>
			</Modal>

			<Modal
				className="w-600px brad-20"
				show={this.state.showCallTranscriptionModal}
				onHide={e => this.setState({ showCallTranscriptionModal: false })}>
				<Modal.Header closeButton className="bb-0 bg-dull"></Modal.Header>
				<Modal.Body className="text-center bg-dull">
					<div className="bg-light p-30 brad-20 b-1 box-shadow-v3 mb-30">
						<h4 className="text-500 mt-20 mb-20">Call Transcription</h4>
						<p>
							As you have call recording enabled on your account, would you like your recordings to be automatically transcribed? <i>Cost is only 5p per minute.</i>
						</p>

						<CallTranscriptionBenefitsList />
						<CallTranscriptionActivation isBox={false} hideNowNowText={true} customToggleWrapperClass="pl-20 pr-20 pt-40 pb-40 brad-20 mb-40 b-1 bg-light box-shadow w-300px center-block" />
					</div>

				</Modal.Body>
				<Modal.Footer>
					<div className="col-md-12 text-end">
						<Button variant="default" size="xs" onClick={e => this.setState({ showCallTranscriptionModal: false })}>Close</Button>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal className="modal-small" show={this.state.showAddNote} onHide={e => this.setState({ showAddNote: false })}>
				{/* <Modal.Header closeButton className="bb-0">
                     <Modal.Title>Note about the call</Modal.Title>
                </Modal.Header> */}
				<Modal.Body className="text-center row">
					<div className="col-md-12">
						<p>Write a note about this call. This way it will be easier for you to find this call record in the future.</p>
					</div>

					<div className="col-md-12 pb-20 pt-20">

						<textarea rows="5" onChange={e => this.setState({ recordingNote: e.target.value })} defaultValue={(this.state.selectedRecording !== null) ? this.state.selectedRecording.note : ''} className="form-control" placeholder="eg. Delivery: Caller wanted to confirm the delivery date of his package." />
						<Button onClick={e => this.handleUpdateNote()} variant="cta" size="lg" className="mt-30 btn-block">{(this.state.selectedRecording !== null && this.state.selectedRecording.note === null) ? 'Add' : 'Update'} Note</Button>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="col-md-12">
						<Button variant="default" size="xs" onClick={e => this.setState({ showAddNote: false })}>Close</Button>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal size="w-1000px" show={this.state.showAudioPlayer} onHide={e => this.setState({ showAudioPlayer: false, callTranscription: undefined, showCallTranscriptionActivatedConfirmation: false })}>
				<Modal.Header className="bb-0 bg-dull" closeButton>
				</Modal.Header>
				<Modal.Body className="bg-dull">
					<div className="row mb-30 text-center">
						<div className="col-md-3 col-sm-6 col-xs-6 col-xxs-12 mb-20">
							<div className="b-1 brad-10 p-20 p-xxs-10 box-shadow bg-light">
								<p className="m-0 text-sm">
									<span className="text-400">Call date: </span>
									<br />
									{this.state.selectedRecording !== null && getCallDateTimeForDateTimeString(this.state.selectedRecording.callDate)}
								</p>
							</div>
						</div>
						<div className="col-md-3 col-sm-6 col-xs-6 col-xxs-12 mb-20">
							<div className="b-1 brad-10 p-20 p-xxs-10 box-shadow bg-light">
								<p className="m-0 text-sm">
									<span className="text-400">Call from:</span>
									<br />
									<a className="text-cta" href={'tel:$((this.state.selectedRecording !== null) ? this.state.selectedRecording.callerCli.replace(/\s/g, \'\') : "")'}>
										{(this.state.selectedRecording !== null) ? (this.state.selectedRecording.callerCli.substring(0) === '0' || this.state.selectedRecording.callerCli.substring(0) === '44') ? this.state.selectedRecording.callerCli : this.state.selectedRecording.callerCli : ''}
									</a>
								</p>
							</div>
						</div>
						<div className="col-md-3 col-sm-6 col-xs-6 col-xxs-12 mb-20">
							<div className="b-1 brad-10 p-20 p-xxs-10 box-shadow bg-light">
								<p className="m-0 text-sm">
									<span className="text-400">Number called:</span>
									<br />
									{(this.state.selectedRecording !== null) ? this.state.selectedRecording.calledDdi : ''}
								</p>
							</div>
						</div>
						<div className="col-md-3 col-sm-6 col-xs-6 col-xxs-12 mb-20">
							<div className="b-1 brad-10 p-20 p-xxs-10 box-shadow bg-light">
								<p className="m-0 text-sm">
									<span className="text-400">Duration:</span>
									<br />
									{(this.state.selectedRecording !== null) ? this.state.selectedRecording.recordingDurationSecs : ''}
								</p>
							</div>
						</div>
					</div>

					<audio id="audioPlayer" controls autoPlay>
						Your browser does not support the audio element. Please install newest version of Google Chrome or Firefox.
					</audio>

					{!this.state.callTranscriptionActive &&
						<div className="bg-gradient-v1 text-center mb-20 p-30 pb-50 brad-30 text-lightest">
							<div className="w-500px center-block">
								<h4 className="text-500 mb-30">Search for words spoken or not-spoken during the call with Call Transcription</h4>
								<p>Besides having your call recordings why not
									automatically transcribe them to text? Just 5p per minute.
									<OverlayTrigger trigger={['hover', 'focus']} placement="top"
										overlay={
											<Popover>
												<CallTranscriptionBenefitsList />
											</Popover>
										}>
										<span className="underline cursor-pointer ml-5">See benefits</span>
									</OverlayTrigger>
								</p>

								<CallTranscriptionActivation
									isBox={false}
									activateCallTranscriptionClicked={() => this.setState({ callTranscriptionActive: true, showCallTranscriptionActivatedConfirmation: true })}
									customToggleWrapperClass="mt-40 mb-20 b-1 brad-20 p-30 w-300px center-block text-center"
								/>
							</div>

						</div>
					}

					{this.state.showCallTranscriptionActivatedConfirmation &&
						<div className="bg-gradient-v1 text-center mb-20 p-30 pb-50 brad-30 text-lightest">
							<div className="w-500px center-block">
								<span className="circle-tick success center-block margin-bottom-20 slide-in-blurred-top scale-up1">
									<span className="icon icon-checkmark4 scale-up"></span>
								</span>
								<h4 className="text-500 mb-30 mt-20 text-success">Call transcription activated!</h4>
								<div className="text-start">
									<p>From now on, any new outbound or inbound calls will be automatically transcribed. Using the search option you will be able to search for specific words or phrases.</p>
									<p className="text-500">Make a quick test right now</p>
									<p>Make a call to or using your SwitchboardFREE number. Within minutes the call recording along with call transcription should appear.</p>
								</div>
							</div>
						</div>
					}

					{this.state.callTranscription !== undefined && this.state.callTranscription.length > 1 &&
						<div className="calls-inbox">
							<div className="calls-details" style={{ width: '100%', backgroundColor: '#f2f2f2' }}>
								<div className="row">
									<div className="call-transcription-section">
										<h5 className="text-500 mb-30">Transcription of this call</h5>

										{this.state.callTranscription.map((x, index) => {
										  return <>
												{x.text === ''
												  ? <></>
												  : <div className="col-12 mb-20 chat-bubble">
														<div className={x.isCallInitiator ? 'caller' : 'answerer'} style={{ cursor: 'pointer' }} onClick={() => this.startAudioPlayerAtSpecificTime(Number(x.startTimeInSeconds))}>
															{(index === 0 || (index !== 0 && x.isCallInitiator !== this.state.callTranscription[index - 1].isCallInitiator)) && <div className="text-xs mb-5 text-end">

																{x.isCallInitiator === true

																  ? this.state.initiatorName !== null
																    ? this.state.initiatorName
																    : simplePhoneNumberFormat(this.state.initiatorNumber)
																  :																	<>
																		{this.state.numberDescription !== null
																		  ? <>
																				{this.state.numberDescription}
																			</>
																		  :																			simplePhoneNumberFormat(this.state.numberCalled)
																		}
																	</>
																}
																<span> at {x.textTimeSaid}</span>
															</div>}
															<div className="p-20 brad-20 chat" style={{ backgroundColor: x.isCallInitiator && '#fff' }} >
																<span> {x.text} </span>
															</div>
														</div>
													</div>}
											</>
										})}
									</div>
								</div>

							</div>
						</div>}
					<div className="text-center pt-20 mb-10">
						<Button onClick={e => this.handleRecordingDownload((this.state.selectedRecording !== null) ? this.state.selectedRecording.id : 0, e)} variant="cta" size="lg" className="btn-xxs-block">Download</Button>
					</div>
					<div className="text-center pt-30 mt-30 bt-1">
						<h4 className="text-500">{(this.state.selectedRecording !== null && this.state.selectedRecording.note === null) ? 'Add' : 'Update'} note about this call</h4>
					</div>
					<div className="text-center pt-20 pb-30">
						<textarea rows="5" type="text" onChange={e => this.setState({ recordingNote: e.target.value })} defaultValue={(this.state.selectedRecording !== null) ? this.state.selectedRecording.note : ''} className="form-control" placeholder="eg. Delivery: Caller wanted to confirm the delivery date of his package." />
						<Button onClick={e => this.handleUpdateNote()} variant="cta" size="md" className="mt-30 btn-xxs-block">{(this.state.selectedRecording !== null && this.state.selectedRecording.note === null) ? 'Add' : 'Update'} note</Button>
					</div>

				</Modal.Body>
			</Modal>

		</>
  }

  handleRecordingDownload (CallRecordingId, event) {
    const context = this
    context.setState({ isLoading: true })
    event.preventDefault()

    const url = `api/CallRecording/DownloadCallRecordingAudio/${CallRecordingId}`

    fetchHelper.getBlob(url).then(response => {
      context.setState({ isLoading: false })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${CallRecordingId}_callRecording.wav`)
      document.body.appendChild(link)
      link.click()
    }).catch(error => console.error(error))
  }

  handleAddNote (callRecordingId, event, context) {
    const recording = _.find(this.state.data, ['id', callRecordingId])

    context.setState({ selectedRecording: recording })

    context.setState({ showAddNote: true })
  }

  renderNote (note) {
    const noteToShow = note.substring(0, 30)
    return (
			<OverlayTrigger placement="top" overlay={
				<Tooltip>
					{note}
				</Tooltip>
			}>
				<span>{noteToShow}...</span>
			</OverlayTrigger>

    )
  }

  handleCallInfomationButtonPressed (callId, isOutbound) {
    window.open('/customer/my-calls/call-information/' + (isOutbound === true ? 1 : 0) + '/' + callId, '_blank')
  }

  handleShowPlayer (callRecordingId, isOutbound, event, context) {
    if (this.state.callTranscriptionActive) {
      fetchHelper.getJson(`api/CallRecording/GetCallTranscription/${callRecordingId}/${isOutbound}`).then(res => {
        this.setState({ callTranscription: res.data.callTranscription, initiatorName: res.data.initiatorName, initiatorNumber: res.data.initiatorNumber, numberDescription: res.data.numberDescription, numberCalled: res.data.numberCalled })
      })
    }

    const recording = _.find(this.state.data, ['id', callRecordingId])

    if (recording.callDate.includes('T')) {
      const date = recording.callDate.split('T')[0]
      const time = recording.callDate.split('T')[1]
      recording.callDate = date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0] + ' ' + time.split(':')[0] + ':' + time.split(':')[1]
    }

    context.setState({ selectedRecording: recording })

    this.setState({ showAudioPlayer: true })
  }

  startAudioPlayerAtSpecificTime (startTimeInSeconds) {
    const audioPlayer = document.getElementById('audioPlayer')
    audioPlayer.pause()
    audioPlayer.currentTime = startTimeInSeconds
    audioPlayer.play()
  }

  handleUpdateNote () {
    const id = this.state.selectedRecording.id

    const index = _.findIndex(this.state.data, function (recording) {
      return recording.id === id
    })

    const editedRecording = this.state.selectedRecording
    editedRecording.note = this.state.recordingNote

    this.setState({ data: update(this.state.data, { [index]: { $set: editedRecording } }) })

    const url = 'api/CallRecording/UpdateNote/'

    const note = editedRecording.note

    fetchHelper.postJson(url, { id, note }).then(response => {
      if (response.data === true) {
        this.setState({ showAddNote: false })
      }
    }).catch(error => console.error(error))
  }

  loadFirstHundreCallRecordings (context) {
    fetchHelper.getJson('api/CallRecording/GetFirstHundredCallRecordings').then(res => {
      const reportData = res.data

      if (reportData !== null && reportData !== '') {
        reportData.forEach(record => { record.callerCli = (record.callerCli !== 'Withheld') ? simplePhoneNumberFormat(record.callerCli) : record.callerCli })
        reportData.forEach(record => { record.calledDdi = simplePhoneNumberFormat(record.calledDdi) })
        reportData.forEach(record => { record.callDate = simpleDateTimeFormat(record.callDate) })
        reportData.forEach(record => { record.recordingDurationSecs = simpleDurationFormat(record.recordingDurationSecs) })

        // context.setState({ data: reportData }, () => context.addFilterPlaceholder());
      }

      context.setState({ isLoading: false, hasPerformedSearch: true, isInitialSearch: true, data: reportData })

      scroller.scrollTo('report', {
        duration: 1500,
        delay: 100,
        smooth: true
      })
    })
  }
}

export default withRouter(connect(
  state => {
    return {
      services: state.sbfApp.enabledServices
    }
  },
  {
    notify: handleNotificationChange
  }
)(CallRecordingList))
