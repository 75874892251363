import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import Helmet from 'react-helmet'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ClientLogos from '../../components/ClientLogos'

class MarketingCaseStudy extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return <>
			<ScrollToTopOnMount />
			<DisplayMainFooter />
			<DisplayMainHeader />

			<Helmet>
				<title>How SwitchboardFREE Helped a Marketing Agency Achieve Campaign Success</title>
			</Helmet>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container">
					<div className="row mt-100 mt-xs-30">
						<div className="col-md-6 col-sm-12 order-last text-center p-xs-30">
							<img src="/images/case-studies/marketing3.jpg" alt="" className="img-responsive mt-20 mt-xs-0" style={{ maxWidth: '600px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-first">
							<h1 className="text-500 text-darker">How SwitchboardFREE Helped a Marketing Agency Achieve Campaign Success</h1>
							<h2 className="text-lg text-400 mb-0 mt-30 text-dark-light w-600px">
								Suzy<sup>*</sup> noticed had an influx of clients who were failing to engage with the over 55's
							</h2>

							<p className="mt-30">
								As a marketer, Suzy's business was built on the foundation that she could help any business achieve any income that they desired. However, many of her clients we're struggling to reach an older demographic with her tried and tested tactics.
							</p>

							<div className="bg-dull brad-10 p-50 d-none d-md-block mt-30" style={{ backgroundColor: '#d6e8ef' }}>
								<p className="m-0"><em>Suzy<sup>*</sup> runs a marketing agency, helping small to medium businesses increase their income.</em></p>
							</div>

						</div>
					</div>

				</div>
			</div>

			<div className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40" style={{ backgroundColor: '#d6e8ef' }}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-md-12">
							<h2 className="text-500 text-darker text-center h4 m-0">
								<span className="text-cta">&ldquo;</span>
								I didn’t want to let my clients down. I knew I had to find something different, an alternative way &amp; I would have to experiment!
								<span className="text-cta">&rdquo;</span>
							</h2>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container">
					<div className="row mt-100 mt-xs-100">
						<div className="col-md-6 col-sm-12 order-last order-md-first text-end pr-50 pr-xs-0">
							<img src="/images/case-studies/marketing.jpg" alt="Marketing agency" className="img-responsive" style={{ maxWidth: '600px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-first order-md-last">
							<p>
								From her many years of experience, she knew that social media was an effective way to drive traffic to a website. But having been blind-sighted by the likes of Facebook and Instagram, she had temporarily forgotten that the humble telephone was still a primary method for communication.
							</p>

							<div className="bg-dull brad-10 p-50 d-none d-md-block mt-30 mb-50" style={{ backgroundColor: '#f7ffd0' }}>
								<p className="mb-0"><em>&ldquo;As marketers we’ve become so obsessed with internet presence & growing an online audience, that we’ve forgotten that a large proportion of people still like to pick up the phone & talk.&rdquo;</em></p>
							</div>

							<p className="lead text-secondary text-500">
								The Opportunity of Call Management
							</p>
							<p>
								Looking for a way to experiment with launching a campaign targeted at the over 55's giving the option to call, Suzy<sup>*</sup> thought she would try SwitchboardFREE.
							</p>
							<p>
								What drew Suzy<sup>*</sup> to SwitchboardFREE was that she could have several numbers that she could then track. Real metrics were important in determining the success of her mock campaign.
							</p>

							<div className="bg-dull brad-10 p-50 d-none d-md-block mt-30" style={{ backgroundColor: '#d6e8ef' }}>
								<p className="mb-0"><em>&ldquo;By using different numbers on websites, adverts, special offers, radio & any other mediums, I knew I could accurately track the source of every call.&rdquo;</em></p>
							</div>
						</div>
					</div>

					<div className="row mt-100 mt-xs-100">
						<div className="col-md-6 col-sm-12 order-first order-md-last text-start pl-50 pl-xs-0">
							<img src="/images/case-studies/marketing2.jpg" alt="Marketing agency" className="img-responsive" style={{ maxWidth: '600px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-last order-md-first">
							<h2 className="text-lg text-500 mb-30 mt-0 text-secondary w-600px">
								A campaign that can be duplicated
							</h2>

							<p>
								Utilizing the free numbers, so she could keep her client's costs down, Suzy<sup>*</sup> ensured that the cost of calling the 0845 and 0844 numbers was clear on each of her ads. And it was clear by the results that a pay-per-minute number was not a barrier to callers, as the phones rang hot.
							</p>

							<div className="bg-dull brad-10 p-50 d-none d-md-block mt-30 mb-50" style={{ backgroundColor: '#f7ffd0' }}>
								<p className="mb-0"><em>&ldquo;Further testing proved that when a phone number was presented to an older demographic they would call rather than email or DM.&rdquo;</em></p>
							</div>

							<p>
								Having found success, Suzy<sup>*</sup> was able to go back to her clients and help them implement marketing that included business numbers. They were delighted that they could have numbers at no extra cost with SwitchboardFREE and even had the option to monitor their own metrics if they wanted. Suzy soon told all her clients about SwitchboardFREE tracking, while encouraging them to use contact numbers in their next campaign.
							</p>
						</div>
					</div>

					<div className="row">
						<div className="col-12 text-center pt-50">
							<p><sup>*</sup>Name changed for anonymity.</p>
						</div>
					</div>

				</div>
			</div>

			<div className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40" style={{ backgroundColor: '#d6e8ef' }}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-md-12">
							<h2 className="text-500 text-darker h4 m-0 text-center">
								<span className="text-cta">&ldquo;</span>
								As a marketer, I’ve found SwitchborardFREE to be invaluable to both me & my clients. For national campaigns, you can get 500+ individual numbers, or if you’re a smaller concern like myself you can have 6 numbers & more than 20 FREE features for a budget-friendly £10.96 a month. Plus, if your campaign is successful (which it will be) then you can manage the influx of calls with call stacking, call queuing & some great on-hold tunes!
								<span className="text-cta">&rdquo;</span>
							</h2>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-80 pt-xs-40 pt-80 pb-xs-40">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-7 col-md-12">
							<h2 className="text-500 text-darker h4 m-0 text-center">
								If you’d like to learn more about SwitchboardFREE or try it our for yourself, click on of the buttons below.
							</h2>

							<p className="text-center mt-30">
								<Link className="btn btn-secondary mr-20 mr-xs-0" to="/features">
									Discover Free Features
								</Link>
								<Link className="btn btn-cta mr-20 mr-xs-0" to="/get-started">
									Find Your Plan
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center mt-20">
						<div id="contentMobile"></div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull ">
				<div className="container">
					<CarouselWidget />
				</div>
			</div>

			{/* Logoos */}
			<div className="full-width bg-light">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">Partners &amp; Customers</h3>
							{/* <h6>The UK's favourite call management system</h6> */}
							<p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>
			<ClientLogos style='light' />


		</>
	}
}

export default connect(
	state => {
		return ({
		})
	},
	{}
)(MarketingCaseStudy)
