import React, { Component } from 'react'
import { fetchHelper } from '../helpers/fetchHelper'

export default class StatsBanner extends Component {
  state = {
    totalMins: '0',
    totalCustomers: '0'
  }

  render () {
    return (
            <span>
    			We have successfully handled <strong>{this.state.totalMins}</strong> call minutes for over <strong>{this.state.totalCustomers}</strong> businesses!
            </span>
    )
  }

  componentDidMount () {
    const context = this
    context.getMins()
    context.getCustomers()
    window.setInterval(function () {
      context.getMins()
    }, 300000) // Every 5-mins
  }

  getMins () {
    const url = 'api/Home/GetTotalMins'
    fetchHelper.getJson(url)
      .then(response => {
        if (response.data !== undefined) { this.setState({ totalMins: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }

  getCustomers () {
    const url = 'api/Home/GetTotalCustomers'
    fetchHelper.getJson(url)
      .then(response => {
        if (response !== undefined) { this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }
}
