import { Component } from 'react'
import { connect } from 'react-redux'
import { siteHeader } from '../constants/Constants'
import * as fromSbfApp from '../store/SBF'

class DisplayNoHeader extends Component {
  constructor (props) {
    super(props)
  }

  componentWillMount () {
    this.props.notifyOfHeaderChange(siteHeader.NO_HEADER)
  }

  render () {
    return null
  }
}

export default connect(
  state => state,
  {
    notifyOfHeaderChange: fromSbfApp.notifyOfHeaderChange
  }
)(DisplayNoHeader)
