import React from 'react'
import { Link, type RouteComponentProps } from 'react-router-dom'
import Features from '../Features/Features'

interface NumberTypesFeaturesProps {
  history: RouteComponentProps['history']
}

const NumberTypesFeatures = (props: NumberTypesFeaturesProps) => {
  return (
        <>
            <div className="full-width bg-dull pb-20 pt-xs-40 pb-xs-40 pt-20">
                <div className="container numbers-pages">
                    <div className="row">
                        <div className="col-md-12 text-center pb-40">
                            <h2 className="text-500 text-darker">Have Over 40 Features Completely Free</h2>
                            <h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-800px center-block">
                                Every plan offers more than 20 professional call management tools to help you run your business.  <Link to="/features">Learn more <span className="icon icon-arrow-right8 text-xs" /></Link>
                            </h2>
                        </div>
                    </div>
                </div>

                <Features FeaturesSlider={true} history={props.history} />
            </div>
        </>

  )
}

export default NumberTypesFeatures
