import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

class TelavoxFlow extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
        <Helmet>
                <title>Hi Hi - A laugh or greeting over the centuries | SwitchboardFREE</title>
                <meta name="description" content="From Morse code to gaming, the phrase hi hi is here to stay." />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/7-competitor-bg.jpg')" }} className="full-width full-hero-small">
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center pb-60">
                                <h1>A laugh or a greeting - ‘hi hi’ is communication over the centuries!</h1>
                                <h4>From Morse code to gaming, the phrase hi hi is here to stay.</h4>
                            </div>
                        </div>
                        <div className="col-md-12 pb-30 bb-1">
                            <p>Communication. That is what SwitchboardFREE is all about. That and ensuring your business never misses a call!</p>
                            <p>We thought we would entertain you with a couple of fun communication facts whilst informing you of how you can make sure your customers hear you say ‘Hi hi’ rather than ‘I’m sorry, I can’t take your call right now…..’</p>
                            <h4 className="text-secondary text-500 mb-30 mt-60">Spanning the centuries from the 1840s to the 2020s:</h4>
                            <p>
                                <i>Fun communication fact #1:</i> “hi hi" is the Morse equivalent of a laugh as in Morse it sounds like someone chuckling! The origin dates back to before radio (where actual sound is communicated) to the telegraph days, where sounds are just a pattern of beeps. In today’s world, Morse hi hi is comparable to a smiley!
                            </p>
                            <p>
                                <i>Fun communication fact #2:</i> “hi hi” is a modern form of greeting but especially typical in online gaming. It’s usually just hi hi but you can add an extra “hi” for emphasis!
                            </p>
                            <h4 className="text-secondary text-500 mb-30 mt-60">
                                Maintain your business communications with SwitchboardFREE
                            </h4>
                            <p>
                            Did you know that if you don’t answer the telephone when it rings you are signalling to your customer (or potential customer) that they’re not important to you? 85% of people will not call back, they will move on to your competitor. Can you afford to lose that sort of business?
                            </p>
                            <p>
                            Don’t take the risk of losing sales; get a <Link to={'/virtual-phone-numbers'}>virtual phone number</Link> from SwitchboardFREE, take control of your communications and never miss a business call again!
                            </p>
                            <p>
                            Prices start from only 99p and you’re not tied into a contract!
                            </p>
                            <p className="text-center mt-30">
                                <Link to={'/number-types'} className="btn btn-cta btn-lg">Compare numbers</Link>
                            </p>
                            <h4 className="text-secondary text-500 mt-60 mb-30">
                                Benefits of a virtual phone number
                            </h4>
                            <p>
                                <strong>Choice</strong> - choose your number(s), your town or city and your package.
                            </p>
                            <p>
                                <strong>Local/geographic numbers increase your sales calls</strong> - it is said that by advertising a <Link to={'/local-phone-numbers/'} className="underline text-cta">local (01) number</Link> you can receive up to 22% more calls from that area.
                            </p>
                            <p>
                                <strong>Image</strong> - you can be in control of the image you portray for your business simply by the number you present to your customers.
                            </p>
                            <p>
                            <strong>Never miss a call</strong> - you can divert your <Link to={'/virtual-phone-numbers'} className="underline text-cta">virtual phone number</Link> to anywhere you like so that you, or someone you nominate, can take calls at any time.
                            </p>
                            <p>
                            <strong>Call management system</strong> - manage your calls via our free call management system with over 40 valuable business tools to get the most out of your number.
                            </p>
                            <p>
                            <strong>Disaster recovery</strong> - because a <Link to={'/virtual-phone-numbers'} className="underline text-cta">virtual phone number</Link> is based over the internet you can handle your calls from anywhere.
                            </p>
                            <p>
                            <strong>Department choices</strong> - you can set up departments for your customer to choose: "Press 1 for sales, press 2 for support..."
                            </p>
                            <p>
                            <strong>Cost savings</strong> - save money on marketing; reduced call charges and no line rental and benefit from improved business efficiency and flexibility.
                            </p>
                            <p>
                            <strong>Productivity</strong> - having a portable, flexible system means you or your colleagues can take business calls wherever you may be.
                            </p>
                            <p>
                            <strong>Consistency</strong> - even though <Link to={'/virtual-phone-numbers'} className="underline text-cta">virtual phone numbers</Link> are not tied to a specific device, your customer will only ever have to call one number.
                            </p>
                            <p>
                            <strong>Efficient marketing</strong> - virtual phone lines supply helpful marketing data. You can log into your account and see where your calls are coming from.
                            </p>
                            <p>
                            <strong>Quick set up</strong> - a <Link to={'/virtual-phone-numbers'} className="underline text-cta">virtual phone number</Link>  can be set up in minutes. As long as you have a phone and an internet connection a basic service can usually be yours within minutes.
                            </p>
                            <p>
                            <strong>Integrates with a VoIP phone system</strong> - (Voice over Internet Protocol.) You can have a virtual phone number on its own if that’s all you need. You might however like to have a complete <Link to={'/business-voip-phone-system'} className="underline text-cta">VoIP phone system</Link> for unified communications <a href='https://blog.switchboardfree.co.uk/2020/a-definitive-guide-to-business-voip-phone-systems/' className="underline text-cta">Check out our blog for more information on VoIP phone systems</a>.
                            </p>

                            <h4 className="text-secondary text-500 mt-60 mb-30">
                                Choose SwitchboardFREE and ensure you always get to say “hi hi” to your callers
                            </h4>
                            <p>
                                With SwitchboardFREE you’ll be dealing with a company with over 15 years telecoms and technology experience, supported by our robust cloud-based system giving 99.9% uptime.
                            </p>
                            <p className="pt-30">
                                With SwitchboardFREE you can get:
                            </p>
                            <p>
                                <ul>
                                    <li>
                                        A vast choice of virtual phone numbers: 0800, prestigious 0207 or 0203 London, 0333, 084 and geographical 01 numbers
                                    </li>
                                    <li>
                                        An account with over 40 free business features such as call recording, call forwarding, call management and call reporting
                                    </li>
                                    <li>
                                        The ability to answer your calls wherever you are
                                    </li>
                                    <li>
                                        Either inbound calling, outbound calling or both
                                    </li>
                                    <li>
                                        VoIP phone system with choice of hardware and software if desired
                                    </li>
                                    <li>
                                        The ability to make and receive calls from anywhere in the world
                                    </li>
                                    <li>
                                        In-depth statistical and reporting features
                                    </li>
                                    <li>
                                        Choice of packages - prices start from 99p
                                    </li>
                                    <li>
                                        Free supporting app
                                    </li>
                                    <li>
                                        Easy set-up and full UK support
                                    </li>
                                </ul>
                            </p>
                            <p className="pt-30">
                                Find out more about virtual telephone numbers: <a href='https://blog.switchboardfree.co.uk/2020/a-definitive-guide-to-virtual-telephone-numbers/' className="underline text-cta">A definitive guide to Virtual Telephone Numbers</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <ClientLogos style='light' showText={true}/>
        </>
  }

  componentDidMount () {

  }
}

export default connect(
  state => {
    return state
  },
  {}
)(TelavoxFlow)
