import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface VonageProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const Vonage = (props: VonageProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Vonage vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Vonage vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Vonage vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Who are Vonage?</h2>
                            <p>Vonage are a telecommunications company providing cloud-based communication services. Their goal is to help small businesses improve their connectivity.</p>
                            <p>If you want to use Vonage, you need to have a broadband connection, a home phone and a Vonage Box, which you need to connect to the phone and the broadband.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Vonage services</h2>
                            <p>Vonage offer phone lines to home and small businesses, as well as office phone systems for enterprises with 2 to 30 employees.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Vonage phone lines for home and small business packages</h2>
                            <p className="text-500 text-lg text-dark">Premium</p>
                            <p>The Premium plan involves unlimited UK landline calls made to 01,02,03 numbers. Calls made to 084 numbers are charged at 9.5p / minute.</p>
                            <p>The monthly fee for Premium is £11.50 (incl. VAT). By subscribing to the service, the customers get 20 standard call features, such as call transfer, call divert and voicemail. Other useful tools are the call waiting (which enables the user to switch calls), the SimulRIng (multiple lines ringing at once), and the three-way calling.</p>

                            <p className="text-500 text-lg text-dark">Standard</p>
                            <p>The Standard package includes 1000 minutes to UK landlines and mobiles and the monthly subscription costs £14.40 (with VAT).</p>
                            <p>This package offers more features, albeit some of them are to be paid for. The mobile and desktop app costs £1.50 per month and conference calling services start from £10 per month. Call recording is also charged at £10 per month. Among the added features are call forward, auto attendant and hunt groups.</p>

                            <p className="text-500 text-lg text-dark">Business Unlimited</p>
                            <p>Business Unlimited offers unlimited calls to landlines and mobiles in the UK, but again, just to 01, 02 and 03 numbers. Calls to 084 numbers are priced at 9.5p / minute.</p>
                            <p>With Business Unlimited you can make and receive calls on multiple devices, and benefit from features such as busy lamp field, remote office (access your office phone to make or transfer calls via any remote phone through 3-step dialling), multi device extension (incoming calls appear at multiple locations simultaneously), along with all the other features from the other two packages.</p>
                            <p>The monthly fee is £20.40 (with VAT).</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Vonage office phone system packages</h2>
                            <p>The office phone system service that aims at businesses with 2 or more employees is again divided into 3 plans, with the same features, with just minor differences from the home and small business service.</p>

                            <p className="text-500 text-lg text-dark">Standard</p>
                            <p>This package costs £14 (incl. VAT) per month and includes 1000 minutes to landlines and mobiles. You can choose a number from a specific UK area, or port your existing number.</p>
                            <p>It is also required to have an office desktop or IP phone from Vonage. These start from £40 up to £195 per handset.</p>

                            <p className="text-500 text-lg text-dark">Business</p>
                            <p>The Business plan is priced at £18 (incl. VAT) and has 1000 minutes to landline and mobile numbers.</p>

                            <p className="text-500 text-lg text-dark">Business Unlimited</p>
                            <p>The Business Unlimited package is different from the previous two, because it allows unlimited calls to UK landlines and mobiles. It costs £20.40 (with VAT) monthly, and the customers can use all the features outlined above.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Vonage Extensions</h2>
                            <p>Vonage Extensions is a mobile app that enables customers to receive calls made to their landline number on their mobile. Similarly, they can make calls from their mobile while showing their Vonage landline number to the recipients.</p>
                            <p>It is a feature designed to serve small and home business owners.</p>

                            <p className="text-500 text-lg text-dark">Vonage Extensions prices</p>
                            <p>The prices for the Vonage Extensions app range from £8.50 to £15, depending on whether there is a limit to the calls one can make.</p>

                            <p className="text-500 text-lg text-dark">Additional fees</p>
                            <p>Aside from the extra costs for some of the features, Vonage have several other additional fees.</p>
                            <p>The Line Disconnection Fee is fixed at £23.99.</p>
                            <p>And each of fax line activation (or disconnection), virtual number activation and plan change (only if you downgrade) cost £5.99.</p>

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Differences between Vonage and SwitchboardFREE</h2>
                            <p>While Vonage is oriented more towards providing an entire phone system to their users, SwitchboardFREE is predominantly focused on call handling, and reducing the amount of missed calls. Vonage require their customers to purchase a Vonage handset, and SwitchboardFREE provide our users with free virtual landline numbers to redirect the incoming calls from. We only charge for the respective package selected by the client and have no setup fees.</p>
                            <p>In terms of number types provided, Vonage only offer local number types, and SwitchboardFREE add to these 084 and 0800 numbers. Notably, our 084 are completely free and an unlimited number of calls made to this number can be diverted to a landline. And you can get unlimited redirection to mobiles for just £10.96 per month.</p>
                            <p>Vonage supply the customers with a wide range of features, some of which deserve to be singled out. Such are the conference calling that enables more people to take part in an important call, and the hunt groups that distribute your incoming calls to multiple users at once so the first available can pick the phone immediately. Core call management features like call diversion, call transfer, voicemail, redirection to mobile phones and department selection are available for both products.</p>
                            <p>One significant difference in pricing are the charges Vonage incur for some of their main features. For example, if you want to enable call recording to your profile, you need to pay an extra £10 per month. And the mobile and desktop app costs a further £1.50 per month. Similarly, conference call services start from £10 per month, as opposed to SwitchboardFREE’s <a href="https://con-flab.co.uk/" target="_blank" rel="noreferrer">Con-Flab service</a>, which provides completely fee conference calling.</p>
                            <p>SwitchboardFREE require no long-term commitment from customers, unlike Vonage whose contracts are a minimum of 12 months.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Vonages current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.vonage.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(Vonage)
