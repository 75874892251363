import moment from 'moment'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Countdown from 'react-countdown'

export default class HandsetModal extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    stock: 0
  }

  render () {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <>
				<p className="text-700 text-cta text-uppercase text-md mb-20 mt-10">
					Create your account within...
				</p>
				<div className="countdown-timer">
					<div className="timer-block">
						{hours}<br /><span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}<br /><span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}<br /><span className="text-sm">seconds</span>
					</div>
				</div>
				<p className="mt-10 mb-20 text-lightest text-500">
					To secure your FREE Handset
				</p>
			</>
    }

    return (
      this.props.wireless
        ? <Modal className="full-screen" show={this.props.show} onHide={this.props.hide} >
					<Modal.Body>
						<span className="close-modal" onClick={this.props.hide}>
							<span>&times;</span>
						</span>
						<div className="row">
							<div className="col-md-5 text-center pt-30">
								<img className="" src="/images/product-shots/free-yealink.jpg" alt="SwitchboardFREE Wireless Phone" style={{ maxWidth: '100%' }} />
							</div>
							<div className="col-md-7">
								<h2 className="text-cta text-500 pb-30">Business Grade Cordless DECT VoIP Phone</h2>
								<p className="text-400 text-md">Yealink DECT Phone W53P is a SIP Cordless Phone System designed specifically for small businesses</p>
								<p className="mb-30">
									With our Yealink W53P Cordless Handset users can benefit from lifelike voice communications, multi-tasking convenience, professional features like intercom, transfer, and call forward. Integrated PoE (Power over Ethernet - No power supply required)
								</p>
								<p className="text-primary underline text-italic pt-10 text-500 mb-0">
									Features:
								</p>

								<ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-10 mb-30">
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Exceptional HD sound with wideband technology</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>1.8" color display with intuitive user interface</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>5-10 hours talk time, 100 hours standby time</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Free calls between handsets & App globally</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Integrated PoE Class 1 (no power adapter needed)</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Desktop or wall mountable for extra flexibility</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Make & receive calls from your SwitchboardFREE account</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Present your SwitchboardFREE number on your outbound calls</em></li>
								</ul>

								<p className="mb-30 p-0 bt-1 pt-20 text-md">
									Hurry, only  <span className="text-500">{this.state.stock} left in-stock</span>
								</p>

								<span className="p-10 bg-darker mb-20 d-inline-block center-block mt-0 text-center w-100 brad-5">
									<Countdown date={moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
								</span>

								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={this.props.hide}>
									Continue &amp; Choose Number
								</Button>

							</div>
						</div>
					</Modal.Body>
				</Modal>
        :				<Modal className="full-screen" show={this.props.show} onHide={this.props.hide} >
					<Modal.Body>
						<span className="close-modal" onClick={this.props.hide}>
							<span>&times;</span>
						</span>
						<div className="row">
							<div className="col-md-5 text-center pt-30">
								<img className="" src="/images/product-shots/T31P.png" alt="SwitchboardFREE Desk Phone" style={{ maxWidth: '100%' }} />
							</div>
							<div className="col-md-7">
								<h2 className="text-cta text-500 pb-30">SwitchboardFREE VoIP Desk Phone</h2>
								<p className="mb-30">
									An entry-level colour screen IP Phone. With high-definition voice, 320x240 2.4-inch colour main screen, 4 SIP lines, local 6-party conference call and other extended features like EHS headset, it provides a high-quality and convenient experience for SwitchboardFREE users.
								</p>
								<p className="text-primary underline text-italic pt-30 text-500">
									Features:
								</p>

								<ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-30 mb-30">
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>4 SIP Lines, SIP hotspot</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>1000 local phonebook, caller ID, call hold, call transfer </em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>2.4" main colour display</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>HD audio on speakerphone and handset</em></li>
									<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Support 6-party local conference</em></li>
								</ul>

								<p className="mb-30 p-0 bt-1 pt-20 text-md">
									Hurry, only  <span className="text-500">{this.state.stock} left in-stock</span>
								</p>

								<span className="p-10 bg-darker mb-20 d-inline-block center-block mt-0 text-center w-100 brad-5">
									<Countdown date={moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
								</span>

								{this.props.chooseType &&
									<>
										<Button variant="cta" size="lg" className="btn-xxs-block" onClick={this.props.chooseType}>
											Continue with <span className="text-500 underline">Full VoIP Plan</span>
										</Button>
										<p className="text-sm underline mt-20 text-dullest cursor-pointer" onClick={this.props.hide}>
											Or, go back to compare plans.
										</p>
									</>
								}

								{this.props.choosePlan &&
									<>
										<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.choosePlan(4)}>
											Select Unlimited
										</Button>
										<span className="ml-20 mr-20">or</span>
										<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.choosePlan(3)}>
											Select Standard
										</Button>
										<p className="text-sm underline mt-20 text-dullest cursor-pointer" onClick={this.props.hide}>
											Close &amp; compare plans.
										</p>
									</>
								}

								{this.props.checkout &&
									<>
										<Button variant="cta" size="lg" className="btn-xxs-block" onClick={this.props.hide}>
											Continue Checkout
										</Button>
									</>
								}

							</div>
						</div>
					</Modal.Body>
				</Modal>
    )
  }

  componentDidMount () {
    const context = this
    context.setState({ stock: ((Math.random() + 3) * 15).toFixed(0) })
  }
}
