import React, { useEffect, useState } from "react";
import LoadingNotification from "../components/LoadingNotification";
import { Button, Modal } from "react-bootstrap";
import { fetchHelper } from "../helpers/fetchHelper";
import { Link } from "react-router-dom";

interface ManagedSbfAccount
{    
    clientId: Number
    fullName: string
    emailAddress: string
}

const SwapAccount: React.FunctionComponent = (): JSX.Element => {

    const [loadInitalData, setLoadedInitalData] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [managedAccounts, setManagedAccounts] = useState<ManagedSbfAccount[]>([]);
    const [swapToClientId, setSwapToClientId] = useState<Number>(0);

    useEffect(() =>{
        if(loadInitalData === false && loadingData === false){
            setLoadingData(true);

            fetchHelper.getJson('/api/users/GetListOfManagedAccounts').then((res) =>{
                setManagedAccounts(res.data === '' ? [] : res.data as ManagedSbfAccount[]);
                setLoadedInitalData(true);
                setLoadingData(false);
            })

        }

        if(swapToClientId !== 0){
            const clientId = swapToClientId;
            setSwapToClientId(0);

            fetchHelper.getJson('/api/users/GetJwtForManagedAccount/' + clientId).then((res) =>{
                const data = res.data;
                
                if(data !== '' && data !== null && data !== undefined){
                    localStorage.setItem('user', JSON.stringify(data));                    
                    window.location.href = '/customer/dashboard';
                }
            })
        }
    });
    
	return (
        <> 
            <div className="bg-light p-30 pb-40  brad-10 b-1 w-800px center-block mb-20 mt-50" style={{ overflow: "auto" }}>
                <h4 className="pb-10 text-400 text-center mb-0">Managed Accounts</h4>
                <p className="mb-40 text-center">View all the accounts that you can manage below. Or, go <Link to="/customer/dashboard" className="underline text-primary cursor-pointer">back to your dashboard</Link>.</p>

                <div className="row">
                    {loadingData === true
                        ?
                        <LoadingNotification className="mt-30" message={'Loading...'} isLoading={true} textColor="text-dark" height={50} />
                        :
                        <>
                            {managedAccounts.map((value, index) => {
                                return <div className="row">
                                    <div className="col-xs-12 col-md-12 ">
                                        <div className="brad-10 b-1 box-shadow p-20 mb-20 text-center">
                                            <table style={{width:'100%'}}>
                                                <tr>
                                                    <td>
                                                        <p className="text-500 text-dullest pl-10 pr-10 mb-10" style={{textAlign:"left", whiteSpace:'nowrap'}}>
                                                            {value.fullName} 
                                                        </p>
                                                    </td>
                                                    <td rowSpan={2} style={{textAlign:"right", width:'99%'}}>
                                                        <Button variant="cta" size="sm" onClick={() => setSwapToClientId(value.clientId)}>Log in now</Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="text-400 m-0 text-sm pl-10 mb-10" style={{textAlign:"left"}}>
                                                            {value.emailAddress}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </>
                    }

                    <p className="text-center mt-30">
                        <Link to="/customer/dashboard" className="btn btn-secondary">Back to your dashboard</Link>
                    </p>

                </div>
            </div>
        </>
    );

};

export default SwapAccount;
