import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { PromptTypes } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'
import PromptAudioPlayer from '../PromptAudioPlayer'
import MainIntroductionInstructionHelperDialog from './InstructionHelperDialogs/MainIntroductionInstructionHelperDialog'

const MainIntroduction = props => (
    <div className="row bg-light p-30 zoom">
        <div className="col-md-5 pb-sm-10">
            <h5 className="text-secondary text-500 text-lg">
                Main Introduction
            </h5>
            <p className="text-400">
                This is the announcement that will greet your callers when calling your SwitchboardFREE number.  <br /><a onClick={props.handleShowMainIntroductionHelperInstruction} className="underline" id="MainIntroductionMoreInfoLink">More info</a>
            </p>
        </div>

        <div className="col-md-7 mt-10">
            <p> Select introduction:</p>
            <MainIntroductionInstructionHelperDialog />
            <Select
                className="form-control select-menu"
                classNamePrefix="custom"
                id="globalIntroSelect"
                onChange={props.handleGlobalIntroChange}
                options={props.globalIntroPrompts}
                simpleValue
                name="selectedGlobalIntro"
                value={props.selectedGlobalIntroPrompt !== undefined ? { value: props.selectedGlobalIntroPrompt.promptId, label: props.selectedGlobalIntroPrompt.promptDescription } : undefined}
                searchable={false}
                clearable={false}
                isLoading={props.isLoadingPromptOptions}
            />
            {/* <ReactAudioPlayer */}
            {/* className="mt-30" */}
            {/* src={props.introUrl} */}
            {/* controls */}
            {/* /> */}
            <PromptAudioPlayer promptId={props.selectedGlobalIntroPrompt !== undefined ? props.selectedGlobalIntroPrompt.promptId : undefined} voice={props.voice} promptType={PromptTypes.departmentalMainIntro} />
        </div>
    </div>
)

const mapStateToProps = state => {
  return ({
    globalIntroPrompts: state.configuration.prompts.globalIntroPrompts,
    selectedGlobalIntroPrompt: state.configuration.configuration.globalIntroPrompt ? state.configuration.configuration.globalIntroPrompt : undefined,
    isLoadingPromptOptions: state.configuration.loading.globalIntroPrompts,
    voice: state.configuration.configuration.voicePrompt.voicePromptId
  })
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainIntroduction)

// export default connect(
//     state => state.configuration,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(IntroductionSection);
