import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import connect from 'react-redux/es/connect/connect'
import * as yup from 'yup'
import { fetchHelper } from '../../helpers/fetchHelper'
import { simplePhoneNumberFormat } from '../../helpers/utils'
import { handleNotificationChange } from '../../store/SBF'

const _ = require('lodash')

class FaxesConfig extends Component {
  static defaultProps = {}

  static propTypes = { handleUpdateCancel: PropTypes.func, handleUpdateSaved: PropTypes.func }

  state = { description: '', email: '', errors: {}, touched: { description: false, email: false } }

  componentDidMount () {
    yup.addMethod(yup.string, 'emails', function (message) {
      return this.test('emails', message, function (value) {
        const arr = value.split(';').map(s => s.trim())
        const arrSchema = yup.array().of(yup.string().email())
        return arrSchema.isValidSync(arr)
      })
    })

    this.validation = yup.object().shape({
      description: yup.string().required('Please enter a description to recognised your Fax-2-Email number'),
      email: yup.string('Please enter a valid destination email').required().emails('Please enter a valid destination email')
    })
  }

  componentDidUpdate (prevProps, prevState) {
    // if (prevProps.faxNo && this.props.faxNo && (prevProps.faxNo.email !== this.props.faxNo.email || prevProps.fax.description !== this.props.faxNo.description))
    // {
    //             this.setState({email:this.props.faxNo.email, });
    // }

    if (!prevProps.fax && this.props.fax) {
      this.setState({ email: this.props.fax.email, description: this.props.fax.faxDescription })
    }
  }

  render () {
    const faxNo = this.props.fax ? this.props.fax.faxNumber : undefined
    return (
            <Modal className="modal-small" show={faxNo} onHide={e => this.props.handleUpdateCancel()}>
                <Modal.Header closeButton>
                    {faxNo &&
                        <Modal.Title>Settings for fax number {simplePhoneNumberFormat(faxNo)}</Modal.Title>
                    }
                </Modal.Header>
                {faxNo ? <Modal.Body className="clearfix">
                    <label>Description</label>
                    <input type='text' className="form-control" maxLength={64} value={this.state.description} onChange={e => {
                      this.setState({
                        description: e.target.value
                      }, () => {
                        if (this.state.touched.description) {
                          const errors = this.validate()
                          this.setState({ errors })
                        }
                      })
                    }} />
                    {/* <label>Enter a description to distinguish between multiple fax numbers</label> */}
                    {this.state.touched.description && this.state.errors.description &&
                        <p className="text-danger">{this.state.errors.description}</p>}
                    <label className="pt-30">Send faxes to email address</label>
                    <input type='email' className="form-control" maxLength={128} value={this.state.email} onChange={e => {
                      this.setState({ email: e.target.value }, () => {
                        if (this.state.touched.email) {
                          const errors = this.validate()
                          this.setState({ errors })
                        }
                      })
                    }} />
                    {/* <p>Enter the email address you would like your faxes to be sent to.</p> */}
                    {this.state.touched.email && this.state.errors.email && <p className="text-danger">{this.state.errors.email}</p>}

                    <div className="pt-30 pb-20 text-center">
                        <Button onClick={e => this.handleFaxSaveConfig()}
                            disabled={this.hasFailedValidation(this.state.errors)} variant="cta" size="lg">
                            Save Settings
                        </Button>
                    </div>
                </Modal.Body> : null}
            </Modal>
    )
  }

  validate () {
    const context = this

    function getErrorsFromValidationError (validationError) {
      const FIRST_ERROR = 0
      return validationError.inner.reduce(function (errors, error) {
        return {
          ...errors,
          [error.path]: error.errors[FIRST_ERROR]
        }
      }, {})
    }

    try {
      context.validation.validateSync(this.state, { abortEarly: false })
      return {}
    } catch (error) {
      const errors = getErrorsFromValidationError(error)
      return errors
    }
  }

  handleFaxSaveConfig () {
    const context = this
    const errors = this.validate()

    this.setState({ errors, touched: { description: true, email: true } })

    if (!_.isEmpty(errors)) {

    } else {
      const url = '/api/Numbers/UpdateFaxConfig'
      const faxNo = this.props.fax ? this.props.fax.faxNumber : undefined
      const data = { description: this.state.description, email: this.state.email, faxNo }

      fetchHelper.postJson(url, data).then(res => {
        if (res.data) {
          context.props.handleUpdateSaved(data)
        } else {
          context.props.notify({ message: 'Error saving configuration, please contact support', isError: true })
        }
      }).catch(function (error) {
        context.props.notify({ message: 'Error saving configuration, please contact support', isError: true })
      })
    }
  }

  hasFailedValidation (error) {
    return ((this.state.touched.email && this.state.errors.email) || (this.state.touched.description && this.state.errors.description))
  }
}

export default connect(
  state => {
    return state
  },
  { notify: handleNotificationChange }
)(FaxesConfig)
