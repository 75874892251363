import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const RedirectSectionHelperDialog = props => (
    <Modal size="w-900px" show={props.isDisplayed}
           onHide={props.hideRedirectSectionHelperDialog}>
        <Modal.Header closeButton>
            <Modal.Title className="text-500">Redirects and ring groups explained</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="ratio ratio-16x9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/F7SQ-xGK9g4" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            <div className="full-width full-secondary row mt-30">
                <div className="col-md-12">
                <h3 className="text-center pb-30 text-500 pt-30">Let us handle your <br/>SwitchboardFREE calls</h3>
                <p className="text-center">
                    Did you know that you can redirect your calls to our team of professional receptionist just by adding
                    <br className="d-none d-lg-block"/>
                     a unique number into one of your redirect boxes? Perfect for businesses that are
                    <br className="d-none d-lg-block"/>
                    missing many calls or if you want to give your company that &quot;big feel&quot;.
                </p>
                <p className="text-center">With us you will have not just one receptionist, but a team of <br className="d-none d-lg-block"/>professionally trained receptionists.</p>
                <div className="ratio ratio-16x9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/B6maFUzVwyE" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p className="text-center mt-40 mb-60 pb-40 bb-2">
                    <Link className="btn btn-cta btn-lg" to={'/telephone-answering-service/'} target="_blank">Find out more</Link>
                </p>

                <h3 className="text-center pb-30 text-500 pb-30 pt-30">Are you thinking about employing a receptionist yourself?
                <br/>Watch the below video before you do that!</h3>
                <div className="ratio ratio-16x9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/2JlSuAFt3IM" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p className="text-center mt-30">
                    <Link className="btn btn-cta btn-lg" to={'/telephone-answering-service/'} target="_blank">Find out more</Link>
                </p>
                </div>
            </div>
            <div className="full-width full-secondary row">
                <div className="col-md-12 text-center">
                    <h3 className="text-center pb-30 text-500">Interested in using our telephone answering service?<br/>
                    Call us now on <a href="tel:02031891213">0203 189 1213</a>.
                    </h3>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideRedirectSectionHelperDialog}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.RedirectSectionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedirectSectionHelperDialog)
