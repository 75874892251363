import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import CallRecordingBenefits from '../../../components/CallRecordingBenefits'
import DisplaySimpleHeader from '../../../components/DisplaySimpleHeader'
import FooterMain from '../../../components/FooterMain'
import CarouselWidget from '../../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import StatsBanner from '../../../components/StatsBanner'
import { isLoggedIn } from '../../../selectors'

class CallRecording extends React.Component {
  constructor (props) {
    super(props)

    this.handleShowLogin = this.handleShowLogin.bind(this)
    this.handleCloseLogin = this.handleCloseLogin.bind(this)

    this.state = {
      showLogin: false
    }
  }

  handleShowLogin () {
    this.setState({ showLogin: true })
  }

  handleCloseLogin () {
    this.setState({ showLogin: false })
  }

  scrollTo () {
    scroller.scrollTo('start', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  render () {
    return <><FooterMain /><ScrollToTopOnMount /><DisplaySimpleHeader />
            <Helmet>
                <title>Call Recording - Start recording your phone calls</title>
                <meta name="description" content="Don’t miss any details; avoid any possible legal complications and stay compliant." />
                <body className={'body-simple bg-light call-recording'.concat(localStorage.getItem('isBeta') ? 'beta-body' : '')} />
            </Helmet>
            <header>
                <div className="full-width full-hero no-overlay pt-100 pb-100 pt-xxs-50 pb-xxs-50" id="hero" style={{ backgroundImage: "url('/images/backgrounds/call-recording3.jpg')", backgroundPosition: '50% 20%' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center text-lightest">
                                <h1 className="text-lightest text-500">Start recording your calls!</h1>
                                <h2 className="subtitle text-400">Keep firm control over your companies communications.</h2>
                                <p>
                                    From not missing important details to reducing conflicts, recording your calls<br className="d-none d-md-block" /> may have a wider impact on your business than you dare consider.
                                </p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-20">
                                <Link className="btn btn-cta btn-xl pl-xs-20 pr-xs-20 mr-20 btn-mobile-block mb-xs-5" to={'#'} onClick={this.handleShowLogin}>Get it now</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center arrow-scroll">
                                <a onClick={this.scrollTo}>
                                    <span className="icon icon-arrow-down4"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="full-width full-secondary p-20">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-center text-lightest mt-10 text-100 text-lg">
                            <StatsBanner />
                        </h3>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light" id="start">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pb-60">
                            <h2>Why you should record your calls</h2>
                        </div>
                    </div>
                    <CallRecordingBenefits />

                    <div className="row">
                        <div className="col-md-12 text-center pb-30 pt-20">
                            <h3 className="text-400">Add call recording to your account</h3>
                            <p className="text-xs-left pb-20">
                                A small addition to your business that can have a big impact.
                            </p>
                            <Link className="btn btn-cta btn-xl pl-xs-20 pr-xs-20 mr-20 mb-xs-5" to={'#'} onClick={this.handleShowLogin}>Get it now</Link>
                            <p className="text-sm pt-20">
                                If you prefer to do it over the phone or if you have any questions, <br className="d-none d-md-block" />give us a call on <a href="tel:02031891213">0203 189 1213</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>

        </>
  }

  componentDidMount () {

    // setHero();
    // window.addEventListener("resize", setHero);
    // function setHero() {
    //     const heroDiv = document.getElementById('hero');
    //     if (heroDiv !== null) {
    //         if (window.outerWidth > 980) {
    //             let height = window.innerHeight - 110;
    //             heroDiv.style.height = height + "px";
    //         }
    //         // else {
    //         // 	heroDiv.style.height = "auto";
    //         // }
    //     }
    // }
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state),
      monthlyPrice: state.home.productDetails.monthlyRepeatPayment,
      setupCharge: state.home.productDetails.monthlyFirstPayment
    })
  },
  {}
)(CallRecording)
