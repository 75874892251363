import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Slider from 'react-slick'
import MohTracks from '../components/MohTracks'

export default class MusicOnHoldPlaylists extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      show: false,
      showMohTracks: false,
      mohCatId: 0
      // showXmas: false,
      // showBeauty: false,
      // showFuneral: false,
      // showSolicitors: false,
      // showDental: false,
      // showJob: false,
      // showProduct: false,
      // showRadio: false,
      // showDriving: false,
      // showEstates: false,
      // showBuilders: false,
      // showSecurity: false,
      // showFlowers: false,
      // showCakes:false,
      // exampleSrc: ""
    }
  }

  handleCloseModal () {
    this.setState({
      showMohTracks: false,
      mohCatId: undefined
    })
  }

  handleShowTracks (id, name) {
    this.setState({
      showMohTracks: true,
      mohCatId: id,
      mohName: name
    })
  }

  render () {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    return <>
                <Slider className="showreel-page" {...settings}>

                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(78, 'Best of Ed Sheeran')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/78.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Ed Sheeran</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(112, 'Best of Adele')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/112.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Adele</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(124, 'Best of Oasis')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/124.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Oasis</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(136, 'Best of Coldplay')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/136.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Coldplay</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(111, 'Best of David Bowie')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/111.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Best of David Bowie</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(121, 'Best of Elton John')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/121.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Elton John</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(96, 'Best of George Michael')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/96.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of George Michael</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(22, 'Best of Abba')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/22.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Abba</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(87, 'Best of AC/DC')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/87.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of AC/DC</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(123, 'Best of Britney Spears')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/123.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Britney Spears</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(83, 'Best of Aerosmith')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/83.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Aerosmith</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(307, 'Best of Burt Bacharach')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/307.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of Burt Bacharach</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30">
                    <div className="img-cover b-0 brad-10" style={{ backgroundImage: 'url(images/moh/end-bestof.jpg)' }}>
                    </div>
                    <p className="text-sm text-400 pt-20"><a href="/get-started?utm_source=music_on_hold_page&utm_medium=website&utm_campaign=music_on_hold" className="text-cta text-500"><i>Create account</i></a> or <a href="/login?utm_source=music_on_hold_page&utm_medium=website&utm_campaign=music_on_hold" className="text-cta text-500"><i>Log In</i></a></p>
                </div>

               </Slider>

            <Modal show={this.state.showCakes} onHide={() => this.setState({ showCakes: false })}>
                <Modal.Body>
                    <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/575320728&color=%23ff9314&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>
                    <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/575320710&color=%23ff9314&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>
                 </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" size="xs" onClick={() => this.setState({ showCakes: false })}>Close</Button>
                </Modal.Footer>
            </Modal>

            <MohTracks showModal={this.state.showMohTracks} catId={this.state.mohCatId} hideModal={e => this.handleCloseModal()} name={this.state.mohName} />

        </>
  }
}
