import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { is0203Offer, is0207Offer, is033Offer, is0800Offer, isGeoOffer, NumbersGlobalOfferPrice } from '../../constants/Constants'
import GetProdPrice from '../GetProdPrice'

// example use with some props enabled.

// <NumberTypeInfo
//     hidePrices={true}
//     hideIcons={true}
//     hideCompareButtons={true}
//     CustomDivClass="col-xs-12 b-3"
//     CTAbuttonText="Choose number"
//     CustomCTAButtonClass="xxx"
//     CustomCompareButtonClass="yyy"
//     CustomHeadingClass="text-secondary"
//     CustomDescriptionClass="text-cta text-500"
//     Custom01headingText="custom 01 heading"
//     Custom0203headingText="custom 0203 heading"
//     Custom0207headingText="custom 0207 heading"
//     Custom0800headingText="custom 0800 heading"
//     Custom03headingText="custom 03 heading"
//     Custom084headingText="custom 084 heading"
//     Custom01description="01 custom description text"
//     Custom0203description="0203 custom description text"
//     Custom0207description="0207 custom description text"
//     Custom0800description="0800 custom description text"
//     Custom03description="0203 custom description text"
//     Custom084description="084 custom description text"
// />

const NumberTypeInfo = props => (
    <div className="row row-flex flex-3-col number-types-block">
        <div className={props.CustomDivClass === undefined ? 'col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30' : props.CustomDivClass }>
            {!props.hideIcons &&
                <div className="icon-block-cta bg-light"><span className="icon icon-location2 text-secondary"></span></div>
            }
            <h4 className={props.CustomHeadingClass === undefined ? 'text-500' : props.CustomHeadingClass }>
                {props.Custom01headingText === undefined
                  ? 'Local numbers'
                  : props.CustomHeadingClass
                }
            </h4>
            <p className={!props.hidePrices ? 'text-400 ' + props.CustomDescriptionClass : 'text-400 pb-20 ' + props.CustomDescriptionClass}>
                {props.Custom01description === undefined
                  ? 'We love to shop and call locally. Advertising a local number can increase interest in your business by 22% from the local area.'
                  : props.Custom01description
                }
            </p>
            {!props.hidePrices &&
                <h5 className={`${isGeoOffer ? 'text-cta' : 'text-secondary'} text-500 text-sm pb-10`}>
                    {isGeoOffer && 'On Sale - '}From just &pound;{NumbersGlobalOfferPrice} per month
                </h5>
            }
            <div className="button-set">
                {!props.hideCompareButtons &&
                    <Link className={props.CustomCompareButtonClass === undefined ? 'btn btn-secondary btn-sm m-5 btn-xxs-block' : props.CustomCompareButtonClass } to={'/number-types'}>
                        Compare
                    </Link>
                }
                <Link className={props.CustomCTAButtonClass === undefined ? 'btn btn-cta btn-sm m-5 btn-xxs-block' : props.CustomCTAButtonClass } to={'/local-phone-numbers'}>
                    {props.CTAbuttonText === undefined ? 'Learn more' : props.CTAbuttonText }
                </Link>
            </div>
        </div>
        <div className={props.CustomDivClass === undefined ? 'col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30' : props.CustomDivClass }>
            {!props.hideIcons &&
                <div className="icon-block-cta bg-light"><span className="icon icon-gherkin text-secondary"></span></div>
            }
            <h4 className={props.CustomHeadingClass === undefined ? 'text-500' : props.CustomHeadingClass }>
            {props.Custom0203headingText === undefined
              ? '0203 London numbers'
              : props.Custom0203headingText
            }
            </h4>
            <p className={!props.hidePrices ? 'text-400 ' + props.CustomDescriptionClass : 'text-400 pb-20 ' + props.CustomDescriptionClass}>
            {props.Custom0203description === undefined
              ? 'Give your company a London presence without the expense of renting a London office.'
              : props.Custom0203description
            }
            </p>
            {!props.hidePrices &&
                <h5 className={`${is0203Offer ? 'text-cta' : 'text-secondary'} text-500 text-sm pb-10`}>
                    {is0203Offer && 'On Sale - '}From just <GetProdPrice prefix="0203" /> per month
                </h5>
            }

            <div className="button-set">
            {!props.hideCompareButtons &&
                <Link className={props.CustomCompareButtonClass === undefined ? 'btn btn-secondary btn-sm m-5 btn-xxs-block' : props.CustomCompareButtonClass } to={'/number-types'}>Compare</Link>
            }
                <Link className={props.CustomCTAButtonClass === undefined ? 'btn btn-cta btn-sm m-5 btn-xxs-block' : props.CustomCTAButtonClass } to={'/0203-numbers'}>
                    {props.CTAbuttonText === undefined ? 'Learn more' : props.CTAbuttonText }
                </Link>
            </div>
        </div>
        <div className={props.CustomDivClass === undefined ? 'col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30' : props.CustomDivClass }>
            {!props.hideIcons &&
                <div className="icon-block-cta bg-light"><span className="icon icon-bigben text-secondary"></span></div>
            }
            <h4 className={props.CustomHeadingClass === undefined ? 'text-500' : props.CustomHeadingClass }>
                {props.Custom0207headingText === undefined
                  ? '0207 London numbers'
                  : props.Custom0207headingText
                }
            </h4>
            <p className={!props.hidePrices ? 'text-400 ' + props.CustomDescriptionClass : 'text-400 pb-20 ' + props.CustomDescriptionClass}>
                {props.Custom0207description === undefined
                  ? 'Rarely available and highly prestigious London numbers that give you an established London presence.'
                  : props.Custom0207description
                }
            </p>
            {!props.hidePrices &&
                <h5 className={`${is0207Offer ? 'text-cta' : 'text-secondary'} text-500 text-sm pb-10`}>
                    {is0207Offer && 'On Sale - '}From just &pound;{NumbersGlobalOfferPrice} per month
                </h5>
            }
            <div className="button-set">
            {!props.hideCompareButtons &&
                <Link className={props.CustomCompareButtonClass === undefined ? 'btn btn-secondary btn-sm m-5 btn-xxs-block' : props.CustomCompareButtonClass } to={'/number-types'}>Compare</Link>
            }
                <Link className={props.CustomCTAButtonClass === undefined ? 'btn btn-cta btn-sm m-5 btn-xxs-block' : props.CustomCTAButtonClass } to={'/0207-numbers'}>
                    {props.CTAbuttonText === undefined ? 'Learn more' : props.CTAbuttonText }
                </Link>
            </div>
        </div>
        <div className={props.CustomDivClass === undefined ? 'col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30' : props.CustomDivClass }>
            {!props.hideIcons &&
                <div className="icon-block-cta bg-light"><span className="icon icon-0800 text-secondary"></span></div>
            }
            <h4 className={props.CustomHeadingClass === undefined ? 'text-500' : props.CustomHeadingClass }>
            {props.Custom0800headingText === undefined
              ? '0800 numbers'
              : props.Custom0800headingText
                }
            </h4>
            <p className={!props.hidePrices ? 'text-400 ' + props.CustomDescriptionClass : 'text-400 pb-20 ' + props.CustomDescriptionClass}>
                {props.Custom0800description === undefined
                  ? 'Allow your customers to call free from landlines and mobiles. A great number to use on advertising.'
                  : props.Custom0800description
                }
            </p>
            {!props.hidePrices &&
                <h5 className={`${is0800Offer ? 'text-cta' : 'text-secondary'} text-500 text-sm pb-10`}>
                    {is0800Offer && 'On Sale - '}From just &pound;{NumbersGlobalOfferPrice} per month
                </h5>
            }
            <div className="button-set">
            {!props.hideCompareButtons &&
                <Link className={props.CustomCompareButtonClass === undefined ? 'btn btn-secondary btn-sm m-5 btn-xxs-block' : props.CustomCompareButtonClass } to={'/number-types'}>Compare</Link>
            }
                <Link className={props.CustomCTAButtonClass === undefined ? 'btn btn-cta btn-sm m-5 btn-xxs-block' : props.CustomCTAButtonClass } to={'/0800-numbers'}>
                    {props.CTAbuttonText === undefined ? 'Learn more' : props.CTAbuttonText }
                </Link>
            </div>
        </div>
        <div className={props.CustomDivClass === undefined ? 'col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30' : props.CustomDivClass }>
            {!props.hideIcons &&
                <div className="icon-block-cta bg-light"><span className="icon icon-03 text-secondary"></span></div>
            }
            <h4 className={props.CustomHeadingClass === undefined ? 'text-500' : props.CustomHeadingClass }>
                {props.Custom03headingText === undefined
                  ? '0333 numbers'
                  : props.Custom03headingText
                }
            </h4>
            <p className={!props.hidePrices ? 'text-400 ' + props.CustomDescriptionClass : 'text-400 pb-20 ' + props.CustomDescriptionClass}>
                {props.Custom03description === undefined
                  ? 'Get a national presence and allow your callers to phone using their inclusive bundled minutes.'
                  : props.Custom03description
                }
            </p>
            {!props.hidePrices &&
                <h5 className={`${is033Offer ? 'text-cta' : 'text-secondary'} text-500 text-sm pb-10`}>
                    {is033Offer && 'On Sale - '}From just &pound;{NumbersGlobalOfferPrice} per month
                </h5>
            }
            <div className="button-set">
            {!props.hideCompareButtons &&
                <Link className={props.CustomCompareButtonClass === undefined ? 'btn btn-secondary btn-sm m-5 btn-xxs-block' : props.CustomCompareButtonClass } to={'/number-types'}>Compare</Link>
            }
                <Link className={props.CustomCTAButtonClass === undefined ? 'btn btn-cta btn-sm m-5 btn-xxs-block' : props.CustomCTAButtonClass } to={'/03-numbers'}>
                    {props.CTAbuttonText === undefined ? 'Learn more' : props.CTAbuttonText }
                </Link>
            </div>
        </div>
        <div className={props.CustomDivClass === undefined ? 'col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30' : props.CustomDivClass }>
            {!props.hideIcons &&
                <div className="icon-block-cta bg-light"><span className="icon icon-phone2 text-secondary"></span></div>
            }
            <h4 className={props.CustomHeadingClass === undefined ? 'text-500' : props.CustomHeadingClass }>
            {props.Custom084headingText === undefined
              ? '084 numbers'
              : props.Custom084headingText
                }
            </h4>
            <p className={!props.hidePrices ? 'text-400 ' + props.CustomDescriptionClass : 'text-400 pb-20 ' + props.CustomDescriptionClass}>
                {props.Custom084description === undefined
                  ? 'Give your business a national presence at absolutely no cost to you when routing to a landline.'
                  : props.Custom084description
                }
            </p>

            {!props.hidePrices &&
                <h5 className="text-secondary text-500 text-sm pb-10">
                    Free
                </h5>
            }
            <div className="button-set">
            {!props.hideCompareButtons &&
                <Link className={props.CustomCompareButtonClass === undefined ? 'btn btn-secondary btn-sm m-5 btn-xxs-block' : props.CustomCompareButtonClass } to={'/number-types'}>Compare</Link>
            }
                <Link className={props.CustomCTAButtonClass === undefined ? 'btn btn-cta btn-sm m-5 btn-xxs-block' : props.CustomCTAButtonClass } to={'/084-numbers'}>
                    {props.CTAbuttonText === undefined ? 'Learn more' : props.CTAbuttonText }
                </Link>
            </div>
        </div>
    </div>
)

export default connect()(NumberTypeInfo)
