import React from 'react'
import { Popover } from 'react-bootstrap'
export const TpsProtectionPerUserCharge = 3
export const PaymentMode = 1 // 0 = Live, 1 = Sandbox
export const MaxNumbersLoad = 120
export const FanvilProdId = 1
export const YeaLinkProdId = 2
export const ht201 = 3
export const HeadsetId = 4
export const x3s = 6
export const x7a = 7
export const t31p = 8
export const w73p = 9
export const FanvilDongle = 10
export enum PaymentProviders {
  WorldPay = 1,
  GoCardless = 4,
  Manual = 8,
  Stripe = 9
};

// Outbound Plans
export const UnlimitedOutboundPlan = 4
export const BasicOutboundPlan = 3
export const PaygPlan = 2

// Package Offers
export const UnlimPlanDetails = {
  FreeCallBlock: true,
  FreeCallRecording: false,
  UnlimDiscount: false, // Was £15, now £12 offer
  FreeHeadset: false,
  FreeHandset: true
}

// Number type on 99p offer
export const is0203Offer = false
export const is0207Offer = false
export const is0800Offer = true
export const is0808Offer = true
export const is033Offer = false
export const isGeoOffer = false
export const NumbersGlobalOfferPrice = 0.99 // the promo price, for all number types
export const numberOfferDuration = 1 // Total months for intro offer
export const isSoftphoneOffer = true
export const GlobalOfferEnabled = false
export const AppOnlyPrice = 5
export const AppOnlyPrice0800 = 10

// Toggle "Community Support Package on/off"
export const commSupportPackAvailable = true
export const free033NumberPackageId = 216

// Product offers
const isUmrOnOffer = false
export const isCallRecordOnOffer = true
export const VoipHandsetDiscount = 0
export const MultiBuyDiscount = 0.12 // 12% discount when buying handsets equal to users
export const MultiBuyDiscountMin = 3 // Min. users eligible for multi buy discount
export const AnnualDiscount = 0.05 // 5% discount when paying annually
export const PowerSupplyFee = 2.99 // £2.99 to add power supply (We'll add it anyway, so free money!)
export const ExpressDeliveryCharge = 10
export const PortingCredit = 50 // £50 free account credit offer for porting number in
export const CallAnsweringPricePerCall = 3.4 // £3.40 standard price per call
export const PowerSupplyCharge = 3.99 // £3.99, lol
export const WizardDiscounts = {
  HandsetDiscount: 0.2, // 20%
  ExtraUsers: 0.5, // 50%
  CallBlock: 0.5, // 50%
  CallRecord: 0.5 // 50% off
}

// Whether 0800 numbers are on offer
// export const is0800OnOffer = true;
// export const is0203OnOffer = false;
// export const is01OnOffer = true

// Sort Directions
export const ASCENDING = '0'
export const DESCENDING = '1'

// Number of numbers that must be on an account before we display the number filter textbox
export const NUMBER_FILTER_LOWER_THRESHOLD = 10

export enum validationStates {
  VALID = 'VALID',
  INVALID = 'INVALID',
  INDETERMINATE = 'INDETERMINATE',
  UNCHECKED = 'UNCHECKED',
  EMPTY = 'EMPTY',
  INUSE = 'INUSE', // Reserved only for email in use error
}

export namespace validationStates {
  export function fromBool (isValid: boolean): validationStates {
    return isValid ? validationStates.VALID : validationStates.INVALID
  }
}

export enum billingPeriod {
  WEEK = 2,
  MONTH = 3,
  YEAR = 4,
  ONEOFF = 1,
  NOTSET = 0,
  DAY = 5,
  NONE = -1,
  BIANNUAL = 6
};

export const validationMessages = {
  EMPTY_FIELD: 'Please enter a value',
  INVALID_NAME: 'Please enter a valid name',
  INVALID_TEL: 'Please enter a valid UK phone number',
  INVALID_EMAIL: 'Please enter a valid email'
}

export const siteFooter = {
  MAIN: 'FOOTER_MAIN',
  SIMPLE: 'FOOTER_SIMPLE',
  NO_FOOTER: 'NO_FOOTER',
  CONFIG: 'CONFIG_FOOTER',
  LOGGEDIN: 'LOGGEDIN_FOOTER'
}

export const siteHeader = {
  MAIN: 'HEADER_MAIN',
  SIMPLE: 'HEADER_SIMPLE',
  SIMPLEV2: 'HEADER_SIMPLEV2',
  NO_HEADER: 'NO_HEADER'
}

export const profileFields = {
  firstName: 'FIRST_NAME',
  surName: 'SURNAME',
  email: 'EMAIL',
  emailUnique: 'EMAIL_UNIQUE',
  confirmEmail: 'CONFIRM_EMAIL',
  password: 'PASSWORD',
  confirmPassword: 'CONFIRM_PASSWORD'
}

export enum MinuteBundle {
  none = 0,
  small = 1,
  medium = 2,
  large = 3,
}

export enum productBillingOption {
  billingOptionMonthly = 13,
  billingOptionYearly = 14,
  contactBookBillingOptionMonthly = 83,
  contactBookBillingOptionYearly = 84,
  callFilteringBillingOptionMonthly = 79,
  callFilteringBillingOptionYearly = 80,
  umrBillingOptionMonthly = isUmrOnOffer ? 78 : 71,
  umrBillingOptionYearly = 89,
  goldenNumberBusinessOptionMonthly = 64,
  goldenNumberBusinessOptionYearly = 65,
  goldenNumberProfessionalOptionMonthly = 66,
  goldenNumberProfessionalOptionYearly = 67,
  goldenNumberGoldOptionMonthly = 68,
  goldenNumberGoldOptionYearly = 69,
  showReelBillingOptionMonthly = 90,
  endpointBillingOptionMonthly = 96,
  fanvilx4BillingOptionOneOff = 97,
  yealinkBillingOptionOneOff = 106,
  vipCallerBillingOptionMonthly = 109,
  vipCallerBillingOptionYearly = 110,
  callBlockingBillingOptionMonthly = 111,
  callBlockingBillingOptionYearly = 112,
  whatsappSupportMonthly = 107,
  f2eBillingOptionMonthly = 13,
  f2eBillingOptionYearly = 14,
  callRecordingHalfPriceSingleBillingOptionMonthly = 108,
  tpsProtectionMonthly = 117,
  tpsProtectionYearly = 118,
  callRecordingAllBillingOptionMonthly = 92,
  callRecordingUnlimitedBillingOptionMonthly = 93,
  halfPriceCallRecordingAllBillingOptionMonthly = 119,
  halfPriceCallRecordingAllBillingOptionYearly = 120,
  halfPriceCallRecordingUnlimitedBillingOptionMonthly = 121,
  halfPriceCallRecordingUnlimitedBillingOptionYearly = 122,
  callRecordingAllBillingOptionYearly = 123,
  callRecordingUnlimitedBillingOptionYearly = 124
}

export const WizardProductBillingOptions = {
  endpointOptionMonthly: 113,
  callBlockingOptionMonthly: 114,
  callRecordingOptionMonthly: 108
}

export const VatMultiplier = 0.2

export enum SignupStart {
  HomePage,
  NumberTypeLandingPage,
  WhatsappLandingPage,
}

export enum ProductIds {
  autoMobileTopUp = 86,
  mobileRedirectCredit = 77,
  prompts = 79,
  cityNumbersPack = 120,
  goldenNumber = 80,
  contactBook = 119,
  faxToEmail = 76,
  callFiltering = 117,
  umr = 97,
  showReel = 123,
  callRecordSingle = 125,
  callRecordAll = 124,
  endpoint = 126,
  outboundMinutes = 127,
  fanvilX4 = 128,
  outboundCallRecording = 129,
  vipCallers = 131,
  callBlocking = 132,
  whatsAppSupport = 136,
  tpsProtection = 137,
}

export const signupPath = '/get-started'

export const GoldenNumberPackageIds = {
  businessPriceMonthly: 1,
  businessPriceYearly: 2,
  professionalPriceMonthly: 3,
  professionalPriceYearly: 4,
  goldPriceMonthly: 5,
  goldPriceYearly: 6,
  unknown: 0
}

export enum NumbersPremiumFilter {
  all = 999,
  business = 0,
  professional = 5,
  golden = 10,
};

export enum numberPackageTypes {
  COMBINED = 'combined',
  MOBILE = 'mobile',
  LANDLINE = 'landline',
}

export const AutoTopupValues = [
  { value: 10, label: '£10.00', price: 10.0 },
  { value: 11, label: '£20.00', price: 20.0 },
  { value: 12, label: '£30.00', price: 30.0 },
  { value: 13, label: '£40.00', price: 40.0 },
  { value: 14, label: '£50.00', price: 50.0 },
  { value: 17, label: '£100.00', price: 100.0 },
  { value: 18, label: '£200.00', price: 200.0 }
]

// export const SingleTopupValues = [
//     {value: 15, label: 'One-off payment of £20.00', price:20.00},
//     {value: 16, label: 'One-off payment of £10.00', price:10.00},
//     {value: 17, label: 'One-off payment of £30.00', price:30.00},
//     {value: 18, label: 'One-off payment of £40.00', price:40.00},
//     {value: 19, label: 'One-off payment of £50.00', price:50.00},
//     {value: 73, label: 'One-off payment of £100.00', price:100.00},
//     {value: 74, label: 'One-off payment of £200.00', price:200.00},
// ];

export const SingleTopupValues = [
  { price: 5, topupId: 115 },
  { price: 10, topupId: 16 },
  { price: 15, topupId: 116 },
  { price: 20, topupId: 15 },
  { price: 40, topupId: 18 },
  { price: 30, topupId: 17 },
  { price: 50, topupId: 19 }
]

export const SingleTopupDefaultValue = 15
export const AutoTopupDefaultValue = AutoTopupValues.filter(
  (f) => f.value === 11
)[0]

export const MinimumPromptPurchaseThreshold = 180.0
export const MinimumPromptPurchaseThresholdInhouse = 50.0
export const PricePerWord = 0.7

export const InhouseMinimumPromptPurchaseThreshold = 50.0
export const InhousePricePerWord = 0.27

export const MultiNumberPrice = 1.99
export const MultiNumberPrice0800 = 4.99

// Crude Regex's to filter out any obvious bad phone numbers, proper validation is done backend!
export const MobileRegex = /^(((\+|00)(44)|(44)|(0)|)(7)([0-9]{9}))/
export const LandlineRegex = /^(((\+|00)(44)|(44)|(0)|)(1|2)([0-9]{9}))/
export const BasicTelRegex = /^\+?[\d\s]{7,15}/

// More advanced regex validation
export const ukLandlineRegex =
    /^((\(?01\d{3}\)?\s?\d{3}\s?\d{3})|(\(?02\d{3}\)?\s?\d{3}\s?\d{3})|(\(?01\d{2}\)?\s?\d{3}\s?\d{4})|(\(?02\d{2}\)?\s?\d{3}\s?\d{4})|(\(?01\d{1}\)?\s?\d{4}\s?\d{4}))|(\(?02\d{1}\)?\s?\d{4}\s?\d{4})(\s?\#(\d{4}|\d{3}))?$/
export const ukMobileRegex =
    /^(((\+44\s?\d{4}|\(?0\d{4}\)?|44\s?\d{4})\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/

export const PromptTypes = {
  standardIntro: 2,
  standardOoh: 7,
  standardWhisper: 3,
  departmentalMainIntro: 4,
  departmentalChoice: 5,
  departmentalIntro: 6,
  departmentOoh: 1,
  departmentalWhisper: 8,
  standardVoicemail: 9,
  departmentalVoicemail: 10,
  holdMusicTrack: 11,
  blockedCaller: 12
}

export const PromptVoice = {
  paul: 1,
  janice: 2,
  chris: 3
}

export const PhoneTypes = {
  landline: 1,
  mobile: 2,
  unknown: 3
}

export const RedirectType = {
  PhoneNumber: 1,
  Endpoint: 2,
  Group: 3
}

export const SmsLimit = 140 // Character limit for SMS
export const ShowOutboundToAll = true // Set to true when we want anyone to be able to signup
export const HideRingGroupsConfig = false
export const RedirectLimit = 10 // Maximum number of redirects per user
export const RedirectGroupLimit = 50 // Maximum redirect groups allowed
export const RedirectGroupItemsLimit = 10 // Maximum items (redirects) allowed in a group
export const FirstNameLimit = 20 // Character limit for user firstname
export const LastNameLimit = 30 // Character limit for user lastname
export const NameLimit = FirstNameLimit + LastNameLimit // Full name limit
export const CompanyNameLimit = 30 // Character limit for client company name
export const EmailLimit = 128 // Char limit for email addresses
export const AddressLimit = 30 // Char limit for client.add1, add2 etc

export enum BasketItemType {
  phoneNumber = 1,
  package = 2,
  hardware = 3,
  packageOutbound = 4,
  Botlon = 5,
}

export const DeliveryType = {
  standard: 1,
  free: 2,
  express: 3
}

export const VoipPhoneModels = [
  {
    model: 'X4',
    id: 2,
    price: 84.99,
    monthlyPrice: 6
  },
  {
    model: 'W52P',
    id: 4,
    price: 104.99,
    monthlyPrice: 7.5
  }
]

export const additionalUserMins = 1000
export const postcodeApiKey = 'ce980-f478c-354fb-05a62'
export const WhatsAppBusinessPackageId = 217
export const additionalLandlineRate = 3 // pence per min
export const additionalMobileRate = 6 // pence per min
export const internationalFromRate = 3 // 3p pm

// The enum values should not be edited or change positions as this can introduce bugs, new items can be safely added to the end of the list however
export enum SignupStep {
  IsSelectingPlan, // isOnSelectSignupTypeStep
  HasSelectedPlan,
  IsSelectingNumberType, // isOnSelectNumberStep
  HasSelectedNumberType,
  IsSelectingPhoneNumbers,
  HasSelectedPhoneNumbers,
  IsSelectingPackage, // isOnChoosePlanStep
  IsSelectingAdditionalOutboundUsers,
  IsProvidingLoginDetails,
  IsProvidingProfile, // isOnProvideSignupProfileDetailsStep
  HasProfileSignupProfile, // isOnSignupCheckoutStep
  IsCheckingOut
}

// Shared popovers
export const UserPopover = (
    <Popover title="What is a user?">
        <p>
            A SwitchboardFREE user is someone that can make outbound calls from your
            SwitchboardFREE number(s). (e.g. If you work from an office and there is
            yourself and 6 other employees who all need to be able to make calls then
            you'd need 7 users).
        </p>
        <p>
            Users are able to make calls via their PC (using a softphone), the
            SwitchboardFREE Phone iOS/Android app or using our SwitchboardFREE Desktop
            handset.
        </p>
        <p>
            Users can be added or removed at anytime via your SwitchboardFREE
            administration area.
        </p>
    </Popover>
)

// Phone info / specs
export const phone_2 = (
    <>
        <p className="text-500 text-secondary">Generic</p>
        <ul>
            <li>4 SIP Lines</li>
            <li>HD Voice</li>
            <li>PoE Enabled</li>
            <li>2 LCD Colour Screens (Main + DSS)</li>
            <li>
                Handset(HS) / Hands-free (HF) / Headphone(HP) mode
                <br /> (EHS support for Plantronics headsets)
            </li>
            <li>Intelligent DSS Keys</li>
            <li>Desktop / Wall-mount installation</li>
            <li>Optional External Power Supply</li>
            <li>Economical and Environmental friendly package</li>
            <li>Industrial Standard Certifications: CE/FCC</li>
        </ul>
        <p className="text-500 text-secondary">Call Features</p>
        <ul>
            <li>Call out / answer / reject</li>
            <li>Mute / Unmute (microphone)</li>
            <li>Call Hold / Resume</li>
            <li>Call Waiting</li>
            <li>Intercom</li>
            <li>Caller ID Display</li>
            <li>Speed Dial</li>
            <li>Anonymous Call (Hide Caller ID)</li>
            <li>Call Forwarding (Always/Busy/No Answer)</li>
            <li>Call Transfer (Attended/Unattended)</li>
            <li>Call Parking/Pick-up (depending on server)</li>
            <li>Redial/Auto-Redial</li>
            <li>Do-Not-Disturb (per line / per phone)</li>
            <li>Auto-Answering (per line)</li>
            <li>Voice Message (on server)</li>
            <li>Local 3-way Conference</li>
            <li>Hot Line</li>
            <li>Hot-Desking</li>
        </ul>
        <p className="text-500 text-secondary">Phone Features</p>
        <ul>
            <li>Phonebook (500 entries)</li>
            <li>Remote Phonebook (XML/LDAP)</li>
            <li>Call log (100 entries, in/out/missed) </li>
            <li>Black/White List Call Filtering</li>
            <li>Voice Message Waiting Indication (VMWI)</li>
            <li>Programmable DSS/Soft keys</li>
            <li>Network Time Synchronisation</li>
            <li>Action URL / Active URI</li>
        </ul>
        <p className="text-500 text-secondary">Audio</p>
        <ul>
            <li>
                HD Voice Microphone/Speaker (Handset/Hands-free, 0 ſġ7KHz
                <br /> Frequency Response)
            </li>
            <li>Wideband ADC/DAC 16KHz Sampling</li>
            <li>Narrowband CODEC: G.711a/u, G.723.1, G.726-32K, G.729AB</li>
            <li>Wideband CODEC: G.722</li>
            <li>
                Full-duplex Acoustic Echo Canceller (AEC) – Hands-free Mode,
                <br /> 96ms tail-length
            </li>
            <li>
                Voice Activity Detection (VAD) / Comfort Noise Generation (CNG) /<br />{' '}
                Background Noise Estimation (BNE) / Noise Reduction (NR)
            </li>
            <li>Packet Loss Concealment (PLC)</li>
            <li>Dynamic Adaptive Jitter Buffer up to 300ms</li>
            <li>DTMF: In-band, Out-of-Band – DTMF-Relay (RFC2833) / SIP INFO</li>
        </ul>
        <p className="text-500 text-secondary">Networking</p>
        <ul>
            <li>
                Physical: 10/100Mbps(X4), 10/100/1000Mbps (X4G) Ethernet, dual
                <br /> bridged port for PC bypass
            </li>
            <li>IP Configuration: Static / DHCP / PPPoE</li>
            <li>Network Access Control: 802.1x</li>
            <li>VPN: L2TP (Basic Unencrypted) / OpenVPN</li>
            <li>VLAN</li>
            <li>QoS</li>
        </ul>
        <p className="text-500 text-secondary">Protocols</p>
        <ul>
            <li>SIP2.0 over UDP/TCP/TLS</li>
            <li>RTP/RTCP/SRTP</li>
            <li>STUN</li>
            <li>DHCP</li>
            <li>PPPoE</li>
            <li>802.1x</li>
            <li>L2TP (Basic Unencrypted)</li>
            <li>OpenVPN</li>
            <li>SNTP</li>
            <li>FTP/TFTP</li>
            <li>HTTP/HTTPS</li>
            <li>TR069</li>
        </ul>
        <p className="text-500 text-secondary">RFCs</p>
        <ul>
            <li>
                354/1321/1350/1769/1889/1890/2131/2132/2616/2617/2661/2833/2976
                <br /> /3261/3262/3263/3264/3265/3268/3311/3489/3711/4346/4566/5630/
                <br /> 5865{' '}
            </li>
        </ul>
        <p className="text-500 text-secondary">Deployment &amp; Maintenance</p>
        <ul>
            <li>
                Auto-Provisioning via FTP/TFTP/HTTP/HTTPS/DHCP OPT66/SIP
                <br /> PNP/TR069
            </li>
            <li>Web Management Portal</li>
            <li>Web-based Packet Dump</li>
            <li>Configuration Export / Import</li>
            <li>Phonebook Import/Export</li>
            <li>Firmware Upgrade</li>
            <li>Syslog</li>
        </ul>
        <p className="text-500 text-secondary">Physical Specifications</p>
        <ul>
            <li>Main LCD x1: 2.8 inch (320x240) LCD colour screen</li>
            <li>DSS key-mapping LCD x1: LCD colour screen</li>
            <li>
                Keypad: 38 keys, including:
                <br />
                &nbsp;- 4 Soft-keys
                <br />
                &nbsp;- 6 Function keys
                <br />
                &nbsp;- 5 Navigation keys
                <br />
                &nbsp;- 12 Standard Phone Digits keys
                <br />
                &nbsp;- 3 Volume Control keys, Up/Down/Mute(Microphone)
                <br />
                &nbsp;- 1 Hands-free key
                <br />
                &nbsp;- 6 DSS Keys with tri-colour LED
                <br />
                &nbsp;- 1 Page-Jump/Configuration (PJC) key
            </li>
            <li>HD Hands-free Speaker (0 ſġ7KHz) x1</li>
            <li>
                HD Hands-free Microphone (0 ſġ7KHz) x1
                <ul>
                    <li>HD Handset (RJ9) x1</li>
                    <li>Standard RJ9 Handset Wire x1</li>
                </ul>
            </li>
            <li>
                1.5M CAT5 Ethernet Cable x1
                <br /> ͻRJ9 Phone Jacket x2: Handset x1, Headphone x1
            </li>
            <li>
                RJ45 Ethernet Jacket x2: Network x1 (802.3AF POE Class 2 Enabled),
                <br /> PC x1 (Bridged to Network)
            </li>
            <li>Main Chipset: Broadcom</li>
            <li>DC Power Input: 5V/1A</li>
            <li>
                Power Consumption: (X4) Idle – ſ 1.3W, Peak – ſ4.3W
                <br /> (X4G) Idle – ſ 1.7W, Peak – ſ5.7W
            </li>
            <li>Working Temperature: 0 ~ 40ɗ</li>
            <li>Working Humidity: 10 ſ 65%</li>
            <li>Dual-Functional Back Rack x1: Desktop Stand / Wall-mount</li>
            <li>Colour: Black</li>
            <li>Package Dimensions: 285 x 270 x 65mm (W x H x L)</li>
        </ul>
    </>
)

export const phone_4 = (
    <>
        <p className="text-500 text-secondary">
            The Perfect IP DECT Phone for any Business
        </p>
        <p>
            The Yealink W52P is a scalable cordless IP DECT phone combination
            comprising one W52H handset and a DECT base station. This is a flexible,
            state-of-the-art cordless phone which can grow with your business.
        </p>
        <p>
            Up to 5 stylish W52 handsets can be registered to the base station, which
            can handle up to 4 simultaneous calls made by its 5 SIP accounts. The DECT
            signal provides coverage of up to 50m indoors and 300m outdoors, keeping
            everyone connected as your workplace grows.{' '}
        </p>
        <p className="text-500 text-secondary">
            Powerful DECT phone's wide range of features
        </p>
        <p>
            This powerful DECT phone's wide range of features includes 3-way
            conferencing, call forwarding, intercom, call hold and call transfer,
            giving you comprehensive control over your call management.
        </p>
        <p>
            This phone is easy to set up and works with the most widely used
            platforms, such as Broadsoft, Asterisk, 3CX, etc.
        </p>
        <p className="text-500 text-secondary">Features:</p>
        <ul>
            <li>Cordless IP DECT phone with a separate base station</li>
            <li>Base supports up to 5 handsets</li>
            <li>Range up to 50m indoors</li>
            <li>Range up to 300m outdoors</li>
            <li>Energy-saving features</li>
            <li>Up to 4 simultaneous calls</li>
            <li>Up to 5 handsets, Up to 5 VoIP accounts</li>
            <li>Handset select for receiving&nbsp; calls</li>
            <li>Handset and Number select for making calls</li>
            <li>Paging, Intercom, Auto-answer</li>
            <li>Call hold, Call transfer</li>
            <li>Switching between calls</li>
            <li>3-way conferencing</li>
            <li>Call waiting, Mute, DND</li>
            <li>Caller ID display, Redial</li>
            <li>Anonymous call, Anonymous call rejection</li>
            <li>Call forward (Always/Busy/No Answer)</li>
            <li>Speed dial, Voicemail, Silence</li>
            <li>Message Waiting Indication (MWI)</li>
            <li>Local&nbsp; Phonebook for up to 500 entries (store in the base)</li>
            <li>Remote Phonebook</li>
            <li>Phonebook search / import / export</li>
            <li>Call history (Outgoing/Missed/Accepted)</li>
            <li>Direct IP call without SIP proxy</li>
            <li>Reset to factory, Reboot</li>
            <li>Keypad lock, Emergency call</li>
            <li>Dial Plan,&nbsp;&nbsp; Music on hold</li>
            <li>Broadsoft Directory, BroadSoft Call Log</li>
            <li>Broadworks Feature Key Synchronization</li>
            <li>9 ringer melodies</li>
            <li>Screen Saver,&nbsp; Two kinds of colour schemes</li>
            <li>Multiple languages</li>
            <li>Full-duplex speakerphone</li>
            <li>Receiver volume control: 5 steps</li>
            <li>Ringer volume control: 5 steps+off</li>
            <li>Multiple advisory tones</li>
            <li>Acoustic warning for low battery status</li>
            <li>DTMF</li>
            <li>Wideband codec: G.722</li>
            <li>Narrowband codec: G.711µ/A, G.723, G.726, G.729, iLBC</li>
            <li>VAD, CNG, AEC, PLC, AJB</li>
            <li>Range: 50 meters indoor, 300 meters outdoor</li>
            <li>10 hours talk time, 100 hours standby time</li>
            <li>1.8’’ 128x160 pixels colour display</li>
            <li>Desktop or wall mountable</li>
            <li>LCD backlit, Key backlit</li>
            <li>Energy-saving ECO mode/ECO Mode+</li>
            <li>
                12 key numerical keypad, 5 navigation keys 2 softkeys, 6 function keys,
                6 shortcut keys
            </li>
            <li>3 LEDs on Base: 1 x power, 1 x Network, 1 x Call</li>
        </ul>
    </>
)

export const CarrierIds = [39, 47, 50, 51]

export const VoicemailPlanId = 237
export const VoicemailPlan0800Id = 238

// Hardcoded bolton package ids
export const small0800 = 208
export const med0800 = 133
export const large0800 = 236
export const small = 214
export const med = 107
export const large = 164

// 0800 inbound price
export const mob0800 = 20.55
export const split0800 = 16.96
export const ll0800 = 15.96

export const baseUrl = 'http://1f4e-194-12-7-146.ngrok.io'

export interface LoginDetails {
  isAuthenticated: boolean
  fName: string
  email: string
  clientId: number
  sName: string
  password?: string
  persist?: boolean
}

export const stripeReturnBaseUrl = 'https://switchboardfree.co.uk/'
export const stripePublicKey = 'pk_live_51M81EYJF5zIVQdioOFqNt4rGCIXNVQABo9Jnv9CWYHStTTUvmowfEznZ8k6nfEuFawE3Tvd0z1fS3gK5KNk4dnd500l5lT6KRA'
