import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'
import CustomPrompts from '../../CustomPrompts'

const IntroductionInstructionHelperDialog = props => (
    <Modal className="w-900px" show={props.isDisplayed}
           onHide={props.handleHideIntroductionHelperInstruction}>
        <Modal.Header closeButton className='text-center'>
            <Modal.Title className="text-500">Introduction</Modal.Title>
        </Modal.Header>
        <Modal.Body className="col-md-12">
            <p>
                The introduction is the first thing your callers will hear when calling your number during your open
                hours.
                </p>
                <p>
                Should anyone call your number outside of your open hours they will be played your out of hours message
                (which you can also set on this settings page) and also be given the option to leave a voicemail if you
                have the voicemail facility switched on.
            </p>
            <div className="col-md-12 text-center pb-30 pt-30">
                <h3 className="text-500">Make your company sound amazing!</h3>
                <p>Choose one of the below options to make your company stand out from the rest. Select an option below to record your out of hours message, introduction, call screening, voicemail message, departments menu... or simply do it yourself.</p>
            </div>
            <CustomPrompts />

        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.handleHideIntroductionHelperInstruction}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.introductionInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntroductionInstructionHelperDialog)
