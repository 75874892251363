import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Logos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import SvgRender from '../../components/SvgRender'
import { BasicOutboundPlan, productBillingOption, ProductIds, UnlimitedOutboundPlan, validationStates, WizardProductBillingOptions } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat } from '../../helpers/utils'
import { getConfiguration, getConfigValidators, isLoggedIn } from '../../selectors'
import { changeUserDetails, handleInitSipUsers, validateUser } from '../../store/Configuration'
import { getProductPrice } from '../../store/Home'
import { handleCallBlockingEnabledChange, handleNotificationChange, handleShowCallbackForm, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'
import TermsAndConditions from '../../Text/TermsAndConditions'
const _ = require('lodash')

class OutboundUpsell extends React.Component {
  state = {
    isLoading: true,
    isSaving: false,
    isBasic: false,
    qty: 0,
    step: 1,
    totalAdded: 0
  }

  constructor (props) {
    super(props)
  }

  render () {
    const { config } = this.props
    const { validation } = this.props

    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        this.setState({ offerEnded: true })
        return 'Offer ended.'
      } else {
        // Render a countdown
        return <>
					<p className="text-500 text-lightest text-uppercase text-md mb-20">
						HURRY... <em className="text-cta">Ends <u>Midnight Sunday</u></em>
					</p>
					<div className="countdown-timer">
						<div className="timer-block">
							{days}<br /><span className="text-sm">days</span>
						</div>
						<div className="timer-block">
							{hours}<br /><span className="text-sm">hours</span>
						</div>
						<div className="timer-block">
							{minutes}<br /><span className="text-sm">minutes</span>
						</div>
						<div className="timer-block">
							{seconds}<br /><span className="text-sm">seconds</span>
						</div>
					</div>
				</>
      }
    }

    let unlimPlan = { rental: 0, seconds: 0 }
    let basicPlan = { rental: 0, seconds: 0 }

    if (this.state.packages) {
      unlimPlan = this.state.packages.find(f => f.packageId === UnlimitedOutboundPlan)
      basicPlan = this.state.packages.find(f => f.packageId === BasicOutboundPlan)
    }

    const callBlockPrice = { firstPayment: 0, repeatPayment: 0, discount: 50 }

    if (this.props.productDetails) {
      const callBlockProdDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.callBlocking)

      if (callBlockProdDetails) {
        callBlockPrice.firstPayment = callBlockProdDetails.monthlyPrice.firstPaymentAmount
        callBlockPrice.repeatPayment = callBlockProdDetails.monthlyPrice.repeatPaymentAmount
      }
    }

    // Sort discounts
    unlimPlan = { ...unlimPlan, discount: 53.333333 }
    basicPlan = { ...basicPlan, discount: 50 }

    return <><DisplayMainFooter />
			<Helmet>
				<body className={'body-simple bg-dull'} />
				<title>EXCLUSIVE! Get Unlimited Outbound Calls!</title>
			</Helmet>
			{this.state.isLoading
			  ? <div className="p-50">
					<LoadingNotification loadingText={'Getting your plans, please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
				</div>
			  :				<>
					<div className="p-50 p-sm-30 p-xs-15 pt-xs-50 pt-100" style={{ background: "#fff url('/images/campaigns/outbound/background.jpg') no-repeat", backgroundPosition: '125% 100%', backgroundSize: '60%' }}>
						<div className="container p-xs-0">
							<div className="row mb-50">
								<div className="col-md-8 col-sm-10 mx-auto text-center">
									<span className="bg-secondary p-10 pr-50 pl-50 pl-xs-10 pr-xs-10 text-light text-lg text-500 text-uppercase text-lightest text-xs-sm">
										Existing customer exclusive
									</span>
									<p className="text-lg text-400 mt-30">
										<em>We Want To Reward You With This Incredible, One-Time Only, <br className="d-none d-sm-block" />Rock-Crushing Offer...</em>
									</p>
									<h1>
										Make <span className="text-cta">Outgoing Calls</span> Using Your SwitchboardFREE Number <em className="text-500"><u>For A Whole Month FREE!</u></em>
									</h1>
									{this.state.step < 4 && !this.state.offerEnded &&
										<>
											<span className="p-30 bg-darker mb-20 d-inline-block center-block brad-100 mt-50">
												<Countdown date="2021-08-22T23:59:59" renderer={renderCountdown} />
											</span>
											<p className="text-sm mb-0 p-0">
												{((Math.random() * 15) + 2).toFixed(0)} people upgraded in the last hour
											</p>
										</>
									}
								</div>
							</div>
							<div className="row">
								<div className={`${this.state.step === 3 ? 'col-lg-8 col-md-10 col-sm-12 ' : 'col-lg-8 col-md-9 col-sm-12'}`}>
									<div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
										{(this.state.step === 3 && this.state.isLoadingSipUsers === true)
										  ? <LoadingNotification loadingText={'Getting your users, please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
										  :											this.state.offerEnded
										    ? <>
													<h3 className="text-500 mt-30">
														Oh no!
													</h3>
													<p className="text-400 lead mb-10 mt-0">Sorry, but this offer has now ended.</p>
													<p className="text-md mt-30">
														Please keep an eye on your inbox and our social media channels for new offers in the future.
													</p>
													<p className="mt-30">
														<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
													</p>
												</>
										    :												this.state.diallerSent === true
										      ? <>
														<h3 className="text-500 mt-30">
															Thanks!
														</h3>
														<p className="text-400 lead mb-10 mt-0">We'll give you a call shortly.</p>
														<p className="text-md mt-30">
															We've successfully received your request and one of our team will call you shortly to discuss your requirements and help you get setup.
														</p>
														<p className="mt-30">
															<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
														</p>
													</>
										      :											this.renderContent(this.state.step, basicPlan, unlimPlan, callBlockPrice, validation, config.sipUsers)
										}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="bg-dull p-50 text-center">
						<sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
					</div>
				</>
			}

			<div className="bg-light pt-50 pb-50">
				<h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
				<Logos style="light" />
			</div>

			<div className="full-width bg-dull">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>

			<Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
				<Modal.Header closeButton>
					<Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TermsAndConditions />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
				</Modal.Footer>
			</Modal>

			<ChoosePayment
				total={this.state.wpAmount ? calculateVat(this.state.wpAmount) : 0}
				clientId={this.props.clientId}
				purchaseData={this.state.key}
				returnRoute={`get-outbound?t=${this.state.token}`}
				email={this.props.userDetails.email}
				name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
				mandate={!this.props.services.hasAgreement}
			/>

		</>
  }

  renderContent (step, basicPlan, unlimPlan, callBlockPrice, validation, sipUsers) {
    switch (step) {
      case 1:
        return (
					<>
						<p className="text-400 text-uppercase text-lg mb-10 mt-0">Upgrade your account today!</p>
						<div className="row mb-30">
							<div className="col-sm-3 col-md-2 col-xs-3 text-xs pt-10 col-xxs-12 text-center text-xs-start pb-xxs-10">
								<em>Upgrade option:</em>
							</div>
							<div className="col-sm-9 col-md-10 col-xs-9 col-xxs-12 text-center text-xs-start">
								<span className={`mr-10 text-500 ${this.state.isBasic ? 'text-muted' : 'active'}`}>Unlimited</span>
								<Toggle
									onClick={() => this.setState({ isBasic: this.state.isBasic !== true })}
									on=""
									off=""
									offstyle="default"
									onstyle="success"
									width="null"
									height="null"
									size="sm"
									className="toggle-secondary rounded h-35px w-60px"
									active={this.state.isBasic}
								/>
								<span className={`ml-10 text-500 ${this.state.isBasic ? 'active' : 'text-muted'}`}>Basic</span>
							</div>
						</div>

						{this.state.isBasic
						  ? <>
								<h3 className="text-500">
									{basicPlan.seconds / 60} Mins of Outbound Calls <br className="d-none d-sm-block" />
									<span className="text-cta">FREE <span className="text-xs">For A Month <em className="text-400">(+ {basicPlan.discount}% OFF... For Life!)</em></span></span>
								</h3>
								<p className="text-dullest text-sm">
									<strike>Normally &pound;{basicPlan.rental}</strike> - &pound;{(basicPlan.rental - (basicPlan.rental * (basicPlan.discount / 100))).toFixed(0)} per user/month thereafter. <u>Cancel anytime.</u>
								</p>
							</>
						  :							<>
								<div className="row">
									<div className="col-md-10">
										<h3 className="text-500 mt-0">
											Unlimited Outbound Calls <br className="d-none d-sm-block" />
											<span className="text-cta">FREE <span className="text-xs">For A Month <em className="text-400">(+ {unlimPlan.discount.toFixed(0)}% OFF... For Life!)</em></span></span>
										</h3>
										<p className="text-dullest text-sm">
											<strike>Normally &pound;{unlimPlan.rental}</strike> - &pound;{(unlimPlan.rental - (unlimPlan.rental * (unlimPlan.discount / 100))).toFixed(0)} per user/month thereafter. <u>Cancel anytime.</u>
										</p>
									</div>
									<div className="col-md-2">
										<img src="/images/campaigns/outbound/best-value.png" alt="Best Value" />
									</div>
								</div>
							</>
						}

						<ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-30 text-xs-xs">
							{this.state.isBasic
							  ? <>
									<li className="text-md mb-5"><i className="icon icon-cross2 text-danger mr-5"></i><em><strike>UNLIMITED</strike> 400 Mins of Outgoing Calls <sup>*</sup></em></li>
									<li className="text-md mb-5"><i className="icon icon-cross2 text-danger mr-5"></i><em><strike>&pound;50 Call Answering Credit </strike></em></li>
								</>
							  :								<>
									<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>UNLIMITED Outgoing Calls <sup>*</sup></em></li>
									<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>&pound;50 Call Answering Credit</em></li>
								</>
							}
							<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Present Any Of Your SwitchboardFREE Numbers When You Dial Out</em></li>
							<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>FREE Softphone For Your PC or Laptop</em></li>
							<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>FREE Mobile Phone App To Make &amp; Receive Calls</em></li>
							<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Full UK Technical Support</em></li>
							<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>One Month Free, Then Rolling Monthly!</em></li>
						</ul>

						{this.state.submittingToDialler
						  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
						  :							<>
								<p className="mt-30 mb-20">
									<Button variant="cta" size="lg" className="btn-block" onClick={() => this.handleAddUsersToAccount(1, this.state.isBasic ? basicPlan : unlimPlan)}>Get your free month now</Button>
								</p>
								{this.state.diallerSent === false &&
									<p className="text-400 text-danger text-center">
										Sorry, there was a problem submitting your request, please try again.
									</p>
								}
								<p className="text-center">
									<Button variant="link" className="text-dark" onClick={() => this.handleAddToDialler()}>Or, request more information</Button>
								</p>
							</>
						}

					</>
        )
      case 2:
        return (
					<>
						<div className="text-center">
							<span className="bg-light p-20 brad-20 box-shadow shadow-lighter d-inline-block">
								<img src="/images/icons/nav/nav_dropdown_about-us.svg" width="50" />
							</span>
							<h3 className="text-500 mt-30">
								Add Extra Users
											</h3>
							<p className="text-400 lead mb-10 mt-0">Allow others in your team to make &amp; receive calls</p>
							<p className="text-dullest text-sm text-400">
								<span className="text-cta text-uppercase text-500">Free for a month</span> (&pound;{this.state.isBasic ? (basicPlan.rental - (basicPlan.rental * (basicPlan.discount / 100))).toFixed(0) : (unlimPlan.rental - (unlimPlan.rental * (unlimPlan.discount / 100))).toFixed(0)} per user/month thereafter. <u>Cancel anytime.</u>)
											</p>
							<p className="col-md-10 mx-auto text-md mt-30">
								Additional users will be able to make & receive calls using your SwitchboardFREE number just like you. Calls betwewen users are free anywhere in the world & multiple users can be combined into teams to have all phones ring at once.
											</p>

							<div className="row pt-10 text-center clearfix">
								<p className="text-500 mt-30 text-md">Number of users to add</p>
								<div className="col-md-12 text-500">
									<form className={'qty qty-secondary mt-10'} style={{ display: 'inline-block' }}>
										<input
											type="button"
											className="btn btn-default"
											onClick={() => this.handleChangeQty(true)}
											value="-"
											disabled={this.state.qty === 0}
										/>
										<input
											type="tel"
											id="number"
											className="form-control"
											value={this.state.qty}
										/>
										<input
											type="button"
											className="btn btn-default"
											onClick={() => this.handleChangeQty(false)}
											value="+"
										/>
									</form>
									<p className="mt-30">
										<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.handleAddUsersToAccount(this.state.qty, this.state.isBasic ? basicPlan : unlimPlan)}>Add users</Button>
									</p>
									<p className="underline mt-30 cursor-pointer" onClick={() => this.handleGetSipUsers() + this.setState({ step: 3 })}>
										No thanks, I don&apos;t need anyone else.
									</p>
								</div>
							</div>

						</div>
					</>
        )
      case 3:
        return (
					<>
						<div className="text-center">
							<h3 className="text-500 mt-30">
								Setup your new user(s)
							</h3>
							<p className="text-400 lead mb-10 mt-0">Let&apos;s get you setup to make &amp; receive calls.</p>
							<p className="col-md-10 mx-auto text-md mt-30">
								To enable your users to get setup to make &amp; receive calls, we'll send them links to download our phone apps and PC softphone. We'll also include their user details to help them get setup quickly &amp; easily.
							</p>
							<div className="row">
								<div className="col-xs-12">
									<div className="row sipusers-section mt-50 row-flex flex-3-col flex-nopad">
										{(sipUsers && sipUsers.length >= 1) && sipUsers.map((user, i) =>
											<div className={'col-md-4 col-xs-12 col-xxs-12 mb-30 text-center bg-light p-30 p-sm-15 brad-30 box-shadow shadow-lighter'}>
												<p className={`mb-5 ${validation && validation.users && validation.users[i] && validation.users[i].validEmail === validationStates.INVALID ? 'text-danger' : ''}`}>
													<span className="text-500">{user.extName}<br />{user.extName.includes(user.extNumber) ? '' : `(Ext. ${user.extNumber})`}</span>
												</p>

												{this.state.showUsersError &&
													((validation && validation.users && validation.users.length >= i && validation.users[i] && (validation.users[i].validEmail === validationStates.INVALID && validation.users[i].validMobile === validationStates.INVALID)) ||
														(user.email.length === 0 && user.mobile.length === 0)) &&
													<span className="help-block text-danger text-xs mt-0 mb-0 text-400">
														Please enter a mobile number or email address.
														</span>
												}

												<label className={`mt-10 ${validation && validation.users && validation.users[i] && validation.users[i].validEmail === validationStates.INVALID ? 'text-danger' : ''}`}>Name</label>
												<input type='text'
													className={'form-control mb-20'}
													value={user.extName}
													onChange={e => this.props.changeUserDetails({
													  position: i,
													  email: user.email,
													  mobile: user.mobile,
													  name: e.target.value
													})}
												/>

												<label className={`${validation && validation.users && validation.users[i] && validation.users[i].validEmail === validationStates.INVALID && validation.users[i].validMobile !== validationStates.VALID ? 'text-danger' : ''}`}>Email address</label>
												<input type='text'
													className={`form-control mb-20 ${validation && validation.users && validation.users[i] && validation.users[i].validEmail === validationStates.INVALID && validation.users[i].validMobile !== validationStates.VALID ? 'has-error' : ''}`}
													value={user.email}
													onChange={e => this.props.changeUserDetails({
													  position: i,
													  name: user.extName,
													  email: e.target.value,
													  mobile: user.mobile
													})}
													onBlur={e => this.props.validateUser({
													  user,
													  val: e.target.value,
													  position: i,
													  userCount: sipUsers.length,
													  type: 'email',
													  validation
													})}
												/>

												<label className={`${validation && validation.users && validation.users[i] && validation.users[i].validMobile === validationStates.INVALID && validation.users[i].validEmail !== validationStates.VALID ? 'text-danger' : ''}`}>Mobile number</label>
												<input type='tel'
													className={`mb-30 form-control ${validation && validation.users && validation.users[i] && validation.users[i].validMobile === validationStates.INVALID && validation.users[i].validEmail !== validationStates.VALID ? 'has-error' : ''}`}
													value={user.mobile}
													onChange={e => this.props.changeUserDetails({
													  position: i,
													  email: user.email,
													  name: user.extName,
													  mobile: e.target.value
													})}
													onBlur={e => this.props.validateUser({
													  user,
													  val: e.target.value,
													  position: i,
													  userCount: sipUsers.length,
													  type: 'mobile',
													  validation
													})}
												/>
											</div>
										)}

									</div>

								</div>
							</div>
							<p className="mt-30">
								{this.state.isSavingUsers
								  ? <LoadingNotification loadingText={'Saving, please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
								  :									<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.handleCheckSipUsers(sipUsers, validation.users)}>Save settings</Button>
								}
							</p>
						</div>
					</>
        )
      case 4:
        return (
					<>
						<div className="text-center">
							<span className="bg-light p-20 brad-20 box-shadow shadow-lighter d-inline-block pt-10 pb-10">
								<SvgRender icon="call-blocking" svgWidth="60px" svgClassName="mt-10" />
							</span>
							<h3 className="text-500 mt-30">
								Tired of spam &amp; cold calls?
							</h3>
							<p className="text-400 lead mb-10 mt-0">Add <span className="text-cta">Nuisance Call Blocker</span> to your account.</p>
							<p className="text-dullest text-sm text-400">
								<strike>Normally &pound;{callBlockPrice.repeatPayment} p/month</strike> - <span className="text-cta">ONE-TIME OFFER. JUST &pound;{(callBlockPrice.repeatPayment - (callBlockPrice.repeatPayment * (callBlockPrice.discount / 100))).toFixed(2)} p/month</span>
							</p>
							<div className="row">
								<p className="col-md-10 mx-auto text-md mt-30">
									Your time is important!<br className="d-none d-sm-block" />
								Ensure it isn't stolen by nuisance or unwanted phone calls.<br /><br />
									<u>Activate this powerful feature and you'll be able to:</u>
								</p>
							</div>
							<ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-30">
								<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Block all calls from outside the UK</em></li>
								<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Block "withheld" calls</em></li>
								<li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Block individual or a list of numbers</em></li>
							</ul>
							<p className="mt-30">
								{this.state.isPurchaseInProgress
								  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
								  :									<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.handlePurchaseCallBlocking()}>Add to account now</Button>
								}
							</p>
							<p className="underline mt-30 cursor-pointer" onClick={() => this.setState({ step: 5 })}>
								No thanks, I don&apos;t want to block nuisance calls
							</p>
						</div>
					</>
        )
      case 5:
        return (
					<>
						<h3 className="text-500 mt-30">
							All done!
						</h3>
						<p className="text-400 lead mb-10 mt-0">Congratulations, you can now start making &amp; receiving calls.</p>
						<p className="text-md mt-30">
							We've added {this.state.totalAdded} {this.state.totalAdded > 1 ? 'users' : 'user'} to your account. Don't forget, your first month is free and then you'll start paying <u>&pound;{this.state.isBasic ? ((basicPlan.rental - (basicPlan.rental * (basicPlan.discount / 100))) * this.state.totalAdded).toFixed(0) : ((unlimPlan.rental - (unlimPlan.rental * (unlimPlan.discount / 100))) * this.state.totalAdded).toFixed(0)} after your free month</u>.
						</p>
						<p className="mt-30">
							<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
						</p>

					</>
        )
    }
  }

  handleCheckSipUsers (users, validation) {
    const context = this
    let canContinue = true

    if (validation === undefined || validation.length === 0) {
      context.setState({ showUsersError: true })
    } else {
      const check = new Promise((resolve, reject) => {
        users.forEach((user, i, a) => {
          const val = validation[i]
          if (_.isEmpty(val) && (user.mobile || user.email)) {
            canContinue = false
          } else if ((user.email !== '' && val.validEmail === validationStates.INVALID || user.mobile !== '' && val.validMobile === validationStates.INVALID) && (user.mobile || user.email)) {
            canContinue = false
          } else if (user.email === '' && user.mobile === '') {
            canContinue = false
          }
          if (i === a.length - 1) resolve()
        })
      })

      check.then(() => {
        if (!canContinue) {
          // Validation failed
          context.setState({ showUsersError: true })
        } else {
          context.setState({ isSavingUsers: true }, () => {
            // Let's email the sip users and continue to next step
            const url = `api/AccessToken/InviteSipUsers?t=${context.state.token}`
            fetchHelper.postJson(url, users)
              .then(res => {
                if (res.data === true) {
                  context.setState({ isSavingUsers: false, step: this.state.hasCallBlocking === true ? 5 : 4 })
                } else {
                  context.setState({ showUsersError: true, isSavingUsers: false })
                }
              }).catch(err => console.error(err))
          })
        }
      })
    }
  }

  handleAddUsersToAccount (qty, plan) {
    const context = this
    const url = `/api/AccessToken/AddSipUsersToClient?t=${context.state.token}`
    const data = {
      Qty: qty,
      PackageId: plan.packageId,
      IsBasic: this.state.isBasic
    }

    fetchHelper.postJson(url, data)
      .then(res => {
        if (res.data === true) {
          window.dataLayer.push({
            event: 'HalfPriceUserUpgrade'
          })

          context.props.notify({ message: qty > 1 ? 'Your users have been added to your account.' : 'Your user has been added to your account.' })
          context.setState({ totalAdded: this.state.totalAdded + qty, step: this.state.step === 1 ? 2 : 3 })
          if (context.state.step === 3) {
            context.setState({ isLoadingSipUsers: true })
            this.handleGetSipUsers()
          }
        }
      }).catch(err => console.error(err))
  }

  handleGetSipUsers () {
    const context = this
    context.setState({ step: 3 }, () => {
      const url = `/api/AccessToken/GetSipUsersForClient?t=${context.state.token}`
      fetchHelper.getJson(url)
        .then(res => {
          context.props.handleInitSipUsers(res.data)
          context.setState({ isLoadingSipUsers: false })
        })
        .catch(err => console.error(err))
    })
  }

  componentWillMount () {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount () {
    const context = this
    if (context.state.token) {
      fetchHelper.getJson(`/api/AccessToken/ClientHasCallBlocking?t=${context.state.token}`)
        .then(res => this.setState({ hasCallBlocking: res.data }))
        .catch(err => console.error(err))
    } else if (!this.props.isLoggedIn) {
      // Login
      this.props.history.push('/login?redirect=get-outbound')
    }

    const purchasedCallBlock = localStorage.getItem('purchaseCallBlocking')
    if (purchasedCallBlock) { context.enableCallBlocking(context) }

    const url = `/api/AccessToken/GetPackageInfoByIds?ids=${UnlimitedOutboundPlan}&ids=${BasicOutboundPlan}`

    fetchHelper.getJson(url)
      .then(res => {
        this.setState({ isLoading: false, packages: res.data })
      }).catch(err => console.error(err))

    context.props.getProductDetails([
      { productId: ProductIds.callBlocking, monthlyOption: productBillingOption.callBlockingBillingOptionMonthly }
    ])

    const storageStep = localStorage.getItem('step')
    if (storageStep) {
      context.setState({ step: JSON.parse(localStorage.getItem('step')) })
      localStorage.removeItem('step')
    }
  }

  handleChangeQty (isDecrement) {
    let qty = this.state.qty
    if (isDecrement && qty > 0) {
      qty = qty -= 1
    } else if (!isDecrement) {
      qty = qty += 1
    }

    this.setState({ qty })
  }

  handlePurchaseCallBlocking () {
    const context = this
    const url = `/api/AccessToken/PurchaseProduct?t=${context.state.token}`

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.postJson(url, { BillingOption: WizardProductBillingOptions.callBlockingOptionMonthly, ProductId: ProductIds.callBlocking }).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          context.setState({ step: 5 }, () => {
            context.enableCallBlocking(context)
          })
        } else if (res.data && res.data.postponed === true) {
          context.setState({ step: 5 }, () => {
            context.enableCallBlocking(context)
            context.props.handleShowPaymentPostponedModal()
          })
        } else {
          localStorage.setItem('step', JSON.stringify(this.state.step))
          localStorage.setItem('purchaseCallBlocking', true)
          context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false, clientId: res.clientId })
          context.props.handleShowPaymentOptionsModal()
        }

        window.dataLayer.push({
          event: 'callBlockingPurchased'
        })
      }).catch(function (error) {
        context.setState({ isPurchaseInProgress: false })
        console.error(error)
      })
    })
  }

  enableCallBlocking (context) {
    localStorage.removeItem('purchaseCallBlocking')
    context.setState({ isPurchaseInProgress: false })
    context.props.notify({ message: 'Call blocking enabled' })
    context.props.handleCallBlockingEnabledChange(true)
  }

  handleAddToDialler () {
    const context = this

    context.setState({ submittingToDialler: true }, () => {
      const url = `/api/AccessToken/SubmitClientToDialler?t=${context.state.token}&comments=Requested%20more%20information%20about%20%C2%A37%20per%20month%20outbound%20offer&listid=10500&campaign=4`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ diallerSent: res.data, submittingToDialler: false })
        }).catch(err => console.error(err))
    })
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      productDetails: state.home.productDetails.products,
      config: getConfiguration(state),
      validation: getConfigValidators(state),
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    getProductDetails: getProductPrice,
    notify: handleNotificationChange,
    handleShowCallbackForm,
    handleInitSipUsers,
    changeUserDetails,
    validateUser,
    handleCallBlockingEnabledChange,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal
  }
)(OutboundUpsell)
