import React from 'react'
import { connect } from 'react-redux'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

class Customers extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount/><DisplayMainFooter/><DisplayMainHeader/>
   <header style={{ backgroundImage: "url('/images/backgrounds/invoice-bg.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="PrivacyPolicyH1">SwitchboardFREE Privacy Policy</h1>
            </header>
    <div className="container-styled">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h4 className="pt-20 text-500">About this privacy policy</h4>
                    <p>We are committed to safeguarding the privacy of our website visitors; in this policy we explain how we will treat your personal information.</p>
                    <p>Any information or data you provide on this website will be managed by Liquid 11 Limited. Liquid 11 Limited is registered in England, company number: 04404380 with registered office at 61 Alexandra Road, Lowestoft, Suffolk, NR32 1PL. Liquid 11 Limited is a registered Data Controller with the Information Commissioner’s Office; its registration number is Z9500565. When we refer to “we”, “us”, “Switchboardfree” or ”Liquid11” we are referring to this company.</p>
                   <p className="pt-20">You can get in touch with us in any of the following ways:</p>
                    <ul className="text-400">
                        <li>By email: <a href="mailto:support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a></li>
                        <li>Through this website: <a href="https://www.switchboardfree.co.uk" className="text-cta">https://www.switchboardfree.co.uk</a></li>
                        <li>By post: SwitchboardFREE, Liquid11 Limited, Sea Lake Road, Lowestoft, Suffolk, NR32 3LQ</li>
                    </ul>
                    <p className="pt-20">This privacy policy applies to individuals who use and may/may not register/use www.Switchboardfree.co.uk.
                        This policy applies to the information about yourself that you choose to provide us with or that you allow us to collect. This includes:</p>
                        <ul className="text-400">
                        <li>information you provide during the registration process, such as your name and contact details</li>
                        <li>information which we may obtain about you from third parties (for example, because you have given third parties permission to share that information with us)</li>
                        <li>information we collect about how you use the website</li>
                    </ul>
                <h4 className="pt-20 text-500"> Our obligations</h4>
                        <p>We comply with the GDPR (General Data Protection Regulation) in the way we use and share your personal data. We may process your personal data ourselves or through data processors who will process data on our behalf. If we use data processors we will remain responsible for ensuring that they comply with this policy and data protection law. We will take reasonable precautions to safeguard the personal information that you supply.</p>
                        <h4 className="pt-20 text-500">Marketing</h4>
                        <p>We may use the information you provide to update you with information about goods and services which we think may be of interest to you and for updating you about site/account changes.</p>
                        <p>Your data may also be shared across the Liquid 11 Group of companies. Liquid 11 Group of companies consist of Liquid 11 Limited, Liquid 11 Holdings Limited, Liquid 11 Data Limited, Liquid 11 Estates Limited and SwitchboardFree Limited.</p>
                        <p>We may also from time to time supply your information to third parties for marketing or relationship management purposes. The main purposes to which your data are put are to allow third parties to provide you with information about goods and services which they think may be of interest to you and/or to maintain any existing relationship they may have with you. Such information may be provided by marketing communications or advertising which is tailored to your interests. Your data may also be used in the way described below. Be assured that any such parties will only be allowed to use your data in accordance with GDPR and other applicable law relating to privacy.</p>
                        <p>By submitting your personal data on this site, whether in those fields marked mandatory or otherwise, you are affirming your consent for such information to be used in accordance with this Privacy Policy. You will be able to withdraw that consent at any time by the methods described later on.</p>
                        <p>If you do tick any of the boxes in the fair processing notice inviting you to give your consent, we may contact you via the following marketing channels: Email, Post, SMS and Telephone. You can opt out of different marketing channels from within your account. So, for example, if you tick the post and e-mail boxes, that means we can contact you via post and e-mail but not by the other channels.</p>
                        <p>If you opt in to telephone calls you are consenting to receiving live calls and recorded calls.</p>
                        <p>By continuing to use this website you have consented to information being collected from your device by cookies or other similar technologies, for the purposes described in this Privacy Policy, including the display of targeted advertising based on the information provided.</p>
                <h4 className="pt-20 text-500">Unsubscribing directly with SwitchboardFREE</h4>
                        <p>You can unsubscribe directly with us by logging into your account, visiting the profile page and choose unsubscribe. This will remove consent for to contact you for marketing reasons. This will not stop other companies that may hold your data independently from contacting you. We recommend using the above methods as well.</p>
                        <p>Please note that SwitchboardFree and Liquid 11 Group companies may still need to retain your details for use for the non-direct marketing purposes described in this Privacy Policy.</p>
                <h4 className="pt-20 text-500">Useful Information</h4>
                        <p>For more information on Data Protection and your rights you can visit:</p>
                        <ul className="text-400">
                            <li>The Direct Marketing Association: <a href="https://www.dma.org.uk" className="text-cta" target="_blank" rel="noreferrer">www.dma.org.uk</a></li>
                            <li>The Information Commissioner: <a href="https://www.ico.org.uk" className="text-cta" target="_blank" rel="noreferrer">www.ico.org.uk</a></li>
                            <li>The Citizens Advice Bureau:  <a href="https://www.adviceguide.org.uk" className="text-cta" target="_blank" rel="noreferrer">www.adviceguide.org.uk</a></li>
                        </ul>
                <h4 className="pt-20 text-500">Combining and Profiling data</h4>
                        <p>The information you give us may be combined with other information about you that is obtained from other sources. The combination is usually undertaken with a view to enhancing an existing database with more information. For example:</p>
                        <ul className="text-400">
                            <li>The data you provide here may be combined with data you have provided elsewhere to verify your identity or validate the information you have provided (for example in the context of a third party’s anti-fraud measures.</li>
                            <li>If you have given any third parties permission to share your information, or if you sign in through a social medium, those third parties may be able to find and use information about you so that, in order to speed up the registration process or offer you tailored advertising, you don&apos;t have to provide the information directly to us. The information we obtain will be used in accordance with the other parts of this privacy policy.</li>
                            <li>We may profile your data against third party data that may enable us to glean further information about your geographic area.</li>
                        </ul>
                        <p>The consent you provided for any information you have previously shared with us or any third parties will be renewed at the time you give your consent for any new information sharing.</p>
                <h4 className="pt-20 text-500">Analysis and derivative data products</h4>
                        <p>Sometimes your data will be used for analysis purposes or to build data products. In these instances, the information is aggregated and wherever possible anonymised in line with the Information.</p>
                <h4 className="pt-20 text-500">Legitimate Interests</h4>
                        <p>Under the Data Protection Act, we are also permitted to share some information with third parties who use such data for non-marketing purposes (including credit and risk assessment and management, identification and fraud prevention, debt collection and returning assets to you). This would include the data you provide to us today, at any time in the past and in the future.</p>
                <h4 className="pt-20 text-500">Part registration</h4>
                        <p>If you begin to register on SwitchboardFree but do not complete the registration, we may temporarily collect and store the information you have provided so that we may contact you. For example, we may send you operational emails to enquire about why the registration was not completed. This information will not be used for any other purpose, will not be stored for longer than necessary and will not be shared with third parties. Our aim is simply to provide you with the highest level of service that we can.</p>
                <h4 className="pt-20 text-500">Retention and destruction of personal information</h4>
                        <p>We will take reasonable steps to ensure the accuracy of the information we hold about you. We will not use your personal information unless it is (to the best of our knowledge) accurate and up to date. We will not keep your personal information for longer than necessary. Once it has been decided that the information is no longer needed, it will be deleted promptly.</p>
                <h4 className="pt-20 text-500">Your choices in relation to personal information</h4>
                        <p>You have the right to make a Subject Access Request, i.e. a right to be told whether we hold your personal information and, subject to certain exceptions, to be provided with a copy of such information. If you would like to make a Subject Access Request, please do so in writing to the address above.</p>
                <h4 className="pt-20 text-500">Additional disclosure of your information</h4>
                        <p>If our business (or a significant part of it) offered for sale, your personal information may be given to the prospective buyer, who will be able to use it to help them evaluate the proposed sale. If the sale is completed, the buyer will be able to continue using your information in accordance with this privacy policy.</p>
                        <p>We will also disclose your personal data if we are under a legal obligation to do so.</p>
                <h4 className="pt-20 text-500">Security</h4>
                        <p>Unfortunately, the transmission of data over the internet is not completely secure. Although we will do our best to protect your personal information, we cannot guarantee its security. Once we have received your data we will use strict security measures to try to protect it against loss, misuse, or unauthorised alterations.</p>
                        <p>We will also require any third parties with whom we share your information to keep the information secure.</p>
                <h4 className="pt-20 text-500">Changes</h4>
                        <p>We may change the terms of this privacy policy from time to time. Any changes will be posted on this page.</p>
                <h4 className="pt-20 text-500">Contacting us, exercising your information rights and Complaints</h4>
                        <p>If you any questions or comments about this Privacy Policy, wish to exercise your information rights in connection with the personal data you have shared with us or wish to complain, please contact us via the information at the top of this Privacy Policy.</p>
            </div>
        </div>

    </div>
    </div>

    </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(Customers)
