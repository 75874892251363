import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Tooltip from 'react-bootstrap/Tooltip'
import CreateOrUpdateTranscriptionTriggerRule from '../components/CallTranscription/CreateOrUpdateTranscriptionTriggerRule'
import { fetchHelper } from '../helpers/fetchHelper'

const saidByWho = [
  { value: '1', label: 'anyone' },
  { value: '2', label: 'myself' },
  { value: '3', label: 'caller' }
]

const WordMention = [
  { value: '1', label: 'mentioned' },
  { value: '2', label: 'not mentioned' }
]

const CallTypeOptions = [
  { value: '1', label: 'any type' },
  { value: '2', label: 'outbound call' },
  { value: '3', label: 'inbound call' }
]

const callDurationOptions = [
  { value: '1', label: 'any duration' },
  { value: '2', label: 'less than 1 minute' },
  { value: '3', label: '1 to 5 minutes' },
  { value: '4', label: '5 to 15 minutes' },
  { value: '5', label: 'over 15 minutes' }
]

export default class CallTranscriptionRules extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showEditAlertModal: false,
      updaingRule: 0,
      showDeleteRuleQuestion: false,
      alerts: []
    }

    this.getListOfTriggerRules()
  }

  getListOfTriggerRules () {
    const context = this

    fetchHelper.getJson('/api/CallTranscription/GetListOfTranscriptionKeywordTriggerForClient')
      .then(res => {
        if (res.data.length === 0) {
          if (context.props.noRulesFound) {
            context.props.noRulesFound()
          }
        } else {
          if (context.props.rulesFound) {
            context.props.rulesFound()
          }
        }

        context.setState
        ({
          alerts: res.data,
          updaingRule: 0
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  render () {
    return <>
            <CreateOrUpdateTranscriptionTriggerRule
                show={this.state.showEditAlertModal}
                closePressed={() => this.setState({ showEditAlertModal: false })}
                updateRuleId={this.state.updatingRule}
                alerts={this.state.alerts}
                triggerCreated={() => { this.getListOfTriggerRules(); this.setState({ showEditAlertModal: false }) }}
                triggerDeleted={() => { this.getListOfTriggerRules(); this.setState({ showEditAlertModal: false }) }}
                triggerUpdated={() => { this.getListOfTriggerRules(); this.setState({ showEditAlertModal: false }) }}
            />

            <div className="row">
                <div className="col-md-12 w-700px center-block">
                    <div className="row">
                        <div className="col-md-12 pb-20 bb-2 mb-20 mt-30">
                            <div className="row">
                                <div className="col-7 text-start">

                                </div>
                                <div className="col-5 text-end ">
                                    <span className="btn btn-xs btn-cta mb-0 mt-10" onClick={() => this.setState({ showEditAlertModal: !this.state.showEditAlertModal, updatingRule: 0 })} id="AddNewButton">Add new trigger</span>
                                    <OverlayTrigger placement="top" overlay={<Popover>
                                        <p>Create a new trigger to alert you in realtime, via email once a specific word or phrase has been or hasn't been said during a call.</p>
                                        <p className="mb-0">For example, you might want to be alerted to any mentions of the word "sale" to measure performance, or you might want to check if your team mentions your "terms and conditions" for compliance purposes.</p></Popover>}>
                                        <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                                    </OverlayTrigger>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.alerts.map((x) => (<div className="col-md-12 bg-light brad-10 box-shadow mb-20 pt-20 pb-20 pl-30 pr-30 zoom">
                                <div className="row">
                                    <div className="col-12 col-sm-8 text-center text-sm-start pb-xs-20">
                                        <h6 className="text-500 mt-0 mb-0 pt-0 pb-2 ">&quot;<i className="trim-200px1 va-bottom">{x.ruleName} </i>&quot;</h6>
                                    </div>
                                    <div className="col-12 col-sm-4 float-end text-center text-sm-end">
                                        <span className="text-400 mr-10">Calls:</span>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>View {x.numberOfTriggers} calls that contain <i>&quot;{x.phrase} &quot;</i> (opens in new window)</Tooltip>}>
                                            <a href="/customer/calls" className="text-cta underline cursor-pointer text-500 mr-30">{x.numberOfTriggers}</a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>View & edit</Tooltip>}>
                                            <span className="btn btn-xs btn-outline no-shadow" onClick={() => this.setState({ showEditAlertModal: !this.state.showEditAlertModal, updatingRule: x.id })} id="EditRuleButton">Edit</span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
  }
}
