import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import 'react-datetime/css/react-datetime.css'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { type RouteComponentProps } from 'react-router-dom'
import MyNumbers from '../components/Dashboard/MyNumbers'
import DisplayLoggedInFooter from '../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import CallRecordingPackages from '../components/purchase/CallRecordingPackages'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import LocalStorageHelper from '../helpers/LocalStorageHelper'
import { type ReduxState } from '../models/SignupTypes'
import * as myNumbers from '../store/MyNumbers'
import { getFirstName } from '../store/SBF'

export interface MyNumbersPageProps extends RouteComponentProps {
  callRecordingDialogDisplayed: boolean
  callAnsweringDialogDisplayed: boolean
  clientID: number
  userFirstName: string
  services: Services
  handleDismissCallRecordingDialog: () => void
  updateUserFromLocalStorage: (user: { fName: string | null }) => void
}

export interface Services {
  hasUmr: boolean
  mobileCredit: number
  autoTopup: boolean
  hasAgreement: boolean
  autoTopupAmount: AutoTopupAmount
  hasCallBlocking: boolean
  hasContactBook: boolean
  displayBillingWarning: boolean
  displayUmrUpsell: boolean
  hasCallRecordingAll: boolean
  hasUnlimitedCallRecording: boolean
  hasCallRecordingSingle: boolean
  hasOutboundCalling: boolean
  outboundCallingPackage: null
  isInOutboundBeta: boolean
  hasVipCallerFeature: boolean
  hasNewCallBlockingFeature: boolean
  hasOutboundCallRecording: boolean
  displayOutstandingInvoiceWarning: boolean
}

export interface AutoTopupAmount {
  label: string
  value: number
}

export interface MyNumbersPageState {
  isLoadingFaxes: boolean
  faxes: any[]
  showCancelModal: boolean
  showCancelCRModal: boolean
  showCRPurchased: boolean
  showNoAgreementWarning: boolean
  showRecordingsDepartments: boolean
  recordingsActive: boolean
  hasCallRecordingActivated: boolean
  selectedRecordingNum: SelectedRecordingNum
  reopenSetButtonDisabled: boolean
}

export interface SelectedRecordingNum {
  number: string
  qlid: number
}

class MyNumbersPage extends React.Component<MyNumbersPageProps, MyNumbersPageState> {
  state = {
    isLoadingFaxes: false,
    faxes: [],
    selectedFax: undefined,
    showCancelModal: false,
    showCancelCRModal: false,
    showCRPurchased: false,
    showNoAgreementWarning: false,
    showRecordingsDepartments: false,
    recordingsActive: true,
    hasCallRecordingActivated: true,
    selectedRecordingNum: {
      number: '',
      qlid: 0
    },
    reopenSetButtonDisabled: false
    // showRecordingUpgrade:false
    // showOneNumber: false
  }

  constructor (props) {
    super(props)
  }

  render () {
    return (
            <>
                <ScrollToTopOnMount />
                <DisplayLoggedInFooter />
                <DisplayMainHeader />
                <Helmet>
                    <body className={'logged-in my-numbers '} />
                    <title>My Numbers</title>
                </Helmet>
                <header className="full-width my-calls-header">
                    <h1 id="MyNumbersH1">Number Management</h1>
                    <h2 className="subtitle">Manage your inbound business phone numbers</h2>
                </header>
                <div className="container pt-30 bg-xxs-dull">
                    <div className="my-numbers center-block mb-30 brad-30 pl-xs-0 pr-xs-0">
                        <MyNumbers services={this.props.services} history={this.props.history} />

                        <div className="row">
                            <div className="col-xs-12 pt-30 pb-50 text-center">
                                <Button id="GetANewNumberButton" variant="btn btn-lg btn-cta" onClick={e => { this.props.history.push('/customer/numbers/new') }}>
                                    Get A New Number
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal className="w-1100px" show={this.props.callRecordingDialogDisplayed} onHide={this.props.handleDismissCallRecordingDialog}>
                    <Modal.Body className="text-center p-0">
                        <CallRecordingPackages showTicks={true} ticksTextColour="text-lightest" userServices={this.props.services} className="p-20" CallTranscriptionHeadingCustomClass="text-lightest"/>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12">
                            <Button variant="default" onClick={() => { this.props.handleDismissCallRecordingDialog() }}>
                                Close
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showCRPurchased} onHide={() => { this.setState({ showCRPurchased: false }) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Call recording has been added</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <div
                            className="full-width full-secondary bg-overlay text-lightest pt-30 pb-40"
                            style={{ backgroundImage: "url('/images/backgrounds/call-recording.jpg')", backgroundPosition: 'right' }}
                        >
                            <div className="container text-center container-sm" style={{ width: '100%' }}>
                                <h2 className="text-500">
                                    {!this.props.userFirstName || this.props.userFirstName.toLowerCase() === 'null' ? 'Congratulations' : `Congratulations, ${this.props.userFirstName}`}{' '}
                                </h2>
                                <p className="text-md mb-50">Call recording has now been enabled on your account.</p>
                                <p>
                                    You can now turn call recording on, or off, for your numbers via the "Numbers page" using the toggle switch.<br />
                                    Alternatively, you can enable Call Recording per number and/or department via the number settings page.
                                </p>
                                <p>
                                    You can access your recorded calls to download, listen or delete recordings via your <a href="customer/call-recording/">call recording page</a>.
                                </p>
                                <p className="mt-30 mb-30">
                                    <Button variant="cta" onClick={() => { this.setState({ showCRPurchased: false }) }}>
                                        View your numbers
                                    </Button>
                                </p>
                                <p>
                                    Should you need any help, or have any questions, related to call recording feel free to <a href="/contact">get in touch with us</a>.
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
    )
  }

  componentDidMount () {
    if (!this.props.userFirstName) {
      const user = LocalStorageHelper.getItem<{ fName: string | null }>('user')?.value
      if ((user !== null) && user.fName) {
        this.props.updateUserFromLocalStorage(user)
      }
    }
  }
}
export default connect(
  (state: ReduxState) => {
    return {
      callRecordingDialogDisplayed: state.myNumbers.callRecordingDialogDisplayed,
      callAnsweringDialogDisplayed: state.myNumbers.callAnsweringDialogDisplayed,
      clientId: state.sbfApp.authentication.clientId,
      userFirstName: getFirstName(state),
      services: state.sbfApp.enabledServices
    }
  },
  {
    handleDismissCallRecordingDialog: myNumbers.handleDismissCallRecordingDialog
  }
)(MyNumbersPage)
