import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CallbackRequest from '../../components/CallbackRequest'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'

class UnlimitedMobileRedirects extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <><ScrollToTopOnMount/><DisplayMainFooter/><DisplayMainHeader/>
                <header id="start">
                    <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/mobile-redirects-bg.jpg')" }}>
                        <h1 className="text-500">
                            Unlimited Mobile Redirects
                        </h1>
                        <h2 className="subtitle">
                            Ensure you never miss a business call with the flexibility of unlimited call redirection to your mobile
                        </h2>
                    </div>
                </header>
                <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-3 pb-30 text-center">
                            <div className="icon-block mt-20">
                                <span className="icon icon-airplane" title="local"></span>
                            </div>
                            <h4 className="pt-10 text-500 text-center">On the go?</h4>
                            <p>With a SwitchboardFREE 084 virtual business number and unlimited call routing
                                that you can manage from all of your devices.</p>
                        </div>
                        <div className="col-sm-6 col-md-3 pb-30 text-center">
                            <div className="icon-block mt-20">
                                <span className="icon icon-office" title="local"></span>
                            </div>
                            <h4 className="pt-10 text-500 text-center">Out of the office?</h4>
                            <p>No problem, divert all of your business calls to one or more mobiles while you are out of
                                the office or on-the-move.</p>
                        </div>
                        <div className="col-sm-6 col-md-3 pb-30 text-center">
                            <div className="icon-block mt-20">
                                <span className="icon icon-phone-wave" title="local"></span>
                            </div>
                            <h4 className="pt-10 text-500 text-center">Too many calls?</h4>
                            <p>No problem, overflow calls that you aren't able to answer on your landline to one or more
                                mobile numbers to handle the additional call volumes and never miss a single call.</p>
                        </div>
                        <div className="col-sm-6 col-md-3 pb-30 text-center">
                            <div className="icon-block mt-20">
                                <span className="icon icon-phone" title="local"></span>
                            </div>
                            <h4 className="pt-10 text-500 text-center">Faulty landline?</h4>
                            <p>No problem, redirect your business calls to one or more mobiles while your landline is
                                out-of-order and continue running your business as normal.</p>
                        </div>
                    </div>

                </div>
                <div className="full-width full-secondary text-lightest pb-30 pt-50">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500 pb-30">Get Started</h3>
                            <h4>Never miss a call again whatever the reason and choose from monthly or annual <br className="d-none d-md-block"/>options so you get the cover you need.</h4>

                            <Link className="btn btn-sm btn-cta btn-lg btn-mobile-block mb-20 mt-30" to={this.props.isLoggedIn ? '/customer/numbers/new' : '/get-started'}>Get Started</Link>

                        </div>
                        </div>
                    </div>

                    </div>
                    <div className="full-width  text-center pb-60">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 w-600px center-block bg-dull b-1 brad-20 p-30 mt-30">
                                    <h3 className="pb-30 text-500">Request a callback</h3>
                                    <CallbackRequest subject="Callback request from Unlimited Mobile Redirects" isForm={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
  }

  componentDidMount () {

  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(UnlimitedMobileRedirects)
