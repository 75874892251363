import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isValidEmailSyntax, isValidNameSyntax, isValidTelSyntax } from '../../helpers/validationHelpers'

class HowItWorks extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isOpen: false,
      isOpenTwo: false,
      showVideo: false
    }
    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false,
      isValid: false
    }
  }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow () {
    this.setState({ show: true })
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
        {/* <HowWorksMenu /> */}
            <Helmet>
                <title>How Our Service Works - Business VoIP System</title>
                <meta name="description" content="How does SwitchboardFREE work? Find out how the most complete call management tool for business works, right here." />
                <style type="text/css">{`
                        .bottom-light-gradient {
							background: rgb(38,166,181); /* Old browsers */
							background: -moz-radial-gradient(center, ellipse cover,  rgba(38,166,181,1) 2%, rgba(44,128,139,1) 100%); /* FF3.6-15 */
							background: -webkit-radial-gradient(center, ellipse cover,  rgba(38,166,181,1) 2%,rgba(44,128,139,1) 100%); /* Chrome10-25,Safari5.1-6 */
							background: radial-gradient(ellipse at center,  rgba(38,166,181,1) 2%,rgba(44,128,139,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26a6b5', endColorstr='#2c808b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
						}
					}
					`}</style>
            </Helmet>

            <header style={{ backgroundImage: "url('/images/backgrounds/howitworks-bg.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="HowItWorksH1">How SwitchboardFREE Works</h1>
                <h2 className=" subtitle">Understand your customer’s journey with your new inbound call service</h2>
            </header>

            <div className="full-width bg-light pt-50 pb-50">

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className='text-center'>
                                <h2 className="text-center text-500 mt-30">
                                See How SwitchboardFREE&apos;s Business <br className='d-none d-lg-block'/>Phone System Works 
                                </h2>
                                <p>Watch the below video &amp; discover how SwitchboardFREE&apos;s VoIP calls are processed.</p>
                            </div>
                            
                            <div className="ratio ratio-16x9 mx-auto pb-30">
                                <iframe className="embed-responsive-item brad-30" width="560" height="315" src="https://www.youtube.com/embed/dfR_soHgfe8?si=CKYVTaSGAxwMe2sV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        
                        <div className="col-md-10 mx-auto mt-60">
                            <h2 className="text-center text-500 mb-50 mt-30">
                                Inbound call management explained
                            </h2>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row flex-sm-row-reverse">
                                        <div className=" col-12 col-sm-4 text-center float-md-end">
                                            <img src="/images/icons/how-it-works/receiving-calls.jpg" alt="Receiving Calls" />
                                        </div>
                                        <div className="col-12 col-sm-8">
                                            <div className="row bullet-block mb-100 mb-xxs-30">
                                                <div className="d-none d-xs-block col-xs-2">
                                                    <span className="icon-block bg-cta icon-full-dull sm text-500">
                                                        1
                                                    </span>
                                                </div>
                                                <div className="col-12 col-xs-10">
                                                    <h3 className="text-500 text-cta text-xl mt-0">
                                                        <span className="d-inline d-xs-none">1. </span>Your customer dials in on your new virtual number
                                                    </h3>
                                                    <p className="text-400">
                                                    Whichever number your customer uses, your new management system will route their call correctly and efficiently. Plus, you’ll have confidence that you will have satisfied customers as you never miss a call.
                                                        <span className="text-cta cursor-pointer ml-10" onClick={() => this.setState({ showStep1: !this.state.showStep1 })} id="Step1MoreLessLink">
                                                            {this.state.showStep1 ? 'Less' : 'More'}
                                                        </span>
                                                    </p>
                                                    <hr className="hr-h-4 bg-dullest b-0 mb-0 mt-50" />
                                                    <div className={`bg-dull  ${this.state.showStep1 ? 'd-block' : 'd-none'}`}>
                                                        <div className="p-30" id={this.state.showStep1 ? "Step1InfoBox" : ""}>
                                                            <p className="text-500">The below features will improve your customer’s experience:</p>
                                                            <ul className="styled-list cta rounded text-400 pr-30 ml-0 pl-0 pt-30">
                                                                <li>Your calls can be automatically routed to your PC, laptop, phone, iOS, Android, VoIP phone, custom teams, mobile or landline. You can have up to 100 destinations.</li>
                                                                <li>You can see your caller's number or the number they dialled in on.</li>
                                                                <li>You can instantly re-route calls or update your settings from the app or website 24/7.</li>
                                                                <li>Add a call whisper to understand what department your customer is looking for (sales, service, etc) before they hear your voice. You could also distinguish between work and personal calls.</li>
                                                                <li>You can have your calls routed based on the time of day or day of the week.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row flex-sm-row-reverse">
                                        <div className="col-12 col-sm-4 text-center float-md-end">
                                            <img src="/images/icons/how-it-works/audio-branding.jpg" alt="Greetings" />
                                        </div>
                                        <div className="col-12 col-sm-8">
                                            <div className="row bullet-block mb-100 mb-xxs-30">
                                                <div className="col-xs-2 d-none d-xs-block">
                                                    <span className="icon-block bg-cta icon-full-dull sm text-500">
                                                        2
                                                    </span>
                                                </div>
                                                <div className="col-12 col-xs-10">
                                                    <h3 className="text-500 text-cta text-xl mt-0">
                                                    <span className="d-inline d-xs-none">2. </span>Your customer hears a professional &amp; friendly greeting
                                                    </h3>
                                                    <p className="text-400">
                                                    You’ve chosen one of our free recorded introductions (or recorded your own) and it represents your company perfectly. Depending on your customer's needs, and your bespoke system set up, they will clearly understand how you are handling their call and how to proceed next.
                                                        <span className="text-cta cursor-pointer ml-10" onClick={() => this.setState({ showStep2: !this.state.showStep2 })} id="Step2MoreLessLink">
                                                            {this.state.showStep2 ? 'Less' : 'More'}
                                                        </span>
                                                    </p>
                                                    <hr className="hr-h-4 bg-dullest b-0 mb-0 mt-50" />
                                                    <div className={`bg-dull  ${this.state.showStep2 ? 'd-block' : 'd-none'}`}>
                                                        <div className="p-30" id={this.state.showStep2 ? "Step2InfoBox" : ""}>
                                                            <p className="text-500">
                                                                Single department:
                                                            </p>
                                                            <p className="text-400">Your caller will hear your single greeting and will be silently diverted to one of up to five destinations. This can be landlines, mobiles, or extensions, or you can create ring groups where multiple phones are alerted at the same time. You have the flexibility to have your destinations set sequentially, so if destination one goes unanswered, the call will move to destination two and so on until someone answers the call. Or you can load-balance them to ensure each of your team members receives an equal number of calls.</p>
                                                            <p><em>“Thank you for your call, we are attempting to transfer you to..."</em></p>
                                                            <audio />
                                                            <p className="text-500">
                                                                Multiple departments:
                                                            </p>
                                                            <p className="text-400">Your caller will hear a friendly greeting and then will choose a department from the main menu. You can easily configure up to nine departments separately and set up as many as five different destinations (numbers, extensions or hunt groups). This option works perfectly for companies that work from multiple locations.</p>
                                                            <p><em>"Press 1 for sales, press 2 for support..."</em></p>
                                                            <audio />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row flex-sm-row-reverse">
                                        <div className="col-md-4 col-sm-5 text-center float-none float-sm-end">
                                            <img src="/images/icons/how-it-works/on-hold-music.jpg" alt="On Hold Music" />
                                        </div>
                                        <div className="col-md-8 col-sm-7">
                                            <div className="row bullet-block mb-100 mb-xxs-30">
                                                <div className="col-xs-2 d-none d-xs-block">
                                                    <span className="icon-block bg-cta icon-full-dull sm text-500">
                                                        3
                                                    </span>
                                                </div>
                                                <div className="col-xs-10 col-xxs-12">
                                                    <h3 className="text-500 text-cta text-xl mt-0">
                                                    <span className="d-inline d-xs-none">3. </span>Your customer waits on hold
                                                    </h3>
                                                    <p className="text-400">
                                                    If your customer is on hold, they will be pleasantly surprised by the high-quality, tailored music playing through their receiver. They may also be grateful for any additional audio branding and information that accompanies it.
                                                        <span className="text-cta cursor-pointer ml-10" onClick={() => this.setState({ showStep3: !this.state.showStep3 })} id="Step3MoreLessLink">
                                                            {this.state.showStep3 ? 'Less' : 'More'}
                                                        </span>
                                                    </p>
                                                    <hr className="hr-h-4 bg-dullest b-0 mb-0 mt-50" />
                                                    <div className={`bg-dull  ${this.state.showStep3 ? 'd-block' : 'd-none'}`}>
                                                        <div className="p-30" id={this.state.showStep3 ? "Step3InfoBox" : ""}>
                                                            <p className="text-500">
                                                                On-hold music:
                                                            </p>
                                                            <p className="text-400">
                                                            Many companies underutilise their hold music and it can be a source of frustration for their customers. The right on-hold music can create the perfect impression of your company and something toe-tapping to listen to will give your customer a stress-free experience. You can choose from a large, regularly updated selection of music. From contemporary chart music like Adele, themed music for the holidays like Christmas or Valentine's, easy listening or something more expressive like Bhangra. Switch it up and change your playlists as often as you like.
                                                            </p>
                                                            <audio />
                                                            <p className="text-500 mt-30">
                                                                Audio Branding:
                                                            </p>
                                                            <p className="text-400">You can use your customer's time on hold to give them helpful information or promote your brand, products or services. You can create, or have our experienced team of voice artists and audio technicians put together a bespoke showreel containing your message with a compelling audio track that reflects your business image. </p>
                                                            <audio />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row flex-sm-row-reverse">
                                        <div className="col-md-4 col-sm-5 text-center float-none float-sm-end">
                                            <img src="/images/icons/how-it-works/missed-calls.jpg" alt="Call Routing" />
                                        </div>
                                        <div className="col-md-8 col-sm-7">
                                            <div className="row bullet-block mb-100 mb-xxs-30">
                                                <div className="col-xs-2 d-none d-xs-block">
                                                    <span className="icon-block bg-cta icon-full-dull sm text-500">
                                                        4
                                                    </span>
                                                </div>
                                                <div className="col-xs-10 col-xxs-12">
                                                    <h3 className="text-500 text-cta text-xl mt-0">
                                                    <span className="d-inline d-xs-none">4. </span>Your customer leaves a message
                                                    </h3>
                                                    <p className="text-400">
                                                    If your lines are busy and your customer chooses not to wait, then they can leave a voicemail. Any messages or missed calls are sent directly to your mobile app so you have a record of callbacks. Customers will know that they won’t need to keep calling in because their message is safe in your hands.
                                                        <span className="text-cta cursor-pointer ml-10" onClick={() => this.setState({ showStep4: !this.state.showStep4 })} id="Step4MoreLessLink">
                                                            {this.state.showStep4 ? 'Less' : 'More'}
                                                        </span>
                                                    </p>
                                                    <hr className="hr-h-4 bg-dullest b-0 mb-0 mt-50" />
                                                    <div className={`bg-dull  ${this.state.showStep4 ? 'd-block' : 'd-none'}`}>
                                                        <div className="p-30" id={this.state.showStep4 ? "Step4InfoBox" : ""}>
                                                            <ul className="styled-list cta rounded text-400 pr-30 ml-0 pl-0 pt-30">
                                                                <li>Your call lists are available for up to 24 months for accurate record keeping.</li>
                                                                <li>Your customer's voicemails are emailed to you in real-time, so you can distribute them to the right person quickly.</li>
                                                                <li>Your callback lists are designed to be ‘smart’ and make you more efficient. If your customer calls back before you call them, their name will automatically be removed from the callback list.</li>
                                                                <li>Smart callback lists are available for you and your staff with just a few clicks.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 pt-50 pb-30 text-center">
                                            <Link to={'/number-types'} className="btn btn-cta btn-lg" id="GetStartedButton">
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pb-50 pt-20">
                            <h2 className="text-500">
                                You Can Get Started in 3 Easy Steps!
							</h2>
                        </div>
                        <div className="col-md-4 text-center pb-xs-30">
                            <span className="icon-block lg icon-full-cta">
                                <span className="icon number">1</span>
                            </span>
                            <h5 className="text-500 pt-20 mb-20">Quickly sign up for an account then choose a number</h5>
                            <p>After entering a few details, you can choose from a vast range of virtual numbers from any UK town or city. Pick your business a prestigious London 0203 / 0207 number or even a non-geographical 0333 / 0800 / 084 number. You can even keep your existing number.</p>
                        </div>
                        <div className="col-md-4 text-center pb-xs-30">
                            <span className="icon-block lg icon-full-secondary">
                                <span className="icon number">2</span>
                            </span>
                            <h5 className="text-500 pt-20 mb-20">Choose the perfect package for your business</h5>
                            <p>You can decide between an inbound call management system with a virtual number connected to your existing landline, mobile or phone system. Or a cloud-based VoIP phone system that gives you the flexibility to make and receive calls.</p>
                        </div>
                        <div className="col-md-4 text-center">
                            <span className="icon-block lg icon-full-dull">
                                <span className="icon number">3</span>
                            </span>
                            <h5 className="text-500 pt-20 mb-50">Start making or receiving calls</h5>
                            <p>Within minutes you will be able to start using your new number! If you need help, you can speak to our UK-based customer service experts who will answer your call with friendly, professional advice and support.</p>
                        </div>
                        <div className="col-md-12 pt-50 pb-30 text-center">
                            <Link to={'get-started'} className="btn btn-cta btn-lg" id="SignUpNowButton">
                                Sign up now
                            </Link>
                        </div>
                        <div className="col-xs-12">
                            <hr className="hr-h-10 bg-dull b-0 mt-50" />
                        </div>
                    </div>
                </div>

            <div className="full-width pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 text-center pt-50">
                            <h2 className="text-500 mt-0">Additional Services</h2>
                            <p className="lead text-400">Need more? SwitchboardFREE works seamlessly with our other services to further empower your business</p>
                        </div>
                    </div>
                    <div className="row row-flex flex-4-col flex-sm-2-col mt-30">
                        <div className="col-md-3 col-sm-6 bg-light mr-25 p-0 mb-sm-30 ml-sm-5 mr-sm-5">
                            <img src="/images/thumbs/call-answering.jpg" alt="Call Answering" className="img-responsive w-100" />
                            <div className="p-20">
                                <p className="text-400 lead text-lg text-center">
                                    Live Agent Call Answering
                                </p>
                                <p>SwitchboardFREE’s perfect partner is the call answering service, Pocket Receptionist. The Pocket Receptionist team use their training to handle your overflow or unanswered calls when you don’t have the staff to do it for you.</p>
                                <p>Giving your business the weight of six extra team members, your customers are by someone friendly who will answer simple queries, take messages, process payments and collect information as if they were sitting with you in your office. There are no hidden charges and you only pay for the calls that are answered.</p>
                            </div>
                            <div className="button-set p-0 bottom bt-1">
                                <Link to="/telephone-answering-service" className="btn btn-lightest btn-block cta-hover text-start p-20 brad-0 text-cta" id="LiveAgentCallAnsweringDiscoverMoreButton">Discover more</Link>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 bg-light mr-25 p-0 mb-xs-30 ml-sm-5 mr-sm-5">
                            <img src="/images/thumbs/call-recording.jpg" alt="Call Recording & Storage" className="img-responsive w-100" />
                            <div className="bg-light p-20">
                                <p className="text-400 lead text-lg text-center">
                                    Call Recording &amp; Storage
                                </p>
                                <p>Capture every detail of your calls and store them for as long as you need.</p>
                                <p>Use your saved audio to train staff, improve sales and monitor customer service performance.</p>
                                <p>It can even prove useful for resolving customer conflicts and can ensure legality and compliance.</p>
                            </div>
                            <div className="button-set p-0 bottom bt-1">
                                <Link to="/call-recording" className="btn btn-lightest btn-block cta-hover text-start p-20 brad-0 text-cta" id="CallRecordingDiscoverMoreButton">Discover more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width pb-50 pt-50 bg-light">
                <div className="container text-center">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-500">Still Unsure?</h2>
                            <p className="lead text-400">Watch our video below</p>
                            <p>
                                <a onClick={() => this.setState({ showVideo: true })}>
                                    <img src="/images/video-thumbs/sf-video.jpg" alt="What is SwitchboardFREE video" style={{ maxWidth: '100%' }} />
                                </a>
                            </p>
                            <p className="mt-30">
                                <Link to={'get-started'} className="btn btn-lg btn-cta" id="CreateAFreeAccountButton">Create a free account</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width bg-dull p-0" id="wireless">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 pt-100 float-end pt-xs-30">
                            <h2 className="text-500">Speak to us Today</h2>
                            <p className="lead text-400">Connect with our friendly UK-based team, who are ready to help you every step of the way.</p>
                            <p className="mb-30">Call us on <a href="tel:0203 189 1213">0203 189 1213</a> or email us at <a href="mailto:support@switchboardfree.co.uk">support@switchboardfree.co.uk</a> if you have any questions or if you need any help.  </p>
                            <p>
                                <Link to={'/contact'} className="btn btn-lg btn-cta" id="ContactUsButton">Contact us</Link>
                            </p>
                        </div>
                        <div className="col-md-6 pt-50">
                            <img src="/images/headers/baker.png" alt="Baker" style={{ maxWidth: '90%' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pb-20">
                <div className="row">
                    <div className="col-4 d-block order-last mt-20">
                        <div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#f7ffd0' }}>
                            <p><em>“Really impressed with switchboardfree.  Easily set up with a good onboarding help from Zoe”</em></p>
                            <span className="text-500 pt-5 d-inline-block">
                                Danny <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
                            </span>
                            <img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
                        </div>
                    </div>
                    <div className="col-4 d-block order-last mt-20">
                        <div className="bg-dull brad-10 p-20 clearfix pb-45" style={{ backgroundColor: '#d6e8ef' }}>
                            <p><em>“Very good service, was set up in minutes.

                            Thank you”</em></p>
                            <span className="text-500 pt-5 d-inline-block">
                                Tom <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
                            </span>
                            <img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
                        </div>
                    </div>
                    <div className="col-4 d-block order-last mt-20">
                        <div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#ffebeb' }}>
                            <p><em>“Fantastic, Friendly, Helpful... Been with them for nearly 15 years... Wouldn't use anyone else.”</em></p>

                            <span className="text-500 pt-5 d-inline-block">
                                John <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
                            </span>
                            <img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
                        </div>
                    </div>
                </div>

            </div>

            <Modal className="modal-transparent w-900px" show={this.state.showVideo} onHide={() => this.setState({ showVideo: false })}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="ratio ratio-16x9">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/0e5_W9Llhn8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" size="xs" onClick={() => this.setState({ showVideo: false })}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
  }

  checkNameValidation (val) {
    let nameValid
    if (val && val.length > 1) {
      nameValid = isValidNameSyntax(val)
    } else {
      nameValid = false
    }
    const isValid = nameValid === true && this.state.telValid !== false && this.state.email && this.state.emailValid !== false
    this.setState({ ...this.state, nameValid, isValid })
  }

  checkEmailValidation (val) {
    let emailValid
    if (val && val.length > 5) {
      emailValid = isValidEmailSyntax(val)
    } else {
      emailValid = false
    }

    const isValid = emailValid === true && this.state.telValid !== false && this.state.fullName && this.state.nameValid !== false
    this.setState({ ...this.state, emailValid, isValid })
  }

  checkTelValidation (val) {
    let telValid
    if (val && val > 8) {
      telValid = isValidTelSyntax(val)
    } else {
      telValid = false
    }
    const isValid = telValid === true && this.state.fullName && this.state.nameValid !== false && this.state.email && this.state.emailValid !== false
    this.setState({ ...this.state, telValid, isValid })
  }

  handleFormSubmit (context) {
    this.checkNameValidation(context.fullName)
    this.checkEmailValidation(context.email)

    if (context.tel && context.tel.length > 1) { this.checkTelValidation(context.tel) }

    if (this.state.isValid) {
      const url = '/api/Home/InfographicDownload'
      const data = Object.assign(
        { name: context.fullName },
        { email: context.email },
        { tel: context.tel }
      )

      fetchHelper.postJson(url, data)
        .then(res => {
          this.setState({ formSent: res.data })
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(HowItWorks)
