import React from 'react'
import { connect } from 'react-redux'
import LoadingNotification from '../components/LoadingNotification'
import { fetchHelper } from '../helpers/fetchHelper'
const fileDownload = require('js-file-download')

class TermsAndConditionsWrapper extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    loading: true
  }

  render () {
    return (
			<>
				<style>
					{`
                header, .header, footer { display: none; }
                p { color: #222; }
            `}
				</style>
				<div className="text-center p-50">
					<LoadingNotification loadingText={'Please wait...'} isLoading={this.state.loading}
						width={100} height={100} />
				</div>
			</>
    )
  }

  componentDidMount () {
    const url = '/api/Home/Terms/'

    if (this.props.match.params.format !== 'pdf') {
      fetchHelper.getJson(url).then(response => {
        this.setState({ loading: false })
        const newDoc = document.open('text/html', 'replace')
        newDoc.write(response.data)
        newDoc.close()
      }).catch(error => {
        console.error(error)
      })
    } else {
      fetchHelper.getBlob(url).then(res => {
        fileDownload(res.data, 'switchboardfree_terms.pdf')
      }).catch(error => console.error(error))
    }
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(TermsAndConditionsWrapper)
