import update from 'immutability-helper'
import { combineReducers } from 'redux'
import { createAction, handleActions } from 'redux-actions'
import { fetchHelper } from '../helpers/fetchHelper'
import * as userService from '../services/UserService'
import { handleLoginReceived } from './SBF'

export const handleLoginRequest = createAction('HANDLE_LOGIN_REQUEST')

export const updateUsername = createAction('UPDATE_LOGIN_USERNAME')
export const updatePassword = createAction('UPDATE_LOGIN_PASSWORD')
export const handleLoginError = createAction('HANDLE_LOGIN_ERROR')

const getProdutPriceRequested = createAction('GET_PRODUCT_PRICE_REQUESTED')
const getProductPriceReceived = createAction('GET_PRODUCT_PRICE_RECEIVED')
export const setNumberPriceDetails = createAction('SET_NUMBER_PRICE_DETAILS')

// export const handleLogoutRequest = createAction('HANDLE_LOGOUT');

export const getProductPrice = context => async (dispatch) => {
  let processed = 0
  const products = []

  context.forEach((product) => {
    const prodId = product.productId
    const monthlyOption = product.monthlyOption
    const yearlyOption = product.yearlyOption

    if ((monthlyOption || yearlyOption) || prodId) {
      dispatch(getProdutPriceRequested())

      let getPriceUrl = `api/Purchase/GetProductPrice?prodId=${prodId}`
      if (yearlyOption) { getPriceUrl += `&yearlyOptionId=${yearlyOption}` }

      if (monthlyOption) { getPriceUrl += `&monthlyOptionId=${monthlyOption}` }

      fetchHelper.getJson(getPriceUrl)
        .then(res => {
          products.push(res.data)
          processed++
          if (processed === context.length) {
            dispatch(getProductPriceReceived(products))
          }
        }).catch(err => {
          console.error(err)
        })
    }
  })
}

export const handleBELogin = context => async (dispatch) => {
  context.event && context.event.preventDefault()
  dispatch(handleLoginReceived({
    fName: context.fName,
    clientId: context.clientId,
    email: context.email
  }))
}

export const handleLogin = context => async (dispatch) => {
  context.event && context.event.preventDefault()
  dispatch(handleLoginRequest(context))
  userService.login(context.userName, context.password, context.persist)
    .then(
      user => {
        if (user === null) {
          dispatch(handleLoginError())
        } else {
          if (user.isSuccessful === false) {
            dispatch(handleLoginError(user.reason))
            return
          }

          window.dataLayer.push({
            event: 'login',
            clientid: user.clientId
          })

          dispatch(handleLoginReceived(user))
          if (context.route && context.route !== undefined && context.route.length > 0 && !context.disableRedirect) {
            context.history.push(context.route)
          } else {
            context.history.push('/customer/dashboard')
          }
        }
      },
      error => {
        console.error(error)
        dispatch(handleLoginError(''))
      }
    )
}

const loginStateReducer = handleActions({
  [handleLoginReceived] (state, action) {
    return { username: '', password: '', loggingIn: false, loginFailed: false }
  },
  [updateUsername] (state, action) {
    return { ...state, username: action.payload }
  },
  [updatePassword] (state, action) {
    return { ...state, password: action.payload }
  },
  [handleLoginRequest] (state, action) {
    return { ...state, loggingIn: true, loginFailed: false }
  },
  [handleLoginError] (state, action) {
    return { ...state, loginFailed: true, loggingIn: false, reason: action.payload }
  }
}, { username: '', password: '', loggingIn: false, loginFailed: false })

const productDetailsReducer = handleActions({
  [getProductPriceReceived] (state, action) {
    return update(state, {
      products: {
        $set: action.payload
      }
    })
  },
  [setNumberPriceDetails] (state, action) {
    return update(state, {
      monthlyRepeatPayment: { $set: action.payload }
    })
  }
}, {
  products: null
})

export const reducer = combineReducers({
  login: loginStateReducer,
  productDetails: productDetailsReducer
})
