import React from 'react'
import { validationStates } from '../constants/Constants'

const Validator = props => {
  return props.validation && (props.validation.valid === validationStates.INVALID || props.validation === true)
    ? (
        <div className={`${props.customClass ? props.customClass : 'alert alert-danger pt-5 pb-5'}`}>
            <div className="help-block m-0 text-danger text-400 text-xs">
                {props.children ? props.children : props.validation.errorMsg}
            </div>
        </div>
      )
    : null
}

export default Validator
