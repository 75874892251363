import moment from 'moment'
import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import LoadingNotification from '../../../components/LoadingNotification'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../../helpers/fetchHelper'

class BlogPost extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
  }

  render () {
    function createMarkup (html) {
      return {
        __html: html
      }
    };

    return <>
			<Helmet>
				<title>{`${(this.state.blog ? this.state.blog.title : 'Loading...')}`}</title>
				<script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-594ba1da6589de6b"></script>
			</Helmet>
			<ScrollToTopOnMount />
			<DisplayMainFooter />
			<DisplayMainHeader />
			<div className="full-width bg-light pt-50 pb-50">
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							{this.state.blog
							  ? <>
									<h1 className="text-start mb-0 pb-20 bb-1 mt-0" dangerouslySetInnerHTML={createMarkup(this.state.blog.title)}></h1>
									<p className="text-dullest text-sm pt-10 pb-10 bb-1 mb-50 mt-0 ">Posted: {moment(this.state.blog.date).format('LLLL')}</p>

									<div className="blog-article" dangerouslySetInnerHTML={createMarkup(this.state.blog.content)}></div>
								</>
							  : <LoadingNotification loadingText={'Loading, please wait...'} textColor="text-dark" isLoading={true} width={75} height={75} />}
							<div className="bt-1 mt-30 pt-20">
								<div className="row">
									<div className="col-md-6 float-end text-end text-md-center">
										<div className="addthis_inline_share_toolbox"></div>
									</div>
									<div className="col-md-6">
										<Link className="btn btn-cta" to="/blog/">Back to all blogs</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<h2 className="text-lg text-500 mt-0 mb-30">Recent posts</h2>
							{this.state.recentPosts && this.state.recentPosts.length > 0
							  ? this.state.recentPosts.map((post, i) =>
									<Link to={`/blog/${post.url}?id=${post.id}`} className="pt-15 pb-15 bb-1 text-400 block" dangerouslySetInnerHTML={createMarkup(post.title)}>{ }</Link>
							  )
							  : <LoadingNotification loadingText={'Loading, please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />}
						</div>
					</div>
				</div>
			</div>
		</>
  }

  getBlogPost (id) {
    const context = this
    fetchHelper.getJson(`/api/Home/GetBlogContent/${id}`)
      .then(res => {
        context.setState({ blog: res.data })
      }).catch(err => console.error(err))
  }

  componentDidUpdate () {
    const context = this
    const query = new URLSearchParams(this.props.location.search)
    if (query) {
      const id = query.get('id')
      if (id !== context.state.id) {
        context.setState({ id })
        context.getBlogPost(id)
      }
    }
  }

  componentDidMount () {
    const context = this

    fetchHelper.getJson(`/api/Home/GetRecentBlogPosts/${10}`)
      .then(res => {
        context.setState({ recentPosts: res.data.blogs.sort((a, b) => b.id - a.id) })
      }).catch(err => console.error(err))
  }
}

export default withRouter(connect(
  state => {
  },
  {
  }
)(BlogPost))
