import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { type RouteComponentProps } from 'react-router-dom'
import LoadingNotification from '../components/LoadingNotification'
import {
  additionalLandlineRate,
  additionalMobileRate, AppOnlyPrice,
  AppOnlyPrice0800, is0800Offer,
  large,
  large0800,
  med,
  med0800,
  MinuteBundle,
  numberOfferDuration,
  NumbersGlobalOfferPrice, productBillingOption,
  ProductIds,
  small,
  small0800, UnlimitedOutboundPlan, UnlimPlanDetails
} from '../constants/Constants'
import HandsetModal from '../containers/Signup/Modals/HandsetModal'
import { fetchHelper } from '../helpers/fetchHelper'
import { numberWithCommas } from '../helpers/utils'
import {
  type ProductPriceDetailsDto,
  type ProductPriceDto
} from '../models/GenericTypes'
import {
  SbfPackage, type Basket,
  type InboundPackagesDto,
  type Package,
  type PackageResultDto,
  type PackageStub
} from '../models/SignupTypes'
import { fetchProductPrices } from '../services/ProductService'
import LocalStorageHelper from '../helpers/LocalStorageHelper'

export interface SelectPackageProps extends RouteComponentProps {
  prefix: string
  isSignup: boolean
  userQty: number
  loggedIn: boolean
  showInbound: boolean
  is99pOffer: boolean
  isUpgradeModal: boolean
  onClickSmallPrint: (arg: any) => void
  showFeaturesModal: () => void
  preventDefaultSelection: boolean
  continueWithoutBoltOn?: () => void
  isBasic: boolean
  isModal: boolean
  noParentRowClass: boolean
  currentBasket: Basket
  handleSelectPackage: (packData: SbfPackage) => void
  handleRemoveBoltOn: () => void
}

export interface SelectPackageState {
  isLoading: boolean
  showExtraInboundInfo: boolean
  currentPackageIndex: number
  umr: boolean
  appOnlyPrice: number
  fairUse: string
  umrPayment: ProductPriceDetailsDto
  packages: PackageResultDto | InboundPackagesDto
  productDetails: ProductPriceDto[]
  showHandset: boolean
  hovering: number | null
  showExtraPackInfo: boolean
  capacityReached: boolean
  showTpsWarning: boolean
}

const SelectPackage: React.FunctionComponent<SelectPackageProps> = (
  props
): JSX.Element => {
  const initialState: SelectPackageState = {
    productDetails: [],
    capacityReached: false,
    packages: { inboundPackages: [], packages: [] },
    showExtraInboundInfo: false,
    showExtraPackInfo: false,
    showHandset: false,
    appOnlyPrice: 5,
    currentPackageIndex: 0,
    fairUse: '',
    umr: false,
    umrPayment: { firstPaymentAmount: 0, repeatPaymentAmount: 0 },
    hovering: null,
    isLoading: true,
    showTpsWarning: false
  }

  const [state, setState] = React.useState<SelectPackageState>(initialState)

  const isOutbound = props.currentBasket.isOutbound
  const is0800 = props.prefix.startsWith('080') || props.currentBasket.numbers[0].number.startsWith('080')

  useEffect(() => {
    {
      getProductDetails()
      setTimeout(() => {
        const packages = state.packages as PackageResultDto
        if (packages.packages.length === 0 && state.isLoading) {
          getOutboundPackages()
        }
      }, 2000)
    }
  }, [props.currentBasket.isOutbound])

  function renderCallFeatures (pack: Package, packageIndex: number) {
    if (pack.numberType.startsWith('080')) {
      return (
        <>
          <li>1x Free Bronze 0800 phone number</li>
          <li>
            {(pack.inboundSeconds.landlineSeconds + pack.inboundSeconds.mobileSeconds) / 60} inbound minutes to our app, softphone &amp; VoIP phones
          </li>
          {(pack.outboundSeconds.landlineSeconds +
            pack.outboundSeconds.mobileSeconds) > 0 &&
            <li>
              {numberWithCommas((pack.outboundSeconds.landlineSeconds +
                pack.outboundSeconds.mobileSeconds) /
                60)}{' '}
              outbound minutes to landline &amp; mobile
            </li>
          }
          {(packageIndex === 0 || packageIndex === 1)
            && <>
              <li>
                On-hold music
                <br /><span className="text-sm">
                Including top charts, updated fortnightly
                </span>
              </li>
              <li>
                Multiple departments
                <br /><span className="text-sm">
                  Press 1 for support, 2 for sales
                </span>
              </li>
            </>
          }
        </>
      )
    } else if (packageIndex !== 1) {
      return (
        <>
          {(props.prefix.startsWith('01') || props.prefix.startsWith('02') || props.prefix.startsWith('03') )&& <li>Free 01, 02 or 03 phone number</li>}
          <li>
            Unlimited* inbound mins to our app, softphone &amp; VoIP phones
          </li>
          {(pack.outboundSeconds.landlineSeconds +
            pack.outboundSeconds.mobileSeconds) > 0
            ? <li>
              {numberWithCommas(
                (pack.outboundSeconds.landlineSeconds +
                  pack.outboundSeconds.mobileSeconds) /
                60
              )}{' '}
              outbound minutes to landline &amp; mobile
            </li>
            : <li>
              UK outbound calls
              <br /> available from {additionalLandlineRate}p / min
            </li>
          }

          {packageIndex === 0
            ? <>
              <li>
                On-hold music
                <br /><span className="text-sm">
                  Including top charts, updated fortnightly
                </span>
              </li>
              <li>
                Multiple departments
                <br /><span className="text-sm">
                  Press 1 for support, 2 for sales
                </span>
              </li>
              <li>
                {props.userQty}x Free Desk Phone - <span className="text-cta">Worth &pound;55</span>
                <br />
                <span className="text-sm">
                  On 12-month agreements. <br className="d-none d-sm-block" />
                  Limited stock.{' '}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => { setState({ ...state, showHandset: true }) }}
                  >
                    More info
                  </span>
                </span>
              </li>
            </>
            : <>
            </>
          }
        </>
      )
    } else if (packageIndex === 1) {
      return (
        <>
          {(props.prefix.startsWith('01') || props.prefix.startsWith('02') || props.prefix.startsWith('03') )&& <li>Free 01, 02 or 03 phone number</li>}
          <li>
            Unlimited* inbound mins to our app, softphone &amp; VoIP phones
          </li>
          <li>
            Unlimited outbound minutes to landline &amp; mobile{' '}
            <sup>*</sup>
            <br />
          </li>
          <li>
            On-hold music
            <br /><span className="text-sm">
              Including top charts, updated fortnightly
            </span>
          </li>
          <li>
            Multiple departments
            <br /><span className="text-sm">
              Press 1 for support, 2 for sales
            </span>
          </li>
          {UnlimPlanDetails.FreeHeadset && (
            <li className="mb-0">
              Free Headset -{' '}
              <span className="text-cta">Worth &pound;39.99</span>
              <br />
              <span className="text-sm">
                Available with new annual subscriptions
              </span>
            </li>
          )}
          {UnlimPlanDetails.FreeHandset && (
            <li>
              {props.userQty}x Free Desk Phone - <span className="text-cta">Worth &pound;{55 * props.userQty}</span>
              <br />
              <span className="text-sm">
                On 6-month or 12-month agreements. <br className="d-none d-sm-block" />
                Limited stock.{' '}
                <span
                  className="underline cursor-pointer"
                  onClick={() => { setState({ ...state, showHandset: true }) }}
                >
                  More info
                </span>
              </span>
            </li>
          )}
          {UnlimPlanDetails.FreeCallRecording && <li>Free call recording</li>}
          {UnlimPlanDetails.FreeCallBlock && <li>Nuisance call blocker -{' '}
            <span className="text-cta">Worth &pound;3</span>
          </li>
          }

        </>
      )
    } else {
      return (
        <>
          {(props.prefix.startsWith('01') || props.prefix.startsWith('02') || props.prefix.startsWith('03') )&& <li>Free 01, 02 or 03 phone number</li>}
          <li>
            Unlimited* inbound minutes to our app, softphone &amp; VoIP phones
          </li>
          <li>
            UK outbound calls
            <br /> available from {additionalLandlineRate}p / min
          </li>
        </>
      )
    }
  }

  function renderPackageBlock (pack: Package, packageIndex: number) {
    function render99pOfferBlock () {
      return (
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-6 col-md-offset-2 old-price">
            <span className="text-sm block">Normally </span>
            <sup>&pound;</sup>
            {packageIndex === 1 && UnlimPlanDetails.UnlimDiscount
              ? (
                <span className="text-lg">{pack.offerPrice}</span>
                )
              : (
                <span className="text-lg">{pack.monthlyPrice}</span>
                )}
          </div>
          <div className="col-md-4 col-sm-6 col-xs-6 ">
            <span className="text-sm block">First month</span>
            <span className="text-lg">99</span>
            <sup>p*</sup>
          </div>
        </div>
      )
    }

    function renderDiscountBlock () {
      return (
        <div className="row justify-content-md-center">
          <div className="col-md-4 col-sm-6 col-xs-6 col-md-offset-2 old-price">
            <span className="text-sm block">Was </span>
            <sup>&pound;</sup>
            <span className="text-lg">{pack.monthlyPrice}</span>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-6 ">
            <span className="text-sm block">Now Just </span>
            <sup>&pound;</sup>
            <span className="text-lg">{pack.offerPrice}</span>
          </div>
        </div>
      )
    }

    function render0800DiscountCost () {
      return (
        <>
          <span className="text-lg">99</span>
          <sup>p*</sup>
        </>
      )
    }

    function renderCost () {
      return (
        <>
          <sup>&pound;</sup>
          <span className="text-lg">{pack.monthlyPrice}</span>
        </>
      )
    }

    function renderExtraPackInfo () {
      return (
        <>
          <div className="d-block d-sm-none">
            <ul
              className={`mt-40 mt-xs-20 clearfix ${pack.numberType.startsWith('080') ? 'mb-35' : 'pl-0'
                }`}
            >
              {renderCallFeatures(pack, packageIndex)}
            </ul>

            <p className="text-xs text-400 text-center p-10 text-500">
              No long-term contract, setup fees or activation fees.
              <span
                className="text-dullest underline cursor-pointer text-400 ml-10"
                onClick={() => { props.onClickSmallPrint('terms') }}
              >
                Read the small print
              </span>
            </p>
          </div>
        </>
      )
    }

    function renderShowExtraPackInfoButtons () {
      return (
        <div className="d-block d-sm-none text-center">
          {state.showExtraPackInfo
            ? (
              <Button
                variant="link"
                className="text-cta underlined text-500 text-sm mt-30 mt-xs-10 text-cta"
                onClick={() => { setState({ ...state, showExtraPackInfo: false }) }}
              >
                Hide details
              </Button>
              )
            : (
              <Button
                variant="link"
                className="text-cta underlined text-500 text-sm mt-30 mt-xs-10 text-cta"
                onClick={() => { setState({ ...state, showExtraPackInfo: true }) }}
              >
                See more details...
              </Button>
              )}
        </div>
      )
    }

    return (
      <div
        className='col-12 col-sm-6 col-md-4 pl-15 pr-15 pl-xs-10 pr-xs-10 float-start'
      >
        <div
          className={`package-box mb-20 ${(pack.packageId === UnlimitedOutboundPlan && !pack.numberType.startsWith('080')) ? 'unlim' :
             pack.numberType.startsWith('080') && packageIndex === 1 ? 'unlim' : ''
            } ${state.hovering !== null ? 'not-hover' : ''} ${state.hovering === packageIndex ? 'hover' : ''
            }`}
          onMouseEnter={() => { setState({ ...state, hovering: packageIndex }) }}
          onMouseLeave={() => { setState({ ...state, hovering: null }) }}
        >
          <div className="box-header">
            {packageIndex === 1 && (
              <div className="d-flex flex-row">
                <div className="flash flash-secondary d-inline-block">
                  {(UnlimPlanDetails.UnlimDiscount && pack.monthlyPrice !== pack.offerPrice) ?
                    <span>
                      HUGE
                      <br />
                      Savings
                    </span>
                    :
                    <span>
                      Most
                      <br />
                      popular
                    </span>
                    }
                </div>
              </div>
            )}
            {packageIndex === 0 && (
              <div className="d-flex flex-row">
                {pack.monthlyPrice !== pack.offerPrice ?
                  <div className="flash d-inline-block">
                    <span>
                      On
                      <br />
                      SALE!
                    </span>
                  </div>
                  : 
                  <div className="flash d-inline-block">
                    <span>
                      Most
                      <br />
                      Popular
                    </span>
                  </div>
                  }
              </div>
            )}
            <h3
              className={`text-500 ${packageIndex === 1 ? 'mt-0 mt-xs-20' : ''}`}
            >
              {pack.packageName}
            </h3>
            <span
              className={`text-xl text-500 pkg-price ${packageIndex === 1 && UnlimPlanDetails.UnlimDiscount
                ? 'offer-price'
                : ''
                }`}
            >
              {props.is99pOffer
                ? render99pOfferBlock()
                : pack.monthlyPrice !== pack.offerPrice // On offer
                  ? renderDiscountBlock()
                  : is0800Offer && pack.numberType.startsWith('080') && pack.offerPrice !== pack.monthlyPrice
                    ? render0800DiscountCost()
                    : renderCost()}
            </span>
            {pack.numberType === '0800' && is0800Offer && pack.offerPrice !== pack.monthlyPrice
              ? (
                <p className="text-sm text-400 clearfix text-dark">
                  per month per user <span className="text-dullest">+ VAT</span><br />
                  &pound;{pack.offerPrice} for first month - &pound;{pack.monthlyPrice} after{' '}
                </p>
                )
              : (
                <p className="text-sm text-400 clearfix text-dark">
                  per month per user <span className="text-dullest">+ VAT</span>
                </p>
                )}
            <span className="text-center block mt-40 mb-20 mt-xs-20">
              <Button
                variant="cta" size="lg"
                className="btn-rounded text-center"
                onClick={() => { handleSetPackage(packageIndex, pack, false, false, '') }
                }
              >
                {props.isUpgradeModal ? 'Upgrade' : `Choose ${pack.packageName}`}
              </Button>
            </span>
          </div>
          <div className="box-body">
            <div className="d-block d-sm-none">
              {renderShowExtraPackInfoButtons()}
            </div>

            <div className="d-none d-sm-block">
              <p className="text-xs text-400 text-center p-10 text-500">
                No long-term contract, setup fees or activation fees.
                <span
                  className="text-dullest underline cursor-pointer text-400 ml-10"
                  onClick={() => { props.onClickSmallPrint('terms') }}
                >
                  Read the small print
                </span>
              </p>

              <ul
                className={`mt-40 mt-xs-20 clearfix ${pack.numberType.startsWith('080') ? 'mb-35' : 'pl-0'
                  }`}
              >
                {renderCallFeatures(pack, packageIndex)}
              </ul>
            </div>

            {state.showExtraPackInfo && renderExtraPackInfo()}

            {packageIndex === 1 && (
              <div className="d-flex">
                <div className="bg-dull brad-10 p-20 w-100 mt-30 text-center" style={{ backgroundColor: '#d6e8ef' }}>
                  <div className="flash d-block mt-neg-40 mt-xs-0">
                    <span>
                      FREE
                      <br />
                      Feature
                    </span>
                  </div>

                  <p className="text-500 mt-20 mb-10">
                    Includes TPS Protection
                  </p>
                  <p className="text-sm mt-0">
                    Don't risk calling numbers that could result in a fine with SwitchboardFREE TPS Protection. <span className="text-cta text-500">Worth &pound;{10 + (3 * props.userQty)}</span>{' '}
                    <span
                      className="underline cursor-pointer"
                      onClick={() => { setState({ ...state, showTpsWarning: true }) }}
                    >
                      More info
                    </span>
                  </p>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    )
  }

  function render084Plans (
    isAlone: boolean,
    isSignup: boolean,
    loggedIn: boolean
  ) {
    if (state.umrPayment?.firstPaymentAmount === 0) { getProductDetails() }
    const capacityReached = state.capacityReached

    function render084UnlimitedPanel () {
      return (
        <div
          className={'col-md-4 col-sm-6 col-xs-12 mb-xs-20 mx-auto'}
        >
          <div className="package-box mb-20">
            <div className="box-header">
              <h3 className="text-500">084 Unlimited</h3>
              <span className="text-xl text-500 pkg-price">
                <sup>&pound;</sup>
                <span className="text-lg">
                  {state.umrPayment ? state.umrPayment.repeatPaymentAmount : 0}
                </span>
              </span>
              <p className="text-sm text-400 clearfix text-dark">
                per month per user <span className="text-dullest">+ VAT</span>
              </p>

              <span className="text-center block mt-10 mb-30 mt-30">
                <Button
                  variant="cta" size="lg"
                  className="btn-rounded text-center"
                  onClick={() => { handleSetPackage(null, null, true, true, '') }}
                >
                  Get started
                </Button>
              </span>
              <p className="text-xs text-400 text-center p-10 text-500">
                No long-term contract, setup fees or activation fees.
                <span
                  className="text-dullest underline cursor-pointer text-400 ml-10"
                  onClick={() => { props.onClickSmallPrint('terms') }}
                >
                  Read the small print
                </span>
              </p>
            </div>
            <div className="box-body">
              <ul className="ml-5 mt-40 clearfix">
                <li>
                  Includes an 084 number
                  <br />
                  <span className="text-sm">One 0844 or 0843 number</span>
                </li>
                <li>
                  20+ call features
                  <br />
                  <span className="text-sm">
                    Including music on hold, customised prompts, open close
                    times &amp;{' '}
                    <span
                      className="cursor-pointer underline"
                      onClick={() => { props.showFeaturesModal() }}
                    >
                      view features
                    </span>
                  </span>
                </li>
                <li>
                  Unlimited* inbound calls
                  <br />
                  <span className="text-sm">
                    To VoIP, UK landline and UK mobile
                  </span>
                </li>
                <li>
                  Unlimited outbound calls <sup>*</sup>
                </li>
                <li>
                  PC softphone
                  <br />
                  <span className="text-sm">
                    Make &amp; receive calls on a PC or laptop
                  </span>
                </li>
                <li>
                  Free iOS &amp; Android apps
                  <br />
                  <span className="text-sm">
                    Make &amp; receive calls anywhere in the world
                  </span>
                </li>
                <li>
                  Number presentation
                  <br />
                  <span className="text-sm">
                    Display your business number when making calls
                  </span>
                </li>
                <li>No set-up or activation fees</li>
                <li>Zero contract</li>
              </ul>
            </div>
          </div>
        </div>
      )
    }

    function render084FreePanel () {
      return (
        <div className="col-md-4 col-sm-6 col-xs-12 mx-auto mb-xs-20">
          <div className="package-box mb-20">
            <div className="box-header">
              <h3 className="text-500">084 Free</h3>
              <span className="text-xl text-500 pkg-price">
                <sup>&pound;</sup>
                <span className="text-lg">0.00</span>
              </span>
              <p className="text-sm text-400 clearfix text-dark">
                per month per user <span className="text-dullest">+ VAT</span>
              </p>

              <span className="text-center block mt-10 mb-30 mt-30">
                <Button
                  variant="cta" size="lg"
                  className="btn-rounded text-center"
                  onClick={() => { handleSetPackage(null, null, true, false, '') }
                  }
                >
                  Get started
                </Button>
              </span>
            </div>
            <div className="box-body">
              <ul className="ml-5 mt-40 clearfix">
                <li>
                  Includes an 084 number
                  <br />
                  <span className="text-sm">One 0844 or 0843 number</span>
                </li>
                <li>
                  20+ call features
                  <br />
                  <span className="text-sm">
                    Including music on hold, customised prompts, open close
                    times &amp;{' '}
                    <span
                      className="cursor-pointer underline"
                      onClick={() => { props.showFeaturesModal() }}
                    >
                      more
                    </span>
                  </span>
                </li>
                <li>
                  Unlimited* inbound calls
                  <br />
                  <span className="text-sm">To VoIP or UK landline only</span>
                </li>
                <li>
                  PC softphone
                  <br />
                  <span className="text-sm">
                    Make &amp; receive calls on a PC or laptop
                  </span>
                </li>
                <li>
                  Free iOS &amp; Android apps
                  <br />
                  <span className="text-sm">
                    Make &amp; receive calls anywhere in the world
                  </span>
                </li>
                <li>
                  Number presentation
                  <br />
                  <span className="text-sm">
                    Display your business number when making calls
                  </span>
                </li>
                <li>No set-up or activation fees</li>
                <li>Zero contract</li>
              </ul>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="col-md-12 clearfix">
        <div
          className={`mb-30 ${isAlone ? 'pb-50' : ''} ${isSignup ? '' : 'brad-30'
            }`}
        >
          <div className="row package-container free-plans pr-xs-0">
            {loggedIn && !capacityReached && render084FreePanel()}
            {render084UnlimitedPanel()}
          </div>
        </div>
      </div>
    )
  }

  function getMinuteBundlesFromPackageId (packageId: number): MinuteBundle {
    if (is0800) {
      switch (packageId) {
        case small0800:
          return MinuteBundle.small
        case med0800:
          return MinuteBundle.medium
        default:
          return MinuteBundle.none
      }
    } else {
      switch (packageId) {
        case small:
          return MinuteBundle.small
        case med:
          return MinuteBundle.medium
        default:
          return MinuteBundle.none
      }
    }
  }

  function handleAddMinutesBoltOn(num: MinuteBundle) {
    if (getMinuteBundlesFromPackageId(props.currentBasket.package?.packageId ?? 0) === num) {
      handleRemoveBoltOn()
    } else {
      let mins = is0800 ? 200 : 1000
      let cost = is0800 ? AppOnlyPrice0800 + 10 : AppOnlyPrice + 4
      let name = is0800 ? 'Basic Plan' : 'Basic Plan'
      let packageId = is0800 ? small0800 : small

      if (num === MinuteBundle.medium) {
        mins = is0800 ? 400 : 2500
        cost = is0800 ? AppOnlyPrice0800 + 20 : AppOnlyPrice + 9
        name = is0800 ? 'Standard Plan' : 'Standard Plan'
        packageId = is0800 ? med0800 : med
      } 

      const packData = new SbfPackage(
        name,
        mins * 60,
        cost,
        cost,
        packageId,
        num,
        props.prefix,
        {
          qty: props.userQty,
          monthlyPrice: 0
        }
      )
      props.handleSelectPackage(packData)
    }
  }

  function handleRemoveBoltOn () {
    props.handleRemoveBoltOn()
  }

  function renderInboundPackageBlock () {
    if (
      props.prefix &&
      is0800 &&
      is0800Offer &&
      state.appOnlyPrice !== AppOnlyPrice0800
    ) {
      setState({ ...state, appOnlyPrice: AppOnlyPrice0800 })
    }

    const inboundPackages = [
      {
        packageName: 'Standard Plan',
        minutes: props.prefix && is0800 ? 400 : 2500,
        price: state.appOnlyPrice + (props.prefix && is0800 ? 20 : 9)
      },
      {
        packageName: 'Basic Plan',
        minutes: props.prefix && is0800 ? 200 : 1000,
        price: state.appOnlyPrice + (props.prefix && is0800 ? 10 : 4)
      },
      {
        packageName: 'App Only',
        minutes: 0,
        price: state.appOnlyPrice
      }
    ]

    return (
      <>
        {inboundPackages.map((pack, i) => {
          return (
            <div
              className={'col-12 col-sm-6 col-md-4 pl-15 pr-15 pl-xs-10 pr-xs-10 float-start'}
            >
              <div className={`package-box mb-20 ${i === 1 ? 'unlim' : '' }`}>
                <div className="box-header">
                  {i === 0 && (
                    <div className="d-flex flex-row">
                      <div className="flash d-inline-block">
                        <span>
                          Amazing
                          <br />
                          value
                        </span>
                      </div>
                    </div>
                  )}
                  {i === 1 && (
                    <div className="d-flex flex-row">
                      <div className="flash flash-secondary d-inline-block">
                        <span>
                          Most
                          <br />
                          Popular
                        </span>
                      </div>
                    </div>
                  )}

                  <h3 className="text-500">{pack.packageName}</h3>
                  <span className="text-xl text-500 pkg-price">
                    <sup>&pound;</sup>
                    <span className="text-lg">
                      {pack.price}
                    </span>
                  </span>
                  <p className="text-sm text-400 clearfix text-dark">
                    per month per user <span className="text-dullest">+ VAT</span>
                  </p>

                  <span className="text-center block mt-10 mb-30 mt-30">
                    <Button
                      variant="cta" size="lg"
                      className="btn-rounded text-center"
                      onClick={() => i == 0 ? 
                        handleAddMinutesBoltOn(MinuteBundle.medium)
                        : i == 1 ?
                          handleAddMinutesBoltOn(MinuteBundle.small)
                          : props.continueWithoutBoltOn?.() }
                    >
                      Choose {pack.packageName}
                    </Button>
                  </span>
                  <p className="text-xs text-400 text-center p-10 text-500">
                    No long-term contract or setup fees.
                  </p>
                </div>
                <div className="box-body">
                  <ul className="ml-5 mt-40 clearfix">
                  {(props.prefix.startsWith('01') || props.prefix.startsWith('02') || props.prefix.startsWith('03') )&& <li>Free 01, 02 or 03 phone number</li>}
                  <li>
                      Unlimited inbound mins to our app, softphone & VoIP phones
                    </li>
                    <li>
                      {i === 2 ? 
                        'Redirect to landline or mobile from 3p/min' 
                        : `${numberWithCommas(pack.minutes ?? 0)} redirect minutes to UK mobile or landline.`
                      }
                    </li>
                    {i !== 2 && 
                      <>
                      <li>
                        On-hold music<br />
                        <span className="text-sm">Including top charts, updated fortnightly</span>
                      </li>
                      <li>
                        Multiple departments<br />
                        <span className="text-sm">Press 1 for support, 2 for sales</span>
                      </li>
                      </>}
                  </ul>
                </div>
              </div>
            </div>
          )
        }) }
      </>
    )
  }

  if (
    props.prefix &&
    is0800 &&
    state.fairUse !== '400'
  ) {
    setState({ ...state, fairUse: '400' })
  }

  function renderMain() {
    if (props.isSignup === true) {
      LocalStorageHelper.setItem<boolean>('isNewCustomer', true)
    }
    if (state.isLoading) {
      return (
        <LoadingNotification
          isLoading={true}
          message={'Loading available packages'}
          textColor="text-dark"
        />)
    }

    if (props.showInbound) {
      return (
        <>
          <div
            className={`package-container col-xs-12 pr-xs-0 ${props.prefix && is0800
              ? 'freephone-packages'
              : ''
              }`}
          >
            <div className="packages-slider w-1100px clearfix center-block mt-60 mt-xs-0 mb-30">
              {renderInboundPackageBlock()}
            </div>
          </div>

          <div className="col-xs-12 text-center text-xs mt-20 mt-xxs-10 mb-xxs-60">
            <i className="text-400">
              <sup>*</sup> Unlimited calls only available for calls answered
              using our SwitchboardFREE iOS &amp; Android Phone Apps, PC/Mac Softphone or VoIP Deskphones, subject
              to a fair usage policy totalling {state.fairUse} minutes per
              month.
              <br /> Any calls answered on a landline or mobile will be
              charged at 3p or 6p per minute, unless a minutes bolt-on has
              been added.
              <br />
              All bolt-on options include a 50/50 split of mobile and landline
              minutes. e.g. 1,000 mins plan is 500 landline minutes &amp; 500
              mobile minutes, totalling 1,000 minutes overall.
              <br />
              Out of bundle call rates apply: {additionalLandlineRate}p per
              minute to landline &amp; {additionalMobileRate}p per minute to
              mobile.
            </i>
          </div>
        </>
      )
    } else {
      return state.packages
        ? (
          <>
            <div className={`${props.noParentRowClass ? 'row' : ''}`}>
              {!props.prefix && (
                <>
                  <div className="package-container col-xs-12 pr-xs-0">
                    <div className="packages-slider w-1100px clearfix center-block mt-30 mt-xs-0">
                      {'packages' in state.packages &&
                        state.packages.packages.map(
                          (pack: Package, packageIndex) =>
                            renderPackageBlock(pack, packageIndex)
                        )}
                    </div>
                  </div>

                  {!props.isUpgradeModal &&
                    !props.isBasic &&
                    render084Plans(false, props.isSignup, props.loggedIn)}
                  {!props.isBasic && (
                    <p className="text-xs text-center">
                      <sup>*</sup> Unlimited calls are subject to fair usage
                      policies. {' '}
                      <span
                        className="underline cursor-pointer"
                        onClick={() => { props.onClickSmallPrint('terms') }}
                      >
                        Terms &amp; conditions.
                      </span>
                    </p>
                  )}
                </>
              )}

              {props.prefix && props.prefix !== '0844'&& props.prefix !== '084' && (
                <>
                  <div
                    className={`package-container col-xs-12 pr-xs-0`}
                  >
                    <div className="packages-slider w-1100px clearfix center-block mt-30 mt-xs-0">
                      {'packages' in state.packages &&
                        state.packages.packages &&
                        state.packages.packages.map(
                          (pack: Package, packageIndex: number) =>
                            renderPackageBlock(pack, packageIndex)
                        )}
                    </div>
                  </div>
                  {!props.isModal && (
                    <div className="col-xs-12 text-center mt-50 mt-xxs-10 mb-xxs-60">
                      {!props.isBasic && (
                        <p className="text-xs">
                          <sup>*</sup> Unlimited calls are subject to fair usage
                          policies.{' '}
                          <span
                            className="underline cursor-pointer"
                            onClick={() => { props.onClickSmallPrint('terms') }}
                          >
                            Terms &amp; conditions.
                          </span>
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}

              {props.prefix &&
                (props.prefix === '0844' || props.prefix === '084') &&
                !props.isUpgradeModal &&
                render084Plans(true, props.isSignup, props.loggedIn)}
            </div>

            <Modal
              show={state.showTpsWarning}
              onHide={() => { setState({ ...state, showTpsWarning: false }) }
              }
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-500 mx-auto">TPS Protection</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-40">
                <p className="text-400 mb-10">
                  The Telephone Preference Service (TPS) is a service that allows individuals to opt out of unsolicited sales and marketing calls. Calling these numbers could result in a fine of up to &pound;500,000
                </p>

                <p className="text-400 mb-10">
                  At SwitchboardFREE, we have created a feature to stop that happening! With our TPS Protection, you can rest assured that you, or your colleagues, won't be dialling numbers that are listed on the TPS.
                </p>

                <p className="text-500 mt-30">Benefits of SwitchboardFREE's TPS Protection:</p>
                <ul className="fancy cta pl-25 pt-0 pb-0 text-400">
                  <li>Avoid spending valuable time and resources on bad leads.</li>
                  <li>Focus on potential customers who are open to your calls.</li>
                  <li>Improve the efficiency of your sales and marketing efforts.</li>
                  <li>Avoid potential fines from the ICO.</li>
                </ul>
                <p className="pt-10 mb-0 text-400"><em>Switch off at any time.</em></p>
              </Modal.Body>
              <Modal.Footer className="text-center">
                <Button
                  variant="cta"
                  size="lg"
                  className="btn-block"
                  onClick={() => { handleChoosePlan(4) }}
                >
                  Continue with Unlimited
                </Button>
                <p
                  className="text-xs text-dark underline mt-30 mx-auto pb-20 cursor-pointer"
                  onClick={() => { setState({ ...state, showTpsWarning: false }) }
                  }
                >
                  Close this window.
                </p>
              </Modal.Footer>
            </Modal>

            <HandsetModal
              show={state.showHandset}
              hide={() => { setState({ ...state, showHandset: false }) }}
              choosePlan={(pack) => { handleChoosePlan(pack) }}
            />

          </>
          )
        : (
          <LoadingNotification
            isLoading={true}
            message={'Loading available packages'}
          />
          )
    }
  }

  return renderMain()

  function handleChoosePlan (id: number) {
    setState({ ...state, showHandset: false })

    { /* @ts-expect-error */ }
    const packages: PackageResultDto = state.packages

    const pack: Package | undefined = packages.packages.find(f => f.packageId === id)

    if (pack !== undefined) {
      handleSetPackage(0, pack, false, false, pack.packageName)
    }
  }

  function getProductDetails () {
    fetchProductPrices(
      [
        {
          productId: ProductIds.umr,
          monthlyOption: productBillingOption.umrBillingOptionMonthly
        }
      ],
      (prices) => {
        setState({
          ...state,
          productDetails: prices,
          umrPayment: prices.find(prod => prod.productId === ProductIds.umr)?.monthlyPrice ?? state.umrPayment
        })
      }
    )
  }

  async function getOutboundPackages () {
    const url = `/api/Purchase/GetPackages/${props.isUpgradeModal}`

    await fetchHelper
      .getJson<PackageResultDto>(url)
      .then((res) => {
        const dto = res.data
        let packages = [...dto.packages]

        let standard: Package

        // If we already have an 0800 selected, just show freephone plans
        if (props.prefix && is0800) {
          packages = packages.filter(f => f.numberType === '0800')
          standard = packages.length > 1 ? packages[1] : packages[0]
        } else {
          packages = packages.filter(f => f.numberType !== '0800')
          standard = packages.find((f) => f.packageId === 3)!
        }

        packages = packages.filter((f) => f.packageId !== standard.packageId)

        // Re-order
        packages.unshift(standard)

        dto.packages = packages
        setState({ ...state, packages: dto, isLoading: false })
      })
      .catch((err) => { console.error(err) })
  }

  function handleSetPackage (
    i: number | null,
    pack: PackageStub | null,
    is084: boolean,
    umr: boolean,
    packName: string
  ) {
    if (is084) {
      setState({ ...state, umr })

      const packData = new SbfPackage(
        !umr ? 'Free 084' : 'Unlimited 084',
        0,
        !umr ? 0 : state.umrPayment.repeatPaymentAmount,
        0,
        UnlimitedOutboundPlan,
        0,
        '084',
        {
          qty: props.userQty,
          monthlyPrice: 0
        }
      )
      packData.umr = umr
      props.handleSelectPackage(packData)
    } else {
      if (pack !== null) {
        if ('packageName' in pack) {
          const packData = new SbfPackage(
            pack.packageName,
            pack.inboundSeconds.landlineSeconds +
            pack.inboundSeconds.mobileSeconds +
            (pack.outboundSeconds.landlineSeconds +
              pack.outboundSeconds.mobileSeconds),
            pack.offerPrice !== pack.monthlyPrice ? // on offer
              pack.offerPrice
              : pack.monthlyPrice,
            pack.monthlyPrice,
            pack.packageId,
            i ?? 0,
            pack.numberType,
            {
              qty: props.userQty,
              monthlyPrice: pack.monthlyPrice
            }
          )

          props.handleSelectPackage(packData)
        }
      }
    }
  }
}

export default SelectPackage
