import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

const ProgressRing = props => {
  const [offset, setOffset] = useState(0)
  const circleRef = useRef(null)
  const {
    size,
    progress,
    strokeWidth,
    circleOneStroke,
    circleTwoStroke
  } = props

  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference
    setOffset(progressOffset)

    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out'
  }, [setOffset, progress, circumference, offset])

  return (
        <>
            <svg className="progress-ring"
                width={size}
                height={size}
            >
                <circle
                    className="bg"
                    stroke={circleOneStroke}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                />
                <circle
                    className="circle"
                    ref={circleRef}
                    stroke={circleTwoStroke}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
            </svg>
        </>
  )
}

ProgressRing.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  circleOneStroke: PropTypes.string.isRequired,
  circleTwoStroke: PropTypes.string.isRequired
}

export default ProgressRing
