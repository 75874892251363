import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { fetchHelper } from '../helpers/fetchHelper'

class VoicemailAudioPlayerButton extends Component {
  static defaultProps = {}

  static propTypes = { callId: PropTypes.number }

  state = { isPlaying: false }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.isPlaying && this.state.isPlaying) {
      fetchHelper.getBlob(this.getPromptUrl()).then(response => {
        const { audioPlayer } = this.refs
        audioPlayer.pause()
        audioPlayer.currentTime = 0
        audioPlayer.src = window.URL.createObjectURL(response.data, { type: 'audio/x-wav' })
        audioPlayer.play()
      }).catch(error => console.error(error))
    }

    if (prevState.isPlaying && !this.state.isPlaying) {
      const { audioPlayer } = this.refs
      audioPlayer.pause()
      audioPlayer.currentTime = 0
    }
  }

  getPromptUrl () {
    return `api/Reports/GetVoicemailAudio/${this.props.callId}`
  }

  render () {
    return (
            <>
                <audio ref="audioPlayer" className="mt-30" onEnded={e => this.setState({ isPlaying: false })}/>
                <Button size="xs" variant="cta" className="m-5" onClick={e => this.setState({ isPlaying: !this.state.isPlaying })} {...this.props}>{this.state.isPlaying ? 'Stop' : 'Play'}</Button>
            </>
    )
  }
}

export default VoicemailAudioPlayerButton
