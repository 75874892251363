import React, { Component } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { RequestAuthorizationCode } from 'react-oauth2-auth-code-flow'
import { fetchHelper } from '../../../../helpers/fetchHelper'
import OauthHelper from '../../../../helpers/OauthHelper'
import LoadingNotification from '../../../LoadingNotification'

const SeeYourContactBook = (
    <Tooltip>
     The total number of your contacts imported from this CRM.
    </Tooltip>
)

export default class GenericCrmTab extends Component {
  state = {
    disconnectedFromCrm: false,
    saveChangesPopupMessage: undefined,
    showDisconnectFromCrmModal: false,
    isLoadingData: true,
    crmName: '',
    numberOfContacts: 0,
    currentMissedCallAssignedTo: 0,
    currentVoicemailsAssignedTo: 0,
    missedCallAssignedTo: 0,
    voicemailsAssignedTo: 0,
    crmStaff: [
      { label: 'Kelly', value: 1 },
      { label: 'Fiona', value: 2 },
      { label: 'Seb', value: 3 }
    ],
    intervalId: undefined,
    disconnectMessage: '0%'
  }

  componentDidMount () {
    const url = '/api/GenericCrm/GetCrmData/' + this.props.crmId

    fetchHelper.getJson(url).then((res) => {
      this.setState({
        crmName: res.data.crmName,
        numberOfContacts: res.data.numberOfContacts,
        missedCallAssignedTo: res.data.missedCallAssignedTo,
        voicemailsAssignedTo: res.data.voicemailAssignedTo,
        currentMissedCallAssignedTo: res.data.missedCallAssignedTo,
        currentVoicemailsAssignedTo: res.data.voicemailAssignedTo,
        crmStaff: res.data.crmStaff,
        isLoadingData: false
      })
    })
  }

  async disconnectFromCrmPressed () {
    this.setState({
      showDisconnectFromCrmModal: true
    })
  }

  async disconnectFromCrmConfirmed () {
    const url = 'api/GenericCrm/DisconnectFromCrm/' + this.props.crmId

    this.setState({
      disconnectingCrm: true,
      disconnectMessage: '0%'
    })

    fetchHelper.postJson(url).then((res) => {
      if (res.data === true) {
        const context = this

        const intevalId = window.setInterval(function () {
          context.getDisconnectPercentageDone()
        }, 3000) // Every 3 seconds

        this.setState({
          intervalId: intevalId
        })
      }
    })
  }

  async saveChangesPressed () {
    const url = 'api/GenericCrm/SaveCrmSettings/' + this.props.crmId + '/' + this.state.voicemailsAssignedTo + '/' + this.state.missedCallAssignedTo

    fetchHelper.postJson(url).then((res) => {
      if (res.data === true) {
        this.setState({
          saveChangesPopupMessage: this.state.crmName + ' settings saved',
          currentMissedCallAssignedTo: this.state.missedCallAssignedTo,
          currentVoicemailsAssignedTo: this.state.voicemailsAssignedTo
        })
      } else {
        this.setState({ saveChangesPopupMessage: this.state.crmName + ' settings failed to save' })
      }
    })
  }

  async assignedVoicemailsToChanged (event) {
    this.setState({ voicemailsAssignedTo: Number(event.target.value) })
  }

  async assignedMissedCallsToChanged (event) {
    this.setState({ missedCallAssignedTo: Number(event.target.value) })
  }

  getDisconnectPercentageDone () {
    const url = 'api/GenericCrm/GetCrmDisconnectProgress/' + this.props.crmId

    fetchHelper.getJson(url).then((res) => {
      const percentageDone = res.data

      if (percentageDone === '100%') {
        window.clearInterval(this.state.intervalId)
        this.setState({
          intervalId: 0,
          disconnectingCrm: false,
          disconnectedFromCrm: true
        })
      } else if (percentageDone === 'error') {
        window.clearInterval(this.state.intervalId)
        this.setState({ intervalId: 0 })
      } else {
        this.setState({ disconnectMessage: percentageDone })
      }
    })
  }

  render () {
    if (this.state.isLoadingData === true) {
      return this.renderLoadingCrmData()
    } else if (this.state.disconnectedFromCrm === true) {
      return this.renderDisconnectedFromCrm()
    } else if (this.state.disconnectingCrm === true) {
      return this.renderDisconnectingFromCrm()
    } else {
      return this.renderMainScreen()
    }
  }

  renderDisconnectingFromCrm () {
    return <>
            <div className='row text-center pt-30 pb-30'>
                <LoadingNotification isLoading={true} message={ 'Disconnecting from ' + this.state.crmName} />
                <p>Please wait until the disconnection process is finished. ({this.state.disconnectMessage})</p>
            </div>
        </>
  }

  renderDisconnectedFromCrm () {
    const oauthClient = OauthHelper.GetCapsuleCrmOauthClient()
    const oauthClientPipeDrive = OauthHelper.GetPipeDriveCrmOauthClient()
    const oauthUrlHubspot = OauthHelper.GetHubSpotCrmOauthUrl()

    return <>
            <div className='row text-center pt-30 pb-30'>
                <div className='col-md-12'>
                    <h5 className='text-500'>
                        You have successfully disconnected {this.state.crmName}
                    </h5>
                    <p className='pb-20'>
                        Your {this.state.crmName} have been removed from your <br className='hidden-xs'/>SwitchboardFREE Contact Book.
                    </p>

                    {this.state.crmName === 'Capsule' &&
                        <RequestAuthorizationCode
                            oauthClient={oauthClient}
                            render={({ url }) =>
                            <>
                                <div className="col-xs-12">
                                    <a href={url} className="btn btn-sm btn-cta">Re-Connect with {this.state.crmName}</a>
                                </div>
                            </>
                        } />
                    }

                    {this.state.crmName === 'Pipedrive' &&
                        <RequestAuthorizationCode
                            oauthClient={oauthClientPipeDrive}
                            render={({ url }) =>
                            <>
                                <div className="col-xs-12">
                                    <a href={url} className="btn btn-sm btn-cta">Re-Connect with {this.state.crmName}</a>
                                </div>
                            </>
                        } />
                    }

                    {this.state.crmName === 'HubSpot' &&
                        <RequestAuthorizationCode
                            oauthClient={oauthClientPipeDrive}
                            render={({ url }) =>
                            <>
                                <div className="col-xs-12">
                                    <a href={oauthUrlHubspot} className="btn btn-sm btn-cta">Re-Connect with {this.state.crmName}</a>
                                </div>
                            </>
                        } />
                    }
                </div>
            </div>
    </>
  }

  renderLoadingCrmData () {
    return <>
            <div className='row text-center pt-30 pb-30'>
                <LoadingNotification isLoading={true} message={'Loading CRM Settings'} />
                <p> Loading CRM Settings </p>
            </div>
        </>
  }

  renderMainScreen () {
    return <>
            <Modal show={this.state.showDisconnectFromCrmModal === true}>
                <Modal.Header closeButton className='bb-0'>
                    <Modal.Title className='h5 text-500'>
                        Are you sure you want to disconnect {this.state.crmName} CRM?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Once you disconnect your {this.state.crmName} integration the following will happen:
                     </p>
                     <ul className='fancy cross-list text-400 pl-25 pt-20'>
                         <li className='pb-20'>
                            All your {this.state.crmName} contacts will be removed from your SwitchboardFREE's Contact Book, SwitchboardFREE's Phone and Admin apps and your SwitchboardFREE's Cordless and Deskphone.
                         </li>
                         <li className='pb-20'>
                            Received calls, missed calls and voicemails won't be fed into your {this.state.crmName} CRM.
                         </li>
                         <li className='pb-20'>
                            The A.I. Caller Greeting™ won't greet your callers by their name held in {this.state.crmName} CRM.
                         </li>
                         <li className='pb-20'>
                            You won't be alerted to your callers' name before you accept a call.
                         </li>
                         <li className='pb-20'>
                             Callers name won't be displayed in your missed call and voicemail email alerts, your call stats, and incoming calls via. the app, cordless phone, desk phone and your softphone.
                         </li>
                         <li className='pb-20'>
                            SwitchboardFREE won't link notes from {this.state.crmName} to your call recordings.
                         </li>

                     </ul>
                    <p className='pt-20 mb-0 text-500'>Remember, {this.state.crmName}'s integration with SwitchboardFREE is free of charge.  Do you want to still want to disconnect {this.state.crmName} from SwitchboardFREE?
                    </p>
                </Modal.Body>
                <Modal.Footer className='text-center bt-0'>
                    <Button variant="danger" bsSzie="lg" className="float-none" onClick={() => this.disconnectFromCrmConfirmed()}>Yes, disconnect</Button>
                    <div className='col-md-12 mt-10'>
                        <p className="underline text-dark pt-20 pb-20 cursor-pointer text-sm inline-block" onClick={() => this.setState({ showDisconnectFromCrmModal: false })}>Cancel and close this window.</p>
                    </div>

                </Modal.Footer>
            </Modal>

            <Modal show={this.state.saveChangesPopupMessage !== undefined}>
                <Modal.Header>
                    <Modal.Title>
                        Save Changes For {this.state.crmName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{this.state.saveChangesPopupMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="float-start" onClick={() => this.setState({ saveChangesPopupMessage: undefined })}>Close</Button>
                </Modal.Footer>
            </Modal>

            <div className='row text-center mt-50'>
                <div className='col-sm-8 br-1 br-xs-0'>
                    <div className='p-20 pt-0 text-start'>
                        <h4 className='text-500 mt-0'>
                            {this.state.crmName}
                        </h4>
                        <p>
                            With {this.state.crmName} CRM integration you benefit from:
                        </p>
                        <ul className="fancy cta pl-25 text-400 mt-30">
                            <li className="pb-20">
                                All of your contacts are quickly and easily accessible across all your devices
                            </li>
                            <li className="pb-20">
                                Received calls, missed calls and voicemails are all fed directly into your {this.state.crmName} CRM system
                            </li>
                            <li className="pb-20">
                                A.I. Caller Greeting™. Greets your callers by their name held in {this.state.crmName}
                            </li>
                            <li className="pb-20">
                                Be alerted to your callers' name before you accept a call
                            </li>
                            <li className="pb-20">
                                Automatically link notes from {this.state.crmName} to your calls recordings
                            </li>
                            <li className="pb-20">
                                When you answer a call on your PC using the softphone, your caller's {this.state.crmName} page instantly opens in your browser
                            </li>
                        </ul>
                        <div className='row'>
                            <div className='col-md-12'>
                                {this.state.missedCallAssignedTo === this.state.currentMissedCallAssignedTo && this.state.voicemailsAssignedTo === this.state.currentVoicemailsAssignedTo
                                  ? <></>
                                  : <Button
                                        variant="cta"
                                        bsSzie="lg"
                                        className="btn-lg pt-20 pb-20"
                                        disabled={ this.state.missedCallAssignedTo === this.state.currentMissedCallAssignedTo && this.state.voicemailsAssignedTo === this.state.currentVoicemailsAssignedTo}
                                        onClick={() => this.saveChangesPressed()}>
                                        Save Changes
                                    </Button>
                                }

                                <div className='col-md-12'>
                                    <span className="pt-20 pb-20 inline-block cursor-pointer text-danger text-sm text-400 underline" onClick={() => this.disconnectFromCrmPressed()}>
                                        Disconnect {this.state.crmName}
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='row'>
                        <div className='col-sm-12 mb-20'>
                            <div className='bg-dull b-1 p-20 brad-20'>
                                <p>Number of contacts</p>
                                <p>
                                    <OverlayTrigger placement="top" overlay={SeeYourContactBook}>
                                        <a href='/customer/contact-book/' className="text-cta text-sm underline">
                                            {this.state.numberOfContacts}
                                        </a>
                                    </OverlayTrigger>
                                </p>
                            </div>
                        </div>

                        <div className='col-sm-12 mb-20'>
                            <div className='bg-dull b-1 p-20 brad-20'>
                                <p>Assign missed calls to</p>
                                <select className="form-control" onChange={(event) => this.assignedMissedCallsToChanged(event)}>
                                    <option className='form-control' value={0} selected={this.state.missedCallAssignedTo === 0}>No one</option>
                                    {this.state.crmStaff && this.state.crmStaff.map((staff) => {
                                      return <option value={Number(staff.value)} selected={this.state.missedCallAssignedTo === Number(staff.value)}>{staff.label}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-sm-12 mb-20'>
                            <div className='bg-dull b-1 p-20 brad-20'>
                                <p>Assign voicemails to</p>
                                <select className="form-control" onChange={(event) => this.assignedVoicemailsToChanged(event)}>
                                    <option value={0} selected={this.state.voicemailsAssignedTo === 0}>No one</option>
                                    {this.state.crmStaff && this.state.crmStaff.map((staff) => {
                                      return <option value={Number(staff.value)} selected={this.state.voicemailsAssignedTo === Number(staff.value)}>{staff.label}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }
}
