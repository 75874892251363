import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { siteFooter } from '../constants/Constants'
import CallbackRequest from './CallbackRequest'

const DisplayConfigFooter = props => {
  if (props.ShowDisplayConfigFooter) {
    return (
            <>
                <div className="full-width full-secondary d-none d-sm-block text-lightest fixed" id="footerFloat">
                    <div className="container">
                        <div className="row text-center">
                            <>
                                <div className="col-md-3 col-sm-3 text-md text-sm-md br-1 text-400">
                                    <a href="tel:02031891213"><span className="icon icon-phone mr-5"></span> 0203 189 1213</a>
                                </div>
                                <div className="col-md-3 col-sm-3 text-md text-sm-md br-1 text-400">
                                    <span className="icon icon-phone-outgoing mr-5"></span> <CallbackRequest textLink={true} buttonText="Request Callback" />
                                </div>
                                <div className="col-md-3 col-sm-3 text-md text-sm-md br-1 text-400">
                                    <a href=""><span className="icon icon-bubble mr-5"></span> Live Chat</a>
                                </div>
                                <div className="col-md-3 d-none d-sm-block pt-0 pb-0">
                                    <Link className="btn btn-cta btn-sm btn-mobile-block mt-0" to={'/customer/numbers/new'}>Add New Number</Link>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
                <footer id="footerMain" className="pt-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-2 text-xs-center links">
                                <ul className="col-md-6">
                                    <li className="pb-10">
                                        <Link to={'/customer/numbers/'}>My Numbers</Link>
                                    </li>
                                    <li className="pb-10">
                                        <Link to={'/customer/stats/'}>Call Stats</Link>
                                    </li>
                                    <li className="pb-10">
                                        <Link to={'/webhooks/'}>Webhooks integration</Link>
                                    </li>
                                </ul>
                                <ul className="col-md-6">
                                    <li className="pb-10">
                                        <Link to={'/contact/'}>Help</Link>
                                    </li>
                                    <li className="pb-10">
                                        <Link to={'/privacy-policy'}>Privacy policy</Link>
                                    </li>
                                    <li className="pb-10">
                                        <Link to={'/terms-and-conditions'} target="_blank">Terms and Conditions</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-4 col-sm-6 bordered bt-0 pt-xs-30 br-xs-0 bl-xs-0 bb-0">
                                <div className="col-md-6">
                                    <a href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREE&hl=en_GB" rel="noopener"><img className="app-img" src={'images/google-play.svg'} /></a>
                                </div>
                                <div className="col-md-6">
                                    <a href="https://itunes.apple.com/gb/app/sbf-2012/id556878596"rel="noopener"><img className="app-img" src={'images/apple-store.svg'} /></a>
                                </div>
                                <div className="col-md-6 pt-20">
                                    <img src={'/images/prs-logo.png'}/>
                                </div>
                                <div className="col-md-6 pt-20">
                                    <img src={'/images/years/years-stamp-19.png'} alt="SwitchboardFREE - Established in 2005" className="mt-0 stamp" />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-3 text-xs-center">
                                <a className="block text-lg" href="tel:02031891213">0203 189 1213</a>
                                <a className="block text-md text-sm-sm" href="mailto:support@switchboardfree.co.uk">support@switchboardfree.co.uk</a>
                                <CallbackRequest />

                                <div className="icons">
                                    <a href="https://www.youtube.com/subscription_center?add_user=SwitchboardFREE" rel="noopener" target="blank">
                                        <img src="/images/icons/social-media/youtube.jpg" alt="Follow on Youtube" className="img-circle mt-20" />
                                    </a>
                                    <a href="https://www.facebook.com/SwitchboardFREEUK" rel="noopener" target="blank" >
                                        <img src="/images/icons/social-media/facebook.jpg" alt="Follow on Facebook" className="img-circle mt-20" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/switchboardfree" rel="noopener" target="blank">
                                        <img src="/images/icons/social-media/linkedin.jpg" alt="Follow on Linkedin" className="img-circle mt-20" />
                                    </a>
                                    <a href="https://www.twitter.com/SwitchboardFREE"rel="noopener" target="blank">
                                        <img src="/images/icons/social-media/twitter.jpg" alt="Follow on Twitter" className="img-circle mt-20" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <hr />
                                <p className="text-xs text-lightest">&copy; {(new Date().getFullYear())} Liquid11, Sea Lake Road, Lowestoft, Suffolk, NR32 3LQ, Company number: 04404380 Regulated by Ofcom.</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
    )
  } else { return null }
}

const mapStateToProps = state => {
  return { ShowDisplayConfigFooter: state.sbfApp.visibility.FOOTER === siteFooter.CONFIG }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {},
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayConfigFooter)
