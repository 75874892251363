import React from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import DisplayLoggedInFooter from '../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import LoadingNotification from '../components/LoadingNotification'
import BillingAgreements from '../components/Profile/Billing/BillingAgreements'
import BillingDetails from '../components/Profile/Billing/BillingDetails'
import Invoices from '../components/Profile/Billing/Invoices'
import CrmSettings from '../components/Profile/CRMSettings/CrmSettings'
import AccountSettings from '../components/Profile/CallRecordingSettings/AccountSettings'
import UsageSection from '../components/Profile/CallUsage/UsageSection'
import EmergencyContacts from '../components/Profile/EmergencyContactInfo/EmergencyContacts'
import PasswordSection from '../components/Profile/Security/PasswordSection'
import NumberRentals from '../components/Profile/Subscriptions/NumberRentals'
import Subscriptions from '../components/Profile/Subscriptions/Subscriptions'
import TPSProtectionSettings from '../components/Profile/TPSProtectionSettings/TPSProtectionSettings'
import AddressDetails from '../components/Profile/YourDetails/AddressDetails'
import CompanyDetails from '../components/Profile/YourDetails/CompanyDetails'
import ContactDetails from '../components/Profile/YourDetails/ContactDetails'
import OptinDetails from '../components/Profile/YourDetails/OptinDetails'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { fetchHelper } from '../helpers/fetchHelper'
import { getQueryStringFromProps } from '../helpers/utils'
import { handleNotificationChange } from '../store/SBF'

const _ = require('lodash')

class ProfilePage extends React.Component {
  state = {
    isLoading: true,
    contactDetails: {}
  }

  constructor (props) {
    super(props)
  }

  render () {
    let title = 'Account Details'

    const yourDetailsPage = window.location.href.toLowerCase().indexOf('your-details') > -1
    const securityPage = window.location.href.toLowerCase().indexOf('security') > -1
    const billingPage = window.location.href.toLowerCase().indexOf('billing') > -1
    const subscriptionAndNumbersPage = window.location.href.toLowerCase().indexOf('subscriptions-and-numbers') > -1
    const callRecordingSettingsPage = window.location.href.toLowerCase().indexOf('call-recording-settings') > -1
    const emergencyContactPage = window.location.href.toLowerCase().indexOf('emergency-contacts') > -1
    const usagePage = window.location.href.toLowerCase().indexOf('usage') > -1
    const crmSettingsPage = window.location.href.toLowerCase().indexOf('crm-settings') > -1
    const tpsProtectionSettingsPage = window.location.href.toLowerCase().indexOf('tps-protection-settings') > -1

    let defaultTab = "first"
    if (this.props.match !== undefined) {
      if (this.props.match.params.activeTab) {
        defaultTab = this.props.match.params.activeTab
      } else if (yourDetailsPage) {
        defaultTab = "first"
        title = 'Contact Details'
      } else if (securityPage) {
        defaultTab = "second"
        title = 'Security Settings'
      } else if (billingPage) {
        defaultTab = "third"
        title = 'Billing Details'
      } else if (usagePage) {
        defaultTab = "four"
        title = 'Usage Page'
      } else if (subscriptionAndNumbersPage) {
        defaultTab = "five"
        title = 'Your Subscriptions'
      } else if (callRecordingSettingsPage) {
        defaultTab = "six"
        title = 'Call Recording Settings'
      } else if (emergencyContactPage) {
        defaultTab = "seven"
        title = 'Emergency Contact Details'
      }else if (crmSettingsPage) {
        defaultTab = "eight"
        title = 'CRM Settings'
      } else if (tpsProtectionSettingsPage) {
        defaultTab = "nine"
        title = 'TPS Protection Settings'
      } else {
        defaultTab = "first"
        title = 'Contact Details'
      }
    }

    return <>
            <ScrollToTopOnMount/>
            <DisplayLoggedInFooter/>
            <DisplayMainHeader/>
            <Helmet>
                <title>{title}</title>
                <style type="text/css">{`
                        .outbound-widget {
                            margin:0 auto 30px auto !important;
                            position:relative;
                        }
                        .outbound-widget .new-tag {
                            left:-20px !important;
                        }
                        @media screen and (max-width:1600px) {
                            .outbound-widget {
                                right:unset !important;
                            }
                        }
                        @media screen and (max-width:1300px) {
                            .outbound-widget {
                                top:unset !important;
                            }
                        }
                    
                    `}</style>
            </Helmet>
            <header>
                <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/profile-bg.jpg')" }}>
                    <h1 className="text-center">Account Settings</h1>
                </div>
            </header>
            <div className="full-width bg-dull pt-60">
                <div className="container">
                    <div className="row">
                        {this.state.isLoading
                          ? <div>
                                <LoadingNotification loadingText={'Loading...'} isLoading={this.state.isLoading} textColor="text-dark"
                                    width={100} height={100} />
                            </div>
                          : <React.Fragment>
                                <Tab.Container id="left-tabs-example" defaultActiveKey={defaultTab}>
                                    <Row>
                                        <Col sm={3}>
                                        <Nav variant="pills" className="flex-column mt-0">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Your Details</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Security</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Billing</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="four">Call Usage &amp; Charges</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="five">Subscription &amp; Numbers</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="six">Call Recording Settings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="seven">Emergency Contacts</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="eight">CRM Settings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="nine">TPS Protection</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        </Col>
                                        <Col sm={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                    <div id="contactDetails" className="bg-light p-30 pb-40 brad-10 b-1 w-100 center-block mb-20">
                                                        <ContactDetails {...this.state.contactDetails} history={this.props.history} />
                                                    </div>

                                                    <div id="addressDetails" className="bg-light p-30 pb-40 brad-10 b-1 w-100 center-block mb-20">
                                                        <AddressDetails {...this.state.address} />
                                                    </div>
                                                    <div id="companyDetails" className="bg-light p-30 pb-40 brad-10 b-1 w-100 center-block mb-20">
                                                        <CompanyDetails {...this.state.companySelectOptions} {...this.state.selectedCompanyDetails} />
                                                    </div>
                                                    <div className="bg-light p-30 pb-40 brad-10 b-1 w-100 center-block mb-20">
                                                        <OptinDetails {...this.state.optins} />
                                                    </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                {<PasswordSection />}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <BillingDetails {...this.state.billingDetails} />
                                                <BillingAgreements />
                                                {/* Auto topup */}
                                                <div className="bg-light p-30 pb-40 brad-10 b-1 w-100 center-block mb-20">
                                                    <div className="col-md-12 text-center">
                                                        <h4 className="pb-20 text-400">Auto Credit Top-up</h4>
                                                        <p className="pb-20">
                                                            &quot;Auto Credit Top-up&quot; is a convenient service which will
                                                            automatically top-up your account credit via your credit or debit card
                                                            whenever your balance gets below £2.50
                                                            ensuring that your credit never runs out and that you never miss a call!
                                                        </p>
                                                    </div>
                                                    {/* Show this if auto topup is not enabled - of course the customer needs to have a payment method added to enable it - show him an error about the button if he click it */}
                                                    <div className="text-center pt-20">
                                                        <Link to="/customer/top-up" className="btn btn-cta btn-sm">Manage Auto Top-Up</Link>
                                                    </div>

                                                    {/* End of: Show this if auto topup is not enabled */}
                                                </div>

                                                <Invoices />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="four">
                                                <UsageSection />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="five">
                                                <Subscriptions />
                                                <NumberRentals/>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="six">
                                                <AccountSettings/>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="seven">
                                                <EmergencyContacts/>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="eight">
                                                <CrmSettings/>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="nine">
                                                <TPSProtectionSettings/>
                                            </Tab.Pane>
                                        </Tab.Content>
                                        </Col>
                                    </Row>
                                    </Tab.Container>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </>
  }

  centerTabs () {
    const tabs = document.getElementsByClassName('nav-tabs')
    if (tabs && tabs.length > 0) {
      tabs[0].classList.add('nav-tabs-centered')
      const wrapper = document.createElement('div')
      wrapper.classList.add('tabs-container')
      tabs[0].parentNode.insertBefore(wrapper, tabs[0])
      wrapper.appendChild(tabs[0])
    }
  }

  componentDidMount () {
    document.title = 'Profile'

    const url = 'api/Profile/GetProfile'

    fetchHelper.getJson(url).then(response => {
      const data = response.data
      const companySelectOptions = data.companySelectOptions
      const companySizeOptions = companySelectOptions.companySize
      const positionOptions = companySelectOptions.position
      const industryOptions = companySelectOptions.industry

      const selectedCompanyPosition = data.companyDetails.companyPositionID > 0 ? _.find(positionOptions, option => option.value === data.companyDetails.companyPositionID.toString()) : positionOptions[0]
      const selectedIndustry = data.companyDetails.industry > 0 ? _.find(industryOptions, option => option.value === data.companyDetails.industry.toString()) : industryOptions[0]
      const selectedCompanySize = data.companyDetails.companySizeID > 0 ? _.find(companySizeOptions, option => option.value === data.companyDetails.companySizeID.toString()) : companySizeOptions[0]

      const selectedCompanyDetails = { selectedCompanySize, selectedIndustry, selectedCompanyPosition }

      const billingDetails = data.billingDetails

      this.setState({
        isLoading: false,
        contactDetails: data.contactDetails,
        address: data.address,
        companySelectOptions,
        selectedCompanyDetails,
        billingDetails,
        optins: data.optinDetails,
        outboundDetails: data.outboundDetails
      })
      this.centerTabs()
    }).catch(error => console.error(error))

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('cancel=true')) {
        this.props.notify({ message: 'Your payment details were not added', isError: true })
      }
    }

    if (this.props.match !== undefined && this.props.match.params.scrollSection) {
      scroller.scrollTo(this.props.match.params.scrollSection, {
        duration: 1500,
        smooth: true,
        delay: 1000,
        offset: -200
      })
    }
  }
}

export default connect(
  state => {
    return state
  },
  {
    notify: handleNotificationChange
  }
)(withRouter(ProfilePage))
