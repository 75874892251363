import React, { Component } from 'react'
import Datetime from 'react-datetime';
import { fetchHelper } from '../../../helpers/fetchHelper'

class UsageSection extends Component {
  state = {
    isLoading: false,
    inboundData: [],
    outboundData: [],
    customBarShown: false,
    customStartFrom: undefined,
    customEndAt: undefined,
    selectedText: undefined,
    invalidSearch: false,
    invalidSearchMessage: '',
    twoMonthsAgoStart: undefined,
    oneMonthAgoStart: undefined,
    currentMonthStart: undefined,
    showMinutesRemaining: true
  }

  loadDateRange (yearFrom, monthFrom, dayFrom, yearTo, monthTo, dayTo) {
    this.setState({ isLoading: true, showMinutesRemaining: false })

    fetchHelper.getJson('api/CallUsageAndCharges/GetCallUsageAndChargeData/' + yearFrom + '/' + monthFrom + '/' + dayFrom + '/' + yearTo + '/' + monthTo + '/' + dayTo).then((data) => {
      if (data) {
        if (data.status !== 200) {
          this.setState({
            isLoading: false,
            invalidSearch: true,
            invalidSearchMessage: 'This function is restricted to period of 3 months or less'
          })
        } else {
          this.setState({
            isLoading: false,
            inboundData: data.data.inboundData,
            outboundData: data.data.outboundData,
            invalidSearch: false
          })
        }
      }
    })
  }

  thisMonthClicked () {
    const date = this.state.currentMonthStart

    const yearFrom = date.getFullYear()
    const monthFrom = date.getMonth() + 1
    const dayFrom = 1
    const dayTo = new Date(yearFrom, monthFrom, 0).getDate()

    this.loadDateRange(yearFrom, monthFrom, dayFrom, yearFrom, monthFrom, dayTo)
    this.setState({
      selectedText: date.toLocaleString('default', { month: 'long' }),
      customBarShown: false, 
      showMinutesRemaining: true
    })
  }

  lastMonthClicked () {
    const date = this.state.oneMonthAgoStart

    const yearFrom = date.getFullYear()
    const monthFrom = date.getMonth() + 1
    const dayFrom = 1
    const dayTo = new Date(yearFrom, monthFrom, 0).getDate()

    this.loadDateRange(yearFrom, monthFrom, dayFrom, yearFrom, monthFrom, dayTo)
    this.setState({
      selectedText: date.toLocaleString('default', { month: 'long' }),
      customBarShown: false,
      showMinutesRemaining: false
    })
  }

  twoMonthsAgoClicked () {
    const date = this.state.twoMonthsAgoStart

    const yearFrom = date.getFullYear()
    const monthFrom = date.getMonth() + 1
    const dayFrom = 1
    const dayTo = new Date(yearFrom, monthFrom, 0).getDate()

    this.loadDateRange(yearFrom, monthFrom, dayFrom, yearFrom, monthFrom, dayTo)

    this.setState({
      selectedText: date.toLocaleString('default', { month: 'long' }),
      customBarShown: false,
      showMinutesRemaining: false
    })
  }

  customClicked () {
    this.setState({ customBarShown: !this.state.customBarShown })
  }

  viewResultsClicked () {
    if (this.state.customStartFrom < this.state.customEndAt) {
      const yearFrom = this.state.customStartFrom.get('year')
      const monthFrom = this.state.customStartFrom.get('month') + 1
      const dayFrom = this.state.customStartFrom.get('date')

      const yearTo = this.state.customEndAt.get('year')
      const monthTo = this.state.customEndAt.get('month') + 1
      const dayTo = this.state.customEndAt.get('date')

      this.loadDateRange(yearFrom, monthFrom, dayFrom, yearTo, monthTo, dayTo)
      this.setState({ selectedText: 'Custom' })
    } else {
      this.setState({
        selectedText: 'Custom',
        invalidSearch: true,
        invalidSearchMessage: 'The start date is after the end date'
      })
    }
  }

  renderClickableButton (uniqueId, text, onClick) {
    return this.state.selectedText !== undefined && this.state.selectedText === text
      ? <div id={uniqueId} className="btn btn-sm btn-secondary btn-block active b-0" onClick={() => onClick()}>

                    <span className='icon icon-checkmark3 text-lightest mr-10'></span>

               {text}
            </div>
      : <div id={uniqueId} className="btn btn-sm btn-default btn-block b-0" onClick={() => onClick()}>
                {text}
            </div>
  }

  renderCost (title, minutesUsed, cost, minutesRemaining, showMinutesRemaining) {
    return <div className="text-center b-1 brad-10 box-shadow mb-20 pt-15">
            <div>
                <p className="pb-0 text-500 mb-0">{title}</p>
                <p style={showMinutesRemaining ? {marginBottom:0} : {}}>{minutesUsed} Minutes used {cost === 0 ? '' : '(£' + (cost).toFixed(2) + ' surcharge)'}</p>
                {showMinutesRemaining && <p>{minutesRemaining} Minutes remaining</p>}
            </div>
        </div>
  }

  renderSummaryInfo (title, text) {
    return <div className="text-center b-1 brad-10 box-shadow mb-20 pt-15">
            <div>
                <p className="pb-0 text-500 mb-0">{title}</p>
                <p>{text}</p>
            </div>
        </div>
  }

  renderLoadingIcon () {
    return <div className="text-center pt-30">
            <img id="loading" src={'images/icons/loading.svg'} width="70" height="70" />
            <p className="text-dark text-400"> Loading Data </p>
        </div>
  }

  renderInvalidSearch () {
    return <div className="text-center p-30 alert-warning d-block center-block w-400px brad-10 mt-20 ">
            <p className="text-dark text-500">Invalid Search</p>
            <p className="text-dark text-400 mb-0"> {this.state.invalidSearchMessage}.</p>
        </div>
  }

  validateStartPage (current) {
    const futureDays = Datetime.moment()
    return current.isBefore(futureDays)
  }

  validateEndPage (current) {
    const futureDays = Datetime.moment()
    return current.isBefore(futureDays)
  }

  renderPage () {
    let inboundCost = 0
    let inboundMinutes = 0

    for (let i = 0; i < this.state.inboundData.length; i++) {
      inboundCost += (this.state.inboundData[i].landlineCost + this.state.inboundData[i].mobileCost)
      inboundMinutes += (this.state.inboundData[i].mobileMinutes + this.state.inboundData[i].landlineMinutes)
    }

    let outboundCost = 0
    let outboundMinutes = 0

    for (let i = 0; i < this.state.outboundData.length; i++) {
      outboundCost += (this.state.outboundData[i].ukLandlineCost + this.state.outboundData[i].ukMobileCost + this.state.outboundData[i].otherCost)
      outboundMinutes += (this.state.outboundData[i].ukLandlineMinutes + this.state.outboundData[i].ukMobileMinutes + this.state.outboundData[i].otherMinutes)
    }

    const totalCost = Number(inboundCost + outboundCost)
    const totalMinutes = Number(inboundMinutes + outboundMinutes)

    return <>
            <div className='text-center mt-30'>
                {/* <hr style={{borderTop:"1px solid black"}}/> */}
                <h5 className='text-500'>Summary</h5>
                {/* <hr style={{borderTop:"1px solid black"}}/> */}
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    {this.renderSummaryInfo('Total Cost', '£' + totalCost.toFixed(2))}
                </div>
                <div className='col-md-4'>
                    {this.renderSummaryInfo('Inbound Cost', '£' + inboundCost.toFixed(2))}
                </div>
                <div className='col-md-4'>
                    {this.renderSummaryInfo('Outbound Cost', '£' + outboundCost.toFixed(2))}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    {this.renderSummaryInfo('Total Minutes', totalMinutes)}
                </div>
                <div className='col-md-4'>
                    {this.renderSummaryInfo('Inbound Minutes', inboundMinutes)}
                </div>
                <div className='col-md-4'>
                    {this.renderSummaryInfo('Outbound  Minutes', outboundMinutes)}
                </div>
            </div>
            <div className='text-center mt-30'>
                <h5 className='text-500'>Inbound Breakdown</h5>
            </div>
            {this.state.inboundData.map((x) => {
              return <>
                    <h5 className="text-500">
                       <span className='icon icon-phone2 text-duller mr-5'></span> {x.number}
                    </h5>
                    <div className="row">
                        <div className="col-md-6 pr-5">
                            {this.renderCost('Landline', x.landlineMinutes, x.landlineCost, x.landlineMinutesRemaining, this.state.showMinutesRemaining)}
                        </div>
                        <div className="col-md-6 pl-5">
                            {this.renderCost('Mobile', x.mobileMinutes, x.mobileCost, x.mobileMinutesRemaining, this.state.showMinutesRemaining)}
                        </div>
                    </div>
                    <br/>
                </>
            })}
            {this.state.inboundData.length === 0 && <div className="row">
                <div className="text-center pl-30 pr-30 pt-10 pb-10 alert-warning d-block p-30 center-block w-400px brad-10 mt-20 mb-30">
                    <p className='mb-0 alert alert-warning'>No usage detected during this time.</p>
                </div>
            </div>}

            <div className='text-center mt-30'>

                <h5 className='text-500'>Outbound Breakdown</h5>

            </div>

            {this.state.outboundData.map((x) => {
              return <>
                    <h5 className='text-500'>
                        <span className='icon icon-phone2 text-duller mr-5'></span> {x.extName} - Extension {x.extNumber} ({x.name})
                    </h5>
                    <div className="row">
                        <div className="col-md-4 pr-10">
                            {this.renderCost('UK Landline', x.ukLandlineMinutes, x.ukLandlineCost, x.ukLandlineMinutesRemaining, this.state.showMinutesRemaining)}
                        </div>
                        <div className="col-md-4 pr-5 pl-5">
                            {this.renderCost('UK Mobile', x.ukMobileMinutes, x.ukMobileCost, x.ukMobileMinutesRemaining, this.state.showMinutesRemaining)}
                        </div>
                        <div className="col-md-4 pl-10">
                            {this.renderCost('Other', x.otherMinutes, x.otherCost, 0, this.state.showMinutesRemaining)}
                        </div>
                    </div>
                    <br/>
                </>
            })}
            {this.state.outboundData.length === 0 && <div className="row">
                <div className="text-center pl-30 pr-30 pt-10 pb-10 alert-warning d-block p-30 center-block w-400px brad-10 mt-20">
                    <p className='mb-0 alert alert-warning'>No usage detected during this time.</p>
                </div>
            </div>}
        </>
  }

  componentDidMount () {
    const currentMonthStart = new Date()
    currentMonthStart.setDate(1)
    currentMonthStart.setMonth(currentMonthStart.getMonth())

    const prevousMonthStart = new Date(currentMonthStart)
    prevousMonthStart.setMonth(currentMonthStart.getMonth() - 1)

    const twoMonthsAgoStart = new Date(currentMonthStart)
    twoMonthsAgoStart.setMonth(currentMonthStart.getMonth() - 2)

    this.setState({
      twoMonthsAgoStart,
      oneMonthAgoStart: prevousMonthStart,
      currentMonthStart
    }, () => {
      this.thisMonthClicked()
    })
  }

  render () {
    const thisMonthText = this.state.currentMonthStart === undefined ? '' : this.state.currentMonthStart.toLocaleString('default', { month: 'long' })
    const lastMonthText = this.state.oneMonthAgoStart === undefined ? '' : this.state.oneMonthAgoStart.toLocaleString('default', { month: 'long' })
    const twoMonthsAgoText = this.state.twoMonthsAgoStart === undefined ? '' : this.state.twoMonthsAgoStart.toLocaleString('default', { month: 'long' })

    const futureDays = Datetime.moment()

    const validStartDate = function (current) {
      const isValid = current.isBefore(futureDays)

      return isValid
    }

    const validEndDate = function (current) {
      const isValid = current.isBefore(futureDays)

      return isValid
    }

    return (
            <div className="bg-light p-30 pb-40  brad-10 b-1 w-100 center-block mb-20" style={{ overflow: 'auto' }}>
                <h4 className="pb-20 text-400 text-center">Call Usage &amp; Charges</h4>
                <p className="mb-40 text-center">Select a date range below to get a full breakdown of call volumes and any associated charges.</p>

                <div className="row">
                    <div className="col-xs-6 col-sm-3 mb-10">
                        {this.renderClickableButton('twoMonthsAgoButton', twoMonthsAgoText, () => this.twoMonthsAgoClicked())}
                    </div>
                    <div className="col-xs-6 col-sm-3 mb-10">
                        {this.renderClickableButton('lastMonthButton', lastMonthText, () => this.lastMonthClicked())}
                    </div>
                    <div className="col-xs-6 col-sm-3 mb-10">
                        {this.renderClickableButton('thisMonthButton', thisMonthText, () => this.thisMonthClicked())}
                    </div>
                    <div className="col-xs-6 col-sm-3 mb-10">
                        {this.renderClickableButton('customButton', 'Custom', () => this.customClicked())}
                    </div>
                </div>
                {this.state.customBarShown === true && <>
                    <div className="row">
                        <div className='col-12 bg-dull b-1 brad-10 p-20 mt-30'>
                            <div className='row'>
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p>Start date</p>
                                            <Datetime isValidDate={validStartDate} inputProps={{ id: 'startDateInput', readOnly: true }} closeOnSelect={true} timeFormat={false} dateFormat='DD/MM/YYYY' isSearchable={false} value={this.state.customStartFrom} onChange={(x) => this.setState({ customStartFrom: x })}/>
                                        </div>
                                        <div className="col-md-6">
                                            <p>End date</p>
                                            <Datetime isValidDate={validEndDate} inputProps={{ id: 'endDateInput', readOnly: true }} closeOnSelect={true} timeFormat={false} dateFormat='DD/MM/YYYY' isSearchable={false} value={this.state.customEndAt} onChange={(x) => this.setState({ customEndAt: x })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 mt-40">
                                    {this.renderClickableButton('viewResultsButton', 'View Results', () => this.viewResultsClicked())}
                                </div>
                            </div>
                        </div>

                    </div>
                </>}
                {this.state.invalidSearch === true
                  ? this.renderInvalidSearch()
                  : this.state.isLoading
                    ? this.renderLoadingIcon()
                    : this.renderPage()
                }
            </div>
    )
  }
}

export default UsageSection
