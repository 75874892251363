import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router'
import DisplayLoggedInFooter from '../DisplayLoggedInFooter'
import DisplayMainHeader from '../DisplayMainHeader'
import MyCallsCallStats from '../MyCalls/MyCallsCallStats'
import ScrollToTopOnMount from '../ScrollToTopOnMount'
import ModalVideo from 'react-modal-video'

class MyCalls extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
        isOpenSeven: false
      }
    }
  
  /* -- Strucutre
    *    Title Div
    *    Banner Div
    *    Content Div
    */
  render () {
    const voicemailPage = window.location.href.toLowerCase().indexOf('voicemails') > -1
    const voicemailDetailsPage = window.location.href.toLowerCase().indexOf('voicemail-details') > -1

    const callStatsPage = window.location.href.toLowerCase().indexOf('call-stats') > -1
    const callInformationPage = window.location.href.toLowerCase().indexOf('call-information') > -1

    const callAlertsPage = window.location.href.toLowerCase().indexOf('call-alerts') > -1
    const callEditAlertPage = window.location.href.toLowerCase().indexOf('edit-alert') > -1
    const callCreateAlertPage = window.location.href.toLowerCase().indexOf('create-alert') > -1

    const defaultTab = voicemailDetailsPage || voicemailPage ? 4 : callInformationPage || callStatsPage ? 2 : callEditAlertPage || callCreateAlertPage || callAlertsPage ? 3 : 2

    return (
            <>
                <ScrollToTopOnMount/>
                <DisplayLoggedInFooter/>
                <DisplayMainHeader/>
                <Helmet>
                    <style type="text/css">
                        {`  
                            .nav-tabs > li {
                                float:none;
                                display:inline-block;
                                zoom:1;
                            }
                            
                            .nav-tabs {
                                text-align:center;
                                display:inline-block !important;
                            }

                            .tab-pane {
                                width: 100%;
                                background-color: white;
                            }
                        `}
                    </style>
                </Helmet>
                <header className="full-width my-calls-header">
                    <h1 id="CallReportsH1">Call Reports</h1>
                    <h2 className="subtitle">Run reports and download call listings. <span className='underline cursor-poiner' onClick={() => this.setState({ isOpenSeven: true })} >Watch video</span></h2>
                </header>
                <ModalVideo channel='youtube' isOpen={this.state.isOpenSeven} videoId='jGQbiUc9qi0' onClose={() => this.setState({ isOpenSeven: false })} />
                        <div className="container">
                            <div className="center-block mt-30 mb-30 brad-30" >
                                <MyCallsCallStats/>
                            </div>
                        </div>
             </>
    )
  }
}


export default withRouter(MyCalls)