import React from 'react'
import { Redirect, withRouter } from 'react-router'

class MyCallsCallInformation extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const isOutbound = this.props.match.params.isOutbound
    const callId = this.props.match.params.callId

    return (<Redirect to={'/customer/calls?callId=' + callId + '&isInbound=' + (isOutbound === 1 ? 0 : 1)} />)
  }
/*
    state = {
        CallDate: undefined,
        CallerNumber: undefined,
        CallDuration: undefined,
        HoldTime: undefined,
        Result: undefined,
        AnsweredBy: undefined,
        Cost: 0,
        showAddNotePopup: false,
        callNote: '',
        callRecordingId: undefined,
        rejectCode: undefined,
        withheld: false,
        loading: true,
        hasVoicemail: false,
        showAddToContactBook: false,
        newContactName: undefined,
        newContactCompany: undefined,
        ignorePersonalizedIntro: false,
        callRecordingBreakdown: undefined,
        isDownloading: false,
        isLoadingCallRecording: true
    };

    componentDidMount(){
        const isOutbound = this.props.match.params.isOutbound;
        const callId = this.props.match.params.callId;
        let url = '/api/Numbers/GetCallInformation/' + isOutbound + '/'+ callId;

        fetchHelper.getJson(url).then(response => {
            this.setState({
                CallDate: response.data.callDateText,
                CallerNumber: response.data.callerNumber,
                CallDuration: response.data.callDuration,
                HoldTime: response.data.holdTime,
                Result: response.data.result,
                AnsweredBy: response.data.answeredBy,
                Cost: response.data.cost,
                callNote : response.data.note,
                callRecordingId: response.data.callRecordingId,
                rejectCode: response.data.rejectCode,
                withheld: response.data.withheld,
                hasVoicemail: response.data.hasVoicemail,
                loading: false,
                contactbookName: response.data.contactbookName,
                contactbookCompany: response.data.contactbookCompany,
                callRecordingBreakdown: response.data.callRecordingBreakdown,
                isLoadingCallRecording: response.data.callRecordingId !== undefined && response.data.callRecordingId > 0
            });

            if(response.data.callRecordingId !== undefined && response.data.callRecordingId > 0){
                var callRecordingUrl = "/api/CallRecording/DownloadCallRecordingAudio/" + response.data.callRecordingId;

                fetchHelper.getBlob(callRecordingUrl).then(response => {
                    this.setState({isLoadingCallRecording: false});
                    const audioPlayer = document.getElementById('audioPlayer');
                    audioPlayer.pause();
                    audioPlayer.currentTime = 0;
                    audioPlayer.src = window.URL.createObjectURL(response.data, { type: 'audio/mpeg' });
                }).catch(error => console.error(error));
            }
        });
    }

    startAudioPlayerAtSpecificTime(startTimeInSeconds){
        const audioPlayer = document.getElementById('audioPlayer');
        audioPlayer.pause();
        audioPlayer.currentTime = startTimeInSeconds;
        audioPlayer.play();
    }

    handleSaveNewContact() {
        let url = '/api/Contacts/SaveNewContactBookContact';

        let newContact = {
		    company: this.state.newContactCompany,
		    description: this.state.newContactName,
		    number: this.state.CallerNumber.includes('.') ? this.state.AnsweredBy.trim() : '0' + this.state.CallerNumber.trim(),
		    ignorePersonalizedIntro: this.state.ignorePersonalizedIntro
        }

        fetchHelper.postJson(url, newContact).then(res => {
            if(res.data === true){

                const company = this.state.newContactCompany;
                const name = this.state.newContactName;

                this.setState({
                    newContactName: undefined,
                    newContactCompany: undefined,
                    ignorePersonalizedIntro: false,
                    showAddToContactBook: false,
                    contactbookName: name,
                    contactbookCompany: company
                });
            }

        }).catch(function (error) {
            console.error(error);
        });
    }

    handleBackPressed(){
        this.props.history.push(`/customer/my-calls/call-stats`)
    }

    handleAddToContactBookPressed(){
        this.setState({showAddToContactBook: true})
    }

    handleDownloadPressed(){
        if(this.state.isDownloading === false){

            this.setState({
                isDownloading: true
            });

            var callRecordingUrl = "/api/CallRecording/DownloadCallRecordingAudio/" + this.state.callRecordingId;

            fetchHelper.getBlob(callRecordingUrl).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const date = new Date();
                const year = date.getFullYear();
                const month = ('00' + (date.getMonth() + 1)).slice(-2);
                const day = ('00' + date.getDate()).slice(-2);

                const hour = ('00' + date.getHours()).slice(-2);
                const minute = ('00' + date.getMinutes()).slice(-2);
                const second = ('00' + date.getSeconds()).slice(-2);
                link.setAttribute('download',  'SwitchboardFREE_' + year + month + day + '_'  + hour + minute + second + '_' + this.state.callRecordingId + '.mp3');
                document.body.appendChild(link);
                link.click();

                this.setState({
                    isDownloading: false
                });
            }).catch(error => console.error(error));
        }
    }

    formatCli(cli)
    {
        cli = cli.trim();

        if(cli.length > 9)
        {
            if(cli[0] !== '0'){
                cli = '0' + cli;
            }

            var firstThree = cli.substring(0, 3);
            if(firstThree === '020' || firstThree === '084' || firstThree === '080' || firstThree === '033'){
                cli = cli.substring(0, cli.length - 7) + ' ' + cli.substring(cli.length - 7, cli.length -  4) +' ' + cli.substring(cli.length - 4);
            }
            else {
                cli = cli.substring(0, cli.length - 6) + ' ' + cli.substring(cli.length - 6, cli.length -  3) +' ' + cli.substring(cli.length - 3);
            }
        }

        return cli;
    }
/*
    render(){

        if(this.state.loading === true){
            return (
                <>
                    <p className="text-center">
                        <img src="/images/icons/loading.svg" alt="Loading call information..." width="100" height="100" />
                    </p>
                </>
            );
        }
        else{
            return (
                <>

                    <Modal size="w-450px" show={this.state.showAddToContactBook === true} onHide={e => this.setState({showAddToContactBook: false})}>
                        <Modal.Body>
                            <div className="col-md-12 text-center pb-20">
                                <h5 className="text-center text-500 text-primary">Add new Contact</h5>
                            </div>
                            <span className="close-modal" onClick={() => this.setState({ showAddToContactBook: false })}>
                                <span>&times;</span>
                            </span>
                            <div className="col-md-12">
                                <div className={"form-group mb-0 " + (this.state.newContactName === undefined || this.state.newContactName === '' ? "has-error" : "")}>
                                    <label className="control-label">Callers name / description</label>
                                    <input className="form-control" type='text'
                                        placeholder={'Caller\'s Description / Name'}
                                        value={this.state.newContactName === undefined ? '' : this.state.newContactName}
                                        onChange={e => {

                                            var value = e.target.value.replaceAll(' ','');

                                            if(value.length > 50)
                                            {
                                                value = value.substring(0, 50);
                                            }

                                            this.setState({
                                                newContactName: value
                                            })
                                        }}/>
                                </div>
                                <label className="pt-20">Company</label>
                                <input className="form-control" type='text' placeholder={'Optional'}
                                    value={this.state.newContactCompany === undefined ? '' : this.state.newContactCompany}
                                    onChange={e => {
                                        var value = e.target.value;

                                        if(value.length > 50)
                                        {
                                            value = value.substring(0, 50);
                                        }

                                        if (!e) {
                                            this.setState({newContactCompany: ''})
                                        }
                                        else {
                                            this.setState({newContactCompany: value})
                                        }
                                    }}
                                />

                                <div className={"form-group"}>
                                    <label className="control-label pt-20">Phone Number</label>
                                    <input className="form-control" type='tel' placeholder={'Phone number'} maxLength={11}
                                        value={this.state.CallerNumber.includes('.') ? this.state.AnsweredBy : '0' + this.state.CallerNumber}
                                    />
                                </div>

                                <div className="bg-dark p-30 brad-20 text-center mt-30">
                                    <SvgRender icon="hello" svgWidth="50px" svgClassName="mb-10" />
                                    <h6 className="text-500 text-lightest">Turn on A.I. Caller Greeting?</h6>
                                    <p className="text-italic text-500 text-lightest text-sm">
                                        Recognise &amp; greet mobile callers by name.
                                    </p>
                                    <div className="block">
                                        <Toggle
                                            onClick={(e) => { this.setState({ignorePersonalizedIntro: !e})}}
                                            on={<span>ON</span>}
                                            off={<span>OFF</span>}
                                            offstyle="default"
                                            onstyle="success"
                                            width="null"
                                            height="null"
                                            size="sm"
                                            className="rounded mt-10"
                                            active={!this.state.ignorePersonalizedIntro}
                                        />
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer className="bt-0">
                            <div className="col-md-12 text-center pt-30 pb-30">
                            <Button size="large" variant="cta" className="btn-block" disabled={!this.state.newContactName} onClick={e => this.handleSaveNewContact()}>Save Contact</Button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <div className="container bg-gradient-v1 text-center mb-20 mt-30 p-30 pb-50 brad-30 text-lightest" style={{maxWidth: "970px", padding:"20px"}}>
                        <h4 className="text-500">
                            Use our new Calls page instead.
                        </h4>
                        <p>
                            We have a new page where you can view your calls in one place.
                        </p>
                        <p className="mt-30">
                            <a href="/customer/calls" className="btn btn-lg btn-lightest">See all my calls</a>
                        </p>
                    </div>
                    <div className="container text-start"  style={{maxWidth: "970px", padding:"20px"}}>
                        <div className="row">
                            <div className="col-xs-12 b-1 bg-light mb-20 brad-20 box-shadow p-30">
                                {this.state.contactbookName !== null &&
                                    <span className="text-500">
                                        <span className="inline-block" style={{fontSize:"20px"}}>
                                            {this.state.contactbookName}
                                        </span>

                                        {this.state.contactbookCompany !== null && this.state.contactbookCompany !== '' &&
                                            <span style={{fontSize:"20px"}}>
                                                <span className="text-400" title="Company">
                                                    &nbsp;from&nbsp;
                                                     <span className="text-500">
                                                        {this.state.contactbookCompany}
                                                    </span>
                                                </span>
                                            </span>
                                        }
                                    </span>

                                }

                                <p className="mb-5 mt-30">
                                    <span className="text-500">Call Direction: </span>

                                    {this.state.CallerNumber.includes('.') ?
                                        <span className="text-400">Outbound</span>
                                        :
                                        <span className="text-400">Inbound</span>
                                    }
                                </p>
                                <p className="mb-5">
                                    <span className="text-500">Call status: </span>

                                    {this.state.Result === 'Missed' ?
                                    <span className="text-danger text-400"><span className="icon icon-phone-slash text-danger mr-5 ml-5 text-sm"></span>Missed call</span>
                                    :
                                    <span className="text-success text-400"><span className="icon icon-phone2 text-success mr-5 ml-5 text-sm"></span>Answered call</span>
                                    }
                                </p>
                                <p className="mb-5">
                                    {this.state.CallerNumber.includes('.') ?
                                        <>
                                            <span className="text-500">User: </span>

                                            <span className="text-dark underline">
                                                {this.state.CallerNumber}
                                            </span>
                                        </>
                                    :
                                    <>
                                        <span className="text-500">Callers number: </span>

                                        {this.state.withheld === true ?
                                            'Withheld'
                                        :
                                            <a className="text-dark underline" href={`tel:${this.formatCli(this.state.CallerNumber)}`}>
                                                {this.formatCli(this.state.CallerNumber)}
                                            </a>
                                        }

                                        {this.state.contactbookName === null && this.state.withheld !== true &&
                                             <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
                                                <Popover>
                                                    <p className="mb-0">
                                                        Add to your Contact Book.
                                                    </p>
                                                </Popover>
                                            }>
                                               <span className="ml-10 text-xs cursor-pointer" onClick={ () => this.handleAddToContactBookPressed() }>
                                                    <span className="icon icon-plus-circle2 text-cta"></span>
                                                </span>
                                            </OverlayTrigger>
                                        }

                                    </>
                                    }
                                </p>
                                <p className="mb-5">
                                    <span className="text-500">Call date:</span> {this.state.CallDate}
                                </p>
                                <p className="mb-5">
                                    <span className="text-500">Call lasted:</span> {this.state.CallDuration}
                                </p>

                                {this.state.CallerNumber.includes('.') === false && <p className="mb-5">
                                    <span className="text-500">On hold for:</span> {this.state.HoldTime}
                                </p>
                                }

                                {this.state.AnsweredBy.includes('.') ?
                                    <p className="mb-5">
                                        <span className="text-500">Answered by:</span> {this.state.AnsweredBy}
                                    </p>
                                :
                                <>
                                <p className="mb-5">
                                    <span className="text-500">Dialed Number: </span>

                                    <a className="text-dark underline" href={`tel:${this.formatCli(this.state.AnsweredBy)}`}>
                                        {this.formatCli(this.state.AnsweredBy)}
                                    </a>

                                    {this.state.contactbookName === null && this.state.withheld !== true &&
                                        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
                                            <Popover>
                                                <p className="mb-0">
                                                    Add to your Contact Book.
                                                </p>
                                            </Popover>
                                        }>
                                        <span className="ml-10 text-xs cursor-pointer" onClick={ () => this.handleAddToContactBookPressed() }>
                                                <span className="icon icon-plus-circle2 text-cta"></span>
                                            </span>
                                        </OverlayTrigger>
                                    }
                                    </p>
                                </>
                                }
                                <p className="mb-5">
                                    <span className="text-500">Cost:</span> {this.state.Cost === 0 ? 'Free' : '£' + this.state.Cost}
                                </p>

                                {this.state.callRecordingId !== 0 &&
                                    <p className="mb-5">
                                        <span className="text-500">Download recording: </span>
                                        {this.state.isLoadingCallRecording === true ?
                                            <span className="text-dark underline cursor-pointer">Loading</span>
                                        :
                                            <span className="text-dark underline cursor-pointer" onClick={() => this.handleDownloadPressed() }>Download</span>
                                        }
                                    </p>
                                }

                                 {this.state.callRecordingId !== 0 && this.state.isLoadingCallRecording === false &&

                                    <div className="col-xs-12 pt-30" style={{visibility:this.state.Result !== 'Missed' && this.state.callRecordingId !== 0 ? "" : "hidden"}}>
                                        <h5 className="text-500">Listen to your call</h5>
                                        <audio className="hide-volume-controls brad-10 d-block" ref="audioPlayer" id="audioPlayer" controls/>
                                    </div>
                                }

                                {this.state.callRecordingId !== 0 && this.state.isLoadingCallRecording === true &&

                                   <div className="col-xs-12 pt-30" style={{visibility:this.state.Result !== 'Missed' && this.state.callRecordingId !== 0 ? "" : "hidden"}}>
                                       <h5 className="text-500">Loading your call recording...</h5>
                                       <p><img src="/images/icons/loading.svg" alt="loading the recording" width="50" height="50" /></p>
                                   </div>
                               }

                                {this.state.hasVoicemail === true && isLoadingCallRecording === true && <div className="col-xs-12 pt-30">
                                    <p className="text-500">Listen to your voicemail</p>
                                    <p>Loading voicemail</p>
                                    </div>
                                }

                                {this.state.hasVoicemail === true && isLoadingCallRecording === false && <div className="col-xs-12 pt-30">
                                    <p className="text-500">Listen to your voicemail</p>
                                        <audio className="hide-volume-controls brad-10 d-block" ref="audioPlayer" controls />
                                    </div>
                                }

                                {this.state.callRecordingBreakdown !== undefined && this.state.callRecordingBreakdown !== null && this.state.callRecordingBreakdown.length > 1 ? <div className="row">
                                    <div className="text-start mb-30 ">
                                        <h5 className="text-500 mt-40 pt-30 bt-1">Call Transcription
                                        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
                                                <Popover>
                                                    <p className="mb-0">
                                                        This call transcription has been automatically generated.
                                                    </p>
                                                </Popover>
                                            }>
                                                <span className="icon icon-info2 text-xs ml-10 text-dark-light"></span>
                                            </OverlayTrigger>
                                        </h5>
                                    </div>
                                    {this.state.callRecordingBreakdown.length > 1 && this.state.callRecordingBreakdown.map((x, index) => {

                                        return <>
                                            <div
                                            // className="text-left d-block"
                                            className={`d-block mb-20 ${x.isCallInitiator ? `float-end` : 'float-start'} `}
                                            >
                                                {(index === 0 || (index !== 0 && x.isCallInitiator !== this.state.callRecordingBreakdown[index - 1].isCallInitiator)) &&
                                                <p className={`d-block text-sm mb-5 text-italic ${x.isCallInitiator ? `text-end` : 'text-start'} `}>
                                                    {x.isCallInitiator === true ? 'Caller' : 'Answerer'} at {x.textTimeSaid}
                                                </p>
                                                }
                                                <span className="brad-15 box-shadow pt-10 pb-10 pl-20 pr-20 text-400" style={{
                                                    backgroundColor: x.isCallInitiator ? '#eee' : '#0096FF',
                                                    color: x.isCallInitiator ? 'black' : 'white',
                                                    float: x.isCallInitiator ? 'right' : 'left',
                                                    padding:'10px',
                                                    maxWidth:'400px',
                                                    display:'inline-block',
                                                    cursor:'pointer'
                                                }}
                                                onClick={() => this.startAudioPlayerAtSpecificTime(Number(x.startTimeInSeconds))}>
                                                    {x.text}
                                                </span>
                                            </div>

                                        </>
                                    })}

                                </div>
                                :
                                <div className="row">

                                </div>

                                }
                            </div>
                        </div>

                    </div>
                </>
            );
        }
    }
    */
}

export default withRouter(MyCallsCallInformation)
