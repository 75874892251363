import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import CallbackRequest from '../../../components/CallbackRequest'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../../selectors'

class OpenCloseTimes extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>Open and close your phone lines automatically - Free Virtual Call Management System</title>
                <meta name="description" content="Open and close your phone lines whenever you want" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/open-close.jpg')" }} className="full-width full-hero-small">
                <h1>Open and close times</h1>
                <h2 className="subtitle text-center">Automatically open and close your business phone lines</h2>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto pb-30">
                            <p>
                                All businesses have their open and closing times. Besides locking your doors at the end of the day it makes sense to close your phone lines too and with the free "open &amp; close times" feature you can set your opening hours to suit.
                            </p>
                            <h4 className="pt-30 text-500">Set your open and close times</h4>
                            <p>
                                We provide you with few quick selection options. By default your SwitchboardFREE number open and close time is set to be &quot;Open 24/7 (All week)&quot;.
                            </p>
                            <figure className="pb-30 pt-30 mb-30 mt-30 text-center">
                                <img className="img-responsive b-1 pb-10 brad-20" src="/images/screenshots/open-close1.png" alt="open close settings screenshot" />
                                <figcaption className="text-xs pt-10">Screenshot of the Open &amp; Close settings</figcaption>
                            </figure>
                            <h4 className="pt-30 text-500">Bespoke opening hours</h4>
                            <p>
                                If your hours are different from the ones available for quick selection, you should select the “Bespoke Opening Hours”. This option will give you more flexibility with specifying your open-close times for each day of the week (see below screenshot).
                            </p>
                            <figure className="pb-30 pt-30 mb-30 mt-30 text-center">
                                <img className="img-responsive b-1 pb-10 brad-20" src="/images/screenshots/open-close2.png" alt="open close settings screenshot" />
                                <figcaption className="text-xs pt-10">Screenshot of the Bespoke Opening Hours settings</figcaption>
                            </figure>
                            <h4 className=" text-500">
                                Automatically close your lines during Bank Holiday
                            </h4>
                            <p>
                                Another great feature is where you can decide to open or close your lines automatically during bank holidays.
                            </p>
                            <figure className="pb-30 pt-30 mb-30 mt-30 text-center">
                                <img className="img-responsive b-1 pb-10 brad-20" src="/images/screenshots/open-close3.png" alt="open close settings screenshot" />
                                <figcaption className="text-xs pt-10">Screenshot of the Open &amp; Close Bank Holidays settings</figcaption>
                            </figure>
                            <h4 className="text-500">
                                Turn on your Voicemail
                            </h4>
                            <p>
                                It is strongly recommended to turn on your voicemail. This way when someone calls your SwitchboardFREE number when your lines are closed you give the caller the option to leave a message.
                            </p>
                            <figure className="pb-30 pt-30 mb-30 mt-30 text-center">
                                <img className="img-responsive b-1 pb-10 brad-20" src="/images/screenshots/open-close4.png" alt="voicemail settings screenshot" />
                                <figcaption className="text-xs pt-10">Screenshot of the Voicemail settings</figcaption>
                            </figure>
                            <h4 className=" text-500">
                                What happens when someone calls when my lines are closed?
                            </h4>
                            <p>
                                When someone calls your SwitchboardFREE number when your lines are closed they will hear your Out Of Hours message. For example, the caller might hear the following:
                            </p>
                            <div className="row">
                                <div className="col-md-6 mx-auto">
                                    <blockquote className="text-md bl-0 p-30 mt-30 mb-30 brad-10">
                                        <span className="icon icon-quotes-left text-cta pr-20 float-start"></span>
                                        <p className="text-start text-md"><i>Our offices are closed, could you please call us during our normal business hours, or to leave us a voicemail please press 1 on your phones keypad and record after the tone.</i></p>
                                    </blockquote>
                                </div>
                            </div>
                            <p>
                                If you caller presses 1 to leave you a message, you will receive an email with the audio recording.
                            </p>
                            <h4 className="pt-30 text-500">
                                Can I change the Out Of Hours message?
                            </h4>
                            <p>
                                Yes you can! Besides the out of hours message you can also change your introduction, voicemail message, department prompts, call screening (whisper) and even music on-hold.
                                You can do this for free via the <a href="app">SwitchboardFREE app</a>. Alternatively, you can use our professional voice artists or our in-house team for an additional fee.  {/* Click here to find out more. */}
                            </p>
                            <div className="ratio ratio-16x9 pt-30">
                                <iframe src="https://www.youtube.com/embed/W0l_hvSKMUI" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full-width bg-dull text-center p-30 mt-30 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <h4 className="text-400 pb-30">Do you have any more questions about open &amp; close times?</h4>
                                <h5 className="mb-30 pb-30">Call us on <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a>, email <a href="support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a>
                            or request a callback below. Our offices are open Monday to Friday 8:30am to 6pm.</h5>
                                <p></p>
                                <CallbackRequest subject="Callback request from Open Close page" isForm={true} />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(OpenCloseTimes)
