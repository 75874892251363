import React from 'react';
import { connect } from 'react-redux';

class SupportPhoneNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '0203 189 1213',
    };
  }

  static getPhoneNumber() {
    return '0203 189 1213';
  }

  render() {
    return <>{this.state.phoneNumber}</>;
  }

  componentWillMount() {}
}

export default connect(
  (state) => {
    return state;
  },
  {}
)(SupportPhoneNumber);
