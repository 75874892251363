export const fieldNames = {
  SIGNUP_EMAIL: 'signupEmail',
  SIGNUP_MOBILE: 'Mobile',
  SIGNUP_LANDLINE: 'Landline'
}

export const signupValidationErrors = {
  VALIDATE_SIGNUP_EMAIL_SYNTAX: 'Please check you have entered a valid email and try again',
  VALIDATE_SIGNUP_EMAIL_EMPTY: 'Please enter an email address',
  VALIDATE_SIGNUP_NAME_EMPTY: 'Please enter your name',
  VALIDATE_SIGNUP_NAME_SYNTAX: 'Your name is invalid',
  VALIDATE_SIGNUP_FIRSTNAME_EMPTY: 'Please enter your first name',
  VALIDATE_SIGNUP_FIRSTNAME_SYNTAX: 'First name is invalid',
  VALIDATE_SIGNUP_LASTNAME_EMPTY: 'Please enter your last name',
  VALIDATE_SIGNUP_LASTNAME_SYNTAX: 'Last name is invalid',
  VALIDATE_SIGNUP_MOBILE: 'Please enter a valid UK mobile number',
  VALIDATE_SIGNUP_LANDLINE: 'Please enter a valid UK landline number',
  VALIDATE_SIGNUP_PHONE: 'Please enter a valid telephone number',
  VALIDATE_IN_USE_EMAIL: 'This email is already in use, please sign in',
  VALIDATE_SIGNUP_PASSWORD_EMPTY: 'Please enter your password',
  VALIDATE_SIGNUP_PASSWORD_SYNTAX: 'Password is invalid',
  VALIDATE_SIGNUP_COMPANY_EMPTY: 'Please enter a company name',
  VALIDATE_SIGNUP_FIELD_EMPTY: 'This field cannot be empty',
  VALIDATE_SIGNUP_EMAIL: 'Please enter a valid email address',
  VALIDATE_SIGNUP_PHONE_EMPTY: 'Please enter your phone number'
}
