import React from 'react'
import { Modal } from 'react-bootstrap'
import Features from './Features/Features'

class PlansFeatures extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    showFeatures: false
  }

  componentDidUpdate (prevProps) {
    if (this.props.showFeaturesModal !== prevProps.showFeaturesModal) { this.setState({ showFeatures: this.props.showFeaturesModal }) }
  }

  render () {
    return <>
            <div className="col-xs-12 text-center mb-40 mb-xs-0">
                {!this.props.hideIntroText &&
                    <p className="w-100 text-sm text-dullest text-500 text-italic mt-20 mb-10 mt-xs-10 mb-xs-0">All plans include...</p>
                }
                <div className="row w-1200px center-block mb-xs-30">
                    <div className="col-md-1-5 col-sm-3 col-xs-6 d-none d-md-block ">
                        <p className="text-sm">
                            <span className="text-500">Multiple Departments</span><br />
                            <span className="text-sm">Set up-to 9 departments, "Press 1 for sales, 2 for support..."</span>
                        </p>
                    </div>
                    <div className="col-md-1-5 col-sm-3 col-xs-6 d-none d-md-block ">
                        <p className="text-sm">
                            <span className="text-500">Music On-Hold</span><br />
                            <span className="text-sm">Choose from a wide selection of music including our popular charts music.</span>
                        </p>
                    </div>
                    <div className="col-md-1-5 d-none d-md-block">
                        <p className="text-sm">
                            <span className="text-500">Voicemail to Email</span><br />
                            <span className="text-sm">
                                Instantly get an email or app alert when you receive a voicemail.
                                <span className="text-primary text-500 underline cursor-pointer pt-20 d-block" onClick={() => this.setState({ showFeatures: true })}>View 20+ more free features...</span>
                            </span>
                        </p>
                    </div>
                    <div className="col-md-1-5 col-sm-3 d-none d-md-block ">
                        <p className="text-sm">
                            <span className="text-500">Open &amp; Close Times</span><br />
                            <span className="text-sm">Instantly set open and close times to fit your business hours.</span>
                        </p>
                    </div>
                    <div className="col-md-1-5 col-sm-3 d-none d-md-block ">
                        <p className="text-sm">
                            <span className="text-500">Prompts &amp; Voices</span><br />
                            <span className="text-sm">Greet your customers with a recorded introduction or departmental prompt.</span>
                        </p>
                    </div>
                    <div className="col-xs-12 d-block d-md-none">
                        <p className="text-sm">
                            <span className="text-500">Over 40 Powerful Call Features</span><br />
                            <span className="text-sm">Including Open Close Times, Customised Prompts, Time-Of-Day Routing &amp; <span className="text-primary text-500 underline cursor-pointer" onClick={() => this.setState({ showFeatures: true })}>more</span></span>
                        </p>
                    </div>
                </div>
            </div>

            <Modal className='features-modal w-900px' show={this.state.showFeatures} onHide={e => this.setState({ showFeatures: false })}>
                <Modal.Header closeButton className='bb-0' />
                <Modal.Body className="text-center">
                    <h2 className="text-500 text-lg">20+ Powerful Smart Features</h2>
                    <p>Every plan includes these great call features, designed to help you stay in control &amp; make life easier.</p>
                    <Features
                    displayMain={true}
                    limit={4}
                    nobg={true}
                    isModal={true}
                    />
                </Modal.Body>
            </Modal>

        </>
  }
}

export default PlansFeatures
