import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { isLoggedIn } from '../selectors'
import { handleShowCallbackForm } from '../store/SBF'

class BTSwitchOffGuide extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>A quick guide to BT's landline switch off </title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>A quick guide to BT's landline switch off</h1>
                <h2 className=" subtitle">Discover how the death of the landline effects your business</h2>
            </header>
            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <p>BT's goal is to <a href="https://www.bbc.co.uk/news/technology-58233420" rel="nofollow noopener noreferrer" target="_blank">deactivate the UK's outdated analogue (PTSN) system</a> and replace it with a new and much-improved Internet-based (VoIP) system by 2025. This digital switchover is a crucial upgrade to the UK's entire telecommunications infrastructure and the overhaul has been in progress since 2017.  </p>
                            <p>VoIP has been around since 1995 and although the early systems were expensive and unstable, advancements in technology and the infrastructure of the internet mean that businesses and households in the UK will be able to benefit from the advantages that this new technology brings.  </p>
                            <p className="text-500 text-md mt-50">The key advantages of a VoIP system </p>
                            <ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    Lower running costs, from as little as &pound;5 per month.
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    <Link to="/features">Increased features</Link>, integrations and usability
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    Easily scalable for businesses
                                </li>
                                <li className="mb-10">
                                    <span className="icon icon-checkmark-circle text-success mr-10" />
                                    Total portability
                                </li>
                            </ul>

                            <p>I&apos;s important to consider that 2025 is merely the "cut-off" date and you&apos;re expected to migrate your phone systems over to VoIP well in advance of this date.  </p>
                            <p>BT will <a href="https://www.openreach.co.uk/cpportal/products/the-all-ip-programme/stopsell-updates" rel="nofollow noopener noreferrer" target="_blank">stop selling any new wholesale line rentals</a> on the old analogue system by September 2023 to further accelerate this transition. So, unless you've already migrated your phone system over to VoIP, you haven't got long left.   </p>
                            <p>Countries such as Japan, Sweden and Germany have already made the move as early as 2007, and we're in line to follow suit soon. </p>

                            <div className="mt-40 mb-30">
                                <div className="col bg-light b-1 brad-10 box-shadow-v3 p-30 clearfix">
                                    <span className="d-inline-block float-start text-darker col-12 col-md-5">
                                        <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                                            Experience the benefits of VoIP for your self
                                        </span>
                                        <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-10" style={{ lineHeight: '1em' }}>
                                            <span className="text-cta">Get started</span> with VoIP today
                                        </p>
                                    </span>

                                    <span className="d-inline-block float-end col-12 col-md-6">
                                        <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.handleSignup()}>Get started</Button>
                                        <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
                                    </span>
                                </div>
                            </div>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">How does this affect me? </h2>
                            <p>If you have a conventional landline for your business then you need to consider upgrading today. If you wait until the 2023 business deadline or even the 2025 cut-off date, you run the risk of being switched over automatically by your current provider. </p>
                            <p>This could tie you into a long or unnecessarily expensive contract, and this may not be the best option for you in terms of the level of service that your business will need.  </p>
                            <p>More importantly, these benefits are available to you right now.  </p>
                            <ul className="text-400">
                                <li>Lower costs </li>
                                <li>Increased accessibility </li>
                                <li>Complete portability </li>
                                <li>Higher scalability </li>
                                <li>Advanced features for small and large teams </li>
                                <li>Clearer voice quality </li>
                                <li>Supports multitasking </li>
                                <li>More flexibility with softphones </li>
                            </ul>
                            <p>If you&apos;re still using a standard landline or worse still, your mobile as your business number, then you&apos;re not only missing out on these benefits, you could also be missing sales and key gains in productivity.  </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">The big questions </h2>
                            <p className="text-500 text-md">VoIP systems are only for big business </p>
                            <p>The advantages may be different for individual use cases but the benefits of VoIP are for any size business, whether you&apos;re a sole trader or a large enterprise.  </p>

                            <p className="text-500 text-md mt-50">I'll lose customers if I have to get a new number </p>
                            <p>You can easily port your current business number to most VoIP providers. Porting takes around a week to move your number to a new provider and there may be a small admin charge.   </p>

                            <div className="mt-20 mb-30">
                                <div className="col bg-light b-1 brad-10 box-shadow-v3 p-30 clearfix">
                                    <span className="d-inline-block float-start text-darker col-12 col-md-5">
                                        <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                                            Transfer your number in as little as 7 days.
                                        </span>
                                        <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-10" style={{ lineHeight: '1em' }}>
                                            <span className="text-cta">Get started</span> with your transfer today
                                        </p>
                                    </span>

                                    <span className="d-inline-block float-end col-12 col-md-6">
                                        <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.handleSignup()}>Get started</Button>
                                        <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
                                    </span>
                                </div>
                            </div>

                            <p className="text-500 text-md mt-50">Isn't the call quality poor? </p>
                            <p>VoIP call quality used to be patchy at its inception but the tables have now turned. VoIP now offers crystal clear HD audio for the large majority of users. </p>

                            <p className="text-500 text-md mt-50">I&apos;s difficult to move my old phone system </p>
                            <p>Changing any technical system can be tricky for any business, but VoIP is cloud-based and as such requires very little change for most businesses.   </p>

                            <p className="text-500 text-md mt-50">What happens if my internet goes down? </p>
                            <p>Most VoIP systems utilise softphones and apps to make and receive calls also, so you can easily set up mobile phone apps for all of your staff. This means you'll be able to take and receive calls still using your business number even if you suffer from any internet or power outage.  </p>

                            <p className="text-500 text-md mt-50">I&apos;s too expensive </p>
                            <p>The primary reason businesses move to VoIP is for the initial cost savings so i&apos;s going to be a lot more cost-effective than your old system. In addition to the initial cost savings, being able to scale up and down without paying for unused systems and users gives you additional savings you may have not even factored in.   </p>

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">What should I do now?</h2>
                            <p>If you haven't already moved to a VoIP-based phone service then you need to think about upgrading your phone system now.  </p>
                            <p>Our dedicated switching team are on hand to answer your questions and advise on the most appropriate and cost-effective VoIP system for your business.  </p>

                            <div className="mt-50">
                                <div className="col bg-light brad-10 box-shadow-v3 p-30 b-1 clearfix">
                                    <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
                                        <span className="text-cta">Talk to us today</span> and get started
                                    </p>
                                    <h2 className="text-lg text-400 mb-0 mt-0 text-dark-light">
                                        Schedule a quick, no-obligation call &amp; discover...
                                    </h2>
                                    <ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            How you could reduce your phone costs
                                        </li>
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            How you can benefit from the advantages of VoIP
                                        </li>
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            Why you should move early before BT's deadline
                                        </li>
                                    </ul>

                                    <span className="d-inline-block">
                                        <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.props.handleShowCallbackForm()}>Get a callback</Button>
                                        <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="brad-10 bg-primary bg-gradient p-40 text-center pb-0 sticky-t-100">
                                <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                                    Discover the power of VoIP for your business
                                </span>
                                <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-20" style={{ lineHeight: '1em' }}>
                                    <span className="text-darker">Switch to VoIP</span> easily &amp; quickly with us
                                </p>
                                <p className="text-md text-400 mt-30 mb-0">
                                    From as little as &pound;5 per month.
                                </p>

                                <span className="d-inline-block col-12 mt-20 mb-50">
                                    <Button variant="cta" className="btn btn-lightest btn-outline p-15 pl-50 pr-50 text-md mb-xs-20" onClick={() => this.handleSignup()}>Get started</Button>
                                    <p className="text-sm mt-20">Or, call <a href="tel:02031891213" className="text-lightest">0203 189 1213</a></p>
                                </span>

                                <img src="/images/voip-feature-img.png" alt="VoIP Phone System" className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentDidMount () {

  }

  handleSignup () {
    localStorage.setItem('landingPage', true)
    this.props.history.push('/get-started')
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(BTSwitchOffGuide)
