import axios from 'axios'
import update from 'immutability-helper'
import { combineReducers } from 'redux'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { loadingMessages } from '../constants/ConfigurationConstants'
import { AutoTopupDefaultValue, PhoneTypes, siteFooter, siteHeader, validationMessages, validationStates } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import { checkNumberType } from '../helpers/utils'
import { isValidCardCode, isValidCardNumber, isValidEmailSyntax, isValidExpiryDate, isValidNameSyntax, isValidPostcode, isValidTelSyntax } from '../helpers/validationHelpers'

// ACTION CREATORS
export const requestConfig = createAction('REQUEST_CONFIG')
export const fetchMyNumbersRequest = createAction('MY_NUMBERS_FETCH_REQUEST')
export const redirectToLogin = createAction('REDIRECT_TO_LOGIN')
export const handleLoginReceived = createAction('HANDLE_LOGIN_RECEIVED')
export const updateUserFromLocalStorage = createAction('UPDATE_USER_FROM_LOCAL_STORAGE')

export const handleHidePaymentPostponedModal = createAction('HANDLE_HIDE_PAYMENT_POSTPONED_MODAL')
export const handleShowPaymentPostponedModal = createAction('HANDLE_SHOW_PAYMENT_POSTPONED_MODAL')
export const handleHidePaymentOptionsModal = createAction('HANDLE_HIDE_PAYMENT_OPTIONS_MODAL')
export const handleShowPaymentOptionsModal = createAction('HANDLE_SHOW_PAYMENT_OPTIONS_MODAL')
export const handleShowBillingWarning = createAction('HANDLE_SHOW_BILLING_WARNING')
export const handleHideBillingWarning = createAction('HANDLE_HIDE_BILLING_WARNING')
export const handleShowCallbackForm = createAction('HANDLE_SHOW_CALLBACK_FORM')
export const handleHideCallbackForm = createAction('HANDLE_HIDE_CALLBACK_FORM')
export const notifyOfFooterChange = createAction('NOTIFY_OF_FOOTER_CHANGE')
export const notifyOfHeaderChange = createAction('NOTIFY_OF_HEADER_CHANGE')
export const handleNotificationChange = createAction('HANDLE_NOTIFICATION_CHANGE')
export const handleResetNotification = createAction('HANDLE_RESET_NOTIFICATION')
export const handleUpdateCredit = createAction('HANDLE_UPDATE_CREDIT')
export const handleChangeHasAutoTopup = createAction('HANDLE_UPDATE_AUTO_TOPUP')
export const handleUpdateEnabledServices = createAction('HANDLE_UPDATE_MOBILE_CREDIT')
export const handleChangeHasAgreement = createAction('HANDLE_HAS_AGREEMENT_CHANGE')
export const handleAutoTopupAmountChange = createAction('HANDLE_AUTO_TOPUP_AMOUNT_CHANGE')
export const handleContactBookEnabledChange = createAction('HANDLE_CONTACT_BOOK_ENABLED_CHANGE')
export const handleVipCallersEnabledChange = createAction('HANDLE_VIP_CALLERS_ENABLED_CHANGE')
export const handleUmrEnabledChange = createAction('HANDLE_UMR_ENABLED_CHANGE')
export const handleCallFilteringEnabledChange = createAction('HANDLE_CALL_FILTERING_ENABLED_CHANGE')
export const handleCallBlockingEnabledChange = createAction('HANDLE_CALL_BLOCKING_ENABLED_CHANGE')
export const handleShowReelEnabledChange = createAction('HANDLE_SHOW_REEL_ENABLED_CHANGE')
export const handleChangeDisplayBillingWarning = createAction('HANDLE_CHANGE_DISPLAY_BILLING_WARNING')
export const handleChangeDisplayUmrUpsell = createAction('HANDLE_CHANGE_DISPLAY_UMR_UPSELL')
export const handleCallRecordingUnlimitedEnableChange = createAction('HANDLE_CALL_RECORDING_UNLIMITED_ENABLED_CHANGE')
export const handleCallRecordingAllEnableChange = createAction('HANDLE_CALL_RECORDING_ALL_ENABLED_CHANGE')
export const handleCallRecordingSingleEnableChange = createAction('HANDLE_CALL_RECORDING_SINGLE_ENABLED_CHANGE')
export const handleOutboundCallingEnableChange = createAction('HANDLE_OUTBOUND_CALLING_ENABLED_CHANGE')
export const handleTPSProtectionEnabledChange = createAction('HANDLE_TPS_PROTECTION_ENABLED_CHANGE')

const validationTelRequest = createAction('VALIDATE_TEL_REQUEST')
const validationTelReceived = createAction('VALIDATE_TEL_RECEIVED')
const validateEmailFieldSyntax = createAction('VALIDATE_EMAIL_FIELD_SYNTAX')
const validateTelFieldSyntax = createAction('VALIDATE_TEL_FIELD_SYNTAX')
const validateNameFieldSyntax = createAction('VALIDATE_NAME_FIELD_SYNTAX')
const validateMessageFieldSyntax = createAction('VALIDATE_MESSAGE_FIELD_SYNTAX')

export const handleNameChange = createAction('HANDLE_NAME_CHANGE')
export const handleEmailChange = createAction('HANDLE_EMAIL_CHANGE')
export const handleTelChange = createAction('HANDLE_TEL_CHANGE')
export const handleMessageChange = createAction('HANDLE_MESSAGE_CHANGE')

// Delivery data for physical items
export const handleDeliveryNameChange = createAction('HANDLE_DELIVERY_NAME_CHANGE')
export const handleDeliveryAdd1Change = createAction('HANDLE_DELIVERY_ADD1_CHANGE')
export const handleDeliveryAdd2Change = createAction('HANDLE_DELIVERY_ADD2_CHANGE')
export const handleDeliveryTownChange = createAction('HANDLE_DELIVERY_TOWN_CHANGE')
export const handleDeliveryCountyChange = createAction('HANDLE_DELIVERY_COUNTY_CHANGE')
export const handleDeliveryPcodeChange = createAction('HANLDE_DELIVERY_PCODE_CHANGE')
export const handleDeliveryNoteChange = createAction('HANDLE_DELIVERY_NOTE_CHANGE')

const validateDeliveryName = createAction('HANDLE_VALIDATE_DELIVERY_NAME')
const validateDeliveryAdd1 = createAction('HANDLE_VALIDATE_DELIVERY_ADD1')
const validateDeliveryTown = createAction('HANDLE_VALIDATE_DELIVERY_TOWN')
const validateDeliveryPcode = createAction('HANDLE_VALIDATE_DELIVERY_PCODE')

// Billing address data
export const handleBillingNameChange = createAction('HANDLE_BILLING_NAME_CHANGE')
export const handleBillingAdd1Change = createAction('HANDLE_BILLING_ADD1_CHANGE')
export const handleBillingAdd2Change = createAction('HANDLE_BILLING_ADD2_CHANGE')
export const handleBillingTownChange = createAction('HANDLE_BILLING_TOWN_CHANGE')
export const handleBillingCountyChange = createAction('HANDLE_BILLING_COUNTY_CHANGE')
export const handleBillingPcodeChange = createAction('HANDLE_BILLING_PCODE_CHANGE')
export const handleBillingCardNumberChange = createAction('HANDLE_CARD_NUMBER_CHANGE')
export const handleBillingCardExpiryChange = createAction('HANDLE_CARD_EXPIRY_CHANGE')
export const handleBillingCardCodeChange = createAction('HANDLE_CARD_CODE_CHANGE')

const validateBillingName = createAction('HANDLE_VALIDATE_BILLING_NAME')
const validateBillingAdd1 = createAction('HANDLE_VALIDATE_BILLING_ADD1')
const validateBillingTown = createAction('HANDLE_VALIDATE_BILLING_TOWN')
const validateBillingPcode = createAction('HANDLE_VALIDATE_BILLING_PCODE')
const validateBillingCardNumber = createAction('HANDLE_VALIDATE_CARD_NUMBER')
const validateBillingCardExpiry = createAction('HANDLE_VALIDATE_CARD_EXPIRY')
const validateBillingCardCode = createAction('HANDLE_VALIDATE_CARD_CODE')

export const validateEmail = context => async (dispatch) => {
  const email = context.newValue
  let emailValidationState
  let isValidEmail = false

  if (email.length === 0) {
    emailValidationState = validationStates.EMPTY
  } else {
    isValidEmail = isValidEmailSyntax(email)
    emailValidationState = validationStates.fromBool(isValidEmail)
  }
  dispatch(validateEmailFieldSyntax(emailValidationState))
}

export const validateTel = context => async (dispatch) => {
  const tel = context.newValue

  if (tel && tel.length > 0) {
    const isValidTel = isValidTelSyntax(tel)
    const validPhoneValidationState = validationStates.fromBool(isValidTel)

    if (!context.return) { dispatch(validateTelFieldSyntax(validPhoneValidationState)) }

    if (isValidTel) {
      dispatch(validationTelRequest())

      const url = 'api/Users/isValidTelephone'
      const numberType = checkNumberType(tel)

      axios.post(url, { phoneNumber: tel, landline: numberType === PhoneTypes.landline })
        .then(function (res) {
          dispatch(validationTelReceived(validationStates.fromBool(res.data)))
        })
        .catch(function (err) {
          console.error(err)
        })
    } else {
      dispatch(validateTelFieldSyntax(validationStates.INVALID))
    }
  } else {
    dispatch(validateTelFieldSyntax(context.allowEmpty ? validationStates.VALID : validationStates.EMPTY))
  }
}

export const validateName = context => async (dispatch) => {
  const name = context.newValue
  const field = context.field ? context.field.toLowerCase() : ''
  let validFieldState
  if (name.length > 1) {
    const isValid = isValidNameSyntax(name, field)
    validFieldState = validationStates.fromBool(isValid)
  } else {
    validFieldState = validationStates.EMPTY
  }
  dispatch(validateNameFieldSyntax(validFieldState))
}

export const validateMessage = context => async (dispatch) => {
  const message = context.newValue
  let validFieldState = validationStates.VALID
  if (message.length === 0) {
    validFieldState = validationStates.EMPTY
  }
  dispatch(validateMessageFieldSyntax(validFieldState))
}

// Delivery details
export const validateAddressDetails = context => async (dispatch) => {
  const val = context.newValue.trim()
  const field = context.fieldName
  const isBilling = context.isBilling
  let validFieldState

  if (val.length < 2) {
    validFieldState = validationStates.EMPTY

    switch (field) {
      case 'name':
        isBilling ? dispatch(validateBillingName(validFieldState)) : dispatch(validateDeliveryName(validFieldState))
        break
      case 'address':
        isBilling ? dispatch(validateBillingAdd1(validFieldState)) : dispatch(validateDeliveryAdd1(validFieldState))
        break
      case 'town':
        isBilling ? dispatch(validateBillingTown(validFieldState)) : dispatch(validateDeliveryTown(validFieldState))
        break
      case 'pcode':
        isBilling ? dispatch(validateBillingPcode(validFieldState)) : dispatch(validateDeliveryPcode(validFieldState))
        break
    }
  } else {
    switch (field) {
      case 'name':
        validFieldState = isValidNameSyntax(val)
        isBilling ? dispatch(validateBillingName(validationStates.fromBool(validFieldState))) : dispatch(validateDeliveryName(validationStates.fromBool(validFieldState)))
        break
      case 'address':
        validFieldState = validationStates.fromBool(true)
        isBilling ? dispatch(validateBillingAdd1(validationStates.fromBool(validFieldState))) : dispatch(validateDeliveryAdd1(validationStates.fromBool(validFieldState)))
        break
      case 'town':
        validFieldState = validationStates.fromBool(true)
        isBilling ? dispatch(validateBillingTown(validationStates.fromBool(validFieldState))) : dispatch(validateDeliveryTown(validationStates.fromBool(validFieldState)))
        break
      case 'pcode':
        validFieldState = isValidPostcode(val)
        isBilling ? dispatch(validateBillingPcode(validationStates.fromBool(validFieldState))) : dispatch(validateDeliveryPcode(validationStates.fromBool(validFieldState)))
        break
    }
  }
}

export const validateCardDetails = context => async (dispatch) => {
  const val = context.newValue
  const field = context.fieldName
  let validFieldState

  switch (field) {
    case 'number':
      validFieldState = isValidCardNumber(val)
      dispatch(validateBillingCardNumber(val.length < 2 ? validationStates.EMPTY : validationStates.fromBool(validFieldState)))
      break
    case 'expiry':
      validFieldState = isValidExpiryDate(val)
      dispatch(validateBillingCardExpiry(val.length < 2 ? validationStates.EMPTY : validationStates.fromBool(validFieldState)))
      break
    case 'code':
      validFieldState = isValidCardCode(val, context.cardType)
      dispatch(validateBillingCardCode(val.length < 2 ? validationStates.EMPTY : validationStates.fromBool(validFieldState)))
      break
  }
}

export const logout = context => async (dispatch) => {
  fetchHelper.postJson('/api/Users/notifyLogout')

  // Move data to session storage
  sessionStorage.setItem('user', localStorage.getItem('user'))
  sessionStorage.setItem('loggedout', new Date())

  localStorage.removeItem('user')
  localStorage.removeItem('userDetails')

  if (!localStorage.getItem('exitIntent')) {
    // window.exitIntent.showPopup();
  }

  fetchHelper.dispose()
  dispatch(redirectToLogin())
  if (context) {
    context.history.push('/')
  } else {
    window.location.href = '/'
  }
}

const loadingMessageReducer = handleActions({
  [requestConfig] () {
    return loadingMessages[requestConfig]
  },
  [fetchMyNumbersRequest] () {
    return loadingMessages[fetchMyNumbersRequest]
  },
  [redirectToLogin] () {
    return ''
  }
}, '')

const numberPurchaseReducer = handleActions({

}, { packages: {} })

const visibilityReducer = handleActions({
  [notifyOfFooterChange] (state, action) {
    return { ...state, FOOTER: action.payload }
  },
  [notifyOfHeaderChange] (state, action) {
    return { ...state, HEADER: action.payload }
  },
  [handleShowCallbackForm] (state, action) {
    return { ...state, showCallbackForm: true }
  },
  [handleHideCallbackForm] (state, action) {
    return { ...state, showCallbackForm: false }
  },
  [handleShowPaymentOptionsModal] (state, action) {
    return { ...state, showPaymentOptionsModal: true }
  },
  [handleHidePaymentOptionsModal] (state, action) {
    return { ...state, showPaymentOptionsModal: false }
  },
  [handleHidePaymentPostponedModal] (state, action) {
    return { ...state, showPaymentPostponedModal: false }
  },
  [handleShowPaymentPostponedModal] (state, action) {
    return { ...state, showPaymentPostponedModal: true, showPaymentOptionsModal: false }
  },
  [handleShowBillingWarning] (state, action) {
    return { ...state, showBillingWarning: true }
  },
  [handleHideBillingWarning] (state, action) {
    return { ...state, showBillingWarning: false }
  }
}, { FOOTER: siteFooter.SIMPLE, HEADER: siteHeader.SIMPLE, showCallbackForm: false, showBillingWarning: false, showPaymentOptionsModal: false, showPaymentPostponedModal: false })

const authReducer = handleActions({
  [handleLoginReceived] (state, action) {
    return { ...state, isAuthenticated: true, fName: action.payload.fName, sName: action.payload.sName, clientId: action.payload.clientId, email: action.payload.email }
  },
  [redirectToLogin] (state, action) {
    return { ...state, isAuthenticated: false, fName: '', sName: '', email: '', clientId: 0 }
  },
  [updateUserFromLocalStorage] (state, action) {
    const userInfo = action.payload
    return { ...state, isAuthenticated: true, fName: userInfo.fName, sName: userInfo.sName, clientId: userInfo.clientId, email: userInfo.email }
  }
}, { isAuthenticated: !!localStorage.getItem('user'), fName: '', email: '', clientId: 0 })

const notificationReducer = handleActions({
  [handleNotificationChange] (state, action) {
    return { ...state, message: action.payload.message, isErrorState: action.payload.isError || action.payload.removeBasket, isBasket: action.payload.isBasket, removeBasket: action.payload.removeBasket }
  },
  [handleResetNotification] (state, action) {
    return { ...state, message: '', isErrorState: false, isBasket: false }
  }
}, { message: '', isErrorState: false, isBasket: false })

const enabledServicesReducer = handleActions({
  [handleUpdateCredit] (state, action) {
    return { ...state, mobileCredit: action.payload }
  },
  [handleChangeHasAutoTopup] (state, action) {
    return { ...state, autoTopup: action.payload }
  },
  [handleUpdateEnabledServices] (state, action) {
    const payload = action.payload
    return {
      ...state,
      mobileCredit: payload.mobileCredit,
      hasAgreement: payload.hasAgreement,
      autoTopup: payload.hasAutoTopup,
      autoTopupAmount: payload.autoTopupAmount,
      hasCallBlocking: payload.hasCallBlocking,
      hasContactBook: payload.hasContactBook,
      hasUmr: payload.hasUmr,
      displayUmrUpsell: payload.displayUmrUpsell,
      displayBillingWarning: payload.displayBillingWarning,
      hasShowReel: payload.hasShowReel,
      hasCallRecordingAll: payload.hasCallRecordingAll,
      hasUnlimitedCallRecording: payload.hasUnlimitedCallRecording,
      hasCallRecordingSingle: payload.hasCallRecordingSingle,
      hasOutboundCalling: payload.hasOutboundCalling,
      outboundCallingPackage: payload.outboundCallingPackage,
      isInOutboundBeta: payload.isInOutboundBeta,
      hasVipCallerFeature: payload.hasVipCallerFeature,
      hasNewCallBlockingFeature: payload.hasNewCallBlockingFeature,
      hasOutboundCallRecording: payload.hasOutboundCallRecording,
      displayOutstandingInvoiceWarning: payload.displayOutstandingInvoiceWarning,
      hasEmergencyContacts: payload.hasEmergencyContacts,
      displayUnfinishedSignupWarning: payload.displayUnfinishedSignupWarning,
      signupSummary: payload.signupSummary,
      hasCallTranscription: payload.hasCallTranscription,
      hasTPSProtection: payload.hasTPSProtection
    }
  },
  [handleChangeHasAgreement] (state, action) {
    return { ...state, hasAgreement: action.payload }
  },
  [handleAutoTopupAmountChange] (state, action) {
    return { ...state, autoTopupAmount: { value: action.payload.value, label: action.payload.label } }
  },
  [handleContactBookEnabledChange] (state, action) {
    return { ...state, hasContactBook: action.payload }
  },
  [handleVipCallersEnabledChange] (state, action) {
    return { ...state, hasVipCallerFeature: action.payload }
  },
  [handleCallBlockingEnabledChange] (state, action) {
    return { ...state, hasNewCallBlockingFeature: action.payload }
  },
  [handleCallFilteringEnabledChange] (state, action) {
    return { ...state, hasCallBlocking: action.payload }
  },
  [handleUmrEnabledChange] (state, action) {
    return { ...state, hasUmr: action.payload }
  },
  [handleChangeDisplayUmrUpsell] (state, action) {
    return { ...state, displayUmrUpsell: action.payload }
  },
  [handleChangeDisplayBillingWarning] (state, action) {
    return { ...state, displayBillingWarning: action.payload }
  },
  [handleShowReelEnabledChange] (state, action) {
    return { ...state, hasShowReel: action.payload }
  },
  [handleCallRecordingSingleEnableChange] (state, action) {
    return { ...state, hasCallRecordingSingle: action.payload }
  },
  [handleCallRecordingAllEnableChange] (state, action) {
    return { ...state, hasCallRecordingAll: action.payload }
  },
  [handleCallRecordingUnlimitedEnableChange] (state, action) {
    return { ...state, hasUnlimitedCallRecording: action.payload }
  },
  [handleOutboundCallingEnableChange] (state, action) {
    return { ...state, hasOutboundCalling: action.payload }
  },
  [handleTPSProtectionEnabledChange] (state, action) {
    return { ...state, hasTPSProtection: action.payload }
  }
}, {
  hasUmr: false,
  mobileCredit: 0,
  autoTopup: false,
  hasAgreement: false,
  autoTopupAmount: AutoTopupDefaultValue,
  hasCallBlocking: false,
  hasContactBook: false,
  displayBillingWarning: false,
  displayUmrUpsell: false,
  hasShowReel: false,
  hasCallRecordingAll: false,
  hasUnlimitedCallRecording: false,
  hasCallRecordingSingle: false,
  hasOutboundCalling: false,
  outboundCallingPackage: false,
  isInOutboundBeta: false,
  hasVipCallerFeature: false,
  hasNewCallBlockingFeature: false,
  hasOutboundCallRecording: false,
  displayUnfinishedSignupWarning: false,
  hasTPSProtection: false
})

const validationReducer = handleActions({
  [validateEmailFieldSyntax] (state, action) {
    return update(state, {
      emailSyntax: { $set: { valid: action.payload } }
    })
  },
  [validateTelFieldSyntax] (state, action) {
    return update(state, {
      telSyntax: { $set: { valid: action.payload } }
    })
  },
  [validationTelReceived] (state, action) {
    return update(state, {
      telFull: { $set: { valid: action.payload } }
    })
  },
  [validateNameFieldSyntax] (state, action) {
    return update(state, {
      nameSyntax: { $set: { valid: action.payload } }
    })
  },
  [validateMessageFieldSyntax] (state, action) {
    return update(state, {
      messageSyntax: { $set: { valid: action.payload } }
    })
  },
  [handleNameChange] (state, action) {
    return update(state, {
      nameSyntax: { $set: { valid: validationStates.UNCHECKED } }
    })
  },
  [handleTelChange] (state, action) {
    return update(state, {
      telSyntax: { $set: { valid: validationStates.UNCHECKED } },
      telFull: { $set: { valid: validationStates.UNCHECKED } }
    })
  },
  [handleEmailChange] (state, action) {
    return update(state, {
      emailSyntax: { $set: { valid: validationStates.UNCHECKED } }
    })
  },
  [handleMessageChange] (state, action) {
    return update(state, {
      messageSyntax: { $set: { valid: validationStates.UNCHECKED } }
    })
  }
}, {
  emailSyntax: { valid: validationStates.UNCHECKED },
  telSyntax: { valid: validationStates.UNCHECKED },
  nameSyntax: { valid: validationStates.UNCHECKED },
  messageSyntax: { valid: validationStates.UNCHECKED },
  telFull: { valid: validationStates.UNCHECKED }
})

const deliveryReducer = handleActions({
  [handleDeliveryNameChange] (state, action) {
    return update(state, { deliveryName: { $set: { value: action.payload.newValue } } })
  },
  [handleDeliveryAdd1Change] (state, action) {
    return update(state, { deliveryAdd1: { $set: { value: action.payload.newValue } } })
  },
  [handleDeliveryAdd2Change] (state, action) {
    return update(state, { deliveryAdd2: { $set: { value: action.payload.newValue } } })
  },
  [handleDeliveryTownChange] (state, action) {
    return update(state, { deliveryTown: { $set: { value: action.payload.newValue } } })
  },
  [handleDeliveryCountyChange] (state, action) {
    return update(state, { deliveryCounty: { $set: { value: action.payload.newValue } } })
  },
  [handleDeliveryPcodeChange] (state, action) {
    return update(state, { deliveryPcode: { $set: { value: action.payload.newValue } } })
  },
  [handleDeliveryNoteChange] (state, action) {
    return update(state, { deliveryNote: { $set: { value: action.payload.newValue } } })
  },
  // Validation
  [validateDeliveryName] (state, action) {
    return update(state, { deliveryName: { $merge: { ...state.deliveryName, valid: action.payload } } })
  },
  [validateDeliveryAdd1] (state, action) {
    return update(state, { deliveryAdd1: { $merge: { ...state.deliveryAdd1, valid: action.payload } } })
  },
  [validateDeliveryTown] (state, action) {
    return update(state, { deliveryTown: { $merge: { ...state.deliveryTown, valid: action.payload } } })
  },
  [validateDeliveryPcode] (state, action) {
    return update(state, { deliveryPcode: { $merge: { ...state.deliveryPcode, valid: action.payload } } })
  }
}, {})

const billingReducer = handleActions({
  [handleBillingNameChange] (state, action) {
    return update(state, { billingName: { $merge: { ...state.billingName, value: action.payload.newValue } } })
  },
  [handleBillingCardNumberChange] (state, action) {
    return update(state, { billingCardNumber: { $merge: { ...state.billingCardNumber, value: action.payload.newValue } } })
  },
  [handleBillingCardExpiryChange] (state, action) {
    return update(state, { billingCardExpiry: { $merge: { ...state.billingCardExpiry, value: action.payload.newValue } } })
  },
  [handleBillingCardCodeChange] (state, action) {
    return update(state, { billingCardCode: { $merge: { ...state.billingCardCode, value: action.payload.newValue } } })
  },
  [handleBillingAdd1Change] (state, action) {
    return update(state, { billingAdd1: { $merge: { ...state.billingAdd1, value: action.payload.newValue } } })
  },
  [handleBillingAdd2Change] (state, action) {
    return update(state, { billingAdd2: { $set: { value: action.payload.newValue } } })
  },
  [handleBillingTownChange] (state, action) {
    return update(state, { billingTown: { $merge: { ...state.billingTown, value: action.payload.newValue } } })
  },
  [handleBillingCountyChange] (state, action) {
    return update(state, { billingCounty: { $set: { value: action.payload.newValue } } })
  },
  [handleBillingPcodeChange] (state, action) {
    return update(state, { billingPcode: { $merge: { ...state.billingPcode, value: action.payload.newValue } } })
  },

  // Validation
  [validateBillingName] (state, action) {
    return update(state, { billingName: { $merge: { ...state.billingName, valid: action.payload } } })
  },
  [validateBillingAdd1] (state, action) {
    return update(state, { billingAdd1: { $merge: { ...state.billingAdd1, valid: action.payload } } })
  },
  [validateBillingTown] (state, action) {
    return update(state, { billingTown: { $merge: { ...state.billingTown, valid: action.payload } } })
  },
  [validateBillingPcode] (state, action) {
    return update(state, { billingPcode: { $merge: { ...state.billingPcde, valid: action.payload } } })
  },
  [validateBillingCardNumber] (state, action) {
    return update(state, { billingCardNumber: { $merge: { ...state.billingCardNumber, valid: action.payload } } })
  },
  [validateBillingCardExpiry] (state, action) {
    return update(state, { billingCardExpiry: { $merge: { ...state.billingCardExpiry, valid: action.payload } } })
  },
  [validateBillingCardCode] (state, action) {
    return update(state, { billingCardCode: { $merge: { ...state.billingCardCode, valid: action.payload } } })
  }
}, {
  billingName: { valid: validationStates.UNCHECKED },
  billingAdd1: { valid: validationStates.UNCHECKED },
  billingTown: { valid: validationStates.UNCHECKED },
  billingPcode: { valid: validationStates.UNCHECKED },
  billingCardNumber: { valid: validationStates.UNCHECKED },
  billingCardExpiry: { valid: validationStates.UNCHECKED },
  billingCardCode: { valid: validationStates.UNCHECKED }
})

// REDUCERS
export const reducer = combineReducers({
  loadingMessage: loadingMessageReducer,
  visibility: visibilityReducer,
  authentication: authReducer,
  notification: notificationReducer,
  enabledServices: enabledServicesReducer,
  validation: validationReducer,
  deliveryData: deliveryReducer,
  billingData: billingReducer
})

// SELECTORS
const emailRegexValidationState = state => state.sbfApp.validation.emailSyntax.valid
const telRegexValidationState = state => state.sbfApp.validation.telSyntax.valid
const nameRegexValidationState = state => state.sbfApp.validation.nameSyntax.valid
const messageEmptyValidationState = state => state.sbfApp.validation.messageSyntax.valid
const telFullValidationState = state => state.sbfApp.validation.telFull.valid

// Delivery address
const deliveryNameValidationState = state => state.sbfApp.deliveryData.deliveryName.valid
const deliveryAdd1ValidationState = state => state.sbfApp.deliveryData.deliveryAdd1.valid
const deliveryTownValidationState = state => state.sbfApp.deliveryData.deliveryTown.valid
const deliveryPcodeValidationState = state => state.sbfApp.deliveryData.deliveryPcode.valid

// Billing Address
const billingNameValidationState = state => state.sbfApp.billingData.billingName.valid
const billingAdd1ValidationState = state => state.sbfApp.billingData.billingAdd1.valid
const billingTownValidationState = state => state.sbfApp.billingData.billingTown.valid
const billingPcodeValidationState = state => state.sbfApp.billingData.billingPcode.valid
const billingCardNumberValidationState = state => state.sbfApp.billingData.billingCardNumber.valid
const billingCardExpiryValidationState = state => state.sbfApp.billingData.billingCardExpiry.valid
const billingCardCodeValidationState = state => state.sbfApp.billingData.billingCardCode.valid

export const getDeliveryNameValidationState = createSelector(
  [deliveryNameValidationState],
  (nameValidationState) => {
    if (nameValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your name' }
    }

    if (nameValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: 'Please enter a real name' }
    }

    return nameValidationState
  }
)

export const getDeliveryAdd1ValidationState = createSelector(
  [deliveryAdd1ValidationState],
  (add1ValidationState) => {
    if (add1ValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your address' }
    }

    return add1ValidationState
  }
)

export const getDeliveryTownValidationState = createSelector(
  [deliveryTownValidationState],
  (townValidationState) => {
    if (townValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter a Town/City' }
    }

    return townValidationState
  }
)

export const getDeliveryPcodeValidationState = createSelector(
  [deliveryPcodeValidationState],
  (pcodeValidationState) => {
    if (pcodeValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your postcode' }
    }

    if (pcodeValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: 'Please enter a valid postcode' }
    }

    return pcodeValidationState
  }
)

export const getBillingNameValidationState = createSelector(
  [billingNameValidationState],
  (nameValidationState) => {
    if (nameValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your name' }
    }

    if (nameValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: 'Please enter a real name' }
    }

    return nameValidationState
  }
)

export const getBillingAdd1ValidationState = createSelector(
  [billingAdd1ValidationState],
  (add1ValidationState) => {
    if (add1ValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your billing address' }
    }

    return add1ValidationState
  }
)

export const getBillingTownValidationState = createSelector(
  [billingTownValidationState],
  (townValidationState) => {
    if (townValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your town' }
    }

    return townValidationState
  }
)

export const getBillingPcodeValidationState = createSelector(
  [billingPcodeValidationState],
  (pcodeValidationState) => {
    if (pcodeValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your postcode' }
    }

    if (pcodeValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: 'Please enter a valid postcode' }
    }

    return pcodeValidationState
  }
)

export const getBillingCardNumberValidationState = createSelector(
  [billingCardNumberValidationState],
  (cardNumberValidationState) => {
    if (cardNumberValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your card number' }
    }

    if (cardNumberValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: 'Please enter a valid card number' }
    }

    return cardNumberValidationState
  }
)

export const getBillingCardExpiryValidationState = createSelector(
  [billingCardExpiryValidationState],
  (cardExpiryValidationState) => {
    if (cardExpiryValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your expiry date' }
    }

    if (cardExpiryValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: 'Please enter a valid expiry date' }
    }

    return cardExpiryValidationState
  }
)

export const getBillingCardCodeValidationStates = createSelector(
  [billingCardCodeValidationState],
  (cardCodeValidationState) => {
    if (cardCodeValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter your CVV' }
    }

    if (cardCodeValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: 'Please enter a valid CVV' }
    }

    return cardCodeValidationState
  }
)

export const getNameRegexValidationState = createSelector(
  [nameRegexValidationState],
  (nameValidationState) => {
    if (nameValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: validationMessages.EMPTY_FIELD }
    }
    if (nameValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: validationMessages.INVALID_NAME }
    }
    return nameValidationState
  }
)

export const getTelValidationState = createSelector(
  [telRegexValidationState, telFullValidationState],
  (regexValidationState, fullValidationState) => {
    if (regexValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: validationMessages.EMPTY_FIELD }
    } else if (regexValidationState === validationStates.INVALID || fullValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: validationMessages.INVALID_TEL }
    } else {
      return { valid: regexValidationState }
    }
  }
)

export const getTelRegexValidationState = createSelector(
  [telRegexValidationState],
  (telValidationState) => {
    if (telValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: validationMessages.EMPTY_FIELD }
    }
    if (telValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: validationMessages.INVALID_TEL }
    }
    return telValidationState
  }
)

export const getEmailRegexValidationState = createSelector(
  [emailRegexValidationState],
  (emailValidationState) => {
    if (emailValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: validationMessages.EMPTY_FIELD }
    }
    if (emailValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: validationMessages.INVALID_EMAIL }
    }
    return emailValidationState
  }
)

export const getMessageValidationState = createSelector(
  [messageEmptyValidationState],
  (messageValidationState) => {
    if (messageValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: validationMessages.EMPTY_FIELD }
    }
    return messageValidationState
  }
)

export const getFirstName = state => {
  return state.sbfApp.authentication.fName !== undefined ? state.sbfApp.authentication.fName : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).fName : ''
}

export const getMobileCredit = state => {
  return state.sbfApp.enabledServices.mobileCredit
}

export const getHasAgreement = state => {
  return state.sbfApp.enabledServices.hasAgreement
}

export const getHasAutoTopup = state => {
  return state.sbfApp.enabledServices.autoTopup
}

export const getAutoTopupAmount = state => {
  return state.sbfApp.enabledServices.autoTopupAmount
}
