import axios from 'axios'
import update from 'immutability-helper'
import { combineReducers } from 'redux'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { BasketItemType, PhoneTypes, validationStates } from '../constants/Constants'
import { signupValidationErrors } from '../constants/SignupConstants'
import { checkNumberType } from '../helpers/utils'
import { isValidEmailSyntax, isValidLandlineSyntax, isValidMobSyntax, isValidNameSyntax, isValidTelSyntax } from '../helpers/validationHelpers'

const validateSignupName = createAction('VALIDATE_SIGNUP_NAME')
const validateSignupFirstName = createAction('VALIDATE_SIGNUP_FIRST_NAME')
const validateSignupLastName = createAction('VALIDATE_SIGNUP_LAST_NAME')
const validateSignupEmailSyntax = createAction('VALIDATE_SIGNUP_EMAIL_SYNTAX')
const validateSignupCompanyName = createAction('VALIDATE_SIGNUP_COMPANY_NAME')
const validateSignupPortSyntax = createAction('VALIDATE_SIGNUP_PORT_SYNTAX')
const validationSignupEmailNotInUseRequest = createAction('VALIDATE_SIGNUP_EMAIL_NOT_USED_REQUEST')
const validationSignupEmailNotInUseReceived = createAction('VALIDATE_SIGNUP_EMAIL_NOT_USED_RECEIVED')
const validationSignupEmailInUse = createAction('MARK_SIGNUP_EMAIL_IN_USE')
const validationSignupMobileReceived = createAction('ACTIVATE_SIGNUP_MOBILE_RECEIVED')
const validationSignupLandlineRequest = createAction('ACTIVATE_SIGNUP_LANDLINE_REQUEST')
const validationSignupLandlineReceived = createAction('ACTIVATE_SIGNUP_LANDLINE_RECEVIED')
const validationSignupPortRequest = createAction('ACTIVATE_SIGNUP_PORT_REQUEST')
const validationSignupPortReceived = createAction('ACTIVATE_SIGNUP_PORT_RECEVIED')

export const handleSignupEmailChange = createAction('HANDLE_SIGNUP_EMAIL_CHANGE')
export const handleSignupNameChange = createAction('HANDLE_SIGNUP_NAME_CHANGE')
export const handleSignupFirstNameChange = createAction('HANDLE_SIGNUP_FIRST_NAME_CHANGE')
export const handleSignupLastNameChange = createAction('HANDLE_SIGNUP_LAST_NAME_CHANGE')
export const handleSignupCompanyChange = createAction('HANDLE_SIGNUP_COMPANY_CHANGE')
export const handleSignupMobileChange = createAction('HANDLE_SIGNUP_MOBILE_CHANGE')
export const handleSignupLandlineChange = createAction('HANDLE_SIGNUP_LANDLINE_CHANGE')
export const handleSignupPasswordChange = createAction('HANDLE_SIGNUP_PASSWORD_CHANGE')
export const handleUserWantsStep2 = createAction('HANDLE_USER_WANTS_STEP_2')
export const notifyStep2Reached = createAction('SIGN_STAGE_2_REACHED')
export const handleSignupPortChange = createAction('HANDLE_SIGNUP_PORT_CHANGE')

export const handleSetSelectedDdi = createAction('SET_SELECTED_SIGNUP_DDI')
export const handleSetSelectedArea = createAction('SET_SELECTED_AREA')
export const handleAddToBasket = createAction('ADD_ITEM_TO_BASKET')
export const handleRemoveFromBasket = createAction('REMOVE_ITEM_FROM_BASKET')
export const handleBasketInit = createAction('INITIALISE_BASKET')
export const handleBasketClear = createAction('CLEAR_SAVED_BASKET')

const validationSignupPhoneRequest = createAction('ACTIVATE_SIGNUP_PHONE_REQUEST')
const validationSignupPhoneReceived = createAction('ACTIVATE_SIGNUP_PHONE_RECEIVED')
const validateSignupPhoneSyntax = createAction('VALIDATE_SIGNUP_PHONE_SYNTAX')
const validateSignupLandlineSyntax = createAction('VALIDATE_SIGNUP_LANDLINE_SYNTAX')
const validateSignupMobileSyntax = createAction('VALIDATE_SIGNUP_MOBILE_SYNTAX')
const validationEmailReceived = createAction('ACTIVATE_EMAIL_VALIDATION_RECEIVED')
const validationEmailRequest = createAction('ACTIVATE_EMAIL_VALIDATION_REQUEST')

export const handleMarketingOptInChange = createAction('HANDLE_OPTIN_MARKETING_CHANGE')
export const handleTermsOptInChange = createAction('HANDLE_ACCEPT_TERMS_CHANGE')
export const handleSignupPhoneChange = createAction('HANDLE_SIGNUP_PHONE_CHANGE')

export const validatePhone = context => async (dispatch) => {
  const phone = context.newValue

  if (phone && phone.length > 0) {
    let isValidPhone = isValidTelSyntax(phone)
    if (context.type && context.type.toLowerCase() === 'mobile') {
      isValidPhone = isValidMobSyntax(phone)
    } else if (context.type && context.type.toLowerCase() === 'landline') {
      isValidPhone = isValidLandlineSyntax(phone)
    }

    if (isValidPhone) {
      dispatch(validationSignupPhoneRequest())

      const url = 'api/Users/isValidTelephone'
      const numberType = checkNumberType(phone)

      axios.post(url, { phoneNumber: phone, landline: numberType === PhoneTypes.landline })
        .then(function (res) {
          if (context.type && context.type.toLowerCase() === 'landline') {
            return dispatch(validationSignupLandlineReceived(validationStates.fromBool(res.data)))
          } else if (context.type && context.type.toLowerCase() === 'mobile') {
            return dispatch(validationSignupMobileReceived(validationStates.fromBool(res.data)))
          } else {
            return dispatch(validationSignupPhoneReceived(validationStates.fromBool(res.data)))
          }
        })
        .catch(function (error) {
          console.error(error)
        })
    } else {
      return dispatch(validationSignupPhoneReceived(validationStates.fromBool(false)))
      if (context.type && context.type.toLowerCase() === 'landline') {
        dispatch(validateSignupLandlineSyntax(validationStates.INVALID))
      } else if (context.type && context.type.toLowerCase() === 'mobile') {
        dispatch(validateSignupMobileSyntax(validationStates.INVALID))
      } else {
        dispatch(validateSignupPhoneSyntax(validationStates.INVALID))
      }
    }
  } else {
    if (context.type && context.type.toLowerCase() === 'landline') {
      dispatch(validateSignupLandlineSyntax(context.allowEmpty ? validationStates.VALID : validationStates.EMPTY))
    } else if (context.type && context.type.toLowerCase() === 'mobile') {
      dispatch(validateSignupMobileSyntax(context.allowEmpty ? validationStates.VALID : validationStates.EMPTY))
    } else {
      dispatch(validateSignupPhoneSyntax(context.allowEmpty ? validationStates.VALID : validationStates.EMPTY))
    }
  }
}

export const validateSignupEmail = context => async (dispatch) => {
  const email = context.newValue
  let validEmailValidationState
  let isValidEmail = false
  if (email.length === 0) {
    validEmailValidationState = validationStates.EMPTY
  } else {
    isValidEmail = isValidEmailSyntax(email)
    validEmailValidationState = validationStates.fromBool(isValidEmail)
  }
  dispatch(validateSignupEmailSyntax(validEmailValidationState))

  if (context.loggedIn !== true) {
    if (isValidEmail && email) {
      dispatch(validationSignupEmailNotInUseRequest())

      const url = 'api/Users/DoesSignupEmailAlreadyExists/'

      axios.post(url, { signupEmail: email })
        .then(function (response) {
          dispatch(validationSignupEmailNotInUseReceived(validationStates.fromBool(!response.data)))
        })
        .catch(function (error) {
          console.error(error)
        })
    }
  }
}

export const validateLandline = context => async (dispatch) => {
  const landline = context.newValue
  if (landline.length > 0) {
    const isValidLandline = isValidLandlineSyntax(landline)
    const validLandlineValidationState = validationStates.fromBool(isValidLandline)
    if (context.type === 'port') {
      dispatch(validateSignupPortSyntax(validLandlineValidationState))
    } else {
      dispatch(validateSignupLandlineSyntax(validLandlineValidationState))
    }

    if (isValidLandline) {
      if (context.type === 'port') {
        dispatch(validationSignupPortRequest())
      } else {
        dispatch(validationSignupLandlineRequest())
      }
      const url = 'api/Users/isValidTelephone/'

      axios.post(url, { phoneNumber: landline, landline: true, isPortNumber: context.type === 'port' })
        .then(function (response) {
          if (context.type === 'port') {
            dispatch(validationSignupPortReceived(validationStates.fromBool(response.data)))
          } else {
            dispatch(validationSignupLandlineReceived(validationStates.fromBool(response.data)))
          }
        })
        .catch(function (error) {
          console.error(error)
        })
    }
  } else {
    if (context.type === 'port') {
      dispatch(validateSignupPortSyntax(validationStates.EMPTY))
    }

    dispatch(validateSignupLandlineSyntax(validationStates.EMPTY))
  }
}

export const validateNameField = context => async (dispatch) => {
  const string = context.newValue
  let validFieldState

  if (string.length > 1) {
    const isValid = string.length > 0 && isValidNameSyntax(string)
    validFieldState = validationStates.fromBool(isValid)
  } else {
    validFieldState = validationStates.EMPTY
  }
  if (context.firstName === true) {
    dispatch(validateSignupFirstName(validFieldState))
  } else if (context.lastName === true) {
    dispatch(validateSignupLastName(validFieldState))
  } else {
    dispatch(validateSignupName(validFieldState))
  }
}

export const validateEmptyField = context => async (dispatch) => {
  const string = context.newValue
  switch (context.field) {
    default:
      return dispatch(validateSignupCompanyName(string.length > 0 ? validationStates.VALID : validationStates.EMPTY))
  }
}

export const validateCompanyName = context => async (dispatch) => {
  const company = context.newValue
  const isValid = company.length <= 32
  const companyValidationState = validationStates.fromBool(isValid)
  dispatch(validateSignupCompanyName(companyValidationState))
}

export const handleValidateSignupForm = context => async (dispatch) => {
  // signup.validation
}

const signupFormReducer = handleActions({
  [handleSignupNameChange] (state, action) {
    return update(state, { signupName: { $set: action.payload.newValue } })
  },
  [handleSignupFirstNameChange] (state, action) {
    return update(state, { signupFirstName: { $set: action.payload.newValue } })
  },
  [handleSignupLastNameChange] (state, action) {
    return update(state, { signupLastName: { $set: action.payload.newValue } })
  },
  [handleSignupEmailChange] (state, action) {
    return update(state, { signupEmail: { $set: action.payload.newValue } })
  },
  [handleSignupPhoneChange] (state, action) {
    let ud = localStorage.getItem('userDetails') // user details
    if (ud) {
      ud = JSON.parse(ud)
      localStorage.setItem('userDetails', JSON.stringify({ ...ud, phone: action.payload.newValue }))
    }

    if (action.payload.type && action.payload.type.toLowerCase() === 'mobile') {
      return update(state, { signupMobile: { $set: action.payload.newValue } })
    } else if (action.payload.type && action.payload.type.toLowerCase() === 'landline') {
      return update(state, { signupLandline: { $set: action.payload.newValue } })
    } else {
      return update(state, { signupPhone: { $set: action.payload.newValue } })
    }
  },
  [handleSignupCompanyChange] (state, action) {
    let ud = localStorage.getItem('userDetails') // user details
    if (ud) {
      ud = JSON.parse(ud)
      localStorage.setItem('userDetails', JSON.stringify({ ...ud, company: action.payload.newValue }))
    }
    return update(state, { signupCompanyName: { $set: action.payload.newValue } })
  },
  [handleSignupPasswordChange] (state, action) {
    return update(state, { signupPassword: { $set: action.payload.newValue } })
  },

  [handleMarketingOptInChange] (state, action) {
    return update(state, { marketingOptin: { $set: action.payload.newValue } })
  },
  [handleTermsOptInChange] (state, action) {
    return update(state, { acceptTerms: { $set: action.payload.newValue } })
  },
  [handleSignupPortChange] (state, action) {
    return update(state, { signupPort: { $set: action.payload.newValue } })
  },
  [handleSetSelectedArea] (state, action) {
    localStorage.setItem('ddiLid', action.payload)
    return update(state, { ddiLid: { $set: action.payload } })
  }
}, {})

const packageReducer = handleActions({
  [handleSetSelectedDdi] (state, action) {
    if (action.payload && action.payload.number) {
      localStorage.setItem('selectedDdi', JSON.stringify(action.payload))
      if (action.payload.number.startsWith('084')) {
        localStorage.removeItem('selectedInboundPackage')
        localStorage.removeItem('selectedInboundIndex')
      }
      return update(state, { selectedDdi: { $set: action.payload } })
    }
  },
  [handleBasketClear] (state, action) {
    localStorage.removeItem('basket')
    return { ...state, currentBasket: {} }
  },
  [handleBasketInit] (state, action) {
    localStorage.setItem('basketUpdate', new Date())
    const basket = localStorage.getItem('Basket')
    if (basket !== null && basket !== 'undefined') { return { ...state, currentBasket: JSON.parse(basket) } }
    if (basket === 'undefined') { localStorage.removeItem('basket') }
  },
  [handleAddToBasket] (state, action) {
    localStorage.setItem('basketUpdate', new Date())
    const basket = localStorage.getItem('Basket')
    if (!basket) {
      // New basket
      const selectedNumbers = []
      let selectedPackage
      const selectedHardware = []

      if (action.payload.itemType === BasketItemType.phoneNumber) { selectedNumbers.push({ ...action.payload.item, available: true }) }

      if (action.payload.itemType === BasketItemType.package) { selectedPackage = action.payload.item }

      if (action.payload.itemType === BasketItemType.hardware) { selectedHardware.push(action.payload.item) }

      const newBasket = {
        numbers: selectedNumbers,
        package: selectedPackage || {},
        hardware: selectedHardware
      }

      localStorage.setItem('Basket', JSON.stringify(newBasket))
      return update(state, {
        currentBasket: { $set: newBasket }
      })
    } else {
      // Update basket
      const parsedBasket = JSON.parse(basket)

      let selectedNumbers = parsedBasket.numbers
      let selectedPackage = parsedBasket.package
      const selectedHardware = parsedBasket.hardware

      if (action.payload.itemType === BasketItemType.phoneNumber) {
        if (action.payload.editing && action.payload.editing === true) {
          const numbersArr = selectedNumbers
          numbersArr[0] = { ...action.payload.item, available: true }
          selectedNumbers = numbersArr
        } else {
          selectedNumbers.push({ ...action.payload.item, available: true })
        }
      }

      if (action.payload.itemType === BasketItemType.package) { selectedPackage = action.payload.item }

      if (action.payload.itemType === BasketItemType.hardware) {
        const existing = selectedHardware.findIndex(f => f.prodId === action.payload.item.prodId && f.name === action.payload.item.name && f.monthlyPayments === action.payload.item.monthlyPayments)
        if (existing >= 0) {
          selectedHardware[existing].qty = action.payload.item.qty
          selectedHardware[existing].amount = action.payload.item.amount
        } else {
          selectedHardware.push(action.payload.item)
        }
      }

      const newBasket = {
        numbers: selectedNumbers,
        package: selectedPackage || {},
        hardware: selectedHardware
      }

      localStorage.setItem('Basket', JSON.stringify(newBasket))

      return { ...state, currentBasket: newBasket }
    }
  },
  [handleRemoveFromBasket] (state, action) {
    localStorage.setItem('basketUpdate', new Date())
    const basket = localStorage.getItem('Basket')

    if (basket && basket !== 'undefined') {
      const parsedBasket = JSON.parse(basket)
      let selectedNumbers = parsedBasket.numbers
      let selectedPackage = parsedBasket.package
      let selectedHardware = parsedBasket.hardware

      if (action.payload.itemType === BasketItemType.phoneNumber) {
        if (action.payload.removeAll) {
          selectedNumbers = []
        } else {
          selectedNumbers = selectedNumbers.filter(f => f.ddiid !== action.payload.item.ddiid)
        }
      }

      if (action.payload.itemType === BasketItemType.package) { selectedPackage = {} }

      if (action.payload.itemType === BasketItemType.hardware) {
        if (action.payload.removeAll) {
          selectedHardware = []
        } else {
          let itemToDelete

          if (action.payload.item.productId) {
            itemToDelete = selectedHardware.findIndex(f => f.prodId === action.payload.item.productId && f.monthlyPayments === action.payload.item.monthly)
          } else {
            itemToDelete = selectedHardware.findIndex(f => f.name === action.payload.item.name && f.monthlyPayments === action.payload.item.monthly)
          }
          selectedHardware.splice(itemToDelete, 1)
        }
      }

      const newBasket = {
        numbers: selectedNumbers,
        package: selectedPackage || {},
        hardware: selectedHardware
      }

      localStorage.setItem('Basket', JSON.stringify(newBasket))
      return update(state, {
        currentBasket: { $set: newBasket }
      })
    }
  }
}, {})

const signupProgressReducer = handleActions({
  [handleUserWantsStep2] (state, action) {
    return { ...state, userAwaitingPreStep2Validation: true }
  },
  [notifyStep2Reached] (state, action) {
    return { ...state, step2Reached: true }
  }
}, { userAwaitingPreStep2Validation: false, step2Reached: false })

const validationReducer = handleActions({
  //
  [validateSignupPhoneSyntax] (state, action) {
    return update(state, {
      signupPhoneSyntax: { $set: { valid: action.payload } }
    })
  },
  [validateSignupMobileSyntax] (state, action) {
    return update(state, {
      signupMobileSyntax: { $set: { valid: action.payload } }
    })
  },
  [validateSignupLandlineSyntax] (state, action) {
    return update(state, {
      signupLandlineSyntax: { $set: { valid: action.payload } }
    })
  },
  [validationSignupPhoneReceived] (state, action) {
    return update(state, {
      signupPhoneFull: { $set: { valid: action.payload } }
    })
  },
  [validationSignupMobileReceived] (state, action) {
    return update(state, {
      signupMobileFull: { $set: { valid: action.payload } }
    })
  },
  [validationSignupLandlineReceived] (state, action) {
    return update(state, {
      signupLandlineFull: { $set: { valid: action.payload } }
    })
  },
  [validationEmailReceived] (state, action) {
    return update(state, {
      signupEmailFull: { $set: { valid: action.payload } }
    })
  },
  [handleSignupPhoneChange] (state, action) {
    if (action.payload.type && action.payload.type === 'landline') {
      return update(state, {
        signupLandlineSyntax: { $set: { valid: validationStates.UNCHECKED } },
        signupLandlineFull: { $set: { valid: validationStates.UNCHECKED } }
      })
    } else if (action.payload.type && action.payload.type === 'mobile') {
      return update(state, {
        signupMobileSyntax: { $set: { valid: validationStates.UNCHECKED } },
        signupMobileFull: { $set: { valid: validationStates.UNCHECKED } }
      })
    } else {
      return update(state, {
        signupPhoneSyntax: { $set: { valid: validationStates.UNCHECKED } },
        signupPhoneFull: { $set: { valid: validationStates.UNCHECKED } }
      })
    }
  },
  [validationSignupEmailInUse] (state, action) {
    return update(state, {
      signupEmailAvailability: { $set: { valid: validationStates.INUSE } }
    })
  },
  [validateSignupName] (state, action) {
    return update(state, {
      signupName: { $set: { valid: action.payload } }
    })
  },
  [validateSignupFirstName] (state, action) {
    return update(state, {
      signupFirstName: { $set: { valid: action.payload } }
    })
  },
  [validateSignupLastName] (state, action) {
    return update(state, {
      signupLastName: { $set: { valid: action.payload } }
    })
  },
  [validateSignupCompanyName] (state, action) {
    return update(state, {
      signupCompanyName: { $set: { valid: action.payload } }
    })
  },
  [validateSignupPortSyntax] (state, action) {
    return update(state, {
      signupPortSyntax: { $set: { valid: action.payload } }
    })
  },
  [validateSignupEmailSyntax] (state, action) {
    return update(state, {
      signupEmailSyntax: { $set: { valid: action.payload } }
    })
  },
  [validationSignupEmailNotInUseReceived] (state, action) {
    return update(state, {
      signupEmailAvailability: { $set: { valid: action.payload } }
    })
  },
  [validationSignupMobileReceived] (state, action) {
    return update(state, {
      signupMobFull: { $set: { valid: action.payload } }
    })
  },
  [validationSignupPortReceived] (state, action) {
    return update(state, {
      signupPortFull: { $set: { valid: action.payload } }
    })
  },
  [handleSignupEmailChange] (state, action) {
    return update(state, {
      signupEmailSyntax: { $set: { valid: validationStates.UNCHECKED } },
      signupEmailAvailability: { $set: { valid: validationStates.UNCHECKED } }
    })
  },
  [handleSignupLandlineChange] (state, action) {
    return update(state, {
      signupLandlineSyntax: { $set: { valid: validationStates.UNCHECKED } },
      signupLandlineFull: { $set: { valid: validationStates.UNCHECKED } }
    })
  },
  [handleSignupPortChange] (state, action) {
    return update(state, {
      signupPortSyntax: { $set: { valid: validationStates.UNCHECKED } },
      signupPortFull: { $set: { valid: validationStates.UNCHECKED } }
    })
  },
  [handleSignupCompanyChange] (state, action) {
    return update(state, {
      signupCompanyName: { $set: { valid: validationStates.UNCHECKED } }
    })
  }
}, {
  signupPhoneSyntax: { valid: validationStates.UNCHECKED },
  signupPhoneFull: { valid: validationStates.UNCHECKED },
  signupMobileSyntax: { valid: validationStates.UNCHECKED },
  signupMobileFull: { valid: validationStates.UNCHECKED },
  signupLandlineSyntax: { valid: validationStates.UNCHECKED },
  signupLandlineFull: { valid: validationStates.UNCHECKED },
  signupName: { valid: validationStates.UNCHECKED },
  signupFirstName: { valid: validationStates.UNCHECKED },
  signupLastName: { valid: validationStates.UNCHECKED },
  signupEmailFull: { valid: validationStates.UNCHECKED },
  signupEmailSyntax: { valid: validationStates.UNCHECKED },
  signupEmailAvailability: { valid: validationStates.UNCHECKED },
  signupPassword: { valid: validationStates.UNCHECKED },
  signupCompanyName: { valid: validationStates.UNCHECKED },
  signupPortSyntax: { valid: validationStates.UNCHECKED },
  signupPortFull: { valid: validationStates.UNCHECKED }

})

export const reducer = combineReducers({
  signupFields: signupFormReducer,
  packageInfo: packageReducer,
  validation: validationReducer,
  signupProgress: signupProgressReducer
})

// SELECTORS
// Name
const signupNameRegexValidationState = state => state.signup.validation.signupName.valid
const signupFirstNameRegexValidationState = state => state.signup.validation.signupFirstName.valid
const signupLastNameRegexValidationState = state => state.signup.validation.signupLastName.valid
export const signupLandlineFullValidationState = state => state.signup.validation.signupLandlineFull.valid
export const signupPhoneFullValidationState = state => state.signup.validation.signupPhoneFull.valid
export const signupMobileFullValidationState = state => state.signup.validation.signupMobileFull.valid

// Telephones
const signupPhoneRegexValidationState = state => state.signup.validation.signupPhoneSyntax.valid
const signupMobileRegexValidationState = state => state.signup.validation.signupMobileSyntax.valid
const signupLandlineRegexValidationState = state => state.signup.validation.signupLandlineSyntax.valid
const signupPortRegexValidationState = state => state.signup.validation.signupPortSyntax.valid

// Email
const signupEmailRegexValidationState = state => state.signup.validation.signupEmailSyntax.valid
export const signupEmailFullValidationState = state => state.signup.validation.signupEmailFull.valid
export const signupEmailInUseValidationState = state => state.signup.validation.signupEmailAvailability.valid

const signupCompanyNameEmptyValidationState = state => state.signup.validation.signupCompanyName.valid
const userHasIndicatedTheyWantToMoveToNextStep = state => state.signup.signupProgress.userAwaitingPreStep2Validation

export const signupPortFullValidationState = state => state.signup.validation.signupPortFull.valid

export const getSignupPhoneValidationState = createSelector(
  [signupPhoneRegexValidationState, signupPhoneFullValidationState],
  (phoneRegexValidationState, phoneFullValidationState) => {
    if (phoneRegexValidationState === validationStates.EMPTY) { return { valid: validationStates.EMPTY, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_PHONE_EMPTY } }

    if (phoneFullValidationState === validationStates.INVALID || phoneFullValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_PHONE }
    }

    return phoneFullValidationState
  }
)

export const getSignupMobileValidationState = createSelector(
  [signupMobileRegexValidationState, signupMobileFullValidationState],
  (mobileRegexValidationState, mobileFullValidationState) => {
    if (mobileRegexValidationState === validationStates.INVALID || mobileFullValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_MOBILE }
    }

    if (mobileRegexValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_MOBILE }
    }

    return mobileRegexValidationState
  }
)

export const getSignupLandlineValidationState = createSelector(
  [signupLandlineRegexValidationState, signupLandlineFullValidationState],
  (landlineRegexValidationState, landlineFullValidationState) => {
    if (landlineRegexValidationState === validationStates.INVALID || landlineFullValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_LANDLINE }
    }

    if (landlineRegexValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_LANDLINE }
    }

    return landlineRegexValidationState
  }
)

export const getSignupEmailValidationState = createSelector(
  [signupEmailRegexValidationState, signupEmailInUseValidationState],
  (emailRegexValidationState, emailAvailableValidationState) => {
    if (emailRegexValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_EMAIL_EMPTY }
    }

    if (emailRegexValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_EMAIL_SYNTAX }
    }

    if (emailAvailableValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: signupValidationErrors.VALIDATE_IN_USE_EMAIL }
    }

    if (emailAvailableValidationState === validationStates.VALID && emailRegexValidationState === validationStates.VALID) {
      return validationStates.VALID
    }
    return emailRegexValidationState
  }
)

export const getSignupPortValidationState = createSelector(
  [signupPortRegexValidationState, signupPortFullValidationState],
  (portRegexValidationState, portFullValidationState) => {
    if (portRegexValidationState === validationStates.INVALID || portFullValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_LANDLINE }
    }

    if (portRegexValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'Please enter a valid landline number' }
    }

    return portRegexValidationState
  }
)

export const getSignupNameValidationState = createSelector(
  [signupNameRegexValidationState],
  (nameValidationState) => {
    if (nameValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_NAME_EMPTY }
    }
    if (nameValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_NAME_SYNTAX }
    }
    return nameValidationState
  }
)

export const getSignupFirstNameValidationState = createSelector(
  [signupFirstNameRegexValidationState],
  (firstNameValidationState) => {
    if (firstNameValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_FIRST_NAME_EMPTY }
    }
    if (firstNameValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_FIRST_NAME_SYNTAX }
    }
    return firstNameValidationState
  }
)

export const getSignupLastNameValidationState = createSelector(
  [signupLastNameRegexValidationState],
  (lastNameValidationState) => {
    if (lastNameValidationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_LAST_NAME_EMPTY }
    }
    if (lastNameValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: signupValidationErrors.VALIDATE_SIGNUP_LAST_NAME_SYNTAX }
    }
    return lastNameValidationState
  }
)

export const getSignupCompanyNameValidationState = createSelector(
  [signupCompanyNameEmptyValidationState],
  (companyNameValidationState) => {
    if (companyNameValidationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: 'Company name is too long (Max. 32 chars)' }
    }
    return companyNameValidationState
  }
)

export const getIsStep2Ready = createSelector(
  [getSignupEmailValidationState, userHasIndicatedTheyWantToMoveToNextStep, getSignupNameValidationState],
  (emailValidationState, userReadyToProgress, firstNameValidationState, lastNameValidationState, signupMobValidationState, signupPasswordValidation) => {
    // let canProgressToStep2 = emailValidationState && emailValidationState.valid === validationStates.VALID && userReadyToProgress;
    let progressState
    if (emailValidationState === validationStates.UNCHECKED &&
            firstNameValidationState === validationStates.UNCHECKED &&
            lastNameValidationState === validationStates.UNCHECKED &&
            signupMobValidationState === validationStates.UNCHECKED
            // signupPasswordValidation === validationStates.UNCHECKED
    ) {
      return validationStates.UNCHECKED
    }

    const canProgressToStep2 = emailValidationState === validationStates.VALID &&
            firstNameValidationState === validationStates.VALID &&
            lastNameValidationState === validationStates.VALID &&
            signupMobValidationState === validationStates.VALID
    // signupPasswordValidation === validationStates.VALID;

    progressState = validationStates.fromBool(canProgressToStep2)
    return progressState
  }
)

export const getIsStep2Reached = state => state.signup.signupProgress.step2Reached
