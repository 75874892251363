import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'

class OutboundManual extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      // activeKey: this.props.match.params.activeTab ? this.props.match.params.activeTab : 'installation'
    }
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>VoIP Phone Setup Guide</title>
                <meta name="description" content="SwitchboardFREE outbound phone - Fanvil X4U manual" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/howitworks-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1 id="InstallationManualsH1">Installation manuals</h1>
                <h2 className="subtitle">Click on the device to get your installation instructions.</h2>
            </header>
            <div className="full-width bg-light">
                <div className="container">
                                <div className="row text-center mb-100">
                                    <div className="col-sm-3 col-xs-6 col-xxs-12 mb-20">
                                        <Link to={'/setup/deskphone'}>
                                            <div className="b-1 brad-20 p-30 p-sm-10 zoom cursor-pointer">
                                                <img src="/images/product-shots/ecom/X4U.jpg" className="img-responsive" />
                                                <h5 className="text-500 text-sm-md">Deskphone</h5>
                                                <Link to={'/setup/deskphone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-sm-3 col-xs-6 col-xxs-12 mb-20">
                                        <Link to={'/setup/wireless-phone'}>
                                            <div className="b-1 brad-20 p-30 p-sm-10 zoom cursor-pointer">
                                                <img src="/images/product-shots/ecom/W52P.jpg" className="img-responsive" />
                                                <h5 className="text-500 text-sm-md">Wireless Phone</h5>
                                                <Link to={'/setup/wireless-phone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-sm-3 col-xs-6 col-xxs-12 mb-20">
                                        <Link to={'/setup/app'}>
                                            <div className="b-1 brad-20 p-30 p-sm-10 zoom cursor-pointer">
                                                <img src="/images/product-shots/ecom/app.jpg" className="img-responsive" />
                                                <h5 className="text-500 text-sm-md">App</h5>
                                                <Link to={'/setup/app'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-sm-3 col-xs-6 col-xxs-12 mb-20">
                                        <Link to={'/setup/softphone'}>
                                            <div className="b-1 brad-20 p-30 p-sm-10 zoom cursor-pointer">
                                                <img src="/images/product-shots/ecom/softphone.jpg" className="img-responsive" />
                                                <h5 className="text-500 text-sm-md">Softphone</h5>
                                                <Link to={'/setup/softphone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

        </>
  }
}
export default connect(
  state => {
    return state
  },
  {}
)(OutboundManual)
