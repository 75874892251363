import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AboutMenu from '../../components/AboutMenu'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { getCustomerCount } from '../../helpers/generic'

interface AboutUsProps {
  isLoggedIn: boolean
}

const AboutUs = (props: AboutUsProps) => {
  const [customerCount, setCustomerCount] = useState(0)
  useEffect(() => {
    getCustomers()
  }, [])

  const getCustomers = async () => {
    const data = await getCustomerCount()
    setCustomerCount(data)
  }

  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />
            <AboutMenu />
            <Helmet>
                <title>About SwitchboardFREE - Free Virtual Call Management System</title>
                <meta name="description" content="SwitchboardFREE is the UK's favourite free call management system providing free phone numbers and virtual call management services for UK businesses." />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1 id="AboutSwitchboardFREEH1">About SwitchboardFREE</h1>
                <h2 className=" subtitle">Providing smarter call management for over 18 years</h2>
            </header>

            <div className="container-fluid bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-20 pb-30">
                            <p className="pt-20">Have you ever had that feeling of regret and disappointment, as you look at your phone and discover too many missed calls - again? And, have you ever worked out how much money-in-your pocket those calls could have potentially earned you? Don’t! It’s a scary number! But just in case you’re curious, here are some stats:</p>
                            <ul className="fancy cta text-400 pl-30 pt-10 pb-10">
                                <li className="mb-5">75% of customers still prefer to make contact via phone call.</li>
                                <li className="mb-5">23% of all calls to businesses like yours are missed.</li>
                                <li className="mb-5">On average that’s £63.41 of revenue lost (not including residual).</li>
                            </ul>

                            <p>-Ouch!</p>

                            <p>Back in 2005, we did some research on small to medium-sized businesses and found that missing customer calls was a prolific problem. Some even felt that it was normal due to the budget constraints of smaller businesses and the self-employed. It was apparent that the money to invest in any type of call management system just wasn’t available; yet had it been, revenues would’ve increased significantly making call management justifiable and profitable.</p>
                            <p>The lack of affordable call management to nurture customers, and close and onboard leads was clearly putting many businesses at a disadvantage and was risking brand image. To break that unfair barrier, our in-house tech guys built a call management system and offered it to small and medium-sized businesses for free! And, with that, SwitchborardFREE was born.</p>
                            <p>18 years on and our UK in-house-based team have helped and supported more than {customerCount} businesses, simply by routing inbound calls to multiple or overflow numbers to significantly reduce missed calls and opportunities. Throughout that time we have continually improved and updated our service to enhance the customer experience and to meet the fundamental needs of businesses such as yours.</p>
                            <p>Whether you’re a solopreneur or a medium enterprise, a missed call equals missed business and is a source of anxiety every day. We think you’ll agree that call management shouldn’t be just for big businesses, and at SwitchboardFREE, with our collective 34 years of experience in the telecommunication industry, we’ve made it so it’s not. In fact, we’ve made it our mission to continually allow small-medium businesses like yours to future-proof themselves with a cost-effective, easy-to-set-up, scalable and portable call management system, that will evolve along with you and your business for years to come.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Free call management</h2>
                            <p>To find out how SwitchboardFREE can support your call management needs you can email our team at <a href="mailto:support@switchboardfree.co.uk" className="underline">support@switchboardfree.co.uk</a> or call our experts direct on <a href="tel:02031891213" className="underline">0203 189 1213</a>. Of course, if you're happy to browse our website, feel free! </p>
                        </div>
                        <div className="col-md-6">
                            <div className="timeline">
                                <div className="entries">
                                    <div className="entry mb-40">
                                        <h4 className="big">2022</h4>
                                        <p className="text-600 text-secondary text-md">Launch of Call Transcription </p>
                                        <p className="text-400">
                                            Transcriptions is a great new feature, available to everyone that records their calls. Transcription enables users to review the contents of their calls much quicker and more conveniently then listening through hours of call recordings. It always means users are now able to instantly search for calls that contain specific spoken words (e.g. search for calls where someone mentioned "party")
                                        </p>
                                    </div>
                                    <div className="entry mb-40">
                                        <h4 className="big">2021</h4>
                                        <p className="text-600 text-secondary text-md">New SBF Admin &amp; Phone Apps </p>
                                        <p className="text-400">
                                            Building upon the success of the relatively new Phone App and the well-established Admin App both of these went through a radical redesign, adding in controls for many new platform features and much improving usability making our apps quicker and easier to use.
                                        </p>
                                    </div>
                                    <div className="entry mb-40">
                                        <h4 className="big">2020</h4>
                                        <p className="text-600 text-secondary text-md">Launch of "PR-Lite"</p>
                                        <p className="text-400">
                                            PR-Lite is a groundbreaking industry first which allows users to have calls to their SwitchboardFREE numbers answered by real live call agents (thanks to our sister company Pocket Receptionist) without any commitment, on a Pay-As-You-Go basis and with instant setup. Users are able to go onto their self-service settings portal and with the click of a single button can have their calls answered quickly and professionally for every call!
                                        </p>
                                    </div>
                                    <div className="entry mb-40">
                                        <h4 className="big">2019</h4>
                                        <p className="text-600 text-secondary text-md">Launch of Outbound</p>
                                        <p className="text-400">  With our customers businesses growing and looking for ways to grow even further, it quickly became clear that inbound calling alone was not enough for our customers and they needed to be able to call their customers directly and outbound calling was added to meet that need and further our mission to provide the best service possible to our customers</p>
                                    </div>
                                    <div className="entry mb-40">
                                        <h4 className="big">2018</h4>
                                        <p className="text-600 text-secondary text-md">Launch of Call Recording</p>
                                        <p className="text-400">
                                            Call recording is a powerful addition to the SwitchboardFREE phone system. It enables users to protect themselves and provide clarity when it comes to customer disputes, staffing issues as well as providing incredible business insight for training purposes.
                                        </p>
                                    </div>
                                    <div className="entry mb-40">
                                        <h4 className="big">2017</h4>
                                        <p className="text-600 text-secondary text-md">Launch of PR</p>
                                        <p className="text-400">After many years of focusing on doing anything and everything we could to help customers never miss
                                            any important business calls it became apparent that as clever as technology is there are some situations
                                            where a call simply cannot be answered and a good old fashioned human is required to help out. With this
                                            in mind we launched a fully managed virtual receptionist service to compliment SwitchboardFREE.
                                            Pocket Receptionist is a cost effective way of having your own P.A. to answer your calls when you're
                                            not able to but without the expense of an additional member of staff. <a href="/telephone-answering-service">click here out find out more.</a></p>
                                    </div>
                                    <div className="entry">
                                        <h4 className="big">2014</h4>
                                        <p className="text-600 text-secondary text-md">Platform upgrade and relocation</p>
                                        <p className="text-400">Major investment was put into SwitchboardFREE not only rebuilding the product from the
                                            ground up based on all the great feedback received over our thousands of customers over
                                            the previous years but also relocation for purposeful data centres ensuring maximum resiliancy.</p>
                                    </div>
                                    <div className="entry">
                                        <h4 className="big">2013</h4>
                                        <p className="text-600 text-secondary text-md">Introduction of number types</p>
                                        <p className="text-400">Initially SwitchboardFREE was based entirely on providing 0870, 0844 and 0843 numbers for free to UK
                                            businesses (and we still do) however as demand began to grow for all other number types (0800,
                                            London, 03 and local numbers) these were introduced with 01 numbers now accounting for around 70% of
                                            all new number allocations.</p>
                                    </div>
                                    <div className="entry">
                                        <h4 className="big">2012</h4>
                                        <p className="text-600 text-secondary text-md">Launch of Unlimited Mobile Redirects</p>
                                        <p className="text-400">SwitchboardFREE revolutionised the virtual number industry by being the first
                                            to offer unlimited calls to your mobile for a single monthly price. Still the most
                                            popular upgrade, this feature alone saves companies millions of pounds each year
                                            ensuring they never miss a call.</p>
                                    </div>
                                    <div className="entry">
                                        <h4 className="big">2011</h4>
                                        <p className="text-600 text-secondary text-md">Expansion and move to own offices</p>
                                        <p className="text-400">Due to SwitchboardFREE's continued success and increase in the size of our customer support
                                            teams we moved to larger offices overlooking Lowestoft harbour and providing plenty of room for
                                            expansion in the years to come.</p>
                                    </div>
                                    <div className="entry">
                                        <h4 className="big">2005</h4>
                                        <p className="text-600 text-secondary text-md">SwitchboardFREE was born!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="full-width full-secondary text-lightest p-30 pt-60 pb-60">
                <div className="col-md-12 text-center">
                    <h3 className="text-500">Enjoy business call management, get started in minutes risk-free today</h3>
                    <p>We are confident you won't find another provider that will offer you better
                        call management services, with no catches or commitments.<br />With SwitchboardFREE, you are in complete control.
                    </p>
                    <Link className="btn btn-lg btn-cta btn-mobile-block mt-20 mb-30" to={props.isLoggedIn ? '/customer/numbers/new' : '/get-started'}>Choose your number</Link>

                    <p>Or <a href="./compare-business-numbers/">compare pricing &amp; packages</a></p>
                </div>
            </div>
            <ClientLogos style='light' />
            <div className="full-width bg-dull pt-50">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(AboutUs)
