import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fieldNames } from '../../constants/ConfigurationConstants'
import { getConfigValidators } from '../../selectors'
import * as configurationActions from '../../store/Configuration'
import Validator from '../Validator'
import MissedCallEmailAlertsInstructionHelperDialog from './InstructionHelperDialogs/MissedCallEmailAlertsInstructionHelperDialog'

const MissedCallEmailAlertsSection = (props) => (
    <>
        <div className="row bg-dullest p-30 zoom">
            <div className="col-md-5 pb-sm-10">
                <h5 className="text-secondary text-500 text-lg">
                    Missed Call Email Alerts
                </h5>
                <p className="text-400">Receive a real-time alert via email containing the caller’s number, geographical location and time stamp, allowing you to call them straight back.
                    <br />
                    <a onClick={props.handleShowMissedCallEmailAlertsInstruction} className="underline" id="MissedCallEmailAlertsMoreInfoLink">More info</a></p>
            </div>
            <div className="col-md-7 mt-10 pl-sm-0 pr-sm-0">
                <div className='row'>
                    <MissedCallEmailAlertsInstructionHelperDialog />
                    <div className="col-md-4 pb-sm-30 text-sm-center">
                        <p>Turn ON/OFF:</p>

                        <Toggle
                            className="mt-0"
                            onClick={e => props.handleMissedCallEmailsEnabledChange(e)}
                            on={<span>ON</span>}
                            off={<span>OFF</span>}
                            size="sm"
                            offstyle="default"
                            onstyle="success"
                            width="null"
                            height="null"
                            active={props.missedCallEmailsEnabled}
                            id="MissedCallEmailAlertsToggle"
                        />
                    </div>

                    <div className="col-md-8">
                        <Validator validation={props.validation[fieldNames.MISSED_CALL_EMAIL]} />
                        <p>Email address: </p>
                        <input type="text" className="form-control" placeholder="eg. john@gmail.com;john@yahoo.com" inputMode='email' value={props.missedCallEmailAddress} maxLength='128' onBlur={e => props.validateEmailSyntax({ fieldName: fieldNames.MISSED_CALL_EMAIL, value: e.target.value })} onChange={e => props.handleMissedCallEmailChange(e.target.value)} id="MissedCallEmailAlertsInput"/>
                    </div>
                </div>
            </div>
        </div>
    </>
)

const mapStateToProps = state => ({
  missedCallEmailsEnabled: state.configuration.configuration.isMissedCallEmailEnabled,
  missedCallEmailAddress: state.configuration.configuration.missedCallEmailAddress,
  validation: getConfigValidators(state)
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissedCallEmailAlertsSection)
