import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleResetNotification } from '../store/SBF'
const classNames = require('classnames')

class Toasty extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {}

  render() {
    return (
      (this.props.isBasket === true || this.props.checkoutIsBasket === true)
        ? <div id="toastBasket" className="pl-0 basket-toast">
          <div id="desc">
            {(this.props.removeBasket || this.props.checkoutRemoveBasket)
              ? <span className="text-500">{this.props.message ? this.props.message : this.props.checkoutMessage} removed from basket.</span>
              : <span><span className="text-500">{this.props.message ? this.props.message : this.props.checkoutMessage} added to basket.</span><br /><span className="text-sm">You can update or remove at checkout.</span></span>
            }
          </div>
        </div>
        : <div id="toast">
          <div id="img">
            <span className={classNames({ icon: true, 'icon-cross': this.props.isError, 'icon-checkmark4': !this.props.isError })} />
          </div>
          <div id="desc">
            {this.props.message}
          </div>
        </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.message && this.props.message !== prevProps.message) || (this.props.checkoutMessage && this.props.checkoutMessage !== prevProps.checkoutMessage)) {
      if (this.props.isBasket || this.props.checkoutIsBasket) {
        const toast = document.getElementById('toastBasket')
        toast.className = classNames({ show: true, failure: this.props.isError || this.props.removeBasket || this.props.checkoutRemoveBasket, 'pl-0': this.props.isBasket || this.props.checkoutIsBasket })
        const reset = this.props.resetNotification

        setTimeout(function () { toast.className = classNames({}); reset() }, 5000)
      } else {
        const toast = document.getElementById('toast')
        toast.className = classNames({ show: true, failure: this.props.isError, 'pl-0': this.props.isBasket || this.props.checkoutIsBasket })
        const reset = this.props.resetNotification

        setTimeout(function () { toast.className = classNames({}); reset() }, 5000)
      }
    }
  }
}

export default connect(state => ({ message: state.sbfApp.notification.message, isError: state.sbfApp.notification.isErrorState, isBasket: state.sbfApp.notification.isBasket, removeBasket: state.sbfApp.notification.removeBasket }), { resetNotification: handleResetNotification })(Toasty)
