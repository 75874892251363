import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { type RouteComponentProps } from 'react-router-dom'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import DisplayNoFooter from '../../components/DisplayNoFooter'
import DisplaySimpleHeaderV2 from '../../components/DisplaySimpleHeaderV2'
import GetProdPrice from '../../components/GetProdPrice'
import ProgressSteps from '../../components/ProgressSteps'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import NumberTypeChooser from '../../components/purchase/NumberTypeChooser'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import Validator from '../../components/Validator'
import {
	is0203Offer,
	is0207Offer,
	is033Offer,
	is0800Offer,
	isGeoOffer, PortingCredit, validationStates, type BasketItemType, type SignupStep
} from '../../constants/Constants'
import { isValidLandlineSyntax } from '../../helpers/validationHelpers'
import {
	type NumberType
} from '../../models/GenericTypes'
import {
	type Basket,
	type BasketItem, type SbfNumber, type SignupValidation
} from '../../models/SignupTypes'

export interface GetNumberProps extends RouteComponentProps {
  signupPort: string
  handleClearAllPhoneNumbersFromBasket: () => void
  selectedNumberType: string
  isLoggedIn: boolean
  handleAddToBasket: (args: BasketItem) => void
  handleChangeNumberType: (numberType: NumberType) => void
  currentBasket: Basket
	handleUpdateBasket: (basket: Basket, number?: SbfNumber, removed?: boolean) => void
  handleContinue: (isPorting: boolean) => void
  handleClearNumberType: () => void
  handleClearGeoRegion: () => void
  handleBack: (step?: SignupStep) => void
}

export interface GetNumberState {
  userHasRequestedNumberHelp: boolean
  showGenericError: boolean
  showCompare: boolean
	focusPort: boolean
	numberToPort: string
}

export interface RemoveFromBasketArgs {
  item: BasketItemType
  removeAll: boolean
}

const GetNumber: React.FunctionComponent<GetNumberProps> = (
  props
): JSX.Element => {
  // let portNumberVal = "";

  const [state, setState] = useState<GetNumberState>({
    focusPort: false,
    showCompare: false,
    userHasRequestedNumberHelp: false,
		showGenericError: false,
		numberToPort: ''
  })

  const [transferNumberValidationState, setTransferNumberValidation] =
		useState<SignupValidation>({
		  validationState: validationStates.UNCHECKED,
		  errorMsg: ''
		})

  useEffect(() => {
  }, [])

  function handleCancelPort () {
    props.handleClearNumberType()
  }

  function handleAddPortingNumberToBasket (customerNumberToPort: string) {
    if (isValidLandline(customerNumberToPort)) {
      props.handleAddToBasket({
        ddiid: 0,
        ddipremium: 0,
        number: customerNumberToPort
      })
    }
  }

  function isValidLandline (landlineNumber: string) {
    if (isValidLandlineSyntax(landlineNumber)) {
      setTransferNumberValidation({
        validationState: validationStates.VALID,
        errorMsg: 'Please enter a valid UK landline number'
      })
      return true
    } else {
      setTransferNumberValidation({
        validationState: validationStates.INVALID,
        errorMsg: 'Please enter a valid UK landline number'
      })
      return false
    }
  }

  useEffect(() => {
    window.dataLayer.push({
      event: 'signupNumbers'
    })
  }, [])

  function renderDdiSelector () {
    return (
			<>
				<div className="col-md-12 text-center d-none d-sm-block">
					<span className="separator-block block-dark text-400 mb-50">
						<span>Choose your perfect number(s) below</span>
					</span>
				</div>

				<div className="col-md-12">
					<DdiSelectorNew
						numberType={props.selectedNumberType}
						isSignup={true}
						currentBasket={props.currentBasket}
						hideShowAll={true}
						isModal={false}
						handleUpdateBasket={(basket: Basket, number: SbfNumber, removed: boolean) => {
						  props.handleUpdateBasket(basket, number, removed)
						}}
						menuOpen={true}
						hideLoadMore={false}
						hidestars={false}
						isEditing={false}
						isLoggedIn={props.isLoggedIn}
						isSimple={false}
						citysOnly={false}
						handleChangeNumberType={(numberType) => {
						  props.handleChangeNumberType(numberType)
						}}
						handleClearGeoRegion={function (): void {
						  props.handleClearGeoRegion()
						}} />
				</div>
			</>
    )
  }

  function renderCompareNumberTypesModal () {
    return (
			<Modal
				show={state.showCompare}
				onHide={() => { setState({ ...state, showCompare: false }) }}
			>
				<Modal.Header closeButton className="bb-0 pb-0"></Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-xs-12 text-center pb-30">
							<h1 className="mt-0 pt-0">Compare numbers</h1>
							<p>
								Whatever your business we have the perfect phone number for you
							</p>
						</div>
					</div>

					<div className="row row-flex">
						<div className="col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30">
							<div className="icon-block-cta bg-light">
								<span className="icon icon-location2 text-secondary"></span>
							</div>
							<h6 className="text-500">Local numbers</h6>
							<p className="text-400 text-sm">
								We love to shop locally. When searching for local business we
								like to call a local number.
							</p>
							<h5
								className={`${isGeoOffer ? 'text-cta' : 'text-secondary'
									} text-500 text-sm pb-10`}
							>
								{isGeoOffer && 'On Sale - '}From just{' '}
								<GetProdPrice prefix="01" />
							</h5>
						</div>
						<div className="col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30 br-1 bt-1 bb-1 br-sm-0">
							<div className="icon-block-cta bg-light">
								<span className="icon icon-gherkin text-secondary"></span>
							</div>
							<h6 className="text-500">0203 London numbers</h6>
							<p className="text-400 text-sm">
								Give your company a London presence without the expense of
								renting a London office.
							</p>
							<h5
								className={`${is0203Offer ? 'text-cta' : 'text-secondary'
									} text-500 text-sm pb-10`}
							>
								{is0203Offer && 'On Sale - '}From just{' '}
								<GetProdPrice prefix="0203" />
							</h5>
						</div>
						<div className="col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30 bt-1 bb-1 bt-sm-0 br-sm-1">
							<div className="icon-block-cta bg-light">
								<span className="icon icon-bigben text-secondary"></span>
							</div>
							<h6 className="text-500">0207 London numbers</h6>
							<p className="text-400 text-sm">
								Rarely available and highly prestigious London numbers.
							</p>
							<h5
								className={`${is0207Offer ? 'text-cta' : 'text-secondary'
									} text-500 text-sm pb-10`}
							>
								{is0207Offer && 'On Sale - '}From just{' '}
								<GetProdPrice prefix="0207" />
							</h5>
						</div>
						<div
							className="col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30 br-1 br-sm-0 bt-sm-0 bb-xxs-1">
							<div className="icon-block-cta bg-light">
								<span className="icon icon-0800 text-secondary"></span>
							</div>
							<h6 className="text-500">0800 numbers</h6>
							<p className="text-400 text-sm">
								Calls made to 0800 numbers from both landline and mobile phones.
							</p>
							<h5
								className={`${is0800Offer ? 'text-cta' : 'text-secondary'
									} text-500 text-sm pb-10`}
							>
								{is0800Offer && 'On Sale - '}From just{' '}
								<GetProdPrice prefix="0800" />
							</h5>
						</div>
						<div className="col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30 br-1">
							<div className="icon-block-cta bg-light">
								<span className="icon icon-03 text-secondary"></span>
							</div>
							<h6 className="text-500">0333 numbers</h6>
							<p className="text-400 text-sm">
								Get a professional business number with a national presence.
							</p>
							<h5
								className={`${is033Offer ? 'text-cta' : 'text-secondary'
									} text-500 text-sm pb-10`}
							>
								{is033Offer && 'On Sale - '}From just{' '}
								<GetProdPrice prefix="03" />
							</h5>
						</div>
						<div className="col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30 bt-sm-1">
							<div className="icon-block-cta bg-light">
								<span className="icon icon-phone2 text-secondary"></span>
							</div>
							<h6 className="text-500">084 numbers</h6>
							<p className="text-400 text-sm">
								084 number will give your business a professional sound and
								national presence.
							</p>
							<h5 className="text-secondary text-500 text-sm pb-10">Free</h5>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={(e) => { setState({ ...state, showCompare: false }) }}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
    )
  }

  function renderSelectNumberTypeChoosers () {
    function renderLocalNumbersNumberTypeChooser () {
      return <NumberTypeChooser
				numberTypeTitle="01/02"
				numberTypeDescription="Local numbers"
				isActive={props.selectedNumberType === '01'}
				onClick={(e) => {
				  props.handleChangeNumberType('01')
				}}
				disabled={
					props.currentBasket &&
					!_.isEmpty(props.currentBasket) &&
					props.currentBasket.numbers.some(
					  (num) =>
					    num.number.startsWith('084') || num.number.startsWith('080')
					)
				}
			/>
    }

    function renderLondon0203NumberTypeChooser () {
      return <NumberTypeChooser
				numberTypeTitle="0203"
				numberTypeDescription="London numbers"
				isActive={props.selectedNumberType === '0203'}
				onClick={(e) => {
				  props.handleChangeNumberType('0203')
				}}
				disabled={
					props.currentBasket &&
					!_.isEmpty(props.currentBasket) &&
					props.currentBasket.numbers.some(
					  (num) =>
					    num.number.startsWith('084') || num.number.startsWith('080')
					)
				}
			/>
    }

    function renderLondon0207NumberTypeChooser () {
      return <NumberTypeChooser
				numberTypeTitle="0207"
				numberTypeDescription="London numbers"
				isActive={props.selectedNumberType === '0207'}
				onClick={(e) => {
				  props.handleChangeNumberType('0207')
				}}
				disabled={
					props.currentBasket &&
					!_.isEmpty(props.currentBasket) &&
					props.currentBasket.numbers.some(
					  (num) =>
					    num.number.startsWith('084') || num.number.startsWith('080')
					)
				}
			/>
    }

    function renderFreephone0800NumberTypeChooser () {
      return <NumberTypeChooser
				numberTypeTitle="0800"
				numberTypeDescription="Freephone numbers"
				isActive={props.selectedNumberType === '0800'}
				onClick={(e) => {
				  props.handleChangeNumberType('0800')
				}}
				disabled={
					props.currentBasket &&
					!_.isEmpty(props.currentBasket) &&
					props.currentBasket.numbers.some(
					  (num) =>
					    num.number.startsWith('01') ||
							num.number.startsWith('02') ||
							num.number.startsWith('03') ||
							num.number.startsWith('084')
					)
				}
			/>
    }

    function renderFreephone0808NumberTypeChooser () {
      return <NumberTypeChooser
				numberTypeTitle="0808"
				numberTypeDescription="Freephone numbers"
				isActive={props.selectedNumberType === '0808'}
				onClick={(e) => {
				  props.handleChangeNumberType('0808')
				}}
				disabled={
					props.currentBasket &&
					!_.isEmpty(props.currentBasket) &&
					props.currentBasket.numbers.some(
					  (num) =>
					    num.number.startsWith('01') ||
							num.number.startsWith('02') ||
							num.number.startsWith('03') ||
							num.number.startsWith('084')
					)
				}
			/>
    }

    function renderNationalNumberTypeChooser () {
      return <NumberTypeChooser
				numberTypeTitle="084"
				numberTypeDescription="National numbers"
				isActive={props.selectedNumberType === '084'}
				onClick={(e) => {
				  props.handleChangeNumberType('084')
				}}
				disabled={
					props.currentBasket &&
					!_.isEmpty(props.currentBasket) &&
					props.currentBasket.numbers.some(
					  (num) =>
					    num.number.startsWith('080') ||
							num.number.startsWith('01') ||
							num.number.startsWith('02') ||
							num.number.startsWith('03')
					)
				}
				basicView={true}
			/>
    }

    function renderNonGeo0333NumberTypeChooser () {
      return <NumberTypeChooser
				numberTypeTitle="0333"
				numberTypeDescription="Non Geo numbers"
				isActive={props.selectedNumberType === '0333'}
				onClick={(e) => {
				  props.handleChangeNumberType('0333')
				}}
				disabled={
					props.currentBasket &&
					!_.isEmpty(props.currentBasket) &&
					props.currentBasket.numbers.some(
					  (num) =>
					    num.number.startsWith('084') || num.number.startsWith('080')
					)
				}
			/>
    }

    return (
			<>
				<div className="row m-0">
					<div className="col-md-12 pl-0 pr-0 text-center number-types direction-block mt-30">
						{renderLocalNumbersNumberTypeChooser()}
						{renderNonGeo0333NumberTypeChooser()}
						{renderLondon0203NumberTypeChooser()}
						{renderLondon0207NumberTypeChooser()}
						{renderFreephone0800NumberTypeChooser()}
						{renderFreephone0808NumberTypeChooser()}
						{renderNationalNumberTypeChooser()}
					</div>
					<div className="col-md-12 text-center pt-10 pb-10">
						{renderCompareNumberTypesModal()}
					</div>
				</div>
			</>
    )
  }

  function renderYouvePickedThisNumberTypeHeader () {
    return (
			<>
				<p className="text-400 lead text-center mb-50 mt-20 mb-xs-20">
					You've chosen {props.selectedNumberType} numbers.{' '}
					<br className="d-block d-sm-none" />
					<span className="underline cursor-pointer text-xs text-primary" onClick={event => { props.handleClearNumberType() }}>Change type</span>
				</p>
			</>
    )
  }

  function renderPortingForm () {
    function renderContinueWithPortingNumberButton () {
      return (
				<Button
					variant="cta"
					className="btn-xxs-block"
					onClick={() => {
						if (
							transferNumberValidationState.validationState === validationStates.VALID
						) {
							props.handleContinue(true)
						} else if (isValidLandline(state.numberToPort)) {
							props.handleContinue(true)
						}
					}}
				>
					Pick Your Package <i className="icon icon-arrow-right4"></i>
				</Button>
      )
    }

    return (
			<div className="col-xs-12 mt-20">
				<div className="bg-light b-0 brad-20 p-50 p-xxs-20 text-center porting-box box-shadow shadow-lighter">
					<div className="row">
						<div className="col-md-12 text-center">
							{PortingCredit > 0
							  ? (
								<h3 className="text-500 text-md mb-0 pb-40 mt-20">
									Transfer your number to us <br className="d-none d-sm-block" />
									&amp; get{' '}
									<span className="text-cta">
										&pound;{PortingCredit} FREE
									</span>{' '}
									credit
								</h3>
							    )
							  : (
								<h3 className="text-400 mb-0 pb-40">
									Transfer your existing number to us
								</h3>
							    )}
						</div>
						<div className="col-md-12">
							<Form.Group
								className={`mb-0 text-start animated ${state.focusPort ||
										(props.signupPort && props.signupPort.length > 0)
										? 'focused'
										: ''
									}`}
							>
								<Form.Label className="text-500 text-center w-100">
									Enter the number you'd like to transfer to SwitchboardFREE
								</Form.Label>
								<Form.Control
									className="text text-md"
									type="tel"
									onChange={(e) => {
										setState({ ...state, numberToPort: e.target.value })
										setTransferNumberValidation({
											validationState: validationStates.UNCHECKED,
											errorMsg: ''
										})
									}}
									onBlur={(e) => {
										setState({ ...state, focusPort: false })
										if (e.target.value) { handleAddPortingNumberToBasket(e.target.value) }
									}}
									onFocus={() => {
										setState({ ...state, focusPort: true })
									}}
									isInvalid={transferNumberValidationState.validationState === validationStates.INVALID}
								/>
								<Form.Control.Feedback type="invalid" className="text-400 pl-15">
									{transferNumberValidationState.errorMsg}
								</Form.Control.Feedback>
							</Form.Group>
						</div>
					</div>
					<p className="pt-30 pb-20">
						Sit back &amp; relax while we take care of the entire process for
						you.
						<br />
						We'll even give you a{' '}
						<span className="text-500">free 0333 number</span> to experience our{' '}
						<br className="d-none d-sm-bock" />
						service whilst we wait for your number.
					</p>
					<p>
						Transfers are typically completed within 7 days,{' '}
						<br className="d-none d-sm-bock" />
						depending on your current provider.
					</p>
					<div className="col-md-12 col-sm-12 pt-20">
						<Validator validation={state.showGenericError}>
							{transferNumberValidationState.errorMsg
							  ? (
								<p>{transferNumberValidationState.errorMsg}</p>
							    )
							  : (
								<p>
									There seems to be a problem with your request.
									<br />
									Please double check all the fields above.
								</p>
							    )}
						</Validator>
					</div>
					{renderContinueWithPortingNumberButton()}

					<p className="pt-30 text-center">
						<a className="text-sm" onClick={() => { handleCancelPort() }}>
							Cancel, choose a different number.
						</a>
					</p>
				</div>
			</div>
    )
  }

  function renderContinueWithNewNumberButton () {
    return (
			<div className="col-xs-12 bt-1 signup-save p-30 pl-xxs-10 pr-xxs-10 text-center">
				<div className="">
					<p className="mb-0">
						<Button
							variant="cta"
							size="lg"
							className="btn-xxs-block pl-xxs-10 pr-xxs-10"
							onClick={() => { props.handleContinue(false) }}
							disabled={
								!props.currentBasket ||
								(props.currentBasket && _.isEmpty(props.currentBasket)) ||
								(props.currentBasket && _.isEmpty(props.currentBasket.numbers))
							}
						>
							Continue to plans
							<span className="icon icon-arrow-right4 ml-10 text-xs"></span>
						</Button>
					</p>
				</div>
			</div>
    )
  }

  function renderChooseBetweenPortOrNewNumberHeader () {
    return (
			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center fixed-container pb-50 pb-sm-20">
						{props.isLoggedIn
						  ? (
							<>
								<h3 className="text-500 mt-0" id="AddNewNumberH3">
									Add a new number to your account
								</h3>
								<p>
									Transfer your existing number to us, or select a new number
									below...
								</p>
							</>
						    )
						  : (
							<>
								<h3 className="text-500 mt-0" id="NumbersH3">Choose your perfect number</h3>
								<p>
									Transfer your existing number to us, or select a new number
									below...
								</p>
							</>
						    )}
					</div>
				</div>
			</div>
    )
  }

  function renderChooseBetweenPortOrNewNumberRadioButtonGroup () {
    const hideNumberType = props.selectedNumberType

    return (
			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center fixed-container mob-2-col">
						<Button
							variant="default"
							className={`${props.selectedNumberType !== 'port' ? '' : 'active'
								} btn-radio mr-10 bg-light mr-20 box-shadow shadow-lighter`}
							onClick={(e) => {
							  props.selectedNumberType !== 'port'
							    ? props.handleChangeNumberType('port')
							    : undefined
							}
							}
						>
							<span className="radio-circle">
								{props.selectedNumberType === 'port' && (
									<span className="radio-checked"></span>
								)}
							</span>
							<span className="radio-text">Transfer your <span className="d-none d-xs-inline">existing</span> number</span>
						</Button>

						<Button
							variant="default"
							className={`${props.selectedNumberType !== 'port' ? 'active' : ''
								} btn-radio mr-10 bg-light box-shadow shadow-lighter`}
							onClick={(e) => {
							  props.selectedNumberType === 'port'
							    ? props.handleChangeNumberType('')
							    : undefined
							}
							}
						>
							<span className="radio-circle">
								{props.selectedNumberType !== 'port' && (
									<span className="radio-checked"></span>
								)}
							</span>
							<span className="radio-text">Choose a new number</span>
						</Button>

						{props.selectedNumberType !== 'port'
						  ? (
							<>
								{!hideNumberType
								  ?									<span className="separator-block block-dark text-400 mt-60 mb-30 mt-xs-20 mb-xs-10">
										<span>Select number type</span>
									</span>
								  :									<></>
								}
							</>

						    )
						  : (
							<span className="separator-block block-dark text-400 mt-60 mb-30">
								<span>
									Enter your number below
								</span>
							</span>
						    )}

					</div>
				</div>
			</div>
    )
  }

  function renderMain () {
    const hideNumberType = props.selectedNumberType

    function canShowDdiSelector () {
      if (props.selectedNumberType === '01') {
        return true
      }
      return true
    }

    return (
			<div>
				<div className="full-width mb-xs-0 pb-xs-0">
					{renderChooseBetweenPortOrNewNumberHeader()}
					{renderChooseBetweenPortOrNewNumberRadioButtonGroup()}
				</div>
				<div className="container">
					<div className="col-md-12">
						<div className={''}>
							{props.selectedNumberType !== 'port' && (
								<>
									{!hideNumberType
									  ? renderSelectNumberTypeChoosers()
									  : renderYouvePickedThisNumberTypeHeader()
									}

									{props.selectedNumberType && (
										<div id="chooseNumber" className="pl-xs-20 pr-xs-20">
											{canShowDdiSelector() && renderDdiSelector()}
										</div>
									)}
								</>
							)}

							{props.selectedNumberType &&
								<p className="text-sm cursor-pointer d-block pt-20 text-center" onClick={() => {
								  props.handleBack()
								}}>
									<span className="icon icon-arrow-left4 mr-3 text-xs"></span> <span className="underline">Go back to previous step</span>
								</p>
							}

							<div className="row">
								<>
									{props.selectedNumberType === 'port' && renderPortingForm()}
									{props.selectedNumberType !== 'port' &&
										renderContinueWithNewNumberButton()}
								</>
							</div>
						</div>
					</div>
				</div>
			</div>
    )
  }

  function renderPageHeader () {
    return (
			<>
				{props.isLoggedIn
				  ? (
					<>
						<DisplayMainHeader />
					</>
				    )
				  : (
					<>
						<DisplaySimpleHeaderV2 />
					</>
				    )}
			</>
    )
  }

  return (
		<>
			<ScrollToTopOnMount />
			<DisplayNoFooter />
			{renderPageHeader()}
			<Helmet>
				<body
					className={'body-simple bg-dull body-signup get-number step2-choosing-number-type'}
				/>
			</Helmet>

			<div className="full-width progress-container">
				<div className="row">
					<div className="col-md-12 text-center pl-0 pr-0" id="start">
						<ProgressSteps
							current={1}
							steps={props.isLoggedIn ? 3 : 4}
							history={props.history}
							loggedIn={props.isLoggedIn}
							changeStep={step => { props.handleBack(step) }}
						/>
					</div>
				</div>
			</div>
			{renderMain()}
		</>
  )
}

export default GetNumber
