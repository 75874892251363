import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'

class ManualCapsuleCRM extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      activeKey: this.props.match.params.activeTab ? this.props.match.params.activeTab : 'installation',
      showHandsetOrder: false
    }
  }

  render () {
    return (
          <>
            <ScrollToTopOnMount />
            <DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
              <title>Capsule CRM integration</title>
              <meta
                name="description"
                content="Automatically synchronise your Capsule CRM database with SwitchboardFREE for a more connected phone system."
              />
            </Helmet>
            <header
              style={{
                backgroundImage: "url('/images/backgrounds/howitworks-bg.jpg')"
              }}
              className="full-width full-hero-small with-text"
            >
              <h1>Capsule CRM integration</h1>
              <h2 className="subtitle">
                Automatically synchronise your Capsule CRM database with <br className="d-none d-md-block"/>SwitchboardFREE for a more connected phone system.
              </h2>
            </header>
            <div className="container-styled">
              <div className="container pb-100 pt-60">
                <div className="row">
                  <div className="col-md-12">
                    <div className="w-800px center-block">
                      <div className="ratio ratio-16x9 brad-20">
                          <iframe src="https://www.youtube.com/embed/OQ_iFA5KVXY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="full-width bg-dull">
              <div className="container">
                <div className="row text-center mb-30 mt-30">
                  <div className="col-md-8 mx-auto">
                    <div className="row">
                      <div className="col-xs-12 mb-30">
                        <h3 className="text-500">Other devices manuals</h3>
                      </div>
                      <div className="col-xs-4 col-xxs-12 mb-20">
                        <Link to={'/setup/wireless-phone'}>
                          <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                            <img
                              src="/images/product-shots/ecom/W52P.jpg"
                              className="img-responsive"
                            />
                            <h5 className="text-500 text-sm-md">
                              Wireless Phone
                            </h5>
                            <Link
                              to={'/setup/wireless-phone'}
                              className="btn btn-xs btn-cta mt-10 mb-20"
                            >
                              See Instructions
                            </Link>
                          </div>
                        </Link>
                      </div>
                      <div className="col-xs-4 col-xxs-12 mb-20">
                        <Link to={'/setup/app'}>
                          <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                            <img
                              src="/images/product-shots/ecom/app.jpg"
                              className="img-responsive"
                            />
                            <h5 className="text-500 text-sm-md">App</h5>
                            <Link
                              to={'/setup/app'}
                              className="btn btn-xs btn-cta mt-10 mb-20"
                            >
                              See Instructions
                            </Link>
                          </div>
                        </Link>
                      </div>
                      <div className="col-xs-4 col-xxs-12 mb-20">
                        <Link to={'/setup/softphone'}>
                          <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                            <img
                              src="/images/product-shots/ecom/softphone.jpg"
                              className="img-responsive"
                            />
                            <h5 className="text-500 text-sm-md">
                              PC Softphone
                            </h5>
                            <Link
                              to={'/setup/softphone'}
                              className="btn btn-xs btn-cta mt-10 mb-20"
                            >
                              See Instructions
                            </Link>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
    )
  }

  componentDidMount () {

  }
}
export default connect(
  state => {
    return state
  },
  {}
)(ManualCapsuleCRM)
