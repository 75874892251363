// export const numberHelperOption = ({"none": 0, "national": 1, "local": 2, "london": 3});

export const numberTypes = [
  {
    numberType: '0203',
    title: '0203 London Numbers',
    subtitle: 'London presence in our capital with international appeal'
  },
  {
    numberType: '01',
    title: 'Local Geographic Numbers',
    subtitle: 'We love to shop locally, and when searching for local businesses we like to call a local number'
  },
  {
    numberType: '03',
    title: '03 Numbers',
    subtitle: '03 numbers give your business a national presence and offer your customers a mobile-friendly option to call you, making them great for customer support lines.'
  },
  {
    numberType: '0800',
    title: '0800 Numbers',
    subtitle: 'Calls made to 0800 numbers from both landline and mobile phones are completely free'
  },
  {
    numberType: '0808',
    title: '0808 Numbers',
    subtitle: 'Calls made to 0807 numbers from both landline and mobile phones are completely free'
  },
  {
    numberType: '0207',
    title: '0207 Numbers',
    subtitle: 'Rarely available and highly prestigious, having one for your business will make it appear as though you operate from London'
  },
  {
    numberType: '084',
    title: '084 Numbers',
    subtitle: 'An 084 Business number will give your business a professional sound and national presence instantly',
    priceFrom: 0
  }
]
