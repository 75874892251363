import React from 'react'
import { Button } from 'react-bootstrap'
import { type RouteComponentProps } from 'react-router'

interface PageGetStartedCtaProps {
  mainText: string
  mainColouredText: string
  smallText: string
  history: RouteComponentProps['history']
}

const PageGetStartedCta = (props: PageGetStartedCtaProps) => {
  const handleSignup = () => {
    localStorage.setItem('landingPage', 'true')
    props.history.push('/get-started')
  }

  return (
        <div className="mt-40 mb-30">
            <div className="col bg-light b-1 brad-10 box-shadow-v3 p-30 clearfix">
                <span className="d-inline-block float-start text-darker col-12 col-md-5">
                    <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                        {props.smallText}
                    </span>
                    <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-10" style={{ lineHeight: '1em' }}>
                        <span className="text-cta"> {props.mainColouredText} </span> {props.mainText}
                    </p>
                </span>

                <span className="d-inline-block float-end col-12 col-md-6">
                    <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md m-5 mt-0" onClick={() => { handleSignup() }} id="GetStartedButton">Get started</Button>
                    <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md m-5 mt-0">Call us now</a>
                </span>
            </div>
        </div>
  )
}

export default PageGetStartedCta
