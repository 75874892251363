import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import DisplayMainFooter from "../../../components/DisplayMainFooter";
import DisplayMainHeader from '../../../components/DisplayMainHeader';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';

const _ = require('lodash')

class KeywordTriggersSalesPage extends React.Component {
  render() {
    return <>
      <ScrollToTopOnMount />
      <DisplayMainHeader />
      <DisplayMainFooter />
      <Helmet>
        <title>Keyword Triggers & Alerts</title>
        <meta name="description" content="Introducing SwitchboardFREE's Innovative Feature - Ensuring Quality, Compliance and Respect in Every Conversation." />
        <style type="text/css">{`
                       .no-bg{
                            background-image:none !important;
                       }
                       .well, .well.well-transparent p,.well.well-transparent h5{
                        color:#3b3b3b !important;
                       }

                    `}</style>
      </Helmet>

      <header>
        <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/call-filtering.jpg')" }}>
          <h1 id="KeywordsTriggersSalesH1">Keyword Triggers & Alerts</h1>
          <h2 className="subtitle">Ensuring Quality, Compliance and Respect in Every Conversation</h2>
        </div>
      </header>

      <div className={'full-width bg-light'}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10">

              <div className="row mt-100 mb-60">
                <div className="col-md-6 col-sm-12 order-last">
                  <img src="/images/call-transcription2.jpg" alt="Call Recording with transcription" className="img-responsive brad-20 " style={{ maxWidth: '600px' }} />
                </div>
                <div className="col-md-6 col-sm-12 order-first">
                  <h2 className="text-500 text-darker">
                    Elevate Your Communication with SwitchboardFREE
                  </h2>
                  <h2 className="text-lg text-400 mb-30 mt-10 text-dark-light w-600px">
                    Harness the Power of Real-Time Monitoring with Keyword Triggers & Alerts
                  </h2>

                  <p>
                    In the world of business communication, every word matters. Whether you're looking to ensure compliance, maintain quality control, or protect your team from unpleasant interactions, SwitchboardFREE has you covered.
                  </p>

                  <p>
                    Keyword Triggers & Alerts, equips you with the power to monitor your call conversations like never before. By setting up alerts for specific words or phrases, you can gain real-time insights into your conversations and take immediate action when necessary.
                  </p>

                  <Link variant="cta" className="btn btn-cta btn-lg mt-30 mb-0" to={'/get-started'} > Create account </Link>

                </div>
              </div>

              <div className="row mt-100 mb-60">
                <div className="col-md-6 col-sm-12 order-md-first text-end pt-100 pt-xs-0">
                  <img src="/images/keyword-triggers.jpg" alt="Keyword Triggers with transcription" className="img-responsive brad-20 " style={{ maxWidth: "600px" }} />
                </div>
                <div className="col-md-6 col-sm-12 order-first order-md-first">
                  <h2 className="text-500 text-darker">
                    Embrace Next-Level Communication Standards
                  </h2>
                  <h2 className="text-lg text-400 mb-30 mt-10 text-dark-light w-600px">
                    Safeguard Compliance, Promote Respect, and Take Control with SwitchboardFREE
                  </h2>

                  <p>
                    SwitchboardFREE is committed to providing you with not just a VoIP service, but a smarter communication experience. With this feature, you have more control over your business conversations than ever before.</p>

                  <ul className="styled-list success rounded text-start text-400 mt-40 pl-0">
                    <li className="pb-10">
                      <strong>Instant Alerts:</strong> Experience the magic of real-time notifications. Immediately after a call, get alerted when trigger words are spoken, or missed, facilitating swift responses to any situation.
                    </li>
                    <li className="pb-10">
                      <strong>Assured Compliance:</strong> Keep your sales team protocol-perfect. Set alerts for mandatory phrases, and know immediately if they're skipped.
                    </li>
                    <li className="pb-10">
                      <strong>Respectful Communication:</strong> Shield your staff from offensive language and maintain professionalism. Receive alerts for inappropriate phrases, ensuring a respectful environment.
                    </li>
                    <li className="pb-10">
                      <strong>Full Control:</strong> Monitor all or specific numbers at your discretion. With Keyword Triggers & Alerts, enjoy seamless control tailored to your business needs.
                    </li>
                  </ul>

                  <Link variant="cta" className="btn btn-cta btn-lg mt-30 mb-0" to={'/get-started'} > Create account </Link>

                </div>
              </div>

              <div className="row mt-100 mb-60 justify-content-center">
                <div className="col-10 text-center box-shadow-v3 p-40 brad-20">
                  <h2 className="text-lg text-400 mb-30 mt-10 text-dark-light">
                    So why wait? Start using Keyword Triggers & Alerts today and keep your business communication on track, respectful, and compliant.
                  </h2>

                  <Link variant="cta" className="btn btn-cta btn-lg mt-30 mb-0" to={'/get-started'} > Create account </Link>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  }
}

export default (KeywordTriggersSalesPage)
