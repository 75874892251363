import React from 'react'
import { Route } from 'react-router-dom'

const DepartmentButton = props => (
    <Route render={({ history }) => (
        <><input type='button' className={'btn img-circle'.concat(props.checked ? ' active' : '').concat(props.isEnabled ? ' turned-on' : ' switched-off')} name='departmentNumber' value={props.depNo}
                     onClick={e => {
                       history.push('/customer/numbers/' + props.queueNo + '/' + e.target.value)
                     }}/></>
    )}/>
)

export default DepartmentButton
