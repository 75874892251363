import React from 'react'
import { Button, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import { validationStates } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { getTelValidationState, handleNotificationChange, validateTel } from '../../store/SBF'
import LoadingNotification from '../LoadingNotification'

const _ = require('lodash')

class TeamManagement extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      teamNameUsed: false
    }
  }

  handleFetchTeams () {
    // Get teams
    const context = this
    const url = 'api/Dashboard/GetTeams'
    fetchHelper.getJson(url)
      .then(response => {
        const data = response.data
        context.setState({ teams: data, isLoading: false })
      }).catch(err => console.error('Error', err))
  }

  async componentDidMount () {
    this.handleFetchTeams()
    if (this.props.createTeam) { this.handleAddTeam() }
  }

  handleTeamNameChanged (event) {
    const newName = event.target.value
    const context = this
    const url = 'api/Dashboard/IsTeamNameUsed/' + newName

    fetchHelper.getJson(url).then(response => {
      context.setState({ showNameError: false, teamNameUsed: response.data })
    }).catch(err => console.error('Error', err))

    context.setState({ teamToAdd: { ...context.state.teamToAdd, name: newName } })
  }

  handleEditTeamNameChanged (event) {
    const newName = event.target.value
    const context = this
    const url = 'api/Dashboard/IsTeamNameUsed/' + newName

    fetchHelper.getJson(url).then(response => {
      context.setState({ showNameError: false, teamNameUsed: response.data })
    }).catch(err => console.error('Error', err))

    context.setState({ teamToEdit: { ...context.state.teamToEdit, name: newName } })
  }

  componentDidUpdate () {
    if (this.props.telValidationState && this.props.telValidationState.valid === validationStates.INVALID && !this.state.showNumberError) {
      this.setState({ showNumberError: true, errorMsg: this.props.telValidationState.errorMsg ? this.props.telValidationState.errorMsg : 'Please enter a valid phone number' })
    } else if (this.props.telValidationState && this.props.telValidationState.valid === validationStates.VALID && this.state.showNumberError === true) {
      this.setState({ showNumberError: false })
    }
  }

  render () {
    return (
            <>
            <Modal className='w-900px' show={this.state.showUsersAndTeamsVideo} onHide={e => this.setState({ showUsersAndTeamsVideo: false })}>
                <Modal.Header closeButton className='bb-0' />
                <Modal.Body className="text-center">
                <div className="ratio ratio-16x9 pt-20">
                    <iframe src="https://www.youtube.com/embed/5EEAqlWXHws" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>
                </Modal.Body>
            </Modal>

                {this.state.isLoading
                  ? <LoadingNotification loadingText={'Loading your teams'} isLoading={this.state.isLoading} textColor="text-dark"
                        width={100} height={100} />
                  : <>

                  {this.state.teams && this.state.teams.length > 5 && <div className="pb-30 text-end pt-30 pb-30">
                        <Button id="AddNewTeamButton" variant="cta" size="sm" onClick={() => this.handleAddTeam()}>Add new team</Button>
                    </div>
                    }
                        {this.state.teams && this.state.teams.length > 0 &&
                            <>
                                {/* {!this.props.isWizard &&
                                    <div className="row mb-30">
                                        <div className="col-xs-12 col-md-6 col-sm-5 pt-10">
                                            <h5 className="float-start mr-10 mt-0">My Teams</h5>
                                            <span className="badge bg-cta text-xs text-500">{this.state.teams ? this.state.teams.length : 0}</span>
                                        </div>
                                    </div>
                                } */}
                                {this.state.teams && this.state.teams.map((team, i) => {
                                    return (
                                        <div id={`${team.name.replace(/\s/g, '')}Row`} className="text-400 well well-light well-sm box-shadow pt-10 pb-10 zoom brad-20 mb-20">
                                            <div className="col-xs-12 pt-20 pb-10 text-center text-sm-start">
                                                <div className="row pl-30 pr-30">
                                                    <div className="col-xs-8">
                                                        <div className="row">
                                                            <div className="col-xs-12 col-sm-8 pl-0 text-500">
                                                                <h4 className='text-secondary text-500 cursor-pointer m-0' onClick={() => this.handleEditTeam(team.id)}>
                                                                    {team.name}
                                                                </h4>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-4 pl-0">
                                                                <span className='text-dark underline cursor-pointer' onClick={() => this.handleEditTeam(team.id)}>{team.members} users</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-4 pr-0 text-center text-sm-end">
                                                        <Button id={`DeleteTeamButton${team.name.replace(/\s/g, '')}`} variant="secondary" size='sm' className='btn-rounded mr-5' onClick={() => this.setState({ showDeleteWarning: true, teamToDelete: team })}>
                                                            Delete
                                                        </Button>
                                                        <Button id={`SettingsTeamButton${team.name.replace(/\s/g, '')}`} variant="cta" size='sm' className='btn-rounded' onClick={() => this.handleEditTeam(team.id)}>
                                                            Settings
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  )
                                })}
                            </>
                        }

                    </>
                }

                {this.state.teams && this.state.teams.length > 0
                  ? <div className="pb-30 text-end pt-30 pb-30">
                        <Button variant="cta" size="sm" onClick={() => this.handleAddTeam()}>Add new team</Button>
                    </div>
                  : <div className="p-30 text-center box-shadow-v3 bg-light brad-20 w-600px center-block mb-60">
                        <h4 className='text-500'>Add your 1st team</h4>
                        <p className='pt-20 text-start pb-30'>
                          It looks like you don&apos;t have any teams created. When you create your team, you will be able to select it in the&nbsp;
                          
                          <OverlayTrigger placement="top" 
                              overlay={<Popover>
                              <img src='/images/redirects2.jpg' className='img-responsive' />
                              </Popover>}>
                               <span className='underline text-cta'>numbers settings redirects section</span>
                          </OverlayTrigger>.
                          </p>
                          <p>
                          This way when someone calls your SwitchboardFREE number, it will ring all of your team members at once. This will reduce the chances of missing a call and reduce the callers wait time.
                        </p>
                        <Button variant="cta" size="lg" onClick={() => this.handleAddTeam()}>Add your first team</Button> <span className='btn btn-lg btn-secondary' onClick={() => this.setState({ showUsersAndTeamsVideo: true })}>See video</span>
                    </div>
                }
                <Modal className="modal-small question-modal" show={this.state.showGenericError} onHide={() => this.closeGenericError()}>
                    <Modal.Body className="text-center">
                        <div className="icon-wrapper mt-20 mb-20">
                            <span className="icon icon-warning"></span>
                        </div>
                        <h4>Oops, something went wrong</h4>
                        <p>{this.state.msg}</p>
                    </Modal.Body>
                    <Modal.Footer className="text-center bt-0 p-0 mt-30">
                        <Button variant="default" size="block" className="brad-0 pt-20 pb-20 w-100" onClick={() => this.closeGenericError()}>Close</Button>
                    </Modal.Footer>
                </Modal>

                {this.state.teamToAdd &&
                    <Modal className="modal-med new-team" show={this.state.createTeam} onHide={() => this.setState({ createTeam: false })}>
                        <span className="close-modal" onClick={() => this.setState({ createTeam: false })}>
                            <span>&times;</span>
                        </span>
                        <Modal.Body>
                            <div className="col-md-12 text-center pb-40">
                                <h5 className="text-center text-500" id="AddNewTeamHeader">Add new team</h5>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <Form.Group>
                                        <Form.Control
                                            id="AddTeamNameInput"
                                            className={this.state.showNameError ? 'has-error' : ''}
                                            value={this.state.teamToAdd.name}
                                            onChange={e => this.handleTeamNameChanged(e)}
                                        />
                                        {this.state.showNameError &&
                                            <span className="text-sm text-danger">This team name already exists</span>
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mt-30 mb-10">
                                <div className="col-xs-12 col-sm-6 text-sm mt-0 mb-10">
                                    Select users
                                </div>
                                {!this.state.addNumberToTeam &&
                                    <div className="col-xs-12 col-sm-6 text-end">
                                        <span className="text-primary text-500 cursor-pointer" onClick={() => this.handleAddNumberToTeam()} id="AddTeamAddNumberLink">
                                            Add number
                                        </span>
                                    </div>}
                            </div>
                            {this.state.addNumberToTeam &&
                                <>
                                    <Form className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group mb-5">
                                            <Form.Group>
                                                <Form.Control
                                                    id="AddTeamPhoneNumberInput"
                                                    className={`${this.state.showNumberError ? 'has-error' : ''}`}
                                                    placeholder="Phone number"
                                                    value={this.state.numberToAdd.number}
                                                    onChange={e => this.setState({ numberToAdd: { ...this.state.numberToAdd, number: e.target.value } })}
                                                    onBlur={e => this.props.validateTel({ newValue: e.target.value })} />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="form-group mb-5">
                                            <Form.Group>
                                                <Form.Control
                                                    id="AddTeamPhoneNameInput"
                                                    placeholder="Name (optional)"
                                                    value={this.state.numberToAdd.name}
                                                    onChange={e => this.setState({ numberToAdd: { ...this.state.numberToAdd, name: e.target.value } })} />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Form>
                                    {this.state.showNumberError &&
                                        <div className="col-sm-12">
                                            <p className="text-400 text-danger text-sm">
                                                {this.state.errorMsg}
                                            </p>
                                        </div>
                                    }
                                </>
                            }
                            {!this.state.addNumberToTeam &&
                                this.state.users && this.state.users.map((item, i) => {
                              return (
                                        <div className="row pl-20 pr-20 text-sm teams-user-row">
                                            <div className={`col-xs-12 pt-20 pb-20 cursor-pointer ${i === this.state.users.length - 1 ? '' : 'bb-1'}`} onClick={() => this.handleToggleUser(i)}>
                                                <div className="row text-sm">
                                                    <div id={`AddNewTeamUsername${i}`} className="col-xs-12 col-sm-5 text-500 pl-0">
                                                        {item.description}
                                                    </div>
                                                    <div id={`AddNewTeamUserExt${i}`} className="col-xs-6 col-sm-4 pl-0 text-500">
                                                        {item.itemDetail}
                                                    </div>
                                                    <div className="col-xs-6 col-sm-3 pr-0 text-end">
                                                        <span className="styled-checkbox checkbox-light float-end" style={{ margin: '-12px 0 0 -30px' }}>
                                                            <Form.Check inline
                                                                checked={item.checked} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                              )
                            })}
                            {!this.state.addNumberToTeam &&
                                this.state.numbersToAdd && this.state.numbersToAdd.map((item, i) => {
                              return (
                                        <div className="row pl-20 pr-20 text-sm teams-user-row">
                                            <div className={`col-xs-12 pt-20 pb-20 cursor-pointer ${i === this.state.users.length - 1 ? '' : 'bb-1'}`}>
                                                <div className="row text-sm">
                                                    <div className="col-xs-12 col-sm-5 text-500 pl-0">
                                                        {item.description}
                                                    </div>
                                                    <div className="col-xs-6 col-sm-4 pl-0 text-500">
                                                        {item.itemDetail}
                                                    </div>
                                                    <div className="col-xs-6 col-sm-3 pr-0 text-end">
                                                        <span className="styled-checkbox checkbox-light float-end" style={{ margin: '-12px 0 0 -30px' }}>
                                                            <Form.Check inline
                                                                checked={item.checked} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                              )
                            })}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button id={this.state.addNumberToTeam ? 'AddNewTeamAddNumberButton' : 'AddNewTeamCreateTeamButton'} variant="cta" className='float-end' disabled={this.state.addNumberToTeam ? false : (this.state.teamToAdd.name === '' || this.state.teamNameUsed === true)} onClick={() => this.state.addNumberToTeam ? this.handleAddNumberToNewTeam() : this.handleSaveNewTeam()}>
                                {this.state.addNumberToTeam ? 'Add Number' : 'Create Team'}
                            </Button>
                            {this.state.addUserToTeam || this.state.addNumberToTeam
                              ? <Button id="AddNewTeamCancelButton" className="float-start" onClick={() => this.handleCancelAdding()}>Cancel</Button>
                              : <Button id="AddNewTeamCloseButton" className="float-start" onClick={() => this.setState({ createTeam: false })}>Close</Button>
                            }
                        </Modal.Footer>
                    </Modal>
                }

                <Modal className="modal-med" show={this.state.editTeam} onHide={() => this.setState({ editTeam: false, createTeam: false, teamToEdit: undefined, addUserToTeam: false, addNumberToTeam: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="text-start text-500" id="EditTeamHeader">
                                {this.state.teamToEdit && this.state.teamToEdit.name && this.state.teamToEdit.name.length > 0
                                  ? `Editing ${this.state.teamToEdit.name}`
                                  : 'Edit team'
                                }
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.editTeam && this.state.teamToEdit &&
                            <>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Edit team name</Form.Label>
                                                <Form.Control
                                                    id="EditTeamNameInput"
                                                    value={this.state.teamToEdit.name}
                                                    onChange={e => this.handleEditTeamNameChanged(e)}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                                <div className="row text-sm mt-30 mb-10">
                                    <div className="col-xs-6 text-500">
                                        {this.state.addNumberToTeam ? 'Add a number to team' : this.state.addUserToTeam ? 'Add user(s) to team' : 'Users'}
                                    </div>
                                    {!this.state.addUserToTeam
                                      ? <div className="col-xs-6 text-end">
                                            <span className="text-primary text-500 cursor-pointer" onClick={() => this.handleAddUserToTeam()} id="AddUserRingGroupLink">
                                                + Add user
                                        </span>
                                        </div>
                                      : !this.state.addNumberToTeam &&
                                        <div className="col-xs-6 text-end">
                                            <span className="text-primary text-500 cursor-pointer" onClick={() => this.handleAddNumberToTeam()} id="AddNumberRingGroupLink">
                                                Add number
                                                </span>
                                        </div>
                                    }
                                </div>
                                {this.state.addNumberToTeam &&
                                    <div className="row">
                                        <Form>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-5">
                                                    <Form.Group>
                                                    <Form.Control
                                                        id="EditTeamPhoneNumberInput"
                                                        className={`${this.state.showNumberError ? 'has-error' : ''}`}
                                                        placeholder="Phone number"
                                                        value={this.state.numberToAdd.number}
                                                        onChange={e => this.setState({ numberToAdd: { ...this.state.numberToAdd, number: e.target.value } })}
                                                        onBlur={e => this.handleValidateNumber(e.target.value)} />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-5">
                                                <Form.Group>
                                                    <Form.Control
                                                        id="EditTeamPhoneNameInput"
                                                        placeholder="Name (optional)"
                                                        value={this.state.numberToAdd.name}
                                                        onChange={e => this.setState({ numberToAdd: { ...this.state.numberToAdd, name: e.target.value } })} />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Form>
                                        {this.state.showNumberError &&
                                            <div className="col-sm-12">
                                                <p className="text-400 text-danger text-sm">
                                                    {this.state.errorMsg}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                }
                                {this.state.addUserToTeam && !this.state.addNumberToTeam &&
                                    this.state.users && this.state.users.map((item, i) => {
                                  return (
                                            <div className="row pl-20 pr-20 text-sm teams-user-row">
                                                <div className={`col-xs-12 pt-20 pb-20 cursor-pointer ${i === this.state.users.length - 1 ? '' : 'bb-1'}`} onClick={() => this.handleToggleUser(i)}>
                                                    <div className="row text-sm">
                                                        <div id={`EditTeamUsername${i}`} className="col-xs-12 col-sm-5 text-500 pl-0">
                                                            {item.description}
                                                        </div>
                                                        <div id={`EditTeamUserExt${i}`} className="col-xs-6 col-sm-4 pl-0 text-500">
                                                            {item.itemDetail}
                                                        </div>
                                                        <div className="col-xs-6 col-sm-3 pr-0 text-end">
                                                            <span className="styled-checkbox checkbox-light float-end" style={{ margin: '-12px 0 0 -30px' }}>
                                                                <Form.Check inline
                                                                    checked={item.checked} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                  )
                                })
                                }
                                {!this.state.addUserToTeam && !this.state.addNumberToTeam && this.state.teamToEdit.members.map((item, i) => {
                                  return (
                                        <div className="row pl-20 pr-20 text-sm">
                                            <div className={`col-xs-12 pt-20 pb-20 ${i === this.state.teamToEdit.members.length - 1 ? '' : 'bb-1'}`}>
                                                <div className="row text-sm">
                                                    <div id={`EditTeamUsername${i}`} className="col-xs-12 col-sm-5 text-500 pl-0 text-secondary text-md">
                                                        {item.description}
                                                    </div>
                                                    <div id={`EditTeamUserExt${i}`} className="col-xs-6 col-sm-4 pl-0 text-500">
                                                        {item.itemDetail}
                                                    </div>
                                                    <div className="col-xs-6 col-sm-3 pr-0 text-end">
                                                        <Button id={`EditTeamDeleteButton${i}`} variant="transparent" className="p-0 m-0 text-dark" onClick={() => this.deleteMemberFromTeam(item.id)}>
                                                            <i className="icon icon-bin"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  )
                                })}
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.isLoading
                          ? <LoadingNotification loadingText={'Saving, pleast wait...'} isLoading={this.state.isLoading} textColor="text-dark"
                                width={50} height={50} />
                          : <>
                                <Button id="SaveTeamEditButton" variant="cta" size="sm" onClick={() => this.state.addNumberToTeam ? this.handleSaveNumberToTeam() : this.state.addUserToTeam ? this.handleAddUsersToTeam() : this.handleSaveTeam()}
                                    disabled={this.state.teamNameUsed === true || (this.state.addNumberToTeam && (this.state.showNumberError || this.state.numberToAdd.number.length < 10))}>
                                    Save
                                </Button>
                                {this.state.addUserToTeam || this.state.addNumberToTeam

                                  ? <Button id="CancelTeamEditButton" variant="primary" size="sm" onClick={() => this.handleCancelAdding()}>Cancel</Button>
                                  : <Button id="CloseTeamEditButton" variant="primary" size="sm" onClick={() => this.setState({ editTeam: false, createTeam: false, teamToEdit: undefined, addUserToTeam: false, addNumberToTeam: false })}>Close</Button>

                                }
                            </>
                        }
                    </Modal.Footer>
                </Modal>

                {this.state.teamToDelete &&
                    <Modal className="modal-small question-modal" show={this.state.showDeleteWarning} onHide={() => this.setState({ showDeleteWarning: false, teamToDelete: undefined })}>
                        <Modal.Body className="text-center">
                            <div className="icon-wrapper mt-20 mb-20">
                                <span className="icon icon-warning"></span>
                            </div>
                            <h4>Are you sure?</h4>
                            <p>You&apos;re about to delete a team ({this.state.teamToDelete.name}), this action cannot be reversed.</p>
                        </Modal.Body>
                        <Modal.Footer className="text-center bt-0 p-0 mt-30">
                            <Button id="DeleteModalCancelButton" variant='cta' size="block" className="brad-0 pt-20 pb-20 w-50" onClick={() => this.setState({ showDeleteWarning: false, teamToDelete: undefined })}>Cancel</Button>
                            <Button id="DeleteModalDeleteButton" variant="danger" size="block" className="brad-0 pt-20 pb-20 w-50" onClick={() => this.handleDeleteTeam(this.state.teamToDelete.id)}>Delete</Button>
                        </Modal.Footer>
                    </Modal>
                }

            </>
    )
  }

  handleValidateNumber (number) {
    const url = '/api/Users/isValidTelephone'
    fetchHelper.postJson(url, { phoneNumber: number })
      .then(res => {
        return res.data
      }).catch(err => console.error(err))
  }

  handleValidateNumbertoAdd () {
    const num = this.state.numberToAdd
    if (num) {
      if (_.isEmpty(num.number)) {
        this.setState({ showNumberError: true, errorMsg: 'Please enter a number' })
      }
    } else {
      this.setState({ showNumberError: true, errorMsg: 'Please enter a number' })
    }
  }

  handleAddNumberToNewTeam () {
    const context = this
    context.handleValidateNumbertoAdd()
    let arr = []
    if (context.state.numbersToAdd && context.state.numbersToAdd.length > 0) { arr = context.state.numbersToAdd }

    const data = {
      ItemType: 1,
      itemDetail: context.state.numberToAdd.number,
      description: context.state.numberToAdd.name
    }

    arr.push(data)

    context.setState({ numbersToAdd: arr, addNumberToTeam: false })
  }

  handleSaveNumberToTeam () {
    const context = this
    context.handleValidateNumbertoAdd()
    if (context.state.showNumberError !== true) {
      const arr = []
      const data = {
        ItemType: 1,
        itemDetail: context.state.numberToAdd.number,
        Description: context.state.numberToAdd.name
      }
      arr.push(data)

      context.setState({ isLoading: true }, () => {
        const url = '/api/Dashboard/AddMembersToTeam'
        fetchHelper.postJson(url, { TeamId: context.state.teamToEdit.id, Members: arr })
          .then(res => {
            context.setState({ isLoading: false, users: res.data === true ? undefined : context.state.users, teamToAdd: res.data === true ? undefined : context.state.teamToAdd, createTeam: res.data !== true }, () => {
              context.handleFetchTeams()
              context.handleCancelAdding()
              context.handleEditTeam(context.state.teamToEdit.id)
            })
            context.props.notify({ message: res.data === true ? 'Your number has been added.' : 'There was a problem adding your number. Please try again, or contact support.', isError: res.data === false })
          }).catch(err => console.error(err))
      })
    }
  }

  handleCancelAdding () {
    if (this.state.addNumberToTeam) {
      this.setState({
        addUserToTeam: false,
        addNumberToTeam: false
      })
    } else {
      this.setState({
        addUserToTeam: false,
        addNumberToTeam: false
      })
    }
  }

  handleSaveNewTeam () {
    const context = this
    // Check team name
    const teams = context.state.teams.filter(f => f.name === context.state.teamToAdd.name)
    if (teams.length > 0) {
      // Already exists
      context.setState({ showNameError: true })
      context.props.notify({ message: 'That name already exists', isError: true })
    } else {
      let members = context.state.users.filter(f => f.checked)
      if (context.state.numbersToAdd && context.state.numbersToAdd.length > 0) { members = [...members, ...context.state.numbersToAdd] }

      const data = {
        Name: context.state.teamToAdd.name,
        Members: members
      }

      const url = 'api/Dashboard/CreateNewTeam'
      fetchHelper.postJson(url, data)
        .then(res => {
          context.setState({ users: res.data === true ? undefined : context.state.users, teamToAdd: res.data === true ? undefined : context.state.teamToAdd, createTeam: res.data !== true }, () => {
            context.handleFetchTeams()
          })
          this.props.notify({ message: res.data === true ? 'Your new team has been saved.' : 'There was a problem saving your team. Please try again, or contact support.', isError: res.data === false })
        }).catch(err => console.error(err))
    }
  }

  handleToggleUser (i) {
    const users = this.state.users
    users[i] = {
      ...users[i],
      checked: !users[i].checked
    }

    this.setState({
      users
    })
  }

  handleAddUserToTeam () {
    const context = this
    this.setState({ addUserToTeam: true }, () => {
      if (!context.state.users) { this.fetchUsers() }
    })
  }

  handleAddNumberToTeam () {
    this.setState({ addNumberToTeam: true, numberToAdd: { name: '', number: '' } })
  }

  handleAddTeam () {
    const context = this
    context.setState({
      createTeam: true,
      teamNameUsed: false,
      teamToAdd: { name: 'My new team' }
    }, () => {
      if (!context.state.users) { this.fetchUsers() }
    })
  }

  fetchUsers () {
    const context = this
    const url = `api/Dashboard/GetSipUsers/${this.state.teamToEdit ? this.state.teamToEdit.id : 0}/${this.state.teamToEdit ? this.state.teamToEdit && this.state.teamToEdit.id > 0 : false}`
    fetchHelper.getJson(url)
      .then(res => {
        context.setState({
          users: res.data
        })
      }).catch(err => console.error(err))
  }

  handleEditTeam (teamId) {
    const context = this
    context.setState({ editTeam: true, teamLoading: true }, () => {
      const url = `api/Dashboard/GetTeamToEdit/${teamId}`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ teamToEdit: res.data, teamLoading: false, teamNameUsed: false })
        }).catch(err => console.error(err))
    })
  }

  handleAddUsersToTeam () {
    const context = this
    const usersToAdd = context.state.users.filter(f => f.checked)
    const data = {
      TeamId: context.state.teamToEdit.id,
      Members: usersToAdd
    }

    const url = 'api/Dashboard/AddMembersToTeam'
    fetchHelper.postJson(url, data)
      .then(res => {
        context.setState({
          addUserToTeam: res.data === false,
          users: res.data === true ? undefined : context.state.users,
          teamToEdit: {
            ...context.state.teamToEdit,
            members: res.data === true ? context.state.teamToEdit.members.concat(usersToAdd) : context.state.teamToEdit.members
          }
        }, () => {
          this.props.notify({ message: res.data === true ? 'Your team has been updated.' : 'There was a problem updating your team. Please try again, or contact support.', isError: res.data === false })
        })
      })
  }

  handleSaveTeam () {
    const context = this
    const url = 'api/Dashboard/EditTeam'
    const data = {
      Id: context.state.teamToEdit.id,
      Members: context.state.teamToEdit.members,
      Name: context.state.teamToEdit.name
    }
    fetchHelper.postJson(url, data)
      .then(res => {
        context.setState({
          isLoading: res.data === true, teamToEdit: res.data === true ? undefined : context.state.teamToEdit, editTeam: res.data === false, createTeam: res.data === false
        }, () => this.handleFetchTeams())
        this.props.notify({ message: res.data === true ? 'Your team has been updated.' : 'There was a problem updating your team. Please try again, or contact support.', isError: res.data === false })
      }).catch(err => console.error(err))
  }

  deleteMemberFromTeam (id) {
    const context = this
    const url = 'api/Dashboard/RemoveTeamMember'
    const data = {
      MemberId: id,
      TeamId: context.state.teamToEdit.id
    }

    fetchHelper.postJson(url, data)
      .then(res => {
        context.setState({
          teamToEdit: {
            ...context.state.teamToEdit,
            members: context.state.teamToEdit.members.filter(f => f.id !== id)
          }
        })
        this.props.notify({ message: res.data === true ? 'Your team has been updated.' : 'There was a problem updating your team. Please try again, or contact support.', isError: res.data === false })
      }).catch(err => console.error(err))
  }

  handleDeleteTeam (id) {
    const context = this
    const url = `api/Dashboard/RemoveTeam/${id}`
    fetchHelper.postJson(url)
      .then(res => {
        context.setState({
          teamToDelete: undefined,
          showDeleteWarning: false,
          teams: context.state.teams.filter(f => f.id !== id)
        })
        this.props.notify({ message: res.data === true ? 'Your team has been removed.' : 'There was a problem removing this team. Please try again, or contact support.', isError: res.data === false })
      }).catch(err => console.error(err))
  }
}

export default connect(
  state => {
    return {
      clientId: state.sbfApp.authentication.clientId,
      telValidationState: getTelValidationState(state)
    }
  }, {
    validateTel,
    notify: handleNotificationChange
  }
)(TeamManagement)
