import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import TermsAndConditions from '../../Text/TermsAndConditions'

export default class PaymentMethods extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showTsAndCs: false
    }
  }

  render () {
    const align = this.props.align ? this.props.align : 'center'
    return (
            <>
                <div className={`col-md-12 text-${align} ${this.props.small ? '' : 'pt-30'}`}>
                    <img src={'/images/credit-cards/stripe-badge-transparent.png'} width="320" className="worldPay"
                        alt="Payments powered by Stripe" />

                    {!this.props.hideText &&
                    <div className="pt-30 pb-30">
                        <small>
                            By purchasing this product you agree to the <a onClick={e => this.setState({ showTsAndCs: true })} className="underline">terms &amp; conditions</a> of this product.
                            <br />All prices are exclusive of VAT at 20%.
                        </small>
                    </div>
                    }
                </div>
                <Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms &amp; Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TermsAndConditions />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </>
    )
  }
}
