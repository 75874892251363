import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { fetchHelper } from '../../../helpers/fetchHelper'
import TermsAndConditions from '../../../Text/TermsAndConditions'

class PocketSectionHelperDialog extends React.Component {
  state = {
    showTsAndCs: false,
    hasCallRecordingActivated: true,
    ddiLink: undefined
  }

  constructor (props) {
    super(props)
  }

  componentDidMount () {
    const url = '/api/numbers/GetClientsOnlyNumberOrNull'

    fetchHelper.getJson(url).then(res => {
      this.setState({ ddiLink: res.data })
    }).catch(function (error) {
      console.error(error)
    })

    fetchHelper.getJson('api/numbers/ClientHasPocketActivated').then(res => {
      this.setState({ hasCallRecordingActivated: res.data })
    }).catch(function (error) {
      console.error(error)
    })
  }

  render () {
    return <>
    <Modal size="w-900px" className={'brad-b-20 ' + (this.props.liteMode ? ' modal-med' : '') } show={this.props.visable}
           onHide={this.props.onClose}>
               {this.props.liteMode
                 ? <Modal.Header className="p-0">
                    <a onClick={(this.props.onClose)}>
                        <span className="text-dark text-center pointer"
                        style={{
                          width: '25px',
                          height: '25px',
                          border: '2px solid #888',
                          borderRadius: '50%',
                          display: 'block',
                          top: '10px',
                          position: 'absolute',
                          right: '10px',
                          zIndex: '1'
                        }}><span></span>&times;</span>
                    </a>
               </Modal.Header>

                 : <Modal.Header className="p-0 bg-secondary">
            {/* <Modal.Title>More about pocket</Modal.Title> */}
            <a onClick={(this.props.onClose)}>
                <span className="text-lightest text-center pointer"
                style={{
                  width: '25px',
                  height: '25px',
                  border: '2px solid #fff',
                  borderRadius: '50%',
                  display: 'block',
                  top: '10px',
                  position: 'absolute',
                  right: '10px'
                }}><span></span>&times;</span>
            </a>
            <div className="bg-secondary">
                <h1 className="text-lightest p-30" style={{ fontSize: '54px' }}>Live Agent Call <br className="d-none d-md-block"/>Answering, from <br className="d-none d-md-block"/>SwitchboardFREE</h1>
                <img src="images/headers/call-answering-pg.png"
                className="d-none d-md-block"
                style={{ width: '317px', right: '50px', top: '14px', position: 'absolute' }}/>
            </div>
        </Modal.Header>
    }
        <Modal.Body className="pb-0">

            {(this.props.liteMode ? (
            <div className="text-center">
                <h3 className="text-500">Live Agent Call Answering</h3>
                <h5 className="text-500 text-cta">You’re just a click away!</h5>
                <p className="pt-20">
                    Thanks for choosing Live Agent Call Answering - our team are ready and waiting to take your calls.
                </p>
                <p className="pt-10">
                    By clicking the Activate Now button below you agree our terms and conditions - <span onClick={e => this.setState({ showTsAndCs: true })} className="text-cta underline text-400 text-link">view them here</span>. The service will be active right away and can be switched off at any time.
                </p>
            </div>)
              : (
            <div>
                <div className="text-center">
                    <h2 className="text-500">Let us answer <br className="d-block d-xs-none"/>the calls you can’t!</h2>
                    <h4 className="text-500 pb-40">Reliable <span className="text-cta">&bull;</span> Convenient  <span className="text-cta">&bull;</span>  Unrivalled Value</h4>
                </div>
                <div className="row pl-30 pr-30 pb-30 pl-sm-10 pr-sm-10">

            {/* { (this.state.ddiLink !== undefined && this.state.ddiLink !== null && this.state.ddiLink !== '' && this.state.hasCallRecordingActivated === false) &&
                    <div className="col-sm-12 mb-60 text-center">
                        <div className="block highlight-block">
                            <div style={{background:"#fde7d7"}}>
                                <h4 className="text-uppercase text-cta text-500 mt-0 mb-0 pt-10 pb-10 text-italic">Special ‘Free Call’ Giveaway!</h4>
                            </div>
                            <h2 className="text-500 mb-0 pt-0 mt-10"><span className="text-cta">&pound;50 FREE</span> <br className="d-block d-xs-none"/>Call Answering</h2>
                            <h3 className="text-500 pb-20 mt-0">
                                <span className="text-cta">**</span>
                                <span className="underline">When You Try Today!</span>
                                <span className="text-cta">**</span>
                            </h3>
                        </div>
                    </div>
            } */}
                    <div className="col-sm-6">
                        <p className="text-500">
                            With SwitchboardFREE Live Call Answering you’ll never miss a call again.
                        </p>
                        <p className="pt-10 text-400">
                            We can answer all of your calls or just the ones you miss, so you can respond to callers at a time that better suits you.
                        </p>

                        <p className="text-500 pt-10">Your dedicated team will capture and email you the following:</p>
                        <ul className="styled-list list-2 cta pl-10 pt-10 text-400">
                            <li className="pb-10">
                                The caller’s name
                            </li>
                            <li className="pb-10">
                                Return phone number
                            </li>
                            <li className="pb-10">
                                Reason for the call
                            </li>
                            <li className="pb-10">
                                A short message
                            </li>
                            <li className="pb-10">
                                Time and date of the call
                            </li>
                        </ul>
                        <p className="pt-10 text-400">
                        Your callers will always be greeted promptly and professionally, and you can turn us on or off as you need.
                        </p>
                        <p className="text-500 pt-10">
                            This incredible feature has no monthly fees - only pay for the calls we answer.
                        </p>
                        <p className="pt-10 text-400">
                        SwitchboardFREE call answering is available between <span className="text-500">7:00 - 23:00 Mon- Fri and Sat &amp; Sun 9:00 - 17:00</span> (including Bank Holidays).
                        Calls costs <span className="text-500">just £3.40 per call</span>, there are no extra charges, and any call we take will come off your existing call credit.
                        I&apos;s really that simple.</p>
                        <p className="pt-10 text-500">
                            Activate today and see how much faster your business grows!
                        </p>
                    </div>

                    <div className="col-sm-6">
                        <div className="brad-t-10 bg-secondary p-15 text-center">
                            <h4 className="text-lightest text-500">Live Call Answering <br className="d-none d-sm-block"/>Gives You:</h4>
                        </div>
                        <div className="p-10 b-2">
                            <ul className="styled-list list-size-2 rounded pl-20 pt-30">
                                <li>
                                    <span className="text-500">Your Very Own Team</span>
                                    <br/>
                                    <span className="text-italic text-400 text-sm">(Experienced, Dedicated Receptionists)</span>
                                </li>
                                <li>
                                <span className="text-500">Instant Set Up</span>
                                <br/>
                                <span className="text-italic text-400 text-sm">(Activate In Seconds)</span>
                                </li>
                                <li>
                                <span className="text-500">Versatile Options</span>
                                <br/>
                                <span className="text-italic text-400 text-sm">(Answer All Calls or Overflow Only)</span>
                                </li>
                                <li>
                                <span className="text-500">Complete Flexibility</span>
                                <br/>
                                <span className="text-italic text-400 text-sm">(Turn On And Off At Will)</span>
                                </li>
                                <li>
                                <span className="text-500">Zero Commitment</span>
                                <br/>
                                <span className="text-italic text-400 text-sm">(No Monthy Fees, Simply Pay As You Go)</span>
                                </li>
                            </ul>
                        </div>
                        <div className="text-center pt-20 pb-20 brad-b-10 mb-30" style={{ backgroundColor: '#3b3b3b' }}>
                            <h5 className="text-cta text-caps text-500">Incredible Value!</h5>
                            <h4 className="text-400 text-lightest">All for <span className="text-500">JUST £3.40 </span>/call</h4>
                        </div>
                    </div>
                </div>
            </div>
                ))}

            {/* { (this.state.ddiLink !== undefined && this.state.ddiLink !== null && this.state.ddiLink !== '' && this.state.hasCallRecordingActivated === false) ?
                <div className="text-center">
                    <div className="row">
                        <div className="col-md-12 text-center pt-20 pb-50 pl-30 pr-30">
                            <button className="btn btn-cta btn-xl mb-30 mt-20" onClick={ () => { this.props.history.push('/customer/numbers/' + this.state.ddiLink + '/callAnsweringChanged') } }>
                                Activate Now
                            </button>
                        </div>
                    </div>
                </div>
                :
                <></>
            } */}
            {this.props.disableActivate === true
              ? ''

              : <>
                <div className="text-center">
                <div className="row">
                        <div className="col-md-12 text-center pt-20 pb-50 pl-30 pr-30">
                            {this.props.showTCs === true
                              ? <div className="text-400 pb-20">

                            By clicking the Activate Now button below you agree our terms and conditions - <span onClick={e => this.setState({ showTsAndCs: true })} className="text-cta underline text-400 text-link">view them here</span>. The service will be active right away and can be switched off at any time.
                            </div>
                              : ''
                            }
                            {this.props.disableActivate === true
                              ? ''
                              : <a
                            onClick={() => {
                              this.props.onActivate()
                              this.props.onClose()
                            }
                            } className="btn btn-cta btn-xl">Activate NOW</a>}
                            <p className="text-italic text-400 pt-30">
                            Live Call Answering is supplied by Pocket Receptionist.
                            </p>
                        </div>
                    </div>

                    {this.props.liteMode
                      ? <div className="row brad-b-20 p-30" style={{ backgroundColor: '#f6f6f6' }}>
                            <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                            <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                            <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                            <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                            <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                            <h6 className="text-500 text-dark">&quot;Fantastic Service. Would be lost without it&quot;</h6>
                            <p className="text-500 text-dark pt-10 pb-20">Jonathan Hobday</p>
                            <p className="text-400 text-sm text-italic pt-10">
                            Live Call Answering is powered by Pocket Receptionist.
                            Calls will be answered between <span className="text-500">8.30am - 6pm, Monday - Friday</span> and cost just <span className="text-500">&pound;2.90 per call</span>.
                            There are no extra charges (any call we take will come off your existing call credit).
                            </p>
                        </div>
                      : ''
                    }
                    </div>

                </>
            }
              {this.props.liteMode
                ? ''
                : <div className="row" style={{ backgroundImage: 'url(images/backgrounds/pr-bg2.jpg)', backgroundSize: 'cover' }}>
                    <div className="col-md-12 text-center pt-60 pb-60">
                        <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                        <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                        <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                        <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                        <span className="icon icon-star-full text-lg mr-10 text-cta"></span>
                        <h3 className="text-500 text-lightest">&quot;Fantastic Service. Would be lost without it&quot;</h3>
                        <h5 className="text-500 text-lightest">Jonathan Hobday</h5>
                    </div>
                </div>
    }
        </Modal.Body>
    </Modal>

    <Modal size="lg" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="text-sm p-30">

                                    <TermsAndConditions />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
                                    </Modal.Footer>
                                </Modal>
    </>
  };
}

export default withRouter(connect(

)(PocketSectionHelperDialog))
