import React, { Component } from 'react'

export default class TextBannerWidget extends Component {
  render () {
    return (
            <div id="text-banner-widget"></div>
    )
  }

  componentDidMount () {
    // create widget code
    const widgetInit = document.createElement('script')
    widgetInit.type = 'text/javascript'
    widgetInit.async = true
    if (this.props.textDark) {
      widgetInit.innerHTML = "richSnippetReviewsWidgets('text-banner-widget', {store: 'switchboard-free',starsClr: '#f47e27',textClr: '#666',logoClr: '#666', widgetName: 'text-banner', css:'.ReviewsLogo--small .ReviewsLogo__text i {color:#666 !important;}' });"
    } else {
      widgetInit.innerHTML = "richSnippetReviewsWidgets('text-banner-widget', {store: 'switchboard-free',starsClr: '#f47e27',textClr: '#fff',logoClr: '#fff', widgetName: 'text-banner', css:'.ReviewsLogo--small .ReviewsLogo__text i {color:#fff !important;}' });"
    }

    // append to body
    document.body.appendChild(widgetInit)
  }
}
