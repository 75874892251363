import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import CarouselWidget from '../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { isLoggedIn } from '../selectors'

class CallTransfers extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter />  <DisplayMainHeader />
            <Helmet>
                <title>How to transfer calls | SwitchboardFREE</title>
                <meta name="description" content="Find out how to transfer calls on SwitchboardFREE deskphone, cordless phone or softphone." />.
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/plans.jpg')" }} className="full-width full-hero-small with-text" >
                <h1>How to transfer calls</h1>
                <h2 className=" subtitle">Find out how to transfer calls on our deskphone, cordless phone or softphone.</h2>
            </header>

            <div className="full-width bg-light">
                <div className="container">
                    <div className="row row-flex mb-100">
                        <div className="col-sm-6">
                            <div className="bg-dull brad-20 p-30 box-shadow">
                                <h4 className="text-500">Blind transfer</h4>
                                <p>
                                A blind transfer is when you transfer the caller to another users extension without speaking to them first.
                                </p>
                                <p className="text-500 pt-10 pb-20">
                                How to perform a blind transfer on your:<br/>deskphone, softphone and SwitchboardFREE app
                                </p>
                                <ol className="text-400 pl-15">
                                    <li className="pb-10">
                                        <span className="text-500">Softphone:</span>
                                        <br/>
                                        Press the transfer button <span className="text-500">or</span> press #7,
                                        followed by the extension number you wish to transfer the call to.
                                    </li>
                                    <li className="pb-10">
                                        <span className="text-500">Desk phone:</span>
                                        <br/>
                                        Press #7, followed by the extension number you wish to transfer the call to.
                                    </li>
                                    <li className="pb-10">
                                        <span className="text-500">SwitchboardFREE Phone App: </span>
                                        <br/>
                                        Press #7, followed by the extension number you wish to transfer the call to.
                                    </li>
                                </ol>
                                <p>
                                If a call is not answered by the final recipient in 10 seconds then call will return to the original recipient.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="bg-dull brad-20 p-30 box-shadow">
                                <h4 className="text-500">An Assisted transfer</h4>
                                <p>
                                    An assisted transfer is when you transfer the caller to another users extension,
                                    but before the call is put through, you will be able to speak to the other user before transfering the caller to them. This is useful to make a polite introduction.
                                </p>
                                <p className="text-500 pt-10 pb-20">
                                    How to perform a assisted transfer on your:<br/>Softphone, Desk phone &amp; SwitchboardFREE Phone App
                                </p>
                                <ol className="text-400 pl-15">
                                    <li className="pb-10">
                                        Press #8, followed by the extension number of the user you wish to dial.
                                    </li>
                                    <li className="pb-10">
                                        You can then speak to that person before connecting the caller to them.

                                    </li>
                                    <li className="pb-10">
                                        Hang up the call to connect the two parties.
                                    </li>
                                </ol>
                                <p>
                                If a call is not answered by the final recipient in 10 seconds then call will return to the original recipient.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-secondary pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500 text-lightest">Make calls using your virtual number</h3>
                            <p className="text-lightest mb-30">
                                Buy or pre-configured phones and start making and receiving calls.
                            </p>
                            <p>
                                <a href="/phones-apps" className="btn btn-lg btn-cta">
                                    See our phones
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width full-dull">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>
        </>
  }

  // componentDidMount() {
  //     this.getCustomers();
  // }

  // getCustomers() {
  //     let url = 'api/Home/GetTotalCustomers';
  //     fetchHelper.getJson(url)
  //         .then(response => {
  //             if (response !== undefined)
  //                 this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) })
  //         })
  //         .catch(error => {
  //             console.error(error);
  //         })
  // }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state)
    }
  }
  // {
  //     login: fromHome.handleLogin
  // }
)(CallTransfers)
