import React from 'react'
import { SignupStep } from '../constants/Constants'
import ProgressStep from './ProgressStep'

const titles = (props) => (
  props.loggedIn
    ? (
        [
          'Numbers',
          'Minutes',
          'Checkout'
        ])
    : ([
        'Numbers',
        'Plans',
        'Information',
        'Checkout'
      ])
)

export default class ProgressSteps extends React.Component {
  static get Step () {
    return ProgressStep
  }

  handleChangeStep (current, i, history) {
    if (current > i) {
      switch (i) {
        case 1:
          this.props.changeStep(SignupStep.IsSelectingNumberType)
          break
        case 2:
          this.props.changeStep(SignupStep.IsSelectingPackage)
          break
        case 3:
          this.props.changeStep(SignupStep.IsProvidingProfile)
          break
        case 4:
          this.props.changeStep(SignupStep.IsCheckingOut)
          break
      }
    }
  }

  renderSteps = () => {
    const steps = []

    for (let i = 1; i <= this.props.steps; i++) {
      const obj =
                <li key={i} className={this.props.current === i ? 'is-active' : '' + this.props.current > i ? 'done' : ''} onClick={() => this.handleChangeStep(this.props.current, i, this.props.history)}>
                    {this.props.current > i &&
                        <span className="icon icon-checkmark4 mr-10 text-success" />
                    }
                    <span className="step-name">{titles(this.props)[i - 1]}</span>
                {/*    <span className="step"></span> */}
                </li>
      steps.push(obj)
    }
    return steps
  }

  render () {
    // Center by default
    const alignment = this.props.align === undefined ? 'center' : this.props.align
    const stepsCount = React.Children.count(this.props.children)
    return (
            <div className={'progress-steps text-'.concat(alignment) + (stepsCount > 0 ? '' : ' simple') + (stepsCount > 5 || this.props.steps > 5 ? ' long' : '')}>
                <ol>
                    {stepsCount > 0
                      ? React.Children.map(this.props.children.filter(c => c), (el, index) =>
                            <ProgressStep title={el.props.title} index={index} current={this.props.current} key={index} />
                      )
                      : this.renderSteps()
                    }
                </ol>
            </div>
    )
  }
}
