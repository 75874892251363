import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import HandsetOrders from '../../components/leadgen/HandsetOrders'
import LiveChatBlock from '../../components/LiveChatBlock'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import StatsBanner from '../../components/StatsBanner'
import { FanvilProdId, t31p, w73p, x7a } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'
import PurchasePhones from '../../components/purchase/PurchasePhones'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'

class W53P extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showHandsetOrder: false
    }
  }

  scrollTo (section) {
    scroller.scrollTo(`${section}`, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  render() {
    const basket = getEmptyBasket()
    if (LocalStorageHelper.getItem('BasketHardware')?.value !== null) {
      basket.hardware = LocalStorageHelper.getItem('BasketHardware').value
    }

    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>Yealink W73P Cordless VoIP Phone</title>
                <meta name="description" content="Yealink DECT Phone W73P is a SIP Cordless Phone System designed specifically for small businesses." />
                <style type="text/css">{`
                @media screen and (max-width:768px) {
                    .col-md-1-5.col-xs-4 {
                        width:33.33333333%;
                    }
                    .col-md-1-5.col-xs-6 {
                        width:50%;
                    }
                }
                @media screen and (max-width:600px) {
                    .col-md-1-5.col-xxs-6 {
                        width:50%;
                    }
                }
				@media screen and (max-width: 1400px){
                    .bg-img {
                        background-position: 100% 100%;
                    }
                }
                @media screen and (max-width: 1024px){
                    .bg-img {
                        background-image: none !important;
                    }
                }
				`}</style>

            </Helmet>
            <HandsetOrders showModal={this.state.showHandsetOrder} onHide={() => this.setState({ showHandsetOrder: false })} model="Wireless" />
			      {this.props.isLoggedIn &&
				      <Modal className="full-screen products-modal" show={this.state.showEditDevices} onHide={() => this.setState({ showEditDevices: false })}>
					      <Modal.Body className="bg-dull">
						      <span className="close-modal" onClick={() => this.setState({ showEditDevices: false })}>
							      <span>&times;</span>
						      </span>

						      <PurchasePhones
							      displayInline={true}
							      handleUpdateHardware={(hardware) => handleHandsetUpdated(hardware)}
							      currentBasket={basket}
							      showEditDevices={false}
							      handleCloseDevicesModal={() => this.setState({ showEditDevices: false })}
							      isSignup={false}
							      hideFree={true}
							      purchaseUrl="/api/Purchase/PurchasePhones"
							      displayInstalments={true}
							      notSlider={true}
							      CustomDivClass="w-16 text-center p-10"
						      />

					      </Modal.Body>
				      </Modal>
			      }

            <header style={{ backgroundImage: "url('/images/backgrounds/departments-bg.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="YealinkW73PVoipPhoneH1">Yealink W73P Cordless VoIP Phone</h1>
                <h2 className=" subtitle">Choose optional business-grade plug and play phones, apps and accessories to best match your businesses needs.</h2>
            </header>

            <div className="full-width bg-light pb-100 pb-sm-20 pt-50" id="wireless">
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                        <div className="col-12 text-center mb-60">
                            <h2 className="text-500">Business Grade Cordless DECT VoIP Phone</h2>
                            <p className="lead text-400">
                                Yealink DECT Phone W73P is a SIP Cordless Phone System designed specifically for small businesses
                            </p>
                        </div>
                        <div className="col-lg-6 pt-0 pt-md-0 float-sm-end float-none">
                            <img src="/images/product-shots/w73p.png" alt="Yealink Cordless Handset" style={{ maxWidth: '100%' }} />
                        </div>
                        <div className="col-lg-6 pt-40 pt-xs-20">
                            <p className="mb-30">
                                With our Yealink W73P Cordless Handset users can benefit from lifelike voice communications, multi-tasking convenience, professional features like intercom, transfer, and call forward.
                                Integrated PoE (Power over Ethernet – No power supply required)
                            </p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 mb-md-0 ml-0 pl-0 pt-30 mb-xs-0">
                                        <li>
                                            Exceptional HD sound with wideband technology
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            1.8" color display with intuitive user interface
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            5-10 hours talk time, 100 hours standby time
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Free calls between handsets &amp; App globally
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-md-0 pt-xs-0">
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Integrated PoE  Class 1 (no power adapter needed)
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Desktop or wall mountable for extra flexibility
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Make &amp; receive calls from your SwitchboardFREE account
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                            Present your SwitchboardFREE number on your outbound calls
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="plans pt-30 mb-50">
                                <div className="plan">
                                    <span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={w73p} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
                                    <br />
                                    <span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
                                </div>
                            </div>

                            <p className="pb-40">
                                <Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(w73p)}>Order Now</Button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull pt-60">
                <div className="container">
                    <div className="row">
                        <div className="col text-center pt-30 pb-30">
                            <h3 className="text-500">Related Products</h3>
                            <p>All our phones come pre-configured. All you need to do is connect few cables and you&apos;re ready to <br className="d-none d-md-block"/>make and receive calls using your SwitchboardFREE numbers.</p>
                        </div>
                    </div>
                    <div className="row row-flex flex-3-col flex-sm-2-col flex-xs-grow">
                        <div className="col-xs-4 col-xxs-12 text-center mb-20">
                            <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                <img src="/images/product-shots/ecom/T31P.jpg" alt="Yealink T31P VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                <h4 className="text-500 mb-5">VoIP Handset</h4>
                                <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(t31p)}>Order Now</Button>
                                <br/>
                                <a href="/products/voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                            </div>
                        </div>
                        <div className="col-xs-4 col-xxs-12 text-center mb-20">
                            <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                <img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                <h4 className="text-500 mb-5">Advanced VoIP Handset</h4>
                                <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(FanvilProdId)}>Order Now</Button>
                                <br/>
                                <a href="/products/advanced-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                            </div>
                        </div>
                        <div className="col-xs-4 col-xxs-12 text-center mb-20">
                            <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                <img src="/images/product-shots/ecom/X7A.jpg" alt="Fanvil X7A Android IP Video Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                <h4 className="text-500 mb-5">Android IP Video Phone</h4>
                                <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(x7a)}>Order Now</Button>
                                <br/>
                                <a href="/products/x7a-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LiveChatBlock style="secondary" />

            <div className="full-width bg-light">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>

            <div className="full-width full-secondary p-20">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-center text-lightest mt-10 text-100 text-xl">
                            <StatsBanner />
                        </h3>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500">SwitchboardFREE</h3>
                            <h6 className="text-400">The UK's favourite call management system</h6>
                            <p className="text-400">Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentDidMount () {
    this.getCustomers()
    this.getHardware()
  }

  getHardware () {
    const url = 'api/Purchase/GetHardware'
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data) {
          this.setState({ products: res.data })
        }
      }).catch(err => console.error(err))
  }

  handleAddHandset (prodId) {
    const product = this.state.products.filter(f => f.prodId === prodId)
    const tempData = [{
      qty: 1,
      ...product[0]
    }]
    localStorage.setItem('HardwareFirst', JSON.stringify(tempData))
    this.props.history.push('/get-started')
  }

  getCustomers () {
    const url = 'api/Home/GetTotalCustomers'
    fetchHelper.getJson(url)
      .then(response => {
        if (response !== undefined) { this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }
}
export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(W53P)
