import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { isLoggedIn } from '../selectors'
import { handleShowCallbackForm } from '../store/SBF'

class MobileNumberForBusiness extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>7 Reasons not to use a mobile for your business</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>7 Reasons not to use a mobile for your business</h1>
                <h2 className=" subtitle">Discover why you should NOT use a mobile phone for your business number.</h2>
            </header>
            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <p className="text-500 text-lg">1. It looks unprofessional </p>
                            <p>A mobile phone number on your website suggests you either have no office, you&apos;re a sole trader working from home or you&apos;re out on the road all the time. And, if you answer a business call the same way you would answer a personal call on your mobile, it may not portray you in the best light. Can you afford to lose a big customer because you&apos;re not being perceived as the professional business you would like to be?</p>

                            <p className="text-500 text-lg">2. It can get expensive</p>
                            <p>Professional business numbers cost a lot less than you think and come with unlimited bundles so you can easily manage your spending. If your workforce grows, equipping them with the latest smartphones can cost thousands, plus, you'll need to add a hefty insurance premium to that too. </p>

                            <div className="mt-40 mb-30">
                                <div className="col bg-light b-1 brad-10 box-shadow-v3 p-30 clearfix">
                                    <span className="d-inline-block float-start text-darker col-12 col-md-5">
                                        <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                                            Get a <span className="text-cta">virtual landline</span> diverting to your mobile
                                        </span>
                                        <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-10" style={{ lineHeight: '1em' }}>
                                            Ditch your mobile today
                                        </p>
                                    </span>

                                    <span className="d-inline-block float-end col-12 col-md-6">
                                        <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.handleSignup()}>Get started</Button>
                                        <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
                                    </span>
                                </div>
                            </div>

                            <p className="text-500 text-lg">3. No contingency</p>
                            <p>If you lose or break your mobile phone, a replacement can take time and you may be stuck with a temporary number for a while. During this time, you could be missing new business opportunities or losing your existing customers. This could also occur if you&apos;re out-of-signal or even out of battery power.  One missed sale or new customer could potentially pay for a whole year of dedicated, business-class telecoms.  </p>

                            <p className="text-500 text-lg">4. Minimal data safety</p>
                            <p>If a staff member leaves the company or moves to a competitor, all of their contacts, call logs and interactions on their mobile may either be erased or worse, utalised by their new employer.  The only sure way of having full control over the data and security of your communications is to implement a dedicated, professional business phone system. </p>

                            <p className="text-500 text-lg">5. It doesn't help your work-life balance</p>
                            <p>If business calls are coming through in your personal time, it can be difficult to switch off and separate the two. We all like to work hard but without boundaries, you never really feel like you can fully relax.  Dedicated business phone systems allow you to set specific work hours and can roll over to a professionally recorded voicemail when you&apos;re off the clock. </p>

                            <p className="text-500 text-lg">6. Very little control</p>
                            <p>Knowing when you&apos;re busy and how many calls you've missed is key to being in control of your business. By using an online dashboard with access to easy call reports, you&apos;re able to track how your employees are interacting with your customers and where potential opportunities, or even problems may exist. </p>

                            <p className="text-500 text-lg">7. It doesn't scale</p>
                            <p>If you have more than one person in a business, scaling becomes an issue. Adding or removing additional mobile handsets can be costly and the lack of transparency on who takes calls and at what time is at best confusing. You can easily see that setting up a professional phone system, gives you almost unlimited options from day one and ensures you are prepared no matter how big your business becomes.  </p>

                            <div className="mt-50">
                                <div className="col bg-light brad-10 box-shadow-v3 p-30 b-1 clearfix">
                                    <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
                                        <span className="text-cta">Talk to us today</span> and get started
                                    </p>
                                    <h2 className="text-lg text-400 mb-0 mt-0 text-dark-light">
                                        Schedule a quick, no-obligation call &amp; discover...
                                    </h2>
                                    <ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            How to upgrade your phone system quickly &amp; easily
                                        </li>
                                        <li className="text-md mb-10">
                                            <span className="icon icon-checkmark-circle text-success mr-10" />
                                            How to get a virtual landline number redirecting to your mobile
                                        </li>
                                    </ul>

                                    <span className="d-inline-block">
                                        <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.props.handleShowCallbackForm()}>Get a callback</Button>
                                        <a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="brad-10 bg-primary bg-gradient p-40 text-center pb-0 sticky-t-100">
                                <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                                    Discover the power of VoIP for your business
                                </span>
                                <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-20" style={{ lineHeight: '1em' }}>
                                    <span className="text-darker">Get a landline</span> redirecting to your mobile
                                </p>
                                <p className="text-md text-400 mt-30 mb-0">
                                    From as little as &pound;9 per month.
                                </p>

                                <span className="d-inline-block col-12 mt-20 mb-50">
                                    <Button variant="cta" className="btn btn-lightest btn-outline p-15 pl-50 pr-50 text-md mb-xs-20" onClick={() => this.handleSignup()}>Get started</Button>
                                    <p className="text-sm mt-20">Or, call <a href="tel:02031891213" className="text-lightest">0203 189 1213</a></p>
                                </span>

                                <img src="/images/voip-feature-img.png" alt="VoIP Phone System" className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  handleSignup () {
    localStorage.setItem('landingPage', true)
    this.props.history.push('/get-started')
  }

  componentDidMount () {

  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(MobileNumberForBusiness)
