import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { validationStates } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import * as sbf from '../../store/SBF'
import OpeningTimesLiveChat from '../../Text/OpeningTimesLiveChat'
import OpeningTimesOffice from '../../Text/OpeningTimesOffice'

class ContactPage extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    formSent: false,
    contactName: '',
    contactEmail: '',
    contactTel: '',
    contactEnquiry: ''
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
        {/* <AboutMenu /> */}
            <Helmet>
                <title>Contact SwitchboardFREE - Get In Touch With The Team</title>
                <meta name="description" content="Contact us today by email, phone or chat. We're here to answer any questions or queries. See our FAQs." />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/contact-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Contact Us</h1>
                <h2 className=" subtitle">If you haven't already then please view our FAQ page to see if your query has already been answered.</h2>
            </header>

            <div className="container-styled">
                <div className="container mb-50">
                    <div className="row">

                    <div className="col-md-6  pb-xs-60 pr-50 pr-xs-15">
                        {this.state.formSent
                          ? <p className="text-center">
                                Thank your for contacting us.<br />
                                We'll get back to you as soon as we can.<br /><br />
                                However, if your query is urgent please contact us via one of our alternative methods.
                                </p>
                          : <Form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group validationState={this.props.validation.nameValidationState.valid === validationStates.INVALID || this.props.validation.nameValidationState.valid === validationStates.EMPTY ? 'error' : ''}>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control value={this.state.contactName} onChange={e => this.setState({ contactName: e.target.value }) + this.props.handleNameFieldChange()} onBlur={e => this.props.handleValidateName({ newValue: e.target.value })} maxLength={128} />
                                            {this.props.validation.nameValidationState &&
                                                <Form.Text className="text-xs">
                                                    {this.props.validation.nameValidationState.errorMsg}
                                                </Form.Text>
                                            }
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <Form.Group validationState={this.props.validation.telValidationState.valid === validationStates.INVALID || this.props.validation.telValidationState.valid === validationStates.EMPTY ? 'error' : ''}>
                                            <Form.Label>Telephone</Form.Label>
                                            <Form.Control value={this.state.contactTel} type="tel" onChange={e => this.setState({ contactTel: e.target.value }) + this.props.handleTelFieldChange()} onBlur={e => this.props.handleValidateTel({ newValue: e.target.value })} maxLength={30} />
                                            {this.props.validation.telValidationState &&
                                                <Form.Text className="text-xs">
                                                    {this.props.validation.telValidationState.errorMsg}
                                                </Form.Text>
                                            }
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <Form.Group validationState={this.props.validation.emailValidationState.valid === validationStates.INVALID || this.props.validation.emailValidationState.valid === validationStates.EMPTY ? 'error' : ''}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control value={this.state.contactEmail} type="email" onChange={e => this.setState({ contactEmail: e.target.value }) + this.props.handleEmailFieldChange()} onBlur={e => this.props.handleValidateEmail({ newValue: e.target.value })} maxLength={120} />
                                            {this.props.validation.emailValidationState &&
                                                <Form.Text className="text-xs">
                                                    {this.props.validation.emailValidationState.errorMsg}
                                                </Form.Text>
                                            }
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Group validationState={this.props.validation.messageValidationState.valid === validationStates.EMPTY ? 'error' : ''}>
                                    <Form.Label>Enquiry</Form.Label>
                                    <Form.Control componentClass="textarea" className="textarea" value={this.state.contactEnquiry} onChange={e => this.setState({ contactEnquiry: e.target.value }) + this.props.handleMessageFieldChange()} onBlur={e => this.props.handleValidateMessage({ newValue: e.target.value })} maxLength={3000} />
                                    {this.props.validation.messageValidationState &&
                                        <Form.Text className="text-xs">
                                            {this.props.validation.messageValidationState.errorMsg}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Button size="lg" variant="cta" className="btn-block mt-30 mb-20 text-center" type="submit" id="SendMessageButton">Send Message</Button>
                            </Form>
                        }
                    </div>
                    <div className="col-md-6 text-center pl-20 pl-sm-15">
                        <div className="row">
                            <div className="bg-dull col-md-12 p-30 brad-5 mt-25">
                                <div className="row">
                                    <div className="col-xs-6 col-xxs-12">
                                        <h5 className="text-500">Call us</h5>
                                        <p><a href="tel:02031891213" className="text-underline text-cta">0203 189 1213</a>
                                            <br/>
                                            <OpeningTimesOffice TwoLines={true} />
                                            </p>
                                    </div>
                                    <div className="pt-xxs-30 col-xs-6 col-xxs-12">
                                        <h5 className="text-500">Live chat</h5>
                                        <p>Chat to our friendly team
                                        <br />
                                        <OpeningTimesLiveChat TwoLines={true}/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-dull col-md-12 mt-5 p-30 brad-5">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h4 className="text-500">Have any questions?</h4>
                                        <h5 className="pb-10">Give us a call on <a href="tel:02031891213" className="text-dark">0203 189 1213</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="full-width bg-dull pt-50">
                    <div className="container">
                        <CarouselWidget />
                    </div>
                </div>
            </div>
        </>
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const context = this

    const name = context.state.contactName
    const email = context.state.contactEmail
    const tel = context.state.contactTel
    const message = context.state.contactEnquiry

    if (name.length === 0 || email.length === 0 || tel.length === 0 || message.length === 0) {
      // Error
      if (name.length === 0) this.props.handleValidateName({ newValue: name })
      if (email.length === 0) this.props.handleValidateEmail({ newValue: email })
      if (tel.length === 0) this.props.handleValidateTel({ newValue: tel })
      if (message.length === 0) this.props.handleValidateMessage({ newValue: message })
    } else {
      const contact = Object.assign({},
        { name },
        { email },
        { tel },
        { message })

      const url = 'api/Home/Contact'
      fetchHelper.postJson(url, contact)
        .then(result => {
          context.setState({ formSent: result.data })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}

export default connect(
  state => {
    return {
      validation: {
        nameValidationState: sbf.getNameRegexValidationState(state),
        telValidationState: sbf.getTelRegexValidationState(state),
        emailValidationState: sbf.getEmailRegexValidationState(state),
        messageValidationState: sbf.getMessageValidationState(state)
      }
    }
  },
  {
    handleValidateName: sbf.validateName,
    handleValidateTel: sbf.validateTel,
    handleValidateEmail: sbf.validateEmail,
    handleValidateMessage: sbf.validateMessage,
    handleNameFieldChange: sbf.handleNameChange,
    handleTelFieldChange: sbf.handleTelChange,
    handleEmailFieldChange: sbf.handleEmailChange,
    handleMessageFieldChange: sbf.handleMessageChange
  }
)(ContactPage)
