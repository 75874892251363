import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import LoadingNotification from '../../../components/LoadingNotification'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../../helpers/fetchHelper'

class BlogListing extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    page: 1
  }

  render () {
    function createMarkup (html) {
      return {
        __html: html
      }
    };

    if (!this.state.loading && this.state.blogsToShow && this.state.blogsToShow.blogs.length > 0 && (!this.state.blogImages || (this.state.blogImages && this.state.blogImages.length !== this.state.blogsToShow.blogs.length))) {
      this.handleRenderBlogImages()
    }

    return <>
			<Helmet>
				<title>Blog</title>
			</Helmet>
			<ScrollToTopOnMount />
			<DisplayMainFooter />
			<DisplayMainHeader />
			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center" >
							<h1 className="text-center" id="BlogH1">Blog</h1>
							<h2 className="subtitle text-center text-400">The latest news &amp; updates from SwitchboardFREE</h2>
						</div>
					</div>
				</div>
				<div className="full-width bg-dull pt-50 pb-50">
					<div className="container">
						{!this.state.loading && this.state.blogsToShow && this.state.blogsToShow.blogs.length > 0
						  ? <>
								<div className="blog-listing col-md-8 mx-auto">
									{this.state.blogsToShow.blogs.map((blog, i) =>
										<div className="blog-item">
											<div className="box-shadow box-shadow-v3 bg-light p-0 mb-0 brad-5">
												<div className="row">
													<div className="col-md-3 col-sm-3 col-xs-12 text-center pl-xs-30 pr-xs-30">
														<Link to={`/blog/${blog.url}?id=${blog.id}`}>
															{this.state.blogImages && this.state.blogImages.length >= i
															  ? this.renderImg(blog.id)
															  :																<div className="image-loading"></div>
															}
														</Link>
													</div>
													<div className="col-md-9 col-sm-9 col-xs-12 pl-xs-30 pr-xs-30">
														<div className="p-50 pl-0 p-sm-30 pb-sm-0 p-xs-15 pb-20">
															<span className="text-xs text-400 d-block pb-5">Posted on {blog.date}</span>
															<Link to={`/blog/${blog.url}?id=${blog.id}`} className="no-underline">
																<h2 className="text-500 text-secondary text-lg mt-0 mb-10" dangerouslySetInnerHTML={createMarkup(blog.title)}></h2>
															</Link>
															<div className="lead" dangerouslySetInnerHTML={createMarkup(blog.excerpt.replaceAll('read more', ''))}></div>
														</div>
														<div className="button-set pr-sm-30 pl-sm-30 p-xs-15 bottom">
															<Link to={`/blog/${blog.url}?id=${blog.id}`} className="btn btn-cta mt-20 mb-50 mb-sm-30 mb-xs-15 btn-xs-block">Read more</Link>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="pagination col-md-8 mx-auto text-center pt-30 bt-2">
									{this.state.totalPages &&
										this.renderPagination()
									}
								</div>
							</>
						  : <LoadingNotification loadingText={'Loading, please wait...'} textColor="text-dark" isLoading={true} width={75} height={75} />}
					</div>
				</div>
			</div>
		</>
  }

  renderImg (id) {
    const image = this.state.blogImages.find(f => f.id === id)
    if (image && image.img && image.img.length > 0) {
      return (
				<img src={image.img} className="p-50 pr-0 w-100" />
      )
    } else {
      return (<img src="/images/default-blog.png" className="p-50 pr-0 w-100" />)
    }
  }

  renderPagination () {
    const context = this
    const pages = context.state.totalPages
    const buttons = []

    for (let i = 1; i <= pages; i++) {
      buttons.push(
				<Link className={`btn btn-sm ${(context.state.page === i ? 'active btn-secondary' : 'btn-default')} mr-5 ml-5`} to={`/blog/${i}`}>
					{i}
				</Link>
      )
    }

    return buttons
  }

  handleRenderBlogImages () {
    const context = this
    if (context.state.blogsToShow && context.state.blogsToShow.blogs.length > 0) {
      const images = []
      const run = new Promise((resolve, reject) => {
        context.state.blogsToShow.blogs.forEach((blog, i) => {
          fetchHelper.getJson(`/api/Home/GetBlogThumbnail/${blog.id}`)
            .then(res => {
              images.push({ id: blog.id, img: res.data })
              if (i === context.state.blogsToShow.blogs.length - 1) resolve()
            }).catch(err => console.error(err))
        })
      })

      run.then(() => {
        context.setState({ blogImages: images })
      })
    }
  }

  handleGetBlogs (page) {
    const context = this
    const images = []
    context.setState({ loading: true, blogImages: images }, () => {
      fetchHelper.getJson(`/api/Home/GetBlogListing/${page}`)
        .then(res => {
          context.setState({ blogsToShow: res.data, totalPages: res.data.totalPages, loading: false, page })
        }).catch(err => console.error(err))
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const page = this.props.match.params.page ? parseInt(this.props.match.params.page) : 1

    if (this.state.page !== page && !this.state.loading) {
      this.handleGetBlogs(this.props.match.params.page ? parseInt(this.props.match.params.page) : 1)
    }
  }

  componentDidMount () {
    const context = this
    context.handleGetBlogs(this.props.match.params.page ? parseInt(this.props.match.params.page) : 1)
  }
}

export default withRouter(connect(
  state => {
  },
  {
  }
)(BlogListing))
