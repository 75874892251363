import { select } from 'd3-selection'
import React, { Component } from 'react'

class HourStatsChart extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {}

  constructor (props) {
    super(props)
    this.createHourChart = this.createHourChart.bind(this)
  }

  componentDidMount () {
    this.createHourChart()
  }

  componentDidUpdate () {
    this.createHourChart()
  }

  createHourChart () {
    const node = this.node

    const svgWidth = node.getBoundingClientRect().width

    const answeredCalls = [this.props.data.answeredCalls]
    const missedCalls = [this.props.data.missedCalls]

    const oneBlockWidth = svgWidth / this.props.maxCalls
    let monMissedStartX = 0

    const gapBetweenRects = 10

    select(node).selectAll('*').remove()

    select(node)
      .selectAll('rect')// .remove()
      .data(answeredCalls)
      .enter()
      .append('rect')
      .attr('y', function (d) {
        return 0
      })
      .attr('height', '100%')
      .attr('x', 0)
      .attr('width', function (d, i) {
        const acceptedCallBlockTotalWidth = d * oneBlockWidth
        monMissedStartX = acceptedCallBlockTotalWidth
        if (missedCalls[0] === 0) {
          return acceptedCallBlockTotalWidth
        } else {
          return acceptedCallBlockTotalWidth > 0 ? acceptedCallBlockTotalWidth - gapBetweenRects : 0
        }
      })
      .attr('rx', 10) // set the x corner curve radius
      .attr('ry', 10) // set the y corner curve radius
      .attr('fill', 'green')
      .append('title')
      .text(function (d) { return `${d} answered ${d > 1 ? 'calls' : 'call'}` })

    select(node)
      .selectAll()// .remove()
      .data(missedCalls)
      .enter()
      .append('rect')
      .attr('y', 0)
      .attr('height', '100%')
      .attr('x', monMissedStartX)
      .attr('width', function (d) {
        const missedCallBlockTotalWidth = d * oneBlockWidth
        if (answeredCalls[0] === 0) {
          return missedCallBlockTotalWidth > 0 ? missedCallBlockTotalWidth - gapBetweenRects : 0
        } else {
          return missedCallBlockTotalWidth
        }
      })
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('fill', 'red')
      .append('title')
      .text(function (d) { return `${d} missed ${d > 1 ? 'calls' : 'call'}` })
  }

  render () {
    return (
            <svg ref={node => this.node = node} width='100%' height='100%' style={{ 'padding-top': 5, 'padding-bottom': 5 }}></svg>
    )
  }
}

export default HourStatsChart
