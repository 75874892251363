import React from 'react'

export default class ProgressStep extends React.Component {
  render () {
    const index = this.props.index + 1
    return (
            <li key={index} className={this.props.current === index ? 'active' : '' + this.props.current > index ? 'done' : ''}>
                <span className="step">
                    {this.props.current === index &&
                        <img src={'images/icons/current-step.svg'} />
                    }
                    {this.props.current > index &&
                        <i className="icon icon-checkmark4"></i>
                    }
                    {this.props.current < index &&
                        index
                    }
                </span>
                <span className="title">
                    {this.props.title}
                </span>
            </li>
    )
  }
}
