import React from 'react'
import { connect } from 'react-redux'
import CallbackRequest from '../../../components/CallbackRequest'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../../selectors'

class CallScreening extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <header style={{ backgroundImage: "url('/images/backgrounds/call-filtering.jpg')" }} className="full-width full-hero-small">
                <h1>Call Screening</h1>
                <h2 className="subtitle text-center">Know exactly what the call is related to before answering the phone</h2>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto pb-30">
                            <p>
                                Call Screening also referred as Call Whisper is a free feature available for all of your SwitchboardFREE numbers.
                            </p>
                            <h4 className="pt-30">
                                How it works
                            </h4>
                            <p>
                                Before we start, let’s say, as an example that your SwitchboardFREE number is setup with three <a href="/departments-selection">departments</a>. Callers calling your number will be presented with the following options:
                            </p>
                            <div className="row">
                                <div className="col-md-6 mx-auto">
                                    <blockquote className="text-md bl-0 p-30 mt-30 mb-30 brad-10 ">
                                        <span className="icon icon-quotes-left text-cta pr-20 float-start"></span>
                                        <p className="text-start text-md"><i>On your phone keypad, please press 1 for Sales, press 2 for IT, press 3 for Complaints.</i></p>
                                    </blockquote>
                                </div>
                            </div>
                            <p>
                                Now, let&apos;s say, the customer that calls your SwitchboardFREE number has a complaint, so he presses 3 on his phone’s keypad.
                            </p>
                            <p>
                                On your phone you see an incoming call made to your SwitchboardFREE number... you answer it. Now, rather than being connected to the caller straight away, you will hear a message (that's the Call Screening message) informing you what type of call it is. In our example, you might hear the following:
                            </p>
                            <div className="row">
                                <div className="col-md-6 mx-auto">
                                    <blockquote className="text-md bl-0 p-30 mt-30 mb-30 brad-10">
                                        <span className="icon icon-quotes-left text-cta pr-20 float-start"></span>
                                        <p className="text-start text-md"><i>You have a complaints call, press the star key to accept the call or press 3 to send the caller to voicemail</i></p>
                                    </blockquote>
                                </div>
                            </div>

                            <p>
                                This way, you can decide whether to answer the call or not. It also enables you to answer the phone professionally.
                            </p>

                            <h4 className="pt-30">
                                Turning ON/OFF the Call Screening feature
                            </h4>
                            <p>
                                When you log in, go to your SwitchboardFREE number settings.
                            </p>
                            <ol className="pl-15 pt-20">
                                <li className="pb-10">
                                    Scroll to the &quot;Call Screening&quot; section.
                                </li>
                                <li className="pb-10">
                                    Use the switch to either turn it ON or OFF.
                                </li>
                                <li className="pb-10">
                                    Select the type of message you would like to hear when you pick up the call.
                                </li>
                                <li className="pb-10">
                                    Press the &quot;Save Changes&quot; button.
                                </li>
                            </ol>
                            <figure className="pb-30 pt-30 mb-30 mt-30 text-center">
                                <img className="img-responsive b-1 pb-10 brad-20" src="/images/screenshots/call-screening.jpg" alt="call screening settings screenshot" />
                                <figcaption className="text-xs pt-10">Screenshot of the Call Screening settings</figcaption>
                            </figure>
                            <h4 className="pt-30">
                                Recording your own call screening message
                            </h4>
                            <p>
                                Besides recording the call screening message you can also change your introduction, voicemail message, department prompts, out of hours and even music on-hold. You can do this for free via the <a href="app">SwitchboardFREE app</a>. Alternatively, you can use our professional voice artists or our in-house team for an additional fee.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="full-width bg-dull text-center p-30 mt-30 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mx-auto">
                                <h4 className="text-400 pb-30">Do you have any more questions about Call Screening?</h4>
                                <h5 className="mb-30 pb-30">Call us on <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a>, email <a href="support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a> or request a callback below. <br />Our offices are open Monday to Friday 8:30am to 6pm.</h5>
                                <p></p>
                                <CallbackRequest subject="Callback request from Departments page" isForm={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(CallScreening)
