import { createSelector } from 'reselect'
import { validationStates } from './constants/Constants'

const _ = require('lodash')

const getCurrentConfigurationSelectionState = state => state.configuration.selectedConfiguration
const getCurrentSelectedNavigationButtonName = state => state.configuration.selectedConfiguration.navigationSelection

const getSaving = state => state.configuration.saving.isSaving
const getConfigLoadingState = state => state.configuration.loading
const getApiValidation = state => state.configuration.saving.apiValidation

const getConfigValidators = state => state.configuration.validation

const getConfiguration = state => state.configuration.configuration

export const getCallBlockingEnabled = state => state.sbfApp.enabledServices.hasCallBlocking

const getRedirectCount = createSelector(
  getConfiguration,
  configuration => {
    let count = 0

    if (configuration.redirect1) { count = count + 1 }
    if (configuration.redirect2) { count = count + 1 }
    if (configuration.redirect3) { count = count + 1 }
    if (configuration.redirect4) { count = count + 1 }
    if (configuration.redirect5) { count = count + 1 }

    return count
  }
)

const getConfigValidatorErrorCount = createSelector(
  getConfigValidators,
  validations => {
    return _.reduce(validations, function (errorCount, validator) {
      return errorCount + (validator.valid === validationStates.INVALID ? 1 : 0)
    }, 0)
  }
)

const getMyNumbersLoadingState = state => {
  return state.myNumbers.requesting
}

const getConfigurationLoading = createSelector(
  getConfigLoadingState,
  configLoadingState => configLoadingState.configuration
)

const getLoading = createSelector(
  [getConfigurationLoading, getMyNumbersLoadingState],
  (isConfigLoading, myNumbersLoading) => {
    return isConfigLoading || myNumbersLoading
  }
)

export const isLoggedIn = state => {
  return state.sbfApp.authentication.isAuthenticated
}

export const isLoggedOut = state => !isLoggedIn(state)

export const getLoadingMessage = state => state.sbfApp.loadingMessage

const canSubmitConfigChange = createSelector(
  [getConfigValidatorErrorCount,
    getConfigurationLoading,
    getSaving],
  (errorCount, isLoading, isSaving) => {
    const b = errorCount === 0 && !isLoading && !isSaving
    return b
  }
)

const getCurrentRequestedRoute = createSelector(
  getCurrentConfigurationSelectionState,
  currentSelect => {
    if (!currentSelect.queueNo) { return undefined }

    let route = '/customer/numbers/' + currentSelect.queueNo

    if (currentSelect.isDepartmentActive) {
      route = route + '/' + (currentSelect.depNo === undefined ? '1' : currentSelect.depNo)
    }

    if (currentSelect.navigationSelection) {
      route = route + '/' + currentSelect.navigationSelection
    }

    return route
  }
)

export {
  getConfiguration,
  getConfigValidators,
  getCurrentRequestedRoute,
  getCurrentSelectedNavigationButtonName,
  getSaving,
  getConfigurationLoading,
  getLoading,
  canSubmitConfigChange,
  getRedirectCount,
  getApiValidation
}
