import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import FeaturesList from '../../components/Competitors/FeaturesList'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface FlextelProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const Flextel = (props: FlextelProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Flextel vs SwitchboardFREE</title>
                <meta name="description" content="See how Flextel compares with SwitchboardFREE. Flextel is a long-established cloud phone service provider and British company which really pioneered the use of personal numbering back in 1993." />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/3-competitor-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Flextel vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Flextel vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Introduction</h2>
                            <p>Flextel is a long-established cloud phone service provider and British company which really pioneered the use of personal numbering back in 1993.</p>
                            <p>Back then, Flextel was the only company offering personal numbers to businesses and as such amassed a strong following of clients early on.</p>
                            <p>Their early launch of this powerful technology and connectivity made them first to market and many of the communication tools they developed we take for granted today.</p>
                            <p>They're still firmly aimed at providing solutions for growing business and undoubtedly provide communication expertise to business customers nationally.</p>
                            <p>Flex telecom have been around for 28 years and they are one of the original few cloud-based phone providers that still offer a business communication solution to an established network of customers throughout the UK.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Website Navigation Experience</h2>
                            <p>Unfortunately, their current website looks like it hasn’t been updated in many years and as such has a very old-fashioned look and feel.</p>
                            <p>The whole process of navigating their site is one of unfamiliarity and awkwardness. The lack of manned live chat or support and copy heavy website sections further compounds this out-of-date and somewhat frustrating experience.</p>
                            <p>Once you find what you're after and work through the reams of text, you actually find that Flex telecom has a lot to offer and have over 600 area codes for different geographical locations which is huge.</p>
                            <p>They also boast an impressive set of features and solutions to suit most companies but sadly, navigating to what you need is a laborious and unexciting process.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Flex Telecom Plans</h2>
                            <p>Flextel plans are comprehensive, to say the least. There seems to be a plan for every minute package you would ever need from 100 to 50,000.</p>
                            <p>However, the one thing that stands out is the very high cost of the service. The base package alone is £10 for only 100 minutes and these packages scale in both size and cost to some eye-watering amounts!</p>
                            <p>With today’s current competition, these prices are way off the mark, even with no contract and a rich set of features, the cost of the plans are far too expensive to stack up against today's competition.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">The Phone System Cost Comparison</h2>
                            <p>For comparison, SwitchboardFREE offer an "unlimited" or (3,000) minutes inbound and outbound with over 40 free features for only £16 a month. That includes no long-term contract as you would find with Flextel.</p>
                            <p>Just the equivalent number package from Flextel without the extra features (which I’ll get on to next) would be £100! And that’s for only 2,000 minutes. That’s 10 times as much for a like for like service.</p>
                            <p>Click <a href="https://www.flextel.com/costs/Monthly+Plans/" target="_blank" rel="noreferrer nofollow">here</a> to see their plans for yourself!</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">The Feature List</h2>
                            <p>The list of features offered by Flextel is big and many of the features you would expect to see, plus a few standard services disguised as features. But nonetheless, many of these are free and come bundled with the number plans.</p>
                            <p>However, what I consider to be the most frequently used features are priced per minute? This seems to be a unique and rather odd pricing model for such core features.</p>
                            <p>If you were to opt for a local or geographical number, the features such as Call queueing and Voicemail which come as standard for many providers are charged at 1p per minute. This is already on top of the very high plan cost.</p>
                            <p>It seems to me that this is another way to charge more for the two most top used features and I’d imagine the monthly bill would be a shock to many customers.</p>
                            <p>Remember, SwitchboardFREE offer ALL of these features for free with every number:</p>

                            <FeaturesList />

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">In Summary</h2>
                            <p>The dated website and expensive cost of Flextel offering may appeal to some but If you're considering using the Flextel for your VoIP service then please contact or message the friendly Welcome team at SwitchboardFREE via their contact page who will more than happy to give you a like for like comparison.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Flextels current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.flextel.com/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(Flextel)
