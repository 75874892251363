import React, { useEffect, useState } from 'react'
// import {peterMp3Sample} from '../../../sounds/peter-promo.mp3';
import ReactAudioPlayer from 'react-audio-player'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { isValidEmailSyntax } from '../../../helpers/validationHelpers'
import { type ReduxState } from '../../../models/SignupTypes'
import { handleNotificationChange } from '../../../store/SBF'
import EnableTpsProtectionModal from '../../VoipUsers/EnableTpsProtectionModal'

const TPSmessageV1 = require('../../../sounds/tps1.mp3')
const TPSmessageV2 = require('../../../sounds/tps2.mp3')
const TPSmessageV3 = require('../../../sounds/tps3.mp3')
interface TPSProtectionSettingsProps {
  hasTps: boolean
  notify: (obj: any) => void
}

interface FormValues {
  action: number
  emailAddress: string
}

interface GetTPSProtectionSettingsDto {
  action: number
  emailAddress: string
  callsInLast60Days: number
}

// stops from playing two audios at the same time.
document.addEventListener('play', function (e) {
  const audios = document.getElementsByTagName('audio')
  for (let i = 0, len = audios.length; i < len; i++) {
    if (audios[i] !== e.target) {
      audios[i].pause()
    }
  }
}, true)

const TPSProtectionSettings = (props: TPSProtectionSettingsProps) => {
  useEffect(() => {
    if (!props.hasTps) {
      return
    }

    fetchHelper.getJson<GetTPSProtectionSettingsDto>('api/Profile/GetTPSProtectionSettings')
      .then(res => {
        setFormValues({ action: res.data.action, emailAddress: res.data.emailAddress })
        setCallsInLast60Days(res.data.callsInLast60Days)
      }).catch(err => { console.error(err) })
  }, [props.hasTps])

  const [formValues, setFormValues] = useState<FormValues>({ action: 1, emailAddress: '' })
  const [callsInLast60Days, setCallsInLast60Days] = useState(0)
  const [disableDownloadButtons, setDisableDownloadButtons] = useState(false)
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  const [emailValidation, setEmailValidation] = useState(true)
  const [showTpsProtectionModal, setShowTpsProtectionModal] = useState(false)

  const handleSubmit = (event) => {
    setDisableSaveButton(true)
    event.preventDefault()

    if (!isValidEmailSyntax(formValues.emailAddress)) {
      props.notify({ message: 'Invalid email address', isError: true })
      setEmailValidation(false)
      return
    }

    setEmailValidation(true)

    fetchHelper.postJson('api/Profile/UpdateTPSProtectionSettings', formValues)
      .then(res => {
        if (res.data === true) {
          props.notify({ message: 'Your TPS Protection settings have been updated' })
          setDisableSaveButton(false)
        } else {
          props.notify({ message: 'There has been a problem saving your TPS Protection settings', isError: true })
          setDisableSaveButton(false)
        }
      }).catch(err => { console.error(err) })
  }

  const downloadReport = (days: number) => {
    setDisableDownloadButtons(true)
    const res = fetchHelper.getBlob(`api/Profile/GetListOfCalledTPSNumbers/${days}`)

    if (res !== undefined) {
      res.then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'SwitchboardFREE-TPS-Report.csv')
        document.body.appendChild(link)
        link.click()
      }).catch(error => { console.error(error) })

      setDisableDownloadButtons(false)
    } else {
      props.notify({ message: 'There has been an error downloading the report. Please try again shortly', isError: true })
      setDisableDownloadButtons(false)
    }
  }

  return (
        <>
            {props.hasTps
              ? <div className="bg-light p-30 pb-40 brad-10 b-1 w-800px center-block mb-20">
                    <h4 className="pb-20 text-400 text-center">TPS Protection Settings</h4>

                    <div className="col-xs-12">
                        <p className="mb-30">
                        Please select an option below to specify what action should be taken when your users try to call a phone number that is registered with the TPS (Telephone Preference Service).
                        </p>

                        <Form className="row" noValidate onSubmit={handleSubmit}>
                            <div className="b-1 p-10 brad-10 mb-20 bg-dull col-xs-12" onClick={() => { setFormValues({ ...formValues, action: 1 }) }}>
                                <Form.Check
                                    checked={formValues.action === 1}
                                    className="ml-20 mt-10 mb-10"
                                    type="radio"
                                    label={(<div style={{ fontSize: 16 }}><b>Warning</b> - play a warning to the person making the call.</div>)}
                                />
                                <div className="pl-40">
                                    <ReactAudioPlayer src={TPSmessageV3} controls className="brad-10" />
                                </div>
                            </div>
                            <div className="b-1 p-10 brad-10 mb-20 bg-dull col-xs-12" onClick={() => { setFormValues({ ...formValues, action: 0 }) }}>
                                <Form.Check
                                    checked={formValues.action === 0}
                                    className="ml-20 mt-10 mb-10"
                                    type="radio"
                                    label={(<div style={{ fontSize: 16 }}><b>Warning & action</b> - play a warning to the person making the call and <br /> require them to press the star key before making the call.</div>)}
                                />
                                <div className="pl-40">
                                    <ReactAudioPlayer src={TPSmessageV2} controls className="brad-10" />
                                </div>
                            </div>
                            <div className="b-1 p-10 brad-10 mb-10 bg-dull col-xs-12 col-xs-12" onClick={() => { setFormValues({ ...formValues, action: 2 }) }}>
                                <Form.Check
                                    checked={formValues.action === 2}
                                    className="ml-20 mt-10 mb-10"
                                    type="radio"
                                    label={(<div style={{ fontSize: 16 }}><b>Block</b> - block call from being made all together.</div>)}
                                />
                                <div className="pl-40">
                                    <ReactAudioPlayer src={TPSmessageV1} controls className="brad-10" />
                                </div>
                            </div>

                            <h5 className=" pt-30 text-500">Notification</h5>
                            <p>
                                Provide one or multiple email addresses (e.g. john@gmail.com;john@yahoo.co.uk) to receive notifications every time a TPS-listed number is dialed.
                            </p>
                            <div className="col-xs-12">
                                <Form.Control
                                    placeholder="Enter email"
                                    value={formValues.emailAddress}
                                    maxLength={200}
                                    isInvalid={!emailValidation}
                                    onChange={(e) => { setFormValues({ ...formValues, emailAddress: e.target.value.replaceAll(' ', '') }) }} />
                            </div>

                            <div className="col-xs-12 text-center pt-30 mb-30">
                                <Button variant="cta" type='submit' size="lg" disabled={disableSaveButton}>Save settings</Button>
                            </div>
                        </Form>
                    </div>

                    <div className="w-500px pl-30 pr-30 pb-30 center-block b-1 brad-10 box-shadow text-center mt-60 mb-40">
                        <h5 className="pb-10 pl-20 text-500 pt-30">Download TPS Report</h5>
                        {callsInLast60Days === 0
                          ? <>
                            <p>Well done, there have not been any numbers called that are on the TPS in the last 60 days.</p>
                            <p className="mt-20">A CSV file conaining a log of phone numbers will be available to download when numbers on the TPS have been dialled.</p>
                        </>
                          : <>
                            <p className="mt-20">There {callsInLast60Days === 1 ? 'has' : 'have'}  been <b>{callsInLast60Days}</b> {callsInLast60Days === 1 ? 'call' : 'calls'} made to numbers on the TPS in the last 60 days.</p>
                            <p><a className="cta" onClick={() => { downloadReport(60) }}>Download a CSV file</a> containing a log of phone numbers that have been called and are on the TPS within the last 60 days.</p>
                        </>
                    }
                    </div>
                </div>
              : <div className="bg-light text-center p-30 pb-40 brad-10 b-1 w-800px center-block mb-20 mt-30">
                    <h4 className="pb-20 text-400">TPS Protection Settings</h4>
                    <p>You do not have TPS Protection enabled on your account. <br className="d-none d-sm-block"/><a className="text-cta underline" href="/features/tps-protection" target="_blank">Find out what is TPS</a> and how it can protect your business.</p>
                    <p className="mb-40">If you already know what TPS is, then click the <br className="d-none d-sm-block"/>below button to get started.</p>
                    <div className="text-center mb-30">
                        <Button variant="cta" type='submit' size="lg" disabled={disableDownloadButtons} onClick={() => { setShowTpsProtectionModal(true) }}>Activate Now</Button>
                    </div>
                </div>
            }

            <EnableTpsProtectionModal
                showModal={showTpsProtectionModal}
                toggleShowTpsModal={(toggle) => { setShowTpsProtectionModal(toggle) }}
                showTpsInfo={false}
                showPricingInfo={true}
            />
        </>
  )
}

export default connect(
  (state: ReduxState) => {
    return {
      hasTps: state.sbfApp.enabledServices.hasTPSProtection
    }
  },
  {
    notify: handleNotificationChange
  }
)(TPSProtectionSettings)
