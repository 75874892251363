import React from 'react'
import { Button, OverlayTrigger, Popover, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import HandsetOrders from '../../components/leadgen/HandsetOrders'
import LiveChatBlock from '../../components/LiveChatBlock'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import StatsBanner from '../../components/StatsBanner'
import { FanvilProdId, t31p, x7a, YeaLinkProdId } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'
import PurchasePhones from '../../components/purchase/PurchasePhones'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'

const FreePhoneMoreInfo = (
	<Popover id="popover-basic">
		<p>To get this phone(s) for free, during the last step of the signup process (Checkout page), select the <i>Bi-Annual</i> payment option.</p>
        <p>
            <img src="/images/bi-annual.png" className="w-100" alt="Bi-Annual payment screenshot" />
        </p>
	</Popover>
)

class DevicesTiny extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showHandsetOrder: false
    }
  }

  scrollTo (section) {
    scroller.scrollTo(`${section}`, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  render() {
    const basket = getEmptyBasket()
    if (LocalStorageHelper.getItem('BasketHardware')?.value !== null) {
      basket.hardware = LocalStorageHelper.getItem('BasketHardware').value
    }

    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>HD ‘Plug & Play’ VoIP Handsets. Make calls using your Virtual Numbers.</title>
                <meta name="description" content="Get your VoIP Phone from SwitchboardFREE. Make calls using your Virtual Numbers." />
                <body className="body-simple" />
                <style type="text/css">{`
				@media screen and (max-width: 1400px){
                    .bg-img {
                        background-position: 100% 100%;
                    }
                }
                @media screen and (max-width: 1100px) {
                    header h1.pt-60 {
                        padding-top:30px !important;
                    }
                }
                
                @media screen and (max-width: 1024px){
                    .bg-img {
                        background-image: none !important;
                    }
                }
				`}</style>
            </Helmet>

            <HandsetOrders showModal={this.state.showHandsetOrder} onHide={() => this.setState({ showHandsetOrder: false })} model={this.state.model} />
			      {this.props.isLoggedIn &&
				      <Modal className="full-screen products-modal" show={this.state.showEditDevices} onHide={() => this.setState({ showEditDevices: false })}>
					      <Modal.Body className="bg-dull">
						      <span className="close-modal" onClick={() => this.setState({ showEditDevices: false })}>
							      <span>&times;</span>
						      </span>

						      <PurchasePhones
							      displayInline={true}
							      handleUpdateHardware={(hardware) => handleHandsetUpdated(hardware)}
							      currentBasket={basket}
							      showEditDevices={false}
							      handleCloseDevicesModal={() => this.setState({ showEditDevices: false })}
							      isSignup={false}
							      hideFree={true}
							      purchaseUrl="/api/Purchase/PurchasePhones"
							      displayInstalments={true}
							      notSlider={true}
							      CustomDivClass="w-16 text-center p-10"
						      />

					      </Modal.Body>
				      </Modal>
			      }

            <header style={{ backgroundImage: "url('/images/backgrounds/features-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1 id="VoipPhoneH1">SwitchboardFREE VoIP Handset</h1>
                <h2 className="subtitle">Work seamlessly from your office, home or even abroad, with our business-grade,
                <br className="d-none d-md-block" />HD ‘Plug &amp; Play’ VoIP handset. Order yours today!</h2>
            </header>

            <div className="full-width bg-light pb-50 pt-50" id="deskphone">
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                        <div className="col-lg-6 pt-40 pt-md-0 float-sm-end float-none">
                            <img src="/images/product-shots/T31P.png" alt="Yealink T31P VoIP Handset" style={{ maxWidth: '100%' }} />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                                    <h2 className="text-500">VoIP Handset</h2>

                                    <p className="lead text-400 pt-30 pt-xs-0">
								This VoIP Phone is an industrial masterpiece that offers superior user experience for home and office use.
                            </p>
							<p className="mb-30">
								With an elegant appearance and intelligent software, this phone is designed to optimise communication, whilst also fitting seamlessly into any desktop environment.
							</p>
                            <div className="row">
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0">
                                        <li>
                                            2.3" 132 x 64-pixel graphical LCD
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Easy installation and configuration
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Smart and friendly user interface
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Secure and complete provisioning protocols
                                    	</li>
									</ul>
								</div>
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-md-0 pt-xs-0 pt-sm-0">
										<li className="pb-xs-10 pt-xs-10 brad-5">
										    Industrial Standard Certifications: CE/FCC
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											2 SIP Lines
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
									    	Desktop / Wall-mount installation
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Present your SwitchboardFREE number on your outbound calls
                                        </li>
									</ul>
								</div>
							</div>

                            <div className="plans pt-30">
                                <div className="plan">
                                    <span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={t31p} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
                                    <br />
                                    <span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
                                </div>
                                {/* <div className="plan plan-highlight">
                                    <span className="text-md"><span className="text-500">Pay Monthly </span> For Just <span className="text-cta text-500 text-lg">£6</span> <span className="text-italic">p/m (+VAT)</span></span>
                                    <br />
                                    <span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support <span className="text-cta">+</span> Unlimited Warranty</span></span>
                                </div> */}
                            </div>
                            <div className="d-block mt-30">
                                    <p className="bg-secondary brad-10 pt-5 pb-5 pl-20 pr-20 text-center text-lightest d-inline-block">
                                        You can get this phone for FREE! <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={FreePhoneMoreInfo}>
                                            <span className="underline cursor-pointer ml-5 text-sm">See how</span>
                                            </OverlayTrigger>.
                                    </p>
                                </div>
                            <p className="pb-30 pt-30">
                                {/* <Button variant="cta" size="lg" onClick={() => this.setState({ showHandsetOrder: true, model: "x3s" })} className="btn-xxs-block">Order Now</Button> */}
                                <Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(t31p)}>Order Now</Button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull pt-60">
                <div className="container">
                    <div className="row">
                        <div className="col text-center pt-30 pb-30">
                            <h3 className="text-500">Related Products</h3>
                            <p>All our phones come pre-configured. All you need to do is connect few cables and you&apos;re ready to <br className="d-none d-md-block"/>make and receive calls using your SwitchboardFREE numbers.</p>
                        </div>
                    </div>
                    <div className="row row-flex flex-3-col flex-sm-2-col flex-xs-grow">
                        <div className="col-xs-4 col-xxs-12 text-center  mb-20">
                            <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                <img src="/images/product-shots/ecom/W73P.jpg" alt="Cordless VoIP Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                <h4 className="text-500 mb-5">Cordless VoIP Handset</h4>
                                {/* <p className="text-cta text-md">From <span className="text-500">£7.50</span> p/month</p> */}
                                <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(YeaLinkProdId)}>Order Now</Button>
                                <br/>
                                <a href="/products/yealink-w73p-cordless-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>

                                {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                            </div>
                        </div>
                        <div className="col-xs-4 col-xxs-12 text-center mb-20">
                            <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                <img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                <h4 className="text-500 mb-5">Advanced VoIP Handset</h4>
                                {/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
                                <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(FanvilProdId)}>Order Now</Button>
                                <br/>
                                <a href="/products/advanced-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>

                                {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                            </div>
                        </div>
                        <div className="col-xs-4 col-xxs-12 text-center mb-20">
                            <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                <img src="/images/product-shots/ecom/X7A.jpg" alt="Fanvil X7A Android IP Video Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                <h4 className="text-500 mb-5">Android IP Video Phone</h4>
                                {/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
                                <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                {/* <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(FanvilProdId)}>Order Now</Button> */}
                                <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(x7a)}>Order Now</Button>
                                <br/>
                                <a href="/products/x7a-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>

                                {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LiveChatBlock style="secondary" title="Want to know more?" />

            <div className="full-width bg-light">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>

            <div className="full-width full-secondary p-20">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-center text-lightest mt-10 text-100 text-xl">
                            <StatsBanner />
                        </h3>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500">SwitchboardFREE</h3>
                            <h6 className="text-400">The UK's favourite call management system</h6>
                            <p className="text-400">Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentDidMount () {
    this.getCustomers()
    this.getHardware()
  }

  getHardware () {
    const url = 'api/Purchase/GetHardware'
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data) {
          this.setState({ products: res.data })
        }
      }).catch(err => console.error(err))
  }

  handleAddHandset (prodId) {
    const product = this.state.products.filter(f => f.prodId === prodId)
    const tempData = [{
      qty: 1,
      ...product[0]
    }]
    localStorage.setItem('HardwareFirst', JSON.stringify(tempData))
    this.props.history.push('/get-started')
  }

  getCustomers () {
    const url = 'api/Home/GetTotalCustomers'
    fetchHelper.getJson(url)
      .then(response => {
        if (response !== undefined) { this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }
}
export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(DevicesTiny)
