import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import FeaturesList from '../../components/Competitors/FeaturesList'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface HiHiProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const HiHi = (props: HiHiProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>HiHi vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>HiHi vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of HiHi vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <p>It is immediately apparent when you arrive on HiHi’s website, that their main objective is to promote their flagship HiHi3 desk phone.  Their marketing is on-point and the large, high-resolution graphics and videos are enticing, to say the least.</p>
                            <p>HiHi’s Android-based media phone looks very impressive. With a built-in video camera, Google Play Store apps and a big colour touchscreen, this really looks like the future of integrated business communications.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Things I couldn’t find</h2>
                            <p>As a confessed gadget lover and early adopter of anything technical, I found myself naturally seduced by this sleek-looking piece of hardware. All the features and benefits are beautifully laid out for you to absorb and they do a very good job of marketing this to you.</p>
                            <p>However, after a few minutes of searching their website, I started to become frustrated that I couldn’t find any prices or terms for my potential commitment.</p>
                            <p>I couldn’t even find such basics as non-technical FAQs, contract specifics, or even terms and conditions, and as a business owner myself and a potential customer, this information would be fundamental to any buying decision.</p>
                            <p>And it’s not like this information is just well-hidden or only accessible to subscribers. These crucial details seem to be intentionally kept from you unless you call one of their salespeople, and trust me, you’ll need to book some time out of your day if you do.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Sometime later</h2>
                            <p>It came as no surprise that the phone system isn’t cheap. For a couple of handsets plus an average number of calls, the quote came close to a couple of hundred pounds a month.</p>
                            <p>Although it’s difficult to give a true price comparison, as SwitchboardFREE doesn’t offer the same hardware, the price will not be your only concern when compared to the seven-year contract you’ll need to sign.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">But they have good reviews</h2>
                            <p>On the surface of things, HiHi has five-star reviews and seemingly happy customers. With only fourteen, one-star reviews out of 1,000, they can’t be all that bad - right?</p>
                            <p>Well, one rabbit hole led to the discovery that HiHi changed its name in 2017. They used to be known as <a href="https://find-and-update.company-information.service.gov.uk/company/07590894" target="_blank" rel="noreferrer">4COM technologies limited</a>. Now, 4COM reviews came as a shock and rang a few alarm bells. They had accumulated more than <a href="https://uk.trustpilot.com/review/4com.co.uk?stars=1" target="_blank" rel="noreferrer">seventy, 1-star reviews</a>. Interestingly, these reviews heavily referenced the seven-year contract the (now) HiHi insists on.</p>
                            <p>With this revelation, I urge you to check any review in the name of 4COM before coming to any decision on HiHI.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">The reality</h2>
                            <p>To be truthful, although HiHi captured me like a magpie with their shiny new tech, what with my smartphone, laptop and PC, I’m not sure I need (or can even justify having) another screen.</p>
                            <p>I can already make and receive business calls on my mobile using my business number with my SwitchboardFREE phone <a href="/app" target="_blank">app</a>. Not only that, but I can use the free SwitchboardFREE <a href="/softphone" target="_blank">softphone</a> on my laptop and PC. Consequently, for me, I have to say that HiHi doesn’t make financial business sense.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What about you?</h2>
                            <p>If you <i>don’t</i> happen to have more tech than you know what to do with, then HiHi’s flagship 10-inch tablet & phone combo could look like a game changer for your business. The only letdown is that it has 3GB of RAM. (Full Technical Spec <a href="https://www.hihi.co.uk/wp-content/uploads/2021/07/Tech-Spec-HiHi3.pdf" target="_blank" rel="noreferrer">here</a>). If you do a bit of research, comparatively, you can purchase a 10-inch Amazon Fire HD tablet, with a similar spec for a fraction of the cost. And to compensate for the phone element, you could add your Microsoft Teams, Zoom and the SwitchboardFree Phone app to this tablet instead.</p>
                            <p>Ultimately, with HiHi, what you have to consider is whether you want to invest several hundreds of pounds every month for close to a decade. I don’t know about you, but I’ve no idea how my business will change over the next few years. Adversely, a long-term commitment could become a barrier to any company's growth.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Vs SwitchboardFREE</h2>
                            <p>Now you know about HiHi, as promised in the title, here is how SwitchboardFREE stacks up.</p>
                            <p>They don’t insist that you use their hardware, but for those that love something new to play with, you can have a Bluetooth headset and optimised desk phone with SwitchboardFree’s unlimited call package. You’ll find your hardware has more functionality and better battery life, and as for the rest of the package, you’ll get unlimited calls in and out and 20 FREE features. All you will pay is £12 per month. And more importantly - No contract!</p>
                            <p>SwitchboardFree’s Free Features:</p>

                            <FeaturesList />

                            <p>I don’t need to point out how much cheaper SwitchboardFREE is over the short and long term compared to HiHi. Not to mention the freedom that comes with having no contract. They don’t compromise on service either, check out their awards!</p>
                            <p>So finally, if you're seriously considering using the HiHi for your VoIP service, then please contact or message the friendly team at SwitchboardFREE via their contact page. They will be more than happy to give you a full like-for-like comparison.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see HiHis current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.hihi.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(HiHi)
