import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface VoIPQualityTestProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const VoIPQualityTest = (props: VoIPQualityTestProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>VoIP Quality Speed Test</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/features-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1>VoIP Quality Speed Test</h1>
                <h2 className="subtitle">Test the quality of your connection and learn more about VoIP terminology</h2>
            </header>

            <div className="container">
            <h2 className="text-500 text-darker text-xl mt-40 mb-30">What exactly is a VoIP test?</h2>
            <p>A VoIP quality test is a test that can be run to gather information about various sections of your internet connection. These sections include download speeds, upload speeds, latency, jitter and package loss. The details in the VoIP quality test can help identify potential problems with an internet connection.</p>

            <h2 className="text-500 text-darker text-xl mt-40 mb-30">How do I run a VoIP test?</h2>
            <p>To run a VoIP test is simple! You can run a VoIP speed by clicking the button below which will show key information such as latency, jitter and packet loss. </p>
            <Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md m-5 mt-0 mb-20" id="RunYourVoIPTestButton" href="https://speed.cloudflare.com" target="_blank">Run your VoIP test</Button>

            <h4 className="text-darker mt-20 mb-10"><b>Quality Test Examples</b></h4>
            <p>Below is an example of a good VoIP test which is reporting low latency, jitter and 0% packet loss.</p>
            <img src="/images/voiptest/goodtest.png" alt="Good speed test" className="pb-20"/>

            <p>Below is an example of a poor quality VoIP test which is reporting a high latency, high jitter and 10% packet loss. Having high values like the below will cause a very poor quality call.</p>
            <img src="/images/voiptest/badtest.png" alt="Bad speed test" />

            <h2 className="text-500 text-darker text-xl mt-40 mb-30">The top tips to help your VoIP connection quality</h2>
            <ul className="unstyled text-400">
                <li className="mb-10">
                    <span className="icon icon-checkmark-circle text-success mr-10" />
                    Check your internet connection: Make sure that your internet connection is stable and fast enough to support VoIP calls. Close any unnecessary programs or apps that may be using bandwidth and run a speed test to check your internet speed.
                </li>
                <li className="mb-10">
                    <span className="icon icon-checkmark-circle text-success mr-10" />
                    Check your router: Make sure that your router is up-to-date and that the signal is strong enough. Try moving your device closer to the router or resetting the router if necessary.
                </li>
                <li className="mb-10">
                    <span className="icon icon-checkmark-circle text-success mr-10" />
                    Use a wired connection: If possible, connect your device to the internet using an Ethernet cable instead of Wi-Fi. This can provide a more stable connection for VoIP calls.
                </li>
                <li className="mb-10">
                    <span className="icon icon-checkmark-circle text-success mr-10" />
                    Enable Quality of Service (QoS) on your router: as explained above, QoS prioritises the traffic on your network, making sure that the VoIP traffic has a higher priority over other types of traffic.
                </li>
            </ul>

            <h2 className="text-500 text-darker text-xl mt-40 mb-30">What happens if I have a poor VoIP connection?</h2>
            <p>If you have a poor VoIP connection, it means that the quality of your phone calls over the internet will be poor. This can include issues such as delays in the audio, choppy or distorted audio, and dropped calls. This can be caused by a variety of factors such as a slow internet connection, a weak signal, or interference from other devices.</p>

            <h2 className="text-500 text-darker text-xl mt-40 mb-30">The key VoIP quality terms</h2>
            <h4 className="text-darker mt-20 mb-10"><b>QoS</b></h4>
            <p>Quality of Service (QoS) is a set of techniques used to manage and prioritise network traffic to ensure that applications receive the necessary bandwidth and resources to function properly. QoS allows network administrators to set priorities for different types of traffic, such as voice and video, and to ensure that these types of traffic receive priority over less critical applications, such as email or file transfers. This helps to ensure that VoIP calls are clear and of high quality, with minimal delay or interruption.</p>

            <h4 className="text-darker mt-20 mb-10"><b>Bandwidth</b></h4>
            <p>Bandwidth is a term used to describe the amount of data that can be transmitted over a network at a given time.</p>
            <p>VoIP calls require a certain amount of bandwidth to function properly. The more users on a call and the higher the audio and/or video quality, the more bandwidth is needed. If there is not enough bandwidth available, the call quality may suffer and users might experience issues such as choppy audio, delayed audio and video, or dropped calls.</p>
            <p>To ensure good quality VoIP calls, it is important to have sufficient bandwidth available on the network. This can be achieved by adding more bandwidth to the network, or by using Quality of Service (QoS) techniques to manage and prioritise the traffic on the network.</p>

            <h4 className="text-darker mt-20 mb-10"><b>Latency</b></h4>
            <p>Latency is a term used to describe the delay in the delivery of packets of data over a network. In the context of Voice over Internet Protocol (VoIP) systems, latency refers to the delay that occurs between the time a user speaks and the time the audio is heard by the other party.</p>
            <p>Latency can cause problems with the quality of VoIP calls, as users may experience a delay between speaking and hearing the other party. This can make it difficult for users to carry on a conversation in real-time and can lead to confusion or frustration. High latency can also cause audio to be heard as choppy or delayed, which can make it difficult for users to understand each other.</p>
            <p>Latency can be caused by several factors, including network congestion, routing issues, or hardware problems.</p>

            <h4 className="text-darker mt-20 mb-10"><b>Jitter</b></h4>
            <p>Jitter is a term used to describe variations in the delay of packets of data being transmitted over a network. In Voice over Internet Protocol (VoIP) systems, jitter can cause problems with the quality of the audio transmission. Jitter can cause audio to be heard as choppy or delayed, which can make it difficult for users to understand each other.</p>

            <h4 className="text-darker mt-20 mb-10"><b>Packet loss</b></h4>
            <p>Packet loss is a term used to describe the situation when one or more packets of data are not successfully delivered over a network. In the context of Voice over Internet Protocol (VoIP) systems, packet loss can cause problems with the quality of the audio transmission.</p>
            <p>When a packet is lost, the audio information it contains is not received by the other party, causing gaps in the audio transmission. This can cause audio to be heard as choppy or garbled, making it difficult for users to understand each other. High packet loss can also cause calls to drop.</p>
            <p>Packet loss can be caused by several factors, including network congestion, routing issues, or hardware problems. Additionally, if the network's capacity is not sufficient for data being transmitted, packet loss can occur.</p>

            <h4 className="text-darker mt-20 mb-10"><b>Download and upload speeds</b></h4>
            <p>Download and upload speeds refer to the rate at which data can be transferred over a network. In VoIP download refers to the rate at which data (such as audio and video) can be received by the user, while upload speed refers to the rate at which data (such as audio and video) can be sent by the user.</p>
            <p>For a good quality VoIP call, both download and upload speeds must be sufficient to handle the amount of data being transmitted during the call. This includes not only the audio and video data, but also any additional data such as call control signals and call setup information. If either the download or upload speed is too slow, the call quality may suffer and users may experience issues such as choppy audio, delayed audio and video, or dropped calls.</p>
            <p>To ensure good quality VoIP calls, it is important to have sufficient download and upload speeds available on the network. This can be achieved by adding more bandwidth to the network, or by using Quality of Service (QoS) techniques to manage and prioritise the traffic on the network. Additionally, using a direct and low-latency internet connection such as fibre optic can help.</p>

            <h2 className="text-500 text-darker text-xl mt-40 mb-30">For further assistance</h2>
            <p className="mt-40">If you need any assistance around VoIP connection quality, feel free to reach out to one of our friendly UK-based support team <a className="underline cursor-pointer" href="/contact">here</a> or request a call back below. </p>

            <div className="row">
                <div className="col-md-6">
                    <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />
                </div>
            </div>

            </div>

        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(VoIPQualityTest)
