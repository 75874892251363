import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import DisplayMainFooter from '../../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../../../helpers/fetchHelper'
import { isLoggedIn } from '../../../../selectors'

class WebHooks extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showXmas: false,
      showBeauty: false,
      showFuneral: false,
      showSolicitors: false,
      showDental: false,
      showJob: false,
      showProduct: false,
      showRadio: false,
      showDriving: false,
      showEstates: false,
      showBuilders: false,
      showSecurity: false,
      showFlowers: false,
      showCakes: false,
      exampleSrc: '',
      totalCustomers: '0'
    }
  }

  render () {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 359,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>Integrate SwitchboardFREE with Salesforce, HubSpot, Zoho and more</title>
                <meta name="description" content="Discover how to utilise SwitchboardFREE's Webhooks and Zapier to instantly integrate your VoIP phone system with Salesforce, HubSpot, Zoho and much more" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/web-hooks-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1 id="WebhooksH1">Webhooks Integrations</h1>
                <h2 className="subtitle">Integrate your existing CRM and reporting systems with your SwitchboardFREE numbers</h2>
            </header>
            <div className="full-width bg-light">
                <div className="container pb-30 pt-40">
                    <div className="row">
                        <div className="col-md-4 pb-sm-30">
                            <h4 className="text-500 pb-20">About SwitchboardFREE</h4>
                            <p>
                                SwitchboardFREE is a virtual telephone number and VoIP phone service, established to ensure people and businesses ‘never miss a call again’.
                                SwitchboardFREE is the largest and fastest growing service of this kind in the UK, having helped over {this.state.totalCustomers} companies to grow their business.
                                There’s a huge choice of virtual telephone numbers, simple to use web and mobile portal for complete control, underpinned with business-grade infrastructure to offer you an unbeatable 99.99% uptime.
                            </p>
                        </div>
                        <div className="col-md-4 pb-sm-30">
                            <div className="br-2 br-sm-0 bl-2 bl-sm-0 pr-30 pr-sm-0 pl-30 pl-sm-0">
                                <h4 className="text-500 pb-20">VoIP Phone System</h4>
                                <p>
                                    A virtual phone number is a telephone number without a telephone line and It’s a number that can be used to route calls to your landline, or, more likely, your mobile phone.
                                    Because calls are not routed through a traditional exchange you can be based anywhere in the UK and benefit from SwitchboardFREE’s choice of virtual numbers such as 0800; 0844 &amp; 0845; 03;
                                    0207 and local (geographic) numbers.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 pb-sm-30">
                            <h4 className="text-500 pb-20">Virtual Phone Number</h4>
                            <p>
                                A VoIP phone system gives you everything a traditional phone system gives you but with one crucial difference: i&apos;s hosted in the cloud.
                                A cloud-based phone system gives you much more flexibility because you can take and manage calls not just from your desk phone, but from your computer and your mobile.
                                So you can use all of the features of the office system wherever you are.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-50 pt-30 bt-2 bt-sm-0 pt-sm-0 mt-sm-0">
                            <div className="row">
                                <div className="col-md-4 col-lg-3 text-center">
                                    <img src="/images/webhooks/zapier-logo.png" style={{ maxHeight: '130px', maxWidth: '100%' }} />
                                </div>
                                <div className="col-md-8 co-lg-9 pt-30">
                                    <p className="text-500">SwitchboardFREE has partnered with <a href="https://zapier.com/" target="_blank" className="text-cta text-500 underline" rel="noreferrer">Zapier</a> to provide an immersive experience to automate your workflow.</p>
                                    <p>Our system seemlessly integrates with the tools you already know and love. Automate your business and productivity tasks, so you can focus on what’s important to you. Get started today!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-60 mb-20">
                            <div className="bt-2 brad-5 p-20 text-center">
                                <h3 className="m-0 p-0 text-dark text-500 pt-30">Most Popular Integrations</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row zapier-popular">
                        <Slider className="showreel-page" {...settings}>

                            <div className="pl-15 pr-15 col-md-2 col-sm-4 col-xs-6 text-center">
                                <Link id="salesForceIconLink" to={'/web-hook-integrations/salesforce'}>
                                    <div className="well pb-30 pt-30 bg-dull">
                                        <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                                            <img src="/images/webhooks/salesforce.png" height="105" alt="salesforce logo" className="pt-20 pb-20" />
                                        </div>
                                    </div>
                                </Link>
                                <h5 className="text-500 mt-15 mb-10">
                                    Salesforce
                            </h5>
                                <p className="text-xs">
                                    <Link id="salesForceViewDetailsLink" to={'/web-hook-integrations/salesforce'} className="text-dark underline">View details</Link>
                                </p>
                            </div>
                            <div className="pl-15 pr-15 col-md-2 col-sm-4 col-xs-6 text-center">
                                <Link id="hubSpotIconLink" to={'/integrations/hubspot'}>
                                    <div className="well pb-30 pt-30 bg-dull">
                                        <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                                            <img src="/images/webhooks/hubspot.png" height="105" alt="hubspot logo" className="pt-20 pb-20" />
                                        </div>
                                    </div>
                                </Link>
                                <h5 className="text-500 mt-15 mb-10">
                                    HubSpot
                            </h5>
                                <p className="text-xs">
                                    <Link id="hubSpotViewDetailsLink" to={'/integrations/hubspot'} className="text-dark underline">View details</Link>
                                </p>
                            </div>
                            <div className="pl-15 pr-15 col-md-2 col-sm-4 col-xs-6 text-center">
                                <Link id="trelloIconLink" to={'/web-hook-integrations/trello'}>
                                    <div className="well pb-30 pt-30 bg-dull">
                                        <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                                            <img src="/images/webhooks/trello.png" height="105" alt="trello logo" className="pt-20 pb-20" />
                                        </div>
                                    </div>
                                </Link>
                                <h5 className="text-500 mt-15 mb-10">
                                    Trello
                            </h5>
                                <p className="text-xs">
                                    <Link id="trelloViewDetailsLink" to={'/web-hook-integrations/trello'} className="text-dark underline">View details</Link>
                                </p>
                            </div>
                            <div className="pl-15 pr-15 col-md-2 col-sm-4 col-xs-6 text-center">
                                <Link id="slackIconLink" to={'/web-hook-integrations/slack'}>
                                    <div className="well pb-30 pt-30 bg-dull">
                                        <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                                            <img src="/images/webhooks/slack.png" height="105" alt="slack logo" className="pt-20 pb-20" />
                                        </div>
                                    </div>
                                </Link>
                                <h5 className="text-500 mt-15 mb-10">
                                    Slack
                            </h5>
                                <p className="text-xs">
                                    <Link id="slackViewDetailsLink" to={'/web-hook-integrations/slack'} className="text-dark underline">View details</Link>
                                </p>
                            </div>
                            <div className="pl-15 pr-15 col-md-2 col-sm-4 col-xs-6 text-center">
                                <Link id="googleSheetsIconLink" to={'/web-hook-integrations/google-sheets'}>
                                    <div className="well pb-30 pt-30 bg-dull">
                                        <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                                            <img src="/images/webhooks/google-sheets.png" alt="google sheets logo" height="105" className="pt-20 pb-20" />
                                        </div>
                                    </div>
                                </Link>
                                <h5 className="text-500 mt-15 mb-10">
                                    Google Sheets
                            </h5>
                                <p className="text-xs">
                                    <Link id="googleSheetsViewDetailsLink" to={'/web-hook-integrations/google-sheets'} className="text-dark underline">View details</Link>
                                </p>
                            </div>
                            <div className="pl-15 pr-15 col-md-2 col-sm-4 col-xs-6 text-center">
                                <Link id="capsuleIconLink" to={'/integrations/capsule-crm'}>
                                    <div className="well pb-30 pt-30 bg-dull">
                                        <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                                            <img src="/images/webhooks/capsule.png" alt="capsule logo" height="105" className="pt-20 pb-20" />
                                        </div>
                                    </div>
                                </Link>
                                <h5 className="text-500 mt-15 mb-10">
                                    Capsule CRM
                            </h5>
                                <p className="text-xs">
                                    <Link id="capsuleViewDetailsLink" to={'/integrations/capsule-crm'} className="text-dark underline">View details</Link>
                                </p>
                            </div>
                            <div className="pl-15 pr-15 col-md-2 col-sm-4 col-xs-6 text-center">
                                <Link id="todoistIconLink" to={'/web-hook-integrations/todoist'}>
                                    <div className="well pb-30 pt-30 bg-dull">
                                        <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                                            <img src="/images/webhooks/todoist.png" alt="todoist logo" height="105" className="pt-20 pb-20" />
                                        </div>
                                    </div>
                                </Link>
                                <h5 className="text-500 mt-15 mb-10">
                                    Todoist
                            </h5>
                                <p className="text-xs">
                                    <Link id="todoistViewDetailsLink" to={'/web-hook-integrations/todoist'} className="text-dark underline">View details</Link>
                                </p>
                            </div>
                            <div className="pl-15 pr-15 col-md-2 col-sm-4 col-xs-6 text-center">
                                <Link id="microsoftToDoIconLink" to={'/web-hook-integrations/microsoft-to-do'}>
                                    <div className="well pb-30 pt-30 bg-dull">
                                        <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                                            <img src="/images/webhooks/todo.png" alt="microsoft to-do logo" height="105" className="pt-20 pb-20" />
                                        </div>
                                    </div>
                                </Link>
                                <h5 className="text-500 mt-15 mb-10">
                                    Microsoft To-do
                            </h5>
                                <p className="text-xs">
                                    <Link id="microsoftToDoViewDetailsLink" to={'/web-hook-integrations/microsoft-to-do'} className="text-dark underline">View details</Link>
                                </p>
                            </div>
                            <div className="pl-15 pr-15 col-md-2 col-sm-4 col-xs-6 text-center">
                                <Link id="zohoIconLink" to={'/web-hook-integrations/zoho'}>
                                    <div className="well pb-30 pt-30 bg-dull">
                                        <div className="well well-light brad-20 p-10 mb-0 box-shadow" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                                            <img src="/images/webhooks/zoho.png" alt="zoho logo" height="105" className="pt-20 pb-20" />
                                        </div>
                                    </div>
                                </Link>
                                <h5 className="text-500 mt-15 mb-10">
                                    Zoho CRM
                            </h5>
                                <p className="text-xs">
                                    <Link id="zohoViewDetailsLink" to={'/web-hook-integrations/zoho'} className="text-dark underline">View details</Link>
                                </p>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>

            <div className="full-width full-dull">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pt-30 pb-60">
                            <h3 className="text-500">
                                Plus, there’re loads more...
                            </h3>
                            <p className="pt-10">
                                We support all Zapier integrations. Go to <a href="https://www.zapier.com/" className="text-cta underline">www.zapier.com</a> to find out more.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-8 mx-auto">
                            <div className="row zapier-list">
                                <div className="col-12 w-700px center-block">
                                    <a href="https://zapier.com/apps/airtable" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/airtable.png" alt="airtable logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Airtable</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/typeform" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/typeform.jpg" alt="typeform logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">TypeForm</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/clickfunnels" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/clickfunnels.png" alt="clickfunnels logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">ClickFunnels</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/gravity-forms" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/gravity-forms.png" alt="gravity forms logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Gravity Forms</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/asana" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/asana.png" alt="asana logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Asana</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/google-contacts" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/google-contacts.png" alt="google contacts logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Google Contacts</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/stripe" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/stripe.jpg" alt="stripe logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Stripe</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/youtube" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/youtube.png" alt="youtube logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">YouTube</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/dropbox" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/dropbox.png" alt="dropbox logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">DropBox</span>
                                        </div>
                                    </a>

                                    <a href="https://zapier.com/apps/facebook-pages" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/facebook-pages.png" alt="facebook logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Facebook Pages</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/google-forms" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/google-forms.png" alt="google forms logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Google Forms</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/calendly" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/calendly.png" alt="calendly logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Calendly</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/discord" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/discord.png" alt="discord logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Discord</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/jotform" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/jotform.png" alt="JotForm logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">JotForm</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/kajabi" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/kajabi.jpg" alt="Kajabi logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Kajabi</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/instagram" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/instagram.png" alt="instagram logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Instagram</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/woocommerce" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/woocommerce.jpg" alt="WooCommerce logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">WooCommerce</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/acuity-scheduling" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/acuity.jpg" alt="acuity logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Acuity Scheduling</span>
                                        </div>
                                    </a>

                                    <a href="https://zapier.com/apps/wordpress" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/wordpress.png" alt="wordpress logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">WordPress</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/shopify" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/shopify.png" alt="shopify logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Shopify</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/squarespace" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/squarespace.png" alt="squarespace logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Squarespace</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/webflow" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/webflow.png" alt="webflow logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Webflow</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/zendesk" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/zendesk.png" alt="zendesk logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Zendesk</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/evernote" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/evernote.jpg" alt="evernote logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Evernote</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/any-do/integrations" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/anydo.png" alt="Any.do logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Any.do</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/quickbooks" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/quickbooks.png" alt="Quick Books logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">QuickBooks Online</span>
                                        </div>
                                    </a>
                                    <a href="https://zapier.com/apps/eventbrite" target="_blank" className="no-underline" rel="noreferrer">
                                        <div className="bg-light mb-10 brad-10 pt-10 pb-10 b-1 product pl-20">
                                            <img src="/images/webhooks/Eventbrite.png" alt="Eventbrite logo" width="30" height="30" /> <span className="text-sm pl-10 text-500">Eventbrite</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center pt-50 pb-60">
                                    <a href="https://www.zapier.com/" className="btn btn-cta btn-md">Go to Zapier</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pt-30 pb-60">
                            <h3 className="text-500">
                                Integration FAQs
                            </h3>
                            <p className="pt-10">
                                Want to know more?
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-8 mx-auto pb-60 faq">
                            <Accordion>
                                <Accordion.Item className="panel-unstyled" eventKey="0">
                                    <Accordion.Header toggle className="text-400 text-start">

                                            What are Webhooks?

                                    </Accordion.Header>

                                        <Accordion.Body>
                                            <p>Webhooks are HTTP calls that are user-defined and triggered at the end of each phone call that you receive to your SwitchboardFREE number.
                                            Each Webhook contains valuable information such as the result of the call (e.g. Was it answered? Was it missed? Did the caller leave a voicemail?).
                                        </p>
                                        </Accordion.Body>

                                </Accordion.Item>
                                <Accordion.Item className="panel-unstyled" eventKey="1">
                                    <Accordion.Header toggle className="text-400 text-start">

                                            Why should you use SwitchboardFREE Webhooks?

                                    </Accordion.Header>

                                        <Accordion.Body>
                                            <p>In all business it’s important to be as efficient as possible, ensuring good integration between the various systems that are used;
                                            ticketing support systems, CRM systems and email is one way to achieve this and integrating your telephone system is no different.
                                            While SwitchboardFREE provide detailed call reports, call statistics and missed call emails –
                                            wouldn’t it be great to get all of this information posted in real-time to your other systems? Saving you valuable time logging in and out of multiple systems.
                                        </p>
                                        </Accordion.Body>

                                </Accordion.Item>
                                <Accordion.Item className="panel-unstyled" eventKey="2">
                                    <Accordion.Header toggle className="text-400 text-start">

                                            How can Webhooks be used?

                                    </Accordion.Header>

                                        <Accordion.Body>
                                            <p>
                                                Just one example of how Webhooks can be used is to add valuable information into your CRM system on who is calling and when.
                                                You could highlight customers that call in regularly or keep track of which customers are keeping you on the phone the longest.
                                                If you offer customers chargeable support and advice over the phone you could use this information as a basis for producing their service charges.
                                            </p>
                                            <p>
                                                Webhooks are a free feature of SwitchboardFREE and are quick and easy to setup. If you are already a SwitchboardFREE customer <a href="/login" className="text-cta">click here to log in</a> or call <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a> to find out more.
                                            </p>
                                        </Accordion.Body>

                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pt-30 pb-60">
                            <h3 className="text-500">
                                Create your account today
                            </h3>
                            <p className="pt-10">
                                <Link to={'/get-started'} className="btn btn-cta btn-xl mt-30">Sign up now</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentWillMount () { }

  componentDidMount () {
    const context = this
    context.getMins()
    context.getCustomers()
    window.setInterval(function () {
      context.getMins()
    }, 300000) // Every 5-mins
  }

  getMins () {
    const url = 'api/Home/GetTotalMins'
    fetchHelper.getJson(url)
      .then(response => {
        if (response.data !== undefined) { this.setState({ totalMins: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }

  getCustomers () {
    const url = 'api/Home/GetTotalCustomers'
    fetchHelper.getJson(url)
      .then(response => {
        if (response !== undefined) { this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(WebHooks)
