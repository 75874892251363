import update from 'immutability-helper'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import Select from 'react-select'
import CallbackRequest from '../../../components/CallbackRequest'
import DisplayLoggedInFooter from '../../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ChoosePayment from '../../../components/purchase/ChoosePayment'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { productBillingOption, ProductIds } from '../../../constants/Constants'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { calculateVat, getQueryStringFromProps } from '../../../helpers/utils'
import { getProductPrice } from '../../../store/Home'
import { handleNotificationChange, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../../store/SBF'
import TermsAndConditions from '../../../Text/TermsAndConditions'

const _ = require('lodash')

class FaxToEmail extends React.Component {
  state = { isPurchasing: false }

  constructor (props) {
    super(props)
  }

  componentDidMount () {
    const context = this
    const url = 'api/Purchase/GetFaxNumbers'
    fetchHelper.getJson(url).then((response) => {
      context.setState({ faxNos: response.data })
      if (response.data && response.data.length > 0) {
        this.setState({ selectedFaxNo: this.state.faxNos[0] })
      }
    }).catch((response) => {
      console.log(response)
    })

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
          'use strict'
          if (typeof start !== 'number') {
            start = 0
          }

          if (start + search.length > this.length) {
            return false
          } else {
            return this.indexOf(search, start) !== -1
          }
        }
      }
      if (params.includes('new=true')) {
        context.props.notify({ message: 'Purchase complete' })
      }
    }

    document.title = 'Fax to Email'

    this.props.getProductDetails([
      { productId: ProductIds.faxToEmail, monthlyOption: productBillingOption.f2eBillingOptionMonthly, yearlyOption: productBillingOption.f2eBillingOptionYearly }
    ])
  }

  attemptPurchaseFaxNumber (billingOption) {
    const context = this
    if (!context.state.selectedFaxNo) {
      context.props.notify({ message: 'Please select a fax number', isError: true })
      return
    }

    context.setState({ isPurchasing: true }, () => {
      const data = {
        ddiid: Number(context.state.selectedFaxNo.value),
        billingOption
      }

      const url = 'api/Purchase/PurchaseFaxNumber/'
      fetchHelper.postJson(url, data).then((res) => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          const faxNos = context.state.faxNos
          const selectedFaxNoIndex = _.findIndex(faxNos, faxNo => {
            return faxNo.value === context.state.selectedFaxNo.value
          })
          const updatedFaxNos = update(faxNos, { $splice: [[selectedFaxNoIndex, 1]] })
          context.setState({ ...context.state, isPurchasing: false, ...res.data, faxNos: updatedFaxNos, selectedFaxNo: undefined })
        } else if (res.data && res.data.postponed === true) {
          const faxNos = context.state.faxNos
          const selectedFaxNoIndex = _.findIndex(faxNos, faxNo => {
            return faxNo.value === context.state.selectedFaxNo.value
          })
          const updatedFaxNos = update(faxNos, { $splice: [[selectedFaxNoIndex, 1]] })
          context.setState({ ...context.state, isPurchasing: false, ...res.data, faxNos: updatedFaxNos, selectedFaxNo: undefined }, () => {
            context.props.handleShowPaymentPostponedModal()
          })
        } else {
          context.setState({ key: res.data.key, amount: res.data.amount })
          context.props.handleShowPaymentOptionsModal()
        }
      }).catch((response) => {
        console.log(response)
      })
    })
  }

  render () {
    const state = this.state
    let yearlyPriceExcVat = 29.99
    let monthlyPriceExcVat = 4.97

    if (this.props.productDetails) {
      const f2e = this.props.productDetails.find(prod => prod.productId === ProductIds.faxToEmail)
      if (f2e) {
        monthlyPriceExcVat = f2e.monthlyPrice.repeatPaymentAmount
        yearlyPriceExcVat = f2e.yearlyPrice.repeatPaymentAmount
      }
    }

    return <><ScrollToTopOnMount/><DisplayLoggedInFooter/><DisplayMainHeader/>
        {/* <UpgradesMenu/> */}
            <header>
                <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/fax-bg.jpg')" }}>
                <h1 id="Fax2EmailH1">Fax 2 Email</h1>
                    <h2 className="subtitle">Save time and money with fast and reliable fax to email</h2>
                </div>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="w-600px center-block pt-40">
                            <p>
                                Receive faxes as easily and quickly as you receive emails with SwitchboardFREE Fax to email.
                            </p>
                            <p>
                                Faxes will be sent directly to your personal inbox as PDF attachments that you can read and print
                                at your convenience - no more wasted paper, no fax machine or expensive toners required.
                            </p>
                            <p>
                                Receive unlimited faxes on your SwitchboardFREE fax number for only £{monthlyPriceExcVat} per month,
                                or save even more with annual Fax to Email, at just £{yearlyPriceExcVat} for the whole year.
                            </p>
                        </div>
                    </div>
                    <div className="row text-400 mt-30 mb-60 w-600px center-block ">
                        <div className="b-1 brad-20 box-shadow bg-light p-30 col-xs-12">
                            <div className="col-xs-12">
                                <label>Choose your fax-to-email number</label>
                               <Select
                                    id="fax2email"
                                    className="form-control select-menu"
                                    classNamePrefix="custom"
                                    onChange={value => {
                                      const selectedFaxNo = _.find(state.faxNos, faxNoOption => faxNoOption.value === value.value)
                                      return this.setState({ selectedFaxNo })
                                    }}
                                    options={state.faxNos}
                                    clearable={false}
                                    simpleValue
                                    isLoading={!state.faxNos}
                                    name="selectedFax2Email"
                                    value={state.selectedFaxNo}
                                    searchable={false}
                                    disabled={this.state.isPurchasing}
                                />
                            </div>
                            <div className="col-xs-12 pt-20 pb-30 pl-0 pr-0">
                                <div className="row">
                                    <div className="col-xs-12 text-center col-sm-5">
                                        <Button variant="cta" className="btn-block" disabled={this.state.isPurchasing}
                                                onClick={event => {
                                                  this.setState({ amount: calculateVat(monthlyPriceExcVat) }, () => this.attemptPurchaseFaxNumber(productBillingOption.billingOptionMonthly))
                                                }}>&pound;{monthlyPriceExcVat} / month</Button>
                                    </div>
                                    <div className="col-xs-12 col-sm-2 text-center pt-15 pl-0 pr-0">
                                        <p>or...</p>
                                    </div>
                                    <div className="col-xs-12 col-sm-5 text-center">
                                        <Button variant="cta" className="btn-block" disabled={this.state.isPurchasing}
                                                onClick={event => {
                                                  this.setState({ amount: calculateVat(yearlyPriceExcVat) }, () => this.attemptPurchaseFaxNumber(productBillingOption.billingOptionYearly))
                                                }}>&pound;{yearlyPriceExcVat} / year</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full-width full-secondary mt-30 pt-30 pb-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h4 className="text-500">Have any questions?</h4>
                                <h5 className="pb-20"> Give us a call on <a href="tel:02031891213">0203 189 1213</a> or
                                    request a callback</h5>
                                <CallbackRequest/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 text-center pt-30 pb-30">
                            <small>
                                By purchasing this product you agree to the <a
                                onClick={e => this.setState({ showTsAndCs: true })}>terms &amp; conditions</a> of this
                                product.
                                <br/>All prices are exclusive of VAT at 20%.
                            </small>
                        </div>
                        <Modal show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
                            <Modal.Header closeButton>
                                <Modal.Title>Terms &amp; Conditions</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <TermsAndConditions/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary"
                                        onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
            <ChoosePayment
                total={calculateVat(this.state.amount)}
                clientId={this.props.clientId}
                purchaseData={this.state.key}
                email={this.props.userDetails.email}
                name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                mandate={!this.props.services.hasAgreement}
            />

        </>
  }
}

export default connect(
  state => {
    return {
      productDetails: state.home.productDetails.products,
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    getProductDetails: getProductPrice,
    notify: handleNotificationChange,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal
  }
)(FaxToEmail)
