import update from 'immutability-helper'
import React, { Component } from 'react'
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import TermsAndConditions from '../Text/TermsAndConditions'
import DisplayLoggedInFooter from '../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import GetProdPrice from '../components/GetProdPrice'
import LoadingNotification from '../components/LoadingNotification'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import ChoosePayment from '../components/purchase/ChoosePayment'
import { ProductIds, productBillingOption } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import { calculateVat, getQueryStringFromProps } from '../helpers/utils'
import { handleNotificationChange, handleShowBillingWarning, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal, handleVipCallersEnabledChange } from '../store/SBF'

const _ = require('lodash')

class VipCallers extends Component {
  static defaultProps = { isEnabled: false, isImporting: false }

  static propTypes = {}

  state = {
    showTsAndCs: false,
    isPurchaseInProgress: false,
    validContactEntered: false,
    newCallPhoneNumberValid: true
  }

  render () {
    const vipExplain = (
            <Popover title="What is a VIP Caller?">
                <p>Similar to a Prioirty Caller, VIP's will jump to the front of your queue. Calls from VIP's will also be put through even when your lines are closed.</p>
            </Popover>
    )

    const prioExplain = (
            <Popover title="What is a Priority Caller?">
                <p>Marking a caller as a Priority will promote this caller to the front of your queue whenever they call your SwitchboardFREE number.</p>
            </Popover>
    )

    const yearlyPrice = this.props.yearlyPrice
    const monthlyPrice = this.props.monthlyPrice

    return <><ScrollToTopOnMount /><DisplayLoggedInFooter /> <DisplayMainHeader />
            <Helmet>
                <title>Call Prioritiser</title>
            </Helmet>
            <header>
                <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/contactbook-bg.jpg')" }}>
                    <h1 id="CallPrioritiserH1">Call Prioritiser</h1>
                    <h2 className="subtitle">Prioritise your more valued customers</h2>
                </div>
            </header>

            <div className="container-styled">
                {this.props.isEnabled
                  ? <>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center mb-60 text-center">
                                    {/* <h1>Call Prioritiser</h1>
                                    <p className="text-md pt-30">
                                        Categorise callers numbers as <span className="text-500">priority</span> to promote their
                                        calls to the front of your call queue.<br className="d-none d-lg-block"/>Or, <span className="text-500">VIP</span> to promote calls to the front of your queue and put through calls even <br className="d-none d-lg-block"/>when your lines are closed.
                                    </p> */}

                                    <LoadingNotification isLoading={!this.state.contacts} width={100} height={100}
                                        hasFinishedLoading={this.state.contacts} removeOnLoaded={true}
                                        message='Loading...' />
                                </div>

                                <Modal className="modal-small" show={this.state.new}
                                    onHide={e => this.setState({ new: undefined })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add new number</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                            <div className={'form-group ' + (this.state.newCallPhoneNumberValid ? '' : 'has-error')}>
                                                <label className="control-label">Callers phone number</label>
                                                <input className="form-control " type='tel' placeholder={'Caller\'s Number'}
                                                    value={this.state.new === undefined ? '' : this.state.new.number}
                                                    onChange={e => {
                                                      let value = e.target.value

                                                      if (value.length > 18) {
                                                        value = value.substring(0, 18)
                                                      }

                                                      const validEmail = new RegExp('^[A-Za-z0-9]+@+[A-Za-z0-9]+\.+[A-Za-z0-9\.]*').test(value)
                                                      const validNumber = new RegExp('^[0-9]{10,18}$').test(value)

                                                      if (validEmail === false && validNumber === false) {
                                                        this.setState({ newCallPhoneNumberValid: false })
                                                      } else {
                                                        this.setState({ newCallPhoneNumberValid: true })
                                                      }

                                                      if (!e) {
                                                        this.setState({ new: { ...this.state.new, number: '' } })
                                                      } else {
                                                        this.setState({
                                                          new: {
                                                            ...this.state.new,
                                                            number: value
                                                          }
                                                        })
                                                      }
                                                    }} />
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label pt-20">Callers name / description</label>
                                                <input className="form-control" type='text'
                                                    placeholder={'Caller\'s Description / Name'}
                                                    value={this.state.new === undefined ? '' : this.state.new.description}
                                                    onChange={e => {
                                                      let value = e.target.value

                                                      if (value.length > 200) {
                                                        value = value.substring(0, 200)
                                                      }

                                                      this.setState({
                                                        new: {
                                                          ...this.state.new,
                                                          description: value
                                                        }
                                                      })
                                                    }} />
                                            </div>
                                            <label className="pt-20">
                                                Is a VIP?
                                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={vipExplain}>
                                                    <span className="icon icon-info2 ml-5"></span>
                                                </OverlayTrigger>
                                            </label><br />
                                            <Toggle
                                                on={<span>YES</span>}
                                                off={<span>NO</span>}
                                                size="sm"
                                                offstyle="default"
                                                onstyle="success"
                                                width="null"
                                                height="null"
                                                active={this.state.new && this.state.new.vip ? this.state.new.vip : false}
                                                onClick={event => this.handleUpdateNewContactCategory(event)}
                                            />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <div className="col-md-12 text-center">
                                            <Button size="lg" variant="cta" className="btn-block" disabled={!this.state.newCallPhoneNumberValid || !this.state.new || !this.state.new.number || !this.state.new.description || this.state.new.description === null || this.state.new.description.replaceAll(/\s/g, '') === ''}
                                                onClick={e => this.handleSaveNewContact()}>Save</Button>
                                        </div>
                                    </Modal.Footer>
                                </Modal>
                                <Modal className="modal-small" show={this.state.import} onHide={e => this.setState({ import: undefined })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Import Numbers</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="col-md-12 pt-30">
                                            <p><span className="text-500">Step 1:</span> Select where your numbers have been exported from</p>
                                            <div className="col-sm-12 pl-0 pb-20">
                                                <select className="form-control pl-0" onChange={event => this.setState({
                                                  import: {
                                                    ...this.state.import,
                                                    from: event.target.value
                                                  }
                                                })}>
                                                    <option value="">-- Import From --</option>
                                                    <option value="GoogleCSV">GMail Contact Export</option>
                                                    <option value="custom">Custom CSV</option>
                                                </select>
                                            </div>
                                            <p>
                                                <span className="text-500">Step 2:</span> Please select your file and click 'Start import'
                                            </p>
                                            <p className="pt-20">
                                                <strong>Please note:</strong> If you&apos;re importing a custom file please ensure you have "Description" in column A, and "Telephone" in column B.<br />
                                                <a href="/files/import-template.csv" className="btn btn-xs btn-info btn-block mt-10" download>Download sample file</a>
                                            </p>
                                            <div className="col-sm-12 mt-10 well text-center">
                                                <input type="file" name="files" />
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="bt-0">
                                        <div className="col-md-12 text-center pt-20 pb-30">
                                            {this.state.isImporting && <p>Please wait, importing ...</p>}
                                            <Button size="lg" variant="cta" className="btn-block"
                                                onClick={e => this.handleImportContacts()}>Import</Button>
                                        </div>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-sm-8 mx-auto">
                                    {this.state.contacts && this.state.contacts.length === 0 &&
                                        <div className="alert alert-warning text-center">
                                            <p className="text-500 pb-20">You don&apos;t currently have any prioritised callers. </p>
                                            <p>You can <Link to={'#'} className="text-dark underline" onClick={e => this.setState({ new: {} })}>prioritise a single number</Link> or <Link to={'#'} className="text-dark underline" onClick={e => this.setState({ import: {} })}>import multiple numbers at once</Link>.</p>
                                        </div>
                                    }

                                    <div className="row">
                                        {this.state.contacts && this.state.contacts.length > 0 && this.state.contacts.map((contact) => {
                                          return <div key={contact.qcId} className={`col-xs-12 ${contact.vip ? 'vip-contact' : ''}`}>
                                                <div className="bg-dull p-25 mb-10">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    {this.isContactBeingEdited(contact)
                                                                      ? <>
                                                                            <label className="text-center block">Is a VIP?</label>
                                                                            <Toggle
                                                                                on={<span>YES</span>}
                                                                                off={<span>NO</span>}
                                                                                size="sm"
                                                                                offstyle="default"
                                                                                onstyle="success"
                                                                                width="null"
                                                                                height="null"
                                                                                active={this.state.editing && this.state.editing.vip ? this.state.editing.vip : false}
                                                                                onClick={event => this.handleUpdateCategory(event)}
                                                                            />
                                                                        </>
                                                                      : contact.vip
                                                                        ? <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={vipExplain}>
                                                                                <div className="well well-attention brad-5 center-block p-10 mt-5 mb-0 block text-center">
                                                                                    VIP
                                                                            </div>
                                                                            </OverlayTrigger>
                                                                        : <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={prioExplain}>
                                                                                <div className="well brad-5 center-block p-10 mt-5 mb-0 block text-center">
                                                                                    Priority
                                                                            </div>
                                                                            </OverlayTrigger>
                                                                    }

                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="block">
                                                                        <span className="text-md text-400" id="ContactDescription">
                                                                            {contact.description}
                                                                        </span>
                                                                    </div>
                                                                    <div className="block" id="ContactNumber">
                                                                        {contact.number}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 text-center text-md-right pb-sm-30 pt-10">
                                                            {<Button
                                                                variant={this.isContactBeingEdited(contact) ? 'default' : 'primary'}
                                                                size="xs" className="mr-10"
                                                                onClick={e => this.handleEdit(this.state.editing ? undefined : contact)}>{this.isContactBeingEdited(contact) ? 'Cancel' : 'Edit'}</Button>}
                                                            <Button variant={this.isContactBeingEdited(contact) ? 'cta' : 'danger'}
                                                                size="xs"
                                                                onClick={e => this.isContactBeingEdited(contact) ? this.handleSaveContact(contact) : this.handleDeleteContact(contact)}>{this.isContactBeingEdited(contact) ? 'Update' : 'Delete'}</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        <div className="text-center col-md-12 pt-30 mb-50">
                                            <Button className="mb-5 mr-10 btn-mobile-block" variant="cta" size="md"
                                                onClick={e => this.setState({ new: {} })}>Prioritise a number</Button>
                                            <Button className="mb-5 btn-mobile-block" variant="primary" size="md"
                                                onClick={e => this.setState({ import: {} })}>Import multiple numbers</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                  : <>
                        <div className="container">
                            <div className="row">
                                <div className="mt-20 mb-20 pb-30 w-600px center-block">
                                    <p className="pt-20">
                                        We all know that 80% of our business comes from 20% of our customers. Looking after this 20% should be a priority – use our call priority feature to mark the telephone numbers of your key customers ensuring they’re never left in a long queue even in your busiest times.
                                    </p>
                                    <p className="pt-20">
                                        Simple to use... Just mark a single number or a list of numbers as priority and each time we see one of these numbers, we’ll put this to the front of your call queue for you.
                                    </p>
                                    <p className="pt-20">
                                        Just £3 /pm, per account, allowing you to use all of these features across all of your numbers.
                                    </p>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="bg-dull w-600px center-block b-2 brad-20 mb-100">
                                    <div className="row">
                                        <div className="co-md-12 pb-10 pt-30">
                                            <h3 className="text-500">Activate Now</h3>
                                            <p className="text-400">Simply select one of the payment options below.</p>
                                        </div>
                                        <div className="col-md-12">
                                            {this.state.isPurchaseInProgress &&
                                                <LoadingNotification className="mt-30" message={'Purchasing...'} isLoading={true} />
                                            }

                                            <Button variant="cta" size="lg" className="m-5" disabled={this.state.isPurchaseInProgress}
                                                onClick={event => this.handlePurchaseVipCallers(productBillingOption.vipCallerBillingOptionYearly, yearlyPrice)}>
                                                <GetProdPrice productId={ProductIds.vipCallers} yearlyOption={productBillingOption.vipCallerBillingOptionYearly} monthlyOption={productBillingOption.vipCallerBillingOptionMonthly} returnYearly={true} /> per year
                                                </Button>
                                            <Button variant="cta" className="m-5" disabled={this.state.isPurchaseInProgress}
                                                onClick={event => this.handlePurchaseVipCallers(productBillingOption.vipCallerBillingOptionMonthly, monthlyPrice)}
                                                size="lg"><GetProdPrice productId={ProductIds.vipCallers} yearlyOption={productBillingOption.vipCallerBillingOptionYearly} monthlyOption={productBillingOption.vipCallerBillingOptionMonthly} /> per month</Button>
                                            <div className="text-center pt-30">
                                                <img src="/images/credit-cards/CardsAccepted.png" width="300" className="worldPay" alt="Payments powered by WorldPay" />
                                            </div>
                                            <div className="text-center pt-30 pb-30 text-400">
                                                <small>
                                                    By purchasing this product you agree to the <a className="text-dark underline"
                                                        onClick={e => this.setState({ showTsAndCs: true })}>terms &amp; conditions</a> of this product.
                                                <br />All prices are exclusive of VAT at 20%.
                                            </small>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}

                <Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
                    <Modal.Body>
                        <TermsAndConditions />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
                    </Modal.Footer>
                </Modal>
                {/* END OF: Show the below when contact book is not activated */}

            </div>

            <ChoosePayment
                total={calculateVat(this.state.amount)}
                clientId={this.props.clientId}
                purchaseData={this.state.key}
                returnRoute="/customer/upgrades/call-prioritiser"
                email={this.props.userDetails.email}
                name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                mandate={!this.props.services.hasAgreement}
            />

        </>
  }

  handleSaveContact (contact) {
    const editedContact = this.state.editing
    const editingContactId = editedContact.qcId

    const index = _.findIndex(this.state.contacts, function (contact) {
      return contact.qcId === editingContactId
    })

    const categoryValues = {
      priority: editedContact.priority,
      vip: editedContact.vip
    }

    this.setState({ contacts: update(this.state.contacts, { [index]: { $set: editedContact } }), editing: undefined })

    const url = '/api/CallPrioritiser/UpdateContact'

    fetchHelper.postJson(url, { qcId: contact.qcId, ...categoryValues }).then(res => {
      console.log(res)
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleDeleteContact (contact) {
    const url = '/api/CallPrioritiser/DeleteContact'

    const context = this

    const newContacts = _.reject(this.state.contacts, function (thisContact) {
      return thisContact.qcId === contact.qcId
    })

    this.setState({ contacts: newContacts, editing: undefined })

    fetchHelper.postJson(url, { qcId: contact.qcId }).then(res => {

    }).catch(function (error) {
      console.error(error)
    })
  }

  isContactBeingEdited (contact) {
    return this.state.editing && this.state.editing.qcId === contact.qcId
  }

  getTextForContactCategory (contact) {
    if (contact.vip) { return 'VIP' } else if (contact.priority) { return 'Priority' } else { return 'Not Assigned' }
  }

  handleUpdateCategory (event) {
    this.setState({ editing: { ...this.state.editing, vip: event, priority: !event } })

    // let selectedCategory = event.target.value;

    // let categoryValues = this.getUpdatedCategoryValues(selectedCategory);

    // this.setState({ editing: { ...this.state.editing, ...categoryValues } })
  }

  getUpdatedCategoryValues (selectedCategory) {
    const categoryValues = {
      priority: selectedCategory === 'Priority',
      vip: selectedCategory === 'VIP'
    }
    return categoryValues
  }

  LoadContacts () {
    const getContactsUrl = `/api/CallPrioritiser/GetContacts/${true}`
    const context = this

    fetchHelper.getJson(getContactsUrl).then(res => {
      const contacts = res.data
      if (res.data) {
        context.setState({ loadingContacts: false, contacts })
      } else {

      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  componentDidMount () {
    const context = this

    this.setState({ loadingContacts: true })

    this.LoadContacts()

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()

      if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
          'use strict'
          if (typeof start !== 'number') {
            start = 0
          }

          if (start + search.length > this.length) {
            return false
          } else {
            return this.indexOf(search, start) !== -1
          }
        }
      }

      if (params.includes('new=true')) {
        this.enableContactBook(context)
      }
    }
  }

  handleEdit (contact) {
    this.setState({ editing: contact })
  }

  handleSaveNewContact () {
    const url = '/api/CallPrioritiser/SaveNewContactBookContact'

    const newContact = this.state.new

    fetchHelper.postJson(url, newContact).then(res => {
      if (res.data.success) {
        const updatedContacts = update(this.state.contacts, { $unshift: [newContact] })
        this.setState({ contacts: updatedContacts, new: undefined })
        this.LoadContacts()
      }
      this.props.notify({ message: res.data.message, isError: !res.data.success })
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleUpdateNewContactCategory (event) {
    this.setState({ new: { ...this.state.new, vip: event } })
  }

  handleImportContacts () {
    const context = this
    const importData = document.querySelector('input[type="file"]').files[0]
    context.setState({ isImporting: true })
    fetchHelper.postFile(`api/CallPrioritiser/Upload/${this.state.import.from}/${false}`, importData).then(response => {
      if (response.data) {
        context.setState({ contacts: response.data, import: undefined, isImporting: false })
        this.LoadContacts()
      } else {
        context.props.notify({ message: 'Error saving configuration, please contact support', isError: true, isImporting: false })
      }
    }).catch(function (error) {
      context.props.notify({ message: 'Error saving configuration, please contact support', isError: true, isImporting: false })
    })
  }

  handlePurchaseVipCallers (billingOption, amount) {
    if (this.props.displayBillingWarning === true) {
      this.props.handleShowBillingWarning()
    } else {
      const context = this
      const url = 'api/Purchase/PurchaseProduct'

      context.setState({ isPurchaseInProgress: true }, () => {
        fetchHelper.postJson(url, { BillingOption: billingOption, ProductId: ProductIds.vipCallers }).then(res => {
          if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
            this.enableContactBook(context)
          } else if (res.data && res.data.postponed === true) {
            context.setState({ amount, key: res.data.key }, () => {
              context.props.handleShowPaymentPostponedModal()
              this.enableContactBook(context)
            })
          } else {
            context.setState({ amount, key: res.data.key }, () => {
              context.props.handleShowPaymentOptionsModal()
            })
          }
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)
        })
      })
    }
  }

  enableContactBook (context) {
    context.props.notify({ message: 'Call Prioritiser added' })
    context.props.handleVipCallersEnabledChange(true)
  }
}

export default connect(
  state => {
    return {
      isEnabled: state.sbfApp.enabledServices.hasVipCallerFeature,
      monthlyPrice: state.home.productDetails.monthlyRepeatPayment,
      yearlyPrice: state.home.productDetails.yearlyRepeatPayment,
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      displayBillingWarning: state.sbfApp.enabledServices.displayBillingWarning
    }
  },
  {
    notify: handleNotificationChange,
    handleVipCallersEnabledChange,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal,
    handleShowBillingWarning
  }
)(VipCallers)
