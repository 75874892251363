import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import NumberLandingView from '../../components/NumberTypes/NumberLandingView'
import NumberTypeFeatures from '../../components/NumberTypes/NumberTypesFeaures'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isGeoOffer, numberOfferDuration, NumbersPremiumFilter } from '../../constants/Constants'
import { isLoggedIn } from '../../selectors'
import * as getSignup from '../../store/Signup'

class NumbersLocal extends React.Component {
  constructor (props) {
    super(props)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.openModal = this.openModal.bind(this)
  }

  static defaultProps = {}
  openModal () {
    this.setState({ isOpen: true })
  }

  static propTypes = {}

  state = {
    isOpen: false,
    numbersPremiumFilter: NumbersPremiumFilter.all,
    showDdiSelector: false
  }

  handleChangeArea () {
    this.setState({
      geo: undefined,
      selected: undefined,
      numbers: undefined,
      showDdiSelector: false
    })
  }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow () {
    this.setState({ show: true })
  }

  scrollTo (el) {
    scroller.scrollTo(el, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -50
    })
  }

  render () {
    return (
            <>
                <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
                {/* <NumbersMenu /> */}
                <Helmet>
                    <title>Virtual Local Phone Numbers</title>
                    <meta name="description" content="Get a local geographic landline number instantly for your business. No setup fees and with free call management system included.." />
                </Helmet>

                <NumberLandingView
                    h1="Keep it Local"
                    h2="Give your business the local presence customers trust with a 01 & 02 phone number."
                    numberType={'01'}
                    history={this.props.history}
                />

                <div className="full-width bg-light">
                    <div className="container pl-xxs-30 pr-xxs-30">
                        <div className="row">

                            <div className="col-lg-7 col-md-10 mx-auto" >
                                <div id="contentWrapper">

                                    {/* <button onClick={this.openModal} className="btn btn-secondary btn-sm btn-mobile-block mt-20 mr-10 mb-30">Watch video</button> */}
                                    <p className="text-md pt-20 pt-xs-0 text-500 text-center">
                                        {isGeoOffer
                                          ? <h2 className="text-500">
                                                <span className="pt-5 pb-5 pl-20 pr-20 brad-10 text-caps text-500 text-xs text-lightest box-shadow mr-10" style={{ whiteSpace: 'nowrap', background: 'linear-gradient(to right, rgba(245,92,0,1) 0%,rgba(255,120,20,1) 100%)' }}>Special Offer</span>
                                                JUST 99p For Your First <span className="text-500">{numberOfferDuration} Months</span>
                                            </h2>
                                          : <h2 className="text-500 text-darker">
                                                Choose Your Plan From Only <GetProdPrice prefix="01" /> per Month
                                            </h2>
                                        }
                                    </p>
                                    <h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-800px center-block text-center">
                                        Get instant activation without long-term contracts & expensive hardware
								    </h2>
                                    <p className="text-md pt-30 pt-xs-0 text-xs-sm text-xs-left pb-30">
                                        Attaching a local number to your business is a brilliant way to adopt a presence in any town or city in the UK. People trust and support local businesses. In fact, providing a local number can increase your custom in that area by 22%.
                                    </p>
                                    <p>
                                        In addition, when you use multiple numbers spanning several towns and cities, you’ll give your business that nationwide edge, potentially increasing your incoming calls by up to 40%. You can achieve this with the <Link to={'/multi-number-package/'} className="underline text-cta">Multi-number package</Link>.
                                    </p>
                                    <div className="col-md-12 pt-50 pb-30 text-center">
                                        <Button variant="cta" className="btn btn-cta p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 center-block text-center" onClick={() => this.scrollTo('numbers')}>Sign up now</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <NumberTypeFeatures history={this.props.history} />

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center mt-20">
                            <div id="contentMobile"></div>
                        </div>
                    </div>
                </div>

                <div className="full-width bg-dull ">
                    <div className="container">
                        <CarouselWidget />
                    </div>
                </div>

                {/* Logoos */}
                <div className="full-width bg-light">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="text-500">Partners &amp; Customers</h3>
                                {/* <h6>The UK's favourite call management system</h6> */}
                                <p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ClientLogos style='light' />

            </>
    )
  }

  async componentDidMount () {
    const context = this
    context.props.handleBasketInit()
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state),
      currentBasket: state.signup.packageInfo.currentBasket
    })
  },
  {
    handleAddToBasket: getSignup.handleAddToBasket,
    handleRemoveFromBasket: getSignup.handleRemoveFromBasket,
    handleBasketInit: getSignup.handleBasketInit
  }
)(NumbersLocal)
