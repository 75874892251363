import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import * as Configuration from './Configuration'
import * as GetNumber from './GetNumber'
import * as Home from './Home'
import * as MyNumbers from './MyNumbers'
import * as SBF from './SBF'
import * as Signup from './Signup'

export const history = createBrowserHistory()

export default function configureStore (initialState) {
  const reducers = {
    configuration: Configuration.reducer,
    home: Home.reducer,
    myNumbers: MyNumbers.reducer,
    sbfApp: SBF.reducer,
    GetNumber: GetNumber.reducer,
    signup: Signup.reducer
  }

  const middleware = [
    thunk,
    routerMiddleware(history)
  ]

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = []
  const isDevelopment = process.env.NODE_ENV === 'development'
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension())
  }

  const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers
  })

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(routerMiddleware(history), ...middleware),
      ...enhancers)
  )
}
