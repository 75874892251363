import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Slider from 'react-slick'
import MohTracks from '../components/MohTracks'

export default class MusicOnHoldPlaylists extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      show: false,
      showMohTracks: false,
      mohCatId: 0
      // showXmas: false,
      // showBeauty: false,
      // showFuneral: false,
      // showSolicitors: false,
      // showDental: false,
      // showJob: false,
      // showProduct: false,
      // showRadio: false,
      // showDriving: false,
      // showEstates: false,
      // showBuilders: false,
      // showSecurity: false,
      // showFlowers: false,
      // showCakes:false,
      // exampleSrc: ""
    }
  }

  handleCloseModal () {
    this.setState({
      showMohTracks: false,
      mohCatId: undefined
    })
  }

  handleShowTracks (id, name) {
    this.setState({
      showMohTracks: true,
      mohCatId: id,
      mohName: name
    })
  }

  render () {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    return <>
            <Slider className="showreel-page" {...settings}>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(33, 'The Charts')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/33.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">The Charts <span className="text-xs">(updated weekly)</span></h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(36, 'Best of British')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/36.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Best of British</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(23, 'Acoustic')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/23.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Acoustic</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(25, 'Bhangra / Bollywood')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/25.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Bhangra / Bollywood</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(85, 'Blues')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/85.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Blues</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(2, 'Classical')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/2.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Classical</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(79, 'Country')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/79.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Country</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(108, 'Disco')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/108.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Disco</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(65, 'Drum & Bass')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/65.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Drum &amp; Bass</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(60, 'Dubstep')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/60.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Dubstep</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(102, 'Folk')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/102.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Folk</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(26, 'Garage')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/26.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Garage</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(27, 'Grime')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/27.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Grime</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(72, 'Hard Rock / Metal')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/72.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Hard Rock / Metal</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(81, 'Hardstyle')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/81.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Hardstyle</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(19, 'Hip Hop')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/19.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">HipHop</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(117, 'Indie Rock')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/117.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Indie Rock</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(89, 'Irish Music')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/89.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Irish Music</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(1, 'Jazz')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/1.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Jazz</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(5, 'Latin')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/5.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Latin</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(104, 'Motown')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/104.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Motown</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(67, 'Movies & TV Shows')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/67.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Movies &amp; TV Shows</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(101, 'Musicals')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/101.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Musicals</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(100, 'Piano')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/100.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Piano</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(51, 'Reggae')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/51.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Reggae</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(4, 'Rock')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/4.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Rock</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(38, 'Romantic')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/38.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Romantic</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(3, 'Swing')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/3.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Swing</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30" onClick={() => this.handleShowTracks(80, 'Trance')}>
                    <div className="img-cover" style={{ backgroundImage: 'url(images/moh/80.jpg)' }}>
                        <span className="icon icon-play3"></span>
                    </div>
                    <h5 className="text-500">Trance</h5>
                    <p className="text-sm underline text-400">View playlist</p>
                </div>
                <div className="col-xxs-12 col-xs-6 col-md-3 playlist p-0 text-center mb-30">
                    <div className="img-cover b-0 brad-10" style={{ backgroundImage: 'url(images/moh/end-categories.jpg)' }}>
                    </div>
                    <p className="text-sm text-400 pt-20"><a href="/signup/start?utm_source=music_on_hold_page&utm_medium=website&utm_campaign=music_on_hold" className="text-cta text-500"><i>Create account</i></a> or <a href="/login?utm_source=music_on_hold_page&utm_medium=website&utm_campaign=music_on_hold" className="text-cta text-500"><i>Log In</i></a></p>
                </div>

            </Slider>

            <Modal show={this.state.showCakes} onHide={() => this.setState({ showCakes: false })}>
                <Modal.Body>
                    <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/575320728&color=%23ff9314&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>
                    <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/575320710&color=%23ff9314&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" size="xs" onClick={() => this.setState({ showCakes: false })}>Close</Button>
                </Modal.Footer>
            </Modal>

            <MohTracks showModal={this.state.showMohTracks} catId={this.state.mohCatId} hideModal={e => this.handleCloseModal()} name={this.state.mohName} />

        </>
  }
}
