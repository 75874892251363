import React from "react";
import { Button, Modal } from "react-bootstrap";
import Select from 'react-select';
import { fetchHelper } from "../../helpers/fetchHelper";
import { isValidEmailCslSyntax } from "../../helpers/validationHelpers";

const saidByWho = [
	{ value: '0', label: 'anyone' },
	{ value: '-1', label: 'myself' },
	{ value: '-2', label: 'caller' },
	{
		label: 'Users',
		options: [
			{
				label: 'Ben',
				value: 2
			},
			{
				label: 'George',
				value: 1
			},
			{
				label: 'Seb',
				value: 3
			}
		]
	}
]

const WordMention = [
	{ value: '1', label: 'mentioned' },
	{ value: '2', label: 'not mentioned' }
]


const CallTypeOptions = [
	{ value: '1', label: 'All calls' },
	{ value: '2', label: 'Outbound call' },
	{ value: '3', label: 'Inbound call' }
]

const callDurationOptions = [
	{ value: '1', label: 'Any duration' },
	{ value: '2', label: 'Less than 1 minute' },
	{ value: '3', label: '1 to 5 minutes' },
	{ value: '4', label: '5 to 15 minutes' },
	{ value: '5', label: 'Over 15 minutes' }
]

export default class CallTranscriptionRules extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		showDeleteRuleQuestion: false,
		alertName: '',
		alertPhrase: '',
		mentioned: WordMention[0],
		saidByWho: [],
		saidBy: undefined,
		callLength: callDurationOptions[0],
		callType: CallTypeOptions[0],
		emailList: '',
		updateRuleId: 0,
		sbfNumbers: [],
		sbfNumberSelected: undefined
	}

	componentDidMount() {

		fetchHelper.getJson('/api/Numbers/GetSbfNumberAndIds')
			.then(sbfNumberRes => {
				fetchHelper.getJson('/api/Numbers/GetSipEndpointsReactSelectList')
					.then(sipEndpointRes => {
						this.setState
							({

								sbfNumbers: sbfNumberRes.data,
								sbfNumberSelected: sbfNumberRes.data[0],
								saidByWho: sipEndpointRes.data,
								saidBy: sipEndpointRes.data[0]
							});
					})
					.catch(err => {
						console.error(err);
					})
			})
			.catch(err => {
				console.error(err);
			})
	}

	closePressed() {
		if (this.props.closePressed) {
			this.props.closePressed();
		}
	}

	createTriggerPressed() {
		fetchHelper.postJson('/api/CallTranscription/CreateTranscriptionKeywordTrigger/' + this.state.alertPhrase + '/' + this.state.alertName + '/' + this.state.mentioned.value + '/' + this.state.saidBy.value + '/' + this.state.callLength.value + '/' + this.state.callType.value + '/' + this.state.sbfNumberSelected.value + '/' + this.state.emailList, {})
			.then(res => {
				this.setState
					({
						showDeleteRuleQuestion: false,
						alertName: '',
						alertPhrase: '',
						mentioned: WordMention[0],
						saidBy: saidByWho[0],
						callLength: callDurationOptions[0],
						callType: CallTypeOptions[0],
						emailList: ''
					});

				if (this.props.triggerCreated) {
					this.props.triggerCreated();
				}
			})
			.catch(err => {
				console.error(err);
			})
	}

	deletePressed() {
		fetchHelper.postJson('/api/CallTranscription/DeleteTranscriptionKeywordTrigger/' + this.state.updateRuleId + '/', {})
			.then(res => {
				this.setState
					({
						showDeleteRuleQuestion: false,
						alertName: '',
						alertPhrase: '',
						mentioned: WordMention[0],
						saidBy: saidByWho[0],
						callLength: callDurationOptions[0],
						callType: CallTypeOptions[0],
						emailList: ''
					});

				if (this.props.triggerDeleted) {
					this.props.triggerDeleted();
				}
			})
			.catch(err => {
				console.error(err);
			})
	}

	saveChangesPressed() {

		//{keywordTriggerId}/{phrase}/{ruleName}/{isMentioned}/{saidById}/{callDurationId}/{callTypeId}/{commaSeparatedEmailList}
		fetchHelper.postJson('/api/CallTranscription/UpdateTranscriptionKeywordTrigger/' + this.state.updateRuleId + '/' + this.state.alertPhrase + '/' + this.state.alertName + '/' + this.state.mentioned.value + '/' + this.state.saidBy.value + '/' + this.state.callLength.value + '/' + this.state.callType.value + '/' + this.state.sbfNumberSelected.value + '/' + this.state.emailList, {})
			.then(res => {
				if (this.props.triggerUpdated) {
					this.props.triggerUpdated();
				}
			})
			.catch(err => {
				console.error(err);
			})
	}

	setControlToRuleIdSettings(ruleId) {

		fetchHelper.getJson('/api/Numbers/GetSbfNumberAndIds')
			.then(res => {
				fetchHelper.getJson('/api/Numbers/GetSipEndpointsReactSelectList')
					.then(sipEndpointRes => {

						if (this.props.alerts && ruleId !== 0) {

							const alertIndex = this.props.alerts.findIndex(x => Number(x.id) === Number(ruleId));

							if (alertIndex != undefined) {
								const alert = this.props.alerts[alertIndex];

								const callLength = callDurationOptions.filter(x => Number(x.value) === Number(alert.callDuration))
								const callType = CallTypeOptions.filter(x => Number(x.value) === Number(alert.callType))
								const saidBy = sipEndpointRes.data.filter(x => Number(x.value) === Number(alert.saidBy))
								const sbfNumber = res.data.filter(x => Number(x.value) === Number(alert.ddiId))

								this.setState
									({
										sbfNumbers: res.data,
										sbfNumberSelected: sbfNumber,
										saidByWho: sipEndpointRes.data,
										updateRuleId: ruleId,
										mentioned: alert.isMentioned === true ? WordMention[0] : WordMention[1],
										callLength: callLength[0],
										callType: callType[0],
										saidBy: saidBy === undefined ? sipEndpointRes.data[0] : saidBy[0],
										emailList: alert.commaSeparatedEmailList,
										alertPhrase: alert.phrase,
										alertName: alert.ruleName,
										emailListValid: true
									})
							}
							else {
								this.setState
									({
										sbfNumbers: res.data,
										saidByWho: sipEndpointRes.data,
										updateRuleId: ruleId,
										showDeleteRuleQuestion: false,
										alertPhrase: '',
										mentioned: WordMention[0],
										saidBy: sipEndpointRes.data[0],
										callLength: callDurationOptions[0],
										callType: CallTypeOptions[0],
										emailList: '',
										sbfNumberSelected: res.data[0]
									})
							}

						}
						else {
							this.setState
								({
									sbfNumbers: res.data,
									saidByWho: sipEndpointRes.data,
									updateRuleId: ruleId,
									showDeleteRuleQuestion: false,
									alertPhrase: '',
									mentioned: WordMention[0],
									saidBy: sipEndpointRes.data[0],
									callLength: callDurationOptions[0],
									callType: CallTypeOptions[0],
									emailList: '',
									sbfNumberSelected: res.data[0]
								})
						}
					})
					.catch(err => {
						console.error(err);
					})
			})
			.catch(err => {
				console.error(err);
			})
	}

	changeEmailAddress(value) {
		var isValid = isValidEmailCslSyntax(value);

		this.setState({
			emailList: value,
			emailListValid: isValid
		})
	}

	render() {

		const show = this.props.show !== undefined ? this.props.show : false;
		const updateRuleId = this.props.updateRuleId !== undefined ? this.props.updateRuleId : 0; // if 0 then creating new rule

		if (updateRuleId != this.state.updateRuleId) {
			this.setControlToRuleIdSettings(updateRuleId);
		}

		return <>
			<Modal show={show} onHide={e => this.closePressed()}>
				<Modal.Header closeButton className="bb-0 p-0">
				</Modal.Header>
				<Modal.Body className="p-50">
					<div className="col-md-12 text-start pl-0 pr-0">
						<div className="row">
							<div className="col-md-12">
								<h6 className="text-500 mb-10"><span className="text-cta pr-10">Step #1</span> Your alert name and words/phrases</h6>
								<p>This is the word or phrase  that will trigger the alert. For example: "sale", or "terms and conditions".</p>

								<div className="bg-dull p-20">
									<span className="text-400 pb-2 va-middle text-sm text-500">Alert name</span>
									<input className="form-control mt-0 mb-20" placeholder="eg. sale" maxLength={100} value={this.state.alertName} onChange={(e) => this.setState({ alertName: e.target.value })} id="AlertInputBox" />

									<span className="text-400 pb-2 va-middle text-sm text-500">Words or phrases</span>
									<input className="form-control mt-0 mb-10" placeholder="eg. sale" maxLength={100} value={this.state.alertPhrase} onChange={(e) => this.setState({ alertPhrase: e.target.value })} id="PhraseInputBox" />
									<p className="text-sm"><em>Comma seperated. e.g. card, bank, direct debit mandate terms and conditions.</em></p>
								</div>
							</div>

							<div className="col-md-12 mt-30">
								<h6 className="text-500 mb-10"><span className="text-cta pr-10">Step #2</span> Alert rules</h6>
								<p>Before we send you the alert, we check against the following rules.</p>

								<div className="bg-dull p-20">
									<div className="col-md-12 bb-1 rule-row pb-15 pt-15 pl-0 pr-0">
										<span className="text-400 pb-2 va-middle text-sm text-500">The "word/phrase" has to be</span>
											<Select
												options={WordMention}
												className="form-control select-menu ml-10"
												isClearable={false}
												isSearchable={false}
												value={this.state.mentioned}
												onChange={(e) => this.setState({ mentioned: e })}
												id="WordHasToBeDropdown"
											/>
									</div>

									<div className="col-md-12 bb-1 rule-row pb-15 pt-15 pl-0 pr-0">
										<span className="text-400 pb-2 va-middle text-sm text-500">The word "word/phrase" must be said by</span>
											<Select
												options={this.state.saidByWho}
												className="form-control select-menu ml-10"
												isClearable={false}
												isSearchable={false}
												value={this.state.saidBy}
												onChange={(e) => this.setState({ saidBy: e })}
												id="WordMustBeSaidByDropdown"
											/>
									</div>

									<div className="col-md-12 bb-1 rule-row pb-15 pt-15 pl-0 pr-0">
										<span className="text-400 pb-2 va-middle text-sm text-500">Made to/from SwitchboardFREE number</span>
											<Select
												options={this.state.sbfNumbers}
												className="form-control select-menu ml-10"
												isClearable={false}
												isSearchable={false}
												value={this.state.sbfNumberSelected}
												onChange={(e) => this.setState({ sbfNumberSelected: e })}
												id="SwitchboardFREEDropdown"
											/>
									</div>

									<div className="col-md-12 bb-1 rule-row pb-15 pt-15 pl-0 pr-0">
										<span className="text-400 pb-2 va-middle text-sm text-500">Call length has to be at least</span>
											<Select
												options={callDurationOptions}
												className="form-control select-menu ml-10"
												isClearable={false}
												isSearchable={false}
												value={this.state.callLength}
												onChange={(e) => this.setState({ callLength: e })}
												id="CallLengthDropdown"
											/>
									</div>

									<div className="col-md-12 rule-row pb-15 pt-15 pl-0 pr-0">
										<span className="text-400 pb-2 va-middle text-sm text-500">Call type is</span>
											<Select
												options={CallTypeOptions}
												className="form-control select-menu ml-10"
												isClearable={false}
												isSearchable={false}
												value={this.state.callType}
												onChange={(e) => this.setState({ callType: e })}
												id="CallTypeDropdown"
											/>
									</div>
								</div>
							</div>

							<div className="col-md-12 mt-30">
								<h6 className="text-500 mb-10"><span className="text-cta pr-10">Step #3</span> Send email</h6>
								<p>
									When the above rules apply, we send you an email. You can enter multiple emails by dividing them with a comma, for example: <i>john@gmail.com, john@yahoo.com</i> etc.
								</p>
								<div className="bg-dull p-20">
									<span className="text-400 pb-2 va-middle text-sm text-500">Email addresses</span>
									<input className="form-control mt-0 mb-10" placeholder="john@gmail.com, john@yahoo.com" maxLength={200} value={this.state.emailList} onChange={(e) => this.changeEmailAddress(e.target.value)} id="EmailInput" />

									{this.state.emailListValid === false && <p className="text-sm text-danger" id="InvalidEmailWarning">Invalid Email Address</p>}
								</div>
							</div>

						</div>

					</div>
				</Modal.Body>
				<Modal.Footer className="p-30">
					{updateRuleId == 0
						?
						<Button size="lg" variant="cta" className="mb-30 mt-30" disabled={this.state.emailListValid !== true || this.state.emailList.trim() === '' || this.state.alertPhrase.trim() === ''} onClick={(e) => this.createTriggerPressed()} id="CreateTriggerButton">Create Trigger</Button>
						:
						<>
							{this.state.showDeleteRuleQuestion &&
								<div className="alert alert-danger w-400px center-block text-center mt-30">
									<p className="text-500">
										Are you sure you want to delete this rule?
									</p>
									<p className="pt-20 pb-10">
										<span className="btn btn-sm btn-danger" onClick={() => this.deletePressed()} id="DeleteConfirmationButton">Yes, delete</span>
									</p>
									<p className="pt-10 pb-10">
										<span className="underline cursor-pointer" onClick={() => this.setState({ showDeleteRuleQuestion: this.state.showDeleteRuleQuestion && false })} id="NoCancelLink">No, cancel</span>.
									</p>
								</div>
							}
							{this.state.showDeleteRuleQuestion == false &&
								<>
									<Button size="lg" variant="cta" className="mb-20" disabled={this.state.emailListValid !== true || this.state.emailList.trim() === '' || this.state.alertPhrase.trim() === '' || this.state.alertName.trim() === ''} onClick={() => this.saveChangesPressed()} id="SaveChangesButton">Save changes</Button>
								<span className="text-dark underline cursor-pointer text-400 d-block w-100 text-center" onClick={() => this.setState({ showDeleteRuleQuestion: this.state.showDeleteRuleQuestion ? false : true })} id="DeleteThisRuleLink">Delete this rule</span>
								</>
							}
						</>
					}

				</Modal.Footer>
			</Modal>
		</>


	}

}