import React, { Component } from 'react'
import { Button, Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { Helmet } from 'react-helmet'
import { RequestAuthorizationCode } from 'react-oauth2-auth-code-flow'
import { connect } from 'react-redux'
import Select from 'react-select'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import { billingPeriod, productBillingOption, ProductIds } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import OauthHelper from '../../helpers/OauthHelper'
import { calculateVat, getQueryStringFromProps, checkNumberType } from '../../helpers/utils'
import { handleContactBookEnabledChange, handleNotificationChange, handleShowPaymentOptionsModal } from '../../store/SBF'
import TermsAndConditions from '../../Text/TermsAndConditions'

const capsuleDescription = (
    <Popover id="popover-basic">
        <ul className="fancy cta pl-25 text-400 mt-30">
            <li className="pb-20">
                All of your contacts are quickly and easily accessible across all your devices
            </li>
            <li className="pb-20">
                Received calls, missed calls and voicemails are all fed directly into your Capsule CRM system
            </li>
            <li className="pb-20">
                A.I. Caller Greeting™. Greets your callers by their name held in Capsule.
                {/* [Example Recording]  */}
            </li>
            <li className="pb-20">
                Be alerted to your callers' name before you accept a call
                {/* [Example Recording]   */}
            </li>
            <li className="pb-20">
                Automatically link notes from Capsule to your calls recordings
            </li>
            <li className="pb-20">
                When you answer a call, your caller's Capsule page instantly opens in your browser
            </li>
            <li className="pb-20">
                Simple integration
            </li>
        </ul>
    </Popover>
)

const pipedriveDescription = (
    <Popover id="popover-basic">
        <ul className="fancy cta pl-25 text-400 mt-30">
            <li className="pb-20">
                <span className='text-500'>Contact Syncing</span>
                <br/>
                Keep your contact book synced with Pipedrive.
            </li>
            <li className="pb-20">
                <span className='text-500'>Call History</span>
                <br/>
                Include your pipedrive contacts in our call reporting tools.
            </li>
            <li className="pb-20">
                <span className='text-500'>A.I. Caller Greeting™</span>
                <br/>
                Greets your callers by their name held in Capsule.
            </li>
            <li className="pb-20">
                <span className='text-500'>Inbound Call Info</span>
                <br/>
                Show your pipedrive info when a contact calls.
            </li>
            <li className="pb-20">
                <span className='text-500'>Call Recording Notes</span>
                <br/>
                Link notes from pipedrive to your call recordings.
            </li>
            <li className="pb-20">
                <span className='text-500'>Missed Call Notes</span>
                <br/>
                Let your staff know when a call has been missed.
            </li>
            <li className="pb-20">
                <span className='text-500'>Voicemail Notes</span>
                <br/>
                Link notes from pipedrive to your voicemails.
            </li>
        </ul>
    </Popover>
)

const HubSpotDescription = (
    <Popover id="popover-basic">
        <ul className="fancy cta pl-25 text-400 mt-30">
            <li className="pb-20">
                All of your contacts are quickly and easily accessible across all your devices
            </li>
            <li className="pb-20">
                Received calls, missed calls and voicemails are all fed directly into your HubSpot CRM system
            </li>
            <li className="pb-20">
                A.I. Caller Greeting™. Greets your callers by their name held in HubSpot.
            </li>
            <li className="pb-20">
                Be alerted to your callers' name before you accept a call
            </li>
            <li className="pb-20">
                Automatically link notes from HubSpot to your calls recordings
            </li>
            <li className="pb-20">
                When you answer a call, your caller's HubSpot page instantly opens in your browser
            </li>
            <li className="pb-20">
                Simple integration
            </li>
        </ul>
    </Popover>
)

const RemoveCapsuleContact = (
    <Tooltip>
      To edit or remove a contact imported from your Capsule CRM, you will have to do it inside your Capsule account.
    </Tooltip>
)

const ContactGreetingTooltip = (
    <Tooltip>
      Turn ON or OFF caller greeting
    </Tooltip>
)

const GoToCRMsettings = (
    <Tooltip>
        CRM's settings
    </Tooltip>
)

const DeleteContactTooltip = (
    <Tooltip>
      Delete this contact
    </Tooltip>
)

const EditContactTooltip = (
    <Tooltip>
      Edit this contact
    </Tooltip>
)

const NewContactTooltip = (
    <Tooltip>
      Add new contact
    </Tooltip>
)

const SyncContactsTooltip = (
    <Tooltip>
      Sync contacts
    </Tooltip>
)

const SelectMultuipleTooltip = (
    <Tooltip>
      Select multiple
    </Tooltip>
)

const ImportContactsTooltip = (
    <Tooltip>
      Import contacts
    </Tooltip>
)

const _ = require('lodash')

const WhatsCallersGreeting = (
    <Popover id="popover-basic">
        <p className="text-400">Automatically recognise callers' numbers &amp; greet them by name, e.g <i>"Hi Chris..."</i></p>
    </Popover>
)

const syncWithCapsuleOverlay = (
    <Popover>
        <p>
            This contact is sync&apos;d from your Capsule CRM. Any changes to this contact must be done within your CRM
        </p>
    </Popover>
)

const syncWithPipedriveOverlay = (
    <Popover>
        <p>
            This contact is sync&apos;d from your Pipedrive CRM. Any changes to this contact must be done within your CRM
        </p>
    </Popover>
)

const syncWithHubSpotOverlay = (
    <Popover>
        <p>
            This contact is sync&apos;d from your HubSpot CRM. Any changes to this contact must be done within your CRM
        </p>
    </Popover>
)

const pageSizeOptions = [
  { label: '3 per page', value: 3 },
  { label: '5 per page', value: 5 },
  { label: '10 per page', value: 10 },
  { label: '25 per page', value: 25 },
  { label: '50 per page', value: 50 },
  { label: '100 per page', value: 100 }
]

class ContactBook extends Component {
  static defaultProps = { isEnabled: false, isImporting: false }

  static propTypes = {}

  state = {
    showTsAndCs: false,
    isPurchaseInProgress: false,
    contactIdEditing: 0,
    contactIdAddingTo: 0,
    addContactEntryValue: '',
    validContactEntered: false,
    contactBeingDeleted: undefined,
    deleteContactSelected: false,
    contactsToDelete: [],
    showDeleteContactsModal: false,
    toggleActive: true,
    callerGreetingDisabled: false,
    showGreetingWarningModal: false,
    contacts: [],
    capsuleCrmBearerToken: '',
    showSyncContactsModal: false,
    syncSettings: undefined,
    disconnectCapsuleCrm: false,
    disconnectPipedrive: false,
    disconnectHubSpot: false,
    hubSpotCrmEnabled: false,
    searchText: '',
    pageSize: 25,
    pageNumber: 1,
    isLoading: true,
    hideCrmBanner: true
  }

  handleDeleteContactsConfirmed () {
    const data = {
      qcIds: this.state.contactsToDelete
    }

    const url = 'api/contacts/DeleteMultipleContacts'

    fetchHelper.postJson(url, data).then(res => {
      if (res.data === true) {
        this.LoadContacts()
        this.setState({
          showDeleteContactsModal: false,
          deleteContactSelected: false
        })
      } else {
        this.setState({ showDeleteContactsModal: true })
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleChangeDeleteContact (qcId) {
    const array = this.state.contactsToDelete

    const inArray = array.includes(qcId)

    if (inArray === true) {
      const index = array.indexOf(qcId)
      if (index > -1) {
        array.splice(index, 1)
      }
    } else {
      array.push(qcId)
    }

    this.setState({
      contactsToDelete: array
    })
  }

  selectAllForDelete () {
    const array = []

    const contacts = this.state.contacts

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].importerType !== 3) {
        array.push(contacts[i].qcId)
      }
    }

    this.setState({ contactsToDelete: array })
  }

  unselectAllForDelete () {
    this.setState({ contactsToDelete: [] })
  }

  toggleAccountWideCustomerGreetingEnabled () {
    if (this.state.callerGreetingDisabled === true) {
      this.setState({ showGreetingWarningModal: true })
    } else {
      this.changeCallerGreetingAccountSetting()
    }
  }

  handleCallerGreetingEnableConfirmed () {
    this.changeCallerGreetingAccountSetting()
    this.setState({ showGreetingWarningModal: false })
  }

  changeCallerGreetingAccountSetting () {
    fetchHelper.postJson('api/contacts/SetAccountWideCustomerGreetingEnabled/' + this.state.callerGreetingDisabled, {}).then((res) => {
      this.setState({ callerGreetingDisabled: !this.state.callerGreetingDisabled })
    })
  }

  onToggle () {
    this.setState({ toggleActive: !this.state.toggleActive })
  }

  togglePersonalisedIntroSetting (qcId) {
    const contacts = this.state.contacts

    const index = contacts.findIndex(x => x.qcId === qcId)

    fetchHelper.postJson('api/contacts/SetCustomerGreetingEnabled/' + qcId + '/' + contacts[index].ignorePersonalizedIntro, {}).then((res) => {
      contacts[index].ignorePersonalizedIntro = !contacts[index].ignorePersonalizedIntro

      this.setState({
        contacts
      })
    })
  }

  constructor () {
    super()
    this.onToggle = this.onToggle.bind(this)
  }

  anyContactsStartWith (character, contacts) {
    if (contacts === undefined) {
      return false
    }

    return contacts.find(x => x.description !== undefined && x.description.length > 0 && x.description[0].toLowerCase() === character) !== undefined
  }

  handleDeleteContactNumber (index) {
    const contact = this.state.editingContacts[index]

    const url = 'api/contacts/DeleteContactNumber/' + this.state.contactIdEditing + '/' + contact.contactId

    fetchHelper.postJson(url).then((res) => {
      if (res.data === true) {
        const editingContacts = this.state.editingContacts
        editingContacts.splice(index, 1)

        this.setState({
          editingContacts
        })

        this.LoadContacts()
      }
    })
  }

  addNewContactToEditingContacts () {
    const editingContacts = this.state.editingContacts

    editingContacts.push({
      dataType: 1,
      type: 3,
      value: ''
    })

    this.setState({ editingContacts })
  }

  searchTextMatch (contact) {
    const searchText = this.state.searchText.toLowerCase()

    // if blank no search filter is applied
    if (searchText === '') {
      return true
    }

    const company = contact.company
    const descritpion = contact.description

    if (company !== undefined && company !== null) {
      if (company.toLowerCase().includes(searchText)) {
        return true
      }
    }

    if (descritpion !== undefined && descritpion !== null) {
      if (descritpion.toLowerCase().includes(searchText)) {
        return true
      }
    }

    if (contact.contacts !== undefined && contact.contacts !== null) {
      if (contact.contacts.find(x => x.value.toLowerCase().includes(searchText)) !== undefined) {
        return true
      }
    }

    return false
  }

  // when user click "dismiss" on the Capsule banner, set a cookie "hide_capsule_banner_from_contactbook" to true, and add class "hidden" to hide it.
  handleHideCapsuleBanner () {
    localStorage.setItem('hide_capsule_banner_from_contactbook', true)
    this.setState({
      hideCrmBanner: true
    })
  }

  render () {
    const characters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

    const yearlyPrice = this.props.yearlyPrice
    const monthlyPrice = this.props.monthlyPrice

    const oauthClient = OauthHelper.GetCapsuleCrmOauthClient()
    const oauthClientPipeDrive = OauthHelper.GetPipeDriveCrmOauthClient()
    const oauthUrlHubspot = OauthHelper.GetHubSpotCrmOauthUrl()

    let contacts = this.state.contacts

    // apply filter
    contacts = contacts.filter(x => this.searchTextMatch(x) === true)

    let pageNum = this.state.pageNumber

    if (contacts.length > 0) {
      // calculate this before removing entries from the contacts arrray
      var numPages = Math.ceil(contacts.length / this.state.pageSize)

      if (pageNum > numPages) {
        pageNum = numPages
      }

      const min = 0 + (this.state.pageSize * (pageNum - 1))
      const max = min + (this.state.pageSize - 1)

      const filteredContacts = []

      // remove entries above max value
      for (let i = min; i <= max && i < contacts.length; i++) {
        filteredContacts.push(contacts[i])
      }

      contacts = filteredContacts
    }

    return <><ScrollToTopOnMount/><DisplayLoggedInFooter/> <DisplayMainHeader/>
            <Helmet>
                <body className={'logged-in contact-book-page '} />

                <style type="text/css">{`
                        @media screen and (max-width:767px) {
                            #lhnHocButton, .lhnHocChatBtn{
                                display:none;
                            }
                        } 
                    
                    `}</style>
            </Helmet>

            <header className="bg-light pt-60">
                <div className="container">
                    <div className="col-md-12 text-center">

                        <h1 className="text-500">
                            <span className="d-none d-sm-inline-block">
                                <SvgRender icon="contact-book" svgWidth="100px" svgStyle={{ marginLeft: '-105px' }} />
                            </span>
                            <span>Contact Book</span>
                        </h1>
                        <span className="pl-30 pr-30 pt-10 pb-10 brad-30 bg-dark d-inline-block">
                            <i><span className="text-400">With</span> <span className="text-cta text-500">Personalised A.I. Caller Greeting&trade;</span></i>
                        </span>
                    </div>
                </div>

            </header>

            <div className="full-width bg-light">
                <div className="container">
                    <div className="row">
                        <div className="w-700px center-block text-center pt-40 pb-40">
                            <h5 className="text-400">
                                Making it easy to identify your regular callers.
                                Upload companywide contact information including names &amp; numbers, add a caller’s name to missed call &amp; voicemail notifications.
                            </h5>
                        </div>
                    </div>
                </div>
            </div>

            {/* START: Capsule and Pipedrive banner */}
            {this.state.hideCrmBanner === false && this.state.syncSettings && this.state.syncSettings.pipedriveCrmEnabled === false && this.state.syncSettings.capsuleCrmEnabled === false && this.state.syncSettings.hubSpotCrmEnabled === false &&
                    <div className='full-width bg-dark text-center pb-40 pt-60' id="capsuleBanner">
                        <div className='row'>
                            <div className='w-1200px center-block'>
                                <div className='col-12 d-flex align-items-sm-stretch flex-wrap flex-xs-nowrap'>
                                {this.state.syncSettings && this.state.syncSettings.hubSpotCrmEnabled === false

                                  ? <RequestAuthorizationCode
                                    oauthClient={oauthClientPipeDrive}
                                    render={({ url }) =>

                                            <div className={this.state.syncSettings.hubSpotCrmEnabled === false ? 'xxx w-450px w-100  p-30 brad-20 m-10 pb-30 float-start' : 'aaa w-450px w-100  p-50 brad-20 pb-30 ml-auto mr-auto'} style={{ border: '2px solid #646464', minHeight: '364px' }}>
                                                <img src="/images/logo/hubspot-logo-light.png" alt="HubSpot logo" className='img-responsive w-300px text-center center-block'/>
                                                {/* <h4 className="text-500 pb-20 text-lightest">
                                                    Pipedrive CRM
                                                </h4> */}
                                                <p className='text-sm pb-20 pt-30 text-start'>
                                                    Automatically synchronise your HubSpot CRM database with SwitchboardFREE for a more connected phone system. <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={HubSpotDescription}>
                                                    <span className='text-lightest underline'>More info</span></OverlayTrigger>
                                                </p>
                                                <p className='pt-10 pt-xs-30'>
                                                    <a href={oauthUrlHubspot} className="btn btn-xs btn-cta">Connect now</a>
                                                </p>
                                            </div>

                                        }/>
                                  : <></>
                                }

                                {this.state.syncSettings && this.state.syncSettings.pipedriveCrmEnabled === false

                                  ? <RequestAuthorizationCode
                                    oauthClient={oauthClientPipeDrive}
                                    render={({ url }) =>

                                            <div className={this.state.syncSettings.pipedriveCrmEnabled === false ? 'xxx w-450px w-100  p-30 brad-20 m-10 pb-30 float-start' : 'aaa w-450px w-100  p-50 brad-20 pb-30 ml-auto mr-auto'} style={{ border: '2px solid #646464', minHeight: '364px' }}>
                                                <img src="/images/logo/pipedrive-logo-white.png" alt="Pipedrive logo" className='img-responsive w-300px text-center center-block'/>
                                                {/* <h4 className="text-500 pb-20 text-lightest">
                                                    Pipedrive CRM
                                                </h4> */}
                                                <p className='text-sm pb-20 pt-30 text-start'>
                                                    Integrate with Pipedrive to synchronize contacts from Pipedrive to SwitchboardFREE and upload call information to your Pipedrive contacts. <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={pipedriveDescription}>
                                                    <span className='text-lightest underline'>More info</span></OverlayTrigger>
                                                </p>
                                                <p className='pt-10 pt-xs-30'>
                                                    <a href={url} className="btn btn-xs btn-cta">Connect now</a>
                                                </p>
                                            </div>

                                        }/>
                                  : <></>
                                }

                                {
                                    this.state.syncSettings && this.state.syncSettings.capsuleCrmEnabled === false ? <RequestAuthorizationCode
                                    oauthClient={oauthClient}
                                    render={({ url }) =>
                                    <>

                                            <div className={this.state.syncSettings.capsuleCrmEnabled === false ? 'xxx w-450px w-100  p-30 brad-20 m-10 pb-30 float-start' : 'aaa w-450px w-100  p-50 brad-20 pb-30 ml-auto mr-auto'} style={{ border: '2px solid #646464', minHeight: '364px' }}>
                                                <img src="/images/logo/capsule-logo.png" alt="Capsule logo" className='img-responsive w-300px text-center center-block'/>
                                                {/* <h4 className="text-500 pb-20 text-lightest">
                                                    Capsule CRM
                                                </h4> */}
                                                <p className='text-sm pb-20 pt-30 text-start'>
                                                    Capsule CRM integration auto-imports your Capsule contacts into your Contact Book, app, softphone and phones whilst simultaneously logging all of your call activity in your Capsule CRM. <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={capsuleDescription}><span className='text-lightest underline'>More info</span></OverlayTrigger>
                                                </p>
                                                <p className='pt-10 pt-xs-30'>
                                                    <a href={url} className="btn btn-xs btn-cta">Connect now</a>
                                                </p>
                                            </div>

                                    </>
                                }/>
                                      : <></>
                                }
                                </div>

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <p className='pt-30'>
                                    <a onClick={() => this.handleHideCapsuleBanner(this)} className="underline text-xs text-dark-lighter">Don't show me this again</a>
                                </p>
                            </div>
                        </div>
                    </div>
            }
            {/* End: Capsule and Pipedrive banner */}

            <div className="container-styled bg-dull" style={{ backgroundColor: this.state.deleteContactSelected === true && '#cecdcd' }}>
                {this.props.isEnabled
                  ? <>
                    {this.state.deleteContactSelected === false
                      ? <></>

                      : <>
                                            <div className='text-center p-fixed w-100' style={{ bottom: '30px', zIndex: '1001' }}>
                                                <div className="bg-light p-30 brad-30 text-center box-shadow-v4 d-inline-block">
                                                    <Button
                                                            className={this.state.contactsToDelete.length === 0 ? 'm-5 brad-10 pl-30 pr-30 hidden' : 'm-5 brad-10 pl-30 pr-30' }
                                                            title="Delete selected"
                                                            variant="danger"
                                                            size="xs"
                                                            disabled={this.state.contactsToDelete.length === 0}
                                                            onClick={ () => this.setState({ showDeleteContactsModal: true })}
                                                            >
                                                        Delete {this.state.contactsToDelete.length} selected {this.state.contactsToDelete.length > 1 ? 'contacts' : 'contact'}
                                                    </Button>
                                                    <br className={this.state.contactsToDelete.length === 0 ? 'hidden' : 'd-block d-xs-none' } />
                                                    {
                                                        this.state.deleteContactSelected === true &&

                                                            <Button
                                                                variant="secondary"
                                                                size="xs"
                                                                className="m-5 brad-10 pl-30 pr-30"
                                                                onClick={e => this.state.contactsToDelete.length === this.state.contacts.filter(x => x.importerType !== 3).length ? this.unselectAllForDelete() : this.selectAllForDelete() }
                                                                >
                                                                    {this.state.contactsToDelete.length === this.state.contacts.filter(x => x.importerType !== 3).length ? 'Unselect All' : 'Select All'}
                                                            </Button>

                                                    }
                                                    <Button
                                                        className="m-5 brad-10 pl-30 pr-30"
                                                        variant="secondary"
                                                        size="xs"
                                                        onClick={ () => this.setState({ deleteContactSelected: false })}
                                                        >
                                                        Cancel
                                                    </Button>

                                                </div>
                                            </div>

                                        </>
                                    }
                <div className="container pl-xs-10 pr-xs-10">
                        <div className="row">
                            <div className="col-md-12 text-center">

                                    <LoadingNotification isLoading={!this.state.contacts} width={100} height={100} textColor="text-dark"
                                                        hasFinishedLoading={this.state.contacts} removeOnLoaded={true}
                                                        message='Loading contacts...'/>
                            </div>

                                <Modal className="modal-small" show={this.state.showGreetingWarningModal === true} onHide={e => this.setState({ showGreetingWarningModal: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Enable Caller Greetings</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>Enabling this feature will generate a personalised caller greeting based in your contacts names for anyone in your contact book that calls your SwitchboardFREE numbers from a mobile.</p>
                                        <p>Please ensure that all contacts that you add to your account are named appropriately.</p>
                                    </Modal.Body>
                                    <Modal.Footer className="bt-0">
                                        <Button size="lg" variant="cta" className="btn-block" onClick={ () => this.handleCallerGreetingEnableConfirmed()} >Yes</Button>
                                        <p className="text-dark underline text-sm pt-20 text-center" onClick={ () => this.setState({ showGreetingWarningModal: false }) }>No, cancel.</p>
                                    </Modal.Footer>
                                </Modal>

                                <Modal className="modal-small" show={this.state.showDeleteContactsModal === true} onHide={e => this.setState({ showDeleteContactsModal: false })}>
                                    {/* <Modal.Header closeButton>
                                        <Modal.Title>Delete Selected Contacts</Modal.Title>
                                    </Modal.Header> */}
                                    <Modal.Body>
                                    <div className="col-md-12 text-center pb-20">
                                        <h5 className="text-center text-500">
                                        {this.state.deleteContactSelected === false
                                          ? <></>

                                          : <>
                                                    Delete {this.state.contactsToDelete.length} selected {this.state.contactsToDelete.length > 1 ? 'contacts' : 'contact'}?
                                                </>
                                            }
                                        </h5>
                                        <p>Are you sure?</p>
                                    </div>
                                    <span className="close-modal" onClick={() => this.setState({ showDeleteContactsModal: false })}>
                                        <span>&times;</span>
                                    </span>
                                    </Modal.Body>
                                    <Modal.Footer className="bt-0">
                                        <Button size="lg" variant="cta" className="btn-block" onClick={ () => this.handleDeleteContactsConfirmed()} >Yes, delete</Button>
                                        <p className="text-dark underline text-sm pt-20 text-center" onClick={ () => this.setState({ showDeleteContactsModal: false }) }>No, cancel.</p>
                                    </Modal.Footer>
                                </Modal>

                                <Modal size="w-450px" show={this.state.new}
                                    onHide={e => this.setState({ new: undefined })}>
                                        <Modal.Header className='bb-0 p-0' closeButton></Modal.Header>
                                    <Modal.Body>
                                    <div className="col-md-12 text-center pb-20">
                                        <h5 className="text-center text-500 text-primary">Add new Contact</h5>
                                    </div>
                                        <div className="col-md-12">
                                            <div className={'form-group mb-0 ' + (this.state.new === undefined || this.state.new.description === undefined || this.state.new.description === '' ? 'has-error' : '')}>
                                                <label className="control-label">Callers name / description</label>
                                                <input className="form-control" type='text'
                                                    placeholder={'Caller\'s Description / Name'}
                                                    value={this.state.new === undefined ? '' : this.state.new.description}
                                                    onChange={e => {
                                                      let value = e.target.value

                                                      if (value.length > 50) {
                                                        value = value.substring(0, 50)
                                                      }

                                                      this.setState({
                                                        new: {
                                                          ...this.state.new,
                                                          description: value
                                                        }
                                                      })
                                                    }}/>
                                            </div>
                                            <label className="pt-20">Company</label>
                                            <input className="form-control" type='text' placeholder={'Optional'}
                                                value={this.state.new === undefined ? '' : this.state.new.company}
                                                onChange={e => {
                                                  let value = e.target.value

                                                  if (value.length > 50) {
                                                    value = value.substring(0, 50)
                                                  }

                                                  if (!e) {
                                                    this.setState({ new: { ...this.state.new, company: '' } })
                                                  } else {
                                                    this.setState({ new: { ...this.state.new, company: value } })
                                                  }
                                                }}
                                            />

                                            <div className={'form-group ' + (this.state.validContactEntered ? '' : 'has-error')}>
                                                <label className="control-label pt-20">Phone Number</label>
                                                <input className="form-control" type='tel' placeholder={'Phone number'} maxLength={11}
                                                    value={this.state.new === undefined ? '' : this.state.new.number}
                                                    onChange={e => {
                                                      let value = e.target.value.replaceAll(' ', '')

                                                      if (value.length > 50) {
                                                        value = value.substring(0, 50)
                                                      }

                                                      if (!e) {
                                                        this.setState({ new: { ...this.state.new, number: '' } })
                                                      } else {
                                                        this.setState({
                                                          new: {
                                                            ...this.state.new,
                                                            number: value
                                                          }
                                                        })
                                                      }
                                                      const isValid = this.isEmailOrNumberValid(value)

                                                      this.setState({ validContactEntered: isValid })
                                                    }} />
                                            </div>

                                            {/* <input type="checkbox" checked={this.state.new === undefined ? false : this.state.new.ignorePersonalizedIntro} maxLength={50} onChange={() => {
                                                let value = this.state.new === undefined ? false : !this.state.new.ignorePersonalizedIntro
                                                this.setState({
                                                    new: {
                                                        ...this.state.new,
                                                            ignorePersonalizedIntro: value
                                                    }
                                                })
                                            }} /> <span> Exclude from Personalized Intros </span> */}
                                            <div className="bg-dark p-30 brad-20 text-center mt-30">
                                                <SvgRender icon="hello" svgWidth="50px" svgClassName="mb-10" />
                                                <h6 className="text-500 text-lightest">Turn on A.I. Caller Greeting?</h6>
                                                <p className="text-italic text-500 text-lightest text-sm">
                                                    Recognise &amp; greet mobile callers by name.
                                                </p>
                                                <div className="block">
                                                    <Toggle
                                                        onClick={(e) => { this.setState({ new: { ...this.state.new, ignorePersonalizedIntro: !e } }) }}
                                                        on={<span>ON</span>}
                                                        off={<span>OFF</span>}
                                                        offstyle="default"
                                                        onstyle="success"
                                                        width="null"
                                                        height="null"
                                                        size="sm"
                                                        className="rounded mt-10"
                                                        active={this.state.new === undefined ? '' : !this.state.new.ignorePersonalizedIntro}
                                                        id="CallerGreetingToggleModal"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="bt-0">
                                        <div className="col-md-12 text-center pt-30 pb-30">
                                        <Button size="lg" variant="cta" className="btn-block" disabled={!this.state.validContactEntered || !this.state.new || !this.state.new.number || !this.state.new.description || this.state.new.description === null || this.state.new.description.replaceAll(/\s/g, '') === ''}
                                                    onClick={e => this.handleSaveNewContact()}>Save Contact</Button>
                                        </div>
                                    </Modal.Footer>
                                </Modal>

                                <Modal className="modal-small new-number" show={this.state.contactIdAddingTo !== 0} onHide={e => this.setState({ contactIdAddingTo: 0 })}>
                                    {/* <Modal.Header closeButton>

                                    {this.state.contactIdAddingTo > 0 && <Modal.Title>Add additional number for <br/><i>{this.state.contacts.find(x => x.qcId === this.state.contactIdAddingTo ).description}</i></Modal.Title>}

                                    </Modal.Header> */}
                                    <Modal.Body>
                                    <div className="col-md-12 text-center pb-20">
                                        {this.state.contactIdAddingTo > 0 && <h5 className="text-center text-400">Add additional number for <br/><i>{this.state.contacts.find(x => x.qcId === this.state.contactIdAddingTo).description}</i></h5>}
                                    </div>
                                    <span className="close-modal" onClick={() => this.setState({ contactIdAddingTo: 0 })}>
                                        <span>&times;</span>
                                    </span>
                                        <div className={'form-group ' + (this.state.validContactEntered ? '' : 'has-error')}>
                                            <label className="control-label">Phone number</label>
                                            <input className="form-control" type='tel' value={this.state.addContactEntryValue} onChange={(e) => this.setState({ addContactEntryValue: e.target.value, validContactEntered: this.isEmailOrNumberValid(e.target.value) })} />

                                            <label className="pt-20"> Exclude From Personalized Intros </label>
                                            <br/>
                                            <input type='checkbox'
                                                checked={this.state.addContactEntryExcludeFromPersonalizedIntros}
                                                onChange={e => {
                                                  const value = e.target.checked

                                                  this.setState({
                                                    addContactEntryExcludeFromPersonalizedIntros: value
                                                  })
                                                }}
                                            />
                                            <span>&nbsp;Yes</span>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="bt-0">
                                        <div className="col-md-12 pb-10 text-center p-0">
                                            <Button size="lg" variant="cta" className="btn-block" onClick={ () => this.handleAddNewContactEntryPressed() } disabled={this.state.validContactEntered === false}>Add number</Button>
                                        </div>
                                    </Modal.Footer>
                                </Modal>

                                <Modal className="modal-small" show={this.state.contactIdEditing !== 0} onHide={e => this.setState({ contactIdEditing: 0 })}>
                                    <Modal.Header closeButton  className='bb-0'>

                                    {/* {this.state.contactIdEditing > 0 && <Modal.Title> Editing {this.state.contacts.find(x => x.qcId === this.state.contactIdEditing ).description} </Modal.Title>} */}

                                    </Modal.Header>
                                    <Modal.Body>
                                    <div className="col-md-12 text-center pb-20">
                                        <h5 className="text-center text-400">{this.state.contactIdEditing > 0 && <span> Editing {this.state.contacts.find(x => x.qcId === this.state.contactIdEditing).description} </span>}</h5>
                                    </div>
                                    <div className="pr-10">
                                        {
                                            this.state.contactIdEditing > 0 && this.state.editingContacts.map((contactInfo, index) => <div className="row">
                                            { index > 0 && <br/> }
                                            <div className="col-md-12 mb-10">
                                                <input type="tel" value={contactInfo.value} className="form-control" onChange={ (e) => this.handleContactEntryEdited(index, e.target.value) } />
                                                <span className="btn btn-rounded btn-lightest box-shadow delete-number-btn" onClick={() => this.handleDeleteContactNumber(index)}><span className="icon icon-cross"></span></span>
                                            </div>
                                        </div>)
                                        }
                                        {this.state.editingContacts && this.state.editingContacts.length >= 20
                                          ? <span className="pointer text-cta underline pt-20 pb-20 text-end float-end" >Entry Limit Reached</span>
                                          : <span className="pointer text-cta underline pt-20 pb-20 text-end float-end cursor-pointer" onClick={() => this.addNewContactToEditingContacts()}>Add new number</span>
                                        }
                                    </div>

                                    </Modal.Body>
                                    <Modal.Footer className="bt-0">
                                        <div className="col-md-12 pb-10 text-center p-0">
                                            <Button size="lg" variant="cta" className="btn-block" onClick={ () => this.handleUpdateContactPressed() } disabled={this.state.validContactEntered === false}>Update Contact</Button>
                                        </div>
                                    </Modal.Footer>
                                </Modal>

                                <Modal size="w-450px" show={this.state.import} onHide={e => this.setState({ import: undefined })}>
                                    <Modal.Header closeButton className='p-0 bb-0'>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <div className="col-md-12 text-center pb-20">
                                        <h5 className="text-center text-500 text-primary">Import contacts</h5>
                                    </div>
                                        <div className="col-md-12">
                                            <p className="text-sm"><span className="text-500 text-cta text-md">Step 1:</span>
                                            <br/>
                                            Select where your contacts have been exported from</p>
                                            <div className="col-md-12 pl-0 pb-40">
                                                <select className="form-control pl-0" onChange={event => this.setState({
                                                  import: {
                                                    ...this.state.import,
                                                    from: event.target.value
                                                  }
                                                })}>
                                                    <option value="">-- Import From --</option>
                                                    <option value="GoogleCSV">Google Gmail Contact Export</option>
                                                    <option value="custom">Custom CSV</option>
                                                </select>
                                            </div>
                                            {this.state.import && this.state.import.from === 'CapsuleCrm' && <>
                                                <p className="text-sm">
                                                    <span className="text-500 text-cta text-md">Step 2:</span>
                                                    <br/>
                                                    Enter your API Authentication Token
                                                </p>
                                                <input type="text" className="form-control" value={this.state.capsuleCrmBearerToken} onChange={ e => this.setState({ capsuleCrmBearerToken: e.target.value })}></input>
                                                <br/>
                                                <p className="text-sm">
                                                    <span className="text-500 text-cta text-md">Warning:</span>
                                                    <br/>
                                                    This will merge contacts with the same name and company into a single contact instead of creating duplicate contacts
                                                </p>
                                            </>}

                                            {!this.state.import || this.state.import.from !== 'CapsuleCrm' && <>
                                            <p className="text-sm">
                                                <span className="text-500 text-cta text-md">Step 2:</span>
                                                <br/>
                                                Please select your file and click 'Start import'
                                            </p>
                                            <p className="text-sm">
                                                <strong>Please note:</strong> If you&apos;re importing a custom file please ensure you have "Name" in column A with the "Company" in column B and the "Contact" in column C.
                                                <a href="/files/import-contacts-template.csv" className="btn text-primary btn-lightest btn-block mt-10 box-shadow b-1" download><span className="icon icon-download3 mr-10 text-primary"></span>Download sample file</a>
                                            </p>
                                            <div className="col-md-12 mt-10 well text-center">
                                                <input type="file" name="files"/>
                                            </div>
                                            <div className="bg-dark p-30 brad-20 text-center mt-10 col-md-12">
                                                <SvgRender icon="hello" svgWidth="50px" svgClassName="mb-10" />
                                                <h6 className="text-500 text-lightest">Turn on A.I. Caller Greeting?</h6>
                                                <p className="text-italic text-500 text-lightest text-sm">
                                                    Recognise &amp; greet mobile callers by name.
                                                </p>
                                                <div className="block">
                                                    <Toggle
                                                        onClick={() => this.toggleAccountWideCustomerGreetingEnabled()}
                                                        on={<span>ON</span>}
                                                        off={<span>OFF</span>}
                                                        offstyle="default"
                                                        onstyle="success"
                                                        width="null"
                                                        height="null"
                                                        size="sm"
                                                        className="rounded mb-5"
                                                        active={!this.state.callerGreetingDisabled}
                                                    />
                                                </div>
                                            </div>
                                            </>}

                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="bt-0">
                                        <div className="col-md-12 text-center pt-20 pb-30">
                                            {this.state.isImporting && <p>Please wait, importing ...</p>}
                                            <Button size="lg" variant="cta" className="btn-block"
                                                    onClick={e => this.handleImportContacts()}>Import contacts</Button>
                                        </div>
                                    </Modal.Footer>
                                </Modal>

                                <Modal size="w-500px" show={this.state.showSyncContactsModal === true} onHide={e => this.setState({ showSyncContactsModal: false })}>
                                    <Modal.Header closeButton className='p-0 bb-0'>
                                    </Modal.Header>
                                    <Modal.Body className="bg-dull">
                                        <div className="col-md-12 text-center pb-20">
                                            <h5 className="text-center text-500 text-dark">Sync contacts</h5>
                                        </div>
                                        {this.state.disconnectCapsuleCrm === false
                                          ? this.state.syncSettings && this.state.syncSettings.capsuleCrmEnabled === false
                                            ? <RequestAuthorizationCode
                                                oauthClient={oauthClient}
                                                render={({ url }) =>
                                                <>
                                                    <div className="col-xs-12 b-1 p-20 brad-20 bg-light mb-20">
                                                        <div className='row'>
                                                            <div className="col-xs-12 col-sm-2 pl-0 d-block text-center text-sm-start">
                                                                <img src="/images/logo/CapsuleCrm.png" />
                                                            </div>
                                                            <div className="col-xs-12 col-sm-6 pt-15 pt-xxs-0 pl-xxs-0 text-center text-sm-start">
                                                                <span className="text-500">Capsule CRM</span>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-4 text-center text-sm-end pt-15">
                                                                <a href={url} className="btn btn-xs btn-cta">Connect</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }/>
                                            : <div className="col-xs-12 b-1 p-20 brad-20 bg-light mb-20">
                                                <div className='row'>
                                                    <div className="col-xs-12 col-sm-2 pl-0 d-block text-center text-sm-start">
                                                        <img src="/images/logo/CapsuleCrm.png" />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 pt-15 pt-xxs-0 pl-xxs-0 text-center text-sm-start">
                                                        <span className="text-500">Capsule CRM</span>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-4 text-center text-sm-end pt-15">
                                                        <a className="btn btn-xs btn-danger" onClick={() => this.setState({ disconnectCapsuleCrm: true })}>Disconnect</a>
                                                    </div>
                                                </div>
                                            </div>
                                          : <div className="col-xs-12 b-1 p-20 brad-20 bg-light mb-20">
                                                <div className='row'>
                                                    <div className="col-xs-2 pl-0 d-none d-xs-block">
                                                        <img src="/images/logo/CapsuleCrm.png" />
                                                    </div>
                                                    <div className="col-xs-10 pt-xxs-0 text-center">
                                                        <span style={{ fontSize: 12 }}> <strong>This will remove all sync&apos;d contacts from your SwitchboardFREE account are you sure?</strong></span>
                                                        <br/>
                                                        <a className="btn btn-xs btn-cta mr-10" style={{ display: 'inline' }} onClick={() => this.setState({ disconnectCapsuleCrm: false })}>Cancel</a>
                                                        <a className="btn btn-xs btn-cta mr-10" style={{ display: 'inline' }} href="/customer/profile/crm-settings">Settings</a>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {this.state.disconnectPipedrive === false
                                          ? this.state.syncSettings && this.state.syncSettings.pipedriveCrmEnabled === false
                                            ? <RequestAuthorizationCode
                                                oauthClient={oauthClientPipeDrive}
                                                render={({ url }) =>
                                                <>
                                                    <div className="col-xs-12 b-1 p-20 brad-20 bg-light mb-20">
                                                        <div className='row'>
                                                            <div className="col-xs-12 col-sm-2 pl-0 d-block text-center text-sm-start">
                                                                <img src="/images/logo/pipedrive-crm.png" />
                                                            </div>
                                                            <div className="col-xs-12 col-sm-6 pt-15 pt-xxs-0 pl-xxs-0 text-center text-sm-start">
                                                                <span className="text-500">Pipedrive CRM</span>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-4 text-center text-sm-end pt-15">
                                                                <a href={url} className="btn btn-xs btn-cta">Connect</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }/>
                                            : <div className="col-xs-12 b-1 p-20 brad-20 bg-light mb-20">
                                                <div className='row'>
                                                    <div className="col-xs-12 col-sm-2 pl-0 d-block text-center text-sm-start">
                                                        <img src="/images/logo/pipedrive-crm.png" />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 pt-15 pt-xxs-0 pl-xxs-0 text-center text-sm-start">
                                                        <span className="text-500">Pipedrive CRM</span>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-4 text-center text-sm-end pt-15">
                                                        <a className="btn btn-xs btn-danger" onClick={() => this.setState({ disconnectPipedrive: true })}>Disconnect</a>
                                                    </div>
                                                </div>
                                            </div>
                                          : <div className="col-xs-12 b-1 p-20 brad-20 bg-light mb-20">
                                                <div className='row'>
                                                    <div className="col-xs-2 pl-0 d-none d-xs-block">
                                                        <img src="/images/logo/pipedrive-crm.png" />
                                                    </div>
                                                    <div className="col-xs-10 pt-xxs-0 text-center">
                                                        <span style={{ fontSize: 12 }}> <strong>This will remove all sync&apos;d contacts from your SwitchboardFREE account are you sure?</strong></span>
                                                        <br/>
                                                        <a className="btn btn-xs btn-cta mr-10" style={{ display: 'inline' }} onClick={() => this.setState({ disconnectPipedrive: false })}>Cancel</a>
                                                        <a className="btn btn-xs btn-cta mr-10" style={{ display: 'inline' }} href="/customer/profile/crm-settings">Settings</a>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {this.state.disconnectHubSpot === false
                                          ? this.state.syncSettings && this.state.syncSettings.hubSpotCrmEnabled === false
                                            ? <RequestAuthorizationCode
                                                oauthClient={oauthClientPipeDrive}
                                                render={({ url }) =>
                                                <>
                                                    <div className="col-xs-12 b-1 p-20 brad-20 bg-light mb-20">
                                                        <div className='row'>
                                                            <div className="col-xs-12 col-sm-2 pl-0 d-block text-center text-sm-start">
                                                                <img src="/images/logo/hubspotCrmLogo.png" />
                                                            </div>
                                                            <div className="col-xs-12 col-sm-6 pt-15 pt-xxs-0 pl-xxs-0 text-center text-sm-start">
                                                                <span className="text-500">HubSpot CRM</span>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-4 text-center text-sm-end pt-15">
                                                                <a href={oauthUrlHubspot} className="btn btn-xs btn-cta">Connect</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }/>
                                            : <div className="col-xs-12 b-1 p-20 brad-20 bg-light mb-20">
                                                <div className='row'>
                                                        <div className="col-xs-12 col-sm-2 pl-0 d-block text-center text-sm-start">
                                                            <img src="/images/logo/hubspotCrmLogo.png" />
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 pt-15 pt-xxs-0 pl-xxs-0 text-center text-sm-start">
                                                            <span className="text-500">HubSpot CRM</span>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-4 text-center text-sm-end pt-15">
                                                        <a className="btn btn-xs btn-danger" onClick={() => this.setState({ disconnectHubSpot: true })}>Disconnect</a>
                                                    </div>
                                                </div>
                                            </div>
                                          : <div className="col-xs-12 b-1 p-20 brad-20 bg-light mb-20">
                                                <div className='row'>
                                                    <div className="col-xs-2 pl-0 d-none d-xs-block">
                                                        <img src="/images/logo/hubspotCrmLogo.png" />
                                                    </div>
                                                    <div className="col-xs-10 pt-xxs-0 text-center">
                                                        <span style={{ fontSize: 12 }}> <strong>This will remove all sync&apos;d contacts from your SwitchboardFREE account are you sure?</strong></span>
                                                        <br/>
                                                        <a className="btn btn-xs btn-cta mr-10" style={{ display: 'inline' }} onClick={() => this.setState({ disconnectHubSpot: false })}>Cancel</a>
                                                        <a className="btn btn-xs btn-cta mr-10" style={{ display: 'inline' }} href="/customer/profile/crm-settings">Settings</a>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </Modal.Body>
                                    <Modal.Footer className="bt-0 bg-dull">
                                        <div className="col-md-12 text-center pt-20 pb-30">
                                            {this.state.isImporting && <p>Please wait, importing ...</p>}
                                        </div>
                                    </Modal.Footer>
                                </Modal>
                        </div>
                        <div className=" mb-100">

                            <div className="row">

                                    {/* Show this is there's no contacts added. */}
                                    {this.state.contacts && this.state.contacts.length === 0 &&
                                        <div className="text-center pb-30 mt-60 bg-light b-1 box-shadow p-60 brad-30">
                                            <h4 className="text-lg text-500">Looks like you don&apos;t have any entries in your Contact Book</h4>
                                            <p className='pb-30'>Choose an option below. Please note, you are not restricted to using only one of the below options.
                                                <br className='d-none d-sm-block'/>You can mix... you can connect to multiple CRM's, add contacts manually, import them at any time.
                                                </p>
                                            <div className='row-flex flex-3-col'>
                                                <div className='bg-dull p-40 brad-20 mb-20 ml-10 mr-10 col-md-4 zoom b-1'>
                                                    <p className='text-500'>Option #1</p>
                                                    <p className='mb-40'>
                                                        We recommend synchronizing contacts from your CRM system, for example Capsule CRM or Pipedrive. Click the button below to connect to your existing CRM.
                                                    </p>
                                                    <div className='button-set'>
                                                        <Button className="btn-xs-block brad-30" variant="cta" onClick={e => this.setState({ showSyncContactsModal: true, disconnectCapsuleCrm: false, disconnectPipedrive: false })}>Sync Contacts</Button>
                                                    </div>
                                                </div>

                                                <div className='bg-dull p-40 brad-20 mb-20 ml-10 mr-10 col-md-4 zoom b-1'>
                                                    <p className='text-500'>Option #2</p>
                                                    <p className='mb-40'>
                                                        If you don&apos;t have any CRM, you can add your contacts manually.
                                                    </p>
                                                    <div className='button-set'>
                                                        <Button className="btn-xs-block brad-30" variant="cta" onClick={e => this.setState({ new: {} })}>Add Single Contact</Button>
                                                    </div>
                                                </div>

                                                <div className='bg-dull p-40 brad-20 mb-20 ml-10 mr-10 col-md-4 zoom b-1'>
                                                    <p className='text-500'>Option #3</p>
                                                    <p className='mb-40'>
                                                        If you have exported your Google Gmail contacts or you have custom CSV list, then click the button below.
                                                    </p>
                                                    <div className='button-set'>
                                                        <Button className="btn-xs-block brad-30" variant="cta" onClick={e => this.setState({ import: {} })}>Import Contact List</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center bt-1 pt-50 mt-20">
                                                <p className="text-xs">
                                                    <span className="text-500">Please note:</span>
                                                </p>
                                                <p className="text-xs">
                                                    ‘Contact Book’ shares contact information with all users and all numbers in your organisation. This feature is to help you and your team welcome your regular or high value customers, and identify them quickly by name in missed call alerts and voice mails. Phone numbers added here will also appear on individual users phone book making it easy to dial out to your regular customers.
                                                </p>
                                                <p className="text-xs">
                                                    ‘Contact Book’ is a different feature from ‘Personal Contacts’ which is available on the SwitchboardFREE phone app. ‘Personal Contacts’ synchronises a user’s mobile phone contacts and makes them available to that user’s mobile phone app, computer soft phone, and SwitchboardFREE desk phone.
                                                </p>
                                            </div>
                                        </div>
                                     }

                                    {this.state.contacts && this.state.contacts.length === 0 && this.state.isLoading === true &&
                                        <div className="text-center col-md-12 pb-30 mt-60">

                                        <LoadingNotification isLoading={this.state.isLoading === true} width={100} height={100} textColor="text-dark"
                                                        hasFinishedLoading={this.state.isLoading === false} removeOnLoaded={true}
                                                        message='Loading contacts...'/>

                                            <div className="text-center pb-30 pt-30">
                                                <Button className="mb-5 mr-10 btn-mobile-block brad-30 pl-30 pr-30 b-1 text-primary" variant="lightest" onClick={e => this.setState({ new: {} })}><span className="icon icon-plus3 text-primary mr-10"></span>Add Single Contact</Button>
                                                <Button className="mb-5 mr-10 btn-mobile-block brad-30 pl-30 pr-30 b-1 text-primary" variant="lightest" onClick={e => this.setState({ import: {} })}><SvgRender icon="users-list" svgWidth="20px" svgClassName="mr-10"/>Import Contact List</Button>
                                                <Button className="mb-5 btn-mobile-block brad-30 pl-30 pr-30 b-1 text-primary" variant="lightest" onClick={e => this.setState({ showSyncContactsModal: true, disconnectHubSpot: false, disconnectCapsuleCrm: false, disconnectPipedrive: false })}><SvgRender icon="users-list" svgWidth="20px" svgClassName="mr-10"/> Sync Contacts</Button>
                                            </div>
                                            <div className="text-center bt-1 pt-50 mt-20 w-1000px center-block">
                                                <p className="text-xs">
                                                    <span className="text-500">Please note:</span>
                                                </p>
                                                <p className="text-xs">
                                                    ‘Contact Book’ shares contact information with all users and all numbers in your organisation. This feature is to help you and your team welcome your regular or high value customers, and identify them quickly by name in missed call alerts and voice mails. Phone numbers added here will also appear on individual users phone book making it easy to dial out to your regular customers.
                                                </p>
                                                <p className="text-xs">
                                                    ‘Contact Book’ is a different feature from ‘Personal Contacts’ which is available on the SwitchboardFREE phone app. ‘Personal Contacts’ synchronises a user’s mobile phone contacts and makes them available to that user’s mobile phone app, computer soft phone, and SwitchboardFREE desk phone.
                                                </p>
                                            </div>
                                        </div>
                                    }

                                    {/* Show this if there's at least one contact added. */}
                                    {this.state.contacts && this.state.contacts.length > 0 &&
                                        <>
                                            <div className="col-12 center-block mb-30 pt-60 pl-30 pr-55 pr-xs-30">
                                                <div className="row mb-20">
                                                    <div className="col-md-4 col-xxs-12 text-center text-sm-start mb-sm-30">
                                                        <h3 className="text-500 mt-0">Your contacts</h3>
                                                    </div>
                                                    <div className="col-md-8 col-xxs-12 text-center text-sm-start">
                                                        <div className='text-center text-md-end col-md-12'>
                                                            <OverlayTrigger placement="top" overlay={NewContactTooltip}>
                                                                <Button className="mb-5 mr-10 b-1 text-primary p-0" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="Add contact" variant="lightest" size="sm" disabled={this.state.contacts.length >= 5000} onClick={e => this.setState({ new: {} })} id="AddNewContactButton">
                                                                    <span className="icon icon-plus3 text-primary"></span>
                                                                </Button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger placement="top" overlay={ImportContactsTooltip}>
                                                                <Button className="mb-5 mr-10 b-1 text-primary p-0" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="Import contacts" variant="lightest" size="sm" onClick={e => this.setState({ import: {} })} id="ImportContactsButton">
                                                                    <span className="icon icon-import text-primary"></span>
                                                                </Button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger placement="top" overlay={SyncContactsTooltip}>
                                                                <Button className="mb-5 mr-10 b-1 text-primary p-0" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="Sync contacts" variant="lightest" size="sm" onClick={e => this.setState({ showSyncContactsModal: true, disconnectCapsuleCrm: false })}>
                                                                    <span className="icon icon-loop3 text-primary"></span>
                                                                </Button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger placement="top" overlay={GoToCRMsettings}>
                                                                <Button className="mb-5 mr-10 b-1 text-primary p-0" variant="lightest" size="sm" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="CRM Settings">
                                                                    <a href="/customer/profile/crm-settings" className='no-underline' style={{ padding: '20px 20px 20px 11px' }}>
                                                                        <span className="icon icon-wrench text-primary"></span>
                                                                    </a>
                                                                </Button>
                                                            </OverlayTrigger>

                                                            {this.state.deleteContactSelected === false
                                                              ? <OverlayTrigger placement="top" overlay={SelectMultuipleTooltip}>
                                                                    <Button className="mb-5 b-1 text-primary p-0" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="Select multiple" variant="lightest" size="sm" onClick={ () => this.setState({ deleteContactSelected: true, contactsToDelete: [] }) } id="SelectMultipleButton">
                                                                        <span className="icon icon-list2 text-primary"></span>
                                                                    </Button>
                                                                </OverlayTrigger>

                                                              : <>

                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                    <div className='row'>
                                                        <div className="col-xs-12 bg-dark brad-15 p-25 pt-20 pb-20">
                                                            <div className='row'>
                                                                <div className="col-md-9 col-sm-6">
                                                                    <SvgRender icon="hello" svgWidth="40px" svgClassName="absolute d-none d-md-block"/>
                                                                    <p className="pl-60 pl-sm-0 pt-5 text-center text-sm-start">
                                                                        <span className="text-500 pr-30 text-md pr-sm-0 pb-xs-20">Caller Greeting</span>
                                                                        <br className="d-block d-md-none"/><br className="d-block d-sm-none"/>
                                                                        <span className="text-300 text-sm pl-sm-0 pb-xs-20">Automatically recognise callers' numbers &amp; greet them by name, e.g <i>&quot;Hi Chris...&quot;</i></span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-3 col-sm-6 text-center text-sm-end">
                                                                {/* <Button size="xs" variant="lightest" className="btn-rounded pt-10 pb-10 mr-10 mb-5"><span className="icon icon-play4 mr-10"></span>Play sample</Button>  */}
                                                                <Toggle
                                                                        onClick={() => this.toggleAccountWideCustomerGreetingEnabled()}
                                                                        on={<span>ON</span>}
                                                                        off={<span>OFF</span>}
                                                                        offstyle="default"
                                                                        onstyle="success"
                                                                        width="null"
                                                                        height="null"
                                                                        size="sm"
                                                                        className="rounded h-40px w-100px mb-5"
                                                                        active={!this.state.callerGreetingDisabled}
                                                                        id="AccountWideCallerGreetingToggle"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                            </div>

                                            <div className="row mb-30">
                                                <div className="col-12 text-center text-md-start pl-20 pr-20">
                                                    <div className="text-center text-md-start col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type='text'
                                                            placeholder={'Search'}
                                                            value={this.state.searchText}
                                                            onChange={(e) => this.setState({ searchText: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    }

                                    <div className="bg-xs-dull row pl-30 pr-30 pt-xs-30 pb-xs-30 pr-xs-0 pl-xs-25">
                                        {characters.map((character) => {
                                          return this.anyContactsStartWith(character, contacts) === false ? <> </> : <>
                                                <div className="col-md-12 pt-15 pb-5">
                                                    <h6 className="m-0 p-0 text-500 text-dark-lighter">{character.toUpperCase()}</h6>
                                                </div>
                                                {contacts.map((contact) => {
                                                  return (contact.description[0] && contact.description[0].toLowerCase() !== character) ? <> </> : <>

                                                    <div className={contact.importerType === 3 ? ' ' && this.state.deleteContactSelected === false ? ' capsule-contact col-md-12 b-1 box-rounded box-shadow mb-10 contact-row zoom bg-light ' : ' capsule-contact col-md-12 b-1 box-rounded box-shadow mb-10 contact-row bg-light  ' : ' col-md-12 b-1 box-rounded bg-light box-shadow mb-10 contact-row zoom ' }
                                                             style={contact.importerType === 3 ? {} && this.state.deleteContactSelected === false ? { opacity: '1' } : { opacity: '0.3' } : { opacity: '1' } }
                                                             id="Contact"
                                                    >
                                                            <div key={contact.qcId} className="col-md-12 pt-10 pb-30 pb-xs-0 pl-0 pr-0">
                                                                <div className="row pl-15 pr-15">
                                                                    <div className="col-sm-4 col-md-4 pl-0">
                                                                        <div className='row'>
                                                                            {this.state.deleteContactSelected === true && contact.importerType !== 3
                                                                              ? <>
                                                                                    {contact.importerType !== 3 && <div className='col-sm-2 pl-0 pl-15 pt-15 styled-checkbox checkbox-lg mt-5 text-center'>
                                                                                        <input type="checkbox" checked={this.state.contactsToDelete.includes(contact.qcId)} onChange={ () => this.handleChangeDeleteContact(contact.qcId) } />
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                              : <>
                                                                                    <div className="col-sm-2 pl-0 pl-15 pt-15 text-center d-none d-sm-block">
                                                                                        <span className="img-circle b-3 block center-block pt-10" style={{ width: '50px', height: '50px' }}>
                                                                                            <span className="text-cta text-400 text-uppercase">{character}</span>
                                                                                        </span>
                                                                                    </div>

                                                                                </>
                                                                            }

                                                                            <div className="col-sm-10 text-center text-sm-start pt-10 pl-md-30">
                                                                                <div className="block pt-10">
                                                                                    <span className="text-500 pb-xs-10" style={{ fontSize: '20px', lineHeight: '1', wordBreak: 'break-all' }} id="ContactName">
                                                                                        {contact.description}
                                                                                    </span>
                                                                                    <span className="text-sm text-400" id="ContactCompanyName">
                                                                                        <br/>
                                                                                        {contact.company === null ? 'N/A' : contact.company}
                                                                                    </span>
                                                                                </div>
                                                                                {this.isContactBeingEdited(contact) && this.state.editing.barr &&
                                                                                <div className="block">
                                                                                    {this.renderPrompts(this.state.prompts, contact, event => this.handleBarrPromptChange(event))}
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-4 col-sm-4'>
                                                                        <div className="block pt-20 text-center text-sm-start">
                                                                            {contact.contacts !== undefined && contact.contacts.map((contactInfo) => <p className="mb-0">
                                                                                    <span className={'icon ' + (contactInfo.dataType === 1 ? 'icon-phone2' : 'icon-at-sign') + ' text-duller mr-5'} style={{ fontSize: '15px' }}></span>
                                                                                    <a style={{ fontSize: '15px' }} href={ (contactInfo.dataType === 1 ? 'tel:' : 'mailto:') + contactInfo.value} id="ContactInfo">
                                                                                        {contactInfo.value}
                                                                                    </a>
                                                                                </p>)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-4 col-md-4 text-center text-sm-end pb-xs-30">
                                                                        <p className="d-none d-sm-inline-block">
                                                                            <span className="text-xs text-500 mr-10">Greeting:</span>
                                                                        </p>
                                                                        <p className="text-sm d-block d-sm-none text-500 pt-30 pb-0 mb-0">
                                                                            Call greeting:
                                                                        </p>
                                                                        <OverlayTrigger placement="top" overlay={ContactGreetingTooltip}>
                                                                            <Toggle
                                                                                onClick={() => this.togglePersonalisedIntroSetting(contact.qcId)}
                                                                                on={<span>ON</span>}
                                                                                off={<span>OFF</span>}
                                                                                offstyle="default"
                                                                                onstyle="success"
                                                                                width="null"
                                                                                height="null"
                                                                                size="sm"
                                                                                className="rounded mt-10"
                                                                                active={this.state.callerGreetingDisabled === false && contact.ignorePersonalizedIntro === false}
                                                                                disabled={this.state.callerGreetingDisabled}
                                                                                id="CallerGreetingToggle"
                                                                            />
                                                                        </OverlayTrigger>
                                                                        <span className="d-block d-sm-none block p-20"></span>
                                                                   {contact.importerType !== 3 && <OverlayTrigger placement="top" overlay={EditContactTooltip}>
                                                                       <Button
                                                                            variant="default"
                                                                            className="box-shadow-none btn-rounded mt-10 mr-10 ml-10"
                                                                            style={{ height: '30px', width: '30px', padding: '6px 10px 5px 8px' }}
                                                                            size="xs"
                                                                            onClick={e => this.handleEditContactClicked(contact.qcId)}
                                                                            id="EditContactButton">
                                                                                <span className='icon icon-pencil4'></span>
                                                                                </Button></OverlayTrigger>
                                                                                }

                                                                        {this.state.deleteContactSelected === true
                                                                          ? <>
                                                                                {/* {contact.importerType !== 3 && <input type="checkbox" checked={this.state.contactsToDelete.includes(contact.qcId)} onChange={ () => this.handleChangeDeleteContact(contact.qcId) } />} */}
                                                                            </>
                                                                          : <>
                                                                                {contact.importerType !== 3 && <OverlayTrigger placement="top" overlay={DeleteContactTooltip}>
                                                                                        <Button variant="default" className="box-shadow-none btn-rounded mt-10" style={{ height: '30px', width: '30px', padding: '6px 10px 5px 8px' }} size="xs" onClick={e => this.handleDeleteContact(contact)} id="ContactDeleteButton">
                                                                                            <span className='icon icon-bin'></span>
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                    }
                                                                            </>
                                                                        }

                                                                      {contact.importerType === 3 && contact.crmId === 1 && <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={syncWithCapsuleOverlay}>
                                                                        <div className="block text-center text-sm-end pt-20">
                                                                            <span style={{ fontSize: '12px' }}><strong style={{ paddingTop: '50px' }}> Sync'd from Capsule CRM </strong>&nbsp;<img src="/images/logo/CapsuleCrm.png" style={{ maxWidth: '30px', maxHeight: 'auto' }}/></span>
                                                                        </div>
                                                                      </OverlayTrigger>}

                                                                        {contact.importerType === 3 && contact.crmId === 6 && <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={syncWithPipedriveOverlay}>
                                                                            <div className="block text-center text-sm-end pt-20">
                                                                                <span style={{ fontSize: '12px' }}><strong style={{ paddingTop: '50px' }}> Sync'd from Pipedrive CRM </strong>&nbsp;<img src="/images/logo/pipedrive-crm.png" style={{ maxWidth: '30px', maxHeight: 'auto' }}/></span>
                                                                            </div>
                                                                        </OverlayTrigger>}

                                                                        {contact.importerType === 3 && contact.crmId === 3 && <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={syncWithHubSpotOverlay}>
                                                                        <div className="block text-center text-sm-end pt-20">
                                                                            <span style={{ fontSize: '12px' }}><strong style={{ paddingTop: '50px' }}> Sync'd from HubSpot CRM </strong>&nbsp;<img src="/images/logo/hubspotCrmLogo.png" style={{ maxWidth: '30px', maxHeight: 'auto' }}/></span>
                                                                        </div>
                                                                        </OverlayTrigger>}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                })}
                                            </>
                                        })}

                                        {contacts.length !== 0 && <div className={'col-md-12 b-1 box-rounded box-shadow mb-10 bg-light contact-row mt-20'}>
                                                <div className="row pagination">

                                                        <div className="col-12 col-sm-8 pb-20 pt-10 text-center text-sm-start">
                                                            <div className="mt-10 ml-20">
                                                                <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(241,243,244)', color: 'black' }} onClick={() => this.setState({ pageNumber: 1 })} disabled={pageNum === 1}>&lt;&lt;</Button>
                                                                <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(241,243,244)', color: 'black' }} onClick={() => this.setState({ pageNumber: pageNum - 1 })} disabled={pageNum === 1}>&lt;</Button>

                                                                {numPages <= 3 && <>
                                                                    {numPages > 0 && <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: pageNum === 1 ? 'rgb(74,169,181)' : 'rgb(241,243,244)', color: pageNum === 1 ? 'white' : 'black' }} onClick={() => this.setState({ pageNumber: 1 })}>{1}</Button>}
                                                                    {numPages > 1 && <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: pageNum === 2 ? 'rgb(74,169,181)' : 'rgb(241,243,244)', color: pageNum === 2 ? 'white' : 'black' }} onClick={() => this.setState({ pageNumber: 2 })}>{2}</Button>}
                                                                    {numPages > 2 && <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: pageNum === 3 ? 'rgb(74,169,181)' : 'rgb(241,243,244)', color: pageNum === 3 ? 'white' : 'black' }} onClick={() => this.setState({ pageNumber: 3 })}>{3}</Button>}

                                                                    </>
                                                                }

                                                                {numPages > 3 && pageNum <= 2 && <>
                                                                        <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: pageNum === 1 ? 'rgb(74,169,181)' : 'rgb(241,243,244)', color: pageNum === 1 ? 'white' : 'black' }} onClick={() => this.setState({ pageNumber: 1 })}>1</Button>
                                                                        <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: pageNum === 2 ? 'rgb(74,169,181)' : 'rgb(241,243,244)', color: pageNum === 2 ? 'white' : 'black' }} onClick={() => this.setState({ pageNumber: 2 })}>2</Button>
                                                                        <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: pageNum === 3 ? 'rgb(74,169,181)' : 'rgb(241,243,244)', color: pageNum === 3 ? 'white' : 'black' }} onClick={() => this.setState({ pageNumber: 3 })}>3</Button>
                                                                    </>
                                                                }

                                                                {numPages > 3 && pageNum > 2 && pageNum + 1 <= numPages && <>
                                                                        <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(241,243,244)', color: 'black' }} onClick={() => this.setState({ pageNumber: pageNum - 1 })}>{pageNum - 1}</Button>
                                                                        <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(74,169,181)', color: 'white' }} onClick={() => this.setState({ pageNumber: pageNum })}>{pageNum}</Button>
                                                                        <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(241,243,244)', color: 'black' }} onClick={() => this.setState({ pageNumber: pageNum + 1 })}>{pageNum + 1}</Button>
                                                                    </>
                                                                }

                                                                {numPages > 3 && pageNum > 2 && pageNum + 1 > numPages && <>
                                                                        <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(241,243,244)', color: 'black' }} onClick={() => this.setState({ pageNumber: pageNum - 2 })}>{pageNum - 2}</Button>
                                                                        <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(241,243,244)', color: 'black' }} onClick={() => this.setState({ pageNumber: pageNum - 1 })}>{pageNum - 1}</Button>
                                                                        <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(74,169,181)', color: 'white' }} onClick={() => this.setState({ pageNumber: pageNum })}>{pageNum}</Button>
                                                                    </>
                                                                }

                                                                <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(241,243,244)', color: 'black' }} onClick={() => this.setState({ pageNumber: pageNum + 1 })} disabled={pageNum === numPages}>&gt;</Button>
                                                                <Button className="m-5 brad-5 pl-20 pr-20 b-1" style={{ backgroundColor: 'rgb(241,243,244)', color: 'black' }} onClick={() => this.setState({ pageNumber: numPages })} disabled={pageNum === numPages}>&gt;&gt;</Button>

                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-4 float-end pt-30 pr-30 pb-xs-30 pt-xs-0 pr-xs-15">
                                                            <Select options={pageSizeOptions} defaultValue={{ label: '25 per page', value: 25 }} onChange={(e) => this.setState({ pageSize: e.value }) } />
                                                        </div>

                                                </div>
                                            </div>
                                        }
                                    </div>

                                {/*
                                    UX: If the user has more than 5 contacts added, show the "add new contact" and "import contatcs" buttons
                                    below his list of contacts as well... in addition to showing them at the top... This is to avoid unnecessary scrolling.
                                */}
                                {this.state.contacts && this.state.contacts.length > 5 &&
                                    <>
                                         <div className="text-center text-sm-end col-md-12 pt-30 pb-30 pl-50 pr-50">

                                                <OverlayTrigger placement="top" overlay={NewContactTooltip}>
                                                                <Button className="mb-5 mr-10 b-1 text-primary p-0" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="Add contact" variant="lightest" size="sm" disabled={this.state.contacts.length >= 5000} onClick={e => this.setState({ new: {} })} id="AddNewContactButton">
                                                                    <span className="icon icon-plus3 text-primary"></span>
                                                                </Button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger placement="top" overlay={ImportContactsTooltip}>
                                                                <Button className="mb-5 mr-10 b-1 text-primary p-0" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="Import contacts" variant="lightest" size="sm" onClick={e => this.setState({ import: {} })} id="ImportContactsButton">
                                                                    <span className="icon icon-import text-primary"></span>
                                                                </Button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger placement="top" overlay={GoToCRMsettings}>
                                                                <Button className="mb-5 mr-10 b-1 text-primary p-0" variant="lightest" size="sm" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="CRM Settings">
                                                                    <a href="/customer/profile/crm-settings" className='no-underline' style={{ padding: '20px 20px 20px 16px' }}>
                                                                        <span className="icon icon-wrench text-primary"></span>
                                                                    </a>
                                                                </Button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger placement="top" overlay={SyncContactsTooltip}>
                                                                <Button className="mb-5 mr-10 b-1 text-primary p-0" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="Sync contacts" variant="lightest" size="sm" onClick={e => this.setState({ showSyncContactsModal: true, disconnectCapsuleCrm: false })}>
                                                                    <span className="icon icon-loop3 text-primary"></span>
                                                                </Button>
                                                            </OverlayTrigger>

                                                            {this.state.deleteContactSelected === false
                                                              ? <OverlayTrigger placement="top" overlay={SelectMultuipleTooltip}>
                                                                    <Button className="mb-5 b-1 text-primary p-0" style={{ borderRadius: '50%', height: '40px', width: '40px' }} title="Select multiple" variant="lightest" size="sm" onClick={ () => this.setState({ deleteContactSelected: true, contactsToDelete: [] }) } id="SelectMultipleButton">
                                                                        <span className="icon icon-list2 text-primary"></span>
                                                                    </Button>
                                                                </OverlayTrigger>

                                                              : <>
                                                                    <div className="col-md-12 mt-20 pr-0 pl-0 float-none float-sm-end">
                                                                        <Button className="mb-5 btn-xxs-block brad-30 pl-30 pr-30 b-1 text-primary" title="Delete selected" variant="lightest" size="xs" disabled={this.state.contactsToDelete.length === 0} onClick={ () => this.setState({ showDeleteContactsModal: true })}>
                                                                            Delete Selected
                                                                        </Button>
                                                                        {
                                                                            this.state.deleteContactSelected === true &&

                                                                                <Button
                                                                                    variant="lightest"
                                                                                    size="xs" className="mb-5 btn-xxs-block brad-30 pl-30 pr-30 b-1 text-primary"
                                                                                    onClick={e => this.state.contactsToDelete.length === this.state.contacts.filter(x => x.importerType !== 3).length ? this.unselectAllForDelete() : this.selectAllForDelete() }>
                                                                                        {this.state.contactsToDelete.length === this.state.contacts.filter(x => x.importerType !== 3).length ? 'Unselect All' : 'Select All'}
                                                                                </Button>

                                                                        }
                                                                        <Button className="mb-5 btn-xxs-block brad-30 pl-30 pr-30 b-1 text-primary" variant="lightest" size="xs" onClick={ () => this.setState({ deleteContactSelected: false })}>
                                                                            Cancel
                                                                        </Button>

                                                                    </div>
                                                                </>
                                                            }

                                        </div>
                                    </>

                                    }

                            </div>

                        </div>
                    </div>
                    </>
                  : <>
                    <div className="container">
                        <div className="row text-center">
                            <div className="bg-dull col-lg-6 mx-auto b-2 brad-20 mb-100">
                                <div className="row">
                                    <div className="col-md-12 pb-30">
                                        <h2 className="text-500">Activate Now</h2>
                                        <p className="text-md">Please select one of the payment options below.</p>
                                    </div>
                                    <div className="col-md-12">
                                        <Button variant="cta" size="lg" className="m-5" disabled={this.state.isPurchaseInProgress}
                                                onClick={event => this.handlePurchaseContactBook(billingPeriod.YEAR, yearlyPrice)}>
                                                    <GetProdPrice productId={ProductIds.contactBook} yearlyOption={productBillingOption.contactBookBillingOptionYearly} monthlyOption={productBillingOption.contactBookBillingOptionMonthly} returnYearly={true} /> per year
                                                </Button>
                                        <Button variant="cta" className="m-5" disabled={this.state.isPurchaseInProgress}
                                                onClick={event => this.handlePurchaseContactBook(billingPeriod.MONTH, monthlyPrice)}
                                                size="lg"><GetProdPrice productId={ProductIds.contactBook} yearlyOption={productBillingOption.contactBookBillingOptionYearly} monthlyOption={productBillingOption.contactBookBillingOptionMonthly} /> per month</Button>
                                        <div className="text-center pt-30">
                                                <img src={'/images/credit-cards/stripe-badge-transparent.png'} width="320" className="worldPay"
                                                    alt="Payments powered by Stripe" />
                                            </div>
                                            <div className="text-center pt-30 pb-30 text-400">
                                                <small>
                                                    By purchasing this product you agree to the <a className="text-dark underline"
                                                        onClick={e => this.setState({ showTsAndCs: true })}>terms &amp; conditions</a> of this product.
                                                <br />All prices are exclusive of VAT at 20%.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
                </div>
                <Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
                    {/* <Modal.Header closeButton>
                        <Modal.Title>Terms &amp; Conditions</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                    <div className="col-md-12 text-center pb-20">
                        <h5 className="text-center text-400">Terms &amp; Conditions</h5>
                    </div>
                    <span className="close-modal" onClick={() => this.setState({ showTsAndCs: false })}>
                        <span>&times;</span>
                    </span>
                        <TermsAndConditions/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal className="modal-small" show={this.state.contactBeingDeleted !== undefined} onHide={e => this.setState({ contactBeingDeleted: undefined })}>
                    {/* <Modal.Header closeButton>
                    <Modal.Title className="h5">Are you sure?</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body className="text-center pt-20">
                    <div className="col-md-12 text-center pb-20">
                        <h5 className="text-center text-500">Are you sure?</h5>
                    </div>
                    <span className="close-modal" onClick={() => this.setState({ contactBeingDeleted: undefined })}>
                        <span>&times;</span>
                    </span>
                    <p>
                           You're about to delete <i>{this.state.contactBeingDeleted !== undefined && <span> {this.state.contactBeingDeleted.description} </span>}</i> from your contact book. Are you sure?
                        </p>
                    </Modal.Body>
                    <Modal.Footer className="text-center bt-0">
                        <Button variant="cta" size="block" onClick={() => this.handleApproveDeleteContactPressed() }>Yes, delete</Button>
                        <p className="underline mx-auto pt-15 pb-10 text-dark" onClick={e => this.setState({ contactBeingDeleted: undefined })}>No, cancel</p>
                    </Modal.Footer>
                </Modal>

            <ChoosePayment
                total={calculateVat(this.state.amount)}
                clientId={this.props.clientId}
                purchaseData={this.state.key}
                email={this.props.userDetails.email}
                name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                mandate={!this.props.services.hasAgreement}
            />

            </>
  }

  handleAddNewContactEntryPressed () {
    const url = `/api/Contacts/AddNewContactEntry/${this.state.contactIdAddingTo}`

    fetchHelper.postJson(url, { NewContact: this.state.addContactEntryValue, ignorePersonalizedIntro: this.state.addContactEntryExcludeFromPersonalizedIntros }).then(res => {
      this.LoadContacts()
      this.setState({ addContactEntryValue: '', validContactEntered: false })
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleAddContactEntryClicked (contactId) {
    this.setState({
      contactIdAddingTo: contactId,
      validContactEntered: false
    })
  }

  handleEditContactClicked (contactId) {
    const contacts = JSON.parse(JSON.stringify(this.state.contacts.find(x => x.qcId === contactId).contacts))
    this.setState({
      contactIdEditing: contactId,
      editingContactName: this.state.contacts.find(x => x.qcId === contactId).description,
      editingContacts: contacts,
      validContactEntered: true
    })
  }

  handleContactEntryEdited (index, newValue) {
    const contacts = this.state.editingContacts

    contacts[index].value = newValue

    let allContactsValid = true

    for (let i = 0; i < contacts.length; i++) {
      const isValid = this.isEmailOrNumberValid(contacts[i].value)

      if (isValid === false) {
        allContactsValid = false
      }
    }

    this.setState({ editingContacts: contacts, validContactEntered: allContactsValid })
  }

  handleContactNameEdited (contactId, newValue) {
    this.setState({ editingContactName: newValue })
  }

  handleContactEntryExcludeEdited (index, newValue) {
    const contacts = this.state.editingContacts

    contacts[index].ignorePersonalizedIntro = newValue

    let allContactsValid = true

    for (let i = 0; i < contacts.length; i++) {
      const isValid = this.isEmailOrNumberValid(contacts[i].value)

      if (isValid === false) {
        allContactsValid = false
      }
    }

    this.setState({ editingContacts: contacts, validContactEntered: allContactsValid })
  }

  isEmailOrNumberValid (value) {
    const validEmail = new RegExp('^[A-Za-z0-9]+@+[A-Za-z0-9]+\.+[A-Za-z0-9\.]*').test(value)
    const validNumber = new RegExp('^[0-9]{10,18}$').test(value)

    if (validEmail === false && validNumber === false) {
      return false
    } else {
      return true
    }
  }

  renderPrompts (prompts, contact, onPromptChange = event => this.handleBarrPromptChange(event)) {
    return <div>
            <label className="pt-20">Select the prompt this caller will hear:</label>
            <select className="form-control" onChange={onPromptChange}>
                {prompts.map((prompt) => <option key={prompt.id} value={prompt.id}
                                                 selected={contact && contact.blockedPromptId === prompt.id}>{prompt.description}</option>)}
            </select>
        </div>
  }

  handleDeleteContactEntry (index) {
    const contacts = this.state.editingContacts

    contacts.splice(index, 1)

    this.setState({ editingContacts: contacts })
  }

  handleUpdateContactPressed () {
    const url = `/api/Contacts/UpdateContactBookEntry/${this.state.contactIdEditing}`

    const data = {
      name: this.state.editingContactName,
      contacts: this.state.editingContacts
    }

    fetchHelper.postJson(url, data).then(res => {
      this.LoadContacts()
      this.setState({ contactIdEditing: 0 })
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleDeleteContact (contact) {
    this.setState({ contactBeingDeleted: contact })
  }

  handleApproveDeleteContactPressed () {
    const contact = this.state.contactBeingDeleted
    const url = '/api/Contacts/DeleteContact/' + contact.qcId

    const newContacts = _.reject(this.state.contacts, function (thisContact) {
      return thisContact.qcId === contact.qcId
    })

    this.setState({ contacts: newContacts, contactBeingDeleted: undefined })

    fetchHelper.postJson(url).then(res => {
      const newContacts = _.reject(this.state.contacts, function (thisContact) {
        return thisContact.qcId === contact.qcId
      })

      this.setState({ contacts: newContacts, contactBeingDeleted: undefined })
    }).catch(function (error) {
      console.error(error)
    })
  }

  isContactBeingEdited (contact) {
    return this.state.editing && this.state.editing.qcId === contact.qcId
  }

  componentDidMount () {
    document.title = 'Contact Book'

    this.setState({
      loadingContacts: true,
      hideCrmBanner: !!localStorage.getItem('hide_capsule_banner_from_contactbook')
    })

    const context = this

    this.LoadContacts()

    fetchHelper.getJson('api/contacts/GetAccountWideCustomerGreetingEnabled').then((res) => {
      this.setState({ callerGreetingDisabled: !res.data })
    })

    fetchHelper.getJson('api/contacts/GetCrmContactBookSyncSettings').then((res) => {
      this.setState({ syncSettings: res.data })
    })

    this.setState({ loadingPrompts: true })

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()

      if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
          'use strict'
          if (typeof start !== 'number') {
            start = 0
          }

          if (start + search.length > this.length) {
            return false
          } else {
            return this.indexOf(search, start) !== -1
          }
        }
      }
    }
  }

  handleBarrPromptChange (event) {
    console.log(event)

    const promptId = Number(event.target.value)
    this.setState({ editing: { ...this.state.editing, blockedPromptId: promptId } })
  }

  LoadContacts () {
    const getContactsUrl = '/api/Contacts/GetContacts'

    fetchHelper.getJson(getContactsUrl).then(res => {
      if (res.status !== 204) {
        const contacts = res.data
        if (contacts !== null) {
          this.setState({
            contacts,
            new: undefined,
            isLoading: false
          })
        } else {
          alert('error')
          console.error(contacts)

          this.setState({
            isLoading: false
          })
        }
      } else {
        this.setState({
          isLoading: false
        })
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleSaveNewContact () {
    const url = '/api/Contacts/SaveNewContactBookContact'

    const newContact = this.state.new

    fetchHelper.postJson(url, newContact).then(res => {
      console.log(res)
      this.LoadContacts()
      this.setState({ new: undefined })
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleImportContacts () {
    const context = this
    // fetchHelper.postFile(`api/Contacts/Upload/${this.state.import.from}`, importData).then(response => {
    //     if (response.data) {
    //         context.setState({contacts: response.data, import: undefined});
    //     }
    // }).catch(error => {
    //     context.setState({import: undefined});
    //     context.props.notify({message: 'Error importing contacts, please check your file and try again', isError: true});
    // });
    context.setState({ isImporting: true })

    if (this.state.import.from === 'CapsuleCrm') {
      const data = {
        BearerToken: this.state.capsuleCrmBearerToken
      }

      fetchHelper.postJson('api/Contacts/DownloadContactsFromCapsuleCrm', data).then(res => {
        if (res.data === 1) {
          this.setState({
            import: undefined,
            isImporting: false,
            capsuleCrmBearerToken: ''
          })
          this.LoadContacts()
        } else if (res.data === 2) {
          this.setState({
            isImporting: false
          })
          context.props.notify({ message: 'Invaid Authentication Token', isError: true, isImporting: false })
        } else {
          this.setState({
            isImporting: false
          })
          context.props.notify({ message: 'Error importing contacts, please contact support', isError: true, isImporting: false })
        }
      })
    } else {
      console.log('handle contacts')
      const importData = document.querySelector('input[type="file"]').files[0]

      fetchHelper.postFile(`api/Contacts/Upload/${this.state.import.from}`, importData).then(response => {
        if (response.data.sucessful === true) {
          context.setState({ import: undefined, isImporting: false })

          this.LoadContacts()
        } else {
          context.props.notify({ message: response.data.failedMessage, isError: true, isImporting: false })
        }
      }).catch(function (error) {
        context.props.notify({ message: 'Error saving configuration, please contact support', isError: true, isImporting: false })
      })
    }
  }
}

export default connect(
  state => {
    return {
      isEnabled: true,
      monthlyPrice: state.home.productDetails.monthlyRepeatPayment,
      yearlyPrice: state.home.productDetails.yearlyRepeatPayment,
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    notify: handleNotificationChange,
    handleContactBookEnabledChange,
    handleShowPaymentOptionsModal
  }
)(ContactBook)
