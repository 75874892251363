import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

const Competitors = () => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />
            <Helmet>
                <title>Virtual Switchboard Comparison | SwitchboardFREE vs Others</title>
                <meta name="description" content="Find out which switchboard service is best for your company. See what others offer and find out what makes SwitchboardFREE different." />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/mobile-redirects-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Virtual Switchboard Comparison</h1>
                <h2 className="subtitle pt-20">
                    {/* SwitchboardFREE vs Others */}
                    Find out which switchboard service is best for your company. See what others <br className="d-none d-md-block" />offer and find out what makes SwitchboardFREE different.
                </h2>
                <p></p>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/number-supermarket/'}>
                                <img src="/images/competitors/logos/number-supermarket.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Number Supermarket</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/number-people/'}>
                                <img src="/images/competitors/logos/number-people.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Number People</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/flextel/'}>
                                <img src="/images/competitors/logos/flextel.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Flextel</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/another-number/'}>
                                <img src="/images/competitors/logos/another-number.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Another Number</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/pipcall/'}>
                                <img src="/images/competitors/logos/pipcall.jpg" className="img-responsive pb-55 pt-40" style={{ width: '324px' }} />
                                <h4>PiPcall</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/bt-cloud/'}>
                                <img src="/images/competitors/logos/bt.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>BT Cloud</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/telavox-flow/'}>
                                <img src="/images/competitors/logos/telavox.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Telavox Flow</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/cleartone/'}>
                                <img src="/images/competitors/logos/cleartone.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Cleartone</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/press1/'}>
                                <img src="/images/competitors/logos/press1.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Press1</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/ring-central/'}>
                                <img src="/images/competitors/logos/ringcentral.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Ring Central</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/everreach/'}>
                                <img src="/images/competitors/logos/everreach.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Everreach</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/pocket-landline'}>
                                <img src="/images/competitors/logos/ee-pocket-landline.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>EE Pocket Landline</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/virtual-landline/'}>
                                <img src="/images/competitors/logos/virtual-landline.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Virtual Landline</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/virtually-local/'}>
                                <img src="/images/competitors/logos/virtually-local.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Virtually Local</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/tamar-telecommunications/'}>
                                <img src="/images/competitors/logos/tamar.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Tamar Telecommunications</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/TTNC/'}>
                                <img src="/images/competitors/logos/ttnc.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>TTNC</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/vonage/'}>
                                <img src="/images/competitors/logos/vonage.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Vonage</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/hihi/'}>
                                <img src="/images/competitors/logos/hihi.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>HiHi</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/bonline/'}>
                                <img src="/images/competitors/logos/bonline.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>bOnline</h4>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-6 text-center br-sm-1 br-xs-0 pb-30 pt-30 bb-1">
                            <Link to={'/competitors/air-landline/'}>
                                <img src="/images/competitors/logos/airlandline.jpg" className="img-responsive" style={{ width: '324px' }} />
                                <h4>Air Landline</h4>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return state
  },
  {}
)(Competitors)
