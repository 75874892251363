import { Component } from 'react'
import { connect } from 'react-redux'
import { fetchHelper } from '../helpers/fetchHelper'
import { handleUpdateEnabledServices, logout } from '../store/SBF'

class RefreshUserServices extends Component {
  render () {
    return (
      null
    )
  }

  componentDidMount () {
    const context = this

    function getServices () {
      const pause = localStorage.getItem('pauseRefresh') !== null
      if (pause === false) {
        const url = 'api/Users/GetServices'
        fetchHelper.getJson(url).then(response => {
          if (response.data) {
            const credit = response.data.mobileCredits
            const hasAgreement = response.data.hasBillingAgreement
            const hasAutoTopup = response.data.hasAutoTopup
            const hasCallBlocking = response.data.hasCallBlockingFeature
            const hasContactBook = response.data.hasContactBookFeature
            const autoTopupAmount = response.data.autoTopupAmount
            const hasUmr = response.data.hasUmr
            const hasShowReel = response.data.hasShowReelFeature
            const displayUmrUpsell = response.data.displayUmrUpsell
            const displayBillingWarning = response.data.displayBillingWarning
            const hasCallRecordingAll = response.data.hasCallRecordingAll
            const hasUnlimitedCallRecording = response.data.hasUnlimitedCallRecording
            const hasCallRecordingSingle = response.data.hasCallRecordingSingle
            const hasOutboundCalling = response.data.hasOutboundCalling
            const outboundCallingPackage = response.data.outboundCallingPackage
            const inOutboundBeta = response.data.isInOutboundBeta
            const hasVipCallerFeature = response.data.hasVipCallerFeature
            const hasOutboundCallRecording = response.data.hasOutboundCallRecording
            const hasNewCallBlockingFeature = response.data.hasNewCallBlockingFeature
            const hasEmergencyContacts = response.data.hasEmergencyContacts
            const displayOutstandingInvoiceWarning = response.data.displayOutstandingInvoiceWarning
            const displayUnfinishedSignupWarning = response.data.displayUnfinishedSignupWarning
            const signupSummary = response.data.signupSummary
            const hasCallTranscription = response.data.hasCallTranscription
            const hasTPSProtection = response.data.hasTPSProtection

            context.props.handleUpdateEnabledServices({
              mobileCredit: credit,
              hasAgreement,
              hasAutoTopup,
              autoTopupAmount,
              hasCallBlocking,
              hasContactBook,
              hasUmr,
              displayUmrUpsell,
              displayBillingWarning,
              hasShowReel,
              hasCallRecordingAll,
              hasUnlimitedCallRecording,
              hasCallRecordingSingle,
              hasOutboundCalling,
              outboundCallingPackage,
              isInOutboundBeta: inOutboundBeta,
              hasNewCallBlockingFeature,
              hasOutboundCallRecording,
              hasVipCallerFeature,
              displayOutstandingInvoiceWarning,
              hasEmergencyContacts,
              displayUnfinishedSignupWarning,
              signupSummary,
              hasCallTranscription,
              hasTPSProtection
            })
          } else {
            context.props.logout()
          }
        }).catch(error => {
          console.error(error)
        })
      }
    }

    window.setTimeout(function () {
      getServices()
    }, 2000)

    const secondsRefresh = 40
    const id = setInterval(getServices, secondsRefresh * 1000)
    context.setState({ timerId: id })
  }

  componentWillUnmount () {
    if (this.state) {
      const id = this.state.timerId
      clearInterval(id)
    }
  }
}

export default connect(
  state => {
    return {}
  }, {
    handleUpdateEnabledServices,
    logout
  }
)(RefreshUserServices)
