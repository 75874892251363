import React from 'react'
import { connect } from 'react-redux'

const AdvancedSettingsSection = props => (
    <div>{props.children}</div>
)

export default connect(
  state => {},
  dispatch => {}
)(AdvancedSettingsSection)
