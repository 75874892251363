import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import NumberLandingView from '../../components/NumberTypes/NumberLandingView'
import NumberTypeFeatures from '../../components/NumberTypes/NumberTypesFeaures'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import { isLoggedIn } from '../../selectors'

class Numbers0208 extends React.Component {
  static defaultProps = {}

  static propTypes = {}

  state = {}

  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            {/* <NumbersMenu /> */}
            <Helmet>
                <title>Buy 0208 London Numbers</title>
                <meta name="description" content="Purchase a 0208 London number. Get a London office presence without the expense! Instant setup with free virtual phone system." />
            </Helmet>

            <NumberLandingView
                h1="0208 London Numbers"
                h2="Get a rare central London business number."
                numberType={'0208'}
                history={this.props.history}
            />

            <div className="full-width bg-light">
                <div className="container pl-xxs-30 pr-xxs-30">
                    <div className="row">
                        <div className="col-lg-7 col-md-10 mx-auto" >
                            <div id="contentWrapper">
                                <p className="text-md pt-20 pt-xs-0 text-500 text-center">
                                        <i>
                                            From just <GetProdPrice prefix="0208" /> per month
                                        </i>
                                </p>
                                <p className="text-400 text-md text-primary mb-30 text-center">
                                    Instant activation <span className="pl-20 pr-20">&bull;</span> No hardware required <span className="pl-20 pr-20">&bull;</span> No long term contracts
                                </p>
                                <p className="pt-30 pt-xs-0 text-xs-left pb-xs-40">
                                    These rare and iconic 0208 numbers are recognised the world over and are associated with the very heart of London.  Give your business the prestige of operating from an exclusive central London location.
                                </p>
                                <p>
                                    There are very few of these available due to an increase in global demand, so grab one before they all go!
                                </p>
                                <p>
                                    Secure yourself a 0208 number today.
                                </p>

                                <p>
                                    <ul className="unstyled ml-0 pl-0">
                                        <li>
                                            <span className="text-cta icon icon-checkmark mr-10"></span>Exclusive central London location

                                            </li>
                                        <li>
                                            <span className="text-cta icon icon-checkmark mr-10"></span> Very rare!
                                            </li>
                                        <li>
                                            <span className="text-cta icon icon-checkmark mr-10"></span> Over 40 powerful free features
                                            </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NumberTypeFeatures history={this.props.history} />

            <div className="full-width bg-light pt-50 pb-50 mt-30">
                <div className="container">
                    <div className="row text-center features-list">
                        <div className="col-sm-4 pb-sm-30 br-1 br-sm-0">
                            <div className="text-center img-circle bg-dull block center-block" style={{ width: '100px', height: '100px' }}>
                                <SvgRender icon="apps" svgWidth="50px" svgClassName="mt-25" />
                            </div>
                            <p className="pt-20">Manage your calls on the move with <br className="d-block d-sm-none d-lg-block" />the free SwitchboardFREE app.</p>
                        </div>
                        <div className="col-sm-4 pb-sm-30 br-1 br-sm-0">
                            <div className="text-center img-circle bg-dull block center-block" style={{ width: '100px', height: '100px' }}>
                                <SvgRender icon="reporting-features" svgWidth="50px" svgClassName="mt-25" />
                            </div>
                            <p className="pt-20">Grow faster, with over 40 professional <br className="d-block d-sm-none d-lg-block" />free call handling features.</p>
                        </div>
                        <div className="col-sm-4 pb-sm-30">
                            <div className="text-center img-circle bg-dull block center-block" style={{ width: '100px', height: '100px' }}>
                                <SvgRender icon="config-changes" svgWidth="60px" svgClassName="mt-20" />
                            </div>
                            <p className="pt-20">Tailor your number settings to your unique <br className="d-block d-sm-none d-lg-block" />business, and manage easily 24/7.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width bg-dull pt-50">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>

            {/* Logoos */}
            <div className="full-width bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500">Partners &amp; Customers</h3>
                            {/* <h6>The UK's favourite call management system</h6> */}
                            <p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
                        </div>
                    </div>
                </div>
            </div>
            <ClientLogos style='light' />
        </>
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
  }
)(Numbers0208)
