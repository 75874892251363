import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import CallbackRequest from '../../components/CallbackRequest'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'
import { getFirstName } from '../../store/SBF'

class OutboundLandingPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoggedIn: !!((this.props.isLoggedIn || this.props.match.params.clientId)),
      firstName: this.props.userFirstName,
      outboundPrice: 0
    }
  }

  componentDidMount () {
    const api = 'api/Purchase/GetCheapestPackagePrice/'

    fetchHelper.getJson(api)
      .then(res => {
        this.setState({ outboundPrice: res.data.outbound })
      }).catch(err => console.error(err))
  }

  scrollToFAQ () {
    scroller.scrollTo('faq', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  scrollTo () {
    scroller.scrollTo('findoutmore', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter />  <DisplayMainHeader />
            {/* <HowWorksMenu /> */}
            <Helmet>
                <html itemScope itemType="https://schema.org/FAQPage"></html>
                <title>Business VoIP Phone Systems</title>
                <meta name="description" content="Discover the power of outbound calling. Make calls using your 084, 0800, 03, 020 or local city number." />.
                {/* inline style elements */}
                {/* {this.state.isLoggedIn &&
                    <style type="text/css">{`
                        body {
                            padding-top: 0 !important;
                        }
                    `}
                    </style>
                } */}
                <style type="text/css">{`
                .full-width ul.unstyled {
                    list-style-position: outside !important;
                }
                .full-width ul.unstyled li {
                    padding:5px 10px;
                    font-size:1em;
                }
                .full-width ul.unstyled li .bg-cta {
                    border-radius:50%;
                    height:25px;
                    width:25px;
                    display:inline-block;
                    text-align:center;
                    color:#fff;
                }
                .full-width ul.unstyled li .bg-cta .icon {
                    font-size:14px;
                    top:-1px;
                    position:relative;
                }
                    `}</style>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/10-competitor-bg.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="BusinessVoipPhoneSystemH1">Business VoIP Phone System</h1>
                <h2 className=" subtitle">Make outbound calls with our powerful cloud-based VoIP phone system from just &pound;{this.state.outboundPrice} a month!</h2>
            </header>

            <div className="full-width bg-light pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-10 mx-auto">
                            <div className="row">
                                <div className="col-md-6 text-center pb-50 br-5 br-sm-0">
                                    <h3 className="text-500">
                                        What is VoIP?
                                    </h3>
                                    <p className="text-400 pt-20">
                                        VoIP stands for Voice over Internet Protocol and to put it simply means making telephone calls over the internet rather than <br className="d-none d-md-block"/>traditional telephone line. Read more about <a href="/what-is-voip-phone-system" className="text-underline text-cta">what is VoIP</a>.
                                    </p>
                                </div>

                                <div className="col-md-6 text-center pb-50">
                                    <h3 className="text-500">
                                        What is a VoIP Phone System?
                                    </h3>
                                    <p className="text-400 pt-20">
                                        A VoIP phone system gives you everything a traditional phone system gives you but with one crucial difference: i&apos;s hosted in the cloud.
                                    </p>
                                </div>

                                <div className="col-md-12 text-center bt-5 bb-5 pb-60 mb-30 mt-30 pt-30">
                                    <h3 className="text-500">
                                        Get Started
                                    </h3>
                                    <p className="text-400 pt-20">
                                        Choose a virtual telephone number, choose a VoIP phone system and benefit from <br className="d-none d-md-block"/>SwitchboardFREE’s incredible call management system!
                                    </p>
                                    <a onClick={this.scrollTo} className="btn btn-lg btn-cta mt-30">Find out more</a>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light pt-0 pb-0" id="findoutmore">
                <div className="container bb-5 pb-50">
                    <div className="row">
                        <div className="col-md-6 pt-20 pt-sm-0 float-end">
                            <h3 className="text-500">
                                Work smarter with a SwitchboardFREE VoIP Phone System
                            </h3>
                            <p className="text-400 pt-20">
                                In 2015 BT announced their long-term plan to phase out ISDN (’normal’ phone lines), moving its services over to VoIP. The slow phase out is already underway with final-stage deactivation in 2025.
                                As a result of deactivation in 2025, ISDN lines will no longer work therefore you will need to consider an alternative.
                            </p>
                            <h3 className="text-500 pt-30">
                                Why you need a VoIP Phone System
                            </h3>
                            <p className="text-400 pt-20">
                                A VoIP phone system gives you everything a traditional phone system gives you but with one crucial difference: i&apos;s hosted in the cloud.
                            </p>
                        </div>
                        <div className="col-md-6 pt-40 pb-xs-0 pt-md-100">
                            <img src="/images/product-shots/devices.png" alt="SwitchboardFREE VoIP Devices" style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light pt-50 pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pb-60 mb-30">
                            <h3 className="text-500 pb-60">
                                Benefits of a VoIP Phone System
                            </h3>
                            <div className="row">
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-piggy-bank"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Save money</h5>
                                            <p>Operating costs for VoIP phone systems are significantly lower than traditional phone systems.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-equalizer3"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Management features</h5>
                                            <p>Call redirection; call queueing; adding departments; missed call and voicemail services; reporting and statistical capabilities.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-clipboard2"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Future-proof</h5>
                                            <p>Replacing your business telephone system with a VoIP phone system, you can be sure that your telephony is modern and doesn't risk becoming redundant.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-checkmark4"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Stable and reliable</h5>
                                            <p>VoIP telephone systems are stable and reliable. Cloud storage also means you can save important calls and voicemails forever.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-earth"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Accessibility</h5>
                                            <p>Wherever you are in the world you can receive your business calls and manage them effectively. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-weather-lightning2"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Disaster Recovery</h5>
                                            <p>Warehouse flooded? Office on fire? Can’t get to work? Work from anywhere and business can continue as usual.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-display4"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Portability</h5>
                                            <p>Using a VoIP network means you can enable mobile users as well as desk-based users to connect to your telephone system. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-lan"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Easy integration</h5>
                                            <p>VoIP phone systems can be easily integrated with your current systems for databasing, diary management, recording and reporting statistics.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-chart"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Increased productivity</h5>
                                            <p>Take business calls anywhere so that you can work whilst or instead of traveling; call outcomes can be linked to your databases/systems and call features to enhance your service.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-phone-wave"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">High call quality</h5>
                                            <p>Because VoIP uses your internet connection, it offers higher call quality when compared to alternatives. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-stats-growth2"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Scalability</h5>
                                            <p>VoIP systems allow you to add a line as you hire a new employee and remove lines in the case of downsizing. You only ever pay for what you need.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-headset"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Tech support</h5>
                                            <p>Some VoIP phone system companies offer free after-sales support. Many service providers are smaller UK businesses offering more personal and dependable support.  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 pb-30">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="icon-block mt-20 text-center">
                                                <span className="icon icon-phone"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-10 text-center text-xs-start pt-xs-20">
                                            <h5 className="text-500">Choice of hardware/software</h5>
                                            <p>You can have a deskphone; a DECT (Digital Enhanced Cordless Telecommunications) phone (cordless/wireless phone), have a ‘softphone’ on your laptop, use your mobile phone or a combination of any of the above!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center pt-30">
                                    <h3 className="text-500">
                                        Read our definitive guide to VoIP phone systems
                                    </h3>
                                    <Link className="btn btn-lg btn-cta mt-30" to={'blog/help-whats-a-voip-phone-system-how-does-it-work?id=1904'}>VoIP explained</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull pt-50 pb-0">
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                        <div className="col-sm-6 pt-20 pt-sm-0 float-end">
                            <h3 className="text-500">Transform your business</h3>
                            <p className="lead mb-20 text-400">
                                Take some time to consider how you use your current phone system - what do you like about it? What don’t you like? What does it lack?
                            </p>
                            <p className="mb-20">
                                Think about who within the business/company will use a wireless phone and where will they be based?  Are they static or mobile?  Think about how your phone needs might change over the next five years so that you can plan ahead and future-proof your phone system.  Once you have an idea of what you need you will be better placed to choose a VoIP phone system to meet your requirements.
                            </p>
                            <p className="mb-30">
                                Our Business VoIP Phone System comes with a host of incredible features that are guaranteed to transform your business and simplify the way you work!  Our cloud phone system is easy to set up, low cost and will give you the flexibility and features required to optimise your business.  With a range of wired and wireless HD quality handsets, headsets, softphones and mobile apps, we’re able to tailor the system to suit any size of business.
                            </p>
                            <p className="pb-30">
                                <Link to={'/phones-apps'} className="btn btn-lg btn-cta">See all devices</Link>
                            </p>
                        </div>
                        <div className="col-sm-6 pt-40 pb-xs-0 pt-md-100">
                            <img src="/images/product-shots/fanvil-x4u.png" alt="Transform your business using SwitchboardFREE" style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width pt-xs-20 pb-0" style={{ backgroundColor: '#d6e8ef' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 pt-100 pt-md-20">
                            <h3 className="text-500">Call from anywhere in the world</h3>
                            <p className="lead text-400 pt-10">
                                Tired of expensive call charges when you’re away on business or on holiday?
                            </p>
                            <p className="mb-20">
                                With our App, you’re able to make low cost calls from anywhere in the world whilst displaying your virtual number. You can redirect all of your incoming calls directly to the app too.
                            </p>
                            <p className="mb-30">
                                For more information on the app check our <Link to={'phones-apps'}>apps and devices page</Link>.
                            </p>
                            <p className="pb-sm-30">
                                <Link to={'phones-apps'} className="btn btn-lg btn-cta">See all devices</Link>
                            </p>
                        </div>
                        <div className="col-sm-6 pt-40 pb-xs-0">
                            <img className="img-circle bg-light" src="/images/voip-system-travel.png" alt="Travel anywhere with SwitchboardFREE" style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull pt-50 pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 pt-40 pb-xs-0">
                            <img src="/images/smile-receptionist.png" className="img-circle bg-light" alt="UK Support" style={{ maxWidth: '100%' }} />
                        </div>
                        <div className="col-md-6 pt-20 pt-sm-0 float-end align-self-end">
                            <h3 className="text-500">What are you waiting for?</h3>
                            <p className="lead text-400 pt-10">
                                Set up is easy and our UK Welcome Team are on-hand to assist you every step of the way.
                            </p>

                            <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30">
                                <li>
                                    Make and receive phone calls from anywhere in the world, at UK call rates
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                    Present any of your virtual telephone numbers as your outbound number
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                    Have as many users (extensions) as you wish
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                    Free transfer between users in any location worldwide
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                    Over 40 call management features
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                    Easy to use mobile phone app, ‘plug and play’ desktop phone, or softphone
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                    Professional, HD call clarity.
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                    Fantastic UK based support team, and more
                                </li>
                            </ul>
                            <p className="pb-30">
                                <Link to={'/number-types'} className="btn btn-lg btn-cta">Get your number</Link>
                            </p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="full-width bg-secondary pt-50 pb-60 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-lightest">
                            <h3 className="text-lightest text-500 pb-30">Still have questions?</h3>
                            <p>
                                <span className="text-500 text-md  d-sm-none d-block">Give us a call</span>
                                <a href="tel:02031891213" className="text-400 underline">0203 189 1213</a>

                                <span className="pl-30 pr-30 d-none d-sm-inline">/</span>

                                <div className="d-sm-none d-block p-20"></div>

                                <span className="text-500 text-md  d-sm-none d-block">Let&apos;s have a chat</span>
                                <a className="open-live-chat text-400 underline">Live Chat</a>

                                <span className="pl-30 pr-30  d-none d-sm-inline">/</span>

                                <div className="d-sm-none d-block p-20"></div>

                                <span className="text-500 text-md d-sm-none d-block">Schedule a callback</span>
                                <CallbackRequest buttonSize="sm" buttonStyle="primary" buttonText="Request Callback" textLink={true} className="text-400 underline" />
                                <span className="pl-30 pr-30 d-none d-sm-inline">/</span>

                                <div className="d-sm-none d-block p-20"></div>

                                <span className="text-500 text-md d-sm-none d-block">Try our FAQ</span>
                                <a className="text-400 underline" onClick={this.scrollToFAQ}>FAQ</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light pt-50 pb-50" id="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <h3 className="text-center pb-30 text-500">Here’s a quick FAQ</h3>
                            <Accordion>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="0">
                                    <Accordion.Header toggle className="text-start text-400" itemprop="name">
                                        What is the best VoIP phone system?
                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30" itemprop="text">
                                            The best VoIP system is one that will suit your needs the most; one that is scalable as your business changes and one that meets your business budget.
                                            SwitchboardFREE is a UK based company with good reviews, can guide you through the selection and migration process and can offer you reliable after-sales support.
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="1">
                                    <Accordion.Header toggle className="text-400 text-start" itemprop="name">
                                        How much is VoIP monthly?
                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30" itemprop="text">
                                            This varies depending on the size of your company (how many users), what you need from a VoIP phone system and what package of minutes you need.  Prices start from £5 per month.
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="2">
                                    <Accordion.Header toggle className="text-400 text-start" itemprop="name">

                                            Can I use a normal phone?

                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30" itemprop="text">
                                            You can although this is not the best solution and can take a bit of technical know-how to tinker with the phone, cables, adapters and set-up.  VoIP phones can be purchased relatively reasonably and are worth the investment.  Also consider that you will get more than just the handset.  SwitchboardFREE offer competitively priced phones which will be configured for you - all you do is plug and play.  You will also have a dedicated support phone line for any queries or questions you may have and of course all of the call management features to enhance your business communications as a whole
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="3">
                                    <Accordion.Header toggle className="text-400 text-start" itemprop="name">

                                            What do I need for a VoIP phone system?

                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30" itemprop="text">
                                            It depends whether you want basic and low-budget or something a bit more robust.  SwitchboardFREE can supply your complete VoIP phone system (telephone number, call management system and telephone).  With your phone system you can use a PC, a laptop, a tablet or your smartphone and, you might need a headset if you don’t choose a desk phone or a wireless/cordless phone.  You’ll also (of course!) need a good broadband connection.
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="4">
                                    <Accordion.Header toggle className="text-400 text-start" itemprop="name">

                                            Do cordless phones work with VoIP?

                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30" itemprop="text">
                                            Yes they do. An ordinary cordless phone might be tricky to configure and the call quality may not be great so try and purchase a DECT wireless phone.  A DECT phone will be built to work seamlessly with VoIP and will continue to offer the great benefits of going cordless such as freedom of movement and the ability to take a call wherever you are.
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="5">
                                    <Accordion.Header toggle className="text-400 text-start" itemprop="name">

                                            What are the disadvantages of VoIP?

                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30" itemprop="text">
                                            By the very nature of the beast, VoIP phone systems rely on the internet for functionality. Therefore you will need to have decent broadband capacity and a reliable broadband provider to ensure good call quality and good service connection.  You may wish to consider a dedicated business broadband service to minimise these risks to keep the calls flowing and your business functioning. That’s about it for disadvantages - the advantages far outweigh the disadvantages!
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>
        </>
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      userFirstName: getFirstName(state)
    }
  }
  // {
  //     login: fromHome.handleLogin
  // }
)(OutboundLandingPage)
