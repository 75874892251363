import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { productBillingOption, ProductIds, TpsProtectionPerUserCharge } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { type ProductDetails } from '../../models/GenericTypes'
import { type PurchaseResponseDto, type PurchaseTPSProtectionDto, type ReduxState } from '../../models/SignupTypes'
import { type OutboundEndpoint } from '../../models/UserTypes'
import { getProductPrice } from '../../store/Home'
import * as sbf from '../../store/SBF'
import { handleNotificationChange } from '../../store/SBF'

interface EnableTpsProtectionModalProps {
  showModal: boolean
  toggleShowTpsModal: (toggle: boolean) => void
  retrieveEndpoints?: () => void
  handleTPSProtectionEnabledChange: (obj: boolean) => void
  notify: (obj: any) => void
  getProductDetails: (p: Array<{}>) => void
  productDetails: ProductDetails[]
  showTpsInfo: boolean
  showPricingInfo: boolean
}

const EnableTpsProtectionModal = (props: EnableTpsProtectionModalProps) => {
  const [isPurchaseInProgress, setIsPurchaseInProgress] = useState(false)
  const [endpoints, setEndpoints] = useState<OutboundEndpoint[]>([])

  useEffect(() => {
    const url = '/api/Outbound/RetrieveOutboundEndpoints'
    fetchHelper.getJson<OutboundEndpoint[]>(url)
      .then(res => {
        const endpoints = res.data
        setEndpoints(endpoints.filter(f => !f.tpsProtectionEnabled).sort((a, b) => a.id - b.id))
      }).catch(err => { console.error(err) })

    props.getProductDetails([{ productId: ProductIds.tpsProtection, monthlyOption: productBillingOption.tpsProtectionMonthly }])
  }, [])

  const handleToggleAll = (val: boolean) => {
    const newArr = [...endpoints]

    newArr.forEach(ep => {
      ep.tpsProtectionEnabled = val
    })

    setEndpoints(newArr)
  }

  const handleToggle = (i: number, val: boolean) => {
    const newArr = [...endpoints]
    newArr[i].tpsProtectionEnabled = !val
    setEndpoints(newArr)
  }

  const handlePurchase = () => {
    setIsPurchaseInProgress(true)
    const url = 'api/Purchase/PurchaseTPSProtection'

    const eps: number[] = []

    if (endpoints.length > 1) {
      endpoints.forEach((ep) => {
        if (ep.tpsProtectionEnabled) {
          eps.push(ep.id)
        }
      })
    } else {
      eps.push(endpoints[0].id)
    }

    const data = {
      EndpointIds: eps
    }

    fetchHelper.postJson<PurchaseResponseDto, PurchaseTPSProtectionDto>(url, data)
      .then(res => {
        setIsPurchaseInProgress(false)

        if (!res.data.isSuccess) {
          props.notify({ message: 'Error purchasing TPS Protection', isError: true })
        } else {
          if (props.retrieveEndpoints !== null) {
            // @ts-expect-error
            props.retrieveEndpoints()
          }

          props.handleTPSProtectionEnabledChange(true)
          props.toggleShowTpsModal(false)
          props.notify({ message: 'TPS Protection has been purchased' })
        }
      }).catch(err => { console.error(err) })
  }

  return (
        <>
            <Modal size="sm" show={props.showModal} onHide={() => { props.toggleShowTpsModal(false) }} backdrop="static">
                <Modal.Header closeButton={!isPurchaseInProgress}>
                    <Modal.Title>Enable TPS Protection</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-20">
                    {props.showTpsInfo &&
                        <>
                            <p>Protect your company from being fined for calling numbers on the TPS by restricting your users from calling TPS registered phone numbers.</p>
                            {/* <p>&pound;{(props.productDetails[0].monthlyPrice.repeatPaymentAmount).toFixed(2)} per month. The cost per user is &pound;{TpsProtectionPerUserCharge} per month.</p> */}

                            <p>Find out more about TPS Protection <a className="text-cta underline" href="/features/tps-protection" target="_blank">here</a>.</p>
                        </>
                    }

                    {props.showPricingInfo && props.productDetails && (
                        <>
                            {endpoints.filter(f => f.tpsProtectionEnabled) && (
                                <p>The cost to activate TPS Protection on your account is
                                    &pound;{(props.productDetails[0].monthlyPrice.repeatPaymentAmount).toFixed(2)} per month. The cost per user is &pound;{TpsProtectionPerUserCharge} per month.
                                </p>
                            )}
                        </>
                    )}

                    {endpoints && endpoints.length === 1
                      ? (
                        <>
                            <p className="lead text-400 text-center">Great, let's get TPS Protection enabled on your account.</p>
                            <p className="text-center">Add it to your account today, for just &pound;{props.productDetails[0].monthlyPrice.repeatPaymentAmount + TpsProtectionPerUserCharge} per month</p>
                            <p className="text-center mt-20">
                                <Button variant="cta" size="lg" className="btn-xs-block" onClick={() => { handlePurchase() }} disabled={isPurchaseInProgress}>
                                    Upgrade Now
                                </Button>
                            </p>
                        </>
                        )
                      : (
                        <>
                            <p>
                                It looks like you've got multiple users with us. To prevent potential fines from the ICO, we highly recommend enabling the feature for all users who make outbound calls. This will guarantee that your users do not make calls to individuals registered with the TPS.
                            </p>

                            <div className="mt-30 bb-1-dark p-20">
                                <div className="row">
                                    <div className="col-xs-6 pl-0">
                                        <p className="text-500 m-0">User/Ext</p>
                                    </div>
                                    <div className="col-xs-6 text-end">
                                        <p className="text-500 m-0 text-end">TPS enabled</p>
                                    </div>
                                </div>
                            </div>
                            <div className="numbers-scroller mb-10 bb-1">
                                {endpoints &&
                                    endpoints.map((ep, i) => (
                                        <div key={i} className="number bg-light bb-1-dark p-20 dull-hover cursor-pointer" onClick={() => { handleToggle(i, ep.tpsProtectionEnabled) }} >
                                            <div className="row">
                                                <div className="col-xs-6 pl-0">
                                                    <p className="lead text-400 p-0 m-0">
                                                        Ext. {ep.extNumber}
                                                        <br />
                                                    </p>
                                                    <p className="text-sm mb-0 text-dullest">{ep.extName}</p>
                                                </div>
                                                <div className="col-xs-6 text-end pr-0">
                                                    <span className="styled-checkbox checkbox-light checkbox-lg checkbox-full">
                                                        <Form.Check inline className="mt-neg-15" checked={ep.tpsProtectionEnabled} onChange={() => {}} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <Button variant="link" size="sm" className="underline text-400 text-secondary"
                                onClick={() => { handleToggleAll(!(endpoints && endpoints.every(a => a.tpsProtectionEnabled))) }}>

                                {endpoints && endpoints.every(a => a.tpsProtectionEnabled) ? 'Deselect All' : 'Select All'}
                            </Button>
                        </>
                        )}
                </Modal.Body>
                {endpoints && endpoints.length > 1 && (
                    <Modal.Footer className="text-xs-center">
                        <div className="w-100">
                            {endpoints.filter(f => f.tpsProtectionEnabled).length > 0 && (
                                <>
                                    <p className="lead text-400 text-center">
                                        You've selected {endpoints.filter(f => f.tpsProtectionEnabled).length} {endpoints.filter(f => f.tpsProtectionEnabled).length > 1 ? 'users' : 'user'}
                                        <br />Upgrade today for &pound;{(props.productDetails[0].monthlyPrice.repeatPaymentAmount + endpoints.filter(f => f.tpsProtectionEnabled).length * TpsProtectionPerUserCharge).toFixed(2)} pm
                                    </p>
                                </>
                            )}
                        </div>

                        <Button variant="cta" className="btn-block btn-lg" onClick={() => { handlePurchase() }} disabled={isPurchaseInProgress || endpoints.filter(f => f.tpsProtectionEnabled).length === 0}>
                            Upgrade Now
                        </Button>
                        <Button className="mt-20 mb-30 btn-link btn-transparent text-dark text-sm" onClick={() => { props.toggleShowTpsModal(false) }} disabled={isPurchaseInProgress}>
                            Close this window
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
  )
}

export default connect(
  (state: ReduxState) => {
    return {
      hasTps: state.sbfApp.enabledServices.hasTPSProtection,
      productDetails: state.home.productDetails.products
    }
  },
  {
    notify: handleNotificationChange,
    handleTPSProtectionEnabledChange: sbf.handleTPSProtectionEnabledChange,
    getProductDetails: getProductPrice
  }
)(EnableTpsProtectionModal)
