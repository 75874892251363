import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'

const MainNumberDescription = props => (
    <div className="row bg-light p-30 zoom">
        <div className="col-md-5">
            <h5 className="text-secondary text-500 text-lg">Main Number Description
                {/* <button type="button" className="btn btn-default"
                        onClick={props.handleShowMainNumberHelperInstruction}>
                    <span className="glyphicon glyphicon-info-sign text-xxs" aria-hidden="true"/>
                </button> */}
            </h5>

            <p className="text-400">Enter a description for your SwitchboardFREE number to help distinguish it on reports and missed call alerts.</p>
        </div>
        {/* <MainNumberDescriptionHelperDialog/> */}
        <div className="col-md-7 pt-30">
            <input
                type='text'
                className="form-control"
                id="MainNumberDescriptionInput"
                onBlur={e => props.handleMainNumberDescriptionNoFocus(props.globalDescription)}
                onFocus={e => props.handleMainNumberDescriptionHasFocus(props.globalDescription)}
                value={props.globalDescription} maxLength='64'
                onChange={e => props.handleChangeGlobalDescription(e.target.value)}
            />
        </div>
    </div>
)

const mapStateToProps = state => ({
  globalDescription: state.configuration.configuration.globalDescription
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainNumberDescription)
