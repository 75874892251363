import React, { Component } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { RequestAuthorizationCode } from 'react-oauth2-auth-code-flow'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchHelper } from '../../../helpers/fetchHelper'
import OauthHelper from '../../../helpers/OauthHelper'
import { handleNotificationChange } from '../../../store/SBF'
import CrmTab from './CrmSettingsTabs/CrmTab'

const _ = require('lodash')
class CrmSettings extends Component {
  state = {
    isLoading: true,
    crmList: [],
    defaultTabKey: 1
  }

  componentDidMount () {
    const url = '/api/GenericCrm/GetClientCrms'
    fetchHelper.getJson(url).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        const section = res.data[i].name.toLowerCase().replaceAll(' ', '-')

        if (window.location.href.toLowerCase().indexOf(section) > -1) {
          this.setState({ defaultTabKey: res.data[i].crmId })
          break
        }
      }

      this.setState({
        crmList: res.data,
        isLoading: false
      })
    })
  }

  render () {
    const crmList = this.state.crmList
    const oauthClient = OauthHelper.GetCapsuleCrmOauthClient()
    const oauthClientPipeDrive = OauthHelper.GetPipeDriveCrmOauthClient()
    const oauthUrlHubspot = OauthHelper.GetHubSpotCrmOauthUrl()

    return (
            <div className="bg-light p-30 pb-40 brad-10 b-1 w-800px center-block mb-20">
                <div className="text-center" >
                        {crmList.length === 0
                          ? <>
                                <h4 className='text-400 pb-20'>No active CRM's found...</h4>
                                <div className='text-start'>
                                    <p>If you have an account with the below CRM services, then why not integrate them with your SwitchboardFREE's Contact Book?</p>
                                    <p>Once you connect your CRM account with SwitchboardFREE, all of your CRM contacts are quickly and easily accessible across all your devices, that is your SwitchboardFREE Phone app, Softphone and of course our phones.</p>
                                    <p>Received calls, missed calls and voicemails are all fed directly into your CRM system.</p>
                                </div>
                                <div className='row'>
                                    <div className='col-xs-12'>
                                        <h5 className='text-500 text-start bt-1 pb-20 mt-30 pt-30'>Available CRM's</h5>
                                        <RequestAuthorizationCode
                                            oauthClient={oauthClient}
                                            render={({ url }) =>
                                            <>
                                                <div className="w-31 w-xs-47 w-xxs-100 b-1 p-20 brad-20 bg-light mb-20 float-start ml-5 ml-xs-0 mr-10 box-shadow zoom">
                                                    <div className="col-xs-12 pl-0">
                                                        <img src="/images/logo/CapsuleCrm.png" />
                                                    </div>
                                                    <div className="col-xs-12 pt-15 pt-xxs-0 pl-xxs-0">
                                                        <span className="text-500">Capsule CRM</span>
                                                    </div>
                                                    <div className="col-xs-12 pt-15">
                                                        <a href={url} className="btn btn-xs btn-cta">Connect</a>
                                                    </div>
                                                </div>
                                            </>
                                        }/>

                                        <RequestAuthorizationCode
                                            oauthClient={oauthClientPipeDrive}
                                            render={({ url }) =>
                                            <>
                                                <div className="w-31 w-xs-47 w-xxs-100 b-1 p-20 brad-20 bg-light mb-20 float-start ml-5 ml-xs-0 mr-10 box-shadow zoom">
                                                    <div className="col-xs-12 pl-0">
                                                        <img src="/images/logo/pipedrive-crm.png" />
                                                    </div>
                                                    <div className="col-xs-12 pt-15 pt-xxs-0 pl-xxs-0">
                                                        <span className="text-500">Pipedrive CRM</span>
                                                    </div>
                                                    <div className="col-xs-12 pt-15">
                                                        <a href={url} className="btn btn-xs btn-cta">Connect</a>
                                                    </div>
                                                </div>
                                            </>
                                        } />

                                        <RequestAuthorizationCode
                                            oauthClient={oauthClientPipeDrive}
                                            render={({ url }) =>
                                            <>
                                                <div className="w-31 w-xs-47 w-xxs-100 b-1 p-20 brad-20 bg-light mb-20 float-start ml-5 ml-xs-0 mr-10 box-shadow zoom">
                                                    <div className="col-xs-12 pl-0">
                                                        <img src="/images/logo/hubspotCrmLogo.png" />
                                                    </div>
                                                    <div className="col-xs-12 pt-15 pt-xxs-0 pl-xxs-0">
                                                        <span className="text-500">Hubspot CRM</span>
                                                    </div>
                                                    <div className="col-xs-12 pt-15">
                                                        <a href={oauthUrlHubspot} className="btn btn-xs btn-cta">Connect</a>
                                                    </div>
                                                </div>
                                            </>
                                        } />
                                    </div>
                                </div>
                            </>

                          : <>
                        <Tabs
                            defaultActiveKey={crmList.length === 0 ? 0 : crmList[0].crmId}
                        >
                            {crmList.map((crm) => {
                              return <Tab
                                eventKey={crm.crmId}
                                title={crm.name}>
                                    <div className="text-left" >
                                        <CrmTab crmId={crm.crmId} />
                                    </div>
                                </Tab>
                            })}
                        </Tabs>
                        </>
                     }
                </div>
            </div>
    )
  }
}

export default connect(state => {
}, { notify: handleNotificationChange })(withRouter(CrmSettings))
