import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { isValidEmailSyntax, isValidNameSyntax, isValidTelSyntax } from '../../../helpers/validationHelpers'
import { handleNotificationChange } from '../../../store/SBF'

class BillingDetails extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    title: 'Mr',

    firstName: '',
    firstNameValid: false,

    lastName: '',
    lastNameValid: false,

    contactNumber: '',
    contactNumberValid: false,

    email: '',
    emailValid: false,

    confirmEmail: '',
    confirmEmailValid: false
  }

  componentDidMount () {
    this.handleChangeFirstName(this.props.billingFirstName)
    this.handleChangeLastName(this.props.billingSurname)
    this.handleChangeContactNumber(this.props.billingPhoneNumber)
    this.handleChangeEmail(this.props.billingEmail)
    this.handleChangeConfirmEmail(this.props.billingEmail)
    this.setState({
      title: this.props.billingTitle,
      confirmEmailValid: this.props.billingEmail !== undefined && this.props.billingEmail !== ''
    })
  }

  handleChangeFirstName (value) {
    const isValid = isValidNameSyntax(value)

    this.setState({
      firstName: value,
      firstNameValid: isValid
    })
  }

  handleChangeLastName (value) {
    const isValid = isValidNameSyntax(value)

    this.setState({
      lastName: value,
      lastNameValid: isValid
    })
  }

  handleChangeContactNumber (value) {
    const isValid = isValidTelSyntax(value)

    this.setState({
      contactNumber: value,
      contactNumberValid: isValid
    })
  }

  handleChangeEmail (value) {
    const isValid = isValidEmailSyntax(value)

    this.setState({
      email: value,
      emailValid: isValid,
      confirmEmailValid: value === this.state.confirmEmail && isValid
    })
  }

  handleChangeConfirmEmail (value) {
    const isValid = value === this.state.email && this.state.emailValid

    this.setState({
      confirmEmail: value,
      confirmEmailValid: isValid
    })
  }

  handleSaveChangesPressed () {
    const url = '/api/Profile/SaveBillingAddress'

    const data = {
      billingFirstName: this.state.firstName,
      billingSurname: this.state.lastName,
      billingTitle: this.state.title,
      billingEmail: this.state.email,
      billingPhoneNumber: this.state.contactNumber
    }

    fetchHelper.postJson(url, data).then(res => {
      const data = res.data

      if (data.hasErrors) {
        this.props.notify({
          message: 'Unable to save, please correct and try again',
          isError: true
        })
      } else {
        this.props.notify({ message: 'Billing details saved' })
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  render () {
    const invalidBorderStyling = '2px solid #e84118'

    return (
            <>
                <div className="bg-light p-30 pb-40 brad-10 b-1 w-100 center-block mb-20">
                    <h4 className="pb-20 text-400 text-center">Billing Details</h4>
                    <p className="text-center mt-0 mb-30">These are the details that we'll use to send your invoices and payment notifications to.</p>
                    <div className="row">
                        <div className="col-xs-6 col-xxs-12">
                        <label>Title</label>
                        <select className="form-control" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} id="BillingTitleDropdown">
                            <option>Mr</option>
                            <option>Mrs</option>
                            <option>Miss</option>
                            <option>Dr</option>
                            <option>Mx</option>
                        </select>
                    </div>
                    <div className="col-xs-6 col-xxs-12">
                        <label>First name</label>
                        <input id="BillingFirstName" type="text" className="form-control" style={{ border: this.state.firstNameValid === true ? '' : invalidBorderStyling }} placeholder="eg. John" value={this.state.firstName} onChange={(e) => this.handleChangeFirstName(e.target.value)}/>
                    </div>
                    <div className="col-xs-6 col-xxs-12">
                        <label className='pt-20'>Surname</label>
                        <input id="BillingSurname" type="text" className="form-control" style={{ border: this.state.lastNameValid === true ? '' : invalidBorderStyling }} placeholder="eg. Smith" value={this.state.lastName} onChange={(e) => this.handleChangeLastName(e.target.value)}/>
                    </div>
                    <div className="col-xs-6 col-xxs-12">
                        <label className='pt-20'>Contact number</label>
                        <input id="BillingContactNumber" type="text" className="form-control" style={{ border: this.state.contactNumberValid === true ? '' : invalidBorderStyling }} placeholder="eg. 07445605577" value={this.state.contactNumber} onChange={(e) => this.handleChangeContactNumber(e.target.value)}/>
                    </div>
                    <div className="col-xs-6 col-xxs-12">
                        <label className='pt-20'>Email</label>
                        <input id="BillingEmail" type="email" className="form-control" style={{ border: this.state.emailValid === true ? '' : invalidBorderStyling }} placeholder="eg. john@example.com" value={this.state.email} onChange={(e) => this.handleChangeEmail(e.target.value)}/>
                    </div>
                    <div className="col-xs-6 col-xxs-12">
                        <label className='pt-20'>Confirm email</label>
                        <input id="BillingConfirmEmail" type="email" className="form-control" style={{ border: this.state.confirmEmailValid === true ? '' : invalidBorderStyling }} placeholder="eg. john@example.com" value={this.state.confirmEmail} onChange={(e) => this.handleChangeConfirmEmail(e.target.value)}/>
                    </div>
                    <div className="col-xs-12 text-center pt-30">
                        <Button id="SaveBillingDetailsButton" variant="cta" type='submit' size="sm" disabled={!this.state.firstNameValid || !this.state.lastNameValid || !this.state.contactNumberValid || !this.state.emailValid || !this.state.confirmEmailValid} onClick={() => this.handleSaveChangesPressed()}>Save Billing Details</Button>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

/*

*/

export default connect(state => {
}, { notify: handleNotificationChange })(BillingDetails)
