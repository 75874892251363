import { FirstNameLimit, LastNameLimit, EmailLimit, PhoneTypes } from '../constants/Constants'
import { checkNumberType } from './utils'
import { fetchHelper } from './fetchHelper'
import { type AxiosResponse } from 'axios'
import moment from 'moment'

const redirectRegex = /^(((\+|00)(44)|(44)|(0)|)(1|2|7)([0-9]{9}))/
const redirectSameDigitRegex = /(.)\1{9,}/
const invalidRedirectCharacterRegex = /[^\d\s+]/
const emailRegex = /^[+\w-'\.]+@([\w-']+\.)+[\w-]{2,}$/
const ukLandlineRegex = /^(?:\+44\s?|0)[1238]\d\s?(?:\d\s?){7,8}$/
const ukMobileRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?|44\s?\d{4})\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/
const validTelRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
const postcodeRegex = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/
const isDigitRegex = /^([0-9]+)$/

function isDigit (val: string) {
  return isDigitRegex.test(val)
}

function isValidRedirectRegex (redirect: string) {
  redirect = redirect.replace(/\s/g, '')
  if (redirectSameDigitRegex.test(redirect)) {
    return false
  }

  return redirectRegex.test(redirect)
}

function isValidEmailSyntax (email: string) {
  if (!email) {
    return true
  }

  if (email.length === 0) { return false } // Too short

  if (email.length > EmailLimit) { return false } // Too long

  const emails = email.split(';')

  for (let i = 0; i < emails.length; i++) {
    if (!emailRegex.test(emails[i])) {
      return false
    }
  }

  return true
}

function isValidEmailCslSyntax (email: string) {
  if (!email) {
    return false
  }

  if (email.length === 0) { return false } // Too short

  if (email.length > EmailLimit) { return false } // Too long

  const emails = email.split(',')

  for (let i = 0; i < emails.length; i++) {
    if (!emailRegex.test(emails[i].trim())) {
      return false
    }
  }

  return true
}

function isInvalidRedirectCharacter (redirect: string) {
  return invalidRedirectCharacterRegex.test(redirect)
}

function isValidMobSyntax (mobile: string) {
  return ukMobileRegex.test(mobile)
}

function isValidLandlineSyntax (landline: string) {
  return ukLandlineRegex.test(landline)
}

function isValidNameSyntax (string: string, field?: 'firstname' | 'lastname') {
  if (string === undefined || string === null) {
    return false
  }

  if (!string) { return false }

  if (field && field === 'firstname' && string.length > FirstNameLimit) { return false } // Too long

  if (field && field === 'lastname' && string.length > LastNameLimit) { return false } // Too long

  if (!field && string.length > (FirstNameLimit + LastNameLimit)) { return false } // Too long

  return nameRegex.test(string)
}

function isValidTelSyntax (tel: string) {
  if (tel === undefined || tel === null) {
    return false
  }

  return validTelRegex.test(tel)
}

function isValidUrlSyntax (url: string) {
  return urlRegex.test(url)
}

function isValidPostcode (postcode: string) {
  return postcodeRegex.test(postcode)
}

function isValidCardNumber (number: string) {
  return true // Unused atm
}

function isValidExpiryDate (date: string) {
  const month = date.substring(0, 2)
  const year = 20 + date.substring(2)
  const expiry = moment(`${year}-${month}-${moment().format('D')}`)

  if (expiry.isBefore(moment())) { return false }

  return true
}

function isValidCardCode (code: string, type: string) {
  if (type === 'amex') {
    if (code && code.length === 4) { return true } else { return false }
  }

  if (code && code.length === 3) { return true }

  return false
}

export async function validatePhoneNumber(tel: string): Promise<AxiosResponse<boolean>> {
  const url = 'api/Users/isValidTelephone'
  const numberType = checkNumberType(tel)
  return await fetchHelper.postJson(url, { phoneNumber: tel, landline: numberType === PhoneTypes.landline })
}


export {
  isValidRedirectRegex, isInvalidRedirectCharacter, isValidEmailSyntax, isValidEmailCslSyntax, isValidMobSyntax, isValidNameSyntax, isValidLandlineSyntax, isValidTelSyntax, isValidUrlSyntax, isValidPostcode, isValidCardNumber, isValidExpiryDate, isValidCardCode, isDigit
}
