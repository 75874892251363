import moment from 'moment'
import 'rc-time-picker/assets/index.css'
import TimePicker from 'rc-time-picker/lib'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'

const BespokeOpenCloseTimesPicker = props => {
  const timePickerValue = moment(props.time, 'HH:mm')
  return (
        <TimePicker className="form-control" showSecond={false} focusOnOpen={true} value={timePickerValue.isValid() ? timePickerValue : ''} onChange={e => props.changeOpenCloseTime({ day: props.day, time: e.format('HH:mm') })} />
  )
}

export default connect(
  state => state.configuration,
  dispatch => bindActionCreators(configurationActions, dispatch)
)(BespokeOpenCloseTimesPicker)
