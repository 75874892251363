import React, { Component } from 'react'
import Slider from 'react-slick'

export default class Testimonials extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    activeReview: 0
  }

  featuredReviews = [
    {
      review: 'It makes a huge difference to work with partners and suppliers who understand the needs of a small business, and in this regard SwitchboardFREE is a great example so I’m happy to recommend their services whenever I can.',
      name: 'Chris Williams',
      title: 'Technical Director',
      company: 'Imaginative Software Ltd',
      img: '/images/reviews/chris-w.jpg',
      logo: '/images/reviews/logos/imaginative.jpg',
      url: 'www.imaginativesoftware.co.uk'
    },
    {
      review: 'Well!!! What can I say........simply outstanding service. I would 100% without any hesitation recommend this company.',
      name: 'Connor Glass',
      title: 'Director',
      company: 'Hillhead Security',
      img: '/images/reviews/connor-g.jpg',
      logo: '/images/reviews/logos/hillhead.jpg',
      url: 'www.hillheadsecurity.com'
    },
    {
      review: 'This is a great service all round! The tech works like a dream, super-professional inbound impression of my business, coupled with top drawer customer support, it is a real winner.',
      name: 'Toby Turner',
      title: 'Director',
      company: 'Juice Commercial Finance',
      img: '/images/reviews/toby-t.jpg',
      logo: '/images/reviews/logos/juice.jpg',
      url: 'www.juicecommercial.com'
    },
    {
      review: 'SwitchboardFREE have been excellent from the first moment I contacted them. I totally recommend them for both their services and their customer service team.',
      name: 'David Conrich',
      title: 'Director',
      company: 'France Skiing',
      img: '/images/reviews/david-c.jpg',
      logo: '/images/reviews/logos/france.jpg',
      url: 'www.france-skiing.net'
    },
    {
      review: 'Very good service.',
      name: 'Lloydie Gardiner',
      title: 'Holiday Experience Specialist',
      company: 'Not Just Travel',
      img: '/images/reviews/lloydie-g.jpg',
      logo: '/images/reviews/logos/travel.jpg',
      url: 'www.notjusttravel.co.uk'
    },
    {
      review: 'The solution is brilliant for a small business, and the people are even better. We knew we were in good hands from the first moment.',
      name: 'Gavin Casey',
      title: 'Director',
      company: 'Index Property Information',
      img: '/images/reviews/gavin-c.jpg',
      logo: '/images/reviews/logos/index.jpg',
      url: 'www.indexpi.co.uk'
    },
    {
      review: 'Great idea - great service and a great advert for a small business to give the professional impression!',
      name: 'Neil Mathias',
      title: 'Director',
      company: 'Compliance CRG',
      img: '/images/reviews/neil-m.jpg',
      logo: '/images/reviews/logos/compliance.jpg',
      url: 'www.complianceCRG.com'
    },
    {
      review: 'SwitchboardFREE is the best thing for my business, if I miss calls or a message I get an email and the recording left! The system sounds professional and fab music on-hold, definitely a must for any business!',
      name: 'Justine Conway',
      title: 'Owner',
      company: 'Paparazzi Hire',
      img: '/images/reviews/justine-c.jpg',
      logo: '/images/reviews/logos/paparazzi.jpg',
      url: 'www.hirepaparazzi.co.uk'
    },
    {
      review: 'I\'ve been using SwitchboardFree for many years now and have found the service to be invaluable for my small business. The ability to have a dedicated telephone number that has a switchboard with office hours set to it, helps to keep my work and private life separate.',
      name: 'Mark Jones',
      title: 'Owner',
      company: 'MDJ Creative',
      img: '/images/reviews/mark-j.jpg',
      logo: '/images/reviews/logos/mdj.jpg',
      url: 'www.mdjcreative.co.uk'
    },
    {
      review: 'Excellent service and prices. Very pleasant and was able to answer all my questions. Highly recommended 5*',
      name: 'Lee Foster',
      title: 'Owner',
      company: 'RF Digital Solutions',
      img: '/images/reviews/lee-f.jpg',
      logo: '/images/reviews/logos/rfdigital.jpg',
      url: 'www.rfdigitalsolutions.com'
    }
  ]

  render () {
    const settings = {
      dots: false,
      centerMode: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      focusOnSelect: true,
      afterChange: index => (
        this.setState({ activeReview: index })
      ),
      appendDots: dots => (
                <div
                    style={{
                      backgroundColor: '#ddd',
                      borderRadius: '10px',
                      padding: '10px'
                    }}
                >
                    <ul style={{ margin: '0px' }}> {dots} </ul>
                </div>
      ),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    const reviewDetails = this.featuredReviews[this.state.activeReview]
    return (
      this.props.isSlider
        ? <>
                    <div className="col-12 col-md-8 mx-auto">
                        <Slider className="reviews-slider" {...settings}>
                            {this.featuredReviews && this.featuredReviews.length > 0 && this.featuredReviews.map((rev, i) =>
                                <div className="col-md-1-5">
                                    <img src={rev.img} alt={`${rev.name} ${rev.company}`} className="img-responsive img-circle" />
                                </div>
                            )}
                        </Slider>
                    </div>
                    <div className="col-12 col-md-8 mx-auto text-center pt-30">
                        <p className="lead text-400">&rdquo;{reviewDetails.review}&ldquo;</p>
                        <p className="text-500 text-cta mb-0">
                            {reviewDetails.name}
                        </p>
                        <p className="text-sm">
                            {reviewDetails.title} - {reviewDetails.company}
                        </p>
                    </div>
                </>
        : <>
                    <div className="row row-flex flex-2-col">
                        {this.featuredReviews && this.featuredReviews.length > 0 && this.featuredReviews.map((rev, i) =>
                            <div className="col-xxs-12 bg-light col-xs-6 col-sm-6 col-md-6 b-1 brad-10 box-shadow m-20 p-0 text-center">
                                <div className="p-35 mb-35">
                                    <img src={rev.img} alt={`${rev.name} ${rev.company}`} className="img-responsive img-circle" />
                                    <h4 className="mt-30 mb-15">{rev.name}</h4>
                                    <p className="text-sm">
                                        <span className="text-400">{rev.title}</span><br />
                                        {rev.company}
                                    </p>
                                    <span className="bt-1 pt-20 pb-10 d-inline-block">
                                        <span className="icon icon-star-full text-cta"></span><span className="icon icon-star-full text-cta"></span><span className="icon icon-star-full text-cta"></span><span className="icon icon-star-full text-cta"></span><span className="icon icon-star-full text-cta"></span>
                                    </span>
                                    <p className="text-400"><em>&rdquo;{rev.review}&ldquo;</em></p>
                                </div>
                                <div className="button-set">
                                    <p className="mt-30">
                                        <img src={rev.logo} alt={rev.company} />
                                    </p>
                                    <p className="text-xs">
                                        <a href={`http://${rev.url}`} target="_blank" rel="noreferrer">{rev.url}</a>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </>

    // <>
    //     {this.props.showAll ?
    //         renderAllTestimonials(this.state.testimonialList)
    //         :
    //         <Row className="text-center">
    //             {this.state.testimonialList.map((item, i) =>
    //                 <Col md={4} className="pb-sm-50">
    //                     {getCustomerName(item.customerName)}
    //                     <span className="icon icon-quotes-left float-start quote text-secondary"></span>
    //                     <p className="text-400">
    //                         {renderTestimonialText(item.testimonial)}... <Link to={"/reviews/".concat(item.id)}>read more</Link>
    //                     </p>
    //                     {getCustomerCompany(item.customerName)}
    //                 </Col>
    //             )}
    //         </Row>
    //     }

    // </>
    )
  }

  componentDidMount () {
    // let context = this;
    // let url = "";
    // if(this.props.showAll){
    //     url = 'api/Testimonials/GetTestimonials/'
    // } else {
    //     url = 'api/Testimonials/GetTestimonials/3'
    // }

    // fetchHelper.getJson(url)
    //     .then(response => {
    //         let data = response.data;
    //         context.setState({
    //             testimonialList: [...this.state.testimonialList, ...data.testimonialList]
    //         });
    //         scrollToReview();
    //     })
    //     .catch(error => {
    //         console.error(error);
    //     })

    //     let id = this.props.testimonialId;

    //     function scrollToReview(){
    //         scroller.scrollTo("testimonial_" + id, {
    //             duration: 1500,
    //             smooth: true,
    //             delay: 1000,
    //             offset: -200
    //         });
    //     }
  }
}
