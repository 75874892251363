import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CallbackRequest from '../../../components/CallbackRequest'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../../selectors'

class TimeOfDayRoutingDescription extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>Time Of Day Call Routing - Transfer your calls depending on the day and time you choose.</title>
                <meta name="description" content="Time of Day Routing allows you to redirect your calls depending on the day and time you choose." />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/open-close.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Time Of Day Routing</h1>
                <h2 className="subtitle text-center">Redirect your calls depending on the day and time you choose.</h2>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto pb-30 pt-30">
                            <p>
                                With the the &quot;The time-of-day routing&quot; feature, you will be able to increase efficiency by automatically routing calls made to your virtual SwitchboardFREE number, on a specific day and time.
                            </p>
                            <p className="pt-10">
                                This free feature allows you to better manage your busy periods, staffing schedules holidays and weekends.
                            </p>

                            <p className="pt-10">
                                You can route calls to mobiles, landlines, user extensions, ring groups, <Link className="text-cta underline" to={'phones-apps/'}>VoIP phones</Link> or your <Link className="text-cta underline" to={'telephone-answering-service/'}>call answering team</Link>.
                            </p>

                            <p className="pt-10">
                                For example, your team might take regular breaks and you have regular appointments throughout the week, or you just want different people to handle your calls at different times and on different days. This is where time of day redirects come in.
                            </p>
                            <p className="pt-10 pb-20">
                                {/* Once you <Link className="text-cta underline" to={'phones-apps/'}>log in to your SwitchboardFREE account</Link>, go to your numbers settings page, while there, scroll down to the Redirects section. Once there, under your normal redirects you will see the &quot;Time of Day&quot; settings. Click the &quot;Get Started&quot; button. */}
                                Watch the below video to see how to access and setup time of day routing on your account.
                            </p>
                            <div className="ratio ratio-16x9 pt-30">
                                <iframe src="https://www.youtube.com/embed/rI_jdgOhFD0" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full-width bg-dull text-center p-30 mt-30 pb-30 pt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <h4 className="text-500 pb-30">Do you have any more questions about open &amp; close times?</h4>
                                <h5 className="mb-30 pb-30">Call us on <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a>, email <a href="support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a> or request a callback below. Our offices are open Monday to Friday 8:30am to 6pm.</h5>
                                <p></p>
                                <CallbackRequest subject="Callback request from Open Close page" isForm={true} />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(TimeOfDayRoutingDescription)
