import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { fetchHelper } from '../helpers/fetchHelper'
import { simpleDateTimeFormat } from '../helpers/utils'
import DisplayMainFooter from './DisplayMainFooter'
import DisplayMainHeader from './DisplayMainHeader'
import ScrollToTopOnMount from './ScrollToTopOnMount'

class CrmRedirectWebhookPage extends Component {
  state = {
    checkingCrmInfo: true,
    crmData: [],
    notFound: false
  }

  componentDidMount () {
    const token = this.props.match.params.token
    const number = this.props.match.params.number

    const url = '/api/contacts/ScreenPopTokenCheck/' + token + '/' + number
    fetchHelper.getJson(url).then((res) => {
      if (res === undefined || res.data === []) {
        this.setState({ notFound: true, crmData: [], checkingCrmInfo: false })
      } else {
        this.setState({ notFound: false, crmData: res.data, checkingCrmInfo: false })
      }
    })
  }

  render () {
    if (this.state.checkingCrmInfo === false && this.state.notFound === false && this.state.crmData.length === 1) {
      // redirect to external site
      window.location.href = this.state.crmData[0].redirectUrl
    } else {
      return (
                <>
                    <ScrollToTopOnMount/>
                    <DisplayMainHeader/>
                    <DisplayMainFooter/>
                    <Helmet>
                        <title>CRM Redirect</title>
                        <meta name="description" content="Outbound call settings" />
                    </Helmet>
                    <header>
                        <div className="full-width full-hero-small with-text" >
                            <h1>CRM Redirect</h1>
                        </div>
                    </header>

                    <div className="container pt-30 pb-60">

                            {this.state.checkingCrmInfo === true && <div className="w-600px center-block bg-light mt-30 mb-100 brad-30 p-40 text-center box-shadow">
                                <div className="loading-content text-center">
                                    <img id="loading" src={'images/icons/loading.svg'} width="70" height="70" />
                                    <p className="text-dark text-400"> Checking CRM data </p>
                                </div>
                            </div>
                            }

                            {this.state.checkingCrmInfo === false && (this.state.notFound === true || this.state.crmData.length < 1) && <div className="w-600px center-block bg-light mt-30 mb-100 brad-30 p-40 text-center box-shadow">
                                <div className="loading-content text-center">
                                <p className="text-dark text-400"> No CRM data found </p>
                            </div>
                            </div>
                            }

                            {this.state.checkingCrmInfo === false && this.state.notFound === false && this.state.crmData.length > 1 && <div className="text-center">
                                <h4 className="pb-20 text-500">Multiple CRM Contacts Detected</h4>
                                <p className="pb-30">Please select the contact you want to use.</p>
                             </div>
                             }
                            <div className="text-center pb-100">
                                {this.state.checkingCrmInfo === false && this.state.notFound === false && this.state.crmData.length > 1 && this.state.crmData.map((data) => <>

                                        {data.crmId === 1 && <>
                                            <div onClick={() => window.location.href = data.redirectUrl} className="pt-30 pb-20 pl-10 pr-10 brad-20 b-1 bg-light m-10 float-start text-center zoom d-inline-block cursor-pointer w-xs-100 ml-xs-0 mr-xs-0" style={{ width: '250px', maxWidth: '100%', minHeight: '200px' }}>
                                                <img id="loading" src={'images/logo/CapsuleCrm.png'} width="47" height="42" />
                                                <p className="text-500 pt-5">
                                                    {data.description}
                                                    {data.company !== null && <>
                                                        <br/>
                                                        <span>{data.company}</span>
                                                    </>}
                                                </p>
                                                <p className="pt-10 text-sm">
                                                    <span className="text-500">Last Updated:</span> {simpleDateTimeFormat(data.whenCreated).split(' ')[0]}
                                                </p>
                                            </div>
                                            </>
                                        }

                                        {data.crmId === 6 && <>
                                            <div onClick={() => window.location.href = data.redirectUrl} className="pt-30 pb-20 pl-10 pr-10 brad-20 b-1 bg-light m-10 float-start text-center zoom d-inline-block cursor-pointer w-xs-100 ml-xs-0 mr-xs-0" style={{ width: '250px', maxWidth: '100%', minHeight: '200px' }}>
                                                <img id="loading" src={'images/logo/pipedrive-crm.png'} width="47" height="42" />
                                                <p className="text-500 pt-5">
                                                    {data.description}
                                                    {data.company !== null && <>
                                                        <br/>
                                                        <span>{data.company}</span>
                                                    </>}
                                                </p>
                                                <p className="pt-10 text-sm"><span className="text-500">Last Updated:</span> {simpleDateTimeFormat(data.whenCreated).split(' ')[0]}</p>
                                            </div>
                                            </>
                                        }

                                </>
                                )}
                            </div>

                        {/* </div> */}
                    </div>
                </>
      )
    }
  }
}

export default CrmRedirectWebhookPage
