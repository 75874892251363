import React, { Component } from 'react'

export default class ClientLogos extends Component {
  render () {
    return (
            <div className={`full-width ${this.props.style ? `bg-${this.props.style}` : 'bg-dull'} ${this.props.hidden ? `d-${this.props.hidden}` : ''} p-20`} style={{ backgroundColor: this.props.customBg }}>
                {this.props.showText &&
                    <div className="text-center pb-30">
                        <h3 className="text-500">Partners &amp; Customers</h3>
                        <p>The UK's favourite call management system</p>
                    </div>
                }

                <div className="text-center">
                    <img className="p-15" src={'/images/customers/small/google.png'} alt="google logo" />
                    <img className="p-15" src={'/images/customers/small/apple.png'} alt="apple logo" />
                    <img className="p-15" src={'/images/customers/small/ee.png'} alt="ee logo" />
                    <img className="p-15" src={'/images/customers/small/three.png'} alt="three logo" />
                    <img className="p-15" src={'/images/customers/small/vodafone.png'} alt="vodafone logo" />
                    <img className="p-15" src={'/images/customers/small/o2.png'} alt="o2 logo" />
                    <img className="p-15" src={'/images/customers/small/bt.png'} alt="BT logo" />
                    <img className="p-15" src={'/images/customers/small/virgin.png'} alt="virgin logo" />
                    <img className="p-15" src={'/images/customers/small/porsche.png'} alt="porsche logo" />
                    <img className="p-15" src={'/images/customers/small/tesco.png'} alt="teco logo" />
                    <img className="p-15" src={'/images/customers/small/hsbc.png'} alt="HSBC logo" />
                    <img className="p-15" src={'/images/customers/small/groupon.png'} alt="groupon logo" />
                    <img className="p-15" src={'/images/customers/small/dulux.png'} alt="dulux logo" />
                    <img className="p-15" src={'/images/customers/small/british-gas.png'} alt="british-gas logo" />
                </div>
            </div>
    )
  }
}
