import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface CleartoneProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const Cleartone = (props: CleartoneProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Cleartone vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Cleartone vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Cleartone vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Who are Cleartone?</h2>
                            <p>Cleartone are a telecommunications provider specializing in supplying virtual numbers to smaller businesses.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Cleartone pricing and features</h2>
                            <p>The number types Cleartone offer are 0800, 0844, 0845, 0870, 0871, 0333, 0203, 0207, 070 (mobile) and international virtual numbers. The setup fees range from £5 to £1499 and depend on the memorability of each number. There is a fixed monthly cost of £5 for all number types, except the 070 mobiles that come with no monthly fee.</p>
                            <p>Redirection to a landline from the 0844, 0845, 0870, 0871 and 070 numbers, is free, and 2p / minute is charged when redirecting from 0800, 0333, 0203 and 0207.</p>
                            <p>When redirecting to a mobile, the costs vary from 0p (for the 070 mobile numbers) to 14p / minute.</p>
                            <p>The standard features that come along with every number purchased include call screening, voicemail to email, online portal, missed call alerts, recording of welcome prompt and phone call recording.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <p className="text-500 text-lg text-dark">For more advanced features, costs apply as follows:</p>
                            <ul className="fancy cta text-400 pl-30 pt-10 pb-30">
                                <li className="mb-5">Virtual switchboard - £99 setup fee; £10 / month</li>
                                <li className="mb-5">Fax to email - £10 setup fee; £5 / month</li>
                                <li className="mb-5">Live call answering (Pay as you go) - £0 setup fee; £0 / month (£1.25 per call); 10p / SMS delivery</li>
                                <li className="mb-5">Cleartone 25 (25 calls included) - £25 setup fee; £25 / month (£1.10 per excess call); 10p / SMS delivery</li>
                                <li className="mb-5">Cleartone 50 (50 calls included) - £42 setup fee; £42 / month (£1.25 per excess call); 10p / SMS delivery</li>
                                <li className="mb-5">Cleartone 100 (100 calls included) - £48 setup fee; £48 / month (£1.10 per excess call); 10p / SMS delivery</li>
                                <li className="mb-5">Virtual office - £35 setup fee; £20 – 30 / month</li>
                            </ul>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">How do SwitchboardFREE compare with Cleartone?</h2>
                            <p>While Cleartone focus on supplying their users with numbers that hide the location of the caller, at SwitchboardFREE we stress the importance of giving your business local presence. This is the reason why we have placed our local numbers in the heart of our packages. And we are glad to observe how popular they are among our users.</p>
                            <p>In terms of pricing, we have the edge over Cleartone, for most of their advanced features can be exploited by you at no extra cost with SwitchboardFREE, as they are part of all our standard packages (except for the live call answering service).</p>
                            <p>For example, there is no setup fee when signing up to have your calls redirected, while Cleartone want £99 just to provide you with a virtual switchboard in addition to the £10 monthly fee, the setup cost of choosing a number and the £5 monthly rent.</p>
                            <p>You can have all of this for as little as £5 per month, with no setup fees, with SwitchboardFREE.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <p>Our telephone answering service <a href="https://www.pocketreceptionist.co.uk/" target="_blank" id="PRLink" rel="noreferrer">Pocket Receptionist</a> has more features you can benefit from, to ensure a better and more efficient communication between you and your customers.</p>
                            <p>Cleartone are limited in their engagement with your callers to just taking their name, number and the reason for calling.</p>
                            <p>The virtual receptionist we can provide you with could work as a fully integrated part of your business, taking orders on your behalf, providing customer support and acting as a versatile PA.</p>
                            <p>And, again, we do not expect you to pay us for setting you up. Our monthly costs start at £35, in comparison to Cleartone's £60.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Cleartones current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://cleartonecommunications.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(Cleartone)
