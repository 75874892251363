import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface Press1Props extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const Press1 = (props: Press1Props) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Press1 vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Press1 vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Press1 vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Who are Press1?</h2>
                            <p>Press1 provide virtual switchboard services to their clients. They give virtual numbers, calls to which can be forwarded to multiple landlines or mobiles.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Press1 number types and features</h2>
                            <p>There are 3 main number types offered by Press1 – 0844, 0845 and 0870. The customer can add a local number, too, as an extra.</p>
                            <p>All the numbers come with standard features one can expect from a virtual switchboard service. These include email alerts, setting up of opening hours, control panel on the Press1 website to manage your account, together with the call forwarding feature which is the staple of the service.</p>
                            <p>The client can opt to add a voice recording feature to their virtual switchboard, albeit at an additional charge. The first 50 words per recording are priced at £15 and additional 50 words cost £5.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Press1 pricing & packages</h2>
                            <p>For each virtual number there is a setup cost of £24.99. Then, depending on the number type, the monthly fees range from £4.99 for 0844 numbers to £14.99 for 0870 numbers. The 0845 numbers are priced at £9.99 per month.</p>
                            <p>The local numbers are free to activate and are forwarded at no cost to your Press1 virtual switchboard. The monthly subscription is £8.99.</p>
                            <p>Pricing for call forwarding is calculated per minute. While routing to landlines is free, redirection to a mobile number costs 4.7p / minute.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">How do SwitchboardFREE compare with Press1?</h2>
                            <p>First of all, besides offering 084 numbers (for free), we have local numbers as a standard option to choose, and not as an additional feature. And these are among our clients’ favourites.</p>
                            <p>We also offer 03 and 0800 numbers which are particularly business-friendly. The 03 numbers can be called from mobiles at standard call rates, and calls made to 0800 from both landlines and mobiles are completely free.</p>
                            <p>We also boast more advanced features to smoothen your experience with SwitchboardFREE and facilitate your call handling. Along with sending you an email alert, we can also notify you via SMS, which we feel increases your chances of finding out about a potentially missed call early enough, so you can call back as soon as you are free.</p>
                            <p>And to stay in pace with technology, we have our iOS and Android apps that allow you to manage your account at any time, regardless of where you are.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Press1 current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.press1.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(Press1)
