import React, { Component } from 'react'
import { connect } from 'react-redux'
import { is0203Offer, is0207Offer, is033Offer, is0800Offer, isGeoOffer } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import * as fromHome from '../store/Home'

class ProdPrice extends Component {
  static defaultProps = {}

  static propTypes = {}

  render () {
    const paymentDetails = {
      monthlyFirstPayment: 0,
      monthlyRepeatPayment: 0,
      yearlyFirstPayment: 0,
      yearlyRepeatPayment: 0
    }

    if (this.props.productDetails) {
      const details = this.props.productDetails.find(prod => prod.productId === this.props.productId)
      if (details) {
        if (details.monthlyPrice) {
          paymentDetails.monthlyFirstPayment = details.monthlyPrice.firstPaymentAmount && details.monthlyPrice.firstPaymentAmount > 0 ? details.monthlyPrice.firstPaymentAmount : 0
          paymentDetails.monthlyRepeatPayment = details.monthlyPrice.repeatPaymentAmount && details.monthlyPrice.repeatPaymentAmount > 0 ? details.monthlyPrice.repeatPaymentAmount : 0
        } else {
          paymentDetails.monthlyFirstPayment = 0
          paymentDetails.monthlyRepeatPayment = 0
        }

        if (details.yearlyPrice) {
          paymentDetails.yearlyFirstPayment = details.yearlyPrice.firstPaymentAmount && details.yearlyPrice.firstPaymentAmount > 0 ? details.yearlyPrice.firstPaymentAmount : 0
          paymentDetails.yearlyRepeatPayment = details.yearlyPrice.repeatPaymentAmount && details.yearlyPrice.repeatPaymentAmount > 0 ? details.yearlyPrice.repeatPaymentAmount : 0
        } else {
          paymentDetails.yearlyFirstPayment = 0
          paymentDetails.yearlyRepeatPayment = 0
        }
      }
    }

    if ((this.state && this.state.fromPrice) && (this.props.prefix || (this.props.productId && this.props.hardware))) {
      return (
                `£${this.state.fromPrice}`
      )
    }

    if (this.props.returnYearly) {
      // Return yearly pricing
      return (
                <>
                    {paymentDetails.yearlyRepeatPayment === paymentDetails.yearlyFirstPayment
                      ? `£${paymentDetails.yearlyRepeatPayment}`
                      : `£${paymentDetails.yearlyFirstPayment}, followed by £${paymentDetails.yearlyRepeatPayment}`
                    }
                </>
      )
    } else {
      // Return monthly pricing
      return (
                <>
                    {paymentDetails.monthlyRepeatPayment === paymentDetails.monthlyFirstPayment
                      ? `£${paymentDetails.monthlyRepeatPayment}`
                      : `£${paymentDetails.monthlyFirstPayment}, followed by £${paymentDetails.monthlyRepeatPayment}`
                    }
                </>
      )
    }
  }

  async componentDidMount () {
    if (this.props.prefix !== '' && this.props.prefix !== undefined) {
      let isOffer = false
      if (this.props.prefix.startsWith('080')) {
        isOffer = is0800Offer
      } else if (this.props.prefix.startsWith('01')) {
        isOffer = isGeoOffer
      } else if (this.props.prefix.startsWith('0207')) {
        isOffer = is0207Offer
      } else if (this.props.prefix.startsWith('0203')) {
        isOffer = is0203Offer
      } else {
        isOffer = is033Offer
      }

      this.handleApiLookup(isOffer)
    } else if (this.props.productId && this.props.hardware === true) {
      this.handleApiLookup(false)
    } else {
      this.props.getProductDetails([
        {
          productId: this.props.productId,
          yearlyOption: this.props.yearlyOption,
          monthlyOption: this.props.monthlyOption
        }
      ])
    }
  }

  handleApiLookup (isOffer) {
    let getPriceUrl = ''

    if (this.props.hardware === true) {
      getPriceUrl = `api/Purchase/GetProductPrice?prodId=${this.props.productId}&hardware=${this.props.hardware}`
    } else {
      getPriceUrl = `api/Purchase/GetProductPrice?prefix=${this.props.prefix}&onOffer=${isOffer}&loggedIn=${this.props.loggedIn}&hardware=${this.props.hardware}`
    }

    fetchHelper.getJson(getPriceUrl)
      .then(res => {
        if (this.props.callback) {
          this.props.callback(res.data.monthlyPrice.repeatPaymentAmount)
        } else {
          this.setState({ fromPrice: this.props.hardware === true ? res.data.oneOffCost : res.data.monthlyPrice.repeatPaymentAmount })
        }
        if (!this.props.hardware) {
          this.props.setNumberPrice(!this.props.ignoreOffer && res.data.monthlyPrice.repeatPaymentAmount > res.data.monthlyPrice.firstPaymentAmount ? res.data.monthlyPrice.firstPaymentAmount : res.data.monthlyPrice.repeatPaymentAmount)
        }
      }).catch(err => {
        console.error(err)
      })
  }
}

export default connect(
  state => {
    return {
      productDetails: state.home.productDetails.products
    }
  },
  {
    getProductDetails: fromHome.getProductPrice,
    setNumberPrice: fromHome.setNumberPriceDetails
  }
)(ProdPrice)
