import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'

const StandardMultipleDepartmentToggle = props => {
  return (
  // <ul className="nav nav-tabs nav-tabs-centered">
  //     <Route render={({history}) => (
  //         <li className={props.isStandardActive ? "active" : ""}>
  //             <a onClick={e => history.push('/customer/numbers/' + props.qlid)}>
  //                 Single Department
  //             </a>
  //         </li>
  //     )}/>
  //     <Route render={({history}) => (
  //         <li className={props.isDepartmentActive ? "active" : ""}>
  //         <a onClick={e => history.push('/customer/numbers/' + props.qlid + '/' + (props.depNo === undefined ? '1' : props.depNo))}>
  //             Multiple Departments
  //         </a>
  //     </li>
  //     )}/>
  // </ul>

            <>
                <Route render={({ history }) => (
                    <Button
                        variant={props.isStandardActive ? 'success' : 'default'}
                        size="sm"
                        className={props.isStandardActive ? 'active' : ''}
                        // className={props.isStandardActive ? "btn-sm btn-success active" : "btn-sm btn-default"}

                        onClick={e => props.isDepartmentActive ? history.push('/customer/numbers/' + props.qlid) + props.handleDeptConfigurationChange() : undefined}>
                            Single Department
                    </Button>
                )}/>
                <Route render={({ history }) => (
                    <Button
                    variant={props.isDepartmentActive ? 'success' : 'default'}
                    size="sm"
                    className={props.isDepartmentActive ? 'active' : ''}
                    // className={props.isDepartmentActive ? "btn-sm btn-success active" : "btn-sm"}
                    onClick={e => props.isStandardActive ? history.push('/customer/numbers/' + props.qlid + '/' + (props.depNo === undefined ? '1' : props.depNo)) + props.handleDeptConfigurationChange() : undefined}>
                        Multiple Departments
                </Button>
                )}/>
            </>

  )
}

const mapStateToProps = state => ({
  isDepartmentActive: state.configuration.selectedConfiguration.isDepartmentActive,
  isStandardActive: state.configuration.selectedConfiguration.isStandardActive,
  depNo: state.configuration.selectedConfiguration.depNo,
  qlid: state.configuration.selectedConfiguration.queueNo,
  handleDeptConfigurationChange: state.configuration.selectedConfiguration.handleDeptConfigurationChange
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardMultipleDepartmentToggle)
