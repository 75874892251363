import moment from 'moment'

export function getTomorrowMorningDate () {
  const rightNow = moment()
  const tomorrow = rightNow.add(1, 'day')

  const tomorrowMorning = tomorrow.set({
    hour: '8',
    minute: '0',
    second: '0'
  })

  return tomorrowMorning
}
