import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

class Customers extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount/><DisplayMainFooter/><DisplayMainHeader/>
            <Helmet>
                <title>Access to emergency services</title>
                <meta name="description" content="SwitchboardFREE offers its SIP Trunk customers in England, Wales, Scotland, and Northern Ireland access to the UK public emergency call services, enabling them to make outbound calls." />
            </Helmet>
   <header style={{ backgroundImage: "url('/images/backgrounds/features-bg.jpg')" }} className="full-width full-hero-small with-text">
        <h1>Access to emergency services</h1>
    </header>
    <div className="container-styled">
        <div className="container">
            <div className="row">
                <div className="col-md-12 w-1000px center-block">
                        <p className="pt-20">SwitchboardFREE offers its SIP Trunk customers in England, Wales, Scotland, and Northern Ireland access to the UK public emergency call services, enabling them to make outbound calls.</p>
                        <p>After providing us with your address, we will validate it and forward the details to the Emergency Handling Authority, ensuring that emergency services can locate your address during a crisis.</p>

                        <h4 className="text-500">
                        Making an Emergency Call
                        </h4>
                        <p>
                        When you dial 999 or 112, your call will be routed from the SwitchboardFREE network to national emergency operators who will attend to your call.
                        </p>
                        <p>
                        The operator will require specific information to route your call correctly. You will need to articulate the nature of your emergency, your location, phone number, and any other requested details as clearly and promptly as possible.
                        </p>
                        <p>
                        The operator will then transfer your call to the relevant emergency service, such as the ambulance, police, fire department, coastguard services, or mountain and cave rescue services.
                        </p>
                        <h4 className="text-500">
                        Limitations
                        </h4>
                        <p>
                        Please note that the emergency call service requires an active internet connection. In case of an internet outage, power cut, or inactive account, all services, including emergency call services, will be unavailable. Thus, we advise that you use a fixed line or mobile phone whenever possible to call the emergency services.
                        </p>
                        <h4 className="text-500">
                        Charges for Emergency Calls
                        </h4>
                        <p>
                        SwitchboardFREE's end-users are not charged for making calls to 999 and 112 emergency service numbers.
                        </p>
                        <p>
                        However, service providers who resell or use our emergency service on behalf of their end-users will be charged a call set-up fee of £1.25 per call plus 55 pence per minute.
                        </p>
                        <h4 className="text-500">
                        Correction of Missing or Invalid Data Administration Costs
                        </h4>
                        <p>
                        If SwitchboardFREE receives a request from BT or the Emergency Handling Authority (EHA) concerning the following issues, we will charge £20 per incident for correction of:
                        </p>
                        <ul className="fancy cta text-400 pl-25">
                            <li>
                            Incomplete or invalid End User data submitted to the EHA.
                            </li>
                            <li>
                            A call made to 112/999 with incomplete or invalid address data submitted for the CLI presented.
                            </li>
                            <li>
                            A call made to 112/999 with no valid CLI presented.
                            </li>
                        </ul>
                        <p>
                        All prices listed exclude VAT.
                        </p>
                        <p>
                        Please <a href="/contact" className="underline cta">contact us</a> if you have any questions.
                        </p>
                </div>
            </div>
        </div>
    </div>

    </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(Customers)
