import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import CallbackRequest from '../../../components/CallbackRequest'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../../selectors'

class Departments extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>Press 1 for... press 2 for... Multiple Departments vs. Single Department- Free Virtual Call Management System</title>
                <meta name="description" content="Easily set up to 9 different departments on your phone" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/departments-bg.jpg')" }} className="full-width full-hero-small pl-30 pr-30">
                <h1>Multiple Departments vs. Single Department</h1>
                <h2 className="subtitle text-center">Thank you for calling [Company Name], please press 1 for sales, press 2 for support [...] press 9 for IT</h2>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="w-1000px center-block pb-30 pt-30">
                            <p>
                                On SwitchboardFREE your number can work in one of two ways, either in "Single Department" mode with one lot of settings or in "Multiple Departments" mode, where each department has its own individual settings.
                            </p>
                            <p className="pb-30">
                                To select either Single or Multiple Departments, while inside your numbers settings page, click on “Single Department” or “Multiple Departments” button and then click “Save Changes” button to confirm your changes.

                            </p>
                            <div className="row">
                                {/* <div className="col-md-3 text-center pt-10 pb-30">
                                    <img className="img-responsive b-1 pb-10 brad-20 box-shadow" src="/images/screenshots/departments.png" alt="departments settings screenshot" />
                                </div> */}
                                <div className="col-md-12">
                                <img className="img-responsive b-1 p-30 mb-20 brad-20 box-shadow float-end ml-30" src="/images/screenshots/departments.png" alt="multiple departments settings screenshot" style={{ maxWidth: '400px' }}/>
                                    <p>
                                        In "Multiple Departments" mode callers to your number are provided with a selection of options which you setup to ensure callers are routed to the most appropriate person or department.
                                        For example, when callers call your SwitchboardFREE number they might hear the following:
                                    </p>
                                    <p>
                                    You can configure up to 9 separate departments. Each department has its own independent settings, for example, your Sales department might have different opening times, redirect numbers, different music on-hold, alerts sent to different email addresses etc.
                                    </p>
                                    <h4 className="pt-30 text-500">
                                        Turning on your departments
                                    </h4>
                                    <p>
                                        To configure your departments simply click on the department’s number (it’s the keypad on the right hand side… see keypad screenshot) and click the toggle switch to enable it…. And then continue with its individual settings. Always remember to click “Save changes” after you’re done editing your department.
                                    </p>
                                    <p>
                                        The department number in orange is the one you are currently on, departments numbers in green are enabled and departments in grey and disabled.
                                    </p>
                                    <h4 className="pt-30 text-500">
                                        Multiple Departments - Who is it for?
                                    </h4>
                                    <p>
                                        Multiple Departments mode is ideal for growing companies or if your workforce is split over different locations or if you simply want to get that "big company feel" even though you might be a small business.
                                    </p>
                                    <h4 className="pt-30 text-500">
                                        Single Departments - Who is it for?
                                    </h4>
                                    <p>
                                        Single Department mode is mostly used by small businesses or one-mans bands and people that do not want to configure more than one department.
                                    </p>
                                    <h4 className="pt-30 text-500">
                                       Watch the video showing how to set up Multiple Departments
                                    </h4>
                                    <div className="ratio ratio-16x9 pt-30">
                                        <iframe src="https://www.youtube.com/embed/cykYlQGGbmE" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
            <div className="full-width bg-dull text-center p-30 mt-30 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 w-800px center-block">
                                <h4 className="text-500 pb-30">Do you have any more questions about <br className="d-none d-sm-block"/>Single & Multiple Departments?</h4>
                                <h5 className="mb-30 pb-30 text-400 mb-30">Call us on <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a>, email <a href="support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a> or request a callback below. Our offices are open Monday to Friday 8:30am to 6pm.</h5>

                                <CallbackRequest subject="Callback request from Departments page" isForm={true} />
                            </div>
                        </div>
                    </div>

                </div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(Departments)
