import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import DisplaySimpleFooter from '../components/DisplaySimpleFooter'
import LoadingNotification from '../components/LoadingNotification'
import QueueMonitor from '../components/QueueMonitor'
import QueueMonitorAlarm from '../components/QueueMonitorAlarm'
import { siteFooter } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import { simplePhoneNumberFormat } from '../helpers/utils'
import * as fromApp from '../store/SBF'

const _ = require('lodash')

class CallMonitor extends React.Component {
  state = {}
  defaultAlertThreshold = 2

  constructor (props) {
    super(props)

    this.state = { muted: false }
    this.onToggle = this.onToggle.bind(this)

    this.updateDimensions = this.updateDimensions.bind(this)
  }

  onToggle () {
    this.setState({ muted: !this.state.muted })
  }

  handleThresholdChange (newThresholdValue, queueState) {
    const index = _.findIndex(this.state.data, qs => qs.dep === queueState.dep)
    let threshvoldValInt

    if (!newThresholdValue) {
      threshvoldValInt = newThresholdValue
    } else {
      threshvoldValInt = parseInt(newThresholdValue)

      if (threshvoldValInt > 1000) {
        threshvoldValInt = 1000
      }

      if (threshvoldValInt < 1) {
        threshvoldValInt = 1
      }
    }

    const newState = { ...this.state.alertThresholds, [index]: threshvoldValInt }
    this.setState({ alertThresholdsNotSet: newState })
  }

  handleAlertThresholdSet (queueState) {
    const index = _.findIndex(this.state.data, qs => qs.dep === queueState.dep)

    if (this.state.alertThresholdsNotSet[index] < this.defaultAlertThreshold) {
      // reset
      this.state.alertThresholdsNotSet[index] = this.defaultAlertThreshold
      this.state.alertThresholds[index] = this.defaultAlertThreshold
    }

    const newState = { ...this.state.alertThresholds, [index]: this.state.alertThresholdsNotSet[index] }
    this.setState({ alertThresholds: newState })
  }

  render () {
    const { alertThresholds, muted } = this.state
    const ddi = this.props.match.params.ddi
    let shouldSoundAlarm = false
    if (alertThresholds) {
      shouldSoundAlarm = _.some(this.state.data, function (queueData, index) {
        const alertThreshold = alertThresholds[index]
        return queueData.waiting >= alertThreshold && !muted
      })
    }

    return <div className="container-fluid"><DisplaySimpleFooter/>
        <Helmet>
                <body className={'call-monitor '} />
                <title>{simplePhoneNumberFormat(ddi)} Live Call Monitor</title>
            </Helmet>
            <div className="mt-10 mb-30">
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <h1>Live Call Monitor for {simplePhoneNumberFormat(ddi)}</h1>
                        {!this.state.data &&
                        <LoadingNotification message={'Loading your Queue Data'} isLoading={!this.state.data}/>}
                    </div>

                    {this.state.data && this.state.data.map((queueState, index) => <QueueMonitor key={queueState.dep}
                                                                                                 depNo={queueState.dep > 0 ? queueState.dep : undefined}
                                                                                                 description={queueState.description}
                                                                                                 waiting={queueState.waiting}
                                                                                                 avgHold={queueState.avgHold}
                                                                                                 connected={queueState.connected}
                                                                                                 maxHold={queueState.maxHold}
                                                                                                 alertThresholdNotSet={this.state.alertThresholdsNotSet ? this.state.alertThresholdsNotSet[index] : undefined}
                                                                                                 alertThreshold={this.state.alertThresholds ? this.state.alertThresholds[index] : undefined}
                                                                                                 width={this.getPercentAdjustmentFor(this.state.width)}
                                                                                                 handleAlertThresholdChange={e => this.handleThresholdChange(e.target.value, queueState)}
                                                                                                 handleAlertThresholdSet={() => this.handleAlertThresholdSet(queueState)} />)}
                </div>
            </div>
            <div className="alarm-sound col-md-6 mx-auto p-30 mb-30 mt-30 bg-light text-center brad-10 box-shadow">
            <h4>Would you like to mute the alarm sound?</h4>
            <p>This is the sound you will hear when the number of people in the queue reaches the number you have entered above.</p>
            <p className="text-500">Turn the sound off?</p>
            <QueueMonitorAlarm shouldSoundAlarm={shouldSoundAlarm} />

            <Toggle
                className="mt-0"
                onClick={this.onToggle}
                on={<span>YES</span>}
                off={<span>NO</span>}
                size="sm"
                offstyle="default"
                onstyle="success"
                width="null"
                height="null"
                active={this.state.muted}
            />
            </div>
        </div>
  }

  updateDimensions () {
    const w = window
    const d = document
    const documentElement = d.documentElement
    const body = d.getElementsByTagName('body')[0]
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth
    const height = w.innerHeight || documentElement.clientHeight || body.clientHeight

    this.setState({ width, height })
  }

  componentWillMount () {
    this.updateDimensions()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions)

    const id = this.state.timerId
    clearInterval(id)
  }

  componentDidMount () {
    document.body.style.backgroundColor = '#eee'
    document.body.style.paddingTop = '0'
    // document.title = "Live Call Monitor";
    window.addEventListener('resize', this.updateDimensions)
    document.getElementsByClassName('navbar')[0].style.display = 'none'
    this.props.notifyOfFooterChange({ footer: siteFooter.SIMPLE, isVisible: true })

    const ddi = this.props.match.params.ddi
    const url = `api/Numbers/GetQueueMonitorInfo/${ddi}`
    const context = this

    context.setState({ isLoading: true })

    function getStats () {
      fetchHelper.getJson(url)
        .then(response => {
          context.setState({ isLoading: false })
          const resData = response.data
          context.setState({
            data: resData
          })

          const alertThresholds = resData.map(_ => context.defaultAlertThreshold)
          const alertThresholdsNotSet = resData.map(_ => context.defaultAlertThreshold)

          if (!context.state.alertThresholds) {
            context.setState({ alertThresholds })
            context.setState({ alertThresholdsNotSet })
          }
        })
        .catch(error => {
          context.setState({ isLoading: false })
          console.error(error)
        })
    }

    getStats()
    const secondsRefresh = 15
    const id = setInterval(getStats, secondsRefresh * 1000)
    context.setState({ timerId: id })
  }

  getPercentAdjustmentFor (width) {
    const oneDept = [{ width: Infinity, pct: 100.0 }]
    const twoDepts = [{ width: Infinity, pct: 50.0 }]
    const threeDepts = [{ width: 768, pct: 100.0 }, { width: Infinity, pct: 33.11 }]
    const fourDepts = [{ width: 600, pct: 100.0 }, { width: 768, pct: 50.0 }, { width: 992, pct: 50.0 }, { width: Infinity, pct: 24.11 }]
    const fiveDepts = [{ width: 600, pct: 100.0 }, { width: 768, pct: 50.0 }, { width: 1300, pct: 33.11 }, { width: Infinity, pct: 19.11 }]
    const sixDepts = [{ width: 600, pct: 100.0 }, { width: 768, pct: 50.0 }, { width: 992, pct: 33.11 }, { width: 1300, pct: 24.11 }, { width: Infinity, pct: 16.11 }]
    const sevenDepts = [{ width: 600, pct: 100.0 }, { width: 768, pct: 50.0 }, { width: 992, pct: 33.11 }, { width: 1500, pct: 24.11 }, { width: Infinity, pct: 14.11 }]
    const eightDepts = [{ width: 600, pct: 100.0 }, { width: 768, pct: 50.0 }, { width: 992, pct: 33.11 }, { width: 1800, pct: 24.11 }, { width: Infinity, pct: 12.11 }]
    const nineDepts = [{ width: 600, pct: 100.0 }, { width: 768, pct: 50.0 }, { width: 1100, pct: 25.0 }, { width: 1700, pct: 20.0 }, { width: Infinity, pct: 11.11 }]

    return _.find([oneDept, twoDepts, threeDepts, fourDepts, fiveDepts, sixDepts, sevenDepts, eightDepts, nineDepts][this.state.data.length - 1], function (v) {
      return width <= v.width
    }).pct
  }
}

export default connect(
  state => {
    return state
  },
  {
    notifyOfFooterChange: fromApp.notifyOfFooterChange
  }
)(CallMonitor)
