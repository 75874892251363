import moment from 'moment'
import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import connect from 'react-redux/es/connect/connect'
import { Link } from 'react-router-dom'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { calculateVat, getQueryStringByParameter } from '../../../helpers/utils'
import { handleHidePaymentOptionsModal, handleNotificationChange, handleShowPaymentOptionsModal } from '../../../store/SBF'
import LoadingNotification from '../../LoadingNotification'
import ChoosePayment from '../../purchase/ChoosePayment'

const _ = require('lodash')

class BillingAgreements extends Component {
  static defaultProps = {}

  state = {
    agreements: [],
    editingAgreement: undefined,
    ordinals: [],
    wpAmount: 0,
    userDetails: {},
    debtGuid: ''
  }

  renderProviderLogo (provider) {
    const formatted = provider.toLowerCase().replace(' ', '')
    switch (formatted) {
      case 'stripe':
        return <img src="/images/credit-cards/stripe.png" alt="Stripe" height="30" />
      case 'worldpay':
        return <img src="/images/wp-logo.png" alt="Powered by Worldpay" height="30" />
      case 'gocardless':
        return <img src="/images/credit-cards/direct-debit.png" className="pr-10" height="30" alt="Direct Debit" />
    }
  }

  render () {
    function ordinal_suffix_of (i) {
      const j = i % 10
      const k = i % 100
      if (j === 1 && k !== 11) {
        return i + 'st'
      }
      if (j === 2 && k !== 12) {
        return i + 'nd'
      }
      if (j === 3 && k !== 13) {
        return i + 'rd'
      }
      return i + 'th'
    }

    let returnRoute = '/customer/profile/billing&new=true'
    if (localStorage.getItem('Basket')) {
      const basket = JSON.parse(localStorage.getItem('Basket'))
      if (basket && (basket.package && !_.isEmpty(basket.package)) && basket.numbers && !_.isEmpty(basket.numbers)) { returnRoute = '/signup/checkout/complete' }
    }

    const ordinalArray = this.state.agreements.map((s, index) => Object.assign({}, { value: index + 1 }, { label: ordinal_suffix_of(index + 1) }))

    return (
			<div className="bg-light p-30 pb-40 brad-10 b-1 w-100 center-block mb-20">
				<ChoosePayment
					basket={this.state.basket}
					signupData={this.state.signupData}
					company={this.state.signupData ? this.state.signupData.optionalInfo.company : ''}
					mandate={true}
					total={this.state.wpAmount}
					clientId={this.props.clientId}
					purchaseData={this.state.key}
					email={this.state.userDetails.email}
					name={`${this.state.userDetails.fName} ${this.state.userDetails.sName}`}
					returnRoute={returnRoute}
					newAgreement={this.state.wpAmount === 0}
					description={this.state.wpAmount > 0 ? 'Outstanding Balance' : undefined}
					debtGuid={this.state.debtGuid}
					isInDebt={this.state.debtGuid && this.state.wpAmount > 0}
					isRedirect={false}
					handleParentHideModal={() => this.handlePostPayment()}
				/>

				<h4 className="pb-20 text-center text-400">Payment Details</h4>

				{/* Show this when card details are available */}
				{this.state.agreements.length > 0 && <div className="">
					<div className="table-responsive">
						<table className="table table-striped table-hover text-sm text-400">
							<thead>
								<tr>
									<th></th>
									<th>ID:</th>
									<th>Name:</th>
									<th>Description:</th>
									<th>Card type:</th>
									<th>Preference</th>
									<th>Status:</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{/* card row */}
								{this.state.agreements.map((agreement, index) => {
								  return <tr className={(agreement.lastPaymentStatus && agreement.lastPaymentStatus.trim()) === 'F' ? 'danger' : ''}>
										<td>
											{this.renderProviderLogo(agreement.provider)}
										</td>
										<td>{agreement.agreementID}</td>
										<td>{agreement.name}</td>
										<td>{agreement.agreementType}</td>
										<td>{agreement.cardType}</td>
										<td>{ordinal_suffix_of(index + 1)}</td>
										<td>
											{(agreement.lastPaymentStatus && agreement.lastPaymentStatus.trim() === 'F') &&
												<>
													<div className="badge badge-danger">Failing</div>
													<p className="text-xs m-0">
														Failing since: {moment(agreement.lastPayment).format('DD/MM/YYYY hh:mm').toString()}
													</p>
												</>
											}
											{(agreement.lastPaymentStatus && agreement.lastPaymentStatus.trim() === 'P') &&
												<div className="badge badge-success">Ok</div>
											}
											{(agreement.lastPaymentStatus && agreement.lastPaymentStatus.trim() === 'C') &&
												<div className="badge">Cancelled</div>
											}
										</td>
										<td><Button variant="cta" size="xs" onClick={e => this.setState({ editingAgreement: Object.assign({}, agreement, { preference: index + 1 }) })}>Edit</Button></td>
									</tr>
								})}
							</tbody>
						</table>
					</div>
				</div>}
				{/* End of: Show this when card details are available */}

				<div id="billingDetails" className="">
					<div className="text-center">
						{/* Show below paragraph if there's no card details present */}
						{this.state.agreements.length === 0 && <p className="alert alert-danger text-danger text-sm">
							It looks like you don&apos;t have any payment methods added. Click the
							below button to add a new payment method.
						</p>}

						{this.state.isLoading
						  ? <LoadingNotification className="mt-10" message={'Please wait...'} textColor="text-darker" height="30px" isLoading={true} />
						  :							<Button variant="cta" size="sm" onClick={e => this.handleCreateNewAgreementClicked()}>Add Payment Details</Button>
						}

					</div>
				</div>
				{/* End of: Show this if there's no card details present */}
				{this.renderEditPayment(ordinalArray)}

				<Modal className="modal-med question-modal text-center" show={this.state.showPaid} onHide={e => this.setState({ showPaid: false })}>
					<Modal.Body className="clearfix">
						<div className="icon-wrapper mt-20 mb-20 icon-success">
							<span className="icon icon-checkmark"></span>
						</div>
						<h4 className="text-500">Outstanding debt settled</h4>
						<p>Your outstanding payment of &pound;{this.state.wpAmount.toFixed(2)} has been settled.</p>
						<p>You can find more information and download a copy of your <br className="d-none d-sm-block" />invoices on your <Link to="/customer/profile/billing">profile page</Link>.</p>
						<p className="mt-30">
							<Button variant="default" onClick={e => this.setState({ showPaid: false })} style={{ float: 'none' }}>Close</Button>
						</p>
					</Modal.Body>
				</Modal>

				{(this.props.services.signupSummary && this.props.services.displayUnfinishedSignupWarning) &&
					<Modal show={this.state.showAbandonedSignup} backdrop="static" size="sm">
						<Modal.Header>
							<Modal.Title className="mx-auto text-lg">Continue where you left off</Modal.Title>
						</Modal.Header>
						<Modal.Body className="text-center">
							<p className="text-md text-400">Looks like you abandoned your previous checkout!</p>
							<p>
								You don&apos;t currently have a package on your account.<br />
								Do you want to continue with your <span className="text-500">{this.props.services.signupSummary.plan} plan</span>?
							</p>
							<p><span className="text-500">Summary:</span><br />
								Numbers:<br />
								{this.props.services.signupSummary.numbers.map(number => <span>{number}<br /></span>)}
								<span className="text-500">Monthly Total: &pound;{this.props.services.signupSummary.total}</span>
							</p>
							<Link to={`/get-started/${this.props.services.signupSummary.signupKey}`} className="btn btn-cta mt-20">Continue Checkout</Link>
							<Button variant="link" size="sm" className="mt-15 underline" onClick={() => this.setState({ showAbandonedSignup: false }) }>Cancel checkout</Button>
						</Modal.Body>
						<Modal.Footer>
							<p className="text-sm text-center">Alternatively, you can get in touch with us on <br className="d-none d-md-block" /><a href="tel:02031891213" className="text-cta underline">0203 189 1213</a> or <a className="text-cta underline" href="javascript:window.lhnJsSdk.openHOC('c');">live chat</a></p>
						</Modal.Footer>
					</Modal>
				}
			</div>
    )
  }

  handlePostPayment () {
    const context = this

    this.setState({ isLoading: false }, () => {
      context.getBillingAgreements(context)
      context.props.handleHidePaymentOptionsModal()
    })
  }

  componentDidMount () {
    const context = this

    if (localStorage.getItem('signupData') && !context.state.signupData) {
      const data = JSON.parse(localStorage.getItem('signupData'))
      context.setState({ signupData: data, basket: data.basket })
    }

    this.getBillingAgreements(context)

    if (context.props.userDetails) {
      const details = context.props.userDetails
      context.setState({ userDetails: details })

      if (!details.email || !details.fName || !details.sName) {
        fetchHelper.getJson('api/Users/GetClientDetailsForCheckout')
          .then(res => {
            const obj = {
              email: res.data.email,
              fName: res.data.fname,
              sname: res.data.sname
            }
            context.setState({ userDetails: obj })
          }).catch(err => console.error(err))
      }
    }

    const newAgreement = getQueryStringByParameter('new')
    if (newAgreement && !this.state.checkDebts) {
      const context = this
      context.setState({ checkDebts: true })
      context.props.notify({ message: 'Thank you, your new agreement is set up' })
    }
  }

  getBillingAgreements () {
    const context = this
    const url = 'api/Profile/GetBillingAgreements'

    fetchHelper.getJson(url).then(response => {
      context.setState({ agreements: response.data })
    }).catch(error => console.error(error))
  }

  renderEditPayment (ordinalArray) {
    const agreement = this.state.editingAgreement
    return <Modal show={agreement} onHide={e => this.setState({ editingAgreement: undefined })}>
			<Modal.Header closeButton>
				<Modal.Title>Edit agreement</Modal.Title>
			</Modal.Header>
			{agreement && <Modal.Body>
				<div className="row bb-1 mb-30 pb-10 text-center">
					<div className="col-md-6">
						<p className="text-400">Agreement ID: {agreement.agreementID}</p>
					</div>
					<div className="col-md-6">
						<p className="text-400">Agreement Provider: {agreement.provider}</p>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<p>Description <input className="form-control" type='text' value={agreement.name} onChange={e => this.setState({ editingAgreement: { ...agreement, name: e.target.value } })} /></p>
					</div>
					<div className="col-md-6">
						<p>Preference <select className="form-control" onChange={e => this.setState({ editingAgreement: { ...agreement, preference: Number(e.target.value) } })}>{ordinalArray.map((prefOption, index) => {
						  return <option selected={agreement.preference === prefOption.value}
								value={prefOption.value}>{prefOption.label}</option>
						})}</select></p>
					</div>
				</div>
				<div className="row text-center">
					<div className="col-md-12">
						<p className="p-30">We will use your preference to order how we bill your agreements (For example, we will try an agreement with a preference of "1st", before "2nd", or "3rd" etc)</p>
					</div>
				</div>
			</Modal.Body>}
			<Modal.Footer>
				<Button variant="cta" disabled={!(this.state.editingAgreement && this.state.editingAgreement.name)} onClick={e => this.saveUpdatedAgreement()}>Save Changes</Button>
				<Button variant="default" className="float-start" onClick={e => this.setState({ editingAgreement: undefined })}>Close</Button>
			</Modal.Footer>
		</Modal>
  }

  saveUpdatedAgreement () {
    const context = this
    const url = '/api/Profile/UpdateAgreement'

    const editingAgreement = this.state.editingAgreement
    const agreements = this.state.agreements

    const newArray = _.reduce(_.range(0, agreements.length + 1), function (array, index) {
      const originalPositionIndex = _.findIndex(agreements, agreement => agreement.agreementID === editingAgreement.agreementID)
      const switchingItemIndex = (editingAgreement.preference - 1) // the new position of the item in the array based on the preference selected

      if (originalPositionIndex > switchingItemIndex) {
        if (index < switchingItemIndex) // for any items below the new position leave as they are
        {
          array.push(agreements[index])
        } else if (index === switchingItemIndex) {
          array.push(editingAgreement) // when we have a match on the new position return the edited item
        } else if (index > switchingItemIndex) {
          if (agreements[index - 1].agreementID !== editingAgreement.agreementID) // we don&apos;t return the edited item at i&apos;s original position otherwise we'd see it twice
          {
            array.push(agreements[index - 1]) // for any items that appear after the new position that the edited item has been inserted into then return the item after applying an offset to account for the shift
          }
        }
      } else if (originalPositionIndex < switchingItemIndex) {
        if (index !== originalPositionIndex) {
          if (index === editingAgreement.preference) {
            array.push(editingAgreement)
          } else if (index < originalPositionIndex) {
            array.push(agreements[index])
          } else if (index > originalPositionIndex && index < editingAgreement.preference) {
            array.push(agreements[index])
          } else if (index > editingAgreement.preference) {
            array.push(agreements[index - 1])
          }
        }
      }

      return array
    }, [])

    const updatedAgreementDataAndNewPositions = Object.assign({}, Object.assign({ agreementID: editingAgreement.agreementID }, { name: editingAgreement.name }), { preference: _.map(newArray, function (agreement) { return agreement.agreementID }) })

    fetchHelper.postJson(url, updatedAgreementDataAndNewPositions).then(res => {
      if (res.data) {
        context.setState({ agreements: res.data, editingAgreement: undefined })
        context.props.notify({ message: 'Agreement updated' })
      } else {
        context.props.notify({ message: 'Error changing agreement details, please contact support', isError: true })
      }
    }).catch(function (error) {
      context.props.notify({ message: 'Error changing agreement details, please contact support', isError: true })
    })
  }

  handleCreateNewAgreementClicked () {
    if (this.props.services.signupSummary && this.props.services.displayUnfinishedSignupWarning) {
      this.setState({ showAbandonedSignup: true })
    } else {
      this.setState({ newAgreement: true, isLoading: true }, () => {
        fetchHelper.getJson('api/Home/GetUpdateBillingDetailsInfoWithClientId').then(response => {
          const code = response.data.code
          this.setState({ debtGuid: code })

          fetchHelper.getJson(`api/Home/GetUpdateBillingDetailsInfo/${code}`).then(response => {
            this.setState({
              isLoading: false
            }, () => {
              if (response.data.clientId > 0) {
                const debt = response.data?.amountOwedTotal ?? 0 > 0 // Check if customer has outstanding debt.
                this.setState({ wpAmount: calculateVat(debt) })

                localStorage.setItem('clientId', response.data.clientId)

                this.props.handleShowPaymentOptionsModal()
              }
            })
          }).catch(error => console.error(error))
        }).catch(error => console.error(error))
      })
    }
  }
}

export default connect(
  state => {
    return {
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    handleShowPaymentOptionsModal,
    handleHidePaymentOptionsModal,
    notify: handleNotificationChange
  }
)(BillingAgreements)
