import React, { useEffect } from 'react'
import {
  Button,
  Form
} from 'react-bootstrap'
import Select from 'react-select'
import {
  AddressLimit,
  postcodeApiKey, validationStates
} from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import {
  isValidNameSyntax,
  isValidPostcode
} from '../helpers/validationHelpers'
import {
  type AddressDto, type PostcodeLookupDto,
  type ValueLabelSelectOption
} from '../models/GenericTypes'
import {
  type AddressValidationDto
} from '../models/SignupTypes'

interface AddressLookupProps {
  deliveryAddress: AddressDto
  billingAddress: AddressDto
  showDeliveryOnly?: boolean
  handleUpdateAddress: (data: AddressDto, isBilling?: boolean) => void
  padding?: string
  showDeliveryAddress: boolean
  isLoggedIn?: boolean | undefined
}

interface AddressLookupState {
  sameAddress: boolean
  focusPostcode: boolean
  focusManualAdd1: boolean
  focusManualAdd2: boolean
  focusManualTown: boolean
  focusManualCounty: boolean
  focusManualPcode: boolean
  showManualAddressEntry: boolean
  selectedBillingAddress: boolean
  showDropdown: boolean
  selectedDeliveryAddress: boolean
  properties: Array<ValueLabelSelectOption<string, string>>
  showPcodeError: boolean
  rawAddressData: PostcodeLookupDto | undefined
}

interface ValidationState {
  deliveryAddressValidation: AddressValidationDto
  billingAddressValidation: AddressValidationDto
}

const AddressLookup: React.FunctionComponent<AddressLookupProps> = (
  props: AddressLookupProps
): JSX.Element => {
  // let basket: Basket = props.currentBasket;

  const initialState: AddressLookupState = {
    sameAddress: true,
    focusPostcode: false,
    focusManualAdd1: false,
    focusManualAdd2: false,
    focusManualTown: false,
    focusManualCounty: false,
    focusManualPcode: false,
    showManualAddressEntry: false,
    selectedBillingAddress: false,
    showDropdown: false,
    selectedDeliveryAddress: false,
    properties: [],
    showPcodeError: false,
    rawAddressData: undefined
  }

  const initialValidationState: ValidationState = {
    deliveryAddressValidation: {
      name: validationStates.UNCHECKED,
      add1: validationStates.UNCHECKED,
      add2: validationStates.UNCHECKED,
      add3: validationStates.UNCHECKED,
      pcode: validationStates.UNCHECKED
    },
    billingAddressValidation: {
      name: validationStates.UNCHECKED,
      add1: validationStates.UNCHECKED,
      add2: validationStates.UNCHECKED,
      add3: validationStates.UNCHECKED,
      pcode: validationStates.UNCHECKED
    }
  }

  const [state, setState] = React.useState<AddressLookupState>(initialState)
  const [validationState, setValidationState] = React.useState<ValidationState>(initialValidationState)

  useEffect(() => {
    if (state.sameAddress && !props.showDeliveryOnly && validationState.billingAddressValidation?.pcode === validationStates.UNCHECKED) {
      if (props.billingAddress?.pcode !== '' || props.billingAddress?.pcode !== undefined) {
        validateAddressDetails({ newValue: props.billingAddress.pcode, fieldName: 'pcode', isBilling: state.sameAddress && !props.showDeliveryOnly })
      }
    } else if ((props.deliveryAddress?.pcode !== '' || props.deliveryAddress?.pcode !== undefined) && validationState.deliveryAddressValidation?.pcode === validationStates.UNCHECKED) {
      validateAddressDetails({ newValue: props.deliveryAddress.pcode, fieldName: 'pcode', isBilling: state.sameAddress && !props.showDeliveryOnly })
    }
  }, [])

  function renderComponent () {
    return (
			<>
				<div className={`row ${props.padding ? props.padding : 'p-30'}`}>
					{!props.showDeliveryOnly &&
						<div className="col-xs-12">
							<span className="separator-block block-dark block-lg text-400 mt-0 mb-30 text-md text-center bg-light">
								<span className="bg-light">Billing Address</span>
							</span>
						</div>
					}

					{state.selectedBillingAddress && !props.showDeliveryOnly &&
						renderSelectedBillingAddress()
					}

					{(!state.sameAddress || props.showDeliveryOnly === true) &&
						renderSelectedDeliveryAddress()
					}

					{state.showDropdown && !state.showManualAddressEntry &&
						renderAddressSelection()
					}

					{((props.showDeliveryOnly && !state.selectedDeliveryAddress && !state.showDropdown) || (!props.showDeliveryOnly && !state.showManualAddressEntry && ((!state.showDropdown && !state.selectedBillingAddress) || (!state.showDropdown && !state.sameAddress && !state.selectedDeliveryAddress)))) &&
						renderPostcodeEntry()
					}

					{(state.showManualAddressEntry) &&
						renderManualAddressEntry()
					}

				</div>
			</>

    )
  }

  function renderSelectedBillingAddress () {
    return (
			<>
				<div className="col-xs-12">
					<div className="bg-dull b-1-dark p-20">
						<div className="row">
							<div className="col-xs-12">
								<p className="text-400">
									{props.billingAddress.add1}<br />
									{props.billingAddress?.add1?.length > 0 ? <span>{props.billingAddress.add2}<br /></span> : ''}
									{props.billingAddress.add3}<br />
									{props.billingAddress.add4 ? <span>{props.billingAddress.add4}<br /></span> : ''}
									{props.billingAddress.pcode}
								</p>
							</div>
							{props.showDeliveryAddress &&
								<div className="col-xs-12">
									<label className="pl-30 text-400 mt-10 text-md">
										Deliver to the same address
										<span className="styled-checkbox float-start" style={{ margin: '-12px 0 0 -30px' }}>
											<Form.Check inline
												checked={state.sameAddress}
												onChange={e => { setState({ ...state, sameAddress: !state.sameAddress }) }} />
										</span>
									</label>
								</div>
							}
							<div className="col-xs-12 bt-1 mt-10 pt-20">
								<span className="text-500 cta-hover text-secondary cursor-pointer text-sm" onClick={() => { handleChangeBillingAddress() }}>CHANGE BILLING ADDRESS</span>
							</div>
						</div>
					</div>
				</div>
			</>
    )
  }

  function renderSelectedDeliveryAddress () {
    return (
			<>
				{(props.showDeliveryOnly === true || props.showDeliveryAddress) &&
					<div className="col-xs-12 mt-30">
						<span className="separator-block block-dark block-lg text-400 mt-0 mb-30 text-md text-center">
							<span className="bg-light">Delivery Address</span>
						</span>
					</div>
				}

				{state.selectedDeliveryAddress &&
					<>
						<div className="col-xs-12">
							<div className="bg-dull b-1-dark p-20">
								<div className="row">
									<div className="col-xs-12">
										<p className="text-400">
											{props.deliveryAddress.add1}<br />
											{props.deliveryAddress.add2 ? <span>{props.deliveryAddress.add2}<br /></span> : ''}
											{props.deliveryAddress.add3}<br />
											{props.deliveryAddress.add4 ? <span>{props.deliveryAddress.add4}<br /></span> : ''}
											{props.deliveryAddress.pcode}
										</p>
									</div>
									<div className="col-xs-12 bt-1 mt-10 pt-20">
										<span className="text-500 cta-hover text-secondary cursor-pointer text-sm" onClick={() => { handlechangeDeliveryAddress() }}>CHANGE DELIVERY ADDRESS</span>
									</div>
								</div>
							</div>
						</div>
					</>
				}
			</>
    )
  }

  function renderAddressSelection () {
    return (
			<div className="col-xs-12">
				<Select
					className="form-control select-menu text-start h-60px mb-20 text-xs"
					classNamePrefix="custom"
					placeholder="Select your address"
					onChange={e => { handleSetAddress(e, false) }}
					options={state.properties}
					// simpleValue
					value={undefined}
					isSearchable={true}
					isClearable={false}
					menuIsOpen={true}
				/>
				<p className="text-sm underline cta-hover cursor-pointer" onClick={() => { setState({ ...state, showManualAddressEntry: true }) }}>
					Can't find your address? Enter it manually.
				</p>
			</div>
    )
  }

  function renderPostcodeEntry () {
    return (
      <Form className="row" onSubmit={e => { e.preventDefault(); handleAddressLookup(state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.pcode ?? '' : props.deliveryAddress.pcode ?? '') } }>
				<div className="col-xxs-12 col-xs-6">
					<Form.Group className={`mb-10 form-group animated ${(state.focusPostcode || (state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.pcode?.length > 0 : props.deliveryAddress.pcode?.length > 0)) ? 'focused' : ''}`}>
						<Form.Label>Postcode</Form.Label>
						<Form.Control
							value={state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.pcode ?? '' : props.deliveryAddress.pcode ?? ''}
							maxLength={12}
							onFocus={() => { setState({ ...state, focusPostcode: true }) }}
							onBlur={e => {
							  validateAddressDetails({ newValue: e.target.value, fieldName: 'pcode', isBilling: state.sameAddress && !props.showDeliveryOnly })
							  setState({ ...state, focusPostcode: false })
							}}
							isInvalid={state.sameAddress && !props.showDeliveryOnly ? validationState.billingAddressValidation?.pcode === validationStates.INVALID : validationState.deliveryAddressValidation?.pcode === validationStates.INVALID}
							isValid={state.sameAddress && !props.showDeliveryOnly ? validationState.billingAddressValidation?.pcode === validationStates.VALID : validationState.deliveryAddressValidation?.pcode === validationStates.VALID}
							onChange={e => {
							  if (state.sameAddress && !props.showDeliveryOnly) {
							    props.handleUpdateAddress({ ...props.billingAddress, pcode: e.target.value }, true)
							    setState({ ...state, showPcodeError: false })
							  } else {
							    props.handleUpdateAddress({ ...props.deliveryAddress, pcode: e.target.value })
							    setState({ ...state, showPcodeError: false })
							  }
							}} />

						<Form.Control.Feedback type="invalid" className="text-400 pl-15">
							Please enter a valid postcode
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className="col-xxs-12 col-xs-6">
					<Button variant="secondary" className="btn-block postcode" size="lg" onClick={() => { handleAddressLookup(state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.pcode ?? '' : props.deliveryAddress.pcode ?? '') }}>
						<span className="text-sm">
							Find <span className="d-none d-md-inline-block"> address</span>
						</span>
					</Button>
				</div>
				{state.showPcodeError &&
					<div className="col-xs-12">
						<p className="text-danger text-sm">
							Unfortunately, we can't find any addresses with this postcode. Please try again, <a className="underline text-dark" onClick={() => { setState({ ...state, showManualAddressEntry: true }) }}>or enter your address manually</a>.
						</p>
					</div>
				}
			</Form>
    )
  }

  function renderManualAddressEntry () {
    return (
			<Form>
				<div className="col-sm-12">
					<Form.Group className={`mb-10 animated ${(state.focusManualAdd1 || (state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add1?.length > 0 : props.deliveryAddress.add1?.length > 0) ? 'focused' : '')}`}>
						<Form.Label>Address Line 1</Form.Label>
						<Form.Control
							value={state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add1 ?? '' : props.deliveryAddress.add1 ?? ''}
							onFocus={() => { setState({ ...state, focusManualAdd1: true }) }}
							onBlur={e => {
							  validateAddressDetails({ newValue: e.target.value, fieldName: 'address', isBilling: state.sameAddress && !props.showDeliveryOnly })
							  setState({ ...state, focusManualAdd1: false })
							}}
							isInvalid={state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add1.length > AddressLimit : props.deliveryAddress.add1.length > AddressLimit}
							onChange={e => { state.sameAddress && !props.showDeliveryOnly ? props.handleUpdateAddress({ ...props.billingAddress, add1: e.target.value }, true) : props.handleUpdateAddress({ ...props.deliveryAddress, add1: e.target.value }) }} />
						<Form.Control.Feedback type="invalid" className="text-400 pl-15">
							Address line 1 is {state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add1.length - AddressLimit : props.deliveryAddress.add1.length - AddressLimit} characters too long.
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className="col-sm-12">
					<Form.Group className={`mb-10 animated ${(state.focusManualAdd2 || (state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add2?.length > 0 : props.deliveryAddress.add2.length > 0) ? 'focused' : '')}`}>
						<Form.Label>Address Line 2</Form.Label>
						<Form.Control
							value={state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add2 ?? '' : props.deliveryAddress.add2 ?? ''}
							onFocus={() => { setState({ ...state, focusManualAdd2: true }) }}
							onBlur={e => { setState({ ...state, focusManualAdd2: false }) }}
							isInvalid={state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add2?.length > AddressLimit : props.deliveryAddress.add2?.length > AddressLimit}
							onChange={e => { state.sameAddress && !props.showDeliveryOnly ? props.handleUpdateAddress({ ...props.billingAddress, add2: e.target.value }, true) : props.handleUpdateAddress({ ...props.deliveryAddress, add2: e.target.value }) }} />

						<Form.Control.Feedback type="invalid" className="text-400 pl-15">
							Address line 2 is {state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add2?.length - AddressLimit : props.deliveryAddress.add2?.length - AddressLimit} characters too long.
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className="col-sm-12">
					<Form.Group className={`mb-10 animated ${(state.focusManualTown || (state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add3?.length > 0 : props.deliveryAddress.add3?.length > 0) ? 'focused' : '')}`}>
						<Form.Label>Town / City</Form.Label>
						<Form.Control
							value={state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.add3 ?? '' : props.deliveryAddress.add3 ?? ''}
							onFocus={() => { setState({ ...state, focusManualTown: true }) }}
							onBlur={e => {
							  validateAddressDetails({ newValue: e.target.value, fieldName: 'town', isBilling: state.sameAddress && !props.showDeliveryOnly })
							  setState({ ...state, focusManualTown: false })
							}}
							isInvalid={state.sameAddress ? validationState.billingAddressValidation?.add3 === validationStates.INVALID : validationState.deliveryAddressValidation?.add3 === validationStates.INVALID}
							onChange={e => { state.sameAddress && !props.showDeliveryOnly ? props.handleUpdateAddress({ ...props.billingAddress, add3: e.target.value }, true) : props.handleUpdateAddress({ ...props.deliveryAddress, add3: e.target.value }) }} />

						<Form.Control.Feedback type="invalid" className="text-400 pl-15">
							Please enter a valid town
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className="col-sm-12">
					<Form.Group className={`mb-10 animated ${(state.focusManualPcode || (state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.pcode?.length > 0 : props.billingAddress.pcode && props.deliveryAddress.pcode?.length > 0) ? 'focused' : '')}`}>
						<Form.Label>Postcode</Form.Label>
						<Form.Control
							value={state.sameAddress && !props.showDeliveryOnly ? props.billingAddress.pcode ?? '' : props.deliveryAddress.pcode ?? '' }
							onFocus={() => { setState({ ...state, focusManualPcode: true }) }}
							onBlur={e => {
							  validateAddressDetails({ newValue: e.target.value, fieldName: 'pcode', isBilling: state.sameAddress && !props.showDeliveryOnly })
							  setState({ ...state, focusManualPcode: false })
							}}
							isInvalid={state.sameAddress && !props.showDeliveryOnly ? validationState.billingAddressValidation?.pcode === validationStates.INVALID : validationState.deliveryAddressValidation?.pcode === validationStates.INVALID}
							onChange={e => { state.sameAddress && !props.showDeliveryOnly ? props.handleUpdateAddress({ ...props.billingAddress, pcode: e.target.value }, true) : props.handleUpdateAddress({ ...props.deliveryAddress, pcode: e.target.value }) }} />

						<Form.Control.Feedback type="invalid" className="text-400 pl-15">
							Please enter a valid postcode
						</Form.Control.Feedback>

					</Form.Group>
				</div>
				<div className="col-xs-12 text-center mt-10 mb-30">
					<Button size="lg" variant="cta" onClick={() => { handleSetAddress(undefined, true) }} disabled={state.sameAddress && !props.showDeliveryOnly
					  ? (validationState.billingAddressValidation?.add1 !== validationStates.VALID || validationState.billingAddressValidation?.add3 !== validationStates.VALID || validationState.billingAddressValidation?.pcode !== validationStates.VALID)
					  : (validationState.deliveryAddressValidation?.add1 !== validationStates.VALID || validationState.deliveryAddressValidation?.add3 !== validationStates.VALID || validationState.deliveryAddressValidation?.pcode !== validationStates.VALID)}
					>
						Save {state.sameAddress && !props.showDeliveryOnly ? 'Billing Address' : 'Delivery Address'}
					</Button>
				</div>
				<div className="col-xs-12 text-center">
					<p className="text-sm underline cta-hover cursor-pointer" onClick={() => { setState({ ...state, showManualAddressEntry: false, showDropdown: false }) }}>
						Search address
					</p>
				</div>
			</Form>
    )
  }

  function validateAddressDetails (context: { newValue: string, fieldName: string, isBilling?: boolean }) {
    const val = context.newValue.trim()
    const field = context.fieldName
    const isBilling = context.isBilling
    let validFieldState = val?.length > 0 ? validationStates.VALID : validationStates.INVALID

    switch (field) {
      case 'name':
        validFieldState = validationStates.fromBool(isValidNameSyntax(val))
        if (isBilling) {
          setValidationState({
            ...validationState,
            billingAddressValidation: {
              ...validationState.billingAddressValidation,
              name: validFieldState
            }
          })
        } else {
          setValidationState({
            ...validationState,
            deliveryAddressValidation: {
              ...validationState.billingAddressValidation,
              name: validFieldState
            }
          })
        }
        break
      case 'address':
        if (isBilling) {
          setValidationState({
            ...validationState,
            billingAddressValidation: {
              ...validationState.billingAddressValidation,
              add1: validFieldState
            }
          })
        } else {
          setValidationState({
            ...validationState,
            deliveryAddressValidation: {
              ...validationState.billingAddressValidation,
              add1: validFieldState
            }
          })
        }
        break
      case 'town':
        if (isBilling) {
          setValidationState({
            ...validationState,
            billingAddressValidation: {
              ...validationState.billingAddressValidation,
              add3: validFieldState
            }
          })
        } else {
          setValidationState({
            ...validationState,
            deliveryAddressValidation: {
              ...validationState.billingAddressValidation,
              add3: validFieldState
            }
          })
        }
        break
      case 'pcode':
        validFieldState = validationStates.fromBool(isValidPostcode(val))
        if (isBilling) {
          setValidationState({
            ...validationState,
            billingAddressValidation: {
              ...validationState.billingAddressValidation,
              pcode: validFieldState
            }
          })
        } else {
          setValidationState({
            ...validationState,
            deliveryAddressValidation: {
              ...validationState.billingAddressValidation,
              pcode: validFieldState
            }
          })
        }
        break
    }
  }

  function handleChangeBillingAddress () {
    setState({ ...state, showDropdown: false, selectedBillingAddress: false, selectedDeliveryAddress: false, sameAddress: true })
    handleEmptyAddress(true)
  }

  function handlechangeDeliveryAddress () {
    setState({ ...state, showDropdown: false, selectedDeliveryAddress: false })
    handleEmptyAddress(false)
  }

  function handleEmptyAddress (isBilling: boolean) {
    props.handleUpdateAddress({
      add1: '',
      add2: '',
      add3: '',
      add4: '',
      pcode: '',
      name: '',
      notes: ''
    }, isBilling)
  }

  function handleAddressLookup (postcode) {
    if (validationState.billingAddressValidation?.pcode === validationStates.VALID || validationState.deliveryAddressValidation?.pcode === validationStates.VALID) {
      // All lgood
      const url = '//pcls1.craftyclicks.co.uk/json/rapidaddress'
      fetchHelper.getJson<PostcodeLookupDto>(`${url}/?key=${postcodeApiKey}&postcode=${postcode}`, '', true)
        .then(res => {
          if (res.data.thoroughfares.length > 0) {
            const properties: any[] = []
            const tf = res.data.thoroughfares[0]

            tf.delivery_points.sort((a, b) => parseFloat(a.building_number) - parseFloat(b.building_number)).forEach((info, i) => {
              properties.push({
                label: `
                                    ${info.organisation_name ? `${info.organisation_name},` : info.sub_building_name ? `${info.sub_building_name},` : `${info.building_number},`}
                                    ${info.building_name ? `${info.building_name},` : ''}
                                    ${tf.thoroughfare_name} ${tf.thoroughfare_descriptor}${res.data.dependent_locality
? `, 
                                    ${res.data.dependent_locality}`
: ''}, 
                                    ${res.data.town}
                                    `,
                value: i
              })
            })

            setState({ ...state, rawAddressData: res.data, properties, showDropdown: true })
          } else {
            // No data
            setState({ ...state, showPcodeError: true })
          }
        }).catch(err => { console.error(err) })
    } else {
      // Not good
      setState({ ...state, showPcodeError: true })
    }
  }

  function handleSetAddress (e, manual) {
    if (manual) {
      if (state.sameAddress && !props.showDeliveryOnly) {
        setState({ ...state, selectedBillingAddress: true, selectedDeliveryAddress: false, showDropdown: false, showManualAddressEntry: false })
      } else {
        setState({ ...state, selectedDeliveryAddress: true, showDropdown: false, showManualAddressEntry: false })
      }
    } else {
      const raw = state.rawAddressData
      const tf = raw!.thoroughfares[0]
      const dp = tf.delivery_points[e.value]

      const address: AddressDto = {
        add1: dp.organisation_name?.length > 0
          ? dp.organisation_name
          : dp.sub_building_name?.length > 0
            ? `${dp.sub_building_name}, ${dp.building_number}`
            : `${dp.building_number}, ${tf.thoroughfare_name} ${tf.thoroughfare_descriptor}`,
        add2: dp.building_name?.length > 0 ? dp.building_name : raw!.dependent_locality,
        add3: raw!.town,
        add4: raw!.postal_county,
        pcode: raw!.postcode,
        name: '',
        notes: ''
      }

      if (state.sameAddress && !props.showDeliveryOnly) {
        props.handleUpdateAddress(address, true)

        setState({ ...state, selectedBillingAddress: true, selectedDeliveryAddress: false, showDropdown: false })
      } else {
        props.handleUpdateAddress(address, false)

        setState({ ...state, selectedDeliveryAddress: true, showDropdown: false })
      }
    }
  }

  return renderComponent()
}
export default AddressLookup
