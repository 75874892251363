import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import AboutMenu from '../../components/AboutMenu'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

class TeamPage extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader /><AboutMenu />
            <Helmet>
                <title>Meet The Team - SwitchboardFREE</title>
                <meta name="description" content="Find out more about the SwitchboardFREE team, meet a few of them here." />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/7-competitor-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Meet the team</h1>
                <h2 className="subtitle">Say hello to the people behind SwitchboardFREE</h2>
            </header>
            <div className="container-styled pt-60 bg-dull">
                <div className="container team-gallery">
                    <div className="row row-flex flex-4-col flex-sm-2-col no-flex-grow flex-sm-grow flex-md-grow mb-50 mb-xs-0">
                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 jay-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Katie McSweeney</h5>
                            <h6 className="text-400 mt-0">Customer Services</h6>
                            <p className="pt-10 pb-30">As part of the SwitchboardFREE Team, Katie will be able to assist you with new and existing account enquires ensuring that you get the most out of your SwitchboardFREE experience.</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 zoe-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Zoe King</h5>
                            <h6 className="text-400 mt-0">Customer Services</h6>
                            <p className="pt-10 pb-30">Zoe’s aim is to deliver fantastic Customer Service and Support to our valued customers.  She strives to complete all tasks that come her way and enjoys the challenge!</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 chrisv-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Chris Venables</h5>
                            <h6 className="text-400 mt-0">CTO</h6>
                            <p className="pt-10 pb-30">Chris is tasked with turning the weird and whacky ideas of the product development team into secure, solid and robust commercial products.</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 george-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">George Saint</h5>
                            <h6 className="text-400 mt-0">Technical Team</h6>
                            <p className="pt-10 pb-30">George works within the SwitchboardFREE technical team helping to develop and support internal business tools and our cloud hosted websites.</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 bryn-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Bryn Thompson</h5>
                            <h6 className="text-400 mt-0">Marketing Director</h6>
                            <p className="pt-10 pb-30">Bryn runs the online marketing activities of SwitchboardFREE, making sure as many people as possible know about this great free online service.</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 christ-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Chris Thrower</h5>
                            <h6 className="text-400 mt-0">Design Manager</h6>
                            <p className="pt-10 pb-30">As design manager team Chris is responsible for creating and maintaining the look and feel of SwitchboardFREE.</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 seb-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Seb Szostkiewicz</h5>
                            <h6 className="text-400 mt-0">Design Team</h6>
                            <p className="pt-10 pb-30">Seb designs and maintains the look and feel of everything related to SwitchboardFREE.</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 ben-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Ben Regan</h5>
                            <h6 className="text-400 mt-0">Technical &amp; Tester</h6>
                            <p className="pt-10 pb-30">Ben supports and maintains a number of internal systems in addition to testing SwitchboardFREE updates to make sure they are issue free.</p>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 rebecca-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Rebecca Dowsett</h5>
                            <h6 className="text-400 mt-0">Finance &amp; Administration</h6>
                            <p className="pt-10 pb-30">Aswell as handling general SwitchboardFREE customer support calls Rebecca also looks after Administration and reseller payments.</p>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 steve-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Steve O'Leary</h5>
                            <h6 className="text-400 mt-0">Technical Team</h6>
                            <p className="pt-10 pb-30">Steve works within the SwitchboardFREE technical team helping to develop and support some of our cutting edge systems.</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3 text-center pb-xs-60 b-1 pt-30 brad-20 pb-0 mb-30 bg-light zoom">
                            <div className="icon-block mb-10 mike-photo"></div>
                            <h5 className="text-500 mb-0 mt-20">Mike Knights</h5>
                            <h6 className="text-400 mt-0">Technical Team</h6>
                            <p className="pt-10 pb-30">Mike works on the back end systems for SwitchboardFREE, from writing code for the website to maintaining the hardware that powers our system.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(TeamPage)
