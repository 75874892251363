import React from 'react'
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import { productBillingOption, ProductIds } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculatePartialMonthRefund, calculateRemainingMonth, checkUserHasCallRecording } from '../../helpers/utils'
import * as fromHome from '../../store/Home'
import * as fromNumbers from '../../store/MyNumbers'
import * as fromSbf from '../../store/SBF'
import CallRecordingBenefits from '../CallRecordingBenefits'
import CallTranscriptionActivation from '../CallTranscriptionActivation'
import PaymentMethods from './PaymentMethods'

class CallRecordingPackages extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showRecordingPackageChange: false,
      newPackage: {
        name: '',
        firstPayment: 0,
        repeatPayment: 0,
        productId: 0,
        optionId: 0
      },
      callTranscriptionActive: false,
      callTranscriptionPurchaseOptionSelected: false,
      addingToAccount: false
    }
  }

  trigger () {
    debugger
  }

  getPrices () {
    this.props.getProductDetails([
      { productId: ProductIds.callRecordAll, monthlyOption: productBillingOption.callRecordingAllBillingOptionMonthly },
      { productId: ProductIds.callRecordAll, monthlyOption: productBillingOption.callRecordingUnlimitedBillingOptionMonthly }
    ])
  }

  componentDidMount () {
    const url = 'api/CallTranscription/HasCallTranscriptionTurnedOn'
    fetchHelper.getJson(url)
      .then(res => {
        this.setState({ callTranscriptionActive: res.data })
      }).catch(err => console.error(err))

    this.getPrices()
  }

  render () {
    const hasCallRecordingAll = this.props.userServices
      ? this.props.userServices.hasCallRecordingAll
      : this.props.services ? this.props.services.hasCallRecordingAll : false

    const hasCallRecording = this.props.userServices ? checkUserHasCallRecording(this.props.userServices) : this.props.services ? checkUserHasCallRecording(this.props.services) : false

    const hasUnlimitedCallRecording = this.props.userServices ? this.props.userServices.hasUnlimitedCallRecording : this.props.services ? this.props.services.hasUnlimitedCallRecording : false

    const crAllPrice = { firstPayment: 0, repeatPayment: 0 }
    const crUnlimitedPrice = { firstPayment: 0, repeatPayment: 0 }
    if (this.props.productDetails) {
      const crAll = this.props.productDetails.find(prod => prod.productId === ProductIds.callRecordAll && prod.billingOptionId === productBillingOption.callRecordingAllBillingOptionMonthly)
      const crUnlimited = this.props.productDetails.find(prod => prod.productId === ProductIds.callRecordAll && prod.billingOptionId === productBillingOption.callRecordingUnlimitedBillingOptionMonthly)
      if (crAll) {
        crAllPrice.firstPayment = crAll.monthlyPrice.firstPaymentAmount
        crAllPrice.repeatPayment = crAll.monthlyPrice.repeatPaymentAmount
      }
      if (crUnlimited) {
        crUnlimitedPrice.firstPayment = crUnlimited.monthlyPrice.firstPaymentAmount
        crUnlimitedPrice.repeatPayment = crUnlimited.monthlyPrice.repeatPaymentAmount
      }
    } else {
      this.getPrices()
    }

    let buttonText = 'Add to my account'
    if (this.props.isBasket) { buttonText = 'Add to basket' }
    if (hasCallRecording && hasCallRecordingAll) { buttonText = 'Upgrade to Unlimited' }

    return <>
			<div
				className={` ${this.props.callRecordingPackagesWrapperClass === undefined ? 'full-width full-secondary bg-overlay text-lightest pt-30 pb-40' : this.props.callRecordingPackagesWrapperClass}`}
				style={{ backgroundImage: "url('/images/backgrounds/call-recording.jpg')", backgroundPosition: 'right' }}
			// style={this.props.callRecordingPackagesWrapperStyle === undefined ? {backgroundImage: "url('/images/backgrounds/call-recording.jpg')", backgroundPosition: 'right'} : {callRecordingPackagesWrapperStyle}}

			>
				<div className="w-900px center-block container text-center">

					{hasCallRecording === false
					  ? <>
							{this.renderStartRecordingYourCallsText(this.props.showTicks)}
							{this.renderSelectCallRecordingPackages(false, crAllPrice, crUnlimitedPrice, hasCallRecording, buttonText)}
						</>
					  :						<>
							{this.state.callTranscriptionActive === true
							  ? <>
									{hasUnlimitedCallRecording === true && this.renderCallTranscriptionActive(hasUnlimitedCallRecording)}
									{!hasUnlimitedCallRecording && this.renderUpgradeCallRecordingPackageScreen()}
									{!hasUnlimitedCallRecording && this.renderSelectCallRecordingPackages(hasCallRecordingAll, crAllPrice, crUnlimitedPrice, hasCallRecording, buttonText)}
								</>
							  :								<>
									{hasUnlimitedCallRecording === false
									  ? <>
											{this.renderUpgradeCallRecordingPackageScreen()}
											{this.renderSelectCallRecordingPackages(hasCallRecordingAll, crAllPrice, crUnlimitedPrice, hasCallRecording, buttonText)}
											{this.renderBuyCallTranscription()}
										</>
									  :										<>
											{this.renderCallRecordingActiveText(hasUnlimitedCallRecording)}
											{this.renderBuyCallTranscription()}
										</>
									}
								</>
							}
						</>
					}

					{this.props.purchaseCancelled &&
						<div className="text-start p-20">
							<CallRecordingBenefits />
						</div>
					}

					<PaymentMethods hideIcons={true} />
				</div>
			</div>

			<Modal size="sm" show={this.state.showRecordingPackageChange} onHide={() => this.setState({ showRecordingPackageChange: false })}>
				<Modal.Header className="text-center" closeButton>
					<Modal.Title>Change your package</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>You're about to switch to the <i>{this.state.newPackage.name}</i> package. Your new monthly payment for call recording will be &pound;{this.state.newPackage.repeatPayment}.</p>
					<p className="text-center mb-0 text-500 pt-20">Are you sure you want to change your package?</p>
				</Modal.Body>
				<Modal.Footer className="bt-0 pb-50">
					<Button variant="cta" onClick={() => this.handlePurchaseCallRecording(this.state.newPackage.firstPayment, this.state.newPackage.optionId, this.state.newPackage.productId, hasCallRecording, this.state.newPackage.repeatPayment)}>Yes, change my package</Button>
				</Modal.Footer>
			</Modal>

			<ChoosePayment
				total={this.state.wpAmount}
				clientId={this.props.clientId}
				purchaseData={this.state.key}
				returnRoute="/customer/call-recording/"
				email={this.props.userDetails.email}
				name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
				mandate={!this.props.services.hasAgreement}
			/>

		</>
  }

  renderTurnOnCallTranscription () {
    return <div className="text-center b-1 well well-transparent p-20 brad-20 mt-20 mb-20 w-400px center-block text-lightest">
			<h5 className="text-500 mt-0 pt-0 text-lightest">
				Search for words spoken during your calls
				<OverlayTrigger trigger={['hover', 'focus']} placement="top"
					overlay={
						<Popover>
							<ul className="fancy text-start cta text-400 mt-20 pl-40">
								<li className="pb-10">
									<strong>Boost staff productivity,</strong> by ensuring employees spend less time on admin & more time on engaging with customers.
								</li>
								<li className="pb-10">
									<strong>Easily analyse data,</strong> & keep up-to-date with your customer's needs, preferences & pain points to deliver consistent & exceptional customer service.
								</li>
								<li className="pb-10">
									<strong>Enhance your decision-making,</strong> by uncovering valuable insights within your call data to optimise your communication & marketing strategies.
								</li>
								<li className="pb-10">
									<strong>Remain compliant,</strong> by easily checking that staff are passing on essential information or terms & conditions to your customers.
								</li>
								<li className="pb-10">
									<strong>Simplify training & quality control,</strong> by revisiting past calls to highlight best practices, identify areas for improvement, or educate new team members.
								</li>
							</ul>
						</Popover>
					}
				>
					<span className="icon icon-info2 text-xs ml-5"></span>
				</OverlayTrigger>
			</h5>
			<p className="text-sm">Use our call transcription with your call recordings,<br /><i>just 5p per minute.</i></p>
			<CallTranscriptionActivation isBox={false} customToggleWrapperClass="mt-20 mb-10" />
		</div>
  }

  renderBuyCallTranscription () {
    return <>
			<div className="center-block mt-30 w-600px b-1 p-30 brad-20 mb-50">
				<h4 className="text-500 mt-0 mb-20">
					Search for words spoken or not spoken during a call with Call Transcription
				</h4>

				<p>
					As you have call recording enabled on your account, would you like you calls to be automatically transcribed? <i>For just 5p per minute.</i>
				</p>
				<ul className="fancy text-start cta text-400 mt-20 pl-60">
					<li className="pb-10">
						<strong>Boost staff productivity,</strong> by ensuring employees spend less time on admin & more time on engaging with customers.
					</li>
					<li className="pb-10">
						<strong>Easily analyse data,</strong> & keep up-to-date with your customer's needs, preferences & pain points to deliver consistent & exceptional customer service.
					</li>
					<li className="pb-10">
						<strong>Enhance your decision-making,</strong> by uncovering valuable insights within your call data to optimise your communication & marketing strategies.
					</li>
					<li className="pb-10">
						<strong>Remain compliant,</strong> by easily checking that staff are passing on essential information or terms & conditions to your customers.
					</li>
					<li className="pb-10">
						<strong>Simplify training & quality control,</strong> by revisiting past calls to highlight best practices, identify areas for improvement, or educate new team members.
					</li>
				</ul>
				<CallTranscriptionActivation
					isBox={false}
					customToggleWrapperClass="mt-40 mb-20 b-2 brad-20 p-30 w-300px center-block"
					clickedNotNowMaybeLater={() => this.setState({ clickedNotNowMaybeLaterOnCallTranscription: true })}
					activateCallTranscriptionClicked={() => this.setState({ callTranscriptionActive: true })}
					deactivateCallTranscriptionClicked={() => this.setState({ callTranscriptionActive: false })}
					callTranscriptionActive={this.state.callTranscriptionActive}
				/>
			</div>
		</>
  }

  renderStartRecordingYourCallsText (showTicks) {
    return <div className="mb-30 mt-30">

			{this.props.PackagesCustomIntroText === undefined
			  ? <>
					<h2 className="text-500 mb-0">Start recording your calls</h2>
					<h5 className="text-400 mb-40 mt-0">Select your package to get started</h5>
				</>
			  :				this.props.PackagesCustomIntroText
			}

			{showTicks &&
				<div className="text-start w-900px center-block">
					<p className="text-500 text-left pl-50 pb-10 pt-30">Call recording quick facts...</p>
					<ul
						className={`fancy text-start text-400 cta pl-50 pr-50 ${this.props.ticksTextColour === undefined ? 'text-lightest' : this.props.ticksTextColour}`}>
						<div className="row">
							<div className="col-xs-6">
								<li className="pb-10">
									Call recording works across all your numbers
								</li>
								<li className="pb-10">
									Inbound &amp; outbound calls are recorded
								</li>
								<li className="pb-10">
									Enable or disable for specific number or user
								</li>
							</div>
							<div className="col-xs-6">
								<li className="pb-10">
									Recording audio file is sent to your email
								</li>
								<li className="pb-10">
									Add notes, download, listen online on our website
								</li>
								<li className="pb-10">
									Use our advanced search to find specific recording
								</li>
							</div>
						</div>
					</ul>
				</div>
			}
		</div>
  }

  renderSelectCallRecordingPackages (onlyShowUnlimitedPackage, crAllPrice, crUnlimitedPrice, hasCallRecording, buttonText) {
    const showRibbons = true

    const context = this

    return <>

			<div className="w-900px center-block">
				<div className={`row row-flex flex-center flex-sm p-20 ${onlyShowUnlimitedPackage ? 'flex-auto' : ''}`}>

					{/* EVERY NUMBER PACKAGE */}
					{onlyShowUnlimitedPackage === false &&
						<div
							// className="col-md-4 mt-sm-20 well well-transparent mr-30 mr-sm-0 mb-40"
							className={` ${this.props.StandardPackageCustomClass === undefined ? 'col-md-4 mt-sm-20 well well-transparent mr-30 mr-sm-0 mb-40' : this.props.StandardPackageCustomClass}`}
							style={{ position: 'relative' }}>
							<h3
								className={`pt-20 text-500 ${this.props.headingsTextColour === undefined ? 'text-lightest' : this.props.headingsTextColour}`}>Standard</h3>
							<p>
								Record all calls (Inbound &amp; Outbound)<br /> across all your numbers!
							</p>
							{/* <p className="text-500"><i>First month FREE + 30 days FREE storage</i></p> */}
							<h4 className={`text-500 pt-10 pb-10 ${this.props.priceTextColour === undefined ? 'text-lightest' : this.props.priceTextColour}`}>
								{crAllPrice.firstPayment === crAllPrice.repeatPayment
								  ? <>
										&pound;{crAllPrice.repeatPayment} <span className="text-xs">per month *</span>
									</>
								  :									<>
										{showRibbons || crAllPrice.firstPayment === 0
										  ? (showRibbons || (!crUnlimitedPrice.firstPayment === 0))
										      ? <img src="/images/tags/tag5.png" className="new-tag" alt="1st month free and 30 days free storage" style={{ width: '180px', left: '-25px', top: '-25px' }} />
										      :												''
										  :											`Only £${crAllPrice.firstPayment} `
										}
										&pound;{crAllPrice.repeatPayment} per month
									</>
								}
							</h4>

							{(this.state.callTranscriptionActive === false) && <div className="text-center b-1 well well-transparent p-20 brad-20 mt-20 mb-20 w-400px center-block text-lightest">
								<h6 className={`text-500 mt-0 pt-0 ${this.props.CallTranscriptionHeadingCustomClass === undefined ? 'text-dark' : this.props.CallTranscriptionHeadingCustomClass}`}>
									Search for words and phrases spoken or missing during call with Call Transcription
									<OverlayTrigger trigger={['hover', 'focus']} placement="top"
										overlay={
											<Popover>
												<ul className="fancy text-start cta text-400 mt-20 pl-40">
													<li className="pb-10">
														<strong>Boost staff productivity,</strong> by ensuring employees spend less time on admin & more time on engaging with customers.
													</li>
													<li className="pb-10">
														<strong>Easily analyse data,</strong> & keep up-to-date with your customer's needs, preferences & pain points to deliver consistent & exceptional customer service.
													</li>
													<li className="pb-10">
														<strong>Enhance your decision-making,</strong> by uncovering valuable insights within your call data to optimise your communication & marketing strategies.
													</li>
													<li className="pb-10">
														<strong>Remain compliant,</strong> by easily checking that staff are passing on essential information or terms & conditions to your customers.
													</li>
													<li className="pb-10">
														<strong>Simplify training & quality control,</strong> by revisiting past calls to highlight best practices, identify areas for improvement, or educate new team members.
													</li>
												</ul>
											</Popover>
										}
									>
										<span className="icon icon-info2 text-xs ml-5"></span>
									</OverlayTrigger>
								</h6>
								<p className="text-sm">Automatically transcribe your call recordings, <i>just 5p per minute.</i></p>
								<CallTranscriptionActivation isBox={false} disableApiCall={true} valueOverride={this.state.callTranscriptionPurchaseOptionSelected || this.props.preselectTranscription} activateCallTranscriptionClicked={() => this.setState({ callTranscriptionPurchaseOptionSelected: true })} deactivateCallTranscriptionClicked={() => this.setState({ callTranscriptionPurchaseOptionSelected: false })} customToggleWrapperClass="mt-20 mb-10" />
							</div>}

							{/* {this.renderBuyCallTranscription()} */}
							<div className="text-center">
								<Button variant="cta" className="mt-20 mb-20" size="md" disabled={this.state.addingToAccount} onClick={() => hasCallRecording ? context.setState({ showRecordingPackageChange: true, newPackage: { name: 'Call Recording (All Numbers)', firstPayment: crAllPrice.firstPayment, repeatPayment: crAllPrice.repeatPayment, optionId: productBillingOption.callRecordingAllBillingOptionMonthly, productId: ProductIds.callRecordAll } }) : context.handlePurchaseCallRecording(crAllPrice.firstPayment, productBillingOption.callRecordingAllBillingOptionMonthly, ProductIds.callRecordAll, hasCallRecording, crAllPrice.repeatPayment, 'all numbers')}>
									{buttonText}
								</Button>
							</div>

							<p className="text-sm pb-20">No long-term contracts, cancel at any time.</p>
							<p className="text-xs pb-20">
								All recordings are stored for 30 days free of change and can be downloaded or removed at any time, after 30 days storage is charged at 1p per minute / per month for recordings you don’t delete.
							</p>
						</div>
					}

					<div
						className={` ${onlyShowUnlimitedPackage === true ? 'w-600px mt-sm-20 well well-transparent mr-0 mb-40' : 'col-md-4 mt-sm-20 well well-transparent mr-0 mb-40' && this.props.UnlimitedPackageCustomClass === undefined ? 'col-md-4 mt-sm-20 well well-transparent mr-0 mb-40' : this.props.UnlimitedPackageCustomClass}`}
						style={{ position: 'relative' }}
					>
						<h3 className="pt-20 text-500">Unlimited</h3>

						<p>
							Record all calls (Inbound &amp; Outbound)<br /> across all your numbers. Plus, <span className="text-600">free storage</span>
						</p>
						{/* <p className="text-500"><i>First month is <del>{crUnlimitedPrice.repeatPayment}</del> FREE!</i></p> */}
						<h4 className="text-500 pt-10 pb-10">
							{crUnlimitedPrice.firstPayment === crUnlimitedPrice.repeatPayment
							  ? <>
									&pound;{crUnlimitedPrice.repeatPayment} <span className="text-xs">per month *</span>
								</>
							  :								<>
									{showRibbons || crUnlimitedPrice.firstPayment === 0
									  ? (showRibbons || (!crAllPrice.firstPayment === 0))
									      ? <img src="/images/tags/tag6.png" className="new-tag" alt="1st month free and 30 days free storage" style={{ width: '180px', left: '-25px', top: '-25px' }} />
									      :											''
									  :										`Only &pound;${crUnlimitedPrice.firstPayment} `
									}
									&pound;{crUnlimitedPrice.repeatPayment} per month
								</>
							}
						</h4>
						{(this.state.callTranscriptionActive === false) && <div className="text-center b-1 well well-transparent p-20 brad-20 mt-20 mb-20 w-400px center-block text-lightest">
							<h6 className={`text-500 mt-0 pt-0 ${this.props.CallTranscriptionHeadingCustomClass === undefined ? 'text-dark' : this.props.CallTranscriptionHeadingCustomClass}`}>
								Search for words and phrases spoken or missing during call with Call Transcription
								<OverlayTrigger trigger={['hover', 'focus']} placement="top"
									overlay={
										<Popover>
											<ul className="fancy text-start cta text-400 mt-20 pl-40">
												<li className="pb-10">
													<strong>Boost staff productivity,</strong> by ensuring employees spend less time on admin & more time on engaging with customers.
												</li>
												<li className="pb-10">
													<strong>Easily analyse data,</strong> & keep up-to-date with your customer's needs, preferences & pain points to deliver consistent & exceptional customer service.
												</li>
												<li className="pb-10">
													<strong>Enhance your decision-making,</strong> by uncovering valuable insights within your call data to optimise your communication & marketing strategies.
												</li>
												<li className="pb-10">
													<strong>Remain compliant,</strong> by easily checking that staff are passing on essential information or terms & conditions to your customers.
												</li>
												<li className="pb-10">
													<strong>Simplify training & quality control,</strong> by revisiting past calls to highlight best practices, identify areas for improvement, or educate new team members.
												</li>
											</ul>
										</Popover>
									}
								>
									<span className="icon icon-info2 text-xs ml-5"></span>
								</OverlayTrigger>
							</h6>
							<p className="text-sm">Automatically transcribe your call recordings, <i>just 5p per minute.</i></p>
							<CallTranscriptionActivation isBox={false} disableApiCall={true} valueOverride={this.state.callTranscriptionPurchaseOptionSelected || this.props.preselectTranscription} activateCallTranscriptionClicked={() => this.setState({ callTranscriptionPurchaseOptionSelected: true })} deactivateCallTranscriptionClicked={() => this.setState({ callTranscriptionPurchaseOptionSelected: false })} customToggleWrapperClass="mt-20 mb-10" />
						</div>}
						<div className="text-center">
							<Button variant="cta" className="mt-20 mb-20" size="md" disabled={this.state.addingToAccount} onClick={() => hasCallRecording ? this.setState({ showRecordingPackageChange: true, newPackage: { name: 'Call Recording Unlimited', firstPayment: crUnlimitedPrice.firstPayment, repeatPayment: crUnlimitedPrice.repeatPayment, optionId: productBillingOption.callRecordingUnlimitedBillingOptionMonthly, productId: ProductIds.callRecordAll } }) : this.handlePurchaseCallRecording(crUnlimitedPrice.firstPayment, productBillingOption.callRecordingUnlimitedBillingOptionMonthly, ProductIds.callRecordAll, hasCallRecording, crUnlimitedPrice.repeatPayment, 'unlimited')}>
								{buttonText}
							</Button>
						</div>

						<p className="text-sm pb-20">No long-term contracts, cancel at any time.</p>
						<p className="text-xs pb-20">
							Includes 6-years of unlimited call recording storage.
						</p>
					</div>

				</div>
			</div>
		</>
  }

  renderCallRecordingActiveText (hasUnlimitedCallRecording) {
    return <div className="mb-30 mt-30">
			<h4 className="text-500">{hasUnlimitedCallRecording === true ? 'Unlimited' : 'Standard'} Call Recording has been activated!</h4>
			<p>From now on, all your calls will be recorded.</p>
		</div>
  }

  renderUpgradeCallRecordingPackageScreen () {
    return <div className="mb-30 mt-30">
			<h2 className="text-500">Upgrade your package</h2>
			<p className="text-md">Currenntly you&apos;re using the Standard package... Ready to upgrade to Unlimited?</p>
		</div>
  }

  renderCallTranscriptionActive (hasUnlimitedCallRecording) {
    return <>
			<div className="center-block well well-transparent mt-30 w-600px b-1 p-30 brad-20">
				<h4 className="text-500 mb-20">Call Transcription &amp; {hasUnlimitedCallRecording === true ? 'Unlimited' : 'Standard'} Call Recording has been activated!</h4>
				<p>From now on, all new call recordings will be automatically transcribed. All transcription will be available on your call recording page.</p>
				<Button variant="cta" size="lg" className="mt-20 mb-20" onClick={() => this.props.history.push('/customer/call-recording/')}>Go to my recordings</Button>
			</div>
		</>
  }

  handlePurchaseCallRecording (amount, optionId, prodId, hasCallRecording) {
    if (this.props.userServices && this.props.userServices.displayBillingWarning) {
      if (this.props.handleShowBillingWarning) {
        this.props.handleShowBillingWarning()
      }
    } else {
      if (hasCallRecording === true) {
        // User is upgrading, so we need to adjust the invoice amount
        fetchHelper.getJson('api/Users/GetCustomersBillingDay').then(async (res) => {
          const billingDay = res.data
          const lastPayment = await fetchHelper.getJson('api/CallRecording/GetLastPaymentAmountForCustomer')
          const lastPaymentAmount = Math.max(lastPayment.data / 1.2) // Remove VAT from price
          const partialRefundAmount = calculatePartialMonthRefund(lastPaymentAmount, billingDay)
          amount = parseFloat(calculateRemainingMonth(amount, billingDay) - partialRefundAmount)
          this.handlePurchase(amount, optionId, prodId)
        }).catch(err => console.error(err))
      } else {
        this.handlePurchase(amount, optionId, prodId)
      }
    }
  }

  handlePurchase (amount, optionId, prodId) {
    this.setState({ addingToAccount: true })
    const context = this
    const url = 'api/Purchase/PurchaseCallRecording'

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.postJson(url, { ProductId: prodId, billingOption: optionId !== null && optionId > 0 ? optionId : this.props.billingOption ? this.props.billingOption : productBillingOption.callRecordingAllBillingOptionMonthly, IsUnlimited: optionId === productBillingOption.callRecordingUnlimitedBillingOptionMonthly, Amount: amount }).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          this.enableCallRecording(context, optionId)
          this.turnOnCallTranscriptionIfSelected()
        } else if (res.data && res.data.postponed === true) {
          this.enableCallRecording(context, optionId)
          this.turnOnCallTranscriptionIfSelected()
          context.props.handleShowPaymentPostponedModal()
        } else {
          context.setState({ showRecordingPackageChange: false, wpAmount: amount, key: res.data.key, isPurchaseInProgress: false }, () => {
            context.props.handleShowPaymentOptionsModal()
          })
        }
        context.setState({ addingToAccount: false })
      }).catch(err => context.setState({ addingToAccount: false }))
    })
  }

  turnOnCallTranscriptionIfSelected () {
    if (this.state.callTranscriptionActive === false && this.state.callTranscriptionPurchaseOptionSelected === true) {
      const url = 'api/CallTranscription/TurnOnCallTranscription'
      fetchHelper.postJson(url, {})
        .catch(err => console.error(err))
    }
  }

  enableCallRecording (context, optionId) {
    if (context.props.handleDismissCallRecordingDialog) {
      context.props.handleDismissCallRecordingDialog()
    }
    context.setState({ isPurchaseInProgress: false, showRecordingPackageChange: false })
    if (optionId === productBillingOption.callRecordingUnlimitedBillingOptionMonthly) {
      if (context.props.handleCallRecordingUnlimitedEnableChange) {
        context.props.handleCallRecordingUnlimitedEnableChange(true)
      }
    } else {
      if (context.props.handleCallRecordingAllEnableChange) {
        context.props.handleCallRecordingAllEnableChange(true)
      }
    }
    context.props.notify({ message: 'Call Recording Has Been Added' })
  }
}

export default withRouter(connect(
  state => {
    return {
      productDetails: state.home.productDetails.products,
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    handleCallRecordingUnlimitedEnableChange: fromSbf.handleCallRecordingUnlimitedEnableChange,
    handleCallRecordingAllEnableChange: fromSbf.handleCallRecordingAllEnableChange,
    getProductDetails: fromHome.getProductPrice,
    notify: fromSbf.handleNotificationChange,
    handleShowPaymentOptionsModal: fromSbf.handleShowPaymentOptionsModal,
    handleDismissCallRecordingDialog: fromNumbers.handleDismissCallRecordingDialog,
    handleShowPaymentPostponedModal: fromSbf.handleShowPaymentPostponedModal,
    handleShowBillingWarning: fromSbf.handleShowBillingWarning
  }
)(CallRecordingPackages))
