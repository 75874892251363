import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchHelper } from '../helpers/fetchHelper'

class CallbackRequest extends Component {
  state = {
    modalActive: false,
    buttonStyle: this.props.buttonStyle === undefined ? 'cta' : this.props.buttonStyle,
    buttonText: this.props.buttonText === undefined ? 'Request callback' : this.props.buttonText,
    buttonSize: this.props.buttonSize === undefined ? 'md' : this.props.buttonSize,
    subject: this.props.subject === undefined ? 'Callback request' : this.props.subject, // custom email subject line. For example: subject="Outbound upgrade request"
    url: window.location.href === undefined ? 'no url' : window.location.href, // shows full URL on the email, so we know from which page the user clicked from.
    ccEmails: this.props.ccEmails === null ? null : this.props.ccEmails, // type in email addresses to cc them as well, once users submits the form, for example: ccEmails="seb@liquid11.co.uk,sales@liquid11.co.uk,john@gmail.com"
    bccEmails: this.props.bccEmails === null ? null : this.props.bccEmails, // type in email addresses to bcc (Any recipients on the Bcc line of an email are not visible to others on the email) them as well, once users submits the form, for example: bccEmails="seb@liquid11.co.uk,sales@liquid11.co.uk,john@gmail.com"
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    fullName: this.props.firstName ? this.props.firstName : '' + ' ' + this.props.lastName ? this.props.lastName : '',
    email: this.props.email === undefined ? null : this.props.email,
    tel: this.props.telephone,
    timeToCall: '',
    title: this.props.title === undefined ? 'Request a callback' : this.props.title // modal popup top heading
  }

  render () {
    return (
      this.props.isForm
        ? this.state.callbackSent
          ? <p className="text-center"><strong>Thanks, your callback request has been sent.</strong></p>
          :					<>
						<Form onSubmit={this.handleCallbackSubmit}>
							<div className="mx-auto col-12">
								<div className="row">
									{this.props.intro &&
										<p className="text-500 mb-50">{this.props.intro}</p>
									}

									<div className={`mb-20 ${this.props.formFullWidth === true ? 'col-md-12' : 'col-md-6'}`}>

										<Form.Group>
											<Form.Control
												className="text"
												placeholder='Name'
												value={this.state.fullName}
												onChange={e => this.setState({ fullName: e.target.value })} />
										</Form.Group>
									</div>
									<div className={`mb-20 ${this.props.formFullWidth === true ? 'col-md-12' : 'col-md-6'}`}>
										<Form.Group>
											<Form.Control
												type="tel"
												className="text"
												placeholder='Telephone'
												value={this.state.tel}
												onChange={e => this.setState({ tel: e.target.value })} />
										</Form.Group>
									</div>
								</div>
								<div className="row">
									<div className={`mb-20 ${this.props.formFullWidth === true ? 'col-md-12' : 'col-md-6'}`}>
										<Form.Group>
											<Form.Control
												className="text"
												placeholder='Email'
												value={this.state.email}
												onChange={e => this.setState({ email: e.target.value })} />
										</Form.Group>
									</div>

									{!this.props.hideTime &&
										<div className={`mb-20 ${this.props.formFullWidth === true ? 'col-md-12' : 'col-md-6'}`}>
											<select className="select form-control" placeholder="select" onChange={e => this.setState({ timeToCall: e.target.value })}>
												<option value="">Best time to call</option>
												<option value="Before 9am">Before 9</option>
												<option value="Between 9-12">Between 9-12</option>
												<option value="Between 12-2">Between 12-2</option>
												<option value="Between 2-5">Between 2-5</option>
												<option value="After 5">After 5</option>
											</select>
										</div>

									}
									<div className="col-md-12 text-center">
										<Button type="submit" variant="cta" size={this.state.buttonSize} className="btn-mobile-block mt-20" disabled={!this.state.email || !this.state.tel && (!this.state.fullName)} type="submit">Submit</Button>
									</div>
								</div>
							</div>
						</Form>
					</>
        :				<>
					{this.props.textLink
					  ? <a onClick={() => this.setState({ modalActive: !this.state.modalActive })} className={`${this.props.class ? this.props.class : 'text-400'}`}>{this.state.buttonText}</a>
					  :						<Button variant={this.state.buttonStyle} size={this.state.buttonSize} onClick={() => this.setState({ modalActive: !this.state.modalActive })} className={this.props.classNames}>{this.state.buttonText}</Button>
					}
					<Form onSubmit={this.handleCallbackSubmit} style={{ display: 'inline-block' }}>
						<Modal show={this.state.modalActive} className="modal-small" onHide={() => this.setState({ modalActive: false })}>
							<Modal.Header closeButton>
								<Modal.Title>{this.state.title}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								{this.state.callbackSent
								  ? <p className="text-center"><strong>Thanks, your request has been sent.</strong></p>
								  :									<>
										{/* <div className="hidden">
                                        <p url={this.state.url}>{this.state.url}</p>
                                        <p subject={this.state.subject}>{this.state.subject}</p>
                                        <p ccEmails={this.state.ccEmails}>{this.state.ccEmails}</p>
                                        <p bccEmails={this.state.bccEmails}>{this.state.bccEmails}</p>
                                    </div> */}

										<Form.Group className="mb-10">
											<Form.Control
												className="text"
												placeholder='First Name'
												value={this.state.firstName}
												onChange={e => this.setState({ firstName: e.target.value })} />
										</Form.Group>
										<Form.Group className="mb-10">
											<Form.Control
												className="text"
												placeholder='Last Name'
												value={this.state.lastName}
												onChange={e => this.setState({ lastName: e.target.value })} />
										</Form.Group>
										<Form.Group className="mb-10">
											<Form.Control
												type="tel"
												className="text"
												placeholder='Telephone'
												value={this.state.tel}
												onChange={e => this.setState({ tel: e.target.value })} />
										</Form.Group>
										<Form.Group>
											<Form.Select value={this.state.webhookType} onChange={e => this.setState({ webhookType: e.target.value })}>
												<option value="">Best time to call</option>
												<option value="ASAP">ASAP</option>
												<option value="Before 9am">Before 9</option>
												<option value="Between 9-12">Between 9-12</option>
												<option value="Between 12-2">Between 12-2</option>
												<option value="Between 2-5">Between 2-5</option>
												<option value="After 5">After 5</option>
											</Form.Select>
										</Form.Group>
									</>
								}
							</Modal.Body>
							<Modal.Footer>
								{!this.state.callbackSent &&
									<p className="d-flex justify-content-center col-12">
										<Button variant="cta" className="text-uppercase" disabled={(!this.state.firstName || !this.state.lastName) || !this.state.tel} type="submit" onClick={this.handleCallbackSubmit}>
											Submit Request
										</Button>
									</p>
								}
								<p className="text-center mt-30 w-100 clearfix">
									<Button size="sm" variant="default" className="text-uppercase" onClick={() => this.setState({ modalActive: false })}>
										Close
									</Button>
								</p>
							</Modal.Footer>
						</Modal>
					</Form>
				</>
    )
  }

  handleCallbackSubmit = (e) => {
    e.preventDefault()

    const callback = Object.assign({},
      { subject: this.state.subject },
      { url: this.state.url },
      { ccEmails: this.state.ccEmails },
      { bccEmails: this.state.bccEmails },
      { name: this.props.isForm ? this.state.fullName : this.state.firstName + ' ' + this.state.lastName },
      { email: this.state.email },
      { telephone: this.state.tel },
      { time: this.state.timeToCall })

    const url = 'api/Home/CallbackRequest'

    fetchHelper.postJson(url, callback)
      .then(result => {
        this.setState({ callbackSent: result.data })
      })
      .catch(error => {
        console.error(error)
      })
  }
}

export default connect(
  state => {
  },
  {
  }
)(CallbackRequest)
