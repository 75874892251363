import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const SmsCallbackSectionHelperDialog = props => (
    <Modal show={props.isDisplayed}
           onHide={props.hideSmsCallbackHelperInstruction}>
        <Modal.Header closeButton>
            <Modal.Title>Callback SMS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                If you miss a call, our system can automatically send your caller a customised message from you.
            </p>

            <p>
                This can be used to send a short apology, with links to your website and/or email address, or a quick
                message explaining that you will call them back.
            </p>

            <p>
                <strong>Please note:</strong> Each SMS will be charged at 10p and will be taken from your account credit
                automatically. Therefore, it is recommended that you enable <a href="/customer/top-up" target="_blank">Auto Topup</a> on your account when using this feature.
            </p>
            <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/7mm3IF3HzVM"></iframe>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideSmsCallbackHelperInstruction}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.SmsCallbackInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmsCallbackSectionHelperDialog)
