import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import HandsetOrders from '../../components/leadgen/HandsetOrders'
import LiveChatBlock from '../../components/LiveChatBlock'
import PurchasePhones from '../../components/purchase/PurchasePhones'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import StatsBanner from '../../components/StatsBanner'
import { FanvilProdId, HeadsetId, ht201, t31p, w73p, x7a, FanvilDongle } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'
import { isLoggedIn } from '../../selectors'

class Devices extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showHandsetOrder: false
    }
  }

  scrollTo (section) {
    scroller.scrollTo(`${section}`, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  render () {
    const basket = getEmptyBasket()
    if (LocalStorageHelper.getItem('BasketHardware')?.value !== null) {
      basket.hardware = LocalStorageHelper.getItem('BasketHardware').value
    }

    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
			<Helmet>
				<title>Our VoIP Phones, Apps &amp; Devices</title>
				<meta name="description" content="Find out about SwitchboardFREE apps, get the free SwitchboardFREE app on your mobile devices and run your business from anywhere." />
				<style type="text/css">{`
                @media screen and (max-width:768px) {
                    .col-md-1-5.col-xs-4 {
                        width:33.33333333%;
                    }
                    .col-md-1-5.col-xs-6 {
                        width:50%;
                    }
                }
                @media screen and (max-width:600px) {
                    .col-md-1-5.col-xxs-6 {
                        width:50%;
                    }
                }
				@media screen and (max-width: 1400px){
                    .bg-img {
                        background-position: 100% 100%;
                    }
                }
                @media screen and (max-width: 1024px){
                    .bg-img {
                        background-image: none !important;
                    }
                }
				`}</style>
			</Helmet>

			<HandsetOrders showModal={this.state.showHandsetOrder} onHide={() => this.setState({ showHandsetOrder: false })} model={this.state.model} />

			{this.props.isLoggedIn &&
				<Modal className="full-screen products-modal" show={this.state.showEditDevices} onHide={() => this.setState({ showEditDevices: false })}>
					<Modal.Body className="bg-dull">
						<span className="close-modal" onClick={() => this.setState({ showEditDevices: false })}>
							<span>&times;</span>
						</span>

						<PurchasePhones
							displayInline={true}
							handleUpdateHardware={(hardware) => handleHandsetUpdated(hardware)}
							currentBasket={basket}
							showEditDevices={false}
							handleCloseDevicesModal={() => this.setState({ showEditDevices: false })}
							isSignup={false}
							hideFree={true}
							purchaseUrl="/api/Purchase/PurchasePhones"
							displayInstalments={true}
							notSlider={true}
							CustomDivClass="w-16 text-center p-10"
						/>

					</Modal.Body>
				</Modal>
			}

			<header style={{ backgroundImage: "url('/images/backgrounds/8-competitor-bg.jpg')" }} className="full-width full-hero-small with-text" >
				<h1>Unify Your Communications</h1>
				<h2 className=" subtitle">Choose the hardware &amp; software that matches your budget & business needs.</h2>
			</header>

			<div className="full-width bg-dull pb-50 pt-50 d-none d-md-block">
				<div className="container-fluid">
					<div className="row text-center">
						<div className="col-xs-12 pb-50">
							<h2 className="text-500">Run Your Businesses Anywhere</h2>
							<h4>
							Whether you choose a business-grade plug & play phone or the app, you’ll be able to seamlessly <br className="d-none d-md-block" />move between your office, home or even abroad.
                            </h4>
						</div>
						<div className="w-12 w-sm-25 center-block float-start p-20 cursor-pointer">
							<img src="/images/product-shots/ecom/T31P.jpg" alt="Yealink T31P VoIP Phone" className="img-thumbnail img-responsive w-250px" onClick={() => this.scrollTo('x3s')} />
							<p className="text-500 mt-15 mb-0">
							VoIP <br className="d-block d-xs-none" />Handset
                            </p>
							<p className="mt-0 mb-0">
								<Button variant="link" className="text-dark underline btn-sm" onClick={() => this.scrollTo('deskphone')}>Learn more</Button>
							</p>
						</div>
						<div className="w-12 w-sm-25 center-block float-start p-20 cursor-pointer">
							<img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U Deskphone" className="img-thumbnail img-responsive w-250px" onClick={() => this.scrollTo('deskphone')} />
							<p className="text-500 mt-15 mb-0">
							Advanced VoIP <br className="d-block d-xs-none" />Handset
                            </p>
							<p className="mt-0 mb-0">
								<Button variant="link" className="text-dark underline btn-sm" onClick={() => this.scrollTo('deskphone')}>Learn more</Button>
							</p>
						</div>
						<div className="w-12 w-sm-25 center-block float-start p-20 cursor-pointer">
							<img src="/images/product-shots/ecom/W73P.jpg" alt="Yealink W53P Cordless Handset" className="img-thumbnail img-responsive w-250px" onClick={() => this.scrollTo('wireless')} />
							<p className="text-500 mt-15 mb-0">
								Cordless VoIP <br className="d-block d-xs-none" />Handset
                            </p>
							<p className="mt-0 mb-0">
								<Button variant="link" className="text-dark underline btn-sm" onClick={() => this.scrollTo('wireless')}>Learn more</Button>
							</p>
						</div>
						<div className="w-12 w-sm-25 center-block float-start p-20 cursor-pointer">
							<img src="/images/product-shots/ecom/X7A.jpg" alt="Fanvil X7A Android touchscreen phone" className="img-thumbnail img-responsive w-250px" onClick={() => this.scrollTo('x7a')} />
							<p className="text-500 mt-15 mb-0">
								X7A Android <br className="d-block d-xs-none" />Touchscreen Phone
                            </p>
							<p className="mt-0 mb-0">
								<Button variant="link" className="text-dark underline btn-sm" onClick={() => this.scrollTo('x7a')}>Learn more</Button>
							</p>
						</div>
						<div className="w-12 w-sm-25 center-block float-start p-20 cursor-pointer">
							<img src="/images/product-shots/ecom/HT201.jpg" alt="Fanvil Headset" className="img-thumbnail img-responsive w-250px" onClick={() => this.scrollTo('headset')} />
							<p className="text-500 mt-15 mb-0">
								Compatible <br className="d-block d-xs-none" />Headset
                            </p>
							<p className="mt-0 mb-0">
								<Button variant="link" className="text-dark underline btn-sm" onClick={() => this.scrollTo('headset')}>Learn more</Button>
							</p>
						</div>
						<div className="w-12 w-sm-25 center-block float-start p-20 cursor-pointer">
							<img src="/images/product-shots/ecom/app.jpg" alt="SBF Phone App" className="img-thumbnail img-responsive w-250px" onClick={() => this.scrollTo('apps')} />
							<p className="text-500 mt-15 mb-0">
								Mobile <br className="d-block d-xs-none" />Apps
                            </p>
							<p className="mt-0 mb-0">
								<Button variant="link" className="text-dark underline btn-sm" onClick={() => this.scrollTo('apps')}>Learn more</Button>
							</p>
						</div>
						<div className="w-12 w-sm-25 center-block float-start p-20 cursor-pointer">
							<img src="/images/product-shots/ecom/softphone.jpg" alt="SwitchboardFREEE Softphone" className="img-thumbnail img-responsive w-250px" onClick={() => this.scrollTo('softphone')} />
							<p className="text-500 mt-15 mb-0">
								Softphone
                            </p>
							<p className="mt-0 mb-0">
								<Button variant="link" className="text-dark underline btn-xxs-block btn-sm" onClick={() => this.scrollTo('softphone')}>Learn more</Button>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-50 pt-50" id="x3s">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 pt-200 pt-sm-0 pt-xs-0 pull-sm-none text-center text-md-start">
							<img src="/images/product-shots/T31P.png" alt="T31P VoIP Handset" className="mh-sm-400px" style={{ maxWidth: '100%' }} />
						</div>
						<div className="col-12 col-lg-6 pt-30 float-none float-sm-end">
							<h2 className="text-500 text-center text-md-start">VoIP Handset</h2>

							<p className="lead text-400 pt-30 pt-xs-0">
								An up-to-date phone that is neither costly nor complicated.
                            </p>
							<p className="mb-30">
								Get the traditional office look with this intelligent VoIP-ready handset.
								It has been designed so you can seamlessly swap out your old office analogue
								phones without the need for you and your staff to learn new tech.
							</p>
							<div className="row">
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0">
										<li>
											User-friendly, just pick up & talk.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											You can sit at any desk with the trouble-free plug &amp; play technology.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											There’ll be no need to squint with the clear 2.3" 132 x 64-pixel graphical LCD.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Grow your business with 2 VoIP phone lines.
                                    	</li>
									</ul>
								</div>
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-xs-0 pt-sm-0">
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Feel free to put your new phone on a desk or on the wall.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Let your customers know it&apos;s you calling by presenting your SwitchboardFREE number on your outbound calls.
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Have peace of mind with Industrial Standard Certifications: CE/FCC.
                                        </li>
									</ul>
								</div>
							</div>
							<div className="plans pb-30">
								<div className="plan mb-sm-0 mb-xs-50">
									<span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={t31p} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
									<br />
									<span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
								</div>
							</div>
							<p className="pb-30">
								<Link to={'/products/voip-phone'} className="btn btn-lg btn-secondary mr-10 mr-xxs-0 mb-xxs-10 btn-xxs-block" id="VoipHandsetLearnMoreButton">Learn more</Link>
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(t31p)} id="VoipHandsetOrderNowButton">Order Now</Button>
							</p>
						</div>

					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-50 pt-50" id="deskphone">
				<div className="container">
					<div className="row flex-sm-row-reverse">
						<div className="col-12 col-lg-6 float-none float-md-end pt-150 pt-sm-0 pt-xs-0 text-center text-md-start">
							<img src="/images/product-shots/fanvil-x4u.png" alt="Fanvil X4U VoIP Phone" className="mh-sm-400px" style={{ maxWidth: '100%' }} />
						</div>
						<div className="col-12 col-lg-6 pl-50 pl-sm-15">
							<h2 className="text-500 text-center text-md-start">Advanced VoIP Handset</h2>

							<p className="lead text-400 pt-30 pt-xs-0">
								Separate business from pleasure with a smart desk phone.
                            </p>
							<p className="mb-30">
								Regain your work-life balance and move away from the distraction of business mobile phones.
								This professional-looking handset is a convenient alternative to the reliance on mobiles as it
								has the ability to access your cloud-based contact list in real time.
								You can also programme touch-call buttons for priority numbers and more besides!
                            </p>
							<div className="row">
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0">
										<li>
											Plug &amp; play technology offers the flexibility to work from the office or at home.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Avoid asking your customer to repeat themselves with crystal clear HD audio providing clarity with every call.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Have the flexibility to make &amp; receive calls from your SwitchboardFREE account.
                                        </li>
									</ul>
								</div>
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-xs-0 pt-sm-0">
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Let your customers know it&apos;s you calling by presenting your SwitchboardFREE number on your outbound calls.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Easily keep in contact with your staff with free calls between handsets & mobile app (globally).
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Give your customers an efficient service by redirecting incoming calls to any handset or app in your account.
                                        </li>
									</ul>
								</div>
							</div>
							<div className="plans pb-30">
								<div className="plan mb-sm-0 mb-xs-50">
									<span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={FanvilProdId} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
									<br />
									<span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
								</div>
							</div>

							<p className="pb-30">
								<Link to={'/products/advanced-voip-phone'} className="btn btn-lg btn-secondary mr-10 mr-xxs-0 mb-xxs-10 btn-xxs-block" id="AdvancedVoIPHandsetLearnMoreButton">Learn more</Link>
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(FanvilProdId)} id="AdvancedVoIPHandsetOrderNowButton">Order Now</Button>
							</p>
						</div>

					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-50 pt-50" id="wireless">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 pt-200 pt-sm-0 pt-xs-0 pull-sm-none text-center text-md-start">
							<img src="/images/product-shots/w73p.png" alt="Yealink W73P Cordless Handset" className="mh-sm-400px" style={{ maxWidth: '100%' }} />
						</div>
						<div className="col-12 col-lg-6 pt-30 float-none float-sm-end">
							<h2 className="text-500 text-center text-md-start">Cordless VoIP Handset</h2>

							<p className="lead text-400 pt-30 pt-xs-0">
								Get the freedom of being mobile without the business-mobile cost.
                            </p>
							<p className="mb-30">
								Supplying your staff with a company mobile phone can be expensive, and setting them up for work-only use can be difficult too.
								This cordless handset is the perfect alternative as it can be used flexibly anywhere within your office.
								Plus the push buttons will get you feeling nostalgic for those pre-touchscreen mobile phones we all had!
                            </p>
							<div className="row">
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0">
										<li>
										Answer &amp; go with this easy-to-use phone with an unobtrusive 1.8" colour display.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Say “Pardon sir” no more by hearing every call with exceptional HD sound.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Avoid battery life anxiety with 5-10 hours of talk time or 100 hours of standby time.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Make communications seamless with features like intercom, transfer, &amp; call forwarding.
                                        </li>
									</ul>
								</div>
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-xs-0 pt-sm-0">
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Keep your staff updated with free calls between handsets &amp; app, globally.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											There’s no need for a power adapter with the integrated PoE Class 1.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Have the flexibility to make & receive calls from your SwitchboardFREE account.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Let your customers know it&apos;s you calling by presenting your SwitchboardFREE number on your outbound calls.
                                        </li>
									</ul>
								</div>
							</div>
							<div className="plans pb-30">
								<div className="plan mb-sm-0 mb-xs-50">
									<span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={w73p} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
									<br />
									<span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
								</div>
							</div>
							<p className="pb-30">
								<Link to={'/products/yealink-w73p-cordless-phone'} className="btn btn-lg btn-secondary mr-10 mr-xxs-0 mb-xxs-10 btn-xxs-block" id="WirelessVoIPHandsetLearnMoreButton">Learn more</Link>
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(w73p)}>Order Now</Button>
							</p>
						</div>

					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-50 pt-50" id="x7a">
				<div className="container">
					<div className="row flex-sm-row-reverse">
						<div className="col-12 col-lg-6 float-none float-md-end pt-150 pt-sm-0 pt-xs-0 text-center">
							<img src="/images/product-shots/X7A.png" alt="Fanvil X7A Android touchscreen phone" className="mh-sm-400px" style={{ maxWidth: '100%' }} />
						</div>
						<div className="col-12 col-lg-6 pl-50 pl-sm-15">
							<h2 className="text-500 text-center text-md-start">X7A Android Touchscreen Phone</h2>

							<p className="lead text-400 pt-30 pt-xs-0">
								Embrace the comfort of a traditional phone but with all the bells &amp; whistles.
							</p>
							<p className="mb-30">
							Relive the days of cradling the phone between your neck and shoulders so you can talk &amp;
							 type and feel the release of stress as you slam the phone down after a frustrating call -
							 we’ve all done it! This high-end phone offers you the best of both worlds with its modern take on the
							 traditional look and feel, coupled with high-end sound, video and application ability.
                            </p>
							<p className="mb-30">
							In technical terms, this customer favourite is based on the Android 9.0 system
							so is easy to use and navigate. The 7-inch colour screen has touch technology,
							whilst the phone itself has built-in Bluetooth, a 2.4G/5G wi-fi module and
							integrates the USB 2.0 interface.
							</p>
							<div className="row">
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0">
										<li>
                                        	Run your business your way & have as many as 20 VoIP lines from different providers coming all into one phone.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                        	Have a mini meeting with the 3-way conference feature.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                        	Ensure all calls are answered by using your phone as a hotspot or central hub to connect any other phones you wish to ring at the same time.
                                        </li>
                                        <li className="pb-xs-10 pt-xs-10 brad-5">
                                        	See what’s what on the 7” capacity colour touch-screen.
                                        </li>
									</ul>
								</div>
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-xs-0 pt-sm-0">
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Streamline staff communication by directly dialling up to 112 extension numbers.
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Give your customer that face-to-face service with video codec H.264 for receiving video calls.
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Use the dual gigabit port to connect both your phone & PC to your router with one socket. Use the same wires to boost power with the integrated PoE.
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Get comfortable with your phone by adjusting it to sit at 40 or 50 degrees.
										</li>
									</ul>
								</div>
							</div>
							<div className="plans pb-30">
								<div className="plan mb-sm-0 mb-xs-50">
									<span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={x7a} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
									<br />
									<span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
								</div>
							</div>

							<p className="pb-30">
								<Link to={'/products/x7a-voip-phone'} className="btn btn-lg btn-secondary mr-10 mr-xxs-0 mb-xxs-10 btn-xxs-block" id="X7AAndroidTouchcreenPhoneLearnMoreButton">Learn more</Link>
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(x7a)} id="X7AAndroidTouchcreenPhoneBuyNowButton">Buy Now</Button>
							</p>
						</div>

					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-50 pt-50" id="FanvilDongle">
				<div className="container">
					<div className="row flex-sm-row-reverse">
						<div className="col-12 col-lg-6 float-none float-md-end pt-150 pt-sm-0 pt-xs-0 text-center">
							<img src="/images/product-shots/fanvil-dongle.png" alt="Fanvil Wifi Dongle Wf20" className="mh-sm-400px" style={{ maxWidth: '100%' }} />
						</div>
						<div className="col-12 col-lg-6 pl-50 pl-sm-15">
							<h2 className="text-500 text-center text-md-start">Fanvil USB WiFi Dongle</h2>

							<p className="lead text-400 pt-30 pt-xs-0">
							Connecting your VoIP phones to any available wireless networks.
							</p>
							<p className="mb-30">
							Your USB Wi-Fi dongle allows you to dispense with tangled wires and use your X4 phone anywhere that has Wi-Fi. Although small, it packs a powerful transmission rate of 150 Mbps ensuring maximum connectivity. Just plug it into your X4 phone and you’re good to go!
							</p>
							<div className="row">
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0">
										<li>
											Compatible with our <Link to="/products/advanced-voip-phone">Advanced VoIP Handset</Link>
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
										Easy to use, just plug & play 
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
										Flexible & mobile 
										</li>
									</ul>
								</div>
								<div className="col-12 col-lg-6">
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-xs-0 pt-sm-0">
										<li className="pb-xs-10 pt-xs-10 brad-5">
										Uses very little power 
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
										High transmission rate to keep you reliably connected 
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
										Compliant with the IEEE802.11n, IEEE802.11b/g, and 802.11ac standards 
										</li>
									</ul>
								</div>
							</div>
							<div className="plans pb-30">
								<div className="plan mb-sm-0 mb-xs-50">
									<span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={FanvilDongle} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
									<br />
									<span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
								</div>
							</div>

							<p className="pb-30">
								<Link to={'/products/fanvil-wifi-dongle'} className="btn btn-lg btn-secondary mr-10 mr-xxs-0 mb-xxs-10 btn-xxs-block">Learn more</Link>
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(FanvilDongle)}>Buy Now</Button>
							</p>
						</div>

					</div>
				</div>
			</div>


			<div className="full-width bg-darker pb-50 pt-0 pb-sm-0 bg-img" id="apps" style={{ backgroundImage: "url('/images/product-shots/mobile-app-hand.png')", backgroundSize: 'initial', backgroundRepeat: 'no-repeat', backgroundPosition: '90% 100%' }}>
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 pt-100 pt-sm-10 pb-sm-30 pl-50 pl-sm-15">
							<h2 className="text-500 text-lightest mt-0 mt-sm-50 text-center text-lg-start">SwitchboardFREE Mobile Apps</h2>
							<p className="lead text-lightest text-center text-lg-start">Be connected to your business from anywhere.</p>
							<div className="row mt-50">
								<div className="col-12 col-lg-2 text-center text-lg-start">
									<img src="/images/product-shots/phone-app-icon.png" alt="SwitchboardFREE Phone App" style={{ maxWidth: '100%' }} />
								</div>
								<div className="col-12 col-lg-10">
									<p className="lead text-500 text-cta text-center text-lg-start mt-sm-15">
										SwitchboardFREE Phone App  <span className="badge badge-lg cta-gradient">FREE!</span>
									</p>
									<p className="mb-30">
										Ideal for occasional use allowing you to make and receive calls via any of your SwitchboardFREE numbers.
                                    </p>
									<p className="mb-30">
									Designed for businesses that have staff on the move, this app makes for a perfect travelling companion.
									Unlike international calls, which can cost up to £20 per minute when calling into the UK,
									this app is completely free! It’s not the only benefit of the app, but on its own could save you thousands of pounds in call changes.
                                    </p>
									<p className="text-400 text-md pb-30 text-center text-lg-start">
										<a target="_blank" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '100%', width: '230px' }} className="m-5" id="ApplePhoneApp"/></a>
										<a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '100%', width: '230px' }} className="m-5" id="AndroidPhoneApp"/></a>
									</p>
								</div>
							</div>
							<div className="row mt-50">
								<div className="col-12 col-lg-2 text-center text-lg-start">
									<img src="/images/product-shots/management-app-icon.png" alt="SwitchboardFREE Management App" style={{ maxWidth: '100%' }} />
								</div>
								<div className="col-12 col-lg-10">
									<p className="lead text-500 text-cta text-center text-lg-start mt-sm-15">
										SwitchboardFREE Management App  <span className="badge badge-lg cta-gradient">FREE!</span>
									</p>
									<p className="mb-30">
										Manage your account when you’re out &amp; about.
                                    </p>
									<p className="mb-30">
										The SwitchboardFREE management app has been built to allow you to have 100% control of
										your account wherever you are. You can manage inbound calls, configure number settings,
										update your redirect numbers and even change your on-hold music and customer greeting on the move.
										Need to check missed calls or listen to voicemails? You can do that too - and so much more!
                                    </p>
									<p className="text-400 text-md pb-30 text-center text-lg-start">
										<a target="_blank" href="https://apps.apple.com/gb/app/sbf-2012/id556878596" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '100%', width: '230px' }} className="m-5" id="AppleManagementApp"/></a>
										<a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREE&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '100%', width: '230px' }} className="m-5" id="AndroidManagementApp"/></a>
									</p>
									{!this.props.isLoggedIn &&
										<p className="text-center text-lg-start">
											<Link to={'get-started'} className="btn btn-cta btn-lg" id="CreateAnAccountButton">Create an account</Link>
										</p>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-50 pt-50" id="softphone">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 pt-0 pt-xs-0 pull-sm-none text-center text-md-start pt-40">
							<img src="/images/product-shots/softphone_desktop1.png" className="mh-sm-400px" alt="SwitchboardFREE VoIP Softphone" style={{ maxWidth: '100%' }} />
						</div>
						<div className="col-12 col-lg-6 pt-30 float-none float-sm-end">
							<h2 className="text-500">SwitchboardFREE Softphone
                            {/* <span className="badge badge-lg cta-gradient">FREE!</span> */}
							</h2>
							<p className="lead text-400">
								Easily make & receive calls via your laptop or PC.
                            </p>
							<p>
								If you find yourself without a desk phone or other call-making and receiving hardware then
								SwitchboardFREE’s softphone is the perfect backup. It will alert you when a call is coming in
								so you can answer and it allows you to make calls from any of your SwitchboardFREE numbers.
							</p>
							<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30">
								<li className="pb-xs-10 pt-xs-10 brad-5">
									Your calls will be crisp & clear with HD audio via your internet connection (IP).
                                </li>
								<li className="pb-xs-10 pt-xs-10 brad-5">
									Call up user presence in real-time to manage availability & set your status to manage your calls.
                                </li>
								<li className="pb-xs-10 pt-xs-10 brad-5">
									Keep business running efficiently with a comprehensive call history panel & access to call details.
                                </li>
								<li className="pb-xs-10 pt-xs-10 brad-5">
									Enjoy an easier work life by integrating your softphone with your SwitchboardFREE Contact Book.
                                </li>
							</ul>
							<p className="pb-30">
								<Link to={'/setup/softphone'} className="btn btn-lg btn-secondary mr-10 mr-xxs-0 mb-xxs-10 btn-xxs-block" id="WindowsSoftphoneFindOutMoreButton">Find out more</Link>
								<a href="https://www.switchboardfree.co.uk/files/switchboardFREE.zip" className="btn btn-lg btn-cta btn-xxs-block">Download now</a>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-50 pt-50" id="headset">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h2 className="text-500 pb-60">
								VoIP Compatible Headsets
                            </h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-6 pr-sm-15 pr-50 pl-50 pl-sm-15">
							<div className="row">
								<div className="b-1 pt-30 pb-30 pl-60 pr-60 brad-20 box-shadow-v3">
								<div className="col-12 pl-100 pl-xs-0 text-center text-md-start">
									<div className="p-absolute absolute mr-50 mr-sm-0 ml-sm-100 ml-xxs-0">
										<img src="/images/orange-tag-blank.png" alt="price tag" style={{ width: '110px' }} />
										<div className="absolute" style={{ top: '27px', left: '31px' }}>
											<span className="text-lightest text-500 absolute" style={{ fontSize: '20px', top: '-7px' }}>SAVE</span>
											<br />
											<span className="text-lightest text-500 absolute" style={{ fontSize: '45px', left: '-6px', top: '5px' }}>&pound;5</span>
										</div>
									</div>

									<img src="/images/product-shots/bee.png" alt="Bee Multi-Adapter Headsets" style={{ maxWidth: '100%', maxHeight: '350px' }} />
								</div>
								<div className="col-12 pt-30">
									<div className="text-center text-md-start">
										<h3 className="text-500">New Bee Multi-Adapter Headsets</h3>
										<div className="plans pt-30 text-center text-md-start mb-sm-10">
												<div className="plan text-center mb-sm-0">
													<span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={HeadsetId} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
													<br />
													<span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
												</div>
										</div>
									</div>

									<p className="lead text-400 pt-30">
										Be hands-free & noise free with this light & comfortable headset.
                                    </p>
									<p className="mb-30">
										Your customer will hear you perfectly over the noisiest of offices or call centres with noise-cancelling technology.
										And you will be able to hear them perfectly too because the fully adjustable boom delivers crystal clear audio thus
										a professional customer experience every time.
                                    </p>
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-sm-0">
										<li>
											Perfect for use with the SwitchboardFREE softphone.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Easy to use, just plug & play with either the USB or 3.5mm jack connections.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Block out any unwanted noise with the noise-cancelling microphone.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Because of the ultra-light & breathable material ear cushion, you will be hands-free & comfortable all day long.
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Control is at your fingertips with the multi-functional panel for volume, microphone mute & speaker mute. (USB only).
                                        </li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Completely portable due to its foldable design.
                                        </li>
									</ul>
									<p className="pb-30">
										<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(HeadsetId)} id="NewbeeHeadsetOrderNowButton">Order Now</Button>
									</p>
								</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-6 pl-sm-15 pl-50 pr-50 pr-sm-15">
							<div className="row">
								<div className="b-1 pt-30 pb-30 pl-60 pr-60 brad-20 box-shadow-v3">
									<div className="col-12 pl-50 pl-xs-0 text-center text-md-start">
										<img src="/images/product-shots/fanvil.png" alt="Fanvil Headsets" style={{ maxWidth: '100%', maxHeight: '350px' }} />
									</div>
									<div className="col-12 pt-30">
										<div className="text-center text-md-start">
											<h3 className="text-500">Fanvil Deskphone Headsets</h3>
											<div className="plans pt-30 text-center text-md-start mb-sm-10">
												<div className="plan text-center mb-sm-0">
													<span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={ht201} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
													<br />
													<span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
												</div>
											</div>
										</div>

										<p className="lead text-400 pt-30">
											A luxury headset that endures the longest of days.
										</p>
										<p className="mb-30">
											No need to break a sweat with the high-end soft foam and leatherette ear cushions which keep your ears cool all day.
											Match that with the noise cancelling tech and wideband audio, and you get comfort and crystal-clear conversations and happy staff and happy customers.
										</p>
										<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-sm-0">
											<li>
												Easy to use, just plug in & play.
											</li>
											<li className="pb-xs-10 pt-xs-10 brad-5">
												Your customer only hears you with the superior noise-cancelling microphone.
											</li>
											<li className="pb-xs-10 pt-xs-10 brad-5">
												Twiddle the reinforced cord as much as you like & still get a perfect connection.
											</li>
											<li className="pb-xs-10 pt-xs-10 brad-5">
												Avoid a scare by letting the peakstop technology remove loud noises before they reach your ears.
											</li>
										</ul>
										<p className="pb-30">
											<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.isLoggedIn ? this.setState({ showEditDevices: true }) : this.handleAddHandset(ht201)} id="FanvilDeskphoneHeadsetOrderNowButton">Order Now</Button>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<LiveChatBlock style="secondary" />

			<div className="full-width bg-light">
				<div className="container">
					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>
		</>
  }

  componentDidMount () {
    this.getCustomers()
    this.getHardware()
  }

  getHardware () {
    const url = 'api/Purchase/GetHardware'
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data) {
          this.setState({ products: res.data })
        }
      }).catch(err => console.error(err))
  }

  handleAddHandset (prodId) {
    const product = this.state.products.filter(f => f.prodId === prodId)
    const tempData = [{
      qty: 1,
      ...product[0]
    }]

    localStorage.setItem('HardwareFirst', JSON.stringify(tempData))

    this.props.history.push('/get-started')
  }

  getCustomers () {
    const url = 'api/Home/GetTotalCustomers'
    fetchHelper.getJson(url)
      .then(response => {
        if (response !== undefined) { this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }
}
export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(Devices)
