import React, { Component } from 'react'
import Helmet from 'react-helmet'

export default class ImageRightFullWidth extends Component {
  state = {
  }

  /// ////////////////////////////////////////////////////
  //
  //   Example usage:
  //
  //   <ImageRightFullWidth
  //        ImgURL="/images/backgrounds/moh-bg8.jpg"
  //        ImgURLmobile="/images/signup-side-img-mobile.jpg" // optional... use only if you want the background-image to be different when screen is ≤ 767px.
  //        ImgURLmobileID="xxx" // only add this if if you have more than one <ImageRightFullWidth /> components on the page, and both will use different images ImgURLmobile on ≤ 767px.
  //
  //        TextCopy={
  //            <>
  //                <p className="text-400">
  //                   some text goes here
  //                </p>
  //
  //                <h3 className="text-400 pt-20">
  //                    dome text
  //                </h3>
  //             </>
  //           }
  ///
  //    />
  //
  /// //////////////////////////////////////////////////////

  render () {
    return (
            <>
                <Helmet>
                <style type="text/css">{`
                    @media screen and (max-width:767px) {
                        .w-xs-100.img-right-bg#${this.props.ImgURLmobileID} {
                            background-image: url(${this.props.ImgURLmobile ? this.props.ImgURLmobile : '/images/beard-guy.jpg'}) !important;
                        }
                    }

                    `}</style>
                </Helmet>
                <div className="full-width pt-0 pb-0" >
                    <div className="row-flex">
                        <div className={`${this.props.MainContentClass ? this.props.MainContentClass + ' w-50 w-xs-100 float-start ' : ' w-50 w-xs-100 float-start bg-secondary pt-60 pb-60 pt-xs-0 pb-xs-30'}`}
                        style={{ backgroundColor: `${this.props.bgContentColor !== 'undefined' ? this.props.bgContentColor : 'transparent'}` }}>

                            <div className="w-800px p-60 p-xs-30 float-end text-lightest">
                                {this.props.TextCopy}
                            </div>
                        </div>
                        <div
                            className={`${this.props.RightImageClass ? this.props.RightImageClass + ' w-50 w-xs-100 float-start img-right-bg ' : ' w-50 w-xs-100 float-start img-right-bg'}`}
                            id={this.props.ImgURLmobileID}
                            style={{
                              backgroundImage: `url(${this.props.ImgURL})`,
                              backgroundPosition: `${this.props.bgImgPosition ? this.props.bgImgPosition : '60% 62%'}`,
                              backgroundSize: `${this.props.bgSizeType ? this.props.bgSizeType : 'cover'}`,
                              backgroundColor: `${this.props.bgImgColor !== 'undefined' ? this.props.bgImgColor : 'transparent'}`,
                              backgroundRepeat: 'no-repeat',
                              minHeight: '300px'
                            }}>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}
