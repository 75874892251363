import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { fetchHelper } from '../helpers/fetchHelper'
import { isValidEmailSyntax, isValidNameSyntax, isValidTelSyntax } from '../helpers/validationHelpers'

class DisasterRecovery extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isOpen: false,
      isOpenTwo: false,
      showVideo: false
    }
    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false,
      isValid: false
    }
  }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow () {
    this.setState({ show: true })
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>Disaster Recovery - Stay One Step Ahead Of Potential Disasters</title>
                <meta name="description" content="Disaster Recovery - Stay One Step Ahead Of Potential Disasters" />
                <style type="text/css">{`
						
                        @media screen and (max-width:1440px) {
							.mx-auto {
								margin-left: 0%;
							}
							.col-md-10 {
								width: 100%;
							}
						}
					
					`}</style>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/referrer-tracking-bg.jpg')" }} className="full-width full-hero-small">
                <h1>SwitchboardFREE Disaster Recovery</h1>
                <h2 className="subtitle text-center">Stay one step ahead of potential disasters</h2>
            </header>

            <div className="full-width bg-light pt-50 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center pb-50">
                            <h2>
                                Built-In Disaster Recovery At No Extra Charge
							</h2>
                            <p className="lead text-400">
                                Redirect calls to alternative locations, home-workers or mobile phones. <br className="d-none d-md-block" />Make and receive your business call anywhere, instantly.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="row">
                                <div className="col-sm-6 text-400">
                                    <p>
                                        IT problems, power cuts, fires, floods or pandemics aren't just a problem, you could lose customers, revenue, staff or even your entire business.
									</p>
                                    <p className="text-500 mt-30 pb-20">
                                        With our Disaster Recovery service you can:
									</p>
                                    <ol className="pl-20 cta text-400">
                                        <li className="pb-10">
                                            Instantly re-route your incoming calls.
										</li>
                                        <li className="pb-10">
                                            Give your team the ability to dial out presenting your business number.
										</li>
                                        <li className="pb-10">
                                            Route or overflow your inbound calls to our team of trained receptionists.
										</li>
                                        <li className="pb-10">
                                            Bring your team together with our free conference services.
										</li>
                                    </ol>
                                </div>
                                <div className="col-sm-6">
                                    <div className="bg-dull b-2 brad-10 text-uppercase grey-box">
                                        <div className="bg-cta mt-30 text-center p-10">
                                            <p className="text-500 text-lightest text-md mb-0">small businesses that experience a disaster</p>
                                        </div>
                                        <div className="clearfix text-center" style={{ margin: '0 auto' }}>
                                            <div className="grey-box-content">
                                                <div className="text-500 float-start text-cta pr-10 line-one">
                                                    <span>
                                                        Up
														<br />
														to
													</span>
                                                </div>
                                                <div className="text-500 float-start text-cta line-two">
                                                    <span>60%</span>
                                                </div>
                                                <div className="text-500 float-start text-cta text-start pl-0 line-three col-xs-12">
                                                    <span>
                                                        Never Reopen!
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <span className="plus-middle"><span>+</span></span>

            <div className="full-width bg-dull pt-50 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pb-40">
                            <h2 className="text-500">In-Built Disaster Recovery Tools That Really Work</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="row">

                                <div className="col-md-6 pt-50">
                                    <img src="/images/d1.jpg" alt="Baker" style={{ maxWidth: '100%', width: '100%' }} />
                                </div>
                                <div className="col-md-6 pt-50">
                                    <h3 className="text-400 mt-0">Inbound Call Management</h3>
                                    <p className="text-400">
                                        Ensure you callers receive a professional telephone experience and your calls are answered and routed to the correct people or team.
									</p>
                                    <p className="mb-30 pt-20">
                                        Quickly and easily set up powerful inbound call management with the flexibility to make changes instantly.
									</p>
                                    <p>
                                        <Link to={'/number-types'} className="btn btn-md btn-secondary mb-5 mt-5 mr-5 btn-xxs-block">Get a virtual number</Link>
                                        <Link to={'/business-voip-phone-system'} className="btn btn-md btn-cta mb-5 mt-5 btn-xxs-block">Get a full VoIP System</Link>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="bg-light p-30 pt-50 brad-10 mt-30 text-400">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <ul className="styled-list cta rounded mb-0 pl-sm-0 pr-sm-0">
                                                    <li>
                                                        Huge range of numbers to choose from
													</li>
                                                    <li>
                                                        Set up is quick and easy
													</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6">
                                                <ul className="styled-list cta rounded mb-0 pl-sm-0 pr-sm-0">
                                                    <li>
                                                        UK based support team
													</li>
                                                    <li>
                                                        Five-star independent review
													</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <span className="plus-middle"><span>+</span></span>

            <div className="full-width bg-light pt-50 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="row">
                                <div className="col-md-6 pt-50">
                                    <img src="/images/d2.jpg" alt="delivery" style={{ maxWidth: '100%', width: '100%' }} />
                                </div>
                                <div className="col-md-6 pt-50">
                                    <h3 className="text-400 mt-0">Outbound Dialling</h3>
                                    <p className="text-400">
                                        Powerful cloud-based phone system for up to 100 users. Use our plug and play desk phones or quickly deploy our calling app on your team's smartphones.
									</p>
                                    <p className="mb-30 pt-20">Make and receive phone calls from anywhere in the world and transfer between users in any location worldwide for free.
									</p>
                                    <p>
                                        <Link to={'/number-types'} className="btn btn-md btn-secondary mb-5 mt-5 mr-5 btn-xxs-block">Get a virtual number</Link>
                                        <Link to={'/business-voip-phone-system'} className="btn btn-md btn-cta mb-5 mt-5 btn-xxs-block">Get a full VoIP System</Link>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="bg-dull p-30 pt-50 brad-10 mt-30 text-400">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ul className="styled-list cta rounded mb-0 pl-sm-0 pr-sm-0">
                                                    <li>
                                                        Make and receive phone calls from anywhere in the world
    												</li>
                                                    <li>
                                                        Have as many users (extensions) as you wish
												</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul className="styled-list cta rounded mb-0 pl-sm-0 pr-sm-0">
                                                    <li>
                                                        Easy to use app, ‘plug and play’ desktop phone, or softphone
													</li>
                                                    <li>
                                                        Professional, HD call clarity.

													</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <span className="plus-middle"><span>+</span></span>

            <div className="full-width bg-dull pt-50 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="row">

                                <div className="col-md-6 pt-50">
                                    <img src="/images/d3.jpg" alt="delivery" style={{ maxWidth: '100%', width: '100%' }} />
                                </div>
                                <div className="col-md-6 pt-50">
                                    <h3 className="text-400 mt-0">Call Answering</h3>
                                    <p className="text-400">
                                        Route your calls to our team of experienced receptionists who will be trained to take your calls exactly the way you want.

							</p>
                                    <p className="mb-30 pt-20">

                                        Direct all your calls or just the overflow. Important calls can be transferred in real-time or give specific messages to your callers. It’s totally up to you.

									</p>
                                    <p>
                                        <Link to={'/number-types'} className="btn btn-md btn-secondary mb-5 mt-5 mr-5 btn-xxs-block">Get a virtual number</Link>
                                        <Link to={'/business-voip-phone-system'} className="btn btn-md btn-cta mb-5 mt-5 btn-xxs-block">Get a full VoIP System</Link>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="bg-light p-30 pt-50 brad-10 mt-30 text-400">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ul className="styled-list cta rounded mb-0 pl-sm-0 pr-sm-0">
                                                    <li>
                                                        Same-Day set up with a dedicated account manager
    												</li>
                                                    <li>
                                                        Call recording for your peace of mind
													</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul className="styled-list cta rounded mb-0 pl-sm-0 pr-sm-0">
                                                    <li>
                                                        Real-time messages sent to your APP, email and online portal.
													</li>
                                                    <li>
                                                        Independently rated as a  5-Star service.
													</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <span className="plus-middle"><span>+</span></span>

            <div className="full-width bg-light pt-50 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="row">
                                <div className="col-md-6 pt-50">
                                    <img src="/images/d4.jpg" alt="delivery" style={{ maxWidth: '100%', width: '100%' }} />
                                </div>
                                <div className="col-md-6 pt-50">
                                    <h3 className="text-400 mt-0">Conference Calling <br className="d-none d-md-block" />When You Need It</h3>
                                    <p className="text-400">
                                        Con-Flab is a secure business-class audio conference service that’s free from a landline, mobile phone or internationally.
									</p>
                                    <p className="mb-30 pt-20">
                                        Stay in contact with your team with easy to set up conference meetings.
									</p>
                                    <p>
                                        <Link to={'/number-types'} className="btn btn-md btn-secondary mb-5 mt-5 mr-5 btn-xxs-block">Get a virtual number</Link>
                                        <Link to={'/business-voip-phone-system'} className="btn btn-md btn-cta mb-5 mt-5 btn-xxs-block">Get a full VoIP System</Link>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="bg-dull p-30 pt-50 brad-10 mt-30 text-400">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ul className="styled-list cta rounded mb-0 pl-sm-0 pr-sm-0">
                                                    <li>
                                                        Conference recordings.
    												</li>
                                                    <li>
                                                        Easy conference control including “listen-only” PIN.
													</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul className="styled-list cta rounded mb-0 pl-sm-0 pr-sm-0">
                                                    <li>
                                                        Full-featured conference app.

													</li>
                                                    <li>
                                                        Independently rated as a 5-Star service.
													</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="full-width full-secondary p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 float-end pt-50">
                            <h2 className="text-500">Instant protection for complete peace of mind</h2>
                            <p className="lead text-400">These services can be set up within the hour and actioned instantly, giving you the peace of mind that you have a Business continuity or disaster recovery plan in place.</p>
                            <p className="mb-30">Our UK based welcome team are on hand to talk you through the right options for your business.</p>
                            <p>
                                <Link to={'get-started'} className="btn btn-lg btn-cta btn-xxs-block">Get Started</Link>
                            </p>
                        </div>
                        <div className="col-md-6 pt-50">
                            <img src="/images/desk2.jpg" alt="Desk" style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-transparent w-900px" show={this.state.showVideo} onHide={() => this.setState({ showVideo: false })}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="ratio ratio-16x9">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/0e5_W9Llhn8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" size="xs" onClick={() => this.setState({ showVideo: false })}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
  }

  checkNameValidation (val) {
    let nameValid
    if (val && val.length > 1) {
      nameValid = isValidNameSyntax(val)
    } else {
      nameValid = false
    }
    const isValid = nameValid === true && this.state.telValid !== false && this.state.email && this.state.emailValid !== false
    this.setState({ ...this.state, nameValid, isValid })
  }

  checkEmailValidation (val) {
    let emailValid
    if (val && val.length > 5) {
      emailValid = isValidEmailSyntax(val)
    } else {
      emailValid = false
    }

    const isValid = emailValid === true && this.state.telValid !== false && this.state.fullName && this.state.nameValid !== false
    this.setState({ ...this.state, emailValid, isValid })
  }

  checkTelValidation (val) {
    let telValid
    if (val && val > 8) {
      telValid = isValidTelSyntax(val)
    } else {
      telValid = false
    }
    const isValid = telValid === true && this.state.fullName && this.state.nameValid !== false && this.state.email && this.state.emailValid !== false
    this.setState({ ...this.state, telValid, isValid })
  }

  handleFormSubmit (context) {
    this.checkNameValidation(context.fullName)
    this.checkEmailValidation(context.email)

    if (context.tel && context.tel.length > 1) { this.checkTelValidation(context.tel) }

    if (this.state.isValid) {
      const url = '/api/Home/InfographicDownload'
      const data = Object.assign(
        { name: context.fullName },
        { email: context.email },
        { tel: context.tel }
      )

      fetchHelper.postJson(url, data)
        .then(res => {
          this.setState({ formSent: res.data })
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(DisasterRecovery)
