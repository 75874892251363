import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'

export const QuickCloseAutoReopenDialog = props => {
  return (
        <Modal size='lg' show={props.quickCloseAutoReopenDialogDisplayed} onHide={props.dismiss}>
            <Modal.Header closeButton>
                <Modal.Title>Automatically re-open</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Choose a date/time when you would like your <span id="SPNautoOpenSpan">number</span> to
                    automatically re-open.
                </p>
                <div className="row">
                    <div className="col-md-6 col-sm-8 mx-auto text-center">
                        <label>Date/Time</label>
                        {/* <input className="auto-reopen form-control" type="text" placeholder="Please choose" /> */}
                        <Datetime value={props.dateTime} dateFormat='DD/MM/YYYY' onChange={e => props.onAutoReopenTimeChange(e)} />
                    </div>
                </div>
                <div className="alert alert-danger gap-top" style={{ display: 'none' }}/>
            </Modal.Body>
            <Modal.Footer>
                <hr className="light big-gap"/>
                <div className="row">
                    <div className="col-md-6 float-end">
                        <Button size="lg" variant='cta' onClickCapture={props.handleSaveAutoReopenTime}
                                className="float-end">Set reopen time</Button>
                    </div>
                    <div className="col-md-6">
                        <a href="javascript:" className="btn blue" data-dismiss="modal">Don&apos;t auto open</a>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
  )
}
