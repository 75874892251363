import React from 'react'
import { Modal, OverlayTrigger, Popover, Ratio } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import CallRecordingBenefits from '../../../components/CallRecordingBenefits'
import CallTranscriptionActivation from '../../../components/CallTranscriptionActivation'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import CallRecordingPackages from '../../../components/purchase/CallRecordingPackages'
import RefreshUserServices from '../../../components/RefreshUserServices'
import CarouselWidget from '../../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { productBillingOption, ProductIds } from '../../../constants/Constants'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { checkUserHasCallRecording } from '../../../helpers/utils'
import { isLoggedIn } from '../../../selectors'
import * as fromHome from '../../../store/Home'
import * as SBF from '../../../store/SBF'

class CallRecordingPurchase extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showLogin: false,
      callTranscriptionActive: false
    }
  }

  scrollTo () {
    scroller.scrollTo('start', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  scrollToPackages () {
    scroller.scrollTo('purchase', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  render () {
    // if(this.props.services.hasUnlimitedCallRecording) window.location = "/customer/call-recording/";
    const hasCallRecording = checkUserHasCallRecording(this.props.services)

    const callRecordPrice = { firstPayment: 0, repeatPayment: 0 }

    if (this.props.productDetails) {
      const callRecordPaymentDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.callRecordAll)

      if (callRecordPaymentDetails) {
        callRecordPrice.firstPayment = callRecordPaymentDetails.monthlyPrice.firstPaymentAmount
        callRecordPrice.repeatPayment = callRecordPaymentDetails.monthlyPrice.repeatPaymentAmount
      }
    }

    return <><ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            {this.props.isLoggedIn &&
                <>
                    <RefreshUserServices />
                </>
            }
            <Helmet>
                <title>Call Recording - Start recording your phone calls</title>
                <meta name="description" content="Don’t miss any details; avoid any possible legal complications and stay compliant." />
                <style type="text/css">{`
                        .free-for-a-month {
                            font-size:60px;
                        }
                       @media screen and (max-width:1500px) {
                            .free-for-a-month {
                                font-size:49px;
                            }
                        }
                    }
                    `}</style>
            </Helmet>
            <header>
                <div className="full-width full-hero no-overlay callrecording-page pt-50 pb-xxs-50" id="hero" style={{ backgroundImage: "url('/images/backgrounds/call-recording.jpg')" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center text-lightest">

                            {this.props.isLoggedIn
                              ? <>
                                {hasCallRecording

                                  ? <>
                                        <h1 className="text-lightest text-500 pt-40">
                                            Your call recording is active!
                                        </h1>

                                        {this.props.services.hasUnlimitedCallRecording
                                          ? <>
                                                <p>
                                                    All your inbound and outbound calls are recorded.
                                                </p>
                                                <p className="pt-30 mb-0 pb-0">
                                                    <a className="btn btn-cta btn-lg" href="/customer/call-recording">View my recordings</a>
                                                </p>
                                                {this.state.callTranscriptionActive === true
                                                  ? <></>
                                                  : <>
                                                        <div className="text-center b-1 well well-transparent p-20 brad-20 mt-40 mb-20 w-500px center-block text-lightest">
                                                            <h5 className="text-500 mt-0 pt-0 text-lightest">
                                                                Call Transcription
                                                                <OverlayTrigger trigger={['hover', 'focus']} placement="top"
                                                                overlay={
                                                                    <Popover>
                                                                        <ul className="fancy text-start cta text-400 mt-20 pl-40">
                                                                            <li className="pb-10">
                                                                                <strong>Capture Every Conversation:</strong> Call Transcription ensures every word spoken during a call is documented in text. No more note-taking, no more forgetting important details - you'll have a ready-to-use record at your fingertips.
                                                                            </li>
                                                                            <li className="pb-10">
                                                                                <strong>Search with Ease:</strong> Ever spent time trying to remember a critical detail from a past call? Now you can simply search for it. Our Call Transcription lets you search for specific words or phrases mentioned in any conversation.
                                                                            </li>
                                                                            <li className="pb-10">
                                                                                <strong>Stay Compliant:</strong> Never miss out on mentioning essential details again. With our feature, you can quickly search for terms or conditions that need to be discussed during a call, ensuring complete professionalism and compliance.
                                                                            </li>
                                                                            <li className="pb-10">
                                                                                <strong>Set Up Custom Alerts:</strong> With our Call Transcription feature, you can now set alerts for specific words or phrases that are either mentioned or missing from your calls. This ensures you&apos;re immediately notified when critical information is shared or when important terms have been omitted, helping you maintain consistent quality control and compliance in every conversation.
                                                                            </li>
                                                                        </ul>
                                                                    </Popover>
                                                                }
                                                                >
                                                                    <span className="icon icon-info2 text-xs ml-5"></span>
                                                                </OverlayTrigger>
                                                            </h5>
                                                            <p className="text-sm">Automatically transcribe your call recordings,<br/><i>just 5p per minute.</i></p>
                                                            <CallTranscriptionActivation
                                                                isBox={false}
                                                                customToggleWrapperClass="mt-40 mb-20"
                                                                clickedNotNowMaybeLater={() => this.setState({ clickedNotNowMaybeLaterOnCallTranscription: true })}
                                                                activateCallTranscriptionClicked={() => this.setState({ callTranscriptionActive: true })}
                                                                deactivateCallTranscriptionClicked={() => this.setState({ callTranscriptionActive: false })}
                                                                callTranscriptionActive= {this.state.callTranscriptionActive}
                                                                CallTranscriptionHeadingCustomClass="text-lightest"
                                                            />
                                                        </div>

                                                    </>
                                                }
                                            </>
                                          : <>
                                                <p>
                                                    All your inbound and outbound call recordings are <a href="/customer/call-recording" className="underline">here</a>.
                                                </p>
                                                <p className="mb-0 pb-0">Ready to upgrade your package?</p>
                                            </>
                                        }
                                    </>

                                  : <>
                                        <h1 className="text-lightest text-500" id="CallRecordingH1">
                                            Call Recording – Start today<br/>
                                            <span className="text-cta free-for-a-month">FREE for a month!</span>
                                        </h1>
                                        <p style={{ fontSzie: '26px', paddingTop: '19px' }}>Capture every detail so you can train your staff, improve compliance<br/> &amp; easily resolve customer conflicts.</p>
                                        <p style={{ paddingTop: '18px' }}>No long-term contracts, cancel at any time. From just £{callRecordPrice.repeatPayment} p/m.</p>
                                    </>
                                    }
                            </>

                              : <>
                                <h1 className="text-lightest text-500" id="CallRecordingH1">
                                    Call Recording – Start today<br/>
                                    <span className="text-cta free-for-a-month">FREE for a month!</span>
                                </h1>
                                <p style={{ fontSzie: '26px', paddingTop: '19px' }}>Capture every detail so you can train your staff, improve compliance<br/> &amp; easily resolve customer conflicts.</p>
                                        <p style={{ paddingTop: '18px' }}>No long-term contracts, cancel at any time. From just £{callRecordPrice.repeatPayment} p/m.</p>
                            </>

                            }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-20">
                                {!this.props.services.hasUnlimitedCallRecording &&
                                    <Link className="btn btn-cta btn-xl mt-10 d-inline-block brad-5" to={'#'} onClick={() => this.props.isLoggedIn ? this.scrollToPackages() : this.setState({ showLogin: true })} id="OrderTodayButton">
                                        <strong className="text-lightest">
                                        {hasCallRecording
                                          ? 'Upgrade call recording'
                                          : 'Order today & 1st month FREE!'
                                        }
                                        </strong>
                                    </Link>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center arrow-scroll">

                                {!this.props.services.hasUnlimitedCallRecording &&
                                    <>
                                        {hasCallRecording
                                          ? <a onClick={this.scrollToPackages}>
                                                <span className="icon icon-arrow-down4"></span>
                                            </a>
                                          : <a onClick={this.scrollTo}>
                                                <span className="icon icon-arrow-down4"></span>
                                            </a>
                                        }
                                    </>

                                }
                                {/* <a onClick={this.scrollToPackages}>
                                    <span className="icon icon-arrow-down4"></span>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="full-width bg-light" id="start">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pb-60 pt-30">
                        {this.props.isLoggedIn
                          ? <>
                                {hasCallRecording

                                  ? <>
                                         <h2>Benefits of call recording</h2>
                                    </>

                                  : <>
                                        <h2>Why you should use call recording</h2>
                                    </>
                                    }
                            </>

                          : <>
                                <h2>Why you should use call recording</h2>
                            </>

                            }

                        </div>
                    </div>
                    <CallRecordingBenefits />
                </div>
            </div>

            {this.props.isLoggedIn
              ? !this.props.services.hasUnlimitedCallRecording &&
                <div id="purchase">
                    <CallRecordingPackages userServices={this.props.services} CallTranscriptionHeadingCustomClass="text-lightest" />
                </div>
              : <div className="full-width full-secondary text-lightest">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h2>
                                    Watch the video
                                </h2>
                                <div className="col-md-6 mx-auto">
                                <Ratio aspectRatio="16x9" >
									<iframe width="560" height="315" className='brad-30' src="https://www.youtube.com/embed/9G36njgm3hE?si=9USEaRr_JUxuyC5v" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
								</Ratio>
                                </div>                            </div>
                            <div className="col-md-12 text-center mt-30">
                                <h3 className="pb-20 text-500 d-block">Start recording your calls today!
                                <h5 className='text-500'>FREE for a month!</h5>
                                </h3>
                                <p style={{ fontSzie: '26px', paddingTop: '19px' }}>Capture every detail so you can train your staff, improve compliance<br/> &amp; easily resolve customer conflicts.</p>
                                <p style={{ paddingTop: '18px' }}>No long-term contracts, cancel at any time. From just £{callRecordPrice.repeatPayment} p/m.</p>

                                {!this.props.services.hasUnlimitedCallRecording &&
                                    <Link className="btn btn-cta btn-lg mt-10 d-inline-block brad-5" to={'#'} onClick={() => this.props.isLoggedIn ? this.scrollToPackages() : this.setState({ showLogin: true })} id="OrderTodayButton">
                                        <strong className="text-lightest">
                                        {hasCallRecording
                                          ? 'Upgrade call recording'
                                          : 'Order today & get your first month FREE!'
                                        }
                                        </strong>
                                    </Link>
                                }
                                <p className="text-sm pt-20 pb-30">
                                    If you prefer to do it over the phone or if you have any questions, <br className="d-none d-md-block" />give us a call on <a href="tel:02031891213">0203 189 1213</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="full-width bg-dull">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>

            <Modal className="w-400px" show={this.state.showLogin} onHide={() => this.setState({ showLogin: false })}>
                <Modal.Header className="text-center bb-0 d-block" closeButton>
                    <Modal.Title>Log into your account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 text-center pb-30">
                            <form name="form" className="text-start" onSubmit={e => this.props.login({
                              userName: this.props.userName,
                              password: this.props.password,
                              event: e,
                              history: this.props.history,
                              route: '/customer/upgrades/call-recording/',
                              persist: document.getElementById('persistLogin').checked
                            })}>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <label>Email</label>
                                        <input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} id="EmailInput"/>
                                    </div>
                                    <div className="col-xs-12 pt-20">
                                        <label>Password</label>
                                        <input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} id="PasswordInput"/>
                                    </div>
                                </div>
                                <div className="pt-10 pb-10 row">
                                    <div className="col-xs-6">
                                        <input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
                                    </div>
                                    <div className="col-xs-6 text-end">
                                        <Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
                                    </div>
                                </div>
                                <div className="text-center pt-30">
                                    {this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                                    {this.props.loginFailed === true && (this.props.loginFailReason === '' || this.props.loginFailReason === null || this.props.loginFailReason === undefined) ?
                                      <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'} className="underline text-danger">resetting your password</Link>.</p>
                                      :
                                      <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage" hidden={!this.props.loginFailed}>{this.props.loginFailReason}</p>
                                    }
                                    <button className="btn btn-cta btn-lg btn-mobile-block" id="LoginButton">Log In</button>

                                    <p className="text-center bt-1 mt-30 pt-30 text-sm">
                                        Don&apos;t have an account with us yet? <br /><Link to="/get-started" className="underline text-cta" id="CreateYourAccountNowLink">Create your account now</Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="default" size="xs" onClick={() => this.setState({ showLogin: false })}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        </>
  }

  componentDidMount () {
    if (this.props.isLoggedIn) {
      const url = 'api/CallTranscription/HasCallTranscriptionTurnedOn'
      fetchHelper.getJson(url)
        .then(res => {
          this.setState({ callTranscriptionActive: res.data })
        }).catch(err => console.error(err))
    }

    this.props.getProductDetails([
      { productId: ProductIds.callRecordAll, monthlyOption: productBillingOption.callRecordingAllBillingOptionMonthly }
    ])
  }
  // componentDidMount() {
  //     setHero();
  //     window.addEventListener("resize", setHero);
  //     function setHero() {
  //         const heroDiv = document.getElementById('hero');
  //         if (heroDiv !== null) {
  //             if (window.outerWidth > 980) {
  //                 let height = window.innerHeight - 110;
  //                 heroDiv.style.height = height + "px";
  //             }
  //             // else {
  //             // 	heroDiv.style.height = "auto";
  //             // }
  //         }
  //     }
  // }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      productDetails: state.home.productDetails.products,
      services: state.sbfApp.enabledServices,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed,
      loginFailReason: state.home.login.reason
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    handleCallRecordingUnlimitedEnableChange: SBF.handleCallRecordingUnlimitedEnableChange,
    handleCallRecordingSingleEnableChange: SBF.handleCallRecordingSingleEnableChange,
    handleCallRecordingAllEnableChange: SBF.handleCallRecordingAllEnableChange,
    getProductDetails: fromHome.getProductPrice
  }
)(CallRecordingPurchase)
