import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import Helmet from 'react-helmet'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ClientLogos from '../../components/ClientLogos'

class NexusRamps extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return <>
			<ScrollToTopOnMount />
			<DisplayMainFooter />
			<DisplayMainHeader />

			<Helmet>
				<title>How SwitchboardFREE Transformed the Way A Building Design Firm Communicated With Their Customers</title>
			</Helmet>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container">
					<div className="row mt-100 mt-xs-30 justify-content-center">
						<div className="col-12 text-center">
							<h1 className="text-500 text-darker">How SwitchboardFREE Facilitated the Smooth Running & Growth of An Accessibility Company</h1>
						</div>

						<div className="col-md-8 col-sm-12 text-center mt-30">
							<div class="ratio ratio-16x9">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/GZhcqwDqw4I?si=7XY0fmBtqUqFuQIZ" title="Case study video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40" style={{ backgroundColor: '#d6e8ef' }}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-md-12">
							<h2 className="text-500 text-darker text-center h4 m-0">
								A friend suggested they try SwitchboardFREE and since then, they’ve not looked back!
							</h2>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container">
					<div className="row mt-100 mt-xs-100">
						<div className="col-md-6 col-sm-12 order-last order-md-first text-end pr-50 pr-xs-0">
							<img src="/images/case-studies/nexus-ramps.jpg" alt="Marketing agency" className="img-responsive" style={{ maxWidth: '600px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-first order-md-last">
							<p>
								Andy confessed that he was happy to use his mobile when he first started Nexus Ramps. But as his business grew it became inconvenient, encroached on his personal time and appeared unprofessional.
							</p>
							<p>
								Having found SwitchboardFREE, and its simplicity of use, Andy has been able to offer his customers a personalised experience with AI greetings, custom prompts, call routing and department options. His staff have benefitted too, as they have the flexibility of working from home and calls are evenly distributed with time-of-day routing and team call groups.
							</p>
							<p>
								The open and closed facility has also given Andy some more free time by creating an automatic boundary without losing customers. And although he tries to keep business and leisure separate, that still didn’t stop him from dialling out from his business number whilst abroad, relieved by the knowledge that he wouldn’t incur an expensive international call bill.
							</p>
							<p>
								As a business owner, Andy understands that SwitchboardFREE can grow along with his business, and he fully intends to utilise that ability. He knows that in his industry offering customers a local number to phone provides credibility. And as his business expands further south, he intends to invest in a geographical number to give the locals confidence in his service.
							</p>
							<p>
								Andy had so much to say about SwitchboardFREE’s many features! But don’t take our word for it, have a look for yourself.
							</p>
							<p className="mt-30">
								<Link className="btn btn-cta mr-20 mr-xs-0" to="/">
									Can SwitchboardFREE Help My Business?
								</Link>
							</p>
						</div>
					</div>

				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center mt-20">
						<div id="contentMobile"></div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull ">
				<div className="container">
					<CarouselWidget />
				</div>
			</div>

			{/* Logoos */}
			<div className="full-width bg-light">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">Partners &amp; Customers</h3>
							{/* <h6>The UK's favourite call management system</h6> */}
							<p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>
			<ClientLogos style='light' />


		</>
	}
}

export default connect(
	state => {
		return ({
		})
	},
	{}
)(NexusRamps)
