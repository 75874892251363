import React from 'react'
import { Button } from 'react-bootstrap'

const SaveButton = props => {
  return (
        <Button id={props.isSaving ? 'SavingButton' : 'SaveChangesButton'} variant="cta" className="text-center save-button btn-xs-block" size="lg" disabled={props.disabled} onClick={e => props.onClick()}>
            {props.isSaving ? 'Saving ...' : 'Save Changes'}
        </Button>
  )
}

export default SaveButton
