import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { fetchHelper } from '../helpers/fetchHelper'

class PromptAudioPlayer extends Component {
  static defaultProps = {}

  static propTypes = { promptId: PropTypes.number, voice: PropTypes.number, promptType: PropTypes.number }

  state = {}

  render () {
    return (
            <>
                <div className="hide-volume-controls">
                    <audio className="hide-volume-controls" id={`audio_${this.props.promptId}`} ref="audioPlayer" className={this.props.noMargin ? '' : ' mt-30'} controls></audio>
                </div>
            </>
    )
  }

  componentDidMount () {
    if (this.props.promptId) {
      this.LoadSoundToMusicPlayer()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.promptId) {
      this.LoadSoundToMusicPlayer()
    }
  }

  LoadSoundToMusicPlayer () {
    fetchHelper.getBlob(this.getPromptUrl()).then(response => {
      const { audioPlayer } = this.refs
      audioPlayer.pause()
      audioPlayer.currentTime = 0
      audioPlayer.src = window.URL.createObjectURL(response.data, { type: 'audio/x-wav' })
    }).catch(error => console.error(error))
  }

  getPromptUrl () {
    return `api/Configuration/GetPromptAudio/${this.props.voice}/${this.props.promptType}/${this.props.promptId}`
  }
}

export default PromptAudioPlayer
