import update from 'immutability-helper'
import { combineReducers } from 'redux'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { configValidationErrors, deliveryMethodOptions, fieldNames, openCloseTimeConstants } from '../constants/ConfigurationConstants'
import { PhoneTypes, RedirectType, validationStates } from '../constants/Constants'
import { getIdForTime, getTimeForQuickOpenCloseId } from '../helpers/configurationhelper'
import { fetchHelper } from '../helpers/fetchHelper'
import { checkNumberType } from '../helpers/utils'
import { isValidEmailSyntax, isValidMobSyntax, isValidRedirectRegex } from '../helpers/validationHelpers'
import { logout, requestConfig } from './SBF'

const _ = require('lodash')

const handleRemoveRedirect = createAction('HANDLE_REMOVE_REDIRECT')
export const handleToggleEmptyRedirectsWarning = createAction('HANDLE_TOGGLE_EMPTY_REDIRECTS_WARNING')
export const handleResetTimeSlotRedirectValidation = createAction('RESET_TIMESLOT_REDIRECTS_VALIDATION')
export const handleEditTimeSlot = createAction('HANDLE_EDIT_TIMESLOT')
export const handleUpdateConfigTimeslots = createAction('HANDLE_UPDATE_CONFIG_TIMESLOTS')
export const handleUpdateDayTimeSlotDays = createAction('HANDLE_UPDATE_TIMESLOT_DAYS')
export const handleRedirectsChecked = createAction('HANDLE_REDIRECTS_CHECKED')
export const handleUpdateTimeSlotRedirectSeconds = createAction('HANDLE_CHANGE_TIMESLOT_REDIRECT_TIME')
export const handleRemoveRedirectFromTimeSlot = createAction('HANDLE_REMOVE_REDIRECT_FROM_TIMESLOT')
export const removeTimeSlotValidation = createAction('HANDLE_REMOVE_TIMESLOT_VALIDATION')
export const handleAddRedirectToTimeSlot = createAction('HANDLE_ADD_REDIRECT_TO_TIMESLOT')
export const handleReOrderTimeslotRedirect = createAction('HANDLE_REDORDER_TIMESLOT_REDIRECTS')
export const handleRemoveTimeSlot = createAction('HANDLE_REMOVE_TIMESLOT')
export const handleChangeTimeSlotTime = createAction('HANDLE_CHANGE_TIMESLOT_TIME')
export const handleToggleTimeSlotDay = createAction('HANDLE_TOGGLE_TIMESLOT_DAY')
export const handleAddTimeSlot = createAction('HANDLE_ADD_TIMESLOT')
export const handleInitDaytimeRouting = createAction('HANDLE_INIT_TIME_ROUTING')
export const handleInitRedirects = createAction('HANDLE_INIT_REDIRECTS')
export const handleInitSipUsers = createAction('HANDLE_INIT_SIP_USERS')
export const handleAddRedirect = createAction('HANDLE_ADD_NEW_REDIRECT_NUMBER')
export const handleUmrPopupShown = createAction('HANDLE_UMR_POPUP_SHOWN')
export const handleShowUmrDialog = createAction('HANDLE_SHOW_UMR_POPUP')
export const handleHideUmrDialog = createAction('HANDLE_HIDE_UMR_POPUP')
export const handleClearRedirectsHistory = createAction('CLEAR_REDIRECTS_HISTORY')
export const changeRedirectNumber = createAction('CHANGE_REDIRECT_NUMBER')
export const updateRedirectDuration = createAction('UPDATE_REDIRECT_DURATION')
export const openCloseQuickTimeSelected = createAction('SET_QUICK_TIME_SETTING')
export const updateAutoCloseBankHols = createAction('CHANGE_AUTO_CLOSE_BANK_HOLS_SETTING')
export const changeOpenCloseTime = createAction('UPDATE_AUTO_CLOSE_TIME')
export const changeBespokeHoursHourClose = createAction('UPDATE_BESPOKE_OPEN_CLOST_TOGGLE')
export const updateVoicePromptSelection = createAction('UPDATE_VOICE_CHOICE_SELECTION')
export const handleMissedCallEmailChange = createAction('UPDATE_MISSED_CALL_EMAIL')
export const handleGlobalMissedCallEmailChange = createAction('UPDATE_GLOBAL_MISSED_CALL_EMAIL')
export const handleVoicemailEmailChange = createAction('UPDATE_VOICEMAIL_EMAIL')
export const handleVoicemailDelayChange = createAction('UPDATE_VOICEMAIL_DELAY')
export const handleVoicemailMessageChange = createAction('UPDATE_VOICEMAIL_PROMPT')
export const reOrderRedirect = createAction('REORDER_REDIRECT')
export const handleDeliveryMethodChange = createAction('UPDATE_DELIVERY_METHOD_CHANGE')
export const changeUserDetails = createAction('CHANGE_USER_DETAILS')

export const handleNumberPresentationChange = createAction('UPDATE_NUMBER_PRESENTATION_CHANGE')
export const handleChangeNumberDescription = createAction('UPDATE_NUMBER_DESCRIPTION')
export const handleAnnouncePositionChange = createAction('UPDATE_ANNOUNCE_POSITION_ENABLED_TOGGLE')
export const handleChangeGaDetectCodeUrl = createAction('UPDATE_GA_DETECT_CODE_URL')
export const handleGaNumberTrackingCodeChange = createAction('UPDATE_GA_NUMBER_TRACKING_CODE')
export const handleGaGeoTrackingCodeChange = createAction('UPDATE_GA_GEO_TRACKING_CODE')
export const handleGaCategoryChange = createAction('UPDATE_GA_CATEGORY')
export const handleQuickCloseChange = createAction('UPDATE_QUICK_CLOSE_STATE')
export const handleAllowInternationalCallersChanged = createAction('UPDATE_INTERNATIONAL_CALLS_ALLOWED_TOGGLE')
export const handleAllowWitheldCallersChanged = createAction('UPDATE_WITHELD_CALLS_ALLOWED_TOGGLE')
export const handleAutomaticSmsEnabledChange = createAction('UPDATE_AUTOMATIC_SMS_TOGGLE')
export const handleSmsMessageChange = createAction('UPDATE_AUTOMATIC_SMS_MESSAGE')
export const handleDynamicCliChange = createAction('UPDATE_DYNAMIC_CLI_NUMBER')
export const handleChangeDepartmentEnabled = createAction('UPDATE_DEPARTMENT_ENABLED_STATE')
export const handleDeptConfigurationChange = createAction('UPDATE_DEPARTMENT_CONFIG_NUMBER')
export const handleChangeGlobalDescription = createAction('UPDATE_GLOBAL_DESCRIPTION')
export const handleAutoDetectGaUpdate = createAction('UPDATE_GA_TRACKING')
export const handleShowMultipleDepartmentsHelperDialog = createAction('HANDLE_SHOW_MD_INSTRUCTION')
export const hideMultipleDepartmentsHelperDialog = createAction('HANDLE_HIDE_MD_INSTRUCTION')
export const handleCallScreeningEnabledChange = createAction('UPDATE_CALL_SCREENING_ENABLED_TOGGLE')
export const handleMissedCallEmailsEnabledChange = createAction('UPDATE_MISSED_CALL_EMAIL_ENABLED_TOGGLE')
export const handleGlobalMissedCallEmailsEnabledChange = createAction('UPDATE_GLOBAL_MISSED_CALL_EMAIL_ENABLED_TOGGLE')
export const handleVoicemailEnabledChange = createAction('UPDATE_VOICEMAIL_ENABLED_TOGGLE')
export const handleCallRecordingEnabledChange = createAction('UPDATE_CALL_RECORDING_ENABLED_TOGGLE')
export const handleCallRecordingEmailChange = createAction('UPDATE_CALL_RECORDING_EMAIL')
export const handleWhatsAppEnableChange = createAction('UPDATE_WHATSAPP_ENABLED_TOGGLE')

export const handleHideMainIntroductionHelperInstruction = createAction('HIDE_MAIN_INTRO_HELPER_INSTRUCTION')
export const handleShowMainIntroductionHelperInstruction = createAction('SHOW_MAIN_INTRO_HELPER_INSTRUCTION')
export const handleHideWhatsAppInstruction = createAction('HIDE_WHATSAPP_INSTRUCTION')
export const handleShowWhatsAppInstruction = createAction('SHOW_WHATSAPP_INSTRUCTION')
export const handleShowSystemVoiceSamples = createAction('SHOW_SYSTEM_VOICE_SAMPLES_DIALOG')
export const hideSystemVoicePromptSampleDialog = createAction('HIDE_SYSTEM_VOICE_SAMPLES_DIALOG')
export const handleShowCallWhisperHelperInstruction = createAction('SHOW_WHISPER_INSTRUCTION_DIALOG')
export const hideCallWhisperInstructionHelperDialog = createAction('HIDE_WHISPER_INSTRUCTION_DIALOG')
export const handleShowIntroductionHelperInstruction = createAction('HANDLE_SHOW_INTRODUTION_HELPER_INSTRUCTION')
export const handleHideIntroductionHelperInstruction = createAction('HANDLE_HIDE_INTRODUTION_HELPER_INSTRUCTION')
export const handleShowMusicOnHoldInstruction = createAction('HANDLE_SHOW_MOH_INSTRUCTION')
export const handleHideMusicOnHoldInstruction = createAction('HANDLE_HIDE_MOH_INSTRUCTION')
export const handleShowMissedCallEmailAlertsInstruction = createAction('HANDLE_SHOW_MISSED_CALL_INSTRUCTION')
export const handleHideMissedCallEmailAlertsInstruction = createAction('HANDLE_HIDE_MISSED_CALL_INSTRUCTION')
export const handleShowWhatsAppHelperInstruction = createAction('SHOW_WHATSAPP_HELPER_INSTRUCTION')
export const handleHideWhatsAppHelperInstruction = createAction('HIDE_WHATSAPP_HELPER_INSTRUCTION')

export const handleHideMultipleDepartmentsHelperDialog = createAction('HANDLE_HIDE_MDL_INSTRUCTION')
export const handleShowVoicemailInstructionHelperDialog = createAction('HANDLE_SHOW_VOICEMAIL_INSTRUCTION')
export const hideVoicemailInstructionHelperDialog = createAction('HANDLE_HIDE_VOICEMAIL_INSTRUCTION')
export const handleShowOutOfHoursHelperInstruction = createAction('HANDLE_SHOW_OOH_INSTRUCTION')
export const hideOohInstructionHelperDialog = createAction('HANDLE_HIDE_OOH_INSTRUCTION')
export const handleShowDeliveryMethodHelperInstruction = createAction('HANDLE_SHOW_DELIVERY_METHOD_INSTRUCTION')
export const hideDeliveryMethodHelperDialog = createAction('HIDE_SHOW_DELIVERY_METHOD_INSTRUCTION')
export const handleShowNumberPresentationHelperInstruction = createAction('HANDLE_SHOW_NUMBER_PRESENTATION_INSTRUCTION')
export const hideNumberPresentationHelperDialog = createAction('HIDE_SHOW_NUMBER_PRESENTATION_INSTRUCTION')
export const hideCustomRecordingMethodHelperDialog = createAction('HIDE_CUSTOM_RECORDING_INSTRUCTION')
export const handleShowCustomRecordingSectionHelperInstruction = createAction('SHOW_CUSTOM_RECORDING_INSTRUCTION')
export const handleShowNumberDescriptionHelperInstruction = createAction('SHOW_NUMBER_DESCRIPTION_INSTRUCTION')
export const hideNumberDescriptionHelperInstruction = createAction('HIDE_NUMBER_DESCRIPTION_INSTRUCTION')
export const handleShowRedirectSectionHelperDialog = createAction('SHOW_REDIRECT_HELPER_INSTRUCTION')
export const hideRedirectSectionHelperDialog = createAction('HIDE_REDIRECT_HELPER_INSTRUCTION')
export const handleShowAnnouncePositionHelperInstruction = createAction('SHOW_ANNOUNCE_POSITION_INSTRUCTION')
export const hideAnnouncePositionHelperInstruction = createAction('HIDE_ANNOUNCE_POSITION_INSTRUCTION')
export const handleShowGoogleTrackingCodeDialogButtonClick = createAction('SHOW_GOOGLE_ANALYICS_DETECT_TRACKING_CODE_DIALOG')
export const hideGoogleAnalyticsAutoDetectTrackingCodeDialog = createAction('HIDE_GOOGLE_ANALYICS_DETECT_TRACKING_CODE_DIALOG')
export const handleShowGoogleAnalyticsHelperInstruction = createAction('SHOW_GOOGLE_ANALYICS_DETECT_TRACKING_CODE_DIALOG')
export const hideGoogleAnalyticsHelperInstruction = createAction('HIDE_GOOGLE_ANALYTICS_HELPER_INSTRUCTION')
export const handleShowQuickCloseHelperInstruction = createAction('SHOW_QUICK_CLOSE_HELPER_INSTRUCTION')
export const hideQuickCloseHelperInstruction = createAction('HIDE_QUICK_CLOSE_HELPER_INSTRUCTION')
export const handleShowCallFilteringHelperInstruction = createAction('SHOW_CALL_FILTERING_HELPER_INSTRUCTION')
export const hideCallFilteringHelperInstruction = createAction('HIDE_CALL_FILTERING_HELPER_INSTRUCTION')
export const handleShowSmsCallbackHelperInstruction = createAction('SHOW_SMS_CALLBACK_HELPER_INSTRUCTION')
export const hideSmsCallbackHelperInstruction = createAction('HIDE_SMS_CALLBACK_HELPER_INSTRUCTION')
export const handleShowMainNumberHelperInstruction = createAction('SHOW_MAIN_NUMBER_DESCRIPTION_HELPER_INSTRUCTION')
export const hideMainNumberDescriptionInstructionHelperDialog = createAction('HIDE_MAIN_NUMBER_DESCRIPTION_HELPER_INSTRUCTION')
export const handleShowGlobalMissedCallEmailAlertsInstruction = createAction('HANDLE_SHOW_GLOBAL_MISSED_CALL_INSTRUCTION')
export const handleHideGlobalMissedCallEmailAlertsInstruction = createAction('HANDLE_HIDE_GLOBAL_MISSED_CALL_INSTRUCTION')
export const handleShowDepartmentMenuChoicePromptSectionHelper = createAction('HANDLE_SHOW_DEPARTMENT_CHOICE_INSTRUCTION')
export const handleHideDepartmentMenuChoiceInstructionHelperDialog = createAction('HANDLE_HIDE_DEPARTMENT_CHOICE_INSTRUCTION')
export const handleMainNumberDescriptionHasFocus = createAction('HANDLE_MAIN_NUMBER_DESCRIPTION_HAS_FOCUS')
export const handleMainNumberDescriptionNoFocus = createAction('HANDLE_MAIN_NUMBER_DESCRIPTION_NO_FOCUS')
export const handleNumberDescriptionHasFocus = createAction('HANDLE_NUMBER_DESCRIPTION_HAS_FOCUS')
export const handleNumberDesctiptionNoFocus = createAction('HANDLE_NUMBER_DESCRIPTION_NO_FOCUS')

export const handleWhisperChange = createAction('UPDATE_WHISPER_PROMPT_SELECTION')
export const handleIntroChange = createAction('UPDATE_INTRO_PROMPT_SELECTION')
export const handleMohChange = createAction('UPDATE_MOH_SELECTION')
export const handleOohChange = createAction('UPDATE_OOH_SELECTION')
export const handleGlobalIntroChange = createAction('UPDATE_GLOBAL_INTRO_SELECTION')
export const handleDepartmentMenuChange = createAction('UPDATE_DEPARTMENT_MENU_SELECTION')

const requestWhisperPrompts = createAction('REQUEST_WHISPER_PROMPTS')
const receiveWhisperPrompts = createAction('RECEIVE_WHISPER_PROMPTS')
const requestIntroPrompts = createAction('REQUEST_INTRO_PROMPTS')
const receiveIntroPrompts = createAction('RECEIVE_INTRO_PROMPTS')
const requestMohPrompts = createAction('REQUEST_MOH_PROMPTS')
const receiveMohPrompts = createAction('RECEIVE_MOH_PROMPTS')

const requestPocketData = createAction('REQUEST_POCKET_DATA')
const receivePocketData = createAction('RECEIVE_POCKET_DATA')

export const handleConfigSaveChanges = createAction('HANDLE_SAVE_CHANGES_FLAG')
export const handlePocketEnabledChange = createAction('HANDLE_POCKET_ENABLE_CHANGE')
export const handlePocketCompanyNameChange = createAction('HANDLE_POCKET_COMPANY_CHANGE')
export const handlePocketSecondsDurationChange = createAction('HANDLE_POCKET_SECONDS_CHANGE')
export const handlePocketGreetingChange = createAction('HANDLE_POCKET_GREETNG_CHANGE')
export const handleShowPocketMethodHelperInstruction = createAction('HANDLE_POCKET_HELPER_SHOW')
export const handleHidePocketMethodHelperInstruction = createAction('HANDLE_POCKET_HELPER_HIDE')
export const handlePocketSetupShow = createAction('HANDLE_POCKET_SETUP_SHOW')
export const handlePocketSetupHide = createAction('HANDLE_POCKET_SETUP_HIDE')
export const handlePocketSetupResult = createAction('HANDLE_POCKET_SETUP_RESULT')

export const handleShowDayTimeRouting = createAction('HANDLE_DAYTIME_ROUTING_SHOW')
export const handleHideDayTimeRouting = createAction('HANDLE_DAYTIME_ROUTING_HIDE')

const requestMohTrack = createAction('REQUEST_MOH_TRACK')
const receiveMohTrack = createAction('RECEIVE_MOH_TRACK')

const handleUnsavedChange = createAction('HANDLE_UNSAVED_CHANGE')

const requestVoicemailPrompts = createAction('REQUEST_VOICEMAIL_PROMPTS')
const receiveVoicemailPrompts = createAction('RECEIVE_VOICEMAIL_PROMPTS')

const requestOohPrompts = createAction('REQUEST_OOH_PROMPTS')
const receiveOohPrompts = createAction('RECEIVE_OOH_PROMPTS')

const requestGlobalIntroPrompts = createAction('REQUEST_GLOBAL_INTRO_PROMPTS')
const receiveGlobalIntroPrompts = createAction('RECEIVE_GLOBAL_INTRO_PROMPTS')

const requestDepartmentMenuPrompts = createAction('REQUEST_DEPARTMENT_MENU_PROMPTS')
const receiveDepartmentMenuPrompts = createAction('RECEIVE_DEPARTMENT_MENU_PROMPTS')

const requestRedirectsHistory = createAction('REQUEST_REDIRECTS_HISTORY')
const receiveRedirectsHistory = createAction('RECEIVE_REDIRECTS_HISTORY')

const requestOutboundUsers = createAction('REQUEST_OUTBOUND_USERS')
const receiveOutboundUsers = createAction('RECEIVE_OUTBOUND_USERS')

// Ring groups
const requestRingGroups = createAction('REQUEST_RING_GROUPS')
const receiveRingGroups = createAction('RECEIVE_RING_GROUPS')
const validateGroupName = createAction('VALIDATE_RING_GROUP_NAME')

export const handleShowCreateEditModal = createAction('HANDLE_SHOW_RING_GROUP_MANAGEMENT_MODAL')
export const handleHideCreateEditModal = createAction('HANDLE_HIDE_RING_GROUP_MANAGEMENT_MODAL')

const toggleToStandard = createAction('TOGGLE_TO_STANDARD')
const toggleToDepartment = createAction('TOGGLE_TO_DEPARTMENTAL')

export const handleNavigationButtonSelect = createAction('HANDLE_NAVIGATION_BUTTON_SELECT')

const receiveConfigResult = createAction('RECEIVE_CONFIG_RESULT')
const requestConfigSave = createAction('REQUEST_CONFIG_SAVE')
const receiveConfigSaveResult = createAction('RECEIVE_CONFIG_SAVE_RESULT')

const validateRedirectRegex = createAction('VALIDATION_REDIRECT_REGEX')
const validateRedirectSetDefault = createAction('VALIDATION_REDIRECT_SET_DEFAULT')
const validateRedirectFullRequest = createAction('VALIDATION_REDIRECT_FULL_REQUEST')
const validateRedirectFullReceived = createAction('VALIDATION_REDIRECT_FULL_RECEIVED')
const validateAtLeastOneRedirect = createAction('VALIDATE_AT_LEAST_ONE_REDIRECT')
const initRedirectVal = createAction('INITILIAZE_VALIDATION_REDIRECT_ARRAY')
const validateRedirectEndpoint = createAction('VALIDATE_REDIRECT_ENDPOINT')
const validateRedirectGroup = createAction('VALIDATE_REDIRECT_GROUP')
const initUserVal = createAction('INITIALIZE_VALIDATION_SIP_USER')
const validateUserFullReceived = createAction('VALIDATE_SIP_USER')
const validateUserToAddFullReceived = createAction('VALIDATE_SIP_USER_TO_ADD')
export const validateTimeSlot = createAction('VALIDATE_TIME_SLOT')

export const validateEmailSyntax = createAction('VALIDATE_EMAIL_SYNTAX')
export const validateCloseTime = createAction('VALIDATE_CLOSE_TIME')

export const validateGroupNameField = context => async (dispatch) => {
  const name = context.newValue
  let validState
  if (name.length > 1) {
    const url = 'api/Configuration/IsGroupNameUsed/' + name
    fetchHelper.getJson(url).then(res => {
      validState = validationStates.fromBool((res.data === false))
      dispatch(validateGroupName(validState))
    }).catch(function (error) {
      console.error(error)
    })
  } else {
    validState = validationStates.EMPTY

    dispatch(validateGroupName(validState))
  }
}

export const removeRedirect = context => async (dispatch) => {
  if (context.wizard) {
    dispatch(handleRemoveRedirect({ redirect: context.redirect.order, wizard: true }))
  } else {
    dispatch(handleRemoveRedirect({ redirect: context.redirect.redirectDetail }))
  }
}

export const validateUserToAdd = context => async (dispatch) => {
  const val = context.val
  const type = context.type
  let isValidEmail = context.validation && context.validation.userToAdd ? context.validation.userToAdd.emailValid : validationStates.EMPTY
  let isValidMobile = context.validation && context.validation.userToAdd ? context.validation.userToAdd.mobileValid : validationStates.EMPTY

  if (type === 'email') {
    isValidEmail = validationStates.fromBool(isValidEmailSyntax(val))
    dispatch(validateUserToAddFullReceived({
      emailValid: isValidEmail,
      mobileValid: isValidMobile
    }))
  } else {
    const numberType = checkNumberType(val)
    isValidMobile = numberType === PhoneTypes.mobile ? isValidMobSyntax(val) : false

    if (isValidMobile) {
      // HLR
      const url = 'api/Users/isValidTelephone'
      fetchHelper.postJson(url, { phoneNumber: val })
        .then(res => {
          isValidMobile = validationStates.fromBool(res.data)
          dispatch(validateUserToAddFullReceived({
            emailValid: isValidEmail,
            mobileValid: isValidMobile
          }))
        }).catch(err => console.error(err))
    }
  }
}

export const validateUser = context => async (dispatch) => {
  const val = context.val
  const position = context.position
  const count = context.userCount
  const type = context.type

  let isValidEmail = (context.validation && context.validation.users && context.validation.users.length >= position) ? context.validation.users[position].validEmail : validationStates.EMPTY
  let isValidMobile = (context.validation && context.validation.users && context.validation.users.length >= position) ? context.validation.users[position].validMobile : validationStates.EMPTY

  dispatch(initUserVal({ count }))

  if (type === 'email') {
    // Check users email is valid
    isValidEmail = validationStates.fromBool(isValidEmailSyntax(val))
    dispatch(validateUserFullReceived({
      position,
      validEmail: isValidEmail,
      validMobile: isValidMobile
    }))
  } else {
    // Validate mobile number
    const numberType = checkNumberType(val)
    isValidMobile = numberType === PhoneTypes.mobile ? isValidMobSyntax(val) : validationStates.fromBool(false)

    if (isValidMobile) {
      // HLR
      const url = '/api/Users/isValidTelephone'
      await fetchHelper.postJson(url, { phoneNumber: val })
        .then(res => {
          isValidMobile = validationStates.fromBool(res.data)
          dispatch(validateUserFullReceived({
            position,
            validEmail: isValidEmail,
            validMobile: isValidMobile
          }))
        }).catch(err => console.error(err))
    } else {
      dispatch(validateUserFullReceived({
        position,
        validEmail: isValidEmail,
        validMobile: isValidMobile
      }))
    }
  }
}

export const validateRedirect = context => async (dispatch) => {
  const redirect = context.redirect
  const position = context.position
  const count = context.redirectCount
  const hasAtLeastOneRedirect = count > 0
  const type = context.redirectType
  const redirectExceptions = context.redirectExceptions
  dispatch(initRedirectVal({ count }))

  if (type === RedirectType.PhoneNumber) {
    if (redirectExceptions && redirectExceptions.includes(redirect)) {
      dispatch(validateRedirectGroup({
        position,
        pass: validationStates.VALID,
        error: undefined,
        isTimeSlot: context.isTimeSlot,
        redirectCount: context.redirectCount
      }))
    }
    else {
      const regexPass = redirect && redirect.length > 0 ? isValidRedirectRegex(redirect) : false

      dispatch(validateRedirectSetDefault({ count, position, pass: validationStates.VALID }))
      if (!context.isMultiDept || (context.isMultiDept && context.isDeptActive)) {
        dispatch(validateAtLeastOneRedirect({
          fieldName: fieldNames.REDIRECTS_GROUP,
          pass: validationStates.fromBool(hasAtLeastOneRedirect)
        }))
        if (hasAtLeastOneRedirect && (redirect !== null && redirect !== '')) {
          dispatch(validateRedirectRegex({ position, pass: validationStates.fromBool(regexPass) }))
        }
      } else {
        dispatch(validateRedirectSetDefault({ position, pass: validationStates.VALID }))
        if (redirect !== undefined && redirect !== '') {
          dispatch(validateRedirectRegex({ position, pass: validationStates.fromBool(regexPass) }))
        }
      }

      // only make the API check if the regex has passed
      if (regexPass) {
        const url = 'api/Configuration/IsValidRedirectNumber'

        const hasPassedFullValidation = await fetchHelper.postJson(url, { number: redirect })
        if (hasPassedFullValidation.data === true) {
          dispatch(validateRedirectFullReceived({
            position,
            pass: validationStates.fromBool(hasPassedFullValidation),
            isTimeSlot: context.isTimeSlot,
            redirectCount: context.redirectCount,
            wizard: context.wizard
          }))
        } else {
          dispatch(validateRedirectFullReceived({
            position,
            pass: validationStates.INVALID,
            isTimeSlot: context.isTimeSlot,
            redirectCount: context.redirectCount,
            wizard: context.wizard
          }))
        }
      } else {
        dispatch(validateRedirectFullReceived({
          position,
          pass: validationStates.INVALID,
          isTimeSlot: context.isTimeSlot,
          redirectCount: context.redirectCount,
          wizard: context.wizard
        }))
      }
    }
  } else if (type === RedirectType.Endpoint) {
    // Validate Endpoint
    const url = `api/Configuration/ValidateRedirectEndpoint/${context.itemId}`
    const isValid = await fetchHelper.getJson(url)
    dispatch(validateRedirectEndpoint({
      position,
      pass: validationStates.fromBool(isValid.data),
      isTimeSlot: context.isTimeSlot,
      redirectCount: context.redirectCount
    }))
  } else {
    // Validated Ring Group
    const url = `api/Configuration/ValidateRedirectGroup/${context.itemId}`
    const isValid = await fetchHelper.getJson(url)
    dispatch(validateRedirectGroup({
      position,
      pass: validationStates.fromBool(isValid.data.valid),
      error: isValid.data.error,
      isTimeSlot: context.isTimeSlot,
      redirectCount: context.redirectCount
    }))
  }
}


export const handleConfigSave = context => async (dispatch) => {
  const notifier = context.notifier
  localStorage.removeItem('deptToggled')

  let redirectCount = 0
  if (context.config.redirects) {
    const redirects = context.config.redirects.filter(f => !f.deleted)
    redirectCount += redirects.length
  }

  if (context.config.timeSlots) {
    context.config.timeSlots.forEach((slot, i) => {
      if (slot.redirects && slot.redirects.length) { redirectCount += slot.redirects.length }
    })
  }

  if (redirectCount === 0 && context.ignoreRedirects !== true) {
    dispatch(handleToggleEmptyRedirectsWarning(true))
  } else if (context.canSubmit === false) {
    notifier({ message: 'Please correct the highlighted errors before saving changes', isError: true })
  } else {
    dispatch(handleToggleEmptyRedirectsWarning(false))
    dispatch(requestConfigSave(context))

    const queueNo = context.queueNo
    const depNo = context.depNo
    const config = context.config
    config.voicePrompt.voicePromptId = Number(config.voicePrompt.voicePromptId)
    config.whisperPrompt.promptId = Number(config.whisperPrompt.promptId)
    config.voicemailPrompt.promptId = Number(config.voicemailPrompt.promptId)
    config.outOfHoursPrompt.promptId = Number(config.outOfHoursPrompt.promptId)
    config.introPrompt.promptId = Number(config.introPrompt.promptId)
    config.musicOnHoldPrompt.promptId = Number(config.musicOnHoldPrompt.promptId)

    config.unsavedChanges = false
    sessionStorage.removeItem('unsavedChanges')
    const url = depNo !== undefined ? 'api/Configuration/' + queueNo + '/' + depNo : 'api/Configuration/' + queueNo

    await fetchHelper.postJson(url, config)
      .then(res => {
        const validation = res.data

        if (validation !== null && validation.valid === false) {
          dispatch(initRedirectVal({ count: config.redirects.length }))

          notifier({ message: 'There was a problem saving your settings', isError: true })
          dispatch(receiveConfigSaveResult(validation))
          dispatch(handleConfigSaveChanges())

          if (validation.field) {
            if (validation.field.startsWith('redirect')) {
              let position = 0
              position = Number(validation.field[validation.field.length - 2])
              isNaN(position)
              position = Number(validation.field[validation.field.length - 1])

              dispatch(validateRedirectFullReceived({
                position,
                pass: validationStates.INVALID,
                msg: validation.error
              }))
            } else if (validation.field.toLowerCase().includes('close')) {
              dispatch(validateCloseTime({
                fieldName: validation.field,
                pass: validationStates.INVALID
              }))
            }
          }
        } else {
          notifier({ message: 'Settings saved', isError: false })
          dispatch(receiveConfigSaveResult())
          dispatch(handleConfigSaveChanges())
        }
      })

    if (!localStorage.getItem('umrPopupShown') && context.queueNo.startsWith('084') && !context.hasUmr) {
      dispatch(handleShowUmrDialog())
    }
  }
}

export const requestConfiguration = context => async (dispatch) => {
  dispatch(requestConfig())

  const queueNo = context.queueNo
  const depNo = context.depNo

  const url = depNo !== undefined ? 'api/Configuration/' + queueNo + '/' + depNo : 'api/Configuration/' + queueNo

  if (depNo !== undefined) {
    dispatch(toggleToDepartment(context))
  } else {
    dispatch(toggleToStandard(context))
  }

  const response = await fetchHelper.getJson(url)
  if (!fetchHelper.isUnauthorised(response)) {
    const config = response.data
    const id = getIdForTime(config)
    config.quickOpenCloseTimeId = id.toString()

    dispatch(receiveConfigResult(config))
  }
}

export const fetchWhisperPrompts = context => async (dispatch) => {
  dispatch(requestWhisperPrompts())

  const url = 'api/Configuration/WhisperPrompts'

  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    // dispatch(logout({history:context.history}))
  } else {
    const whisperPrompts = response.data
    dispatch(receiveWhisperPrompts(whisperPrompts))
  }
}

export const fetchDepartmentMenuPrompts = context => async (dispatch) => {
  dispatch(requestDepartmentMenuPrompts())

  const url = 'api/Configuration/DepartmentMenuPrompts'

  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    // dispatch(logout({history:context.history}))
  } else {
    const departmentMenuPrompts = response.data
    dispatch(receiveDepartmentMenuPrompts(departmentMenuPrompts))
  }
}

export const fetchPocketData = context => async (dispatch) => {
  dispatch(requestPocketData())

  const queueNo = context.queueNo
  const depNo = context.depNo

  const url = depNo !== undefined ? 'api/Configuration/GetPocketInfo/' + queueNo + '/' + depNo : 'api/Configuration/GetPocketInfo/' + queueNo
  const response = await fetchHelper.getJson(url)
  if (response) {
    if (fetchHelper.isUnauthorised(response)) {
      // dispatch(logout({history:context.history}))
    } else {
      const pocketData = response.data
      dispatch(receivePocketData(pocketData))
      if (context.callback) { context.callback() }
    }
  }
}

export const fetchIntroPrompts = context => async (dispatch) => {
  dispatch(requestIntroPrompts())

  const url = 'api/Configuration/IntroPrompts'
  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    // dispatch(logout({history:context.history}))
  } else {
    const introPrompts = response.data
    dispatch(receiveIntroPrompts(introPrompts))
  }
}

export const fetchMohPrompts = context => async (dispatch) => {
  dispatch(requestMohPrompts())

  const url = 'api/Configuration/MohPrompts'

  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    // dispatch(logout({history:context.history}))
  } else {
    const mohPrompts = response.data
    dispatch(receiveMohPrompts(mohPrompts))
  }
}

export const fetchMohTrack = context => async (dispatch) => {
  dispatch(requestMohTrack())

  if (context.value === undefined) {
    let departmentNumber = context.depNo
    if (departmentNumber === undefined) {
      departmentNumber = 0
    }
    const queueNo = context.queueNo

    const url = 'api/Configuration/GetRandomSelectedTrackIdFromQueueNumber/' + queueNo + '/' + departmentNumber

    const response = await fetchHelper.getJson(url)

    const data = {
      promptId: response.data.categoryId,
      trackId: response.data.trackId,
      promptDescription: response.data.categoryDescription
    }

    dispatch(receiveMohTrack(data))
  } else {
    // api call in here
    const url = 'api/Configuration/GetRandomTrackIdFromCategory/' + context.value

    const response = await fetchHelper.getJson(url)

    if (fetchHelper.isUnauthorised(response)) {
      // dispatch(logout({history:context.history}))
    } else {
      const data = {
        promptId: context.value,
        trackId: response.data,
        promptDescription: context.label
      }

      dispatch(receiveMohTrack(data))
    }
  }
}

export const fetchVoicemailPrompts = context => async (dispatch) => {
  dispatch(requestVoicemailPrompts())

  const url = 'api/Configuration/VoicemailPrompts'

  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    // dispatch(logout({history:context.history}))
  } else {
    const voicemailPrompts = response.data
    dispatch(receiveVoicemailPrompts(voicemailPrompts))
  }
}

export const fetchOohPrompts = context => async (dispatch) => {
  dispatch(requestOohPrompts())

  const url = 'api/Configuration/OutOfHoursPrompts'

  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    // dispatch(logout({history:context.history}))
  } else {
    const oohPrompts = response.data
    dispatch(receiveOohPrompts(oohPrompts))
  }
}

export const fetchGlobalIntroPrompts = context => async (dispatch) => {
  dispatch(requestGlobalIntroPrompts())

  const url = 'api/Configuration/GlobalIntroPrompts'

  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    // dispatch(logout({history:context.history}))
  } else {
    const globalIntroPrompts = response.data
    dispatch(receiveGlobalIntroPrompts(globalIntroPrompts))
  }
}

export const fetchRedirectsHistory = context => async (dispatch) => {
  dispatch(requestRedirectsHistory())

  const url = 'api/Configuration/GetRedirectsHistory'
  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    dispatch(logout({ history: context.history }))
  } else {
    const redirectsHistory = response.data
    dispatch(receiveRedirectsHistory(redirectsHistory))
  }
}

export const fetchOutboundUsers = context => async (dispatch) => {
  dispatch(requestOutboundUsers())

  const url = 'api/Configuration/GetOutboundUsers'
  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    dispatch(logout({ history: context.history }))
  } else {
    const outboundUsers = response.data
    dispatch(receiveOutboundUsers(outboundUsers))
  }
}

export const fetchRingGroups = context => async (dispatch) => {
  dispatch(requestRingGroups())

  if (context.groups && context.groups.length > 0 && !context.new) {
    dispatch(receiveRingGroups(context.groups))
  } else {
    const url = 'api/Configuration/GetRingGroups'
    const response = await fetchHelper.getJson(url)
    if (fetchHelper.isUnauthorised(response)) {
      dispatch(logout({ history: context.history }))
    } else {
      if (response.data.length > 0) {
        const ringGroups = response.data
        dispatch(receiveRingGroups(ringGroups))
      }
    }
  }
}

export const clearRedirectsHistory = context => async (dispatch) => {
  const url = 'api/Configuration/ClearClientRedirectHistory'
  const res = await fetchHelper.getJson(url)
  const notifier = context.notifier

  if (res.data) {
    dispatch(handleClearRedirectsHistory())
    notifier({ message: 'Your redirect history has been cleared.', isError: false })
  } else {
    // Something went wrong
    notifier({ message: 'There was an error clearing your redirects. Please try again, or contact support.', isError: true })
  }
}

const savingStateReducer = handleActions({
  [requestConfigSave] (state) {
    return { ...state, isSaving: true }
  },
  [receiveConfigSaveResult] (state, action) {
    return { ...state, isSaving: false, apiValidation: action.payload }
  }
}, { isSaving: false })

const loadingStateReducer = handleActions({
  [requestWhisperPrompts] (state) {
    return { ...state, whisperPrompts: true }
  },
  [receiveWhisperPrompts] (state) {
    return { ...state, whisperPrompts: false }
  },
  [requestIntroPrompts] (state) {
    return { ...state, introPrompts: true }
  },
  [receiveIntroPrompts] (state) {
    return { ...state, introPrompts: false }
  },
  [requestMohPrompts] (state) {
    return { ...state, mohPrompts: true }
  },
  [receiveMohPrompts] (state) {
    return { ...state, mohPrompts: false }
  },
  [requestVoicemailPrompts] (state) {
    return { ...state, voicemailPrompts: true }
  },
  [receiveVoicemailPrompts] (state) {
    return { ...state, voicemailPrompts: false }
  },
  [requestOohPrompts] (state) {
    return { ...state, oohPrompts: true }
  },
  [receiveOohPrompts] (state) {
    return { ...state, oohPrompts: false }
  },
  [requestGlobalIntroPrompts] (state) {
    return { ...state, globalIntroPrompts: true }
  },
  [receiveGlobalIntroPrompts] (state) {
    return { ...state, globalIntroPrompts: false }
  },
  [requestDepartmentMenuPrompts] (state) {
    return { ...state, departmentMenuPrompts: true }
  },
  [receiveDepartmentMenuPrompts] (state) {
    return { ...state, departmentMenuPrompts: false }
  },
  [requestConfig] (state) {
    return { ...state, configuration: true }
  },
  [receiveConfigResult] (state) {
    return { ...state, configuration: false }
  },
  [requestRedirectsHistory] (state) {
    return { ...state, redirectsHistory: true }
  },
  [receiveRedirectsHistory] (state) {
    return { ...state, redirectsHistory: false }
  },
  [requestOutboundUsers] (state) {
    return { ...state, outboundUsers: true }
  },
  [receiveOutboundUsers] (state) {
    return { ...state, outboundUsers: false }
  },
  [requestRingGroups] (state) {
    return { ...state, ringGroups: true }
  },
  [requestRingGroups] (state) {
    return { ...state, ringGroups: false }
  },
  [requestPocketData] (state) {
    return { ...state, pocket: false }
  }
}, {
  whisperPrompts: false,
  introPrompts: false,
  mohPrompts: false,
  oohPrompts: false,
  voicemailPrompts: false,
  globalIntroPrompts: false,
  departmentMenuPrompts: false,
  configuration: false,
  redirectsHistory: false,
  outboundUsers: false,
  ringGroups: false,
  pocket: false
})

const dialogVisibilityReducer = handleActions({
  [handleShowUmrDialog] (state) {
    localStorage.setItem('umrPopupShown', new Date())
    return { ...state, showUmrDialog: true }
  },
  [handleHideUmrDialog] (state) {
    return { ...state, showUmrDialog: false }
  },
  [handleShowMultipleDepartmentsHelperDialog] (state) {
    return { ...state, MultipleDepartmentsHelperDialogDisplayed: true }
  },
  [handleHideMultipleDepartmentsHelperDialog] (state) {
    return { ...state, MultipleDepartmentsHelperDialogDisplayed: false }
  },
  [handleShowMainIntroductionHelperInstruction] (state) {
    return { ...state, mainIntroductionInstructionHelperDialogDisplayed: true }
  },
  [handleHideMainIntroductionHelperInstruction] (state) {
    return { ...state, mainIntroductionInstructionHelperDialogDisplayed: false }
  },
  [handleShowWhatsAppInstruction] (state) {
    return { ...state, whatsappHelperDialogDisplayed: true }
  },
  [handleHideWhatsAppInstruction] (state) {
    return { ...state, whatsappHelperDialogDisplayed: false }
  },
  [handleShowSystemVoiceSamples] (state) {
    return { ...state, showSystemVoicePromptSampleDialog: true }
  },
  [hideSystemVoicePromptSampleDialog] (state) {
    return { ...state, showSystemVoicePromptSampleDialog: false }
  },
  [handleShowCallWhisperHelperInstruction] (state) {
    return { ...state, callWhisperInstructionHelperDialogDisplayed: true }
  },
  [hideCallWhisperInstructionHelperDialog] (state) {
    return { ...state, callWhisperInstructionHelperDialogDisplayed: false }
  },
  [handleShowPocketMethodHelperInstruction] (state) {
    return { ...state, pocketHelperInstructionDialogDisplayed: true }
  },
  [handleHidePocketMethodHelperInstruction] (state) {
    return { ...state, pocketHelperInstructionDialogDisplayed: false }
  },
  [handlePocketSetupShow] (state) {
    return { ...state, setupPocketLink: true }
  },
  [handlePocketSetupHide] (state) {
    return { ...state, setupPocketLink: false }
  },
  [handlePocketSetupResult] (state, action) {
    const res = action.payload
    return { ...state, pocketResult: res }
  },
  [handleShowWhatsAppHelperInstruction] (state) {
    return { ...state, whatsAppInstructionHelperDialogDisplayed: true }
  },
  [handleHideWhatsAppHelperInstruction] (state) {
    return { ...state, whatsAppInstructionHelperDialogDisplayed: false }
  },
  [handleShowIntroductionHelperInstruction] (state) {
    return { ...state, introductionInstructionHelperDialogDisplayed: true }
  },
  [handleHideIntroductionHelperInstruction] (state) {
    return { ...state, introductionInstructionHelperDialogDisplayed: false }
  },
  [handleShowMusicOnHoldInstruction] (state) {
    return { ...state, mohInstructionHelperDialogDisplayed: true }
  },
  [handleHideMusicOnHoldInstruction] (state) {
    return { ...state, mohInstructionHelperDialogDisplayed: false }
  },
  [handleShowMissedCallEmailAlertsInstruction] (state) {
    return { ...state, missedCallInstructionHelperDialogDisplayed: true }
  },
  [handleHideMissedCallEmailAlertsInstruction] (state) {
    return { ...state, missedCallInstructionHelperDialogDisplayed: false }
  },
  [handleShowVoicemailInstructionHelperDialog] (state) {
    return { ...state, voicemailInstructionHelperDialogDisplayed: true }
  },
  [hideVoicemailInstructionHelperDialog] (state) {
    return { ...state, voicemailInstructionHelperDialogDisplayed: false }
  },
  [handleShowOutOfHoursHelperInstruction] (state) {
    return { ...state, oohInstructionHelperDialogDisplayed: true }
  },
  [hideOohInstructionHelperDialog] (state) {
    return { ...state, oohInstructionHelperDialogDisplayed: false }
  },
  [handleShowDeliveryMethodHelperInstruction] (state) {
    return { ...state, deliveryMethodInstructionHelperDialogDisplayed: true }
  },
  [hideDeliveryMethodHelperDialog] (state) {
    return { ...state, deliveryMethodInstructionHelperDialogDisplayed: false }
  },
  [handleShowNumberPresentationHelperInstruction] (state) {
    return { ...state, numberPresentationInstructionHelperDialogDisplayed: true }
  },
  [hideNumberPresentationHelperDialog] (state) {
    return { ...state, numberPresentationInstructionHelperDialogDisplayed: false }
  },
  [handleShowCustomRecordingSectionHelperInstruction] (state) {
    return { ...state, customRecordingInstructionHelperDialogDisplayed: true }
  },
  [hideCustomRecordingMethodHelperDialog] (state) {
    return { ...state, customRecordingInstructionHelperDialogDisplayed: false }
  },
  [handleShowNumberDescriptionHelperInstruction] (state) {
    return { ...state, numberDescriptionInstructionHelperDialogDisplayed: true }
  },
  [hideNumberDescriptionHelperInstruction] (state) {
    return { ...state, numberDescriptionInstructionHelperDialogDisplayed: false }
  },
  [handleShowRedirectSectionHelperDialog] (state) {
    return { ...state, RedirectSectionHelperDialogDisplayed: true }
  },
  [hideRedirectSectionHelperDialog] (state) {
    return { ...state, RedirectSectionHelperDialogDisplayed: false }
  },
  [handleShowAnnouncePositionHelperInstruction] (state) {
    return { ...state, announcePositionInstructionHelperDialogDisplayed: true }
  },
  [hideAnnouncePositionHelperInstruction] (state) {
    return { ...state, announcePositionInstructionHelperDialogDisplayed: false }
  },
  [handleShowGoogleTrackingCodeDialogButtonClick] (state) {
    return { ...state, autoDetectGoogleTrackingCodeDialogDisplayed: true }
  },
  [hideGoogleAnalyticsAutoDetectTrackingCodeDialog] (state) {
    return { ...state, autoDetectGoogleTrackingCodeDialogDisplayed: false }
  },
  [handleShowGoogleAnalyticsHelperInstruction] (state) {
    return { ...state, googleAnalyticsInstructionHelperDialogDisplayed: true }
  },
  [hideGoogleAnalyticsHelperInstruction] (state) {
    return { ...state, googleAnalyticsInstructionHelperDialogDisplayed: false }
  },
  [handleShowQuickCloseHelperInstruction] (state) {
    return { ...state, quickCloseInstructionHelperDialogDisplayed: true }
  },
  [hideQuickCloseHelperInstruction] (state) {
    return { ...state, quickCloseInstructionHelperDialogDisplayed: false }
  },
  [handleShowCallFilteringHelperInstruction] (state) {
    return { ...state, callFilteringInstructionHelperDialogDisplayed: true }
  },
  [hideCallFilteringHelperInstruction] (state) {
    return { ...state, callFilteringInstructionHelperDialogDisplayed: false }
  },
  [handleShowSmsCallbackHelperInstruction] (state) {
    return { ...state, SmsCallbackInstructionHelperDialogDisplayed: true }
  },
  [hideSmsCallbackHelperInstruction] (state) {
    return { ...state, SmsCallbackInstructionHelperDialogDisplayed: false }
  },
  [handleShowMainNumberHelperInstruction] (state) {
    return { ...state, mainNumberDescriptionInstructionHelperDialogDisplayed: true }
  },
  [hideMainNumberDescriptionInstructionHelperDialog] (state) {
    return { ...state, mainNumberDescriptionInstructionHelperDialogDisplayed: false }
  },
  [handleShowGlobalMissedCallEmailAlertsInstruction] (state) {
    return { ...state, globalMissedCallInstructionHelperDialogDisplayed: true }
  },
  [handleHideGlobalMissedCallEmailAlertsInstruction] (state) {
    return { ...state, globalMissedCallInstructionHelperDialogDisplayed: false }
  },
  [handleShowDepartmentMenuChoicePromptSectionHelper] (state) {
    return { ...state, departmentMenuChoiceInstructionHelperDialogDisplayed: true }
  },
  [handleHideDepartmentMenuChoiceInstructionHelperDialog] (state) {
    return { ...state, departmentMenuChoiceInstructionHelperDialogDisplayed: false }
  },
  [handleShowCreateEditModal] (state, action) {
    return {
      ...state,
      showCreateEditModal: true,
      isEditingRingGroup: action.payload.editing,
      groupToEdit: action.payload.groupId
    }
  },
  [handleHideCreateEditModal] (state) {
    return {
      ...state,
      showCreateEditModal: false,
      groupToEdit: 0
    }
  },
  [handleShowDayTimeRouting] (state) {
    return { ...state, dayTimeRoutingDisplayed: true }
  },
  [handleHideDayTimeRouting] (state) {
    return { ...state, dayTimeRoutingDisplayed: false }
  }
}, {
  showSystemVoicePromptSampleDialog: false,
  callWhisperInstructionHelperDialogDisplayed: false,
  handleHideIntroductionHelperInstruction: false,
  mohInstructionHelperDialogDisplayed: false,
  missedCallInstructionHelperDialogDisplayed: false,
  voicemailInstructionHelperDialogDisplayed: false,
  MultipleDepartmentsHelperDialogDisplayed: false,
  oohInstructionHelperDialogDisplayed: false,
  deliveryMethodInstructionHelperDialogDisplayed: false,
  numberPresentationInstructionHelperDialogDisplayed: false,
  customRecordingInstructionHelperDialogDisplayed: false,
  announcePositionInstructionHelperDialogDisplayed: false,
  autoDetectGoogleTrackingCodeDialogDisplayed: false,
  googleAnalyticsInstructionHelperDialogDisplayed: false,
  quickCloseInstructionHelperDialogDisplayed: false,
  callFilteringInstructionHelperDialogDisplayed: false,
  callBackSmsInstructionHelperDialogDisplayed: false,
  mainIntroductionInstructionHelperDialogDisplayed: false,
  whatsappHelperDialogDisplayed: false,
  mainNumberDescriptionInstructionHelperDialogDisplayed: false,
  globalMissedCallInstructionHelperDialogDisplayed: false,
  departmentMenuChoiceInstructionHelperDialogDisplayed: false,
  showUmrDialog: false,
  showCreateEditModal: false,
  whatsAppInstructionHelperDialogDisplayed: false
})

const promptsReducer = handleActions({
  [receiveGlobalIntroPrompts] (state, action) {
    return { ...state, globalIntroPrompts: action.payload }
  },
  [receiveWhisperPrompts] (state, action) {
    return { ...state, whisperPrompts: action.payload }
  },
  [receiveIntroPrompts] (state, action) {
    return { ...state, introPrompts: action.payload }
  },
  [receiveMohPrompts] (state, action) {
    return { ...state, mohPrompts: action.payload }
  },
  [receiveVoicemailPrompts] (state, action) {
    return { ...state, voicemailPrompts: action.payload }
  },
  [receiveOohPrompts] (state, action) {
    return { ...state, oohPrompts: action.payload }
  },
  [receiveDepartmentMenuPrompts] (state, action) {
    return { ...state, departmentMenuPrompts: action.payload }
  }
}, { introPrompts: [] })

const redirectsReducer = handleActions({
  [receiveRedirectsHistory] (state, action) {
    return { ...state, redirectsHistory: action.payload }
  },
  [handleClearRedirectsHistory] (state, action) {
    return { ...state, redirectsHistory: null }
  },
  [receiveOutboundUsers] (state, action) {
    return { ...state, outboundUsers: action.payload }
  },
  [receiveRingGroups] (state, action) {
    return { ...state, ringGroups: action.payload }
  }
}, { redirectsHistory: [], outboundUsers: [], ringGroups: [] })

const validationReducer = handleActions({
  [validateTimeSlot] (state, action) {
    if (action.payload === undefined) {
      return update(state, {
        timeslot: {
          $set: { valid: validationStates.fromBool(true) }
        }
      })
    }

    const startTime = action.payload.slot.startTime
    const endTime = action.payload.slot.endTime

    return update(state, {
      timeslot: {
        $set: {
          valid: action.payload.slot && parseInt(startTime) < parseInt(endTime) &&
                        (action.payload.slot.mon || action.payload.slot.tue || action.payload.slot.wed || action.payload.slot.thur || action.payload.slot.fri || action.payload.slot.sat || action.payload.slot.sun)
            ? validationStates.fromBool(true)
            : validationStates.fromBool(false)
        }
      },
      timeslotTimes: {
        $set: {
          valid: validationStates.fromBool(startTime < endTime)
        }
      }
    })
  },
  [initUserVal] (state, action) {
    if (!state.users) {
      const array = new Array(action.payload.count).fill({})

      return update(state, {
        users: { $set: array }
      })
    } else {
      const newArray = state.users
      newArray.push({})

      return update(state, {
        users: { $set: newArray }
      })
    }
  },
  [initRedirectVal] (state, action) {
    if (!state.redirects) {
      const array = new Array(action.payload.count).fill({})

      return update(state, {
        redirects: { $set: array }
      })
    } else {
      const newArray = state.redirects
      newArray.push({})

      return update(state, {
        redirects: { $set: newArray }
      })
    }
  },
  [validateRedirectRegex] (state, action) {
    return update(state, {
      redirects: {
        [action.payload.position === 0 ? action.payload.position : action.payload.position - 1]: {
          $merge: { valid: action.payload.pass, errorMsg: configValidationErrors[action.type], timeslot: action.payload.timeslot ? action.payload.timeslot : undefined }
        }
      }
    })
  },
  [validateRedirectSetDefault] (state, action) {
    return update(state, {
      redirects: {
        [action.payload.position === 0 ? action.payload.position : action.payload.position - 1]: {
          $merge: {
            valid: validationStates.VALID
          }
        }
      }
    })
  },
  [validateRedirectFullRequest] (state, action) {
    return update(state, {
      redirects: {
        [action.payload.position === 0 ? action.payload.position : action.payload.position - 1]: {
          $merge: {
            valid: validationStates.INDETERMINATE
          }
        }
      }
    })
  },
  [handleResetTimeSlotRedirectValidation] (state, action) {
    const array = new Array(action.payload.length > 0 ? action.payload.length : 1).fill({ })

    return update(state, {
      timeslotRedirects: {
        $set: [...array]
      }
    })
  },
  [validateRedirectFullReceived] (state, action) {
    if (action.payload.isTimeSlot === true) {
      if (!state.timeslotRedirects || (state.timeslotRedirects && state.timeslotRedirects.length !== action.payload.redirectCount)) {
        const array = new Array(action.payload.redirectCount).fill({})
        array[action.payload.position] = {
          valid: action.payload.pass,
          errorMsg: configValidationErrors[action.type]
        }

        return update(state, {
          timeslotRedirects: {
            $set: array
          }
        })
      } else {
        return update(state, {
          timeslotRedirects: {
            [action.payload.position]: {
              $merge: { valid: action.payload.pass, errorMsg: configValidationErrors[action.type] }
            }
          }
        })
      }
    } else {
      const newArr = state.redirects
      if (action.payload.wizard) {
        newArr[action.payload.position - 1] = {
          valid: action.payload.pass,
          errorMsg: action.payload.msg ? action.payload.msg : configValidationErrors[action.type]
        }
      } else {
        newArr[action.payload.position === 0 ? action.payload.position : action.payload.position - 1] = {
          valid: action.payload.pass,
          errorMsg: action.payload.msg ? action.payload.msg : configValidationErrors[action.type]
        }
      }

      return {
        ...state,
        redirects: newArr
      }
    }
  },
  [validateUserFullReceived] (state, action) {
    const newArr = state.users
    newArr[action.payload.position] = {
      validEmail: action.payload.validEmail,
      validMobile: action.payload.validMobile
    }

    return {
      ...state,
      users: newArr
    }
  },
  [validateUserToAddFullReceived] (state, action) {
    return {
      ...state,
      userToAdd: action.payload
    }
  },
  [validateRedirectEndpoint] (state, action) {
    if (action.payload.isTimeSlot === true) {
      if (!state.timeslotRedirects || (state.timeslotRedirects && state.timeslotRedirects.length !== action.payload.redirectCount)) {
        const array = new Array(action.payload.redirectCount).fill({})
        array[action.payload.position] = {
          valid: action.payload.pass,
          errorMsg: configValidationErrors[action.type]
        }

        return update(state, {
          timeslotRedirects: {
            $set: array
          }
        })
      } else {
        return update(state, {
          timeslotRedirects: {
            [action.payload.position]: {
              $merge: { valid: action.payload.pass, errorMsg: configValidationErrors[action.type] }
            }
          }
        })
      }
    } else {
      return update(state, {
        redirects: {
          [action.payload.position === 0 ? action.payload.position : action.payload.position - 1]: {
            $merge: { valid: action.payload.pass, errorMsg: configValidationErrors[action.type] }
          }
        }
      })
    }
  },
  [validateRedirectGroup] (state, action) {
    if (action.payload.isTimeSlot === true) {
      if (!state.timeslotRedirects || (state.timeslotRedirects && state.timeslotRedirects.length !== action.payload.redirectCount)) {
        const array = new Array(action.payload.redirectCount).fill({})
        array[action.payload.position] = {
          valid: action.payload.pass,
          errorMsg: configValidationErrors[action.type]
        }

        return update(state, {
          timeslotRedirects: {
            $set: array
          }
        })
      } else {
        return update(state, {
          timeslotRedirects: {
            [action.payload.position]: {
              $merge: { valid: action.payload.pass, errorMsg: configValidationErrors[action.type] }
            }
          }
        })
      }
    } else {
      return update(state, {
        redirects: {
          [action.payload.position === 0 ? action.payload.position : action.payload.position - 1]: {
            $merge: { valid: action.payload.pass, errorMsg: action.payload.error ? action.payload.error : configValidationErrors[action.type] }
          }
        }
      })
    }
  },
  [validateAtLeastOneRedirect] (state, action) {
    return update(state, {
      [action.payload.fieldName]: {
        $merge: { valid: action.payload.pass, errorMsg: configValidationErrors[action.type] }
      }
    })
  },
  [validateEmailSyntax] (state, action) {
    return update(state, {
      [action.payload.fieldName]: {
        $merge: { valid: validationStates.fromBool(isValidEmailSyntax(action.payload.value)), errorMsg: configValidationErrors[action.type] }
      }
    })
  },
  [validateCloseTime] (state, action) {
    return update(state, {
      [action.payload.fieldName]: {
        $set: {
          valid: action.payload.pass,
          errorMsg: configValidationErrors[action.type]
        }
      }
    })
  },
  [validateGroupName] (state, action) {
    return update(state, {
      groupName: { $set: { valid: action.payload } }
    })
  }
}, {
  redirectsGroup: { valid: validationStates.VALID },
  globalMissedCallEmail: { valid: validationStates.VALID },
  voicemailEmail: { valid: validationStates.VALID },
  missedCallEmail: { valid: validationStates.VALID },
  monClose: { valid: validationStates.VALID },
  tueClose: { valid: validationStates.VALID },
  wedClose: { valid: validationStates.VALID },
  thuClose: { valid: validationStates.VALID },
  friClose: { valid: validationStates.VALID },
  satClose: { valid: validationStates.VALID },
  sunClose: { valid: validationStates.VALID },
  groupName: { valid: validationStates.UNCHECKED },
  callRecordEmail: { valid: validationStates.VALID }
})

const standardDepartmentSelectionReducer = handleActions({
  [toggleToStandard] (state, action) {
    return {
      isStandardActive: true,
      isDepartmentActive: false,
      queueNo: action.payload.queueNo,
      depNo: action.payload.depNo
    }
  },
  [toggleToDepartment] (state, action) {
    return {
      isStandardActive: false,
      isDepartmentActive: true,
      queueNo: action.payload.queueNo,
      depNo: action.payload.depNo
    }
  },
  [handleDeptConfigurationChange] (state, action) {
    localStorage.setItem('deptToggled', true)
  },
  [handleNavigationButtonSelect] (state, action) {
    return { ...state, navigationSelection: action.payload }
  }
}, { isStandardActive: true, isDepartmentActive: false, depNo: '1', queueNo: undefined, navigationSelection: undefined })

const configurationReducer = handleActions({
  [receivePocketData] (state, action) {
    sessionStorage.removeItem('unsavedChanges')
    return { ...state, pocket: { ...state.pocket, linkData: action.payload, isPocketEnabled: action.payload.isEnabled, greeting: action.payload.greeting, redirectAfter: action.payload.redirectAfter, companyName: action.payload.companyName }, unsavedChanges: false }
  },
  [handleConfigSaveChanges] (state, action) {
    sessionStorage.removeItem('unsavedChanges')
    return { ...state, unsavedChanges: false }
  },
  [handlePocketEnabledChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, pocket: { ...state.pocket, isPocketEnabled: action.payload }, unsavedChanges: true }
  },
  [handlePocketGreetingChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, pocket: { ...state.pocket, greeting: action.payload }, unsavedChanges: true }
  },
  [handlePocketCompanyNameChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, pocket: { ...state.pocket, companyName: action.payload }, unsavedChanges: true }
  },
  [handlePocketSecondsDurationChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, pocket: { ...state.pocket, redirectAfter: action.payload.newValue }, unsavedChanges: true }
  },
  [requestMohTrack] (state) {
    return { ...state, isSaving: true }
  },
  [receiveMohTrack] (state, action) {
    return { ...state, musicOnHoldPrompt: { ...state.musicOnHoldPrompt, promptId: action.payload.promptId, promptDescription: action.payload.promptDescription, selectedMohTrackId: action.payload.trackId }, unsavedChanges: state.musicOnHoldPrompt !== undefined }
  },
  [handleAutoDetectGaUpdate] (state, action) {
    return { ...state, ...action.payload }
  },
  [handleMainNumberDescriptionHasFocus] (state, action) {
    if (action.payload.toLowerCase() === '* new number *') {
      return { ...state, globalDescription: '' }
    } else {
      return state
    }
  },
  [handleMainNumberDescriptionNoFocus] (state, action) {
    if (action.payload === '') {
      return { ...state, globalDescription: '* NEW NUMBER *' }
    } else {
      return state
    }
  },
  [handleNumberDescriptionHasFocus] (state, action) {
    if (action.payload.toLowerCase() === '* new number *') {
      return { ...state, description: '' }
    } else {
      return state
    }
  },
  [handleNumberDesctiptionNoFocus] (state, action) {
    if (action.payload === '') {
      return { ...state, description: '* NEW NUMBER *' }
    } else {
      return state
    }
  },
  [handleDepartmentMenuChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, menuOptionPrompt: { ...state.menuOptionPrompt, promptId: Number(action.payload.value), promptDescription: action.payload.label }, unsavedChanges: true }
  },
  [handleGlobalIntroChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, globalIntroPrompt: { ...state.globalIntroPrompt, promptId: Number(action.payload.value), promptDescription: action.payload.label }, unsavedChanges: true }
  },
  [handleChangeGlobalDescription] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, globalDescription: action.payload, unsavedChanges: true }
  },
  [handleChangeDepartmentEnabled] (state, action) {
    const depIndex = _.findIndex(state.departmentsEnabled, dep => dep.digit === action.payload.depNo)
    sessionStorage.setItem('unsavedChanges', true)
    return update(state,
      {
        departmentsEnabled: { [depIndex]: { $merge: { enabled: action.payload.checked } } },
        isDepartmentEnabled: { $set: action.payload.checked },
        unsavedChanges: { $set: true }
      })
  },
  [receiveConfigResult] (state, action) {
    return { ...state, ...action.payload }
  },
  [handleRedirectsChecked] (state, action) {
    return { ...state, redirectUpdated: false }
  },
  [changeRedirectNumber] (state, action) {
    // TODO: RETURN obj for wizard instead of merging state!
    if (action.payload.isTimeSlot === true) {
      return update(state, {
        dayTimeRouting: {
          redirects: {
            [action.payload.position]: {
              $merge: {
                redirectDetail: action.payload.itemType === RedirectType.PhoneNumber ? action.payload.newNumber : action.payload.itemId,
                redirectName: action.payload.newNumber,
                redirectType: action.payload.itemType
              }
            }
          }
        },
        redirectUpdated: { $set: true },
        enableTimeslotSave: { $set: true }
      })
    } else {
      sessionStorage.setItem('unsavedChanges', true)
      return update(state, {
        redirects: {
          [action.payload.position === 0 ? action.payload.position : action.payload.position - 1]: {
            $merge: {
              redirectDetail: action.payload.itemType === RedirectType.PhoneNumber ? action.payload.newNumber : action.payload.itemId,
              redirectName: action.payload.newNumber,
              redirectType: action.payload.itemType
            }
          }
        },
        unsavedChanges: { $set: true }
      })
    }
  },
  [changeUserDetails] (state, action) {
    return update(state, {
      sipUsers: {
        [action.payload.position]: {
          $merge: {
            email: action.payload.email,
            mobile: action.payload.mobile,
            extName: action.payload.name
          }
        }
      }
    })
  },
  [updateRedirectDuration] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return update(state, {
      redirects: {
        [action.payload.position === 0 ? action.payload.position : action.payload.position - 1]: {
          $merge: {
            dialDuration: action.payload.newValue.value
          }
        }
      },
      unsavedChanges: { $set: true }
    })
  },
  [handleAddRedirect] (state, action) {
    let newArr = []
    if (state.redirects && state.redirects.length > 0) {
      newArr = state.redirects
    }
    sessionStorage.setItem('unsavedChanges', true)
    newArr.push({ dialDuration: 30, order: state.redirects ? state.redirects.length + 1 : 1, redirectType: 1, deleted: false })

    return {
      ...state,
      redirects: newArr,
      unsavedChanges: true
    }
  },
  [handleRemoveRedirect] (state, action) {
    const newArr = state.redirects
    let i = 0
    if (action.payload.wizard) {
      i = newArr.findIndex(f => f.order === action.payload.redirect)
    } else {
      i = newArr.findIndex(f => f.redirectDetail === action.payload.redirect)
    }

    newArr[i].deleted = true
    sessionStorage.setItem('unsavedChanges', true)

    return {
      ...state,
      redirects: newArr,
      unsavedChanges: true
    }
  },
  [reOrderRedirect] (state, action) {
    const newArr = state.redirects
    const i = newArr.findIndex(f => f.order === action.payload.position)
    newArr[i].order = action.payload.isMoveUp === true ? action.payload.position - 1 : action.payload.position + 1

    if (action.payload.isMoveUp === true) { newArr[i - 1].order = newArr[i - 1].order + 1 } else { newArr[i + 1].order = newArr[i + 1].order - 1 }

    newArr.sort((a, b) => a.order - b.order)
    sessionStorage.setItem('unsavedChanges', true)

    return {
      ...state,
      redirects: newArr,
      unsavedChanges: true
    }
  },
  [updateAutoCloseBankHols] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, autoCloseBankHols: action.payload, unsavedChanges: true }
  },
  [openCloseQuickTimeSelected] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, quickOpenCloseTimeId: action.payload.newValue, ...action.payload.openCloseTimes, ...getTimeForQuickOpenCloseId(Number(action.payload.newValue)), unsavedChanges: true }
  },
  [changeOpenCloseTime] (state, action) {
    if (action.payload.time === undefined) {
      return state
    }

    const time = action.payload.time

    if (action.payload.day === openCloseTimeConstants.MON_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, monOpen: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.MON_CLOSED) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, monClose: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.TUE_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, tueOpen: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.TUE_CLOSED) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, tueClose: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.WED_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, wedOpen: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.WED_CLOSED) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, wedClose: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.THU_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, thuOpen: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.THU_CLOSED) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, thuClose: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.FRI_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, friOpen: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.FRI_CLOSED) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, friClose: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.SAT_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, satOpen: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.SAT_CLOSED) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, satClose: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.SUN_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, sunOpen: time, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.SUN_CLOSED) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, sunClose: time, unsavedChanges: true }
    }

    return state
  },
  [changeBespokeHoursHourClose] (state, action) {
    if (action.payload.day === openCloseTimeConstants.MON_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, openMon: action.payload.isOpen, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.TUE_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, openTue: action.payload.isOpen, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.WED_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, openWed: action.payload.isOpen, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.THU_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, openThu: action.payload.isOpen, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.FRI_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, openFri: action.payload.isOpen, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.SAT_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, openSat: action.payload.isOpen, unsavedChanges: true }
    }
    if (action.payload.day === openCloseTimeConstants.SUN_OPEN) {
      sessionStorage.setItem('unsavedChanges', true)
      return { ...state, openSun: action.payload.isOpen, unsavedChanges: true }
    }
  },
  [handleMohChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, musicOnHoldPrompt: { ...state.musicOnHoldPrompt, promptId: Number(action.payload.value), promptDescription: action.payload.label }, unsavedChanges: true }
  },
  [handleOohChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, outOfHoursPrompt: { ...state.outOfHoursPrompt, promptId: Number(action.payload.value), promptDescription: action.payload.label }, unsavedChanges: true }
  },
  [updateVoicePromptSelection] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, voicePrompt: { ...state.voicePrompt, voicePromptId: action.payload.newValue.value, voice: action.payload.newValue.label }, unsavedChanges: true }
  },
  [handleWhisperChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, whisperPrompt: { ...state.whisperPrompt, promptId: Number(action.payload.value), promptDescription: action.payload.label }, unsavedChanges: true }
  },
  [handleIntroChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, introPrompt: { ...state.introPrompt, promptId: Number(action.payload.value), promptDescription: action.payload.label }, unsavedChanges: true }
  },
  [handleCallScreeningEnabledChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, isWhisperEnabled: action.payload, unsavedChanges: true }
  },
  [handleCallRecordingEnabledChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, isCallRecEnabled: action.payload, unsavedChanges: true }
  },
  [handleCallRecordingEmailChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, callRecordingEmailAddress: action.payload, unsavedChanges: true }
  },
  [handleMissedCallEmailsEnabledChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, isMissedCallEmailEnabled: action.payload, unsavedChanges: true }
  },
  [handleGlobalMissedCallEmailsEnabledChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, isGlobalMissedCallEmailEnabled: action.payload, unsavedChanges: true }
  },
  [handleMissedCallEmailChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, missedCallEmailAddress: action.payload, unsavedChanges: true }
  },
  [handleGlobalMissedCallEmailChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, globalMcEmailAddress: action.payload, unsavedChanges: true }
  },
  [handleVoicemailEmailChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, voicemailEmailAddress: action.payload, unsavedChanges: true }
  },
  [handleVoicemailEnabledChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, isVoicemailEnabled: action.payload, unsavedChanges: true }
  },
  [handleVoicemailDelayChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, voicemailDelay: { ...state.voicemailDelay, voicemailDelaySeconds: action.payload.value, voicemailDelayText: action.payload.label }, unsavedChanges: true }
  },
  [handleVoicemailMessageChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return {
      ...state,
      voicemailPrompt: { ...state.voicemailPrompt, promptId: Number(action.payload.value), promptDescription: action.payload.label },
      unsavedChanges: true
    }
  },
  // [handleCallScreeningEnabledChange](state, action) {
  //     return {...state, isWhisperEnabled: action.payload, unsavedChanges: true}
  // },
  [handleDeliveryMethodChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    if (action.payload) {
      // Load balance
      const method = deliveryMethodOptions.find(f => f.value === 'P')
      return { ...state, deliveryMethod: { deliveryMethodKey: method.value, deliveryMethodText: method.label }, unsavedChanges: true }
    } else {
      // Sequential
      const method = deliveryMethodOptions.find(f => f.value === 'S')
      return { ...state, deliveryMethod: { deliveryMethodKey: method.value, deliveryMethodText: method.label }, unsavedChanges: true }
    }
  },
  [handleNumberPresentationChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return {
      ...state,
      numberPresentation: { ...state.numberPresentation, numberPresentationOptionKey: action.payload.value, numberPresentationOptionText: action.payload.label },
      unsavedChanges: true
    }
  },
  [handleChangeNumberDescription] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, description: action.payload, unsavedChanges: true }
  },
  [handleAnnouncePositionChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, isAnnouncePositionEnabled: action.payload, unsavedChanges: true }
  },
  [handleChangeGaDetectCodeUrl] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, gaUrl: action.payload, unsavedChanges: true }
  },
  [handleGaNumberTrackingCodeChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, gaCodeNumber: action.payload, unsavedChanges: true }
  },
  [handleGaGeoTrackingCodeChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, gaCodeGeo: action.payload, unsavedChanges: true }
  },
  [handleGaCategoryChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, gaCategory: action.payload, unsavedChanges: true }
  },
  [handleQuickCloseChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, quickclose: action.payload, unsavedChanges: true }
  },
  [handleAllowWitheldCallersChanged] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, barrWithheld: action.payload, unsavedChanges: true }
  },
  [handleAllowInternationalCallersChanged] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, barrInternational: action.payload, unsavedChanges: true }
  },
  [handleAutomaticSmsEnabledChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, isMissedCallSmsEnabled: action.payload, unsavedChanges: true }
  },
  [handleSmsMessageChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, smsMcMessage: action.payload, unsavedChanges: true }
  },
  [handleDynamicCliChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, dynamicCli: action.payload, unsavedChanges: true }
  },
  [handleWhatsAppEnableChange] (state, action) {
    sessionStorage.setItem('unsavedChanges', true)
    return { ...state, whatsAppEnabled: action.payload, unsavedChanges: true }
  },
  [handleInitRedirects] (state, action) {
    // Set redirects in config state for wizard
    return { ...state, redirects: action.payload }
  },
  [handleInitSipUsers] (state, action) {
    // Set sip users in config state for wizard
    return { ...state, sipUsers: action.payload }
  },
  [handleInitDaytimeRouting] (state, action) {
    return { ...state, dayTimeRouting: action.payload }
  },
  [handleAddTimeSlot] (state, action) {
    const queue = action.payload.queue
    const dept = action.payload.dept
    const obj = {
      mon: false,
      tue: false,
      wed: false,
      thur: false,
      fri: false,
      sat: false,
      sun: false,
      startTime: '0900',
      endTime: '1700',
      queueNo: queue,
      dept: Number(dept),
      redirects: []
    }

    return {
      ...state,
      dayTimeRouting: obj,
      enableTimeslotSave: false
    }
  },
  [handleUpdateDayTimeSlotDays] (state, action) {
    return update(state, {
      dayTimeRouting: { $set: action.payload.slot },
      enableTimeslotSave: { $set: true }
    })
  },
  [handleChangeTimeSlotTime] (state, action) {
    return update(state, {
      dayTimeRouting: { $set: action.payload.slot },
      enableTimeslotSave: { $set: true }
    })
  },
  [handleRemoveTimeSlot] (state, action) {
    const arr = state.timeSlots
    const i = action.payload.index
    arr.splice(i, 1)

    return update(state, {
      timeSlots: {
        $set: [...arr]
      }
    })
  },
  [handleEditTimeSlot] (state, action) {
    return update(state, {
      dayTimeRouting: {
        $set: {
          ...action.payload.slot,
          position: action.payload.index + 1
        }
      },
      enableTimeslotSave: { $set: false }
    })
  },
  [handleAddRedirectToTimeSlot] (state, action) {
    let redirects = state.dayTimeRouting.redirects
    if (!redirects || (redirects && redirects.length === 0)) { redirects = [] }

    redirects.push({
      dialDuration: 30,
      redirectDetail: '',
      redirectName: '',
      redirectType: 1,
      order: redirects.length + 1
    })

    return update(state, {
      dayTimeRouting: {
        redirects: { $set: [...redirects] }
      },
      enableTimeslotSave: { $set: true }
    })
  },
  [handleReOrderTimeslotRedirect](state, action) {
    const newArr = [...state.dayTimeRouting.redirects];
    const currentIndex = newArr.findIndex(f => f.order === action.payload.position);
    const targetOrder = action.payload.isMoveUp ? action.payload.position - 1 : action.payload.position + 1;

    const swapIndex = newArr.findIndex(f => f.order === targetOrder);
    if (currentIndex >= 0 && swapIndex >= 0) {
      newArr[currentIndex].order = targetOrder;
      newArr[swapIndex].order = action.payload.position;
    }

    newArr.sort((a, b) => a.order - b.order);

    sessionStorage.setItem('unsavedChanges', true);

    return update(state, {
      dayTimeRouting: {
        redirects: {
          $set: newArr
        }
      },
      enableTimeslotSave: { $set: true }
    });
  },

  [handleRemoveRedirectFromTimeSlot](state, action) {
    const updatedRedirects = [
      ...state.dayTimeRouting.redirects.slice(0, action.payload.index),
      ...state.dayTimeRouting.redirects.slice(action.payload.index + 1)
    ];

    const reorderRedirects = updatedRedirects.map((redirect, i) => ({
      ...redirect,
      order: i + 1, 
    }));

    return update(state, {
      dayTimeRouting: {
        redirects: {
          $set: reorderRedirects,
        }
      },
      enableTimeslotSave: { $set: true }
    });
  },
  [handleUpdateTimeSlotRedirectSeconds] (state, action) {
    const redirects = state.dayTimeRouting.redirects
    redirects[action.payload.index].dialDuration = action.payload.value

    return update(state, {
      dayTimeRouting: {
        redirects: {
          $set: [...redirects]
        }
      },
      enableTimeslotSave: { $set: true }
    })
  },
  [handleUpdateConfigTimeslots] (state, action) {
    const slot = action.payload.timeslot
    let array = state.timeSlots

    if (action.payload.newId !== undefined && action.payload.newId > 0) { slot.id = action.payload.newId }

    if (array === undefined || (array && array.length === 0)) { array = [] }

    if (slot.id !== undefined && array && array.length > 0) {
      // Update slot
      const index = array.findIndex(f => f.id === slot.id)
      if (index >= 0) {
        array[index] = slot
      } else {
        array.push(slot)
      }
    } else {
      // Adding a new
      array.push(slot)
    }

    return update(state, {
      timeSlots: {
        $set: [...array]
      },
      enableTimeslotSave: { $set: false }
    })
  },
  [handleToggleEmptyRedirectsWarning] (state, action) {
    return { ...state, showEmptyRedirectsWarning: action.payload }
  }
}, {
  deliveryMethod: { deliveryMethodKey: 'S' },
  numberPresentation: { numberPresentationOptionKey: 'CLI' },
  voicePrompt: { voicePromptId: 2 },
  unsavedChanges: false,
  showUmrPopup: false,
  umrPopupShown: false,
  pocket: { linkData: { isLinked: false }, isPocketEnabled: false },
  dayTimeRouting: undefined,
  enableTimeslotSave: false,
  showEmptyRedirectsWarning: false
})

export const reducer = combineReducers({
  configuration: configurationReducer,
  prompts: promptsReducer,
  dialogVisibility: dialogVisibilityReducer,
  loading: loadingStateReducer,
  saving: savingStateReducer,
  selectedConfiguration: standardDepartmentSelectionReducer,
  validation: validationReducer,
  redirects: redirectsReducer
})

// SELECTORS
const groupNameValidationState = state => state.configuration.validation.groupName.valid

export const departmentsEnabledState = state => state.configuration.configuration.departmentsEnabled

export const getGroupNameValidationState = createSelector(
  [groupNameValidationState],
  (validationState) => {
    if (validationState === validationStates.INVALID) {
      return { valid: validationStates.INVALID, errorMsg: 'Please enter a valid group name' }
    }

    if (validationState === validationStates.EMPTY) {
      return { valid: validationStates.EMPTY, errorMsg: 'please enter a group name' }
    }

    return validationState
  }
)
