import moment from 'moment'
import React from 'react'
import { Button, Modal, Popover } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'
import { Link } from 'react-router-dom'
import NavigationPrompt from 'react-router-navigation-prompt'
import {
    animateScroll as scroll,
    Element,
    Events,
    scroller
} from 'react-scroll'
import AdvancedSettingsSection from '../components/configuration/AdvancedSettingsSection'
import AnnouncePositionSection from '../components/configuration/AnnouncePositionSection'
import CallFilteringSection from '../components/configuration/CallFilteringSection'
import CallRecording from '../components/configuration/CallRecording'
import CallScreeningSection from '../components/configuration/CallScreeningSection'
import ConfigNavigationMenu from '../components/configuration/ConfigNavigationMenu'
import CustomRecordingsSection from '../components/configuration/CustomRecordingsSection'
import DayTimeRedirects from '../components/configuration/DayTimeRedirects'
import DeliveryMethodSection from '../components/configuration/DeliveryMethodSection'
import DepartmentChoicePromptSection from '../components/configuration/DepartmentChoicePromptSection'
import GlobalDepartmentSettings from '../components/configuration/GlobalDepartmentSettings'
import GlobalMissedCallEmailAlertsSection from '../components/configuration/GlobalMissedCallEmailAlertsSection'
import RedirectSectionHelperDialog from '../components/configuration/InstructionHelperDialogs/RedirectSectionHelperDialog'
import IntroductionSection from '../components/configuration/IntroductionSection'
import MainIntroduction from '../components/configuration/MainIntroduction'
import MainNumberDescription from '../components/configuration/MainNumberDescription'
import MissedCallEmailAlertsSection from '../components/configuration/MissedCallEmailAlertsSection'
import MusicOnHoldSection from '../components/configuration/MusicOnHoldSection'
import NumberDescriptionSection from '../components/configuration/NumberDescriptionSection'
import NumberPresentationSection from '../components/configuration/NumberPresentationSection'
import OpenCloseTimes from '../components/configuration/OpenCloseTimes'
import OutOfHoursMessageSection from '../components/configuration/OutOfHoursMessageSection'
import PocketReceptionistSection from '../components/configuration/PocketReceptionistSection'
import RedirectNumber from '../components/configuration/RedirectNumber'
import RedirectSecondsSelect from '../components/configuration/RedirectSecondsSelect'
import SmsCallbackSection from '../components/configuration/SmsCallbackSection'
import SystemVoiceSamplesDialog from '../components/configuration/SystemVoiceSamplesDialog'
import SystemVoiceSelection from '../components/configuration/SystemVoiceSelection'
import UmrUpgradeDialog from '../components/configuration/UmrUpgradeDialog'
import VoicemailSection from '../components/configuration/VoicemailSection'
import DisplayNoFooter from '../components/DisplayNoFooter'
// import DisplaySimpleHeader from '../components/DisplaySimpleHeader'
import DisplaySimpleHeaderV2 from '../components/DisplaySimpleHeaderV2'
import RefreshUserServices from '../components/RefreshUserServices'
import SaveButton from '../components/SaveButton'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import Validator from '../components/Validator'
import VoipUsers from '../components/VoipUsers/VoipUsers'
import {
    fieldNames,
    scrollSectionNames
} from '../constants/ConfigurationConstants'
import { productBillingOption, ProductIds, RedirectLimit } from '../constants/Constants'
import RedirectNumberSection from '../constants/RedirectNumberSection'
import { fetchHelper } from '../helpers/fetchHelper'
import { isObject, simplePhoneNumberFormat } from '../helpers/utils'
import {
    canSubmitConfigChange,
    getCallBlockingEnabled,
    getConfiguration,
    getConfigurationLoading,
    getConfigValidators,
    getCurrentRequestedRoute,
    getRedirectCount,
    getSaving
} from '../selectors'
import * as configurationActions from '../store/Configuration'
import * as fromHome from '../store/Home'
import { handleNotificationChange } from '../store/SBF'
import DynamicCliPrefixes from '../components/configuration/DynamicCliPrefixes'

const redirectsPopover = (
    <Popover id="popover-trigger-hover-focus">
        <p className="text-400">This is the amount of time we will ring this redirect number before pausing and attempting the next one.
        If you have only one redirect, then after the pause it will retry the first redirect over again &amp; again.
    </p>
    </Popover>
)

class ConfigurationPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      toggleActive: false,
      showingRequestAutoDetectGaModal: false,
      isUnableToAutoDetectGaCode: false,
      unsavedChanges: this.props.config.unsavedChanges,
      voiceOptions: [],
      isLoadingVoiceOptions: false,
      recordingsPin: '',
      redirectExceptions: [],
      defaultRdtab: 1
    }

    this.onToggle = this.onToggle.bind(this)
  }

  onToggle () {
    this.setState({ toggleActive: !this.state.toggleActive })
  }

  handleEditUsers () {
    this.setState({ showUserSettings: true })
  }

  render () {
    const deptToggled = localStorage.getItem('deptToggled')
    if (this.state.unsavedChanges !== true && deptToggled) {
      this.setState({ unsavedChanges: true })
    }

    if (this.state.unsavedChanges !== true && sessionStorage.getItem('unsavedChanges')) { this.setState({ unsavedChanges: true }) }

    const { config } = this.props
    const { validation } = this.props

    const isDepartmentLayout = (this.props.match.params.depNo !== undefined)
    const isStandardLayout = !isDepartmentLayout

    const redirectNumberSection = <RedirectNumberSection>
                    <div className="row">
                        <Validator className="col-md-12" validation={validation[fieldNames.REDIRECTS_GROUP]} />
                           
                                <div className="">
                                    {(config.redirects && config.redirects.length > 0)
                                      ? config.redirects.filter(f => !f.deleted).map((red, i) =>
                                        <div className="config-redirects-row row bg-dull brad-10 p-10 pr-20 mb-10">
                                            <div className="col-xs-12 col-sm-6 pr-10 redirect-number-wrapper" style={{ position: 'relative' }}>
                                                <RedirectNumber handleEditUsers={() => this.handleEditUsers()} number={red} redirectType={red.redirectType} position={red.order} hasUmr={this.props.hasUmr} notifier={this.props.notify}
                                                    onValidate={redirect => this.props.validateRedirect({
                                                      redirect,
                                                      isMultiDept: isDepartmentLayout,
                                                      position: red.order,
                                                      redirectCount: config.redirects.length,
                                                      redirectType: red.redirectType
                                                    })}
                                                    onValidateUserOrGroup={item => this.props.validateRedirect({
                                                      isMultiDept: isDepartmentLayout,
                                                      position: red.order,
                                                      redirectCount: config.redirects.length,
                                                      redirectType: red.redirectType,
                                                      itemId: red.redirectDetail
                                                    })}
                                                    onRemoveRedirect={() => this.props.removeRedirect({
                                                      redirect: red,
                                                      queue: this.props.selectedConfiguration.queueNo,
                                                      notifier: this.props.notify
                                                    })} />
                                            </div>

                                            <div className="float-start text-center pt-25 pl-xs-20 pr-xs-20 redirect-for" style={{ width: '45px' }}>
                                                <p>for</p>
                                            </div>

                                            <div className="col-xs-8 col-sm-4 pt-15 pl-10 redirect-time">
                                                <RedirectSecondsSelect seconds={isObject(red.dialDuration) ? red.dialDuration : { value: red.dialDuration, label: red.dialDuration + ' Seconds' }} position={red.order} />
                                            </div>
                                            <div className="col-xs-12">
                                                <Validator validation={validation.redirects ? validation.redirects[i] : null} />
                                            </div>
                                        </div>
                                      )
                                      : <div className="text-danger mb-20 text-400 col-xs-12">
                                            <p>You currently have no redirects setup. Please add a new one below.</p>
                                        </div>
                                    }
                                </div>
                                    {config.redirects && ((config.redirects.filter(f => f.deleted !== true).length < RedirectLimit) || this.props.match.params.queueNo === '08000096761') &&
                                        <div className="pb-xs-30">
                                            <p className={`text-primary text-500 text-sm cursor-pointer m-0 d-inline-block ${config.redirects.length > 0 ? 'mt-30 mt-xxs-0' : ''}`} onClick={() => this.props.handleAddRedirect()} id="AddAnotherRedirectLink">
                                                <span className="icon icon-plus-circle2 mr-10"></span><span className="underline">Add another redirect</span>
                                            </p>
                                        </div>
                                    }

                        

                            <div className='pl-15 pr-15'>
                                <DeliveryMethodSection />
                            </div>
                        </div>

                    <DayTimeRedirects timeslots={config.timeSlots} queueNo={this.props.match.params.queueNo} dept={this.props.match.params.depNo} validation={validation} />

            <Modal className="w-900px" show={this.state.showUserSettings} onHide={() => this.setState({ showUserSettings: false })}>
                <Modal.Body>
                    <VoipUsers />
                </Modal.Body>
                <Modal.Footer className="text-center bt-0 p-0 mt-30">
                    <Button className="btn-block brad-0 pt-20 pb-20 w-100" onClick={() => this.setState({ showUserSettings: false })}>Close</Button>
                </Modal.Footer>
            </Modal>

        </RedirectNumberSection>

    if (config.timeSlots && config.timeSlots.length > 0 && this.state.defaultRdtab !== 2) { this.setState({ defaultRdtab: 2 }) }

    if (/Android|iPhone/i.test(navigator.userAgent) && this.state.showMobileApp !== true && !localStorage.getItem('hideAppWarning')) {
      this.setState({ showMobileApp: true })
    }

    return <><ScrollToTopOnMount /><DisplaySimpleHeaderV2 /><RefreshUserServices /><DisplayNoFooter />
    
            {/* only render navigation prompt if not moving to config page or auto scrolling */}
            {(this.props.match.path !== '/customer/numbers/:queueNo(\\d*)/:depNo(\\d)?/:scrollSection?') &&
                <NavigationPrompt
                    afterConfirm={() => localStorage.removeItem('deptToggled')}
                    renderIfNotActive={true}
                    when={this.state.unsavedChanges}>
                    {({ onConfirm, onCancel }) => (
                        <Prompt
                            when={this.state.unsavedChanges}
                            message='You have unsaved changes, are you sure you want to continue?'
                        />)}
                </NavigationPrompt>
            }
            <Helmet>
                <body className={'config-page '.concat(localStorage.getItem('dark_theme') ? 'dark-theme' : '') } />
                <title>Number settings</title>
            </Helmet>
            <UmrUpgradeDialog />

            <Modal show={this.props.config.showEmptyRedirectsWarning} onHide={() => this.props.handleToggleEmptyRedirectsWarning(false)}>
                <Modal.Body>
                    <p className="lead text-500"> You've not set any redirects! </p>
                    <p>
                        You haven't entered any users or phone numbers in your "Redirect Numbers" setting, so calls to your {simplePhoneNumberFormat(this.props.match.params.queueNo)} number will therefore be treated as if your line is closed.
                    </p>
                    <p>
                        Click "Continue & Save" if this is intended otherwise click "Set Redirects" to choose where you would like you calls delivered to.
                    </p>
                </Modal.Body>
                <Modal.Footer className="text-center p-30">
                    <Button id="ContinueAndSaveButton" variant="cta" onClick={e => this.props.handleConfigSave({ queueNo: this.props.selectedConfiguration.queueNo, depNo: this.props.selectedConfiguration.depNo, config: this.props.config, notifier: this.props.notify, canSubmit: this.props.canSubmit, hasUmr: this.props.hasUmr, ignoreRedirects: true })}>
                        Continue &amp; Save
                    </Button>
                    <Button variant="outline" onClick={() => this.props.handleToggleEmptyRedirectsWarning(false)}>
                        Set Redirects
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="w-900px" show={this.state.showMobileApp} onHide={() => this.setState({ showMobileApp: false })}>
                <Modal.Body>
                    <p className="lead text-400"> We notice that you&apos;re using this page on your mobile phone. </p>
                    <p>
                       Did you know we have a free iOS &amp; Android app available to manage your numbers on-the-go?
                    </p>
                    <p>
                        We strongly recommend downloading our app when using a mobile phone to manage your number.
                    </p>
                    <p>
                        <Link to="/get-app" className="text-underline">Download it today</Link> to instantly improve your experience.
                    </p>
                </Modal.Body>
                <Modal.Footer className="text-center p-30">
                    <p>
                        <Link to="/get-app" className="btn btn-cta">
                            Download App <span className="icon icon-arrow-right5 text-sm ml-5"></span>
                        </Link>
                    </p>
                    <Button variant="link" className="text-darker text-underline mt-20 text-sm " onClick={() => { this.setState({ showMobileApp: false }); localStorage.setItem('hideAppWarning', true) }}>
                        No thanks, I don&apos;t want the app.
                    </Button>
                </Modal.Footer>
            </Modal>

            <form>
                <fieldset className="pb-100" disabled={this.props.isLoading}>
                    <div className="configuration-page" id="configurationPage">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="floating-save text-center">
                                    <a href="/customer/numbers" className="mr-20 btn btn-lg btn-info text-lightest"><span className="d-none d-sm-block" id="BackToNumbersButton">Back to Numbers</span><span className="d-block d-sm-none">Go Back</span></a>
                                    <SaveButton disabled={this.props.isSaving || this.props.isLoading || !this.state.unsavedChanges} isSaving={this.props.isSaving} onClick={e => this.props.handleConfigSave({ queueNo: this.props.selectedConfiguration.queueNo, depNo: this.props.selectedConfiguration.depNo, config: this.props.config, notifier: this.props.notify, canSubmit: this.props.canSubmit, hasUmr: this.props.hasUmr })} />
                                </div>

                                <ConfigNavigationMenu disabled={this.props.isSaving || this.props.isLoading || !this.state.unsavedChanges} isDepartmentConfig={this.props.selectedConfiguration.isDepartmentActive} queueNo={this.props.match.params.queueNo} isSaving={this.props.isSaving} handleSave={e => this.props.handleConfigSave({ queueNo: this.props.selectedConfiguration.queueNo, depNo: this.props.selectedConfiguration.depNo, config: this.props.config, notifier: this.props.notify, canSubmit: this.props.canSubmit, hasUmr: this.props.hasUmr })} />
                                {isDepartmentLayout &&
                                    <>
                                        <div className="config-area type-departments">
                                            <h1 className="text-xxl text-500 show-1300 hidden text-center mt-5 pt-20">Settings for <br className="d-block d-xs-none" />{simplePhoneNumberFormat(this.props.match.params.queueNo)}</h1>
                                            <GlobalDepartmentSettings className="clearfix">
                                                <div className="row">
                                                    <div className="text-center col-md-12 mb-30">
                                                        <h1 className="text-xxl mt-30">
                                                            Global Department Settings
                                                        </h1>
                                                        <p>The following settings affect what your caller hears before they have selected a department.</p>
                                                    </div>
                                                </div>

                                                <>
                                                    <div className="anchor" name={scrollSectionNames.MAIN_NUMBER_DESCRIPTION}></div>
                                                    <MainNumberDescription />
                                                </>
                                                <>
                                                    <div className="row bg-dullest p-30 zoom">
                                                        <div className="anchor" name={scrollSectionNames.SYSTEM_VOICE}></div>
                                                        <div className="col-md-5">
                                                            <h5 className="text-secondary text-500 text-lg">
                                                                System Voice
                                                    </h5>
                                                            <p className="text-400">
                                                                Select the voice you would like to use for your number.
                                                        <br className="d-none d-md-block" />
                                                                All prompts your callers hear will be played in your chosen voice.
                                                    </p>
                                                        </div>
                                                        <div className="col-md-7 text-center">
                                                            <SystemVoiceSamplesDialog />
                                                            <SystemVoiceSelection options={this.state.voiceOptions} />
                                                        </div>
                                                    </div>
                                                </>
                                                <>
                                                    <div className="anchor" name={scrollSectionNames.MAIN_INTRODUCTION}></div>
                                                    <MainIntroduction />
                                                </>
                                                <>
                                                    <div className="anchor" name={scrollSectionNames.GLOBAL_MISSED_CALL_EMAIL}></div>
                                                    <GlobalMissedCallEmailAlertsSection />
                                                </>
                                                {this.props.hasCallBlocking && <>
                                                    <div className="anchor" name={scrollSectionNames.CALL_FILTERING}></div>
                                                    <CallFilteringSection />
                                                </>}

                                                <>
                                                  <div className="anchor" name={scrollSectionNames.DYNAMIC_CLI}></div>
                                                  <DynamicCliPrefixes />
                                                </>
                                                <div className="anchor" name={scrollSectionNames.INDIVIDUAL_DEPARTMENT}></div>
                                                <div className="text-center className-md-12 mt-60 mb-30">
                                                    <h1 className="text-xxl">
                                                        Individual Department Settings
                                                    </h1>
                                                    <p>
                                                        The below settings are specific to individual departments. You can configure up to 9
                                        separate departments. <br className="d-none d-sm-block" />Each department has i&apos;s own independent settings including up
                                                                                                                                        to 5 redirect numbers.
                                    </p>
                                                </div>
                                            </GlobalDepartmentSettings>
                                            <>
                                                <div className="anchor" name={scrollSectionNames.NUMBER_DESCRIPTION}></div>
                                                <NumberDescriptionSection />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.INTRODUCTION}></div>
                                                <IntroductionSection />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.OPEN_CLOSE_TIMES}></div>
                                                <OpenCloseTimes />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.DEPARTMENT_MENU}></div>
                                                <DepartmentChoicePromptSection />
                                            </>

                                            <>
                                                <div className="row bg-light p-30 zoom">
                                                    <div className="redirects row">
                                                        <div className="col-md-5">
                                                            <div className="anchor" name={scrollSectionNames.REDIRECTS}></div>
                                                            <h5 className="text-secondary text-500 text-lg" id="RedirectNumbersTextMD">
                                                                Redirect Numbers
                                                            </h5>
                                                            <p className="text-400">
                                                                Redirect your virtual numbers to either an existing landline; mobile phone or SwitchboardFREE softphone. <a className="underline" onClick={this.props.handleShowRedirectSectionHelperDialog}>Video: Redirects &amp; ring groups explained</a>
                                                        </p>
                                                        <RedirectSectionHelperDialog />
                                                        </div>
                                                        <div className="col-md-7 redirects-section pl-sm-20 pr-sm-0">

                                                            {redirectNumberSection}

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            {(true || config.hasPrNumber === true) && <>
                                                <div className="anchor" name={scrollSectionNames.POCKET}></div>
                                                    <div className='pocket-section'>
                                                        <PocketReceptionistSection />
                                                    </div>
                                            </>}

                                            <>
                                                <div className="anchor" name={scrollSectionNames.CALL_RECORDING}></div>
                                                <CallRecording userServices={this.props.services} queueNo={this.props.match.params.queueNo} />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.CALL_SCREENING}></div>
                                                <CallScreeningSection />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.MUSIC_ON_HOLD}></div>
                                                <MusicOnHoldSection />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.MISSED_CALL_EMAIL_ALERTS}></div>
                                                <MissedCallEmailAlertsSection />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.VOICEMAIL}></div>
                                                <VoicemailSection />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.OUT_OF_HOURS}></div>
                                                <OutOfHoursMessageSection />
                                            </>

                                            {/* <>
                                                <div className="anchor" name={scrollSectionNames.DELIVERY_METHOD}></div>
                                                <DeliveryMethodSection />
                                            </> */}

                                            <>
                                                <div className="anchor" name={scrollSectionNames.NUMBER_PRESENTATION}></div>
                                                <NumberPresentationSection />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.CUSTOM_RECORDINGS}></div>
                                                <CustomRecordingsSection />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.ANNOUNCE_POSITION}></div>
                                                <AnnouncePositionSection />
                                            </>

                                            <>
                                                <div className="anchor" name={scrollSectionNames.CALLBACK_SMS}></div>
                                                <SmsCallbackSection />
                                            </>
                                        </div>
                                    </>
                                }

                                {isStandardLayout &&
                                    <>
                                        <div className="config-area type-single">

                                            <h1 className="text-xxl text-500 show-1300 text-center hidden mt-5 pt-20">Settings for <br className="d-block d-xs-none" />{simplePhoneNumberFormat(this.props.match.params.queueNo)}</h1>

                                            <div className="anchor" name={scrollSectionNames.NUMBER_DESCRIPTION}></div>
                                            <>
                                                <NumberDescriptionSection />
                                            </>

                                            <div className="anchor" name={scrollSectionNames.OPEN_CLOSE_TIMES}></div>
                                            <>
                                                <OpenCloseTimes />
                                            </>

                                            <div className="anchor" name={scrollSectionNames.SYSTEM_VOICE}></div>
                                            <div className="row bg-dullest p-30 zoom">
                                                <div className="col-md-5 pb-sm-10">
                                                    <h5 className="text-secondary text-500 text-lg">
                                                        System Voice
                                                    </h5>
                                                    <p className="text-400">
                                                        Select the voice you would like to use for your number. All prompts your callers hear will be
                                                        <br className="d-none d-lg-block" />played in your chosen voice.
                                                    </p>
                                                </div>
                                                <div className="col-md-7 text-center">
                                                    <SystemVoiceSamplesDialog />
                                                    <Element><SystemVoiceSelection options={this.state.voiceOptions} /></Element>
                                                </div>
                                            </div>
                                            <div className="anchor" name={scrollSectionNames.INTRODUCTION}></div>
                                            <>
                                                <IntroductionSection />
                                            </>
                                            <>
                                                <div className="anchor" name={scrollSectionNames.REDIRECTS}></div>
                                                <div className="redirects">
                                                    <div className="row bg-light p-30 zoom">
                                                        <div className="col-md-5 pb-sm-10">
                                                            <h5 className="text-secondary text-500 text-lg" id="RedirectNumbersTextSD">
                                                                Redirect Numbers
                                                            </h5>
                                                            <p className="text-400">
                                                                Redirect your virtual numbers to either an existing landline; mobile phone or SwitchboardFREE softphone. <a className="underline" onClick={this.props.handleShowRedirectSectionHelperDialog}>Video: Redirects &amp; ring groups explained</a>
                                                            </p>
                                                            <RedirectSectionHelperDialog />

                                                        </div>
                                                        <div className="col-md-7 redirects-section pl-sm-20 pr-sm-20">

                                                                    <DeliveryMethodSection />
                                                                    <RedirectNumberSection>
                                                                        <div className="row p-xxs-10 pl-xxs-0">
                                                                            <Validator className="col-md-12" validation={validation[fieldNames.REDIRECTS_GROUP]} />
                                                                            <div className="bb-2 p-20">
                                                                                <div className="">
                                                                                    {(config.redirects && config.redirects.length > 0) ? config.redirects.filter(f => !f.deleted).map((red, i) =>
                                                                                        <div className="config-redirects-row row bg-dull brad-10 p-10 pr-20 mb-10">
                                                                                            <div className="col-xs-12 col-sm-6 pr-10 redirect-number-wrapper" style={{ position: 'relative' }}>
                                                                                                <RedirectNumber handleEditUsers={() => this.handleEditUsers()} number={red} redirectType={red.redirectType} position={red.order} hasUmr={this.props.hasUmr} notifier={this.props.notify}
                                                                                                    onValidate={redirect => this.props.validateRedirect({
                                                                                                      redirect,
                                                                                                      isMultiDept: isDepartmentLayout,
                                                                                                      position: red.order,
                                                                                                      redirectCount: config.redirects.length,
                                                                                                      redirectType: red.redirectType,
                                                                                                      redirectExceptions: this.state.redirectExceptions
                                                                                                    })}
                                                                                                    onValidateUserOrGroup={item => this.props.validateRedirect({
                                                                                                      isMultiDept: isDepartmentLayout,
                                                                                                      position: red.order,
                                                                                                      redirectCount: config.redirects.length,
                                                                                                      redirectType: red.redirectType,
                                                                                                      itemId: red.redirectDetail
                                                                                                    })}
                                                                                                    onRemoveRedirect={() => this.props.removeRedirect({
                                                                                                      redirect: red,
                                                                                                      queue: config.queueNumber,
                                                                                                      notifier: this.props.notify
                                                                                                    })} />
                                                                                            </div>

                                                                                            <div className="float-start text-center pt-25 pl-xs-20 pr-xs-20 redirect-for" style={{ width: '45px' }}>
                                                                                                <p>for</p>
                                                                                            </div>

                                                                                            <div className="col-xs-8 col-sm-4 pt-15 pl-10 redirect-time">
                                                                                                <RedirectSecondsSelect seconds={isObject(red.dialDuration) ? red.dialDuration : { value: red.dialDuration, label: red.dialDuration + ' Seconds' }} position={red.order} />
                                                                                            </div>

                                                                                            <div className="col-xs-12">
                                                                                                <Validator validation={validation.redirects ? validation.redirects[i] : null} />
                                                                                            </div>

                                                                                        </div>
                                                                                    )
                                                                                      : <div className="text-danger mb-20 text-400 ml-15">
                                                                                            <p>You currently have no redirects setup. Please add a new one below.</p>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                {config.redirects && ((config.redirects.filter(f => f.deleted !== true).length < RedirectLimit) || this.props.match.params.queueNo === '08000096761') &&
                                                                                    <div className="pb-xs-30">
                                                                                        <p className={`text-primary text-500 text-sm cursor-pointer m-0 d-inline-block ${config.redirects.length > 0 ? 'mt-30 mt-xxs-0' : ''}`} onClick={() => this.props.handleAddRedirect()}>
                                                                                            <span className="icon icon-plus-circle2 mr-10"></span><span className="underline" id="AddAnotherRedirectLink">Add another redirect</span>
                                                                                        </p>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </RedirectNumberSection>

                                                                    <DayTimeRedirects timeslots={config.timeSlots} queueNo={this.props.match.params.queueNo} dept={this.props.match.params.depNo} validation={validation} />

                                                            <Modal className="w-900px" show={this.state.showUserSettings} onHide={() => this.setState({ showUserSettings: false })}>
                                                                <Modal.Body>
                                                                    <VoipUsers />
                                                                </Modal.Body>
                                                                <Modal.Footer className="text-center bt-0 p-0 mt-30">
                                                                    <Button className="btn-block brad-0 pt-20 pb-20 w-100" onClick={() => this.setState({ showUserSettings: false })}>Close</Button>
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </div>

                                                    </div>
                                                </div>
                                            </>

                                            
                                            {(true || config.hasPrNumber === true) && <>
                                                <div className="anchor" name={scrollSectionNames.CALL_ANSWERING}></div>
                                                <div className='pocket-section'>
                                                    <PocketReceptionistSection />
                                                </div>

                                            </>}

                                            <div className="anchor" name={scrollSectionNames.CALL_RECORDING}></div>
                                            <>
                                                <CallRecording userServices={this.props.services} queueNo={this.props.match.params.queueNo} />
                                            </>

                                            <div className="anchor" name={scrollSectionNames.CALL_SCREENING}></div>
                                            <>
                                                <CallScreeningSection />
                                            </>

                                            <div className="anchor" name={scrollSectionNames.MUSIC_ON_HOLD}></div>
                                            <>
                                                <MusicOnHoldSection />
                                            </>

                                            <div className="anchor" name={scrollSectionNames.MISSED_CALL_EMAIL_ALERTS}></div>
                                            <>
                                                <MissedCallEmailAlertsSection />
                                            </>

                                            <div className="anchor" name={scrollSectionNames.VOICEMAIL}></div>
                                            <>
                                                <VoicemailSection />
                                            </>

                                            <div className="anchor" name={scrollSectionNames.OUT_OF_HOURS}></div>
                                            <>
                                                <OutOfHoursMessageSection />
                                            </>

                                            {/* <div className="anchor" name={scrollSectionNames.DELIVERY_METHOD}></div>
                                            <>
                                                <DeliveryMethodSection />
                                            </> */}

                                            <div className="anchor" name={scrollSectionNames.NUMBER_PRESENTATION}></div>
                                            <>
                                                <NumberPresentationSection />
                                            </>

                                            <div className="anchor" name={scrollSectionNames.CUSTOM_RECORDINGS}></div>
                                            <>
                                                <CustomRecordingsSection />
                                            </>

                                            <AdvancedSettingsSection>

                                                <div className="anchor" name={scrollSectionNames.ANNOUNCE_POSITION}></div>
                                                <>
                                                    <AnnouncePositionSection />
                                                </>

                                                {/* <div className="anchor" name={scrollSectionNames.QUICK_CLOSE}></div>
                                <Element><QuickCloseSection/></Element> */}

                                                <div className="anchor" name={scrollSectionNames.CALLBACK_SMS}></div>
                                                <>
                                                    <SmsCallbackSection />
                                                </>

                                                <div className="anchor" name={scrollSectionNames.DYNAMIC_CLI}></div>
                                                <>
                                                    <DynamicCliPrefixes />
                                                </>

                                                <div className="anchor" name={scrollSectionNames.CALL_FILTERING} />
                                                {this.props.hasCallBlocking &&
                                                    <>
                                                        <CallFilteringSection />
                                                    </>}

                                                {/* <div className="anchor" name={scrollSectionNames.CLOUD_STORAGE}></div> */}
                                                {/* <Element><CloudStorageIntegration/></Element> */}

                                            </AdvancedSettingsSection>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form></>
  }

  handleAutoDetectCodeShowDialog (config) {
    this.setState({ showingRequestAutoDetectGaModal: true })
  }

  fetchRingGroups () {
    this.props.fetchRingGroups({ history: this.props.history, groups: this.props.ringGroups })
  }

  componentWillMount () {
    this.props.requestConfiguration({ ...this.props.match.params, history: this.props.history }).then(() => this.props.fetchPocketData({ ...this.props.match.params, history: this.props.history }))
    this.props.fetchWhisperPrompts({ history: this.props.history })
    this.props.fetchIntroPrompts({ history: this.props.history })
    this.props.fetchMohPrompts({ history: this.props.history })
    this.props.fetchMohTrack(this.props.match.params)
    this.props.fetchVoicemailPrompts({ history: this.props.history })
    this.props.fetchOohPrompts({ history: this.props.history })
    this.props.fetchGlobalIntroPrompts({ history: this.props.history })
    this.props.fetchDepartmentMenuPrompts({ history: this.props.history })
    this.props.fetchRedirectsHistory({ history: this.props.history })
    this.props.fetchOutboundUsers({ history: this.props.history })
    this.props.handleNavigationButtonSelect(this.props.match.params.scrollSection)
    this.fetchRingGroups()

    const context = this
    const queueNo = context.props.match.params.queueNo
    const url = `/api/Configuration/GetVoiceOptions/${queueNo}`

    context.setState({ isLoadingVoiceOptions: true })

    fetchHelper.getJson(url).then(res => {
      context.setState({ voiceOptions: res.data, isLoadingVoiceOptions: false })
    }).catch(function (error) {
      console.error(error)
    })
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ unsavedChanges: nextProps.config.unsavedChanges })

    const oldRoute = this.props.route
    const newRoute = nextProps.route

    if (oldRoute && oldRoute !== newRoute) {
      this.props.history.push(newRoute)
    }

    const oldScrollSection = this.props.match.params.scrollSection
    const newScrollSection = nextProps.match.params.scrollSection

    if (newScrollSection && oldScrollSection !== newScrollSection) {
      scroller.scrollTo(newScrollSection, {
        duration: 1500,
        delay: 100,
        smooth: true
      })
    }

    if (!newScrollSection && oldRoute !== newRoute) {
      if (nextProps.selectedConfiguration.isDepartmentActive) {
        // Department scrolls to here instead of the top
        scroller.scrollTo('individualDepartment', {
          duration: 1500,
          delay: 100,
          smooth: true
        })
      } else {
        scroll.scrollToTop({
          duration: 1500,
          delay: 100,
          smooth: true
        })
      }
    }

    const nextDepNo = nextProps.match.params.depNo
    const nextQueueNo = nextProps.match.params.queueNo

    const oldDepNo = this.props.match.params.depNo
    const oldQueueNo = this.props.match.params.queueNo

    if (nextDepNo !== oldDepNo || nextQueueNo !== oldQueueNo) {
      this.props.requestConfiguration(nextProps.match.params)
      this.props.fetchPocketData(nextProps.match.params)
    }
  }

  componentDidUpdate () {
    if (this.state.unsavedChanges) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentDidMount () {
    const umrPopupShown = localStorage.getItem('umrPopupShown')
    if (umrPopupShown !== null) {
      const now = moment()
      const diff = now.diff(umrPopupShown, 'days')
      if (diff >= 7) { localStorage.removeItem('umrPopupShown') }
    }

    Events.scrollEvent.register('begin', function () {
    })

    Events.scrollEvent.register('end', function () {
    })

    if (this.props.match.params.scrollSection) {
      let scrollSection = this.props.match.params.scrollSection

      if (scrollSection === 'callAnsweringChange') {
        scrollSection = 'callAnswering'

        scroller.scrollTo(scrollSection, {
          duration: 1500,
          delay: 100,
          smooth: true
        })

        setTimeout(() => { document.getElementById('pocketOnOffSwitch').click() }, 5 * 1000)
      } else {
        scroller.scrollTo(this.props.match.params.scrollSection, {
          duration: 1500,
          delay: 100,
          smooth: true
        })
      }
    }

    if (!sidebarToggle === null) {
      var sidebarToggle = document.getElementById('sidebarToggle')
      const aside = document.getElementById('aside')

      sidebarToggle.addEventListener('click', function () {
        aside.style.left = (aside.dataset.toggled ^= 1) ? '700' : '-380px'
        sidebarToggle.style.left = (sidebarToggle.dataset.toggled ^= 1) ? '380px' : '0'
      })
    }

    this.props.getProductDetails([
      { productId: ProductIds.umr, monthlyOption: productBillingOption.umrBillingOptionMonthly }
    ])

    fetchHelper.getJson('api/Configuration/GetRedirectExceptions').then((res) => {
      this.setState({ redirectExceptions: res.data })
    })
  }

  componentWillUnmount () {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
  }

  handleChangeAutoDetectGaUrl (gaAutoDetectUrl) {
    this.setState({ gaAutoDetectUrl })
  }

  handleDetectCode () {
    const url = 'api/Configuration/LookupGoogleTrackingCode'

    fetchHelper.postJson(url, { url: this.state.gaAutoDetectUrl }).then(response => {
      if (response.data) {
        this.props.handleAutoDetectGaUpdate(response.data)
        this.setState({ showingRequestAutoDetectGaModal: false, isUnableToAutoDetectGaCode: false })
      } else {
        this.setState({ isUnableToAutoDetectGaCode: true })
      }
    }).catch(error => {
      this.setState({ isUnableToAutoDetectGaCode: true })
    })
  }
}

export default connect(
  state => {
    return ({
      config: getConfiguration(state), // the actual configuration data pulled from the API in response to selectedConfiguration below
      prompts: state.configuration.prompts, // the data for each of the prompt types, e.g, music on hold, intro's etc
      dialogVisibility: state.configuration.dialogVisibility, // stores the displayed/hidden state of the various dialogs,e.g,help popups, google analytics
      selectedConfiguration: state.configuration.selectedConfiguration, // updates with user intent prior to fetching actual config from API, e.g, when switching between departments
      route: getCurrentRequestedRoute(state), // ignore for now,
      isSaving: getSaving(state), // when the config is being saved
      isLoading: getConfigurationLoading(state), // when the config is being loaded,
      validation: getConfigValidators(state),
      canSubmit: canSubmitConfigChange(state),
      redirectCount: getRedirectCount(state),
      hasCallBlocking: getCallBlockingEnabled(state),
      hasUmr: state.sbfApp.enabledServices.hasUmr,
      services: state.sbfApp.enabledServices,
      ringGroups: state.configuration.redirects.ringGroups
    })
  },
  {
    getProductDetails: fromHome.getProductPrice,
    requestConfiguration: configurationActions.requestConfiguration,
    fetchWhisperPrompts: configurationActions.fetchWhisperPrompts,
    fetchIntroPrompts: configurationActions.fetchIntroPrompts,
    fetchMohPrompts: configurationActions.fetchMohPrompts,
    fetchMohTrack: configurationActions.fetchMohTrack,
    fetchVoicemailPrompts: configurationActions.fetchVoicemailPrompts,
    fetchOohPrompts: configurationActions.fetchOohPrompts,
    fetchGlobalIntroPrompts: configurationActions.fetchGlobalIntroPrompts,
    fetchDepartmentMenuPrompts: configurationActions.fetchDepartmentMenuPrompts,
    fetchPocketData: configurationActions.fetchPocketData,
    handleNavigationButtonSelect: configurationActions.handleNavigationButtonSelect,
    handleConfigSave: configurationActions.handleConfigSave,
    validateRedirect: configurationActions.validateRedirect,
    handleAddRedirect: configurationActions.handleAddRedirect,
    handleAutoDetectGaUpdate: configurationActions.handleAutoDetectGaUpdate,
    handleShowRedirectSectionHelperDialog: configurationActions.handleShowRedirectSectionHelperDialog,
    notify: handleNotificationChange,
    fetchRedirectsHistory: configurationActions.fetchRedirectsHistory,
    fetchOutboundUsers: configurationActions.fetchOutboundUsers,
    fetchRingGroups: configurationActions.fetchRingGroups,
    removeRedirect: configurationActions.removeRedirect,
    handleToggleEmptyRedirectsWarning: configurationActions.handleToggleEmptyRedirectsWarning
  }
)(ConfigurationPage)
