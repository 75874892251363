import React, { Component } from 'react'
import GenericCrmTab from './GenericCrmTab'

export default class CrmTab extends Component {
  render () {
    switch (Number(this.props.crmId)) {
      default:
        return <GenericCrmTab crmId={Number(this.props.crmId)} />
    }
  }
}
