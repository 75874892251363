import React from 'react'
import { Button, FloatingLabel, Form, InputGroup, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { Radio, RadioGroup } from 'react-radio-group'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import LoadingNotification from '../../components/LoadingNotification'
import { pocketRedirectOptions, prMode } from '../../constants/ConfigurationConstants'
import { PhoneTypes } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { checkNumberType } from '../../helpers/utils'
import * as configurationActions from '../../store/Configuration'
import GetEnvVariable from '../GetEnvVariable'

class PocketReceptionistSection extends React.Component {
  constructor (props) {
    super(props)
    this.passwd = ''
    this.isLoading = false
    this.screen = ''
    this.email = ''

  }

  state = {
    showTsAndCs: false,
    showPocketPackages: false
  }

  render () {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <>
				<div className="countdown-timer timer-small text-lightest bg-gradient-pocket center-block box-shadow-v3 brad-10 pt-15 mb-10">
					<div className="timer-block">
						{days}
						<br />
						<span className="text-sm">days</span>
					</div>
					<div className="timer-block">
						{hours}
						<br />
						<span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}
						<br />
						<span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}
						<br />
						<span className="text-sm">seconds</span>
					</div>
				</div>
			</>
    }
    return <>
			<div className="row bg-dullest p-30 zoom">
									
				<div className="row p-30">
					<div className="col-md-5 pb-sm-10 number-description">

						 <>
								<h5 className="text-secondary text-500 text-lg">
									Live Agent Call Answering
								</h5>
								<p className="text-500">
									Let us answer the calls you can’t.
								</p>
								<p className="text-400">
									Relax and concentrate on your job, while we answer the calls for you.
									<br /><br />
									We can answer your calls between:<br /><br />
									Monday to Friday <span className='text-500'>8:00 - 20:00</span><br />
									Saturday and Sunday <span className='text-500'>9:00 - 18:00</span><br />
									Bank Holidays <span className='text-500'>9:00 - 16:00</span>
									<br /><br />
									Please note changes to company name and or greeting as part of this call answering service may take up to 15 minutes to reach our expert call handlers
								</p>

								<Modal className="w-1300px" show={this.state.showPocketPackages} onHide={e => this.setState({ showPocketPackages: false })}>
									<Modal.Header className='bb-0' closeButton>
									</Modal.Header>
									<Modal.Body>
										<div className='row d-flex'>
											<div className='col-md-6 mb-20'>
												<div className='bg-light box-shadow-v3 b-1 brad-20 p-0'>
													<div className='text-center bg-gradient-pocket text-lightest p-20 pb-40 brad-t-20'>
														<h3 className='text-500 m-0 p-0 text-lightest mb-20'>Lite</h3>
														<p className='p-0 m-0'>Ad-hoc cover for very short-term cover e.g. whilst in meetings, or out for lunch!</p>
														<p className='pt-20'>
															<span className='text-500 text-md'>&pound;<GetEnvVariable envName="PR_SBF_Call_Cost" type="price"  /><sup>*</sup></span>
															<em className="text-400 ml-5 text-sm">per call</em>
														</p>
														<Button size="sm" variant="light" onClick={() => this.handleOnOffStateChange(true)}>Choose Lite</Button>
													</div>
													<ul className='fancy pl-60 pr-40 cta pt-20 pb-20 text-400'>
														<li className='pb-10'>Own greeting
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p>Your calls will be answered with your company name</p>
																	<p className='mb-0'>For example: <i>&quot;Hello, thank you for calling [company name], how can I help?&quot;</i></p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Basic Message Taking
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>We will collect name, contact number and a short message.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Call Coverage 8:00 - 20:00 Mon to Fri, 9:00 - 18:00 Sat &amp; Sun, Bank Holidays 9:00 - 16:00
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>These are the hours your calls will be answered by our agents.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Real-time message notifications via Email
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>We will send you an email in real-time, with the call details included.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
												</ul>
												<p className='mb-0 text-sm p-20 pt-0'>
													<sup>*</sup>&pound;<GetEnvVariable envName="PR_SBF_Call_Cost" type="price" /> covers the first 2 minutes of the call, after this you're then billed <GetEnvVariable envName="PR_SBF_Additional_Minute_Rate" type="price" />p per minute.
												</p>
							
												</div>
											</div>

											<div className='col-md-6 mb-20'>
												<div className='bg-light box-shadow-v3 b-1 brad-20 p-0 p-relative'>
													<img src="/images/tag-popular.png" style={{ position: 'absolute', left: '-20px', top: '-19px' }} />
													<div className='text-center bg-gradient-pocket text-lightest p-20 pb-40 brad-t-20'>
														<h3 className='text-500 m-0 p-0 text-lightest mb-20'>Full</h3>
														<p className='p-0 m-0'>An ongoing solution to ensure every call is captured with full access to all PR features!</p>
														<p className='pt-20'>
															<span className='text-500 text-md'>From &pound;64.00</span>
															<em className="text-400 ml-5 text-sm">per month</em>
														</p>
														<Button size="sm" variant="light" onClick={() => this.setState({ showNumberConfirm: true, prMode: prMode.FULL, showPocketPackages: false })}>Choose Full</Button>
													</div>
													<ul className='fancy pl-60 pr-40 cta pt-20 pb-20 text-400'>
														<li className='pb-10'>Own greeting
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Select how you would like us to greet your callers.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Detailed Messages &amp; FAQ’s
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>We can collect all information required, qualify the call for you by asking certain questions &amp; work from a list of FAQ’s to handle many enquiries for you.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Get Calls Transferred
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Select the calls you would like us to transfer to you and we will filter out the rest.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Bespoke Services Available
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Add diary management, bookings, appointment setting, payments etc. all via your existing software.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>7 Days a Week Coverage
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Select the days/hours you would like your agents to cover your calls.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Real-time message notifications via Email &amp; App
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>We will send you an email and a push notification to the App in real-time when a call has been taken (SMS alerts also available).</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Multiple Package Options
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Packages to suit the coverage you need starting from 30 calls per month.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Call Recording
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Listen back to all calls that your agents have taken for you.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Get the free App
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Access many free features via App.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Free Call Blocking
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Block unwanted calls.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Activate VIP List
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Add friends &amp; family to your VIP list so their calls bypass your agents and go straight to you.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
														<li className='pb-10'>Free Call Back Widget
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover>
																	<p className='mb-0'>Encourage visitors to your website to request a call back by one of our agents.</p>
																</Popover>
															}>
																<span className="icon icon-info2 text-xs ml-5 text-dark-lighter"></span>
															</OverlayTrigger>
														</li>
													</ul>
										
												</div>
											</div>


											<div className='col-md-12 bt-1 pt-30 mt-30 text-center'>
												<p className='underline cursor-pointer' onClick={e => this.setState({ showPocketPackages: false })}>Close this window</p>
											</div>
										</div>
									</Modal.Body>
								</Modal>

							</>
						  
						

					</div>
					<div className="col-md-7 mt-10 pl-sm-0 pr-sm-0 pl-60">
						<div className='row'>
							<h5 className="text-dark text-500 text-md text-center mb-20">Select your package</h5>

							{(this.state.prMode === undefined && !this.state.showPrWarning) &&
								<>
									<div className='row d-flex pr-packages'>

										<div className='col-12 col-sm-6'>
											<div className='bg-light box-shadow-v3 b-1 brad-20 p-0'>
												<div className='text-center bg-gradient-pocket text-lightest p-20 brad-t-20 text-sm' style={{ minHeight: '220px' }}>
													<h4 className='text-500 m-0 p-0 text-lightest'>Lite</h4>
													<p className='p-0 m-0 mt-15 text-sm'>Ad-hoc cover for very short-term cover e.g. whilst in meetings, or out for lunch!</p>
													<p className='pt-20 m-0'>
													<span className='text-500 text-md'>&pound;<GetEnvVariable envName="PR_SBF_Call_Cost" type="price" /> </span>
														<em className="text-400 d-block text-sm">per call</em><br/>
													<small>
														&pound;<GetEnvVariable envName="PR_SBF_Call_Cost" type="price" /> covers the first 2 mins.<br />Then, <GetEnvVariable envName="PR_SBF_Additional_Minute_Rate" type="price" />p per minute.
													</small>

													</p>
												</div>
												<div className='p-20 text-center'>
													<Toggle
														id="pocketOnOffSwitch"
														className="mt-5 mb-5"
														style={{ minWidth: '75px' }}
														onClick={e => (this.props.pocket && this.props.pocket.isPocketEnabled === true) ? this.setState({ showPrWarning: true }) : this.handleOnOffStateChange(e)}
														on={<span>ON</span>}
														off={<span>OFF</span>}
														size="sm"
														offstyle="default"
														onstyle="success"
														width="null"
														height="null"
														active={this.props.pocket && this.props.pocket.isPocketEnabled === true}
													/>


													<p className='pl-0 pt-10 m-0 text-center text-xs'>
														<span className="underline cursor-pointer" onClick={e => this.setState({ showPocketPackages: true })}>
															Compare Plans
														</span>
													</p>
												</div>
											</div>
										</div>

										<div className='col-12 col-sm-6'>
											<div className='bg-light box-shadow-v3 b-1 brad-20 p-0 p-relative'>
												<div className='text-center bg-gradient-pocket text-lightest p-20 brad-t-20 text-sm' style={{ minHeight: '220px' }}>
													<img src="/images/tag-popular.png" style={{ position: 'absolute', left: '-20px', top: '-19px' }} />
													<h4 className='text-500 m-0 p-0 text-lightest'>Full</h4>
													<p className='p-0 m-0 mt-15 text-sm'>An ongoing solution to ensure every call is captured with full access to all PR features!</p>
													<p className='pt-20 m-0'>
														<span className='text-500 text-md'>Get 24/7 coverage<br/>from just &pound;64</span>
														<em className="text-400 d-block text-sm">per month</em><br/>
													</p>
												</div>

												<div className='p-20'>
													<Button size="sm" variant="transparent" className="btn-outline-pocket btn-block" disabled={this.props.prMode === prMode.FULL} onClick={() => this.setState({ showNumberConfirm: true, prMode: prMode.FULL })}>
														{(this.props.prMode === prMode.LITE || this.props.prMode === prMode.HOL) ? 'Upgrade' : 'Enable'}
													</Button>
													<p className='pl-0 pt-10 m-0 text-center text-xs'>
														<span className="underline cursor-pointer" onClick={e => this.setState({ showPocketPackages: true })}>
															Compare Plans
														</span>
													</p>
												</div>
											</div>
										</div>

									</div>
								</>
							}

							{(this.state.showNumberConfirm) &&
								<div className="col-12">
									<div className='alert alert-secondary p-30'>
										<h4 className='text-500 pt-0 mt-0 mb-20 '>Confirm your number</h4>
										<p>
											To continue please confirm or change your contact phone number.
										</p>
										<div className='pt-30'>
											<InputGroup>
												<FloatingLabel
													label="Call me on"
													className="mb-3 text-md d-flex"
												>
													<Form.Control
														value={this.state.numberToCall}
														onChange={e => this.setState({ numberToCall: e.target.value })}
														type="tel"
														placeholder='Your number'
														className="w-200px"
														isInvalid={this.state.numberError} />
													<Button className='btn btn-cta d-inline ml-neg-5' onClick={() => this.handleUpdatePrMode(this.state.prMode, this.state.numberToCall)} disabled={this.state.isLoading}>Confirm</Button>
												</FloatingLabel>
											</InputGroup>
											{this.state.numberError === true &&
												<div className="text-400 text-sm text-danger mt-5">
													Please enter a valid phone number
												</div>
											}
											{this.state.isLoading &&
												<LoadingNotification loadingText={'Please wait...'} isLoading={this.isLoading} height="50" width="50" />
											}
										</div>
										<p className='pt-30'>
											<span className='underline cursor-pointer' onClick={() => this.setState({ showNumberConfirm: false, prMode: undefined })}>I've changed my mind, cancel this.</span>
										</p>
									</div>
								</div>
							}

							{this.state.showPrThanks &&
								<div className="col-12">
									<div className='alert alert-success p-30'>
										<h4 className='text-500 pt-0 mt-0 mb-20 text-success'>Thank you!</h4>
										<p className='text-success'>
											Your interest in the {(this.state.prMode === prMode.FULL) ? 'PR Full' : 'PR Holiday'} plan as been noted. One of the Welcome Team will call you shortly to go through the package in further detail and get your account up and running!
										</p>
										<p className='pt-10 text-success'>
											<span className='underline cursor-pointer' onClick={() => this.setState({ showPrThanks: false, prMode: undefined })}>Close this</span>
										</p>
									</div>
								</div>
							}

							{this.state.showPrWarning &&
								<div className="col-12">
									<div className='alert alert-warning p-30'>
										<h4 className='text-500 pt-0 mt-0 mb-20 text-warning'>Are you sure?</h4>
										<p className='text-warning mb-20'>
											You're about to turn OFF call answering. Our team won't answer calls on your behalf during that time. Are you sure you want to do that?
										</p>
										<p className='pb-10'>

											<Button variant="cta" onClick={() => this.handleOnOffStateChange(false)}>Yes,  turn off</Button>
										</p>
										<p className='pt-10'>
											<span className='underline cursor-pointer' onClick={() => this.setState({ showPrWarning: false })}>I've changed my mind, cancel this.</span>
										</p>
									</div>
								</div>
							}

							{((this.props.prMode === prMode.LITE && (!this.state.prMode > prMode.LITE || this.state.prMode === undefined)) || (this.props.pocket && this.props.pocket.isPocketEnabled === true)) &&
								<>
									<div className="col-md-12 pl-sm-0 pr-sm-0">
										<div className="text-center pb-0">
											<RadioGroup className="bg-dull brad-10 p-20" name="pocketAnswerType" selectedValue={((this.props.pocket ? this.props.pocket.redirectAfter : 0) > 0 ? 'overflowCalls' : 'AnswerAllCalls')} >
												<div className='row'>
													<div className="col-xs-6">
														<Radio value="AnswerAllCalls"
															disabled={!(this.props.pocket)}
															onChange={e => this.props.handlePocketSecondsDurationChange({ newValue: -60 })}

														/>
														<br />
														<p className="block pt-10 text-500">
															Answer all calls
														</p>
													</div>
													<div className="col-xs-6">
														<Radio value="overflowCalls"
															disabled={!(this.props.pocket)}
															onChange={e => this.props.handlePocketSecondsDurationChange({ newValue: 40 })}

														/>
														<br />
														<p className="block pt-10 text-500">
															Overflow calls
															<br />
															<span className="text-300">(set time below)</span>
														</p>
													</div>
												</div>
											</RadioGroup>
										</div>

										{((this.props.pocket ? this.props.pocket.redirectAfter : 0) > 0
										  ? <>
												<p className="mt-20"><span className="text-500">Answer after</span> (This is the time when we will take your calls)</p>

												<Select
													isDisabled={!(this.props.pocket)}
													className="form-control select-menu mb-10"
													classNamePrefix="custom"
													id={'pocket_seconds_select'}
													options={pocketRedirectOptions}
													simpleValue
													name="selected-state"
													value={{ value: (this.props.pocket ? this.props.pocket.redirectAfter : 20), label: (this.props.pocket ? this.props.pocket.redirectAfter : 20) + ' Seconds' }}
													onChange={e => this.props.handlePocketSecondsDurationChange({ newValue: e.value })}
													searchable={false}
													clearable={false}
												/>
											</>
										  : '')}
										<p className="mt-30"><span className="text-500">Company Name</span> (This is the name we will use to greet your callers)</p>
										<input type='text' disabled={!(this.props.pocket)} value={(this.props.pocket ? this.props.pocket.companyName : 'n/a')} maxLength='150' className="form-control" onChange={e => this.props.handlePocketCompanyNameChange(e.target.value)} />

										<p className="mt-30"><span className="text-500">Greeting</span> (This is what we will use to greet your callers)</p>
										<textarea id={'pocket_greeting'} className="form-control" disabled={!(this.props.pocket)} maxLength='250' value={(this.props.pocket ? this.props.pocket.greeting : 'n/a')} onChange={e => this.props.handlePocketGreetingChange(e.target.value)} > </textarea>
									
									</div>
								</>
							}
						</div>
					</div>
				</div>
			</div>
		</>
  }

  handleCheckValidTelephone (tel) {
    const url = 'api/Users/isValidTelephone'
    const numberType = checkNumberType(tel)
    return fetchHelper.postJson(url, { phoneNumber: tel, landline: numberType === PhoneTypes.landline })
  }

  handleUpdatePrMode (mode, number) {
    this.setState({ showPocketPackages: false, prMode: mode, isLoading: true }, () => {
      if (mode === prMode.FULL || mode === prMode.HOL) {
        // validate number
        this.setState({ isLoading: true }, () => {
          const isValidPhone = this.handleCheckValidTelephone(this.state.numberToCall).then(res => {
            this.setState({ isLoading: false, numberError: res.data !== true })
            if (res.data) {
              const url = `api/Configuration/EmailPocketSales/${mode}/${number}/${this.props.queueNo}`
              fetchHelper.getJson(url)
                .then(res => {
                  if (res.data) {
                    this.setState({ showPrThanks: true, showNumberConfirm: false })
                  }
                }).catch(err => console.error(err))
            }
          }).catch(err => console.error('test', err))
        })
      }
    })
  }

  handleOnOffStateChange (e) {

    this.setState({ showPrWarning: false })
	this.setState({ showPocketPackages: false })

	// api call to set existing PR lite number
	// against this queue and if necesary allocate a new
	// pr lite number

    this.props.handlePocketEnabledChange(e)


  }

  passwordChange (e) {
    this.passwd = e
  }

  emailChange (e) {
    this.email = e
  }


};

export default connect(
  state => ({
    pocket: state.configuration.configuration.pocket,
    setupDisplayed: state.configuration.dialogVisibility.setupPocketLink,
    pocketResult: state.configuration.dialogVisibility.pocketResult,
    queueNo: state.configuration.selectedConfiguration.queueNo,
    email: state.configuration.configuration.userEmailAddress,
    prMode: state.configuration.configuration.prMode
  }),
  dispatch => bindActionCreators(configurationActions, dispatch)
)(PocketReceptionistSection)
