import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { CarrierIds } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'

export default class SiteMapComponent extends Component {
  constructor (props) {
    super(props)
  }

  state = {
    regions: undefined,
    loadingRegions: true
  }

  render () {
    return (
            <>
                <div className="row mb-30">
                    <div className="col-md-12 mb-20 mt-10" >
                        <h4 className="text-400"> Main Links</h4>
                    </div>
                    <div className="col-md-3">
                        <p><Link id={'SiteMapLinkHome'} to={'/'}>Home</Link></p>
                        <p><Link id={'SiteMapLinkReviews'} to={'/reviews/'}>Testimonials</Link></p>
                        <p><Link id={'SiteMapLinkVirtualPhoneNumbers'} to={'/virtual-phone-numbers'}>Virtual Phone Numbers</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkFaq'} to={'/faq/'}> FAQ </Link></p>
                        <p><Link id={'SiteMapLinkSignup'} to={'/get-started'}> Sign Up</Link></p>
                        <p><Link id={'SiteMapLinkVoipPhoneSystem'} to={'/business-voip-phone-system'}> Business VoIP Phone System</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkHowItWorks'} to={'/how-it-works'}> How it Works</Link></p>
                        <p><Link id={'SiteMapLinkAboutUs'} to={'/about-us/'}> About Us</Link></p>
                        <p><Link id={'SiteMapLinkBlog'} to={'/blog'}>Blog</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkAboutUs'} to={'/international-calls'}> International Calling</Link></p>
                    </div>
                </div>
                <div className="row mb-30">
                    <div className="col-md-12 mb-20 mt-10 bt-1 pt-20" >
                        <h4 className="text-400">Number Types</h4>
                    </div>
                    <div className="col-md-3">
                        <p><Link id={'SiteMapLink084Numbers'} to={'/084-numbers/'}> 084 Numbers </Link></p>
                        <p><Link id={'SiteMapLink03Numbers'} to={'/03-numbers/'}> 03 Numbers</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkLocalPhoneNumbers'} to={'/local-phone-numbers/'}> Local Geographic Numbers </Link></p>
                        <p><Link id={'SiteMapLinkAllTownsAndCities'} to={'/area-codes/'}> All Towns/Cities </Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLink0800Numbers'} to={'/0800-numbers/'}> 0800 Numbers</Link></p>
                        <p><Link id={'SiteMapLink0203Numbers'} to={'/0203-numbers/'}> 0203 Numbers</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLink0207Numbers'} to={'/0207-numbers/'}> 0207 Numbers</Link></p>
                        <p><Link id={'SiteMapLinkCompareBusinessNumbers'} to={'/compare-business-numbers/'}> Compare Numbers</Link></p>
                    </div>
                </div>
                <div className="row mb-30">
                    <div className="col-md-12 mb-20 mt-30 bt-1 pt-20">
                        <h4 className="text-400">Features</h4>
                    </div>
                    <div className="col-md-3">
                        <p><Link id={'SiteMapLinkCloseYourLines'} to={'/features'}> Close your lines at any time </Link></p>
                        <p><Link id={'SiteMapLinkQueueCalls'} to={'/features'}> Queue Calls</Link></p>
                        <p><Link id={'SiteMapLinkCustomizablePrompts'} to={'/features'}> Customisable Prompts</Link></p>
                        <p><Link id={'SiteMapLinkCampaignReporting'} to={'/features'}> Campaign Reporting</Link></p>
                        <p><Link id={'SiteMapLinkChangeConfigurationInstantly'} to={'/features'}> Change Configuration Instantly</Link></p>
                        <p><Link id={'SiteMapLinkGeographicNumbers'} to={'/features'}> Geographic Numbers</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkRedirectCallsToYourMobile'} to={'/features'}>Redirect calls to your mobile</Link> </p>
                        <p><Link id={'SiteMapLink2xFree084Numbers'} to={'/features'}> 2x Free 084 Numbers</Link></p>
                        <p><Link id={'SiteMapLinkFreeMusicOnHold'} to={'/features'}> Free on hold music</Link></p>
                        <p><Link id={'SiteMapLinkUniqueCallerLists'} to={'/features'}> Unique Caller Lists</Link></p>
                        <p><Link id={'SiteMapLinkCustomAnnouncements'} to={'/features'}> Custom Announcements</Link></p>
                        <p><Link id={'SiteMapLinkFeatures0800Numbers'} to={'/0800-numbers/'}> 0800 Numbers</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkMobileApps'} to={'/app/'}> Mobile Apps</Link></p>
                        <p><Link id={'SiteMapLinkDepartmentSelection'} to={'/features'}> Department Selection</Link></p>
                        <p><Link id={'SiteMapLinkMissedCallAlerts'} to={'/features'}> Missed Call Alerts</Link></p>
                        <p><Link id={'SiteMapLinkMissedCallLists'} to={'/features'}> Missed Call Lists</Link></p>
                        <p><Link id={'SiteMapLinkDisasterRecovery'} to={'/features'}> Disaster Recovery</Link></p>
                        <p><Link id={'SiteMapLinkLondonNumbers'} to={'/0207-numbers/'}> London Numbers</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkRerouteYourCalls'} to={'/features'}> Re-route your calls</Link></p>
                        <p><Link id={'SiteMapLinkCallScreening'} to={'/features'}> Call Screening</Link></p>
                        <p><Link id={'SiteMapLinkVoicemailServices'} to={'/features'}> Voicemail Services</Link></p>
                        <p><Link id={'SiteMapLinkIdentiftBusyPeriod'} to={'/features'}> Identify Busy Period</Link></p>
                        <p><Link id={'SiteMapLinkFeatures03Numbers'} to={'/03-numbers/'}> 03 Numbers</Link></p>
                    </div>
                </div>
                <div className="row mb-30">
                    <div className="col-md-12 mb-20 mt-10 bt-1 pt-20">
                        <h4 className="text-400">Call Tracking</h4>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkReferrerTracking'} to={'/call-tracking-website-traffic-sources/'}>Referrer Tracking</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkLoyaltyTracking'} to={'/call-tracking-visitor-session/'}>Loyalty Tracking</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkPayPerClickTracking'} to={'/call-tracking-online-advertising/'}>Pay Per Click Tracking</Link></p>
                    </div>
                </div>
                <div className="row mb-30">
                    <div className="col-md-12 mb-20 mt-10 bt-1 pt-20">
                        <h4 className="text-400">Useful Links</h4>
                    </div>
                    <div className="col-md-3">
                        <p><Link id={'SiteMapLinkIphoneApp'} to={'/app/'}>iPhone App</Link></p>
                        <p><Link id={'SiteMapLinkIpadApp'} to={'/app/'}>iPad App</Link></p>
                        <p><Link id={'SiteMapLinkAndroidApp'} to={'/app/'}>Android App</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><Link id={'SiteMapLinkCustomers'} to={'/customers'}>Customers</Link></p>
                        <p><Link id={'SiteMapLinkForgotPassword'} to={'/forgot-password'}>Forgot Password</Link></p>
                        <p><Link id={'SiteMapLinkUsefulLinksSignUp'} to={'/get-started'}>Sign Up</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><a id={'SiteMapLinkBrochure'} href="/pdf/sbf.pdf" target="_blank">SwitchboardFree Brochure</a></p>
                        <p><Link id={'SiteMapLinkFaxToEmail'} to={'/fax-to-email'}>Fax 2 Email</Link></p>
                    </div>
                    <div className="col-md-3" >
                        <p><a id={'SiteMapLinkPrivacyPolicy'} href="/privacy-policy" target="_blank">Privacy Policy</a></p>
                        <p><Link id={'SiteMapLinkCosts'} to={'/costs'}>Costs</Link></p>
                    </div>
                </div>
                <div className="row mb-30">
                    <div className="col-md-12 mb-20 mt-10 bt-1 pt-20" >
                        <h4 className="text-400">Local Numbers</h4>
                    </div>
                    {(this.state.regions && this.state.regions.length > 0) &&
                        this.state.regions.map((region, i) => {
                          return (
                                <p className="col-md-3">
                                    <Link id={`region_${i}`} to={`/area-code/${region.prefix}-${region.location.replace(' ', '_').toLowerCase()}-numbers`}>
                                        {region.prefix} {region.location}
                                    </Link>
                                </p>
                          )
                        })
                    }
                </div>


            </>
    )
  }

  async componentDidMount () {
    const getGeoRegionsUrl = '/api/Purchase/GetGeoRegions'
    fetchHelper.postJson(getGeoRegionsUrl, { CarrierIds }).then(res => {
      const all = res.data.allData
      this.setState({ loadingRegions: false, regions: all })
    }).catch(function (error) {
      console.error(error)
    })
  }
}
