import React from 'react'
import { Button } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { simpleDateTimeFormat } from '../../helpers/utils'
import { QuickCloseAutoReopenDialog } from './QuickCloseAutoReopenDialog'

export const QuickCloseToggle = props => {
  return (
        <>

                {/* <input type='checkbox' checked={props.quickClosed} onChange={e => props.handleChangeQuickClose(e.target.checked)}/> */}
                <Toggle
                    id={props.quickClosed ? `${props.id}Closed` : `${props.id}Open`}
                    className="mt-5 rounded reversed-toggle"
                    onClick={e => {
                      return props.handleChangeQuickClose(e)
                    }}
                    on={<span>CLOSED</span>}
                    off={<span>OPEN</span>}
                    size="sm"
                    offstyle="success"
                    onstyle="default"
                    width="null"
                    height="null"
                    active={props.quickClosed}
                    disabled={typeof props.disabled !== 'undefined' ? props.disabled : false}
                />
                <span className="slider round"></span>
                <QuickCloseAutoReopenDialog quickCloseAutoReopenDialogDisplayed={props.autoReopenDialogDisplayed}
                                            onAutoReopenTimeChange={props.onAutoReopenTimeChange}
                                            handleSaveAutoReopenTime={props.handleSaveAutoReopenTime}
                                            dismiss={props.handleDismissAutoReopenDialog} dateTime={props.dateTime}/>

            <div>
                {props.quickClosed && props.stateDirty && !props.autoReopenTime &&
                <Button variant="secondary" size="xs" className="mt-5 re-open" onClick={props.handleShowAutoReopenDialog}>Auto re-open</Button>}
                {props.autoReopenTime && <><label>{simpleDateTimeFormat(props.autoReopenTime)}</label><br/>
                <Button variant="transparent" size="xs" className="p-5 text-xs" onClick={e => props.handleCancelAutoReopen(props.qlid)}><span className="icon icon-cancel mr-5"></span>Cancel</Button>
                </>}
            </div>
        </>

  )
}
