import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import TopupSelect from '../../components/TopupSelect'
import { AutoTopupValues, ProductIds, SingleTopupValues } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat } from '../../helpers/utils'
import {
  getAutoTopupAmount, getHasAgreement, getHasAutoTopup, getMobileCredit,
  handleAutoTopupAmountChange,
  handleChangeHasAgreement,
  handleChangeHasAutoTopup, handleNotificationChange, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal, handleUpdateCredit
} from '../../store/SBF'
import TermsAndConditions from '../../Text/TermsAndConditions'

const _ = require('lodash')

class TopUps extends React.Component {
  state = {
    showTsAndCs: false, topupInProgress: false
  }

  constructor (props) {
    super(props)
  }

  activateAutoTopup (e) {
    const url = '/api/Purchase/ActivateAutoTopup'

    const context = this
    const amountId = context.props.autoTopupAmount.value
    fetchHelper.postJson(url, { amountId }).then(res => {
      if (res.data && (res.data === true || res.data.success === true || res.data.isSuccess === true)) {
        context.props.handleChangeHasAgreement(true)
        context.props.handleChangeHasAutoTopup(true)
        context.props.notify({ message: 'Auto topup activated' })
      } else if (res.data && res.data.postponed === true) {
        context.props.handleChangeHasAgreement(true)
        context.props.handleChangeHasAutoTopup(true)
        context.props.notify({ message: 'Auto topup activated' })
        context.props.handleShowPaymentPostponedModal()
      } else {
        context.props.handleChangeHasAgreement(false)
        context.props.handleChangeHasAutoTopup(false)
        context.setState({ prodId: ProductIds.autoMobileTopUp, topupInvId: undefined, topupAmount: undefined })
        context.props.handleShowPaymentOptionsModal()
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  deactivateAutoTopup (e) {
    const url = '/api/Purchase/DeactivateAutoTopup'

    const context = this

    fetchHelper.postJson(url, {}).then(res => {
      context.props.handleChangeHasAutoTopup(false)
      context.props.notify({ message: 'Auto topup deactivated' })
    }).catch(function (error) {
      console.error(error)
    })
  }

  formatMobileCredit (mobileCredit) {
    return `£${mobileCredit.toFixed(2)}`
  }

  render () {
    return <><ScrollToTopOnMount/><DisplayLoggedInFooter/><DisplayMainHeader/>
        {/* <UpgradesMenu/> */}
            <Helmet>
                <title>Topup Credit</title>
            </Helmet>
            <header>
                <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/contactbook-bg.jpg')" }}>
                    <h1>Add credit to your account</h1>
                    <h2 className="subtitle">You need credit in order to redirect calls <br className="d-none d-lg-block"/>that are outside your call package.</h2>
                </div>
            </header>
            <div className="container-styled bg-dull">
                <div className="container">
                    <div className="row pt-30 pt-xs-0 pb-30">
                        <div className="col-12">
                            <div className="w-300px center-block mb-30 mt-30">
                            <div className="bg-light brad-20 p-30 b-1 box-shadow-lighter text-center">
                                <h6 className="text-500">Your current credit</h6>
                                <h4 className="text-500 text-cta">{this.formatMobileCredit(this.props.mobileCredit)}</h4>
                            </div>
                        </div>
                        </div>
                        <div className="col-12">
                            <div className="w-900px center-block">
                            <div className="row-flex flex-2-col">
                                <div className="col-md-12 bg-light brad-20 p-30 pb-30 b-1 box-shadow-lighter text-center mb-20">
                                    <h5 className="text-500">Auto Top Up</h5>
                                    <p>Automatically top up your account credit when it falls below £2.50. Never worry about running out of credit and missing calls.</p>
                                    <div className="pt-30">
                                        <TopupSelect className="mb-20" options={AutoTopupValues} value={this.props.autoTopupAmount}
                                                    handleTopupSelection={e => this.handleAutoTopupSelection(e, this.props.autoTopupEnabled)}/>
                                        <div className="text-center">
                                            {!this.props.paymentAgreementEnabled &&
                                                <p className="text-400 text-danger w-100 mb-20 text-sm">
                                                    <span className="text-500">Note:</span> You do not currently have a billing agreement. Clicking "Activate" will direct you to WorldPay to create a new billing agreement.
                                                </p>
                                            }
                                            <div>
                                            {this.props.autoTopupEnabled
                                              ? <Button variant="danger" className="btn-mobile-block"
                                                        onClick={e => this.deactivateAutoTopup(e)}>Deactivate</Button>
                                              : <Button variant="cta" className="btn-mobile-block" data-delay={3}
                                                    onClick={e => this.activateAutoTopup(e)}>Activate</Button>}
                                            </div>

                                            {!this.props.paymentAgreementEnabled &&
                                                <p className="mt-20">
                                                        <img src={'/images/credit-cards/stripe-badge-transparent.png'} width="320" className="worldPay"
                                                            alt="Payments powered by Stripe" />
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 bg-light brad-20 p-30 pb-30 b-1 box-shadow-lighter text-center mb-20">
                                    <h5 className="text-500">One-off Top Up</h5>
                                    <p>Add extra credit to your account at your convenience whenever you need it. Please select one of the options below.</p>

                                    <div className="text-center pt-30">
                                        <Button variant="cta" onClick={e => this.purchaseCredit(10.00)} size="xs" className="m-5 " disabled={this.state.topupInProgress}>+£10.00</Button>
                                        <Button variant="cta" onClick={e => this.purchaseCredit(20.00)} size="xs" className="m-5 " disabled={this.state.topupInProgress}>+£20.00</Button>
                                        <Button className="m-5" variant="cta" onClick={e => this.purchaseCredit(40.00)} size="xs" disabled={this.state.topupInProgress}>+£40.00</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 text-center pt-30 bt-1 mt-60 mb-60">
                                <small className="text-400">
                                    By purchasing this product you agree to the <a className="text-cta" onClick={e => this.setState({ showTsAndCs: true })}>terms &amp; conditions</a> of this product.
                                    <br/>All prices are exclusive of VAT at 20%.
                                </small>
                            </div>
                            <Modal show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Terms &amp; Conditions</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <TermsAndConditions/>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <ChoosePayment
                total={calculateVat(this.state.topupAmount)}
                clientId={this.props.clientId}
                purchaseData={this.state.key}
                email={this.props.userDetails.email}
                name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                mandate={!this.props.services.hasAgreement}
            />

        </>
  }

  componentWillMount () {
    const queryString = this.props.match.params.queryString
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('new=true')) {
        this.props.handleChangeHasAgreement(true)
        this.props.handleChangeHasAutoTopup(true)
        this.props.notify({ message: 'Auto topup activated' })
      } else if (params.includes('newcred=true')) {
        this.props.notify({ message: 'Topup credit added' })
      }
    }
  }

  handleAutoTopupSelection (e, isActive) {
    const context = this
    context.props.handleAutoTopupAmountChange(e)

    if (isActive) {
      const url = '/api/Purchase/UpdateAutoTopupAmount'

      fetchHelper.postJson(url, { amountId: e.value }).then(res => {
        context.props.notify({ message: 'Auto topup amount updated' })
      }).catch(function (error) {
        console.error(error)
      })
    }
  }

  purchaseCredit (creditToAdd) {
    const context = this

    context.setState({ topupInProgress: true }, () => {
      const url = '/api/Purchase/PurchaseSingleMobileTopup'

      const topupId = _.find(SingleTopupValues, tv => tv.price === creditToAdd).topupId

      fetchHelper.postJson(url, { TopupOptionId: topupId }).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          context.setState({ topupInProgress: false }, () => {
            context.props.notify({ message: 'Credit added' })
            context.props.handleUpdateCredit(res.data.newCreditAmount)
          })
        } else {
          const priceIncVat = calculateVat(creditToAdd)
          context.setState({ key: res.data.key, topupAmount: priceIncVat })
          context.props.handleShowPaymentOptionsModal()
        }
      }).catch(function (error) {
        context.setState({ topupInProgress: false })
        console.error(error)
      })
    })
  }
}

export default connect(
  state => {
    return {
      clientId: state.sbfApp.authentication.clientId,
      paymentAgreementEnabled: getHasAgreement(state),
      autoTopupEnabled: getHasAutoTopup(state),
      autoTopupAmount: getAutoTopupAmount(state),
      mobileCredit: getMobileCredit(state),
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    handleChangeHasAutoTopup,
    handleChangeHasAgreement,
    handleAutoTopupAmountChange,
    notify: handleNotificationChange,
    handleUpdateCredit,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal
  }
)(TopUps)
