import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { fetchHelper } from '../../helpers/fetchHelper'
import DisplayLoggedInFooter from '../DisplayLoggedInFooter'
import DisplayMainHeader from '../DisplayMainHeader'
import ScrollToTopOnMount from '../ScrollToTopOnMount'

class ContactBookSyncHubSpotCrm extends React.Component {
  state = {
    syncingContacts: true,
    stage: 'Queued... Please wait.',
    code: '',
    intervalId: undefined
  }

  componentDidMount () {
    const query = new URLSearchParams(this.props.location.search)

    const code = query.get('code')

    const url = '/api/contacts/RegisterHubSpotCrmSyncRequest'

    const data = {
      code
    }

    fetchHelper.postJson(url, data).then(res => {

    })

    const id = setInterval(() => this.checkHubSpotCrmSyncStatus(), 3 * 1000)

    this.setState({
      code,
      intervalId: id
    })
  }

  checkHubSpotCrmSyncStatus () {
    const url = '/api/contacts/GetCrmInitialSyncStatus/3'

    fetchHelper.getJson(url).then(res => {
      this.setState({
        stage: res.data,
        syncingContacts: res.data !== 'Done'
      })
    })
  }

  componentWillUnmount () {
    if (this.state) {
      const id = this.state.intervalId
      clearInterval(id)
    }
  }

  render () {
    return <>
            <ScrollToTopOnMount/>
            <DisplayLoggedInFooter/>
            <DisplayMainHeader/>
            <Helmet>
                <title>HubSpot CRM Sync</title>
                <meta name="description" content="Outbound call settings" />
            </Helmet>
            <header>
                <div className="full-width full-hero-small with-text" >
                <h1>HubSpot CRM Sync</h1>
                    {/* <h2 className="subtitle">Block individual numbers, as well as all international <br className="visible-lg"/>calls and withheld calls, too!</h2> */}
                </div>
            </header>
            {/* <header className="full-width my-calls-header">
                <h1 className="text-500">Capsule CRM Sync</h1>
            </header> */}

            <div className="container pt-30 pb-30">
                <div className="w-600px center-block bg-light mt-30 mb-100 brad-30 p-40 text-center box-shadow">

                    {this.state.syncingContacts === true && <div className="loading-content text-center">
                        <img id="loading" src={'images/icons/loading.svg'} width="70" height="70" />
                        <p className="text-dark text-400">{this.state.stage}</p>
                    </div>}

                    {this.state.syncingContacts === false && <div className="loading-content text-center">
                        <div className="success-icon mt-20 mb-20">
                            <span className="icon icon-checkmark4"></span>
                        </div>
                        <p>Your HubSpot CRM contacts has been synced successfully.</p>
                        <p className="pt-20">
                            <Link to={'/customer/contact-book/'} className="btn btn-cta btn-sm">See your updated contacts</Link>
                        </p>
                    </div>}
                </div>
            </div>
        </>
  }
}

export default ContactBookSyncHubSpotCrm
