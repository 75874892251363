import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import EnableTpsProtectionModal from '../../components/VoipUsers/EnableTpsProtectionModal'
import {
	productBillingOption, ProductIds, TpsProtectionPerUserCharge
} from '../../constants/Constants'
import { isLoggedIn } from '../../selectors'
import { getProductPrice } from '../../store/Home'
import { handleNotificationChange, handleShowCallbackForm, handleTPSProtectionEnabledChange } from '../../store/SBF'

class Tps extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      showTpsPurchaseModal: false,
	  showTpsProtectionVideo: false
    }
  }

  scrollTo () {
    scroller.scrollTo('start', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  handleSignup () {
    localStorage.setItem('landingPage', true)
    this.props.history.push('/get-started')
  }

  handleEnableTps () {
    this.setState({ showTpsPurchaseModal: true })
  }

  render () {
    const tpsPayment = { firstPayment: 0, repeatPayment: 0 }
    if (this.props.productDetails) {
      const tpsPaymentDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.tpsProtection)
      if (tpsPaymentDetails) {
        tpsPayment.firstPayment = tpsPaymentDetails.monthlyPrice.firstPaymentAmount
        tpsPayment.repeatPayment = tpsPaymentDetails.monthlyPrice.repeatPaymentAmount
      }

      if (tpsPayment.repeatPayment === 0) { this.props.getProductDetails([{ productId: ProductIds.tpsProtection, monthlyOption: productBillingOption.tpsProtectionMonthly }]) }
    }

    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
			<Helmet>
				<title>TPS Protection - Stay Compliant and Avoid Hefty Fines</title>
				<meta name="description" content="TPS Protection feature provides businesses with an easy and efficient way to stay compliant with TPS regulations." />
			</Helmet>
			<header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
				<h1 id="TPSProtectionH1">TPS Protection</h1>
				<h2 className=" subtitle">Stay Compliant and Avoid Hefty Fines</h2>
			</header>

			<div className="full-width bg-light pt-30 no-overflow">
				<div className="container">
					<div className="row">
						<div className={`${this.props.services.hasTPSProtection !== true ? 'col-md-8' : 'w-1000px center-block'}`}>

						{this.props.services.hasTPSProtection === true
						  ? <div className="w-600px center-block text-center b-1 box-shadow-v3 bg-light brad-20 p-30 pb-50 mt-30 mb-30">
									<span className="circle-tick success center-block margin-bottom-20 slide-in-blurred-top scale-up1">
										<span className="icon icon-checkmark4 scale-up"></span>
									</span>
									<h4 className="text-500 mt-30 mb-20">The TPS Protection is enabled!</h4>

									<p><Link to={'/customer/profile/tps-protection-settings'} className="underline text-cta">Click here</Link> to make changes to your TPS Protection settings.</p>
									<p className="pb-20">To enable this feature for more users, go to your <Link to={'/customer/outbound-management'} className="underline text-cta">users page</Link>.</p>
									<div className="ratio ratio-16x9 pt-20">
											<iframe src="https://www.youtube.com/embed/yn5uz5ieljg" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
										</div>
									<Link to={'/customer/outbound-management'} className="btn btn-cta btn-lg mt-30">Go to My Users</Link>
								</div>
						  :								<>

									<p>In today's fast-paced business environment, keeping up with various regulations is essential for any organisation. One such regulation is the Telephone Preference Service (TPS) which aims to protect consumers from unsolicited marketing calls.</p>

									<p>TPS Protection feature ensures that your business remains compliant and helps you avoid potential fines. Let&apos;s dive into the details of TPS and the protection feature offered by SwitchboardFREE.</p>

									<h2 className="text-500 text-darker text-xl mt-40 mb-30">What is the TPS? </h2>

									<p>The <a href="https://www.tpsonline.org.uk/" target="_blank" rel="noreferrer">Telephone Preference Service (TPS)</a> is a free service that allows individuals to register their telephone numbers in the UK to indicate that they do not wish to receive unsolicited sales or marketing calls. The TPS is managed by the <a href="https://dma.org.uk/" target="_blank" rel="noreferrer">Direct Marketing Association (DMA)</a> and is enforced by the <a href="https://ico.org.uk/" target="_blank" rel="noreferrer">Information Commissioner's Office (ICO)</a>. By law, organisations making outbound marketing calls are required to screen their call lists against the TPS and avoid making unsolicited marketing calls to those registered numbers.</p>

									<h2 className="text-500 text-darker text-xl mt-40 mb-30">Watch our video about TPS Protection</h2>
										<div className="ratio ratio-16x9 pt-20">
											<iframe src="https://www.youtube.com/embed/yn5uz5ieljg" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
										</div>
									<h2 className="text-500 text-darker text-xl mt-40 mb-30">Potential fines for calling someone on the TPS </h2>
									<p>Failure to comply with the TPS regulations can lead to significant fines for your organisation. The ICO has the authority to issue fines of up to £500,000 for non-compliant organisations, depending on the severity of the breach. In addition, reputational damage from negative publicity can be detrimental to your business. It is, therefore, crucial to have a system in place to ensure compliance with the TPS.</p>

									<h2 className="text-500 text-darker text-xl mt-40 mb-30">How it works </h2>
									<p>SwitchboardFREE's TPS Protection feature provides businesses with an easy and efficient way to stay compliant with TPS regulations. When making outbound calls, users have the option to block calls, or be warned about calls, to a number that's on the TPS. This ensures that you avoid making unsolicited calls to those who have opted out of receiving them.</p>
									<p>The TPS Protection feature is seamlessly integrated into SwitchboardFREE's platform, making it simple for users to stay compliant without having to manage separate systems or lists. With this feature, your business can focus on reaching the right audience without worrying about potential fines or reputation damage.</p>


									<h2 className="text-500 text-darker text-xl mt-40 mb-30">How much does it cost?</h2>
									<p>SwitchboardFREE's TPS Protection feature is available for an affordable {<GetProdPrice productId={ProductIds.tpsProtection} monthlyOption={productBillingOption.tpsProtectionMonthly} />} per month. Additionally, there is a usage-based fee of £{TpsProtectionPerUserCharge} per user, per month. This cost-effective solution helps protect your business from costly fines and ensures that you adhere to the TPS regulations.</p>

									<div className="mt-50 mb-50">
										<div className="col bg-light brad-10 box-shadow-v3 p-30 b-1 clearfix">
											<p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
												<span className="text-cta">Talk to us today</span> and get started
											</p>
											<h2 className="text-lg text-400 mb-0 mt-0 text-dark-light">
												Schedule a quick, no-obligation call &amp; discover...
											</h2>
											<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
												<li className="text-md mb-10">
													<span className="icon icon-checkmark-circle text-success mr-10" />
													How you could reduce your phone costs
												</li>
												<li className="text-md mb-10">
													<span className="icon icon-checkmark-circle text-success mr-10" />
													How you can benefit from the advantages of VoIP
												</li>
												<li className="text-md mb-10">
													<span className="icon icon-checkmark-circle text-success mr-10" />
													The benefits of our TPS Protection feature
												</li>
											</ul>

											<span className="d-inline-block">
												<Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.props.handleShowCallbackForm()} id="GetACallbackButton">Get a callback</Button>
												<a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
											</span>
										</div>
									</div>
									<p>In conclusion, the TPS Protection feature offered by SwitchboardFREE is a valuable tool for any organisation that makes outbound sales or marketing calls. By staying compliant with TPS regulations, you can avoid potential fines, maintain your business reputation, and focus on what matters most – growing your business. With the affordable pricing of this feature, there's no reason not to safeguard your organisation and ensure a more effective and responsible approach to your marketing efforts.</p>

								</>
							}

						</div>
						{this.props.services.hasTPSProtection === true ?
							<></>
							:
							<div className="col-md-4">

									<div className="brad-10 bg-primary bg-gradient p-40 text-center pb-0 sticky-t-100">
										<span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
											Instantly activate TPS Protection
										</span>
										<p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-20" style={{ lineHeight: '1em' }}>
											<span className="text-primary-light">Enable</span> TPS Protection quickly &amp; easily
										</p>
										<p className="text-md text-400 mt-30 mb-0">
											From as little as &pound;{tpsPayment.firstPayment + TpsProtectionPerUserCharge} per month.
										</p>

										<span className="d-inline-block col-12 mt-20 mb-50">
											<Button variant="cta" className="btn btn-lightest btn-outline p-15 pl-50 pr-50 text-md mb-xs-20" onClick={() => this.handleEnableTps()}>Enable now</Button>
											<p className="text-sm mt-20">Or, call <a href="tel:02031891213" className="text-lightest">0203 189 1213</a></p>
										</span>

									</div>

							</div>
						}
					</div>
				</div>

				{this.state.showTpsPurchaseModal &&
					<EnableTpsProtectionModal
						showModal={this.state.showTpsPurchaseModal}
						toggleShowTpsModal={() => this.setState({ showTpsPurchaseModal: false })}
						showTpsInfo={false}
						showPricingInfo={true}
					/>
				}

			</div>
		</>
  }

  componentDidMount () {
    this.props.getProductDetails([{ productId: ProductIds.tpsProtection, monthlyOption: productBillingOption.tpsProtectionMonthly }])
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      services: state.sbfApp.enabledServices,
      productDetails: state.home.productDetails.products
    }
  },
  {
    handleShowCallbackForm,
    notify: handleNotificationChange,
    getProductDetails: getProductPrice,
    handleTPSProtectionEnabledChange
  }
)(Tps)
