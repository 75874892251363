import { useCallback, useEffect, useRef } from 'react';
import { fetchHelper } from '../../helpers/fetchHelper';
import {
  UseLinkedinLoginProps
} from "../../models/OAuth";

//https://learn.microsoft.com/en-us/linkedin/consumer/integrations/self-serve/sign-in-with-linkedin-v2
//https://learn.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow

const getPopupPositionProperties = ({ width = 600, height = 600 }) => {
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;
  return `left=${left},top=${top},width=${width},height=${height}`;
};

const generateRandomString = (length = 20) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export default function LinkedinLogin({
  redirectUri,
  clientId,
  onSuccess,
  onError,
  scope = 'r_emailaddress r_liteprofile',
  state = ''
}: UseLinkedinLoginProps) {
  const popupRef = useRef<Window | null>(null);

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      const savedState = localStorage.getItem('linkedin_oauth2_state');
      if (event.origin === window.location.origin) {
        if (event.data.errorMessage && event.data.from === 'Linked In') {
          if (event.data.state !== savedState) {
            popupRef.current && popupRef.current.close();
            return;
          }
          onError && onError(event.data);
          popupRef.current && popupRef.current.close();
        } else if (event.data.code && event.data.from === 'Linked In') {
          if (event.data.state !== savedState) {
            popupRef.current && popupRef.current.close();
            return;
          }
          if (event.data.code) {
            // Lets get access code 
            fetchHelper.postJson('/api/Users/GetLinkedInUserDetails', {
              Code: `${event.data.code}`,
              ClientId: clientId
            }).then(res => {
              onSuccess(res)
            }).catch(err => console.error(err))
          }
          popupRef.current && popupRef.current.close();
        }
      }
    },
    [onError, onSuccess],
  );

  useEffect(() => {
    return () => {
      window.removeEventListener('message', receiveMessage, false);

      if (popupRef.current) {
        popupRef.current.close();
        popupRef.current = null;
      }
    };
  }, [receiveMessage]);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const getUrl = () => {
    const scopeParam = `&scope=${encodeURI(scope)}`;
    const generatedState = state || generateRandomString();
    localStorage.setItem('linkedin_oauth2_state', generatedState);
    const linkedInAuthLink = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${scopeParam}&state=${generatedState}`;
    return linkedInAuthLink;
  };

  const liAuth = useCallback(
    () => {
      popupRef.current?.close();
      popupRef.current = window.open(
        getUrl(),
        '_blank',
        getPopupPositionProperties({ width: 600, height: 600 }),
      );
    },
    [],
  );

  return liAuth;
}