import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'
import CustomPrompts from '../../CustomPrompts'

const VoicemailInstructionHelperDialog = props => (
    <Modal className="w-900px" show={props.isDisplayed}
           onHide={props.hideVoicemailInstructionHelperDialog}>
        <Modal.Header closeButton>
            <Modal.Title className="text-500">Voicemail</Modal.Title>
        </Modal.Header>
        <Modal.Body className="col-md-12">
            <p>
                If you enable the voicemail facility your callers will be given the option (but not
                forced) to leave a voicemail instead of waiting in your call queue.
            </p>
           <p>
                To give you a chance to answer the call before your caller decides to leave a
                voicemail you can also set how long (in seconds) before your caller is given the option
                to leave a voicemail.
            </p>

            <p>
                The "voicemail message" is what your caller will hear after pressing 1 and before they are given the
                chance to record their voicemail to you. This message can be customised and is a good opportunity to apologies to the caller for
                not being able to answer the call in time and to manage their expectations on when they can expect to
                hear back from you.
            </p>
            <h5 className="pt-20 text-500">Multiple emails</h5>
            <p>
            Voicemails are instantly emailed to the email address which you enter. You can enter one or more email addresses by separating them with semicolons, for example: <i>john@example.com;john@gmail.com</i>
            </p>

            <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/dfyg6ZjSqKM" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </div>

            <div className="col-md-12 text-center pb-30 pt-30">
                <h3 className="text-500">Make your company sound amazing!</h3>
                <p>Choose one of the below voice artists to record your out of hours message, introduction, call screening, voicemail message, departments menu... or simply do it yourself.</p>
            </div>
            <CustomPrompts />
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideVoicemailInstructionHelperDialog}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.voicemailInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoicemailInstructionHelperDialog)
