import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import FeatureEnableButton from '../../../components/FeatureEnableButton'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import CallTranscriptionToggle from "../../../components/CallTranscription/CallTranscriptionToggle"

class CallTranscriptionUpgradePage extends React.Component {
  render () {
    return <>
			<ScrollToTopOnMount />
            <DisplayMainHeader />
            <DisplayMainFooter />
			<Helmet>
				<title>Call Transcription</title>
				<meta name="description" content="Automatically transcribe your calls to text." />
				<style type="text/css">{`
                       .no-bg{
                            background-image:none !important;
                       }
                       .well, .well.well-transparent p,.well.well-transparent h5{
                        color:#3b3b3b !important;
                       }

                    `}</style>
			</Helmet>

			<header>
				<div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/call-filtering.jpg')" }}>
					<h1 id="CallTranscriptionH1">Call Transcription</h1>
					<h2 className="subtitle">Search your conversations for present or missing words and phrases.
					<br/>
						A powerful tool for auditing, compliance, and training.</h2>
				</div>
			</header>

			<div className={'full-width bg-light'}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-10">

							<div className="row mt-50 mb-60">
								<div className="col-md-6 col-sm-12 order-last">
									<img src="/images/call-transcription.jpg" alt="Call Recording with transcription" className="img-responsive brad-20 " style={{ maxWidth: '600px' }} />
								</div>
								<div className="col-md-6 col-sm-12 order-first">
									<h2 className="text-500 text-darker">
										Never Miss a Detail Again
									</h2>
									<h2 className="text-lg text-400 mb-30 mt-10 text-dark-light w-600px">
										Unlock Business Efficiency with SwitchboardFREE's Call Transcription
									</h2>

									<p>
										With our innovative VoIP phone systems, we're now bringing you a tool that will streamline your call management like never before. Here's why our Call Transcription feature is a game-changer for your business:</p>

									<ul className="styled-list success rounded text-start text-400 mt-40 pl-0">
										<li className="pb-10">
											<strong>Capture Every Conversation:</strong> Call Transcription ensures every word spoken during a call is documented in text. No more note-taking, no more forgetting important details - you'll have a ready-to-use record at your fingertips.
										</li>
										<li className="pb-10">
											<strong>Search for keywords with Ease:</strong> Ever spent time trying to remember a critical detail from a past call, a date, a time, a price quoted? Now you can simply search for it. Our Call Transcription lets you search for specific words or phrases mentioned in any conversation.
										</li>
										<li className="pb-10">
											<strong>Negative keyword search:</strong>
											Search for conversations missing a word or a phrase. This is a must have tool when it comes to compliance.
											Instantly compile a list of all calls missing essential words or phrases. A powerful tool that will save you a small fortune and simplyfy compliance and regulation workloads.
										</li>
										<li className="pb-10">
											<strong>Real time alerts:</strong>
											Coming soon, a real time email notification system, allowing you to identify calls that may contain, or may not contain a specific word or phrase, important facts that should have been included. Be alerted to these calls right away allowing you to imporove staff performance and minimise risk.
										</li>
										<li className="pb-10">
											<strong>Stay Compliant:</strong> Never miss out on mentioning essential details again. With our feature, you can quickly search for terms or conditions that need to be discussed during a call, ensuring complete professionalism and compliance.
										</li>
									</ul>

									<CallTranscriptionToggle />

								</div>
							</div>
							<div className="row mt-100 mb-60">
								<div className="col-md-6 col-sm-12 order-last order-md-first text-end pt-100 pt-xs0">
									<img src="/images/homepage-features-3.jpg" alt="Call Recording with transcription" className="img-responsive brad-20 " style={{ maxWidth: "600px" }} />
								</div>
								<div className="col-md-6 col-sm-12 order-first order-md-last">
									<h2 className="text-500 text-darker">
										Harness the Power of Every Word
									</h2>
									<h2 className="text-lg text-400 mb-30 mt-10 text-dark-light w-600px">
										Transform Your Business Communications with Call Transcription
									</h2>

									<p>
										With no fixed monthly charges and a user-friendly rate of 5p per minute of transcribed calls, our Call Transcription feature is the cost-effective, efficient solution you've been looking for to manage your business communication. </p>

									<ul className="styled-list success rounded text-start text-400 mt-40 pl-0">
										<li className="pb-10">
											<strong>Set Up Custom Alerts:</strong> With our Call Transcription feature, you can now set alerts for specific words or phrases that are either mentioned or missing from your calls. This ensures you&apos;re immediately notified when critical information is shared or when important terms have been omitted, helping you maintain consistent quality control and compliance in every conversation.
										</li>
										<li className="pb-10">
											<strong>Simplify Training &amp; Quality Control:</strong> Easily revisit past calls to highlight best practices, identify areas for improvement, or educate new team members. With transcripts at your disposal, every call becomes a potential learning opportunity.
										</li>
									</ul>
									<CallTranscriptionToggle />

								</div>
							</div>

							<div className="row mt-100 mb-60 justify-content-center">
								<div className="col-10 text-center box-shadow-v3 p-40 brad-20">
									<h2 className="text-lg text-400 mb-30 mt-10 text-dark-light">
										With no fixed monthly charges and a budget-friendly rate of 5p per minute of transcribed calls, Call Transcription is the perfect addition to your call management system.
									</h2>

									<CallTranscriptionToggle />

								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
  }
}

export default CallTranscriptionUpgradePage
