import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplaySimpleHeader from '../../components/DisplaySimpleHeader'
import LeadGenForm from '../../components/LeadGenForm'
import PlansAndNumbersDropdown from '../../components/PlansAndNumbersDropdown'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SelectPackage from '../../components/SelectPackage'
import VoiPTerms from '../../components/VoiPTerms'
import { BasketItemType } from '../../constants/Constants'
import { getEmptyBasket } from '../../helpers/utils'
import { isLoggedIn } from '../../selectors'
import { getProductPrice } from '../../store/Home'
import { handleShowCallbackForm } from '../../store/SBF'
import { handleAddToBasket, handleBasketClear, handleBasketInit, handleRemoveFromBasket } from '../../store/Signup'
import { getCustomerCount } from '../../helpers/generic'

const _ = require('lodash')

class DynamicLandingPage extends React.Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    prefix: '01',
    redirectToMobile: false,
	totalCustomers: 0
  }

  constructor (props) {
    super(props)
  }

  scrollTo (el) {
    scroller.scrollTo(el, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -90
    })
  }

  handleSelectPackage (packDetails) {
    const context = this
    if (!this.state.selectedPackage || !_.isEqual(packDetails, this.state.selectedPackage)) {
      context.setState({ selectedPackage: packDetails }, () => {
        context.props.handleAddToBasket({
          item: packDetails,
          itemType: BasketItemType.package
        })
        context.scrollTo('chooseNumber')
      })
    }
  }

  render () {
    const basket = getEmptyBasket()

    const header = this.state.header ? this.state.header : 'Virtual number &amp; unlimited calls for just 99p'
    const subheader = this.state.subheader ? this.state.subheader : 'We have virtual phone numbers for every UK town and city. Pick up one today and get a months unlimited incoming and outgoing calls for only 99p!'
    const cta = this.state.cta ? this.state.cta : this.state.offer ? 'Start your 99p plan' : 'Choose your plan'

    return <>
			<ScrollToTopOnMount /><DisplayMainFooter /><DisplaySimpleHeader />
			<Helmet>
				<title dangerouslySetInnerHTML={{ __html: header }}></title>
				<meta name="description" content={subheader} />

				<style type="text/css">{`
                .side-box {
                    background-image:url(/images/smile-receptionist.png);
                    background-position:163% 100%;
                    background-size:470px;
                    background-repeat:no-repeat;
                    background-color:#ff9314;
                    min-height:637px;
                }
                .header .navbar.navbar-simple {
                    min-height: 75px;
                }
                @media screen and (max-width:1170px) {
                    .side-box {
                        background-image:none;
                        min-height:unset;
                    }
                }
                @media screen and (max-width:992px) {
                    .form-control.h-60px {
                        height:45px;
                    }
                }
                @media screen and (max-height:800px) {
                    .form-control.h-60px {
                        height:45px;
                    }
                }
                @media screen and (max-width:767px) {
                    .side-box p.text-lg{
                        font-size:21px !important;
                    }
                    .col-xs-6 .icon-block {
                        width: 130px;
                        height: 130px;
                        padding: 8px 0;
                      }
                }

                    `}</style>
			</Helmet>
			<header style={{ backgroundImage: 'url(\'/images/backgrounds/header7.jpg\')' }} className="full-width full-hero-small with-text overlay" >
				<div className="w-1200px center-block">
					<h1 dangerouslySetInnerHTML={{ __html: header }}></h1>
					<h2 className="subtitle pt-10" dangerouslySetInnerHTML={{ __html: subheader }}></h2>
					<Button size="lg" variant="cta" className="position-relative mt-50" style={{ zIndex: '1' }} onClick={() => this.scrollTo('choosePlan')}>
						{cta}
					</Button>
				</div>
			</header>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center mt-30">
							<h3 className="text-500">
								Get the best UK based, <span className="text-cta">5-star rated VoIP system</span> <br className="d-none d-md-block" />for your business
                            </h3>
							<h5 className="text-400 mt-40">
								All our virtual numbers come with a simple 30-day rolling contract, no setup or activation fees, <br className="d-none d-md-block" />a free iOS or Android app, plus, a fully featured softphone for your PC or laptop.
                            </h5>
						</div>
					</div>
					<div className="row w-1300px center-block mt-60">
						<div className="col-sm-6">
							<h5 className="text-500">Find the perfect virtual number for your business</h5>
							<p>
								A  virtual number allows you to look more professional, better manage your calls, and provide a service that makes you stand out from your competition.
								It’s guaranteed to make your life easier, stay ahead of the game, and save costs and increase profits at the same time.
                            </p>

							<h5 className="text-500 mt-30">It’s cloud-based &amp; easy to control</h5>
							<p>
								You choose a number that’s suits your business and advertise this wherever you see fit. When a caller rings your new number, this rings on your App, softphone, landline or mobile.
								You can then use over 40 free features such as Open &amp; Close Times, Time of day routing and Custom introductions to enhance your company’s image, increase efficiency and reduce your missed calls.
                            </p>

							<h5 className="text-500 mt-30">Get set up &amp; sorted in no time</h5>
							<p>
								Set up takes only a couple of minutes and you can start testing and using the service straight away.
								We have over 40 free features to help you get the most out of the service and if you need any help, our welcome team on hand via the phone, email or live chat to help with questions you have.
                            </p>

							<h5 className="text-500 mt-30">Your business is our no. 1 priority</h5>
							<p>
								From sole traders to large enterprises our mission is to help businesses of all sizes get the best out of there telecoms.
								We’ve helped over {this.state.totalCustomers} businesses in the UK save time, cut costs, and fuel their sales. We’re UK based, independently 5-star rated,
								and our customer service team are totally UK based for your comfort and ease.
                            </p>
						</div>
						<div className="col-sm-6">
							<div className="brad-10 text-lightest p-30 mt-10 side-box">
								<div className="row">
									<div className="col-md-12">
										<p className="text-lg text-italic text-400 pt-30 pt-xs-10">&quot;These guys really know how to look after customers. The VoIP service they have provided me with for both my businesses is flawless and the support is great. Highly recommended company.&quot;</p>
										<p className="pt-10">
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-10"></span>
											<span>Nigel</span>
											<span className="bg-dark brad-20 text-lightest pt-0 pb-3 pl-10 pr-10 ml-10">
												<span className="img-circle text-lightest d-inline-block mr-5" style={{ width: '15px', height: '15px', backgroundColor: '#33b995', verticalAlign: 'middle' }}>
													<span className="icon icon-checkmark4" style={{ fontSize: '9px', padding: '4px 3px 3px 2px', display: 'block' }} />
												</span>
												<span className="text-500 text-xs">Verified user</span>
											</span>
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-6 col-md-12 text-md-center">
										<p className="text-md text-500 mt-40 mb-20">Want to know more?</p>
										<p>
											Give a call on <a className="text-500 underline-hover text-lightest" href="tel:02031891213">0203 189 1213</a>
										</p>
										<Button variant="light" size="lg" className="text-500 text-cta mb-20 mt-10" style={{ fontSize: '16px' }} onClick={() => this.props.handleShowCallbackForm()}>
											Speak to an expert
                                        </Button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 clearfix text-center pt-40" id="choosePlan">
							<h3 className="text-500 mb-30">
								Simple plan options to suit your needs...
                            </h3>
						</div>
					</div>
					<div className="row">
						<div className={this.state.inboundOnly ? 'p-30 box-shadow shadow-lighter brad-30 clearfix m-30' : ''}>
							{this.state.inboundOnly &&
								<div className="col-md-12 fixed-container">
									<div className="p-20 b-1 mb-40 brad-20 pt-30 pb-30">
										<div className="row">
											<div className="col-md-12 text-center">
												<h5 className="text-500 d-inline pr-60 pr-xxs-0 block-xxs pb-xxs-20">Send calls to your:</h5>
												<label className="small text-md pl-15 text-400 pr-50">
													Landline
                                                        <span className="styled-checkbox checkbox-light float-start" style={{ margin: '-15px 0 0 -30px' }}>
														<Form.Check
															inline
															checked={this.state.redirectToLandline}
															onChange={e => this.setState({ redirectToLandline: e.target.checked })} />
													</span>
												</label>

												<label className="small text-md pl-15 text-400">
													Mobile
                                                        <span className="styled-checkbox checkbox-light float-start" style={{ margin: '-15px 0 0 -30px' }}>
														<Form.Check
															inline
															checked={this.state.redirectToMobile}
															onChange={e => this.setState({ redirectToMobile: e.target.checked })} />
													</span>
												</label>

											</div>
											<div className="col-md-12 text-center pt-20">
												<p className="text-sm text-400">
													When someone calls your SwitchboardFREE number we will redirect it through <br className="d-none d-md-block" />to your landline, mobile, or both.
                                                </p>
											</div>
											{this.state.showOutbound !== true && !this.state.redirectToLandline && !this.state.redirectToMobile &&
												<div className="form-group has-error text-center mb-0 mt-0 clearfix">
													<div className="help-block m-0 pt-20 text-sm">
														To see available packages, you must select at least one of the above options.
                                                        </div>
												</div>
											}
										</div>
									</div>
								</div>
							}

							<div className="col-md-12">
								<SelectPackage
									prefix={this.state.prefix}
									selectedPackage={this.state.selectedPackage}
									handleSetPackage={i => this.handleSelectPackage(i)}
									isSignup={this.props.location.pathname.includes('get-started')}
									history={this.props.history}
									userQty={this.state.userQty}
									onClickSmallPrint={ele => this.scrollTo(ele)}
									loggedIn={this.props.isLoggedIn}
									redirectToLandline={this.state.redirectToLandline}
									redirectToMobile={this.state.redirectToMobile}
									isBasic={true}
									is99pOffer={this.state.offer}
									showInbound={this.state.inboundOnly}
									preventDefaultSelection={true}
									currentBasket={basket}
								/>
							</div>

						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-70 pt-70" id="chooseNumber">
				<div className="container numbers-pages pl-sm-0 pr-sm-0">
					<div className="row">
						<div className="col-md-12 text-center pb-60">
							<h3 className="text-500">
								Choose the perfect Virtual Phone Number(s) for your business...
                            </h3>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-7">
							<PlansAndNumbersDropdown
								basket={basket}
								prefix={this.state.prefix}
								ddiLid={this.state.ddiLid?.value}
								selectedPackage={this.state.selectedPackage}
								handleChangeNumberType={e => this.setState({ prefix: e })}
								handleChangePackage={e => this.handleSelectPackage(e)}
								handleChangeAreaCode={e => this.setState({ ddiLid: e })}
								inboundOnly={this.state.inboundOnly === true }
								preventDefaultSelection={true}

								WrapperClassName="mb-40"
								LabelsClassName="text-xs text-dark text-400"

								PlansButtonClassName="text-start bg-light pl-25 pr-25 b-0"
								PlanNameClassName="text-dark text-400"
								PlansResultsDropdownClassName="bg-light box-shadow"
								PlanNameClassNameSelected="text-primary text-500"

								NumberButtonClassName={`text-start bg-light pl-25 pr-25 ml-5 b-0 ${this.state.inboundOnly ? 'inbound-only' : ''}`}
								NumberNameClassName="text-dark text-400"
								NumbersResultsDropdownClassName="bg-light ml-5 box-shadow"
								NumberNameClassNameSelected="text-cta text-500"

								AreaCodeButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
								AreaCodeNameClassName="text-dark text-400"
								AreaCodeResultsDropdownClassName="bg-light box-shadow"
								AreaCodeNameClassNameSelected="text-cta text-500"
							/>

							<DdiSelectorNew
								isSimple={true}
								hideRegions={true}
								ddiLid={this.state.ddiLid?.value}
								noScroll={true}
								numberType={this.state.prefix}
								isSignup={false}
								currentBasket={this.props.currentBasket}
								hideShowAll={false}
								isModal={false}
								menuOpen={true}
								handleAddToBasket={e =>
								  this.props.handleAddToBasket(e)
								}
								handleRemoveFromBasket={e =>
								  this.props.handleRemoveFromBasket(e)
								}
							/>
						</div>
						<div className="col-sm-5">
							<LeadGenForm
								FormHeadingText="Instant signup"
								ReserveNumber={true}
								ButtonText="Create my account"
								hideLabels={true}
								showFees={true}
								CompanyName={true}
								basket={basket}
								offerAvailable={this.state.offer}
								inboundOnly={this.state.inboundOnly}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pt-50 pb-50">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center mb-60">
							<h3 className="text-500">Why businesses choose Virtual Numbers</h3>
						</div>
						<div className="col-md-8 mx-auto">
							<div className="row">
								<div className="col-xxs-12 col-xs-6 col-sm-6 col-md-4 text-center pb-50 pl-40 pr-40 pl-40 pr-40">
									<div className="icon-block icon-cta xl">
										<img className="icon" src="/images/icons/tie.svg" style={{ height: '70px' }} />
									</div>
									<h5 className="text-500 pb-30">Appear professional</h5>
									<p className="text-dark pt-10 pb-10">
										Virtual numbers help make your business look more professional to customers. Get taken seriously and upgrade your telecoms.
                                    </p>
								</div>

								<div className="col-xxs-12 col-xs-6 col-sm-4 text-center pb-50 pl-40 pr-40">
									<div className="icon-block icon-cta xl">
										<img className="icon" src="/images/icons/happy.svg" style={{ height: '60px' }} />
									</div>
									<h5 className="text-500 pb-30">Better Customer Satisfaction</h5>
									<p className="text-dark pt-10 pb-10">
										Delight your customers &amp; manange their expectations with a host of features, like Open &amp; Close Times, Time-Of-Day Routing, On-hold Music and more.
                                    </p>
								</div>

								<div className="col-xxs-12 col-xs-6 col-sm-4 text-center pb-50 pl-40 pr-40">
									<div className="icon-block icon-cta xl">
										<img className="icon" src="/images/icons/piggy.svg" style={{ height: '70px' }} />
									</div>
									<h5 className="text-500 pb-30">Attract More Customers</h5>
									<p className="text-dark pt-10 pb-10">
										Whether it’s to attract local customers or provide a national mobile-friendly option, get callers to trust you with the right virtual number type.
                                    </p>
								</div>

								<div className="col-xxs-12 col-xs-6 col-sm-4 text-center pb-50 pl-40 pr-40">
									<div className="icon-block icon-cta xl">
										<img className="icon" src="/images/icons/phone-call.svg" style={{ height: '60px' }} />
									</div>
									<h5 className="text-500 pb-30">Never Miss A Call</h5>
									<p className="text-dark pt-10 pb-10">
										If you don’t answer the phone, you can bet your competition will. With instant alerts &amp; optional call handling we guaranty you’ll never miss a call.
                                    </p>
								</div>
								<div className="col-xxs-12 col-xs-6 col-sm-4 text-center pb-50 pl-40 pr-40">
									<div className="icon-block icon-cta xl">
										<img className="icon" src="/images/icons/cup.svg" style={{ height: '70px' }} />
									</div>
									<h5 className="text-500 pb-30">Stand Out From The Crowd</h5>
									<p className="text-dark pt-10 pb-10">
										With customisable prompts &amp; other unique features you’ll stand head &amp; shoulders above your competition &amp; callers will remember your brand.
                                    </p>
								</div>
								<div className="col-xxs-12 col-xs-6 col-sm-4 text-center pb-50 pl-40 pr-40">
									<div className="icon-block icon-cta xl">
										<img className="icon" src="/images/icons/tap.svg" style={{ height: '70px' }} />
									</div>
									<h5 className="text-500 pb-30">More Control Over Your Calls</h5>
									<p className="text-dark pt-10 pb-10">
										Easily manage your calls from our app or browser. React immediately to changes in your business or moniter any call in an instant, you’re in control.
                                    </p>
								</div>
							</div>
							{/* <div className="row">
                                <div className="col-xs-12 text-center pb-30">
                                    <Link to={'/get-started'} className="btn btn-lg btn-cta">Create account</Link>
                                </div>
                            </div> */}
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pt-50 pb-50" id="faq">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div id="terms" className="mt-60 mt-xs-30">
								<p className="text-500">Terms &amp; conditions</p>
								<VoiPTerms />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pt-50">
				<div className="container">
					<CarouselWidget />
				</div>
			</div>

			{/* Logoos */}
			<div className="full-width bg-light">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">Partners &amp; Customers</h3>
							{/* <h6>The UK's favourite call management system</h6> */}
							<p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>
			<ClientLogos style='light' />

		</>
  }

  async getCustomers () {
    const data = await getCustomerCount()

    this.setState({ totalCustomers:  data})
  }

  componentDidMount () {
	this.getCustomers()
    this.props.handleBasketClear()
    this.props.handleBasketInit()
    const query = new URLSearchParams(this.props.location.search)
    if (query) {
      if (query.get('inbound')) {
        this.setState({ inboundOnly: true, redirectToMobile: true })
      }

      const header = query.get('header')
      const subheader = query.get('subheader')
      const cta = query.get('cta')
      const offer = query.get('offer')
      if (header) { this.setState({ header }) }

      if (subheader) { this.setState({ subheader }) }

      if (cta) { this.setState({ cta }) }

      if (offer) { this.setState({ offer: true }) }
    }
  }
}

export default connect(
  state => {
    return ({
      monthlyPayment: state.home.productDetails.monthlyFirstPayment,
      isLoggedIn: isLoggedIn(state),
      currentBasket: state.signup.packageInfo.currentBasket
    })
  },
  {
    handleRemoveFromBasket,
    handleAddToBasket,
    handleBasketClear,
    handleBasketInit,
    getProductDetails: getProductPrice,
    handleShowCallbackForm
  }
)(DynamicLandingPage)
