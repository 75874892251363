import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import connect from 'react-redux/es/connect/connect'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { handleNotificationChange } from '../../../store/SBF'
import LoadingNotification from '../../LoadingNotification'
import Pager from '../../pager'

class Subscriptions extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    page: 1, totalPages: 1, fetchingSubscriptions: false, showCancelModal: false
  }

  componentDidMount () {
    this.getSubscriptions()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getSubscriptions()
    }
  }

  getSubscriptions () {
    const context = this
    const url = `/api/Profile/GetSubscriptions/page=${this.state.page}`
    context.setState({ fetchingSubscriptions: true })

    fetchHelper.getJson(url).then(res => {
      const subscriptions = res.data.subscriptions
      const totalPages = res.data.totalPages

      context.setState({ subscriptions, totalPages, fetchingSubscriptions: false })
    }).catch(function (error) {
      context.setState({ fetchingSubscriptions: false })
    })
  }

  handlePageChange (page) {
    this.setState({ page })
  }

  render () {
    return (

            <>
                <Modal className="modal-med notify-modal" show={this.state.showCancelModal} onHide={ () => this.setState({ showCancelModal: false })}>
                    <Modal.Body className="text-center">
                        <div className="icon-wrapper mt-20 mb-20">
                            <span className="icon icon-headphones"></span>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    Please email <a href="mailto:support@switchboardfree.co.uk" className="text-dark underline">support@switchboardfree.co.uk</a> to cancel your subscription.
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="text-center bt-0 p-0 mt-30">
                        <Button variant="cta" className="btn-block brad-0 pt-20 pb-20" onClick={() => this.setState({ showCancelModal: false })}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            <div className="bg-light p-30 pb-40 brad-10 b-1 w-800px center-block mb-20" style={{ overflow: 'auto' }}>
                {this.state.fetchingSubscriptions && <LoadingNotification message={'Loading ...'} isLoading={true}/>}

                {this.state.subscriptions && this.state.subscriptions.length === 0
                  ? <div className="col-sm-12 col-md-12 pl-0 pr-0 pt-10 pb-10">
                        <div className="text-center">
                            <h4 className="pb-20 text-400">Subscriptions</h4>
                            <p>
                                There aren't any subscriptions connected with your account.
                            </p>
                        </div>
                    </div>

                  : <div className="col-sm-12 col-md-12 pb-40">
                        <div className='row d-none d-md-block'>
                            <div className="col-md-12 text-center">
                                <h4 className="pb-20 text-400">Subscriptions</h4>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-1 col-md-1 text-center">
                                <p className="text-500 text-xs">ID</p>
                            </div>
                            <div className="col-sm-4 col-md-4 text-center">
                                <p className="text-500 text-xs">Description</p>
                            </div>
                            <div className="col-sm-3 col-md-3 text-center">
                                <p className="text-500 text-xs">Billing Period</p>
                            </div>
                            <div className="col-sm-2 col-md-2 text-center">
                                <p className="text-500 text-xs">Billing Amount</p>
                            </div>
                        </div>

                    <div className='row'>
                        {this.state.subscriptions && this.state.subscriptions.map((sub) => <>
                            <div className="col-sm-12 col-md-12 pl-0 pr-0 bt-1 pt-10 pb-10">
                                <div className='row'>
                                    <div className="col-sm-12 col-md-1 text-center pt-sm-30">
                                        <p className="text-sm">
                                            <span className="text-500 text-xs d-block d-md-none">ID: </span>
                                            {sub.subscriptionId}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-md-4 text-center pt-sm-30">
                                        <p className="text-sm">
                                            <span className="text-500 text-xs d-block d-md-none">Description: </span>
                                            {sub.description}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-md-3 text-center pt-sm-30">
                                        <p className="text-sm">
                                            <span className="text-500 text-xs d-block d-md-none">Billing Period: </span>
                                            {sub.billingDescription}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-md-2 text-center pt-sm-30">
                                        <p className="text-sm">
                                            <span className="text-500 text-xs d-block d-md-none">Billing Amount: </span>
                                            &pound;{sub.amount}</p>
                                    </div>
                                    <div className="col-sm-12 col-md-2 text-center pb-sm-30 pt-sm-30">
                                        <Button variant="danger" size="xs" onClick={() => this.setState({ showCancelModal: true })}>Cancel</Button>
                                    </div>
                                </div>
                            </div>
                        </>)}
                    </div>

                    {/* Subscription subpages */}
                    <Pager onSelected={page => this.handlePageChange(page)} activePage={this.state.page} pages={this.state.totalPages}/>
                    </div>
                }
            </div>

            </>
    )
  }
}

export default connect(
  state => {},
  { notify: handleNotificationChange }
)(Subscriptions)
