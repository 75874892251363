import React from 'react'
import { Button, Form, InputGroup, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import Select from 'react-select'
import { daytimeSelectorOptions, redirectDurationOptions } from '../../constants/ConfigurationConstants'
import { RedirectLimit, RedirectType, validationStates } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import * as configurationActions from '../../store/Configuration'
import { handleNotificationChange } from '../../store/SBF'
import LoadingNotification from '../LoadingNotification'
import RedirectNumberDropdown from './RedirectNumberDropdown'

const _ = require('lodash')

const redirectOrderPopover = (isMoveUp, position) => (
	<Popover id="popover-trigger-hover-focus">
		<p className="text-400">
			{isMoveUp === true ? `Move redirect up to position #${position - 1}` : `Move redirect down to position #${position + 1}`}
		</p>
	</Popover>
)

class DayTimeRedirects extends React.Component {
  state = {
    isLoading: false,
    canProceed: true,
    validationChecked: false
  }

  constructor (props) {
    super(props)
  }

  render () {
    if (!this.state.validationChecked || this.props.redirectUpdated) {
      this.handleCheckValidation()
      this.props.handleRedirectsChecked()
    }

    return <>
			<div className="row">
				<div className="col-md-12 pt-30 pb-60 mt-20">
					<p className="text-500">Time Of Day Redirects<sup>*</sup>
					</p>
					<p>Automatically change where your calls are redirected to at different times.</p>

					{this.props.timeslots && this.props.timeslots.length > 0
					  ? <>
							<p className="text-500 text-sm mt-30">Current timeslots:</p>

							<div className="daytime-routing-container bt-1">
								{this.props.timeslots.map((item, i) => {
								  return (
										<div className="daytime-routing-row mt-1 bb-1 text-sm p-20 text-400">
											<div className="row">
												<div className="col-sm-1">
													{i + 1}.
												</div>
												<div className="col-sm-5">
													{item.mon && <span className="daytime-routing-day">Mon</span>}
													{item.tue && <span className="daytime-routing-day">Tue</span>}
													{item.wed && <span className="daytime-routing-day">Wed</span>}
													{item.thur && <span className="daytime-routing-day">Thu</span>}
													{item.fri && <span className="daytime-routing-day">Fri</span>}
													{item.sat && <span className="daytime-routing-day">Sat</span>}
													{item.sun && <span className="daytime-routing-day">Sun</span>}
												</div>
												<div className="col-sm-3">
													{`${item.startTime.substring(0, 2)}:${item.startTime.substring(2, 4)}`} - {`${item.endTime.substring(0, 2)}:${item.endTime.substring(2, 4)}`}
												</div>
												<div className="col-sm-3 text-end">
													<Button variant="link" className="mr-30 text-primary" onClick={() => this.handleEditTimeslot(item, i)} id={`EditTimeslotButton${i + 1}`}>Edit</Button>
													<Button variant="link" className="text-primary" onClick={() => this.handleRemoveTimeslot(item.id, i)} id="DeleteTimeslotButton">Delete</Button>
												</div>
											</div>
										</div>
								  )
								})}
							</div>

							<Button variant="transparent" className="text-primary btn-block mt-20 box-shadow shadow-lighter b-1" onClick={() => this.handleAddNewTimeSlot()} id="AddNewTimeslotButton">
								+ Add new time slot
							</Button>
						</>
					  :						<p className="pt-10">
							<a onClick={() => this.handleAddNewTimeSlot()} className="text-500 text-secondary btn btn-transparent text-primary b-1-dark" id="TimeslotsGetStartedButton">Get Started</a>
						</p>
					}

					<p className="mt-30 text-xs mb-0">
						<sup>*</sup>Please note, this feature is not currently available to manage on the SwitchboardFREE Phone app.
					</p>
				</div>
			</div>
			<Modal size="w-700px" show={this.props.isDisplayed} onHide={() => this.handleCloseModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						Time of day redirects
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="pl-30 pr-30 pl-xs-0 pr-xs-0">
						<p>
							Select the days, time and phone numbers (or ring groups or extensions) you'd like your calls automatically redirected to.
						</p>
						<p className="pb-30 pb-xxs-15">
							Please note, the time of day redirects settings you enter below will overwrite your existing redirects ONLY during the times you specify below.
						</p>

						{this.props.dayTimeRouting &&
							<div className="daytime-settings-wrapper mb-50">
								<div className="brad-10 mt-10 mb-10 box-shadow shadow-lighter b-1">
									<div className="bb-1 p-20">
										<div className="row">
											<div className="col-xs-6">
												<p className="lead text-md text-primary text-500 m-0">
													Time slot #{this.props.dayTimeRouting.position}
												</p>
											</div>
										</div>
									</div>
									<div className="day-selector p-20">
										<div className="row">
											<div className="col-xxs-12 col-xs-7 mb-xxs-20 days">
												<div className="w-13 text-center float-start">
													<span className={`img-circle b-2 ${this.props.dayTimeRouting.mon === true ? 'active' : ''}`} title="Monday" onClick={() => this.handleToggleTimeSlotDay({ day: 'mon', enabled: !this.props.dayTimeRouting.mon })} id={this.props.dayTimeRouting.mon === true ? 'MondayTimeslotDayActive' : 'MondayTimeslotDay'}>
														M
													</span>
												</div>
												<div className="w-13 text-center float-start">
													<span className={`img-circle b-2 ${this.props.dayTimeRouting.tue === true ? 'active' : ''}`} title="Tuesday" onClick={() => this.handleToggleTimeSlotDay({ day: 'tue', enabled: !this.props.dayTimeRouting.tue })} id={this.props.dayTimeRouting.tue === true ? 'TuesdayTimeslotDayActive' : 'TuesdayTimeslotDay'}>
														T
													</span>
												</div>
												<div className="w-13 text-center float-start">
													<span className={`img-circle b-2 ${this.props.dayTimeRouting.wed === true ? 'active' : ''}`} title="Wednesday" onClick={() => this.handleToggleTimeSlotDay({ day: 'wed', enabled: !this.props.dayTimeRouting.wed })} id={this.props.dayTimeRouting.wed === true ? 'WednesdayTimeslotDayActive' : 'WednesdayTimeslotDay'}>
														W
													</span>
												</div>
												<div className="w-13 text-center float-start">
													<span className={`img-circle b-2 ${this.props.dayTimeRouting.thur === true ? 'active' : ''}`} title="Thursday" onClick={() => this.handleToggleTimeSlotDay({ day: 'thur', enabled: !this.props.dayTimeRouting.thur })} id={this.props.dayTimeRouting.thur === true ? 'ThursdayTimeslotDayActive' : 'ThursdayTimeslotDay'}>
														T
													</span>
												</div>
												<div className="w-13 text-center float-start">
													<span className={`img-circle b-2 ${this.props.dayTimeRouting.fri === true ? 'active' : ''}`} title="Friday" onClick={() => this.handleToggleTimeSlotDay({ day: 'fri', enabled: !this.props.dayTimeRouting.fri })} id={this.props.dayTimeRouting.fri === true ? 'FridayTimeslotDayActive' : 'FridayTimeslotDay'}>
														F
													</span>
												</div>
												<div className="w-13 text-center float-start">
													<span className={`img-circle b-2 ${this.props.dayTimeRouting.sat === true ? 'active' : ''}`} title="Saturday" onClick={() => this.handleToggleTimeSlotDay({ day: 'sat', enabled: !this.props.dayTimeRouting.sat })} id={this.props.dayTimeRouting.sat === true ? 'SaturdayTimeslotDayActive' : 'SaturdayTimeslotDay'}>
														S
													</span>
												</div>
												<div className="w-13 text-center float-start">
													<span className={`img-circle b-2 ${this.props.dayTimeRouting.sun === true ? 'active' : ''}`} title="Sunday" onClick={() => this.handleToggleTimeSlotDay({ day: 'sun', enabled: !this.props.dayTimeRouting.sun })} id={this.props.dayTimeRouting.sun === true ? 'SundayTimeslotDayActive' : 'SundayTimeslotDay'}>
														S
													</span>
												</div>
											</div>
											<div className="col-xxs-12 col-xs-5 pl-xxs-15 pr-30 time">
												<div className="row">
													<div className="col-xs-5 pr-0">
														<Select
															className="form-control select-menu mb-10 time-of-day"
															classNamePrefix="custom"
															simpleValue
															options={daytimeSelectorOptions.filter(f => f.value !== '2400')}
															searchable={false}
															clearable={false}
															onChange={e => this.handleChangeTimeSlotTime({ type: 'start', time: e.value }) + this.validateTimeslotTime({ slot: this.props.dayTimeRouting })}
															value={daytimeSelectorOptions.filter(f => f.value === this.props.dayTimeRouting.startTime)}
															id="ToDStartTime"
														/>
													</div>
													<div className="col-xs-2 pt-10 pl-0 pr-0 text-center">
														<span>to</span>
													</div>
													<div className="col-xs-5 pl-0 pr-xxs-0">
														<Select
															className={`form-control select-menu mb-10 time-of-day ${this.props.validation && this.props.validation.timeslotTimes && this.props.validation.timeslotTimes.valid === validationStates.INVALID ? 'has-error' : ''}`}
															classNamePrefix="custom"
															simpleValue
															options={daytimeSelectorOptions}
															searchable={false}
															clearable={false}
															onChange={e => this.handleChangeTimeSlotTime({ type: 'end', time: e.value }) + this.validateTimeslotTime({ slot: this.props.dayTimeRouting })}
															value={daytimeSelectorOptions.filter(f => f.value === this.props.dayTimeRouting.endTime)}
															id="ToDEndTime"
														/>
													</div>
												</div>
											</div>
										</div>

										{this.state.showTimeWarning &&
											<div className="row">
												<div className="col-xs-12">
													<p className="text-danger text-400 text-sm ml-5">
														<i className="icon icon-warning mr-5"></i> You've chosen a time where your line is closed! Please adjust your opening hours or your time of day routing will not work correctly.
													</p>
												</div>
											</div>
										}

										{this.props.validation && this.props.validation.timeslot && !_.isEmpty(this.props.validation.timeslot) && this.props.validation.timeslot.valid === validationStates.INVALID &&
											(!this.props.dayTimeRouting.mon && !this.props.dayTimeRouting.tue && !this.props.dayTimeRouting.wed && !this.props.dayTimeRouting.thur && !this.props.dayTimeRouting.fri && !this.props.dayTimeRouting.sat && !this.props.dayTimeRouting.sun)
										  ? <div className="row">
												<div className="col-xs-12">
													<p className="text-dullest text-400 text-sm ml-10">
														Please choose atleast 1 day for this timeslot
													</p>
												</div>
											</div>
										  :											this.props.validation && this.props.validation.timeslotTimes && this.props.validation.timeslotTimes.valid === validationStates.INVALID &&
											<div className="row">
												<div className="col-xs-12 text-400 text-danger text-end text-sm pr-20">
													Your end time must be later than your start time
												</div>
											</div>
										}
									</div>
									<div className="bg-dull p-20 brad-b-10">
										<div className="row">
											<div className="col-xs-12 text-start mb-10">
												<p className="text-500 p-0 m-0 text-sm" id="RedirectToText">Redirect to:</p>
											</div>
											{this.props.dayTimeRouting && this.props.dayTimeRouting.redirects && this.props.dayTimeRouting.redirects.length > 0 && this.props.dayTimeRouting.redirects.map((red, index) => {
											  return (
													<div className="config-redirects-row row">
														<div className="col-12">
															<span className="text-500 text-xs">Redirect #{red.order}</span>
														</div>
														<div className="col-xs-12 col-sm-6 pr-10 redirects-section" style={{ position: 'relative' }}>

															{/* Re-ordering buttons */}

															<div className="reorder pb-5 text-end padding-sm">
																{red.order > 1 &&
																	<OverlayTrigger
																		trigger={['hover', 'focus']}
																		placement="top"
																		overlay={redirectOrderPopover(true, red.order)}
																	>
																		<Button variant="transparent" size="xs" className="move-up no-shadow brad-0 text-lighter less-pad" onClick={() => this.props.handleReOrderTimeslotRedirect({ isMoveUp: true, position: red.order })}>
																			<span className="icon icon-arrow-up8"></span>
																		</Button>
																	</OverlayTrigger>
																}

																{red.order < this.props.redirectCount &&
																	<OverlayTrigger
																		trigger={['hover', 'focus']}
																		placement="top"
																		overlay={redirectOrderPopover(false, red.order)}
																	>
																		<Button variant="transparent" size="xs" className="move-down no-shadow brad-0 text-lighter less-pad" onClick={() => this.props.handleReOrderTimeslotRedirect({ isMoveUp: false, position: red.order })}>
																			<span className="icon icon-arrow-down8"></span>
																		</Button>
																	</OverlayTrigger>
																}

																<Button variant="transparent" size="xs" className="no-shadow brad-0 text-lighter less-pad"
																	onClick={() => this.handleRemoveRedirect(red, index)} id={`DeleteToDRedirect${index + 1}Button`}>
																	<span className="icon icon-bin" id={`DeleteRedirectIcon${index + 1}`}></span>
																</Button>

															</div>

															{/* End re-ordering */}

															<Form.Group>
																<InputGroup className={`${this.props.validation && this.props.validation.timeslotRedirects && this.props.validation.timeslotRedirects.length > index && this.props.validation.timeslotRedirects[index].valid === validationStates.INVALID ? 'has-error' : ''}`}>
																	<input type='tel' className="form-control text-400" maxLength='16' id={`ToDRedirectNumber${index + 1}`}
																		onBlur={e => +this.handleCheckValidation() + red.redirectType === RedirectType.PhoneNumber
																		  ? this.props.validateRedirect({
																		    redirect: red.redirectDetail,
																		    position: index,
																		    redirectCount: this.props.dayTimeRouting.redirects.length,
																		    redirectType: red.redirectType,
																		    redirectExceptions: this.state.redirectExceptions
																		  })
																		  :																			this.props.validateRedirect({
																		    position: index,
																		    redirectCount: this.props.dayTimeRouting.redirects.length,
																		    redirectType: red.redirectType,
																		    itemId: red.redirectDetail
																		  })
																		}
																		onChange={e => this.props.changeRedirectNumber({
																		  newNumber: e.target.value,
																		  position: index,
																		  itemType: RedirectType.PhoneNumber,
																		  isTimeSlot: true
																		})}
																		value={red.redirectType === RedirectType.PhoneNumber
																		  ? red.redirectDetail
																		  : red.redirectType === RedirectType.Endpoint ? red.redirectName : red.redirectName}
																	/>

																	<RedirectNumberDropdown number={red} isTimeSlot={true} position={index} />
																</InputGroup>
															</Form.Group>

														</div>
														<div className="float-start text-center pt-10 pl-xs-20 pr-xs-20" style={{ width: '30px' }}>
															<p>for</p>
														</div>

														<div className="col-xs-8 col-sm-4 pt-0 pl-10">
															<Select
																className="form-control select-menu mb-10"
																classNamePrefix="custom"
																simpleValue
																options={redirectDurationOptions}
																value={redirectDurationOptions.filter(f => f.value === red.dialDuration)}
																searchable={false}
																clearable={false}
																onChange={e => this.props.handleUpdateTimeSlotRedirectSeconds({ index, value: e.value })}
																id={`ToDRedirectNumberDuration${index + 1}`}
															/>
														</div>

													</div>

											  )
											})}
										</div>
										{!this.props.dayTimeRouting || (this.props.dayTimeRouting && this.props.dayTimeRouting.redirects && this.props.dayTimeRouting.redirects.length !== RedirectLimit) &&
											<div className="row">
												<div className="col-xs-12 pt-10">
													<p className="text-primary text-500 text-sm cursor-pointer m-0 d-inline-block" onClick={() => this.props.handleAddRedirect() + this.handleCheckValidation()}>
														<span className="icon icon-plus-circle2 mr-10"></span><span className="underline" id="AddAnotherRedirectToDRedirectsLink">Add another redirect</span>
													</p>
												</div>
											</div>
										}
										{this.props.validation && this.props.validation.timeslotRedirects && !_.isEmpty(this.props.validation.timeslotRedirects) && this.props.validation.timeslotRedirects.some(s => s.valid === validationStates.INVALID) &&
											<div className="row">
												<div className="col-xs-12">
													<p className="text-warning text-400 text-sm mt-20">
														Please check your redirects.
													</p>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						}
					</div>
				</Modal.Body>
				<Modal.Footer className="text-center">
					{this.state.isLoading
					  ? <LoadingNotification loadingText={'Loading...'} isLoading={true} height="50" width="50" />
					  :						<Button id="SaveAddTimeSlotButton" variant="cta" onClick={() => this.handleCheckValidation() + this.handleSaveChanges()} disabled={!this.props.enableTimeslotSave || (this.props.dayTimeRouting && this.props.dayTimeRouting.redirects && this.props.dayTimeRouting.redirects.length === 0) || (this.props.dayTimeRouting && this.props.dayTimeRouting.redirects && this.props.dayTimeRouting.redirects.some(s => s.redirectDetail === ''))}>
							{this.state.editTimeSlot ? 'Save Changes' : 'Add Time Slot'}
						</Button>
					}
				</Modal.Footer>
			</Modal>
		</>
  }

  handleEditTimeslot (slot, i) {
    this.setState({ editTimeSlot: true }, () => {
      this.props.handleResetTimeSlotRedirectValidation({ length: slot.redirects.length })
      this.props.handleEditTimeSlot({ slot, index: i })
      this.props.handleShowModal()
    })
  }

  handleAddNewTimeSlot () {
    this.props.handleShowModal()
    this.setState({ validationChecked: false, editTimeSlot: false })
    this.props.addNewSlot({ queue: this.props.queueNo, dept: this.props.dept })
  }

  validateTimeslotTime () {
    const obj = this.props.dayTimeRouting
    const url = 'api/Configuration/ValidateTimeSlotTimes'
    const data = {
      Mon: obj.mon,
      Tue: obj.tue,
      Wed: obj.wed,
      Thur: obj.thur,
      Fri: obj.fri,
      Sat: obj.sat,
      Sun: obj.sun,
      StartTime: obj.startTime,
      EndTime: obj.endTime,
      QueueNo: this.props.queueNo,
      Dept: this.props.dept
    }

    fetchHelper.postJson(url, data)
      .then(res => {
        this.setState({ showTimeWarning: !res.data })
      }).catch(err => console.error(err))

    this.props.validateTimeSlot({ slot: this.props.dayTimeRouting })
  }

  handleToggleTimeSlotDay (obj) {
    const day = obj.day
    const enabled = obj.enabled

    const slot = this.props.dayTimeRouting
    switch (day) {
      case 'mon':
        slot.mon = enabled
        break
      case 'tue':
        slot.tue = enabled
        break
      case 'wed':
        slot.wed = enabled
        break
      case 'thur':
        slot.thur = enabled
        break
      case 'fri':
        slot.fri = enabled
        break
      case 'sat':
        slot.sat = enabled
        break
      case 'sun':
        slot.sun = enabled
        break
    }

    this.props.handleUpdateDayTimeSlotDays({ slot })
    this.validateTimeslotTime()
  }

  handleChangeTimeSlotTime (obj) {
    const slot = this.props.dayTimeRouting

    if (obj.type === 'start') {
      slot.startTime = obj.time
    } else {
      slot.endTime = obj.time
    }

    this.props.handleChangeTimeSlotTime({ slot })
  }

  handleRemoveRedirect (redirect, i) {
    if (!redirect.id) {
      this.props.handleRemoveRedirectFromTimeSlot({ index: i })
    } else {
      const url = `api/Configuration/RemoveRedirectFromTimeslot/${redirect.id}`
      fetchHelper.postJson(url)
        .then(res => {
          if (res.data) {
            // Good
            this.props.handleRemoveRedirectFromTimeSlot({ index: i })
            this.props.notify({
              message: 'Your redirect has been removed'
            })
          } else {
            // No good
            this.props.notify({
              message: 'There was a problem removing your redirect. Please try again',
              isError: true
            })
          }
        }).catch(err => console.error(err))
    }
  }

  handleRemoveTimeslot (slotId, index) {
    this.setState({ validationChecked: false }, () => {
      if (slotId) {
        const url = `api/Configuration/RemoveCallRoutingSlot/${slotId}`
        fetchHelper.postJson(url)
          .then(res => {
            if (res.data) {
              // Good
              this.props.handleRemoveTimeSlot({ index })
              this.props.notify({
                message: 'Your time slot has been removed'
              })
            } else {
              // No good
              this.props.notify({
                message: 'There was a problem removing your slot. Please try again',
                isError: true
              })
            }
          })
      } else {
        this.props.handleRemoveTimeSlot({ index })
      }
    })

    this.props.removeTimeSlotValidation({ index })
  }

  handleCheckValidation () {
    if (this.props.dayTimeRouting) {
      this.validateTimeslotTime()
      this.props.validateTimeSlot({ slot: this.props.dayTimeRouting })

      if (this.props.dayTimeRouting && this.props.dayTimeRouting.redirects && this.props.dayTimeRouting.redirects.length > 0) {
        this.props.dayTimeRouting.redirects.map((red, index) => {
          if (red.redirectType === RedirectType.PhoneNumber) {
            this.props.validateRedirect({
              redirect: red.redirectDetail,
              position: index,
              redirectCount: this.props.dayTimeRouting.redirects.length,
              redirectType: red.redirectType,
              isTimeSlot: true
            })
          } else {
            this.props.validateRedirect({
              position: index,
              redirectCount: this.props.dayTimeRouting.redirects.length,
              redirectType: red.redirectType,
              itemId: red.redirectDetail,
              isTimeSlot: true
            })
          }
        })
      }

      this.setState({ validationChecked: true })
    }
  }

  handleSaveChanges () {
    // Validate
    this.setState({ isLoading: true, canProceed: true }, () => {
      this.handleCheckValidation()

      if (this.props.validation.timeslot) {
        if (_.isEmpty(this.props.validation.timeslot) || this.props.validation.timeslot.valid === validationStates.INVALID) { this.setState({ canProceed: false }) }

        if (this.props.validation.timeslotRedirects) {
          this.props.validation.timeslotRedirects.map(red => {
            if (red.valid === validationStates.INVALID) { this.setState({ canProceed: false }) }
          })
        }
      } else {
        this.setState({ canProceed: false })
      }

      if (this.props.validation.timeslotTimes && this.props.validation.timeslotTimes.valid === validationStates.INVALID) { this.setState({ canProceed: false }) }

      setTimeout(() => {
        if (this.state.canProceed) {
          const url = 'api/Configuration/AddOrUpdateCallRoutingSlots'
          fetchHelper.postJson(url, this.props.dayTimeRouting)
            .then(res => {
              this.setState({ isLoading: false, editTimeSlot: false })
              if (res.data > 0) {
                // Good
                this.props.handleUpdateConfigTimeslots({ timeslot: this.props.dayTimeRouting, newId: res.data })
                this.props.handleHideModal()
                this.props.notify({
                  message: 'Your settings have been saved.'
                })
              } else {
                // No good
                this.props.notify({
                  message: 'There was a problem saving your settings. Please try again',
                  isError: true
                })
              }
            })
        } else {
          this.props.notify({
            message: 'There was a problem saving your settings. Please try again',
            isError: true
          })
          this.setState({ isLoading: false })
        }
      }, 1000)
    })
  }

  handleCloseModal () {
    this.props.handleHideModal()
    this.props.validateTimeSlot(undefined)
  }
}

export default connect(
  state => {
    return {
      validation: state.configuration.validation,
      redirectsHistory: state.configuration.redirects.redirectsHistory,
      outboundUsers: state.configuration.redirects.outboundUsers,
      ringGroups: state.configuration.redirects.ringGroups,
      isDisplayed: state.configuration.dialogVisibility.dayTimeRoutingDisplayed,
      dayTimeRouting: state.configuration.configuration.dayTimeRouting,
      enableTimeslotSave: state.configuration.configuration.enableTimeslotSave,
      redirectUpdated: state.configuration.configuration.redirectUpdated
    }
  }, {
    handleShowModal: configurationActions.handleShowDayTimeRouting,
    handleHideModal: configurationActions.handleHideDayTimeRouting,
    initRouting: configurationActions.handleInitDaytimeRouting,
    addNewSlot: configurationActions.handleAddTimeSlot,
    handleToggleTimeSlotDay: configurationActions.handleToggleTimeSlotDay,
    handleChangeTimeSlotTime: configurationActions.handleChangeTimeSlotTime,
    handleRemoveTimeSlot: configurationActions.handleRemoveTimeSlot,
    handleAddRedirect: configurationActions.handleAddRedirectToTimeSlot,
    handleReOrderTimeslotRedirect: configurationActions.handleReOrderTimeslotRedirect,
    changeRedirectNumber: configurationActions.changeRedirectNumber,
    validateRedirect: configurationActions.validateRedirect,
    validateTimeSlot: configurationActions.validateTimeSlot,
    removeTimeSlotValidation: configurationActions.removeTimeSlotValidation,
    handleRemoveRedirectFromTimeSlot: configurationActions.handleRemoveRedirectFromTimeSlot,
    handleUpdateTimeSlotRedirectSeconds: configurationActions.handleUpdateTimeSlotRedirectSeconds,
    handleRedirectsChecked: configurationActions.handleRedirectsChecked,
    handleUpdateDayTimeSlotDays: configurationActions.handleUpdateDayTimeSlotDays,
    handleUpdateConfigTimeslots: configurationActions.handleUpdateConfigTimeslots,
    handleResetTimeSlotRedirectValidation: configurationActions.handleResetTimeSlotRedirectValidation,
    handleEditTimeSlot: configurationActions.handleEditTimeSlot,
    notify: handleNotificationChange
  }
)(DayTimeRedirects)
