import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import LoadingNotification from '../components/LoadingNotification'
import { CarrierIds, is0800Offer, UnlimPlanDetails } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
const _ = require('lodash')

export default class PlansAndNumbersDropdown extends Component {
  state = {
    showPlans: false,
    showNumbers: false
  }

  /// ////////////////////////////////////////////////////////////////
  //
  //   Example usage:
  //
  //    <PlansAndNumbersDropdown
  //        prefix="01"
  //        selectPackage="{package object}"
  //        WrapperClassName="mb-40"
  //        PlansButtonClassName="text-start bg-light pl-25 pr-25 b-0"
  //        NumberButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
  //        LabelsClassName="text-xs text-dark text-400"
  //        NumberNameClassName="text-dark text-400"
  //        PlanNameClassName="text-dark text-400"
  //        PlansResultsDropdownClassName="bg-light box-shadow"
  //        NumbersResultsDropdownClassName="bg-light ml-5 box-shadow"
  //        PlanNameClassNameSelected="text-primary text-500"
  //        NumberNameClassNameSelected="text-cta text-500"
  //    />
  //
  /// ////////////////////////////////////////////////////////////////

  render () {
    const numberTypes = [
      {
        numberType: '01',
        title: '01/02 Local'
      },
      {
        numberType: '0203',
        title: '0203 London'
      },
      {
        numberType: '0207',
        title: '0207 London'
      },
      {
        numberType: '03',
        title: '03 National'
      },
      {
        numberType: '0800',
        title: '0800 Freephone'
      },
      {
        numberType: '0808',
        title: '0808 Freephone'
      }
    ]

    return (
			<>
				<div className={`plans-numbers-dropdown pb-10 text-start ${this.props.WrapperClassName} ${this.props.prefix === '01' ? 'local-numbers' : ''}`}>

					{(this.props.inboundOnly !== true && this.props.hidePlans !== true) &&
						<Button className={`plan-type-btn box-shadow shadow-lighter ${this.props.PlansButtonClassName}`} onClick={() => this.setState({ showPlans: !this.state.showPlans, showNumbers: false, showAreaCodes: false })}>
							<span className={`block ${this.props.LabelsClassName}`}>Plan</span>
							<span className={this.props.PlanNameClassNameSelected}>{this.props.selectedPackage && this.state.packages ? this.state.packages.find(f => f.packageId === this.props.selectedPackage.packageId).packageName : 'Please choose'} {this.props.selectedPackage && this.props.selectedPackage.packageId !== 2 ? 'VoIP' : ''}</span>
							<span className={`${this.props.IconClassName ? this.props.IconClassName + ' icon float-end ' : ' icon icon-arrow-down3 float-end text-dark-light'}`}></span>
						</Button>
					}

					<Button className={`number-type-btn box-shadow shadow-lighter ${this.props.NumberButtonClassName}`} onClick={() => this.setState({ showNumbers: !this.state.showNumbers, showPlans: false, showAreaCodes: false })} id="NumberTypeDropdown">
						<span className={`block ${this.props.LabelsClassName}`}>Number Type</span>
						<span className={this.props.NumberNameClassNameSelected}>{this.props.prefix ? numberTypes.find(f => f.numberType === this.props.prefix).title : ''}</span>
						<span className={`${this.props.IconClassName ? this.props.IconClassName + ' icon float-end ' : ' icon icon-arrow-down3 float-end text-dark-light'}`}></span>
					</Button>

					{this.props.prefix === '01' &&
						<Button className={`area-code-btn box-shadow shadow-lighter ${this.props.AreaCodeButtonClassName}`} onClick={() => this.setState({ showAreaCodes: !this.state.showAreaCodes, showPlans: false, showNumbers: false })} id="AreaCodeDropdown">
							{this.state.loadingRegions
							  ? <LoadingNotification loadingText={'Loading...'} textColor="text-dark" isLoading={true} width={30} height={30} />
							  : <>
									<span className={`block ${this.props.LabelsClassName}`}>Area Code</span>
									<span className={this.props.AreaCodeNameClassNameSelected}>
                    {this.props.ddiLid && this.state.regions ?
                      this.state.regions.find(f => f.value === this.props.ddiLid).label :
                      this.props.defaultArea && this.props.defaultArea.length > 2 ?
                        this.state.regions && this.state.regions.find(f => f.label.includes(this.props.defaultArea)).label :
                        'Select area'
                    }
									</span>
									<span className={`${this.props.IconClassName ? this.props.IconClassName + ' icon float-end ' : ' icon icon-arrow-down3 float-end text-dark-light'}`}></span>
								</>
							}
						</Button>
					}

					<div className="dropdowns-wrapper">
						{(this.props.inboundOnly !== true && this.props.hidePlans !== true) &&
							<div style={{ opacity: this.state.showPlans ? '1' : '0', visibility: this.state.showPlans ? 'visible' : 'hidden' }} className={`plans-dropdown-results ${this.props.PlansResultsDropdownClassName}`}>
							{this.state.packages && this.state.packages.length > 0 && this.state.packages.map((pack, i) =>
							  (!this.props.prefix.startsWith('08') && pack.numberType === '0800')
							    ? ''
							    : <span
									className={`block pl-25 pr-25 pt-10 pb-10 cursor-pointer ${this.props.PlanNameClassName} ${this.props.selectedPackage && this.props.selectedPackage.packageId === pack.packageId ? 'selected' : ''}`}
									onClick={() => this.handleChangePlan(pack, i)}>
										{`${pack.packageName} ${pack.packageId !== 2 ? 'VoIP' : ''}`}
									</span>
							)}
							</div>
						}

						<div style={{ opacity: this.state.showNumbers ? '1' : '0', display: this.state.showNumbers ? 'block' : 'none' }} className={`numbers-dropdown-results ${this.props.NumbersResultsDropdownClassName}`} id={this.state.showNumbers ? "NumberTypeDropdownOpened" : "NumberTypeDropdownClosed"}>
							{this.props.displayAll
							  ? numberTypes.map((item, i) =>
										<span
											className={`block pl-25 pr-25 pt-10 pb-10 cursor-pointer ${this.props.NumberNameClassName} ${this.props.prefix === item.numberType ? 'selected' : ''}`}
											onClick={() => this.handleChangeNumberType(item)}
											id={`${item.numberType}NumberDropdown`}>
											{item.title}
										</span>
							  )
							  : !this.props.prefix.startsWith('08')
							      ? numberTypes.filter(f => !f.numberType.startsWith('08')).map((item, i) =>
										<span
											className={`block pl-25 pr-25 pt-10 pb-10 cursor-pointer ${this.props.NumberNameClassName} ${this.props.prefix === item.numberType ? 'selected' : ''}`}
											onClick={() => this.handleChangeNumberType(item)}>
											{item.title}
										</span>
							      )
							      : numberTypes.filter(f => f.numberType.startsWith('08')).map((item, i) =>
									<span
										className={`block pl-25 pr-25 pt-10 pb-10 cursor-pointer ${this.props.NumberNameClassName} ${this.props.prefix === item.numberType ? 'selected' : ''}`}
										onClick={() => this.handleChangeNumberType(item)}>
										{item.title}
									</span>
							      )
							}
						</div>

						{this.props.prefix === '01' &&
							<div style={{ opacity: this.state.showAreaCodes ? '1' : '0', display: this.state.showAreaCodes ? 'block' : 'none' }} className={`area-code-dropdown-results ${this.props.AreaCodeResultsDropdownClassName}`}>
								{this.state.regions && this.state.regions.length > 0 && this.state.regions.map((region, i) =>
									<span
										id={`AreaId${region.value}`}
										className={`block pl-25 pr-25 pt-10 pb-10 cursor-pointer ${this.props.AreaCodeNameClassName} ${this.props.ddiLid === region.value ? 'selected' : ''}`}
										onClick={() => this.handleChangeAreaCode(region)}>{region.label}</span>
								)}
							</div>
						}

					</div>
				</div>
			</>
    )
  }

  handleChangePlan(pack, i) {
    if (this.props.hidePlans === true) return;

    const context = this
    const packData = {
      name: pack.packageName,
      seconds: (pack.inboundSeconds.landlineSeconds + pack.inboundSeconds.mobileSeconds) + (pack.outboundSeconds.landlineSeconds + pack.outboundSeconds.mobileSeconds),
      rental: pack.packageName.toLowerCase().includes('unlimited') && UnlimPlanDetails.UnlimDiscount === true ? pack.offerPrice : pack.numberType === '0800' && is0800Offer ? pack.offerPrice : pack.monthlyPrice,
      fullPrice: pack.monthlyPrice,
      packageId: pack.packageId,
      packageIndex: i,
      numberType: pack.numberType,
      users: {
        qty: 1,
        monthlyPrice: 0
      }
    }

    context.setState({ showPlans: !this.state.showPlans }, () => {
      context.props.handleChangePackage(packData)
    })
  }

  handleChangeNumberType (type) {
    const context = this
    context.setState({ showNumbers: !this.state.showNumbers }, () => {
      context.props.handleChangeNumberType(type.numberType)
    })
  }

  handleChangeAreaCode (area) {
    const context = this
    context.setState({ showAreaCodes: false }, () => {
      context.props.handleChangeAreaCode(area)
    })
  }

  componentDidMount () {
    const context = this

    if (this.props.inboundOnly !== true || this.props.hidePlans) {
      fetchHelper.getJson('/api/Purchase/GetPackages/')
        .then(res => {
          const packages = res.data.packages
          context.setState({ packages }, () => {
            if (!this.props.basket || (this.props.basket && _.isEmpty(this.props.basket.package))) {
              const plan = packages && packages.length > 1 ? packages[1] : packages[0]
              if (!this.props.preventDefaultSelection) {
                context.handleChangePlan(plan)
              }
            }
          })
        })
        .catch(err => console.error(err))

      context.setState({ loadingRegions: true })
    }

    fetchHelper.postJson('/api/Purchase/GetGeoRegions', { CarrierIds }).then(res => {
      context.setState({ loadingRegions: false, regions: res.data.regions })
    }).catch(function (error) {
      console.error(error)
    })
  }
}
