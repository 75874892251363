import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'react-router-dom'

export default class Cookies extends Component {
	state = {
		showModal: false,
		analyticsCookies: true,
		marketingCookies: true
	}

	render() {
		return (
			<>
				<CookieConsent
					buttonId="AcceptAllCookiesButton"
					declineButtonId="CookieSettingsButton"
					location="bottom"
					buttonText="Accept cookies"
					declineButtonText="Settings"
					cookieName="AcceptCookies"
					expires={150}
					buttonWrapperClasses="container w-1200px"
					enableDeclineButton
					buttonClasses="btn btn-cta btn-sm float-start mr-20"
					declineButtonClasses="btn btn-outline btn-sm"
					disableStyles={true}
					setDeclineCookie={false}
					onAccept={() => {
						this.handleSave(true)
					}}
					onDecline={() => {
						// Open cookie settings modal
						this.setState({ showModal: true })
					}}
				>
					<div className="container w-1200px">
						<p className="text-sm mb-20">
							We use cookies to run our website, analyze your use of our services, manage your online preferences &amp; personalize ad content. By accepting our cookies, you'll get relevant content and social media features, personalized ads, and an enhanced browsing experience on our website. To manage your choices, click "Cookie Settings." Necessary cookies are required for the core website functionality and cannot be rejected. For more information, see our <Link to="/cookie-policy" className="text-cta underline">cookie policy</Link>.
						</p>
					</div>
				</CookieConsent>

				<Modal
					size="sm"
					show={this.state.showModal}
					onHide={() => this.setState({ showModal: false })}
				>
					<Modal.Body>
						<p className="text-500 text-lg">About cookies on this site</p>
						<p>
							Cookies used on this site are categorised and below you can read about each category and allow or deny some or all of them, except for Necessary Cookies which are required to provide core website functionality. When categories that have been previously allowed are disabled, all cookies assigned to that category will be removed from your browser.
						</p>
						<p><Link to="/cookie-policy" className="text-cta underline" onClick={() => this.setState({ showModal: false })}>View our cookie policy</Link></p>
						<Button variant="cta" size="sm" className="mr-20" onClick={() => this.handleSave(true)}>Allow all cookies</Button>
						<Button variant="outline" size="sm" onClick={() => this.handleSave(false)}>Deny all</Button>
						<div className="mt-30">
							<div className="row">
								<div className="col-2">
									<Toggle
										on=""
										off=""
										size="sm"
										offstyle="default"
										onstyle="success"
										width="null"
										height="null"
										className="toggle-sm toggle-secondary"
										active={true}
									/>
								</div>
								<div className="col-10">
									<p className="text-500 mb-10">Necessary cookies</p>
									<p>
										Some cookies are required to provide core website functionality. The website won't function properly without these cookies and they are enabled by default and cannot be disabled.
									</p>
								</div>
							</div>

							<div className="row mt-20">
								<div className="col-2">
									<Toggle
										on=""
										off=""
										size="sm"
										offstyle="default"
										onstyle="success"
										width="null"
										height="null"
										className="toggle-sm toggle-secondary"
										active={this.state.analyticsCookies}
										onClick={(e) => this.setState({ analyticsCookies: !this.state.analyticsCookies })}
									/>
								</div>
								<div className="col-10">
									<p className="text-500 mb-10">Analytical cookies</p>
									<p>
										Analytical cookies help us improve our website by collecting and reporting information on its usage.
									</p>
								</div>
							</div>

							<div className="row mt-20">
								<div className="col-2">
									<Toggle
										on=""
										off=""
										size="sm"
										offstyle="default"
										onstyle="success"
										width="null"
										height="null"
										className="toggle-sm toggle-secondary"
										active={this.state.marketingCookies}
										onClick={(e) => this.setState({ marketingCookies: !this.state.marketingCookies })}
									/>
								</div>
								<div className="col-10">
									<p className="text-500 mb-10">Marketing cookies</p>
									<p>
										Marketing cookies are used to track visitors across websites to allow publishers to display relevant and engaging advertisements.
									</p>
								</div>
							</div>

						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success" size="sm" className="mr-20" onClick={() => this.handleSave()}>Save cookie settings</Button>
					</Modal.Footer>
				</Modal>

			</>
		)
	}

	handleSave(acceptAll) {
		if (acceptAll === false) {
			document.cookie = 'AcceptCookies=false; max-age=86400; path=/;'
		} else if (acceptAll === true || (acceptAll !== true && (this.state.marketingCookies === true || this.state.analyticsCookies === true))) {
			document.cookie = 'AcceptCookies=true; max-age=86400; path=/;'
		} else {
			document.cookie = 'AcceptCookies=false; max-age=86400; path=/;'
		}

		const consentMode = {
			'functionality_storage': acceptAll ? 'granted' : 'denied',
			'security_storage': acceptAll ? 'granted' : 'denied',
			'ad_storage': acceptAll || this.state.marketingCookies ? 'granted' : 'denied',
			'analytics_storage': acceptAll || this.state.analyticsCookies ? 'granted' : 'denied',
			'personalization': acceptAll || this.state.marketingCookies ? 'granted' : 'denied',
			'personalization_storage': acceptAll || this.state.marketingCookies ? 'granted' : 'denied',
		};

		gtag('consent', 'update', consentMode);
		localStorage.setItem('consentMode', JSON.stringify(consentMode));

		this.setState({ showModal: false })
	}
}
