import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import NumberLandingView from '../../components/NumberTypes/NumberLandingView'
import NumberTypeFeatures from '../../components/NumberTypes/NumberTypesFeaures'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import { productBillingOption, ProductIds } from '../../constants/Constants'
import { getProductPrice } from '../../store/Home'

class Numbers084 extends React.Component {
  static defaultProps = {}

  static propTypes = {}

  state = {}

  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.getProdDetails()
  }

  getProdDetails () {
    this.props.getProductDetails([
      { productId: ProductIds.callRecordSingle, monthlyOption: productBillingOption.callRecordingAllBillingOptionMonthly },
      { productId: ProductIds.umr, monthlyOption: productBillingOption.umrBillingOptionMonthly },
      { productId: ProductIds.endpoint, monthlyOption: productBillingOption.endpointBillingOptionMonthly }
    ])
  }

  render () {
    const umrPayment = { firstPayment: 0, repeatPayment: 0 }
    if (this.props.productDetails) {
      const umrPaymentDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.umr)
      if (umrPaymentDetails) {
        umrPayment.firstPayment = umrPaymentDetails.monthlyPrice.firstPaymentAmount
        umrPayment.repeatPayment = umrPaymentDetails.monthlyPrice.repeatPaymentAmount
      }
    }

    return <>
            <ScrollToTopOnMount /> <DisplayMainFooter /> <DisplayMainHeader />
            {/* <NumbersMenu /> */}
            <Helmet>
                <title>Buy 0844 Numbers</title>
                <meta name="description"
                    content="Get an 0844 business phone numbers here! Instant online activation, includes the UK's favourite call management system." />
            </Helmet>

            <NumberLandingView
                h1="0844 Phone Numbers"
                h2="Enjoy a national presence with 0844 phone numbers for your business."
                numberType={'0844'}
                history={this.props.history}
            />

            <div className="full-width bg-light">
                <div className="container pl-xxs-30 pr-xxs-30">
                    <div className="row">
                        <div className="col-lg-7 col-md-10 mx-auto">
                            <p className="text-md pt-30 pt-xs-0 text-xs-sm text-xs-left pb-30">
                                An 084 number will give your business a national presence and ensure you never miss a call.
                                For just &pound;{umrPayment.repeatPayment} per month you can redirect unlimited calls to VoIP user(s), UK mobiles, UK landlines or teams.
                            </p>
                            <p>
                                <ul className="unstyled ml-0 pl-0">
                                    <li>
                                        <span className="text-cta icon icon-checkmark mr-10"></span> Up to 22% increase in calls locally
                                            </li>
                                    <li>
                                        <span className="text-cta icon icon-checkmark mr-10"></span> Trusted local numbers
                                            </li>
                                    <li>
                                        <span className="text-cta icon icon-checkmark mr-10"></span> Over 40 powerful free features
                                            </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <NumberTypeFeatures history={this.props.history} />

            <div className="full-width bg-light pt-50 pb-50">
                <div className="container">
                    <div className="row text-center features-list">
                        <div className="col-sm-4 pb-sm-30 br-1 br-sm-0">
                            <div className="text-center img-circle bg-dull block center-block" style={{ width: '100px', height: '100px' }}>
                                <SvgRender icon="apps" svgWidth="50px" svgClassName="mt-25" />
                            </div>
                            <p className="pt-20">Manage your calls on the move with <br className="d-block d-sm-none d-lg-block" />the free SwitchboardFREE app.</p>
                        </div>
                        <div className="col-sm-4 pb-sm-30 br-1 br-sm-0">
                            <div className="text-center img-circle bg-dull block center-block" style={{ width: '100px', height: '100px' }}>
                                <SvgRender icon="reporting-features" svgWidth="50px" svgClassName="mt-25" />
                            </div>
                            <p className="pt-20">Grow faster, with over 40 professional <br className="d-block d-sm-none d-lg-block" />free call handling features.</p>
                        </div>
                        <div className="col-sm-4 pb-sm-30">
                            <div className="text-center img-circle bg-dull block center-block" style={{ width: '100px', height: '100px' }}>
                                <SvgRender icon="config-changes" svgWidth="60px" svgClassName="mt-20" />
                            </div>
                            <p className="pt-20">Tailor your number settings to your unique <br className="d-block d-sm-none d-lg-block" />business, and manage easily 24/7.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull pt-50">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>

            {/* Logoos */}
            <div className="full-width bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500">Partners &amp; Customers</h3>
                            {/* <h6>The UK's favourite call management system</h6> */}
                            <p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
                        </div>
                    </div>
                </div>
            </div>
            <ClientLogos style='light' />

            {/* <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center" >
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId="XsEQiuKm418?start=25&end=82" onClose={() => this.setState({ isOpen: false })} />
                            <button onClick={(e) => this.setState({isOpen: true})} className="btn btn-secondary btn-sm btn-mobile-block mt-20 mr-10 mb-30">Watch video</button>
                        </div>
                    </div>
                </div>
                <NumberLandingView numberType={'0844'} />
            </div> */}
        </>
  }
}

export default connect(
  state => {
    return {
      productDetails: state.home.productDetails.products
    }
  },
  {
    getProductDetails: getProductPrice
  }
)(Numbers084)
