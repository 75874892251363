import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import HandsetOrders from '../../../components/leadgen/HandsetOrders'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'

class OutboundManualApp extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      activeKey: this.props.match.params.activeTab ? this.props.match.params.activeTab : 'installation',
      showHandsetOrder: false
    }
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>VoIP Phone Setup Guide</title>
                <meta name="description" content="SwitchboardFREE outbound phone - Fanvil X4U manual" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/howitworks-bg.jpg')" }} className="full-width full-hero-small with-text">
            <h1>SwitchboardFREE Phone App</h1>
            <h2 className="subtitle">Make and receive calls using our free smartphone VoIP app.</h2>
            </header>

            <div className="container-styled">
                <div className="container pb-100">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <h4 className="text-500 text-cta mt-50">Download the app (if you haven't already)</h4>
                            <p>Click one of the below images to download the app.</p>
                            <p className="text-400 text-md pb-30 text-center text-md-left">
                                <a target="_blank" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '100%', width: '230px' }} className="m-5" /></a>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '100%', width: '230px' }} className="m-5"/></a>
                            </p>

                            <h4 className="text-500 text-cta mt-50">Log in details</h4>
                            <p>In your browser <a href='https://www.switchboardfree.co.uk/login' className="text-cta underline">log into</a> your SwitchboardFREE account (if you haven't already) and go to your Outbound users page <a href='https://www.switchboardfree.co.uk/customer/outbound-management' className="text-cta underline" target="_blank" rel="noreferrer">https://www.switchboardfree.co.uk/customer/outbound-management</a>
                            </p>
                            <p>
                                Click the highlighted <span className="text-500">&quot;eye icon&quot;</span> on the user you would like the details for.
                            </p>
                            <div className="mt-30 mb-30">
                                <img src="/images/softphone/users.jpg" className="p-10 brad-10 box-shadow text-center b-2 img-responsive" />
                            </div>
                            <p>
                                After that, you will see your login details which you will need to enter in the apps login screen.
                            </p>
                            <div className="mt-30 mb-30 text-center">
                                <img src="/images/bria/s2.jpg" style={{ maxWidth: '500px' }} className="p-10 brad-10 box-shadow text-center b-2 img-responsive" />
                            </div>
                            <h4 className="text-500 text-cta mt-50">Blind transfer</h4>
                                <p>
                                    A blind transfer is when you transfer the caller to another users extension without speaking to them first.
                                </p>
                                <p className="text-500 pt-10 pb-20">
                                    How to perform a blind transfer on your:<br/>deskphone, softphone and SwitchboardFREE app
                                </p>
                                <ol className="text-400 pl-15">
                                    <li className="pb-10">
                                        <span className="text-500">Softphone:</span>
                                        <br/>
                                        Press the transfer button <span className="text-500">or</span> press #7,
                                        followed by the extension number you wish to transfer the call to.
                                    </li>
                                    <li className="pb-10">
                                        <span className="text-500">Desk phone:</span>
                                        <br/>
                                        Press #7, followed by the extension number you wish to transfer the call to.
                                    </li>
                                    <li className="pb-10">
                                        <span className="text-500">SwitchboardFREE Phone App: </span>
                                        <br/>
                                        Press #7, followed by the extension number you wish to transfer the call to.
                                    </li>
                                </ol>
                                <p>
                                If a call is not answered by the final recipient in 10 seconds then call will return to the original recipient.
                                </p>

                                <h4 className="text-500 text-cta mt-50">An Assisted transfer</h4>
                                <p>
                                    An assisted transfer is when you transfer the caller to another users extension,
                                    but before the call is put through, you will be able to speak to the other user before transfering the caller to them. This is useful to make a polite introduction.
                                </p>
                                <p className="text-500 pt-10 pb-20">
                                    How to perform a assisted transfer on your:<br/>Softphone, Desk phone &amp; SwitchboardFREE Phone App
                                </p>
                                <ol className="text-400 pl-15">
                                    <li className="pb-10">
                                        Press #8, followed by the extension number of the user you wish to dial.
                                    </li>
                                    <li className="pb-10">
                                        You can then speak to that person before connecting the caller to them.

                                    </li>
                                    <li className="pb-10">
                                        Hang up the call to connect the two parties.
                                    </li>
                                </ol>
                                <p>
                                If a call is not answered by the final recipient in 10 seconds then call will return to the original recipient.
                                </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width bg-dull">
                <div className="container">
                    <div className="row text-center mb-30 mt-30">
                        <div className="col-md-8 mx-auto">
                            <div className="row">
                                <div className="col-xs-12 mb-30">
                                    <h3 className="text-500">Other devices manuals</h3>
                                </div>
                                <div className="col-xs-4 col-xxs-12 mb-20">
                                    <Link to={'/setup/deskphone'}>
                                        <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                                            <img src="/images/product-shots/ecom/X4U.jpg" className="img-responsive" />
                                            <h5 className="text-500 text-sm-md">Deskphone</h5>
                                            <Link to={'/setup/deskphone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-xs-4 col-xxs-12 mb-20">
                                    <Link to={'/setup/wireless-phone'}>
                                        <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                                            <img src="/images/product-shots/ecom/W52P.jpg" className="img-responsive" />
                                            <h5 className="text-500 text-sm-md">Wireless Phone</h5>
                                            <Link to={'/setup/wireless-phone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-xs-4 col-xxs-12 mb-20">
                                    <Link to={'/setup/softphone'}>
                                        <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                                            <img src="/images/product-shots/ecom/softphone.jpg" className="img-responsive" />
                                            <h5 className="text-500 text-sm-md">PC Softphone</h5>
                                            <Link to={'/setup/softphone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <HandsetOrders showModal={this.state.showHandsetOrder} onHide={() => this.setState({ showHandsetOrder: false })} model={this.state.model} />
            <div className="full-width bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto pt-30 mb-100">
                            <div className="row">
                                <div className="col-md-12 text-center pt-30">
                                    <h3 className="text-500">Get Your HD VoIP Handset</h3>
                                    {/* <h4 className="text-cta"><span className="text-500">FROM JUST £6</span> p/month</h4> */}

                                    <h5 className="text-500 pt-10 pb-30">
                                        HASSLE-FREE Installation <span className="text-cta">•</span> FREE UK Support <span className="text-cta">•</span> UNRIVALLED Quality
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 col-xxs-12 text-center mb-xs-20">
                                    <div className="b-2 p-30 brad-10 zoom">
                                        <img src="/images/product-shots/ecom/W52P.jpg" alt="Cordless VoIP Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">Cordless VoIP Handset</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£7.50</span> p/month</p> */}
                                        <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                        <Button variant="cta" size="md" onClick={() => this.setState({ showHandsetOrder: true, model: 'Wireless' })} className="btn-xxs-block">Order Now</Button>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                                <div className="col-xs-6 col-xxs-12 text-center">
                                    <div className="b-2 p-30 brad-10 zoom">
                                        <img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">Android Touchscreen Phone</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
                                        <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                        <Button variant="cta" size="md" onClick={() => this.setState({ showHandsetOrder: true, model: 'Deskphone' })} className="btn-xxs-block">Order Now</Button>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }
}
export default connect(
  state => {
    return state
  },
  {}
)(OutboundManualApp)
