import React from 'react'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import NavItem from 'react-bootstrap/NavItem'
import { connect } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { is0203Offer, is0207Offer, is033Offer, is0800Offer, is0808Offer, isGeoOffer, isSoftphoneOffer } from '../constants/Constants'
import * as configurationActions from '../store/Configuration'

const _ = require('lodash')

const aboutNavRoutes = {
  aboutRoute: '/about-us/',
  teamRoute: '/meet-the-team/',
  contactRoute: '/contact/',
  videosRoute: '/videos/',
  faqRoute: '/faq/',
  reviewsRoute: '/reviews/'
}

const NumbersNavRoutes = {
  numbersGoldRoute: '/golden-phone-numbers/',
  numbers084Route: '/084-numbers/',
  numbers0800Route: '/0800-numbers/',
  numbers03Route: '/03-numbers/',
  numbers0203Route: '/0203-numbers/',
  numbers0207Route: '/0207-numbers/',
  numbers0208Route: '/0208-numbers/',
  numbersCityRoute: '/city-numbers/',
  numbersMultiRoute: '/multi-number-package/',
  numbersFaxRoute: '/fax-to-email',
  numbersLocalRoute: '/local-phone-numbers/',
  numbersAreaRoute: '/area-codes/',
  numbersCompareRoute: '/number-types',
  numbers0808Route: '/0808-numbers/',
  numbersTransferRoute: '/keep-my-number/'
}

const howitWorksNavRoutes = {
  voip: '/business-voip-phone-system',
  inbound: '/virtual-phone-numbers',
  general: '/how-it-works'
}

const featuresNavRoutes = {
  features: '/features',
  phones: '/phones-apps',
  apps: '/products/app/',
  phoneWireless: '/products/yealink-w73p-cordless-phone',
  featuresMobile: '/features',
  softphone: '/softphone',
  holdMusic: '/music-on-hold',
  departments: '/departments-selection',
  openClose: '/open-close-your-phone-lines',
  callScreening: '/call-screening',
  prompts: '/professional-prompts',
  alerts: '/missed-call-email-alerts',
  proPrompts: '/professional-prompts/',
  recording: '/call-recording',
  spamBlock: '/features/2',
  filtering: '/features/2',
  reporting: '/features/2',
  callAnswering: '/telephone-answering-service/',
  disaster: '/business-disaster-recovery',
  timeofday: '/time-of-day-call-routing',
  webhooks: '/webhooks',
  capsuleCrm: '/integrations/capsule-crm',
  pipedriveCrm: '/integrations/pipedrive-crm',
  freshsales: '/integrations/fresh-sales',
  hubspot: '/integrations/hubspot',
  salesforce: '/integrations/salesforce',
  zohocrm: '/integrations/zoho-crm',
  callTranscription: '/features/call-transcription',
    conversationSearch: '/features/conversation-search',
    keywordTrigger: '/features/keyword-triggers',
  tpsProtection: '/features/tps-protection',
  integationsFeaturePage: '/features/4'
}

const LoggedOutMenu = (props) => {
  return <>
        <Nav
            navbarScroll
            // style={{ maxHeight: '100px' }}
            className="me-auto my-2 my-lg-0"
            // className="nav navbar-nav"
        >
            <NavItem className='login'>
                <Link className=" text-500 nav-link" to="/login/">
                    Log In
                </Link>
            </NavItem>
            <NavItem className='signup'>
                <Link className=" text-500 nav-link" to="/get-started">
                    Sign Up
                </Link>
            </NavItem>

            <NavDropdown title="How It Works" className={'nav-pairs activeNav'.concat(_.includes(howitWorksNavRoutes, props.path) ? ' active' : '')} id="basic-nav-dropdown">
                <LinkContainer to={howitWorksNavRoutes.general}>
                    <NavDropdown.Item id="NavMenuHowItWorks"><span className="icon"><img src="/images/icons/nav/nav_dropdown_how-it-works.svg" /></span>How it works</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={howitWorksNavRoutes.voip}>
                    <NavDropdown.Item id="NavMenuVoipPhoneSystem"><span className="icon"><img src="/images/icons/nav/nav_dropdown_voip-phone-system.svg" /></span>VoIP Phone System</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={howitWorksNavRoutes.inbound}>
                    <NavDropdown.Item id="NavMenuVirtualNumbers"><span className="icon"><img src="/images/icons/nav/nav_dropdown_virtual-numbers.svg" /></span>Virtual Numbers</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>

            <NavDropdown title="Number Types" className={'nav-pairs nav-2-col numbers-menu activeNav'.concat(_.includes(NumbersNavRoutes, props.path) ? ' active' : '')} id="basic-nav-dropdown">
                <div className='row m-0'>
                <div className="col-md-6">
                    <span className="text-500 text-cta mb-20 block"><span className="icon"><img src="/images/icons/nav/nav_dropdown_number-types.svg" /></span>Number types</span>
                    <LinkContainer to={NumbersNavRoutes.numbersLocalRoute}>
                        <NavDropdown.Item id="NavMenuLocalGeoNumbers">Local geo numbers {isGeoOffer && <span className="pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: '10px', top: '-2px' }}>JUST 99p!</span>}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbers0203Route}>
                        <NavDropdown.Item id="NavMenu0203Numbers">0203 numbers {is0203Offer && <span className="pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: '10px', top: '-2px' }}>JUST 99p!</span>} </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbers0207Route}>
                        <NavDropdown.Item id="NavMenu0207Numbers">0207 numbers {is0207Offer && <span className="pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: '10px', top: '-2px' }}>JUST 99p!</span>}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbers0208Route}>
                        <NavDropdown.Item id="NavMenu0208Numbers">0208 numbers </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbers03Route}>
                        <NavDropdown.Item id="NavMenu03Numbers">03 numbers {is033Offer && <span className="pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: '10px', top: '-2px' }}>JUST 99p!</span>}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbers0800Route}>
                        <NavDropdown.Item id="NavMenu0800Numbers">0800 numbers {is0800Offer && <span className="pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: '10px', top: '-2px' }}>JUST 99p!</span>}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbers0808Route}>
                        <NavDropdown.Item id="NavMenu0808Numbers">0808 numbers {is0808Offer
                          ? <span className="pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: '10px', top: '-2px' }}>JUST 99p!</span>
                          : <span className="pl-10 pr-10 pt-3 pb-3 bg-success text-lightest brad-10" style={{ fontSize: '10px', top: '-2px' }}>NEW!</span>}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbersTransferRoute}>
                        <NavDropdown.Item id="NavMenuTransferExistingNumber">Transfer existing number</NavDropdown.Item>
                    </LinkContainer>
                </div>
                <div className="col-md-6">
                    <span className="text-500 text-cta mb-20 block"><span className="icon"><img src="/images/icons/nav/nav_dropdown_other.svg" /></span>Other</span>
                    <LinkContainer to={NumbersNavRoutes.numbersGoldRoute}>
                        <NavDropdown.Item id="NavMenuGoldNumbers">Memorable numbers <span className='icon icon-star-full text-cta text-xs'></span><span className='icon icon-star-full text-cta text-xs'></span><span className='icon icon-star-full text-cta text-xs'></span></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbersCityRoute}>
                        <NavDropdown.Item id="NavMenuUkCityNumbers">UK City numbers</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbersMultiRoute}>
                        <NavDropdown.Item id="NavMenuMultipleNumbers">Multiple numbers</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbersCompareRoute}>
                        <NavDropdown.Item id="NavMenuCompareNumbers">Compare numbers</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={NumbersNavRoutes.numbers084Route}>
                        <NavDropdown.Item id="NavMenu084Numbers">084 numbers</NavDropdown.Item>
                    </LinkContainer>
                </div>
                </div>
            </NavDropdown>

            <NavItem className='features'>
                <Link className="d-block features text-500 nav-link" to="/features">
                    Features
                </Link>
            </NavItem>

            <NavItem className='phones'>
                <Link className="d-block phones text-500 nav-link" to="/phones-apps">
                VoIP Phones
                </Link>
            </NavItem>

            <NavItem className='devices'>
                <Link className="d-block devices text-500 nav-link" to="/webhooks">
                    Integrations
                </Link>
            </NavItem>

            <NavDropdown title="Features" className={'nav-pairs nav-5-col d-none features-menu activeNav'.concat(_.includes(featuresNavRoutes, props.path) ? ' active' : '')} id="basic-nav-dropdown">
                <div className='row'>

                <div className="col pl-0 pr-0">
                    <span className="text-500 text-cta mb-20 block"><span className="icon"><img src="/images/icons/nav/nav_dropdown_apps-devices.svg" /></span>Apps &amp; Phones</span>
                    <LinkContainer to="/products/x7a-voip-phone">
                        <NavDropdown.Item id="NavMenuTouchscreenVoipHandset">Touchscreen VoIP handset</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/products/voip-phone">
                        <NavDropdown.Item id="NavMenuDesktopVoipHandset">Desktop VoIP handset</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/products/advanced-voip-phone">
                        <NavDropdown.Item id="NavMenuAdvancedVoipHandset">Advanced VoIP handset</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/products/yealink-w73p-cordless-phone">
                        <NavDropdown.Item id="NavMenuWirelessVoipHandset">Cordless VoIP handset</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/products/fanvil-wifi-dongle">
                        <NavDropdown.Item id="NavMenuWifiDongle">USB Wi-Fi Dongle</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.apps}>
                        <NavDropdown.Item id="NavMenuMobileApps">Mobile apps</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/products/softphone">
                        <NavDropdown.Item id="NavMenuSoftphone">Softphone {isSoftphoneOffer && <span className="pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: '10px', top: '-2px' }}>Free</span>}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.phones}>
                        <NavDropdown.Item className="text-muted text-500" id="NavMenuDiscoverMore">Discover more</NavDropdown.Item>
                    </LinkContainer>
                </div>
                <div className="col pl-0 pr-0">
                    <span className="text-500 text-cta mb-20 block"><span className="icon"><img src="/images/icons/nav/nav_dropdown_free-features.svg" /></span>Free features</span>
                    <LinkContainer to={featuresNavRoutes.holdMusic}>
                        <NavDropdown.Item id="NavMenuOnHoldMuisc">On-hold music</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.departments}>
                        <NavDropdown.Item id="NavMenuMultipleDepartments">Multiple departments</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.openClose}>
                        <NavDropdown.Item id="NavMenuOpenCloseTimes">Open &amp; close times</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.disaster}>
                        <NavDropdown.Item id="NavMenuDisasterRecovery">Disaster recovery</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.timeofday}>
                        <NavDropdown.Item id="NavMenuTimeOfDayRouting">Time of day routing </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.callScreening}>
                        <NavDropdown.Item id="NavMenuCallScreening">Call screening</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.prompts}>
                        <NavDropdown.Item id="NavMenuCustomPrompts">Custom prompts</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.alerts}>
                        <NavDropdown.Item id="NavMenuInstantAlerts">Instant alerts</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.features}>
                        <NavDropdown.Item className="text-muted text-500" id="NavMenuFeaturesDiscoverMore">Discover more</NavDropdown.Item>
                    </LinkContainer>
                </div>
                <div className="col pl-0 pr-0">
                    <span className="text-500 text-cta mb-20 block"><span className="icon"><img src="/images/icons/nav/nav_dropdown_premium-features.svg" /></span>Premium features</span>
                    <LinkContainer to={featuresNavRoutes.callAnswering}>
                        <NavDropdown.Item id="NavMenuCallAnswering">Call answering</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.proPrompts}>
                        <NavDropdown.Item id="NavMenuProfessionalPrompts">Professional prompts</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.recording}>
                        <NavDropdown.Item id="NavMenuCallRecording">Call recording</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.conversationSearch}>
                        <NavDropdown.Item id="NavMenuConversationSearch">
                            Conversation Search
                            <span className="ml-10 pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: "10px", top: "-2px" }}>NEW!</span>
                        </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.keywordTrigger}>
                        <NavDropdown.Item id="NavMenuKeywordTrigger">
                            Keyword Triggers &amp; Alerts
                            <span className="ml-10 pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: "10px", top: "-2px" }}>NEW!</span>
                        </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.callTranscription}>
                        <NavDropdown.Item id="NavMenuCallTranscription">
                            Call Transcription
                                <span className="ml-10 pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: "10px", top: "-2px" }}>NEW!</span>
                        </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.tpsProtection}>
                        <NavDropdown.Item id="NavMenuTpsProtection">
                                TPS Protection
                            <span className="ml-10 pl-10 pr-10 pt-3 pb-3 bg-cta text-lightest brad-10" style={{ fontSize: '10px', top: '-2px' }}>NEW!</span>
                        </NavDropdown.Item>
                    </LinkContainer>
                    {/* <LinkContainer to={featuresNavRoutes.spamBlock}> */}
                    {/*    <NavDropdown.Item id="NavMenuSpamCallBlocker">Nuisance call blocker</NavDropdown.Item> */}
                    {/* </LinkContainer> */}
                    {/* <LinkContainer to={featuresNavRoutes.filtering}> */}
                    {/*    <NavDropdown.Item id="NavMenuCallFiltering">Call filtering</NavDropdown.Item> */}
                    {/* </LinkContainer> */}
                    <LinkContainer to={'/features/2'}>
                        <NavDropdown.Item className="text-muted text-500" id="NavMenuDiscoverMoreFeatures2">Discover more</NavDropdown.Item>
                    </LinkContainer>
                </div>
                <div className="col pl-0 pr-0">
                    <span className="text-500 text-cta mb-20 block"><span className="icon"><img src="/images/icons/nav/nav_dropdown_reporting-features.svg" /></span>Integrations</span>
                    <LinkContainer to={featuresNavRoutes.capsuleCrm}>
                        <NavDropdown.Item id="NavMenuCapsule">Capsule CRM</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.pipedriveCrm}>
                        <NavDropdown.Item id="NavMenuPipedrive">Pipedrive CRM</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.hubspot}>
                        <NavDropdown.Item id="NavMenuHubSpot">HubSpot CRM</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/web-hook-integrations/google-sheets'}>
                        <NavDropdown.Item id="NavMenuWebhookGoogleSheets">Google Sheets</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/web-hook-integrations/microsoft-to-do'}>
                        <NavDropdown.Item id="NavMenuWebhookMicrosoftToDo">Microsoft To-do</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/web-hook-integrations/salesforce'}>
                        <NavDropdown.Item id="NavMenuWebhookSalesforce">Salesforce</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/web-hook-integrations/slack'}>
                        <NavDropdown.Item id="NavMenuWebhookSlack">Slack</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/web-hook-integrations/zoho'}>
                        <NavDropdown.Item id="NavMenuWebhookZoho">Zoho CRM</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={featuresNavRoutes.webhooks}>
                        <NavDropdown.Item id="NavMenuWebhooks">Webhooks</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/features/4'}>
                        <NavDropdown.Item className="text-muted text-500" id="NavMenuDiscoverMoreIntegrations">Discover more</NavDropdown.Item>
                    </LinkContainer>
                </div>
                </div>
            </NavDropdown>

            <NavDropdown title="Resources" className={'nav-pairs activeNav'.concat(_.includes(aboutNavRoutes, props.path) ? ' active' : '')} id="basic-nav-dropdown">
                <LinkContainer to={aboutNavRoutes.aboutRoute}>
                    <NavDropdown.Item id="NavMenuAboutUs"><span className="icon"><img src="/images/icons/nav/nav_dropdown_about-us.svg" /></span>About us</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/setup/softphone">
                    <NavDropdown.Item id="NavMenuSoftphoneSetupGuide"><span className="icon"><img src="/images/icons/nav/nav_dropdown_softphone-guide.svg" /></span>Softphone setup guide</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/voip-quality-test">
                    <NavDropdown.Item id="VoIPQualityTestGuide"><span className="icon"><img src="/images/icons/nav/nav_dropdown_softphone-guide.svg" /></span>VoIP quality test guide</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={aboutNavRoutes.faqRoute}>
                    <NavDropdown.Item id="NavMenuFaqs"><span className="icon"><img src="/images/icons/nav/nav_dropdown_faqs.svg" /></span>FAQs</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/blog">
                    <NavDropdown.Item id="NavMenuResourcesBlog">  <span className="icon"><img src="/images/icons/nav/nav_dropdown_blog.svg" /></span> Blog</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={aboutNavRoutes.contactRoute}>
                    <NavDropdown.Item id="NavMenuContactUs"><span className="icon"><img src="/images/icons/nav/nav_dropdown_contact.svg" /></span>Contact us</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
        </Nav>

        <div
        className="d-flex d-none right-side"
        // className="float-end mt-10 mb-10 d-none d-md-block"
        >
            <a href="tel:0203 189 1213" className="btn btn-transparent mr-xs-0 text-secondary text-500 text-sm">
                <span className="icon icon-phone2 pr-10 text-500"></span>
                0203 189 1213
            </a>
            <Link id="NavMenuGetStarted" to="/get-started" className="float-end btn btn-cta btn-rounded">Choose a plan</Link>
            <Link id="NavMenuLogin" to="/login" className="float-end btn btn-transparent text-cta mr-20 text-500 text-sm">Login </Link>
        </div>

    </>
}

LoggedOutMenu.propTypes = {}
LoggedOutMenu.defaultProps = {}

const mapStateToProps = state => {
  return ({})
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(LoggedOutMenu)
