import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { PromptTypes } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'
import PromptAudioPlayer from '../PromptAudioPlayer'
import OutOfHoursSectionHelperDialog from './InstructionHelperDialogs/OutOfHoursSectionHelperDialog'

const OutOfHoursSection = props => (
    <>
        <div className="row bg-dullest p-30 zoom">
            <div className="col-md-5 pb-sm-10">
                <h5 className="text-secondary text-500 text-lg">Out of Hours Message
                </h5>
                <p className="text-400">
                    This is the message your callers will hear if your lines are closed. You can choose a “canned” message from the dropdown (on the right), record your own from the Custom Recording section below or via our mobile app.
                    <br />
                    <a onClick={props.handleShowOutOfHoursHelperInstruction} className="underline" id="OutOfHoursMessageMoreInfoLink">More info</a>
                </p>
                <p className="pt-20">
                    <span className="bg-success text-400 text-lightest brad-10" style={{ padding: '3px 10px', fontSize: '12px', display: 'inline-block', marginRight: '5px', top: '-4px', position: 'relative' }}>Tip</span> Record a custom out-of-hours message such as your “open hours” or any other useful information
                    <br className="d-none d-lg-block" />
                    and then set your line as closed. This is a great way for callers to hear an automated announcement.
                    <br className="d-none d-lg-block" />
                    This works best when you have multiple departments selected.
                    <br /><br />
                    For example. &quot;<i>Press 1 for our open hours or press 2 to talk to an advisor</i>&quot;.
                </p>
            </div>
            <div className="col-md-7 mt-10">
                <p>Select message:</p>
                <OutOfHoursSectionHelperDialog />
                <Select
                    id="oohSelect"
                    className="form-control select-menu"
                    classNamePrefix="custom"
                    onChange={props.handleOohChange}
                    options={props.oohPrompts}
                    simpleValue
                    name="selectedOoh"
                    value={props.selectedOohPrompt !== undefined ? { label: props.selectedOohPrompt.promptDescription, value: props.selectedOohPrompt.promptId } : undefined}
                    searchable={false}
                    clearable={false}
                    isLoading={props.isLoadingPromptOptions}
                />
                <PromptAudioPlayer promptId={props.selectedOohPrompt !== undefined ? props.selectedOohPrompt.promptId : undefined} voice={props.voice} promptType={PromptTypes.standardOoh} />
            </div>
        </div>
    </>
)

const mapStateToProps = state => ({
  oohPrompts: state.configuration.prompts.oohPrompts,
  selectedOohPrompt: state.configuration.configuration.outOfHoursPrompt,
  isLoadingPromptOptions: state.configuration.loading.oohPrompts,
  voice: state.configuration.configuration.voicePrompt.voicePromptId
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutOfHoursSection)

// export default connect(
//     state => state.configuration,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(IntroductionSection);
