import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import LoadingNotification from '../components/LoadingNotification'
import SvgRender from '../components/SvgRender'
import { fetchHelper } from '../helpers/fetchHelper'
import * as fromApp from '../store/SBF'

class CallbackRequestNew extends Component {
  state = {
    showIcon: false,
    submitted: false,
    showMsg: false
  }

  componentDidMount () {
    const context = this
    setTimeout(() => {
      context.setState({ showIcon: true })
    }, 2000)

    setTimeout(() => {
      context.setState({ showMsg: true })
    }, 2500)
  }

  handleSubmit () {
    const context = this
    const name = context.state.name
    const tel = context.state.tel
    let canSubmit = true
    context.setState({ isLoading: true }, () => {
      if (!name || name && name.length === 0) {
        context.setState({ nameError: true })
        canSubmit = false
      } else if (!tel || tel && tel.length < 10) {
        context.setState({ telError: true })
        canSubmit = false
      }

      if (canSubmit === true) {
        const url = `/api/Home/CallbackRequest/${name}/${tel}`
        fetchHelper.getJson(url)
          .then(res => {
            context.setState({ isLoading: false })
            if (res.data === true) {
              context.setState({ submitted: true })
            } else {
              context.setState({ telError: true })
            }

            window.dataLayer.push({
              event: 'callbackSubmit'
            })
          }).catch(err => console.error(err))
      }
    })
  }

  handleClose () {
    sessionStorage.setItem('hideCallback', true)
    this.props.handleHideCallbackForm()
  }

  render () {
    return (
            <>
                <div className="callback-widget">
                    <div className={`callback-box box-shadow-lighter box-hidden b-1 ${this.props.showCallbackForm === true ? 'show' : ''}  ${this.state.submitted ? 'callback-submitted' : ''}   `}>
                        <div className="callback-header bg-primary bg-gradient">
                            <span className="callback-close-box cursor-pointer" onClick={() => this.handleClose()}>&times;</span>
                        </div>
                        <div className="callback-body text-center">
                            <img src="/images/team/hannahw3.png" className="agent-photo" />
                            <div className="agent-photo callback-success text-center bg-light" style={{ display: 'none' }}>
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 178.6 155.7" style={{ enableBackground: 'new 0 0 178.6 155.7', width: '38px', paddingTop: '20px' }} xmlSpace="preserve">
                                    <path style={{ fill: '#ff7814' }} d="M36.9,133.7c5.5,11.7,15.6,22,26.4,22h0.1c20.5,0,21.8-10.8,41.7-53.3c12.4-26.5,26-48.9,47.9-72.9l0.1-0.1
                                        c12.4-12.5,18.7-17.6,22.2-20.4c1.5-1.2,2.4-2,2.8-2.5c0.3-0.4,0.4-0.8,0.4-1.7l0-0.2l-0.1-0.2c-1-3.1-3.2-4.5-7.4-4.5
                                        c-6.6,0-18.6,5-33.7,14.2c-29.4,18-53.4,44.6-73.9,88.7l-1.1,2.4l-1.4-2.3C40.4,68.8,23,66.1,16.5,66.1c-2.4,0-4.6,0.3-6.2,0.9
                                        c-2.6,1-8.9,3.9-10.1,9c-0.8,3.4,0.8,7.2,4.7,11.5C19.2,102.9,29,116.8,36.9,133.7z"/>
                                </svg>
                            </div>
                            {this.state.submitted
                              ? <div className="callback-success">
                                    <p className="text-cta text-500 pb-5">Thanks!</p>
                                    <p className="text-italic pb-10 text-400">
                                        One of our welcome team will give <br />you a call shortly.
                                    </p>
                                </div>
                              : <div className="row callback-form">
                                    <div className="callback-intro">
                                        <h5 className="text-500">FREE Instant Callback</h5>
                                        <p className="text-cta text-500 pb-5">Welcome Team</p>
                                        <p className="text-italic pb-10 text-400">
                                            For a FREE callback, please enter your preferred contact number below.
                                    </p>
                                    </div>
                                    <div className="col-xs-12 pb-10">
                                        <input className={`form-control form-underline-graylight ${this.state.nameError ? 'has-error' : ''}`} type="text" placeholder="Your name" maxLength="50" value={this.state.name ? this.state.name : ''} onChange={e => this.setState({ nameError: false, name: e.target.value })} />
                                    </div>
                                    {this.state.nameError &&
                                        <div className="col-xs-12">
                                            <span className="text-sm text-400 text-danger">Please enter your name</span>
                                        </div>
                                    }
                                    <div className="col-xs-12">
                                        <input className={`form-control form-underline-graylight ${this.state.telError ? 'has-error' : ''}`} type="tel" placeholder="Your phone number" maxLength="14" value={this.state.tel ? this.state.tel : ''} onChange={e => this.setState({ telError: false, tel: e.target.value.replace(/\D/, '') })} />
                                        <SvgRender icon="uk-flag" svgWidth="25px" svgClassName="flag" />
                                    </div>
                                    {this.state.telError &&
                                        <div className="col-xs-12">
                                            <span className="text-sm text-400 text-danger">Please enter a valid telephone number</span>
                                        </div>
                                    }
                                    <div className="col-xs-12 mt-20 mb-15">
                                        {this.state.isLoading
                                          ? <LoadingNotification className="mt-30" message={'Loading...'} isLoading={true} textColor="text-dark" height={50} />
                                          : <Button variant="outline" className="btn gradient btn-outline btn-block btn-rounded text-dark btn-submit" disabled={!this.state.name || !this.state.tel} onClick={() => this.handleSubmit()}>
                                                <img src="~/Content/images/loading.gif" alt="Please wati..." style={{ display: 'none' }} />
                                                <span>
                                                    Schedule a Callback
                                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 252.5 444.6" style={{ enableBackground: 'new 0 0 252.5 444.6' }} xmlSpace="preserve">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    style={{ fill: '#ff7814' }}
                                                                    d="M30.1,444.6c-8,0-15.6-3.1-21.3-8.8c-11.7-11.7-11.7-30.8,0-42.6l171-171L8.7,51.1C-2.7,39.3-2.7,20.9,8.6,9.2C14.4,3.3,22,0,30.3,0c7.8,0,15.3,3,20.9,8.5l192.5,192.5c11.7,11.7,11.7,30.8,0,42.6L51.4,435.8C45.7,441.5,38.1,444.6,30.1,444.6z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </Button>
                                        }
                                    </div>
                                </div>}

                        </div>
                    </div>
                    <div className={`callback-icon box-shadow ${this.state.showIcon === true ? 'show' : ''}`} onClick={() => this.props.showCallbackForm === true ? this.props.handleHideCallbackForm() : this.props.handleShowCallbackForm() }>
                        <svg id="Isolation_Mode" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 290.24 290.24">
                            <path className="callback-icon-svg"
                                d="M213.11,175.07c-18.15,18.15-18.15,36.3-36.3,36.3s-36.3-18.15-54.45-36.3-36.3-36.3-36.3-54.45,18.15-18.15,36.3-36.3-36.3-72.6-54.45-72.6S13.46,66.17,13.46,66.17c0,36.3,37.3,109.89,72.6,145.2S195,284,231.26,284c0,0,54.45-36.3,54.45-54.45S231.26,156.92,213.11,175.07Z"
                                transform="translate(-4.46 -2.72)" />
                        </svg>
                    </div>
                    <div className={`callback-msg box-shadow ${this.state.showMsg ? 'show' : ''}`}>
                        <span className="callback-close-msg" onClick={() => this.setState({ showMsg: false })}>&times;</span>
                        <p>
                            Need help? Receive a FREE callback from one of our team!
                        </p>
                    </div>
                </div>
            </>
    )
  }
}

export default connect(
  state => {
    return ({
      showCallbackForm: state.sbfApp.visibility.showCallbackForm
    })
  },
  {
    handleShowCallbackForm: fromApp.handleShowCallbackForm,
    handleHideCallbackForm: fromApp.handleHideCallbackForm
  }
)(CallbackRequestNew)
