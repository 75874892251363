import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import CarouselWidget from '../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { fetchHelper } from '../helpers/fetchHelper'
import { isLoggedIn } from '../selectors'

class WhatIsVoIP extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    return (
      <>
        <ScrollToTopOnMount />
        <DisplayMainFooter /> <DisplayMainHeader />
        <Helmet>
          <title>What is VoIP phone system | SwitchboardFREE</title>
          <meta
            name="description"
            content="VoIP or (voice over internet protocol) started its life way back in 1973, I know. It&apos;s hard to think that the seemingly modern-day VoIP phone system started almost 50 years ago but as is such the case for most of today's technological leaps, VoIP was created in the name of war."
          />
          .{/* inline style elements */}
          {/* {this.state.isLoggedIn &&
                    <style type="text/css">{`
                        body {
                            padding-top: 0 !important;
                        }
                    `}
                    </style>
                } */}
          <style type="text/css">{`
                .full-width ul.unstyled {
                    list-style-position: outside !important;
                }
                .full-width ul.unstyled li {
                    padding:5px 10px;
                    font-size:1em;
                }
                .full-width ul.unstyled li .bg-cta {
                    border-radius:50%;
                    height:25px;
                    width:25px;
                    display:inline-block;
                    text-align:center;
                    color:#fff;
                }
                .full-width ul.unstyled li .bg-cta .icon {
                    font-size:14px;
                    top:-1px;
                    position:relative;
                }
                .icon-block.xl .icon {
                    top: 18px !important;
                }
                @media screen and (max-width:767px) {
                    .col-xs-6 .icon-block {
                      width: 130px;
                      height: 130px;
                      padding: 8px 0;
                    }
                  }
                  @media screen and (max-width: 1150px){
                    .bg-image {
                        background-image: none !important;
                    }
                    .bg-image .col-sm-6 {
                        width: 100%;
                        margin-left: 0;
                    }
                  }
                    `}</style>
        </Helmet>
        <header
          style={{ backgroundImage: "url('/images/backgrounds/plans.jpg')" }}
          className="full-width full-hero-small with-text"
        >
          <h1>What exactly is a VoIP phone system anyway?</h1>
          {/* <h2 className=" subtitle">A brief history lesson about The origins of VoIP</h2> */}
        </header>
        <div className="full-width bg-light pt-50 pb-50">
          <div className="container w-900px center-block">
            <div className="row">
              <div className="col-md-12 text-center pb-40">
                <h3 className="text-500">
                  A brief history lesson about <br className="d-none d-sm-block" />
                  the origins of VoIP
                </h3>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12 center-block pb-50">
                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/cannon.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">From war to war games</h4>
                    <p>
                      VoIP or (voice over internet protocol) started its life
                      way back in 1973, I know. It&apos;s hard to think that the
                      seemingly modern-day VoIP phone system started almost 50
                      years ago but as is such the case for most of today's
                      technological leaps, VoIP was created in the name of war.
                    </p>
                    <p className="pt-15">
                      Back in 1973 Bob McAuley, Ed Hofstetter, and Charlie Radar
                      whilst working at MIT for the US Department of Defence
                      Advanced Research Arm (ARPANET) sent the world&apos;s first
                      Voice over internet packet and thus created VoIP and by
                      1976 they had also created the world's very first
                      conference call.
                    </p>
                    <p className="pt-15">
                      Needless to say, this phone system was very much kept in
                      the military domain for many years and the general public
                      would have to wait until 1989 before this technology was
                      used publicly by video game players to communicate with
                      each other while playing games using only dial-up modems.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/project-management.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">The evolution of technology</h4>
                    <p>
                      In the early 1990s technology had enabled Brian C Wiles to
                      half the required bandwidth used to send these voice
                      packages and he released the program to the world under
                      the name of Netfone.
                    </p>
                    <p className="pt-15">
                      This became the world&apos;s first publicly available
                      software-based VoIP phone system. They later changed their
                      name to Speak Freely and by 1993, the world witnessed the
                      first-ever video conference.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/tie.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">A year later</h4>
                    <p>
                      In 1994, a free subscriber-only service (Free world
                      dial-up) had emerged but at this point, you couldn&apos;t make
                      a VoIP call and just dial out to any number as this was
                      established for only a network of known users to
                      communicate with each other for free.
                    </p>
                    <p className="pt-15">
                      And finally, in 1995, VocalTec created the first-ever
                      commercial VoIP application, allowing users to make a full
                      VoIP call to another phone.
                    </p>
                    <p className="pt-15">
                      This phone system wasn't free and was charged on a
                      per-minute basis. This was mainly used to circumvent
                      international and long-distance calls but even back then
                      this required a PC with some real punch.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/goodbye.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Say hello to SIP</h4>
                    <p>
                      In 1996, Virtual PBX launched using their version of
                      internet calling and this used a completely different
                      technology known as SIP.
                    </p>
                    <p className="pt-15">
                      SIP or "sip trunking" uses a PBX (Private Branch Exchange)
                      which needed to be connected to copper wires under the
                      ground and communicated via PSTN, not VoIP.
                    </p>
                    <p className="pt-15">
                      SIP was easier to scale and came with portal access which
                      appealed to big businesses, so a divide grew between the
                      more business-focused and expensive SIP and the cheaper
                      and less restrictive VoIP system.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/web-programming.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Open source kicks it up a gear</h4>
                    <p>
                      By 1998 VoIP only accounted for 1% of all phone traffic
                      and new ways of making phone calls were being rapidly
                      developed. Mark Spencer come into the fold in 1999 with a
                      new IP-PBX Linux based system called Asterisk.
                    </p>
                    <p className="pt-15">
                      This new, open-source phone system took the expensive
                      hardware out but kept the scalability and portability of a
                      VoIP system.
                    </p>
                    <p className="pt-15">
                      Asterisk popularity grew with pace and as this was open
                      source software, it has been continually improved by
                      thousands of tech-savvy enthusiasts and is still widely
                      used by small businesses today.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/buildings.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">The big players enter the game</h4>
                    <p>
                      By 2003 VoIP phone calls increased to 25% of all voice
                      calls, not surprisingly, this was the year Skype and
                      Vonage are born.
                    </p>
                    <p className="pt-15">
                      Skype started as audio-only and only later grew to be a
                      predominantly video calling platform. This company was
                      purchased by eBay in 2003 and later on by Microsoft in
                      2011.
                    </p>
                    <p className="pt-15">
                      Vonage came along in 2004 and took only 2 years to amass
                      almost 2 million subscribers. This is when VoIP and SIP
                      started to skyrocket.
                    </p>
                    <p className="pt-15">
                      From 2005 we started seeing the introduction of the first
                      mobile VoIP applications and in the last 10 years, this
                      VoIP voice over internet has really become mainstream with
                      hosted VoIP service growing at roughly 17% each year.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/bars.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">As it stands today</h4>
                    <p>
                      VoIP now stands at almost a £15 billion industry. This is
                      helped further with a massive push during the Coronavirus
                      pandemic, and with remote work on the increase, this
                      growth shows no sign of slowing down.
                    </p>
                    <p className="pt-15">
                      Unified communications and omnichannel routing are now
                      common terms for businesses, and people expect to
                      instantly communicate in whichever method they choose for
                      both their co-workers and also their loved ones.
                    </p>
                    <p className="pt-15">
                      So let&apos;s find out more about this technology.
                    </p>
                  </div>
                </div>
             </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12 text-center pb-40">
                <h3 className="text-500">So what is VoIP?</h3>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12 center-block pb-50">
                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/presentation.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">What does VoIP stand for?</h4>
                    <p>
                      VoIP or (Voice over Internet Protocol) is also known as IP
                      telephony, Voice over IP or IP phone, is simply a
                      technology that delivers voice communications and
                      multimedia such as pictures and videos using an internet
                      connection.
                    </p>
                    <p className="pt-15">
                      Your traditional phone lines or home landline uses an
                      older and different technology called PSTN (public
                      switched telephone network) or (switched telephone network
                      PSTN) and can only handle voice calls.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/cloud.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">
                      Hosted VoIP or cloud-based VoIP systems
                    </h4>
                    <p>
                      Hosted VoIP, Cloud-based VoIP, cloud-based system or
                      Hosted PBX are all terms where your phone system connects
                      to a provider via your internet connection. That provider
                      maintains and “Hosts” that equipment off-site or in a data
                      centre.
                    </p>
                    <p className="pt-15">
                      These tend to be of a low cost and requires no maintenance
                      on behalf of the user, These are often continually
                      upgraded and improved and come with a good level of
                      support.
                    </p>

                    <p className="pt-15">
                      Any changes required to your settings are typically made
                      via a web or app-based portal and you can easily change
                      providers if you’re unhappy with your service. I&apos;s worth
                      also noting that most hosted VoIP companies have the
                      ability to port your number to them.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/keys.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">
                      Private or on-site VoIP systems
                    </h4>
                    <p>
                      On-site VoIP systems are where you host and maintain all
                      the hardware and technical resource at your location.
                      On-site VoIP systems are usually housed in a server
                      cabinet and require experienced technical staff to
                      maintain and update the system.
                    </p>
                    <p className="pt-15">
                      These on-site systems come at a greater cost in terms of
                      the hardware and the resourcing of staff to maintain the
                      system but you’re also free to make highly customisable
                      changes and you have full control over ongoing costs,
                      however, with the rate at which this technology is now
                      changing, only the very dedicated opt for this option.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/padlock2.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Security</h4>
                    <p>
                      As we’ve discussed earlier VoIP is just information being
                      transferred over the interest and is as secure as your
                      regular internet traffic.
                    </p>
                    <p className="pt-15">
                      Adding some basic cybersecurity methods and some basic
                      good practices can keep you safe from most hackers. VoIP
                      is no more or less secure than your traditional phone
                      line, but neither of these technologies are completely
                      infallible.
                    </p>
                    <p className="pt-15">
                      Your VoIP hosts should be able to inform you of their
                      security level and procedures and if you’re taking
                      payments details over the phone or your calls are of a
                      sensitive nature then it&apos;s worth ensuring your VoIP
                      providers are well established, trustworthy and that they
                      have the right security credentials.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/alarm.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Emergency calls</h4>
                    <p>
                      One thing you should be aware of is that emergency calls
                      cannot be made from a VoIP phone if there&apos;s no internet
                      connectivity. VoIP providers are therefore unable to
                      guarantee emergency calls.
                    </p>
                    <p className="pt-15">
                      Even if you have a strong internet connection and you make
                      an emergency call, the emergency services may have trouble
                      in determining your location, which is not normally a
                      problem for traditional phone systems. This doesn't
                      usually pose an issue today as most of us now carry a
                      mobile phone.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/rain.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">The dark days of VoIP</h4>
                    <p>
                      Go back 10 or so years and VoIP got a bit of a bad rap for
                      poor call quality, constant dropouts and a complicated
                      user experience.
                    </p>
                    <p className="pt-15">
                      VoIP did have some other teething issues in the past with
                      many smaller companies reselling or packaging up cheap or
                      substandard software and providing very little in the way
                      of support.
                    </p>
                    <p className="pt-15">
                      These early systems were dependant on expensive PBX that
                      demanded a hefty monthly subscription, expensive support
                      and almost everything came as a chargeable add-on. Some of
                      the very early services even had you listening to
                      sponsored advertising before you even made or received a
                      call.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/thumbs-down.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Distrust and negativity</h4>
                    <p>
                      VoIP was never as judiciously looked after as our
                      traditional phone system and this widened the gap of
                      distrust for the product amongst consumers and some
                      businesses alike. This negative legacy has remained with
                      many, even despite VoIP&apos;s recent advances.
                    </p>
                    <p className="pt-15">
                      It&apos;s only lately that improvements to the stability, speed
                      and infrastructure of the intent, have things started to
                      look up for VoIP and with some of the bigger players
                      entering the game, VoIP has now started to appease even
                      these early naysayers.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12 text-center pb-40">
                <h3 className="text-500">What is VoIP used for? </h3>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12 center-block pb-50">
                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/tie.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Let&apos;s get down to business</h4>
                    <p>
                      Business VoIP use has undoubtedly spearheaded the recent
                      VoIP onslaught and this has remained very much a
                      business-oriented product.
                    </p>
                    <p className="pt-15">
                      After all, businesses tend to have large phone bills and
                      choosing a cheaper alternative can save thousands off a
                      typical business balance sheet.
                    </p>
                    <p className="pt-15">
                      However, i&apos;s not only the cost savings you get with VoIP
                      that's the main driver for its use. VoIP is so versatile
                      as a business phone, i&apos;s almost infinitely configurable
                      with features such as:
                    </p>
                    <p className="pt-15">
                      <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0">
                        <li className="pb-xs-10 pt-xs-10 brad-5">User extensions</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Custom introductions</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Hold music</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Departments</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Call recording</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">A customer reporting system</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Remote working</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Call forwarding</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">A mobile app</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Desk VoIP phones</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">improved call quality</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Caller ID</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Voicemail to email</li>
                        <li className="pb-xs-10 pt-xs-10 brad-5">Missed call alert</li>
                      </ul>
                    </p>
                    <p className="pt-15">
                      This system eventually becomes ingrained into the
                      personality of that business and using a "traditional"
                      phone line for a business, even if you&apos;re a small business
                      just doesn't stack up.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/edit2.svg"
                        style={{ height: '60px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">A small note on private use</h4>
                    <p>
                      I&apos;s worth mentioning that i&apos;s not just businesses that
                      now use VoIP. We're all now familiar with Facetime calls
                      and WhatsApp calls from our mobile phones.
                    </p>
                    <p className="pt-15">
                      These are all VoIP or IP based communications and with
                      more unlimited mobile data plans, VoIP's use in the
                      consumer market is not only here to stay but we can expect
                      some great things on the horizon, but I'll discuss that in
                      more depth later.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/recorded-call.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Call recording</h4>
                    <p>
                      It wasn&apos;t too long ago when call recording was only within
                      the reach of corporates and larger companies due to its
                      eye-watering cost. Now, thanks to advances in VoIP
                      technology, call recording can be just a simple add on to
                      your phone service.
                    </p>
                    <p className="pt-15">
                      If you have never used a call recording system for your
                      business, you might not think it a necessity but call
                      recording is something that once you have, you will wonder
                      how on earth you coped without it!
                    </p>
                    <p className="pt-15">
                      It&apos;s a small addition that can have a big impact from
                      removing conflicts to staff training and compliance.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/devices.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">
                      Multiple devices &amp; multiple numbers
                    </h4>
                    <p>
                      With a traditional phone system, you&apos;re typically
                      allocated a single number and that number is based on your
                      geographical location but with VoIP, you can have as many
                      numbers as you wish and you&apos;re free to choose the type of
                      number you want for your business.{' '}
                    </p>
                    <p className="pt-15">
                      Why is this important? Suppose you&apos;re a small business, a
                      single person working from home running your e-commerce
                      business from your kitchen and you want to appear bigger
                      and more prestigious than you actually are.
                    </p>
                    <p className="pt-15">
                      That's easy, you just purchase a local number based on
                      your location from your VoIP provider, a London number and
                      perhaps a Glasgow number.
                    </p>
                    <p className="pt-15">
                      On your website, you list your other two offices with
                      separate phones numbers, all of which route back to your
                      mobile phone sat on your kitchen table. and voila! The
                      perception of your business has now changed.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/bullhorn.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Marketing</h4>
                    <p>
                      VoIP allows you to use multiple numbers and this can
                      actually be a lot more useful than just improving your
                      perception.
                    </p>
                    <p className="pt-15">
                      Let&apos;s say that your small business is going well and you
                      have £1,000 to spend on marketing and you want to try
                      three different approaches. You use a third of your budget
                      on Web ads, a third on Radio advertising and a third on a
                      local leaflet drop.
                    </p>
                    <p className="pt-15">
                      You can easily track which method gave you the best return
                      by purchasing three different numbers from your VoIP
                      supplier and using these on each of your adverts. At the
                      end of the month, you look on your portal to see how many
                      phones call each advert produced.
                    </p>
                    <p className="pt-15">
                      VoIP numbers are used temporarily in this way and many of
                      the large newspapers will add a different phone number for
                      the same adverts for different regions so they can discern
                      what adverts do the best in which location.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/smartphone.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Apps and devices</h4>
                    <p>
                      Physical phone hardware or "desk phones" for businesses
                      can be expensive and they tend to date quite rapidly too.
                      imagine footing the bill to replace all the phones in
                      large businesses because of the release of a new must-have
                      feature or upgrade.
                    </p>
                    <p className="pt-15">
                      More businesses are finding that a mix of softphones (PC
                      based phones) and mobiles are the perfect combinations to
                      keep costs down whilst also staying on top of the latest
                      features and upgrades.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/telephone.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">Softphones</h4>
                    <p>
                      A softphone is simply a piece of software that runs on a
                      PC or laptop which allows you to make VoIP calls. This
                      works just like a standard phone system or phone lines and
                      removes the need for a desk phone. The only hardware
                      required is usually a USB headset.
                    </p>
                    <p className="pt-15">
                      Whilst on the move, users can call using an app on their
                      mobile device that mimics their work softphone, meaning
                      that they can make VoIP calls presenting their business
                      phone number and dial users by their extensions.
                    </p>
                    <p className="pt-15">
                      If any updates or changes occur, this is handled with a
                      simple update to the software or the App and any new users
                      can be created and removed easily without the need for a
                      physical phone.
                    </p>
                    <p className="pt-15">
                      This option is not only very scalable and efficient, but
                      i&apos;s also kinder to the environment and your local
                      landfill.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/restricted-area.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">VoIP Restrictions</h4>
                    <p>
                      Not all countries embrace VoIP and around 20 countries
                      actually prohibit its usage or its usage is not made
                      available, Notably:
                    </p>
                    <p className="pt-15 italic text-italic">
                      Belize, Brazil, Caribbean Region, China, Cuba, Egypt,
                      Iran, Israel, Jordan, Jordan, Kuwait, Mexico, Morocco,
                      North Korea, Oman, Pakistan, Paraguay, Qatar, South Korea
                      and the UAE.
                    </p>
                    <p className="pt-15">
                      The reason for these counties not using VoIP is usually
                      down to three reasons, i&apos;s either a "security" or
                      "privacy" risk in places where the media and
                      communications are monitored/restricted. Where the local
                      government-run phone company and they fear losing revenue
                      to this cheaper alternative, or the country&apos;s
                      infrastructure or local area network lan just doesn't
                      support VoIP.
                    </p>
                    <p className="pt-15">
                      Those counties now more than ever face many social and
                      economic challenges as the rest of the world enjoy this
                      free and cheap communication.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-md-12 text-center pb-40">
                    <h3 className="text-500">Why is VoIP so important?</h3>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/magic-ball.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">The future of phone calls</h4>
                    <p>
                      Improvements in the speed and stability of the internet
                      and collaborative tools such as Microsoft Teams and VoIP
                      phone systems have accelerated the rise in people working
                      from home.
                    </p>
                    <p className="pt-15">
                      Employees are increasingly sharing their work time between
                      home and the office and even in "third spaces" such as a
                      cafe or open office.
                    </p>
                    <p className="pt-15">
                      Covid has also been a major catalyst for this "work from
                      home" movement and around a quarter of those who were
                      forced to work from home want to remain working from home.
                    </p>
                    <p className="pt-15">
                      This is why VoIP is so important for our future and
                      traditional phones lines are destined to be resigned to
                      the history books.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/death.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">The death of landlines</h4>
                    <p>
                      The next big shift will be the move from the millions of
                      unused landlines collecting dust in our homes to hopefully
                      something a lot more useful.
                    </p>
                    <p className="pt-15">
                      Landlines, I hope are the next to be usurped by VoIP and
                      rightly so. Even with wireless home phones, these are
                      rarely used and if they do ring, they're often tucked away
                      in some quiet little corner and chances of anyone under
                      the age of 60 actually answering is slim to none.
                    </p>
                    <p className="pt-15">
                      But having a landline number can be useful, you may need
                      it as a collective number for your household, You often
                      need to provide this to utility companies and you can dial
                      out without divulging your mobile number.
                    </p>
                    <p className="pt-15">
                      It would so much more beneficial if you had a cloud-based
                      landline number that if called, rang your family's mobiles
                      in sequence or in unison?
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/satellite.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">
                      Copper wire and cables vs satellites
                    </h4>
                    <p>
                      The other thing wrong with landlines, i&apos;s that's a
                      physical wire running through your house, often on a
                      tortuous route and suspended outside, exposed to the
                      elements and subject to corrosion, wind damage or even
                      vandalism. The expensive infrastructure and maintenance
                      required to uphold this increasingly unused method of
                      communication are huge.
                    </p>
                    <p className="pt-15">
                      I know for many of us at the moment, a landline connection
                      is a requirement for home broadband but with the advent of
                      5G, most large cities can now receive faster than a fibre
                      internet connection to a small black MIFI box on a window
                      shelf.
                    </p>
                    <p className="pt-15">
                      These MIFI boxes are low energy consumption, low
                      maintenance, infinitely upgradable and wonderfully
                      portable.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/online-meeting.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">
                      Video calls &amp; Video conferencing{' '}
                    </h4>
                    <p>
                      So many of us now video call for work and at home that
                      this movement has been named the "Zoom Boom".
                    </p>
                    <p className="pt-15">
                      Video calls were already steadily growing pre-Covid and
                      2021 just ramped this up a notch. Video conferencing for
                      work is incredibly useful, my coworkers are spread out
                      across multiple offices and also from home, but we can
                      meet and collaborate and share like never before.
                    </p>
                    <p className="pt-15">
                      Even grandparents are face-timing their grandchildren,
                      sharing smiles artwork and commenting how much the
                      children have grown, so much so that children are becoming
                      very accustomed to this communication method.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-md-12 text-center pb-40">
                    <h3 className="text-500">The future of VoIP</h3>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/infrastructure.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">The demise of analogue</h4>
                    <p>
                      BT is still the first choice for landline communication in
                      the UK but they are actually leading the change to shut
                      down their traditional telephone network (ISDN) in the UK
                      with the intention to move us all in the UK over to the
                      "cloud" or Internet-based telephony (VoIP).
                    </p>
                    <p className="pt-15">
                      This process has already started and by the end of 2020,
                      there were no new ISDN orders processed and by 2025, the
                      old network will be finally deactivated, meaning all new
                      lines will make calls using an internet connection only.
                    </p>
                    <p className="pt-15">
                      This is quite a significant change for the telephone
                      industry and our telephone systems but this will move us
                      from our old infrastructure to a fibre-based future is
                      certainly a step in the right direction.
                    </p>
                    <p className="pt-15">
                      VoIP has made some big advances since these early days and
                      the evolution of VoIP has not only surpassed its
                      predecessor, It&apos;s beaten it hands down.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">So. Where next? </h4>
                    <p>
                      We all know we need to move away from traditional
                      telephones but before you rush in, there are some things
                      to consider.
                    </p>
                    <p className="pt-15">
                      There are many companies out there willing to set you up
                      with a nice lengthy contract, confusing billing plans and
                      hidden charges so making the right choices and choosing a
                      reputable provider is key.
                    </p>
                    <p className="pt-15">
                      Make sure you understand what need from a VoIP service is
                      and what it can offer to ensure you choose the best/most
                      efficient option for you.
                    </p>
                    <p className="pt-15">
                      Do you have the infrastructure to support VoIP? You’ll be
                      making calls via your internet line rather than a phone
                      line so you’ll need to consider bandwidth usage and line
                      stability.
                    </p>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/power-supply.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500">I miss my hardware</h4>
                    <p>
                      If you like to have a physical handset on your desk, you
                      can easily purchase a VoIP handset or VoIP phones, but as
                      with all things, these vary in features and price. With
                      colour touch screens and removable tablets, you can get
                      almost any feature you want, albeit at a cost.
                    </p>
                    <p className="pt-15">
                      In reality, a lot of this glitz is not needed and a simple
                      handset will suffice. However, first of all, I would urge
                      you to try a softphone, these are a hell of a lot cheaper
                      and you'll be surprised at quickly you get used to them
                      unless you like to slam a handset down like a boss.
                    </p>
                  </div>
                </div>

<div itemScope itemType="https://schema.org/FAQPage">

                <div className="row mt-30">
                  <div className="col-md-12 text-center pb-40">
                    <h3 className="text-500">Frequently asked questions</h3>
                  </div>
                </div>

                <div className="row mt-30" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500" itemProp="name">
                        What is the best VoIP cloud phone system?
                    </h4>
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text">
                            <p>
                                Most companies offering a VoIP phone system are experts in
                                their field with many years of experience behind them. The
                                best VoIP cloud phone system is one that will suit your
                                needs the best; one that is scalable as your business
                                changes and one that meets your business budget. Consider
                                a company that has good reviews and can offer you reliable
                                after-sales support.
                            </p>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-30" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500" itemProp="name">
                      Is a VoIP telephone system only used for big business?
                    </h4>
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text">
                            <p>
                            VoIP services can be used by any size of business. There
                            are dedicated "business VoIP" packages available and these
                            tend to be aimed at the larger business and are sometimes
                            called "cloud telephone system" or "business VoIP system"
                            </p>
                            <p className="pt-15">
                            These business-centric packages often come bundled with
                            VoIP phones, a mobile app and support to help you manage
                            the telephone system.
                            </p>
                            <p className="pt-15">
                            But a small business can also benefit from this type of
                            system as long as you have a strong stable internet
                            network service or you may have to consider changing your
                            service provider.
                            </p>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-30" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500" itemProp="name">
                      Are there any other terms a VoIP phone system is known by?
                    </h4>
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text">
                        <p>
                            Any time you see the terms "Cloud-based", "VoIP phones",
                            "VoIP services", "cloud phone", "cloud network", "cloud
                            service" or "cloud system". These all usually refer to
                            VoIP.
                        </p>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-30" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500" itemProp="name">How much is VoIP monthly?</h4>
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text">
                            <p>
                            This varies depending on the VoIP service provider you
                            use. You usually buy a bundle of minutes or VoIP calls
                            each month and every time you make a VoIP call, this
                            amount decreases.
                            </p>
                            <p className="pt-15">
                            You may also have to factor in the cost for a desk phone
                            or even desk phones but generally, you'll get charged to
                            make and receive calls just as you would your existing
                            phone.
                            </p>
                            <p className="pt-15">
                            There are many VoIP solutions and these vary from company
                            to company. Cost also depends on the size of your
                            business, the number of users, your requirement to make
                            international calls and what you need from a VoIP phone
                            system.
                            </p>
                            <p className="pt-15">
                            There are some services available for free but
                            fundamentally, you will get what you pay for – These start
                            at around £5 a month for a basic, single-user service
                            which is ideal for small businesses and up to £25-£30 per
                            month for a high-end package.
                            </p>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-30" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500" itemProp="name">
                      Can you use a normal phone for VoIP?
                    </h4>
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text">
                            <p>
                            Only with an adapter, however, a VoIP phone or business
                            VoIP phone can be purchased relatively cheaply and often
                            comes with a dedicated business phone number. These are
                            worth the investment if you&apos;re serious about your business
                            communications, plus you often get a lot more
                            functionality than just the handset.
                            </p>
                            <p className="pt-15">
                            You will also have a dedicated support phone line for any
                            queries or questions you may have and of course, all of
                            the call management features to enhance your business
                            communications as a whole.
                            </p>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-30" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500" itemProp="name">
                      What are the disadvantages of VoIP?
                    </h4>
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text">
                            <p>
                            By the very nature of the beast, a VoIP phone system
                            relies on a stable internet connection for functionality.
                            Therefore you will need to have a decent broadband
                            capacity and a reliable broadband provider to ensure good
                            call quality and good service connection.{' '}
                            </p>
                            <p className="pt-15">
                            You may wish to consider a dedicated business broadband
                            service to minimise these risks to keep the calls flowing
                            and your business functioning.
                            </p>
                            <p className="pt-15">
                            That&apos;s about it for disadvantages – There arent many....
                            </p>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-30" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500" itemProp="name">Are VoIP systems cheaper?</h4>
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text">
                            <p>
                            Operating costs for VoIP phone systems are significantly
                            lower than traditional phone lines. Here are specific
                            instances where your organisation can save money by using
                            a VoIP phone system:
                            </p>
                            <p className="pt-15">
                            <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0">
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                VoIP significantly cuts down domestic and
                                international call charges. A VoIP number, also known
                                as a virtual number, doesn&apos;t go through an ‘exchange’
                                and is usually charged as a local rate number even for
                                long-distance calls.
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                You’ll no longer have to maintain separate networks
                                for data and phones.
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                As VoIP uses your internet connection/broadband
                                connection to make and receive your telephone calls,
                                this can dramatically reduce your business's running
                                costs by eliminating the need for lots of individual
                                telephone lines.
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                VoIP handsets tend to be less expensive than
                                traditional telephones.
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                VoIP phone systems support teleconferencing and video
                                conferencing which can reduce the need to travel for
                                meetings.
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                Some VoIP phone systems include free calls between
                                extensions, meaning you can call your colleagues
                                completely free. Even if they’re abroad!
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                Your VoIP provider can tailor their telephone systems
                                to your exact needs.
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5">
                                A VoIP cloud-based system is built and designed
                                specifically for business.
                                </li>
                            </ul>
                            </p>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-30" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500" itemProp="name">
                      Does my business need a VoIP system?
                    </h4>
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text">
                                <p>In short, YES.</p>
                            <p className="pt-15">
                            That standard landline service just doesn't stack up for
                            business calls, for only a few pounds a month you can get
                            a professional business phone system that calls multiple
                            numbers at once, has professional greeting departments,
                            missed call alerts, voice mail, call recording, call
                            forwarding and the list goes on.
                            </p>
                            <p className="pt-15">
                            These are very quick to set up, they reduce your missed
                            calls and even one additional sales call could pay for the
                            service for a year. If you have a team of employees, then
                            you'll also be making a big saving on call costs whilst
                            increasing the flexibility of your team.
                            </p>
                            <p className="pt-15">
                            I can think of no good reason not to leverage this
                            technology.
                            </p>
                        </div>

                    </div>

                  </div>
                </div>

                <div className="row mt-30" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <div className="col-xs-2 pt-15">
                    <div className="icon-block icon-cta xl">
                      <img
                        className="icon"
                        src="/images/icons/questions.svg"
                        style={{ height: '70px' }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-10 pl-40">
                    <h4 className="text-500"itemProp="name">
                      Do I need a VoIP system in my home?
                    </h4>
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text">
                            <p>Yes! All UK analogue landlines will be retired in favour of home VoIP by the end of 2027. If your current provider intends to offer residential VoIP, it&apos;s likely that they will contact you to switch you over before the deadline. </p>
                            <p className="pt-15">
                            The great news is that you don&apos;t have to wait to get the benefits of VoIP within your home. You can switch over now and start saving money right away, with SwitchboardFREE&apos;s sister company Phonely. <a href="https://www.phonely.co.uk/plans" target='_blank'>Plans start from £9.97</a>. You get unlimited calls* and you can keep your existing number.  
                            </p>
                            <p className="pt-15">
                            <a href="https://www.phonely.co.uk/services/home-voip" target='_blank' className="btn btn-secondary btn-sm">More on Phonely Home VoIP</a>
                            </p>
                            <p>
                            * Unlimited within <a href="https://www.phonely.co.uk/policies/fair-usage-policy" target='_blank'>Phonely&apos;s fair usage policy</a>. 
                            </p>
                        </div>
                    </div>
                  </div>
                </div>

              </div>

              </div>
            </div>
          </div>
        </div>
        <div className="full-width full-dull">
          <div className="container">
            <CarouselWidget />
          </div>
        </div>
      </>
    )
  }

  componentDidMount () {
    this.getCustomers()
  }

  getCustomers () {
    const url = 'api/Home/GetTotalCustomers'
    fetchHelper
      .getJson(url)
      .then((response) => {
        if (response !== undefined) {
          this.setState({
            totalCustomers: response.data.toLocaleString(navigator.language)
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export default connect(
  (state) => {
    return {
      isLoggedIn: isLoggedIn(state)
    }
  }
  // {
  //     login: fromHome.handleLogin
  // }
)(WhatIsVoIP)
