import React from 'react'
import { Button } from 'react-bootstrap'

const _ = require('lodash')
const classNames = require('classnames')

const pager = (props) => {
  const upperPage = props.pages + 1
  return (
    props.pages > 0 && <div className="col-xs-12 col-sm-12 col-md-12 text-center pt-20 pager">
            {_.range(1, upperPage).map((page) => {
              return <Button onClick={event => props.onSelected(page)}
                               className={classNames('btn btn-transparent btn-square b-1 pr-20 pl-20 pt-5 pb-5', { active: page === props.activePage })}>{page}</Button>
            })}
        </div>
  )
}

pager.propTypes = {}
pager.defaultProps = {}

export default pager
