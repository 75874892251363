import React from 'react'
import { Accordion, Button, OverlayTrigger, Popover } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { scroller } from 'react-scroll'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import PlansAndNumbersDropdown from '../../components/PlansAndNumbersDropdown'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import StatsBanner from '../../components/StatsBanner'
import { MultiNumberPrice } from '../../constants/Constants'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'
import { handleShowCallbackForm } from '../../store/SBF'
import { handleAddToBasket, handleBasketInit, handleRemoveFromBasket } from '../../store/Signup'

class LandlineOnMobile extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    prefix: '0203'
  }

  scrollTo (el) {
    scroller.scrollTo(el, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -50
    })
  }

  render () {
    const popover = (
			<Popover title="Number already taken">
				<p>
					SwitchboardFREE's landline to mobile service is compatible with:
				</p>
				<ul>
					<li>EE</li>
					<li>Vodafone</li>
					<li>O2</li>
					<li>Three/3</li>
					<li>Tesco Mobile</li>
					<li>Virgin</li>
					<li>Giff Gaff</li>
					<li>BT</li>
					<li>Sky Mobile</li>
					<li>Voxi</li>
					<li>Lebara</li>
					<li>Smarty</li>
					<li>TalkTalk</li>
					<li>Lyca</li>
				</ul>
			</Popover>
    )

    const basket = getEmptyBasket()
    const numbers = LocalStorageHelper.getItem('LandingPageNumberSelection')
    basket.numbers = numbers.value ?? []

    return <>
			<ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
			<Helmet>
				<title>Get a Landline on a mobile phone | SwitchboardFREE</title>
				<meta name="description" content="How do I get a landline on my mobile? Is it possible to get a landline number that rings my mobile?" />
			</Helmet>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container">
					<div className="row mt-100">
						<div className="col-md-6 col-sm-12 order-last text-center">
							<img src="/images/homepage-features-2.jpg" alt="" className="img-responsive mt-40 mt-xs-0" style={{ maxWidth: '600px' }} />
							<img src="/images/icons/trust-icons.png" alt="" className="img-responsive mt-40 mt-xs-0" style={{ maxWidth: '500px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-first">
							<h1 className="text-500 text-darker">Get a landline number for mobile</h1>
							<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-600px">
								Choose your perfect local or national number, from just &pound;5 per month.
							</h2>

							<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
								<li className="text-md mb-10">
									<span className="icon icon-checkmark-circle text-success mr-10" />
									Local 01/02 or National 0333 number, diverted to your mobile
								</li>
								<li className="text-md mb-10">
									<span className="icon icon-checkmark-circle text-success mr-10" />
									Get get a landline number diverting to your mobile in minutes
								</li>
								<li className="text-md mb-10">
									<span className="icon icon-checkmark-circle text-success mr-10" />
									Redirect to your mobile number, or use our Phone App for <em>unlimited calls</em>
								</li>
								<li className="text-md mb-10">
									<span className="icon icon-checkmark-circle text-success mr-10" />
									Instant activation. No setup fees. No long-term contracts
								</li>
								<li className="text-md mb-10">
									<span className="icon icon-checkmark-circle text-success mr-10" />
									Works seamlessly with any network
									<OverlayTrigger
										trigger={['hover', 'focus']}
										placement="top"
										overlay={popover}
									>
										<span>
											<img className="pl-15" src="/images/customers/small/ee.png" alt="ee logo" />
											<img className="pl-15" src="/images/customers/small/three.png" alt="three logo" />
											<img className="pl-15" src="/images/customers/small/vodafone.png" alt="vodafone logo" />
											<img className="pl-15" src="/images/customers/small/o2.png" alt="o2 logo" />
											<span className="text-xs text-underline pl-20 text-primary cursor-pointer text-400">View all...</span>
										</span>
									</OverlayTrigger>
								</li>
							</ul>

							<div className="bg-dull brad-10 p-50 d-none d-md-block" style={{ backgroundColor: '#ffebeb' }}>
								<p><em>“Fantastic company, been with them for 5 years and never had a problem.<br /><br />
									Switchboardfree have helped me grow my business with a reliable service and a landline phone number linked to 2 different mobiles so we can take calls wherever we are.<br /><br />
									Cannot fault this company. 100% recommended by me.”</em></p>

								<span className="text-500 pt-10 d-inline-block">
									Bill <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
								</span>
								<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
							</div>
							<Button variant="cta" className="btn btn-cta p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.scrollTo('numbers')}>Choose your numbers</Button>
							<Button variant="outline" className="btn btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.props.handleShowCallbackForm()}>Get a callback</Button>
						</div>
						<div className="col-12 d-block d-md-none order-last mt-20">
							<div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#ffebeb' }}>
								<p><em>“Fantastic company, been with them for 5 years and never had a problem.<br /><br />
									Switchboardfree have helped me grow my business with a reliable service and a landline phone number linked to 2 different mobiles so we can take calls wherever we are.<br /><br />
									Cannot fault this company. 100% recommended by me.”</em></p>
								<span className="text-500 pt-10 d-inline-block">
									Bill <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
								</span>
								<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="mt-15" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull p-20" >
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-darkest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>
			<ClientLogos style='dull' showText={false} />

			<div className="full-width bg-light pt-60" id="start">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center mb-20 mb-xs-0">
							<h2 className="text-500">We’ve got the perfect number for you</h2>
							<h4 className="pt-30 text-400">
								One of the common questions we hear at SwitchboardFREE is <i>“How do I get a landline on a mobile?”</i> <br className="d-none d-md-block" />or <i>“Is it possible to get a landline number that rings my mobile?”</i>
							</h4>
							<h4 className="pt-30 pb-60 text-400">
								The answer to both these questions is Yes! - and it’s very simple to do.
							</h4>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 text-center text-md-right">
							<img src="/images/devices.png" className="img-responsive" style={{ maxWidth: '560px' }} />
						</div>
						<div className="col-md-6">
							<p>
								SwitchboardFREE offers a range of different number types varying from <span className="text-500">local 01/02</span> numbers through to <span className="text-500">0333</span> and <span className="text-500">0800</span> numbers. Each number type has its own unique benefits that can affect the way your customers view your business.
							</p>
							<p className="pt-20">
								Often know as a ‘Pocket Landline’, getting a virtual phone number routing to your mobile is a great solution for anyone who is out on the road and not in one location.
							</p>
							<p className="pt-20">
								You can make outbound phone calls from your mobile phone presenting your landline number to you customers via our app.
							</p>
							<p className="pt-20">
								It also has the added benefit of making your business seem larger and builds more trust with potential clients.
							</p>
							<p className="text-500">
								Numbers start from the low price of £5 a month so it doesn’t have to break the bank either.
							</p>
							<Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.scrollTo('numbers')}>Get your number</Button>
						</div>

					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-md-12 mt-50">
							<div className="row">
								<div className="col-md-12 bg-dull brad-50 pt-60 pl-100 pb-60 pr-100 pl-xs-20 pr-xs-20 mb-50" style={{ backgroundColor: '#d6e8ef' }}>
									<div className="row">
										<div className="col-md-12 text-center pb-50">
											<h2 className="text-500">
												I&apos;s as easy as 1-2-3!
											</h2>
										</div>
										<div className="col-md-4 text-center pb-sm-30">
											<span className="icon-block lg icon-full-secondary">
												<span className="icon number">1</span>
											</span>
											<h5 className="text-500 pt-20 mb-20">Sign up for an account &amp; <br className="d-none d-md-block" />choose a number</h5>
											<p>Choose from a huge range of virtual numbers from any UK town or city, a prestigious London 0203/0207 number, a non-geographic 0333, 0800, 084 or keep your existing number.</p>
										</div>
										<div className="col-md-4 text-center pb-sm-30">
											<span className="icon-block lg icon-full-secondary">
												<span className="icon number">2</span>
											</span>
											<h5 className="text-500 pt-20 mb-20">Choose the perfect system <br className="d-none d-md-block" />to suit you</h5>
											<p>Choose between inbound call management with a virtual number connected to your existing landline, mobile or phone system. Or, a cloud-based VoIP phone system allowing you to make &amp; receive calls.</p>
										</div>
										<div className="col-md-4 text-center">
											<span className="icon-block lg icon-full-secondary">
												<span className="icon number">3</span>
											</span>
											<h5 className="text-500 pt-20 mb-20">Start making or <br className="d-none d-md-block" />receiving calls</h5>
											<p>Set up your phone number in minutes and start using your new smart number right away. Speak to our UK based customer service team who guarantee to answer your call with friendly advice or support.</p>
										</div>
									</div>
									<div className="row justify-content-center">
										<div className="col-12 mt-50">
											<div className="bg-light brad-10 box-shadow-v3 p-30 clearfix">
												<span className="d-inline-block float-start text-darker">
													<span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
														Choose from 0203, 0207, 01, 0333, 0800 or 0808
													</span>
													<p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
														<span className="text-cta">Choose your number</span> and get started
													</p>
												</span>

												<span className="d-inline-block float-end">
													<Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.scrollTo('numbers')}>Find your number</Button>
													<a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div className="full-width bg-dull pb-70 pt-70 number-page" id="numbers">
				<div className="container numbers-pages pl-sm-0 pr-sm-0">
					<div className="row">
						<div className="col-md-12 text-center pb-60">
							<h2 className="text-500 text-darker">
								Select your perfect numbers
							</h2>
							<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light">
								Divert multiple numbers to your mobile for an additional &pound;{MultiNumberPrice} per number,<br className="d-none d-md-block" />
								or simply choose a single number, included free with your plan <em>(Which you'll choose later)</em>.
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<PlansAndNumbersDropdown
								prefix={this.state.prefix}
								ddiLid={this.state.ddiLid?.value}
								defaultArea={this.state.prefix}
								handleChangeNumberType={e => this.setState({ prefix: e })}
								handleChangeAreaCode={e => this.setState({ ddiLid: e })}
								hidePlans={true}
								preventDefaultSelection={true}

								WrapperClassName="mb-40"
								LabelsClassName="text-xs text-dark text-400"

								NumberButtonClassName={'text-start bg-light pl-25 pr-25 ml-5 b-0 inbound-only'}
								NumberNameClassName="text-dark text-400"
								NumbersResultsDropdownClassName="bg-light ml-5 box-shadow"
								NumberNameClassNameSelected="text-cta text-500"

								AreaCodeButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
								AreaCodeNameClassName="text-dark text-400"
								AreaCodeResultsDropdownClassName="bg-light box-shadow"
								AreaCodeNameClassNameSelected="text-cta text-500"
							/>

							<DdiSelectorNew
								numberType={this.state.prefix}
								isSignup={false}
								currentBasket={basket}
								hideShowAll={false}
								isModal={false}
								hideLoadMore={false}
								hideRegions={true}
								hidestars={false}
								isLoggedIn={false}
								isEditing={false}
								menuOpen={false}
								citysOnly={false}
								ddiLid={this.state.ddiLid?.value}
								handleUpdateBasket={(_, sbfNumber) => {
								  // let item: LocalStorageItem<SbfNumber[]> = LocalStorageHelper.getItem < SbfNumber[] > ("LandingPageNumberSelection");
								  const item = LocalStorageHelper.getItem('LandingPageNumberSelection')
								  let sbfNumbers = item.value ?? []
								  // add the number to the array if it doesn't already exist, otherwise remove it
								  if (sbfNumbers.find(number => number.number === sbfNumber.number)) {
								    sbfNumbers = sbfNumbers.filter(number => number.number !== sbfNumber.number)
								  } else {
								    sbfNumbers.push(sbfNumber)
								  }
								  LocalStorageHelper.setItem('LandingPageNumberSelection', sbfNumbers)
								  this.setState({})
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-darker pb-50 pt-0 pb-sm-0 bg-img" id="apps" style={{ backgroundImage: "url('/images/product-shots/mobile-app-hand.png')", backgroundSize: 'initial', backgroundRepeat: 'no-repeat', backgroundPosition: '90% 100%' }}>
				<div className="container">
					<div className="row">
						<div className="col-md-6 pt-100 pt-sm-10 pb-sm-30">
							<h2 className="text-500 text-lightest mt-0 mt-sm-50 ">Our Mobile Apps</h2>
							<p className="lead text-lightest ">Be connected to the office even when on the move.</p>
							<div className="row mt-50">
								<div className="col-xs-12 col-md-2 text-center text-sm-start">
									<img src="/images/product-shots/phone-app-icon.png" alt="SwitchboardFREE Phone App" style={{ maxWidth: '100%' }} />
								</div>
								<div className="col-xs-12 col-md-10">
									<p className="lead text-500 text-cta  text-start mt-sm-15">
										SwitchboardFREE Phone App
									</p>
									<p className="mb-30">
										Ideal for occasional use allowing you to make and receive calls via any of your SwitchboardFREE numbers.
									</p>
									<p className="mb-30">
										Our Phone App is perfect for holiday use and international travel. Some international destination charges are up to £20 per minute to call back to the UK. Avoid unnecessary call charges by making and receiving calls at local rates. This feature alone is guaranteed to save travelers thousands of pounds..
									</p>
									<p className="text-400 text-md pb-30 text-left">
										<a target="_blank" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '150px' }} className="mr-20 mr-xs-10 mb-5" /></a>
										<a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '150px' }} /></a>
									</p>
								</div>
							</div>
							<div className="row mt-50">
								<div className="col-xs-12 col-md-2  text-center text-sm-left">
									<img src="/images/product-shots/management-app-icon.png" alt="SwitchboardFREE Management App" style={{ maxWidth: '100%' }} />
								</div>
								<div className="col-xs-12 col-md-10">
									<p className="lead text-500 text-cta text-start mt-sm-15">
										SwitchboardFREE Management App
									</p>
									<p className="mb-30">
										With our SwitchboardFREE management app you have total control over your account, allowing you to manage your inbound calls and configure your number settings, all while on the go. Update your redirect numbers, on hold music or customise your greetings. See all missed calls and listen to voicemails all in one place.
									</p>
									<p className="text-400 text-md pb-30 text-left">
										<a target="_blank" href="https://apps.apple.com/gb/app/sbf-2012/id556878596" rel="noreferrer"><img src="/images/app-store.svg" alt="iOS App" style={{ maxWidth: '150px' }} className="mr-20 mr-xs-10 mb-5" /></a>
										<a target="_blank" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREE&hl=en_GB" rel="noreferrer"><img src="/images/g-play.svg" alt="Android App" style={{ maxWidth: '150px' }} /></a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pt-50 pb-50">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center pb-60">
							<h2 className="text-500 text-darker">
								Your questions, answered
							</h2>
							<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light">
								Still not sure if our landline to mobile service is for you?
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<Accordion accordion>
								<Accordion.Item eventKey={1} className="panel-unstyled" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
									<Accordion.Header className="text-start text-400 pl-10" toggle>
										<span itemProp="name">What networks does this service work with?</span>
									</Accordion.Header>
									<Accordion.Body collapsible itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
										<p>
											SwitchboardFREE's landline to mobile service works seamlessly with all majoy UK networks. These include:
											EE, O2, Vodafone, Three/3, Tesco, Virgin, Giff Gaff, BT, Sky, Voxi, Lebara, Smarty, TalkTalk and Lyca.
										</p>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey={2} className="panel-unstyled" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
									<Accordion.Header className="text-start text-400 pl-10" toggle>
										<span itemProp="name">How do I get a landline on mobile?</span>
									</Accordion.Header>
									<Accordion.Body collapsible itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
										<p>
											Simply choose your number(s) to start your setup. With SwitchboardFREE you can choose between Local 01/02 numbers, including London numbers, or National 0333 numbers.
										</p>
										<p>Once you've chosen your perfect number(s) you'll be able to choose the most relevant plan for your business and instantly start making &amp; receiving calls.</p>
										<p>To get the calls from your new landline on your mobile phone is simple. You can either choose to forward the calls directly to your mobile phone, or you can download our Phone App which will enable you to answer a call anywhere in the world (As long as you have a Wi-Fi or Data connection)</p>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey={3} className="panel-unstyled" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
									<Accordion.Header className="text-start text-400 pl-10" toggle>
										<span itemProp="name">How much does this service cost?</span>
									</Accordion.Header>
									<Accordion.Body collapsible itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
										<p>
											Our plans start from as little as &pound;5 per month.<br />
											If you'd like to forward the calls directly to your mobile phone, then you'll need to add a minutes bolt-on, or pay 6p per minute to UK mobile phones.
										</p>
										<p>Alternatively, you can answer unlimited calls on our Phone App on your mobile phone (or PC/Laptop) or just &pound;5 per month.</p>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>

					</div>
					<div className="row mt-50">
						<div className="col bg-light brad-10 box-shadow-v3 p-30">
							<span className="d-inline-block float-start text-darker">
								<p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
									<span className="text-cta">Talk to us today</span> and get started
								</p>
								<h2 className="text-lg text-400 mb-0 mt-0 text-dark-light">
									Schedule a quick, no-obligation call &amp; discover...
								</h2>
								<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
									<li className="text-md mb-10">
										<span className="icon icon-checkmark-circle text-success mr-10" />
										How our landline to mobile service works
									</li>
									<li className="text-md mb-10">
										<span className="icon icon-checkmark-circle text-success mr-10" />
										How to get setup with our landline to mobile service
									</li>
								</ul>
							</span>

							<span className="d-inline-block float-end">
								<Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.props.handleShowCallbackForm()}>Get a callback</Button>
								<a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
							</span>
						</div>
					</div>
				</div>
			</div>

		</>
  }

  componentWillMount () {
  }

  componentDidMount () {
  }
}

export default withRouter(
  connect(
    state => {
      return {
        currentBasket: state.signup.packageInfo.currentBasket
      }
    },
    {
      handleAddToBasket,
      handleRemoveFromBasket,
      handleBasketInit,
      handleShowCallbackForm
    }
  )(LandlineOnMobile)
)
