import moment from 'moment'
import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AddressLookup from '../components/AddressLookup'
import LoadingNotification from '../components/LoadingNotification'
import ChoosePayment from '../components/purchase/ChoosePayment'
import { CompanyNameLimit, EmailLimit, GlobalOfferEnabled, MultiNumberPrice, NameLimit, numberOfferDuration, NumbersGlobalOfferPrice, validationStates } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import { calculateVat } from '../helpers/utils'
import { handleBELogin } from '../store/Home'
import { handleShowPaymentOptionsModal } from '../store/SBF'
import * as fromSignup from '../store/Signup'
import { getIsStep2Reached, getIsStep2Ready, getSignupEmailValidationState, getSignupNameValidationState, getSignupPhoneValidationState, signupEmailInUseValidationState } from '../store/Signup'

class LeadGenForm extends Component {
  state = {
  }

  /// ////////////////////////////////////////////////////////////////
  //
  //   Example usage:
  //
  //   <LeadGenForm
  //        FormHeadingText="Transfer your number"
  //        ButtonText="Check eligibility now"
  //        hideLabels={true}
  //        EmailAddress={true}
  //        CompanyName={true}
  //    />
  //
  /// ////////////////////////////////////////////////////////////////

  componentDidMount () {
    if (localStorage.getItem('userDetails')) {
      const user = JSON.parse(localStorage.getItem('userDetails'))
      // Pre-fill
      this.props.handleSignupPhoneChange({ newValue: user.phone })
      this.props.handleSignupNameChange({ newValue: user.name })
      this.props.handleSignupEmailChange({ newValue: user.email })
      this.props.handleSignupCompanyChange({ newValue: user.company })

      // Validate
      this.props.validateSignupPhone({ newValue: user.phone })
      this.props.validateNameField({ newValue: user.name })
      this.props.validateSignupEmail({ newValue: user.email })
    }
  }

  render () {
    let total = 0
    if (this.props.showFees) {
      const basket = this.props.basket

      if (basket) {
        basket.numbers.forEach((num) => {
          total += num.ddipremium
        })

        if (basket.numbers && basket.numbers.length > 1) { total += (basket.numbers.length - 1) * MultiNumberPrice }

        if (basket.package && basket.package.rental) {
          if (GlobalOfferEnabled === true && this.props.offerAvailable === true) {
            total += NumbersGlobalOfferPrice
          } else {
            total += basket.package.rental
          }

          if (basket.package.users.qty > 1) {
            total += (basket.package.users.qty - 1) * basket.package.rental
          }
        }
      }
    }

    return (
			<>
				<div className={`${this.props.FormClass ? this.props.FormClass + ' leads-form ' : ' leads-form text-lightest dark-gradient pl-60 pr-60 pr-sm-30 pl-sm-30 pt-30 pb-30 brad-20'}`}>
					{this.state.showSuccess === true
					  ? <>
							<h4 className={`${this.props.FormHeadingTextClass ? this.props.FormHeadingTextClass : ' text-cta text-500 text-center pb-20'}`}>
								{this.props.name
								  ? `Thanks, ${this.props.name.split(' ')[0]}!`
								  :									'Thanks!'
								}
							</h4>
							<p className="lead text-400">
								One of our team will give you a call shortly to help you get setup with SwitchboardFREE.
							</p>
						</>
					  :						<>
							<h4 className={`${this.props.FormHeadingTextClass ? this.props.FormHeadingTextClass : ' text-cta text-500 text-center pb-20'}`}>
								{`${this.props.FormHeadingText ? this.props.FormHeadingText : 'Enter your details'}`}
							</h4>

							<div className="row">
								<div className="col-sm-12">
									<p className="text-500 text-sm text-center text-lightest">
										{this.props.inboundOnly
										  ? 'Enter a phone number you would like calls redirected to'
										  :											'Please enter the best number to contact you on'
										}
									</p>
									<Form>
										<Form.Group className={`mb-30 animated ${(this.state.focusPhone || (this.props.phone && this.props.phone.length > 0)) ? 'focused' : ''}`}
											validationState={this.props.signupValidation.phoneValidationState.valid === validationStates.INVALID || this.props.signupValidation.phoneValidationState.valid === validationStates.EMPTY ? 'error' : ''}>
											<Form.Label>Enter your phone number</Form.Label>
											<Form.Control
												type="tel"
												className="text-md box-shadow shadow-lighter no-border h-60"
												value={this.props.phone}
												onFocus={() => this.setState({ focusPhone: true })}
												onBlur={e => this.setState({ focusPhone: false }) + this.props.validateSignupPhone({ newValue: e.target.value })}
												onChange={e => this.props.handleSignupPhoneChange({ newValue: e.target.value }) + this.setState({ showGenericError: false, isProcessing: false })} />

											{(this.props.signupValidation.phoneValidationState.valid === validationStates.INVALID || this.props.signupValidation.phoneValidationState.valid === validationStates.EMPTY) &&
												<Form.Text className="text-sm">
													{this.props.signupValidation.phoneValidationState.valid === validationStates.INVALID
													  ? 'Please a valid phone number'
													  :														'Please enter your phone number'
													}
												</Form.Text>
											}
										</Form.Group>
									</Form>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12">
									<Form.Group className={`animated mb-30 ${(this.state.focusName || (this.props.name && this.props.name.length > 0)) ? 'focused' : ''}`}
										validationState={(this.props.name && this.props.name.length > NameLimit) || (this.props.signupValidation.nameValidationState.valid === validationStates.INVALID || this.props.signupValidation.nameValidationState.valid === validationStates.EMPTY) ? 'error' : ''}>
										<Form.Label>Enter your name</Form.Label>
										<Form.Control
											className="text-md box-shadow shadow-lighter no-border h-60"
											value={this.props.name}
											onFocus={() => this.setState({ focusName: true })}
											onBlur={e => this.setState({ focusName: false }) + this.props.validateNameField({ newValue: e.target.value })}
											onChange={e => this.props.handleSignupNameChange({ newValue: e.target.value }) + this.setState({ showGenericError: false })} />
										{(this.props.signupValidation.nameValidationState.valid === validationStates.INVALID || this.props.signupValidation.nameValidationState.valid === validationStates.EMPTY) &&
											<Form.Text className="text-sm">
												{(this.props.name && this.props.name.length > NameLimit)
												  ? 'First name is ' + (this.props.name.length - NameLimit) + ' characters too long.'
												  :													this.props.signupValidation.nameValidationState.valid === validationStates.INVALID
												    ? 'Please a real name'
												    :														'Please enter your name'
												}
											</Form.Text>
										}
									</Form.Group>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12">
									<Form.Group className={`mb-30 animated ${(this.state.focusEmail || (this.props.email && this.props.email.length > 0)) ? 'focused' : ''}`}
										validationState={(this.props.email && this.props.email.length > EmailLimit) || (this.props.signupValidation.emailValidationState.valid === validationStates.INVALID || this.props.signupValidation.emailValidationState.valid === validationStates.EMPTY) ? 'error' : ''}>
										<Form.Label>Enter your email address</Form.Label>
										<Form.Control
											type="email"
											className="text-md box-shadow shadow-lighter no-border h-60"
											value={this.props.email}
											onFocus={() => this.setState({ focusEmail: true })}
											onBlur={e => this.setState({ focusEmail: false }) + this.props.validateSignupEmail({ newValue: e.target.value })}
											onChange={e => this.props.handleSignupEmailChange({ newValue: e.target.value }) + this.setState({ showGenericError: false })} />
										{(this.props.signupValidation.emailValidationState.valid === validationStates.INVALID || this.props.signupValidation.emailValidationState.valid === validationStates.EMPTY) &&
											<Form.Text className="text-sm">
												{this.props.signupValidation.emailValidationState.valid === validationStates.INVALID
												  ? 'Please a valid email address'
												  :													'Please enter your email address'
												}
											</Form.Text>
										}
									</Form.Group>

									{this.props.signupValidation.emailAlreadyInUse === validationStates.INVALID &&
										<div className="mb-30 mt-neg-20">
											<p className="text-500 text-sm">Looks like you've already got an account.</p>
											<p className="text-sm">You can <Link to={'/login'} className="underline text-cta">log into your account</Link> with us, or  if you've forgotten your password you can <Link to={'/forgot-password'} className="underline text-cta">reset it here</Link>.</p>
										</div>
									}

								</div>
							</div>

							{this.props.CompanyName &&
								<div className="row">
									<div className="col-sm-12">
										<Form.Group className={`mb-30 animated ${(this.state.focusCompany || (this.props.company && this.props.company.length > 0)) ? 'focused' : ''}`}
											validationState={(this.props.company && this.props.company.length > CompanyNameLimit) ? 'error' : ''}>
											<Form.Label>Enter your company name</Form.Label>
											<Form.Control
												type="text"
												className="text-md box-shadow shadow-lighter no-border h-60"
												value={this.props.company}
												onFocus={() => this.setState({ focusCompany: true })}
												onBlur={e => this.setState({ focusCompany: false })}
												onChange={e => this.props.handleSignupCompanyChange({ newValue: e.target.value }) + this.setState({ showGenericError: false, isProcessing: false })} />
											{this.props.company && this.props.company.length > CompanyNameLimit &&
												<Form.Text className="text-sm">
													Company name is {this.props.company.length - CompanyNameLimit} characters too long.
                                            </Form.Text>
											}
										</Form.Group>
									</div>
								</div>
							}

							{this.props.AddressDetails &&
								<>
									<div className="row">
										<div className="col-lg-6 col-md-12 pr-5 pr-md-15 mb-md-20">
											<label className={`${this.props.hideLabels && 'hidden'}`}>Address</label>
											<input type="text" placeholder="1st line of address" className="form-control h-60px" />
										</div>
										<div className="col-lg-6 col-md-12 pl-5 pl-md-15">
											<label className={`${this.props.hideLabels && 'hidden'}`}>Postcode</label>
											<input type="text" placeholder="Postcode" className="form-control h-60px" />
										</div>
									</div>
								</>
							}

							{this.props.PortingNumber &&
								<div className="row">
									<div className="col-sm-12">
										<p className="text-500 text-sm text-center text-lightest">Enter the number you would like to transfer to us</p>
										<Form>
										<Form.Group className={`mb-30 animated ${(this.state.focusPortPhone || (this.state.portPhone && this.state.portPhone.length > 0)) ? 'focused' : ''}`}>
											<Form.Label>Enter number to transfer</Form.Label>
											<Form.Control
												type="tel"
												className="text-md box-shadow shadow-lighter no-border h-60"
												value={this.state.portPhone}
												onFocus={() => this.setState({ focusPortPhone: true })}
												onBlur={e => this.setState({ focusPortPhone: false })}
												onChange={e => this.setState({ portPhone: e.target.value, showGenericError: false, isProcessing: false })} />
										</Form.Group>
										</Form>
									</div>
								</div>
							}

							{this.props.showUsers &&
								<div className="p-50 bg-light mb-xxs-60 extra-users box-shadow shadow-lighter brad-5 p-xs-20 mb-10">
									<h5 className="text-500 pt-0 mt-0">
										<span className="text-cta text-xs-md">Add Extra Users</span>
										<br className=" d-block d-xs-none" />
										<span className="text-dark ml-5">
											(Just &pound;{this.props.basket.package?.rental} Each)
										</span>
									</h5>
									<p className="text-sm text-400 clearfix text-dullest">+ VAT monthly per user</p>
									<div className="add-users block">
										<form className="qty mt-0">
											<input
												type="button"
												className="btn btn-dull no-shadow"
												onClick={() => this.props.handleUpdateUsers(true)}
												value="-"
												disabled={this.props.userQty < 1}
											/>
											<input
												type="text"
												id="number"
												className="form-control"
												value={this.props.userQty}
											/>
											<input
												type="button"
												className="btn btn-dull no-shadow"
												onClick={() => this.props.handleUpdateUsers()}
												value="+"
											/>
										</form>

										{this.state.showDetails
										  ? <>
												<p className="w-100 text-sm text-500 text-primary mt-20 mb-20 text-start">
													Included with each user:
												</p>
												<ul className="tick-list text-start mt-0 text-dark ml-0">
													<li>
														<span className="text-500 mr-5">
															{(this.props.basket.package?.seconds ?? 0) / 60}
														</span>
														additional minutes of outbound calling
													</li>
													<li>
														1 extension number, free calling app and softphone license
													</li>
													<li>Unlimited calls and transfers between user extensions</li>
													<li>
														Display any of your numbers when making an outgoing call
													</li>
													<li>
														Ability to make and receive calls anywhere in the world at UK
														call rates
													</li>
												</ul>
											<p className="text-sm underline cursor-pointer text-dark mt-20 mb-0" onClick={() => this.setState({ showDetails: false })}>Hide details</p>
											</>
										  :											<p className="text-sm underline cursor-pointer text-dark mt-20 mb-0" onClick={() => this.setState({ showDetails: true })}>View more details</p>
										}
									</div>
								</div>
							}

							{this.props.showFees &&
								<div className="row">
									<div className="col-sm-12 text-center">
										<h5 className="text-500 text-lightest mb-0">Setup Fee: <span className="text-primary">&pound;{this.props.setupFee !== undefined ? this.props.setupFee : 0.00}</span></h5>
										<h5 className={'text-500 text-lightest mt-5 mb-40'}>
											Monthly Fee: <span className="text-primary">&pound;{total.toFixed(2)}{GlobalOfferEnabled && this.props.offerAvailable === true && <sup>*</sup>}</span>
										</h5>
									</div>
								</div>
							}

							<div className="row mb-20">
								<div className="col-sm-12 text-end">
									{this.state.showGenericError &&
										<p className="text-400 text-center text-danger text-sm mt-0">{this.state.errorMsg}</p>
									}
									{this.state.isProcessing
									  ? <LoadingNotification className="mt-30" message={'Processing...'} isLoading={true} />
									  :										<Button size="lg" variant="cta" className="btn-block pl-xs-25 pr-xs-25" onClick={() => this.handleSubmit(this.props.basket)}
											disabled={
												this.props.signupValidation.emailValidationState !== validationStates.VALID ||
												this.props.signupValidation.phoneValidationState !== validationStates.VALID
											}>
											{`${this.props.ButtonText ? this.props.ButtonText : 'Send details'}`}
										</Button>
									}
									{GlobalOfferEnabled === true && this.props.offerAvailable === true && this.props.basket && this.props.basket.package &&
										<p className="mb-0 text-center text-sm mt-20">
											<sup>* </sup>
										Your <span className="text-600 underline">{this.props.basket && this.props.basket.package.name}</span> plan is just <span className="text-600 underline">
												{`${(NumbersGlobalOfferPrice < 1
													? `${(NumbersGlobalOfferPrice % 1).toFixed(2).substring(2)}p`
: `�${NumbersGlobalOfferPrice}`)
													}`}</span>

											{numberOfferDuration > 1
											  ? ` for your first ${numberOfferDuration} months`
											  : ' for your first month'}
											<br />
										followed by <span className="text-600 underline">&pound;{this.props.basket && this.props.basket.package.rental}</span> per month thereafter.
									</p>
									}
									{this.props.PortingNumber &&
										<p className="text-400 pt-20 text-center">
											Or, call our porting team today on <a href="tel:0203 916 5698" className="text-500">0203 916 5698</a>.
                                </p>
									}

								</div>
							</div>
						</>
					}

				</div>

				{this.props.showFees && !this.props.showTrustBadges &&
					<img src={'/images/credit-cards/stripe-badge-transparent.png'} width="320" className="worldPay"
						alt="Payments powered by Stripe" />
				}

				{this.props.showTrustBadges &&
					<img src="/images/trust-badges.jpg" alt="Secure Checkout" className="mt-20 d-block mx-auto col-12 col-md-8" />
				}

				<Modal size="sm" className="address-modal" show={this.state.showBillingInfo} >
					<Modal.Header>
						<Modal.Title className="text-500">Enter your billing details</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-0">
						<div className="direction-block dir-down bb-1 mb-20">
							{(this.state.showPostcodeSearch === true) &&
								<AddressLookup showDeliveryAddress={false} />
							}
						</div>

						<div className="p-30 text-center">
							<p className="text-sm">
								Total payment today: &pound;{(total + this.props.setupFee).toFixed(2)} (&pound;{calculateVat(total + this.props.setupFee).toFixed(2)} incl vat.)<br />
							</p>

							<p className="text-sm">You'll then be billed &pound;{total} on the {moment().format('Do')} of each month.</p>

							<Button variant="cta" size="xl" className="btn-block text-lg" onClick={() => this.handleCreateAccount(total, this.props.basket)} disabled={this.state.isCreating}>
								Finish &amp; Pay
                            </Button>

							{GlobalOfferEnabled === true && this.props.offerAvailable === true && this.props.basket && this.props.basket.package &&
								<p className="mb-0 text-center text-sm mt-20 text-italic text-dullest">
									<sup>* </sup> {numberOfferDuration > 1
									  ? ` Price valid for your first ${numberOfferDuration} months`
									  : ' Price valid for your first month'} followed by <span className="text-600 underline">
										&pound;{((total - NumbersGlobalOfferPrice) + this.props.basket.package.rental).toFixed(2)}</span> per month thereafter.
									</p>
							}

							<Button variant="link" className="mt-30 underline text-dark text-xs" onClick={e => this.handleCancelSignup()}>Cancel checkout</Button>
						</div>
					</Modal.Body>
				</Modal>

				<Modal className="text-center" size="sm" show={this.state.showWPNotice}>
					<Modal.Body>
						<LoadingNotification className="mt-30" message={'Loading...'} isLoading={true} />
						<p className="lead">
							We're going to take you to Worldpay to process your secure payment.<br />Please wait...
                        </p>
					</Modal.Body>
					<Modal.Footer className="text-center bg-dull">
						<img src="/images/wp-logo.png" alt="Powered by Worldpay" />
					</Modal.Footer>
				</Modal>

				<ChoosePayment
					clientId={this.state.clientId}
					total={calculateVat(total).toFixed(2)}
					basket={this.state.basket}
					returnRoute="/signup/checkout/complete"
					purchaseData={this.state.key}
					email={this.props.email}
					name={this.props.name}
					phone={this.props.phone}
					address={this.props.billing.address}
					signupData={this.state.signupData}
					mandate={!this.props.isLoggedIn || this.props.isLoggedIn && !this.props.services.hasAgreement}
				/>

			</>
    )
  }

  handleSubmit (basket) {
    this.props.validateNameField({ newValue: this.props.name ? this.props.name : '' })
    this.props.validateSignupEmail({ newValue: this.props.email ? this.props.email : '' })
    this.props.validateSignupPhone({ newValue: this.props.phone ? this.props.phone : '' })

    if (!basket && this.props.showFees) {
      this.setState({ showGenericError: true, errorMsg: 'Please choose your plan and number first' })
    } else if ((basket && !basket.numbers) || (basket && basket.numbers && basket.numbers.length === 0)) {
      this.setState({ showGenericError: true, errorMsg: 'Please choose atleast 1 number' })
    } else if (!this.props.name || this.props.name && this.props.name.length === 0) {
      this.setState({ showGenericError: true, errorMsg: 'Please enter your name' })
    } else if (this.props.name && this.props.name.length > NameLimit) {
      this.setState({ showGenericError: true, errorMsg: 'Your name is too long' })
    } else if (this.props.signupValidation.nameValidationState !== validationStates.VALID) {
      this.setState({ showGenericError: true, errorMsg: 'Please enter a valid name' })
    } else if (!this.props.phone || this.props.phone && this.props.phone.length === 0) {
      this.setState({ showGenericError: true, errorMsg: 'Please enter your phone number' })
    } else if (this.props.signupValidation.phoneValidationState !== validationStates.VALID) {
      this.setState({ showGenericError: true, errorMsg: 'Please enter a valid telephone number' })
    } else if (this.props.company && this.props.company.length > CompanyNameLimit) {
      this.setState({ showGenericError: true, errorMsg: 'Your company name is too long' })
    } else if (!this.props.email || this.props.email && this.props.email.length === 0) {
      this.setState({ showGenericError: true, errorMsg: 'Please enter your email address' })
    } else if (this.props.email && this.props.email.length > EmailLimit) {
      this.setState({ showGenericError: true, errorMsg: 'Your email address is too long' })
    } else if (this.props.signupValidation.emailValidationState !== validationStates.VALID) {
      this.setState({ showGenericError: true, errorMsg: 'Please enter a valid email address' })
    } else {
      const userDetails = Object.assign(
        { name: this.props.name },
        { email: this.props.email },
        { company: this.props.company },
        { phone: this.props.phone }
      )
      localStorage.setItem('userDetails', JSON.stringify(userDetails))
      localStorage.removeItem('goingBack')

      if (this.props.ContinueToSignup || this.props.basket) {
        localStorage.setItem('Basket', JSON.stringify(basket))
        localStorage.setItem('landingPage', true)
        localStorage.removeItem('signupKey')

        this.props.history.push('/get-started')
      } else {
        // Submit lead
        const url = 'api/Home/SubmitNewLead'

        const data = {
          Name: this.props.name,
          Email: this.props.email,
          Phone: this.props.phone,
          Company: this.props.company,
          Note: `Port enquiry. Number to port = ${this.state.portPhone}`,
          Subject: 'New porting reuqest'
        }

        fetchHelper.postJson(url, data)
          .then(res => {
            if (res.data === true || res.data.success === true) {
              this.setState({ showSuccess: true })
            } else {
              this.setState({ showGenericError: true, errorMsg: 'Something went wrong. Please try again, or contact our support team.' })
            }
          }).catch(err => console.error(err))
      }
    }
  }

  handleCancelSignup () {
    this.setState({ showBillingInfo: false, isProcessing: false, checkingOut: false })
  }
}

export default connect(
  state => {
    const billingData = state.sbfApp.billingData
    const signupFields = state.signup.signupFields

    return {
      email: signupFields.signupEmail,
      name: signupFields.signupName,
      phone: signupFields.signupPhone,
      company: signupFields.signupCompanyName,
      signupValidation: {
        nameValidationState: getSignupNameValidationState(state),
        emailValidationState: getSignupEmailValidationState(state),
        emailAlreadyInUse: signupEmailInUseValidationState(state),
        phoneValidationState: getSignupPhoneValidationState(state)
      },
      isStep2Visible: getIsStep2Ready(state),
      isStep2Reached: getIsStep2Reached(state),
      billing: {
        address: {
          name: billingData.billingName,
          add1: billingData.billingAdd1,
          add2: billingData.billingAdd2,
          town: billingData.billingTown,
          county: billingData.billingCounty,
          pcode: billingData.billingPcode
        },
        cardNumber: billingData.billingCardNumber,
        cardExpiry: billingData.billingCardExpiry,
        cardCode: billingData.billingCardCode
      },
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    validateSignupEmail: fromSignup.validateSignupEmail,
    validateSignupPhone: fromSignup.validatePhone,
    validateNameField: fromSignup.validateNameField,
    validateCompanyName: fromSignup.validateCompanyName,
    handleSignupNameChange: fromSignup.handleSignupNameChange,
    handleSignupCompanyChange: fromSignup.handleSignupCompanyChange,
    handleSignupEmailChange: fromSignup.handleSignupEmailChange,
    handleSignupPhoneChange: fromSignup.handleSignupPhoneChange,
    handleValidateSignupForm: fromSignup.handleValidateSignupForm,
    handleUserWantsStep2: fromSignup.handleUserWantsStep2,
    handleLogin: handleBELogin,
    handleShowPaymentOptionsModal,
    handleUserWantsStep2: fromSignup.handleUserWantsStep2
  }
)(LeadGenForm)
