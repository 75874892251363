import React from 'react'
import { connect } from 'react-redux'

// ######################################
//
//    Example 1: default styles will be applied.
//
//      <PromptsExamples />
//
//
//    Example 2: example with all available custom classes/styling enabled.
//
//       <PromptsExamples PromptsWrapperClass="bg-dark text-lightest p-30 brad-20 mb-20" HeadingsClass="h5 text-cta" IntrodutionClass="text-primary text-400" PromptExampleClass="text-danger text-500" />
//
// ######################################

class PromptsExamples extends React.Component {
  state = {
    PromptsWrapperClass: this.props.PromptsWrapperClass === undefined ? 'bg-light brad-10 p-20 mb-20 mr-10 b-2' : this.props.PromptsWrapperClass,
    HeadingsClass: this.props.HeadingsClass === undefined ? 'text-500 mt-0 pt-0 text-cta' : this.props.HeadingsClass,
    IntrodutionClass: this.props.IntrodutionClass === undefined ? 'text-sm pb-20' : this.props.IntrodutionClass,
    PromptExampleClass: this.props.PromptExampleClass === undefined ? 'text-sm' : this.props.PromptExampleClass
  }

  constructor (props) {
    super(props)
  }

  render () {
    return <>
                    <div className={this.state.PromptsWrapperClass}>
                        <h6 className={this.state.HeadingsClass}>
                            Standard Introduction
                        </h6>
                        <p className={this.state.IntrodutionClass}>
                            This is the first thing your callers will hear when you don&apos;t have multiple departments set.
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example #1</span>
                            <br/>
                            <i>Hello and thank you for calling (company name). Please wait while we connect your call.</i>
                        </p>
                    </div>

                    <div className={this.state.PromptsWrapperClass}>
                        <h6 className={this.state.HeadingsClass}>
                            Global Department Introduction
                        </h6>
                        <p className={this.state.IntrodutionClass}>
                            The first message played to your callers when you have multiple department set up.
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example #1</span>
                            <br/>
                            <i>Hello and thank you for calling (company name). Please choose one of the following options...</i>
                        </p>
                    </div>

                    <div className={this.state.PromptsWrapperClass}>
                        <h6 className={this.state.HeadingsClass}>
                            Department Menu
                        </h6>
                        <p className={this.state.IntrodutionClass}>
                            Message listing all available department options.
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example #1</span>
                            <br/>
                            <i>Press 1 for General Enquiries, press 2 for Sales and press 3 for Accounts... etc.</i>
                        </p>
                    </div>

                    <div className={this.state.PromptsWrapperClass}>
                        <h6 className={this.state.HeadingsClass}>
                            Individual departments introduction
                        </h6>
                        <p className={this.state.IntrodutionClass}>
                            The first thing your callers will hear once they selected a department number (if enabled).
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example for department #1</span>
                            <br/>
                            <i>We are connecting you to our next available consultant*</i>
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example for department #2</span>
                            <br/>
                            <i>We are connecting you to our next available sales representative*</i>
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example for department #3</span>
                            <br/>
                            <i>We are connecting you to our next available accounts manager*</i>
                        </p>
                        <p className="text-xs text-italic">
                            * These can be changed to terminology you’d prefer.
                        </p>
                    </div>

                    <div className={this.state.PromptsWrapperClass}>
                        <h6 className={this.state.HeadingsClass}>
                            Call Screening
                        </h6>
                        <p className={this.state.IntrodutionClass}>
                            Call Screening is a short message the person answering the call will hear before he or she even connects with the caller. This allows you to decide whether you want to answer the call or not. Also, this option also helps you to answer the call appropriately.
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example for department #1</span>
                            <br/>
                            <i>You have a general enquiries call, press the star (*) key to accept the call or press 3 to send the caller to voicemail.</i>
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example for department #2</span>
                            <br/>
                            <i>You have a sales call, press the star (*) key to accept the call or press 3 to send the caller to voicemail.</i>
                        </p>
                    </div>

                    <div className={this.state.PromptsWrapperClass}>
                        <h6 className={this.state.HeadingsClass}>
                            Out of Hours
                        </h6>
                        <p className={this.state.IntrodutionClass}>
                            This is the message your callers will hear if your lines are closed.
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example #1</span>
                            <br/>
                            <i>Thank you for calling (company name), I’m afraid we are now closed. If you would like to leave your name, number and a brief message, somebody will contact you within office hours, thank you.</i>
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example #2</span>
                            <br/>
                            <i>Thank you for calling (company name), I’m afraid we are now closed. Our offices hours are Monday to Friday 9am till 5.30pm. Could you please leave your name, number and a brief message and somebody will contact you within office hours, thank you.</i>
                        </p>
                    </div>

                    <div className={this.state.PromptsWrapperClass}>
                        <h6 className={this.state.HeadingsClass}>
                            Voicemail
                        </h6>
                        <p className={this.state.IntrodutionClass}>
                            This is the message your callers will hear if your lines are closed.
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example #1</span>
                            <br/>
                            <i>Thank you for calling (company name), I am afraid all our advisors are busy at the moment, please leave your name, number and a brief message and we will call you back as soon as possible.</i>
                        </p>
                        <p className={this.state.PromptExampleClass}>
                            <span className="text-500">Example #2</span>
                            <br/>
                            <i>I’m afraid we are busy at (company name), so please leave your name, number and a brief message and we will contact you shortly. Thank you.</i>
                        </p>
                    </div>
            </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(PromptsExamples)
