import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { numberPresentationOptions } from '../../constants/ConfigurationConstants'
import * as configurationActions from '../../store/Configuration'
import NumberPresentationSectionHelperDialog from './InstructionHelperDialogs/NumberPresentationSectionHelperDialog'

const NumberPresentationSection = props => (
    <>
    <div className="row bg-dullest p-30 zoom">
        <div className="col-md-5 pb-sm-10">
            <h5 className="text-secondary text-500 text-lg">Number Presentation

                </h5>
                <p className="text-400">
                When a caller dials your number, you can either display the number they’re dialling from
                or your own SwitchboardFREE number. <a onClick={props.handleShowNumberPresentationHelperInstruction} className="underline" id="NumberPresentationMoreInfoLink">More info</a>
                </p>
            </div>
            <div className="col-md-7 mt-10">
            <p>Select number:</p>
            <NumberPresentationSectionHelperDialog/>
            <Select
                id="numberPresentationSelect"
                className="form-control select-menu"
                classNamePrefix="custom"
                onChange={props.handleNumberPresentationChange}
                options={numberPresentationOptions}
                simpleValue
                name="selectedNumberPresentation"
                value={props.selectedNumberPresentationOption !== undefined
                  ? {
                      value: props.selectedNumberPresentationOption.numberPresentationOptionKey,
                      label: props.selectedNumberPresentationOption.numberPresentationOptionText
                        ? props.selectedNumberPresentationOption.numberPresentationOptionText
                        : numberPresentationOptions.filter(op => op.value === props.selectedNumberPresentationOption.numberPresentationOptionKey)[0].label
                    }
                  : undefined }
                searchable={false}
                clearable={false}
            />
            </div>
            </div>
    </>
)

const mapStateToProps = state => ({
  selectedNumberPresentationOption: state.configuration.configuration.numberPresentation
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberPresentationSection)

// export default connect(
//     state => state.configuration,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(IntroductionSection);
