import React from 'react'
import { Accordion, Button, OverlayTrigger, Popover } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import Select from 'react-select'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import LoadingNotification from '../../components/LoadingNotification'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import { MultiNumberPrice } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat, getQueryStringFromProps } from '../../helpers/utils'
import { handleNotificationChange, handleShowBillingWarning, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'

const _ = require('lodash')

class NumbersMultiPackage extends React.Component {
  state = {
    howManyNumbers: 5,
    regions: [],
    availableNumbers: [],
    selectedRegions: [],
    selectedNumbers: [],
    isLoadingRegions: false,
    packages: [],
    isLoadingNumbersForChangedRegion: [],
    showPacks: true,
    isPurchaseInProgress: false
  }

  constructor (props) {
    super(props)
  }

  render () {
    const hasNotSelectedFullQuotaOfNumbers = _.filter(this.state.selectedNumbers, num => num).length !== this.state.availableNumbers.length
    return <>
            <ScrollToTopOnMount/><DisplayLoggedInFooter/><DisplayMainHeader/>
            {/* <UpgradesMenu/> */}
            <Helmet>
                <title>Multi Number Packages - SwitchboardFREE</title>
                <meta name="description" content="Get Multiple virtual number as part of a single package - a cost effecting way of having numbers throughout the UK." />
            </Helmet>
            <header>
                <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/multinumbers-bg.jpg')" }}>
                    <h1 id="MultiNumberPackagesH1">Multi-Number Packages</h1>
                    <h2 className="subtitle">A cost effective way of having multiple numbers throughout the UK.</h2>
                </div>
            </header>
            <div className="container-styled bg-dull">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="text-md pt-30">
                                A Multi Number package allows you to have a set of numbers that all share the same allowance of minutes making it a <br className="d-none d-lg-block"/>cost effective way
                                to take advantage of having more than one business number. Simply select how many numbers <br className="d-none d-lg-block"/>
                                you need, select the individual numbers you'd like &amp; how many call minutes you require.
                            </p>
                            <p className="text-md pt-30">
                                For just <span className="text-500">&pound;1.99 per number / per month</span> and a variety of <span className="text-500">minutes packages starting from just £5.00</span> <br className="d-none d-lg-block"/>
                                this is a cost effective way of having a local presence throughout the UK.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="pt-30 pb-30 text-center mt-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto pl-xxs-0 pr-xxs-0">
                            <div className="w-700px bg-light b-1 brad-10 p-20 mb-30 center-block">
                                <h5 className="pb-30 text-400">
                                    <span className="text-500 text-italic pr-10">Step 1: </span>
                                    <span className="text-400">Select how many numbers you need</span>
                                </h5>
                                <p>
                                    <Button variant="cta" className="m-5"
                                        onClick={event => this.setState({ howManyNumbers: 5 })}>5 Numbers</Button>

                                    <Button variant="cta" className="m-5"
                                        onClick={event => this.setState({ howManyNumbers: 10 })}>10 Numbers</Button>

                                    <Button variant="cta" className="m-5"
                                        onClick={event => this.setState({ howManyNumbers: 20 })}>20 Numbers</Button>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8 mx-auto pl-xxs-0 pr-xxs-0 pt-30 pb-60">
                            <div className="divider text-center w-700px">
                                <span className="icon icon-arrow-down2 bg-dull"></span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8 mx-auto pl-xxs-0 pr-xxs-0">
                            <div className="w-700px bg-light b-1 brad-10 p-20 center-block">
                                <h5 className="pb-30 text-400">
                                    <span className="text-500 text-italic pr-10">Step 2: </span>
                                    <span className="text-400">Select your numbers</span>
                                </h5>
                                <div className="row text-center text-500 pb-20">
                                    <div className="col-md-6 d-none d-xs-block ">
                                        Town / City
                                    </div>
                                    <div className="col-md-6 d-none d-xs-block ">
                                        Phone Number
                                    </div>
                                </div>
                                {this.state.isLoadingRegions && <LoadingNotification textColor={'text-dark'} message={'Loading ...'} isLoading={true} />}

                                    {!this.state.isLoadingRegions && this.state.selectedRegions.map((selected, index) => {
                                      return <>
                                        <div className="row text-start mb-30 multi-numbers-box">
                                            <div className="col-xxs-12 col-xs-6 pr-5 pr-xxs-15">
                                                <span className="d-inline-block d-xs-none text-500 text-xs pt-15">City:</span>
                                                <Select
                                                    className="form-control select-menu"
                                                    classNamePrefix="custom"
                                                    onChange={value => this.handleRegionChange(value.value, index)}
                                                    options={this.state.regions}
                                                    simpleValue
                                                    name={`selectedRegion_${index}`}
                                                    value={this.state.selectedRegions[index]}
                                                    searchable={true}
                                                    clearable={false}
                                                />
                                            </div>
                                            <div className="col-xxs-12 col-xs-6 mt-xxs-5 pl-5 pl-xxs-15">
                                                <span className="d-inline-block d-xs-none text-500 text-xs pt-15">Number:</span>
                                                <Select
                                                    className="form-control select-menu"
                                                    classNamePrefix="custom"
                                                    onChange={value => this.handleNumberChange(value.value, index)}
                                                    options={this.state.availableNumbers[index]}
                                                    simpleValue
                                                    name={`selectedNumbers_${index}`}
                                                    value={this.state.selectedNumbers[index]}
                                                    searchable={true}
                                                    clearable={false}
                                                    isLoading={this.state.isLoadingNumbersForChangedRegion[index]}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    })}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8 mx-auto pl-xxs-0 pr-xxs-0 pt-60 pb-60">
                            <div className="divider text-center w-700px">
                                <span className="icon icon-arrow-down2 bg-dull"></span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8 mx-auto pl-xxs-0 pr-xxs-0">
                            <div className="w-700px bg-light b-1 brad-10 p-20 mb-30 center-block">
                                <h5 className="pb-10 text-400">
                                    <span className="text-500 text-italic pr-10">Step 3: </span>
                                    <span className="text-400">Select amount of minutes</span>
                                </h5>
                                {this.renderPackages()}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8 mx-auto pl-xxs-0 pr-xxs-0 pt-30 pb-20">
                            <div className="divider text-center w-700px">
                                <span className="icon icon-arrow-down2 bg-dull"></span>
                            </div>
                        </div>
                    </div>

                        <div className="row mt-40">
                            <div className="col-md-8 mx-auto pl-xxs-0 pr-xxs-0">
                                <div className="w-700px bg-light b-1 brad-10 p-20 mb-30 center-block">
                                    {this.renderFooterText()}
                                    {this.renderTotal()}

                                    <Button variant="cta" size="lg" onClick={event => this.purchaseNumbers()}
                                    disabled={!this.state.selectedPackage || this.state.selectedNumbers.length === 0 || this.state.saving || hasNotSelectedFullQuotaOfNumbers || this.state.isPurchaseInProgress}
                                    className="btn-xxs-block mt-10 mb-20"><span className="d-none d-xs-inline-block">Confirm &amp;</span> Checkout <i className="icon icon-arrow-right4 text-sm"/></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChoosePayment
                total={calculateVat(this.getTotal())}
                clientId={this.props.clientId}
                purchaseData={this.state.key}
                email={this.props.userDetails.email}
                name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                mandate={!this.props.services.hasAgreement}
            />

        </>
  }

  purchaseNumbers () {
    if (this.props.displayBillingWarning === true) {
      this.props.handleShowBillingWarning()
    } else {
      const context = this
      const url = '/api/Purchase/PurchaseMultiNumberPackage'

      context.setState({ isPurchaseInProgress: true }, () => {
        const ddiids = _.map(context.state.selectedNumbers, function (no) {
          return Number(no.value)
        })

        const packageId = context.state.selectedPackage.id
        const data = { Ddiids: ddiids, SelectedPackage: packageId }

        fetchHelper.postJson(url, data).then(res => {
          if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
            context.props.history.push('/customer/numbers&newmultinumber=true')
          } else if (res.data && res.data.postponed === true) {
            context.props.handleShowPaymentPostponedModal()
          } else {
            context.setState({ key: res.data.key })
            context.props.handleShowPaymentOptionsModal()
          }
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)
        })
      })
    }
  }

  renderTotal () {
    if (!this.state.selectedPackage) return
    const total = this.getTotal()
    return (
            <h5 className="text-500 mt-30 mb-20 text-xxs-md" id="TotalCost">
                Total cost: £{total.toFixed(2)} <span className="text-xs">/month</span>
            </h5>
    )
  }

  getTotal () {
    return this.state.selectedPackage ? (MultiNumberPrice * this.state.howManyNumbers) + this.state.selectedPackage.price : 0.0
  }

  renderFooterText () {
    const selectedPackage = this.state.selectedPackage

    if (!selectedPackage) {
      return (
                <p className="text-500">
                    To continue, please select your minutes package above.
                </p>
      )
    }

    let minutesText = ''
    let sharedMinutesInfo = ''
    let includeNewLine = false
    let includeDoubleNewLine = false

    if (selectedPackage.mobile > 0 && selectedPackage.landline === 0) {
      minutesText = `${selectedPackage.mobile} minutes to mobile`
      includeNewLine = true
      sharedMinutesInfo = `Please note, those ${selectedPackage.mobile} minutes will be shared across all of your numbers.`
    } else if (selectedPackage.mobile === 0 && selectedPackage.landline > 0) {
      minutesText = `${selectedPackage.landline} minutes to landline`
      includeNewLine = true
      sharedMinutesInfo = `Please note, those ${selectedPackage.landline} minutes will be shared across all of your numbers.`
    } else {
      minutesText = `a total of ${selectedPackage.landline + selectedPackage.mobile} minutes, equally split between mobile & landline (${selectedPackage.landline} minutes each)`
      includeDoubleNewLine = true
      sharedMinutesInfo = `Please note, those ${selectedPackage.landline + selectedPackage.mobile} minutes will also be shared across all of your selected numbers.`
    }

    return (
            <>
                <h4 className="text-500">Order summary</h4>
                <h6 className="text-400 pt-20" id="FooterText">
                    You've selected {this.state.howManyNumbers} numbers with {minutesText}. {includeNewLine && <br/>}{includeDoubleNewLine && <><br/><br/></>}{sharedMinutesInfo}
                </h6>
            </>
    )
  }

  componentDidMount () {
    document.title = 'Multi-Numbers Packages'
    const context = this

    const howManyNumbers = context.state.howManyNumbers

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => this.getGeoRegionsSelectOptions(pos, howManyNumbers), err => this.getGeoRegionsSelectOptions(err, howManyNumbers))
    } else {
      this.getGeoRegionsSelectOptions(undefined, context.state.howManyNumbers)
    }

    const url = 'api/Purchase/GetMultiNumberPackagePricing'

    fetchHelper.getJson(url).then(response => {
      context.setState({ packages: response.data })
    }).catch(error => console.error(error))

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('new=true')) {
        context.props.notify({ message: 'Purchase successful' })
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const context = this

    if (prevState.howManyNumbers !== this.state.howManyNumbers) {
      // this.getGeoRegionsSelectOptions(undefined, this.state.howManyNumbers);
      const howManyNumbers = context.state.howManyNumbers

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => this.getGeoRegionsSelectOptions(pos, howManyNumbers), err => this.getGeoRegionsSelectOptions(err, howManyNumbers))
      } else {
        this.getGeoRegionsSelectOptions(undefined, context.state.howManyNumbers)
      }
    }
  }

  handleRegionChange (value, index) {
    const newRegion = _.find(this.state.regions, function (region) {
      return region.value === value
    })

    newRegion.numberGroupIndex = index

    const selectedRegions = this.state.selectedRegions
    const newRegions = [...selectedRegions.slice(0, index), newRegion, ...selectedRegions.slice(index + 1)]
    const newIsLoadingNumbersForChangedRegion = [...this.state.isLoadingNumbersForChangedRegion.slice(0, index), true, ...this.state.isLoadingNumbersForChangedRegion.slice(index + 1)]

    this.setState({
      selectedRegions: newRegions,
      isLoadingNumbersForChangedRegion: newIsLoadingNumbersForChangedRegion
    })

    const url = 'api/Purchase/GetAvailableGeoNumbersForPrefix/'
    const context = this
    const selectedDdiids = this.state.selectedNumbers.filter(function (ddiid) {
      if (ddiid === null) {
        return false // skip
      }
      return true
    }).map(num => num.value)

    fetchHelper.postJson(url, { prefix: newRegion.value, selectedDdiids }).then(response => {
      const availableNumbers = context.state.availableNumbers
      const selectedNumbers = context.state.selectedNumbers
      const newAvailableNumbers = [...availableNumbers.slice(0, index), response.data, ...availableNumbers.slice(index + 1)]
      const newSelectedNumbers = [...selectedNumbers.slice(0, index), newAvailableNumbers[index][0], ...selectedNumbers.slice(index + 1)]
      const newIsLoadingNumbersForChangedRegion = [...this.state.isLoadingNumbersForChangedRegion.slice(0, index), false, ...this.state.isLoadingNumbersForChangedRegion.slice(index + 1)]
      context.setState({ availableNumbers: newAvailableNumbers, selectedNumbers: newSelectedNumbers, isLoadingNumbersForChangedRegion: newIsLoadingNumbersForChangedRegion })
    })
      .catch(error => {
        return console.error(error)
      })
  }

  handleNumberChange (value, index) {
    const newNumber = _.find(this.state.availableNumbers[index], function (number) {
      return number.value === value
    })

    const numberAlreadyChosen = _.some(this.state.selectedNumbers, num => num.value === newNumber.value)

    if (!numberAlreadyChosen) {
      const newSelectedNumbers = [...this.state.selectedNumbers.slice(0, index), newNumber, ...this.state.selectedNumbers.slice(index + 1)]
      this.setState({ selectedNumbers: newSelectedNumbers })
    }
  }

  getGeoRegionsSelectOptions (position, howManyNumbers) {
    const url = 'api/Purchase/GetGeoRegionsSelectOptions/'
    const context = this

    const selectedDdiids = this.state.selectedNumbers.map(num => num.value)
    let data = { howManyNumbers, selected: selectedDdiids }

    if (position && position.coords) {
      data = {
        howManyNumbers,
        selected: this.state.selectedNumbers.map(num => num.value),
        longitude: position.coords.longitude,
        latitude: position.coords.latitude
      }
    }

    const isLoadingNumbersForChangedRegion = _.range(0, howManyNumbers).map(pos => false)

    context.setState({ isLoadingRegions: true, isLoadingNumbersForChangedRegion })

    fetchHelper.postJson(url, data).then(response => {
      if (response && response.data) {
        const regions = response.data.geoRegionsSelectOptions
        const availableNumbers = response.data.availableNumbersSelectOptions
        const selectedRegions = _.range(0, this.state.howManyNumbers).map(function (index) {
          return _.find(regions, function (region) {
            return region.numberGroupIndex === index
          })
        })

        const selectedNumbers = _.map(availableNumbers, function (numbers) {
          return numbers[0]
        })

        // regions contains all of the available regions, e.g, Norwich, Lowestoft, Aberdeen etc
        // available numbers contains an array with a selection of purchasable numbers for each selected region
        // selected regions contains an array of the selected regions currently chosen in the drop downs
        context.setState({
          regions,
          availableNumbers,
          selectedRegions,
          selectedNumbers,
          isLoadingRegions: false
        })
      }
    }).catch(error => {
      context.setState({ isLoadingRegions: false })
      return console.error(error)
    })
  }

  renderPackages () {
    function renderSplitPackInfoPopover (mins, total) {
      return (
                <Popover id={'popover'.concat(mins)} title="More information">
                    <p className="text-400">This package includes a total of {total} minutes, equally split between mobile &amp; landlines ({mins} mins to each).</p>
                </Popover>
      )
    }

    const packages = this.state.packages
    const packageGroups = _.groupBy(packages, function (p) {
      return p.mobile + p.landline
    })

    const context = this

    return <>
            <Accordion className="br-0 bb-0 bl-0 bt-0 box-shadow-none bg-transparent-fully multi-numbers-box" expanded={this.state.showPacks}>
                <div>
                    <Accordion.Item className="pt-0 pl-xxs-5 pr-xxs-5">
                        {_.map(packageGroups, function (group, index) {
                          return <>
                                <div className="row ">
                                    {/* <div className="col-xs-12 text-center pt-30">
                                        <h6 className="text-400">{group[0].landline} minutes packages</h6>
                                    </div> */}
                                    {group[0] !== undefined
                                      ? <div className={'col-xs-4 col-xxs-12 pb-10 pt-10 pl-5 pr-5 '.concat(context.state.selectedPackage && context.state.selectedPackage.id === group[0].id ? ' selected' : '')}>
                                        <div className="bg-light pt-20 pb-20 pl-5 pr-5 brad-20 b-1">
                                            <SvgRender icon="selected" />
                                            <h6 className="text-400">{group[0].landline} mins <br/><span className="text-xs">to landline</span></h6>
                                            <p className="mt-20 mb-20 text-500">&pound;{group[0].price} <span className="text-xs">/pm</span></p>
                                            <Button variant="cta" size="xs" onClick={event => context.setState({ selectedPackage: group[0] })}>Select</Button>
                                            <Button variant="secondary" size="xs">Selected</Button>
                                        </div>
                                    </div>
                                      : <div className={'col-xs-4 col-xxs-12 pb-10 pt-10 pl-5 pr-5 '}>
                                        <div className="bg-light pt-20 pb-20 pl-5 pr-5 brad-20 b-1">
                                            <SvgRender icon="selected" />
                                            <h6 className="text-400">Error <br/><span className="text-xs">to landline</span></h6>
                                            <p className="mt-20 mb-20 text-500">Error<span className="text-xs">/pm</span></p>
                                            <Button variant="cta" size="xs">Error</Button>
                                            <Button variant="secondary" size="xs">Selected</Button>
                                        </div>
                                    </div>
                                    }

                                    {group[1] !== undefined
                                      ? <div className={'col-xs-4 col-xxs-12 pb-10 pt-10 pl-5 pr-5 '.concat(context.state.selectedPackage && context.state.selectedPackage.id === group[1].id ? ' selected' : '')}>
                                        <div className="bg-light pt-20 pb-20 pl-5 pr-5 brad-20 b-1">
                                            <SvgRender icon="selected" />
                                            <h6 className="text-400">{group[1].mobile} mins <br/><span className="text-xs">to mobile</span></h6>
                                            <p className="mt-20 mb-20 text-500">&pound;{group[1].price} <span className="text-xs">/pm</span></p>
                                            <Button variant="cta" size="xs" onClick={event => context.setState({ selectedPackage: group[1] })}>Select</Button>
                                            <Button variant="secondary" size="xs">Selected</Button>
                                        </div>
                                    </div>
                                      : <div className={'col-xs-4 col-xxs-12 pb-10 pt-10 pl-5 pr-5 '}>
                                        <div className="bg-light pt-20 pb-20 pl-5 pr-5 brad-20 b-1">
                                            <SvgRender icon="selected" />
                                            <h6 className="text-400">Error <br/><span className="text-xs">to landline</span></h6>
                                            <p className="mt-20 mb-20 text-500">Error<span className="text-xs">/pm</span></p>
                                            <Button variant="cta" size="xs">Error</Button>
                                            <Button variant="secondary" size="xs">Selected</Button>
                                        </div>
                                    </div>
                                    }

                                    {group[2] !== undefined
                                      ? <div className={'col-xs-4 col-xxs-12 pb-10 pt-10 pl-5 pr-5 '.concat(context.state.selectedPackage && context.state.selectedPackage.id === group[2].id ? ' selected' : '')}>
                                        <div className="bg-light pt-20 pb-20 pl-5 pr-5 brad-20 b-1">
                                            <SvgRender icon="selected" />
                                            <h6 className="text-400">
                                                {group[2].landline + group[2].mobile} mins <br/><span className="text-xs">to landline &amp; mobile</span>
                                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={renderSplitPackInfoPopover(group[2].landline, group[2].landline + group[2].mobile)}>
                                                    <span className="icon icon-info2 text-xxs text-dark-light pl-5 d-inline"/>
                                                </OverlayTrigger>
                                            </h6>
                                            <p className="mt-20 mb-20 text-500">&pound;{group[2].price} <span className="text-xs">/pm</span></p>
                                            <Button variant="cta" size="xs" onClick={event => context.setState({ selectedPackage: group[2] })}>Select</Button>
                                            <Button variant="secondary" size="xs">Selected</Button>
                                        </div>
                                    </div>
                                      : <div className={'col-xs-4 col-xxs-12 pb-10 pt-10 pl-5 pr-5 '}>
                                        <div className="bg-light pt-20 pb-20 pl-5 pr-5 brad-20 b-1">
                                            <SvgRender icon="selected" />
                                            <h6 className="text-400">Error <br/><span className="text-xs">to landline</span></h6>
                                            <p className="mt-20 mb-20 text-500">Error<span className="text-xs">/pm</span></p>
                                            <Button variant="cta" size="xs">Error</Button>
                                            <Button variant="secondary" size="xs">Selected</Button>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </>
                        })}
                    </Accordion.Item >
                </div>
            </Accordion>
        </>
  }
}

export default connect(
  state => {
    return {
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      displayBillingWarning: state.sbfApp.enabledServices.displayBillingWarning
    }
  },
  {
    notify: handleNotificationChange,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal,
    handleShowBillingWarning
  }
)(NumbersMultiPackage)
