import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplaySimpleHeader from '../../components/DisplaySimpleHeader'
import IntegrationsSlider from '../../components/Home/IntegrationsSlider'
import LeadGenForm from '../../components/LeadGenForm'
import PlansAndNumbersDropdown from '../../components/PlansAndNumbersDropdown'
import PlansFeatures from '../../components/PlansFeatures'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import Testimonials from '../../components/Testimonials'
import { BasketItemType } from '../../constants/Constants'
import HandsetModal from '../../containers/Signup/Modals/HandsetModal'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'
import { isLoggedIn } from '../../selectors'
import { getProductPrice } from '../../store/Home'
import { handleShowCallbackForm } from '../../store/SBF'
import { handleAddToBasket, handleBasketClear, handleBasketInit, handleRemoveFromBasket } from '../../store/Signup'

const _ = require('lodash')

class DynamicLandingPage extends React.Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    prefix: '01',
    redirectToMobile: false,
    monthlyFee: 16,
    showFeatures: false,
    showHandset: false,
    userQty: 0
  }

  constructor (props) {
    super(props)
  }

  scrollTo (el) {
    scroller.scrollTo(el, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -50
    })
  }

  handleSelectPackage (packDetails) {
    const context = this
    if (!this.state.selectedPackage || !_.isEqual(packDetails, this.state.selectedPackage)) {
      context.setState({ selectedPackage: packDetails }, () => {
        context.props.handleAddToBasket({
          item: packDetails,
          itemType: BasketItemType.package
        })
        context.scrollTo('chooseNumber')
      })
    }
  }

  render () {
    const basket = getEmptyBasket()
    const numbers = LocalStorageHelper.getItem('LandingPageNumberSelection')
    basket.numbers = numbers.value ?? []
    basket.setupFee = 0
    basket.package = {
      name: 'Unlimited',
      seconds: 120000,
      rental: this.state.monthlyFee,
      packageID: 4,
      users: {
        qty: 1 + this.state.userQty,
        monthlyPrice: this.state.monthlyFee
      }
    }

    const header = this.state.header ? this.state.header : '#1 Alternative to BT Cloud Phone'
    const subheader = this.state.subheader ? this.state.subheader : `43% Cheaper than BT, no long-term contracts, just £${this.state.monthlyFee} per month`
    const cta = this.state.cta ? this.state.cta : 'Learn more'

    return <>
			<HandsetModal
				wireless={false}
				show={this.state.showHandset}
				hide={() => this.setState({ showHandset: false })}
			/>
			<ScrollToTopOnMount /><DisplayMainFooter /><DisplaySimpleHeader />
			<Helmet>
				<title>#1 Alternative to BT Cloud Phone</title>
				<meta name="description" content={subheader} />

				<style type="text/css">{`
                .side-box {
                    background-position:163% 100%;
                    background-size:470px;
                    background-repeat:no-repeat;
                    background-color:#ff9314;
                }
                .header .navbar.navbar-simple {
                    min-height: 75px;
                }
                @media screen and (max-width:1170px) {
                    .side-box {
                        background-image:none;
                        min-height:unset;
                    }
                }
                @media screen and (max-width:992px) {
                    .form-control.h-60px {
                        height:45px;
                    }
                }
                @media screen and (max-height:800px) {
                    .form-control.h-60px {
                        height:45px;
                    }
                }
                @media screen and (max-width:767px) {
                    .side-box p.text-lg{
                        font-size:21px !important;
                    }
                    .col-xs-6 .icon-block {
                        width: 130px;
                        height: 130px;
                        padding: 8px 0;
                      }
                }
				#table td, #table th {
					border: none !important;
					box-shadow: none !important;
					font-size: 1.2em;
					margin: 0;
					padding: 10px !important;
					text-align: center;
				}
				#table>tbody>tr:nth-of-type(odd)>* {
					background: white;
				}

                    `}</style>
			</Helmet>
			<header style={{ backgroundImage: 'url(\'/images/backgrounds/header7.jpg\')' }} className="full-width full-hero-small with-text overlay" >
				<div className="w-1200px center-block">
					<h1 dangerouslySetInnerHTML={{ __html: header }}></h1>
					<h2 className="subtitle pt-10" dangerouslySetInnerHTML={{ __html: subheader }}></h2>
					<Button size="lg" variant="cta" className="position-relative mt-50" style={{ zIndex: '1' }} onClick={() => this.scrollTo('learnMore')}>
						{cta}
					</Button>
				</div>
			</header>

			<div className="full-width bg-light" id="learnMore">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center mt-30">
							<h3 className="text-500">
								Get the best UK based, <span className="text-cta">5-star rated VoIP system</span> <br className="d-none d-md-block" />for your business
                            </h3>
							<h5 className="text-400 mt-40">
								43% cheaper than BT Cloud Phone <span className="ml-10 mr-10">•</span> 30-day rolling contract <span className="ml-10 mr-10">•</span> Works with any broadband
                            </h5>
						</div>
					</div>

					<div className="row w-1300px center-block mt-60">
						<div className="col-sm-6">
							<p className="lead text-400 pt-0 pt-xs-0">
								Get started with SwitchboardFREE today. With no long-term commitments and a 43% saving compared to BT Cloud Phone, what do you have to lose?
							</p>
							<p className="mt-20 mb-0 text-500">
								For just &pound;{this.state.monthlyFee} per month, you'll get:
							</p>

							<div className="row">
								<div className="col-12 col-lg-7">
									<ul className="styled-list cta rounded text-400 pr-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0">
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Free 01, 02 or 03 virtual phone number
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Unlimited inbound mins to our app, softphone & VoIP phones
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Unlimited outbound mins from our app, softphone & VoIP phones <sup>*</sup>
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
											Nuisance call blocker  <span className="text-cta text-500 text-sm">(Worth &pound;36 / year)</span>
										</li>
										<li>
											FREE VoIP Desk Phone <span className="text-cta text-500 text-sm"><br />(Worth &pound;55)</span>
											<span
												className="underline cursor-pointer text-sm ml-5"
												onClick={() => this.setState({ showHandset: true })}
											>
												More info
											</span>

										</li>
									</ul>
								</div>
							</div>
							<div className="plans pb-30 mt-20">
								<div className="plan mb-sm-0 mb-xs-50">
									<span className="text-md text-500">Just <span className="text-cta text-500 text-lg">£{this.state.monthlyFee}</span> <span className="text-italic">per month</span></span>
									<br />
									<span className="text-400">No long-term contracts</span>
								</div>
							</div>
							<p className="pb-30">
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.scrollTo('chooseNumber')}>Get Started</Button>
							</p>
						</div>
						<div className="col-sm-6">
							<img className="" src="/images/product-shots/free-x3s.jpg" alt="SwitchboardFREE VoIP Phone" style={{ maxWidth: '90%', marginTop: '-50px' }} />
							<p className="text-sm m-10">
								<sup>*</sup> Free phone included on annual / bi-annual plan only.
							</p>
							<div className="brad-10 text-lightest p-30 mt-10 side-box">
								<div className="row">
									<div className="col-md-12">
										<p className="text-md text-italic text-400 pt-0">&quot;These guys really know how to look after customers. The VoIP service they have provided me with for both my businesses is flawless and the support is great. Highly recommended company.&quot;</p>
										<p className="pt-10">
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-10"></span>
											<span>Nigel</span>
											<span className="bg-dark brad-20 text-lightest pt-0 pb-3 pl-10 pr-10 ml-10">
												<span className="img-circle text-lightest d-inline-block mr-5" style={{ width: '15px', height: '15px', backgroundColor: '#33b995', verticalAlign: 'middle' }}>
													<span className="icon icon-checkmark4" style={{ fontSize: '9px', padding: '4px 3px 3px 2px', display: 'block' }} />
												</span>
												<span className="text-500 text-xs">Verified user</span>
											</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-30 pt-60">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center pb-40">
							<h3 className="text-500">Integrate with the tools you already use</h3>
							<h5 className="text-400">
								Get your call data and missed call alerts or voicemails sent directly to your CRM, Teams or more...
							</h5>
						</div>
					</div>
				</div>
				<IntegrationsSlider />
			</div>

			<div className="full-width bg-light pb-60 pt-60">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center pb-40">
							<h3 className="text-500">Free stuff our competitors don&apos;t give you</h3>
							<h5 className="text-400">
								All ours plans come with over 40 powerful call management features, included for free!
							</h5>
						</div>
					</div>
				</div>
				<PlansFeatures
					hideIntroText={true}
					showFeaturesModal={this.state.showFeatures}
				/>
				<p className="pb-0 text-center">
					<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.scrollTo('chooseNumber')}>Get Started</Button>
				</p>
			</div>

			<div className="full-width bg-dull pt-50">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-9 mx-auto">
							<table className="table table-striped" id="table">
								<thead>
									<tr>
										<th className="col-4"></th>
										<th className="text-lg text-secondary text-500 col-4">
											SwitchboardFREE
										</th>
										<th className="col-4 text-md">
											BT Cloud
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="text-start">Pricing</td>
										<td>&pound;{this.state.monthlyFee} per month</td>
										<td>&pound;22.68 per month</td>
									</tr>
									<tr>
										<td className="text-start">One-off fee</td>
										<td>&pound;0</td>
										<td>&pound;100</td>
									</tr>
									<tr>
										<td className="text-start">Phone included</td>
										<td>FREE<sup>*</sup> <span className="text-cta text-xs text-500">(Worth &pound;55)</span></td>
										<td>From &pound;49.99</td>
									</tr>
									<tr>
										<td className="text-start">Minutes included</td>
										<td>Unlimited</td>
										<td>500 + &pound;3 for unlimited </td>
									</tr>
									<tr>
										<td className="text-start">Contract length</td>
										<td>30 days rolling, cancel anytime</td>
										<td>24 months</td>
									</tr>
									<tr>
										<td className="text-start">Call blocker</td>
										<td><span className="icon icon-checkmark-circle text-success" /></td>
										<td><span className="icon icon-cancel-circle2 text-dullest" /></td>
									</tr>
									<tr>
										<td className="text-start">Music on-hold</td>
										<td><span className="icon icon-checkmark-circle text-success" /></td>
										<td><span className="icon icon-cancel-circle2 text-dullest" /></td>
									</tr>
									<tr>
										<td className="text-start">AI caller greeting</td>
										<td><span className="icon icon-checkmark-circle text-success" /></td>
										<td><span className="icon icon-cancel-circle2 text-dullest" /></td>
									</tr>
									<tr>
										<td className="text-start">Call recording</td>
										<td><span className="icon icon-checkmark-circle text-success" /></td>
										<td><span className="icon icon-cancel-circle2 text-dullest" /></td>
									</tr>
									<tr>
										<td className="text-start">20+ call management features <span className="text-xs underline text-secondary cursor-pointer" onClick={() => this.setState({ showFeatures: true }) }>View more</span></td>
										<td><span className="icon icon-checkmark-circle text-success" /></td>
										<td><span className="icon icon-cancel-circle2 text-dullest" /></td>
									</tr>
									<tr>
										<td className="text-start">UK based support team</td>
										<td><span className="icon icon-checkmark-circle text-success" /></td>
										<td><span className="icon icon-cancel-circle2 text-dullest" /></td>
									</tr>
								</tbody>
							</table>
							<p className="text-center text-sm m-10">
								<sup>*</sup> Free VoIP phone included on annual &amp; bi-annual plans only.
							</p>
							<p className="mt-30 pb-0 text-center">
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.scrollTo('chooseNumber')}>Get Started</Button>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-70 pt-70" id="chooseNumber">
				<div className="container numbers-pages pl-sm-0 pr-sm-0">
					<div className="row">
						<div className="col-md-12 text-center pb-60">
							<h3 className="text-500">
								Choose the perfect Virtual Phone Number(s) for your business...
                            </h3>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-7">
							<PlansAndNumbersDropdown
								prefix={this.state.prefix && this.state.prefix.length <= 4 ? this.state.prefix : '01'}
								ddiLid={this.state.ddiLid?.value}
								defaultArea={this.state.prefix}
								handleChangeNumberType={e => this.setState({ prefix: e })}
								handleChangeAreaCode={e => this.setState({ ddiLid: e })}
								hidePlans={true}
								preventDefaultSelection={true}

								WrapperClassName="mb-40"
								LabelsClassName="text-xs text-dark text-400"

								NumberButtonClassName={'text-start bg-light pl-25 pr-25 ml-5 b-0 inbound-only'}
								NumberNameClassName="text-dark text-400"
								NumbersResultsDropdownClassName="bg-light ml-5 box-shadow"
								NumberNameClassNameSelected="text-cta text-500"

								AreaCodeButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
								AreaCodeNameClassName="text-dark text-400"
								AreaCodeResultsDropdownClassName="bg-light box-shadow"
								AreaCodeNameClassNameSelected="text-cta text-500"
							/>

							<DdiSelectorNew
								numberType={this.state.prefix}
								isSignup={false}
								currentBasket={basket}
								hideShowAll={false}
								isModal={false}
								hideLoadMore={false}
								isSimple={true}
								hideRegions={true}
								hidestars={false}
								isLoggedIn={false}
								isEditing={false}
								menuOpen={false}
								citysOnly={false}
								ddiLid={this.state.ddiLid?.value}
								handleUpdateBasket={(_, sbfNumber) => {
								  // let item: LocalStorageItem<SbfNumber[]> = LocalStorageHelper.getItem < SbfNumber[] > ("LandingPageNumberSelection");
								  const item = LocalStorageHelper.getItem('LandingPageNumberSelection')
								  let sbfNumbers = item.value ?? []
								  // add the number to the array if it doesn't already exist, otherwise remove it
								  if (sbfNumbers.find(number => number.number === sbfNumber.number)) {
								    sbfNumbers = sbfNumbers.filter(number => number.number !== sbfNumber.number)
								  } else {
								    sbfNumbers.push(sbfNumber)
								  }
								  LocalStorageHelper.setItem('LandingPageNumberSelection', sbfNumbers)
								  this.setState({})
								}}
							/>
						</div>
						<div className="col-sm-5">
							<LeadGenForm
								FormHeadingText="Create your account"
								ReserveNumber={true}
								ButtonText="Checkout"
								hideLabels={true}
								showFees={true}
								CompanyName={true}
								EmailAddress={true}
								ContinueToSignup={true}
								history={this.props.history}
								setupFee={0}
								basket={basket}
								handleUpdateUsers={(decrement) => this.setState({ userQty: decrement ? this.state.userQty - 1 : this.state.userQty + 1 })}
								showTrustBadges={true}
								showUsers={true}
								userQty={this.state.userQty}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pt-60 pb-0">
				<div className="container">
					<h2 className="text-center mt-0 mb-30 text-500">Here's what our customers say</h2>
					<Testimonials isSlider={true} />
				</div>
			</div>

			<div className="full-width bg-dull pt-30">
				<div className="container">
					<CarouselWidget />
				</div>
			</div>

			{/* Logoos */}
			<div className="full-width bg-light">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">Partners &amp; Customers</h3>
							{/* <h6>The UK's favourite call management system</h6> */}
							<p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>
			<ClientLogos style='light' />

		</>
  }

  componentDidMount () {
    this.props.handleBasketClear()
    this.props.handleBasketInit()
    const query = new URLSearchParams(this.props.location.search)
    if (query) {
      if (query.get('inbound')) {
        this.setState({ inboundOnly: true, redirectToMobile: true })
      }

      const header = query.get('header')
      const subheader = query.get('subheader')
      const cta = query.get('cta')
      if (header) { this.setState({ header }) }

      if (subheader) { this.setState({ subheader }) }

      if (cta) { this.setState({ cta }) }
    }
  }
}

export default connect(
  state => {
    return ({
      monthlyPayment: state.home.productDetails.monthlyFirstPayment,
      isLoggedIn: isLoggedIn(state),
      currentBasket: state.signup.packageInfo.currentBasket
    })
  },
  {
    handleRemoveFromBasket,
    handleAddToBasket,
    handleBasketClear,
    handleBasketInit,
    getProductDetails: getProductPrice,
    handleShowCallbackForm
  }
)(DynamicLandingPage)
