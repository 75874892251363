import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'

const SystemVoiceSelection = props => {
  return <Select
        className="form-control select-menu mt-20"
        classNamePrefix="custom"
        id="system-voice-select"
        options={props.options}
        simpleValue
        name="selected-voice-prompt"
        value={props.voicePrompt !== undefined
          ? {
              value: props.voicePrompt.voicePromptId,
              label: props.voicePrompt.voice
                ? props.voicePrompt.voice
                : (props.options.length > 0 && (typeof props.options.filter === 'function')) ? props.options.filter(op => op.value === props.voicePrompt.voicePromptId.toString())[0].label : undefined
            }
          : 'Chris'}
        onChange={e => props.updateVoicePromptSelection({ newValue: e })}
        searchable={false}
        clearable={false}
        isLoading={props.isLoadingVoiceOptions}
    />
}

export default connect(
  state => ({ voicePrompt: state.configuration.configuration.voicePrompt }),
  dispatch => bindActionCreators(configurationActions, dispatch)
)(SystemVoiceSelection)
