import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import CarouselWidget from '../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { isLoggedIn } from '../selectors'

class VirtualNumbersExplained extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {

    }
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
		<Helmet>
                <title>What are Virtual Numbers? - SwitchboardFREE</title>
				<meta name="description" content="What are SwitchboardFREE Virtual Numbers" />
				<style type="text/css">{`
                        .bottom-light-gradient {
							background: rgb(38,166,181); /* Old browsers */
							background: -moz-radial-gradient(center, ellipse cover,  rgba(38,166,181,1) 2%, rgba(44,128,139,1) 100%); /* FF3.6-15 */
							background: -webkit-radial-gradient(center, ellipse cover,  rgba(38,166,181,1) 2%,rgba(44,128,139,1) 100%); /* Chrome10-25,Safari5.1-6 */
							background: radial-gradient(ellipse at center,  rgba(38,166,181,1) 2%,rgba(44,128,139,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26a6b5', endColorstr='#2c808b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
						}
					}
					`}</style>
            </Helmet>
			<header className="full-width full-hero-medium full-secondary pb-sm-0 pb-xs-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-10 mx-auto">
							<div className="row">
								<div className="col-sm-12 col-md-5">
									<h1 className="text-lightest pt-70">What are SwitchboardFREE Virtual Numbers</h1>
									<h2 className="subtitle pt-20">Get the freedom &amp; flexibility to make calls anytime anywhere</h2>
								</div>
								<div className="col-sm-12 col-md-7 float-end">
									<img src="/images/headers/woman.png" alt="business woman" style={{ maxWidth: '100%' }} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>

			{this.props.isLoggedIn
			  ? <>
								</>

			  :								<>
									<div className="full-width bg-light pt-50 pb-50">
										<div className="container">
											<div className="row">
												<div className="col-md-12 text-center" itemScope itemType="http://schema.org/Product">
													<h2>
														What is <span itemProp="name">SwitchboardFREE</span>?
													</h2>
													<p className="lead text-400 pt-20" itemProp="description">
														SwitchboardFREE is a cloud-based telephony service that allows you to divert <br className="d-none d-md-block"/>your incoming calls to a phone number of your choice.
													</p>
													<p>
														You can choose a Virtual Number from a selection of all available UK geographical <br className="d-none d-md-block"/>numbers (01 and 02) or national numbers (03 and 0800)
													</p>
												</div>
											</div>
										</div>
									</div>
								</>
							}

			<div className="full-width bg-dull pt-30 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 float-end" itemScope itemType="http://schema.org/Product">
                            <h3 className="text-500 pt-40">What is a <span itemProp="name">Virtual Number</span>?</h3>
							<div itemProp="description">
								<p className="text-400 pt-20 lead">A virtual telephone number is a number that exists in 'the cloud' which isn’t physically linked to a fixed telephone line.
									Calls to this number can be diverted anywhere that suits your needs, normally your mobile but also any landline worldwide.
								</p>
								<p className="mb-30">
									It’s the perfect solution if you don’t want to publish personal mobile or landline numbers on your website and it adds a more professional feel to your business.
								</p>
							</div>
                            <p>
							{this.props.isLoggedIn
							  ? <>
									 <Link to={'/customer/numbers/new'} className="btn btn-cta btn-lg mr-10">Get a new number</Link> <Link to={'/customer/numbers'} className="btn btn-secondary btn-lg">See my numbers</Link>
								</>

							  :								<>
									<Link to={'/number-types'} className="btn btn-cta btn-lg">See numbers</Link>
								</>
							}

                            </p>
                        </div>
                        <div className="col-md-6 pt-0">
                            <img src="/images/mega-bundle/happy-customer3.png" alt="cloud" style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>

			<div className="full-width pb-50 pt-50 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="text-500">Our SwitchboardFREE app</h3>
                            <p className="lead text-400">
								With the app, you will be able to make low-cost outbound calls, displaying the virtual number you have selected upon choosing a plan.
							</p>
                            <p className="mb-30">
								You can also redirect your incoming calls to the app. For more information on the app check our apps and devices page.
							</p>
                            <p>
                                <Link to={'/app'} className="btn btn-cta btn-lg">Find out more</Link>
                            </p>
                        </div>
                        <div className="col-md-6 pt-sm-30 text-sm-center">
                            <img src="/images/app2.png" alt="SwitchboardFREE app" style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>

			{this.props.isLoggedIn
			  ? <>
				</>

			  :				<>
					<div className="full-width bg-dull pt-40 pb-60">
						<div className="container">
							<div className="row">
								<div className="col-md-6 float-end pt-50">
									<h3 className="text-500">Is SwitchboardFREE for me?</h3>
									<div itemProp="description">
										<p className="text-400 pt-20 lead">
										The service is ideal for small businesses or start-ups that need a local or 0800 number.
										</p>
										<p className="mb-30">
											SMEs and sole traders usually use this number as a single point of contact on their websites or business cards, as well as for localised advertising on Google, Bing, Yell and other offline and online advertising channels.
										</p>
										<p className="mb-30">
											SwitchboardFREE is also appropriate for individual users, who want a UK landline number without being tied to a physical address, perfect if you are relocating and want to keep your old number.
										</p>
										<p className="mb-30">
											SwitchboardFREE is a much cheaper solution if you want to cut the high costs of a fixed BT line and break the chains of the 12 month contracts.
										</p>
									</div>
									<p className="mb-30">
									<Link to={'/get-started'} className="btn btn-cta btn-lg">Create your account</Link>
									</p>
								</div>
								<div className="col-md-6 pt-0">
									<img src="/images/mega-bundle/happy-customer5.png" alt="happy customer" style={{ maxWidth: '100%' }} />
								</div>
							</div>
						</div>
					</div>
				</>
			}

			<div className={`full-width ${this.props.isLoggedIn ? 'bg-dull' : 'bg-light'}`}>
				<div className="container">
					<CarouselWidget />
				</div>
			</div>

		</>
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state)
    }
  }
)(VirtualNumbersExplained)
