import React from 'react'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'
import { bindActionCreators } from 'redux'
import { PromptTypes } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'
import PromptAudioPlayer from '../PromptAudioPlayer'
import IntroductionInstructionHelperDialog from './InstructionHelperDialogs/IntroductionInstructionHelperDialog'

const Input = props => <components.Input {...props} maxLength={100} />

const DepartmentChoicePromptSection = props => (
    <>
    <div className="row bg-dullest p-30 zoom">
        <div className="col-md-5 pb-sm-10">
            <h5 className="text-secondary text-500 text-lg">
                Introduction
            </h5>
            <p className="text-400">
                Select the first thing your callers will hear when calling your SwitchboardFREE number. <br/><a className="underline" onClick={props.handleShowIntroductionHelperInstruction} id="IntroductionMoreInfoLink">More info</a>
            </p>
        </div>
            <div className="col-md-7 mt-10 pb-5">

            <p>Select below:</p>

                <IntroductionInstructionHelperDialog/>
                <Select
                    className="form-control select-menu"
                    classNamePrefix="custom"
                    id="introSelect"
                    onChange={props.handleIntroChange}
                    options={props.introPrompts}
                    simpleValue
                    name="selectedIntro"
                    value={props.selectedIntroPrompt !== undefined ? { label: props.selectedIntroPrompt.promptDescription, value: props.selectedIntroPrompt.prompId } : undefined}
                    searchable={false}
                    clearable={false}
                    isLoading={props.isLoadingPromptOptions}
                    components={{ Input }}
                />
                {/* <ReactAudioPlayer */}
                {/* className="mt-30" */}
                {/* src={props.introUrl} */}
                {/* controls */}
                {/* /> */}
                <PromptAudioPlayer promptId={props.selectedIntroPrompt !== undefined ? props.selectedIntroPrompt.promptId : undefined} voice={props.voice} promptType={PromptTypes.standardIntro} />
            </div>
        </div>
    </>
)

const mapStateToProps = state => ({
  introPrompts: state.configuration.prompts.introPrompts,
  selectedIntroPrompt: state.configuration.configuration.introPrompt,
  isLoadingPromptOptions: state.configuration.loading.introPrompts,
  voice: state.configuration.configuration.voicePrompt.voicePromptId
  // introUrl: state.configuration.configuration.introPrompt !== undefined ? 'api/Configuration/DownloadIntroPrompt/' + state.configuration.configuration.introPrompt.promptId : undefined
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentChoicePromptSection)
