
import ClientOAuth2 from 'client-oauth2'

export default class OauthHelper {
  static GetCapsuleCrmOauthClient () {
    const useLiveConnection = true

    if (useLiveConnection === true) {
      return new ClientOAuth2({
        clientId: '13pcugwrj0quf',
        clientSecret: '4lzpctg0dwufkzh9wv28jw69t0626sktnl8t905b9tgxq1gtnd',
        accessTokenUri: 'https://api.capsulecrm.com/oauth/token',
        authorizationUri: 'https://api.capsulecrm.com/oauth/authorise',
        redirectUri: 'https://www.switchboardfree.co.uk/customer/contact-sync/capsule-crm',
        scopes: ['read', 'write']
      })
    } else {
      return new ClientOAuth2({
        clientId: '3px9ndx8c83ut',
        clientSecret: '3bzdd4404hgxs0zac518xn0vb871hhre52fw2f62ixtpopv973',
        accessTokenUri: 'https://api.capsulecrm.com/oauth/token',
        authorizationUri: 'https://api.capsulecrm.com/oauth/authorise',
        redirectUri: 'https://switchboardfree-test.azurewebsites.net/customer/contact-sync/capsule-crm',
        scopes: ['read', 'write']
      })
    }
  }

  static GetPipeDriveCrmOauthClient () {
    const useLiveConnection = true

    if (useLiveConnection === true) {
      return new ClientOAuth2({
        clientId: 'f1473f3a61a1a2e5',
        clientSecret: '2c12ff7ba7fd4827cbe017695d6389420cc8e345',
        accessTokenUri: 'https://oauth.pipedrive.com/oauth/token',
        authorizationUri: 'https://oauth.pipedrive.com/oauth/authorize',
        redirectUri: 'https://www.switchboardfree.co.uk/api/PipedriveCrm/CallbackUrl',
        scopes: ['read', 'write']
      })
    } else {
      return new ClientOAuth2({
        clientId: '2daecd7f23edcdd2',
        clientSecret: '58cde8e1512791a5e060a47aca4fa6e1d916244a',
        accessTokenUri: 'https://oauth.pipedrive.com/oauth/token',
        authorizationUri: 'https://oauth.pipedrive.com/oauth/authorize',
        redirectUri: 'https://switchboardfree-test.azurewebsites.net/api/PipedriveCrm/CallbackUrl',
        scopes: ['read', 'write']
      })
    }
  } 

  static GetHubSpotCrmOauthUrl () {
    const useLiveConnection = true

    if (useLiveConnection === true) {
      return 'https://app-eu1.hubspot.com/oauth/authorize?client_id=635cc0db-ee5f-49c7-9960-428563f49669&redirect_uri=https://switchboardfree.co.uk/customer/contact-sync/HubSpotCrm&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.owners.read'
    } else {
      return 'https://app-eu1.hubspot.com/oauth/authorize?client_id=cb390626-a580-47af-b9f5-c9ee12a33f39&redirect_uri=https://switchboardfree-test.azurewebsites.net/customer/contact-sync/HubSpotCrm&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.owners.read'
    }
  }
}
