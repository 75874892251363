import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import LoadingNotification from '../../components/LoadingNotification'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'
import { handleLogin, updateUsername, updatePassword } from '../../store/Home'
import { handleNotificationChange } from '../../store/SBF'
import Select from 'react-select'
import PromptAudioPlayer from '../../components/PromptAudioPlayer'
import { PromptTypes } from '../../constants/Constants'
import { InlineShareButtons } from 'sharethis-reactjs';

class MusicOnhold extends React.Component {
  state = {
    isLoading: true,
    isCheckingFeatured: true,
    isPurchaseInProgress: false,
    mohTrackId: 0
  }

  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.isLoggedIn === true && this.state.showLogin === true) {
      this.handleGetNumbers()
      this.setState({ showLogin: false })
    }

    if (this.state.mohTrackId === undefined && this.state.featuredCategory && this.state.moh) {
      this.handleMohChange(this.state.mohSelected ? this.state.mohSelected.value : this.state.featuredCategory ? this.state.featuredCategory.id : 0)
    }

    return <><DisplayMainFooter />
      <Helmet>
        <body className={'body-simple'} />
        <title>Update Your On-Hold Music!</title>
        <style type="text/css">{`
						.button-bar {
							background: #fff;
							border-top: #ddd 1px solid;
							padding: 20px;
							position: fixed;
							bottom: 0;
							left: 0;
							right: 0;
							z-index: 9000;
							text-align: center;
						}

            @media screen and (max-width:767px) {
              .background-image { background-image: none !important; }
            }

					`}</style>

      </Helmet>
      {(this.state.isLoading || this.state.isCheckingFeatured)
        ? <div className="p-50">
          <LoadingNotification loadingText={'Getting your numbers, please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
        </div>
        : <>
          <div className={'p-50 p-xs-20 background-image'} style={{
            backgroundColor: this.state.featuredCategory ? `#${this.state.featuredCategory.backgroundColour}` : '#fff',
            backgroundImage: `url('data:image/png;base64,${this.state.featuredCategory?.image}')`,
            backgroundRepeat: 'no-repeat'
          }}>
            <div className="container p-xs-0">
              <div className="row mb-50">
                <div className="col-md-10 mx-auto text-center">
                  <p className="text-lg text-400 mt-30 w-600px mx-auto" style={{ color: this.state.featuredCategory ? `#${this.state.featuredCategory.textColour}` : '#222' }}>
                    {this.state.featuredCategory ?
                      this.state.featuredCategory.subtitle : 
                      'Instantly improve your customer experience' }
                  </p>
                  <h1 className="text-600 text-xxl" style={{ color: this.state.featuredCategory ? `#${this.state.featuredCategory.textColour}` : '#222' }}>
                    {this.state.featuredCategory ?
                      this.state.featuredCategory.title :
                      'Update Your On-Hold Music!'}
                  </h1>

                </div>
              </div>
              <div className="row">
                <div className="col-lg-7 col-md-8 col-sm-12 mx-auto">
                  <div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
                    {this.state.updated === true ?
                      <>
                        <h2 className="text-md text-500 text-center">Your on-hold music has been updated to {this.state.mohSelected.label}</h2>
                        <p className="text-center">Share this on social media!</p>

                        <InlineShareButtons
                          config={{
                            alignment: 'center',
                            color: 'social',
                            enabled: true,
                            font_size: 16,
                            labels: 'cta',
                            language: 'en',
                            networks: [
                              'whatsapp',
                              'linkedin',
                              'messenger',
                              'facebook',
                              'twitter'
                            ],
                            padding: 12,
                            radius: 4,
                            show_total: false,
                            size: 30,

                            url: 'https://www.switchboardfree.co.uk',
                            image: 'https://www.switchboardfree.co.uk/images/default-blog.png',
                            description: `I've just updated my on-hold music for my phone number to ${this.state.mohSelected.label}. #SwitchboardFREE`,
                            title: `I've just updated my on-hold music for my phone number to ${this.state.mohSelected.label}. #SwitchboardFREE`,
                          }}
                        />

                      </> :
                      <>
                        <div className="row mb-30">
                          <div className="col-12 pt-10 col-xxs-12 text-xxs-center pb-xxs-10 text-400">
                            <em><span className="text-500">Step 1:</span> Choose your new on-hold music:</em><br />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="bg-light brad-10 p-30 p-xs-10 mb-50">
                              <div className="form-group">
                                <label className="pr-30">Choose playlist:</label>
                                <Select
                                  id="mohSelect"
                                  className="form-control select-menu mb-20 mx-auto"
                                  classNamePrefix="custom"
                                  onChange={e => this.handleMohChange(e.value)}
                                  options={this.state.moh}
                                  simpleValue
                                  name="selectedMoh"
                                  value={this.state.mohSelected}
                                  searchable={false}
                                  clearable={false}
                                  isLoading={this.state.isLoadingMoh}
                                />
                                <PromptAudioPlayer noMargin={true} promptId={this.state.mohTrackId} promptType={PromptTypes.holdMusicTrack} />

                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-30">
                          <div className="col-12 pt-10 col-xxs-12 text-xxs-center pb-xxs-10 text-400">
                            <em><span className="text-500">Step 2:</span> Select the phone numbers you want to update the on-hold music for:</em><br />
                          </div>
                        </div>

                        <div className="row">
                          {(this.state.numbers && this.state.numbers.length > 0)
                            ? <>
                              <div className="col-sm-12 col-md-12 pb-40">
                                <div className="row">
                                  <div className="col-sm-3 col-md-4 d-none d-md-block">
                                    <p className="text-500 text-xs mb-0 mt-10">Number / Description </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 d-none d-md-block">
                                    <p className="text-500 text-xs mb-0 mt-10">Current Hold Music</p>
                                  </div>
                                  <div className="col-sm-2 col-md-2 text-end">
                                    <Button size="xs" variant="cta" className="mb-20" onClick={() => this.handleToggleAll(!(this.state.numbers && this.state.numbers.every(a => a.selected === true)))}>
                                      Toggle all
                                    </Button>
                                  </div>
                                </div>

                                <div className='row'>
                                  {this.state.numbers.map((num, i) => <>
                                    <div className="bg-light brad-10 p-15 box-shadow shadow-lighter mb-10 cursor-pointer" onClick={() => this.handleToggle(i, num.selected)}>
                                      <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                          <div className="col-sm-12 col-md-4">
                                            <span className="text-500 text-xs d-block d-md-none">Number: </span>
                                            <p className="mb-0 text-sm">
                                              {num.number}<br />
                                              {num.desc}
                                            </p>
                                          </div>
                                          <div className="col-6 col-md-6 pt-sm-20 pt-10">
                                            <span className="text-500 text-xs d-block d-md-none">Current hold music: </span>
                                            <p className="mb-0 text-sm">
                                              {num.moh}
                                            </p>
                                          </div>
                                          <div className="col-sm-12 col-md-2 pt-sm-20 pt-5 text-end text-xs-start">
                                            {!num.isDept &&
                                              <>
                                                <span className="text-500 text-xs d-block d-md-none">Selected: </span>

                                                <span className="styled-checkbox checkbox-light checkbox checkbox-full mr-0 float-end">
                                                  <Form.Check inline className="mt-neg-15" checked={num.selected} />
                                                </span>
                                              </>
                                            }
                                          </div>
                                        </div>
                                        {num.isDept === true &&
                                          <>
                                            <div className="row bt-1 mt-10 pt-10">
                                              <div className="col-sm-12 col-md-10">
                                                <span className="text-500 text-xs d-block d-md-none">Number: </span>
                                                <p className="mb-0 text-sm pt-5">
                                                  Deparments 1-9
                                                </p>
                                              </div>
                                              <div className="col-sm-12 col-md-2 pt-sm-20 pt-5 text-end text-xs-start">
                                                  <>
                                                    <span className="text-500 text-xs d-block d-md-none">Selected: </span>

                                                    <span className="styled-checkbox checkbox-light checkbox checkbox-full mr-0 float-end">
                                                      <Form.Check inline className="mt-neg-15" checked={num.selected} />
                                                    </span>
                                                  </>
                                              </div>

                                            </div>
                                          </>
                                        }
                                      </div>
                                    </div>
                                  </>
                                  )}
                                </div>
                              </div>
                            </>
                            : <p>It doesn't look like you currently have any numbers.</p>
                          }
                        </div>

                        {this.state.isPurchaseInProgress
                          ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
                          : <>
                            <p className="pl-30 pl-xs-0 mt-10 lead text-center text-400">
                              Update On-Hold Music for {this.state.numbers.filter(f => f.selected === true).length} Numbers.
                            </p>

                            <Button variant="cta" size="lg" className="btn-block"
                              disabled={this.state.numbers.filter(f => f.selected === true).length === 0}
                              onClick={() => this.handleUpdate()}>Update On-Hold Music Now</Button>

                          </>
                        }

                        <div className="button-bar d-block d-xs-none d-md-block">
                          {this.state.isPurchaseInProgress
                            ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
                            : <>
                              <Button variant="cta" size="lg"
                                disabled={this.state.numbers.filter(f => f.selected === true).length === 0}
                                onClick={() => this.handleUpdate()}>Update On-Hold Music Now</Button>
                            </>
                          }
                        </div>


                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }

      <Modal size="sm" show={this.state.showLogin}>
        <Modal.Header>
          <Modal.Title className="mx-auto">Log into your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 text-center pb-30">
              <form name="form" className="text-left" onSubmit={e => this.props.login({
                userName: this.props.userName,
                password: this.props.password,
                event: e,
                history: this.props.history,
                persist: document.getElementById('persistLogin').checked,
                route: `/update/hold-music`
              })}>
                <div className="row">
                  <div className="col-xs-12">
                    <p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
                  </div>
                  <div className="col-xs-12">
                    <label>Email</label>
                    <input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
                  </div>
                  <div className="col-xs-12 mt-20">
                    <label>Password</label>
                    <input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
                  </div>
                </div>
                <div className="pt-10 pb-10 row">
                  <div className="col-xs-12 col-md-6 text-start">
                    <input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
                  </div>
                  <div className="col-xs-12 col-md-6 text-end">
                    <Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
                  </div>
                </div>
                <div className="text-center pt-30">
                  {this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                  {this.props.loginFailed === true && (this.props.loginFailReason === '' || this.props.loginFailReason === null || this.props.loginFailReason === undefined) ?
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'} className="underline text-danger">resetting your password</Link>.</p>
                  :
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage" hidden={!this.props.loginFailed}>{this.props.loginFailReason}</p>
                  }
                  <button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

                  <p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
                  <p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  }

  handleMohChange(mohId) {
    fetchHelper.getJson(`api/Configuration/GetRandomTrackIdFromCategory/${mohId}`)
      .then(res => {
        const selectedMoh = _.find(this.state.moh, moh => moh.value === `${mohId}`)
        this.setState({ mohSelected: selectedMoh, mohTrackId: res.data })
      }).catch(err => console.error(err))
  }

  handleUpdate() {
    this.setState({ isPurchaseInProgress: true });

    const url = `/api/AccessToken/ChangeOnholdMusic?t=${this.state.token}`
    const data = {
      Numbers: this.state.numbers.filter(f => f.selected === true),
      Moh: this.state.mohSelected ? this.state.mohSelected.value : this.state.featuredCategory ? this.state.featuredCategory.id : 0,
    }

    fetchHelper.postJson(url, data)
      .then(res => {
        this.setState({ isPurchaseInProgress: false });
        if (res.data === true) {
          this.setState({ updated: true })
        } else {
          this.props.notify({ message: "There was a problem updating your music. Please try again, or contact us.", isError: true })
        }
      }).catch(err => console.error(err));
  }

  handleToggleAll(val) {
    const newArr = this.state.numbers
    newArr.forEach(num => {
      num.selected = val
    })
    this.setState({ numbers: newArr })
  }

  handleToggle(i, val) {
    const newArr = this.state.numbers
    newArr[i].selected = !val
    this.setState({ numbers: newArr })
  }

  componentWillMount() {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  handleGetNumbers() {
    const context = this

    context.setState({ isLoading: true })

    const url = `/api/AccessToken/GetNumbersForClient?t=${context.state.token}`

    fetchHelper.getJson(url)
      .then(res => {
        const numbers = []

        res.data.forEach((num, i) => {
          const data = {
            number: num.number,
            desc: num.desc,
            qlid: num.qlid,
            moh: num.currentMoh,
            isDept: num.isDept,
            selected: true
          }

          numbers.push(data);
        })

        context.setState({
          isLoading: false,
          numbers: numbers
        })
      }).catch(err => console.error(err))
  }

  componentDidMount() {
    this.setState({ isLoadingMoh: true })
    if (!this.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else {
      this.handleGetNumbers()

      const getMohCats = new Promise((resolve, reject) => {
        const url = `/api/AccessToken/GetMohCats?t=${this.state.token}`
        fetchHelper.getJson(url)
          .then(res => {
            this.setState({ moh: res.data, isLoadingMoh: false })
            resolve()
          })
      })

      getMohCats.then(() => {
        const featuredUrl = `/api/AccessToken/GetFeaturedMoh?t=${this.state.token}`
        fetchHelper.getJson(featuredUrl)
          .then(res => {
            this.setState({ isCheckingFeatured: false })
            if (res.data && res.data.id > 0) {
              this.setState({ featuredCategory: res.data })
              this.handleMohChange(res.data.id)
            }
          })
      })
    }
  }

}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      userDetails: state.sbfApp.authentication,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed,
      loginFailReason: state.home.login.reason
    }
  },
  {
    login: handleLogin,
    updateUsername: updateUsername,
    updatePassword: updatePassword,
    notify: handleNotificationChange
  }
)(MusicOnhold)
