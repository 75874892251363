import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const DeliverySectionHelperDialog = props => (
    <Modal show={props.isDisplayed}
           onHide={props.hideDeliveryMethodHelperDialog}>
        <Modal.Header closeButton>
            <Modal.Title>In order vs Distribute evenly</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <p>
<span className="text-500">In order</span> - this option will attempt your redirects in order starting with redirect 1. This is ideal to prioritise your calls to specific individuals or teams.
</p>
<p className="mt-30">
<span className="text-500">Distribute evenly</span> - this option will equally distribute calls amongst your redirects. This is ideal for places where it doesn't matter who exactly picks up the call, as long it is answered. For example, your sales team.
</p>

        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideDeliveryMethodHelperDialog}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.deliveryMethodInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliverySectionHelperDialog)
