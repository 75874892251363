import { combineReducers } from 'redux'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { numberTypes } from '../constants/GetNumberConstants'
import { fetchHelper } from '../helpers/fetchHelper'

const _ = require('lodash')

// ACTION CREATORS
export const getAvailableNumbersRequest = createAction('UPDATE_NUMBER_HELPER_SELECTION')
export const getNumberInfoAndPricesRequest = createAction('GET_NUMBER_INFO_AND_PRICES_REQUEST')
export const getNumberInfoAndPricesReceived = createAction('GET_NUMBER_INFO_AND_PRICES_RECEIVED')

export const getNumberInfoAndPrices = context => async (dispatch) => {
  dispatch(getNumberInfoAndPricesRequest())

  const url = 'api/Purchase/GetNumberGroupDetails'
  const response = await fetchHelper.getJson(url)

  if (fetchHelper.isUnauthorised(response)) {
    // dispatch(logout({history: context.history}))
  } else {
    dispatch(getNumberInfoAndPricesReceived(response.data))
  }
}

// REDUCERS
const numberTypesReducer = handleActions({
  [getNumberInfoAndPricesReceived] (state, action) {
    const numberInfoAndPrices = _.map(action.payload, numberTypeInfo => _.assign({}, numberTypeInfo, _.find(state, y => y.numberType === numberTypeInfo.numberType)))
    numberInfoAndPrices.push(_.find(state, y => y.numberType === '084'))
    return numberInfoAndPrices
  }
}, numberTypes)

const chooseNumberHelperReducer = handleActions({
  [getAvailableNumbersRequest] (state, action) {
    return action.payload.newValue
  }
}, null)

const availableNumbersReducer = handleActions({
  [getAvailableNumbersRequest] (state, action) {
    return state
  }
}, {})

export const reducer = combineReducers({
  chooseNumberOption: chooseNumberHelperReducer,
  numberTypes: numberTypesReducer,
  availableNumbers: availableNumbersReducer
})

// SELECTORS
export const getNumberHelperSelection = state => state.GetNumber.chooseNumberOption ? state.GetNumber.chooseNumberOption.toString() : state.GetNumber.chooseNumberOption
export const getAvailableNumbers = state => state.availableNumbers
const numberTypesInfoSelector = state => state.GetNumber.numberTypes

export const getSelectedHelperNumberTypeInfo = createSelector(
  [getNumberHelperSelection, numberTypesInfoSelector],
  (numberHelperSelection, numberTypes) => _.find(numberTypes, numberType => numberType.numberType === numberHelperSelection)
)

export const getNumber03Selector = createSelector(
  numberTypesInfoSelector,
  numberTypes => {
    return _.find(numberTypes, numberType => numberType.numberType === '03')
  }
)

export const getNumber0203Selector = createSelector(
  numberTypesInfoSelector,
  numberTypes => {
    return _.find(numberTypes, numberType => numberType.numberType === '0203')
  }
)

export const getNumber0207Selector = createSelector(
  numberTypesInfoSelector,
  numberTypes => {
    return _.find(numberTypes, numberType => numberType.numberType === '0207')
  }
)

export const getNumber01Selector = createSelector(
  numberTypesInfoSelector,
  numberTypes => {
    return _.find(numberTypes, numberType => numberType.numberType === '01')
  }
)

export const getNumber0800Selector = createSelector(
  numberTypesInfoSelector,
  numberTypes => {
    return _.find(numberTypes, numberType => numberType.numberType === '0800')
  }
)

export const getNumber0808Selector = createSelector(
  numberTypesInfoSelector,
  numberTypes => {
    return _.find(numberTypes, numberType => numberType.numberType === '0808')
  }
)

export const getNumber084Selector = createSelector(
  numberTypesInfoSelector,
  numberTypes => {
    return _.find(numberTypes, numberType => numberType.numberType === '084')
  }
)
