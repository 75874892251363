import moment from 'moment'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import LoadingNotification from '../../../components/LoadingNotification'
import Pager from '../../../components/pager'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { simplePhoneNumberFormat } from '../../../helpers/utils'

class FaxesView extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = { isFetchingFaxes: false, totalPages: 0, page: 1 }

  handlePageChange (page) {
    this.setState({ page })
  }

  render () {
    return (<><ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <header>
                <div className="full-width full-hero-small" style={{ backgroundImage: "url('/images/backgrounds/faxes-bg.jpg')" }}></div>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pb-30">
                            <h1>Faxes</h1>
                            <h2 className="subtitle">Faxes you have received</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 pt-30 pb-40">
                            {!this.state.faxes && <LoadingNotification message={'Loading your Faxes'} isLoading={true} />}

                            {this.state.faxes && this.state.faxes.length === 0 &&
                                <div className="col-sm-12 col-md-12 pl-0 pr-0 pt-10 pb-10">
                                    <div className="text-center">
                                        <p>
                                            There aren't any faxes to display for this number
                                            </p>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    {this.state.faxes && this.state.faxes.length > 0 &&
                    <>
                        <div className="row">
                            <div className="col-sm-3 col-md-3 text-center">
                                <p className="text-500 text-xs">From</p>
                            </div>
                            <div className="col-sm-2 col-md-2 text-center">
                                <p className="text-500 text-xs">To (Your fax number)</p>
                            </div>
                            <div className="col-sm-3 text-center">
                                <p className="text-500 text-xs">Date and Time</p>
                            </div>
                            <div className="col-sm-2 text-center">
                                <p className="text-500 text-xs">Pages</p>
                            </div>
                            <div className="col-sm-1 text-center">
                                <p className="text-500 text-xs">Status</p>
                            </div>
                            <div className="col-sm-1 text-center">
                                <p className="text-500 text-xs">Download</p>
                            </div>
                        </div>

                        {this.state.faxes && this.state.faxes.map((fax) => <div className="row pl-0 pr-0 bt-1 pt-10 pb-10">
                            <div className="col-sm-3 text-center pt-sm-30">
                                <p>
                                    <span className="text-500 text-xs d-block d-md-none">From: </span>
                                    <a href="tel:{simplePhoneNumberFormat(fax.cli)}" className="text-xs-lg">{simplePhoneNumberFormat(fax.cli)}</a>
                                </p>
                            </div>
                            <div className="col-sm-2 text-center pt-sm-30">
                                <p>
                                    <span className="text-500 text-xs d-block d-md-none">To (Your fax number): </span>
                                    {simplePhoneNumberFormat(fax.queue)}
                                </p>
                            </div>
                            <div className="col-sm-3 text-center pt-sm-30">
                                <p>
                                    <span className="text-500 text-xs d-block d-md-none">Date and Time: </span>
                                    {moment(fax.rxDate).format('DD/MM/YYYY HH:mm')}
                                </p>
                            </div>
                            <div className="col-sm-2 text-center pt-sm-30">
                                <p>
                                    <span className="text-500 text-xs d-block d-md-none">Pages: </span>
                                    {fax.pages}
                                </p>
                            </div>
                            <div className="col-sm-1 text-center pt-sm-30">
                                <p>
                                    <span className="text-500 text-xs d-block d-md-none">Status: </span>
                                    {fax.viewed === 0 ? 'Unread' : 'Read'}
                                </p>
                            </div>
                            <div className="col-sm-1 text-center pb-sm-30 pt-sm-30">
                                <Button variant="cta" size="xs" className="btn-mobile-block" onClick={e => this.handleFaxDownload(fax)}>Download</Button>
                            </div>
                        </div>)}

                        <Pager onSelected={page => this.handlePageChange(page)} activePage={this.state.page}
                            pages={this.state.totalPages} />
                    </>}
                </div>
            </div>
        </>)
  }

  handleFaxDownload (fax) {
    const url = `api/Numbers/DownloadFax/${fax.faxpin}`

    fetchHelper.getBlob(url).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fax.faxpin}.pdf`)
      document.body.appendChild(link)
      link.click()
    }).catch(error => console.error(error))
  }

  componentDidMount () {
    this.getFaxes()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getFaxes()
    }
  }

  getFaxes () {
    const faxNo = this.props.match.params.faxNo
    const currentPage = this.state.page

    const context = this

    const getPromptsUrl = `api/Numbers/GetFaxes/${faxNo}/${currentPage}`

    this.setState({ isFetchingFaxes: true })

    fetchHelper.getJson(getPromptsUrl).then(response => {
      const data = response.data
      context.setState({ isFetchingFaxes: false, totalPages: data.totalPages, faxes: data.faxes })
    }).catch(error => {
      context.setState({ isFetchingFaxes: false })
    })
  }
}

export default FaxesView
