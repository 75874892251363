import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { type StripePaymentElementOptions } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { stripeReturnBaseUrl } from '../../constants/Constants'
import LoadingNotification from '../LoadingNotification'

interface StripeCheckoutFormProps {
  postCode: string
  handlePostPayment: (transId, pmId) => void
  amount?: number | undefined
  email?: string | undefined
  name?: string | undefined
  tel?: string | undefined
  hardware?: boolean | undefined
  address: any
  secret: string
}

const StripeCheckoutForm = (props: StripeCheckoutFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const options: StripePaymentElementOptions = {
    fields: {
      billingDetails: {
        address: {
          country: 'never',
          postalCode: (props.postCode === undefined || props.postCode === '') ? 'auto' : 'never'
        }
      }
    },
    defaultValues: {
      billingDetails: {
        email: props.email,
        name: props.name,
        phone: props.tel
      }
    },
    layout: 'accordion'
  }

  const handleSubmit = async (event) => {
    setIsLoading(true)
    event.preventDefault()

    if ((stripe === null) || (elements === null)) {
      setIsLoading(false)
      return
    }

    if (props.amount !== undefined && props.amount > 0) {
      let paymentResult
      const pmData = {
        billing_details: {
          address: {
            country: 'GB',
            postal_code: props.postCode,
            line1: props.address?.add1,
            line2: props.address?.add2,
            state: props.address?.add3,
            city: props.address?.add4
          }
        }
      }

      if (props.hardware === true) {
        paymentResult = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: `${stripeReturnBaseUrl}signup/checkout/complete`,
            payment_method_data: pmData
          }
        })
      } else {
        paymentResult = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
          confirmParams: {
            mandate_data: {
              customer_acceptance: {
                type: 'online',
                online: {
                  infer_from_client: true
                }
              }
            },
            return_url: `${stripeReturnBaseUrl}signup/checkout/complete`,
            payment_method_data: pmData
          }
        })
      }

      if (paymentResult.error) {
        // Payment failed

        // Let's return a user-friendly error for card declines
        if (paymentResult.error?.code === "card_declined") {

          //https://stripe.com/docs/declines/codes
          switch (paymentResult.error.decline_code) {
            case "authentication_required":
              setErrorMessage('Your cardholder has requested authentication for this transaction. Please try again and complete the 3DS step when prompted.')
              break;
            case "insufficient_funds":
              setErrorMessage('Please ensure you have enough funds available to cover this transcation.')
              break;
            case "lost_card":
            case "stolen_card":
              // Potentially fradulent transaction
              setErrorMessage('The card you\'re using has been reported lost or stolen.')
              break;
            case "fraudulent":
            case "merchant_blacklist":
              // Potentially fradulent transaction
              setErrorMessage('Your card has been declined. Please contact our support team on 0203 189 1213')
              break;
            case "processing_error":
              setErrorMessage('There was a problem processing your payment, please try again.')
              break;
            default:
              setErrorMessage('Your payment was rejected by the bank. Please try a different payment method, or contact your bank.')
          }



        } else {
          // Stripe already returns a user-friendly error message for other declines
          setErrorMessage(paymentResult.error.message)
        }

        setIsLoading(false)
      } else {
        // Payment success
        localStorage.removeItem('stripeSecret')
        props.handlePostPayment(paymentResult.paymentIntent.id, paymentResult.paymentIntent.payment_method)
        setIsLoading(false)
      }
    } else {
      const setupResult = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
          mandate_data: {
            customer_acceptance: {
              type: 'online',
              online: {
                infer_from_client: true
              }
            }
          },
          return_url: `${stripeReturnBaseUrl}signup/checkout/complete`,
          payment_method_data: {
            billing_details: {
              address: {
                country: 'GB'
              }
            }
          }
        }
      })

      if (setupResult.error !== undefined) {
        // Setup failed
        // @ts-expect-error 
        setErrorMessage(setupResult.error.message)
      } else {
        localStorage.removeItem('stripeSecret')
        props.handlePostPayment(setupResult.setupIntent.id, setupResult.setupIntent.payment_method)
        setIsLoading(false)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={options} onReady={() => setIsLoading(false)} />

      {errorMessage && <p className="text-400 text-danger text-sm mt-20 mb-0">{errorMessage}</p>}

      <button className="btn btn-cta mt-20 " disabled={((stripe === null) || (elements === null) || isLoading)}>
        {props.amount === 0 ?
          'Update Payment Details' : 
          <>
           Continue with Payment <span className="icon icon-arrow-right8 text-sm ml-10"></span> 
          </>
        }
      </button>
    </form>

  //  isLoading
  //    ? <LoadingNotification className="mt-30" message={'Loading, please wait...'} textColor="text-darker" isLoading={true} />
  //    :			
  )
}

export default StripeCheckoutForm
