import moment from 'moment'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Logos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat } from '../../helpers/utils'
import { isLoggedIn } from '../../selectors'
import * as fromHome from '../../store/Home'
import { handleNotificationChange, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'
import TermsAndConditions from '../../Text/TermsAndConditions'

class Headphones extends React.Component {
  state = {
    topup: 10
  }

  constructor (props) {
    super(props)
  }

  render () {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <>
				<p className="text-700 text-cta text-uppercase text-md mb-20">
					Ends in:
				</p>
				<div className="countdown-timer">
					<div className="timer-block">
						{days}<br /><span className="text-sm">days</span>
					</div>
					<div className="timer-block">
						{hours}<br /><span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}<br /><span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}<br /><span className="text-sm">seconds</span>
					</div>
				</div>
			</>
    }

    return <><DisplayMainFooter />
			<Helmet>
				<body className={'body-simple bg-dull'} />
				<title>Extra Call Credit for FREE!</title>
				<style type="text/css">{`
					.plan-options {
						transition: all .2s ease-in-out;
					}
					.plan-options .selected-plan{
						height:30px;
						width:30px;
						background-color:#ff9314;
						color:#fff;
						border-radius:50%;
						padding: 5px;
						position: absolute;
						top: 20px;
						left: 25px;
						display:none;
					}
					.plan-options .text-xl{
						font-size:60px !important;
						line-height:1;
					}
					.plan-options.active{
						border:1px solid #ff9314 !important;
					}
					.plan-options.active .selected-plan{
						display:block !important;
					}
					.plan-options:hover{
						cursor:pointer;
						border:1px solid #2c808b !important;

					}
					.main-body{
						background: #fff url('/images/campaigns/double-topup/background.jpg') no-repeat;
						background-position: 80% 100%;
						background-size: 610px;
					}
					.line-1{
						line-height:1;
					}
					.line-2{
						font-size:100px;
						line-height:1;
					}

					@media screen and (max-width:1920px) {
						.main-body{
							background-position: 100% 100%;
						}
					}
					@media screen and (max-width:767px) {
						.line-2{
							font-size:16vw;
						}
					}
                    
                    `}</style>
			</Helmet>
			<div className="p-50 p-sm-30 p-xs-15 pt-xs-50 pt-100 pb-100 main-body" >
				<div className="container p-xs-0">
					<div className="row mb-50">
						<div className="w-1000px center-block text-center">
							<span className="bg-secondary p-10 pr-50 pl-50 pl-xs-10 pr-xs-10 text-light text-lg text-500 text-uppercase text-lightest text-xs-sm">
								{moment().format('MMMM')} Exclusive
							</span>
							<h2 className="text-400 mt-30 text-dark">
								<em>Want Extra Call Credit?...</em>
							</h2>
							<h1 className="text-underline text-cta">
								Get <span className="text-italic text-700 text-uppercase">Double</span> The Number When You Top-Up This Month!
							</h1>
							<h5 className="text-dark text-500 pt-20 text-italic">{moment().format('MMMM')} Only - Don’t Miss Out!</h5>
							<>
								<span className="p-30 bg-darker mb-20 d-inline-block center-block brad-100 mt-50">
									<Countdown date={moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
								</span>
							</>
						</div>
					</div>
					<div className="row">
						<div className={'col-lg-7 col-md-12 col-sm-12'}>
							<div className="bg-dull p-50 p-sm-30 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pt-sm-50 pb-sm-50 pr-xs-20 pl-xs-20 clearfix text-center">
								{this.state.diallerSent === true
								  ? <>
										<h3 className="text-500 mt-30">
											Thanks!
										</h3>
										<p className="text-400 lead mb-10 mt-0">We'll give you a call shortly.</p>
										<p className="text-md mt-30">
											We've successfully received your request and one of our team will call you shortly.
										</p>
										<p className="mt-30">
											<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
										</p>
									</>
								  :									this.state.purchaseComplete === true
								    ? <>
											<h3 className="text-500 mt-30">
												Thanks!
											</h3>
											<p className="text-400 lead mb-10 mt-0">Your order is complete.</p>
											<p className="text-md mt-30">
												We've added &pound;{this.state.topup * 2} call credit to your account.
											</p>
											<p className="mt-30">
												<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
											</p>

										</>
								    :										this.state.showError === true
								      ? <>
												<h3 className="text-500 mt-30">
													Oops..
												</h3>
												<p className="text-400 lead mb-10 mt-0">Sorry, something went wrong.</p>
												<p className="text-md mt-30">
													Something didn't go to plan when adding your credit.
													Please contact our support team on <a href="tel:02031891213" className="text-500 text-dark">0203 189 1213</a> or, <Button variant="link" className="text-dark" onClick={() => this.handleAddToDialler()}>request a callback</Button>
												</p>
											</>
								      :											<>
												<div className="row">
													<div className="col-md-12">
														<>
															<h2 className="text-700 mt-0 text-italic text-cta text-uppercase">
																<span className="line-1 d-block">Top-Up Double-up</span>
																<span className="line-2 d-block">Bonanza</span>
															</h2>
															<p className="text-dark text-lg text-400">
																Add Extra Credit To Your Account
																<br />
																&amp; We’ll Double It On The Spot!
															</p>
															<p className="text-400 underline text-uppercase pt-40 pb-20">
																Select a Top-Up amount:
															</p>
															<div className="row">
																<div className="col-xxs-12 col-xs-4 text-center ">
																	<div className={`box-shadow b-1 brad-20 bg-light plan-options p-20 mt-10 ${this.state.topup === 5 ? 'active' : ''}`} onClick={() => this.setState({ topup: 5 })}>
																		<span className="selected-plan">
																			<span className="icon icon-checkmark4 text-lightest"></span>
																		</span>

																		<span className="text-secondary text-500 text-dark text-uppercase d-block text-md">Top Up</span>
																		<span className="text-secondary text-500 d-block text-xl"><span style={{ fontSize: '26px' }}>&pound;</span>5</span>

																		<span className="text-dark text-500 text-uppercase d-block text-xs">We Double-Up To: <span className="text-cta">&pound;10</span></span>
																	</div>
																</div>
																<div className="col-xxs-12 col-xs-4 text-center ">
																	<div className={`box-shadow b-1 brad-20 bg-light plan-options p-20 mt-10 ${this.state.topup === 10 ? 'active' : ''}`} onClick={() => this.setState({ topup: 10 })}>
																		<span className="selected-plan">
																			<span className="icon icon-checkmark4 text-lightest"></span>
																		</span>

																		<span className="text-secondary text-500 text-dark text-uppercase d-block text-md">Top Up</span>
																		<span className="text-secondary text-500 d-block text-xl"><span style={{ fontSize: '26px' }}>&pound;</span>10</span>

																		<span className="text-dark text-500 text-uppercase d-block text-xs">We Double-Up To: <span className="text-cta">&pound;20</span></span>
																	</div>
																</div>
																<div className="col-xxs-12 col-xs-4 text-center ">
																	<div className={`box-shadow b-1 brad-20 bg-light plan-options p-20 mt-10 ${this.state.topup === 20 ? 'active' : ''}`} onClick={() => this.setState({ topup: 20 })}>
																		<span className="selected-plan">
																			<span className="icon icon-checkmark4 text-lightest"></span>
																		</span>

																		<span className="text-secondary text-500 text-dark text-uppercase d-block text-md">Top Up</span>
																		<span className="text-secondary text-500 d-block text-xl"><span style={{ fontSize: '26px' }}>&pound;</span>20</span>

																		<span className="text-dark text-500 text-uppercase d-block text-xs">We Double-Up To: <span className="text-cta">&pound;40</span></span>
																	</div>
																</div>
															</div>
														</>

													</div>
												</div>
												{this.state.submittingToDialler
												  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
												  :													<>
														{this.state.isPurchaseInProgress
														  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
														  :															<>
																<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20 btn-block brad-10 mt-30"
																	onClick={() => this.handlePurchase()}>Add to account NOW</Button>

																{this.state.diallerSent === false &&
																	<p className="text-400 text-danger text-center pt-20">
																		Sorry, there was a problem submitting your request, please try again.
																	</p>
																}
																<p className="text-dark pt-30">
																	Want to know more? Give us a call on <a href="tel:02031891213" className="text-500 text-dark">0203 189 1213</a> or, <Button variant="link" className="text-dark" onClick={() => this.handleAddToDialler()}>request a callback</Button>
																</p>
															</>
														}
													</>
												}
											</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-dull p-50 text-center">
				<sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
			</div>

			<div className="bg-light pt-50 pb-50">
				<h3 className="text-center text-500 mb-20 text-lg pl-25 pr-25">Proud to work with leading UK businesses</h3>
				<Logos style="light" />
			</div>

			<div className="full-width bg-dull">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>

			<Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
				<Modal.Header closeButton>
					<Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TermsAndConditions />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
				</Modal.Footer>
			</Modal>

			<ChoosePayment
				total={this.state.wpAmount ? calculateVat(this.state.wpAmount) : 0}
				clientId={this.props.clientId}
				purchaseData={this.state.key}
				returnRoute={`/get/double-credit?t=${this.state.token}`}
				email={this.props.userDetails.email}
				name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
				mandate={!this.props.services.hasAgreement}
			/>

			<Modal size="sm" show={this.state.showLogin}>
				<Modal.Header>
					<Modal.Title className="mx-auto">Log into your account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12 text-center pb-30">
							<form name="form" className="text-left" onSubmit={e => this.props.login({
							  userName: this.props.userName,
							  password: this.props.password,
							  event: e,
							  history: this.props.history,
							  persist: document.getElementById('persistLogin').checked,
							  route: '/get/double-credit'
							})}>
								<div className="row">
									<div className="col-xs-12">
										<p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
									</div>
									<div className="col-xs-12">
										<label>Email</label>
										<input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
									</div>
									<div className="col-xs-12 mt-20">
										<label>Password</label>
										<input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
									</div>
								</div>
								<div className="pt-10 pb-10 row">
									<div className="col-xs-6 text-start">
										<input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
									</div>
									<div className="col-xs-6 text-end">
										<Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
									</div>
								</div>
								<div className="text-center pt-30">
									{this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                  {this.props.loginFailed === true && (this.props.loginFailReason === '' || this.props.loginFailReason === null || this.props.loginFailReason === undefined) ?
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'} className="underline text-danger">resetting your password</Link>.</p>
                  :
                  <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage" hidden={!this.props.loginFailed}>{this.props.loginFailReason}</p>
                  }
									<button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

									<p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
									<p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>

		</>
  }

  componentDidUpdate () {
    if (this.props.isLoggedIn && this.state.showLogin === true) {
      this.setState({ showLogin: false })
    }
  }

  componentWillMount () {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount () {
    const context = this

    if (!context.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else if (context.props.isLoggedIn) {
      this.setState({ showLogin: false })
    }

    const query = new URLSearchParams(this.props.location.search)
    if (query) {
      if (query.get('success') || query.get('new') || query.get('newCred')) { context.handleAddCallCredit() }
    }
  }

  handlePurchase () {
    const context = this
    const url = `/api/AccessToken/PurchaseCallCredit?t=${context.state.token}&credit=${this.state.topup}&multiplier=${2}`
    const dto = {
      Credit: context.state.topup,
      Multiplier: 2
    }

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.postJson(url, dto).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          context.setState({ purchaseComplete: true })
        } else if (res.data && res.data.postponed === true) {
          context.setState({ purchaseComplete: true }, () => {
            context.props.handleShowPaymentPostponedModal()
          })
        } else {
          context.setState({ wpAmount: this.state.topup, key: res.data.key, isPurchaseInProgress: false, clientId: res.data.clientId }, () => {
            localStorage.setItem('creditToAdd', this.state.topup)
            context.props.handleShowPaymentOptionsModal()
          })
        }
      }).catch(function (error) {
        context.setState({ isPurchaseInProgress: false })
        console.error(error)
      })
    })
  }

  handleAddToDialler () {
    const context = this

    context.setState({ submittingToDialler: true }, () => {
      const url = `/api/AccessToken/SubmitClientToDialler?t=${context.state.token}&comments=Requested%20more%20information%20about%20%C2%A37%20per%20month%20outbound%20offer&listid=10500&campaign=4`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ diallerSent: res.data, submittingToDialler: false })
        }).catch(err => console.error(err))
    })
  }

  handleAddCallCredit () {
    const context = this
    const credit = localStorage.getItem('creditToAdd')
    const url = `/api/AccessToken/AddCreditToCustomer?t=${context.state.token}`

    context.setState({ topup: credit }, () => {
      fetchHelper.postJson(url, { Credit: credit * 2 })
        .then(res => {
          if (res.data && res.data === true) {
            context.setState({ purchaseComplete: true })
            localStorage.removeItem('creditToAdd')
          } else {
            context.setState({ showError: true })
          }
        })
        .catch(err => console.error(err))
    })
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      services: state.sbfApp.enabledServices,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed,
      userDetails: state.sbfApp.authentication,
      loginFailReason: state.home.login.reason
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    notify: handleNotificationChange,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal
  }
)(Headphones)
