import { Component } from 'react'

class ScrollToTopOnMount extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {}

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      null
    )
  }
}

export default ScrollToTopOnMount
