import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const MultipleDepartmentsHelperDialog = props => (
    <Modal size="lg" show={props.isDisplayed}
           onHide={props.handleHideMultipleDepartmentsHelperDialog}>
        <Modal.Header closeButton>
            <Modal.Title>Single / Multiple Departments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4 className="mt-30 text-500">Multiple Departments</h4>
            <p>
                All callers are played the main introduction, for example: <i>&quot;Thank you for calling [your company]&quot;</i>.
                <br/>Then are offered a main menu. e.g. <i>&quot;Please choose from one of the following options: press 1 for sales, press 2 for support...&quot;</i>.
            </p>
            <p>
                You can have up to 9 departments. Each department has its own independent settings, for example, your Sales department might have different opening times,
                redirect numbers or hunt groups, different music on-hold, alerts sent to different email addresses etc.
            </p>
            <p>
                Multiple Departments mode is ideal for growing companies or if your workforce is split over different locations or if you simply want to get that "big company feel" even though you might be a small business.
            </p>

            <h4 className='text-500'>
                Single Department
            </h4>
            <p>
                If your number is set as single department, callers dialling your number will hear your introduction,
                for example: &quot;Thank you for calling [your company]. Please wait while we connect you...&quot;
            </p>
            <p>
               So the main difference is that callers won't be preseted with &quot;press 1 for sales, press 2 for support...&quot; like on Multiple Departments.
            </p>
           <p>
                Single Department mode is mostly used by small businesses or one-mans bands and people that do not want to configure more than one department.
           </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.handleHideMultipleDepartmentsHelperDialog}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.MultipleDepartmentsHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleDepartmentsHelperDialog)
