import React from 'react'
import { Button, Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { TpsProtectionPerUserCharge, validationStates } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { simplePhoneNumberFormat } from '../../helpers/utils'
import { getProductPrice } from '../../store/Home'
import * as fromNumbers from '../../store/MyNumbers'
import * as sbf from '../../store/SBF'
import { handleNotificationChange } from '../../store/SBF'
import LoadingNotification from '../LoadingNotification'
import CallRecordingPackages from '../purchase/CallRecordingPackages'
import PurchasePhones from '../purchase/PurchasePhones'
import EnableTpsProtectionModal from './EnableTpsProtectionModal'
import OpeningTimesOffice from '../../Text/OpeningTimesOffice'
import SupportPhoneNumber from '../../Text/SupportPhoneNumber'

const formatGroupLabel = data => (
	<div className="p-5 dropdown-group-label">
		<span>{data.label}</span>
		<span className="group-badge">{data.options.length}</span>
	</div>
)

const whatIsCr = (
	<Popover id="popover-basic">
		<p>As you have call recording active on your account, you can enable call recording on a per user basis, too.</p>
		<p className="mb-0">Call recording will record all inbound and outbound calls from your users.</p>
	</Popover>
)

const whatIsTPSProtection = (
	<Popover id="popover-basic">
		<p className="mb-0">TPS Protection will protect your users from calling numbers that are registered on the TPS (Telephone Preference Service)</p>
	</Popover>
)

const whatIsSecret = (
	<Popover id="popover-basic">
		<p className="mb-0">These details are required for your user to use the SwitchboardFREE phone app, or our SwitchboardFEE Softphone.</p>
	</Popover>
)
const whatIsExtension = (
	<Popover id="popover-basic">
		<p className="mb-0">The extension is the number used when dialling this user internally.</p>
	</Popover>
)

const cliPopover = (
	<Popover>
		<p className="mb-0">This is the default number used when the app is loaded and will be displayed when this user dials out from their phone.</p>
	</Popover>
)

const availableCliPopover = (
	<Popover>
		<p className="mb-0">This is the list of available numbers for the user to use when the app is loaded and will be displayed when this user dials out from their phone.</p>
	</Popover>
)

const outboundEnablePopover = (
	<Popover>
		<p className="mb-0">Enable or disable the users ability to make outbound calls.<br/><i>Note: this doesn't effect the user being able to receive inbound calls.</i></p>
	</Popover>
)

class VoipUsers extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      toggleActive: false,
      epToDelete: undefined,
      showDeleteConfirm: false,
      deleteInProgress: false,
      endpoints: [],
      availableClisForUser: [],
      isLoading: true,
      showUpdateSipDetails: false,
      showTpsProtectionModal: false,
      clickedEndpointId: undefined,
      showDisableTpsProtectionModal: false,
      showEnableTpsProtectionModal: false,
			isPurchaseInProgress: false
    }

    this.downloadUserCSV = this.downloadUserCSV.bind(this)
  }

  retrieveEndpoints () {
    const url = '/api/Outbound/RetrieveOutboundEndpoints'
    fetchHelper.getJson(url)
      .then(res => {
        const endpoints = res.data
        this.setState({ endpoints: endpoints.sort((a, b) => a.id - b.id), isLoading: false })
      }).catch(err => console.error(err))
  }

  async componentDidMount () {
    const context = this

    this.retrieveEndpoints()

    // Retrieve cli's for customer
    const url = 'api/Outbound/RetrieveOutboundClis'
    fetchHelper.getJson(url)
      .then(res => {
        context.setState({ clis: res.data })
      }).catch(err => console.error(err))
  }

  closeEditEndpoint () {
    this.setState({
      editEndpoint: false,
      endpointToEdit: {
        extName: '',
        extNumber: 0,
        cli: undefined
      }
    })
  }

  componentDidUpdate () {
    if (this.state.endpoints && this.state.endpoints.length < this.props.userCount) { this.retrieveEndpoints() }
  }

  handleUserAvailableClisChanged (event) {
    let defaultCliAvailable = false

    if (this.state.endpointToEdit.cli !== null) {
      defaultCliAvailable = event.find(x => x.label === this.state.endpointToEdit.cli.label)
    }
    if (defaultCliAvailable === undefined) {
      this.setState({
        availableClisForUser: event,
        endpointToEdit: { ...this.state.endpointToEdit, cli: null }
      })
    } else {
      this.setState({
        availableClisForUser: event
      })
    }
  }

  editEndpointPressed (ep) {
    const context = this
    const endpoint = { ...ep }

    if (endpoint.mobile && endpoint.mobile.length > 0) { this.props.handleValidateTel({ newValue: endpoint.mobile }) }

    if (endpoint.email && endpoint.email.length > 0) { this.props.handleValidateEmail({ newValue: endpoint.email }) }

    const url = 'api/Outbound/GetAvailableClisForEndpoint/' + ep.id

    fetchHelper.getJson(url).then((res) => {
      const data = res.data

      const availableClis = []
      for (let i = 0; i < data.length; i++) {
        const cliId = data[i]

        let cli = context.state.clis[0].options.find(option => Number(option.value) === cliId)

        if (cli === undefined && context.state.clis.length > 1) {
          cli = context.state.clis[1].options.filter(option => Number(option.value) === cliId)
        }

        if (cli !== undefined) {
          availableClis.push(cli)
        }
      }

      this.setState({ availableClisForUser: availableClis })
    })

    if (ep.cli === null) {
      endpoint.cli = null
    } else {
      let selectedCli = context.state.clis[0].options.find(option => option.label.replaceAll(' ', '') === ep.cli.replaceAll(' ', ''))

      if (selectedCli === undefined && context.state.clis.length > 1) {
        selectedCli = context.state.clis[1].options.filter(option => option.label.replaceAll(' ', '') === ep.cli.replaceAll(' ', ''))
      }

      if (selectedCli !== undefined) {
        endpoint.cli = selectedCli
      } else {
        endpoint.cli = null
      }
    }

    this.setState({ editEndpoint: true, endpointToEdit: endpoint })
  }

  render () {
    const hasOutboundCallRecording = (this.props.services.hasOutboundCallRecording || this.props.services.hasCallRecordingSingle || this.props.services.hasCallRecordingAll || this.props.services.hasUnlimitedCallRecording)

    return <>
			{this.state.isLoading &&
				<LoadingNotification loadingText={'Loading users, please wait...'} isLoading={true} width={100} height={100} textColor="text-dark" />
			}

			{this.state.endpoints && this.state.endpoints.length > 0 &&
				<>
					<div className="col-md-12 pb-10">
                        <div className="text-center mb-10 pb-20">
                            <Button bsStyle="cta" bsSize="sm" className="btn-mobile-block p-10" onClick={ () => this.downloadUserCSV() }>
                                <span> Download Users </span>
                            </Button>
                        </div>
						<div className="row headings d-none d-lg-flex">
							<div className="col col-lg-3"></div>
							<div className={`text-500 text-xs pt-10 heading pl-sm-0 pr-sm-0 ${this.props.basicView ? 'col col-lg-1' : 'col col-lg-1'}`}>
								Extension
								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={whatIsExtension}>
									<span className="icon icon-info2 text-sm ml-5"></span>
								</OverlayTrigger>
							</div>
							<div className={`${this.props.basicView ? 'col  col-lg-2' : 'col col-lg-2'} text-500 pt-10 heading text-xs pl-sm-0 pr-sm-0`}>
								Default Cli
								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={cliPopover}>
									<span className="icon icon-info2 text-sm ml-5"></span>
								</OverlayTrigger>
							</div>
							{!this.props.basicView &&
								<>
									<div className="col col-lg-1 text-500 pt-10 heading text-xs pl-sm-0 pr-sm-0">Outbound Dialling
										<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={outboundEnablePopover}>
											<span className="icon icon-info2 text-sm ml-5"></span>
										</OverlayTrigger>
									</div>
									
									<div className="col col-lg-1 text-500 pt-10 heading text-xs pl-sm-0 pr-sm-0">Record calls?
										<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={whatIsCr}>
											<span className="icon icon-info2 text-sm ml-5"></span>
										</OverlayTrigger>
									</div>
									<div className="col col-lg-1 text-500 pt-10 heading text-xs pl-sm-0 pr-sm-0">TPS Protection
										<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={whatIsTPSProtection}>
											<span className="icon icon-info2 text-sm ml-5"></span>
										</OverlayTrigger>
									</div>
								</>
							}
							<div className={'col col-lg-2 text-500 pt-10 heading text-xs pl-sm-0 pr-sm-0'}>
								{/* Login details
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={whatIsSecret}>
									<span className="icon icon-info2 text-sm ml-5"></span>
								</OverlayTrigger> */}
							</div>
							<div className="col col-lg-2"></div>
						</div>
					</div>

					{this.state.endpoints.map((ep, i) => <>
						<div className="users-wrapper">
							<div className={'bg-light brad-10 box-shadow zoom mb-20 b-1 pt-20 pb-20 '.concat(ep.enabled ? 'number-open' : 'number-closed')}>
								<div className="row pl-10 pr-10">
									<div className={`${this.props.basicView ? 'col-12 col-lg-3' : 'col-12 col-lg-3'} queue-number pb-sm-0 pl-sm-0 pr-sm-0 text-center text-lg-start`}>
										<h2 className="h3 mt-0 mb-sm-0 ml-10 ml-xs-0 mt-5 ">
											<span className="number text-xs text-xs-md pb-xs-20 cursor-pointer" onClick={() => this.editEndpointPressed(ep)}>
						
												<strong className="text-secondary" title="Click to edit user">
													{ep.extName !== null && ep.extName.length > 15
													  ? 
															<span>
																{ep.extName.substr(0, 15)}...
															</span>
													
													  :	
													  ep.extName
													}
												</strong>
											
											</span>
										</h2>
									</div>
									<div className="col-12 col-lg-1 pb-sm-0 pl-sm-0 pr-sm-0 extension-number text-center text-lg-start br-1 bb-1 br-lg-0 bb-lg-0">
										<div className="d-block d-lg-none pb-10 pt-30 text-dark text-sm text-400">
											Extension <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={whatIsExtension}>
												<span className="icon icon-info2 text-sm ml-5"></span>
											</OverlayTrigger>
										</div>
										<p className="mt-15 mt-xs-0">{ep.extNumber}</p>
									</div>
									<div className={`${this.props.basicView ? 'col-12 col-lg-2 ' : 'col-12 col-lg-2'} pb-sm-0 pl-sm-0 pr-sm-0 cli-number text-center text-lg-start bb-1 bb-lg-0`}>
										<div className="d-block d-lg-none pb-10 pt-30 text-dark text-sm text-400">
											CLI <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={cliPopover}>
												<span className="icon icon-info2 text-sm ml-5"></span>
											</OverlayTrigger>
										</div>
										<p className="mt-15 mt-xs-0">{ep.cli ? simplePhoneNumberFormat(ep.cli) : <span>&nbsp;</span>}</p>
									</div>
									{!this.props.basicView &&
										<>
											<div className="col-12 col-lg-1 pl-sm-0 pr-sm-0 user-enabled text-center text-lg-start br-1 bb-1 br-lg-0 bb-lg-0 pb-20">
												<div className="d-block d-lg-none pb-10 pt-30 text-dark text-sm text-400">Enable/Disable User</div>
												<Toggle
													className="mt-15 rounded w-70px"
													onClick={e => this.toggleEndpointEnabled({
													  isEnabled: e,
													  id: ep.id,
													  ep
													})}
													on={<span>ON</span>}
													off={<span>OFF</span>}
													size="sm"
													offstyle="default"
													onstyle="success"
													width="null"
													height="null"
													active={ep.enabled}
													id="EnabledToggle"
												/>
												<span className="slider round"></span>
											</div>
											<div className="col-12 col-lg-1 pl-sm-0 pr-sm-0 call-recording text-center text-lg-start bb-1 bb-lg-0 pb-20">
												<div className="d-block d-lg-none pb-10 pt-30 text-dark text-sm text-400">
													Call Recording <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={whatIsCr}>
														<span className="icon icon-info2 text-sm ml-5"></span>
													</OverlayTrigger>
												</div>
												{!hasOutboundCallRecording && !ep.callRecordingEnabled
												  ? <>
														<OverlayTrigger key={i} placement='top' overlay={
															<Popover id={`tooltip_recording_${i}`}>
																<p>This feature is only available when outbound call recording is active on your account.</p>
															</Popover>
														}>
															<span>
																<Toggle
																	className="mt-15 disabled rounded w-70px"
																	onClick={e => this.toggleEndpointCallRecording({
																	  isEnabled: e,
																	  id: ep.id,
																	  ep,
																	  cr: hasOutboundCallRecording
																	})}
																	on={<span>ON</span>}
																	off={<span>OFF</span>}
																	size="sm"
																	offstyle="default"
																	onstyle="success"
																	width="null"
																	height="null"
																	active={ep.callRecordingEnabled}
																	id="CallRecordingToggle"
																/>
																<span className="slider round"></span>
															</span>
														</OverlayTrigger>
													</>
												  :													<>
														<Toggle
															className="mt-15 rounded w-70px"
															onClick={e => this.toggleEndpointCallRecording({
															  isEnabled: e,
															  id: ep.id,
															  ep,
															  cr: hasOutboundCallRecording
															})}
															on={<span>ON</span>}
															off={<span>OFF</span>}
															size="sm"
															offstyle="default"
															onstyle="success"
															width="null"
															height="null"
															active={ep.callRecordingEnabled}
															id="CallRecordingToggle"
														/>
														<span className="slider round"></span>
													</>
												}
											</div>
											<div className="col-12 col-lg-1 pl-sm-0 pr-sm-0 text-center text-lg-start br-1 bb-1 br-lg-0 bb-lg-0 pb-20">
												<div className="d-block d-lg-none pb-10 pt-30 text-dark text-sm text-400">Enable/Disable TPS Protection</div>
												<Toggle
													className="mt-15 rounded w-70px"
													onClick={e => this.toggleTpsProtectionPopup({
													  isEnabled: e,
													  id: ep.id,
													  ep
													})}
													on={<span>ON</span>}
													off={<span>OFF</span>}
													size="sm"
													offstyle="default"
													onstyle="success"
													width="null"
													height="null"
													active={ep.tpsProtectionEnabled}
													id="TPSProtectionToggle"
												/>
												<span className="slider round"></span>
											</div>
										</>
									}
									<div className="col-12 col-lg-2 pb-sm-0 pl-sm-0 pr-sm-0 pt-10 pt-xs-20 user-edits text-center text-lg-end float-lg-end">
										{ep.secret !== '' &&

											<Button title="User login details" variant="default" size="xs" className="box-shadow-none btn-rounded mt-10 mr-5 ml-5" onClick={() => this.revealSecret(ep)} id="ShowSoftphoneDetailsButton">
												<span className={`${this.props.basicView ? 'mr-10' : ''} icon icon-eye d-none d-lg-block`}></span>
												<span className="d-block d-lg-none">User Login Details</span>
											</Button>

										}

											<Button title="Edit user" variant="default" size="xs" className="box-shadow-none btn-rounded mt-10 mr-5 ml-5" onClick={() => this.editEndpointPressed(ep)} id="EditUserButton">
												<span className={`${this.props.basicView ? 'mr-10' : ''} icon icon-pencil d-none d-lg-block`}></span>
												<span className="d-block d-lg-none">Edit</span>
											</Button>


										{!this.props.basicView &&

											<Button title="Delete user" variant="default" size="xs" className="box-shadow-none btn-rounded mt-10 mr-5 ml-5" onClick={() => this.setState({ showDeleteSuccess: false, showDeleteConfirm: true, epToDelete: ep })} id="DeleteUserButton">
												<span className="icon icon-bin d-none d-lg-block"></span>
												<span className="d-block d-lg-none">Delete</span>
											</Button>
										}

										{this.state.isInviting === ep.id
											? <LoadingNotification hideText={true} isLoading={true} width={32} height={32} textColor="text-darker" />
										  :	
												<Button title="Resend SIP login details" variant="default" size="xs" className="box-shadow-none btn-rounded mt-10 mr-5 ml-5" onClick={() => this.handleResendSipDetails(ep)} id="ResendSipDetailsButton">
													<span className="icon icon-envelop d-none d-lg-block"></span>
													<span className="d-block d-lg-none">Resend SIP details</span>
												</Button>
						
										}

										{(ep.macAddress === null || ep.macAddress === '') &&
												<Button title="Buy VoIP handset" variant="default" size="xs" className="box-shadow-none btn-rounded mt-10 mr-5 ml-5" onClick={() => this.setState({ showEditDevices: true })} id="BuyVoipHandsetButton">
													<span className="icon icon-cart d-none d-lg-block"></span>
													<span className="d-block d-lg-none">Buy Handset</span>
												</Button>
											
										}

									</div>
								</div>
							</div>
						</div>
					</>
					)}

				</>
			}

			{this.state.epToShow &&
				<Modal size="lg" show={this.state.showSecret} onHide={() => this.closeRevealSecret()}>
					{/* <Modal.Header closeButton className="bb-0">
                        <Modal.Title>Softphone / App User Login Details</Modal.Title>
                    </Modal.Header> */}
					<Modal.Body className="text-center pl-30 pr-30 pl-xxs-15 pr-xxs-15">
						<span className="close-modal" onClick={e => this.setState({ showSecret: false })}>
							<span>&times;</span>
						</span>
						<div className="col-md-12 text-center pb-40">
							<h5 className="text-center text-500">Softphone &amp; smartphone app user login details</h5>
						</div>
						<div className="col-xs-12 mt-20 text-start bg-dull brad-20 p-20">
							<p className="text-500 mb-20">Use the below details to login using our iOS, Android app or Softphone.</p>
							<p>
								<strong>Username: </strong> {this.state.epToShow.name[0]}.{this.state.epToShow.name[1]}
								<br />
								<strong>Password: </strong> {this.state.epToShow.secret}
							</p>

							<div className="pt-10">
								{this.state.showUpdateSipDetails &&
									<div className="row">
										<div className="col-xs-6 col-xxs-12">
											<div className="form-group">
												<label htmlFor="name">Mobile <span className="text-danger ml-10 text-sm">Required</span></label>
												<input type="tel" className={`form-control ${this.props.validation && this.props.validation.telValidationState !== validationStates.VALID ? 'has-error' : ''}`} id="Mobile" defaultValue={this.state.epToShow.mobile} onChange={e => this.setState({ epToShow: { ...this.state.epToShow, mobile: e.target.value } })} maxLength={20} onBlur={e => this.props.handleValidateTel({ newValue: e.target.value })} />
												{this.props.validation && this.props.validation.telValidationState !== validationStates.VALID &&
													<span className="text-400 text-danger text-sm">
														Please enter a valid mobile number
													</span>
												}
											</div>
										</div>
										<div className="col-xs-6 col-xxs-12">
											<div className="form-group">
												<label htmlFor="name">Email Address<span className="text-danger ml-10 text-sm">Required</span></label>
												<input type="email" className={`form-control ${this.props.validation && this.props.validation.emailValidationState !== validationStates.VALID ? 'has-error' : ''}`} id="EmailAddress" defaultValue={this.state.epToShow.email} onChange={e => this.setState({ epToShow: { ...this.state.epToShow, email: e.target.value } })} maxLength={120} onBlur={e => this.props.handleValidateEmail({ newValue: e.target.value })} />
												{this.props.validation && this.props.validation.emailValidationState !== validationStates.VALID &&
													<span className="text-400 text-danger text-sm">
														Please enter a valid email address
													</span>
												}
											</div>
										</div>
									</div>
								}

								{this.state.isInviting === this.state.epToShow.id
									? <LoadingNotification hideText={true} isLoading={true} width={32} height={32} />
								  :									<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Send the user an SMS & email containing above login details</Tooltip>}>
										<Button variant="secondary" size="sm" onClick={() => this.handleResendSipDetails(this.state.epToShow)} className="mt-10">Resend SIP Details</Button>
									</OverlayTrigger>
								}

							</div>
						</div>

						<div className="divider mt-40 text-center col-xs-12 mb-40">
							<span className="icon icon-arrow-down2"></span>
						</div>

						<h6 className="text-500 mt-40">Get our free iOS and Android app</h6>
						<p>
							Use the above login details to make and receive calls with our iOS
							(<a href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509" rel="noopener noreferrer" className="underline text-cta" target="_blank" id="DownloadOurIosAppLink">download our iOS app</a>)
							or Android app (<a href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB&gl=US" rel="noopener noreferrer" className="underline text-cta" target="_blank" id="DownloadOurAndroidAppLink">download our Android app</a>).
						</p>
						<div className="divider mt-40 text-center">
							<span className="icon icon-arrow-down2"></span>
						</div>
						<h6 className="text-500 mt-40">Trying to make and receive calls from your <br className="d-none d-xs-block" />Windows PC or Apple Mac?</h6>
						<p className="mb-40">
							<Link to={'/setup/softphone'} className="underline text-cta text-400" target="_blank" id="SoftphoneSetupClickHereLink">
								Click here</Link> to download and to get full instructions on how <br className="d-none d-xs-block" />to login using our VoIP Softphone.
						</p>
						<div className="divider mt-40 text-center">
							<span className="icon icon-arrow-down2"></span>
						</div>
						<h6 className="text-500 mt-40">Using one of our VoIP Handsets?</h6>
						<p className="mb-40">
							<Link to={'/setup'} className="underline text-cta text-400" target="_blank" id="VoipHandsetsClickHereLink">
								Click here</Link> to find our full instructions on how <br className="d-none d-xs-block" />to use your VoIP phone.
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => this.closeRevealSecret()} className="btn-xxs-block ml-xxs-0 mt-xxs-10">Close</Button>
					</Modal.Footer>
				</Modal>
			}

			<Modal size="lg" show={this.props.callRecordingDialogDisplayed} onHide={this.props.handleDismissCallRecordingDialog}>
				<Modal.Body className="text-center p-0">
					<CallRecordingPackages userServices={this.props.services} className="p-20" />
				</Modal.Body>
				<Modal.Footer>
					<div className="col-md-12">
						<Button variant="default" size="xs" onClick={() => this.props.handleDismissCallRecordingDialog()}>Close</Button>
					</div>
				</Modal.Footer>
			</Modal>

			{this.state.showDisableEndpointConfirm &&
				<Modal size="lg" show={true} onHide={() => this.setState({ showDisableEndpointConfirm: false })}>
					<Modal.Body className="text-center">
						{this.state.showDisabledSuccess
						  ? <>
								<div className="icon-wrapper mt-20 mb-20 icon-success">
									<span className="icon icon-checkmark"></span>
								</div>
								{this.state.disableCR
								  ? <h4>Call recording has been disabled for this user</h4>
								  :									<h4>User has been disabled</h4>
								}
							</>
						  :							<>
								<div className="icon-wrapper mt-20 mb-20">
									<span className="icon icon-warning"></span>
								</div>
								<h4>Are you sure?</h4>
								<p>
									You're about to disable user <strong>{this.state.epToDisable.extName}</strong> (ext. {this.state.epToDisable.extNumber}).<br />
									Once disabled, this user will no longer be able to make or receive any calls until they're re-enabled.
								</p>
								{this.state.showDisableError && this.state.msg &&
									<div className="alert alert-danger mt-20 mb-0">
										<p>{this.state.msg}</p>
									</div>
								}
							</>
						}
					</Modal.Body>
					<Modal.Footer className="text-center bt-0 p-0 mt-30">
						{this.state.showDisabledSuccess
						  ? <Button className="btn-block brad-0 pt-20 pb-20 w-100" onClick={e => this.setState({ showDisableEndpointConfirm: false, showDisabledSuccess: false })}>Close</Button>
						  :							<>
								<Button variant="default" className="btn-block brad-0 pt-20 pb-20" onClick={e => this.setState({ showDisableEndpointConfirm: false })} id="DisableUserCancelButton">Cancel</Button>
								<Button variant="danger" className="btn-block brad-0 pt-20 pb-20" onClick={e => this.disableEndpoint(this.state.epToDisable)} disabled={this.state.disableInProgress} id="DisableUserConfirmButton">
									{this.state.showDisableError
									  ? 'Try again'
									  :										'Yes, disable'
									}
								</Button>
							</>
						}
					</Modal.Footer>
				</Modal>
			}

			{this.state.epToDelete &&
				<Modal size="modal-med" show={this.state.showDeleteConfirm} onHide={e => this.setState({ showDeleteConfirm: false })}>
					<Modal.Body className="text-center">
						{this.state.showDeleteSuccess
						  ? <>
								<div className="success-modal mt-20 mb-20">
									<span className="icon icon-checkmark4"></span>
								</div>
								<h4 className='text-500'>User has been removed</h4>
							</>
						  :	
						  <>
								<div className="error-modal mt-20 mb-20">
									<span className="icon icon-warning2"></span>
								</div>
								<h4 className='text-500'>Please get in touch</h4>
								<p>
									To remove users from your account please contact customer support on <a className='underline text-dark' href={`tel:${SupportPhoneNumber.getPhoneNumber()}`}><SupportPhoneNumber /></a>. We are open <OpeningTimesOffice />
								</p>
								<p className="mt-20 clearfix">
									<Button className="brad-0 pt-20 pb-20 w-100" onClick={e => this.setState({ showDeleteConfirm: false })}>Close</Button>
								</p>
							</>
						}
					</Modal.Body>
				</Modal>
			}

			{this.state.endpointToEdit &&
				<Modal backdrop="static" keyboard={false} size="lg" show={this.state.editEndpoint} onHide={() => this.closeEditEndpoint()}>
					{/* <Modal.Header closeButton>
                        <Modal.Title>
                            Editing user {this.state.endpointToEdit.extName}
                        </Modal.Title>
                    </Modal.Header> */}
					<Modal.Body className="text-center">
						<span className="close-modal" onClick={() => this.closeEditEndpoint()}>
							<span>&times;</span>
						</span>
						<div className="col-md-12 text-center pb-40">
							<h5 className="text-center text-500" id="EditingUserH5">Editing user {this.state.endpointToEdit.extName}</h5>
						</div>
						<div className="row text-start">
							<div className="col-xs-6 col-xxs-12 pt-20">
								<div className="form-group">
									<label htmlFor="name">Name<span className="text-danger ml-10 text-sm">Required</span></label>
									<input type="text" className="form-control" id="name" value={this.state.endpointToEdit.extName} onKeyPress={e => this.validateName(e)} maxLength={20}
										onChange={e => this.setState({ showNameError: false, endpointToEdit: { ...this.state.endpointToEdit, extName: e.target.value } })} />
									{this.state.showNameError &&
										<div className="alert alert-danger mt-5 p-10">
											Please enter a name
										</div>
									}
								</div>
							</div>
							<div className="col-xs-6 col-xxs-12 pt-20">
								<div className="form-group">
									<label htmlFor="ext">Extension number<span className="text-danger ml-10 text-sm">Required</span></label>
									<input type="number" className="form-control" id="ext" value={this.state.endpointToEdit.extNumber} min={100} max={950}
										onChange={e => this.setState({ showNumberError: false, endpointToEdit: { ...this.state.endpointToEdit, extNumber: e.target.value } })} />
									{this.state.showNumberError &&
										<div className="alert alert-danger mt-5 p-10">
											{this.state.errorMsg
											  ? this.state.errorMsg
											  :												'Please enter an extension between 100 and 950'
											}
										</div>
									}
								</div>
							</div>
							{!this.props.basicView &&
								<div className="col-xs-6 col-xxs-12 pt-20">
									<div className="form-group">
										<label htmlFor="cli">
											CLI
											<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={cliPopover}>
												<span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
											</OverlayTrigger>
										</label>
										<Select
											id="cliSelect"
											className="form-control select-menu"
											classNamePrefix="custom"
											onChange={e => this.setState({ showCliError: false, endpointToEdit: { ...this.state.endpointToEdit, cli: e } })}
											options={this.state.availableClisForUser}
											simpleValue
											name="cliSelect"
											value={this.state.endpointToEdit.cli}
											isLoading={!this.state.clis}
											formatGroupLabel={formatGroupLabel}
										/>
										{this.state.showCliError &&
											<div className="alert alert-danger mt-5 p-10">
												Please choose a number
											</div>
										}
									</div>
								</div>
							}

							<div className="col-xs-6 col-xxs-12 pt-20">
								<div className={`form-group ${this.props.validation && this.props.validation.telValidationState && this.props.validation.telValidationState.valid === validationStates.INVALID ? 'has-error' : ''}`}>
									<label htmlFor="name">Mobile <span className="text-danger ml-10 text-sm">Required</span></label>
									<input type="tel" className="form-control" id="Mobile" defaultValue={this.state.endpointToEdit.mobile} onChange={e => this.setState({ endpointToEdit: { ...this.state.endpointToEdit, mobile: e.target.value } })} maxLength={20} onBlur={e => this.props.handleValidateTel({ newValue: e.target.value })} />
									{this.props.validation && this.props.validation.telValidationState && this.props.validation.telValidationState.valid === validationStates.INVALID &&
										<span className="text-400 text-danger text-sm">
											Please enter a valid mobile number
										</span>
									}
								</div>
							</div>
							<div className="col-xs-6 col-xxs-12 pt-20">
								<div className={`form-group ${this.props.validation && this.props.validation.emailValidationState && this.props.validation.emailValidationState.valid === validationStates.INVALID ? 'has-error' : ''}`}>
									<label htmlFor="name">Email Address<span className="text-danger ml-10 text-sm">Required</span></label>
									<input type="email" className="form-control" id="EmailAddress" defaultValue={this.state.endpointToEdit.email} onChange={e => this.setState({ endpointToEdit: { ...this.state.endpointToEdit, email: e.target.value } })} maxLength={120} onBlur={e => this.props.handleValidateEmail({ newValue: e.target.value })} />
									{this.props.validation && this.props.validation.emailValidationState && this.props.validation.emailValidationState.valid === validationStates.INVALID &&
										<span className="text-400 text-danger text-sm">
											Please enter a valid email address
										</span>
									}
								</div>
							</div>
							{!this.props.basicView &&
								<>
									<div className="col-xs-6 col-xxs-12 pt-20">
										<div className="form-group">
											<label htmlFor="name">Note</label>
											<input type="text" className="form-control" id="Note" defaultValue={this.state.endpointToEdit.note} onChange={e => this.setState({ endpointToEdit: { ...this.state.endpointToEdit, note: e.target.value } })} maxLength={1000} />
										</div>
									</div>
									<div className="col-md-12 pt-20">
										<div className="form-group">
											<label htmlFor="cli">
												Available CLI's
												<OverlayTrigger trigger="hover" placement="right" overlay={availableCliPopover}>
													<span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
												</OverlayTrigger>
											</label>
											<Select
												className="select-menu"
												onChange={(e) => this.handleUserAvailableClisChanged(e)}
												options={this.state.clis}
												value={this.state.availableClisForUser}
												isLoading={!this.state.clis}
												formatGroupLabel={formatGroupLabel}
												isMulti={true}
												filterOption={(option, inputValue) => {
												  if (inputValue.length === 0) {
												    return true
												  }

												  const { label } = option

												  return label.replaceAll(' ', '').startsWith(inputValue.replaceAll(' ', ''))
												}}
											/>
											{this.state.showCliError &&
												<div className="alert alert-danger mt-5 p-10">
													Please choose a number
												</div>
											}
										</div>
									</div>
								</>
							}
						</div>
					</Modal.Body>
					<Modal.Footer className="bt-0 pb-30">
						<Button variant="cta" className="btn-xxs-block btn-lg" onClick={() => this.saveEndpoint()} id="EditUserSaveChangesButton" disabled={
							this.state.endpointToEdit.extNumber < 100 ||
							this.state.endpointToEdit.extNumber > 950 ||
							this.state.endpointToEdit === undefined ||
							this.state.endpointToEdit.email === null ||
							(this.state.endpointToEdit && ((this.state.endpointToEdit.extName && this.state.endpointToEdit.extName.trim().length === 0) ||
								this.props.validation && (this.props.validation.telValidationState !== validationStates.VALID || this.props.validation.emailValidationState !== validationStates.VALID)))}>
							Save Changes
						</Button>
						{/* <Button className="float-start btn-xxs-block mt-xxs-10 ml-xxs-0" onClick={() => this.closeEditEndpoint()}>Close</Button> */}
					</Modal.Footer>
				</Modal>
			}

			<Modal size="lg" show={this.state.showGenericError} onHide={() => this.setState({ showGenericError: false, msg: '' })}>
				<Modal.Body className="text-center">
					<div className="error-modal mt-20 mb-20">
						<span className="icon icon-warning2"></span>
					</div>
					<h4 className='text-500'>Oops, something went wrong</h4>
					<p>{this.state.msg}</p>
				</Modal.Body>
				<Modal.Footer className="text-center bt-0 p-0 mt-30">
					<Button className="btn-block brad-0 pt-20 pb-20 w-100" onClick={() => this.setState({ showGenericError: false, msg: '' })}>Close</Button>
				</Modal.Footer>
			</Modal>

			<Modal size="lg" show={this.state.showSuccess} onHide={() => this.setState({ showSuccess: false, msg: '' })}>
				<Modal.Body className="text-center">
					<div className="success-modal mt-20 mb-20">
						<span className="icon icon-checkmark4"></span>
					</div>
					
					<h4 className='text-500'>Good news!</h4>
					<p>{this.state.msg}</p>
				</Modal.Body>
				<Modal.Footer className="text-center bt-0 p-0 mt-30">
					<Button className="btn-block brad-0 pt-20 pb-20 w-100" onClick={() => this.setState({ showSuccess: false, msg: '' })}>Close</Button>
				</Modal.Footer>
			</Modal>

			<Modal className="w-90 products-modal" show={this.state.showEditDevices} onHide={() => this.setState({ showEditDevices: false })}>
				<Modal.Body className="bg-dull">
					<span className="close-modal" onClick={() => this.setState({ showEditDevices: false })}>
						<span>&times;</span>
					</span>

					<PurchasePhones
						displayInline={true}
						handleUpdateHardware={(hardware) => handleHandsetUpdated(hardware)}
						currentBasket={this.props.basket}
						showEditDevices={false}
						handleCloseDevicesModal={() => this.setState({ showEditDevices: false })}
						isSignup={false}
						hideFree={true}
						returnRoute="customer/outbound-management?success=true"
						displayInstalments={true}
						notSlider={true}
						CustomDivClass="w-16 text-center p-10"
					/>

				</Modal.Body>
			</Modal>

			{this.state.showTpsProtectionModal && this.state.endpoints &&
				<EnableTpsProtectionModal
					showModal={this.state.showTpsProtectionModal}
					toggleShowTpsModal={() => this.setState({ showTpsProtectionModal: false })}
					retrieveEndpoints={() => this.retrieveEndpoints()}
					showTpsInfo={true}
					showPricingInfo={true}
				/>}

			<Modal size="lg" className="modal-med question-modal" show={this.state.showDisableTpsProtectionModal} onHide={() => this.setState({ showDisableTpsProtectionModal: false })}>
				<Modal.Body className="text-center">
					<>
						<div className="icon-wrapper mt-20 mb-20">
							<span className="icon icon-question6"></span>
						</div>
						<h4 className="text-500">Are you sure?</h4>
						<p>
							You're about to disable TPS Protection this user.<br />
							Once disabled, this user will no longer be protected.
							{this.state.TPSfinesExplain
							  ? <></>
							  :							<span className="ml-5 underline text-cta" onClick={() => this.setState({ TPSfinesExplain: !this.state.TPSfinesExplain })}>Read more</span>
							}

							{this.state.TPSfinesExplain &&
								<div className="bg-dull pl-30 pr-30 pt-20 pb-20 mt-20 brad-20 text-start b-1">
									<p>
										Failure to comply with the TPS regulations can lead to significant fines for your organisation. The ICO has the authority to issue fines of up to £500,000 for non-compliant organisations, depending on the severity of the breach.
									</p>
									<p className="mb-0">
										In addition, reputational damage from negative publicity can be detrimental to your business. It is, therefore, crucial to have a system in place to ensure compliance with the TPS.
										<span className="ml-5 underline text-cta" onClick={() => this.setState({ TPSfinesExplain: this.state.TPSfinesExplain === false })}>Hide this</span>
									</p>
								</div>
							}

						</p>

						{this.state.showDisableError && this.state.msg &&
							<div className="alert alert-danger mt-20 mb-0">
								<p>{this.state.msg}</p>
							</div>
						}
					</>
				</Modal.Body>

				<Modal.Footer className="text-center bt-0 p-0 mt-30">
					<Button variant="default" className="btn-block brad-0 pt-20 pb-20" onClick={e => this.setState({ showDisableTpsProtectionModal: false })}>Cancel</Button>
					<Button variant="danger" className="btn-block brad-0 pt-20 pb-20" onClick={e => this.toggleTpsProtection(false)} disabled={this.state.disableInProgress}>
						{this.state.showDisableError
						  ? 'Try again'
						  :							'Yes, disable'
						}
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal className="modal-med question-modal" backdrop="static" show={this.state.showEnableTpsProtectionModal} onHide={() => this.setState({ showEnableTpsProtectionModal: false })}>
				<Modal.Body className="text-center">
					<div className="icon-wrapper mt-20 mb-20">
						<span className="icon icon-question6"></span>
					</div>
					<h4 className="text-500">Enable TPS Protection</h4>
					<p>This will enable TPS Protection for the selected user <br className="d-block"/>for an extra £{TpsProtectionPerUserCharge} per month.</p>
				</Modal.Body>
				<Modal.Footer className="text-center bt-0 p-0 mt-10">
					<Button variant="default" className="btn-block brad-0 pt-20 pb-20" onClick={e => this.setState({ showEnableTpsProtectionModal: false })}>Cancel</Button>
					<Button variant="success" className="btn-block brad-0 pt-20 pb-20" onClick={e => this.toggleTpsProtection(true)} disabled={this.state.disableInProgress}>
						Enable
					</Button>
				</Modal.Footer>
			</Modal>
		</>
  }

  handleEditEndpoint (ep) {
    if (ep.mobile && ep.mobile.length > 0) { this.props.handleValidateTel({ newValue: ep.mobile }) }
    if (ep.email && ep.email.length > 0) { this.props.handleValidateEmail({ newValue: ep.email }) }
    this.setState({ editEndpoint: true, endpointToEdit: ep })
  }

  handleResendSipDetails (ep) {
    // Double check email/tel validation
    this.props.handleValidateTel({ newValue: ep.mobile })
    this.props.handleValidateEmail({ newValue: ep.email })

    this.setState({ isInviting: ep.id }, () => {
      setTimeout(() => {
        if ((this.props.validation && this.props.validation.telValidationState !== validationStates.VALID) || (this.props.validation && this.props.validation.emailValidationState !== validationStates.VALID)) {
          this.setState({ showUpdateSipDetails: true, isInviting: 0 })
        } else {
          const url = 'api/Outbound/InviteSipUsers'
          const user = {
            Id: ep.id,
            Email: ep.email,
            Mobile: ep.mobile,
            ExtName: ep.extName,
            IgnoreSent: true
          }
          fetchHelper.postJson(url, [user])
            .then(res => {
              this.setState({ isInviting: 0 })
              this.props.notify({ message: 'Your invitation has been sent.' })
            }).catch(err => console.error(err))
        }
      }, 1000)
    })
  }

  downloadUserCSV () {
    const url = '/api/Outbound/GetOutboundUsersCSV/'
    fetchHelper.getJson(url)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'SwitchboardFREE-Outbound-Users.csv')
        document.body.appendChild(link)
        link.click()
      }).catch(err => console.error(err))
  }

  showAppLogins () {
    const appDetails = document.getElementById('appDetails')
    const softphoneDetails = document.getElementById('softphoneDetails')

    if (appDetails.style.opacity === '0') {
      appDetails.style.cssText = 'opacity:1; height:auto; transition:opacity 1s ease-out;'
      softphoneDetails.style.cssText = 'opacity:0; height:0;margin:0 !important;padding:0 !important;overflow: hidden;'
    } else {
      appDetails.style.cssText = 'opacity:0; height:0;margin:0 !important;padding:0 !important;overflow: hidden;'
      softphoneDetails.style.cssText = 'opacity:0; height:0;margin:0 !important;padding:0 !important;overflow: hidden;'
    }
  }

  showSoftphoneLogins () {
    const softphoneDetails = document.getElementById('softphoneDetails')
    const appDetails = document.getElementById('appDetails')

    if (softphoneDetails.style.opacity === '0') {
      softphoneDetails.style.cssText = 'opacity:1; height:auto; transition:opacity 1s ease-out;'
      appDetails.style.cssText = 'opacity:0; height:0;margin:0 !important;padding:0 !important;overflow: hidden;'
    } else {
      softphoneDetails.style.cssText = 'opacity:0; height:0;margin:0;padding:0;overflow: hidden;'
      appDetails.style.cssText = 'opacity:0; height:0;margin:0;padding:0;overflow: hidden;'
    }
  }

  async saveEndpoint () {
    const ep = this.state.endpointToEdit
    if (!ep.extName) {
      this.setState({ showNameError: true })
    } else if (ep.extNumber === 0 || ep.extNumber < 100 || ep.extNumber > 950) {
      this.setState({ showNumberError: true })
    } else {
      const url = 'api/Outbound/UpdateOutboundEndpoint'
      const availableCliIds = []

      for (let i = 0; i < this.state.availableClisForUser.length; i++) {
        availableCliIds.push(Number(this.state.availableClisForUser[i].value))
      }
      const data = {
        ExtNumber: Number(ep.extNumber),
        ExtName: ep.extName,
        CliId: ep.cli === null ? null : Number(ep.cli.value),
        Id: ep.id,
        mobile: ep.mobile,
        email: ep.email,
        note: ep.note,
        availableCliIds
      }

      fetchHelper.postJson(url, data)
        .then(res => {
          this.closeEditEndpoint()
          if (res.data.error === true) {
            this.setState({ showGenericError: true, msg: res.data.message })
          } else {
            this.setState({ showSuccess: true, msg: res.data.message })
            this.retrieveEndpoints()
          }
        }).catch(err => console.error(err))
    }
  }

  toggleEndpointCallRecording (obj) {
    if (obj.cr || (!obj.cr && !obj.isEnabled)) {
      const url = 'api/Outbound/DisableEnableCallRecordingUser'
      const data = {
        Id: obj.id,
        Enabled: obj.isEnabled
      }

      fetchHelper.postJson(url, data)
        .then(res => {
          if (res.data.error === true) {
            this.setState({ showGenericError: true, msg: res.data.message })
          } else {
            this.setState({ showDisableError: false, msg: '', disableInProgress: false })
            this.retrieveEndpoints()
          }
        }).catch(err => console.error(err))
    }
  }

  toggleEndpointEnabled (obj) {
    const ep = obj.ep

    if (obj.isEnabled === true) {
      const url = 'api/Outbound/DisableEnableOutboundEndpoint'
      const data = {
        Id: obj.id,
        Enabled: obj.isEnabled
      }

      fetchHelper.postJson(url, data)
        .then(res => {
          if (res.data.error === true) {
            this.setState({ showGenericError: true, msg: res.data.message })
          } else {
            this.retrieveEndpoints()
          }
        }).catch(err => console.error(err))
    } else {
      // show warning before disabling
      this.setState({ showDisabledSuccess: false, msg: '', showDisableEndpointConfirm: true, epToDisable: ep })
    }
  }

  toggleTpsProtectionPopup (obj) {
    if (this.props.services.hasTPSProtection === false) {
      this.setState({ showTpsProtectionModal: true, clickedEndpointId: obj.id })
      return
    }

    if (!obj.isEnabled) {
      this.setState({ showDisableTpsProtectionModal: true, clickedEndpointId: obj.id })
    } else {
      this.setState({ showEnableTpsProtectionModal: true, clickedEndpointId: obj.id })
    }
  }

  toggleTpsProtection (toggleState) {
    this.setState({ disableInProgress: true })

    const url = 'api/Outbound/DisableEnableTpsProtectionOnEndpoint'
    const data = {
      Id: this.state.clickedEndpointId,
      Enabled: toggleState
    }

    fetchHelper.postJson(url, data)
      .then(res => {
        if (res.data.error === true) {
          console.log(res)
          this.setState({ showGenericError: true, msg: res.data.message, disableInProgress: false })
        } else {
          this.setState({ showDisableError: false, msg: '', disableInProgress: false, showDisableTpsProtectionModal: false, showEnableTpsProtectionModal: false })
          this.props.notify({ message: `TPS Protection has been ${toggleState ? 'enabled' : 'disabled'}` })
          this.retrieveEndpoints()
        }
      }).catch(err => console.error(err))
  }

  disableEndpoint (ep) {
    this.setState({ disableInProgress: true })
    const url = 'api/Outbound/DisableEnableOutboundEndpoint'
    const data = {
      Id: ep.id,
      Enabled: false
    }

    fetchHelper.postJson(url, data)
      .then(res => {
        if (res.data.error === true) {
          this.setState({ showDisableError: true, msg: res.data.message, disableInProgress: false })
        } else {
          this.setState({ showDisableError: false, showDisabledSuccess: true, msg: '', disableInProgress: false, disableCR: false })
          this.retrieveEndpoints()
        }
      }).catch(err => console.error(err))
  }

  revealSecret (ep) {
    this.setState({
      epToShow: {
        extName: ep.extName,
        name: ep.name.split('.'),
        extNumber: ep.extNumber,
        secret: ep.secret,
        id: ep.id,
        email: ep.email,
        mobile: ep.mobile
      },
      showSecret: true,
      showUpdateSipDetails: false
    })
  }

  closeRevealSecret () {
    this.setState({ epToShow: undefined, showSecret: false, secretToShow: undefined, passwordError: undefined })
  }

  deleteEndpoint (ep) {
    this.setState({ deleteInProgress: true })
    const url = `api/Outbound/DeleteOutboundEndpoint/${ep.id}`
    fetchHelper.postJson(url)
      .then(res => {
        if (res.data.error === true) {
          this.setState({ showDeleteError: true, msg: res.data.message, deleteInProgress: false })
        } else {
          this.setState({
            showDeleteError: false,
            showDeleteSuccess: true,
            msg: '',
            deleteInProgress: false,
            endpoints: this.state.endpoints.filter(e => e.id !== ep.id)
          })
        }
      }).catch(err => console.error(err))
  }

  closeDelete () {
    this.setState({ showDeleteConfirm: false, epToDelete: undefined })
  }

  validateName (e) {
    const keyPressed = e.key.toLowerCase()
    const acceptedValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', ' ']

    if (acceptedValues.includes(keyPressed) === false) {
      e.preventDefault()
    }
  }
};

export default connect(
  state => {
    return {
      services: state.sbfApp.enabledServices,
      productDetails: state.home.productDetails.products,
      callRecordingDialogDisplayed: state.myNumbers.callRecordingDialogDisplayed,
      clientId: state.sbfApp.authentication.clientId,
      validation: {
        telValidationState: sbf.getTelRegexValidationState(state),
        emailValidationState: sbf.getEmailRegexValidationState(state)
      }
    }
  },
  {
    handleValidateTel: sbf.validateTel,
    handleValidateEmail: sbf.validateEmail,
    notify: handleNotificationChange,
    getProductDetails: getProductPrice,
    handleDismissCallRecordingDialog: fromNumbers.handleDismissCallRecordingDialog,
    handleShowCallRecordingDialog: fromNumbers.handleShowCallRecordingDialog,
    handleTPSProtectionEnabledChange: sbf.handleTPSProtectionEnabledChange
  }
)(VoipUsers)
