import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import Helmet from 'react-helmet'

class CaseStudies extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return <>
			<ScrollToTopOnMount />
			<DisplayMainFooter />
			<DisplayMainHeader />

			<Helmet>
				<title>VoIP Case Studies</title>
			</Helmet>

			<header style={{ backgroundImage: "url('/images/backgrounds/plans.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="VirtualPhoneNumbersH1">VoIP Case Studies</h1>
                <h2 className=" subtitle">See how SwitchboardFREE has helped to transform different <br className='d-none d-lg-block'/>businesses by significantly improving their call handling.</h2>
            </header>

			<div className="full-width bg-light pt-100 pb-60">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 mx-auto">
							<div className='row'>
								<div className='col-sm-6 d-flex mb-40'>
									<div className='b-1 box-shadow brad-10 text-center bg-light d-flex flex-column'>
										<div style={{height:"250px", backgroundImage:"url(/images/case-studies/nexus-thumbnail.jpg)",backgroundSize:"cover", borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}></div>
										<h4 className="text-500 mb-30 h5 pl-30 pr-30 flex-grow-1">Nexus Ramps Learns More Ways to Simultaneously Make Their Customers Feel Valued & Allow Staff to Be Flexible & Efficient</h4>
										<div className="text-center pb-30">
											<Link className="btn btn-secondary btn-sm" to={'/case-studies/nexus-ramps/'}>Read case study</Link>
										</div>
									</div>
								</div>
								<div className='col-sm-6 d-flex mb-40'>
									<div className='b-1 box-shadow brad-10 text-center bg-light d-flex flex-column'>
										<div style={{height:"250px", backgroundImage:"url(/images/case-studies/plumber-thumbnail.jpg)",backgroundSize:"cover", borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}></div>
										<h4 className="text-500 mb-30 h5 pl-30 pr-30 flex-grow-1">Sole Trader &amp; Plumber Increases His Customer Base With Additional Numbers &amp; Missed Call Alerts</h4>
										<div className="text-center pb-30">
											<Link className="btn btn-secondary btn-sm" to={'/case-studies/soletrader/'}>Read case study</Link>
										</div>
									</div>
								</div>
								<div className='col-sm-6 d-flex mb-40'>
									<div className='b-1 box-shadow brad-10 text-center bg-light d-flex flex-column'>
										<div style={{height:"250px", backgroundImage:"url(/images/case-studies/garrett-thumbnail.jpg)",backgroundSize:"cover", borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}></div>
										<h4 className="text-500 mb-30 h5 pl-30 pr-30 flex-grow-1">Ian Garrett Building Design Finds Flexibility A Bonus Whilst Maximising Their Communication Efficiency</h4>
										<div className="text-center pb-30">
											<Link className="btn btn-secondary btn-sm" to={'/case-studies/ian-garrett/'}>Read case study</Link>
										</div>
									</div>
								</div>
								<div className='col-sm-6 d-flex mb-40'>
									<div className='b-1 box-shadow brad-10 text-center bg-light d-flex flex-column'>
										<div style={{height:"250px", backgroundImage:"url(/images/case-studies/marketing-thumbnail.jpg)",backgroundSize:"cover", borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}></div>
										<h4 className="text-500 mb-30 h5 pl-30 pr-30 flex-grow-1">Marketing Agency Re-Discovers the Power of Numbers with Metrics Tracking</h4>
										<div className="text-center pb-30">
											<Link className="btn btn-secondary btn-sm" to={'/case-studies/marketing/'}>Read case study</Link>
										</div>
									</div>
								</div>
	
							</div>


						</div>

					</div>

				</div>
			</div>

			<div className="full-width bg-secondary text-center pt-60 pb-60">
				<div className='container'>
					<div className='row'>
						<div className="col-md-12">
							<h3 className='text-500 text-lightest'>Create your account today</h3>
							<p className='text-lightest'>Start making and receiving calls using your virtual number. Benefits from VoIP features like <br className='d-none d-lg-block'/>free music on-hold, multiple departments and many more.</p>
							<Link className="btn btn-sm btn-lightest btn-lg btn-mobile-block mt-20" to={this.props.isLoggedIn ? '/customer/numbers/new' : '/get-started'}>Get Started</Link>
						</div>
					</div>
				</div>
				
			</div>

		</>
	}

	componentDidMount() {

	}
}

export default connect(
	state => {
		return ({
			isLoggedIn: isLoggedIn(state)
		})
	},
	{}
)(CaseStudies)
