import React from 'react'
import { Button, Modal, OverlayTrigger, Popover, Ratio } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fieldNames } from '../../constants/ConfigurationConstants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { checkUserHasCallRecording } from '../../helpers/utils'
import { getConfigValidators } from '../../selectors'
import * as configurationActions from '../../store/Configuration'
import CallRecordingPackages from '../purchase/CallRecordingPackages'
import Validator from '../Validator'

const callRecordingEmailsPopover = (
    <Popover>
        <p>This field is optional, you can leave it blank.</p>
        <p>SwitchboardFREE can optionally send you an email with a link to the call recording.</p>

        <p className="text-600">Multiple emails</p>
        <p>If you want the email to be sent to multiple email addresses simply divide each address with a semi-colon sign, for example: <i>john@gmail.com;john@yahoo.co.uk; john@example.com</i></p>
    </Popover>
)

class CallRecording extends React.Component {
  constructor (props) {
    super(props)

    this.state = { toggleActive: false }
    this.onToggle = this.onToggle.bind(this)
    this.showCallRecordingVideoModal = this.showCallRecordingVideoModal.bind(this)
    this.closeCallRecordingVideoModal = this.closeCallRecordingVideoModal.bind(this)

    this.state = {
      showRecordingUpgrade: false,
      showOneNumber: false,
      showCallRecordingVideoModal: false
    }
  }

  closeCallRecordingVideoModal () {
    this.setState({ showCallRecordingVideoModal: false })
  }

  showCallRecordingVideoModal () {
    this.setState({ showCallRecordingVideoModal: true })
  }

  onToggle () {
    this.setState({ toggleActive: !this.state.toggleActive })
  }

  render () {
    return <>
            <div className="row bg-light p-30 zoom">
                <div className="col-md-5 pb-sm-10 number-description">
                    <h5 className="text-secondary text-500 text-lg">
                        Call Recording
                    </h5>
                    <p className="text-400">
                    Record calls for accurate record keeping, call monitoring and training. <span onClick={this.showCallRecordingVideoModal} className='text-dark underline'>Watch our video</span> about call recording. 
                    </p>
                    {checkUserHasCallRecording(this.props.userServices) &&
                        <p className="text-400">
                            <a href="/customer/calls" className="underline" id="SeeAllYourRecordingsLink">See all your recordings</a> or <a href="/customer/profile/call-recording-settings" className="underline" id="CallRecordingSeeAdditionalSettingsLink">see additional settings</a>.
                        </p>
                    }

                  <Modal className='w-900px' show={this.state.showCallRecordingVideoModal} onHide={this.closeCallRecordingVideoModal}>
                    <Modal.Header className='b-0' closeButton></Modal.Header>
                    <Modal.Body className="text-center">
                        <Ratio aspectRatio="16x9" >
                          <iframe width="560" height="315"  src="https://www.youtube.com/embed/9G36njgm3hE?si=9USEaRr_JUxuyC5v" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </Ratio>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="default" onClick={this.closeCallRecordingVideoModal}>Close</Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="col-md-7 mt-10">
                    <div className="row">
                        <div className="col-md-4 text-sm-center pb-sm-30">
                            <p>Turn ON/OFF:</p>
                            <Toggle
                                className="mt-0"
                                onClick={e => this.handleChangeCallRecording(e)}
                                on={<span>ON</span>}
                                off={<span>OFF</span>}
                                size="sm"
                                offstyle="default"
                                onstyle="success"
                                width="null"
                                height="null"
                                active={this.props.isCallRecEnabled}
                                id="CallRecordingToggle"
                            />
                        </div>
                        <div className="col-md-8">
                            <p>If you would like an email notification when a call recording captured, please add your email address(es) below
                                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={callRecordingEmailsPopover}>
                                    <span className="icon icon-info2 ml-5 text-dark-light"></span>
                                </OverlayTrigger></p>

                            <Validator validation={this.props.validation[fieldNames.CALL_RECORD_EMAIL]} />

                            <input type='text' placeholder="Optional" className="form-control" id="CallRecordingInput"
                                value={this.props.callRecordingEmailAddress}
                                onChange={e => this.props.handleCallRecordingEmailChange(e.target.value)} maxLength={120}
                                onBlur={e => this.props.validateEmailSyntax({ fieldName: fieldNames.CALL_RECORD_EMAIL, value: e.target.value })} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="w-900px" show={this.state.showOneNumber} onHide={e => this.setState({ showOneNumber: false })}>
                {/* <CallRecordingOneNumber /> */}
                <Modal.Footer>
                    <div className="col-md-12">
                        <Button variant="default" size="xs" onClick={e => this.setState({ showOneNumber: false })}>Cancel</Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className="w-900px" show={this.state.showRecordingUpgrade} onHide={() => this.setState({ showRecordingUpgrade: false })}>
                <Modal.Body className="p-0">
                    <>
                        {/* <div className="col-md-12 text-center pt-10 pb-30 mb-30">
                                    <h1>Start recording your calls</h1>
                                    <h4>Wondering why you should record your calls? Here are some reasons...</h4>
                                </div>
                                <div className="container pl-30 pr-30">
                                    <CallRecordingBenefits/>
                                </div> */}
                        <CallRecordingPackages userServices={this.props.userServices} />
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" size="xs" onClick={() => this.setState({ showRecordingUpgrade: false })}>Close</Button>
                </Modal.Footer>
            </Modal>

        </>
  }

  handleChangeCallRecording (e) {
    const hasCallRecording = checkUserHasCallRecording(this.props.userServices)

    if (e === true) {
      if (hasCallRecording) {
        // Check if they need to upgrade
        fetchHelper.getJson(`api/CallRecording/GetCallRecordingCount/${this.props.queueNo}`).then(res => {
          const count = res.data
          if (count === null || count === 0) {
            this.setState({ showRecordingUpgrade: false })
            this.props.handleCallRecordingEnabledChange(e)
          } else if (count >= 1 && (this.props.userServices.hasCallRecordingAll || this.props.userServices.hasUnlimitedCallRecording)) {
            this.setState({ showRecordingUpgrade: false })
            this.props.handleCallRecordingEnabledChange(e)
          } else {
            this.setState({ showRecordingUpgrade: true })
          }
        })
      } else {
        this.setState({ showRecordingUpgrade: true })
      }
    } else {
      this.setState({ showRecordingUpgrade: false })
      this.props.handleCallRecordingEnabledChange(e)
    }
  }
};

export default connect(
  state => ({
    isCallRecEnabled: state.configuration.configuration.isCallRecEnabled,
    callRecordingEmailAddress: state.configuration.configuration.callRecordingEmailAddress ? state.configuration.configuration.callRecordingEmailAddress : '',
    validation: getConfigValidators(state)
  }),
  dispatch => bindActionCreators(configurationActions, dispatch)
)(CallRecording)
