import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ImageRightFullWidth from '../../components/ImageRightFullWidth'
import LeadGenForm from '../../components/LeadGenForm'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

class NumberPorting extends React.Component {
  constructor (props) {
    super(props)
  }

  scrollTo () {
    scroller.scrollTo('start', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>Choose a virtual number and get unlimited incoming and outgoing calls for only 99p!</title>
                <meta name="description" content="We have virtual phone numbers for every UK town and city. Pick up one today and get unlimited incoming and outgoing calls for only 99p!" />

                <style type="text/css">{`


                    `}</style>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/profile-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1 id="KeepMyNumberH1">Keep your existing phone number <br className="d-none d-sm-block"/>with SwitchboardFREE</h1>
            </header>
            <div className="container-styled no-shadow mb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-20 text-center mb-60">
                            <h3 className="text-500">
                                Transfer your number to us &amp; get &pound;50 call credit
                            </h3>
                            <h5 className="text-400 mt-40">
                                SwitchboardFREE is a cloud-based telephony service that allows you to divert your incoming calls <br className="d-none d-md-block"/>to a phone number of your choice, including your existing phone number.
                            </h5>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-6 mt-15" id="start">
                            <LeadGenForm
                                FormHeadingText="Transfer your number"
                                ButtonText="Check eligibility now"
                                hideLabels={true}
                                PortingNumber={true}
                                EmailAddress={true}
                                CompanyName={true}
                            />
                        </div>
                        <div className="col-md-6 text-start pl-60 pl-sm-15">
                            <h4 className="text-500 pb-20">Bring your number with you</h4>
                            <p>
                                If you’re attached to your existing number then just bring it with you.
                            </p>
                            <p className="pt-10">
                                We’re able to transfer or “port” the majority of UK phone numbers over to our platform.
                                We offer a completely free “porting in” service and your number can be transferred in as little as 7 days.
                            </p>
                            <p className="pt-10">
                                Check to see if your number can be ported here or call our porting team today on <a href="tel:0203 916 5698" className="text-cta underline">0203 916 5698</a>.
                            </p>
{/*
                            <h4 className="text-500 pt-30 pb-20">What is the porting process?</h4>
                            <p>
                                The Porting process is simple and we have a dedicated porting team on hand to walk you through <span className="text-500">just three easy steps:</span>
                            </p>
                             <div className="row text-center mt-30">
                                <div className="col-sm-4">
                                    <span className="icon-block-cta icon-small no-shadow">
                                        <span className="icon number text-dark">1</span>
                                    </span>
                                    <p className="pt-30 pb-20">
                                        Check eligibility &amp; ensure there are no outstanding bills with your existing supplier.
                                    </p>
                                </div>
                                <div className="col-sm-4">
                                    <span className="icon-block-cta text-dark icon-small no-shadow">
                                        <span className="icon number">2</span>
                                    </span>
                                    <p className="pt-30">
                                        <a href="/files/porting.docx" className="text-cta underline">Download</a> the porting authorisation form and print this out on company letterheaded paper.
                                    </p>
                                </div>
                                <div className="col-sm-4">
                                    <span className="icon-block-cta text-dark icon-small no-shadow">
                                        <span className="icon number">3</span>
                                    </span>
                                    <p className="pt-30">
                                        Fill in the form and return this to us either via email (scanned) or via post.
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>

                <div className="container pb-60">

                </div>
            </div>

            <ImageRightFullWidth
                TextCopy={
                    <>
                        <h2 className="text-500 text-lightest pb-30">
                            What happens next?
                        </h2>
                         <p className="text-400">
                            When we receive your completed form, we'll then contact your current provider and ensure your number is moved over to our platform.
                        </p>
                        <p className="text-400 pt-20">
                            You will receive notification from both ourselves and your current provider confirming your porting request as well as the date that the port will take place.
                        </p>
                        <p className="text-400 pt-20">
                            Once on our platform, we'll help you set up your number including any redirects, and test the line for you.
                        </p>
                    </>
                    }
                    ImgURL="/images/beard-guy.jpg"
                    RightImageClass="d-none d-sm-block"
            />

        <div className="full-width bg-light" >
            <div className="container">
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <div className="row mb-30">
                            <div className="col-sm-6 pt-30">
                                <img src="/images/could-phone.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                            <div className="col-sm-6 pt-30 pt-xs-20">
                                <h4 className="text-500 mb-30 mt-0">Porting explained</h4>
                                <p className="text-400 pt-10">
                                    Porting or number "porting" refers to the ability to transfer an existing fixed-line, VoIP line, or Virtual number from one service provider or "carrier" to another.
                                    Porting was introduced to promote competition in the heavily monopolised telecommunications industry and was popularised with the advent of mobile communications.
                                </p>
                                <p className="text-400 pt-10">
                                    Porting in the UK usually involves porting a number from a carrier to BT and from BT to the new carrier. This process requires a form to be printed on company headed paper, this is then scanned and sent to BT to start the process.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-30">
                            <div className="col-xs-12 pt-30 d-block d-sm-none pb-20">
                                <img src="/images/thumbs-up.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                            <div className="col-sm-6 pt-30">
                                <h4 className="text-500 mb-30 mt-0 pt-xs-20">The benefits of porting</h4>
                                <p className="text-400 pt-10">
                                    If you&apos;re a business, being able to port your landline number has many benefits.
                                </p>
                                <p className="text-400 pt-10">
                                    If you've spent a large sum of money adverting your business number, or, your number is used on the side of vehicles or buildings, it can be inconvenient and costly to change your signage if your current VoIP or landline provider's service doesn't suit your needs or becomes too expensive.
                                </p>
                                <p className="text-400 pt-10">
                                    Also, If your business has been established for several years, your telephone number would have been saved by potentially hundreds or thousands of customers, so changing a number would have been a mammoth task until porting was introduced.
                                </p>
                            </div>
                            <div className="col-sm-6 pt-30 d-none d-sm-block">
                                <img src="/images/thumbs-up.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                        </div>
                        <div className="row mb-30">
                            <div className="col-sm-6 pt-30 pt-xs-0">
                                <img src="/images/transfer.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                            <div className="col-sm-6 pt-30 pt-xs-0">
                                <h4 className="text-500 mb-30 mt-0 pt-xs-20">Transferring your existing number to VoIP</h4>
                                <p className="text-400 pt-10">
                                    You can also port an existing fixed landline number to a new VoIP provider. This gives you additional benefits and features to a once simple phone line.
                                </p>
                                <p className="text-400 pt-10">
                                    Switching or porting an existing landline number to VoIP, gives you amazing features and services that often come bundled with a VoIP service, and if you've only ever had a humble landline phone, then a new VOIP provider can really help to give your business a more professional perception whilst minimising your missed calls.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width bg-secondary" >
            <div className="container">
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <div className="row mb-30">
                            <div className="col-sm-6 pt-30">
                                <h4 className="text-500 mb-20 mt-0 pt-xs-40 text-lightest">Can VoIP keep my number?</h4>
                                <p className="text-400 pt-10 text-lightest">
                                    Yes, VoIP service providers can transfer landline numbers in from other VoIP service providers or from standard landline providers. The transfer process is the same and still get to maintain your number.
                                </p>
                                <p className="text-400 pt-10 text-lightest">
                                    In fact, BT are actually leading the change to shut down their traditional telephone network (ISDN) in the UK with the intention to move us all in the UK over to the "cloud" or Internet-based telephony (VoIP).
                                </p>
                                <p className="text-400 pt-10 text-lightest">
                                    This process has already started and by the end of 2020, there were no new ISDN orders processed and by 2025, the old network will be finally deactivated, meaning all new lines will make calls using an internet connection only.
                                </p>
                            </div>
                            <div className="col-sm-6 pt-30">
                                <img src="/images/business.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                        </div>
                        <div className="row mb-30">
                            <div className="col-sm-6 pt-30">
                                <img src="/images/fibre.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                            <div className="col-sm-6 pt-30">
                                <p className="text-400 pt-10 text-lightest">
                                    This is quite a significant change for the phone industry and our phone systems but this will move us from our old infrastructure to a fibre-based future is certainly a step in the right direction.
                                </p>
                                <p className="text-400 pt-10 text-lightest">
                                    If you&apos;re not currently using a VoIP line for your business, you really need to think about making the switch.
                                </p>
                                <p className="text-400 pt-10 text-lightest">
                                    VoIP has made some big advances since these early days and the evolution of VoIP has not only surpassed its predecessor, It’s beaten it hands down.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width bg-light" >
            <div className="container">
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <div className="row mb-30">
                            <div className="col-xs-12 pt-30 d-block d-sm-none">
                                <img src="/images/money.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                            <div className="col-sm-6 pt-30">
                                <h4 className="text-500 mb-20 mt-0 pt-xs-40 text-dark">Does it cost anything to port or transfer a number?</h4>
                                <p className="text-400 pt-10 text-dark">
                                    Not to port a number into SwitchboardFREE.
                                </p>
                                <p className="text-400 pt-10 text-dark">
                                    Some carriers will charge an admin fee but we feel this is part of our service to you. Porting your number to us is totally free and we even give you £50 call credit on top.
                                </p>
                                <p className="text-400 pt-10 text-dark">
                                    Be aware that your existing carrier may charge you an admin fee to port away from their platform.
                                </p>
                            </div>
                            <div className="col-sm-6 pt-30 d-none d-sm-block">
                                <img src="/images/money.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                        </div>
                        <div className="row mb-30">
                            <div className="col-sm-6 pt-30">
                                <img src="/images/clock.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                            <div className="col-sm-6 pt-30">
                                <h4 className="text-500 mb-20 mt-0 pt-xs-40 text-dark">
                                    How long does porting typically take?
                                </h4>
                                <p className="text-400 pt-10 text-dark">
                                    Transferring your existing landline phone number to another service provider typically takes anywhere from 7 days to 21 days depending on who your current provider is and on the efficiency of your new provider.
                                </p>
                                <p className="text-400 pt-10 text-dark">
                                    As a consumer, you can affect the speed of the transfer by ensuring your current bills are all up to date and paid, and getting a finished form on company-headed paper over to your new phone service provider as quickly as you can.
                                </p>
                                <p className="text-400 pt-10 text-dark">
                                    Sometimes there are unforeseen delays during the number transfer and you should ring the customer support team phone number of your existing phone provider to find out the current situation and to make sure they've received all relevant documentation.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-30">
                            <div className="col-xs-12 pt-30 d-block d-sm-none pb-30">
                                <img src="/images/landline.jpg" style={{ maxWidth: '100%' }}/>
                            </div>
                            <div className="col-sm-6 pt-30">
                                <h4 className="text-500 mb-20 mt-0 pt-xs-40 text-dark">Do I still need a phone line?</h4>
                                <p className="text-400 pt-10 text-dark">
                                    No, but you may have an existing phone line for your current broadband service but you don&apos;t need a physical phone line if you&apos;re transferring your number to a VoIP provider.
                                </p>
                            </div>
                            <div className="col-sm-6 pt-30 d-none d-sm-block">
                                <img src="/images/landline.jpg" style={{ maxWidth: '100%', maxHeight: '300px' }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div className="full-width pt-60 pb-60 bg-dark pl-30 pr-30" >
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h4 className="m-0 p-0 text-lightest text-400" style={{ lineHeight: '1.4' }}>
                            Check to see if your number can be ported <a onClick={this.scrollTo} className="text-500 text-cta underline">here</a> or call our porting team today on <a href="tel:0203 916 5698" className="text-500 text-lightest">0203 916 5698</a>.
                        </h4>
                    </div>
                </div>
            </div>

        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(NumberPorting)
