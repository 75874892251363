import React from 'react'
import Accordion from 'react-bootstrap/Accordion'

const VoiPTerms = (props) => {
  return (
        <Accordion accordion>
            <Accordion.Item eventKey={1} className="panel-unstyled package-terms">
                <Accordion.Header>
                        Economy Plan
                </Accordion.Header>
                <Accordion.Body collapsible className="pl-20 ml-0">
                    <p><strong>Inbound calls</strong></p>
                    <p>The Fair Usage Policy for inbound calls is 3000 minutes per user, per month and is based on calls being delivered to our Softphone, our Phone App or VoIP desk phone only. Calls can be delivered to a UK landline or a UK mobile which are billed separately at 3p per minute and 6p per minute respectively. We provide you with a £5 free credit should you wish to do this. </p>
                    <p><strong>Outbound calls</strong></p>
                    <p>Outbound calls to a UK landline or a UK mobile is set at 30 minutes to a UK landline per month and 30 minutes per month to a UK mobile on the networks: (Three, Vodafone, EE and  o2). If you do make calls outside this allowance, calls will be charged at 3p per minute to a landline and 6p per minute to a UK Mobile. If you regularly make more calls than this, you can purchase a ‘bolt-on’, giving you additional value. </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={2} className="panel-unstyled package-terms">
                <Accordion.Header>
                        Standard Plan
                </Accordion.Header>
                <Accordion.Body collapsible className="pl-20 ml-0">
                    <p><strong>Inbound calls</strong></p>
                    <p>The Fair Usage Policy for inbound calls is 3000 minutes per user, per month and is based on calls being delivered to our Softphone, our Phone App or VoIP desk phone only. Calls can be delivered to a UK landline or a UK mobile which are billed separately at 3p per minute and 6p per minute respectively. We provide you with a £5 free credit should you wish to do this. </p>
                    <p>Calls can be delivered to a UK landline or a UK mobile phone which is billed separately at 3p per minute and 6p per minute respectively. We also provide you with a £5 free credit should you wish to do this. </p>
                    <p><strong>Outbound calls</strong></p>
                    <p>Outbound calls to a UK landline or a UK mobile is set at 300 minutes to a UK landline per month and 300 minutes per month to a UK mobile on the networks: (Three, Vodafone, EE and  o2). If you do make calls outside this allowance, calls will be charged at 3p per minute to a landline and 6p per minute to a UK Mobile. If you regularly make more calls than this, you can purchase a ‘bolt-on’, giving you additional value. </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={3} className="panel-unstyled package-terms">
                <Accordion.Header>

                        Unlimited Plan

                </Accordion.Header>
                <Accordion.Body collapsible className="pl-20 ml-0">
                    <p><strong>Inbound calls</strong></p>
                    <p>The Fair Usage Policy for inbound calls is 3000 minutes per user, per month and is based on calls being delivered to our Softphone, our Phone App or VoIP desk phone only. Calls can be delivered to a UK landline or a UK mobile which are billed separately at 3p per minute and 6p per minute respectively. We provide you with a £5 free credit should you wish to do this. </p>
                    <p>Calls can be delivered to a UK landline or a UK mobile which are billed separately at 3p per minute and 6p per minute respectively. We also provide you with a £5 free credit should you wish to do this. </p>
                    <p>You are able to move your outbound credit for both mobile and landline over to inbound mobile or landline redirects if you wish. This can be done by calling our customer service team. </p>
                    <p><strong>Outbound calls</strong></p>
                    <p>Outbound calls to a UK landline or a UK mobile is based on average user and is currently set at 1,200 minutes to a UK landline per month and 1,200 minutes per month to a UK mobile on the networks: (Three, Vodafone, EE and  o2). </p>
                    <p>These allowances are rarely used, however, if you do make calls outside this allowance, these will be charged at 3p per minute to a landline and 6p per minute to a UK Mobile. If you regularly make more calls than this, you can purchase a ‘bolt-on’, giving you additional value. </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={4} className="panel-unstyled package-terms">
                <Accordion.Header>

                    Unlimited 084 Plan

                </Accordion.Header>
                <Accordion.Body collapsible className="pl-20 ml-0">
                    <p><strong>Inbound calls</strong></p>
                    <p>Unlimited inbound calls available to SIP, UK landline redirects or UK mobile redirects, subject to a fair usage limite of 3000 minutes per user, per month.</p>
                    <p><strong>Outbound calls</strong></p>
                    <p>Outbound calls to a UK landline or UK mobiles is based on an average user and is currently set at 1500 minutes to a UK landline per month and 500 minutes per month to a UK mobile on the networks: (Three, Vodafone, EE and  o2). </p>
                    <p>These allowances are rarely used, however, if you do make calls outside this allowance, these will be charged at 3p per minute to a landline and 6p per minute to a UK Mobile. If you regularly make more calls than this, you can purchase a ‘bolt-on’, giving you additional value. </p>

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={5} className="panel-unstyled package-terms">
                <Accordion.Header>

                        Freephone Plan

                </Accordion.Header>
                <Accordion.Body collapsible className="pl-20 ml-0">
                    <p><strong>Inbound calls</strong></p>
                    <p>Inbound SIP calls are set at 100 minutes per user, per month and is based on calls being delivered to our Softphone, our Phone App or VoIP desk phone only. If you receive calls outside this allowance, calls will be charged at 6p per minute from a UK landline and 9p per minute from a UK Mobile. </p>
                    <p>If you regularly make more calls than this, you can purchase a ‘bolt-on’, giving you additional value. </p>
                    <p><strong>Outbound calls</strong></p>
                    <p>Outbound calls to a UK landline or a UK mobile is set at 400 minutes to a UK landline per month and 200 minutes per month to a UK mobile on the networks: (Three, Vodafone, EE and  o2). If you do make calls outside this allowance, calls will be charged at 6p per minute to a landline and 9p per minute to a UK Mobile. </p>
                </Accordion.Body>
            </Accordion.Item>
            
        </Accordion>
  )
}

export default VoiPTerms
