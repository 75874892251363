import update from 'immutability-helper'
import React from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import TermsAndConditions from '../../Text/TermsAndConditions'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import LoadingNotification from '../../components/LoadingNotification'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import { ProductIds, productBillingOption } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat, getQueryStringFromProps, simplePhoneNumberFormat } from '../../helpers/utils'
import * as fromHome from '../../store/Home'
import { handleCallBlockingEnabledChange, handleNotificationChange, handleShowBillingWarning, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'

const _ = require('lodash')

class CallBlocking extends React.Component {
  state = { showTsAndCs: false, toggleActive: false, isPurchaseInProgress: false, validContactEntered: false }

  constructor (props) {
    super(props)
  }
  // let tabs = document.getElementsByClassName('nav-tabs');
  // tabs[0].classList.add("nav-tabs-centered");

  render () {
    const yearlyPrice = this.props.yearlyPrice
    const monthlyPrice = this.props.monthlyPrice

    if (!String.prototype.includes) {
      String.prototype.includes = function (search, start) {
        'use strict'
        if (typeof start !== 'number') {
          start = 0
        }

        if (start + search.length > this.length) {
          return false
        } else {
          return this.indexOf(search, start) !== -1
        }
      }
    }

    function handleChangeBarrInternational (enabledBarr, number, context) {
      // let enabledBarr = event.target.checked;

      const indexOfNumber = _.findIndex(context.state.numbers, thisNumber => thisNumber.qlid === number.qlid)

      const numbers = context.state.numbers
      const updatedNumbers = update(numbers, { [indexOfNumber]: { $merge: { barrInternational: enabledBarr } } })

      context.setState({ numbers: updatedNumbers })

      const url = 'api/CallFiltering/SetInternationalCallBarr'

      fetchHelper.postJson(url, {
        qlid: number.qlid, enableBarr: enabledBarr
      })
        .then(data => {
          return console.log(data)
        })
        .catch(error => {
          return console.error(error)
        })
    }

    function handleChangeBarrWitheld (enabledBarr, number, context) {
      // let enabledBarr = event.target.checked;

      const indexOfNumber = _.findIndex(context.state.numbers, thisNumber => thisNumber.qlid === number.qlid)

      const numbers = context.state.numbers
      const updatedNumbers = update(numbers, { [indexOfNumber]: { $merge: { barrWithheld: enabledBarr } } })

      context.setState({ numbers: updatedNumbers })

      const url = 'api/CallFiltering/SetWitheldCallBarr'

      fetchHelper.postJson(url, {
        qlid: number.qlid, enableBarr: enabledBarr
      })
        .then(data => {
          return console.log(data)
        })
        .catch(error => {
          return console.error(error)
        })
    }

    function handleChangeAccountWideBarrWithheld (enabledBarr, context) {
      // let enabledBarr = event.target.checked;

      context.setState({ blockWithheldCalls: enabledBarr })

      const url = 'api/CallFiltering/SetAccountWideWitheldCallBlock/'

      fetchHelper.postJson(url, { enableBarr: enabledBarr })
        .then(data => {
          return console.log(data)
        })
        .catch(error => {
          return console.error(error)
        })
    }

    function handleChangeAccountWideBarrInternational (enabledBarr, context) {
      // let enabledBarr = event.target.checked;

      context.setState({ blockInternationalCalls: enabledBarr })

      const url = 'api/CallFiltering/SetAccountWideInternationalCallBlock/'

      fetchHelper.postJson(url, { enableBarr: enabledBarr })
        .then(data => {
          return console.log(data)
        })
        .catch(error => {
          return console.error(error)
        })
    }
    function myFunction () {
      const tabs = document.getElementsByClassName('nav-tabs')
      tabs.classList.add('nav-tabs-centered')
    }

    return <><ScrollToTopOnMount /><DisplayLoggedInFooter /> <DisplayMainHeader />
            <Helmet>
                <title>Nuisance Call Blocker</title>
                <style type="text/css">{`
                    .nav-tabs {
                        text-align: center;
                    }
                    .nav-tabs li {
                        float: none !important;
                        display: inline-block !important;
                    }
                    `}
                </style>
            </Helmet>
            <header>
                <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/call-filtering.jpg')" }}>
                <h1 id="NuisanceCallBlockerH1">Nuisance Call Blocker</h1>
                    <h2 className="subtitle">Block individual numbers, as well as all international <br className="d-none d-lg-block"/>calls and withheld calls, too!</h2>
                </div>
            </header>
            {/* <div className="container-styled"> */}
                {this.props.isEnabled ? <>

                    <div className="full-width bg-dull">
                        <div className="container">

                            <div className="row">

                                    <Tabs defaultActiveKey={1} className="tabs-container justify-content-center">
                                        <Tab className="call-blocking" eventKey={1} title="International & Withheld">
                                            <div className="row">
                                                <div className="col-xs-12 text-center mt-20 mb-30">
                                                    <h4 className="text-500 pb-20">Block withheld and international calls across all <br className="d-none d-lg-block"/>of your numbers in one go</h4>
                                                    <p>Please note, blocking withheld and international calls across your entire <br className="d-none d-lg-block"/>account will override any individual settings below.</p>
                                                </div>
                                                <div className="w-600px center-block clearfix pl-xs-0 pr-xs-0 mt-10">
                                                    <div className="row">
                                                        <div className="col-xs-6 col-xxs-12 pl-xxs-20 pr-xxs-20 text-center">
                                                            <div className="well well-light pb-30 mb-xs-20">
                                                                <p className="pb-10 pt-10">Withheld Callers</p>
                                                                <Toggle
                                                                    id="AccountWideWithheldCallersToggle"
                                                                    on={<span>BLOCK</span>}
                                                                    off={<span>ALLOW</span>}
                                                                    size="sm"
                                                                    offstyle="success"
                                                                    onstyle="danger"
                                                                    width="null"
                                                                    height="null"
                                                                    active={this.state.blockWithheldCalls}
                                                                    onClick={event => handleChangeAccountWideBarrWithheld(event, this)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-xxs-12 pl-xxs-20 pr-xxs-20 text-center">
                                                            <div className="well well-light pb-30">
                                                                <p className="pb-10 pt-10">International Callers</p>
                                                                <Toggle
                                                                    id="AccountWideInternationalCallersToggle"
                                                                    on={<span>BLOCK</span>}
                                                                    off={<span>ALLOW</span>}
                                                                    size="sm"
                                                                    offstyle="success"
                                                                    onstyle="danger"
                                                                    width="null"
                                                                    height="null"
                                                                    active={this.state.blockInternationalCalls}
                                                                    onClick={event => handleChangeAccountWideBarrInternational(event, this)}
                                                                />
                                                                {/* <input type='checkbox' checked={this.state.blockInternationalCalls} onChange={event => handleChangeAccountWideBarrInternational(event, this)}/> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {!this.state.isLoading || this.state.isLoading === undefined &&
                                                <LoadingNotification className="mt-30" message={'Loading...'} isLoading={this.state.isLoading} />
                                            }
                                            <div className="row">
                                                <div className="col-md-12 text-center mt-60">
                                                    <h4 className="text-500">Block individual SwithboardFREE number</h4>
                                                    <p>Block withheld and international calls individually.</p>
                                                </div>
                                            </div>
                                            <div className="row w-600px center-block text-center">
                                                <div className="w-600px clearfix center-block pt-30 d-none d-xs-block">
                                                    <div className="row">
                                                        <div className="col-xs-6 text-start">
                                                            <p className="text-xs text-500">SwitchboardFREE number</p>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <p className="text-xs text-500">Withheld  callers</p>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <p className="text-xs text-500">International callers</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.numbers && this.state.numbers.map((number, i) => {
                                                  return <div className="w-600px clearfix center-block bg-light b-1 brad-10 pb-xxs-20 pt-xxs-30 pt-20 pb-10 mb-20 box-shadow zoom">
                                                        <div className="row">
                                                            <div className="col-xs-6 col-xxs-12 text-center text-sm-start pl-25">
                                                                <h5 className="mt-0 mb-5 mb-sm-0 number text-xxs-lg text-secondary text-500">
                                                                    {simplePhoneNumberFormat(number.queue)}
                                                                </h5>
                                                                <p>
                                                                    <small>{number.description}</small>
                                                                </p>
                                                            </div>
                                                            <div className="col-xs-3 col-xxs-12 text-center text-sm-start pt-xxs-30 mt-10">
                                                                <p className="d-block d-xs-none">Block withheld callers</p>
                                                                <Toggle
                                                                    id={`NumberSpecificWithheldCallersToggle${i}`}
                                                                    on={<span>BLOCK</span>}
                                                                    off={<span>ALLOW</span>}
                                                                    size="sm"
                                                                    offstyle="success"
                                                                    onstyle="danger"
                                                                    width="null"
                                                                    height="null"
                                                                    active={number.barrWithheld}
                                                                    disabled={this.state.blockWithheldCalls}
                                                                    onClick={event => handleChangeBarrWitheld(event, number, this)}
                                                                />
                                                            </div>
                                                            <div className="col-xs-3 col-xxs-12 text-center text-sm-start mb-xxs-30 pt-xxs-30 mt-10">
                                                                <p className="d-block d-xs-none">Block international callers</p>

                                                                <Toggle
                                                                    id={`NumberSpecificInternationalCallersToggle${i}`}
                                                                    on={<span>BLOCK</span>}
                                                                    off={<span>ALLOW</span>}
                                                                    size="sm"
                                                                    offstyle="success"
                                                                    onstyle="danger"
                                                                    width="null"
                                                                    height="null"
                                                                    active={number.barrInternational}
                                                                    disabled={this.state.blockInternationalCalls}
                                                                    onClick={event => handleChangeBarrInternational(event, number, this)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </Tab>
                                        <Tab eventKey={2} title="Block Individual Numbers">
                                            <h4 id="YourBlockedCallerListHeader" className="text-center text-500 mt-50">Your blocked caller list</h4>
                                            <p className="text-center mb-30">
                                                When blocking an individual number, you'll get a choice of what prompt that caller hears when <br className="d-none d-lg-block"/>calling your number(s). Withheld or international calls will simply hear your standard out-of-hours <br className="d-none d-lg-block"/>message and the option to leave a voicemail (if turned on).
                                            </p>
                                            <div className="">
                                                <div className="w-600px center-block clearfix pl-15 pr-15">
                                                {this.state.blockedContacts && this.state.blockedContacts.length > 9 &&
                                                    <div className="row">
                                                        <div className="col-md-12 pb-20 pt-30 pl-0 pr-0 text-center">
                                                            <Button id="BlockANumberButton" className="mb-5 mr-10 mr-xxs-0 btn-xxs-block" variant="cta" size="sm" onClick={e => this.setState({ new: { barr: true } })}>
                                                                Block a number
                                                            </Button>
                                                            <Button id="ImportNumbersButton" className="mb-5 btn-xxs-block" variant="primary" size="sm" onClick={e => this.setState({ import: {} })}>
                                                                Import numbers
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    }

                                                    {this.state.blockedContacts && this.state.blockedContacts.length === 0 &&
                                                        <div className="alert alert-warning text-400 text-center col-md-12 pb-30">
                                                            <h6 className="pb-20 text-500">You don&apos;t currently have any blocked callers. </h6>
                                                            <Button id="BlockANumberButton" className="mb-5 mr-10 mr-xxs-0 btn-xxs-block" variant="cta" size="sm" onClick={e => this.setState({ new: { barr: true } })}>
                                                                Block a number
                                                            </Button>
                                                            <Button id="ImportNumbersButton" className="mb-5 btn-xxs-block" variant="primary" size="sm" onClick={e => this.setState({ import: {} })}>
                                                                Import numbers
                                                            </Button>
                                                        </div>
                                                    }

                                                    <div className="row">
                                                        {this.state.blockedContacts && this.state.blockedContacts.length > 0 && this.state.blockedContacts.map((contact) => {
                                                          return <><div key={contact.qcId} className={`w-600px center-block ${contact.vip ? 'vip-contact' : ''}`}>
                                                                <div className="w-600px bg-light b-1 brad-10 p-25 mb-20 zoom box-shadow">
                                                                    <div className="row">
                                                                        <div className="col-xs-6 col-xxs-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-12 text-center text-xs-start">
                                                                                    <div className="block">
                                                                                        <span className="text-md text-500 text-xxs-lg text-secondary">
                                                                                            {contact.description}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="block mb-xxs-20 text-400">
                                                                                        {contact.number}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xs-6 col-xxs-12 text-xs-right text-center pt-10">
                                                                            {<Button
                                                                                id={this.isContactBeingEdited(contact) ? 'CancelButton' : 'EditButton'}
                                                                                variant={this.isContactBeingEdited(contact) ? 'default' : 'primary'}
                                                                                size="xs" className="mr-10"
                                                                                onClick={e => this.handleEdit(this.state.editing ? undefined : contact)}>{this.isContactBeingEdited(contact) ? 'Cancel' : 'Edit'}</Button>}
                                                                            <Button variant={this.isContactBeingEdited(contact) ? 'cta' : 'danger'}
                                                                                id={this.isContactBeingEdited(contact) ? 'UpdateButton' : 'DeleteButton'}
                                                                                size="xs"
                                                                                onClick={e => this.isContactBeingEdited(contact) ? this.handleSaveContact(contact) : this.handleDeleteContact(contact)}>{this.isContactBeingEdited(contact) ? 'Update' : 'Delete'}</Button>
                                                                        </div>

                                                                            {this.isContactBeingEdited(contact) && this.state.editing.barr &&
                                                                            <div className="col-xs-12 pt-10 pb-25 mt-20 bg-light">
                                                                                {this.renderPrompts(this.state.prompts, contact, event => this.handleBarrPromptChange(event))}
                                                                            </div>
                                                                            }

                                                                    </div>
                                                                </div>

                                                            </div>

                                                            </>
                                                        })}

                                                    </div>
                                                    {this.state.blockedContacts && this.state.blockedContacts.length > 0 &&
                                                            <div className="text-center col-md-12 pt-30 mb-50">
                                                                <Button className="mb-5 mr-10 mr-xxs-0 btn-xxs-block" variant="cta" size="sm"
                                                                    onClick={e => this.setState({ new: { barr: true } })} id="BlockANumberButton">Block a number</Button>
                                                                <Button className="mb-5 btn-xxs-block" variant="primary" size="sm"
                                                                    onClick={e => this.setState({ import: {} })}>Import numbers</Button>
                                                            </div>
                                                        }
                                                </div>
                                            </div>
                                            <Modal className="modal-small" show={this.state.new}
                                                onHide={e => this.setState({ new: undefined })}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title id="BlockNewNumberModalHeader">Block new number</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <label>Callers phone number</label>
                                                    <input id="CallersPhoneNumberInput" className="form-control" type='tel' placeholder={'Caller\'s Number'}
                                                        value={this.state.new === undefined ? '' : this.state.new.number}
                                                        onChange={e => {
                                                          let value = e.target.value

                                                          if (value.length > 18) {
                                                            value = value.substring(0, 18)
                                                          }

                                                          const validEmail = new RegExp('^[A-Za-z0-9]+@+[A-Za-z0-9]+\.+[A-Za-z0-9\.]*').test(value)
                                                          const validNumber = new RegExp('^[0-9]{10,18}$').test(value)

                                                          if (validEmail === false && validNumber === false) {
                                                            this.setState({ validContactEntered: false })
                                                          } else {
                                                            this.setState({ validContactEntered: true })
                                                          }

                                                          if (!e) {
                                                            this.setState({ new: { ...this.state.new, number: '' } })
                                                          } else {
                                                            this.setState({
                                                              new: {
                                                                ...this.state.new,
                                                                number: value
                                                              }
                                                            })
                                                          }
                                                        }} />
                                                    <label className="pt-20">Callers name / description</label>
                                                    <input id="CallersNameDescriptionInput" className="form-control mb-15" type='text'
                                                        placeholder={'Caller\'s Description / Name'}
                                                        value={this.state.new === undefined ? '' : this.state.new.description}
                                                        onChange={e => {
                                                          let value = e.target.value

                                                          if (value.length > 200) {
                                                            value = value.substring(0, 200)
                                                          }

                                                          this.setState({
                                                            new: {
                                                              ...this.state.new,
                                                              description: value
                                                            }
                                                          })
                                                        }} />
                                                    {this.renderPrompts(this.state.prompts, undefined, event => this.handleNewContactBarrPromptChange(event))}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <div className="text-center">
                                                        <Button id="SaveButton" size="lg" variant="cta" className="btn-block" disabled={!this.state.validContactEntered || !this.state.new || !this.state.new.number || !this.state.new.description || this.state.new.description === null || this.state.new.description.replaceAll(/\s/g, '') === ''}
                                                            onClick={e => this.handleSaveNewContact()}>Save</Button>
                                                    </div>
                                                </Modal.Footer>
                                            </Modal>
                                            <Modal className="modal-small" show={this.state.import} onHide={e => this.setState({ import: undefined })}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Import Numbers</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="col-md-12 pt-30">
                                                        <p><span className="text-500">Step 1:</span> Select where your numbers have been exported from</p>
                                                        <div className="col-sm-12 pl-0 pb-20">
                                                            <select className="form-control pl-0" onChange={event => this.setState({
                                                              import: {
                                                                ...this.state.import,
                                                                from: event.target.value
                                                              }
                                                            })}>
                                                                <option value="">-- Import From --</option>
                                                                <option value="GoogleCSV">GMail Contact Export</option>
                                                                <option value="custom">Custom CSV</option>
                                                            </select>
                                                        </div>
                                                        <p>
                                                            <span className="text-500">Step 2:</span> Please select your file and click 'Start import'
                                                        </p>
                                                        <p className="pt-20">
                                                            <strong>Please note:</strong> If you&apos;re importing a custom file please ensure you have "Description" in column A, and "Telephone" in column B.<br />
                                                            <a href="/files/import-template.csv" className="btn btn-xs btn-info btn-block mt-10" download>Download sample file</a>
                                                        </p>
                                                        <div className="col-sm-12 mt-10 well text-center">
                                                            <input type="file" name="files" />
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer className="bt-0">
                                                    <div className="col-md-12 text-center pt-20 pb-30">
                                                        {this.state.isImporting && <p>Please wait, importing ...</p>}
                                                        <Button size="lg" variant="cta" className="btn-block"
                                                            onClick={e => this.handleImportContacts()}>Import</Button>
                                                    </div>
                                                </Modal.Footer>
                                            </Modal>
                                        </Tab>
                                    </Tabs>
                                </div>

                            </div>
                        </div>

                </>
                  : <>
                        <div className="full-width bg-light">
                            <div className="container">
                                <div className="row">
                                    <div className="mt-20 mb-20 pb-30 w-600px center-block">
                                        <p className="pt-20">
                                            Your time is important! Use our highest rated add-on to ensure you’re valuable time isn’t stolen by nuisance or unwanted phone calls. With three powerful, customisable features to block any and all unwanted calls.
                                        </p>
                                        <ul className="text-400 unstyled text-start pt-20 pb-20 ml-0 pl-0">
                                            <li>
                                                <span className="icon icon-checkmark text-cta"></span> Block all calls from outside the UK
                                            </li>
                                            <li>
                                                <span className="icon icon-checkmark text-cta"></span> Block &quot;withheld&quot; calls
                                            </li>
                                            <li>
                                                <span className="icon icon-checkmark text-cta"></span> Block a custom list of your choice
                                            </li>
                                        </ul>
                                        <p>
                                            Just £3 /pm, per account, allowing you to use all of these features across <br className="d-none d-lg-block" />all of your numbers.
                                        </p>
                                    </div>
                                </div>

                                <div className="row text-center">
                                    <div className="bg-dull w-600px center-block b-2 brad-20 mb-100">
                                        <div className="row">
                                            <div className="co-md-12 pb-10 pt-30">
                                                <h3 className="text-500">Activate Now</h3>
                                                <p className="text-400">Simply select one of the payment options below.</p>
                                            </div>
                                            <div className="col-md-12">

                                                {this.state.isPurchaseInProgress &&
                                                    <LoadingNotification className="mt-30" message={'Purchasing...'} isLoading={true} />
                                                }

                                                <Button variant="cta" className="m-5" size="lg" disabled={this.state.isPurchaseInProgress} onClick={event => this.handlePurchaseCallFiltering(productBillingOption.callBlockingBillingOptionYearly, yearlyPrice)}>
                                                    <GetProdPrice productId={ProductIds.callBlocking} yearlyOption={productBillingOption.callBlockingBillingOptionYearly} monthlyOption={productBillingOption.callBlockingBillingOptionMonthly} returnYearly={true} /> per year
                                                </Button>
                                                <Button className="m-5" disabled={this.state.isPurchaseInProgress} onClick={event => this.handlePurchaseCallFiltering(productBillingOption.callBlockingBillingOptionMonthly, monthlyPrice)} variant="cta" size="lg">
                                                    <GetProdPrice productId={ProductIds.callBlocking} yearlyOption={productBillingOption.callBlockingBillingOptionYearly} monthlyOption={productBillingOption.callBlockingBillingOptionMonthly} /> per month
                                                </Button>
                                                <div className="text-center pt-30">
                                                <img src={'/images/credit-cards/stripe-badge-transparent.png'} width="320" className="worldPay"
                                                    alt="Payments powered by Stripe" />
                                                </div>
                                                <div className="text-center pt-30 pb-30 text-400">
                                                    <small>
                                                        By purchasing this product you agree to the <a className="text-dark underline"
                                                            onClick={e => this.setState({ showTsAndCs: true })}>terms &amp; conditions</a> of this product.
                                                    <br />All prices are exclusive of VAT at 20%.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
                    <Modal.Body>
                        <TermsAndConditions />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
                    </Modal.Footer>
                </Modal>
                {/* END OF: Show the below when call blocking is not activated */}
            {/* </div> */}
            <ChoosePayment
                total={calculateVat(this.state.amount)}
                clientId={this.props.clientId}
                purchaseData={this.state.key}
                email={this.props.userDetails.email}
                name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                mandate={!this.props.services.hasAgreement}
            />

        </>
  }

  renderPrompts (prompts, contact, onPromptChange = event => this.handleBarrPromptChange(event)) {
    return <div>
            <label className="">Prompt this caller will hear:</label>
            <select className="form-control" onChange={onPromptChange}>
                {prompts && prompts.map((prompt) => <option key={prompt.id} value={prompt.id}
                    selected={contact && contact.blockedPromptId === prompt.id}>{prompt.description}</option>)}
            </select>
        </div>
  }

  handleBarrPromptChange (event) {
    const promptId = Number(event.target.value)
    this.setState({ editing: { ...this.state.editing, blockedPromptId: promptId } })
  }

  handleNewContactBarrPromptChange (event) {
    const promptId = Number(event.target.value)
    this.setState({ new: { ...this.state.new, blockedPromptId: promptId } })
  }

  handleSaveContact (contact) {
    const editedContact = this.state.editing
    const editingContactId = editedContact.qcId

    const index = _.findIndex(this.state.blockedContacts, function (contact) {
      return contact.qcId === editingContactId
    })

    const categoryValues = {
      barr: editedContact.barr,
      blockedPromptId: editedContact.blockedPromptId
    }

    this.setState({ blockedContacts: update(this.state.blockedContacts, { [index]: { $set: editedContact } }), editing: undefined })

    const url = '/api/CallBlocking/UpdateContact'

    fetchHelper.postJson(url, { qcId: contact.qcId, ...categoryValues }).then(res => {
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleSaveNewContact () {
    const url = '/api/CallBlocking/SaveNewContactBookContact'

    const newContact = this.state.new
    fetchHelper.postJson(url, newContact).then(res => {
      if (res.data.success) {
        const updatedContacts = update(this.state.blockedContacts, { $unshift: [newContact] })
        this.setState({ blockedContacts: updatedContacts, new: undefined })
        this.GetContacts()
      }
      this.props.notify({ message: res.data.message, isError: !res.data.success })
    }).catch(function (error) {
      console.error(error)
    })
  }

  handleDeleteContact (contact) {
    const url = '/api/CallBlocking/DeleteContact'

    const context = this

    const newContacts = _.reject(this.state.blockedContacts, function (thisContact) {
      return thisContact.qcId === contact.qcId
    })

    this.setState({ blockedContacts: newContacts, editing: undefined })

    fetchHelper.postJson(url, { qcId: contact.qcId }).then(res => {

    }).catch(function (error) {
      console.error(error)
    })
  }

  handleImportContacts () {
    const context = this
    const importData = document.querySelector('input[type="file"]').files[0]
    context.setState({ isImporting: true })
    fetchHelper.postFile(`api/CallBlocking/Upload/${this.state.import.from}/${true}`, importData).then(response => {
      if (response.data) {
        context.setState({ blockedContacts: response.data, import: undefined, isImporting: false })
        this.GetContacts()
      } else {
        context.props.notify({ message: 'Error saving configuration, please contact support', isError: true, isImporting: false })
      }
    }).catch(function (error) {
      context.props.notify({ message: 'Error saving configuration, please contact support', isError: true, isImporting: false })
    })
  }

  GetContacts () {
    const context = this

    const getContactsUrl = `/api/CallBlocking/GetContacts/${false}`
    this.setState({ loadingContacts: true })
    fetchHelper.getJson(getContactsUrl).then(res => {
      const contacts = res.data

      if (res.data) {
        context.setState({
          loadingContacts: false,
          blockedContacts: contacts
        })
      } else {

      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  componentDidMount () {
    const context = this
    this.GetContacts()

    const url = 'api/CallFiltering/GetCallFilteringNumbers'
    context.setState({ isLoading: true })
    fetchHelper.getJson(url)
      .then(response => {
        const data = response.data
        context.setState({
          blockInternationalCalls: data.blockInternationalCalls,
          blockWithheldCalls: data.blockWithheldCalls,
          numbers: data.callFilterList,
          isLoading: false
        })
      })
      .catch(error => {
        console.error(error)
      })

    const getBlockedPromptsUrl = '/api/CallBlocking/GetBlockedPrompts'
    this.setState({ loadingPrompts: true })
    fetchHelper.getJson(getBlockedPromptsUrl).then(res => {
      const prompts = res.data
      context.setState({ loadingPrompts: false, prompts })
    }).catch(function (error) {
      console.error(error)
    })

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('new=true')) {
        context.enableCallBlocking(context)
      }
    }

    const query = new URLSearchParams(window.location.search)
    if (query.get('block')) {
      this.setState({
        new: {
          barr: true,
          number: query.get('number'),
          description: query.get('desc')
        }
      })
    }
  }

  enableCallBlocking (context) {
    context.props.notify({ message: 'Call blocking enabled' })
    context.props.handleCallBlockingEnabledChange(true)
  }

  handlePurchaseCallFiltering (billingOption, amount) {
    if (this.props.displayBillingWarning === true) {
      this.props.handleShowBillingWarning()
    } else {
      const context = this
      const url = 'api/Purchase/PurchaseProduct'

      context.setState({ isPurchaseInProgress: true }, () => {
        fetchHelper.postJson(url, { BillingOption: billingOption, ProductId: ProductIds.callBlocking }).then(res => {
          if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
            this.enableCallBlocking(context)
          } else if (res.data && res.data.postponed === true) {
            this.enableCallBlocking(context)
            context.props.handleShowPaymentPostponedModal()
          } else {
            context.setState({ key: res.data.key })
            context.props.handleShowPaymentOptionsModal()
          }
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)
        })
      })
    }
  }

  isContactBeingEdited (contact) {
    return this.state.editing && this.state.editing.qcId === contact.qcId
  }

  handleEdit (contact) {
    this.setState({ editing: contact })
  }
}

export default connect(
  state => {
    return {
      isEnabled: state.sbfApp.enabledServices.hasNewCallBlockingFeature,
      monthlyPrice: state.home.productDetails.monthlyRepeatPayment,
      yearlyPrice: state.home.productDetails.yearlyRepeatPayment,
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      displayBillingWarning: state.sbfApp.enabledServices.displayBillingWarning
    }
  },
  {
    notify: handleNotificationChange,
    handleCallBlockingEnabledChange,
    getProductDetails: fromHome.getProductPrice,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal,
    handleShowBillingWarning
  }
)(CallBlocking)
