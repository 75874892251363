import React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { FanvilProdId, x3s, x7a, YeaLinkProdId } from '../../../constants/Constants'
import { fetchHelper } from '../../../helpers/fetchHelper'

const FreePhoneMoreInfo = (
	<Popover id="popover-basic">
		<p>To get this phone(s) for free, during the last step of the signup process (Checkout page), select the <i>Bi-Annual</i> payment option.</p>
        <p>
            <img src="/images/bi-annual.png" className="w-100" alt="Bi-Annual payment screenshot" />
        </p>
	</Popover>
)

class EarbudsManual extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      activeKey: this.props.match.params.activeTab ? this.props.match.params.activeTab : 'installation',
      showHandsetOrder: false
    }
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>SwitchboardFREE Wireless Earbuds manual</title>
                <meta name="description" content="SwitchboardFREE Wireless Earbuds" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/howitworks-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1 id="WirelessEarbudsH1">SwitchboardFREE Wireless Earbuds</h1>
                {/* <h2 className="subtitle">Make and receive calls on your computer with the help of a softphone.</h2> */}
            </header>
            <div className="container-styled">
                <div className="container pb-100">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <p className="pt-30 text-300">
                                SwitchboardFREE Wireless Earbuds give you a truly wireless experience. You can now effortlessly make and receive phone calls as well as listen to music.
                            </p>
                            <p className="pt-10 text-300">
                                These earbuds have been specifically chosen for use with the SwitchboardFREE phone app or the SwitchboardFREE Softphone due to the built-in microphone and digital noise cancelling.
                            </p>
                            <p className="text-500 pt-30">
                                Instructions
                            </p>
                            <ul className="pl-0 list-unstyled">
                                <li>
                                    <span className="text-500">To pair</span> - Open charging case and connect via Bluetooth
                                </li>
                                <li>
                                <span className="text-500">To answer an incoming call</span> -		Single tap: Left earbud
                                </li>
                                <li>
                                <span className="text-500">To reject an incoming call</span> -		Single tap: Right earbud
                                </li>
                                <li>
                                <span className="text-500">To invoke your digital assistant</span> - 		Long hold: Either earbud
                                </li>
                                <li>
                                <span className="text-500">To play and pause</span> -			Double-tap: Either earbud
                                </li>
                                <li>
                                <span className="text-500">To skip forward</span> - 				Triple tap: right earbud
                                </li>
                                <li>
                                <span className="text-500">To skip backward</span> -			Triple tap: Left earbud
                                </li>
                            </ul>
                            <p className="text-500 pt-30">
                            Product Details
                            </p>
                            <ul className="fancy cta pl-25">
                                <li>
                                Easy touch control and one step pairing

                                </li>
                                <li>
Bluetooth 5.1 + EDR (Enhanced Data Rate)
                                </li>

                                <li>
Built-in Mic with digital noise cancelling

                                </li>
                                <li>
4.5 hour talk time / 6.5 music playtime / 120-day standby

                                </li>
                                <li>
Quick 40 minute charge time + 300mAh charging case

                                </li>
                                <li>

Waterproof (IPX4)
                                </li>
                            </ul>

                            <p className="text-500 pt-30">
                            What’s In The Box

                            </p>
                            <ul className="fancy cta pl-25">
                                <li>
                                Earbuds
                                </li>
                                <li>
Charging case#

                                </li>

                                <li>
USB Type-C to USB-A charging cable

                                </li>
                                <li>
                                    Documentation
                                </li>
                            </ul>
                            <h4 className="text-500 pt-60 pb-30">
                            Technical Specs

                            </h4>
                            <p className="text-500">
                                Earbuds:
                            </p>
                            <ul className="fancy cta pl-25">
                                <li>
                                Wireless Bluetooth 5.1 Earbuds
                                </li>
                                <li>
Binaural Noise Reduction

                                </li>
                                <li>
Inbuilt microphone

                                </li>
                                <li>
TWS Smart Headphones
                                </li>
                                <li>
Battery capacity 40mAh

                                </li>
                                <li>

4.5 hour talk time / 6.5 music playtime / 120-day standby

                                </li>
                                <li>
40 minute charge time

                                </li>
                                <li>
Waterproof (IPX4)

                                </li>
                                <li>
Transmission distance 10m-15m
                                </li>
                            </ul>

                            <p className="text-500 pt-30">
                                Charging case:
                            </p>
                            <ul className="fancy cta pl-25">
                                <li>
                                    USB Type-C
                                </li>
                                <li>
                                    Battery capacity 300mAh
                                </li>
                                <li>
                                    Voltage DC 5V
                                </li>
                                <li>
                                    Magnetic close
                                </li>

                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width bg-dull">
                <div className="container">
                    <div className="row text-center mb-30 mt-30">
                        <div className="col-md-8 mx-auto">
                            <div className="row">
                                <div className="col-xs-12 mb-30">
                                    <h3 className="text-500">Other devices manuals</h3>
                                </div>
                                <div className="col-xs-4 col-xxs-12 mb-20">
                                    <Link to={'/setup/deskphone'}>
                                        <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                                            <img src="/images/product-shots/ecom/X4U.jpg" className="img-responsive" />
                                            <h5 className="text-500 text-sm-md">Deskphone</h5>
                                            <Link to={'/setup/deskphone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xs-4 col-xxs-12 mb-20">
                                    <Link to={'/setup/wireless-phone'}>
                                        <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                                            <img src="/images/product-shots/ecom/W52P.jpg" className="img-responsive" />
                                            <h5 className="text-500 text-sm-md">Wireless Phone</h5>
                                            <Link to={'/setup/wireless-phone'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xs-4 col-xxs-12 mb-20">
                                    <Link to={'/setup/app'}>
                                        <div className="b-1 brad-20 p-30 p-sm-10 zoom bg-light">
                                            <img src="/images/product-shots/ecom/app.jpg" className="img-responsive" />
                                            <h5 className="text-500 text-sm-md">App</h5>
                                            <Link to={'/setup/app'} className="btn btn-xs btn-cta mt-10 mb-20">See Instructions</Link>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <HandsetOrders showModal={this.state.showHandsetOrder} onHide={() => this.setState({ showHandsetOrder: false })} model={this.state.model} /> */}
            <div className="full-width bg-light">
                <div className="container" style={{ width: '2000px', maxWidth: '100%' }}>
                    <div className="row">
                        <div className="col-md-12 mx-auto pt-30 mb-100">
                            <div className="row">
                                <div className="col-md-12 text-center pt-30">
                                    <h3 className="text-500">Get Your HD VoIP Handset</h3>
                                    {/* <h4 className="text-cta"><span className="text-500">FROM JUST £6</span> p/month</h4> */}

                                    <h5 className="text-500 pt-10 pb-30">
                                        HASSLE-FREE Installation <span className="text-cta">•</span> FREE UK Support <span className="text-cta">•</span> UNRIVALLED Quality
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-3 col-xxs-12 text-center mb-20">
                                    <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light ">
                                        <img src="/images/product-shots/ecom/W53P.jpg" alt="Cordless VoIP Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">Cordless VoIP Handset</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£7.50</span> p/month</p> */}
                                        <p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
                                        <p className="pt-5 pb-5 pl-20 pr-20 text-center d-block">&nbsp;</p>
                                        <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(YeaLinkProdId)}>Order Now</Button>
                                        <br/>
                                        <a href="/products/yealink-w53p-wireless-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-xxs-12 text-center mb-20">
                                    <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                        <img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">Advanced VoIP Handset</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
                                        <p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
                                        <p className="pt-5 pb-5 pl-20 pr-20 text-center d-block">&nbsp;</p>
                                        <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(FanvilProdId)}>Order Now</Button>
                                        <br/>
                                        <a href="/products/advanced-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-xxs-12 text-center mb-20">
                                    <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                        <img src="/images/product-shots/ecom/X3S.jpg" alt="Fanvil X3S VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">VoIP Handset</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
                                        <p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
                                        <div className="d-block">
                                            <p className="bg-secondary brad-10 pt-5 pb-5 pl-20 pr-20 text-center text-lightest d-inline-block">
                                                Get it FREE! <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={FreePhoneMoreInfo}>
                                                    <span className="underline cursor-pointer ml-5 text-sm">See how</span>
                                                    </OverlayTrigger>.
                                            </p>
                                        </div>

                                        <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(x3s)}>Order Now</Button>
                                        <br/>
                                        <a href="/products/voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-xxs-12 text-center mb-20">
                                    <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                        <img src="/images/product-shots/ecom/X7A.jpg" alt="Fanvil X7A Android IP Video Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                        <h4 className="text-500 mb-5">Android Touchscreen Phone</h4>
                                        {/* <p className="text-cta text-md">From <span className="text-500">£6</span> p/month</p> */}
                                        <p className="pt-10 pb-0">With <span className="text-500">FREE delivery</span></p>
                                        <p className="pt-5 pb-5 pl-20 pr-20 text-center d-block">&nbsp;</p>

                                        <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(x7a)}>Order Now</Button>
                                        <br/>
                                        <a href="/products/x7a-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                                        {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentDidMount () {
    this.getHardware()
  }

  getHardware () {
    const url = 'api/Purchase/GetHardware'
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data) {
          this.setState({ products: res.data })
        }
      }).catch(err => console.error(err))
  }

  handleAddHandset (prodId) {
    const product = this.state.products.filter(f => f.prodId === prodId)
    const tempData = [{
      qty: 1,
      ...product[0]
    }]

    localStorage.setItem('HardwareFirst', JSON.stringify(tempData))

    this.props.history.push('/get-started')
  }
}
export default connect(
  state => {
    return state
  },
  {}
)(EarbudsManual)
