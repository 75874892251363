import React from 'react'
import { connect } from 'react-redux'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { getCustomerCount } from '../helpers/generic'

class Customers extends React.Component {
  constructor (props) {
    super(props)
  }
  state = {
    totalCustomers: 0
  }
  render () {
    return <>
            <ScrollToTopOnMount/><DisplayMainFooter/><DisplayMainHeader/>
   <header id="start">
        <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/profile-bg.jpg')" }}>
            <h1 className="text-500" id="ClientsAndParntersH1">
            Clients and Partners
            </h1>
            <h2 className="subtitle">
            Our clients and partners are testimony to how practical and dependable our service is.
            </h2>
        </div>
    </header>
    <div className="container-styled">
    <div className="container">
       <div className="row">
            <div className="col-md-12 text-center">
                    <p className="pb-30">There are currently over {this.state.totalCustomers} businesses using SwitchboardFREE and below is a very small sample of select customers and partners that you might have heard of.</p>
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/porsche.png'} alt="porsche logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/hsbc.png'} alt="HSBC logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/groupon.png'} alt="Groupon logo" />
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/nhs.png'} alt="NHS logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/rbs.png'} alt="RBS logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/tiscali.png'} alt="Tiscali logo" />
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/uk-distribution.png'} alt="UK Distribution logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/telstra.png'} alt="Telstra logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/cellcast.png'} alt="Cellcast logo" />
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/O2.png'} alt="o2 logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/more-than.png'} alt="More Than logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/opal.png'} alt="Opal logo" />
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/qd.png'} alt="QD logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/british-gas.png'} alt="British Gas logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/cherry-lane.png'} alt="Cherry Lane logo" />
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/nus-online.png'} alt="nus logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/trojan.png'} alt="Trojan logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/tesco.png'} alt="Tesco logo" />
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/fonehouse.png'} alt="Fonehouse logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/esso.png'} alt="ESSO logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/dulux.png'} alt="Dulux logo" />
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/argos.png'} alt="Argos logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/anglian-water.png'} alt="Anglian Water logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/centrebus.png'} alt="Centrebus logo" />
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/co-op-travel.png'} alt="Co-op Travel logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/surfdome.png'} alt="Surfdome logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/samaritans.png'} alt="Samaritans logo" />
            </div>
        </div>
        <div className="row text-center bb-1">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/forethought.png'} alt="Forethought logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/neuro-performance.png'} alt="Neuro-Performance logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/fire-pineapple.png'} alt="Fire Pineapple logo" />
            </div>
        </div>
        <div className="row text-center">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/southwest-film.png'} alt="South West Film logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/vapemate.png'} alt="Vapemate logo" />
            </div>
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/stuart-howitt.png'} alt="Stuart Howitt logo" />
            </div>
        </div>
        <div className="row text-center">
            <div className="col-md-4">
            <img className="p-20" src={'/images/customers/ls-logo.svg'} alt="logo" />
            </div>
        </div>
    </div>
    </div>

    </>
  }

  async getCustomers () {
    const data = await getCustomerCount()

    this.setState({ totalCustomers:  data})
  }

  componentWillMount () { 
    this.getCustomers()
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(Customers)
