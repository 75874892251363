import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'
import CustomPrompts from '../../CustomPrompts'

const OutOfHoursSectionHelperDialog = props => (
    <Modal className="w-900px" show={props.isDisplayed}
           onHide={props.hideOohInstructionHelperDialog}>
        <Modal.Header closeButton>
            <Modal.Title className="text-500">Out Of Hours</Modal.Title>
        </Modal.Header>
        <Modal.Body className="col-md-12">
            <p>
                This is the message which you callers will hear when calling your SwitchboardFREE number while it is
                closed.
            </p>

            <p>
                A SwitchboardFREE number can be closed either because it is outside of the open / close times (which can
                be
                set on this settings page) or the number has been quick closed (which can be set either on this settings
                page
                or on the “My Numbers” page).
            </p>
            <p className="bg-dull p-30 brad-10 mt-20">
            <span className="text-500">Tip:</span> Record a custom out-of-hours message such as your “open hours” or any other useful information and then set your line as closed. This is a great way for callers to hear an automated announcement. You can also do this on a single department if you have multiple departments selected.
            </p>
            <div className="col-md-12 text-center pb-30 pt-30">
                <h3 className="text-500">Make your company sound amazing!</h3>
                <p>Choose one of the below voice artists to record your out of hours message, introduction, call screening, voicemail message, departments menu... or simply do it yourself.</p>
            </div>
            <CustomPrompts />
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideOohInstructionHelperDialog}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.oohInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutOfHoursSectionHelperDialog)
