import { type ProductPriceDto, type ProductPriceOptionsQuery } from '../models/GenericTypes'
import { fetchHelper } from '../helpers/fetchHelper'

export function fetchProductPrices (priceOptionsQueries: ProductPriceOptionsQuery[], receivedProductPricesCallback: (prices: ProductPriceDto[]) => void) {
  Promise.all(priceOptionsQueries.map(async (priceQuery: ProductPriceOptionsQuery) => {
    const prodId = priceQuery.productId
    const monthlyOption = priceQuery.monthlyOption
    const yearlyOption = priceQuery.yearlyOption

    let getPriceUrl = `api/Purchase/GetProductPrice?prodId=${prodId}`
    if (yearlyOption) { getPriceUrl += `&yearlyOptionId=${yearlyOption}` }

    if (monthlyOption) { getPriceUrl += `&monthlyOptionId=${monthlyOption}` }

    return await fetchHelper.getJson<ProductPriceDto>(getPriceUrl)
  })).then(productQueryResponses =>
    productQueryResponses.map(productQueryResponse => productQueryResponse.data)).then((productPrices: ProductPriceDto[]) => { receivedProductPricesCallback(productPrices) })
}
