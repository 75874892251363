import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import Helmet from 'react-helmet'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ClientLogos from '../../components/ClientLogos'

class SoleTraderCaseStudy extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return <>
			<ScrollToTopOnMount />
			<DisplayMainFooter />
			<DisplayMainHeader />

			<Helmet>
				<title>How SwitchboardFREE Helped a Sole Trader Increase His Customer Base</title>
			</Helmet>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container">
					<div className="row mt-100 mt-xs-30">
						<div className="col-md-6 col-sm-12 order-last text-center p-xs-30">
							<img src="/images/case-studies/plumber3.jpg" alt="" className="img-responsive mt-20 mt-xs-0" style={{ maxWidth: '600px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-first">
							<h1 className="text-500 text-darker">How SwitchboardFREE Helped a Sole Trader Increase His Customer Base</h1>
							<h2 className="text-lg text-400 mb-0 mt-30 text-dark-light w-600px">
								Joe<sup>*</sup> knew using his mobile for business was unprofessional
							</h2>

							<p className="mt-30">
								Joe<sup>*</sup> was reluctant to use his personal mobile for his business. He hated not knowing if a call was for business or pleasure but thought it was his only cost-effective solution.
							</p>

							<p>
								It wasn’t just using his own mobile that was becoming an issue. He often felt uncomfortable yet obliged to answer calls whilst doing work for a customer. He wanted to ensure he was dedicating his time to his immediate paying customer but he didn’t want to lose business either.
							</p>

							<div className="bg-dull brad-10 p-50 d-none d-md-block" style={{ backgroundColor: '#d6e8ef' }}>
								<p className="m-0"><em>Joe<sup>*</sup> runs his plumbing business as a sole trader and offers a 24-hour service to his customers</em></p>
							</div>

						</div>
					</div>

				</div>
			</div>

			<div className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40" style={{ backgroundColor: '#d6e8ef' }}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-md-12">
							<h2 className="text-500 text-darker h4 m-0 text-center">
								<span className="text-cta">&ldquo;</span>On more than one occasion I had dropped my phone into a toilet whilst on a job & trying to take a call!<span className="text-cta">&rdquo;</span>
							</h2>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container">
					<div className="row mt-100 mt-xs-100">
						<div className="col-md-6 col-sm-12 order-last order-md-first text-end pr-50 pr-xs-0">
							<img src="/images/case-studies/plumber.jpg" alt="Soletrader Plumber" className="img-responsive" style={{ maxWidth: '600px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-first order-md-last">
							<h2 className="text-lg text-400 mb-30 mt-0 text-dark-light w-600px">
								Joe also believes that presenting a mobile phone number to a potential customer puts them off.
							</h2>

							<p>
								From his experience of working for a larger firm, he could see how calling a mobile number made people feel they were contacting a cowboy or would charge higher rates.
							</p>

							<div className="bg-dull brad-10 p-50 d-none d-md-block mt-30 mb-50" style={{ backgroundColor: '#f7ffd0' }}>
								<p className="mb-0"><em>&ldquo;People prefer using the services of an established company rather than taking a chance on some solo guy with a Monkey Wrench!&rdquo;</em></p>
							</div>

							<p className="lead text-secondary text-500">
								The Opportunity of Call Management
							</p>
							<p>
								Looking for a way to appear more professional and save his personal phone from another dunk, Joe<sup>*</sup> came across SwitchboardFREE.
							</p>
							<p>
								At first, he was attracted by the low monthly cost, it was something that he could easily fit into his budget. Secondly, he could see how some of the free features such as missed call alerts and voice email would be perfect in helping with his missed-call anxiety.
							</p>

							<div className="bg-dull brad-10 p-50 d-none d-md-block mt-30" style={{ backgroundColor: '#d6e8ef' }}>
								<p className="mb-0"><em>&ldquo;Having the choice of more than one number means I can prioritise my work.&rdquo;</em></p>
							</div>
						</div>
					</div>

					<div className="row mt-100 mt-xs-100">
						<div className="col-md-6 col-sm-12 order-first order-md-last text-start pl-50 pl-xs-0">
							<img src="/images/case-studies/plumber2.jpg" alt="Soletrader Plumber" className="img-responsive" style={{ maxWidth: '600px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-last order-md-first">
							<h2 className="text-lg text-500 mb-30 mt-0 text-secondary w-600px">
								A plumber people can trust
							</h2>

							<p>
								Having opted for two free 084 numbers, Joe<sup>*</sup> immediately saw the benefits.
								He found that his business gave the image of being a larger company and despite the 4.2p a minute charge to call, he was getting more enquiries.
							</p>

							<p>
								Joe<sup>*</sup> also found that he could manage his workload better. With one number for enquiries and a different number for emergencies, he could ensure that someone wasn’t waste-deep in water waiting for him whilst he was out doing another job.
							</p>

							<div className="bg-dull brad-10 p-50 d-none d-md-block mt-30 mb-50" style={{ backgroundColor: '#f7ffd0' }}>
								<p className="mb-0"><em>&ldquo;Routing emergency calls to my mobile means I can get to those customers quicker.&rdquo;</em></p>
							</div>

							<p>
								The feature that Joe<sup>*</sup> has had the most benefit from is the missed call alerts. It’s given him the freedom to catch up on people who have called during the day and get straight back to them. This has meant that he hasn’t missed any opportunity and is no longer frustrated.
							</p>
							<p>
								With new professional numbers and a robust call management system, Joe’s* customers remain confident from the moment they call to the completion of the job.
							</p>
						</div>
					</div>

					<div className="row">
						<div className="col-12 text-center pt-50">
							<p><sup>*</sup>Name changed for anonymity.</p>
						</div>
					</div>

				</div>
			</div>

			<div className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40" style={{ backgroundColor: '#d6e8ef' }}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-md-12">
							<h2 className="text-500 text-darker h4 m-0 text-center">
								<span className="text-cta">&ldquo;</span>
								There are loads of features that come with using SwitchboardFREE. Since I’ve been using them, I’ve noticed that I’ve had a lot more work. Customers have been recommending me on the basis that I always get back to them. I believe that SwitchboardFREE can help any business - whether or not your company is large or if it is just you on your tod like me!
								<span className="text-cta">&rdquo;</span>
							</h2>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-80 pt-xs-40 pt-80 pb-xs-40">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-7 col-md-12">
							<h2 className="text-500 text-darker h4 m-0 text-center">
								If you’d like to learn more about SwitchboardFREE or try it our for yourself, click on of the buttons below.
							</h2>

							<p className="text-center mt-30">
								<Link className="btn btn-secondary mr-20 mr-xs-0" to="/features">
									Discover Free Features
								</Link>
								<Link className="btn btn-cta mr-20 mr-xs-0" to="/get-started">
									Find Your Plan
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center mt-20">
						<div id="contentMobile"></div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull ">
				<div className="container">
					<CarouselWidget />
				</div>
			</div>

			{/* Logoos */}
			<div className="full-width bg-light">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">Partners &amp; Customers</h3>
							{/* <h6>The UK's favourite call management system</h6> */}
							<p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>
			<ClientLogos style='light' />


		</>
	}
}

export default connect(
	state => {
		return ({
		})
	},
	{}
)(SoleTraderCaseStudy)
