import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface RingCentralProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const RingCentral = (props: RingCentralProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>RingCentral vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>RingCentral vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of RingCentral vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Who are RingCentral?</h2>
                            <p>RingCentral is an American cloud-based communications and collaboration solution for businesses and was founded in Belmont, California, in 1999.</p>
                            <p>RingCentral provides a cloud-based phone system and offers a set of PBX features such as Multiple extensions, Outlook, Salesforce, DropBox and Box integration, SMS, RingCentral Video conferencing and web conferencing, Fax, Auto-receptionist, logs, RingCentral app, and Rule-based routing and answering.</p>
                            <p>RingCentral's flagship product is RingCentral Office. The company also offers RingCentral Professional and RingCentral Fax.</p>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="pbx" svgWidth="70px" svgClassName="mt-20" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">RingCentral Office</h4>
                                    <p className="pt-10">RingCentral Office is a cloud-based PBX communications system for businesses. RingCentral Office features include auto-attendant, company directory, phone call forwarding and handling, multiple team extensions, an iPhone app, and Android mobile app, Business SMS, RingCentral video conferencing and screen-sharing, and fax.</p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="briefcase" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">RingCentral Professional</h4>
                                    <p className="pt-10">RingCentral Professional is a suite that provides professional telecom and communications services. RingCentral Professional features include a universal telephone number, voice mail, dial-by-name directory, phone call-forwarding, and other features through an iPhone app and Android mobile app.</p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="fax2email" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">RingCentral Fax</h4>
                                    <p className="pt-10">RingCentral Fax allows customers to send and receive faxes through the Internet without a fax machine. RingCentral Fax also integrates with Dropbox, Box.</p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="pricing" svgWidth="70px" svgClassName="mt-20" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">RingCentral packages and pricing</h4>
                                    <p className="pt-10">There are four business communications packages available to RingCentral customers– Essentials, Standard, Premium, and Ultimate. The prices for each of them, except Enterprise, are determined by the number of users signing up and the length of their contract. If a customer opts to use the package for one full year, the monthly cost may go down.</p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="pricing1" svgWidth="80px" svgClassName="mt-20" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">RingCentral Essentials business package</h4>
                                    <p className="pt-10">Prices for the RingCentral Essentials business package range from £7.99 to £12.99 a month and includes 100 minutes per user. You get: The desktop RingCentral app, iPhone app, and Android mobile app, Team messaging, Document sharing, Logs, and you get 24/7 Customer Support.</p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="pricing2" svgWidth="80px" svgClassName="mt-20" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">RingCentral Standard business package</h4>
                                    <p className="pt-10">Prices for the RingCentral Standard business package range from £12.99 to £19.99 a month and includes 750 Inclusive minutes per user. You get: On-Demand Recording, Video meetings with up to 100 participants, Internet fax, Unlimited audio conferencing, Multi-Level IVR, Up to 24-hour meeting duration, Popular integrations including Microsoft 365, Google Workspace, Slack, and a desktop RingCentral app, iPhone app, and Android mobile app.</p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="pricing3" svgWidth="80px" svgClassName="mt-20" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">RingCentral Premium business package</h4>
                                    <p className="pt-10">Prices for the RingCentral Premium business package range from £17.99 to £24.99 a month and includes 2,000 Inclusive minutes per user. You get: Automatic Recording, Video meetings with up to 200 participants, Multi-site admin, and management, Up to 8-digit extensions with site codes, Hot desking, Advanced phone call handling including whisper, barge, Custom roles and permissions, Popular CRM integrations with Salesforce, Zendesk, Developer platform, and custom integrations.</p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="pricing4" svgWidth="80px" svgClassName="mt-20" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">RingCentral Ultimate business package</h4>
                                    <p className="pt-10">Prices for the RingCentral Ultimate business package range from £22.99 to £29.99 a month and includes 4,000 Inclusive minutes per user, Automatic Recording, Video meetings with up to 200 participants, Multi-site admin, and management, Up to 8-digit extensions with site codes, Hot desking, Advanced phone call handling including whisper, barge, Custom roles and permissions, Popular CRM integrations with Salesforce, Zendesk, Developer platform and custom integrations, Device status reports, Device status alerts and Unlimited storage.</p>
                                </div>
                            </div>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">How does SwitchboardFREE compare with RingCentral?</h2>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="compare1" svgWidth="70px" svgClassName="mt-20" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Essential package comparison</h4>
                                    <p>In a direct search comparison, the Ringcentral business pricing seems high in relation to the available service. The RingCentral Essential package (2021) is charged at £7.99 at its lowest price (£12.99 for a single user account).
                                        This covers only <span className="text-500">100</span> inclusive <span className="text-500">outbound minutes only</span> and any inbound minutes are charged as extra. This can cause a significant increase beyond the advertised cost of the service.</p>
                                    <p>The low advertised cost is also only applied if paid for <span className="text-500">a year in advance</span>, locking you into a 12-month contract before you even tested it.</p>
                                    <p>The SwitchboardFREE Pay-As-You-Go VoIP phone package for the same £7.99 would give you <span className="text-500">unlimited</span> inbound calls and an average of <span className="text-500">178</span> outbound minutes to any UK number. This also comes with no long term contracts and no set-up fees, so the minimum term is only 1 month.</p>
                                    <p>SwitchboardFREE also includes £5 free phone call credit and over 40 select free features on all its packages regardless of the monthly cost or the number of team members you have.</p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="compare1" svgWidth="70px" svgClassName="mt-20" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Standard package comparison</h4>
                                    <p>The RingCentral Standard package starts from £12.99 per month but for this low price, you would need over 100 team members. Realistically, this would cost £19.99 for a single team member and £14.99 for 2 to 19 users and includes only 750 inclusive minutes.</p>
                                    <p>In comparison, SwitchboardFREE offers an unlimited VoIP phone package for only £12.99 per month regardless if you opt for a single user or 100. This gives you unlimited inbound and outbound calls up to a fair usage amount of 3,000 minutes per month per user, and you can "pool" all your teams minutes together giving you even greater flexibility and value.</p>
                                </div>
                            </div>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Business communications &amp; Team messaging</h2>
                            <p>Although there are many factors that constitute business communications, such as phone calls, live chats, and video conferences, These don&apos;t necessarily have to be all bundled together.</p>
                            <p>By utilising experts in each field you really get the best performing and best value for each element of business communication without having to compromise or pay for services that don&apos;t fit your needs or are needlessly expensive. </p>
                            <p>The RingCentral video meeting feature added to some of the more expensive packages can easily be replaced with <span className="text-500">free</span> third party services such as Zoom.</p>
                            <p>The free Zoom video conference suite can handle video meetings of up to 100 participants and we found the product to be excellent.</p>
                            <p>There are many video conference platforms out there and if your company uses Microsoft 365 login then the integrated Teams product allows paid users to have video meetings of up to 300 members.</p>
                            <p>Google Meet is also great for business communications and offers free video meetings of up to 100 participants for up to 24 hours. (Participants are required to login using the Gmail account).</p>
                            <p>There are pros and cons to the RingCentral video service as there are with other providers. Unless you try each of the services and make your own decision on which is best for you, you could end up with a bundled service that you're compelled to use because you're contracted to pay for it over the next 12 months.</p>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="integration" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Integrations</h4>
                                    <p>RingCentral offers some really comprehensive integrations with Microsoft 365, Slack, Salesforce, Zendesk and more besides. But again, these integrations seem to be conditional to the package you choose or the monthly cost that you're willing to pay. </p>
                                    <p>SwitchboardFREE on the other hand offer direct integrations with Capsule CRM, PipeDrive and HubSpot. We also offer Webhook integration for FREE across all its packages and chose to partner with Zapier so our system seamlessly integrates with the set of tools you already use regardless if that's Salesforce, Microsoft 365, or Slack. </p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="apps" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Mobile app</h4>
                                    <p>The final aspect of business communications and team messaging is Mobile apps. The 2021 Ringcentral app is comparable to the SwitchboardFREE app and both can make and receive calls via IOS or Android and you can configure phone system settings and access voicemails.</p>
                                    <p>The SwitchboardFREE app isn't yet geared up for team messaging just yet but this is something that's in the pipeline and for late 2021 / early 2022. In the meantime, there are many third party free team messaging apps available.</p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="devices" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Devices</h4>
                                    <p>If you need new work desk phones, as of 2021 Ringcentral offers almost 20 different options across their platform. You can get industry-leading phones and products to help you talk and connect with your team. Ring central offer an unrivalled level of options when it comes to products and desk phones giving you reliable and top quality devices and they have many partners and work with several different manufacturers.</p>
                                    <p>They have devices for any budget from £69.99 for phones like the Polycom VVX 150 to high-end devices like the Unify CP 400, which you can get for £269.00. These expert industry phones and products have many resources to complement most work environments and they may have one to suit your company.</p>
                                    <p>By Comparison, SwitchboardFREE currently only partners with Yealink and offers 2 new handsets as of 2021. One wired and one wireless. We are building the resources and may offer more to fulfil this need and we expect to grow the range of devices across our platform. </p>
                                </div>
                            </div>

                            <div className="row mt-30 bb-1 pb-30">
                                <div className="col-xs-2 pt-15">
                                    <div className="icon-block icon-dull xl">
                                        <SvgRender icon="call-answering" svgWidth="60px" svgClassName="mt-25" />
                                    </div>
                                </div>
                                <div className="col-xs-10 pl-40">
                                    <h4 className="text-500">Live call answering</h4>
                                    <p>One very important feature that SwitchboardFREE has that's a real benefit over RingCentral is the live call answering platform suppliede by Pocket Receptionist. This 5-star rated telephone answering service partners a high-end VoIP platform with real, quality, reliable agents.</p>
                                    <p>Pocket Receptionist uses only in-house, trained UK based agents to answer calls on behalf of your business.</p>
                                </div>
                            </div>

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Frequently asked questions</h2>
                            <h4 className="text-500">How can I try SwitchboardFREE?</h4>
                            <p>If you would like to try SwitchboardFREE, simply <a href="/get-started" className="text-cta" id="GetStartedTodayLink">get started today</a>, or search for "SwitchboardFREE" in your online browser, or you contact us either our sales center or customer center on <a className="text-cta" href="tel:0203 189 1213">0203 189 1213</a> where you can connect or talk to a customer service expert who can assist you to sign up via phone or email. </p>

                            <h4 className="text-500">How do I connect a phone or device to your platform?</h4>
                            <p>All our new devices are plug &amp; play and therefore work out of the box. If you need more information about our devices, we have a dedicated phone and apps page <a className="text-cta" href="/phones-apps" id="PhoneAppsHereLink">here</a> and our new expert technical team can work with you to help you get started.</p>

                            <h4 className="text-500">About SwitchboardFREE</h4>
                            <p>SwitchboardFREE has been established for over 16 years, over the last few years we have been busy developing our live call answering team and in recent years we launched our outbound VoIP system. In the last 12 months, we have also launched our hardware division and even Artificial intelligence call recognition.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">
                                If you need to contact our company regarding this like for like comparison or to compare online industry resources between SwitchboardFREE Ltd (United Kingdom) and 2021 RingCentral inc all rights please call 0844 884 3000 and talk to one of our welcome team. (For security reasons, we may need to take some initial contact details from you in order to provide you with this information)
                                Any prices, features, tools or data comparison of online industry resources in this article between SwitchboardFREE Ltd (United Kingdom) and 2021 RingCentral inc all rights is correct and updated as at 2021. <br />
                                Our Live call answering Staff Hold certificates in Data Protection and go through an external security training process to ensure they comply and work with the EU’s General Data Protection Regulation (GDPR).
                            </p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(RingCentral)
