import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import chrisMp3Sample from '../../sounds/chrisDemo.mp3'
import janiceMp3Sample from '../../sounds/janiceDemo.mp3'
import * as configurationActions from '../../store/Configuration'

const SystemVoiceSamples = props => (
    <div>
        <button type="button" className="btn btn-info btn-xs" onClick={props.handleShowSystemVoiceSamples} id="ListenToTheSamplesButton">Listen to the samples</button>

        <Modal show={props.showSystemVoicePromptSampleDialog} onHide={props.hideSystemVoicePromptSampleDialog}>
            <Modal.Header className='bb-0' closeButton>
                <Modal.Title>
                    <h3 className='text-500'>Voice Samples</h3>
                    <h4 className="text-xs text-400">Click below to hear a sample of the voices available for your SwitchboardFREE number.</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-20">
                    <div className="col-md-6 text-center">
                        <div className='b-1 brad-20 bg-dark p-20 box-shadow'>
                            <img className='image-responsive w-150px' src="/images/chris-round.png" alt="Chris" />
                            <h5 className='text-500 text-lightest pb-20'>Chris</h5>
                            <ReactAudioPlayer
                                className="brad-10"
                                src={chrisMp3Sample}
                                controls
                            />
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <div className='b-1 brad-20 bg-dark p-20 box-shadow'>
                            <img className='image-responsive w-150px' src="/images/janice-round.png" alt="Janice" />
                            <h5 className='text-500 text-lightest pb-20'>Janice</h5>
                            <ReactAudioPlayer
                                className="brad-10"
                                src={janiceMp3Sample}
                                controls
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <h5 className='text-400'>If you would like Chris or Janice or our interal team to record your prompts, head on to our <a className='text-cta underline' href="/professional-prompts">Professional Prompts page</a>.</h5>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <div className="col-md-12 text-center">
                <button className="btn btn-cta btn-md" onClick={props.hideSystemVoicePromptSampleDialog}>Close</button>
                </div>
            </Modal.Footer> */}
        </Modal>
    </div>
)

export default connect(
  state => ({
    showSystemVoicePromptSampleDialog: state.configuration.dialogVisibility.showSystemVoicePromptSampleDialog
  }),
  dispatch => bindActionCreators(configurationActions, dispatch)
)(SystemVoiceSamples)
