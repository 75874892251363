import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import CallbackRequest from '../../../components/CallbackRequest'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../../selectors'

class MissedCallAlerts extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>Missed call email alerts - Free Virtual Call Management System</title>
                <meta name="description" content="Receive missed call alerts to your email whenever you miss a call" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/departments-bg.jpg')" }} className="full-width full-hero-small">
                <h1>Missed Call Alerts</h1>
                <h2 className="subtitle text-center">Instant, real-time alerts whenever a call is missed</h2>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto pb-30">
                            <p>
                                If you can't answer calls made to your SwitchboardFREE number, don&apos;t panic. All the information about the caller is saved and emailed to the email address(es) of your choice.
                            </p>

                            <p className="pt-30">
                                The email will contain:
                            </p>
                            <ul className="styled text-400">
                                <li>the callers number</li>
                                <li>time and date of the call</li>
                                <li>hold time</li>
                                <li>the SwitchboardFREE number the caller dialled</li>
                                <li>the department they chose (if <a href="/departments-selection" className="underline text-cta">departments</a> are enabled).</li>
                            </ul>
                            <h3 className="pt-30 text-500">
                                Turning on the missed call alerts
                            </h3>
                            <p>
                                In your number configuration settings, scroll to the "Missed call alerts" section and click the toggle switch to enable it. In the box on the right, you must input at least one valid email address. You can input more than one email address, just make sure they are separated with a semi-colon (;) symbol.
                               <br /><br />
                               For example: <i>info@company-name.com;john.smith@example.com;john@gmail.com</i> etc.
                            </p>
                            <figure className="pb-30 pt-30 mb-30 mt-30 text-center">
                                <img className="img-responsive b-1 pb-10 brad-20" src="/images/screenshots/missed-call-alerts.jpg" alt="missed call alerts settings screenshot" />
                                <figcaption className="text-xs pt-10">Screenshot of the Missed Call Alerts settings</figcaption>
                            </figure>
                            <h3 className="pt-30 pb-30 text-500">
                                Avoid missing calls
                            </h3>

                            <h5 className="text-500">Add more redirect numbers</h5>
                            <p>
                                Every missed call might have been a potential paying customer. He or she might have now called someone else.
                                To avoid missing calls made to your SwitchboardFREE number(s), check if you have entered more than one redirect number. If there is no answer on your 1st redirect number, our system will try the next one. <a href="redirecting-calls">Find out more about redirecting calls</a>.
                            </p>

                            <h5 className="pt-30 text-500">Automatic SMS</h5>
                            <p>
                                SwitchboardFREE offers a feature called "Callback SMS". When you miss a call, we can send an automatic SMS to your caller with a custom message set by you. For example, you can send an apology for missing their call or tell them you will call them back as soon as you can.
                            </p>

                            <h5 className="pt-30 text-500">Let us answer your calls</h5>
                            <p>
                                SwitchboardFREE also offers a <a href="telephone-answering-service/">telephone answering service, supplied by Pocket Receptionist</a>.
                                A dedicated team of professionally trained receptionists will answer calls to your SwitchboardFREE number(s) for you, while you can carry on with your job. <a href="telephone-answering-service/">Find out more about Pocket Receptionist</a>.
                            </p>
                            <div className="ratio ratio-16x9 pt-30">
                                <iframe src="https://www.youtube.com/embed/X8dKUN4FP_8" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full-width bg-dull text-center p-30 mt-30 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <h4 className="text-500 pb-30">Do you have any more questions about Misssed Call Alerts?</h4>
                                <h5 className="mb-30 pb-30 text-400">Call us on <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a>, email <a href="support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a> <br className="d-none d-sm-block"/>or request a callback below. <br />Our offices are open Monday to Friday 8:30am to 6pm.</h5>
                                <p></p>
                                <CallbackRequest subject="Callback request from Departments page" isForm={true} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(MissedCallAlerts)
