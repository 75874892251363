import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

class Customers extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
			<ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
			<Helmet>
				<title>Cookie Policy</title>
			</Helmet>
			<div className="bg-light pt-50">
				<div className="container w-1100px">
					<div className="row">
						<div className="col-md-12 text-center">
							<h1 className="pb-30">SwitchboardFREE Cookie Policy</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h4 className="pt-20">What Are Cookies?</h4>
							<p>Cookies are small text files that are placed on your computer, smartphone, or other devices when you visit a website. These files help websites store information about your preferences, such as language and region settings, and can be used to remember you when you revisit the website. Cookies are essential for providing a seamless browsing experience and for enabling website functionality.</p>
							<p>For more information about cookies, please visit <a href="https://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">All About Cookies</a>.</p>

							<h4 className="pt-20">Why Does SwitchboardFREE Use Cookies?</h4>
							<p>SwitchboardFREE uses cookies for a variety of purposes:</p>
							<ol>
								<li><strong>Essential Cookies</strong>: These cookies are necessary for the basic functioning of our website. Without them, you may not be able to access certain features, such as secure areas or saved preferences.</li>
								<li><strong>Performance Cookies</strong>: These cookies help us improve the performance of our website by collecting and reporting information about how visitors interact with our site. This information helps us understand which parts of the website are most popular, and how we can make the user experience better.</li>
								<li><strong>Functionality Cookies</strong>: These cookies enable us to remember your preferences and choices, such as your preferred language or region, to provide a more personalized experience.</li>
								<li><strong>Advertising Cookies</strong>: These cookies are used to deliver relevant and tailored advertising to you based on your browsing habits and interests.</li>
							</ol>
							<p>By using our website, you agree to the placement of these cookies on your device, unless you have disabled them in your browser settings.</p>

							<h4 className="pt-20">Managing Cookies</h4>
							<p>You can manage and delete cookies in your browser settings. Most browsers allow you to block or delete cookies, but please note that if you choose to disable cookies, some features of the SwitchboardFREE website may not function properly.</p>
							<p>For detailed information on how to manage cookies in your browser, please visit the links below:</p>
							<ul>
								<li><a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">Google Chrome</a></li>
								<li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
								<li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank" rel="noopener noreferrer">Apple Safari</a></li>
								<li><a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
								<li><a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noopener noreferrer">Internet Explorer</a></li>
							</ul>

							<h4 className="pt-20">Cookies Used on This Website</h4>

							<table className="table">
								<tbody>
									<tr>
										<th>Name</th>
										<th>Host</th>
										<th>Purpose</th>
									</tr>
									<tr>
										<td>_clsk</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used to collect data about how users use our website</td>
									</tr>
									<tr>
										<td>_scid</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used to collect data about how users use our website</td>
									</tr>
									<tr>
										<td>ARRAffinitySameSite</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used to collect data about how users use our website</td>
									</tr>
									<tr>
										<td>lhnRefresh</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used for the functionality of our live chat software</td>
									</tr>
									<tr>
										<td>_uetsid</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used to report conversion events to Twitter</td>
									</tr>
									<tr>
										<td>_ga_YRN8EZFX0M</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used by Google Analytics to track how visitors use our website</td>
									</tr>
									<tr>
										<td>_gid</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used by Google Analytics to track how visitors use our website</td>
									</tr>
									<tr>
										<td>_gcl_au</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used by Google Analytics to track how visitors use our website</td>
									</tr>
									<tr>
										<td>_ga</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used by Google Analytics to track how visitors use our website</td>
									</tr>
									<tr>
										<td>lhnJWT</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used for the functionality of our live chat software</td>
									</tr>
									<tr>
										<td>lhnStorageType</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used for the functionality of our live chat software</td>
									</tr>
									<tr>
										<td>_fbp</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used to report conversion events to Facebook</td>
									</tr>
									<tr>
										<td>lhnContact</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used for the functionality of our live chat software</td>
									</tr>
									<tr>
										<td>_clck</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used by Google Analytics to track how visitors use our website</td>
									</tr>
									<tr>
										<td>_uetvid</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used to report conversion events to Twitter</td>
									</tr>
									<tr>
										<td>ARRAffinity</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used to collect data about how users use our website</td>
									</tr>
									<tr>
										<td>__stripe_mid</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used for the fuctionality of our card payment facility</td>
									</tr>
									<tr>
										<td>__stripe_sid</td>
										<td>.switchboardfree.co.uk</td>
										<td>This cookie is used for the fuctionality of our card payment facility</td>
									</tr>
									<tr>
										<td>MUID</td>
										<td>.bing.com</td>
										<td>This cookie is used to report conversion events to Bing</td>
									</tr>
									<tr>
										<td>sb, presence, fr, datr, locale, xs, usida, wd, c_user</td>
										<td>.facebook.com</td>
										<td>These cookies are used to report conversion events to Facebook</td>
									</tr>
									<tr>
										<td>__Secure-3PAPISID, SAPISID, APISID, SSID, HSID, __Secure-1PSID, __Secure-3PSID, NID, SID, __Secure-1PAPISID</td>
										<td>.google.co.uk</td>
										<td>These cookies are used to report conversion events to Google</td>
									</tr>
									<tr>
										<td>lidc, AnalyticsSyncHistory, UserMatchHistory, li_gc, bcookie</td>
										<td>.linkedin.com</td>
										<td>These cookies are used to report conversion events to LinkedIn</td>
									</tr>
									<tr>
										<td>sc_at</td>
										<td>.snapchat.com</td>
										<td>This cookie is used to report conversion events to Snapchat</td>
									</tr>
									<tr>
										<td>muc_ads</td>
										<td>.t.co</td>
										<td>This cookie is used to personalise adverts that you see from us within the Twitter network</td>
									</tr>
									<tr>
										<td>personalization_id</td>
										<td>.twitter.com</td>
										<td>This cookie is used to personalise adverts that you see from us within the Twitter network</td>
									</tr>
								</tbody>
							</table>

							<h4 className="pt-20">Changes to Our Cookie Policy</h4>
							<p>SwitchboardFREE may update this Cookie Policy from time to time. Any changes will be posted on this page, along with the date of the latest revision. We encourage you to review this policy regularly to stay informed about our use of cookies and related technologies.</p>

							<h4 className="pt-20">Contact Us</h4>
							<p>If you have any questions about our Cookie Policy or our use of cookies, please <Link to="/contact">contact us</Link>.</p>
						</div>
					</div>
				</div>
			</div>
		</>
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(Customers)
