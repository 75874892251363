import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import TextBannerWidget from '../../components/Reviews/TextBannerWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import { Link } from 'react-router-dom'

export default class WaBusiness extends React.Component {
  render() {
    return (
      <>
        <ScrollToTopOnMount />
        <DisplayMainFooter />
        <DisplayMainHeader />
        <Helmet>
          <title>WhatsApp Business Numbers from SwitchboardFREE</title>
          <meta name="description" content="Separate your business from your personal WhatsApp with a WhatsApp Business number from SwitchboardFREE." />
        </Helmet>
        <header className="full-width full-hero-medium circle-image full-secondary pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="row">
                  <div className="col-sm-12 col-md-6 pb-sm-60">
                    <h1 className="text-lightest pt-80 pt-md-30 pt-sm-20 mt-0 text-xs-500">WhatsApp Business Number</h1>
                    <h2 className="subtitle pt-20">
                      WhatsApp for business, WhatsIt all about?
                    </h2>
                  </div>
                  <div className="d-none d-md-block col-md-6 float-end">
                    <img src="/images/wa-business/header2.png" alt="WhatsApp Business" style={{ maxWidth: '100%' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="full-width full-dull text-xs">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <TextBannerWidget textDark={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="full-width bg-light pt-60 pb-60">
          <div className="container">
            <div className="row pl-30 pr-30 pl-sm-5 pr-sm-5">
              <div className="col-lg-8 mx-auto">
                <p>
                  For those of you who haven’t been using WhatsApp for your business communication, you may be missing out on a vital business tool.
                </p>
                <p>
                  WhatsApp is a popular messaging app that most of us use to keep in touch with friends and family by sending texts and media. Some even use it for video calling. Canny business folk have found that by using WhatsApp to communicate, their customers are more receptive because they are perceived as more friendly-like rather than salesy.
                </p>
                <p>Please note that SwitchboardFREE are not able to offer phone numbers to use with WhatsApp Business. We provide virtual/VoIP phone numbers which are not allowed according to WhatsApp Terms and Conditions, all similar providers will have the same issue.</p>
                <p>
                  <Link to="/blog/10-ways-to-keep-in-contact-with-your-customers?id=1758" className="btn btn-secondary">
                    Connecting with Your Customers
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width bg-wa pt-60 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12 col-xs-12 mx-auto">
                <div className="row">
                  <div className="col-md-7 pt-50 pt-sm-0 pt-md-0 pt-xs-0 pr-xs-15 pr-sm-15 pb-xs-50 pb-sm-50">
                    <h4 className="text-500 text-lightest">Does WhatsApp business need a separate phone or device? </h4>
                    <p className="mt-30">
                      WhatsApp has always been known for its one-number-one account rule. However, there was a short period of time when you could assign a VoIP number to your WhatsApp Business messaging app.
                    </p>
                    <p className="mt-30">
                      This meant that you could keep your personal WhatsApp number and WhatsApp business number separate whilst using just one phone. Now, WhatsApp has put a stop to that (why, oh why, WhatsApp?) meaning that if you want to use WhatsApp for business you will either a) have to purchase a second sim and number or b) surrender your personal WhatsApp to your business (and no one wants to do that!).
                    </p>
                  </div>
                  <div className="col-md-5 col-sm-12 col-xs-12 text-center float-end">
                    <img src="/images/wa-business/quick-replies.png" alt="WhatsApp Business" className="img-responsive" style={{ maxWidth: '350px' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width bg-light pt-60 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12 col-xs-12 mx-auto">
                <div className="row">
                  <div className="col-md-7 pt-50 pt-sm-0 pt-md-0 pt-xs-0 pr-xs-15 pr-sm-15 pb-xs-50 pb-sm-50">
                    <h4 className="text-500">Benefits of WhatsApp Business</h4>
                    <p className="mt-30">
                      Despite the pain of getting an additional sim to use for your WhatsApp business number, here at SwitchboardFREE we believe it’s worth it. Why? Because when using WhatsApp Business in tandem with SwitchboardFREE’s call management you will be able to offer your customers the full package when it comes to communication and support. This will help to put you on the map as the business to come to in your industry.
                    </p>
                    <p className="mt-30">
                      Why? <br />Because when using WhatsApp Business in tandem with SwitchboardFREE’s call management you will be able to offer your customers the full package when it comes to communication and support. This will help to put you on the map as the business to come to in your industry.
                    </p>

                  </div>
                  <div className="col-md-5 col-sm-12 col-xs-12 text-center">
                    <img src="/images/wa-business/benefits.png" alt="WhatsApp Business" className="img-responsive" style={{ maxWidth: '350px' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width text-center pb-60 pt-60 bg-dull">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12 col-xs-12 mx-auto">
                <p className="text-400 lead">
                  SwitchboardFREE and <a href="https://business.whatsapp.com/">WhatsApp Business</a> have free downloadable apps that come with lots of <Link to="/features">free features</Link>, which are perfect for small or start-up businesses.
                </p>

                <p className="mt-30">
                  <Link to="/features" className="btn btn-cta btn-md">
                    Discover Free Call Management
                  </Link>
                </p>
              </div>
            </div>
          </div>

        </div>
      </>
    )
  }
}