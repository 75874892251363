import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import ClientLogos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { CarrierIds, isGeoOffer, numberOfferDuration, NumbersGlobalOfferPrice } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'
import * as getSignup from '../../store/Signup'

class NumbersUKCity extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({ isOpen: true })
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            {/* <NumbersMenu /> */}
            <Helmet>
                <title>UK City Numbers - Virtual City Numbers</title>
                <meta name="description" content="Buy a City area code phone number from anywhere in the UK for your business. Instant online activation plus free call management system." />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/ukcity-bg.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="UKAreaCodeNumbersH1">UK City Area Code Numbers</h1>
                <h2 className=" subtitle">Target local markets and give your company a local presence nationally.</h2>
            </header>
            <div className="full-width bg-light pr-xs-10 pl-xs-10 pl-30 pr-30 pt-60 pb-60 text-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center pb-50">
                            <h2 className="text-500">
                            {isGeoOffer
                              ? <>
                                            Buy a virtual UK city number for <br className="d-block d-sm-none"/><span className="text-cta">only {(NumbersGlobalOfferPrice < 1 ? `${(NumbersGlobalOfferPrice % 1).toFixed(2).substring(2)}p` : `£${NumbersGlobalOfferPrice}`)} for {numberOfferDuration === 1 ? 'one month' : numberOfferDuration === 2 ? 'two months' : 'three months'}
                                            </span> then i&apos;s from <GetProdPrice prefix="01" ignoreOffer={true} /> per month.
                                        </>
                              : <>
                                            Buy a virtual UK city number from just <GetProdPrice prefix="01" /> per month
                                        </>
                                    }
                                {/* Buy a virtual UK city number for <br className="d-block d-sm-none"/><span className="text-cta">only 99p a month</span> */}
                            </h2>
                            <p className="text-lg text-xs-md text-400 pt-xs-20">
                                Target local markets and give your company a local presence nationally.
                                <br/>
                                <span className="text-xs-sm">Instant activation • No hardware required • No long term contracts</span>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-md-6 pr-200 pt-30 pr-lg-60 pr-md-60 pr-sm-15">
                            <h4 className="text-500 pb-30">
                                What are Virtual City Numbers?
                            </h4>
                            <p className="text-md pb-20">
                                City numbers are a great way to give you a business a presence in any large town or city in the UK. Instantly giving your business a local presence across the UK, in turn giving you up to 22% increase in calls from that area.
                            </p>
                            <p className="pb-20">
                                By using multiple numbers in different cities, you can give your company a nation presence and enjoy an increase in phone enquiries by up to 40%.
                            </p>
                            <p className="pb-20">
                                City number packs are affordable and instant!
                           </p>
                            <p className="pb-20">
                                If you’re looking for multiple local numbers, try our <Link to={'/multi-number-package/'} className="text-500 underline text-cta">Multi-Number package</Link>.
                            </p>
                            <p className="pb-20">
                                Update your website with your new numbers and enjoy an instant increase in sales calls and enquires.
                            </p>
                            <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30">
                                <li>
                                    Up to 22% increase in calls locally
                                </li>
                                <li>
                                    Trusted local numbers
                                </li>
                                <li>
                                    Over 40 powerful free features
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-5 col-md-6 mt-20">
                            <div className="bg-dull pl-60 pr-60 pl-md-30 pr-md-30 pt-30 pb-30 brad-20 b-2 area-codes" style={{ minHeight: '310px' }}>
                                <div className="text-center">
                                    <h4 className="text-500 text-cta">Find your perfect UK city number</h4>
                                    <p className="pb-30">Select a UK city below:</p>
                                </div>

                                <div className="center-block">

                                    <Select
                                        id="geoLocalRegionSelect"
                                        className="form-control select-menu h-60px"
                                        classNamePrefix="custom"
                                        onChange={e => this.setState({ selectedDdiLocalId: e.value })}
                                        options={this.state.regions}
                                        simpleValue
                                        name="selectedRegion"
                                        defaultValue={this.state.selectedDdiLocalId}
                                        searchable={true}
                                        isLoading={this.state.loadingRegions}
                                    />

                                    <p className="mt-30 text-center">
                                        <Button variant="cta" onClick={e => this.handleSelectedArea(this.state.selectedDdiLocalId, this.props.history, this.props.handleSetSelectedArea)} className="btn-block btn-lg">Select</Button>
                                    </p>

                                    <p className="pt-30 pb-15 text-center">Most seached for...</p>

                                    {this.state.popular
                                      ? <ul className="row list-unstyled center-block most-popular">
                                        {this.state.popular.map((area) => {
                                          return (
                                                <li className="col-xs-6 col-xxs-12 pb-10 pl-5 pr-5">
                                                    <a className="btn btn-lightest btn-block brad-10 zoom" onClick={e => this.handleSelectedArea(area.ddiLid, this.props.history, this.props.handleSetSelectedArea)}>
                                                        <strong className="text-500">{area.location}</strong> <span className="text-400">({area.prefix})</span>
                                                    </a>
                                                </li>
                                          )
                                        })}
                                    </ul>
                                      : null
                                }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-dull pt-50">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>

            {/* Logoos */}
            <div className="full-width bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500">Partners &amp; Customers</h3>
                            {/* <h6>The UK's favourite call management system</h6> */}
                            <p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
                        </div>
                    </div>
                </div>
            </div>
            <ClientLogos style='light' />
        </>
  }

  handleSelectedArea (ddiLid, history, setSelectedArea) {
    if (ddiLid === undefined) {
      return
    }

    fetchHelper.getJson(`/api/purchase/GetRegionNameAndPrefixByDdiLid/${ddiLid}`).then(res => {
      const region = res.data
      const areaCodeRoute = `/area-code/${region.prefix}-${region.location.replace(' / ', '-').replace('(', '').replace(')', '').replace(' ', '-').toLowerCase()}-numbers`
      setSelectedArea(ddiLid)

      history.push(areaCodeRoute)
    }).catch(function (error) {
      console.error(error)
    })
  }

  async componentDidMount () {
    const getGeoRegionsUrl = '/api/Purchase/GetGeoRegions/'
    this.setState({ loadingRegions: true })
    fetchHelper.postJson(getGeoRegionsUrl, { CarrierIds, CitysOnly: true }).then(res => {
      const regions = res.data.regions
      const popular = res.data.popularRegions
      this.setState({ loadingRegions: false, regions, popular })
    }).catch(function (error) {
      console.error(error)
    })
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleSetSelectedArea: getSignup.handleSetSelectedArea
  }
)(NumbersUKCity)
