import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const NumberPresentationSectionHelperDialog = props => (
    <Modal show={props.isDisplayed}
           onHide={props.hideNumberPresentationHelperDialog}>
        <Modal.Header closeButton>
            <Modal.Title className="text-500">Number Presentation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                When you receive a call to your redirect number you can choose which number the
                call appears to be coming from.
            </p>

            <p>
                You can use this feature to show the call as coming from the actual callers number
                (this is used in the majority of cases), or if you want to integrate with an existing
                PBX platform you can set the call as coming from the SwitchboardFREE number which
                has been called.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.hideNumberPresentationHelperDialog}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.numberPresentationInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberPresentationSectionHelperDialog)
