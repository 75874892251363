import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { BESPOKE_OPEN_CLOSE_TIMES } from '../../constants/ConfigurationConstants'
import * as configurationActions from '../../store/Configuration'
import BespokeOpenCloseTimes from './BespokeOpenCloseTimes'
import OpenCloseTimesQuickSet from './OpenCloseTimesQuickSet'

const OpenCloseTimes = props => (
    <>
    <div className="row bg-light p-30 zoom">
        <div className="col-md-5">
            <h5 className="text-secondary text-500 text-lg" id="OpenCloseTimesText">
                Open / Close Times
            </h5>
            <p className="text-400">
                Set open and close times to fit your business hours. Callers phoning outside normal hours will be informed your offices are closed. Optional out-of-hours voicemails are instantly emailed to you.
            </p>
            </div>
            <div className="col-md-7">
            <OpenCloseTimesQuickSet/>

            <div className="text-center col-sm-12 pt-sm-50">
                <p>
                    Would you like to automatically close your SwitchboardFREE number on bank holidays?
                </p>

                    {/* <input type="checkbox" checked={props.autoCloseBankHols} onChange={e => props.changeAutoCloseBankHols(e.target.checked)}/> */}
                    <Toggle
                        className="mt-0"
                        onClick={e => props.changeAutoCloseBankHols(e)}
                        on={<span>YES</span>}
                        off={<span>NO</span>}
                        size="sm"
                        offstyle="default"
                        onstyle="success"
                        width="null"
                        height="null"
                        active={props.autoCloseBankHols}
                        id="OpenCloseBankHolidayToggle"
                    />

                </div>
            </div>
            <div className="col-sm-12">
                {props.quickOpenCloseTimeId === BESPOKE_OPEN_CLOSE_TIMES && <BespokeOpenCloseTimes validation={props.validation}/>}
            </div>
        </div>
        </>

)

export default connect(
  state => ({
    quickOpenCloseTimeId: state.configuration.configuration.quickOpenCloseTimeId,
    autoCloseBankHols: state.configuration.configuration.autoCloseBankHols
  }),
  {
    changeAutoCloseBankHols: configurationActions.updateAutoCloseBankHols
  }
)(OpenCloseTimes)
