import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import ImageLeftFullWidth from '../../components/ImageLeftFullWidth'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { is0203Offer, is0207Offer, is033Offer, is0800Offer, is0808Offer, isGeoOffer, numberOfferDuration, NumbersGlobalOfferPrice } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'

class OutboundLandingPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            {/* <HowWorksMenu /> */}
            <Helmet>
                <title>Virtual Phone Numbers, Local Phone Numbers</title>
                <meta name="description" content="Discover the power of outbound calling. Make calls using your 084, 0800, 03, 020 or local city number." />.
                {/* inline style elements */}
                {/* {this.state.isLoggedIn &&
                    <style type="text/css">{`
                        body {
                            padding-top: 0 !important;
                        }
                    `}
                    </style>
                } */}
                <style type="text/css">{`
                .full-width ul.unstyled {
                    list-style-position: outside !important;
                }
                .full-width ul.unstyled li {
                    padding:5px 10px;
                    font-size:1em;
                }
                .full-width ul.unstyled li .bg-cta {
                    border-radius:50%;
                    height:25px;
                    width:25px;
                    display:inline-block;
                    text-align:center;
                    color:#fff;
                }
                .full-width ul.unstyled li .bg-cta .icon {
                    font-size:14px;
                    top:-1px;
                    position:relative;
                }
                .icon-block.xl .icon {
                    top: 18px !important;
                }
                @media screen and (max-width:767px) {
                    .col-xs-6 .icon-block {
                      width: 130px;
                      height: 130px;
                      padding: 8px 0;
                    }
                  }
                  @media screen and (max-width: 1150px){
                    .bg-image {
                        background-image: none !important;
                    }
                    .bg-image .col-sm-6 {
                        width: 100%;
                        margin-left: 0;
                    }
                  }
                    `}</style>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/plans.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="VirtualPhoneNumbersH1">Virtual Phone Numbers</h1>
                <h2 className=" subtitle">Get a virtual number diverted to your mobile, landline or VoIP system & take the first step in levelling up your business!</h2>
            </header>

            <div className="full-width bg-light pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center pb-50">
                            <h2 className="text-500">
                                {isGeoOffer
                                  ? <>
                                        Buy a virtual number for <br className="d-block d-sm-none" /><span className="text-cta">only {(NumbersGlobalOfferPrice < 1 ? `${(NumbersGlobalOfferPrice % 1).toFixed(2).substring(2)}p` : `£${NumbersGlobalOfferPrice}`)} for {numberOfferDuration === 1 ? 'one month' : numberOfferDuration === 2 ? 'two months' : 'three months'}
                                        </span>
                                    </>
                                  : <>
                                        Buy a virtual UK city number from just <GetProdPrice prefix="01" /> per month
                                    </>
                                }
                            </h2>
                            <p className="text-lg text-xs-md text-400 pt-xs-20">
                                Public image matters, so be located where your customers want you to be. City, local or non-geographical, you can capture every opportunity with a cloud-based virtual number.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-md-6 pr-200 pt-30 pr-lg-60 pr-md-60 pr-sm-15">
                            <h3 className="text-500 pb-30">
                                What is a Virtual Number?
                            </h3>
                            <p className="text-400 text-dark">
                                A virtual landline number is a phone number that isn’t physically connected to or associated with a fixed telephone line.
                            </p>
                            <p className="text-400 pt-20 text-dark">
                                Instead, these contact numbers live in the cloud and exist on the provider’s system or platform. When a call comes in on your chosen virtual number, it can be diverted directly to any mobile, existing landline or VoIP number.
                            </p>

                            <h3 className="text-dark text-500 mb-30 pt-40">
                                Why Choose a Virtual Number?
                            </h3>
                            <p className="text-400 text-dark">
                                The problem with the traditional landline is that it has limited capabilities compared to a virtual, cloud-based one.
                            </p>
                            <p className="text-400 text-dark">
                                For example, with a few clicks, you can increase or decrease the number of lines you have depending on your business needs. Gone are the days when you need expensive and intrusive equipment installed!
                            </p>
                            <p className="text-400 text-dark">
                                Plus you can choose from a variety of numbers such as local, city-based, free phone or non-geographical. This can enhance your credibility and expand your marketing efforts by putting your business where your customers want you to be.
                            </p>
                            <p className="text-400 text-dark">
                                And that’s not all. You can find out even more features right <a href="/features" className="underline">here</a>.
                            </p>

                            <h3 className="text-dark text-500 mb-30 pt-40">
                                What is VoIP?
                            </h3>

                            <p className="text-400 text-dark">
                                VoIP stands for voice over internet protocol; you may have heard it mentioned along with IP telephony, voice over IP or IP phone. Put simply, it’s the technology that moves videos, pictures and voice communications via the internet.
                            </p>
                            <p className="text-400 pt-20 text-dark">
                                Your traditional landline phone uses an old technology that can only deliver voice calls. This can be known as PSTN or public switched telephone network.
                            </p>

                            <h3 className="text-dark text-500 mb-30 pt-40">
                                Still Unsure About Virtual Numbers?
                            </h3>
                            <p className="text-400 pt-20 text-dark">
                                Different virtual numbers can help your business in different ways. We’ve outlined the 6 types available & how they can be used.
                            </p>
                        </div>
                        <div className="col-lg-5 col-md-6 mt-50">
                            <div className="bg-dull pl-40 pr-40 pl-md-30 pr-md-30 pt-30 pb-30 brad-20 b-2 number-types-box p-xxs-15" style={{ minHeight: '310px' }}>
                                <div className="text-center">
                                    <h4 className="text-500 text-cta">Find Your Perfect Virtual Number</h4>
                                    <p className="pb-30 pt-20">Select a virtual number type below:</p>
                                </div>

                                <div className="center-block row">
                                    <div className="col-6 mb-20 pl-xxs-10 pr-xxs-10">
                                        <Link to={'/city-numbers'} className="pl-30 pr-30 pt-15 pb-15 brad-10 bg-secondary btn btn-block" id="BeLocalButton">
                                            <span className="text-500">Be Local</span>
                                            <br />
                                            <span className="text-500">01 &amp; 02</span>
                                            <br />
                                            <span className="text-400 text-xs block">From {isGeoOffer ? `£${NumbersGlobalOfferPrice}` : <GetProdPrice prefix="01" />}</span>
                                        </Link>
                                    </div>
                                    <div className="col-6 mb-20 pl-xxs-10 pr-xxs-10">
                                        <Link to={'/0207-numbers'} className="pl-30 pr-30 pt-15 pb-15 brad-10 bg-secondary btn btn-block" id="BePrestigiousButton">
                                            <span className="text-500">Be Prestigious</span>
                                            <br />
                                            <span className="text-500">0207</span>
                                            <br />
                                            <span className="text-400 text-xs block">From {is0207Offer ? `£${NumbersGlobalOfferPrice}` : <GetProdPrice prefix="0207" />}</span>
                                        </Link>
                                    </div>
                                    <div className="col-6 mb-20 pl-xxs-10 pr-xxs-10">
                                        <Link to={'/0203-numbers'} className="pl-30 pr-30 pt-15 pb-15 brad-10 bg-secondary btn btn-block" id="BeCredibleButton">
                                            <span className="text-500">Be Credible</span>
                                            <br />
                                            <span className="text-500">0203</span>
                                            <br />
                                            <span className="text-400 text-xs block">From {is0203Offer ? `£${NumbersGlobalOfferPrice}` : <GetProdPrice prefix="0203" />}</span>
                                        </Link>
                                    </div>
                                    <div className="col-6 mb-20 pl-xxs-10 pr-xxs-10">
                                        <Link to={'/03-numbers'} className="pl-30 pr-30 pt-15 pb-15 brad-10 bg-secondary btn btn-block" id="BeEverythingButton">
                                            <span className="text-500">Be Everything</span>
                                            <br />
                                            <span className="text-500">0333</span>
                                            <br />
                                            <span className="text-400 text-xs block">From {is033Offer ? `£${NumbersGlobalOfferPrice}` : <GetProdPrice prefix="0333" />}</span>
                                        </Link>

                                    </div>
                                    <div className="col-6 mb-20 pl-xxs-10 pr-xxs-10">
                                        <Link to={'/0800-numbers'} className="pl-30 pr-30 pt-15 pb-15 brad-10 bg-secondary btn btn-block" id="BeFreeButton">
                                            <span className="text-500">Be Free</span>
                                            <br />
                                            <span className="text-500">0800</span>
                                            <br />
                                            <span className="text-400 text-xs block">From {is0800Offer ? '99p' : <GetProdPrice prefix="0800" />}</span>
                                        </Link>
                                    </div>
                                    <div className="col-6 mb-20 pl-xxs-10 pr-xxs-10">
                                        <Link to={'/0808-numbers'} className="pl-30 pr-30 pt-15 pb-15 brad-10 bg-secondary btn btn-block" id="BeExceptionalButton">
                                            <span className="text-500">Be Exceptional</span>
                                            <br />
                                            <span className="text-500">0808</span>
                                            <br />
                                            <span className="text-400 text-xs block">From {is0808Offer ? '99p' : <GetProdPrice prefix="0800" />}</span>
                                        </Link>
                                    </div>
                                    <p className="text-sm pt-10 text-italic text-center clearfix">Instant activation <span className="text-cta">•</span> No long-term contracts <span className="text-cta">•</span> Award-winning UK support</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ImageLeftFullWidth
                MainContentClass="pt-60 pb-60 pt-xs-0 pb-xs-30 bg-dull"
                bgImgPosition="60% 8%"
                ImgURL="/images/21.jpg"
                RightImageClass="d-none d-sm-block"
                TextCopy={
                    <>
                        <h2 className="text-500 mb-30 mt-0 pt-xs-40 text-dark h3">
                            Types of Numbers & Their Benefits
                        </h2>
                        <p className="text-400 text-dark">
                            There are six main virtual number types or types of phone numbers you can choose from:
                        </p>
                        <div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 mt-20 zoom">
                            <h6 className="text-500 pt-20 text-dark">
                                Local virtual phone numbers (01, 02)
                            </h6>
                            <p className="text-400 pt-10 text-dark pb-20">
                                Everyone loves the feeling of supporting a local business, so will be excited to find out more about you. Looking local is easy with the 01 and 02 numbers and will give your business that instant trust element that automatically comes with being close by. Did you know that advertising using a local number can increase interest in your business by 22% from that area?
                            </p>
                            <p className="text-secondary text-500">
                                Choose local from just {isGeoOffer ? `£${NumbersGlobalOfferPrice}` : <GetProdPrice prefix="01" />} per month
                            </p>
                            <p>
                                <Link to={'/city-numbers'} className="btn btn-cta btn-sm m-5 btn-xxs-block" id="LocalLearnMoreButton">Learn More</Link>
                            </p>
                        </div>

                        <div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 mt-20 zoom">
                            <h6 className="text-500 pt-20 text-dark">
                                London number (0207)
                            </h6>
                            <p className="text-400 pt-10 text-dark pb-20">
                                Do you have big city dreams with a small town budget? It’s a common problem, but you can still have the prestige that comes with being a London-based business, without the excessive overheads. Grab yourself one of the rare London 0207 numbers and put your company in amongst those well-known established businesses.
                            </p>
                            <p className="text-secondary text-500">
                                Choose London from just {is0207Offer ? `£${NumbersGlobalOfferPrice}` : <GetProdPrice prefix="0207" />} per month
                            </p>
                            <p>
                                <Link to={'/0207-numbers'} className="btn btn-cta btn-sm m-5 btn-xxs-block" id="0207LearnMoreButton">Learn More</Link>
                            </p>
                        </div>

                        <div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 mt-20 zoom">
                            <h6 className="text-500 pt-20 text-dark">
                                London number (0203)
                            </h6>
                            <p className="text-400 pt-10 text-dark pb-20">
                                Those new, yet up-and-coming businesses based in London are fortunate enough to automatically have the popular 0203 numbers. They get instant credibility just for occupying city space! As you may well know, credibility can elevate any business, so get some easily with this number - there’s no need to relocate!
                            </p>
                            <p className="text-secondary text-500">
                                Choose London from just {is0203Offer ? `£${NumbersGlobalOfferPrice}` : <GetProdPrice prefix="0203" />} per month
                            </p>
                            <p>
                                <Link to={'/0203-numbers'} className="btn btn-cta btn-sm m-5 btn-xxs-block" id="0203LearnMoreButton">Learn More</Link>
                            </p>
                        </div>

                        <div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 mt-20 zoom">
                            <h6 className="text-500 pt-20 text-dark">
                                Free-to-call numbers (0800, 0808)
                            </h6>
                            <p className="text-400 pt-10 text-dark pb-20">
                                Who doesn’t want free? The 0800 and 0808 numbers are instantly recognisable as free phone numbers and will show your customers that taking care of them is your first priority. Including one of these numbers in your advertising will attract more new customers than any other number. It will give your company a sizable appearance in addition to appealing to a wider, even national audience.
                            </p>
                            <p className="text-secondary text-500">
                                Choose free-to-call from just {is0800Offer ? '99p' : <GetProdPrice prefix="0800" />} per month {is0800Offer ? 'It’s in our sale!' : ''}
                            </p>
                            <p>
                                <Link to={'/0800-numbers'} className="btn btn-cta btn-sm m-5 btn-xxs-block" id="0800LearnMoreButton">Learn More about 0800 numbers</Link>
                                <Link to={'/0808-numbers'} className="btn btn-cta btn-sm m-5 btn-xxs-block" id="0808LearnMoreButton">Learn More about 0808 numbers</Link>
                            </p>
                        </div>

                        <div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 mt-20 zoom">
                            <h6 className="text-500 pt-20 text-dark">
                                Non-geographical number (0333)
                            </h6>
                            <p className="text-400 pt-10 text-dark pb-20">
                                Your customers will thank you for providing them with a number that is changed at their local rate - which is sometimes free within a mobile or landline call plan. This number can offer you big business vibes with its national presence, yet still, appeal to locals who will recognise the local rate you are providing for them - which is usually free!
                            </p>
                            <p className="text-secondary text-500">
                                Choose non-geographical from just {is033Offer ? `£${NumbersGlobalOfferPrice}` : <GetProdPrice prefix="03" />} per month
                            </p>
                            <p>
                                <Link to={'/03-numbers'} className="btn btn-cta btn-sm m-5 btn-xxs-block" id="0333LearnMoreButton">Learn More</Link>
                            </p>
                        </div>

                        <div className="bg-light pt-10 pb-20 pl-30 pr-30 brad-20 mb-30 mt-20 zoom">
                            <h6 className="text-500 pt-20 text-dark">
                                Non-geographical number (084)
                            </h6>
                            <p className="text-400 pt-10 text-dark pb-20">
                                The 084 number can give you the nationwide presence you need to elevate your business at absolutely no cost to you. This is a great option if you barely have a budget or would like to find out how call routing to a landline can work for your business without the outlay. There is a small cost to your customer but it will be worth it with the exceptional service that they will receive when they make a call to your office.                             </p>
                            <p className="text-secondary text-500">
                                Choose non-geographical number (084) for FREE
                            </p>
                            <p>
                                <Link to={'/084-numbers'} className="btn btn-cta btn-sm m-5 btn-xxs-block" id="084LearnMoreButton">Learn More</Link>
                            </p>
                        </div>
                    </>
                }
            />

            <div className="container">
                <div className="row">
                    <div className="col-4 d-block order-last mt-20">
                        <div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#f7ffd0' }}>
                            <p><em>“Just started to use this service and it works really well.  There are a lot of features and the system is flexible, and all manageable from my computer or my phone via the app. Hannah in customer services has been brilliant, on hand to answer any questions.”</em></p>

                            <span className="text-500 pt-5 d-inline-block">
                                Robin <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
                            </span>
                            <img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
                        </div>
                    </div>
                    <div className="col-4 d-block order-last mt-20">
                        <div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#d6e8ef' }}>
                            <p><em>“I have used SwitchboardFREE before and have always found it very easy to use. I am also pleased by the level of service from the customer service department if there ever was an issue that I had. Great product for great prices!”</em></p>
                            <span className="text-500 pt-5 d-inline-block">
                                Dan <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
                            </span>
                            <img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
                        </div>
                    </div>
                    <div className="col-4 d-block order-last mt-20">
                        <div className="bg-dull brad-10 p-20 clearfix pb-45" style={{ backgroundColor: '#ffebeb' }}>
                            <p><em>“Hannah's knowledge and professionalism saved me both time and money. She solved some comms issues in our business and she was wonderful to deal with. I cannot recommend this company more highly!”</em></p>
                            <span className="text-500 pt-5 d-inline-block">
                                Elaine <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
                            </span>
                            <img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
                        </div>
                    </div>
                </div>
            </div>

            { /*
            <div className="full-width bg-light bg-xs-dull pt-50 pb-50 pt-xs-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pb-50">
                            <h2 className="text-500">
                                Choose your perfect virtual phone number
							</h2>
                        </div>
                    </div>
                    <NumberTypeInfo
                        hideIcons={true}
                        hideCompareButtons={true}
                        CustomCTAButtonClass="btn btn-secondary pt-5 pb-5 pl-10 pr-10 text-sm"
                        CustomDivClass="col-xxs-12 col-xs-6 col-md-4 mb-0 text-center p-30 bg-xs-light box-shadow-xs mb-xs-20 brad-20"
                    />
                </div>
            </div>

            <div className="full-width bg-secondary pt-50 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="p-25 w-1000px center-block text-center">
                            <h3 className="text-500 text-lightest">Level up your business with virtual phone numbers</h3>
                            <p className="text-md text-lightest pt-20 pb-20">
                                Whether you&apos;re a start up, sole trader or established business, your public image matters.
                            </p>
                            <p className="text-lightest w-700px center-block">
                                We’ve helped over 77,000 businesses in the UK to reduce or eliminate their missed calls whilst also improving their call management. Take control of your telecoms today.
                            </p>
                            <p>
                                <Link to={'/get-started'} className="btn btn-cta btn-md mt-40">
                                    Create your account
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <ImageRightFullWidth
                MainContentClass="pt-60 pb-60 pt-xs-0 pb-xs-30 bg-light"
                TextCopy={
                    <>
                        <h2 className="text-500">
                            The benefits are boundless
                        </h2>
                        <p className="lead text-400 pt-10 text-dark">
                            Set up is easy and our UK Welcome team are on-hand to assist you every step of the way.
                        </p>
                        <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 text-dark">
                            <li>
                            Departmental selections (Press 1 for sales and 2 for support)

                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                            Call Groups to route your callers through to different teams

                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                            Capture every detail with call recording

                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                            Set open and close times to fit your business hours

                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                            Call Screening to help you identify your calls.

                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                            Custom prompts &amp; voices, plus a huge range of on-hold music

                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                            Missed call alerts &amp; Voicemail

                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                            Fully featured iOS &amp; Android apps
                            </li>
                        </ul>
                        <p className="pb-30">
                            <Link to={'/number-types'} className="btn btn-lg btn-cta">Get your number</Link>
                        </p>

                    </>
                    }
                    ImgURL="/images/24.jpg"
                    // RightImageClass="d-none d-sm-block"
            />

            <div className="full-width bg-dull pt-50 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto pl-xs-0 pr-xs-0">
                            <div className="row-flex flex-2-col">
                                <div className="col-xxs-12 col-xs-6 text-start bg-light brad-10 mb-20 pl-30 pr-30 pt-30 pb-30">
                                    <h5 className="text-500">Appear professional</h5>
                                    <p className="text-dark pt-10">
                                        A standard landline just isn’t up to the job if you run a business. Adding a layer of professionalism to your telephony is quick, easy, low cost and the return on investment is huge. Get taken seriously and upgrade your telecoms.
                                    </p>
                                </div>

                                <div className="col-xxs-12 col-xs-6 text-start bg-light brad-10 mb-20 pl-30 pr-30 pt-30 pb-30">
                                    <h5 className="text-500">Make life easier</h5>
                                    <p className="text-dark pt-10">
                                        Easily manage your entire telecoms system from our app or browser. React immediately to changes in your business and see your call stats in an instant. Perfect for business continuity and disaster recovery.
                                    </p>
                                </div>

                                <div className="col-xxs-12 col-md-6 text-start bg-light brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
                                    <h5 className="text-500">Stay ahead of the game</h5>
                                    <p className="text-dark pt-10">
                                        Inbound sales and customer service calls are critically important and ensuring that all your calls are answered and your callers also get a great telephone experience is fundamental to the success of your business.
                                    </p>
                                </div>

                                <div className="col-xxs-12 col-md-6 text-start bg-light brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
                                    <h5 className="text-500">Increase profits</h5>
                                    <p className="text-dark pt-10">
                                        The cost of a missed call is more than you think, if you don’t answer the phone, you can bet your competition will. Improve your audio branding and ensure you never miss a call.
                                    </p>
                                </div>

                                <div className="col-xxs-12 col-md-6 text-start bg-light brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
                                    <h5 className="text-500">Safe &amp; secure</h5>
                                    <p className="text-dark pt-10">
                                        We keep up with the latest technical developments. We look after some of the biggest businesses in the UK so our team constantly update and refine our technical infrastructure so we can deliver a robust and highly secure service to all of our customers.
                                    </p>
                                </div>

                                <div className="col-xxs-12 col-md-6 text-start bg-light brad-10 mb-20 w-46 pl-30 pr-30 pt-30 pb-30">
                                    <h5 className="text-500">No hidden charges</h5>
                                    <p className="text-dark pt-10">
                                        We’re 100% upfront about our prices &amp; billing. Unlike a lot of other providers on the market who promise the world and charge you an arm and a leg, we always tell it to you straight. For more, see our full list of UK and International call rates, and Terms and Conditions.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 text-center pb-30 pt-30">
                                    <Link to={'/get-started'} className="btn btn-lg btn-cta">Create account</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ImageLeftFullWidth
                MainContentClass="pt-60 pb-60 pt-xs-0 pb-xs-30 bg-light"
                bgImgColor="#fcd64f"
                bgContentColor="#fcd64f !important"
                TextCopy={
                    <>
                        <h2 className="text-dark text-500 mb-30 pt-0 h3 mt-0 pt-xs-40">
                            Can I keep my number?
                        </h2>
                         <p className="text-400 text-dark">
                            If you’re attached to your existing phone number then just bring it with you. We’re able to transfer or “port” the majority of UK phone numbers over to our platform quite easily.
                        </p>
                        <p className="text-400 pt-20 text-dark">
                            VoIP service providers can transfer your existing landline phone number or your virtual phone number. A virtual service provider can also port numbers in from other VoIP providers or from standard landline providers. So as long as you get a porting agreement number a virtual phone service provider will allow you to bring your number with you.
                        </p>

                        <h3 className="text-dark text-500 mb-30 pt-40">Do I need a phone number based on my location?</h3>
                        <p className="text-400 text-dark">
                            No, regardless of your area code, you can get a local number without having to be in that location. callers are passed forward to a phone, a group of users, a central company number or anywhere in the world.
                        </p>
                        <p className="text-400 pt-20 text-dark">
                            There are several numbers to choose from and unlike personal phone lines, a business phone can have different virtual number types and several different phone numbers.
                        </p>
                    </>
                    }
                    ImgURL="/images/25.jpg"
                    RightImageClass="d-none d-sm-block"
            />

            <div className="full-width bg-dull pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-900px center-block bg-light brad-30 p-60 pl-xs-20 pr-xs-20 pt-xs-30 pb-xs-30">
                                <h3 className="text-500 mb-30 mt-0 pt-xs-40 text-dark">
                                    How do I receive inbound calls using a virtual phone number?
                                </h3>
                                <p className="text-400 text-dark">
                                    Receiving a business call on a virtual telephone number is easy. The virtual business phone system uses simple "call forwarding" to forward calls to any device or handset you like.
                                </p>
                                <p className="text-400 pt-20 text-dark">
                                    Typically you can receive phone calls on an existing number such as a landline or personal mobile number but this can also be a VoIP enabled mobile phone app, softphone or VoIP enabled handset. The important thing is that calls can be transferred to multiple destinations in varying different ways.
                                </p>
                                <p className="text-400 pt-20 text-dark">
                                    The system uses intelligent call routing and "auto-attendant" to divert calls to a "team" or group of destinations simultaneously, this is great for sales and customers service calls as this minimises the call wait time and reduces missed calls.
                                </p>
                                <p className="text-400 pt-20 text-dark">
                                    Calls can also be answered sequentially. for example, the call may first be diverted to an office phone number for a period of 30 seconds only and if no one answers, the system calls a mobile and so on until the call is answered.
                                </p>
                                <p className="text-400 pt-20 text-dark">
                                    Another feature of a virtual landline is "time-of-day-routing". With time-of-day-routing, you can stipulate the call pattern and call destinations based on the time of the day or the day of the week.
                                </p>
                                <p className="text-400 pt-20 text-dark">
                                    These two features alone give an amazing amount of control and can transform the way your business answers calls.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ImageRightFullWidth
                MainContentClass="pt-60 pb-60 pt-xs-0 pb-xs-30 bg-light"
                TextCopy={
                    <>
                        <h2 className="text-500 mb-30 mt-0 pt-xs-40 text-dark h3">
                        How can I get a virtual number?
                        </h2>
                         <p className="text-400 text-dark">
                             Getting a virtual phone number is easy. Our virtual number phone system comes with over 40 free features and you can choose from any of the local numbers we have on offer. All of our numbers come with:
                        </p>
                        <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 text-dark">
                            <li>
                                A low per month cost
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                Cloud-based online management access
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                5-star customer service and support
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                Low-cost call forwarding to your mobile number
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                A free mobile management app
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                A free mobile calling app
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                Unlimited calls options
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                Unlimited users
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                Low call rates from around the world
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                Free seven day trial of our call answering service
                            </li>
                            <li className="pb-xs-10 pt-xs-10 brad-5">
                                Access to optional features such as call recording &amp; call filtering
                            </li>
                        </ul>
                        <p className="text-400 pt-20 text-dark">
                        <a href="/features" className="text-cta underline">Click here</a> for a full list of our free features and our premium features.
                        </p>
                        <p className="text-400 pt-20 text-dark">
                        You can get started from as little as £5 per month.
                        </p>
                        <p className="text-400 pt-20 text-dark">
                        Just call our customer support team today on <a href="tel:0203 189 1213" className="text-cta underline">0203 189 1213</a> to get started.
                        </p>
                    </>
                    }
                    ImgURL="/images/23.jpg"
                    // RightImageClass="d-none d-sm-block"
            />

            <div className="full-width bg-darker pt-100 pb-100 pb-xs-50 pt-xs-50">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 pr-60 pr-25 pr-xs-0">
                            <h4 className="text-500 mb-30 mt-0 text-lightest">
                                Are virtual numbers just for business?
                            </h4>
                            <p className="text-400 text-lightest">
                                Virtual numbers are predominantly used as a business phone solution or as a "business phone system". With powerful <Link to={'/features/'} className="text-cta">call management features</Link> and customer-focused call routing, i&apos;s no wonder this type of phone system is so popular with businesses.
                            </p>
                            <p className="text-400 pt-20 text-lightest">
                                Virtual numbers come with online management portals where businesses can access their call forwarding settings, add users or make changes to their team in an instant.
                            </p>
                            <p className="text-400 pt-20 text-lightest">
                                The system uses intelligent call routing and "auto-attendant" to divert calls to a "team" or group of destinations simultaneously, this is great for sales and customers service calls as this minimises the call wait time and reduces missed calls.
                            </p>
                            <p className="text-400 pt-20 text-lightest">
                                Being cloud-based makes online changes not only easy but the software is kept updated at all times. You can even make changes to your virtual numbers from a <Link to={'app'} className="text-cta">mobile app</Link>, this gives you the power to change how you receive calls on the fly.
                            </p>
                        </div>
                        <div className="col-sm-6 pl-60 pl-25 pl-xs-15">
                            <h4 className="text-500 mb-30 mt-0 pt-xs-40 text-lightest">Can I use my virtual number anywhere in the world?</h4>

                            <p className="text-400 text-lightest">
                                With virtual numbers, you can pretty much take your phone system anywhere. With the help of a mobile app, you can make and receive calls from all but 20 counties, globally.
                            </p>
                            <p className="text-400 pt-20 text-lightest">
                                Some countries such as China, the UAE and Israel prohibit the use of VoIP due to either a "security" or "privacy" risk in places where the media and communications are monitored/restricted.
                                Check the latest global VoIP restrictions on <a className="text-lightest" href="https://en.wikipedia.org/wiki/Voice_over_IP">Wikipedia</a>. So apart from these countries, you&apos;re free to make or receive calls from anywhere
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light pt-100 pb-100 pb-xs-50 pt-xs-50">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 pr-60 pr-25 pr-xs-0">
                            <h4 className="text-500">Your business calls in safe hands. Guaranteed.</h4>
                            <p className="text-400 pt-10">
                                Our virtual phone numbers run on a full, dual-redundant service at Telehouse North.
                            </p>
                            <p className="mb-30">
                                Telehouse North is the primary home of the London Internet Exchange and is plugged into the backbone of the UK internet network.
                            </p>
                        </div>
                        <div className="col-sm-6 pl-60 pl-25 pl-xs-15">
                            <h4 className="text-500">Stress-free virtual phone numbers</h4>
                            <p className="text-400 pt-10">
                                Virtual phone numbers are quick to set up, easy to manage and can really enhance your business. If you&apos;re not using a virtual phone number for your business, then you&apos;re missing out.
                            </p>
                            <p className="mb-30">
                                We've put together some of the most beneficial aspects of virtual numbers below, together with answers to our most frequently asked questions.
                            </p>
                        </div>
                        <div className="col-sm-12 text-center bt-2 mt-50 pt-40">
                                {isGeoOffer ?
                                    <>
                                        <h3 className="text-500">
                                            Get your virtual number for <br className="d-none d-sm-block" /><span className="text-cta">only {(NumbersGlobalOfferPrice < 1 ? `${(NumbersGlobalOfferPrice % 1).toFixed(2).substring(2)}p` : `£${NumbersGlobalOfferPrice}`)} for {numberOfferDuration === 1 ? 'one month' : numberOfferDuration === 2 ? 'two months' : 'three months'}
                                            </span>
                                        </h3>
                                        <Link to={'/get-started'} className="btn btn-lg btn-cta mt-30">Get your {(NumbersGlobalOfferPrice < 1 ? `${(NumbersGlobalOfferPrice % 1).toFixed(2).substring(2)}p` : `£${NumbersGlobalOfferPrice}`)} number</Link>
                                    </>
                                    :
                                    <>
                                        <h3 className="text-500">
                                            Get your virtual number from just <GetProdPrice prefix="01" /> per month
                                        </h3>
                                        <Link to={'/get-started'} className="btn btn-lg btn-cta mt-30">Get your number</Link>
                                    </>
                                }

                        </div>
                    </div>
                </div>
            </div> */}

            <div className="full-width full-dull">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>
        </>
  }

  componentDidMount () {
    this.getCustomers()
  }

  getCustomers () {
    const url = 'api/Home/GetTotalCustomers'
    fetchHelper.getJson(url)
      .then(response => {
        if (response !== undefined) { this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state)
    }
  }
  // {
  //     login: fromHome.handleLogin
  // }
)(OutboundLandingPage)
