import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import HandsetOrders from '../../components/leadgen/HandsetOrders'
import LiveChatBlock from '../../components/LiveChatBlock'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import StatsBanner from '../../components/StatsBanner'
import { FanvilProdId, FanvilDongle, w73p, x7a } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'
import { Link } from 'react-router-dom'


class FanvilDonglePage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showHandsetOrder: false
    }
  }

  scrollTo (section) {
    scroller.scrollTo(`${section}`, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <title>Fanvil USB Wi-Fi Dongle</title>
                <body className="body-simple" />
                <style type="text/css">{`
				@media screen and (max-width: 1400px){
                    .bg-img {
                        background-position: 100% 100%;
                    }
                }
                @media screen and (max-width: 1100px) {
                    header h1.pt-60 {
                        padding-top:30px !important;
                    }
                }
                
                @media screen and (max-width: 1024px){
                    .bg-img {
                        background-image: none !important;
                    }
                }
				`}</style>
            </Helmet>

            <HandsetOrders showModal={this.state.showHandsetOrder} onHide={() => this.setState({ showHandsetOrder: false })} model={this.state.model} />
            <header style={{ backgroundImage: "url('/images/backgrounds/features-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Fanvil USB Wi-Fi Dongle</h1>
                <h2 className="subtitle">Quickly & Easily connect our Advanced VoIP Handset to your Wi-Fi network. Order yours today!</h2>
            </header>

            <div className="full-width bg-light pb-50 pt-50" id="deskphone">
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                        <div className="col-lg-6 pt-40 pt-md-0 float-sm-end float-none">
                            <img className="" src="/images/product-shots/fanvil-dongle.png" alt="Fanvil Wifi Dongle Wf20" style={{ maxWidth: '100%' }} />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="text-500">Fanvil USB WiFi Dongle</h2>

                            <p className="lead text-400 pt-0">
                              A compact powerhouse designed to seamlessly link your VoIP phones to wireless networks.
                            </p>
                            <p className="mb-30">
                              Introducing the Fanvil WF20 USB Wi-Fi Dongle: It's minimalistic yet efficient design ensures it fits perfectly within any office setup. Boasting a robust transmission speed of 150 Mbps, it promises not just connectivity, but a rapid, stable link. Dive into hassle-free plug-and-play functionality, and watch as it revolutionizes your communication, making accessing vital documents and collaborating smoother than ever before.
                            </p>

                            <div className="row">
								<div className="col-md-6 col-sm-12">
									<ul className="styled-list cta rounded text-400 pr-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0">
										<li>
											Compatible with our <Link to="/products/advanced-voip-phone">Advanced VoIP Handset</Link>
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
										Easy to use, just plug & play 
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
										Flexible & mobile 
										</li>
									</ul>
								</div>
								<div className="col-md-6 col-sm-12">
									<ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 pt-xs-0 pt-sm-0">
										<li className="pb-xs-10 pt-xs-10 brad-5">
										Uses very little power 
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
										High transmission rate to keep you reliably connected 
										</li>
										<li className="pb-xs-10 pt-xs-10 brad-5">
										Compliant with the IEEE802.11n, IEEE802.11b/g, and 802.11ac standards 
										</li>
									</ul>
								</div>
							</div>

                            <div className="plans pt-30">
                                <div className="plan">
                                    <span className="text-md"><span className="text-500">Buy Now </span> For <span className="text-cta text-500 text-lg"><GetProdPrice productId={FanvilDongle} hardware={true} /></span> <span className="text-italic">(+VAT)</span></span>
                                    <br />
                                    <span className="text-sm">Includes <span className="text-500"><span className="text-cta">FREE</span> delivery <span className="text-cta">+</span> Full UK Support</span></span>
                                </div>
                            </div>

                            <p className="pb-30 pt-30">
                                <Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.handleAddHandset(FanvilDongle)}>Order Now</Button>
                            </p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="full-width bg-dull pt-60">
                <div className="container">
                    <div className="row">
                        <div className="col text-center pt-30 pb-30">
                            <h3 className="text-500">Related Products</h3>
                            <p>All our phones come pre-configured. All you need to do is connect few cables and you&apos;re ready to <br className="d-none d-md-block"/>make and receive calls using your SwitchboardFREE numbers.</p>
                        </div>
                    </div>
                    <div className="row row-flex flex-3-col flex-sm-2-col flex-xs-grow">
                        <div className="col-xs-4 col-xxs-12 text-center mb-20">
                            <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                <img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                <h4 className="text-500 mb-5">Advanced VoIP Handset</h4>
                                <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(FanvilProdId)}>Order Now</Button>
                                <br/>
                                <a href="/products/advanced-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                            </div>
                        </div>
                        <div className="col-xs-4 col-xxs-12 text-center mb-20">
                            <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                <img src="/images/product-shots/ecom/W73P.jpg" alt="Cordless VoIP Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                <h4 className="text-500 mb-5">Cordless VoIP Handset</h4>
                                <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(w73p)}>Order Now</Button>
                                <br/>
                                <a href="/products/yealink-w73p-cordless-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                            </div>
                        </div>
                        <div className="col-xs-4 col-xxs-12 text-center mb-20">
                            <div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
                                <img src="/images/product-shots/ecom/X7A.jpg" alt="Fanvil X7A Android IP Video Phone" style={{ maxWidth: '100%', maxHeight: '270px' }}/>
                                <h4 className="text-500 mb-5">Android Touchscreen Phone</h4>
                                <p className="pt-10 pb-10">With <span className="text-500">FREE delivery</span></p>
                                <Button variant="cta" size="md" className="btn-xxs-block" onClick={() => this.handleAddHandset(x7a)}>Order Now</Button>
                                <br/>
                                <a href="/products/x7a-voip-phone" className="text-dark underline pt-20 text-400 d-inline-block">Find out more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LiveChatBlock style="secondary" title="Want to know more?" />

            <div className="full-width bg-light">
                <div className="container">
                    <CarouselWidget />
                </div>
            </div>

            <div className="full-width full-secondary p-20">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-center text-lightest mt-10 text-100 text-xl">
                            <StatsBanner />
                        </h3>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="text-500">SwitchboardFREE</h3>
                            <h6 className="text-400">The UK's favourite call management system</h6>
                            <p className="text-400">Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
  }

  componentDidMount () {
    this.getCustomers()
    this.getHardware()
  }

  getHardware () {
    const url = 'api/Purchase/GetHardware'
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data) {
          this.setState({ products: res.data })
        }
      }).catch(err => console.error(err))
  }

  handleAddHandset (prodId) {
    const product = this.state.products.filter(f => f.prodId === prodId)
    const tempData = [{
      qty: 1,
      ...product[0]
    }]
    localStorage.setItem('HardwareFirst', JSON.stringify(tempData))
    this.props.history.push('/get-started')
  }

  getCustomers () {
    const url = 'api/Home/GetTotalCustomers'
    fetchHelper.getJson(url)
      .then(response => {
        if (response !== undefined) { this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }
}
export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(FanvilDonglePage)
