import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import connect from 'react-redux/es/connect/connect'
import { withRouter } from 'react-router'
import { scroller } from 'react-scroll'
import { MultiNumberPrice, NumbersPremiumFilter } from '../../constants/Constants'
import LocalStorageHelper, { type LocalStorageItem } from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'
import { type SbfNumber } from '../../models/SignupTypes'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'
import * as getSignup from '../../store/Signup'
import DdiSelectorNew from '../purchase/DdiSelectorNew'

interface NumberLandingViewState {
  isOpen: boolean
  numbersPremiumFilter: NumbersPremiumFilter
  loadingNumbers: boolean
};

interface NumberLandingViewProps {
  handleBasketInit: () => void
  numberType: string
  h1: string
  h2: string
  handleAddToBasket: (e: any) => void
  history: any
  handleShowCallbackForm: () => void
}

class NumberLandingView extends Component<NumberLandingViewProps, NumberLandingViewState> {
  state: NumberLandingViewState = {
    isOpen: false,
    numbersPremiumFilter: NumbersPremiumFilter.all,
    loadingNumbers: false
  }

  componentDidMount () {
    window.dataLayer.push({
      numberType: `${this.props.numberType}`,
      event: 'viewProduct'
    })
  }

  scrollTo (el) {
    scroller.scrollTo(el, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -50
    })
  }

  render () {
    const basket = getEmptyBasket()
    const numbers = LocalStorageHelper.getItem<SbfNumber[]>('LandingPageNumberSelection')
    basket.numbers = numbers.value ?? []

    return (
			<>
				<div className="full-width bg-light pb-60 pt-0">
					<div className="container numbers-pages">
						<div className="row mt-100 mt-xs-30">
							<div className="col-md-6 col-sm-12 order-last text-center p-xs-30">
								<img src="/images/homepage-features-2.jpg" alt="" className="img-responsive mt-20 mt-xs-0" style={{ maxWidth: '600px' }} />
								<img src="/images/icons/trust-icons.png" alt="" className="img-responsive mt-20" style={{ maxWidth: '500px' }} />
							</div>
							<div className="col-md-6 col-sm-12 order-first">
								<h1 className="text-500 text-darker" id={this.props.h1 && `${this.props.h1.replace(/\s/g, "")}H1`}>{this.props.h1}</h1>
								<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-600px">
									{this.props.h2}
								</h2>

								<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
									<li className="text-md mb-10">
										<span className="icon icon-checkmark-circle text-success mr-10" />
										Make &amp; receive calls from any device using and displaying your {this.props.numberType === '01' ? '01 / 02' : this.props.numberType} number(s)
									</li>

									{this.props.numberType.startsWith('020')
									  ? <li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Help your business appear larger with a London presence
										</li>
									  : this.props.numberType === '01'
									    ? <li className="text-md mb-10">
												<span className="icon icon-checkmark-circle text-success mr-10" />
												Earn trust with a local town or city number
											</li>
									    : this.props.numberType === '03' ?
									    	<li className="text-md mb-10">
												<span className="icon icon-checkmark-circle text-success mr-10" />
												National 0333 numbers allow your customers to call you for free
											</li> :
											null
									}

									<li className="text-md mb-10">
										<span className="icon icon-checkmark-circle text-success mr-10" />
										Get instant activation & start using your {this.props.numberType === '01' ? '01 / 02' : this.props.numberType} number(s) in minutes
									</li>
									<li className="text-md mb-10">
										<span className="icon icon-checkmark-circle text-success mr-10" />
										Sign up without being tied to a long-term contract or needing expensive hardware
									</li>
								</ul>

								<div className="bg-dull brad-10 p-50 d-none d-md-block" style={{ backgroundColor: '#ffebeb' }}>
									<p><em>&ldquo;The product is excellent, with a lot of really useful functions to facilitate my company's communication, and the support service always exceeds my expectations.&rdquo;</em></p>

									<span className="text-500 pt-10 d-inline-block">
										Rod <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
									</span>
									<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
								</div>

								<Button variant="cta" className="btn btn-cta p-15 pl-50 pr-50 text-md ml-0 m-5 mt-20 mr-xxs-0 ml-xxs-0 btn-xxs-block" onClick={() => { this.scrollTo('numbers') }}>Choose your numbers</Button>
								<Button variant="outline" className="btn btn-outline p-15 pl-50 pr-50 text-md m-5 mr-xs-0 mt-20 ml-xxs-0 btn-xxs-block" onClick={() => { this.props.handleShowCallbackForm() }}>Get a callback</Button>

							</div>
							<div className="col-12 d-block d-md-none order-last mt-20">
								<div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#ffebeb' }}>
									<p><em>&ldquo;The product is excellent, with a lot of really useful functions to facilitate my company's communication, and the support service always exceeds my expectations.&rdquo;</em></p>

									<span className="text-500 pt-10 d-inline-block">
										Rod <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
									</span>
									<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="mt-15" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="numbers" className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40" style={{ backgroundColor: '#d6e8ef' }}>
					<div className="container numbers-pages">
						<div className="number-page">
							<div className="text-center">
								<h2 className="text-500 text-darker" id="PerfectHeader">
									Select your perfect {this.props.numberType === '03' ? '0333' : this.props.numberType && this.props.numberType === '01' ? 'local' : this.props.numberType} numbers
								</h2>
								<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light">
									Add multiple numbers for an additional &pound;{MultiNumberPrice} per number,<br className="d-none d-md-block" />
									or simply choose a single number, included free with your plan <em>(Which you'll choose later)</em>.
								</h2>
								<h5 className="p-30 pt-0 text-400"></h5>
								<div className="row">
									<div
										className="col-lg-12 col-md-10 mx-auto">
										<DdiSelectorNew
											numberType={this.props.numberType}
											isSignup={false}
											currentBasket={basket}
											hideShowAll={false}
											isModal={false}
											hideLoadMore={false}
											isSimple={false}
											hidestars={false}
											isLoggedIn={false}
											isEditing={false}
											menuOpen={false}
											citysOnly={false}
											handleUpdateBasket={(_, sbfNumber: SbfNumber) => {
											  const item: LocalStorageItem<SbfNumber[]> = LocalStorageHelper.getItem<SbfNumber[]>('LandingPageNumberSelection')
											  let sbfNumbers = item.value ?? []
											  // add the number to the array if it doesn't already exist, otherwise remove it
												if (sbfNumbers.find(number => number.number === sbfNumber.number) !== undefined) {
											    sbfNumbers = sbfNumbers.filter(number => number.number !== sbfNumber.number)
											  } else {
											    sbfNumbers.push(sbfNumber)
												}

											  LocalStorageHelper.setItem<SbfNumber[]>('LandingPageNumberSelection', sbfNumbers)
											  this.setState({})
											}} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
    )
  }
}

export default withRouter(
  connect(
    state => {
      return {
        isLoggedIn: isLoggedIn(state),
        currentBasket: state.signup.packageInfo.currentBasket
      }
    },
    {
      handleAddToBasket: getSignup.handleAddToBasket,
      handleRemoveFromBasket: getSignup.handleRemoveFromBasket,
      handleBasketInit: getSignup.handleBasketInit,
      handleShowCallbackForm
    }
  )(NumberLandingView)
)
