import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'
import AnnouncePositionSectionHelperDialog from './InstructionHelperDialogs/AnnouncePositionSectionHelperDialog'

const AnnouncePositionSection = props => (
    <>
        <div className="row bg-dullest p-30 zoom">
            <div className="col-md-5">
                <h5 className="text-secondary text-500 text-lg">Announce Position
                </h5>
                <p className="text-400">
                    If your lines are busy, let your callers know where they are in the queue to help manage expectations. As you answer calls,
                    <br className="d-none d-lg-block" />
                    this number will reduce, and your callers will be updated with their new queue position.
                    <br /><a onClick={props.handleShowAnnouncePositionHelperInstruction} className="underline" id="AnnouncePositionMoreInfoLink">More info</a>
                </p>
            </div>
            <div className="col-md-7 mt-10">
                <div className="text-center">
                    <p>Turn ON/OFF:</p>
                    <AnnouncePositionSectionHelperDialog />

                    <Toggle
                        className="mt-0"
                        onClick={e => props.handleAnnouncePositionChange(e)}
                        on={<span>ON</span>}
                        off={<span>OFF</span>}
                        size="sm"
                        offstyle="default"
                        onstyle="success"
                        width="null"
                        height="null"
                        active={props.isEnabled}
                        id="AnnouncePositionToggle"
                    />

                </div>
            </div>
        </div>
    </>
)

const mapStateToProps = state => ({
  isEnabled: state.configuration.configuration.isAnnouncePositionEnabled
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncePositionSection)

// export default connect(
//     state => state.configuration,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(IntroductionSection);
