import React from 'react'
import { Button } from 'react-bootstrap'
import { type RouteComponentProps } from 'react-router-dom'

interface SideBarGetStartedProps {
  mainText: string
  mainColouredText: string
  smallText: string
  history: RouteComponentProps['history']
}

const SideBarGetStartedCta = (props: SideBarGetStartedProps) => {
  const handleSignup = () => {
    localStorage.setItem('landingPage', 'true')
    props.history.push('/get-started')
  }

  return (
        <div className="brad-10 bg-primary bg-gradient p-40 text-center pb-0 sticky-t-100">
            <span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
                {props.smallText}
            </span>
            <p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20 pt-20" style={{ lineHeight: '1em' }}>
                <span className="text-primary-light"> {props.mainColouredText} </span> {props.mainText}
            </p>
            <p className="text-md text-400 mt-30 mb-0">
                From as little as &pound;5 per month.
            </p>

            <span className="d-inline-block col-12 mt-20 mb-50">
                <Button variant="cta" className="btn btn-lightest btn-outline p-15 pl-50 pr-50 text-md mb-xs-20" onClick={() => { handleSignup() }} id="GetStartedButton">Get started</Button>
                <p className="text-sm mt-20">Or, call <a href="tel:02031891213" className="text-lightest">0203 189 1213</a></p>
            </span>

            <img src="/images/voip-feature-img.png" alt="VoIP Phone System" className="img-responsive" />
        </div>
  )
}

export default SideBarGetStartedCta
