import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import FeaturesList from '../../components/Competitors/FeaturesList'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStartedCta from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface NumberSupermarketProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const NumberSupermarket = (props: NumberSupermarketProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Number Supermarket vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/1-competitor-bg.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Number Supermarket vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Number Supermarket vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Introduction</h2>
                            <p>Number Supermarket offer VoIP and Hosted numbers across three main packages: Standard, Advanced and Professional.</p>
                            <p>Before I touch on the service, I personally found the information on their web site quite limited. This was compounded with a lack of proactive live chat support which made my fact-finding mission quite a difficult one.</p>
                            <p>I can imagine that these key issues coupled with a very low review count would dissuade many potential customers from committing to a comparably long 12-month contract.</p>
                            <p>However, my biggest issue with Number Supermarket, if I were being objective is the complete lack of number choice.</p>
                            <p>Most companies in this sector offer a full National range of phone numbers. Number Supermarket only have 16, local ranges and SwitchboardFREE currently offer 406 local phone code areas in the UK and wales.</p>
                            <p>This complete lack of number choice would worry me if I was a potential new customer as this would severely limit my ability to choose a number that suits my needs or my geographical location.</p>
                            <p>Anyway, Let’s dive into the packages and see what the offer is:</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Package Overview</h2>
                            <div className="b-1 p-30 bg-light box-shadow brad-30 mb-20">
                                <p className="text-500 text-lg text-dark">Number Supermarket Standard Package</p>
                                <p>The Standard package costs &pound;12.99 per month and includes 250 minutes to landlines and 50 minutes to mobiles.</p>
                                <p>The set of features for this plan is rather modest, as it involves just the standard call routing, a voicemail greeting, voicemail to email and fax to email services.</p>
                            </div>

                            <div className="b-1 p-30 bg-light box-shadow brad-30 mb-20">
                                <p className="text-500 text-lg text-dark">Number Supermarket Advanced Package</p>
                                <p>The monthly fee for the Advanced package is &pound;18.99. This has 500 inclusive landline and 200 inclusive mobile minutes.</p>
                                <p>Among the features, the customer can benefit from an out-of-hours messaging service, the call screening function, as well as number display and missed call alerts.</p>
                            </div>

                            <div className="b-1 p-30 bg-light box-shadow brad-30 mb-20">
                                <p className="text-500 text-lg text-dark">Number Supermarket Professional Package</p>
                                <p>The Professional package adds more features, such as a multi-level IVR, unlimited call queue and a call recording service.</p>
                                <p>The tariff includes 1,000 landline minutes and 500 mobile minutes and costs &pound;29.99 per month</p>
                            </div>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Call Alerts, Great Service and Multi level IVR</h2>
                            <p>Although Number Supermarket have an impressive set of features, as you can see, these are heavily restricted based on the package you choose.</p>
                            <p>If you’re on the standard package you’re only able to access a few features on your account, but you’re still paying &pound;12.99 a month and committed to a minimum of 12 months.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">SwitchboardFREE Inbound packages</h2>
                            <p>In comparison, SwitchboardFREE offer unlimited inbound calls to a softphone or app for only &pound;5 a month and all of SwitchboardFREE’s inbound packages come with All these free features:</p>

                            <FeaturesList />

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Diverting to a mobile or landline</h2>
                            <p>If you wanted to divert a SwitchboardFREE number to a landline or mobile, it’s &pound;4 for 1,000 minutes, &pound;9 for 2,500 minutes and &pound;19 for 5,500 minutes.</p>
                            <p>This is around half the cost of Number Supermarket and SwitchboardFREE do not restrict features based on package cost, even at a substantially cheaper rate.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Summary</h2>
                            <p>Another major constraint when it comes to choosing Number Supermarket is the contract length. 12 months is a long time to commit to a service provider and could hamper your business if you make the wrong decision</p>
                            <p>That’s why SwitchboardFREE only operate a rolling 30-day contract, so even if it’s not for you, you’re able to switch provider with very little disruption to your business.</p>
                            <p>It’s also worth noting that SwitchboardFREE offer a full inbound and outbound, unlimited VoIP service for only &pound;16 per month, which covers all eventualities at a very low monthly cost and comes with all the free features as mentioned.</p>
                            <p>If you’re considering using Number Supermarket for your VoIP service then please contact the friendly Welcome team at SwitchboardFREE below who will more than happy to give you a like for like comparison.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Number Supermarkets current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.numbersupermarket.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStartedCta mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(NumberSupermarket)
