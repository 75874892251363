import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { PromptTypes } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'
import PromptAudioPlayer from '../PromptAudioPlayer'
import CallWhisperInstructionHelperDialog from './InstructionHelperDialogs/CallWhisperInstructionHelperDialog'

const CallScreeningSection = (props) => (

    <>
        <div className="row bg-dullest p-30 zoom">
            <div className="col-md-5 pb-sm-10">
                <h5 className="text-secondary text-500 text-lg">
                    Call Screening
                </h5>
                <p className="text-400">
                    Call screening is a very short message that helps you identify the type of call you receive, for example, &quot;<i>This is a call for Accounts</i>&quot;. You can also activate this feature to ensure
                    that calls are not answered by your own phone’s voicemail. <a onClick={props.handleShowCallWhisperHelperInstruction} className="underline" id="CallScreeningMoreInfoLink">More info</a>
                </p>
            </div>
            <div className="col-md-7 mt-10 pl-sm-0 pr-sm-0">
                <CallWhisperInstructionHelperDialog
                    showCallWhisperInstructionHelperDialog={props.callWhisperInstructionHelperDialogDisplayed} />
                <div className='row'>
                    <div className="col-md-4 text-sm-center pb-sm-30">
                        <p>Turn ON/OFF:</p>

                        <Toggle
                            className="mt-0"
                            onClick={e => props.handleCallScreeningEnabledChange(e)}
                            on={<span>ON</span>}
                            off={<span>OFF</span>}
                            size="sm"
                            offstyle="default"
                            onstyle="success"
                            width="null"
                            height="null"
                            active={props.isWhisperEnabled}
                            id="CallScreeningToggle"
                        />
                        <span className="slider-on round"></span>

                    </div>
                    <div className="col-md-8">
                        <p>Select message:</p>
                        <Select
                            id="whisperSelect"
                            className="form-control select-menu"
                            classNamePrefix="custom"
                            onChange={props.handleWhisperChange}
                            options={props.prompts}
                            simpleValue
                            name="selectedWhisper"
                            value={props.whisperPrompt !== undefined ? { label: props.whisperPrompt.promptDescription, value: props.whisperPrompt.promptId } : undefined}
                            searchable={false}
                            clearable={false}
                            isLoading={props.isLoading}
                        />
                        <PromptAudioPlayer promptId={props.whisperPrompt !== undefined ? props.whisperPrompt.promptId : undefined} voice={props.voice} promptType={PromptTypes.standardWhisper} />
                    </div>
                </div>
            </div>
        </div>
    </>
)

export default connect(
  state => ({
    callWhisperInstructionHelperDialogDisplayed: state.configuration.dialogVisibility.callWhisperInstructionHelperDialogDisplayed,
    isWhisperEnabled: state.configuration.configuration.isWhisperEnabled,
    whisperPrompt: state.configuration.configuration.whisperPrompt,
    prompts: state.configuration.prompts.whisperPrompts,
    isLoading: state.configuration.loading.whisperPrompts,
    voice: state.configuration.configuration.voicePrompt.voicePromptId
  }),
  dispatch => bindActionCreators(configurationActions, dispatch)
)(CallScreeningSection)
