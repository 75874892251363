import moment from 'moment'
import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { scroller } from 'react-scroll'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import PlansAndNumbersDropdown from '../../components/PlansAndNumbersDropdown'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { NumbersPremiumFilter } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'
import { handleShowCallbackForm } from '../../store/SBF'
import * as getSignup from '../../store/Signup'

class AreaCode extends React.Component {
  constructor (props) {
    super(props)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow () {
    this.setState({ show: true })
  }

  state = {
    prefix: this.props.match.params.prefix.substring(0, 5),
    location: this.props.match.params.location,
    loadingNumbers: true,
    numbersPremiumFilter: NumbersPremiumFilter.all
  }

  getCustomers () {
    const url = 'api/Home/GetTotalCustomers'
    fetchHelper.getJson(url)
      .then(response => {
        if (response !== undefined) { this.setState({ totalCustomers: response.data.toLocaleString(navigator.language) }) }
      })
      .catch(error => {
        console.error(error)
      })
  }

  scrollTo (el) {
    scroller.scrollTo(el, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -50
    })
  }

  render () {
    const prefix = this.state.prefix

    const findImage = (path) => {
      const http = new XMLHttpRequest()
      http.open('HEAD', path, false)
      http.send()
      return http.status !== 404
    }

    const imageExists = findImage(`/images/backgrounds/locations/${this.state.prefix}.jpg`)
    const basket = getEmptyBasket()
    const numbers = LocalStorageHelper.getItem('LandingPageNumberSelection')
    basket.numbers = numbers.value ?? []

    return <>
			<Helmet>
				<title>{`Get a Virtual ${prefix} ${this.state.location.replace('_', ' ')} Phone Number`}</title>
				<meta name="description" content={`Buy an ${this.state.prefix} ${this.state.location.replace('_', ' ')} virtual phone number for your business. Join over ${this.state.totalCustomers} customers and enjoy over 40 free features with SwitchboardFREE.`} />
				<script type="application/ld+json">{`

                            {
                            "@context": "https://schema.org/", 
                            "@type": "Product", 
                            "name": "${this.state.prefix} ${this.state.location.replace('_', ' ')} number",
                            "image": "https://www.switchboardfree.co.uk/images/backgrounds/locations/${this.state.prefix}.jpg",
                            "description": "The ${this.state.prefix} area code is for ${this.state.location.replace('_', ' ')} and the surrounding area.",
                            "brand": "SwitchboardFREE",
                            "offers": {
                                "@type": "Offer",
                                "url": "https://www.switchboardfree.co.uk/area-code/${this.state.prefix}-${this.state.location.replace('_', ' ').toLowerCase()}-numbers",
                                "priceCurrency": "GBP",
                                "price": "5",
                                "priceValidUntil": "${moment().endOf('month').format('YYYY-MM-DD')}",
                                "availability": "https://schema.org/InStock",
                                "itemCondition": "https://schema.org/NewCondition"
                            }
                        }
                    
                    `}</script>

				<style type="text/css">{`
                .side-box {
                    background-image:url(/images/smile-receptionist.png);
                    background-position:136% 100%;
                    background-size:400px;
                    background-repeat:no-repeat;
                    background-color:#ff9314;
                    min-height:550px;
                }
                .header .navbar.navbar-simple {
                    min-height: 75px;
                }
                @media screen and (max-width:1170px) {
                    .side-box {
                        background-image:none;
                        min-height:unset;
                    }
                }
                @media screen and (max-width:992px) {
                    .form-control.h-60px {
                        height:45px;
                    }
                }
                @media screen and (max-height:800px) {
                    .form-control.h-60px {
                        height:45px;
                    }
                }
                @media screen and (max-width:767px) {
                    .side-box p.text-lg{
                        font-size:21px !important;
                    }
                    .col-xs-6 .icon-block {
                        width: 130px;
                        height: 130px;
                        padding: 8px 0;
                      }
                }

                    `}</style>
			</Helmet>
			<ScrollToTopOnMount />
			<DisplayMainFooter />
			<DisplayMainHeader />
			<header style={{
			  backgroundImage:
					imageExists
					  ? `url('/images/backgrounds/locations/${this.state.prefix}.jpg')`
					  :						"url('/images/backgrounds/locations/local-numbers.jpg')"
			}} className="full-width full-hero-small with-text" >
				<h1 className="text-capatalize">{this.state.prefix} {this.state.location.replace('_', ' ')} Virtual Numbers</h1>
			</header>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container numbers-pages">
					<div className="row mt-100">
						<div className="col-md-6 col-sm-12 order-last text-center p-xs-30">
							<img src="/images/homepage-features-2.jpg" alt="" className="img-responsive mt-20 mt-xs-0" style={{ maxWidth: '600px' }} />
							<img src="/images/icons/trust-icons.png" alt="" className="img-responsive mt-20" style={{ maxWidth: '500px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-first">
							<h1 className="text-500 text-darker text-capatalize">
								{this.state.prefix} {this.state.location.replace('_', ' ')} Numbers
							</h1>
							<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-600px">
								Instantly make &amp; receive calls with an {this.state.prefix} <span className="text-capatalize">{this.state.location.replace('_', ' ')}</span> number
							</h2>

							<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
								<li className="text-md mb-10">
									<span className="icon icon-checkmark-circle text-success mr-10" />
									Instant setup. Start using your {this.state.prefix} <span className="text-capatalize">{this.state.location.replace('_', ' ')}</span> number in minutes.
								</li>
								<li className="text-md mb-10">
									<span className="icon icon-checkmark-circle text-success mr-10" />
									Make and receive calls from any device using your {this.state.prefix} number(s)
								</li>
								<li className="text-md mb-10">
									<span className="icon icon-checkmark-circle text-success mr-10" />
									Gain more trust with a local Town or City number
								</li>
								<li className="text-md mb-10">
									<span className="icon icon-checkmark-circle text-success mr-10" />
									No long-term contracts & no hardware required
								</li>
							</ul>

							<div className="bg-dull brad-10 p-50 d-none d-md-block" style={{ backgroundColor: '#ffebeb' }}>
								<p><em>&ldquo;The product is excellent, with a lot of really useful functions to facilitate my company's communication, and the support service always exceeds my expectations.&rdquo;</em></p>

								<span className="text-500 pt-10 d-inline-block">
									Rod <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
								</span>
								<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
							</div>

							<Button id="ChooseYourNumbersButton" variant="cta" className="btn btn-cta p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.scrollTo('numbers')}>Choose your numbers</Button>
							<Button id="GetACallbackButton" variant="outline" className="btn btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.props.handleShowCallbackForm()}>Get a callback</Button>

						</div>
						<div className="col-12 d-block d-md-none order-last mt-20">
							<div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#ffebeb' }}>
								<p><em>&ldquo;The product is excellent, with a lot of really useful functions to facilitate my company's communication, and the support service always exceeds my expectations.&rdquo;</em></p>

								<span className="text-500 pt-10 d-inline-block">
									Rod <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
								</span>
								<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="mt-15" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40" style={{ backgroundColor: '#d6e8ef' }}>
				<div className="container numbers-pages">
					<div className="row">
						<div className="col-sm-6">
							<h5 className="text-500">What are Virtual Numbers?</h5>
							<p>
								A Virtual Number geographical number, also referred to as a local number,
								is a cloud-based number with a local call prefix that can be called at a local or national rate.
								Create a presence in <span className="text-capatalize">{this.state.location.replace('_', ' ')}</span> by choosing a “local” Virtual Number starting {this.state.prefix} today.
							</p>

							<h5 className="text-500 mt-60">Is it free to call an {this.state.prefix} Number?</h5>
							<p>
								The cost to call any 01, 02, or 03 geographic numbers are all charged at local-rate.
								They are either very low call costs when no phone plan is in place, or often free to call when using inclusive minutes on a landline or mobile phone package.
							</p>

							<h5 className="text-500 mt-60">How do I get a {this.state.prefix} Virtual Number for my business?</h5>
							<p>
								With over 2 million geographic phone numbers to choose for your business, Telecoms World has the perfect range of memorable 01/02 Numbers
								to promote your business in any town or city across the UK from 99p per month with monthly inclusive minutes. Calls can be routed to any UK landline or mobile phone number.
							</p>
							<Button id="ChooseYourNumbersButton" variant="cta" className="btn btn-cta p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.scrollTo('numbers')}>Choose your numbers</Button>
						</div>
						<div className="col-sm-6 d-none d-xs-block">
							<div className="brad-10 text-lightest p-30 mt-10 side-box">
								<div className="row">
									<div className="col-md-12">
										<p className="text-lg text-italic text-400 pt-10 pt-xs-10">&quot;These guys really know how to look after customers. The VoIP service they have provided me with for both my businesses is flawless and the support is great. Highly recommended company.&quot;</p>
										<p className="pt-10">
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-3"></span>
											<span className="icon icon-star-full text-lightest mr-10"></span>
											<span>Nigel</span>
											<span className="bg-dark brad-20 text-lightest pt-0 pb-3 pl-10 pr-10 ml-10">
												<span className="img-circle text-lightest d-inline-block mr-5" style={{ width: '15px', height: '15px', backgroundColor: '#33b995', verticalAlign: 'middle' }}>
													<span className="icon icon-checkmark4" style={{ fontSize: '9px', padding: '4px 3px 3px 2px', display: 'block' }} />
												</span>
												<span className="text-500 text-xs">Verified user</span>
											</span>
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-6 col-md-12 text-md-center">
										<p className="text-md text-500 mt-40 mb-20">Want to know more?</p>
										<p>
											Give a call on <a className="text-500 underline-hover text-lightest" href="tel:02031891213">0203 189 1213</a> <br className="d-none d-lg-block" />or request a callback
										</p>
										<Button variant="light" size="lg" className="text-500 text-cta mb-20 mt-10" style={{ fontSize: '16px' }} onClick={() => this.props.handleShowCallbackForm()}>
											Speak to an expert
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-70 pt-70 number-page" id="numbers">
				<div className="container numbers-pages pl-sm-0 pr-sm-0">
					<div className="row">
						<div className="col-md-12 text-center pb-60">
							<h3 className="text-500">
								Choose the perfect Virtual Phone Number for your business...
							</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<PlansAndNumbersDropdown
								prefix={this.state.prefix && this.state.prefix.length <= 4 ? this.state.prefix : '01'}
								ddiLid={this.state.ddiLid?.value}
								defaultArea={this.state.prefix}
								handleChangeNumberType={e => this.setState({ prefix: e })}
								handleChangeAreaCode={e => this.setState({ ddiLid: e })}
								hidePlans={true}
								preventDefaultSelection={true}

								WrapperClassName="mb-40"
								LabelsClassName="text-xs text-dark text-400"

								NumberButtonClassName={'text-start bg-light pl-25 pr-25 ml-5 b-0 inbound-only'}
								NumberNameClassName="text-dark text-400"
								NumbersResultsDropdownClassName="bg-light ml-5 box-shadow"
								NumberNameClassNameSelected="text-cta text-500"

								AreaCodeButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
								AreaCodeNameClassName="text-dark text-400"
								AreaCodeResultsDropdownClassName="bg-light box-shadow"
								AreaCodeNameClassNameSelected="text-cta text-500"
							/>

							<DdiSelectorNew
								numberType={this.state.prefix}
								isSignup={false}
								currentBasket={basket}
								hideShowAll={false}
								isModal={false}
								hideLoadMore={false}
								hideRegions={true}
								hidestars={false}
								isLoggedIn={false}
								isEditing={false}
								menuOpen={false}
								citysOnly={false}
								ddiLid={this.state.ddiLid?.value}
								handleUpdateBasket={(_, sbfNumber) => {
								  // let item: LocalStorageItem<SbfNumber[]> = LocalStorageHelper.getItem < SbfNumber[] > ("LandingPageNumberSelection");
								  const item = LocalStorageHelper.getItem('LandingPageNumberSelection')
								  let sbfNumbers = item.value ?? []
								  // add the number to the array if it doesn't already exist, otherwise remove it
								  if (sbfNumbers.find(number => number.number === sbfNumber.number)) {
								    sbfNumbers = sbfNumbers.filter(number => number.number !== sbfNumber.number)
								  } else {
								    sbfNumbers.push(sbfNumber)
								  }
								  LocalStorageHelper.setItem('LandingPageNumberSelection', sbfNumbers)
								  this.setState({})
								}}
							/>
						</div>
					</div>
				</div>
			</div>

		</>
  }

  componentDidMount () {
    this.getCustomers()
    this.props.handleBasketInit()

    window.dataLayer.push({
      numberType: `${this.state.prefix}`,
      event: 'viewProduct'
    })
  }
}

export default withRouter(connect(
  state => {
    const signupFields = state.signup.signupFields
    const lidFromStorage = localStorage.getItem('ddiLid')
    return {
      ddiLid: lidFromStorage || signupFields.ddiLid,
      currentBasket: state.signup.packageInfo.currentBasket
    }
  },
  {
    handleAddToBasket: getSignup.handleAddToBasket,
    handleRemoveFromBasket: getSignup.handleRemoveFromBasket,
    handleBasketInit: getSignup.handleBasketInit,
    handleShowCallbackForm
  }
)(AreaCode))
