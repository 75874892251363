import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface PocketLandlineProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const PocketLandline = (props: PocketLandlineProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Pocket Landline vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Pocket Landline vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Pocket Landline vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Introduction</h2>
                            <p>Pocket Landline is an EE product offering local landline numbers to small businesses. The calls made to your new number will be rerouted straight to a mobile number.</p>
                            <p>Pocket Landline can be added as an extra to your current EE device. You need to have a contract with EE or a phone with an EE SIM to use the service.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Pocket Landline costs and features</h2>
                            <p>Pocket Landline costs from £10 per month and adds to your plan’s monthly charge. It is available only to single users who can divert calls from one landline number to one mobile. The first three months of using Pocket Landline are free.</p>
                            <p>The customers can manage their account on the Pocket Landline portal which can be accessed from laptops, mobiles and tablets. The portal gives the customers access to call stats reports. This allows them to identify their peak hours and where the calls come from.</p>
                            <p>There are not too many other features for users to utilise – they can choose or record their own welcome message to their callers, define out-of-hours times, and hear a "call screening", notifying them that the call is to the landline number.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">How do Pocket Landline and SwitchboardFREE compare?</h2>
                            <p>The Pocket Landline service looks rather limited, overall. SwitchboardFREE offer considerably more and overarching features which make it the better call management provider.</p>
                            <p>SwitchboardFREE can provide customers with a wider range of numbers, as opposed to Pocket Landline’s only local types. We can give clients 0800 freephone number type, 0203 and 0207 London numbers, 03 and 5 completely free 084 numbers, while offering local landlines, as well.</p>
                            <p>Although the Pocket Landline service is free for the first three months, we feel that SwitchboardFREE offer greater value for money. We not only provide more number types but our product also comes with more features to help you manage your calls better. You can select up to 5 redirects to transfer the call to you which significantly reduces the risk of missing the call at all.</p>
                            <p>What is more important, with SwitchboardFREE you get the option to choose whether to divert your calls to landline or mobile lines, or to mix your minutes to both. Pocket Landline allows diversion only to a mobile number.</p>
                            <p>Pocket Landline could be a good addition to your EE mobile device especially if you are a sole trader and need a local landline to gain trust for your business. However, SwitchboardFREE is the more wholesome system offering more numbers to select from, more features and broader coverage ensuring that your calls are always answered and your business is running smoothly.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see Pocket landlines current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://pocketlandline.ee.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(PocketLandline)
