import React from 'react'
import Select from 'react-select'

const TopupSelect = (props) => {
  return (
        <Select
            className="form-control select-menu mb-20"
            classNamePrefix="custom"
            onChange={e => props.handleTopupSelection(e)}
            options={props.options}
            simpleValue
            name="topupOptions"
            clearable={false}
            isSearchable={false}
            value={props.value}
        />
  )
}

TopupSelect.propTypes = {}
TopupSelect.defaultProps = {}

export default TopupSelect
