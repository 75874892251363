import moment from 'moment'
import React from 'react'
import { Alert, Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import 'simplebar/dist/simplebar.min.css'
import { NUMBER_FILTER_LOWER_THRESHOLD } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { getFutureDateDays, simplePhoneNumberFormat } from '../../helpers/utils'
import { type ReduxState } from '../../models/SignupTypes'
import * as myNumbers from '../../store/MyNumbers'
import { ClearableTextBox } from '../ClearableTextBox'
import LoadingNotification from '../LoadingNotification'
import FaxesConfig from '../MyNumbers/FaxesConfig'
import { QuickCloseToggle } from '../MyNumbers/QuickCloseToggle'
import CallRecordingPackages from '../purchase/CallRecordingPackages'

const _ = require('lodash')

const quickCloseExplained = (
    <Popover>
        <p className="text-400">When your number is closed, people calling your SwitchboardFREE number will hear:</p>
        <ul className="pl-15">
            <li>Your out of hours message (if turned on)</li>
            <li>They will also be left with an option to leave a voicemail (if turned on)</li>
        </ul>
        <p>Quick Close gives you an option to open and close your number or specific department(s) with a single click. Very useful in case of any emergencies.</p>
    </Popover>
)

export interface AutoReopenTimeDto {
  autoReopenTime: string
  depNo: number
}

export interface ClientQueueNumber {
  quickClosedChanged?: boolean
  ddiid: number
  qlid: number
  callsToday: number
  missedCallsToday: number
  ddi: string
  queueDescription: string
  quickClosed: boolean
  assignedDate: string
  hasDepartments: boolean
  hasFreeMobileRedirects: boolean
  doNotDelete: boolean
  starred: boolean | null
  voicemailCount: number | null
  geoEnabled: boolean | null
  mobileRedirectIncluded: boolean | null
  monthlyRental: number | null
  inclusiveSeconds: number | null
  additionalLandlineMinuteRate: number | null
  additionalMobileMinuteRate: number | null
  inclusiveMinutePool: number | null
  internationalBlock: boolean | null
  withheldBlock: boolean
  alternate03ddiid: string
  autoReopenTimes: AutoReopenTimeDto[]
  autoReopen: string
  hasCallRecording: boolean | null
  callAnswering: boolean
}

export interface SelectedItem {
  label: string
  value: number
}

export interface UserOutboundPackageDetails {
  landlineSeconds: number
  mobileSeconds: number
}

export interface GetServicesDto {
  mobileCredits: number
  hasBillingAgreement: boolean
  hasAutoTopup: boolean
  autoTopupAmountId: number
  autoTopupAmount: SelectedItem
  hasCallBlockingFeature: boolean
  hasContactBookFeature: boolean
  hasUmr: boolean
  displayUmrUpsell: boolean | null
  displayBillingWarning: boolean | null
  hasCallRecordingSingle: boolean
  hasCallRecordingAll: boolean
  hasUnlimitedCallRecording: boolean
  hasOutboundCalling: boolean
  outboundCallingPackage: UserOutboundPackageDetails
  isInOutboundBeta: boolean
  hasOutboundCallRecording: boolean
  hasVipCallerFeature: boolean
  hasNewCallBlockingFeature: boolean
  hasEmergencyContacts: boolean
  displayOutstandingInvoiceWarning: boolean
}

export interface DeptCallAnsweringStateDto {
  digit: string
  enabled: boolean
  callAnswering: boolean | null
  cqmId: number
}

export interface DeptCallRecordingStateDto {
  digit: string
  hasCallRecording: boolean | null
  enabled: boolean
}

export interface DeptOpenCloseStateDto {
  stateDirty?: boolean
  digit: string
  quickClosed: boolean
  autoReopenTime: string | null
  enabled: boolean
}

export interface MyNumbersProps extends RouteComponentProps {
  services: GetServicesDto
  isFetchingNumbers: boolean
  numbers: ClientQueueNumber[]
  phoneNumberSortDirection: string
  numberFilter: string
  starFilterEnabled: boolean
  numberCount: number
  autoReopenDialogDisplayed: boolean
  autoReopenDialogDateTime: Date
  departmentOpenCloseToggleDisplayed: boolean
  departmentOpenCloseStates: DeptOpenCloseStateDto[]
  departmentCallRecordingStates: DeptCallRecordingStateDto[]
  departmentCallAnsweringStates: DeptCallAnsweringStateDto[]
  departmentCallRecordingToggleDisplayed: boolean
  departmentCallAnsweringToggleDisplayed: boolean
  currentNumberEditing: ClientQueueNumber
  currentDepartmentEditing?: number
  // @ts-expect-error
  fetchNumbers: ({ history: any }) => void
  // @ts-expect-error
  handleFilterChange: ({ numberFilter: string, starFilterEnabled: boolean }) => void
  toggleStar: (args: { qlid: number, starred: boolean, filter: { numberFilter: string, starFilterEnabled: boolean }, numbers: ClientQueueNumber[] }) => void
  fetchDepartmentQuickCloseState: (num: ClientQueueNumber) => void
  // @ts-expect-error
  handleChangeQuickClose: ({ quickClosed: boolean, qlid: string }) => void
  handleCancelAutoReopen: (args: { qlid: number, digit?: string }) => void
  handleShowAutoReopenDialog: (args: { num: ClientQueueNumber, departmentNo?: number }) => void
  fetchDepartmentCallRecordingState: (num: ClientQueueNumber) => void
  // @ts-expect-error
  handleChangeCallRecording: ({ hasCallRecording: boolean, qlid: string, userServices: GetServicesDto }) => void
  fetchDepartmentCallAnsweringState: (num: ClientQueueNumber) => void
  handleChangeAutoReopenDateTime: (args: { newValue: moment.Moment, currentTime: moment.Moment }) => void
  handleChangeCallAnswering: (args: { cqmId: number, qlid: number, userServices: GetServicesDto, hasCallAnswering: boolean }) => void
  handleDismissDepartmentCallAnsweringDialog: () => void
  handleChangeCallRecordingDept: (args: { currentRecording?: ClientQueueNumber, userServices: GetServicesDto, callRecordingCount: number, num: ClientQueueNumber, departmentNo: number, hasCallRecording: boolean }) => void
  handleHideDepartmentCallRecordingDialog: () => void
  handleChangeQuickCloseDept: (args: { quickClosed: boolean, num: ClientQueueNumber, departmentNo: number }) => void
  handleHideDeparmentOpenCloseDialog: () => void
  handleDismissAutoReopenDialog: () => void
  handleSaveAutoReopenTime: (args: { num: ClientQueueNumber, departmentNo?: number, autoReopenTime: Date }) => void
}

export interface Services {
  hasUmr: boolean
  mobileCredit: number
  autoTopup: boolean
  hasAgreement: boolean
  autoTopupAmount: AutoTopupAmount
  hasCallBlocking: boolean
  hasContactBook: boolean
  displayBillingWarning: boolean
  displayUmrUpsell: boolean
  hasCallRecordingAll: boolean
  hasUnlimitedCallRecording: boolean
  hasCallRecordingSingle: boolean
  hasOutboundCalling: boolean
  outboundCallingPackage: null
  isInOutboundBeta: boolean
  hasVipCallerFeature: boolean
  hasNewCallBlockingFeature: boolean
  hasOutboundCallRecording: boolean
  displayOutstandingInvoiceWarning: boolean
}

export interface AutoTopupAmount {
  label: string
  value: number
}

export interface MyNumbersState {
  isLoadingFaxes: boolean
  faxes: Fax[]
  showRecordingsDepartments: boolean
  recordingsActive: boolean
  hasCallRecordingActivated: boolean
  selectedRecordingNum?: SelectedRecordingNum
  reopenSetButtonDisabled: boolean
  updatingCallAnswering: boolean
  selectedFax?: Fax
}

export interface Fax {
  quickClosed?: boolean
  ddiid: number
  faxNumber: string
  faxDescription: null | string
  todayCount: number
  email: string
}

export interface SelectedRecordingNum {
  number: string
  qlid: number
}

class MyNumbers extends React.Component<MyNumbersProps, MyNumbersState> {
  state: MyNumbersState = {
    faxes: [],
    hasCallRecordingActivated: false,
    isLoadingFaxes: false,
    recordingsActive: false,
    reopenSetButtonDisabled: false,
    selectedFax: undefined,
    selectedRecordingNum: undefined,
    showRecordingsDepartments: false,
    updatingCallAnswering: false
  }

  private onActivateClick: () => void
  private callAnsweringDialogActivate: boolean
  private callAnsweringDialogLite: boolean

  constructor (props) {
    super(props)
    this.onRecording = this.onRecording.bind(this)

    fetchHelper
      .getJson<boolean>('api/numbers/ClientHasPocketActivated')
      .then(res => {
        this.setState({ hasCallRecordingActivated: res.data })
      })
      .catch(function (error) {
        console.error(error)
      })
    this.handleCallAnsweringClicked = this.handleCallAnsweringClicked.bind(this)
    this.handleChangeOpenningTimeIfValid = this.handleChangeOpenningTimeIfValid.bind(this)
  }

  componentWillMount () {
    this.props.fetchNumbers({ history: this.props.history })
  }

  componentDidUpdate (prevProps, prevState) {
    const context = this
    if (!_.isEqual(this.props.departmentCallAnsweringStates, prevProps.departmentCallAnsweringStates)) {
      context.setState({ updatingCallAnswering: false })
    }
    if (!this.state.isLoadingFaxes && this.state.faxes.length === 0 && this.props.numbers.length > 0) {
      this.setState({ isLoadingFaxes: true }, () => {
        const getFaxesUrl = 'api/Numbers/Faxes'
        fetchHelper
          .getJson<Fax[]>(getFaxesUrl)
          .then(response => {
            context.setState({ faxes: response.data })
          })
          .catch(error => {
            console.error(error)
          })
      })
    }
  }

  render () {
    return this.props.isFetchingNumbers
      ? (
            <LoadingNotification loadingText={'Loading, please wait...'} isLoading={this.props.isFetchingNumbers} textColor="text-dark" width={50} height={50} textSize="sm" />
        )
      : (
            <>
                <FaxesConfig
                    fax={this.state.selectedFax}
                    handleUpdateCancel={() => { this.setState({ selectedFax: undefined }) }}
                    handleUpdateSaved={newFaxDetails => {
                      if (this.state.selectedFax !== null) {
                        const selectedFax = this.state.selectedFax
                        const index = _.findIndex(this.state.faxes, fax => selectedFax !== undefined && selectedFax.ddiid === fax.ddiid) ?? -1

                        if (index !== -1) {
                          const oldFaxes = this.state.faxes
                          const newFaxes = [...oldFaxes.slice(0, index), {
                            ...selectedFax,
                            faxDescription: newFaxDetails.description,
                            email: newFaxDetails.email
                          }, ...oldFaxes.slice(index + 1)]
                          // @ts-expect-error
                          this.setState({ selectedFax: undefined, faxes: newFaxes })
                        }
                      }
                    }}
                />
                <div className="mynumbers-container">
                    <div className="row">
                        <div className="col-xs-12 pb-10 pl-xxs-25 pr-xxs-25">
                            <div className="row pl-25 pr-25">
                                <div className="col-md-4 col-xs-12 col-sm-12 pr-sm-0 pl-xs-0 number-search">
                                    <div className="row">
                                        <div className="col-sm-1 d-none d-sm-block pl-0 pr-0 text-start">
                                        <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                        <Popover>
                                            {this.props.starFilterEnabled ? <p className="mb-0">Show me all numbers</p> : <p className="mb-0">Show me only starred numbers. To mark a number as a favourite, click the star next to your number.</p>}
                                        </Popover>
                                    }>
                                        <Button
                                                id={this.props.starFilterEnabled ? "MainStarToggleButtonOn" : "MainStarToggleButtonOff"}
                                                variant="transparent"
                                                className="pl-0 pr-0 pt-0 text-xl"
                                                disabled={this.props.numberCount === 0}
                                                onClick={e => {
                                                  this.props.handleFilterChange({
                                                    numberFilter: this.props.numberFilter.replace(/ /g, ''),
                                                    starFilterEnabled: !this.props.starFilterEnabled
                                                  })
                                                }
                                                }
                                            >
                                                <span className={this.props.starFilterEnabled ? 'icon icon-star-full' : 'icon icon-star-empty'} />
                                            </Button>
                                    </OverlayTrigger>

                                        </div>
                                        <div className="col-xs-12 col-sm-11 pr-0 pl-xs-0">
                                            {this.props.numberCount >= NUMBER_FILTER_LOWER_THRESHOLD && (
                                                <ClearableTextBox
                                                    placeholder="Find number..."
                                                    value={this.props.numberFilter}
                                                    onChange={e => {
                                                      this.props.handleFilterChange({
                                                        numberFilter: e === undefined ? '' : e.target.value,
                                                        starFilterEnabled: this.props.starFilterEnabled
                                                      })
                                                    }
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 text-center text-500 pt-10 heading calls-today">
                                    Calls
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                        <Popover>
                                            <p className="mb-0">Number of calls received today.</p>
                                        </Popover>
                                    }>
                                        <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-md-1 text-center text-500 pt-10 heading call-monitor">
                                    Monitor
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                        <Popover>
                                            <p>Real-time call and performance statistics.</p>
                                            <p className="mb-0">Perfect for Call Centres. Simply display this page of your wall TV monitor to get an overview of call traffic and to motivate agents to do better by providing them with real-time performance statistics.</p>
                                        </Popover>
                                    }>
                                        <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                                    </OverlayTrigger>
                                    </div>
                                <div className="col-md-2 text-center text-500 pt-10 heading quick-close">
                                    Quick close
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                        <Popover>
                                            <p>With a single click open or close your number or specific department.</p>
                                            <p className="mb-0">When you close your number, callers calling your SwitchboardFREE number will hear your out of hours message.</p>
                                        </Popover>
                                    }>
                                        <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                                    </OverlayTrigger>
                                    </div>
                                <div className="col-md-2 text-center text-500 pt-10 heading call-recording">
                                    Call recording
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                        <Popover>
                                            <p className="mb-0">Capture every detail so you can train your staff, improve compliance and easily resolve customer conflicts.</p>
                                        </Popover>
                                    }>
                                        <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                                    </OverlayTrigger>
                                    </div>
                                <div className="col-md-2 text-center text-500 pt-10 heading call-answering">Call answering
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                        <Popover>
                                            <p>With Call Answering enabled we can answer your calls for you while you're busy.</p>
                                            <p className="mb-0">We'll answer your calls professionaly and with your company name, take a message and email this directly to you in realtime.</p>
                                        </Popover>
                                    }>
                                        <span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-md-2 text-center text-500 pt-10 heading settings-btn" />
                            </div>
                        </div>
                    </div>

                    <div className="row pl-15 pr-15 pr-xxs-25 pl-xxs-25 bg-xxs-dull pt-xxs-30 pb-xxs-30">
                        {this.props.starFilterEnabled &&
                            this.props.numbers.filter(num => num.starred).length === 0 && (
                                <div className="alert alert-info" role="alert">
                                   <p>You currently have no starred numbers. Star your favourite or important numbers to filter them out from the others.</p>
                                </div>
                        )}

                        {this.props.numbers.map((num, i) => (
                            <div className={'number-row text-400 well well-light well-sm box-shadow pt-10 pb-10 zoom brad-20 mb-20 '.concat(num.quickClosed && !num.hasDepartments ? 'number-closed' : 'number-open')}>
                                <div className="number-section col-md-4 queue-number pb-sm-0 pl-sm-0 pr-sm-0 text-center text-lg-start">
                                    <Button
                                        id={num.starred !== undefined && num.starred === true ? `NumberStarButtonOn${i}` : `NumberStarButtonOff${i}`}
                                        variant="transparent"
                                        title={num.starred !== undefined && num.starred === true ? 'Click to unfavorite this number' : 'Click to mark this number as your favorite'}
                                        className="btn pl-10 pr-10 d-none d-sm-block btn-xl"
                                        onClick={e => {
                                          this.props.toggleStar({
                                            qlid: num.qlid,
                                            starred: num.starred ?? false,
                                            filter: {
                                              numberFilter: this.props.numberFilter,
                                              starFilterEnabled: this.props.starFilterEnabled
                                            },
                                            numbers: this.props.numbers
                                          })
                                        }}
                                    >
                                        <span className={num.starred !== undefined && num.starred === true ? 'icon icon-star-full' : 'icon icon-star-empty'} />
                                    </Button>
                                    <h3 className="mt-0 mb-sm-0 ml-10 ml-xs-0 mt-10">
                                        <Link id={`NumberSettingsLink${i}`} className="number text-xs text-xxs-sm text-sm-md text-xs-md" title="Click to see and change this number settings" to={{ pathname: '/customer/numbers/' + num.ddi + (num.hasDepartments ? '/1' : '') }}>
                                            <strong id={`NumberDdi${i}`}>{simplePhoneNumberFormat(num.ddi)}</strong>
                                            <span className="text-xxs text-dark text-400 pb-sm-30 pb-xs-30 block number-description">{num.queueDescription}</span>
                                        </Link>
                                    </h3>
                                </div>

                                {num.quickClosed &&
                                    !num.hasDepartments && (
                                        <div className="d-sm-none col-xs-12 text-center quick-close-info overflow-auto">
                                            <Alert variant="warning" className="mt-0 mb-20">
                                                <p className="mb-0">
                                                    <span className="text-500">This number is closed</span>{' '}
                                                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={quickCloseExplained}>
                                                        <span className="underline text-link text-sm ml-5">More info</span>
                                                    </OverlayTrigger>
                                                    <br />
                                                    Click the &quot;Quick Close&quot; toggle below to turn it back on.
                                                </p>
                                            </Alert>
                                        </div>
                                )}

                                <div className="number-section col-md-1 calls-today pb-sm-30 pb-xs-15 mt-20 mt-sm-0 text-center bb-xxs-1">
                                    <div className="d-block d-lg-none pb-10 pt-10">
                                        Calls <br />Today
                                    </div>
                                    <span className="img-circle">
                                        <Link className='no-underline' to={`/customer/calls?ddiId=${num.ddiid}`}>
                                            <span className="text-lightest">{num.callsToday}</span>
                                        </Link>
                                    </span>
                                    <span className="text-xs block pt-5">{this.GenerateMissedCallsBreakdownText(num)}&nbsp;</span>
                                </div>
                                <div className="number-section col-md-1 call-monitor pb-sm-30 pb-xs-15 mt-20 mt-sm-0 text-center bb-xxs-1">
                                    <div className="d-block d-lg-none pb-10 pt-10">
                                        Call <br />Monitor
                                    </div>
                                    <Link id={`CallMonitorButton${i}`} className="img-circle" title="Real-time Call Monitor" target="_blank" to={'/call-monitor/' + num.ddi}>
                                        <span className="icon icon-tv text-md" />
                                    </Link>
                                    <span className="text-xs block pt-5">&nbsp;</span>
                                </div>

                                <div className="number-section col-md-2 quick-close text-center mt-20 mt-sm-0">
                                    <div className="d-block d-lg-none pb-10 pt-10 pt-sm-10 pt-xs-10">
                                        Quick <br className="d-block d-sm-none" />Close
                                    </div>
                                    {num.hasDepartments && (
                                        <>
                                            <Button id={`QuickCloseButton${i}`} variant="secondary" className="mt-5 mb-5 mt-sm-10 mt-xs-10 mt-xxs-0 btn-rounded btn-xs" onClick={e => { this.props.fetchDepartmentQuickCloseState(num) }}>
                                                <span className="d-none d-xs-block">Quick Close</span>
                                                <span className="d-block d-xs-none">Settings</span>
                                            </Button>
                                        </>
                                    )}

                                    {!num.hasDepartments && (
                                        <>
                                            <QuickCloseToggle
                                                id={`QuickCloseToggle${i}`}
                                                quickClosed={num.quickClosed}
                                                stateDirty={num.quickClosedChanged}
                                                autoReopenTime={num.autoReopen ? num.autoReopen : undefined}
                                                handleChangeQuickClose={quickClosed => {
                                                  this.props.handleChangeQuickClose({
                                                    quickClosed,
                                                    qlid: num.qlid
                                                  })
                                                }
                                                }
                                                qlid={num.qlid}
                                                dateTime={this.props.autoReopenDialogDateTime}
                                                handleCancelAutoReopen={e => { this.props.handleCancelAutoReopen(num) }}
                                                handleShowAutoReopenDialog={() => { this.props.handleShowAutoReopenDialog({ num }) }}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className={`number-section col-md-2 call-recording text-center mt-20 mt-sm-0 ${num.hasCallRecording === true && !this.props.services.hasCallRecordingAll && !this.props.services.hasCallRecordingSingle && !this.props.services.hasUnlimitedCallRecording ? 'disabled' : ''}`}>
                                    <div className="d-block d-lg-none pb-10 pt-10 pt-sm-10 pt-xs-10">
                                        Call <br className="d-block d-sm-none" />Recording
                                    </div>

                                    {num.hasDepartments && (
                                        <>
                                            <Button id={`CallRecordingButton${i}`} variant="secondary" className="mt-5 mb-5 mt-sm-10 mt-xs-10 mt-xxs-0 btn-rounded btn-xs" onClick={() => { this.props.fetchDepartmentCallRecordingState(num) }}>
                                                <span className="d-none d-xs-block">Call Recording</span>
                                                <span className="d-block d-xs-none">Settings</span>
                                            </Button>
                                        </>
                                    )}

                                    {!num.hasDepartments && (
                                        <>
                                            <Toggle
                                                id={`CallRecordingToggle${i}${num.hasCallRecording ? 'On' : 'Off'}`}
                                                className="mt-5 rounded"
                                                onClick={e => {
                                                  this.props.handleChangeCallRecording({
                                                    hasCallRecording: e,
                                                    qlid: num.qlid,
                                                    userServices: this.props.services
                                                  })
                                                }
                                                }
                                                on={<span>ON</span>}
                                                off={<span>OFF</span>}
                                                size="sm"
                                                offstyle="default"
                                                onstyle="success"
                                                width="null"
                                                height="null"
                                                active={num.hasCallRecording}
                                            />
                                            <span className="slider round" />
                                        </>
                                    )}
                                </div>
                                <div className="number-section col-md-2 call-answering text-center mt-20 mt-sm-0">
                                    <div className="d-block d-lg-none pb-10 pt-10 pt-sm-10 pt-xs-10">
                                        Call <br className="d-block d-sm-none" />Answering
                                    </div>

                                    {num.hasDepartments && (
                                        <>
                                            <Button
                                                variant="secondary"
                                                className="mt-5 mb-5 mt-sm-10 mt-xs-10 mt-xxs-0 btn-rounded btn-xs"
                                                onClick={() => {
                                                  this.onActivateClick = () => {
                                                    this.props.history.push('/customer/numbers/' + num.ddi + (num.hasDepartments ? '/1/callAnswering' : '/callAnswering'))
                                                  }
                                                  this.callAnsweringDialogActivate = true
                                                  this.callAnsweringDialogLite = true
                                                  this.props.fetchDepartmentCallAnsweringState(num)
                                                }}
                                            >
                                                <span className="d-none d-xs-block">Call Answering</span>
                                                <span className="d-block d-xs-none">Settings</span>
                                            </Button>
                                        </>
                                    )}

                                    {!num.hasDepartments && (
                                        <>
                                            <Toggle
                                                className="mt-5 rounded"
                                                onClick={e => {
                                                  this.handleCallAnsweringClicked(e, num.ddi, num.hasDepartments)
                                                }}
                                                on={<span>ON</span>}
                                                off={<span>OFF</span>}
                                                size="sm"
                                                offstyle="default"
                                                onstyle="success"
                                                width="null"
                                                height="null"
                                                active={num.callAnswering}
                                            />
                                            <span className="slider round" />
                                        </>
                                    )}
                                </div>
                                <div className="number-section col-md-2 settings-btn text-center">
                                    <Link id={`NumberSettingsButton${i}`} className="number btn btn-cta btn-mobile-block btn-sm mt-20 btn-rounded" title="Click to see and change this number settings" to={{ pathname: '/customer/numbers/' + num.ddi + (num.hasDepartments ? '/1' : '') }}>
                                        Settings
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    {this.state.faxes.length > 0 && this.renderFaxToEmailNumbers()}
                    {this.renderQuickCloseAutoReopenModal()}
                    {this.renderDepartmentQuickCloseModalDialog()}
                    {this.renderDepartmentCallRecordingModalDialog()}
                    {this.renderDepartmentCallAnsweringModalDialog()}
                </div>

                <Modal className="w-900px" show={this.state.showRecordingsDepartments} onHide={() => { this.setState({ showRecordingsDepartments: false }) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Turn on/off recording for specific departments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-xs-12 col-md-4 col-sm-4 pb-20 text-center">
                                <label>Department 1</label>
                                <br />
                                <Toggle className="mt-0" onClick={this.onRecording} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" active={this.state.recordingsActive} />
                                <span className="slider round" />
                            </div>
                            <div className="col-xs-12 col-md-4 col-sm-4 pb-20 text-center">
                                <label>Department 2</label>
                                <br />
                                <Toggle className="mt-0" onClick={this.onRecording} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" active={this.state.recordingsActive} />
                                <span className="slider round" />
                            </div>
                            <div className="col-xs-12 col-md-4 col-sm-4 pb-20 text-center">
                                <label>Department 3</label>
                                <br />
                                <Toggle className="mt-0" onClick={this.onRecording} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" active={this.state.recordingsActive} />
                                <span className="slider round" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-4 col-sm-4 pb-20 text-center">
                                <label>Department 4</label>
                                <br />
                                <Toggle className="mt-0" onClick={this.onRecording} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" active={this.state.recordingsActive} />
                                <span className="slider round" />
                            </div>
                            <div className="col-xs-12 col-md-4 col-sm-4 pb-20 text-center">
                                <label>Department 5</label>
                                <br />
                                <Toggle className="mt-0" onClick={this.onRecording} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" active={this.state.recordingsActive} />
                                <span className="slider round" />
                            </div>
                            <div className="col-xs-12 col-md-4 col-sm-4 pb-20 text-center">
                                <label>Department 6</label>
                                <br />
                                <Toggle className="mt-0" onClick={this.onRecording} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" active={this.state.recordingsActive} />
                                <span className="slider round" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-4 col-sm-4 pb-20 text-center">
                                <label>Department 7</label>
                                <br />
                                <Toggle className="mt-0" onClick={this.onRecording} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" active={this.state.recordingsActive} />
                                <span className="slider round" />
                            </div>
                            <div className="col-xs-12 col-md-4 col-sm-4 pb-20 text-center">
                                <label>Department 8</label>
                                <br />
                                <Toggle className="mt-0" onClick={this.onRecording} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" active={this.state.recordingsActive} />
                                <span className="slider round" />
                            </div>
                            <div className="col-xs-12 col-md-4 col-sm-4 pb-20 text-center">
                                <label>Department 9</label>
                                <br />
                                <Toggle className="mt-0" onClick={this.onRecording} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" active={this.state.recordingsActive} />
                                <span className="slider round" />
                            </div>
                        </div>
                        <div className="row mt-30">
                            <CallRecordingPackages userServices={this.props.services} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={() => { this.setState({ showRecordingsDepartments: false }) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
  }

  handleCallAnsweringClicked (event, ddi, hasDepartments) {
    this.props.history.push('/customer/numbers/' + ddi + (hasDepartments ? '/1/callAnsweringChange' : '/callAnsweringChange'))
  }

  handleChangeOpenningTimeIfValid (e) {
    const isMomentObject = e instanceof moment
    if (isMomentObject) {
      this.props.handleChangeAutoReopenDateTime({
        // @ts-expect-error
        newValue: e,
        currentTime: moment()
      })
    }
    this.setState({ reopenSetButtonDisabled: !isMomentObject })
  }

  handleUpdateCallAnswering (e, dep) {
    const context = this
    context.setState({ updatingCallAnswering: true }, () => {
      context.props.handleChangeCallAnswering({
        hasCallAnswering: e,
        qlid: this.props.currentNumberEditing?.qlid,
        cqmId: dep.cqmId,
        userServices: this.props.services
      })
    })
  }

  renderDepartmentCallAnsweringModalDialog () {
    if (!this.props.departmentCallAnsweringToggleDisplayed) {
      return null
    }
    const map = this.props.departmentCallAnsweringStates.map((dep, index) => (
            <div className="col-xs-4 col-md-4 col-sm-4 pb-20 text-center">
                <label className="pr-10">Department {dep.digit}</label>
                <br />
                <Toggle className="mt-0" onClick={e => { this.handleUpdateCallAnswering(e, dep) }} on={<span>ON</span>} off={<span>OFF</span>} size="sm" offstyle="default" onstyle="success" width="null" height="null" disabled={!dep.enabled} active={dep.callAnswering} />
                <span className="slider round" />
            </div>
    ))
    return (
            <>
                <Modal className="call-answering-popup" show={this.props.departmentCallAnsweringToggleDisplayed} onHide={this.props.handleDismissDepartmentCallAnsweringDialog}>
                    <Modal.Header closeButton>
                        <Modal.Title>Departments call answering status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.updatingCallAnswering
                          ? (
                            <LoadingNotification loadingText={'Please wait...'} isLoading={true} textColor="text-dark" width={50} height={50} textSize="sm" />
                            )
                          : (
                            <>
                                <div className="row">{map.slice(0, 3)}</div>
                                <div className="row">{map.slice(3, 6)}</div>
                                <div className="row">{map.slice(6, 9)}</div>
                            </>
                            )}
                    </Modal.Body>
                </Modal>
            </>
    )
  }

  renderDepartmentCallRecordingModalDialog () {
    if (!this.props.departmentCallRecordingToggleDisplayed) {
      return null
    }
    const map = this.props.departmentCallRecordingStates.map((dep, index) => (
            <div className="col-xs-4 col-md-4 col-sm-4 pb-20 text-center">
                <label className="pr-10">Department {dep.digit}</label>
                <br />
                <Toggle
                    id={`MultiDeptsCallRecordingToggle${index}${dep.hasCallRecording ? 'On' : 'Off'}`}
                    className="mt-0 rounded"
                    onClick={e => {
                      this.setState({
                        selectedRecordingNum: {
                          number: this.props.currentNumberEditing.ddi,
                          qlid: this.props.currentNumberEditing.qlid
                        }
                      })

                      this.props.handleChangeCallRecordingDept({
                        departmentNo: index + 1,
                        num: this.props.currentNumberEditing,
                        hasCallRecording: e,
                        userServices: this.props.services,
                        callRecordingCount: this.props.numbers.filter(n => n.hasCallRecording).length,
                        currentRecording: this.props.numbers.find(f => f.hasCallRecording)
                      })
                    }
                    }
                    on={<span>ON</span>}
                    off={<span>OFF</span>}
                    size="sm"
                    offstyle="default"
                    onstyle="success"
                    width="null"
                    height="null"
                    active={dep.hasCallRecording}
                    disabled={!dep.enabled}
                />
                <span className="slider round" />
            </div>
    ))
    return (
            <>
                <Modal className="call-recording-popup" show={this.props.departmentCallRecordingToggleDisplayed} onHide={this.props.handleHideDepartmentCallRecordingDialog}>
                    <Modal.Header closeButton>
                        <Modal.Title id="DepartmentsCallRecordingStatusModal">Departments call recording status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <>
                                <div className="row">{map.slice(0, 3)}</div>
                                <div className="row">{map.slice(3, 6)}</div>
                                <div className="row">{map.slice(6, 9)}</div>
                            </>
                        }
                    </Modal.Body>
                </Modal>
            </>
    )
  }

  renderDepartmentQuickCloseModalDialog () {
    if (!this.props.departmentOpenCloseToggleDisplayed) {
      return null
    }
    const openCloseStates: DeptOpenCloseStateDto[] = []
    for (let departNum = 1; departNum <= 9; departNum++) {
      for (let index = 0; index < this.props.departmentOpenCloseStates.length; index++) {
        if (Number(this.props.departmentOpenCloseStates[index].digit) === departNum) {
          openCloseStates.push(this.props.departmentOpenCloseStates[index])
          break
        }
      }
    }
    const map = openCloseStates.map((dep, index) => (
            <div className="col-xs-4 col-md-4 col-sm-4 pb-20 text-center" style={{ height: '130px' }}>
                <label className="pr-10">{'Department ' + dep.digit}</label>
                <br />
                <QuickCloseToggle
                    id={`MultiDeptsQuickCloseToggle${index}`}
                    disabled={!dep.enabled}
                    quickClosed={dep.quickClosed}
                    autoReopenTime={dep.autoReopenTime}
                    dateTime={this.props.autoReopenDialogDateTime}
                    departmentNo={index + 1}
                    handleChangeQuickClose={isQuickClosed => {
                      this.props.handleChangeQuickCloseDept({
                        departmentNo: index + 1,
                        num: this.props.currentNumberEditing,
                        quickClosed: isQuickClosed
                      })
                    }
                    }
                    handleShowAutoReopenDialog={() => {
                      this.props.handleShowAutoReopenDialog({
                        num: this.props.currentNumberEditing,
                        departmentNo: index + 1
                      })
                    }
                    }
                    handleCancelAutoReopen={e => { this.props.handleCancelAutoReopen({ digit: dep.digit, qlid: this.props.currentNumberEditing.qlid }) }}
                    stateDirty={dep.stateDirty}
                />
            </div>
    ))
    return (
            <>
                <Modal className="quick-close-popup modal-med" show={this.props.departmentOpenCloseToggleDisplayed} onHide={this.props.handleHideDeparmentOpenCloseDialog}>
                    <Modal.Header closeButton>
                        <Modal.Title id="DepartmentsQuickCloseModal">Departments quick close/open</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <>
                                <div className="row">{map.slice(0, 3)}</div>
                                <div className="row">{map.slice(3, 6)}</div>
                                <div className="row">{map.slice(6, 9)}</div>
                            </>
                        }
                    </Modal.Body>
                </Modal>
            </>
    )
  }

  renderQuickCloseAutoReopenModal () {
    if (!this.props.autoReopenDialogDisplayed) {
      return null
    }
    const yesterday = getFutureDateDays(-1)
    const valid = function (current) {
      return current.isAfter(yesterday)
    }
    return (
            <Modal className="modal-med" show={this.props.autoReopenDialogDisplayed}
                   onHide={this.props.handleDismissAutoReopenDialog}>
                <Modal.Header closeButton className="text-center">
                    <Modal.Title>Automatically re-open</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-8 col-sm-8 mx-auto text-center">
                            <p>
                                Choose a date and time when you would like your <span
                                id="SPNautoOpenSpan">number</span> to automatically re-open.
                            </p>
                            <label>Date/Time</label>
                            {this.renderDateTime(valid)}
                        </div>
                    </div>
                    <div className="alert alert-danger" style={{ display: 'none' }}/>
                </Modal.Body>
                <Modal.Footer className="bt-0">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <Button
                                disabled={this.state.reopenSetButtonDisabled}
                                onClick={() => {
                                  this.props.handleSaveAutoReopenTime({
                                    num: this.props.currentNumberEditing,
                                    departmentNo: this.props.currentDepartmentEditing,
                                    autoReopenTime: this.props.autoReopenDialogDateTime
                                  })
                                }
                                }
                                variant="cta"
                                size="lg"
                                className="btn-mobile-block mb-15"
                            >
                                Set reopen time
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
    )
  }

  // Typescript doesn't like this <DateTime .../> component, so for now suppress the error with @ts-ignore
  // until a solution is found.
  private renderDateTime (valid: (current) => boolean) {
    // @ts-expect-error
    return <Datetime value={this.props.autoReopenDialogDateTime} dateFormat="DD/MM/YYYY" timeFormat="HH:mm"
                         isValidDate={valid} inputProps={{ maxLength: 20 }}
                         onChange={e => { this.handleChangeOpenningTimeIfValid(e) }}/>
  }

  renderFaxToEmailNumbers () {
    return (
            <>
                <div className="row">
                    <div className="col-xs-12 pb-30 pt-30 bottom-border text-center">
                        <h1 className="text-xxl">My Fax Numbers</h1>
                        <p>Fax numbers allow you to receive faxes directly to your email as an attachment</p>
                    </div>
                    <div className="col-xs-12 pb-20 d-none d-md-block">
                        <div className="row">
                            <div className="col-md-3 text-500 pt-10 ">Phone Number</div>
                            <div className="col-md-3 text-center text-500 pt-10">Description</div>
                            <div className="col-md-2 text-center text-500 pt-10">Faxes Today</div>
                            <div className="col-md-2 text-center text-500 pt-10">Destination Email</div>
                            <div className="col-md-2 text-center text-500 pt-10">Settings</div>
                        </div>
                    </div>
                </div>
                {this.state.faxes &&
                    this.state.faxes.map(num => (
                        <div key={num.ddiid} className={'number-row text-400 zoom col-md-12 well ml-xxs-15 mr-xxs-15 well-light well-sm box-shadow pb-sm-40 pb-xs-20 pt-xs-10 pt-10 pb-25 mb-20 '.concat(num.quickClosed ? 'number-closed' : 'number-open')}>
                            <div className="row pl-25 pr-25">
                                <div className="col-md-3 pb-sm-0 pb-xs-10 pl-sm-0 pr-sm-0 pt-10 text-center text-lg-start">
                                    <h3 className="mt-0 mb-sm-0">
                                        <strong>
                                            <Link className="number text-xs text-xxs-sm text-sm-md text-xs-md" title="Click to see and change this number settings" to={`/customer/numbers/faxes/${num.faxNumber}`}>
                                                {simplePhoneNumberFormat(num.faxNumber)}
                                            </Link>
                                        </strong>
                                    </h3>
                                </div>
                                <div className="col-md-3 pb-sm-30 pb-xs-10 pt-25 pt-sm-0 text-400 text-center">{num.faxDescription}</div>
                                <div className="col-md-2 pb-sm-30 pb-xs-10 pt-10 pt-sm-0 text-center">
                                    <div className="d-block d-md-none pb-10 pt-10 text-300">Faxes Today</div>
                                    <span className="img-circle">
                                        <span>{num.todayCount}</span>
                                    </span>
                                </div>
                                <div className="col-md-2 pb-sm-30 pb-xs-10 pt-25 pt-sm-0 text-400 text-center">
                                    <div className="d-block d-md-none pb-10 pt-10 text-300">Destination Email</div>
                                    {num.email}
                                </div>
                                <div className="col-md-2 pb-sm-30 pb-xs-10 pt-10 pt-sm-0 text-center">
                                    <Button type="button" variant="cta" className="btn-mobile-block mt-xs-30 btn-rounded" size="sm" onClick={e => { this.setState({ selectedFax: num }) }}>
                                        Settings
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
            </>
    )
  }

  onRecording () {
    this.setState({ recordingsActive: !this.state.recordingsActive })
  }

  GenerateMissedCallsBreakdownText (num) {
    const answeredCallsToday = num.callsToday - num.voicemailCount - num.missedCallsToday
    const totalAllMissedCallsToday = num.voicemailCount + num.missedCallsToday
    const missedCallsToday = num.missedCallsToday
    const voicemailCallsToday = num.voicemailCount
    if (totalAllMissedCallsToday > 0) {
      if (voicemailCallsToday === 0) {
        return ''.concat(answeredCallsToday + ' answered, ').concat(missedCallsToday + ' missed')
      } else {
        if (missedCallsToday === 0) {
          return ''.concat(answeredCallsToday + ' answered, ').concat(voicemailCallsToday === null ? '0 voicemails' : voicemailCallsToday + ' voicemails')
        } else {
          return ''
            .concat(answeredCallsToday + ' answered, ')
            .concat(missedCallsToday + ' missed, ')
            .concat(voicemailCallsToday === null ? '0 voicemails' : voicemailCallsToday + ' voicemails')
        }
      }
    }
    return ''
  }
}
export default connect(
  (state: ReduxState) => {
    return {
      isFetchingNumbers: state.myNumbers.requesting,
      numbers: myNumbers.getMyNumbersView(state),
      phoneNumberSortDirection: state.myNumbers.phoneNumberSortDirection,
      numberFilter: state.myNumbers.numberFilter,
      starFilterEnabled: state.myNumbers.starFilterEnabled,
      numberCount: state.myNumbers.numberCount,
      autoReopenDialogDisplayed: state.myNumbers.autoReopenDialogDisplayed,
      autoReopenDialogDateTime: state.myNumbers.autoReopenDialogDateTime,
      departmentOpenCloseToggleDisplayed: state.myNumbers.departmentOpenCloseToggleDisplayed,
      departmentOpenCloseStates: state.myNumbers.departmentOpenCloseStates,
      departmentCallRecordingStates: state.myNumbers.departmentCallRecordingStates,
      departmentCallAnsweringStates: state.myNumbers.departmentCallAnsweringStates,
      departmentCallRecordingToggleDisplayed: state.myNumbers.departmentCallRecordingToggleDisplayed,
      departmentCallAnsweringToggleDisplayed: state.myNumbers.departmentCallAnsweringToggleDisplayed,
      currentNumberEditing: state.myNumbers.currentNumberEditing,
      currentDepartmentEditing: state.myNumbers.currentDepartmentEditing
    }
  },
  {
    fetchNumbers: myNumbers.fetchMyNumbers,
    sortPhoneNumber: myNumbers.sortPhoneNumber,
    handleFilterChange: myNumbers.handleFilterChange,
    toggleStar: myNumbers.toggleStar,
    toggleStarFilter: myNumbers.toggleStarFilter,
    handleChangeQuickClose: myNumbers.handleChangeQuickClose,
    handleChangeCallRecording: myNumbers.handleChangeCallRecording,
    handleChangeCallAnswering: myNumbers.handleChangeCallAnswering,
    handleDismissAutoReopenDialog: myNumbers.dismissAutoReopenDialog,
    handleShowAutoReopenDialog: myNumbers.handleShowAutoReopenDialog,
    handleChangeAutoReopenDateTime: myNumbers.handleChangeAutoReopenDateTime,
    handleSaveAutoReopenTime: myNumbers.handleSaveAutoReopenTime,
    handleHideDeparmentOpenCloseDialog: myNumbers.handleHideDeparmentOpenCloseDialog,
    handleHideDepartmentCallRecordingDialog: myNumbers.handleHideDepartmentCallRecordingDialog,
    fetchDepartmentQuickCloseState: myNumbers.fetchDepartmentQuickCloseState,
    fetchDepartmentCallRecordingState: myNumbers.fetchDepartmentCallRecordingState,
    handleChangeQuickCloseDept: myNumbers.handleChangeQuickCloseDept,
    handleChangeCallRecordingDept: myNumbers.handleChangeCallRecordingDept,
    handleCancelAutoReopen: myNumbers.handleCancelAutoReopen,
    fetchDepartmentCallAnsweringState: myNumbers.fetchDepartmentCallAnsweringState,
    handleDismissDepartmentCallAnsweringDialog: myNumbers.handleDismissDepartmentCallAnsweringDialog
  }
)(MyNumbers)
