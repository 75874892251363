import React from 'react'
import { connect } from 'react-redux'

// ######################################
//
//    Example 1: the below will show the opening times in one line
//
//      <OpeningTimesOffice />
//
//
//    Example 2:  the below will show the opening times in two lines. Sat and Sun will be in the second line.
//
//       <OpeningTimesOffice TwoLines={true} />
//
// ######################################

class OpeningTimesOffice extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      this.props.TwoLines
        ? <>
            <span>
                Mon-Fri 9:00 - 17:30
                <br/>
                Sat-Sun is closed.
            </span>
		      </>
        :		
        
        <>
          <span>9:00 - 17:30 Mon-Fri. Sat-Sun we&apos;re closed.</span>
        </>
    )
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return state
  },
  {}
)(OpeningTimesOffice)
