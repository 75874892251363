import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { siteFooter } from '../constants/Constants'

const FooterSimple = props => props.ShowFooterSimple
  ? <footer className="simple border-top text-center">
        <p className="text-xs">
            &copy; {(new Date().getFullYear())} Liquid11, Sea Lake Road, Lowestoft, Suffolk, NR32 3LQ<br />
            Company number: 04404380 Regulated by Ofcom.
            <span className="pl-10 pr-10 d-none d-xs-block">|</span>
            <br className="d-block d-xs-none" />
            <Link to={'/privacy-policy'}>Privacy policy</Link>
            <span className="pl-5 pr-5">/</span>
            <Link to={'/terms-and-conditions'} target="_blank">Terms and Conditions</Link>
        </p>
    </footer>
  : null

const mapStateToProps = state => {
  return { ShowFooterSimple: state.sbfApp.visibility.FOOTER === siteFooter.SIMPLE }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {},
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterSimple)
