import React from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import ModalVideo from 'react-modal-video'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SvgRender from '../../components/SvgRender'
import PurchasePhones from '../../components/purchase/PurchasePhones'
import { ShowOutboundToAll } from '../../constants/Constants'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'

class UpgradesPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
      isOpenTwo: false,
      isOpenThree: false,
      isOpenFour: false,
      isOpenFive: false,
      isOpenSix: false,
      isOpenSeven: false,
      showHandsetOrder: false
    }
  }

  render () {
    const basket = getEmptyBasket()
    if (LocalStorageHelper.getItem('BasketHardware')?.value !== null) {
      basket.hardware = LocalStorageHelper.getItem('BasketHardware').value
    }

    return <div><ScrollToTopOnMount /><DisplayLoggedInFooter /><DisplayMainHeader />
            {/* <UpgradesMenu /> */}
            <header>
                <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/contactbook-bg.jpg')" }}>
                    <h1 id="UpgradesH1">Upgrades</h1>
                    <h2 className="subtitle">Improve efficiency, productivity and compliance with a range of upgrades</h2>
                </div>
            </header>
            <div className="container-styled bg-dull pb-100">
                <div className="container pt-30">

                    <Tabs defaultActiveKey={1} className="tabs-container profile-tabs justify-content-center">
                        <Tab eventKey={1} title="Premium features">
                            <div className="text-center pt-40 pb-40">
                                <h3 className="text-500">
                                    Premium Features
                                </h3>
                                <p>
                                    Premium features are applied instantly to your account and can be canceled at anytime.
                                </p>

                            </div>

                            <div className={'row row-flex flex-3-col flex-sm-2-col no-flex-grow flex-sm-grow '}>

                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="text-center img-circle block mt-10">
                                        <SvgRender icon="tps-protection" svgWidth="50px" svgClassName="mt-neg-10" />
                                    </div>
                                    <h5 className="text-500 pb-20 pt-20">TPS Protection</h5>
                                    <p>SwitchboardFREE's TPS Protection feature provides businesses with an easy and efficient way to stay compliant with TPS regulations.</p>
                                    <div className="button-set">
                                        <Link className="btn btn-cta btn-xs  mr-10" to={'/features/tps-protection'} id="TPSProtectionFindOutMoreButton">Find out more</Link>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="text-center img-circle block mt-10">
                                        <SvgRender icon="voicemail-transcription" svgWidth="50px" svgClassName="mt-neg-10" />
                                    </div>
                                    <h5 className="text-500 pb-20 pt-20">Call Search Pro</h5>
                                    <p>Leverage call transcription for custom alerts, streamlined conversations, enhanced compliance, and easy quality control.</p>
                                    <div className="button-set">
                                        <Link className="btn btn-cta btn-xs  mr-10" to={'/features/call-transcription'} id="CallTranscriptionFindOutMoreButton">Find out more</Link>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="text-center img-circle block mt-10">
                                        <SvgRender icon="voicemail-transcription" svgWidth="50px" svgClassName="mt-neg-10" />
                                    </div>
                                    <h5 className="text-500 pb-20 pt-20">Keyword Triggers</h5>
                                    <p>Enhance Compliance and Quality Control, setup notifications based on the content of your company's calls.</p>
                                    <div className="button-set">
                                        <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/keyword-triggers/'} id="KeywordTriggersFindOutMoreButton">Find out more</Link>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="text-center img-circle block mt-10">
                                        <SvgRender icon="pro-prompts" svgWidth="50px" svgClassName="mt-neg-10" />
                                    </div>
                                    <h5 className="text-500 pb-20 pt-20">Professional Prompts</h5>
                                    <p>Get your own bespoke call prompts recorded by the same voice artist as the rest of the SwitchboardFREE system.</p>
                                    <div className="button-set">
                                        <Link className="btn btn-cta btn-xs  mr-10" to={'/professional-prompts'} id="ProfessionalPromptsFindOutMoreButton">Find out more</Link>
                                    </div>
                                </div>

                                {!this.props.services.hasUnlimitedCallRecording &&
                                    <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                        <div className="text-center img-circle block mt-10">
                                            <SvgRender icon="call-recording" svgWidth="60px" svgClassName="mt-neg-10" />
                                        </div>
                                        <h5 className="text-500 pb-20 pt-20">Call Recording </h5>
                                        <p>Start recording your SwitchboardFREE calls. Listen and download the recording 24/7.</p>
                                        <div className="button-set">
                                            <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/upgrades/call-recording/'} id="CallRecordingFindOutMoreButton">Find out more</Link>
                                        </div>
                                    </div>
                                }

                                {this.props.services.hasContactBook !== true &&
                                    <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                        <div className="text-center img-circle block mt-10">
                                            <SvgRender icon="contact-book" svgWidth="50px" svgClassName="mt-neg-10" />
                                        </div>
                                        <h5 className="text-500 pb-20 pt-20">Contact Book</h5>
                                        <p>Your contact book allows you to put names to numbers which will then be displayed in call reports and also on missed call emails.</p>
                                        <div className="button-set">
                                            <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/upgrades/contact-book/'} id="ContactBookFindOutMoreButton">Find out more</Link>
                                        </div>
                                    </div>
                                }

                                {(!this.props.services.hasOutboundCalling && ShowOutboundToAll) || (!ShowOutboundToAll && this.props.services.isInOutboundBeta) &&
                                    <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                        <div className="text-center img-circle block mt-10">
                                            <SvgRender icon="make-receive-calls" svgWidth="50px" svgClassName="mt-neg-10" />
                                        </div>
                                        <h5 className="text-500 pb-20 pt-20">Outbound Calling</h5>
                                        <p>Discover the power of outbound calling. Make calls to any of your contacts and they'll see your SwitchboardFREE number every time!</p>
                                        <div className="button-set">
                                            <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/upgrades/outbound/'} id="OutboundCallingFindOutMoreButton">Find out more</Link>
                                        </div>
                                    </div>
                                }

                                    <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                        <div className="text-center img-circle block mt-10">
                                            <SvgRender icon="redirects" svgWidth="35px" svgClassName="mt-neg-10" />
                                        </div>
                                        <h5 className="text-500 pb-20 pt-20">Unlimited 084 Mobile Redirects</h5>
                                        <p>Ensure you never miss a business call with unlimited redirects to your mobile</p>
                                        <div className="button-set">
                                            <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/upgrades/unlimited-mobile-redirects/'} id="UMRFindOutMoreButton">Find out more</Link>
                                        </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="text-center img-circle block mt-10">
                                        <SvgRender icon="redirects" svgWidth="35px" svgClassName="mt-neg-10" />
                                    </div>
                                    <h5 className="text-500 pb-20 pt-20">Nuisance Call Blocker</h5>
                                    <p>Block individual numbers, as well as all international calls and withheld calls, too!</p>
                                    <div className="button-set">
                                        <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/upgrades/call-blocking/'} id="CallBlockingFindOutMoreButton">Find out more</Link>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="text-center img-circle block mt-10">
                                        <SvgRender icon="redirects" svgWidth="35px" svgClassName="mt-neg-10" />
                                    </div>
                                    <h5 className="text-500 pb-20 pt-20">Call Prioritiser</h5>
                                    <p>Prioritise your more valued customers by placing their calls to the front of the queue and even putting through their calls while your line is shut.</p>
                                    <div className="button-set">
                                        <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/upgrades/call-prioritiser/'} id="CallPrioritiserFindOutMoreButton">Find out more</Link>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey={2} title="Numbers">
                            <div className="text-center pt-40 pb-40">
                                <h3 className="text-500" id="ExtraNumbersH3">
                                    Extra Numbers
                                </h3>
                                <p>
                                    New numbers are added to your account straight away and instantly active.
                                </p>
                            </div>

                            <div className="row row-flex flex-3-col flex-sm-2-col no-flex-grow flex-sm-grow">
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="icon-block mt-20"><span className="icon icon-location2"></span></div>
                                    <h5 className="text-500 pb-20 pt-20">Local Geo Numbers</h5>
                                    <p>We love to shop locally, and when searching for local businesses we like to call a local number</p>
                                    <h5 className="text-secondary text-400 text-sm pb-xs-60 pb-10">From just <GetProdPrice prefix="01" /></h5>
                                    <div className="button-set">
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpenTwo} videoId='XsEQiuKm418?start=83&end=107' onClose={() => this.setState({ isOpenTwo: false })} />
                                        <Button variant="secondary" size="xs" onClick={() => this.setState({ isOpenTwo: true })} className=" mr-10" id="LocalGeoNumbersWatchVideoButton">Watch video</Button>
                                        <Link className="btn btn-cta btn-xs  mt-xs-10" to={'/customer/numbers/new/01'} id="LocalNumbersGetItNowButton">Get it now</Link>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="icon-block mt-20"><span className="icon icon-phone2"></span></div>
                                    <h5 className="text-500 pb-20 pt-20">Memorable 084 Numbers</h5>
                                    <p>Improve your company image and increase your calls with a memorable 084 number</p>
                                    <div className="button-set">
                                        <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/upgrades/memorable-phone-numbers/'} id="Memorable084NumbersGetItNowButton">Get it now</Link>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="icon-block mt-20"><span className="icon icon-list-numbered"></span></div>
                                    <h5 className="text-500 pb-20 pt-20">Multi Numbers</h5>
                                    <p>A Multi Number package allows you to have a set of numbers that all share the same allowance of minutes.</p>
                                    <div className="button-set">
                                        <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/upgrades/multi-numbers-package/'} id="MultiNumbersGetItNowButton">Get it now</Link>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="icon-block mt-20"><span className="icon icon-gherkin"></span></div>
                                    <h5 className="text-500 pb-20 pt-20">0203 London Numbers</h5>
                                    <p>Give your company a London presence without the expense of renting a London office</p>
                                    <h5 className="text-secondary text-400 text-sm pb-xs-60 pb-10">From just <GetProdPrice prefix="0203" /></h5>
                                    <div className="button-set">
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpenSix} videoId='XsEQiuKm418?start=107&end=125' onClose={() => this.setState({ isOpenSix: false })} />
                                        <Button variant="secondary" size="xs" onClick={() => this.setState({ isOpenSix: true })} className=" mr-10" id="0203LondonNumbersWatchVideoButton">Watch video</Button>
                                        <Link className="btn btn-cta btn-xs  mt-xs-10" to={'/customer/numbers/new/0203'} id="0203LondonNumbersGetItNowButton">Get it now</Link>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="icon-block mt-20"><span className="icon icon-bigben"></span></div>
                                    <h5 className="text-500 pb-20 pt-20">0207 London Numbers</h5>
                                    <p>Rarely available and highly prestigious, having one for your business will make it appear...</p>
                                    <h5 className="text-secondary text-400 text-sm pb-xs-60 pb-10">From just <GetProdPrice prefix="0207" /></h5>
                                    <div className="button-set">
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpenSeven} videoId='XsEQiuKm418?start=107&end=125' onClose={() => this.setState({ isOpenSeven: false })} />
                                        <Button variant="secondary" size="xs" onClick={() => this.setState({ isOpenSeven: true })} className=" mr-10" id="0207LondonNumbersWatchVideoButton">Watch video</Button>
                                        <Link className="btn btn-cta btn-xs  mt-xs-10" to={'/customer/numbers/new/0207'} id="0207LondonGetItNowButton">Get it now</Link>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="icon-block mt-20"><span className="icon icon-03"></span></div>
                                    <h5 className="text-500 pb-20 pt-20">03 Numbers</h5>
                                    <p>Get a professional business number with a national presence</p>
                                    <h5 className="text-secondary text-400 text-sm pb-xs-60 pb-10">From just <GetProdPrice prefix="03" /></h5>
                                    <div className="button-set">
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpenFour} videoId='XsEQiuKm418?start=126&end=143' onClose={() => this.setState({ isOpenFour: false })} />
                                        <Button variant="secondary" size="xs" onClick={() => this.setState({ isOpenFour: true })} className=" mr-10" id="03NumbersWatchVideoButton">Watch video</Button>
                                        <Link className="btn btn-cta btn-xs  mt-xs-10" to={'/customer/numbers/new/03'} id="03NumbersGetItNowButton">Get it now</Link>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="icon-block mt-20"><span className="icon icon-0800"></span></div>
                                    <h5 className="text-500 pb-20 pt-20">0800 Numbers</h5>
                                    <p>Calls made to 0800 numbers from both landline and mobile phones</p>
                                    <h5 className="text-secondary text-400 text-sm pb-xs-60 pb-10">From just <GetProdPrice prefix="0800" /></h5>
                                    <div className="button-set">
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpenThree} videoId='XsEQiuKm418?start=143&end=163' onClose={() => this.setState({ isOpenThree: false })} />
                                        <Button variant="secondary" size="xs" onClick={() => this.setState({ isOpenThree: true })} className=" mr-10" id="0800NumbersWatchVideoButton">Watch video</Button>
                                        <Link className="btn btn-cta btn-xs  mt-xs-10" to={'/customer/numbers/new/0800'} id="0800NumbersGetItNowButton">Get it now</Link>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="icon-block mt-20"><span className="icon icon-phone2"></span></div>
                                    <h5 className="text-500 pb-20 pt-20">084 Numbers</h5>
                                    <p>084 business number will give your business a professional sound and national presence</p>
                                    <h5 className="text-secondary text-400 text-sm pb-xs-60 pb-10">FREE</h5>
                                    <div className="button-set">
                                        <ModalVideo channel='youtube' start="0:24" isOpen={this.state.isOpenFive} videoId='XsEQiuKm418?start=25&end=82' onClose={() => this.setState({ isOpenFive: false })} />
                                        <Button variant="secondary" size="xs" onClick={() => this.setState({ isOpenFive: true })} className=" mr-10" id="084NumbersWatchVideoButton">Watch video</Button>
                                        <Link className="btn btn-cta btn-xs  mt-xs-10" to={'/customer/numbers/new/084'} id="084NumbersGetItNowButton">Get it now</Link>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 bg-light b-1 box-shadow p-30 mb-30 brad-10 text-center">
                                    <div className="text-center img-circle block mt-10">
                                        <SvgRender icon="fax2email" svgWidth="50px" svgClassName="mt-neg-10" />
                                    </div>
                                    <h5 className="text-500 pb-20 pt-20">Fax 2 Email</h5>
                                    <p>Receive faxes as easily and quickly as you receive emails with SwitchboardFREE Fax to Email</p>
                                    <div className="button-set">
                                        <Link className="btn btn-cta btn-xs  mr-10" to={'/customer/fax-to-email-number'} id="Fax2EmailFindOutMoreButton">Find out more</Link>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey={3} title="VoIP phones">
                            <div className="text-center pt-40 pb-40">
                                <h3 className="text-500" id="PurchaseNewVoipPhoneH3">
                                    Purchase a new VoIP Phone
                                </h3>
                                <p>
                                    Choose from our range of plug-and-play, pre-configured, VoIP Phones and headsets.
                                </p>
                            </div>

                            <PurchasePhones
                                displayInline={true}
                                handleUpdateHardware={(hardware) => handleHandsetUpdated(hardware)}
                                currentBasket={basket}
                                showEditDevices={false}
                                handleCloseDevicesModal={() => setState({
                                  ...state,
                                  showEditDevices: false
                                })}
                                isSignup={false}
                                hideFree={true}
                                purchaseUrl="/api/Purchase/PurchasePhones"
                                notSlider={true}
                                CustomDivClass="w-16 text-center p-10"
                                displayInstalments={true}

                            />
                        </Tab>
                    </Tabs>

                </div>
            </div>
        </div>
  }

  componentWillMount () { }
  componentDidMount () {
    document.title = 'Upgrades'
    const tabs = document.getElementsByClassName('nav-tabs')
    tabs[0].classList.add('nav-tabs-centered')
  }
}

export default connect(
  state => {
    return {
      services: state.sbfApp.enabledServices
    }
  },
  {}
)(UpgradesPage)
