import React from 'react'
import { Redirect, Route } from 'react-router'

const permittedRoutesIfBillingRestricted = [
  {
    route: '/ubd/:uniqueId?',
    comparator: 'exactMatch'
  },
  {
    route: '/UpdateBillingDetails/:uniqueId?',
    comparator: 'exactMatch'
  }
]

function IsBlockedByBillingRestricted (props) {
  // if user is set to billing restricted only allow if path is in exception list
  if (JSON.parse(localStorage.getItem('billingRestricted')) === true) {
    for (let i = 0; i < permittedRoutesIfBillingRestricted.length; i++) {
      const comparator = permittedRoutesIfBillingRestricted[i].comparator
      const route = permittedRoutesIfBillingRestricted[i].route

      switch (comparator) {
        case 'exactMatch':
          if (props.match.path === route) {
            return false
          }
          break
        case 'startsWith':
          if (props.match.path.startsWith(route)) {
            return false
          }
          break
      }
    }

    return true
  }

  // if no conditions hit allow access
  return false
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      IsBlockedByBillingRestricted(props) === true
        ? <Redirect to='/ubd' />
        : localStorage.getItem('user') !== undefined
          ? <Component {...props} />
          : <Redirect to='/login' />
    )} />
)

export default PrivateRoute
