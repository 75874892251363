import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface EverreachProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const Everreach = (props: EverreachProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>Everreach vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>Everreach vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of Everreach vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What does Everreach offer?</h2>
                            <p>Everreach is a phone service providing small businesses with virtual numbers. The number types available are local 01/02 and national 03 and 0845 numbers. Every Everreach number comes with standard call management features such as audio greetings, voicemail, call recording, and, of course, call forwarding to landlines and mobile numbers.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Everreach pricing</h2>
                            <p>There are no setup or activation fees with everreach, just the monthly cost of each package.</p>
                            <p>The three available plans are outlined below:</p>

                            <ul className="fancy cta text-400 pl-30 pt-10 pb-30">
                                <li className="mb-5">
                                    <p className="text-500 text-lg text-dark">The Start Plan</p>
                                    <p>The Start Plan includes 500 minutes to landline and mobile numbers, and costs £10 per month.</p>
                                </li>
                                <li className="mb-5">
                                    <p className="text-500 text-lg text-dark">The Grow Plan</p>
                                    <p>The Grow Plan includes 800 minutes and is priced at £15 / month.</p>
                                </li>
                                <li className="mb-5">
                                    <p className="text-500 text-lg text-dark">The Run Plan</p>
                                    <p>The Run Plan, including 2000 minutes, has a monthly fee of £25.</p>
                                </li>
                            </ul>

                            <p>It is good to know that when the monthly minutes allowance is used up, there are no extra call charges. Calls would only be sent straight to voicemail, without making attempt to forward them to a different destination.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Everreach vs. SwitchboardFREE</h2>
                            <p>Along with local, 03, and 084 numbers, SwitchboardFREE provide 0800 freephone numbers to clients. Everreach does not offer this number type.</p>
                            <p>There is no significant difference in the pricing of the two services’ plans, although if you prefer to have your calls forwarded to landline numbers, then SwitchboardFREE is the better option. The testament to this lies in the comparison between the 800-minute packages, where our costs start from £8.96 per month, compared to an almost double price (£15) at everreach.</p>
                            <p>Our decisive advantage comes in the field of the 084 numbers. We offer ours completely free, with no limits to landline minutes, and the possibility of getting unlimited mobile redirects for just £9.96 per month.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see everreachs current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://everreach.co.uk/" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(Everreach)
