import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { fieldNames, voicemailDelayOptions } from '../../constants/ConfigurationConstants'
import { PromptTypes } from '../../constants/Constants'
import { getConfigValidators } from '../../selectors'
import * as configurationActions from '../../store/Configuration'
import PromptAudioPlayer from '../PromptAudioPlayer'
import Validator from '../Validator'
import VoicemailInstructionHelperDialog from './InstructionHelperDialogs/VoicemailInstructionHelperDialog'

const VoicemailSection = (props) => (
    <>
        <div className="row bg-light p-30 zoom">
            <div className="col-md-5 pb-sm-10">
                <h5 className="text-secondary text-500 text-lg">
                    Voicemail

                </h5>
                <p className="text-400">
                    Enter the email address to send voicemails to and choose how long your callers must hold before they can leave a voicemail. <a onClick={props.handleShowVoicemailInstructionHelperDialog} className="underline" id="VoicemailMoreInfoLink">More info</a>
                </p>
            </div>
            <div className="col-md-7 mt-10">
                <VoicemailInstructionHelperDialog />
                <div className="row">
                    <div className="col-md-12 text-center pb-30">
                        <p>Turn ON/OFF:</p>
                        <Toggle
                            className="mt-0"
                            onClick={e => props.handleVoicemailEnabledChange(e)}
                            on={<span>ON</span>}
                            off={<span>OFF</span>}
                            size="sm"
                            offstyle="default"
                            onstyle="success"
                            width="null"
                            height="null"
                            active={props.voicemailEnabled}
                            id="VoicemailToggle"
                        />
                    </div>
                    <div className="clearfix d-block d-md-none"></div>
                    <div className="col-sm-4 pb-sm-30">
                        <p>Email address(es):</p>
                        <input type="text" inputMode='email' maxLength='128' className="form-control" onBlur={e => props.validateEmailSyntax({ fieldName: fieldNames.VOICEMAIL_EMAIL, value: e.target.value })} placeholder="eg. mark@gmail.com;sally@yahoo.co.uk" value={props.voicemailEmailAddress} onChange={e => props.handleVoicemailEmailChange(e.target.value)} id="VoicemailInput" />
                    </div>
                    <div className="col-sm-4 pb-sm-30">
                        <p>
                            Voicemail after:
                        </p>
                        <Validator validation={props.validation[fieldNames.VOICEMAIL_EMAIL]} />
                        <Select
                            id={'voicemail_delay_select'}
                            className="form-control select-menu"
                            classNamePrefix="custom"
                            searchable={false}
                            clearable={false}
                            options={voicemailDelayOptions}
                            simpleValue
                            name="selected-voicemail_delay"
                            value={props.voicemailDelay !== undefined ? { value: props.voicemailDelay.voicemailDelaySeconds, label: props.voicemailDelay.voicemailDelayText ? props.voicemailDelay.voicemailDelayText : 'after ' + props.voicemailDelay.voicemailDelaySeconds.toString() + ' seconds' } : '30'}
                            onChange={props.handleVoicemailDelayChange}
                        />
                    </div>
                    <div className="col-sm-4">
                        <p>
                            Voicemail Message:
                        </p>
                        <Select
                            id={'voicemail_message_select'}
                            searchable={false}
                            clearable={false}
                            className="form-control select-menu"
                            classNamePrefix="custom"
                            options={props.voicemailMessagePrompts}
                            simpleValue
                            name="selected-voicemail_message"
                            value={props.voicemailPrompt !== undefined ? { value: props.voicemailPrompt.promptId, label: props.voicemailPrompt.promptDescription } : undefined}
                            onChange={props.handleVoicemailMessageChange}
                        />
                    </div>

                </div>
                <div className="col-md-12 pl-0 pr-0 mt-30">
                    <PromptAudioPlayer promptId={props.voicemailPrompt !== undefined ? props.voicemailPrompt.promptId : undefined} voice={props.voice} promptType={PromptTypes.standardVoicemail} />
                </div>
            </div>
        </div>
    </>
)

const mapStateToProps = state => ({
  voicemailEnabled: state.configuration.configuration.isVoicemailEnabled,
  voicemailEmailAddress: state.configuration.configuration.voicemailEmailAddress,
  voicemailDelay: state.configuration.configuration.voicemailDelay,
  voicemailMessagePrompts: state.configuration.prompts.voicemailPrompts,
  voicemailPrompt: state.configuration.configuration.voicemailPrompt,
  voice: state.configuration.configuration.voicePrompt.voicePromptId,
  validation: getConfigValidators(state)
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoicemailSection)
