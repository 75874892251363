import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import ConfigFooter from './components/ConfigFooter'
import ContactBookSyncCapsuleCrm from './components/ContactBookSync/ContactBookSyncCapsuleCrm'
import ContactBookSyncHubSpotCrm from './components/ContactBookSync/ContactBookSyncHubSpotCrm'
import ContactBookSyncPipedriveCrm from './components/ContactBookSync/ContactBookSyncPipedriveCrm'
import PipedriveInboundRequest from './components/ContactBookSync/PipedriveInboundRequest'
import Cookies from './components/Cookies'
import CrmRedirectWebhookPage from './components/CrmRedirectWebhookPage'
import ExitIntent from './components/ExitIntent'
import FooterMain from './components/FooterMain'
import FooterSimple from './components/FooterSimple'
import HeaderMain from './components/HeaderMain'
import HeaderSimple from './components/HeaderSimple'
import HeaderSimpleV2 from './components/HeaderSimpleV2'
import CapsuleCrm from './components/Integrations/CapsuleCrm'
import Hubspot from './components/Integrations/Hubspot'
import PipedriveCrm from './components/Integrations/PipedriveCrm'
import InvoiceViewer from './components/InvoiceViewer'
import LoggedInFooter from './components/LoggedInFooter'
import LinkedinRedirect from './components/Login/LinkedinRedirect'
import MyCalls from './components/MyCalls/MyCalls'
import FaxesConfig from './components/MyNumbers/FaxesConfig'
import PrivateRoute from './components/PrivateRoute'
import Toasty from './components/Toasty'
import UpdateBillingDetails from './components/UpdateBillingDetails'
import AddContact from './containers/AccessTokenPages/AddContact'
import BlackFridayHandsets from './containers/AccessTokenPages/BlackFridayHandsets'
import CallAnswering from './containers/AccessTokenPages/CallAnswering'
import CallBlockingUpsell from './containers/AccessTokenPages/CallBlocking'
import CallRecordingUpsell from './containers/AccessTokenPages/CallRecordingUpsell'
import DoubleTopup from './containers/AccessTokenPages/DoubleTopup'
import Handsets from './containers/AccessTokenPages/Handsets'
import MusicOnhold from './containers/AccessTokenPages/MusicOnhold'
import OutboundUpsell from './containers/AccessTokenPages/OutboundUpsell'
import PriceIncrease from './containers/AccessTokenPages/PriceIncrease'
import TranscriptionUpsell from './containers/AccessTokenPages/TranscriptionUpsell'
import VoIPUpsell from './containers/AccessTokenPages/VoIPUpsell'
import SwitchToAnnual from './containers/AccessTokenPages/SwitchToAnnual'
import AllCallsPage from './containers/AllCallsPage'
import BTSwitchOffGuide from './containers/BTSwitchOffGuide'
import CallInformation from './containers/CallInformation'
import CallMonitor from './containers/CallMonitor'
import CallTransfers from './containers/CallTransfers'
import Cancellation from './containers/Cancellation'
import Charities from './containers/Charities'
import AirLandline from './containers/Competitors/AirLandline'
import AnotherNumber from './containers/Competitors/AnotherNumber'
import bOnline from './containers/Competitors/bOnline'
import BTCloud from './containers/Competitors/BTCloud'
import Cleartone from './containers/Competitors/Cleartone'
import Competitors from './containers/Competitors/Competitors'
import Everreach from './containers/Competitors/Everreach'
import Flextel from './containers/Competitors/Flextel'
import HiHi from './containers/Competitors/HiHi'
import HiHiNaturalLanguage from './containers/Competitors/HiHiNaturalLanguage'
import NumberPeople from './containers/Competitors/NumberPeople'
import NumberSupermarket from './containers/Competitors/NumberSupermarket'
import PiPcall from './containers/Competitors/PiPcall'
import PocketLandline from './containers/Competitors/PocketLandline'
import Press1 from './containers/Competitors/Press1'
import RingCentral from './containers/Competitors/RingCentral'
import TamarTelecommunications from './containers/Competitors/TamarTelecommunications'
import TelavoxFlow from './containers/Competitors/TelavoxFlow'
import TTNC from './containers/Competitors/TTNC'
import VirtualLandline from './containers/Competitors/VirtualLandline'
import VirtuallyLocal from './containers/Competitors/VirtuallyLocal'
import Vonage from './containers/Competitors/Vonage'
import ConfigurationPage from './containers/ConfigurationPage'
import CookiePolicy from './containers/CookiePolicy'
import CustomerOutboundSettings from './containers/CustomerOutboundSettings'
import Customers from './containers/Customers'
import CustomerTeamsSettings from './containers/CustomerTeamsSettings'
import Dashboard from './containers/Dashboard'
import AppPage from './containers/Devices/AppPage'
import DeskPhoneVoIP from './containers/Devices/DeskPhoneVoIP'
import Devices from './containers/Devices/Devices'
import FanvilDongle from './containers/Devices/FanvilDongle'
import Softphone from './containers/Devices/Softphone'
import W73P from './containers/Devices/W73P'
import X3PhoneVoIP from './containers/Devices/X3PhoneVoIP'
import X7APhoneVoIP from './containers/Devices/X7APhoneVoIP'
import DisasterRecovery from './containers/DisasterRecovery'
import EmergencyServices from './containers/EmergencyServices'
import CallBlocking from './containers/Features/CallBlocking'
import CallRecordingAdWords from './containers/Features/CallRecording/CallRecordingAdWords'
import CallRecordingPurchase from './containers/Features/CallRecording/CallRecordingPurchase'
import CallRecordingList from './containers/Features/CallRecording/CallRecordingsList'
import CallReporting from './containers/Features/CallReporting'
import ContactBook from './containers/Features/ContactBook'
import CallScreening from './containers/Features/FeaturesPage/CallScreening'
import Departments from './containers/Features/FeaturesPage/Departments'
import FeaturesPage from './containers/Features/FeaturesPage/FeaturesPage'
import MissedCallAlerts from './containers/Features/FeaturesPage/MissedCallAlerts'
import OpenCloseTimes from './containers/Features/FeaturesPage/OpenCloseTimes'
import OutboundDialer from './containers/Features/FeaturesPage/OutboundDialer'
import Redirects from './containers/Features/FeaturesPage/Redirects'
import TimeOfDayRoutingDescription from './containers/Features/FeaturesPage/TimeOfDayRoutingDescription'
import WebHooks from './containers/Features/FeaturesPage/Webhooks/WebHooks'
import ZapierCapsule from './containers/Features/FeaturesPage/Webhooks/ZapierCapsule'
import ZapierGoogleSheets from './containers/Features/FeaturesPage/Webhooks/ZapierGoogleSheets'
import ZapierGoogleTasks from './containers/Features/FeaturesPage/Webhooks/ZapierGoogleTasks'
import ZapierHubspot from './containers/Features/FeaturesPage/Webhooks/ZapierHubspot'
import ZapierMicrosoftToDo from './containers/Features/FeaturesPage/Webhooks/ZapierMicrosoftToDo'
import ZapierSalesforce from './containers/Features/FeaturesPage/Webhooks/ZapierSalesforce'
import ZapierSlack from './containers/Features/FeaturesPage/Webhooks/ZapierSlack'
import ZapierTodoist from './containers/Features/FeaturesPage/Webhooks/ZapierTodoist'
import ZapierTrello from './containers/Features/FeaturesPage/Webhooks/ZapierTrello'
import ZapierZoho from './containers/Features/FeaturesPage/Webhooks/ZapierZoho'
import MusicOnHold from './containers/Features/MusicOnHold'
import Tps from './containers/Features/Tps'
import WebHookSettings from './containers/Features/WebHookSettings'
import ForgotPassword from './containers/ForgotPassword/ForgotPassword'
import ResetPassword from './containers/ForgotPassword/ResetPassword'
import HomePagev3 from './containers/HomePagev3'
import HowItWorks from './containers/HowItWorks/HowItWorks'
import OutboundCalling from './containers/HowItWorks/OutboundCalling'
import BTCloudLanding from './containers/LandingPages/BTCloud'
import DynamicLandingPage from './containers/LandingPages/DynamicLandingPage'
import InternationalCalls from './containers/LandingPages/InternationalCalls'
import LandlineOnMobile from './containers/LandingPages/LandlineOnMobile'
import LandlineOnMobileV2 from './containers/LandingPages/LandlineOnMobilev2'
import LoginPage from './containers/LoginPage'
import MobileNumberForBusiness from './containers/MobileNumberForBusiness'
import MyNumbersPage from './containers/MyNumbersPage'
import NotFound from './containers/NotFound'
import AreaCode from './containers/NumberTypes/AreaCode'
import CompareNumbers from './containers/NumberTypes/CompareNumbers'
import InboundCalling from './containers/NumberTypes/InboundCalling'
import MultipleNumbersPage from './containers/NumberTypes/MultipleNumbersPage'
import NumberPorting from './containers/NumberTypes/NumberPorting'
import Numbers0203 from './containers/NumberTypes/Numbers0203'
import Numbers0207 from './containers/NumberTypes/Numbers0207'
import Numbers0208 from './containers/NumberTypes/Numbers0208'
import Numbers03 from './containers/NumberTypes/Numbers03'
import Numbers0800 from './containers/NumberTypes/Numbers0800'
import Numbers0808 from './containers/NumberTypes/Numbers0808'
import Numbers084 from './containers/NumberTypes/Numbers084'
import NumbersLocal from './containers/NumberTypes/NumbersLocal'
import NumbersMultiPackage from './containers/NumberTypes/NumbersMultiPackage'
import NumbersUKCity from './containers/NumberTypes/NumbersUKCity'
import PocketReceptionist from './containers/OtherProducts/PocketReceptionist'
import PocketReceptionistLoggedIn from './containers/OtherProducts/PocketReceptionistLoggedIn'
import PocketReceptionistSMS from './containers/OtherProducts/PocketReceptionistSMS'
import PhoneAppRedirect from './containers/PhoneAppRedirect'
import PrivacyPolicy from './containers/PrivacyPolicy'
import ProfilePage from './containers/ProfilePage'
import RecordingsManagement from './containers/RecordingsManagement'
import AboutUs from './containers/Resources/AboutUs'
import BlogListing from './containers/Resources/Blog/BlogListing'
import BlogPost from './containers/Resources/Blog/BlogPost'
import ContactPage from './containers/Resources/ContactPage'
import FAQ from './containers/Resources/FAQ'
import EarbudsManual from './containers/Resources/Manuals/EarbudsManual'
import ManualCapsuleCRM from './containers/Resources/Manuals/ManualCapsuleCRM'
import OutboundManual from './containers/Resources/Manuals/OutboundManual'
import OutboundManualApp from './containers/Resources/Manuals/OutboundManualApp'
import OutboundManualDeskphone from './containers/Resources/Manuals/OutboundManualDeskphone'
import OutboundManualSoftphone from './containers/Resources/Manuals/OutboundManualSoftphone'
import OutboundManualSoftphoneMac from './containers/Resources/Manuals/OutboundManualSoftphoneMac'
import OutboundManualWirelessPhone from './containers/Resources/Manuals/OutboundManualWirelessPhone'
import TeamPage from './containers/Resources/Team'
import Videos from './containers/Resources/Videos'
import VoIPQualityTest from './containers/Resources/VoIPQualityTest'
import ChoosePlan from './containers/Signup/ChoosePlan'
import NumberWizard from './containers/Signup/NumberWizard'
import PaymentSetup from './containers/Signup/PaymentSetup'
import Signup from './containers/Signup/Signup'
import SignupType from './containers/Signup/SignupType'
import SiteMap from './containers/SiteMap'
import StartingBusiness from './containers/StartingBusiness'
import TermsAndConditionsWrapper from './containers/TermsAndConditionsWrapper'
import TestimonialsPage from './containers/TestimonialsPage'
import FaxesView from './containers/Upgrades/Fax/FaxesView'
import FaxToEmail from './containers/Upgrades/Fax/FaxToEmail'
import MemorableNumbers from './containers/Upgrades/MemorableNumbers'
import ProfessionalPrompts from './containers/Upgrades/ProfessionalPrompts'
import TopUps from './containers/Upgrades/TopUps'
import UnlimitedMobileRedirects from './containers/Upgrades/UnlimitedMobileRedirects'
import UpgradesPage from './containers/Upgrades/UpgradesPage'
import UpgradesUMR from './containers/Upgrades/UpgradesUMR'
import WABusiness from './containers/Upgrades/WABusiness'
import VipCallers from './containers/VipCallers'
import VirtualNumbersExplained from './containers/VirtualNumbersExplained'
import VirtualVoicemail from './containers/VirtualVoicemail'
import WhatIsVoIP from './containers/WhatIsVoIP'
import { fetchHelper } from './helpers/fetchHelper'
import FeatureEnabledDetector from './Pages/FeatureEnabledDetector'
import CaseStudies from './containers/CaseStudies/CaseStudies'
import Marketing from './containers/CaseStudies/Marketing'
import SoleTrader from './containers/CaseStudies/SoleTrader'
import NexusRamps from './containers/CaseStudies/NexusRamps'
import IanGarrett from './containers/CaseStudies/IanGarrett'


import SwapAccount from './components/SwapAccount'
import KeywordTriggersFunctionPage from './Pages/LoggedIn/Functions/KeywordTriggersFunctionPage'
import KeywordTriggersUpgradePage from './Pages/LoggedIn/Upgrades/KeywordTriggersUpgradePage'
import KeywordTriggersSalesPage from './Pages/LoggedOut/Features/KeywordTriggersSalesPage'

import CallTranscriptionFunctionPage from './Pages/LoggedIn/Functions/CallTranscriptionFunctionPage'
import CallTranscriptionUpgradePage from './Pages/LoggedIn/Upgrades/CallTranscriptionUpgradePage'
import CallTranscriptionSalesPage from './Pages/LoggedOut/Features/CallTranscriptionSalesPage'

import ConversationSearchFunctionPage from './Pages/LoggedIn/Functions/ConversationSearchFunctionPage'
import ConversationSearchUpgradePage from './Pages/LoggedIn/Upgrades/ConversationSearchUpgradePage'
import ConversationSearchSalesPage from './Pages/LoggedOut/Features/ConversationSearchSalesPage'

import GoldNumbers from './containers/AccessTokenPages/GoldNumbers'
import LandingPageDDISelector from './containers/Signup/LandingPageDDISelector'
import { isLoggedIn } from './selectors'
import { getFirstName, updateUserFromLocalStorage } from './store/SBF'

const NotFoundRoute = Route.NotFoundRoute

class App extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		if (window.gtag) {
			if (!localStorage.getItem('GaId')) {
				gtag('get', 'G-YRN8EZFX0M', 'client_id', (client_id) => {
					localStorage.setItem('GaId', client_id)
				})
			}
		}

		const profilePage = <ProfilePage key={window.location.pathname} />
		const myCallsPage = <MyCalls key={window.location.pathname} />

		if (window.location !== window.parent.location) {
			window.parent.location.href = 'https://www.switchboardfree.co.uk'
			return <></>
		} else {
			return (<>

				<ExitIntent />

				<Toasty />

				<HeaderSimple />

				<HeaderSimpleV2 />

				<Cookies />

				<HeaderMain />

				<Helmet titleTemplate="%s | SwitchboardFREE">

					{this.props.isLoggedIn &&
						<body className="logged-in" />
					}

					{/* FB  */}
					<meta property="og:title" content="Virtual Phone Numbers, VoIP Phone Systems - SwitchboardFREE" />
					<meta property="og:description " content="SwitchboardFREE provide virtual landlines and complete VoIP phone systems to over 80,000 UK businesses, all at industry beating prices! " />
					<meta property="og:locale " content="en_GB" />
					<meta property="og:type" content="website" />
					<meta property="og:site_name" content="SwitchboardFREE" />
					<meta property="og:url" content="https://www.switchboardfree.co.uk/" />
					<meta property="og:image" content="https://www.switchboardfree.co.uk/images/open-graph.jpg" />

					{/* Twitter */}
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:creator" content="SwitchboardFREE" />
					<meta name="twitter:title" content="Virtual Phone Numbers, VoIP Phone Systems - SwitchboardFREE" />
					<meta name="twitter:description" content="SwitchboardFREE provide virtual landlines and complete VoIP phone systems to over 80,000 UK businesses, all at industry beating prices! " />
					<meta name="twitter:url" content="https://www.switchboardfree.co.uk/" />
					<meta name="twitter:image" content="https://www.switchboardfree.co.uk/images/open-graph.jpg" />

				</Helmet>

				<Switch>

					{/* 301 Redirects */}
					<Route exact path="/03numbers.aspx" component={() => <Redirect to="/virtual-phone-numbers" />} />
					<Redirect from="/pricing" to="/get-started" />
					<Redirect from="/costs" to="/get-started" />
					<Redirect from="/app/iphone/" to="/app/" />
					<Redirect exact from="/customer/callInformation/:callId?" to="/customer/my-calls/call-information/0/:callId?" />
					<Redirect exact from="/customer/my-calls/call-information/:callId?" to="/customer/my-calls/call-information/0/:callId?" />
					<Redirect from="/app" to="/products/app" />
					<Redirect from="/softphone" to="/products/softphone" />
					<Redirect from="/customer/memorable-phone-numbers" to="/customer/upgrades/memorable-phone-numbers" />
					<Redirect from="/free-community-support-line" to="/" />
					<Redirect from='/outbound-calling' to="/get-started" />
					<Redirect from="/area-codes" to="/city-numbers/" />
					<Redirect from="/getting-started/app" to="/setup/app" />
					<Redirect from="/getting-started/redirects" to="/redirecting-calls" />
					<Redirect from="/getting-started/phones" to="/phones-apps" />
					<Redirect from="/getting-started/softphone" to="/setup/softphone" />
					<Redirect from="/getting-started" to="/phones-apps" />
					<Redirect from="/black-friday/voip-phones:queryString(\&.*)?" to="/phones-apps" />
					<Redirect from="/get-outbound:queryString(\&.*)?" to="/get-started" />
					<Redirect from="/products/yealink-w52p-wireless-phone" to="/products/yealink-w73p-cordless-phone" />
					<Redirect from="/deals/call-recording:queryString(\&.*)?" to="/call-recording" />
					<Redirect from="/deals/call-recording/:existing:queryString(\&.*)?" to="/call-recording" />
					<Redirect from="/huawei-battery-power-saving" to="/" />
					<Redirect from="/custom-prompts" to="/professional-prompts/" />
					<Redirect from="/fax-to-email:queryString(\&.*)?" to="/" />
					<Redirect from="/become-a-reseller/" to="/" />
					<Redirect from="/free-conference-calling/" to="/" />
					<Redirect from="/customer/voip-setup" to="/" />
					<Redirect from="/integrations/salesforce" to="/web-hook-integrations/salesforce" />
					<Redirect from="/integrations/zoho-crm" to="/web-hook-integrations/zoho" />
					<Redirect from="/integrations/fresh-sales" to="/webhooks/" />
					<Redirect from="/call-tracking-website-traffic-sources/" to="/webhooks/" />
					<Redirect from="/call-tracking-visitor-session/" to="/webhooks/" />
					<Redirect from="/call-tracking-online-advertising/" to="/webhooks/" />
					<Redirect from="/call-tracking-offline-advertising/" to="/webhooks/" />
					<Redirect from="/call-tracking/" to="/webhooks/" />
					<Redirect from="/customer/call-filtering:queryString(\&.*)?" to="/customer/call-blocking/" />
					<Redirect from="/customer/upgrades/call-filtering:queryString(\&.*)?" to="/customer/call-blocking/" />

					{/* -------  BELOW ARE ALL LOGGED IN PAGES ---------- */}

					{/* Contact book / CRM integration pages */}
					<PrivateRoute exact path="/customer/swap-account" component={SwapAccount} />
					<PrivateRoute exact path='/customer/contact-book/' component={ContactBook} />
					<PrivateRoute exact path='/customer/contact-sync/capsule-crm/:code?' component={ContactBookSyncCapsuleCrm} />
					{/* below pipedrive routes need to handle login/signup redirection differently */}
					{/* due to pipedrive requirements and are therefore intentially implemented as "Route" */}
					<Route exact path='/customer/contact-sync/pipedrive-crm/:code?' component={ContactBookSyncPipedriveCrm} />
					<Route exact path='/pipedrive-request/:code?' component={PipedriveInboundRequest} />
					<PrivateRoute exact path='/customer/contact-sync/HubSpotCrm/:code?' component={ContactBookSyncHubSpotCrm} />

					{/* Customer Upgrades */}
					<PrivateRoute exact path='/customer/upgrades/professional-prompts/' component={ProfessionalPrompts} />
					<PrivateRoute exact path='/customer/upgrades/call-blocking:queryString(\&.*)?' component={CallBlocking} />
					<PrivateRoute exact path='/customer/upgrades/' component={UpgradesPage} />
					<PrivateRoute exact path='/customer/upgrades/all' component={UpgradesPage} />
					<PrivateRoute exact path='/customer/upgrades/contact-book/' component={ContactBook} />
					<PrivateRoute exact path='/customer/upgrades/unlimited-mobile-redirects:queryString(\&.*)?' component={UpgradesUMR} />
					<PrivateRoute exact path='/customer/upgrades/call-prioritiser/' component={VipCallers} />
					<PrivateRoute exact path="/customer/upgrades/multi-numbers-package:queryString(\&.*)?" component={NumbersMultiPackage} />
					<PrivateRoute exact path='/customer/upgrades/call-recording/' component={CallRecordingPurchase} />
					<PrivateRoute exact path='/customer/upgrades/call-recording:queryString(\&.*)?' component={CallRecordingPurchase} />
					<PrivateRoute exact path='/customer/upgrades/fax-to-email-number/' component={FaxToEmail} />
					<PrivateRoute exact path='/customer/upgrades/memorable-phone-numbers:queryString(\&.*)?' component={MemorableNumbers} />
					<PrivateRoute exact path='/customer/upgrades/top-up:queryString(\&.*)?' component={TopUps} />
					<PrivateRoute exact path='/customer/telephone-answering-service/' component={PocketReceptionistLoggedIn} />

					<PrivateRoute exact path="/features/conversation-search" component={ConversationSearchSalesPage} />
					<PrivateRoute exact path="/features/call-transcription" component={CallTranscriptionSalesPage} />
					<PrivateRoute exact path="/features/keyword-triggers" component={KeywordTriggersSalesPage} />

					<PrivateRoute exact path="/features/tps-protection" component={Tps} />
					<PrivateRoute exact path='/customer/call-blocking:queryString(\&.*)?' component={CallBlocking} />
					<PrivateRoute exact path='/customer/professional-prompts/' component={ProfessionalPrompts} />
					<PrivateRoute exact path='/customer/fax-to-email-number/' component={FaxToEmail} />
					<PrivateRoute exact path='/customer/unlimited-mobile-redirects:queryString(\&.*)?' component={UpgradesUMR} />
					<PrivateRoute exact path="/customer/multi-numbers-package:queryString(\&.*)?" component={NumbersMultiPackage} />
					<PrivateRoute exact path='/customer/call-prioritiser/' component={VipCallers} />
					<PrivateRoute exact path="/customer/call-recording:queryString(\&.*)?/:recordingId?" component={CallRecordingList} />

					<PrivateRoute exact path="/customer/call-transcription" component={() =>
						<FeatureEnabledDetector
							featureId={138}
							enabledComponent={() => <CallTranscriptionFunctionPage />}
							disabledComponent={() => <CallTranscriptionUpgradePage />}
						/>}
					/>

					<PrivateRoute exact path="/customer/keyword-triggers" component={() =>
						<FeatureEnabledDetector
							featureId={138}
							enabledComponent={() => <KeywordTriggersFunctionPage />}
							disabledComponent={() => <KeywordTriggersUpgradePage />}
						/>}
					/>

					<PrivateRoute exact path="/customer/conversation-search" component={() =>
						<FeatureEnabledDetector
							featureId={138}
							enabledComponent={() => <ConversationSearchFunctionPage />}
							disabledComponent={() => <ConversationSearchUpgradePage />}
						/>}
					/>

					{/* New number */}
					<PrivateRoute path='/customer/numbers/new/type' component={SignupType} />
					<PrivateRoute path='/customer/numbers/new/plan' component={ChoosePlan} />
					<PrivateRoute path="/customer/numbers/new/:numberType?" component={Signup} />

					{/* misc logged in page */}
					<PrivateRoute exact path="/manage-recordings" component={RecordingsManagement} />
					<PrivateRoute exact path="/call-monitor/:ddi(\d*)" component={CallMonitor} />
					<PrivateRoute exact path="/customer/numbers/:queueNo(\d*)/:depNo(\d)?/:scrollSection?" component={ConfigurationPage} />
					<PrivateRoute exact path='/customer/numbers/faxes/:faxNo(\d*)' component={FaxesView} />
					<PrivateRoute exact path='/customer/numbers/faxes/:faxNo(\d*)/config' component={FaxesConfig} />
					<PrivateRoute exact path='/customer/invoice/:invId(\d*)/:format?' component={InvoiceViewer} />
					<PrivateRoute exact path="/customer/calls:queryString(\&.*)?" component={AllCallsPage} />
					<PrivateRoute exact path='/customer/top-up:queryString(\&.*)?' component={TopUps} />
					<PrivateRoute exact path="/customer/profile:queryString(\&.*)?/:activeTab(\d*)?/:scrollSection?/" component={() => profilePage} />
					<PrivateRoute exact path="/customer/profile/crm-settings/:scrollSection?" component={() => profilePage} />
					<PrivateRoute exact path="/customer/profile/your-details" component={() => profilePage} />
					<PrivateRoute exact path="/customer/profile/security" component={() => profilePage} />
					<PrivateRoute exact path="/customer/profile/billing" component={() => profilePage} />
					<PrivateRoute exact path="/customer/profile/subscriptions-and-numbers" component={() => profilePage} />
					<PrivateRoute exact path="/customer/profile/call-recording-settings" component={() => profilePage} />
					<PrivateRoute exact path="/customer/callInformation/:callId?" component={CallInformation} />
					<PrivateRoute exact path="/customer/dashboard:queryString(\&.*)?" component={Dashboard} />
					<PrivateRoute exact path="/customer/numbers:queryString(\&.*)?" component={MyNumbersPage} />
					<PrivateRoute exact path="/customer/my-calls" component={() => myCallsPage} />
					<PrivateRoute exact path="/customer/my-calls/edit-alert/:callId?" component={() => myCallsPage} />
					<PrivateRoute exact path="/customer/my-calls/create-alert" component={() => myCallsPage} />
					<PrivateRoute exact path="/customer/my-calls/voicemail-details/:callId?" component={() => myCallsPage} />
					<PrivateRoute exact path="/customer/my-calls/call-stats/:numberId?" component={() => myCallsPage} />
					<PrivateRoute exact path="/customer/my-calls/call-alerts" component={() => myCallsPage} />
					<PrivateRoute exact path="/customer/my-calls/voicemails" component={() => myCallsPage} />
					<PrivateRoute exact path="/customer/my-calls/call-information/:isOutbound/:callId?" component={() => myCallsPage} />
					<PrivateRoute exact path="/customer/outbound-management:queryString(\&.*)?" component={CustomerOutboundSettings} />
					<PrivateRoute exact path="/customer/outbound-management/:createuser?" component={CustomerOutboundSettings} />
					<PrivateRoute exact path="/customer/team-management/:createteam?" component={CustomerTeamsSettings} />

					{/* we are using a token to authenticate this request before popping customer CRM page associated with the contact */}
					<Route exact path='/customer/pop/:token?/:number?' component={CrmRedirectWebhookPage} />

					{/* -------  BELOW ARE ALL LOGGED OUT PAGES ---------- */}

					{/* Pages for debt payment link /> */}
					<Route exact path="/UpdateBillingDetails/:uniqueId:queryString(\&.*)?" component={UpdateBillingDetails} />
					<Route exact path="/UpdateBillingDetails" component={UpdateBillingDetails} />
					<Route exact path="/ubd/:uniqueId:queryString(\&.*)?" component={UpdateBillingDetails} />
					<Route exact path="/ubd" component={UpdateBillingDetails} />

					{/* Post Signup pages */}
					<PrivateRoute exact path='/signup/checkout/complete:queryString(\&.*)?' component={PaymentSetup} />
					<PrivateRoute exact path='/customer/number-setup-wizard/:step(\d*)?:queryString(\&.*)?' component={NumberWizard} />
					<PrivateRoute exact path='/customer/number-setup-wizard:queryString(\&.*)?' component={NumberWizard} />

					{/* Signup pages */}
					<Route exact path="/login/linkedin" component={LinkedinRedirect} />
					<Route exact path='/accept-quote/:signupKey?:queryString(\&.*)?' component={Signup} />
					<Route exact path='/get-started:queryString(\&.*)?' component={Signup} />
					<Route exact path='/get-started/:signupKey?:queryString(\&.*)?' component={Signup} />
					<Route exact path='/signup/1:queryString(\&.*)?' component={Signup} />
					<Route exact path='/signup/1/:selectedNumberType(\d*):queryString(\&.*)?' component={Signup} />
					<Route exact path='/signup/start:queryString(\&.*)?' component={Signup} />
					<Route exact path='/signup/plan:queryString(\&.*)?' component={Signup} />
					<Route exact path='/signup/info:queryString(\&.*)?' component={Signup} />
					<Route exact path='/signup/info/:selectedNumberType(\d*):queryString(\&.*)?' component={Signup} />
					<Route exact path='/signup/numbers/:selectedNumberType?' component={Signup} />
					<Route exact path="/signup/phones/" component={Signup} />
					<Route exact path="/signup/checkout:queryString(\&.*)?" component={Signup} />

					{/* Numbers & Number types */}
					<Route exact path='/area-code/:prefix(\d*)-:location*-numbers' component={AreaCode} />
					<Route exact path='/area-code/:prefix(\d*)/:location*' component={AreaCode} />
					<Route exact path="/0800-numbers/" component={Numbers0800} />
					<Route exact path="/0808-numbers/" component={Numbers0808} />
					<Route exact path="/03-numbers/" component={Numbers03} />
					<Route exact path="/084-numbers/" component={Numbers084} />
					<Route exact path="/local-phone-numbers/" component={NumbersLocal} />
					<Route exact path="/0207-numbers/" component={Numbers0207} />
					<Route exact path="/0208-numbers/" component={Numbers0208} />
					<Route exact path="/0203-numbers/" component={Numbers0203} />
					<Route exact path="/city-numbers" component={NumbersUKCity} />
					<Route exact path="/compare-business-numbers/" component={CompareNumbers} />
					<Route exact path="/number-types/" component={CompareNumbers} />
					<Route exact path='/golden-phone-numbers:queryString(\&.*)?' component={MemorableNumbers} />

					{/* Support & Instructions */}
					<Route exact path='/setup/app' component={OutboundManualApp} />
					<Route exact path='/setup/wireless-phone' component={OutboundManualWirelessPhone} />
					<Route exact path='/setup/deskphone' component={OutboundManualDeskphone} />
					<Route exact path='/setup/softphone/mac' component={OutboundManualSoftphoneMac} />
					<Route exact path='/setup/softphone' component={OutboundManualSoftphone} />
					<Route exact path='/setup/' component={OutboundManual} />
					<Route exact path='/setup/earbuds' component={EarbudsManual} />
					<Route exact path='/call-transfers' component={CallTransfers} />
					<Route exact path="/forgot-password" component={ForgotPassword} />
					<Route exact path="/reset-password/:email(\&.*)" component={ResetPassword} />
					<Route exact path="/reset-password/:key(.*)" component={ResetPassword} />
					<Route exact path='/get-app/' component={PhoneAppRedirect} />
					<Route exact path="/login:queryString(\&.*)?" component={LoginPage} />
					<Route exact path="/contact/" component={ContactPage} />

					{/* features */}
					<Route exact path='/professional-prompts:queryString(\&.*)?' component={ProfessionalPrompts} />
					<Route exact path='/whatsapp-business-number/:existing:queryString(\&.*)?' component={WABusiness} />
					<Route exact path='/whatsapp-business-number:queryString(\&.*)?' component={WABusiness} />
					<Route exact path='/whatsapp:queryString(\&.*)?' component={WABusiness} />
					<Route exact path='/setup/capsule' component={ManualCapsuleCRM} />
					<Route exact path='/virtual-phone-numbers' component={InboundCalling} />
					<Route exact path='/multi-number-package' component={MultipleNumbersPage} />
					<Route exact path='/call-recording/' component={CallRecordingPurchase} />
					<Route exact path='/call-recording//:existing:queryString(\&.*)?' component={CallRecordingPurchase} />
					<Route exact path='/phone-call-recording/' component={CallRecordingAdWords} />
					<Route exact path='/telephone-answering-service/' component={PocketReceptionist} />
					<Route exact path='/telephone-answering-service-activate/' component={PocketReceptionistSMS} />
					<Route exact path="/features/:activeTab(\d*)?" component={FeaturesPage} />
					<Route exact path="/music-on-hold" component={MusicOnHold} />
					<Route exact path="/call-reports/" component={CallReporting} />
					<Route exact path="/call-screening" component={CallScreening} />
					<Route exact path="/outbound-dialer" component={OutboundDialer} />
					<Route exact path="/open-close-your-phone-lines" component={OpenCloseTimes} />
					<Route exact path="/time-of-day-call-routing" component={TimeOfDayRoutingDescription} />
					<Route exact path="/departments-selection" component={Departments} />
					<Route exact path="/missed-call-email-alerts" component={MissedCallAlerts} />
					<Route exact path="/redirecting-calls" component={Redirects} />

					{/* Content pages */}
					<Route exact path='/blog/:page(\d*)?' component={BlogListing} />
					<Route exact path='/blog/:slug*' component={BlogPost} />
					<Route exact path='/bt-landline-switchoff-guide' component={BTSwitchOffGuide} />
					<Route exact path='/reasons-not-to-use-mobile-for-business' component={MobileNumberForBusiness} />
					<Route exact path='/how-to-start-a-business' component={StartingBusiness} />
					<Route exact path='/voip-quality-test' component={VoIPQualityTest} />
					<Route exact path='/what-is-voip-phone-system' component={WhatIsVoIP} />
					<Route exact path='/business-disaster-recovery' component={DisasterRecovery} />
					<Route exact path='/what-are-virtual-numbers' component={VirtualNumbersExplained} />
					<Route exact path='/business-voip-phone-system' component={OutboundCalling} />
					<Route exact path='/get/landline-on-mobile/' component={LandlineOnMobileV2} />
					<Route exact path='/landline-on-mobile/' component={LandlineOnMobile} />
					<Route exact path="/sitemap" component={SiteMap} />
					<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					<Route exact path="/cookie-policy" component={CookiePolicy} />
					<Route exact path="/terms-and-conditions" component={TermsAndConditionsWrapper} />
					<Route exact path='/call-divert-mobile/' component={UnlimitedMobileRedirects} />
					<Route exact path="/keep-my-number/" component={NumberPorting} />
					<Route exact path="/charities/" component={Charities} />
					<Route exact path="/emergency-services" component={EmergencyServices} />
					<Route exact path='/videos/' component={Videos} />
					<Route exact path='/about-us/' component={AboutUs} />
					<Route exact path='/cancellation' component={Cancellation} />
					<Route exact path="/meet-the-team/" component={TeamPage} />
					<Route exact path="/customers" component={Customers} />
					<Route exact path='/faq/' component={FAQ} />
					<Route exact path='/reviews/' component={TestimonialsPage} />
					<Route exact path="/how-it-works" component={HowItWorks} />
					<Route exact path="/:queryString(\&.*)?" component={HomePagev3} />
					<Route exact path="/virtual-voicemail" component={VirtualVoicemail} />
					<Route exact path="/international-calls" component={InternationalCalls} />

					{/* Case study pages */}
					<Route exact path='/case-studies/' component={CaseStudies} />
					<Route exact path='/case-studies/soletrader/' component={SoleTrader} />
					<Route exact path='/case-studies/marketing/' component={Marketing} />
					<Route exact path='/case-studies/ian-garrett/' component={IanGarrett} />
					<Route exact path='/case-studies/nexus-ramps/' component={NexusRamps} />


					{/* PPC Landing Pages & promotional pages */}
					<Route exact path='/get/virtual-phone-numbers:queryString(\&.*)?' component={DynamicLandingPage} />
					<Route exact path='/get/voip-phone-system:queryString(\&.*)?' component={DynamicLandingPage} />
					<Route exact path='/get/business-phone-system:queryString(\&.*)?' component={DynamicLandingPage} />
					<Route exact path='/bt-cloud-alternative:queryString(\&.*)?' component={BTCloudLanding} />
					<Route exact path="/get-call-recording:queryString(\&.*)?" component={CallRecordingUpsell} />
					<Route exact path="/bare-bones-ddi/:prefix(\d*)?" component={LandingPageDDISelector} />

					{/* Handsets & softphones */}
					<Route exact path='/products/yealink-w73p-cordless-phone' component={W73P} />
					<Route exact path='/products/advanced-voip-phone' component={DeskPhoneVoIP} />
					<Route exact path='/products/voip-phone' component={X3PhoneVoIP} />
					<Route exact path='/products/x7a-voip-phone' component={X7APhoneVoIP} />
					<Route exact path="/products/softphone" component={Softphone} />
					<Route exact path='/voip-handsets' component={Devices} />
					<Route exact path='/products/app/' component={AppPage} />
					<Route exact path='/phones-apps' component={Devices} />
					<Route exact path='/products/fanvil-wifi-dongle' component={FanvilDongle} />

					{/* Competitor Stuff */}
					<Route exact path='/competitors/' component={Competitors} />
					<Route exact path='/competitors/number-supermarket/' component={NumberSupermarket} />
					<Route exact path='/competitors/number-people/' component={NumberPeople} />
					<Route exact path='/competitors/flextel/' component={Flextel} />
					<Route exact path='/competitors/another-number/' component={AnotherNumber} />
					<Route exact path='/competitors/bt-cloud/' component={BTCloud} />
					<Route exact path='/competitors/telavox-flow/' component={TelavoxFlow} />
					<Route exact path='/competitors/cleartone/' component={Cleartone} />
					<Route exact path='/competitors/press1/' component={Press1} />
					<Route exact path='/competitors/ring-central/' component={RingCentral} />
					<Route exact path='/competitors/vonage/' component={Vonage} />
					<Route exact path='/competitors/everreach/' component={Everreach} />
					<Route exact path='/competitors/pocket-landline/' component={PocketLandline} />
					<Route exact path='/competitors/virtual-landline/' component={VirtualLandline} />
					<Route exact path='/competitors/virtually-local/' component={VirtuallyLocal} />
					<Route exact path='/competitors/tamar-telecommunications/' component={TamarTelecommunications} />
					<Route exact path='/competitors/TTNC/' component={TTNC} />
					<Route exact path='/competitors/hihi/' component={HiHi} />
					<Route exact path='/competitors/bonline' component={bOnline} />
					<Route exact path='/competitors/air-landline' component={AirLandline} />
					<Route exact path='/competitors/pipcall' component={PiPcall} />
					<Route exact path='/hihi' component={HiHiNaturalLanguage} />

					{/* Integrations */}
					<Route exact path="/webhooks/" component={WebHooks} />
					<Route exact path="/web-hook-settings/" component={WebHookSettings} />
					<Route exact path="/web-hook-integrations/salesforce" component={ZapierSalesforce} />
					<Route exact path="/web-hook-integrations/hubspot" component={ZapierHubspot} />
					<Route exact path="/web-hook-integrations/trello" component={ZapierTrello} />
					<Route exact path="/web-hook-integrations/slack" component={ZapierSlack} />
					<Route exact path="/web-hook-integrations/google-sheets" component={ZapierGoogleSheets} />
					<Route exact path="/web-hook-integrations/google-tasks" component={ZapierGoogleTasks} />
					<Route exact path="/web-hook-integrations/capsule" component={ZapierCapsule} />
					<Route exact path="/web-hook-integrations/todoist" component={ZapierTodoist} />
					<Route exact path="/web-hook-integrations/zoho" component={ZapierZoho} />
					<Route exact path="/web-hook-integrations/microsoft-to-do" component={ZapierMicrosoftToDo} />
					<Route exact path="/integrations/hubspot" component={Hubspot} />
					<Route exact path="/integrations/pipedrive-crm" component={PipedriveCrm} />
					<Route exact path="/integrations/capsule-crm" component={CapsuleCrm} />

					{/* AccessToken pages */}
					<Route exact path="/access/add-contact:queryString(\&.*)?" component={AddContact} />
					<Route exact path="/access/call-blocking:queryString(\&.*)?" component={CallBlockingUpsell} />
					<Route exact path="/access/outbound:queryString(\&.*)?" component={OutboundUpsell} />
					<Route exact path="/deal/call-recording:queryString(\&.*)?" component={CallRecordingUpsell} />
					<Route exact path="/get/call-answering-bundle:queryString(\&.*)?" component={CallAnswering} />
					<Route exact path="/get/double-credit:queryString(\&.*)?" component={DoubleTopup} />
					<Route exact path="/get/free-voip-phone:queryString(\&.*)?" component={VoIPUpsell} />
					<Route exact path="/deal/new-users:queryString(\&.*)?" component={VoIPUpsell} />
					<Route exact path="/price-increase:queryString(\&.*)?" component={PriceIncrease} />
					<Route exact path="/get/handsets:queryString(\&.*)?" component={Handsets} />
					<Route exact path="/get/call-transcription:queryString(\&.*)?" component={TranscriptionUpsell} />
					<Route exact path="/update/hold-music:queryString(\&.*)?" component={MusicOnhold} />
					<Route exact path="/get/golden-numbers/:prefix(\d*)?:queryString(\&.*)?" component={GoldNumbers} />
					<Route exact path="/deal/black-friday:queryString(\&.*)?" component={BlackFridayHandsets} />
					<Route exact path="/deal/save-with-annual:queryString(\&.*)?" component={SwitchToAnnual} />


					{/* 404 Error */}
					<Route path="*" component={NotFound} />

				</Switch>

				<FooterSimple />
				{this.props.isLoggedIn &&
					<LoggedInFooter />
				}
				<ConfigFooter />
				<FooterMain loggedin={this.props.userFirstName} />
			</>
			)
		}
	}

	componentWillMount() {
		const userDb = localStorage.getItem('user')
		if (userDb) {
			const user = JSON.parse(userDb)
			if (user) {
				// this.setState({ user: user });
				this.props.updateUserFromLocalStorage(user)
				// fetchHelper.init(user.token);
				fetchHelper.init(user.token)
			}
		}
	}
}

export default withRouter(connect(
	state => {
		return ({
			userFirstName: getFirstName(state),
			isLoggedIn: isLoggedIn(state)
		})
	}, { updateUserFromLocalStorage }
)(App))
