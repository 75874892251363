import moment from 'moment'
import React from 'react'
import { Button, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import Datetime from 'react-datetime';
import { connect } from 'react-redux'
import CallbackRequest from '../../components/CallbackRequest'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isValidUrlSyntax } from '../../helpers/validationHelpers'
import { isLoggedIn } from '../../selectors'
import { handleNotificationChange } from '../../store/SBF'

class WebHookSettings extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      webhookBody: '',
      webhookUrl: '',
      eleId: 'webhookBody',
      showTestModal: false,
      testValueCli: '0123456789',
      testValueCliWithheld: 0,
      testValueDdi: '9876543210',
      testValueInternational: 0,
      testValueConnected: 0,
      testValueDdiDescription: 'test description',
      testValueVoiceMail: 0,
      testValueCallDuration: moment(),
      testValueHoldTime: moment(),
      testValueDepartmentSelection: '1',
      testValueCallDate: moment()
    }
  }

  handleInsertAtCursor (val) {
    const newVal = window.insertAtCursor.insert(this.state.eleId, '%%' + val + '%%')
    if (this.state.eleId === 'webhookBody') {
      this.setState({ webhookBody: newVal })
    } else {
      this.setState({ webhookUrl: newVal })
    }
  }

  checkUrlValidation (url) {
    const isValidUrl = isValidUrlSyntax(url)
    this.setState({ urlValid: isValidUrl })
  }

  checkBodyValidation (string) {
    const isValid = string.length > 0
    this.setState({ bodyValid: isValid })
  }

  handleSubmit (context) {
    if (this.state.urlValid === true && this.state.bodyValid === true) {
      const url = '/api/Users/AddOrUpdateWebhookSettings'
      const data = Object.assign(
        { id: this.state.webhookId ? this.state.webhookId : null },
        { url: this.state.webhookUrl },
        { urlParams: this.state.webhookBody },
        { method: this.state.webhookMethod },
        { contentType: this.state.webhookType }
      )
      fetchHelper.postJson(url, data)
        .then(res => {
          if (res.data === true) {
            this.props.notify({ message: 'Webhook settings updated' })
          } else {
            this.setState({ formFailed: true })
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      this.setState({ formError: true })
    }
  }

  runWebhookTest () {
    this.checkUrlValidation(this.state.webhookUrl)
    this.checkBodyValidation(this.state.webhookBody)
    if (this.state.urlValid === true && this.state.bodyValid === true) {
      let body = this.state.webhookBody
      body = body.replace('%%cli%%', this.state.testValueCli)
      body = body.replace('%%cli-withheld%%', this.state.testValueCliWithheld)
      body = body.replace('%%ddi%%', this.state.testValueDdi)
      body = body.replace('%%international%%', this.state.testValueInternational)
      body = body.replace('%%connected%%', this.state.testValueConnected)
      body = body.replace('%%ddi-description%%', this.state.testValueDdiDescription)
      body = body.replace('%%voicemail%%', this.state.testValueVoiceMail)
      body = body.replace('%%call-duration%%', this.state.testValueCallDuration)
      body = body.replace('%%hold-time%%', this.state.testValueHoldTime)
      body = body.replace('%%department-selection%%', this.state.testValueDepartmentSelection)
      body = body.replace('%%call-date%%', this.state.testValueCallDate)

      const data = {
        Url: this.state.webhookUrl,
        Method: this.state.webhookMethod,
        ContentType: this.state.webhookType,
        Body: JSON.stringify(body)
      }

      fetchHelper.postJson('/api/Users/RegisterWebhookRequest', data)
        .then(res => {
          if (res.data === true) {
            this.props.notify({ message: 'Webhook Request Registered' })
          } else {
            this.props.notify({ message: 'Webhook Request Failed' })
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      this.setState({ formError: true })
    }
  }

  render () {
    const dataFieldsPopover = (
			<Popover>
				<p>Data fields are placeholders which you can put into your URL and Body fields that will get replaced with real time data as the request is sent to your API. Place holders should begin and end with %% (e.g. %%cli%%). The list below details all of the fields available to use. These can either be typed in by hand or for convenience you can click on the buttons available under the heading "Available Data Fields"</p>
			</Popover>
    )

    const cliPopover = (
			<Popover>
				<p>The number that the call originated from</p>
			</Popover>
    )

    const cliWithheldPopover = (
			<Popover>
				<p>"1" or "0" to indicate whether the caller withheld their CLI</p>
			</Popover>
    )

    const ddiPopover = (
			<Popover>
				<p>The SwitchboardFREE number that the caller called</p>
			</Popover>
    )

    const internationalPopover = (
			<Popover>
				<p>"1" or "0" to indicate whether the call originated from outside of the UK</p>
			</Popover>
    )

    const connectedPopover = (
			<Popover>
				<p>"1" or "0" to indicate whether the call was answered (when the whisper feature is being used this requires the * key to be pressed for the call to be connected)</p>
			</Popover>
    )

    const ddiDescriptionPopover = (
			<Popover>
				<p>The description that has been assigned to the DDI in the SwitchboardFREE portal</p>
			</Popover>
    )

    const voicemailPopover = (
			<Popover>
				<p>"1" or "0" to indicate whether the caller left a voicemail</p>
			</Popover>
    )

    const callDurationPopover = (
			<Popover>
				<p>The total duration of the call in seconds</p>
			</Popover>
    )

    const holdTimePopover = (
			<Popover>
				<p>The duration in seconds the caller was on hold before the call is connected, a voicemail is left or the caller hangs up</p>
			</Popover>
    )

    const popover = (
			<Popover>

			</Popover>
    )

    const departmentSelectionPopover = (
			<Popover>
				<p>This is only applicable when the auto-attendant feature is being used. This contains the number of the department the caller chose to be put through to. Note: if multiple departments are selected this field will contain the last selected department</p>
			</Popover>
    )

    const callDatePopover = (
			<Popover>
				<p>The date and time that the call was made to the SwitchboardFREE number.</p>
			</Popover>
    )

    return <>
			<ScrollToTopOnMount /><DisplayLoggedInFooter /><DisplayMainHeader />
			<header style={{ backgroundImage: "url('/images/backgrounds/profile-bg.jpg')" }} className="full-width full-hero-small">
			</header>

			<Modal className="modal-med question-modal" show={this.state.showTestModal} onHide={e => this.setState({ showTestModal: false })}>
				<Modal.Body className="">
					<div className="row mt-5 mb-10 text-center">
						<div className="col-md-12">
							<h2> Test Webhook </h2>
							<p> if any of the fields are not used in the body, just leave them blank </p>
						</div>
					</div>
					<hr />
					<div className="row mt-5">
						<div className="col-md-4 text-center">
							Field
						</div>
						<div className="col-md-8 text-center">
							Value
						</div>
					</div>
					<hr />
					<div className="row mt-5">
						<div className="col-md-4 pt-10">
							call-date
						</div>
						<div className="col-md-8 text-center">
							<Datetime dateFormat="DD/MM/YYYY" type="text" initialValue={this.state.testValueCallDate} onChange={(e) => this.setState({ testValueCallDate: e })}></Datetime>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							call-duration
						</div>
						<div className="col-md-8 text-center">
							<Datetime dateFormat={false} type="text" initialValue={this.state.testValueCallDuration} onChange={(e) => this.setState({ testValueCallDuration: e })}></Datetime>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							cli
						</div>
						<div className="col-md-8 text-center">
							<input className="form-control" type="number" min={0} defaultValue={this.state.testValueCli} onChange={(e) => this.setState({ testValueCli: e.target.value })}></input>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							cli-withheld
						</div>
						<div className="col-md-8 text-center">
							<select className="form-control" defaultValue={this.state.testValueCliWithheld} onChange={(e) => this.setState({ testValueCliWithheld: e.target.value })}>
								<option label="No" value={0} />
								<option label="Yes" value={1} />
							</select>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							connected
						</div>
						<div className="col-md-8 text-center">
							<select className="form-control" defaultValue={this.state.testValueConnected} onChange={(e) => this.setState({ testValueConnected: e.target.value })}>
								<option label="No" value={0} />
								<option label="Yes" value={1} />
							</select>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							ddi
						</div>
						<div className="col-md-8 text-center">
							<input className="form-control" type="number" defaultValue={this.state.testValueDdi} onChange={(e) => this.setState({ testValueDdi: e.target.value })}></input>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							ddi-description
						</div>
						<div className="col-md-8 text-center">
							<input className="form-control" type="text" defaultValue={this.state.testValueDdiDescription} onChange={(e) => this.setState({ testValueDdiDescription: e.target.value })}></input>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							department-selection
						</div>
						<div className="col-md-8 text-center">
							<input className="form-control" min={1} max={9} type="number" defaultValue={this.state.testValueDepartmentSelection} onChange={(e) => this.setState({ testValueDepartmentSelection: e.target.value })}></input>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							hold-time
						</div>
						<div className="col-md-8 text-center">
							<Datetime dateFormat={false} initialValue={this.state.testValueHoldTime} onChange={(e) => this.setState({ testValueHoldTime: e })}></Datetime>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							international
						</div>
						<div className="col-md-8 text-center">
							<select className="form-control" initialValue={this.state.testValueInternational} onChange={(e) => this.setState({ testValueInternational: e.target.value })}>
								<option label="No" value={0} />
								<option label="Yes" value={1} />
							</select>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							voicemail
						</div>
						<div className="col-md-8 text-center">
							<select className="form-control" defaultValue={this.state.testValueVoiceMail} onChange={(e) => this.setState({ testValueVoiceMail: e.target.value })}>
								<option label="No" value={0} />
								<option label="Yes" value={1} />
							</select>

						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="text-center bt-0 p-0 mt-30">
					<Button variant="default" className="btn-block brad-0 pt-20 pb-20" onClick={e => this.setState({ showTestModal: false })}>Cancel</Button>
					<Button variant="cta" className="btn-block brad-0 pt-20 pb-20" onClick={() => this.runWebhookTest()} >Send Webhook</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={this.state.show} onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Text in a modal</h4>
					<p>
						Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
						cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
						dui. Donec ullamcorper nulla non metus auctor fringilla.
					</p>
					<p>
						Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
						dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
						ac consectetur ac, vestibulum at eros.
					</p>
					<p>
						Praesent commodo cursus magna, vel scelerisque nisl consectetur
						et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
						auctor.
					</p>
					<p>
						Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
						cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
						dui. Donec ullamcorper nulla non metus auctor fringilla.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.handleClose}>Close</Button>
				</Modal.Footer>
			</Modal>

			<div className="container-styled">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h1 className="text-center pb-30">Webhooks</h1>

							<div className="bb-1 pb-20 text-center">
								<h2 className="subtitle">Integrate your existing CRM and reporting systems with your SwitchboardFREE number.</h2>
							</div>
						</div>
						<div className="col-md-12 mt-20">

							<p>
								Webhooks are a quick and convenient way of integrating your existing stats, reporting and CRM systems with your SwitchboardFREE numbers.
							</p>

							<p>
								Webhooks are HTTP requests which get made to a URL which you enter using the method, content type and content body all of which you can define below.
							</p>

							<p>
								Use the "Available Data Field" buttons below to put place holders into your URL and Content Body fields, these place holders will then be replaced with live information when the Webhook is sent to your system.
							</p>
						</div>
						<div className="col-md-12 mt-30 mb-30 pt-30 pb-30 bt-1 bb-1 text-center">
							<h4>Available Data Fields
								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={dataFieldsPopover}>
									<span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
								</OverlayTrigger>
							</h4>
							<div className="mt-10 text-center">
								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={cliPopover}>
									<Button variant="secondary" size="xs" className="mr-10 mb-5" onClick={e => this.handleInsertAtCursor('cli')}>cli</Button>
								</OverlayTrigger>

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={cliWithheldPopover}>
									<Button variant="secondary" size="xs" className="mr-10 mb-5" onClick={e => this.handleInsertAtCursor('cli-withheld')}>cli-withheld</Button>
								</OverlayTrigger>

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={ddiPopover}>
									<Button variant="secondary" size="xs" className="mr-10 mb-5" onClick={e => this.handleInsertAtCursor('ddi')}>ddi</Button>
								</OverlayTrigger>

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={internationalPopover}>
									<Button variant="secondary" size="xs" className="mr-10 mb-5" onClick={e => this.handleInsertAtCursor('international')}>international</Button>
								</OverlayTrigger>

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={connectedPopover}>
									<Button variant="secondary" size="xs" className="mr-10 mb-5" onClick={e => this.handleInsertAtCursor('connected')}>connected</Button>
								</OverlayTrigger>

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={ddiDescriptionPopover}>
									<Button variant="secondary" size="xs" className="mr-10 mb-5" onClick={e => this.handleInsertAtCursor('ddi-description')}>ddi-description</Button>
								</OverlayTrigger>

								<br />

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={voicemailPopover}>
									<Button variant="secondary" size="xs" className="mr-10" onClick={e => this.handleInsertAtCursor('voicemail')}>voicemail</Button>
								</OverlayTrigger>

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={callDurationPopover}>
									<Button variant="secondary" size="xs" className="mr-10" onClick={e => this.handleInsertAtCursor('call-duration')}>call-duration</Button>
								</OverlayTrigger>

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={holdTimePopover}>
									<Button variant="secondary" size="xs" className="mr-10" onClick={e => this.handleInsertAtCursor('hold-time')}>hold-time</Button>
								</OverlayTrigger>

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={departmentSelectionPopover}>
									<Button variant="secondary" size="xs" className="mr-10" onClick={e => this.handleInsertAtCursor('department-selection')}>department-selection</Button>
								</OverlayTrigger>

								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={callDatePopover}>
									<Button variant="secondary" size="xs" className="mr-10" onClick={e => this.handleInsertAtCursor('call-date')}>call-date</Button>
								</OverlayTrigger>
							</div>
						</div>

						<div className="col-md-8 mx-auto mt-10">
							<Form.Group className="row">
								<div className="col-md-2">
									<Form.Label>URL:</Form.Label>
								</div>
								<div className="col-md-10">
									<Form.Control type="text" id="webhookUrl"
										value={this.state.webhookUrl}
										onChange={e => this.setState({ webhookUrl: e.target.value, urlValid: true })}
										onBlur={e => this.checkUrlValidation(e.target.value)}
										onFocus={e => this.setState({ eleId: 'webhookUrl' })}
										isInvalid={this.state.urlValid === false}
										isValid={this.state.urlValid === true}
										placeholder="http(s)://www.mywebhook.co.uk" />

									<Form.Control.Feedback type="invalid" className="text-400 pl-15">
										Please enter a valid URL
									</Form.Control.Feedback>
									<p className="text-xs mt-5">For example: This could be a URL to post information to your CRM system.</p>
								</div>
							</Form.Group>
							<Form.Group className="row mb-20">
								<div className="col-md-2">
									<Form.Label>Method:</Form.Label>
								</div>
								<div className="col-md-10">
									<Form.Select value={this.state.webhookMethod} onChange={e => this.setState({ webhookMethod: e.target.value })}>
										<option value="1">GET</option>
										<option value="2">POST</option>
									</Form.Select>
								</div>
							</Form.Group>
							<Form.Group className="row mb-20">
								<div className="col-md-2">
									<Form.Label>Content Type:</Form.Label>
								</div>
								<div className="col-md-10">
									<Form.Select value={this.state.webhookType} onChange={e => this.setState({ webhookType: e.target.value })}>
										<option value="application/x-www-form-urlencoded">application/x-www-form-urlencoded</option>
										<option value="application/json">application/json</option>
										<option value="text/xml">text/xml</option>
									</Form.Select>
								</div>
							</Form.Group>
							<Form.Group className="row">
								<div className="col-md-2">
									<Form.Label>Body:</Form.Label>
								</div>
								<div className="col-md-10">
									<Form.Control as="textarea" componentClass="textarea" id="webhookBody"
										value={this.state.webhookBody}
										onChange={e => this.setState({ webhookBody: e.target.value, bodyValid: true })}
										onBlur={e => this.checkBodyValidation(e.target.value)}
										onFocus={e => this.setState({ eleId: 'webhookBody' })}
										isInvalid={this.state.bodyValid === false}
										isValid={this.state.bodyValid === true}
										placeholder="" />

									<Form.Control.Feedback type="invalid" className="text-400 pl-15">
										Body cannot be empty
									</Form.Control.Feedback>

								</div>
							</Form.Group>
							<div className="row">
								<div className="col-md-12 text-center mb-30">
									{this.state.formError === false &&
										<div className="alert alert-danger">
											Please correct the highlighted error before saving your changes
										</div>
									}
									{this.state.formFailed === true &&
										<div className="alert alert-danger">
											There was an error saving your webhook settings. Please try again, or call us on <a href="tel:02031891213">0203 189 1213</a>.
										</div>
									}
									<Button type="submit" variant="cta" size="lg" className="btn-mobile-block mt-20" onClick={e => this.handleSubmit(this.state)} data-delay={3}>Save</Button>
									<Button type="submit" variant="cta" size="lg" className="btn-mobile-block mt-20 ml-5" onClick={e => this.setState({ showTestModal: true })}> Test </Button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="full-width bg-dull text-center pb-30">
					<h3 className="text-400">Do you have any more questions about Webhooks?</h3>
					<h5 className="mb-30 pb-30">Call us on <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a>, email <a href="support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a> or request a callback below.</h5>

					<div className="col-8 mx-auto">
						<CallbackRequest subject="Callback request from Webhooks page" isForm={true} />
					</div>
				</div>

			</div>
		</>
  }

  componentDidMount () {
    // Get webhook settings
    const url = '/api/Users/GetWebhookSettings'
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data) {
          this.setState({
            webhookId: res.data.id,
            webhookUrl: res.data.url,
            webhookBody: res.data.params,
            webhookMethod: res.data.submissionMethod,
            webhookType: res.data.contentType
          })
          this.checkUrlValidation(res.data.url)
          this.checkBodyValidation(res.data.params)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  }, {
    notify: handleNotificationChange
  }
)(WebHookSettings)
