import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'
import DeliverySectionHelperDialog from './InstructionHelperDialogs/DeliverySectionHelperDialog'

const redirectSequenceOptions = [
  { value: false, label: 'In Order' },
  { value: true, label: 'Distribute Evenly' }
]

const DeliveryMethodSection = props => (
    <>
        <DeliverySectionHelperDialog />

        <div className="row pt-30 pb-30 mb-0">
            <div className="col-md-12 pb-sm-10">
                <p className="text-dark text-500">
                    Redirect sequence
                </p>
                <p className="text-400">
                    Select how you would like your calls to be delivered to your redirect numbers. <a className="underline" onClick={props.handleShowDeliveryMethodHelperInstruction} id="RedirectSequenceMoreInfoLink">More info</a>
                </p>
            </div>
            <div className="col-md-12 mt-20 mb-30">

                <div className="text-sm-center">
                {/* <p>Redirect sequence:</p> */}
                <Select
                    className="w-300px"
                    defaultValue={{ value: 1, label: 'In Order' }}
                    options={redirectSequenceOptions}
                    onChange={ e => props.handleDeliveryMethodChange(e.value)}
                    simpleValue
                    value={redirectSequenceOptions.filter(x => x.value === (props.selectedDeliveryMethod !== undefined && props.selectedDeliveryMethod.deliveryMethodKey === 'P'))}
                    id="RedirectSequenceDropdown"
                />
                </div>
            </div>
        </div>
    </>
)

const mapStateToProps = state => ({
  selectedDeliveryMethod: state.configuration.configuration.deliveryMethod
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryMethodSection)
