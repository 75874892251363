import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const GlobalMissedCallEmailAlertsInstructionHelperDialog = props => (
    <Modal show={props.isDisplayed}
           onHide={props.handleHideGlobalMissedCallEmailAlertsInstruction}>
        <Modal.Header closeButton>
            <Modal.Title className="text-500">Missed Call Email Alerts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                These missed call alerts will be emailed to you should a caller hangup before they have selected a
                department (in which case the missed call alert will be sent to the missed call alert settings you set
                for that specific department).
            </p>
            <h5 className="pt-30 text-500">Multiple addresses</h5>
            <p>
                To use missed call email alerts you must input at least 1 valid email address. You can enter multiple
                email addresses seperated with a semi-colon, for example: <i>john@yahoo.com; john@gmail.com</i> and switch the toggle button to “on”.
            </p>
            <div className="ratio ratio-16x9 pt-20">
                <iframe src="https://www.youtube.com/embed/X8dKUN4FP_8" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.handleHideGlobalMissedCallEmailAlertsInstruction}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.globalMissedCallInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalMissedCallEmailAlertsInstructionHelperDialog)
