import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import CallbackRequest from '../../../components/CallbackRequest'
import DisplayMainFooter from '../../../components/DisplayMainFooter'
import DisplayMainHeader from '../../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../../selectors'

class OpenCloseTimes extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
            <Helmet>
                <title>Open and close your phone lines automatically - Free Virtual Call Management System</title>
                <meta name="description" content="Open and close your phone lines whenever you want" />
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/open-close.jpg')" }} className="full-width full-hero-small">
                <h1>Outbound dialer</h1>
                <h2 className="subtitle text-center">Automatically open and close your business phone lines</h2>
            </header>
            <div className="container-styled">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto pb-30">
                            <h4 className="pt-30 text-500">What is it?</h4>
                            <p>
                                Our outbound dialer feature is a powerful and convenient way of sharing out lists of numbers for your staff to dial and makes it quick and convenient for them to be able to make contact.
                            </p>
                            <h4 className="pt-30 text-500">Who is it for? </h4>
                            <p>
                                If you regularly have lists of customers or leads to contact then the free Outbound Dialer feature is for you.
                            </p>

                            <h4 className="pt-30 text-500">The benefits of using it? </h4>
                            <p>
                                This feature will help you to organize and distribute those numbers to call from a single convenient management page where you can also review stats and see which numbers have been called.
                            </p>
                            <p>
                                Your staff will benefit from being alerted to any new calls that have been uploaded and very quickly and conveniently make those calls with a single press using the free SwitchboardFREE Phone App, adding notes to the calls as they go.
                            </p>
                            <p>
                                If your member of staff is unable to make contact with a number the Outbound Dialer feature will take care of distributing it again at a later date and time to maximize the chances of making contact.
                            </p>
                            <h4 className="pt-30 text-500">How do I use it?</h4>
                            <p>
                                In order to use this feature you will firstly need to make sure you have at least one member of staff using the SwitchboardFREE Phone App.
                            </p>
                            <ol className="text-400">
                                <li className="pb-20">
                                    To begin using the feature login to your SwitchboardFREE account and go to the “Outbound Dialer” feature located under the “My Calls” menu on the main navigation.
                                </li>
                                <li className="pb-20">
                                    From here upload your list of customers or leads you wish to make contact with. The file you upload must have the correct columns in the correct order. This is describe on the page and there is a template file that you can download.
                                </li>
                                <li className="pb-20">
                                    As soon as your list has been uploaded it will appear on the right hand side of the page, from here you can see the total number of records remaining for your staff to make contact with, you can also temporarily disable or permanently delete the list when necessary.</li>
                                <li className="pb-20">
                                    As soon as your list has been uploaded, the records to dial are instantly distributed to your staff via the SwitchboardFREE Phone App
                                    (download <a className="underline text-cta" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREEPhone&hl=en_GB">Android</a> or <a className="underline text-cta" href="https://apps.apple.com/gb/app/switchboardfree-phone/id1471368509">iOS</a>).
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="full-width bg-dull text-center p-30 mt-30 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <h4 className="text-400 pb-30">Do you have any more questions about open &amp; close times?</h4>
                                <h5 className="mb-30 pb-30">Call us on <a href="tel:0203 189 1213" className="text-cta">0203 189 1213</a>, email <a href="support@switchboardfree.co.uk" className="text-cta">support@switchboardfree.co.uk</a>
                            or request a callback below. Our offices are open Monday to Friday 8:30am to 6pm.</h5>
                                <p></p>
                                <CallbackRequest subject="Callback request from Open Close page" isForm={true} />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
  }

  componentWillMount () { }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(OpenCloseTimes)
