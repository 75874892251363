import React from 'react'
import Helmet from 'react-helmet'
import CallbackRequest from '../../components/CallbackRequest'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

export default class MultipleNumbersPage extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
            <ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            {/* <NumbersMenu /> */}
            <Helmet>
                <title>Multiple UK Phone Numbers </title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/multinumbers-bg.jpg')" }} className="full-width full-hero-small with-text" >
                <h1 id="MultiNumbersLoggedOutH1">Multiple UK phone numbers</h1>
                <h2 className=" subtitle">A cost effective way of having multiple  business numbers across the UK</h2>
            </header>
            <div className="container-styled">
                <div className="container pb-30">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-600px center-block">
                                <p className="text-md pt-10 pt-xs-0 text-xs-sm text-xs-left">Whether your business is spread across multiple locations, has multiple franchisees or wants to project a local presence nationally then using multiple phone numbers is essential.</p>
                                <p className="text-md pt-10 pt-xs-0 text-xs-sm text-xs-left">A Multi-Number package allows you to have a set of numbers that all share the same allowance of minutes making it a cost effective way to take advantage of having more than one business number. Simply select how many numbers you need, select the individual numbers you'd like &amp; how many call minutes you require.</p>
                                <p className="text-md pt-10 pt-xs-0 text-xs-sm text-xs-left pb-xs-40">Minute packages starting from just £9.00 and virtual numbers currently just £1.99 with no long-term contracts.</p>
                            </div>
                            <div className="bg-dull b-2 text-dark p-30 mt-30 brad-5 w-600px center-block text-center">
                                <h4 className=" mt-0 text-400">Multi-Number packages are a cost effective way of having any amount of virtual numbers for your business.</h4>
                                <p className="text-md pt-30 pb-20">Get a callback to find out more about our <br className="d-none d-lg-block" />multiple number packages</p>
                                <CallbackRequest isForm={true} subject="Mutli-nubers package request" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
  }
}
