import React from 'react'
import { connect } from 'react-redux'

class CallTranscriptionBenefitsList extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <>
                <ul className="fancy text-start cta text-400 mt-20 pl-40">
                        <li className="pb-10">
                        Keep records of calls in text format
                        </li>
                        <li className="pb-10">
                        Search & create alerts for words or phrses spoken or not during the call
                        </li>
                        <li className="pb-10">
                        Read it like a chat
                        </li>
                        <li className="pb-10">
                        Easily copy, paste and share the transcription
                        </li>
                        <li className="pb-10">
                        Keep transcriptions for legal or training purposes
                        </li>
                        <li className="pb-10">
                        Improve accessibility
                        </li>
                        <li>
                        No fixed monthly charge, just 5p per minute of transcribed calls.
                        </li>
                </ul>
        </>
  }


}

export default connect(
  state => {
    return state
  },
  {}
)(CallTranscriptionBenefitsList)
