import React from 'react'
import Toggle from "react-bootstrap-toggle";
import { Button } from 'react-bootstrap'
import CallTranscriptionRules from '../components/CallTranscriptionRules'
import { fetchHelper } from '../helpers/fetchHelper'

export default class CallTranscriptionActivation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      callTranscriptionActive: this.props.callTranscriptionActive ? this.props.callTranscriptionActive : false,
      showEditAlertModal: false,
      showDeleteRuleQuestion: false,
      rulesFound: false
    }
    this.onToggle = this.onToggle.bind(this)
  }

  componentDidMount() {
    const url = 'api/CallTranscription/HasCallTranscriptionTurnedOn'
    fetchHelper.getJson(url)
      .then(res => {
        this.setState({ callTranscriptionActive: res.data })
      }).c
  }

  onToggle() {
    this.setState({ callTranscriptionActive: !this.state.callTranscriptionActive })
  }

  notNowClicked() {
    if (this.props.clickedNotNowMaybeLater) {
      this.props.clickedNotNowMaybeLater()
    }
  }

  activateCallTranscriptionClicked() {
    if (this.props.disableApiCall && this.props.disableApiCall === true) {
      if (this.props.activateCallTranscriptionClicked) {
        this.props.activateCallTranscriptionClicked()
      }
      this.setState({ callTranscriptionActive: true })
    } else {
      const url = 'api/CallTranscription/TurnOnCallTranscription'
      fetchHelper.postJson(url, {})
        .then(res => {
          if (res.data === true) {
            if (this.props.activateCallTranscriptionClicked) {
              this.props.activateCallTranscriptionClicked()
            }
            this.setState({ callTranscriptionActive: true })
          }
        }).catch(err => console.error(err))
    }
  }

  deactivateCallTranscriptionClicked() {
    if (this.props.disableApiCall && this.props.disableApiCall === true) {
      if (this.props.deactivateCallTranscriptionClicked) {
        this.props.deactivateCallTranscriptionClicked()
      }
      this.setState({ callTranscriptionActive: false })
    } else {
      const url = 'api/CallTranscription/TurnOffCallTranscription'
      fetchHelper.postJson(url, {})
        .then(res => {
          if (res.data === true) {
            if (this.props.deactivateCallTranscriptionClicked) {
              this.props.deactivateCallTranscriptionClicked()
            }
            this.setState({ callTranscriptionActive: false })
          }
        }).catch(err => console.error(err))
    }
  }

  render() {
    const callTranscriptionActive = this.state.callTranscriptionActive

    return (
      <>
        {(callTranscriptionActive !== true && this.props.isBox === true) ?
          <>
            <div className="row mt-100 mb-60">
              <div className="col-md-6 col-sm-12 order-last order-md-first text-end">
                <img src="/images/call-transcription2.jpg" alt="Call Recording with transcription" className="img-responsive brad-20 " style={{ maxWidth: '600px' }} />
              </div>
              <div className="col-md-6 col-sm-12 order-first order-md-last">
                <h2 className="text-500 text-darker">
                  Harness the Power of Every Word
                </h2>
                <h2 className="text-lg text-400 mb-30 mt-10 text-dark-light w-600px">
                  Transform Your Business Communications with Call Transcription
                </h2>

                <p>
                  With no fixed monthly charges and a user-friendly rate of 5p per minute of transcribed calls, our Call Transcription feature is the cost-effective, efficient solution you've been looking for to manage your business communication. </p>

                <ul className="styled-list success rounded text-start text-400 mt-40 pl-0">
                  <li className="pb-10">
                    <strong>Set Up Custom Alerts:</strong> With our Call Transcription feature, you can now set alerts for specific words or phrases that are either mentioned or missing from your calls. This ensures you&apos;re immediately notified when critical information is shared or when important terms have been omitted, helping you maintain consistent quality control and compliance in every conversation.
                  </li>
                  <li className="pb-10">
                    <strong>Simplify Training & Quality Control:</strong> Easily revisit past calls to highlight best practices, identify areas for improvement, or educate new team members. With transcripts at your disposal, every call becomes a potential learning opportunity.
                  </li>
                </ul>

                <Button variant="cta" size="lg" className=" btn btn-cta btn-lg mt-30 mb-0" onClick={() => this.activateCallTranscriptionClicked()}>Activate Now</Button>
                <p className="text-sm mt-10">Enable Transcription for just 5p per minute.</p>

              </div>
            </div>
            <div className="row mt-50 mb-60">
              <div className="col-md-6 col-sm-12 order-last">
                <img src="/images/call-transcription.jpg" alt="Call Recording with transcription" className="img-responsive brad-20 " style={{ maxWidth: '600px' }} />
              </div>
              <div className="col-md-6 col-sm-12 order-first">
                <h2 className="text-500 text-darker">
                  Never Miss a Detail Again
                </h2>
                <h2 className="text-lg text-400 mb-30 mt-10 text-dark-light w-600px">
                  Unlock Business Efficiency with SwitchboardFREE's Call Transcription
                </h2>

                <p>
                  With our innovative VoIP phone systems, we're now bringing you a tool that will streamline your call management like never before. Here's why our Call Transcription feature is a game-changer for your business:</p>

                <ul className="styled-list success rounded text-start text-400 mt-40 pl-0">
                  <li className="pb-10">
                    <strong>Capture Every Conversation:</strong> Call Transcription ensures every word spoken during a call is documented in text. No more note-taking, no more forgetting important details - you'll have a ready-to-use record at your fingertips.
                  </li>
                  <li className="pb-10">
                    <strong>Search with Ease:</strong> Ever spent time trying to remember a critical detail from a past call? Now you can simply search for it. Our Call Transcription lets you search for specific words or phrases mentioned in any conversation.
                  </li>
                  <li className="pb-10">
                    <strong>Stay Compliant:</strong> Never miss out on mentioning essential details again. With our feature, you can quickly search for terms or conditions that need to be discussed during a call, ensuring complete professionalism and compliance.
                  </li>
                </ul>

                <Button variant="cta" size="lg" className=" btn btn-cta btn-lg mt-30 mb-0" onClick={() => this.activateCallTranscriptionClicked()}>Activate Now</Button>
                <p className="text-sm mt-10">Enable Transcription for just 5p per minute.</p>

              </div>
            </div>
          </> : this.props.isBox ?
            this.state.rulesFound === false
              ?
              <div className={`${this.props.customDivClasses ? this.props.customDivClasses : 'col-md-12 mt-sm-20 well well-transparent'}`} style={{ position: "relative" }}>
                <div>
                  {!this.props.hideText &&
                    <div>
                      {callTranscriptionActive === true &&
                        <>
                          <span class="circle-tick center-block margin-bottom-20 slide-in-blurred-top scale-up1 success">
                            <span class="icon icon-checkmark4 scale-up text-success"></span>
                          </span>
                          <h4 className="text-500 mt-20 mb-20">Call Transcription is enabled</h4>
                          <div className="text-start">
                            <p className="mb-0 pb-0">
                              Fantastic! From now on, all your call recordings will be automatically transcribed. Your call recordings along with the transcriptions will be available on the <a href="/customer/calls" className="underline text-cta" id="CallsPageLink">calls page</a>.
                            </p>
                            <div className="text-center pb-20 pt-20">
                              <Link to={'/customer/calls'} className="btn btn-cta btn-sm mt-20" id="SeeAllMyCallsButton">See all my calls</Link>
                            </div>
                            <div className="divider mt-40 text-center col-xs-12 mb-40">
                              <span className="icon icon-arrow-down2"></span>
                            </div>
                            <h6 className="text-500 pt-20">Create your alerts</h6>
                            <p>In the meantime, you can create your first word/phrase alert. With alerts, you will be sent an email in real-time, once a specific word or phrase has been or hasn't been said during a call.</p>
                            <p>For example, you might want to be alerted to any mentions of the word "sale", or you might want to check if your team mentions your "terms and conditions", as you might be legally obligated to say it.</p>
                          </div>


                          {!this.props.hideCTAdeactivate &&
                            <h5 className="text-500 mt-30">
                              Turn off call transcription?
                            </h5>
                          }
                          {!this.props.hideDeactivateButton &&
                            <Button variant="danger" size="lg" className=" mt-20 mb-30" onClick={() => this.deactivateCallTranscriptionClicked()}>Deactivate</Button>
                          }
                        </>
                      }
                    </div>
                  }

                  {!this.props.hideNotNowText &&
                    <p className="underline" style={{ cursor: 'pointer' }} onClick={() => this.notNowClicked()}>Not now, maybe later</p>
                  }
                </div>
              </div>
              :
              <></>
            :
            <>

              <div className={`${this.props.customToggleWrapperClass && this.props.customToggleWrapperClass}`}>
                <span className="text-500 pr-10">Not now</span>
                <Toggle
                  on=""
                  off=""
                  size="sm"
                  offstyle="default"
                  onstyle="success"
                  width="null"
                  height="null"
                  className="toggle-sm toggle-secondary"
                  active={this.props.valueOverride !== undefined ? this.props.valueOverride : this.state.callTranscriptionActive}
                  onClick={() => this.state.callTranscriptionActive ? this.deactivateCallTranscriptionClicked() : this.activateCallTranscriptionClicked()}
                />
                <span className="text-500 pl-10">Enable</span>
              </div>
            </>
        }
        {this.props.isBox === true && callTranscriptionActive === true ?
          this.props.showAlerts ?
            <></>
            :
            <CallTranscriptionRules
              noRulesFound={() => this.setState({ rulesFound: false })}
              rulesFound={() => this.setState({ rulesFound: true })}
            />
          :
          <></>
        }
      </>
    )
  }
}
