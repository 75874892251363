import React from 'react'
import { Button, Form, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RedirectType } from '../../constants/Constants'
import * as configurationActions from '../../store/Configuration'
import RedirectNumberDropdown from './RedirectNumberDropdown'

const redirectOrderPopover = (isMoveUp, position) => (
    <Popover id="popover-trigger-hover-focus">
        <p className="text-400">
            {isMoveUp === true ? `Move redirect up to position #${position - 1}` : `Move redirect down to position #${position + 1}`}
        </p>
    </Popover>
)

const RedirectNumber = props => (
    <>
        {/* Re-ordering buttons */}

        <div className="reorder pb-5 text-end">
            {props.position > 1 &&
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={redirectOrderPopover(true, props.position)}
                >
                    <Button variant="transparent" size="xs" className="move-up no-shadow brad-0 text-lighter less-pad" onClick={() => props.reOrderRedirect({ isMoveUp: true, position: props.position })}>
                        <span className="icon icon-arrow-up8"></span>
                    </Button>
                </OverlayTrigger>
            }

            {props.position < props.redirectCount &&
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={redirectOrderPopover(false, props.position)}
                >
                    <Button variant="transparent" size="xs" className="move-down no-shadow brad-0 text-lighter less-pad" onClick={() => props.reOrderRedirect({ isMoveUp: false, position: props.position })}>
                        <span className="icon icon-arrow-down8"></span>
                    </Button>
                </OverlayTrigger>
            }

            <Button variant="transparent" size="xs" className="no-shadow brad-0 text-lighter less-pad" onClick={() => props.onRemoveRedirect(props.position)} id={`DeleteRedirect${props.position}Button`}>
                <span className="icon icon-bin" id="DeleteRedirectIcon"></span>
            </Button>
        </div>

        {/* End re-ordering */}

        <p>
            <span className="text-500 redirect-order-number">Redirect Number:</span>
            {props.is084 && !props.hasUmr && props.number && props.number.redirectDetail && (props.number.redirectDetail.startsWith('447') || props.number.redirectDetail.startsWith('07')) &&
                <a onClick={e => props.handleShowUmrDialog()}>
                    <i className="icon icon-warning2 text-danger ml-5"></i>
                </a>
            }
        </p>
        <Form.Group>
            <InputGroup className='redirect-number'>
                <input type='tel' className="form-control" maxLength='16'
                    onBlur={e => props.number.redirectType === RedirectType.PhoneNumber ? props.onValidate(e.target.value) : props.onValidateUserOrGroup(e.target.value)}
                    onChange={e => props.changeRedirectNumber({
                      newNumber: e.target.value,
                      position: props.position,
                      itemType: RedirectType.PhoneNumber,
                      isWizard: props.isWizard
                    })}
                    value={props.number.redirectType === RedirectType.PhoneNumber ? props.number.redirectDetail : props.number.redirectType === RedirectType.Endpoint ? props.number.redirectName : props.number.redirectName}
                    id={`RedirectNumberInput${props.position}`}
                />
                <RedirectNumberDropdown number={props.number} position={props.position} />
            </InputGroup>
        </Form.Group>
    </>
)

const mapStateToProps = state => {
  return ({
    redirectsHistory: state.configuration.redirects.redirectsHistory,
    outboundUsers: state.configuration.redirects.outboundUsers,
    ringGroups: state.configuration.redirects.ringGroups,
    is084: state.configuration.selectedConfiguration.queueNo ? state.configuration.selectedConfiguration.queueNo.startsWith('084') : false
  })
}

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators(configurationActions, dispatch)
)(RedirectNumber)
