import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import RefreshUserServices from '../../components/RefreshUserServices'
import { productBillingOption, ProductIds } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import * as configurationActions from '../../store/Configuration'
import { handleNotificationChange, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'

class umUpgradeDialog extends Component {
  state = {
    wpAmount: 0,
    key: undefined
  }

  render () {
    const umrPayment = { firstPayment: 0, repeatPayment: 0 }
    if (this.props.productDetails) {
      const umrPaymentDetails = this.props.productDetails.find(prod => prod.productId === ProductIds.umr)
      if (umrPaymentDetails) {
        umrPayment.firstPayment = umrPaymentDetails.monthlyPrice.firstPaymentAmount
        umrPayment.repeatPayment = umrPaymentDetails.monthlyPrice.repeatPaymentAmount
      }
    }
    return (
            <>
                <RefreshUserServices />
                <Modal show={this.props.showUmrDialog && !this.props.hasUmr && !this.props.services.displayBillingWarning} onHide={e => this.props.handleHideUmrDialog()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Unlimited Mobile Redirects
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        {this.state.isPurchaseInProgress
                          ? <LoadingNotification
                                className="mt-30"
                                message={'Please wait...'}
                                isLoading={true}
                            />
                          : <>
                                <p className="p-15">
                                    You have set your calls to be forwarded to one or more mobile numbers. Calls redirected to your mobile are charged at 10p per minute. If you would prefer, for a fixed monthly price of just &pound;{umrPayment.repeatPayment} you can redirect unlimited calls to any UK mobile number.
                                </p>
                                <div className="full-width full-secondary bg-overlay text-lightest pt-30 pb-40 text-center" style={{ backgroundImage: "url('/images/backgrounds/call-filtering2.jpg')" }}>
                                    <div className="container">
                                        <h3 className="text-400 pb-20">Add Unlimited Mobile Redirects <br className="d-none d-md-block" />to your account today</h3>
                                        <p>We highly recommend adding Unlimited Mobile Redirects with our 084 numbers.</p>
                                        <ul className="unstyled">
                                            <li>&bull; Ensure you never miss a business call</li>
                                            <li>&bull; Receive calls to you 084 numbers directly on your mobile phone</li>
                                        </ul>
                                        <h4 className="pt-30 text-500">When we say unlimited, we mean it!</h4>
                                        <h5>Receive all your calls to your mobile for 1 monthly fee.</h5>
                                        {umrPayment.firstPayment !== umrPayment.repeatPayment
                                          ? <h4 className="mt-50 mb-0">Add it today for only £{umrPayment.firstPayment} for your first month</h4>
                                          : <h4 className="mt-50 mb-0">Add it today for only £{umrPayment.repeatPayment} per month</h4>
                                        }

                                        <Button size="md"
                                            variant="cta"
                                            className="mt-10 btn-lg"
                                            onClick={event => this.handlePurchaseUmr(umrPayment.firstPayment, this.mobileRedirectsOfferId)}
                                            disabled={this.state.isPurchaseInProgress}
                                        >
                                            Setup Unlimited Mobile Redirects</Button>

                                        {umrPayment.firstPayment !== umrPayment.repeatPayment &&
                                            <p className="pt-10">Followed by £{umrPayment.repeatPayment} per month thereafter</p>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer className="text-center">
                        <p className="text-sm"><a onClick={e => this.props.handleHideUmrDialog()}>No thanks, continue saving settings</a></p>
                    </Modal.Footer>
                </Modal>

                <ChoosePayment
                    total={this.state.wpAmount}
                    clientId={this.props.clientId}
                    purchaseData={this.state.key}
                    email={this.props.userDetails.email}
                    name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                    mandate={!this.props.services.hasAgreement}
                    returnRoute={'/customer/numbers/'.concat(this.props.queueNo)}
                    ipAddress={this.state.ipAddress}
                    signupKey={this.props.signupKey}
                />
            </>
    )
  }

  handlePurchaseUmr (amount, optionId) {
    const context = this
    const url = 'api/Purchase/PurchaseUmr'

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.postJson(url, { billingOption: optionId !== null && optionId > 0 ? optionId : productBillingOption.umrBillingOptionMonthly }).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          context.props.notify({ message: 'Unlimited Mobile Redirects Added' })
          context.props.handleUmrEnabledChange(true)
        } else if (res.data && res.data.postponed === true) {
          context.props.notify({ message: 'Unlimited Mobile Redirects Added' })
          context.props.handleUmrEnabledChange(true)
          context.props.handleShowPaymentPostponedModal()
        } else {
          context.setState({ wpAmount: res.data.amount, key: res.data.key })
          context.props.handleShowPaymentOptionsModal()
        }
      }).catch(function (error) {
        console.error(error)
      })
    })
  }
}

export default connect(
  state => {
    const sbfApp = state.sbfApp
    return {
      queueNo: state.configuration.selectedConfiguration.queueNo,
      hasAgreement: sbfApp.enabledServices.hasAgreement,
      showUmrDialog: state.configuration.dialogVisibility.showUmrDialog,
      hasUmr: sbfApp.enabledServices.hasUmr,
      productDetails: state.home.productDetails.products,
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  }, {
    handleHideUmrDialog: configurationActions.handleHideUmrDialog,
    handleShowPaymentOptionsModal,
    notify: handleNotificationChange,
    handleShowPaymentPostponedModal
  }
)(umUpgradeDialog)
