import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Ratio from 'react-bootstrap/Ratio'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const MissedCallEmailAlertsInstructionHelperDialog = props => (
    <Modal size="lg" show={props.isDisplayed}
           onHide={props.handleHideMissedCallEmailAlertsInstruction}>
        <Modal.Header closeButton>
            <Modal.Title className="text-500">Missed Call Email Alerts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                If you enable this feature you will receive an email whenever someone calls your SwitchboardFREE
                number but for whatever reason you do not get to speak to them (e.g. you are too busy to answer
                the call or they call outside of your opening hours).
            </p>

            <p>
                The email you will receive contains all the details neccesary to call them straight back and also
                tell you which number they have called (in case you have multple SwitchboardFREE numbers) and how
                long they were on hold for.
            </p>
            <h5 className="pt-30 text-500">Multiple addresses</h5>
            <p>You can enter more than one email address by separating them with a semi-colon, for example: john@gmail.com;john@yahoo.co.uk</p>
            <div >
                <Ratio aspectRatio="16x9">
                    <iframe src="https://www.youtube.com/embed/X8dKUN4FP_8" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </Ratio>
            </div>
            {/* <div className="ratio ratio-16x9 pt-20">
                <iframe  src="https://www.youtube.com/embed/X8dKUN4FP_8" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </div> */}
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.handleHideMissedCallEmailAlertsInstruction}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.missedCallInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissedCallEmailAlertsInstructionHelperDialog)
