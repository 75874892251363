import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'
import CallFilteringSectionHelperDialog from './InstructionHelperDialogs/CallFilteringSectionHelperDialog'

const CallFilteringSection = props => (
    <>
        <div className="row bg-light p-30 zoom">
            <div className="col-md-5 pb-sm-20">
                <h5 className="text-secondary text-500 text-lg">Call Filtering
                </h5>
                <p className="text-400">Block calls from witheld and/or international callers. <a href="/customer/call-filtering/" target="_blank">Click here</a> to access your in-depth call filtering settings for every SwitchboardFREE
                    number. <a onClick={props.handleShowCallFilteringHelperInstruction}>More info</a></p>
            </div>
            <CallFilteringSectionHelperDialog />
            <div className="col-md-7 pl-sm-0 pr-sm-0">
                <div className='row'>
                    <div className="col-sm-6 pb-xs-30 text-sm-center">
                        <p>Block international calls:</p>

                        <Toggle
                            className="mt-0"
                            onClick={e => props.handleAllowInternationalCallersChanged(e)}
                            on={<span>Blocked</span>}
                            off={<span>Allowed</span>}
                            size="sm"
                            offstyle="default"
                            onstyle="success"
                            width="null"
                            height="null"
                            active={props.BarrInternational}
                            id="BlockInternationalCallsToggle"
                        />

                    </div>
                    <div className="col-sm-6 pb-xs-30 text-sm-center">
                        <p>Block withheld calls:</p>

                        <Toggle
                            className="mt-0"
                            onClick={e => props.handleAllowWitheldCallersChanged(e)}
                            on={<span>Blocked</span>}
                            off={<span>Allowed</span>}
                            size="sm"
                            offstyle="default"
                            onstyle="success"
                            width="null"
                            height="null"
                            active={props.BarrWithheld}
                            id="BlockWithheldCallsToggle"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
)

const mapStateToProps = state => ({
  BarrInternational: state.configuration.configuration.barrInternational,
  BarrWithheld: state.configuration.configuration.barrWithheld
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallFilteringSection)

// export default connect(
//     state => state.configuration,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(IntroductionSection);
