import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { siteHeader } from '../constants/Constants'
import { isLoggedIn } from '../selectors'

const HeaderSimple = props => props.ShowHeaderSimple
// <div className="header">
//     <div className="container">
//         {/*<Navbar fluid inverse collapseOnSelect className="navbar navbar-default navbar-fixed-top text-start navbar-simple">*/}
//         {/*    <Navbar.Header>*/}
//         {/*        <Navbar.Brand>   */}
//                     {props.isLoggedIn === true ?
//                         <a className="navbar-brand" href="/customer/dashboard"><img src={"/images/logo/sflogo.svg"} width="500" /></a>
//                     :
//                         <a className="navbar-brand" href="/"><img src={"/images/logo/sflogo.svg"} width="500" /></a>
//                     }
//                     <p>Good For Business</p>
//         {/*        </Navbar.Brand>*/}
//         {/*    </Navbar.Header>*/}
//         {/*</Navbar>*/}
//     </div>
// </div>

  ? <>

    <div className="header">
        <Navbar className='navbar-simple text-center'>
            <Navbar.Brand>
                {props.isLoggedIn === true
                  ? <a href="/customer/dashboard">
                        <img src={'/images/logo/sflogo.svg'} width="500" />
                    </a>
                  : <a href="/">
                        <img src={'/images/logo/sflogo.svg'} width="500" />
                    </a>
                }
                <p>Good For Business</p>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
    </div>
</>
  : null

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {},
  dispatch
)

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state),
      ShowHeaderSimple: state.sbfApp.visibility.HEADER === siteHeader.SIMPLE
    })
  },
  {
    mapDispatchToProps
  }
)(HeaderSimple)
