import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import PlansAndNumbersDropdown from '../../components/PlansAndNumbersDropdown'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { calculateVat, getEmptyBasket } from '../../helpers/utils'
import { isLoggedIn } from '../../selectors'
import { handleChangeHasAgreement, handleNotificationChange, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'

class MemorableNumbers extends React.Component {
  state = {
    prefix: '0203',
    showTsAndCs: false,
    purchasingNumber: false
  }

  constructor (props) {
    super(props)
  }

  numberType = {
    business: 0,
    professional: 1,
    gold: 2
  }

  render () {
    const basket = getEmptyBasket()
    const numbers = this.props.isLoggedIn === true ? LocalStorageHelper.getItem('LoggedInNumberSelection') : LocalStorageHelper.getItem('LandingPageNumberSelection')
    basket.numbers = numbers.value ?? []

    return <><ScrollToTopOnMount />
            {this.props.isLoggedIn
              ? <DisplayLoggedInFooter />
              : <DisplayMainFooter />
            }
         <DisplayMainHeader />
        <Helmet>
				<title>Buy unique Gold & Silver Numbers. Give your business a sense of prestige and exclusivity.</title>
				<meta name="description" content="Golden phone numbers are special phone numbers that are easy to remember or have a unique pattern, such as repeated digits." />
			</Helmet>
            <header>
                <div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/contactbook-bg.jpg')" }}>
                    <h1 id="MemorableNumbersH1">Memorable Numbers</h1>
                    <h2 className="subtitle">
                        Make your business stand out and make it more memorable to customers.
                    </h2>
                </div>
            </header>
            <div className="full-width bg-dull pb-70 pt-70 number-page" id="numbers" >
                <div className="container numbers-pages gold-numbers pl-sm-0 pr-sm-0">
                    <div className="row flex-row-reverse">

                        <div className="col-md-7 col-sm-6">
                            <div className="bg-light brad-20 p-30 box-shadow-v3">
                            <PlansAndNumbersDropdown
                                basket={basket}
                                prefix={this.state.prefix}
                                ddiLid={this.state.area?.value}
                                selectedPackage={this.state.selectedPackage}
                                handleChangeNumberType={e => this.setState({ prefix: e })}
                                handleChangePackage={e => this.handleSelectPackage(e)}
                                handleChangeAreaCode={e => this.setState({ area: e })}
                                inboundOnly={this.state.inboundOnly === true}
                                preventDefaultSelection={true}
                                hidePlans={true}
                                displayAll={true}

                                WrapperClassName="mb-40"
                                LabelsClassName="text-xs text-dark text-400"

                                NumberButtonClassName={'text-start bg-light pl-25 pr-25 ml-5 b-0 inbound-only'}
                                NumberNameClassName="text-dark text-400"
                                NumbersResultsDropdownClassName="bg-light ml-5 box-shadow"
                                NumberNameClassNameSelected="text-cta text-500"

                                AreaCodeButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
                                AreaCodeNameClassName="text-dark text-400"
                                AreaCodeResultsDropdownClassName="bg-light box-shadow"
                                AreaCodeNameClassNameSelected="text-cta text-500"
                            />

                            <DdiSelectorNew
                                selectedGeoRegion={this.state.area}
                                premiumOnly={true}
                                numberType={this.state.prefix}
                                isSignup={false}
                                currentBasket={basket}
                                hideShowAll={false}
                                isModal={false}
                                hideLoadMore={false}
                                hideRegions={true}
                                hidestars={false}
                                isLoggedIn={this.props.isLoggedIn}
                                isEditing={false}
                                menuOpen={false}
                                citysOnly={false}
                                ddiLid={this.state.area?.value}
                                handleUpdateBasket={(_, sbfNumber) => {
                                  const item = this.props.isLoggedIn === true ? LocalStorageHelper.getItem('LoggedInNumberSelection') : LocalStorageHelper.getItem('LandingPageNumberSelection')
                                  let sbfNumbers = item.value ?? []
                                  if (sbfNumbers.find(number => number.number === sbfNumber.number)) {
                                    sbfNumbers = sbfNumbers.filter(number => number.number !== sbfNumber.number)
                                  } else {
                                    sbfNumbers.push(sbfNumber)
                                  }
                                  if (this.props.isLoggedIn) {
                                    LocalStorageHelper.setItem('LoggedInNumberSelection', sbfNumbers)
                                  } else {
                                    LocalStorageHelper.setItem('LandingPageNumberSelection', sbfNumbers)
                                  }
                                  this.setState({})
                                }}
                            />
                            </div>
                            {/* <p>
                                Improve your company image and increase your calls with a memorable number.
                                There is a limited amount of memorable numbers available and these are
                                sold on a first-come-first-served basis so secure yours today!
                            </p> */}
                        </div>
                        <div className="col-sm-6 col-md-5 mt-xs-30">
                            <div className="bg-light brad-20 p-30 box-shadow-v3">
                                <h5 className="text-500">What are Memorable Phone Numbers?</h5>
                                <p>
                                    Memorable phone numbers are special phone numbers that are easy to remember or have a unique pattern, such as repeated digits.
                                </p>
                                <p>
                                    Just as a person with a rare or exclusive number plate can feel a sense of prestige and exclusivity, someone with a golden number can also experience a similar feeling of pride and distinction.
                                    These unique identifiers can become a symbol of status or a personal statement of individuality.
                                </p>
                                <h5 className="text-500 pt-15">Credibility, Accessibility and Prestige</h5>
                                <p>
                                    A caller who dials a business with a golden phone number may perceive the business as more established, professional, and trustworthy. The golden number suggests that the business has invested in a unique and memorable phone number, which may indicate that they take their image and branding seriously.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width bg-light pt-50 pb-50" id="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <h3 className="text-center pb-30 text-500">Gold Numbers FAQ</h3>
                            <Accordion>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="0">
                                    <Accordion.Header toggle className="text-start text-400" itemprop="name">
                                        What are Gold Numbers?
                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30 ml-0" itemprop="text">
                                            <p>
                                            Gold phone numbers, also known as vanity numbers or golden numbers, are unique, memorable, and often considered prestigious telephone numbers. They typically have easily recognizable patterns, sequences, or repetitions, making them desirable to businesses and individuals alike.
                                            </p>
                                            <p>
                                            These numbers are sought after for their marketing potential, as they can significantly enhance brand recognition and recall. A gold phone number can be a valuable asset for businesses, as it helps customers remember and associate the number with the company's products or services. It can also improve the effectiveness of advertising campaigns, as potential customers are more likely to retain and act upon a memorable contact number.
                                            </p>
                                            <p>
                                            For individuals, gold phone numbers may be a status symbol or a personal preference based on their aesthetics or ease of use. Some people might choose a number with a meaningful pattern, such as a birthdate, an anniversary, or a lucky number.
                                            </p>
                                            <p>
                                            In general, the value of a gold phone number is determined by several factors, including the level of demand for the specific sequence, the rarity of the pattern, and the ease of recall. The more unique and memorable the number, the higher its perceived value and market price.
                                            </p>
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="1">
                                    <Accordion.Header toggle className="text-400 text-start" itemprop="name">
                                    What do the star ratings next to the numbers mean?
                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30 ml-0" itemprop="text">
                                        <p>
                                            Gold numbers cost £10 per month and are rated 3-stars, while Silver numbers cost £5 per month and are rated 2-stars. Gold numbers are generally considered to be more prestigious and valuable than Silver numbers because they are easier to remember and more unique.
                                        </p>
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="2">
                                    <Accordion.Header toggle className="text-400 text-start" itemprop="name">
                                        Can I use my Gold or Silver number for business purposes?
                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30 ml-0" itemprop="text">
                                        <p>
                                            Yes, you can use your Gold or Silver number for personal or business purposes.
                                        </p>
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="3">
                                    <Accordion.Header toggle className="text-400 text-start" itemprop="name">
                                        Why should I use Gold phone number?
                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30 ml-0" itemprop="text">
                                        <p>
                                            Using a Gold phone number can give your business a professional appearance. A memorable and easy-to-remember phone number can make your business more accessible to potential customers, while giving your business a more professional image.
                                        </p>
                                        <p>
                                            A caller who dials a business with a golden phone number may perceive the business as more established, professional, and trustworthy. The golden number suggests that the business has invested in a unique and memorable phone number, which may indicate that they take their image and branding seriously.
                                        </p>
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" eventKey="4">
                                    <Accordion.Header toggle className="text-400 text-start" itemprop="name">
                                        Are Gold Numbers any good for advertising?
                                    </Accordion.Header>
                                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <Accordion.Body className="pb-30 ml-0" itemprop="text">
                                        <p>
                                            A Gold phone number can be an effective advertising tool. By using a phone number that is easy to remember and relates to your business, you can increase the chances that potential customers will call you instead of your competitors.
                                        </p>
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>

            <ChoosePayment
                total={calculateVat(this.state.price)}
                clientId={this.props.clientId}
                purchaseData={this.state.key}
                email={this.props.userDetails.email}
                name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                mandate={!this.props.services.hasAgreement}
            />

        </>
  }

  componentDidMount () {
    const queryString = this.props.match.params.queryString
    if (queryString) {
      const params = queryString.toLowerCase()
      if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
          'use strict'
          if (typeof start !== 'number') {
            start = 0
          }

          if (start + search.length > this.length) {
            return false
          } else {
            return this.indexOf(search, start) !== -1
          }
        }
      }
      if (params.includes('new=true')) {
        this.props.handleChangeHasAgreement(true)
        this.props.notify({ message: 'Number purchase complete' })
      }
    }
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    notify: handleNotificationChange,
    handleChangeHasAgreement,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal
  }

)(MemorableNumbers)
