import moment from 'moment'
import React from 'react'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'

const ReportDateTimeSelectionPanel = (props) => {
  function getToday () {
    const start = moment().startOf('day')
    const end = moment().endOf('day')
    return { fromDate: start, toDate: end }
  }

  function getLastWeekend () {
    const start = moment().weekday(-1).startOf('day')
    const end = moment().weekday(0).endOf('day')
    return { fromDate: start, toDate: end }
  }

  function getThisWeek () {
    const start = moment().weekday(1).startOf('day')
    const end = moment().weekday(7).endOf('day')
    return { fromDate: start, toDate: end }
  }

  function getThisMonth () {
    const start = moment().startOf('month')
    const end = moment().endOf('month')
    return { fromDate: start, toDate: end }
  }

  function getYesterday () {
    const start = moment().add(-1, 'days').startOf('day')
    const end = moment().add(-1, 'days').endOf('day')
    return { fromDate: start, toDate: end }
  }

  function getPreviousWeekend () {
    const start = moment().weekday(-8).startOf('day')
    const end = moment().weekday(-7).endOf('day')
    return { fromDate: start, toDate: end }
  }

  function getPreviousWeek () {
    const start = moment().weekday(-6).startOf('day')
    const end = moment().weekday(0).endOf('day')
    return { fromDate: start, toDate: end }
  }

  function getLastMonth () {
    const start = moment().add(-1, 'months').startOf('month')
    const end = moment().add(-1, 'months').endOf('month')
    return { fromDate: start, toDate: end }
  }

  function getLastThreeMonths () {
    const start = moment().add(-3, 'months').startOf('month')
    const end = moment().add(-1, 'months').endOf('month')
    return { fromDate: start, toDate: end }
  }

  function getLastSixMonths () {
    const start = moment().add(-6, 'months').startOf('month')
    const end = moment().add(-1, 'months').endOf('month')
    return { fromDate: start, toDate: end }
  }

  function getLastYear () {
    const start = moment().add(-1, 'years').startOf('year')
    const end = moment().add(-1, 'years').endOf('year')
    return { fromDate: start, toDate: end }
  }

  function getThisYear () {
    const start = moment().startOf('year')
    const end = moment().endOf('year')
    return { fromDate: start, toDate: end }
  }

  function handleQuickSelectDateChange (val) {
    let date = getToday()
    switch (val) {
      case '1':
        date = getToday()
        break
      case '2':
        date = getYesterday()
        break
      case '3':
        date = getLastWeekend()
        break
      case '4':
        date = getPreviousWeekend()
        break
      case '5':
        date = getThisWeek()
        break
      case '6':
        date = getPreviousWeek()
        break
      case '7':
        date = getThisMonth()
        break
      case '8':
        date = getLastMonth()
        break
      case '9':
        date = getLastThreeMonths()
        break
      case '10':
        date = getLastSixMonths()
        break
      case '11':
        date = getThisYear()
        break
      case '12':
        date = getLastYear()
        break
    }
    props.onFromDateChange(date.fromDate)
    props.onToDateChange(date.toDate)
  }

  const checkToDate = function (current) {
    props.onFromDateChange(current)
    if (current > props.toDateValue) {
      props.onToDateChange(current)
    }
  }

  const futureDays = Datetime.moment()
  const validFuture = function (current) {
    return current.isBefore(futureDays)
  }

  return (
        <div className="row">
            <div className="col-md-4 col-sm-12">
                <label className="pt-sm-20">Quick date</label>
                <select className="form-control" onChange={e => handleQuickSelectDateChange(e.target.value)}>
                    <option value={1}>Today</option>
                    <option value={2}>Yesterday</option>

                    <option value={3}>Last Weekend</option>
                    <option value={4}>Prev Weekend</option>

                    <option value={5}>This Week</option>
                    <option value={6}>Last Week</option>

                    <option value={7}>This Month</option>
                    <option value={8}>Last Month</option>
                    <option value={9}>Last 3 Months</option>
                    <option value={10}>Last 6 Months</option>

                    <option value={11}>This Year</option>
                    <option value={12}>Last Year</option>
                </select>
            </div>
            <div className="col-md-4 col-sm-6">
                <label className="pt-sm-20">Start date</label>
                <Datetime isValidDate={validFuture} closeOnSelect={true} inputProps={{ readOnly: true }} value={props.fromDateValue} onChange={e => checkToDate(e)} timeFormat={true} dateFormat='DD/MM/YYYY'/>
            </div>
            <div className="col-md-4 col-sm-6">
                <label className="pt-sm-20">End date</label>
                <Datetime isValidDate={validFuture} closeOnSelect={true} inputProps={{ readOnly: true }} value={props.toDateValue} onChange={e => props.onToDateChange(e)} timeFormat={true} dateFormat='DD/MM/YYYY'/>
            </div>
        </div>
  )
}

ReportDateTimeSelectionPanel.propTypes = {}
ReportDateTimeSelectionPanel.defaultProps = {}

export default ReportDateTimeSelectionPanel
