import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchHelper } from '../../../helpers/fetchHelper'
import { handleNotificationChange } from '../../../store/SBF'

const classNames = require('classnames')

const PasswordUI = (props) => {
  const validations = props.rules.map((rule) => rule(props.password))

  const isValid = validations.reduce(function (acc, val) {
    return acc && val
  }, true)

  return <ul className="password-check">{props.children.map((child, i) => {
    const btnClass = classNames(child.props.className, {
      'text-success': validations[i],
      'text-danger': !validations[i] && props.triggeredValidation
    })
    return React.cloneElement(child, { className: btnClass })
  })}

        {props.triggeredValidation && props.password && !isValid && <li className="text-danger">Please check your password meets these criteria</li>}
    </ul>
}

class PasswordSection extends Component {
  state = {
    currentPassword: '',
    newPassword: '',
    newPasswordValid: false,
    repeatNewPassword: '',
    repeatNewPasswordValid: false
  }

  handleNewPasswordChanged (value) {
    const isValid = new RegExp(/[0-9]/).test(value) && value.length >= 12 && new RegExp(/[A-Z]/).test(value) && new RegExp(/[!#@$%^&*)(+=._-]/).test(value)

    this.setState({
      newPassword: value,
      newPasswordValid: isValid,
      repeatNewPasswordValid: this.state.repeatNewPassword === value
    })
  }

  handleRepeatNewPasswordChanged (value) {
    this.setState({
      repeatNewPassword: value,
      repeatNewPasswordValid: this.state.newPassword === value
    })
  }

  render () {
    return (
            <>
                <div className="bg-light p-30 pb-40 brad-10 b-1 w-100 center-block mb-20 ">
                    <div className="text-center">
                        <h4 className="pb-20 text-400">Change password</h4>
                        <p className="pb-20">Your password must be at least 12 characters long and contain at least one
                            number, at least one special character, at least one uppercase and at least one lowercase character.</p>
                    </div>
                    <div className="row w-500px center-block">
                        <div className="col-md-12">
                            <label className="pt-20">Current password</label>
                            <input type="password" className="form-control" maxLength={32} name='password' placeholder="Current password" value={this.state.currentPassword} onChange={(e) => this.setState({ currentPassword: e.target.value })} />
                        </div>
                        <div className="col-md-12">
                            <label className="pt-20">New password</label>
                            <input type="password" className="form-control" maxLength={32} name='newPassword' placeholder="New password" value={this.state.newPassword} onChange={(e) => this.handleNewPasswordChanged(e.target.value)} />
                            <div>
                                <div className="bg-dull text-start p-10 brad-5 mt-10 b-1" style={{ overflow: 'auto' }}>
                                    <p className="p-0 mt-0 mb-5 text-sm text-400 text-center pb-20">Password must contain:</p>
                                    <PasswordUI
                                        className="w-400px"
                                        password={this.state.newPassword}
                                        triggeredValidation={this.state.newPassword}
                                        rules={[v => new RegExp(/[0-9]/).test(v), v => v && v.length >= 12, v => new RegExp(/[A-Z]/).test(v), v => new RegExp(/[!#@$%^&*)(+=._-]/).test(v)]}>
                                        <li className="text-400 col-xs-6 col-xxs-12">
                                            <i className="icon icon-question2"></i>
                                            <i className="icon icon-checkmark-circle is-valid hidden"></i>
                                            <i className="icon icon-cancel-circle2 not-valid"></i>
                                            Contains a number
                                        </li>
                                        <li className="text-400 col-xs-6 col-xxs-12">
                                            <i className="icon icon-question2"></i>
                                            <i className="icon icon-checkmark-circle is-valid hidden"></i>
                                            <i className="icon icon-cancel-circle2 not-valid"></i>
                                            At least 12 characters
                                        </li>
                                        <li className="text-400 col-xs-6 col-xxs-12">
                                            <i className="icon icon-question2"></i>
                                            <i className="icon icon-checkmark-circle is-valid hidden"></i>
                                            <i className="icon icon-cancel-circle2 not-valid"></i>
                                            An uppercase character
                                        </li>
                                        <li className="text-400 col-xs-6 col-xxs-12">
                                            <i className="icon icon-question2"></i>
                                            <i className="icon icon-checkmark-circle is-valid hidden"></i>
                                            <i className="icon icon-cancel-circle2 not-valid"></i>
                                            A special character
                                        </li>
                                    </PasswordUI>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label className="pt-20">Repeat password</label>
                            <input type="password" name='confirm' className="form-control" maxLength={32} placeholder="Repeat password" value={this.state.repeatNewPassword} onChange={(e) => this.handleRepeatNewPasswordChanged(e.target.value)} />
                        </div>
                        <div className="col-md-12 text-center mt-30">
                            <Button variant="cta" size="lg" type='submit' disabled={!this.state.newPasswordValid || !this.state.repeatNewPasswordValid} onClick={() => this.handleChangePasswordPressed()}>Change Password</Button>
                        </div>
                    </div>
                </div>
            </>
    )
  }

  handleChangePasswordPressed () {
    const url = '/api/Profile/SetPassword'

    const values = {
      password: this.state.currentPassword,
      newPassword: this.state.newPassword
    }

    fetchHelper.postJson(url, values).then(res => {
      const data = res.data
      if (data.hasErrors) {
        this.props.notify({
          message: 'Please enter a valid passowrd',
          isError: true
        })
      } else {
        this.props.notify({ message: 'Password updated' })
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  /*

         let url = '/api/Profile/SetPassword';

                    setSubmitting(true);

                    fetchHelper.postJson(url, values).then(res => {
                        const data = res.data;
                        if (data.hasErrors) {
                            this.props.notify({
                                message: "Please enter a valid passowrd",
                                isError: true
                            });

                            setErrors({ password: data.password })
                        }
                        else {
                            this.props.notify({ message: "Password updated" });
                        }
                    }).catch(function (error) {
                        console.error(error);
                    });

    */

  updatePassword () {
    const context = this

    const newPasswordPwd = this.state.password
    const oldPwd = this.state.old

    const url = '/api/Profile/SetPassword/'

    fetchHelper.postJson(url, { oldPwd, newPasswordPwd }).then(res => {
      // let contacts = res.data;
      // context.setState({loadingContacts: false, contacts: contacts});
    }).catch(function (error) {
      console.error(error)
    })
  }
}

export default connect(state => {
}, { notify: handleNotificationChange })(PasswordSection)
