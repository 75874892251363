import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../../store/Configuration'

const MusicOnHoldHelperDialog = props => (
    <Modal size="w-900px" show={props.isDisplayed}
           onHide={props.handleHideMusicOnHoldInstruction}>
        <Modal.Header closeButton>
            <Modal.Title className="text-500">Music On Hold</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                This is the music which will be played to your caller while they are waiting to speak with you.
            </p>

            <p>
                The caller will hear clips of music (usually between 30 seconds to 1 minute long) between which they
                will hear various messages such as their position in the call queue (if enabled) or how they can leave a
                voicemail (if you have these features enabled).
            </p>

        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-xs" onClick={props.handleHideMusicOnHoldInstruction}>Close</button>
        </Modal.Footer>
    </Modal>
)

const mapStateToProps = state => ({ isDisplayed: state.configuration.dialogVisibility.mohInstructionHelperDialogDisplayed })

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MusicOnHoldHelperDialog)
