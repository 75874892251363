import React from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import * as yup from 'yup'
import DisplaySimpleFooter from '../../components/DisplaySimpleFooter'
import DisplaySimpleHeader from '../../components/DisplaySimpleHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../helpers/fetchHelper'
import OpeningTimesOffice from '../../Text/OpeningTimesOffice'

class ForgotPassword extends React.Component {
  state = {
    mobileEnding: '',
    emailSent: false,
    resetting: false,
    resent: false,
    email: ''
  }

  constructor (props) {
    super(props)
  }

  render () {
    function refreshPage () {
      window.location.reload(false)
    }

    return <div><ScrollToTopOnMount/><DisplaySimpleFooter/><DisplaySimpleHeader/>
            <Helmet>
                <body className="body-simple bg-dull " />
                </Helmet>
            <div className="full-width bg-dull">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pb-30 mt-30 mb-30">
                            <div className="w-500px p-30 brad-20 box-shadow bg-light b-1 center-block">

                            {this.state.emailSent
                              ? <div className="text-start">
                                    <h1 className="h4 text-500 text-center">Check your email</h1>
                                    <p>
                                       If you entered a correct email address, you should receive an email from us with further instructions.
                                    </p>
                                    <p>
                                        If there is no email in your inbox (check your spam/junk folder as well) within the next 10 minutes, then please call our support team on <a href="tel:0203 189 1213" className="underline text-cta">0203 189 1213</a>.
                                    </p>
                                    <p>
                                        We're open <OpeningTimesOffice />.
                                    </p>
                                    <p className="pt-25 mt-25 bt-1">
                                        You can now close this page, or <span className="text-cta underline cursor-pointer" onClick={refreshPage}>refresh</span> to try again.
                                    </p>
                                </div>
                              : <div className="text-center">
                                    <h1 className="h4 text-500" id="ForgotYourPasswordH1">Forgot your password?</h1>
                                    <p>
                                        Enter the email address you use to login
                                        and we'll email you a link to change your password.
                                    </p>
                                </div>
                            }

                            {this.state.mobileEnding && !this.state.resent &&
                            <>
                                <div className="bg-dull pt-20 b-1 mt-20 p-30">
                                    <h5 className="text-500 mt-0 mb-20">Get your password reset link sent to your phone</h5>
                                    <p>
                                        We can see that you have a mobile number on your account ending in <span className="text-500">{this.state.mobileEnding}</span>.
                                        We can send your password reset link to this mobile as well.
                                    </p>
                                    <div className="text-center">
                                        <Button variant="cta" disabled={this.state.resetting} onClick={e => this.handlePasswordResendClicked()}>
                                            Send password via SMS
                                        </Button>
                                    </div>
                                </div>
                                </>}
                                {this.state.resent &&
                                <p>
                                    We have also sent your password reset link to the mobile number we have on file for you ending {this.state.mobileEnding}.
                                </p>}

                                {!this.state.emailSent && <>
                                    <input className="form-control mb-20 w-300px center-block mt-30" placeholder="Your login email" type="email" name="email" autoComplete="username" id='resetPasswordEmail' onChange={e => this.handleResetEmailEntered()}/>
                                    <div className="pt-20 pb-20 text-center">
                                        <Button variant="cta" size="md" onClick={e => this.handlePasswordResetClicked()} disabled={this.state.resetting}>Send Password</Button>
                                    </div>
                                    </>}
                        </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
  }

  componentWillMount () {
  }

  componentDidMount () {
    document.title = 'Password Reset'
  }

  handlePasswordResetClicked () {
    const context = this

    const resetEmail = document.getElementById('resetPasswordEmail').value
    const validEmail = yup.string().required().email().isValidSync(resetEmail)

    if (!validEmail) {
      // document.getElementById('emailBadError').style.display = "block";

      this.setState({ reason: 'Please enter a valid SwitchboardFREE email address and try again' })
    } else {
      context.setState({ resetting: true, reason: '' })
      const url = '/api/Users/ResetPasswordRequestLink'

      fetchHelper.postJson(url, { resetEmail, method: 2 }).then(res => {
        // if (res.data && res.data.isSuccess) {
        context.setState({ ...this.state, resetting: false, emailSent: true, ...res.data })
        // }
      }).catch(function (error) {
      })
    }
  }

  handlePasswordResendClicked () {
    const context = this

    const resetEmail = this.state.email
    const url = '/api/Users/ResetPasswordRequestLink'

    context.setState({ resetting: true, reason: '' })

    fetchHelper.postJson(url, { resetEmail, method: 1, resend: true }).then(res => {
      // if (res && res.data) {
      context.setState({ ...this.state, resetting: false, resent: true, ...res.data })
      // }
    }).catch(function (error) {
    })
  }

  handleResetEmailEntered () {
    const context = this
    const resetEmail = document.getElementById('resetPasswordEmail').value
    context.setState({ ...this.state, email: resetEmail })
  }
}

export default connect(
  state => {
    return state
  },
  {}
)(ForgotPassword)
