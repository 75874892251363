import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStarted from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface BTCloudProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const BTCloud = (props: BTCloudProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>BT Cloud vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>BT Cloud vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of BT Cloud vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">What is BT Cloud?</h2>
                            <p>BT Cloud offer a VoIP system that acts like a traditional phone system with the difference of being based in the cloud, i.e., it does not require hardware installation and maintenance.</p>
                            <p>Users of BT Cloud services need to have a BT Business Broadband and it is recommended to buy a BT Business IP phone. The BT VoIP system works only with IP phones.</p>
                            <p>BT Cloud have three services available, BT Cloud Phone, BT Cloud Voice and BT One Phone Office.</p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">BT Cloud Phone</h2>
                            <p>BT Cloud Phone is the simplest of the three and is designed for smaller businesses. It can be used by up to 9 users and presents the customers with the regular features of a phone system, such as call forwarding, routing, transferring, screening, voicemail and call recording. In addition, BT Cloud Phone users can benefit from mobile and desktop apps, free internal calls between employees, and a special online portal to manage their account.</p>
                            <p>BT charge a setup fee of £100 if signing up for the minimum 1-year contract. There is no setup fee if signing a 5-year contract.</p>
                            <p>The subscription costs start from £13 per a user / per month on a 5-year contract.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">BT Cloud Voice</h2>
                            <p>BT Cloud Voice is a more sophisticated and flexible system than BT Cloud Phone. It is designed for businesses from 5 to 15 employees and users have access to more individually tailored features, as well as minute bundles and unlimited UK calls packages.</p>
                            <p>The connection charges remain the same at £100 for 1 or 3-year contracts and £0 for a 5-year contract.</p>
                            <p>The subscription cost starts from £6 per a user / per month on a 5-year contract.</p>

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">BT One Phone Office</h2>
                            <p>The BT One Phone Office system is the most flexible of the three. It is designed for businesses with a mobile workforce and more than 15 users. Pricing cannot be easily determined, as the clients have the freedom to negotiate what works best for them and they can add and remove features when they need them. The standard features involve all of the available features of the other two products as well as a selection of company shared bundles. One considerable difference between the One Phone Office and the Cloud Phone & Cloud Voice systems is that the former requires engineer installation.</p>

                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">How do BT Cloud and SwitchboardFREE compare?</h2>
                            <p>In over 17 years of trading SwitchboardFREE has never charged any setup costs and doesn’t ask for any long term contracts, so confident we are, that you will love the SwitchboardFREE service. You won’t need to install any equipment and don’t need to be with any specific landline or mobile provider to start using the service.</p>
                            <p>Choose a virtual phone number from any UK area code or other number type (0800, 0203, 0207, 03) and if for any reason you wish to swap this in the future you can do so free of charge at any time.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SideBarGetStarted mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(BTCloud)
