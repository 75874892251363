import React from 'react'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import SiteMapComponent from '../components/Sitemap'

export default class NotFound extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <header style={{ backgroundImage: "url('/images/backgrounds/404-page.jpg')" }} className="full-width full-hero-small">
            </header>
            <div className="container-styled">
                <div className="container">
                    <h1 className="text-center">You seem to be lost</h1>
                    <h4 className="text-center">Unfortunately the page you are looking for can't be found.<br />Hopefully you can find the page that you are looking for amongst the links below.</h4>
                    <SiteMapComponent />
                </div>
                <div className="full-width full-secondary text-lightest p-30 ">
                    <div className="col-md-12 text-center">
                        <h3 className="text-500">Enjoy business call management, get started in minutes risk-free today</h3>
                        <p>We are confident you won't find another provider that will offer you better
                            call management services, with no catches or commitments.<br />With SwitchboardFREE, you are in complete control.
                        </p>
                        <Link className="btn btn-lg btn-cta btn-mobile-block mt-20 mb-30" to={'/get-started'}>Choose your number</Link>

                        <p>Or <a href="./compare-business-numbers/">compare pricing &amp; packages</a></p>
                    </div>
                </div>
            </div>
        </>
  }
}
