import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'
import { departmentsEnabledState } from '../../store/Configuration'
import DepartmentButton from './DepartmentButton'

const DepartmentNumberControl = props => {
  return props.isDepartmentActive ? (
        <div className="keypad">
            <div className='container'>
                <div className="row text-center">
                    <p className="text-cta text-500 text-sm pb-10 hide-1300">You're editing Department #{props.depNo}</p>
                    <div className="col-xs-4 pb-10">
                        <DepartmentButton depNo={'1'} isEnabled={props.departmentsEnabled ? props.departmentsEnabled[0].enabled : false} queueNo={props.queueNo}
                                        checked={props.depNo === '1'}/>
                    </div>
                    <div className="col-xs-4 pb-10">
                        <DepartmentButton depNo={'2'} isEnabled={props.departmentsEnabled ? props.departmentsEnabled[1].enabled : false} queueNo={props.queueNo}
                                        checked={props.depNo === '2'}/>
                    </div>
                    <div className="col-xs-4 pb-10">
                        <DepartmentButton depNo={'3'} isEnabled={props.departmentsEnabled ? props.departmentsEnabled[2].enabled : false} queueNo={props.queueNo}
                                        checked={props.depNo === '3'}/>
                    </div>

                    <div className="col-xs-4 pb-10">
                        <DepartmentButton depNo={'4'} isEnabled={props.departmentsEnabled ? props.departmentsEnabled[3].enabled : false} queueNo={props.queueNo}
                                        checked={props.depNo === '4'}/>
                    </div>
                    <div className="col-xs-4 pb-10">
                        <DepartmentButton depNo={'5'} isEnabled={props.departmentsEnabled ? props.departmentsEnabled[4].enabled : false} queueNo={props.queueNo}
                                        checked={props.depNo === '5'}/>
                    </div>
                    <div className="col-xs-4 pb-10">
                        <DepartmentButton depNo={'6'} isEnabled={props.departmentsEnabled ? props.departmentsEnabled[5].enabled : false} queueNo={props.queueNo}
                                        checked={props.depNo === '6'}/>
                    </div>

                    <div className="col-xs-4 pb-10">
                        <DepartmentButton depNo={'7'} isEnabled={props.departmentsEnabled ? props.departmentsEnabled[6].enabled : false} queueNo={props.queueNo}
                                        checked={props.depNo === '7'}/>
                    </div>
                    <div className="col-xs-4 pb-10">
                        <DepartmentButton depNo={'8'} isEnabled={props.departmentsEnabled ? props.departmentsEnabled[7].enabled : false} queueNo={props.queueNo}
                                        checked={props.depNo === '8'}/>
                    </div>
                    <div className="col-xs-4 pb-10">
                        <DepartmentButton depNo={'9'} isEnabled={props.departmentsEnabled ? props.departmentsEnabled[8].enabled : false} queueNo={props.queueNo}
                                        checked={props.depNo === '9'}/>
                    </div>

                    <div className="col-xs-12 text-center department-control">
                        <div className="col-xs-6 pl-0 pr-0">
                            <p className="text-cta text-500 text-sm">Department #{props.depNo} is:</p>
                        </div>
                        <div className="col-xs-6 pl-0 pr-0">
                        {/* <input type='checkbox' checked={props.isEnabled} onChange={e => props.handleChangeDepartmentEnabled({checked:e.target.checked, depNo:props.depNo})}/> */}
                        <Toggle
                                                className="mt-0"
                                                onClick={e => {
                                                  return props.handleChangeDepartmentEnabled({
                                                    checked: e,
                                                    depNo: props.depNo
                                                  })
                                                }}
                                                on={<span>ENABLED</span>}
                                                off={<span>DISABLED</span>}
                                                size="sm"
                                                offstyle="default"
                                                onstyle="success"
                                                width="null"
                                                height="null"
                                                active={props.isEnabled}
                                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
  ) : null
}

const mapStateToProps = state => {
  return ({
    isDepartmentActive: state.configuration.selectedConfiguration.isDepartmentActive,
    isStandardActive: state.configuration.selectedConfiguration.isStandardActive,
    depNo: state.configuration.selectedConfiguration.depNo,
    isEnabled: state.configuration.configuration.isDepartmentEnabled,
    queueNo: state.configuration.selectedConfiguration.queueNo,
    departmentsEnabled: departmentsEnabledState(state)
  })
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentNumberControl)
