import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fieldNames } from '../../constants/ConfigurationConstants'
import { getConfigValidators } from '../../selectors'
import * as configurationActions from '../../store/Configuration'
import Validator from '../Validator'
import GlobalMissedCallEmailAlertsInstructionHelperDialog from './InstructionHelperDialogs/GlobalMissedCallEmailAlertsInstructionHelperDialog'

const GlobalMissedCallEmailAlertsSection = (props) => (
    <>
        <div className="row bg-dullest p-30 zoom">
            <div className="col-md-5 pb-sm-10">
                <h5 className="text-secondary text-500 text-lg">
                    Global Missed Call Email Alerts
                </h5>
                <p className="text-400">
                    Receive a real-time alert via email containing the caller’s number, geographical location and time stamp allowing you to call them straight back.  <a onClick={props.handleShowGlobalMissedCallEmailAlertsInstruction} className="underline" id="GlobalMissedCallEmailAlertsMoreInfoLink">More info</a>
                </p>
            </div>

            <GlobalMissedCallEmailAlertsInstructionHelperDialog />
            <div className="col-md-7 pl-sm-0 pr-sm-0">
                <div className='row'>
                    <div className="col-md-4 pb-sm-30 text-sm-center">
                        <p>Turn ON/OFF:</p>
                        <Toggle
                            className="mt-0"
                            onClick={e => props.handleGlobalMissedCallEmailsEnabledChange(e)}
                            on={<span>ON</span>}
                            off={<span>OFF</span>}
                            size="sm"
                            offstyle="default"
                            onstyle="success"
                            width="null"
                            height="null"
                            active={props.globalMissedCallEmailsEnabled}
                            id="GlobalMissedCallEmailAlertsToggle"
                        />
                    </div>
                    <div className="col-md-8">
                        <p>Send me alerts to email address:</p>
                        <input type="text" inputModel='email' maxLength='128' className="form-control" value={props.globalMissedCallEmailAddress} onBlur={e => props.validateEmailSyntax({ fieldName: fieldNames.GLOBAL_MISSED_CALL_EMAIL, value: e.target.value })} onChange={e => props.handleGlobalMissedCallEmailChange(e.target.value)} id="GlobalMissedCallEmailAlertsInput"/>
                    </div>
                </div>
            </div>
            <Validator validation={props.validation[fieldNames.GLOBAL_MISSED_CALL_EMAIL]} />
        </div>
    </>
)

const mapStateToProps = state => ({
  globalMissedCallEmailsEnabled: state.configuration.configuration.isGlobalMissedCallEmailEnabled,
  globalMissedCallEmailAddress: state.configuration.configuration.globalMcEmailAddress,
  validation: getConfigValidators(state)
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalMissedCallEmailAlertsSection)
