import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'
import { updateUserFromLocalStorage } from '../../store/SBF'
import DisplayLoggedInFooter from '../DisplayLoggedInFooter'
import DisplayMainFooter from '../DisplayMainFooter'
import DisplayMainHeader from '../DisplayMainHeader'
import ScrollToTopOnMount from '../ScrollToTopOnMount'

class ContactBookSyncPipedriveCrm extends React.Component {
  state = {
    syncingContacts: true,
    stage: 'Queued',
    code: '',
    intervalId: undefined
  }

  componentDidMount () {
    if (this.props.isLoggedIn === true) {
      const query = new URLSearchParams(this.props.location.search)

      const code = query.get('code')

      const url = '/api/contacts/RegisterPipedriveCrmSyncRequest'

      const data = {
        code
      }

      fetchHelper.postJson(url, data).then(res => {

      })

      const id = setInterval(() => this.checkCapsuleCrmSyncStatus(), 3 * 1000)

      this.setState({
        code,
        intervalId: id
      })
    }
  }

  checkCapsuleCrmSyncStatus () {
    const url = '/api/contacts/GetCrmInitialSyncStatus/6'

    fetchHelper.getJson(url).then(res => {
      this.setState({
        stage: res.data,
        syncingContacts: res.data !== 'Done'
      })
    })
  }

  componentWillUnmount () {
    if (this.state) {
      const id = this.state.intervalId
      clearInterval(id)
    }
  }

  render () {
    if (this.props.isLoggedIn === false) {
      const query = new URLSearchParams(this.props.location.search)

      const code = query.get('code')

      return <>
                <ScrollToTopOnMount/>
                <DisplayMainHeader
                />
                <DisplayMainFooter/>
                <Helmet>
                    <title>Pipedrive CRM Sync</title>
                    <meta name="description" content="Outbound call settings" />
                </Helmet>
                <header>
                    <div className="full-width full-hero-small with-text" >
                    <h1>Pipedrive CRM Sync</h1>
                    </div>
                </header>

                <div className="container pt-30 pb-30">
                    <div className="w-600px center-block bg-light mt-30 mb-100 brad-30 p-40 text-center box-shadow">

                        {this.state.syncingContacts === true && <div className="loading-content text-center">
                            <p className="text-dark text-400"> click login If you are an existing customer else click sign up </p>
                            <Link to={'/get-started?pipedriveCode=' + code} className="btn btn-lg btn-cta"> Signup </Link> <span>&nbsp;</span>
                            <Link to={'/login&redirect=/customer/contact-sync/pipedrive-crm?code=' + code} className="btn btn-lg btn-cta"> Login </Link>
                        </div>}
                    </div>
                </div>
            </>
    } else {
      return <>
                <ScrollToTopOnMount/>
                <DisplayLoggedInFooter/>
                <DisplayMainHeader/>
                <Helmet>
                    <title>Pipedrive CRM Sync</title>
                    <meta name="description" content="Outbound call settings" />
                </Helmet>
                <header>
                    <div className="full-width full-hero-small with-text" >
                    <h1>Pipedrive CRM Sync</h1>
                    </div>
                </header>

                <div className="container pt-30 pb-30">
                    <div className="w-600px center-block bg-light mt-30 mb-100 brad-30 p-40 text-center box-shadow">

                        {this.state.syncingContacts === true && <div className="loading-content text-center">
                            <img id="loading" src={'images/icons/loading.svg'} width="70" height="70" />
                            <p className="text-dark text-400">{this.state.stage}</p>
                        </div>}

                        {this.state.syncingContacts === false && <div className="loading-content text-center">
                            <div className="success-icon mt-20 mb-20">
                                <span className="icon icon-checkmark4"></span>
                            </div>
                            <p>Your Pipedrive contacts has been synced successfully.</p>
                            <p className="pt-20">
                                <Link to={'/customer/contact-book/'} className="btn btn-cta btn-sm">See your updated contacts</Link>
                            </p>
                        </div>}
                    </div>
                </div>
            </>
    }
  }
}

export default withRouter(connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  }, { updateUserFromLocalStorage }
)(ContactBookSyncPipedriveCrm))
