import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as configurationActions from '../../store/Configuration'
import CustomRecordingSectionHelperDialog from './InstructionHelperDialogs/CustomRecordingSectionHelperDialog'

const CustomRecordingsSection = props => (
    <>
    <div className="row bg-light p-30 zoom">
        <div className="col-md-5">
            <h5 className="text-secondary text-500 text-lg">Custom Recordings
                </h5>
                <p className="text-400">
                To record your own bespoke introduction, out of hours and whisper messages for the SwitchboardFREE number download the SwitchboardFREE <a className="underline" href="https://play.google.com/store/apps/details?id=com.liquid11.SwitchboardFREE&hl=en_GB" id="CustomRecordingsAndroidLink">Android</a> or <a className="underline" href="https://itunes.apple.com/gb/app/sbf-2012/id556878596" id="CustomRecordingsIOSLink">iOS</a> app.
                </p>
         </div>
        <div className="col-md-7 mt-30">

                <CustomRecordingSectionHelperDialog/>
                    <Button size="xs" variant="info" className='m-5' onClick={props.handleShowCustomRecordingSectionHelperInstruction} id="CustomRecordingsMakeARecordingButton">Make a recording</Button>
                    <Link className='btn btn-xs btn-info m-5' to={'/manage-recordings'} id="CustomRecordingsManageRecordingsButton">Manage Recordings</Link>

        </div>
    </div>
    </>
)

const mapStateToProps = state => ({
  // selectedNumberPresentationOption: state.configuration.config.numberPresentation.numberPresentationOptionKey
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  configurationActions,
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomRecordingsSection)

// export default connect(
//     state => state.configuration,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(IntroductionSection);
