import React, { useEffect, useState, type ChangeEvent } from 'react'
import { Button } from 'react-bootstrap'
import { type RouteComponentProps } from 'react-router-dom'
import GetProdPrice from '../../components/GetProdPrice'
import SvgRender from '../../components/SvgRender'
import { MultiNumberPrice, MultiNumberPrice0800, UnlimPlanDetails } from '../../constants/Constants'
import { SupportedCallDirection, type Basket } from '../../models/SignupTypes'
import { fetchHelper } from '../../helpers/fetchHelper'
import { Helmet } from 'react-helmet'

interface SignupTypeProps extends RouteComponentProps {
  firstName: string
  lastName: string
  age: number
  supportedCallDirection?: SupportedCallDirection
  numberOnly: boolean
  numberFull: boolean
  showMobileVoipInfo: boolean
  showMobileInboundInfo: boolean
  isLoggedIn: boolean
  handleChangeNumberOnly: (numberOnly: boolean, numberFull: boolean) => void
  handleInboundOrOutboundSelection: (supportedCallDirection: SupportedCallDirection) => void
  selectedNumberType: string
  basket?: Basket | undefined
}

interface SignupTypeState {
  firstName: string
  lastName: string
  age: number
  hoverNumberOnly: boolean
  hoverNumberFull: boolean
  hoverOutbound: boolean
  showMobileVoipInfo: boolean
  hoverInbound: boolean
  showMobileInboundInfo: boolean
  hasMinsPack?: boolean
}

const SignupType: React.FunctionComponent<SignupTypeProps> = (props) => {
  const [state, setState] = useState<SignupTypeState>({
    hoverInbound: false,
    hoverOutbound: false,
    hoverNumberFull: false,
    firstName: props.firstName,
    lastName: props.lastName,
    age: props.age,
    hoverNumberOnly: false,
    showMobileVoipInfo: false,
    showMobileInboundInfo: false
  })

  useEffect(() => {
    if (props.isLoggedIn && state.hasMinsPack === undefined)
      handleGetUserServices()

    window.dataLayer.push({
      event: 'signupStart'
    })
  }, [])

  function handleGetUserServices() {
    const url = 'api/Users/GetServices'

    fetchHelper.getJson<any>(url)
      .then(res => {
        if (res.data) {
          setState({
            ...state,
            hasMinsPack: res.data.hasInboundMinsPack
          })
        }
      })
  }

  function handleChangeType(val: boolean) {
    if (props.isLoggedIn) {
      if (val) {
        // number only
        props.handleChangeNumberOnly(true, false)
      } else {
        props.handleChangeNumberOnly(false, true)
      }
    } else {
      if (val) {
        props.handleInboundOrOutboundSelection(SupportedCallDirection.InboundAndOutbound)
      } else {
        props.handleInboundOrOutboundSelection(SupportedCallDirection.InboundOnly)
      }
    }
  }

  const isOutboundSelected = props.supportedCallDirection === SupportedCallDirection.InboundAndOutbound
  const isInboundSelected = props.supportedCallDirection === SupportedCallDirection.InboundOnly

  const inboundOutboundSignupSelectionRow = <div className="row">
    <div className="col-12 col-lg-8 mx-auto clearfix">
      <div className="row p-xxs-5 row-flex flex-2-col flex-sm flex-xs-1-col ml-xs-0 mr-xs-0">
        <div
          className={`brad-30 p-20 bg-light text-center mr-50 mr-xs-0 mb-xs-50 signup-package ${isOutboundSelected ? 'active b-cta-2' : 'b-2'}`}
          onMouseEnter={() => { setState({ ...state, hoverOutbound: true }) }}
          onMouseLeave={() => { setState({ ...state, hoverOutbound: false }) }}>
          <span className="block text-start float-start">
            <i className={`icon icon-checkmark-circle text-xl ${isOutboundSelected ? 'text-success' : 'text-dull'}`} />
          </span>
          <div className="p-20 pl-15 pr-15">

            {isOutboundSelected || state.hoverOutbound
              ? <SvgRender icon="make-receive-calls" svgHeight={50} />
              : <SvgRender icon="make-receive-calls-inactive" svgHeight={50} />}
            <h3 className="text-500 text-lg mb-10 mt-20">Make &amp; Receive Calls</h3>
            <p className="text-md text-400 text-cta mb-20">Complete VoIP System</p>
            <p><em>
              Take your business to new heights with a system that grows and flexes with you. Make and receive calls from your mobile, PC, laptop or desk phone. See below for more details.
            </em></p>
            <Button
              variant="lightest"
              className="btn-rounded mt-30 pl-50 pr-50 pr-sm-30 pl-sm-30 pr-xs-15 pl-xs-15 btn-xs-block btn-md"
              onClick={() => { handleChangeType(true) }}>{isOutboundSelected ? 'Selected' : 'Select this plan'}
            </Button>

            <ul className="pl-0 tick-list text-start mb-30 mt-50 text-400 ml-25">
              <li>
                Get everything from our Virtual Phone Number plan, PLUS:
              </li>
              <li>
                For complete freedom, make &amp; receive your calls from anywhere in the world.*
              </li>
              <li>
                Easily add new extensions at any time as your business grows.
              </li>
              <li>
                Conduct business at your desk on the move with your new free mobile & PC softphone app.
              </li>
              <li>
                Answer all calls quickly by simply setting up ‘teams’ so all phones ring at once.
              </li>
              <li>
                Plus, more than 20 FREE features that the others won't give you! <span
                  className="text-cta underline text-500 cursor-pointer ml-5"
                  onClick={() => {
                    setState({
                      ...state,
                      showMobileVoipInfo: !state.showMobileVoipInfo
                    })
                  }}>Read more</span>
              </li>
            </ul>

            
            {state.showMobileVoipInfo &&
              <div className="d-block ">
                <p className="w-100 text-sm text-500 text-primary mt-50 mb-20 text-center mt-xs-20">Free
                  Stuff Others Don't <i>Give</i> You:</p>
                <ul className="pl-0 tick-list text-start ml-25">
                  <li>
                    The same free stuff you get with our Virtual Phone Number plan, PLUS:
                  </li>
                  <li>
                    Get your customer to the right person with free call transfers between users.
                  </li>
                  <li>
                    Be recognised by having your virtual number on display when dialling out.
                  </li>
                  <li>
                    Let your customer choose who they speak to with a choice of departments.
                  </li>
                  <li>
                    Automatically send your customers a quick message if their call goes unanswered for any reason.
                  </li>
                  <li>
                    Tailor your marketing by using Webhooks to allow your account to share data with your current systems.
                  </li>
                  <li>
                    Screen your calls for a more efficient and personalised service.
                  </li>
                  <li>
                    Let your customers know they are being taken care of with professional prompts and system voices.
                  </li>
                  <li>
                    Grab an optional VoIP desktop phone or wireless phone for that traditional office feel.
                  </li>

                </ul>
                <Button
                  variant="lightest"
                  className="btn-rounded mt-30 pl-50 pr-50 pr-sm-30 pl-sm-30 pr-xs-15 pl-xs-15 btn-xs-block btn-md"
                  onClick={() => { handleChangeType(true) }}>{isOutboundSelected ? 'Selected' : 'Select this plan'}
                </Button>
              </div>}

          </div>
        </div>

        <div
          className={`brad-30 p-20 bg-light text-center signup-package ${isInboundSelected ? 'active b-cta-2' : 'b-2'}`}
          onMouseEnter={() => { setState({ ...state, hoverInbound: true }) }}
          onMouseLeave={() => { setState({ ...state, hoverInbound: false }) }}>

          <span className="block text-start float-start">
            <i className={`icon icon-checkmark-circle text-xl ${isInboundSelected ? 'text-success' : 'text-dull'}`} />
          </span>
          <div className="p-20 pl-15 pr-15">

            {isInboundSelected || state.hoverInbound
              ? <SvgRender icon="receive-calls-only" svgHeight={50} />
              : <SvgRender icon="receive-calls-only-inactive" svgHeight={50} />}
            <h3 className="text-500 text-lg mb-10 mt-20">Receive Calls Only</h3>
            <p className="text-md text-400 text-cta mb-20">Virtual Phone Number</p>
            <p><em>
              Give your customers the ultimate service by answering their calls on time, every time. Utilise your free call management features and connect your new virtual number to any landline or mobile number.
            </em></p>

            <Button
              variant="lightest"
              className="btn-rounded mt-30 pl-50 pr-50 pr-sm-30 pl-sm-30 pr-xs-15 pl-xs-15 btn-xs-block btn-md"
              onClick={() => { handleChangeType(false) }}>{isInboundSelected ? 'Selected' : 'Select this plan'}
            </Button>

            <ul className="pl-0 tick-list text-start mb-30 mt-50 text-400  ml-25">
              <li>
                Get unlimited calls diverted to our mobile apps, PC/Mac softphone or VoIP deskphones.
              </li>
              <li>
                Optional bolt-ons available to divert calls to UK mobile or landline numbers.
              </li>
              <li>
                Use our free mobile app to instantly update your settings.
              </li>
              <li>
                Plus, more than 20 FREE features that the others won't give you! <span
                  className="text-cta underline text-500 cursor-pointer ml-5"
                  onClick={() => {
                    setState({
                      ...state,
                      showMobileInboundInfo: !state.showMobileInboundInfo
                    })
                  }}>Read more</span>
              </li>
            </ul>

            {state.showMobileInboundInfo &&
              <div className="d-block">
                <p className="w-100 text-sm text-500 text-primary mt-70 mt-xs-20 mt-sm-30 mb-20 text-center mt-xs-20">Free
                  Stuff Others Don't <i>Give</i> You:</p>
                <ul className="pl-0 tick-list text-start ml-25">
                  <li>
                    Become national & have multiple numbers from any town or city in the UK.
                  </li>
                  <li>
                    Use your virtual numbers anywhere, including abroad. *
                  </li>
                  <li>
                    Look after your budget with 1 virtual number completely free of charge.
                  </li>
                  <li>
                    Create flexibility by routing your calls according to the time of day.
                  </li>
                  <li>
                    Nurture loyal customers by setting up a personalised caller's greeting, “Hi John…”
                  </li>
                  <li>
                    Keep your customers entertained by choosing from the best collection of on-hold music.
                  </li>
                  <li>
                    Make life easy by seamlessly integrating your call management with your existing system.
                  </li>
                  <li>
                    Stack customer calls & let them know where they are in the queue.
                  </li>
                  <li>
                    And of course, receive premier UK customer support from our team.
                  </li>
                  <li className="empty d-none d-sm-block">&nbsp;</li>
                  <li className="empty d-none d-sm-block">&nbsp;</li>
                </ul>
                <Button
                  variant="lightest"
                  className="btn-rounded pl-50 mt-30 pr-50 pr-sm-30 pl-sm-30 pr-xs-15 pl-xs-15 btn-xs-block btn-md"
                  onClick={() => { handleChangeType(false) }}>{isInboundSelected ? 'Selected' : 'Select this plan'}
                </Button>
              </div>}
          </div>
        </div>
        <div className="bg-dull brad-10 p-50 d-none d-xs-block mt-40" style={{ backgroundColor: 'rgb(199, 249, 223)' }}>
          <p><em>“Have been using switchboard for a while now for my phone system. Their android app is really useful and I can make and receive calls using my business number wherever I am now instead of having to use my mobile number!”</em></p>

          <span className="text-500 pt-10 d-inline-block">
            Christopher <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
          </span>
          <img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
        </div>
      </div>
    </div>
  </div>
  return (
    <>
      <Helmet>
        <body
          className={'body-simple bg-dull body-signup pb-0 step1-voip-or-not'}
        />
      </Helmet>
      <div className="mt-50 mb-30 mt-xs-10 mb-xs-0">
        <div className="row">
          <div className="col-md-12 text-center fixed-container pb-30 pb-xs-10">
            <h3 className="text-500 mt-0" id="SignupH3">Choose Your Plan Type</h3>
            <p>How will you use your new virtual number?</p>
          </div>
        </div>
      </div>
      {!props.isLoggedIn
        ? inboundOutboundSignupSelectionRow
        : <div className="row">
          <div className="col-md-8 col-sm-10 mx-auto">
            <div className="row p-xxs-5 justify-content-center">
              {((!props.selectedNumberType?.startsWith('08') && !props.basket?.numbers[0].number.startsWith('080')) && state.hasMinsPack === true) &&
                <div className="col-md-6 col-sm-6 mb-xs-20">
                  <div
                    className={`brad-30 p-20 bg-light text-center cursor-pointer ${props.numberOnly ? 'active b-cta-2' : 'b-2'}`}
                    onClick={() => { handleChangeType(true) }}
                    onMouseEnter={() => { setState({ ...state, hoverNumberOnly: true }) }}
                    onMouseLeave={() => { setState({ ...state, hoverNumberOnly: false }) }}>
                    <span className="block text-start">
                      <i className={`icon icon-checkmark-circle text-xl ${props.numberOnly ? 'text-success' : 'text-dull'}`} />
                    </span>
                    <div className="p-20 pl-15 pr-15">
                      {props.numberOnly || state.hoverNumberOnly
                        ? <SvgRender icon="make-receive-calls" svgHeight={50} />
                        : <SvgRender icon="make-receive-calls-inactive" svgHeight={50} />
                      }
                      <h3 className="text-500 text-lg mb-10">Virtual Number Only</h3>
                      <p className="text-md text-400 text-cta mb-20">Just &pound;{(
                        ((props.basket?.numbers?.length ?? 0 > 0) &&
                          props.basket!.numbers[0].number?.startsWith('080'))
                          ? MultiNumberPrice0800
                          : MultiNumberPrice)} per
                        number, per month</p>
                      <p><em>
                        Instantly add extra number(s) to your account and share your existing
                        minutes.
                      </em></p>
                      <Button
                        className="btn-rounded mt-30 pl-50 pr-50 pr-sm-30 pl-sm-30 pr-xs-15 pl-xs-15 btn-xs-block"
                        onClick={() => { handleChangeType(true) }}>{props.numberOnly ? 'Selected' : 'Continue'}</Button>
                    </div>
                  </div>
                </div>}
              <div className="col-md-6 col-sm-6">
                <div
                  className={`brad-30 p-20 bg-light text-center cursor-pointer ${props.numberFull ? 'active b-cta-2' : 'b-2'}`}
                  onClick={() => { handleChangeType(false) }}
                  onMouseEnter={() => { setState({ ...state, hoverNumberFull: true }) }}
                  onMouseLeave={() => { setState({ ...state, hoverNumberFull: false }) }}>
                  <span className="block text-start">
                    <i className={`icon icon-checkmark-circle text-xl ${props.numberFull ? 'text-success' : 'text-dull'}`} />
                  </span>
                  <div className="p-20 pl-15 pr-15">
                    {props.numberFull || state.hoverNumberFull
                      ? <SvgRender icon="receive-calls-only" svgHeight={50} />
                      : <SvgRender icon="receive-calls-only-inactive" svgHeight={50} />
                    }
                    <h3 className="text-500 text-lg mb-10">Virtual Number + Minutes</h3>
                    <p className="text-md text-400 text-cta mb-20">From just <GetProdPrice prefix="01" /> per month</p>
                    <p><em>
                      Add extra number(s) to your account with additional minutes to landline or
                      mobile.
                    </em></p>

                    <Button
                      className="btn-rounded mt-30 pl-50 pr-50 pr-sm-30 pl-sm-30 pr-xs-15 pl-xs-15 btn-xs-block"
                      onClick={() => { handleChangeType(false) }}>{props.numberFull ? 'Selected' : 'Continue'}</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default SignupType