import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import Helmet from 'react-helmet'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ClientLogos from '../../components/ClientLogos'

class IanGarrett extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return <>
			<ScrollToTopOnMount />
			<DisplayMainFooter />
			<DisplayMainHeader />

			<Helmet>
				<title>How SwitchboardFREE Transformed the Way A Building Design Firm Communicated With Their Customers</title>
			</Helmet>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container">
					<div className="row mt-100 mt-xs-30 justify-content-center">
						<div className="col-12 text-center">
							<h1 className="text-500 text-darker">How SwitchboardFREE Transformed the Way A Building Design Firm Communicated With Their Customers</h1>
						</div>

						<div className="col-md-8 col-sm-12 text-center mt-30">
							<div class="ratio ratio-16x9">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/H0T1x2nda_w?si=VT9sc-wur76P2TKS" title="Case study video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-80 pt-xs-40 pt-80 pb-xs-40" style={{ backgroundColor: '#d6e8ef' }}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-md-12">
							<h2 className="text-500 text-darker text-center h4 m-0">
								A friend suggested they try SwitchboardFREE and since then, they’ve not looked back!
							</h2>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-light pb-60 pt-0">
				<div className="container">
					<div className="row mt-100 mt-xs-100">
						<div className="col-md-6 col-sm-12 order-last order-md-first text-end pr-50 pr-xs-0">
							<img src="/images/case-studies/ian-garrett.jpg" alt="Marketing agency" className="img-responsive" style={{ maxWidth: '600px' }} />
						</div>
						<div className="col-md-6 col-sm-12 order-first order-md-last">
							<p>
								Since opening in 1987, Ian Garratt Building Design had been using an answering machine to pick up calls to the office whilst they were out with clients. It got to the point where calls were so backed up that it took far too long to listen to messages, sort them and get back to the customer. Something had to give.
							</p>
							<p className="text-lg text-500 text-secondary">
								A friend suggested they try SwitchboardFREE and since then, they’ve not looked back!
							</p>

							<p>
								After receiving their new handsets, they just plugged them in, and they were all set. Kyle has found it invaluable that he can receive missed call messages and voicemails transcribed into emails. He can quickly check the details whilst out and about and efficiently prioritise any callbacks.
							</p>
							<p>
								Both Kyle and Ian also have comfort in the flexibility of being able to make calls from their mobiles using their business number. It’s meant that they’ve kept their personal numbers private, which is vitally important. Having this facility has also given way to a more versatile way of working. Kyle can transfer calls to and from the office, and work from home and Ian has conducted business from all over the country from their company number. 
							</p>
							<p>
								Kyle and Ian had many kind words to say about the team at SwitchboardFREE, but don’t take our word for it, have a look for yourself. 
							</p>
							<p className="mt-30">
								<a className="btn btn-secondary mr-20 mr-xs-0" href="https://youtu.be/H0T1x2nda_w" target="_blank">
									Watch Kyle & Ian's Interview
								</a>
								<Link className="btn btn-cta mr-20 mr-xs-0" to="/">
									Can SwitchboardFREE Help My Business?
								</Link>
							</p>
						</div>
					</div>

				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center mt-20">
						<div id="contentMobile"></div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull ">
				<div className="container">
					<CarouselWidget />
				</div>
			</div>

			{/* Logoos */}
			<div className="full-width bg-light">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">Partners &amp; Customers</h3>
							{/* <h6>The UK's favourite call management system</h6> */}
							<p>Instant activation  •  No long-term contracts  •  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>
			<ClientLogos style='light' />


		</>
	}
}

export default connect(
	state => {
		return ({
		})
	},
	{}
)(IanGarrett)
