// Add any local storage keys here. Typescript will tell us if we are using a key which is not in this list.

// places we need to update.
export declare type LocalStorageKey =
    'LandingPageNumberSelection'
    | 'signupKey'
    | 'addedToAccount'
    | 'paymentProvider'
    | 'userDetails'
    | 'purchaseData'
    | 'returnRoute'
    | 'goCardlessId'
    | 'isNewCustomer'
    | 'signupValue'
    | 'clientId'
    | 'signupData'
    | 'ipAddress'
    | 'isBeta'
    | 'user'
    | 'billingRestricted'
    | 'clientId'
    | 'Basket'
    | 'seenWhatsApp'
    | 'skipToCheckout'
    | 'buyWhatsApp'
    | 'lookingAt'
    | 'isUpdating'
    | 'HardwareFirst'
    | 'landingPage'
    | 'inboundOnly'
    | 'stripeSecret'
    | 'BasketHardware'
    | 'DeliveryAddress'
    | 'SignupStep'
    | 'LoggedInNumberSelection'
    | 'IsOutbound'
    | 'IsPorting'
    | 'marketingData'
    | 'basket'
    | 'GaId'

export interface LocalStorageItem<T> {
  value: T | null
  onNotRequiredAnymore: (shouldDelete?: boolean) => void
}

export default class LocalStorageHelper {
  static hasItem (key: LocalStorageKey): boolean {
    if (LocalStorageHelper.hasKeyExpired(key)) {
      return false
    }

    const item = localStorage.getItem(key)
    return item !== null && item !== undefined && item !== ''
  }

  private static hasKeyExpired (key: LocalStorageKey): boolean {
    if (localStorage.getItem(this.getExpireKey(key)) && localStorage.getItem(this.getExpireKey(key)) !== "undefined") {
      const expireDate = JSON.parse((localStorage.getItem(this.getExpireKey(key)))!) as string

      // check if the current date is later then expireDate
      const hasExpired = new Date() > new Date(expireDate)
      if (hasExpired) {
        this.removeItemAndExpireDate(key)
      }
      return hasExpired
    }
    return false
  }

  static getItem<T>(key: LocalStorageKey): LocalStorageItem<T> {
    if (LocalStorageHelper.hasKeyExpired(key)) {
      return {
        value: null,
        onNotRequiredAnymore: () => {}
      }
    }

    const item = localStorage.getItem(key)
    if (!item || (item === undefined || item === 'undefined')) {
      return {
        value: null, onNotRequiredAnymore: () => {}
      }
    }

    const jsonParsed = JSON.parse(item) as T

    return {
      value: jsonParsed,
      onNotRequiredAnymore: (shouldDelete?: boolean) => {
        if (shouldDelete) {
          this.removeItemAndExpireDate(key)
        }
      }
    }
  }

  private static getExpireKey (key: LocalStorageKey) {
    return `${key}_expire`
  }

  private static removeItemAndExpireDate (key: LocalStorageKey) {
    localStorage.removeItem(key)
    localStorage.removeItem(LocalStorageHelper.getExpireKey(key))
  }

  static setItem<T>(key: LocalStorageKey, val: T, expireDate?: Date) {
    localStorage.setItem(key, JSON.stringify(val))
    if (expireDate !== null && expireDate !== undefined) {
      localStorage.setItem(LocalStorageHelper.getExpireKey(key), JSON.stringify(expireDate))
    }
  }

  static removeItem (key: LocalStorageKey) {
    this.removeItemAndExpireDate(key)
  }
}
